import { Component, OnInit, ViewChild } from '@angular/core';
import { WelloneParameterModel } from 'app/wellone/models/welloneparameter.model';
import { WelloneConstant } from 'app/wellone/models/welloneconstant.model';
import { GlobalFilterService } from 'app/wellone/services/globalfilter.service';
import { FinancialScoreService } from 'app/wellone/services/financial.service';
import { FinancialScoreDetailModel } from 'app/wellone/models/financialscore.model';
import { List } from 'linqts';
import { WelloneService } from 'app/wellone/services/wellone.service';
import * as moment from 'moment';

@Component({
  selector: 'app-wellone-financial-score-detail',
  templateUrl: './financialscore.component.html',
  styleUrls: ['./financialscore.component.css']
})

export class FinancialScoreComponent implements OnInit {

  isLoading: boolean = false;
  errorMsg: string;
  loaded: boolean = false;
  financialScoreObj: FinancialScoreDetailModel;
  financialParams: WelloneParameterModel;
  currentTabSummaryCardHeight: string = "550px";
  currentTabScoreCardHeight: string = "500px";
  changePercentage = 0;
  maxDate = '';
  chartMaxYNumber:number;

  tabList: any[] = [{ name: WelloneConstant.ByAge, value: 1 }, { name: WelloneConstant.ByBusinessUnit, value: 2 }, { name: WelloneConstant.ByLocation, value: 3 }]
  constructor(private financialSvc: FinancialScoreService,
    private globalFilterService: GlobalFilterService,
    private welloneService: WelloneService
  ) {


  }

  ngOnInit() : void {

    this.loadFinancialScoreData();
    // filter change
    this.globalFilterService.filterApplyClick.subscribe(res => {
      this.loadFinancialScoreData();
    });

  }

  setBannerText(data) : void {
    this.maxDate = this.formatDate(data.maxDate, 0);
    if (this.financialParams.GlobalFilters.TimePeriod === WelloneConstant.Week) {
      this.maxDate = this.formatDate(data.maxDate, 7);
      this.percChange(data.averageScore_WeekAgo, data.averageScore);
    }
    else if (this.financialParams.GlobalFilters.TimePeriod === WelloneConstant.Month) {
      this.maxDate = this.formatDate(data.maxDate, 30);
      this.percChange(data.averageScore_MonthAgo, data.averageScore);
    }
    else if (this.financialParams.GlobalFilters.TimePeriod === WelloneConstant.Quarter) {
      this.maxDate = this.formatDate(data.maxDate, 90);
      this.percChange(data.averageScore_QtrAgo, data.averageScore);
    }
    else {
      this.maxDate = this.formatDate(data.minDate, 0);
      this.percChange(data.averageScore_Inception, data.averageScore);
    }

  }

  percChange(old:number, latest:number) : void {
    let temp = (latest - old) / old;
    this.changePercentage = +((temp*100).toFixed(1));
  }

  formatDate(responseDate:string, subtractDays:number) : string {
    let date = new Date(responseDate);
    let calculatedDate = new Date();
    if (subtractDays != 0) {
      calculatedDate.setDate(date.getDate() - subtractDays);
    }
    else {
      return moment(date).format('DD-MMM-YYYY');
    }
    
    return moment(calculatedDate).format('DD-MMM-YYYY');
  }

  private loadFinancialScoreData(): void {
    let beforeDate: Date = new Date();
    this.isLoading = true;
    this.loaded = false;
    var oldCopy = this.financialScoreObj ? JSON.parse(JSON.stringify(this.financialScoreObj)) : undefined;
    this.financialScoreObj = undefined;
    this.errorMsg = undefined;
    this.financialParams = this.welloneService.getParamInfo();
    this.financialSvc.getFinancialResult(this.financialParams).toPromise().then((res) => {
      this.isLoading = false;
      this.parseFinancialScoreResult(res, oldCopy);      
      this.loaded = true;
      var afterDate: Date = new Date();
      var diff = (beforeDate.getTime() - afterDate.getTime());
      console.log('lapse in millisecond ' + diff);
      var lapseInSecond = (diff / 1000);
      console.log('lapse in second ' + lapseInSecond);
     
    }).catch(error => {
      this.isLoading = false;
      this.errorMsg = error.message;
      console.log(error);
    });
  }

  private parseFinancialScoreResult(res: any, oldRef: any): void {
    this.setBannerText(res.currentTabResult);
    res.historyTabResult.topBusinessUnitList.forEach((row, index) => {
      row.seriesData.forEach(childRow => {
        childRow.xVal = new Date(childRow.xVal);
      });
      row.seriesData = new List<any>(row.seriesData).OrderBy(x => new Date(x.xVal)).ToArray();
    });
    res.historyTabResult.bottomBusinessUnitList.forEach((row, index) => {
      row.seriesData.forEach(childRow => {
        childRow.xVal = new Date(childRow.xVal);
      });
      row.seriesData = new List<any>(row.seriesData).OrderBy(x => new Date(x.xVal)).ToArray();
    });

    res.historyTabResult.bottomWithoutZeroBusinessUnitList.forEach((row, index) => {
      row.seriesData.forEach(childRow => {
        childRow.xVal = new Date(childRow.xVal);
      });
      row.seriesData = new List<any>(row.seriesData).OrderBy(x => new Date(x.xVal)).ToArray();
    });
    res.historyTabResult.participantTabList = [
      { name: 'AGE', value: 1 },
      { name: 'BUSINESS UNIT', value: 2 }
    ];
    res.currentTabResult.tabList = [
      {name: WelloneConstant.ByAge , value: 1},
      {name : WelloneConstant.ByBusinessUnit, value:2},
      {name: WelloneConstant.ByLocation, value : 3}
    ]
    res.historyTabResult.blankArr = [];

    if (oldRef) {
      res.isCurrentTabSelected = oldRef.isCurrentTabSelected;
      res.selectedScoreDetailTabIndex = oldRef.selectedScoreDetailTabIndex;
      res.historyTabResult.BUSelectedIndex = oldRef.historyTabResult.BUSelectedIndex;
      res.selectedParticipantTabIndex = oldRef.selectedParticipantTabIndex;
      res.selectedBUIndex = oldRef.selectedBUIndex;
      res.selectedCountryIndex = oldRef.selectedCountryIndex;
      res.historyTabResult.participationSelectedBUIndex = oldRef.historyTabResult.participationSelectedBUIndex;
      res.historyTabResult.ParticipationLevelHasBottomRankingUsers = oldRef.historyTabResult.ParticipationLevelHasBottomRankingUsers;
    }
    else {
      res.isCurrentTabSelected = true;
      res.selectedScoreDetailTabIndex = 1;
      res.historyTabResult.BUSelectedIndex = 1;
      res.historyTabResult.participationSelectedBUIndex = 1;
      res.selectedParticipantTabIndex = 1;
      res.selectedBUIndex = 1;
      res.selectedCountryIndex = 1;
      res.historyTabResult.ParticipationLevelHasBottomRankingUsers = false;
     
    }
    res.historyTabResult.selectedParticipantRow = new List<any>(res.historyTabResult.participationList).FirstOrDefault(x => x.selected);
    res.historyTabResult.CustomTickArr = this.getFinancialCustomTickArr(res);
    this.financialScoreObj = res;
    if(this.financialScoreObj.selectedScoreDetailTabIndex === 1 )
    {
      this.financialScoreDetailTabClick({detail: WelloneConstant.ByAge});
    }

   this.setChartMaxYAxis();
  
  }

  private setChartMaxYAxis(): void
  {
    if(!this.financialScoreObj.isCurrentTabSelected)
    {
      let maxNumber = 0;
      if(this.financialScoreObj.historyTabResult.BUSelectedIndex === 1)
      {
        this.financialScoreObj.historyTabResult.topBusinessUnitList.forEach(row => {
          let maxNum = new List<any>(row.seriesData).Max(x => x.yVal);
          if(maxNum > maxNumber)
          {
            maxNumber = maxNum;
          }
        });
        
      } 
      else
      {
         if(this.financialScoreObj.historyTabResult.ParticipationLevelHasBottomRankingUsers)
         {
          this.financialScoreObj.historyTabResult.bottomWithoutZeroBusinessUnitList.forEach(row => {
            let maxNum = new List<any>(row.seriesData).Max(x => x.yVal);
            if(maxNum > maxNumber)
            {
              maxNumber = maxNum;
            }
          });
          
         }
         else
         {
          this.financialScoreObj.historyTabResult.bottomBusinessUnitList.forEach(row => {
            let maxNum = new List<any>(row.seriesData).Max(x => x.yVal);
            if(maxNum > maxNumber)
            {
              maxNumber = maxNum;
            }
          });
          
         }
      }
      this.chartMaxYNumber = maxNumber * 1.1;
    }
  }

  onBottom5HasUsersChange(): void {
    this.financialScoreObj.historyTabResult.ParticipationLevelHasBottomRankingUsers = !this.financialScoreObj.historyTabResult.ParticipationLevelHasBottomRankingUsers;
    this.financialScoreObj.historyTabResult.CustomTickArr = this.getFinancialCustomTickArr(this.financialScoreObj);
    this.setChartMaxYAxis();
  }

  private onParticipationBUDisplayChange(event) : void
  {
    this.financialScoreObj.historyTabResult.participationSelectedBUIndex = parseInt(event.detail);
  }

  private financialScoreTabClick(event): void {
    this.financialScoreObj.isCurrentTabSelected = (event.detail.trim() === "CURRENT");
   
      this.setChartMaxYAxis();
    
  
  }

  private onBUDisplayChange(event): void {
    this.financialScoreObj.historyTabResult.BUSelectedIndex = parseInt(event.detail);
    this.setChartMaxYAxis();
  }

  private financialScoreRangeTabClick(event, row): void {

    var selectedIndex = 1;
    if (event.detail === WelloneConstant.ByAge) {
      selectedIndex = 1;
    }
    else if (event.detail === WelloneConstant.ByBusinessUnit) {
      selectedIndex = 2;
    }
    else if (event.detail === WelloneConstant.ByLocation) {
      selectedIndex = 3;
    }
    row.selectedTabIndex = selectedIndex;

  }

  private getHeight(data: any[]): string {
    var arrLength = data.length;
    let result = this.getRowCountHeight(arrLength) + 'px';
    return result;
  }

  private getRowCountHeight(rowCount: number): number {
    var result = 0;
    if (rowCount === 1) {
      result = 200;
    }
    else if (rowCount === 2) {
      result = 300;
    }
    else if (rowCount === 3) {
      result = 360;
    }
    else if (rowCount === 4) {
      result = 470;
    }
    else if (rowCount > 4) {
      result = (rowCount * 100);
    }
    return result;
  }

  private barExpandCollapseClick(event): void {
    var selectedRow = event.detail;
    var data = this.financialScoreObj.currentTabResult.scoreRangeList;
    let expandedRow = new List<any>(data).FirstOrDefault(x => x.name === selectedRow.text);
    if (expandedRow) { expandedRow.isexpand = selectedRow.isexpand; }
    var expandedRowCount = new List<any>(data).Count(x => x.isexpand);
    var expandHeight = (680 * expandedRowCount);
    this.currentTabSummaryCardHeight = (550 + expandHeight) + 'px';
  }

  private financialScoreDetailTabClick(event): void {
    let rowCount = 0;
    if (event.detail === WelloneConstant.ByAge) {
      this.financialScoreObj.selectedScoreDetailTabIndex = 1;
      rowCount =  this.financialScoreObj.currentTabResult.ageList.length;
    }
    else if (event.detail === WelloneConstant.ByBusinessUnit) {
      this.financialScoreObj.selectedScoreDetailTabIndex = 2;
      rowCount = this.financialScoreObj.selectedBUIndex === 1 ?
       this.financialScoreObj.currentTabResult.topBusinessUnitList.length :
       this.financialScoreObj.currentTabResult.bottomBusinessUnitList.length;
    }
    else if (event.detail === WelloneConstant.ByLocation) {
      this.financialScoreObj.selectedScoreDetailTabIndex = 3;
      rowCount = this.financialScoreObj.selectedCountryIndex === 1 ?
                this.financialScoreObj.currentTabResult.topCountryList.length :
                this.financialScoreObj.currentTabResult.bottomCountryList.length;
    }
    if(rowCount > 0)
    {
      this.currentTabScoreCardHeight = 200 + (this.getRowCountHeight(rowCount)) + 'px';
    }
    else
    {
      this.currentTabScoreCardHeight = '300px';
    }
    
  }

  nextIconDisabled(): boolean {
    if (!this.financialScoreObj.historyTabResult.selectedParticipantRow) {
      return true;
    }
    let maxId = new List<any>(this.financialScoreObj.historyTabResult.participationList).Max(x => x.id);
    let selectedId = this.financialScoreObj.historyTabResult.selectedParticipantRow.id;
    return (maxId === selectedId);
  }

  prevIconDisabled(): boolean {
    if (!this.financialScoreObj.historyTabResult.selectedParticipantRow) {
      return true;
    }
    let minId = new List<any>(this.financialScoreObj.historyTabResult.participationList).Min(x => x.id);
    let selectedId = this.financialScoreObj.historyTabResult.selectedParticipantRow.id;
    return (minId === selectedId);
  }

  private getPeriodDetail(): string {
    let result = '';
    if (this.financialParams.GlobalFilters.TimePeriod === WelloneConstant.All) {
      result = 'All Record';
    }
    else if (this.financialParams.GlobalFilters.TimePeriod === WelloneConstant.Week) {
      result = '1 Week Record';
    }
    else if (this.financialParams.GlobalFilters.TimePeriod === WelloneConstant.Month) {
      result = '1 Month Record';
    }
    else if (this.financialParams.GlobalFilters.TimePeriod === WelloneConstant.Quarter) {
      result = '3 Month Record';
    }
    else {
      result = 'All Record';
    }

    return result;
  }

  participartionBarClick(event: any): void {
    var row = event.detail;
    var selectedId = row.id;
    let selectedRow = new List<any>(this.financialScoreObj.historyTabResult.participationList).FirstOrDefault(x => x.id === selectedId);
    let participationList = JSON.parse(JSON.stringify(this.financialScoreObj.historyTabResult.participationList));
    participationList.forEach(row => {
      row.colorindex = (row.id === selectedId) ? 13 : 3;
      row.selected = (row.id === selectedId);
    });
    this.financialScoreObj.historyTabResult.participationList = participationList;
    this.financialScoreObj.historyTabResult.selectedParticipantRow = selectedRow;

  }

  prevIconClick($event): void {
    let minId = new List<any>(this.financialScoreObj.historyTabResult.participationList).Min(x => x.id);
    let selectedId = this.financialScoreObj.historyTabResult.selectedParticipantRow.id;
    if (minId !== selectedId) {
      selectedId = selectedId - 1;
      let selectedRow = new List<any>(this.financialScoreObj.historyTabResult.participationList).FirstOrDefault(x => x.id === selectedId);
      let participationList = JSON.parse(JSON.stringify(this.financialScoreObj.historyTabResult.participationList));
      participationList.forEach(row => {
        row.colorindex = (row.id === selectedId) ? 13 : 3;
        row.selected = (row.id === selectedId);
      });
      this.financialScoreObj.historyTabResult.participationList = participationList;
      this.financialScoreObj.historyTabResult.selectedParticipantRow = selectedRow;
    }

  }

  participantDemoGraphicTabChanged(event): void {
    if (event.detail.trim() === 'AGE') {
      this.financialScoreObj.selectedParticipantTabIndex = 1;
    }
    else if (event.detail.trim() === 'BUSINESS UNIT') {
      this.financialScoreObj.selectedParticipantTabIndex = 2;
    }
  }

  private onScoreBUDisplayChange(event, row): void
  {
    if(row)
    {
      row.selectedBusinessUnitIndex = parseInt(event.detail);
    }
    else
    {
      this.financialScoreObj.selectedBUIndex = parseInt(event.detail);
    }
    
  }

  private onScoreCountryDisplayChange(event, row): void
  {
    if(row)
    {
      row.selectedCountryIndex = parseInt(event.detail);
    }
    else
    {
      this.financialScoreObj.selectedCountryIndex = parseInt(event.detail);
    }
    
  }

  nextIconClick($event): void {

    let maxId = new List<any>(this.financialScoreObj.historyTabResult.participationList).Max(x => x.id);
    let selectedId = this.financialScoreObj.historyTabResult.selectedParticipantRow.id;
    if (maxId !== selectedId) {
      selectedId = selectedId + 1;
      let selectedRow = new List<any>(this.financialScoreObj.historyTabResult.participationList).FirstOrDefault(x => x.id === selectedId);
      let participationList = JSON.parse(JSON.stringify(this.financialScoreObj.historyTabResult.participationList));
      participationList.forEach(row => {
        row.colorindex = (row.id === selectedId) ? 13 : 3;
        row.selected = (row.id === selectedId);
      });
      this.financialScoreObj.historyTabResult.participationList = participationList;
      this.financialScoreObj.historyTabResult.selectedParticipantRow = selectedRow;
    }

  }

  private getFinancialCustomTickArr(data: any): any[] {
    var financialResult = JSON.parse(JSON.stringify(data))
    let result: any[] = [];
    var list = financialResult.historyTabResult.BUSelectedIndex === 1 ?
      financialResult.historyTabResult.topBusinessUnitList :(financialResult.historyTabResult.ParticipationLevelHasBottomRankingUsers ? financialResult.historyTabResult.bottomWithoutZeroBusinessUnitList : financialResult.historyTabResult.bottomBusinessUnitList);
    var seriesData = [];
    if (list.length > 0) {
      seriesData = list[0].seriesData;      
      for(var count=1; count< list.length; count++)
      {
          var currentRow = list[count];
          if(seriesData.length < currentRow.seriesData.length)
          {
            seriesData = currentRow.seriesData;
          }
      }
    
      
      let tickNum = this.getTickSize(seriesData);
      let participationList = new List<any>(seriesData).OrderByDescending(x => new Date(x.xVal)).ToArray();
      let days = Math.round(participationList.length / tickNum);
      if (participationList !== null && participationList.length >= 7) {
        var scoreDate = new Date(participationList[0].xVal);
        result.push(scoreDate);
        var count = 0;
        var tickSize = 0;
        while (tickSize < (tickNum - 1)) {
          result.push(this.addDays(scoreDate, -1 * days));
          count = count + days;
          scoreDate = participationList[count].xVal;
          tickSize = tickSize + 1;
        }
        result = new List<any>(result).OrderBy(x => x).ToArray();
      }
    }


    return result;
  }

  private addDays(scoreDate: Date, days: number): Date {
    var result = new Date(scoreDate);
    result.setDate(result.getDate() + days);
    return result;
  }

  private getTickSize(data: any): any {
    var result = 7;
    switch (this.financialParams.GlobalFilters.TimePeriod) {
      case WelloneConstant.Week:
        result = 7;
        break;
      case WelloneConstant.Month:
        result = 6;
        break;
      case WelloneConstant.Quarter:
        result = 8;
        break;
      case WelloneConstant.All:
        var monthNum = Math.round(data.length / 30);
        if (monthNum <= 12) {
          result = (monthNum > 3) ? 8 : 6;

        }
        if (monthNum > 12) {
          result = 6;
        }

        monthNum = Math.round(data.length / 30);
        if (monthNum <= 12) {
          result = (monthNum > 3) ? 8 : 6;
        }
        if (monthNum > 12) {
          result = 8;
        }
        break;
      default:
        result = 8;
        break;
    }
    return result;
  }


}
