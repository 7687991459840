import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { JsonPipe } from '@angular/common';
import { GlobalFilterModel } from 'app/wellone/models/globalfilter.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalFilterService {

  @Output() filterApplyClick: EventEmitter<any> = new EventEmitter();
  @Output() hideFilterEvent: EventEmitter<any> = new EventEmitter(); 

  
  selectedValues:any = {};
  constructor(private httpClient: HttpClient) 
  {

  }

  public UpdateGlobalFilterValues(res:any): void
  {
      this.selectedValues = JSON.parse(JSON.stringify(res));
      this.filterApplyClick.emit(this.selectedValues);
  }

  public showFilter(val:boolean): void
  {
    this.hideFilterEvent.emit(val);
  }

  public GetGlobalFilterValues(): any
  {
     var result :GlobalFilterModel = new GlobalFilterModel();
     if(this.selectedValues.BU)
     {
        result.BusinessUnit = this.selectedValues.BU.optionValueList;
     }
     if(this.selectedValues.country)
     {
      result.CountryList = this.selectedValues.country.optionValueList;
     }
     if(this.selectedValues.timeperiod)
     {
      result.TimePeriod = this.selectedValues.timeperiod.optionValueList[0];
     }
     return result;
  }

  public ResetGlobalFilterValues(): void
  {
     this.selectedValues = {};    
     
  }


  public getFilterCountryBUList(params: any)
  {
    return this.httpClient.post<any>(environment.api + 'api/Dashboard/country-bu-list', params);
  }


  
 
}

