import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-cost-projection-save-dialog',
  templateUrl: './cost-projection-save-dialog.component.html',
  styleUrls: ['./cost-projection-save-dialog.component.scss']
})
export class CostProjectionSaveDialogComponent implements OnInit {

  public isSaveDisabled = true;

  constructor(private _dialogRef: MatDialogRef<CostProjectionSaveDialogComponent>,
    private _fb: FormBuilder) { }

  public saveForm = this._fb.group({
    name: ['', [Validators.required]]
  });

  ngOnInit(): void {
    this.saveForm.markAllAsTouched();

    this.saveForm.get('name').valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((name: string) => {
        this.isSaveDisabled = name.trim().length === 0;
      });
  }

  public onSave(): void {
    this._dialogRef.close(this.saveForm.value);
  }
}
