import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FlexChartService {

  constructor(private httpClient: HttpClient) { }

  public getFlexChartData(dashboardParams: any)
  {
    console.log('start to load');
    return this.httpClient.post<any>(environment.api + 'api/Visualization/catch-all', dashboardParams);
  }

  public getCatchAllFlexMappingResult(dashboardParams: any)
  {
    console.log('start to load options');
    return this.httpClient.post<any>(environment.api + 'api/Visualization/catch-all-flex-mapping', dashboardParams);
  }
  
 
}
