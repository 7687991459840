<div class="columnDiv contentPadding" *ngIf="!isLoading && healthScoreObj">
    <div>&nbsp;<br /></div>
    <div>
        <div class="rowDiv">
            <div>
                <div class="rowDiv">
                    <div style="padding-right:16px; padding-top: 12px;">
                        <fa-icon [icon]="['fal', 'heart-rate']"
                        [styles]="{'font-size': '32px', 'color': '#001B5A'}"></fa-icon>
                    </div>
                    <div><aon-h1 style="color:#001B5A;"> Health Score</aon-h1></div>
                </div>
            </div>
            <div class="vertical-line marginLeft20"></div>
            <div class="marginLeft20 marginTop20">
                <aon-p-elg class="fontWeight500">
                    <b> {{ healthScoreObj.healthScoreFormatDate }}</b>
                </aon-p-elg>
            </div>
        </div>
    </div>

    <div>
        <aon-horizontaltab-list (onchange)="healthScoreTabClick($event)">
            <aon-horizontaltab value=1 [selected]="healthScoreObj.currentTabSelection === 'CURRENT'">CURRENT</aon-horizontaltab>
            <aon-horizontaltab value=2 [selected]="healthScoreObj.currentTabSelection === 'HISTORY'">HISTORY</aon-horizontaltab>
            <aon-horizontaltab value=3 [selected]="healthScoreObj.currentTabSelection === 'ORGANIZATION'">ORGANIZATION</aon-horizontaltab>
        </aon-horizontaltab-list>
    </div>
    <div>
        &nbsp;
        <br />
    </div>
    <div >
        <aon-bar-summary width="100%" height="140px" colorindex="11" *ngIf="healthScoreObj.currentTabSelection === 'CURRENT'">
            <aon-bar-summary-content width="15%" top="60px">
                <div class="columnDiv marginLeft20">
                    <div>
                        <aon-p-lg class="whiteColorText">
                            Average Score
                        </aon-p-lg>
                    </div>
                    <div class="negativeMarginTop20">
                        <aon-h1 class="whiteColorText">{{ healthScoreObj.currentTabResult.averageScore }}</aon-h1>
                    </div>
                </div>
            </aon-bar-summary-content>
            <aon-vertical-line></aon-vertical-line>
            <aon-bar-summary-content width="80%" top="60px">
                <div class="columnDiv" style="margin-top: 20px;">
                    <div class="marginTop10" *ngIf="healthScoreObj.isCurrentTabSelected" style=" width:100%">
                        <div class="rowDiv" >
                            <div >
                                <fa-icon size="2x" [icon]="['fal', 'thumbs-up']" [styles]="{'color': 'white'}"></fa-icon>
                            </div>
                            <div 
                                style="margin-left:10px;">
                                <aon-p-elg class="whiteColorText">
                                    Well done! Your Population does not prefer to smoke and this  has the highest positive impact on your overall health score
                                </aon-p-elg>
                            </div>
                        </div>
                        
                    </div>
                    <div *ngIf="healthScoreObj.isCurrentTabSelected" style=" width:100%">
                        <div class="rowDiv" style=" width:100%" >
                            <div >
                                <fa-icon size="2x" [icon]="['fal', 'thumbs-down']" [styles]="{'color': 'white'}"></fa-icon>
                            </div>
                            <div 
                                style="margin-left:10px; margin-top:-5px">
                                <aon-p-elg class="whiteColorText">
                                    Step up! Your population appears to have a low movment score, do propose some walking/moving challenges!
                                </aon-p-elg>
                            </div>
                        </div>
                        
                    </div>

                    <div class="marginTop10" *ngIf="!healthScoreObj.isCurrentTabSelected" style=" width:100%">
                        <div class="rowDiv" >
                            
                            <div>
                                <aon-h4 class="whiteColorText">
                                    Your health score has changed {{ changePercentage }}% since {{ maxDate }}
                                </aon-h4>                           
                            </div>
                        </div>                        
                    </div>                 
                </div>
            </aon-bar-summary-content>

        </aon-bar-summary>
    </div>
    <div>
        <br />
    </div>

    <div *ngIf="healthScoreObj.currentTabSelection === 'CURRENT'">
        <aon-summary-card width="99%" [height]="currentTabSummaryCardHeight">
            <aon-summary-card-header>
                <aon-h3 style="color:#001B5A;">
                    User Distribution by Score Level</aon-h3>
            </aon-summary-card-header>
            <aon-summary-card-content contentwidth="97.5%" top="60px" yaxisoverflow="false">
                <div class="columnDiv">

                    <div class="marginTop20">
                        <aon-h5>Score Breakdown</aon-h5>
                    </div>
                    <div>
                        <aon-p-elg>Select a segment below to see score range details</aon-p-elg>
                    </div>
                    <div>
                        <aon-expandable-bar-chart *ngIf="healthScoreObj.currentTabResult.healthScoreRangeList.length > 0" width ="98%" [data]="healthScoreObj.currentTabResult.healthScoreRangeList"
                            height="500px">
                            <aon-bar-content [text]="row.name" [value]="row.value" [isexpand] ="row.isexpand" (onexpandcollapseclick)="barExpandCollapseClick($event)"
                                *ngFor="let row of healthScoreObj.currentTabResult.healthScoreRangeList;">
                                <div style="height:600px; width:100%;">
                                    <aon-summary-card height="600px">
                                        <aon-summary-card-header>
                                            <aon-h3 style="color:#001B5A;">
                                                {{ row.scoreRange}}
                                            </aon-h3>
                                        </aon-summary-card-header>
                                        <aon-summary-card-content top="70px">
                                            <div class="rowDiv" style='padding-right: 10px;'>
                                              
                                                <div style="width:100%">

                                                    <div
                                                        style="height: 550px; width: 100%; display: flex; flex-direction: column; background-color: white; border-radius: 5px;">

                                                     
                                                        <div style="width: 95%;">
                                                            <aon-horizontaltab-list
                                                                (onchange)="healthScorRangeDemoGraphicTabChanged($event, row.id)">
                                                                <aon-horizontaltab
                                                                    *ngFor="let tabRow of healthScoreObj.demographicTabList;"
                                                                    [value]="tabRow.value"
                                                                    [selected]="tabRow.value === row.selectedDemographicTabIndex">
                                                                    {{ tabRow.name}}</aon-horizontaltab>
                                                            </aon-horizontaltab-list>
                                                        </div>
                                                      
                                                        <div style="height: 100%; overflow-y:auto;  width: 98%;">
                                                            <aon-horizontal-bar-chart displayicons="false"
                                                                *ngIf="row.selectedDemographicTabIndex === 1 && row.demoGraphicAgeList.length > 0"
                                                                [data]="row.demoGraphicAgeList" width="96%"
                                                                height="100%" xaxistitle="Number of Users"
                                                                charttitle="">
                                                            </aon-horizontal-bar-chart>
                                                            <aon-horizontal-bar-chart displayicons="false"
                                                                *ngIf="row.selectedDemographicTabIndex === 2 && row.demoGraphicBusinessUnitList.length > 0"
                                                                [data]="row.demoGraphicBusinessUnitList" width="96%"
                                                                [height]="(row.demoGraphicBusinessUnitList.length <= 4 ? (row.demoGraphicBusinessUnitList.length * 175) + 'px': (row.demoGraphicBusinessUnitList.length * 60) + 'px') "
                                                                xaxistitle="Number of Users" charttitle="">
                                                            </aon-horizontal-bar-chart>
                                                            <aon-horizontal-bar-chart 
                                                                *ngIf="row.selectedDemographicTabIndex === 3"
                                                                [data]="row.componentBreakDownList" displayicons=true
                                                                colorindex="0" width="100%" height="480px" charttitle="" tickformat='.0r'>
                                                            </aon-horizontal-bar-chart>
                                                            <div class="marginLeft20"
                                                                *ngIf="row.selectedDemographicTabIndex === 1 && row.demoGraphicAgeList.length === 0">
                                                                <aon-p-elg>Privacy filter applied for selection less than 10 people</aon-p-elg>
                                                            </div>
                                                            <div class="marginLeft20"
                                                                *ngIf="row.selectedDemographicTabIndex === 2 && row.demoGraphicBusinessUnitList.length === 0">
                                                                <aon-p-elg>Privacy filter applied for selection less than 10 people</aon-p-elg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </aon-summary-card-content>
                                    </aon-summary-card>

                                </div>
                            </aon-bar-content>
                            <aon-bar-content isxaxis="true"></aon-bar-content>
                        </aon-expandable-bar-chart>
                        <aon-p-elg *ngIf="healthScoreObj.currentTabResult.healthScoreRangeList.length === 0">
                                Privacy filter applied for selection less than 10 people
                        </aon-p-elg>
                    </div>
                    <div>
                        <div class="rowDiv">
                            <div style="width:98%">
                                <div style="height: 800px; width: 100%; display: flex; flex-direction: column; background-color: white; border-radius: 5px;">
                                    <div style="height: 12%; border-bottom: 1px solid rgba(155,155,155, 0.5);">
                                        <div>
                                            <aon-h3>Category Breakdown</aon-h3>
                                        </div>
                                        <div>
                                            <aon-p-elg>Select a category below for top 5 business units</aon-p-elg>
                                        </div>
                                    </div>
                                    <div style="height: 90%; width: 100%;">
                                       
                                        <aon-expandable-bar-chart *ngIf="healthScoreObj.currentTabResult.componentBreakdownList.length > 0" [data]="healthScoreObj.currentTabResult.componentBreakdownList" height="2000px">
                                            <aon-bar-content [text]="row.name" [value]="row.value" [isexpand] ="row.isexpand" (onexpandcollapseclick)="barExpandCollapseClick($event)"
                                                *ngFor="let row of healthScoreObj.currentTabResult.componentBreakdownList" displayicon = 'true'>
                                                    <!-- <i slot="iconslot" class="far fa-axe fa-lg">p</i> -->
                                                    <fa-icon slot="iconslot" style="font-size:24px" [icon]="['fal', row.icon]" [styles]="{'color': '#2E2E2E'}"></fa-icon>
                                                    <table class='score-breakdown' style="width: 100%">
                                                        <thead>
                                                            <tr>
                                                                <th class="clickable" style='min-width: 250px;' (click)="sortScoreBreakdownByColumn('Group', row.name)">
                                                                    Group
                                                                    <fa-icon style="margin-left: 15px;" *ngIf="healthScoreObj.currentTabResult.componentBreakdownDictionary.componentDictionary[row.name].sortColumnName === 'Group' && healthScoreObj.currentTabResult.componentBreakdownDictionary.componentDictionary[row.name].isSortOrderDescending" [icon]="['fal', 'sort-alpha-down-alt']"></fa-icon>
                                                                    <fa-icon style="margin-left: 15px;" *ngIf="healthScoreObj.currentTabResult.componentBreakdownDictionary.componentDictionary[row.name].sortColumnName === 'Group' && !healthScoreObj.currentTabResult.componentBreakdownDictionary.componentDictionary[row.name].isSortOrderDescending" [icon]="['fal', 'sort-alpha-down']"></fa-icon>
                                                                </th>
                                                                <th class="clickable" (click)="sortScoreBreakdownByColumn(ageGroupKey, row.name)" style="min-width: 218px" *ngFor="let ageGroupKey of healthScoreObj.currentTabResult.componentBreakdownDictionary.ageBandKeyList">
                                                                    {{ageGroupKey}}
                                                                    <fa-icon style="margin-left: 15px;" *ngIf="healthScoreObj.currentTabResult.componentBreakdownDictionary.componentDictionary[row.name].sortColumnName === ageGroupKey && healthScoreObj.currentTabResult.componentBreakdownDictionary.componentDictionary[row.name].isSortOrderDescending" [icon]="['fal', 'sort-size-down']"></fa-icon>
                                                                    <fa-icon style="margin-left: 15px;" *ngIf="healthScoreObj.currentTabResult.componentBreakdownDictionary.componentDictionary[row.name].sortColumnName === ageGroupKey && !healthScoreObj.currentTabResult.componentBreakdownDictionary.componentDictionary[row.name].isSortOrderDescending" [icon]="['fal', 'sort-size-down-alt']"></fa-icon>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody *ngFor="let businessUnitKey of healthScoreObj.currentTabResult.componentBreakdownDictionary.componentDictionary[row.name].businessUnitKeyList.slice(0, 6)">
                                                            <tr>
                                                                <th [title]="businessUnitKey" style='cursor: default'>
                                                                    <div fxLayout='row'>
                                                                        <div>{{businessUnitKey}}</div>
                                                                    </div>                                        
                                                                </th>
                                                                <td *ngFor="let ageGroupKey of healthScoreObj.currentTabResult.componentBreakdownDictionary.ageBandKeyList">                                                                    
                                                                    <aon-percentage-bar-chart 
                                                                    width = '210px'                                                                     
                                                                    *ngIf = "healthScoreObj.currentTabResult.componentBreakdownDictionary.componentDictionary[row.name].businessUnitDictionary[businessUnitKey].ageBandDictionary[ageGroupKey].value > 0"
                                                                    removegrayspace = "true"
                                                                    xaxistextalign = "30"
                                                                    [firstpercentagelabel]='healthScoreObj.currentTabResult.componentBreakdownDictionary.componentDictionary[row.name].businessUnitDictionary[businessUnitKey].ageBandDictionary[ageGroupKey].name' 
                                                                    [firstpercentagevalue]='healthScoreObj.currentTabResult.componentBreakdownDictionary.componentDictionary[row.name].businessUnitDictionary[businessUnitKey].ageBandDictionary[ageGroupKey].value' 
                                                                    [highlight]='businessUnitKey === "All"'>
                                                                    </aon-percentage-bar-chart>
                                                                    <div class="marginLeft20" *ngIf="healthScoreObj.currentTabResult.componentBreakdownDictionary.componentDictionary[row.name].businessUnitDictionary[businessUnitKey].ageBandDictionary[ageGroupKey].value === 0">
                                                                        <b> NA </b>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                            </aon-bar-content>                                                                
                                            <aon-bar-content xaxislabel ="Score" isxaxis="true"></aon-bar-content>
                                        </aon-expandable-bar-chart>
                                        <aon-p-elg *ngIf="healthScoreObj.currentTabResult.componentBreakdownList.length === 0">
                                            Privacy filter applied for selection less than 10 people
                                        </aon-p-elg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aon-summary-card-content>
        </aon-summary-card>

    </div>

    <div *ngIf="healthScoreObj.currentTabSelection === 'HISTORY'">
        <aon-summary-card width="99%" height="1650px">
            <aon-summary-card-header>

                <div style="display: flex; flex-direction: row; justify-content: space-between;width:98%">
                    <div style=" width:50%">
                        <aon-h3 style="color:#001B5A;">Health Score Over Time</aon-h3>
                    </div>              
                </div>

            </aon-summary-card-header>
            <aon-summary-card-content contentwidth="97.5%" contentheight="95%" top="60px">
                <div class="columnDiv">
                    <div *ngIf="false">
                        <div class="marginTop20" style="height: 600px; width: 98%;  margin-left: 10px; display: flex; flex-direction: column; 
                            background-color: white; border-radius: 5px; justify-content: space-between;">
                         
                            <div style="padding-left:15px;">
                                <aon-h5>By Category</aon-h5>
                            </div>
                            <div style="height: 85%; width: 100%;margin-top:30px;">
                                <aon-multi-line-chart *ngIf="healthScoreObj.overTimeTabResult.categoryList.length > 0"
                                    [xaxistickformat] = "'%b-%y'"
                                    displayicons=false [data]="healthScoreObj.overTimeTabResult.categoryList"
                                    width="100%" height="100%"></aon-multi-line-chart>

                                <div class="marginLeft20 marginTop20"
                                    *ngIf="healthScoreObj.overTimeTabResult.categoryList.length === 0">
                                    <aon-p-elg>Privacy filter applied for selection less than 10 people</aon-p-elg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="false">
                        <br />
                    </div>
                    <div fxLayout="row" style='margin-top: 30px'>
                        <div fxFlex='27' >                            
                            <aon-buttontab-list color="teal" (click)="onHealthOptionChange($event)">
                                <aon-buttontab value=1 [selected]="isOverallSelected" width='140px'>Overall</aon-buttontab>
                                <aon-buttontab value=2 [selected]="!isOverallSelected" width='140px'>Business Unit</aon-buttontab>
                            </aon-buttontab-list>
                        </div>
                        <div fxFlex='20' *ngIf="!isOverallSelected">
                            <div style="height: 36px; margin-top:-25px;">
                                <aon-h5>Driver Type</aon-h5>
                            </div>
                        </div>
                        <div style='width: 20px'></div>
                        <div fxFlex='20' *ngIf="!isOverallSelected">
                            <div style="height: 36px; margin-top:-25px;">
                                <aon-h5>Display</aon-h5>
                            </div>
                        </div>
                        <div fxFlex></div>
                    </div>
                    <div fxLayout="row" >
                        <div fxFlex='27'>&nbsp;</div>
                        <div fxFlex='20' *ngIf="!isOverallSelected" style="margin-top:-42px;">
                            <aon-select [selectedvalue]="selectedDriverType" width="100%" (onselectionchange)='onDriverTypeChange($event)'>
                                <aon-select-option value="health"  selected="true">Health Score</aon-select-option>  
                                <aon-select-option value="lifestyle">Lifestyle</aon-select-option>  
                                <aon-select-option value="body">Body</aon-select-option>  
                                <aon-select-option value="mind">Mind</aon-select-option>  
                            </aon-select>
                        </div>
                        <div style='width: 20px'></div>
                        <div fxFlex='20' *ngIf="!isOverallSelected" style="margin-top:-42px;">
                            <aon-select [selectedvalue]="selectedTopBottomDisplay" width="100%" (onselectionchange)='onTopBottom5Change($event)'>
                                <aon-select-option value="top" selected="true">Top 5</aon-select-option>  
                                <aon-select-option value="bottom">Bottom 5</aon-select-option>  
                            </aon-select>
                        </div>
                        <div fxFlex></div>
                    </div>
                    <div fxLayout="row">
                        <div *ngIf="topBottomDriverMultiLineChart" style="height: 600px; width: 85%; margin-left:-35px; margin-top:20px;">
                            <aon-multi-line-chart tooltip=true 
                            [data]="topBottomDriverMultiLineChart"
                            [xaxistickformat] = "'%b-%y'"
                            xaxisticksize ="m"
                            tooltipleftposition="82%"
                            tooltiptopposition ="215px"
                            [customtickdata]="healthScoreObj.overTimeTabResult.healthScoreTopBottomViewModel.intervalList"
                            yaxistitle="Score"
                            width="100%" height="100%">
                            </aon-multi-line-chart>

                           
                        </div>
                        <div style="height: 600px; margin-top:20px;" *ngIf="!topBottomDriverMultiLineChart">
                            <aon-p-elg >
                                Privacy filter applied for selection less than 10 people
                            </aon-p-elg>
                        </div>
                    </div>
                    <div>
                        <br />
                    </div>
                    <div>
                        <aon-h3>By Change in Health Score</aon-h3>
                    </div>
                    <div>
                        <aon-p-elg>Navigate by clicking the chart to see score range details</aon-p-elg>
                    </div>
                    <div>
                        <div class="rowDiv">
                            <div  ><aon-radiobutton value=1 (ontoggle)="periodChange($event)" [selected]="healthScoreObj.overTimeTabResult.selectedTimePeriod === 1">7 Days</aon-radiobutton></div>
                            <div class="marginLeft10"><aon-radiobutton  (ontoggle)="periodChange($event)" [selected]="healthScoreObj.overTimeTabResult.selectedTimePeriod === 2" value=2>30 Days</aon-radiobutton></div>
                            <div class="marginLeft10"><aon-radiobutton (ontoggle)="periodChange($event)" [selected]="healthScoreObj.overTimeTabResult.selectedTimePeriod === 3" value=3 >3 Months</aon-radiobutton></div>
                            <div class="marginLeft10"><aon-radiobutton (ontoggle)="periodChange($event)" [selected]="healthScoreObj.overTimeTabResult.selectedTimePeriod === 4" value=4 >Since Join</aon-radiobutton></div>
                        </div>
                        <!-- <aon-p-elg>{{ getPeriodDetail() }}</aon-p-elg> -->
                    </div>
                    <div>
                        <br />
                    </div>
                    <div>
                        <div class="rowDiv">
                            
                        </div>
                        <div class="rowDiv">
                            <div style="flex:1; border-radius:5px; border: 1px solid rgb(155,155,155);">
                                <aon-bar-chart *ngIf="healthScoreObj.overTimeTabResult.participantScoreList.length > 0"
                                    (onbarclick) ="participartionBarClick($event)"
                                    yaxistitle="Participants" width="98%" [gridlines]="healthScoreObj.blankArr"
                                    maxyaxisnumber="300" xaxistitle="Change in Score" charttitle="" showbarvalue="true"
                                    [data]="healthScoreObj.overTimeTabResult.participantScoreList" height="630px">
                                </aon-bar-chart>

                                <div class="marginLeft20 marginTop20"
                                    *ngIf="healthScoreObj.overTimeTabResult.participantScoreList.length === 0">
                                    <aon-p-elg>Privacy filter applied for selection less than 10 people</aon-p-elg>
                                </div>
                            </div>
                            <div style="flex:1">
                                <aon-navigation-summary-card colorindex="12" height="650px" width="98%">
                                    <aon-navigation-summary-card-header [naviconshown]="false" [previcondisabled]="prevIconDisabled()"
                                        headerheight="100px" [nexticondisabled]="nextIconDisabled()"
                                        (onprevclick)="prevIconClick($event)" (onnextclick)="nextIconClick($event)">
                                        <div
                                            style="display: flex; flex-direction:column; justify-content:space-between">

                                            <aon-h3 *ngIf="healthScoreObj.selectedParticipantRow">
                                                {{ healthScoreObj.selectedParticipantRow.name }} Point Change
                                            </aon-h3>
                                            <aon-h3 *ngIf="!healthScoreObj.selectedParticipantRow">
                                                0 Point Change
                                            </aon-h3>
                                            <div style="margin-left: 20px;">
                                                <aon-p-lg *ngIf="healthScoreObj.selectedParticipantRow">
                                                    <b style="color:#696969">{{ healthScoreObj.selectedParticipantRow.value | number:'1.0':'en-US'}}
                                                        Participants</b>
                                                </aon-p-lg>
                                            </div>
                                            <div *ngIf="!healthScoreObj.selectedParticipantRow">
                                                <br />
                                            </div>
                                            <div style="width: 98%;">
                                                <aon-horizontaltab-list
                                                    (onchange)="participantDemoGraphicTabChanged($event)">
                                                    <aon-horizontaltab
                                                        *ngFor="let tabRow of healthScoreObj.participantTabList;"
                                                        [value]="tabRow.value" [selected]="tabRow.value === 1">
                                                        {{ tabRow.name}}
                                                    </aon-horizontaltab>
                                                </aon-horizontaltab-list>
                                            </div>
                                        </div>
                                    </aon-navigation-summary-card-header>
                                    <aon-navigation-summary-card-content top="190px" style="padding-bottom: 0%;"
                                        contentheight="90%" contentwidth="98%">
                                        <div *ngIf="healthScoreObj.selectedParticipantRow !== null && healthScoreObj.selectedParticipantRow !== undefined"
                                            style="width: 98%;overflow-y: auto; " [style.height]="healthScoreObj.selectedParticipantTabIndex === 3 ? '70%': '100%'">
                                            <div>
                                                <aon-pyramid-chart
                                                    *ngIf="healthScoreObj.selectedParticipantTabIndex === 1 && healthScoreObj.selectedParticipantRow.participantDriversList.length > 0"
                                                    colorindex="2"
                                                    [data]="healthScoreObj.selectedParticipantRow.participantDriversList"
                                                    displayicons=true width="98%" height="450px" xaxistitle="Score">
                                                </aon-pyramid-chart>
                                                <aon-pyramid-chart
                                                    *ngIf="healthScoreObj.selectedParticipantTabIndex === 2 && healthScoreObj.selectedParticipantRow.participantAgeList.length > 0"
                                                    colorindex="2" 
                                                    isnegativeshown="false"
                                                    [data]="healthScoreObj.selectedParticipantRow.participantAgeList"
                                                    displayicons=true width="98%" height="450px" xaxistitle="Participants">
                                                </aon-pyramid-chart>
                                                <div style="width:182px;"
                                                    *ngIf="healthScoreObj.selectedParticipantTabIndex === 3 && healthScoreObj.selectedParticipantRow.participantBusinessUnitList.length > 0" 
                                                      class="marginLeft20 marginTop10 marginBottom10">
                                                      <aon-select [selectedvalue]="historyBusinessUnitRankOrder"
                                                       width="180px" (onselectionchange)='onHistoryBusinessUnitOrderChange($event)'>
                                                          <aon-select-option value="top"  selected="true">Top 10</aon-select-option>  
                                                          <aon-select-option value="bottom">Bottom 10</aon-select-option>  
                                                      </aon-select>
                                                  </div>
                                                <aon-pyramid-chart
                                                    *ngIf="healthScoreObj.selectedParticipantTabIndex === 3 && historyBusinessUnitList.length > 0"
                                                    colorindex="2"
                                                    [data]="historyBusinessUnitList"
                                                    displayicons=true width="98%"
                                                    [height]="getHeight(historyBusinessUnitList)" 
                                                    isnegativeshown ="false" 
                                                    xaxistitle="Participants">
                                                </aon-pyramid-chart>
                                                   
                                                <div class="marginLeft20 marginTop20"
                                                    *ngIf="healthScoreObj.selectedParticipantRow.participantDriversList.length === 0 && healthScoreObj.selectedParticipantTabIndex === 1">
                                                    <aon-p-elg>Privacy filter applied for selection less than 10 people</aon-p-elg>
                                                </div>
                                                <div class="marginLeft20 marginTop20"
                                                    *ngIf="healthScoreObj.selectedParticipantRow.participantAgeList.length === 0 && healthScoreObj.selectedParticipantTabIndex === 2">
                                                    <aon-p-elg>Privacy filter applied for selection less than 10 people</aon-p-elg>
                                                </div>
                                                <div class="marginLeft20 marginTop20" style="overflow:hidden;"
                                                    *ngIf="historyBusinessUnitList.length === 0 && healthScoreObj.selectedParticipantTabIndex === 3">
                                                    <aon-p-elg>Privacy filter applied for selection less than 10 people</aon-p-elg>
                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="healthScoreObj.selectedParticipantRow === null || healthScoreObj.selectedParticipantRow === undefined"
                                            style="height: 70%; width: 100%; ">
                                            <div style="overflow:hidden;">
                                                <div class="marginLeft20 marginTop20">
                                                    <aon-p-elg>Privacy filter applied for selection less than 10 people</aon-p-elg>
                                                </div>
                                            </div>
                                        </div>
                                    </aon-navigation-summary-card-content>
                                </aon-navigation-summary-card>
                            </div>
                        </div>
                    </div>
                </div>
            </aon-summary-card-content>
        </aon-summary-card>
    </div>

    <div *ngIf="healthScoreObj.currentTabSelection === 'ORGANIZATION'">
        <aon-summary-card width="99%" [height]="healthScoreObj.organizationTabSelection === 'BY AGE' ? '500px' : '900px'">
            <aon-summary-card-header>

                <div style="display: flex; flex-direction: row; justify-content: space-between;width:98%">
                    <div style=" width:50%">

                        <aon-h3 style="color:#001B5A;">SCORE DETAILS</aon-h3>

                    </div>
                    <!-- <div style="display:flex; width:100%;">
                        <div class="rowDiv" style="justify-content: flex-end; width:100%">
                            <div class="custom-button-icon">
                                <fa-icon size="2x" [icon]="['fal', 'chart-line']"></fa-icon>
                            </div>
                        </div>
                    </div> -->
                </div>

            </aon-summary-card-header>
            <aon-summary-card-content contentwidth="97.5%" contentheight="95%" top="60px" style='overflow-x: hidden;'>
                <div class="columnDiv" style='overflow-x: hidden;'>
                    <div fxLayout="row" style='margin-top: 20px;'>
                        <div fxFlex>
                            <aon-horizontaltab-list (onchange)="organizationTabClick($event)">
                                <aon-horizontaltab selected="true">BY AGE</aon-horizontaltab>
                                <aon-horizontaltab>BY BUSINESS UNIT</aon-horizontaltab>
                                <aon-horizontaltab>BY COUNTRY</aon-horizontaltab>
                            </aon-horizontaltab-list>
                        </div>
                    </div>
                    <div *ngIf="healthScoreObj.organizationTabSelection === 'BY AGE'" fxLayout="row" style='margin-top: 20px;'>
                        <table class='organization'>
                            <thead>
                                <tr>
                                    <th style='vertical-align: middle; text-align: left; padding-left: 20px; width: 280px'>Age Group</th>
                                    <th>Average Score</th>
                                </tr>
                            </thead>
                            <tbody *ngIf ="healthScoreObj.organizationTabResult.ageResultList.length > 0">
                                <tr  *ngFor="let ageResult of healthScoreObj.organizationTabResult.ageResultList">
                                    <td style='vertical-align: middle; padding-left: 20px;'>{{ageResult.name}}</td>
                                    <td style='vertical-align: middle'>
                                        <aon-percentage-bar-chart 
                                        width='1000px' 
                                        removegrayspace = "true"
                                        xaxistextalign = "30"
                                        [firstpercentagelabel]="ageResult.text"
                                        [firstpercentagevalue]='ageResult.value/10'>
                                        </aon-percentage-bar-chart>
                                    </td>
                                </tr>
                                
                            </tbody>
                            <tbody *ngIf ="healthScoreObj.organizationTabResult.ageResultList.length === 0">
                                <tr >
                                    <td colspan="2">
                                        <aon-p-elg>Privacy filter applied for selection less than 10 people.</aon-p-elg>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="healthScoreObj.organizationTabSelection === 'BY BUSINESS UNIT'" fxLayout="row" style='margin-top: 10px;'>
                        <aon-select [selectedvalue]="organizationBusinessUnitRankOrder" width="180px" (onselectionchange)='onOrganizationBusinessUnitOrderChange($event)'>
                            <aon-select-option value="top"  selected="true">Top 10</aon-select-option>  
                            <aon-select-option value="bottom">Bottom 10</aon-select-option>  
                        </aon-select>
                    </div>
                    <div *ngIf="healthScoreObj.organizationTabSelection === 'BY BUSINESS UNIT'" fxLayout="row" style='margin-top: 10px;'>
                        <table class='organization'>
                            <thead>
                                <tr>
                                    <th style='vertical-align: middle; text-align: left; padding-left: 20px; width: 280px'>Business Unit</th>
                                    <th>Average Score</th>
                                </tr>
                            </thead>
                            <tbody *ngIf ="organizationBusinessUnitList.length > 0">
                                <tr   *ngFor="let businessUnitResult of organizationBusinessUnitList">
                                    <td [title]='businessUnitResult.name' style='vertical-align: middle; padding-left: 20px; cursor: default'>{{businessUnitResult.name}}</td>
                                    <td style='vertical-align: middle'>
                                        <aon-percentage-bar-chart 
                                        width='1000px' 
                                        removegrayspace = "true"
                                        xaxistextalign = "30"
                                        [firstpercentagelabel]="businessUnitResult.text"
                                        [firstpercentagevalue]='businessUnitResult.value/10'>
                                        </aon-percentage-bar-chart>
                                    </td>
                                </tr>
                               
                            </tbody>
                            <tbody *ngIf ="organizationBusinessUnitList.length === 0">
                                <tr >
                                    <td colspan="2">
                                        <aon-p-elg>Privacy filter applied for selection less than 10 people.</aon-p-elg>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="healthScoreObj.organizationTabSelection === 'BY COUNTRY'" fxLayout="row" style='margin-top: 10px;'>
                        <aon-select [selectedvalue]="organizationCountryRankOrder" width="180px" (onselectionchange)='onOrganizationCountryOrderChange($event)'>
                            <aon-select-option value="top"  selected="true">Top 10</aon-select-option>  
                            <aon-select-option value="bottom">Bottom 10</aon-select-option>  
                        </aon-select>
                    </div>
                    <div *ngIf="healthScoreObj.organizationTabSelection === 'BY COUNTRY'" fxLayout="row" style='margin-top: 10px;'>
                        <table class='organization'>
                            <thead>
                                <tr>
                                    <th style='vertical-align: middle; text-align: left; padding-left: 20px; width: 280px'>Country</th>
                                    <th>Average Score</th>
                                </tr>
                            </thead>
                            <tbody *ngIf ="organizationCountryList.length > 0">
                                <tr   *ngFor="let countryResult of organizationCountryList">
                                    <td style='vertical-align: middle; padding-left: 20px;'>{{countryResult.name}}</td>
                                    <td style='vertical-align: middle'>
                                        <aon-percentage-bar-chart 
                                        width='1000px' 
                                        xaxistextalign = "30"
                                        removegrayspace = "true"
                                        [firstpercentagelabel]="countryResult.text"
                                        [firstpercentagevalue]='countryResult.value/10'>
                                        </aon-percentage-bar-chart>
                                    </td>
                                </tr>
                               
                            </tbody>
                            <tbody *ngIf ="organizationCountryList.length === 0">
                                <tr >
                                    <td colspan="2">
                                        <aon-p-elg>Privacy filter applied for selection less than 10 people.</aon-p-elg>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <br />
                    </div>
                </div>
            </aon-summary-card-content>
        </aon-summary-card>
    </div>    
</div>

<div fxLayout="column" class="loadingOverlayNew" *ngIf="isLoading">
    <aon-loading-spinner></aon-loading-spinner>
</div>

<div class="columnDiv contentPadding" *ngIf="!healthScoreObj && errorMsg">
    <div>
        <aon-p-elg style="color:red;">{{errorMsg}}</aon-p-elg>
    </div>
</div>