export const languages = [
    {
        id   : 'en',
        title: 'English',
        flag : 'global'
    },
    {
        id   : 'es-cl',
        title: 'Español (CL)',
        flag : 'cl'
    },
    {
        id   : 'es-co',
        title: 'Español (CO)',
        flag : 'co'        
    },
    {
        id   : 'es-ec',
        title: 'Español (EC)',
        flag : 'ec'        
    },
    {
        id   : 'es-mx',
        title: 'Español (MX)',
        flag : 'mx'        
    },
    {
        id   : 'es-pe',
        title: 'Español (PE)',
        flag : 'pe'        
    },
    {
        id   : 'pt-br',
        title: 'Português (BR)',
        flag : 'br'        
    },
    {
        id   : 'it',
        title: 'Italian',
        flag : 'it'        
    }
];