<div *ngIf="isLoading" fxLayout="column" class="loadingOverlayNew">
    <aon-loading-spinner></aon-loading-spinner>
</div>
<div class="contentPadding" fxLayout="column" *ngIf="data">
    <div fxLayout="row">
        <div>
            <aon-h1 class="title">
                <span style='margin-right: 25px;'>
                    <fa-icon [icon]="['fal', 'running']" [styles]="{'font-size': '36px'}"></fa-icon>
                </span>Challenge
            </aon-h1>
        </div>
        <aon-vertical-line style='margin-top: -8px'></aon-vertical-line>
        <div style='cursor: pointer; padding-top: 16px' (click)='openChallengeSelectionDialog()'>
            <aon-h2 class="title">
                {{data.name}}
            </aon-h2>
        </div>
        <div style="padding-top: 22px; cursor: pointer;" (click)='openChallengeSelectionDialog()'>
            <fa-icon class='title' style='font-size: 20px; padding-left: 6px;' [icon]="['fal', 'caret-square-down']"></fa-icon>
        </div>
        <div fxFlex></div>
    </div>
    <div fxLayout="row">
        <div fxFlex='100'>
            <aon-bar-summary width="100%" height="140px" colorindex="11">
                <aon-bar-summary-content width="15%" top="60px">
                    <div class="columnDiv" style="margin-left: 20px;">
                        <div>
                            <aon-p-lg class="whiteColorText">
                                Average Score
                            </aon-p-lg>
                        </div>
                        <div style="margin-top: -10px;">
                            <aon-h1 class="whiteColorText">{{data.healthScore}}</aon-h1>
                        </div>
                    </div>
                </aon-bar-summary-content>
                <aon-vertical-line></aon-vertical-line>
                <aon-bar-summary-content width="80%" top="60px">
                    <div fxLayout="column" style='margin-top:2.8%'>
                        <div fxLayout="row">
                            <aon-h4 class="whiteColorText">
                                Challenge Duration: {{data.startDateLong}} - {{data.endDateLong}} ({{data.durationDays}} days)
                            </aon-h4>
                        </div>
                        <div fxLayout="row" *ngIf="false">
                            <aon-p-sm class="whiteColorText">
                                Challenge type: {{data.type}}
                            </aon-p-sm>
                        </div>
                    </div>
                </aon-bar-summary-content>
            </aon-bar-summary>
        </div>
    </div>
    <div fxLayout='row' style='margin-top:30px'>
        <div fxFlex='100'>
            <aon-summary-card width="100%" height="450px">
                <aon-summary-card-content contentwidth='96%'>
                  <div fxLayout='column'style='width: 100%'>
                    <div fxLayout='row'>
                        <div fxFlex='100'>
                            <div>
                                <aon-h3 style="color:#001B5A;">
                                    Health Score Performance 
                                      <!-- <aon-tooltip-default text='Tooltip Here'><fa-icon 
                                        style="font-size: 20px;" 
                                        [icon]="['fal', 'info-circle']"></fa-icon></aon-tooltip-default> -->
                                    </aon-h3>
                                  
                            </div>
                            <div>
                                <table class='grid' style='width:100%; margin-top: 30px'>
                                    <thead>
                                        <tr>
                                            <th colspan='2'><aon-h5>Category</aon-h5></th>
                                            <th colspan='2'><aon-h5>Score</aon-h5></th>
                                            <th colspan='2'><aon-h5>Performance</aon-h5></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let healthScorePerformanceTableRow of healthScorePerformanceTableData">
                                            <td class='icon' style='padding-top: 5px; width: 40px'><fa-icon 
                                                style="font-size: 20px;"
                                                [icon]="healthScorePerformanceTableRow.icon"></fa-icon></td>
                                            <td style="padding-top: 20px; width: 250px"><aon-p-lg>{{healthScorePerformanceTableRow.category}}</aon-p-lg></td>
                                            <td style="padding-top: 0;">
                                                <aon-percentage-bar-chart 
                                                width='800px' 
                                                removegrayspace = "true"
                                                
                                                [firstpercentagelabelshown]="false" 
                                                [firstpercentagelabel]="healthScorePerformanceTableRow.score"
                                                [firstpercentagevalue]='healthScorePerformanceTableRow.score/10' 
                                                [secondpercentageenabled]='false'></aon-percentage-bar-chart>
                                            </td>
                                            <td style="width: 120px; padding-top: 18px; padding-left: 10px;"><aon-p-lg>{{healthScorePerformanceTableRow.score  | number}}</aon-p-lg></td>
                                            <td style="width: 60px; padding-top: 18px;"><aon-p-lg>{{healthScorePerformanceTableRow.performance  | percent: '1.0-1'}}</aon-p-lg></td>
                                            <td class='icon' style='padding-top: 0; width: 140px'>
                                                <fa-icon 
                                                *ngIf="healthScorePerformanceTableRow.performance > 0"  
                                                style="font-size: 16px;"
                                                [icon]="['fal', 'arrow-up']"></fa-icon>
                                                <fa-icon 
                                                *ngIf="healthScorePerformanceTableRow.performance < 0"  
                                                style="font-size: 16px;"
                                                [icon]="['fal', 'arrow-down']"></fa-icon>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                  </div>
                </aon-summary-card-content>
            </aon-summary-card>
        </div>
    </div>
    <div fxLayout='row' style='margin-top:30px'>
        <div fxFlex>
            <aon-summary-card width="100%" [height]="(320 + (90 * data.yCategories.length) + (selectedBusinessUnit === null ? 0 : 300)) + 'px'">
                <aon-summary-card-content contentwidth='97%'>
                  <div fxLayout='column'style='width: 100%'>
                    <div fxLayout='row'>
                        <div fxFlex>
                            <div>
                                <aon-h3 style="color:#001B5A;">Participation Breakdown</aon-h3>
                            </div>
                            <div>
                                <aon-p-lg>Select a row or segment to view details and category scores</aon-p-lg>
                            </div>
                        </div>
                    </div>
                    <div fxLayout='row' *ngIf="false">
                        <div fxFlex></div>
                        <div (click)="toggleViewCompletionRatesEnabled()">
                            <aon-p-lg class="clickable">View Completion Rates</aon-p-lg>
                        </div>
                        <div style='margin-left: 10px;'>
                            <aon-switch [switchon]='isViewCompletionRatesEnabled' (ontoggle)='toggleViewCompletionRatesEnabled()'></aon-switch>
                        </div>
                    </div>
                    <div fxLayout='row'>
                        <table class='participation-breakdown' #participationBreakdownTable>
                            <thead>
                                <tr>
                                    <th class="clickable" (click)="sortParticipationBreakdownByColumn('Group')">
                                        Group
                                        <fa-icon style="margin-left: 15px;" *ngIf="participationBreakdownSortColumn === 'Group' && isParticipationBreakdownSortOrderDescending" [icon]="['fal', 'sort-alpha-down-alt']"></fa-icon>
                                        <fa-icon style="margin-left: 15px;" *ngIf="participationBreakdownSortColumn === 'Group' && !isParticipationBreakdownSortOrderDescending" [icon]="['fal', 'sort-alpha-down']"></fa-icon>
                                    </th>
                                    <th class="clickable" (click)="sortParticipationBreakdownByColumn(ageGroupKey)" style="width: 260px" *ngFor="let ageGroupKey of data.xCategories">
                                        {{ageGroupKey}}
                                        <fa-icon style="margin-left: 15px;" *ngIf="participationBreakdownSortColumn === ageGroupKey && isParticipationBreakdownSortOrderDescending" [icon]="['fal', 'sort-size-down']"></fa-icon>
                                        <fa-icon style="margin-left: 15px;" *ngIf="participationBreakdownSortColumn === ageGroupKey && !isParticipationBreakdownSortOrderDescending" [icon]="['fal', 'sort-size-down-alt']"></fa-icon>
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let businessUnitKey of data.yCategories">
                                <tr>
                                    <th [ngClass]="{'selected': data.businessUnitDictionary[businessUnitKey].selected, 'highlight': data.businessUnitDictionary[businessUnitKey].highlight }">
                                        <div fxLayout='row' style="width:300px;" (click)="toggleSelection(data.businessUnitDictionary[businessUnitKey])">
                                            <div class="clickable">{{businessUnitKey}}</div>
                                            <div fxFlex class="clickable"></div>
                                            <div class="clickable">
                                                <fa-icon *ngIf="data.businessUnitDictionary[businessUnitKey].selected" [icon]="['fal', 'chevron-up']"></fa-icon>
                                                <fa-icon *ngIf="!data.businessUnitDictionary[businessUnitKey].selected" [icon]="['fal', 'chevron-down']"></fa-icon>
                                            </div>
                                        </div>                                        
                                    </th>
                                    <td *ngFor="let ageGroupKey of data.xCategories">
                                        <aon-percentage-bar-chart 
                                        removegrayspace = "true"
                                        width='180px' *ngIf="data.businessUnitDictionary[businessUnitKey].ageGroupDictionary[ageGroupKey].challengeParticipants >= 10"
                                        [firstpercentagelabel]="data.businessUnitDictionary[businessUnitKey].ageGroupDictionary[ageGroupKey].challengeParticipants"
                                        [firstpercentagevalue]='data.businessUnitDictionary[businessUnitKey].ageGroupDictionary[ageGroupKey].participationPercentage' 
                                        [highlight]='data.businessUnitDictionary[businessUnitKey].highlight' 
                                        [selected]='data.businessUnitDictionary[businessUnitKey].ageGroupDictionary[ageGroupKey].selected' 
                                        [secondpercentageenabled]='isViewCompletionRatesEnabled'></aon-percentage-bar-chart>
                                        <div style="width:180px;" *ngIf="data.businessUnitDictionary[businessUnitKey].ageGroupDictionary[ageGroupKey].challengeParticipants < 10">
                                            <b style="margin-left:10px;" [style.color]="data.businessUnitDictionary[businessUnitKey].ageGroupDictionary[ageGroupKey].selected ? '#007BB6' : '#00519B'" >NA</b></div>
                                    </td>
                                </tr>
                                <tr *ngIf="data.businessUnitDictionary[businessUnitKey].selected">
                                    <td class='participation-breakdown-selection-details' colspan="6">
                                        <div fxLayout='row' class='content' fxLayoutAlign='start center'>
                                            <div class='bold'>
                                                <span >{{selectedBusinessUnitLabel}}, ages {{selectedAgeGroupLabel}}</span>
                                               
                                            </div>
                                            <div fxFlex></div>
                                            <div>
                                                <table class='left-right-navigation-table'>
                                                    <tr>
                                                        <td (click)="goToPrevAgeGroup()"><fa-icon [icon]="['fal', 'chevron-left']"></fa-icon></td>
                                                        <td (click)="goToNextAgeGroup()"><fa-icon [icon]="['fal', 'chevron-right']"></fa-icon></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div fxLayout='row' *ngIf="selectedAgeGroup.challengeParticipants >= 10" class='content padding-top-20px' fxLayoutAlign='start start'>
                                            <div fxFlex style="padding-left: 20px;">
                                                <div *ngIf="false"><span class="bold">{{selectedAgeGroup.totalUsers}}</span> total users</div>
                                                <div class="padding-top-15px"><span class="bold">{{selectedAgeGroup.challengeParticipants | number}}</span> challenge participants</div>
                                                <div *ngIf="false" class="padding-top-15px"><span class="bold">{{selectedAgeGroup.completedChallenges}}</span> completed challenges</div>
                                                <div *ngIf="false" class="padding-top-15px"><span class="bold">{{selectedAgeGroup.completionRate}}%</span> completion rate</div>
                                            </div>
                                            <div class="content-card" style="width: 250px;">
                                                <div fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="margin-right: 15px;">
                                                        <fa-icon [icon]="['fal', 'wave-triangle']"></fa-icon>
                                                    </div>
                                                    <div fxFlex>
                                                        <span class='bold'>Health Score</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div fxFlex style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.averageScore}}</span>
                                                    </div>
                                                    <div style='padding-top: 5px; padding-right: 15px' *ngIf="selectedAgeGroup.averageScoreRank > 0">
                                                        <span style='font-size: 14px;'>Rank: {{selectedAgeGroup.averageScoreRank}} of {{selectedAgeGroup.rankTotal}}</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.averageScorePercentage | percent: '1.0-1'}}</span>
                                                    </div>
                                                    <div style='padding-top: 2px; padding-left: 10px'>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.averageScorePercentage > 0" [icon]="['fal', 'arrow-up']"></fa-icon>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.averageScorePercentage < 0" [icon]="['fal', 'arrow-down']"></fa-icon>
                                                    </div>
                                                    <div style='padding-top: 5px; padding-left: 10px'>
                                                        <span style='font-size: 14px;'>since {{selectedAgeGroup.averageScoreFromDate}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="content-card" style="width: 250px;">
                                                <div fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="margin-right: 15px;">
                                                        <fa-icon [icon]="['fal', 'apple-alt']"></fa-icon>
                                                    </div>
                                                    <div fxFlex>
                                                        <span class='bold'>Lifestyle Score</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div fxFlex style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.lifestyleScore}}</span>
                                                    </div>
                                                    <div style='padding-top: 5px; padding-right: 15px' *ngIf="selectedAgeGroup.lifestyleScoreRank > 0">
                                                        <span style='font-size: 14px;'>Rank: {{selectedAgeGroup.lifestyleScoreRank}} of {{selectedAgeGroup.rankTotal}}</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.lifestyleScorePercentage | percent: '1.0-1'}}</span>
                                                    </div>
                                                    <div style='padding-top: 2px; padding-left: 10px'>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.lifestyleScorePercentage > 0" [icon]="['fal', 'arrow-up']"></fa-icon>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.lifestyleScorePercentage < 0" [icon]="['fal', 'arrow-down']"></fa-icon>
                                                    </div>
                                                    <div style='padding-top: 5px; padding-left: 10px'>
                                                        <span style='font-size: 14px;'>since {{selectedAgeGroup.lifestyleScoreFromDate}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="content-card" style="width: 250px;">
                                                <div fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="margin-right: 15px;">
                                                        <fa-icon [icon]="['fal', 'running']"></fa-icon>
                                                    </div>
                                                    <div fxFlex>
                                                        <span class='bold'>Body Score</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div fxFlex style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.bodyScore}}</span>
                                                    </div>
                                                    <div style='padding-top: 5px; padding-right: 15px'*ngIf="selectedAgeGroup.bodyScoreRank > 0">
                                                        <span style='font-size: 14px;'>Rank: {{selectedAgeGroup.bodyScoreRank}} of {{selectedAgeGroup.rankTotal}}</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.bodyScorePercentage | percent: '1.0-1'}}</span>
                                                    </div>
                                                    <div style='padding-top: 2px; padding-left: 10px'>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.bodyScorePercentage > 0" [icon]="['fal', 'arrow-up']"></fa-icon>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.bodyScorePercentage < 0" [icon]="['fal', 'arrow-down']"></fa-icon>
                                                    </div>
                                                    <div style='padding-top: 5px; padding-left: 10px'>
                                                        <span style='font-size: 14px;'>since {{selectedAgeGroup.bodyScoreFromDate}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="content-card" style="width: 250px;">
                                                <div fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="margin-right: 15px;">
                                                        <fa-icon [icon]="['fal', 'head-side-brain']"></fa-icon>
                                                    </div>
                                                    <div fxFlex>
                                                        <span class='bold'>Mind Score</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div fxFlex style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.mindScore}}</span>
                                                    </div>
                                                    <div style='padding-top: 5px; padding-right: 15px'*ngIf="selectedAgeGroup.mindScoreRank > 0">
                                                        <span style='font-size: 14px;'>Rank: {{selectedAgeGroup.mindScoreRank}} of {{selectedAgeGroup.rankTotal}}</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.mindScorePercentage  | percent: '1.0-1'}}</span>
                                                    </div>
                                                    <div style='padding-top: 2px; padding-left: 10px'>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.mindScorePercentage > 0" [icon]="['fal', 'arrow-up']"></fa-icon>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.mindScorePercentage < 0" [icon]="['fal', 'arrow-down']"></fa-icon>
                                                    </div>
                                                    <div style='padding-top: 5px; padding-left: 10px'>
                                                        <span style='font-size: 14px;'>since {{selectedAgeGroup.mindScoreFromDate}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="width: 20px;"></div>
                                        </div>

                                        <div fxLayout='row' *ngIf="selectedAgeGroup.challengeParticipants < 10" class='content padding-top-20px' fxLayoutAlign='start start'>
                                            <aon-p-elm>NA</aon-p-elm>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                  </div>
                </aon-summary-card-content>
            </aon-summary-card>
        </div>
    </div>
</div>

<div class="contentPadding" fxLayout="column" style="height: 83vh;" *ngIf="!data && !isLoading">    
    <aon-h1 class="title" style="margin-top:50px;"> No Data Found.</aon-h1>
</div>
