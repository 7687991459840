<div class='action-list'>
    <div class='action-list__actions'>
        <div *ngIf="isDownloadHccFileShown" (click)="onDownloadHccFile()" title="Download Hcc Calculator">
            <span class="material-icons action-list__actions__icon">
                get_app
            </span>
            <span class="action-list__actions__label">
                Download HCC Calculator
            </span>
        </div>
        <div (click)="onLoad()" title="Load Scenario">
            <span class="material-icons action-list__actions__icon">
                folder_open
            </span>
            <span class="action-list__actions__label">
                Load Scenario
            </span>
        </div>
        <div *ngIf="isSaveShown" (click)="onSave()"  title="Save Scenario">
            <span 
            [ngClass]="{
                'material-icons': true, 
                'action-list__actions__icon': true,
                'action-list__actions--disabled': isSaveDisabled}">
                save
            </span>
            <span [ngClass]="{
                'action-list__actions__label': true, 
                'action-list__actions--disabled': isSaveDisabled}">
                Save Scenario
            </span>
        </div>
    </div>
</div>
<div class='cost-projection'>
    <div class='cost-projection__inputs-section'>
        <app-cost-projection-inputs *ngIf="inputForm"
        [inputForm]="inputForm"
        (userResetForm)="onUserResetInputForm()"
        (userUpdateForm)="onUserUpdateInputForm()" 
        (userSubmitForm)="onUserSubmitInputForm()"></app-cost-projection-inputs>
    </div>
    <div class='cost-projection__results-section'>
        <app-cost-projection-results 
        [inputForm]="inputForm"
        [resultsForm]="resultsForm"
        [resultsState]="resultsState"></app-cost-projection-results>
    </div>
</div>
