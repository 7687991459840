export const locale = {
    lang: 'es-pe',
    data: {
        'ageBand': {
            'AgeBandHeading': 'Inscripciones por rango etario',
            'Benchmark': 'Benchmark',
            'IncludeClient': 'Incluir Cliente',
            'ExcludeClient': 'Excluir Cliente',
            'Portfolio': 'Portafolio',
            'Industry': 'Industria',
            'None': 'Ninguno',
            'MemberCount': 'Total de afiliados',
            'AverageAge': 'Edad promedio',
            'AverageFamilySize': 'Tamaño promedio por familia',
            'RelationshipDistribution': 'Distribución por Parentesco',
            'Split': 'División',
            'PctOfTotalClient': '% del Cliente Total',
            'EnrollmentTrend': 'Tendencia de inscripción',
            'Male': 'Masculino',
            'Female': 'Femenino',
            'PctOfPopulation': 'de población',
            'Employee': 'Trabajador',
            'Child': 'Niño',
            'Sibling': 'Hermana',
            'Dimensions': 'Dimensión',
            'Gender': 'Género',
            'Relationship': 'Parentesco',
            'Measure': 'Medida',
            'Number': 'Numero',
            'PctDistribution': '% Del Total',
            'Dependent': 'Dependiente',
            'DateRange': 'to',
            'Others': 'Otros',
        }
    }
};