export const locale = {
    lang: 'pt-br',
    data: {
        'ExportQueue': {
            'ExportQueue': 'Exportar Fila',
            'CanViewExportQueueAndStatusHere': 'Pode visualizar a fila de exportação e o status aqui',
            'Name': 'Nome',
            'FileName': 'Nome do Arquivo',
            'Status': 'Status',
            'CreatedOn': 'Criado em',
            'Scheduled': 'Programado',
            'Processing': 'Processando',
            'Succeeded': 'Sucesso',
            'Failed': 'Erro'
        }
    }
};
