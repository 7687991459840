export const locale = {
    lang: 'es-ec',
    data: {
        'ClientGroupAdmin': {
            'ClientGroupAdministration': 'CLIENT GROUP ADMINISTRATION',
            'ClientGroups': 'Client Groups',
            'NewClientGroup': 'NEW CLIENT GROUP',
            'NewClientGroupName': 'New Client Group Name',
            'Clients': 'Clients',
            'Save': 'SAVE',
            'Success': 'Success',
            'Name': 'Name',
            'CreatedBy': 'Created By',
            'CreatedOn': 'Created On',
            'GroupName': 'Group Name',
            'ClientName': 'Client Name',
            'SelectedClients': 'Selected Clients',
            'UnselectedClients': 'Unselected Clients',
            'Search': 'Search',
            'clientGroupColumnDefs': [
                { headerName: 'Name', field: 'name', sort: 'asc' }
            ],
            'clientColumnDefs': [
                { headerName: 'Name', field: 'name', sort: 'asc' }
            ]
        }
    }
};