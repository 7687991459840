<div style="height: 100%">

  <svg width="100%" height="100%" viewBox="0 0 42 42" class="donut">
    <circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>
    <defs>
      <clipPath id="myCircle">
    <circle class="donut-ring" cx="21" cy="21" r="15.91549430918954"  fill="transparent" stroke="#d2d3d4" 
    stroke-width="1.5"></circle>
      </clipPath>
    </defs>
    <image width="17" x="30%" y="25%" height="17" xlink:href="../assets/aonicons/Gender-01.svg" clip-path="url(#myCircle)" ></image>
    <g class="donut-text">
      <text  transform="translate(0, 1)">
        <tspan x="50%" y="75%" text-anchor="middle" class="donut-Inlabel">
          {{title}}
        </tspan>
      </text></g>
    <circle class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" [attr.stroke]="colorPurple"
     stroke-width="1.5" [attr.stroke-dasharray]="getSVGDashArray()" stroke-dashoffset="25"></circle>
    <circle class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" [attr.stroke]="colorBlue"
     stroke-width="1.5" [attr.stroke-dasharray]="getSVGDashArray2()" [attr.stroke-dashoffset]="getoffset2()"></circle>
     <g class="donut-text" *ngIf="percentage > 0">
      <text  transform="translate(0, 1)">
        <tspan x="115%" y="25%" text-anchor="middle" class="donut-Outlabel">
            {{label1}} 
         </tspan>
        <tspan x="115%" y="40%" text-anchor="middle" class="donut-percent" [attr.fill]="colorPurple">
          {{percentage}}%
        </tspan>
      </text>
    </g>
    <g class="donut-text" *ngIf="percentage2 > 0">

      <text  transform="translate(0, 1)">
        <tspan x="-15%" y="25%" text-anchor="middle" class="donut-Outlabel">
            {{label2}}  
         </tspan>
        <tspan x="-15%" y="40%" text-anchor="middle" class="donut-percent" [attr.fill]="colorBlue">
         {{percentage2}}%
        </tspan>
      </text>
    </g>
    </svg>
</div>