export const locale = {
    lang: 'es-mx',
    data: {
        'AvgCostUtil': {
            'AverageCostImpact': 'Impacto del costo promedio',
            'UtilizationImpact': 'Impacto de utilización',
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'OverallTrend': 'Tendencia general',
            'AverageCost': 'Var. Costo promedio',
            'Utilization': 'Utilización',
            'FreqOfUse': 'Frecuencia de Uso',
            'ServicesReceived': 'Servicios recibidos',
            'KeyImpacts': 'Impactos clave',
            'AvgCostPerClaim': 'Costo Promedio',
            'Prior': 'Vigencia Anterior',
            'Current': 'Vigencia Actual',
            'YoYChange': 'Cambio año tras año',
            'Benchmark': 'Análisis comparativo',
            'ClaimType': 'Causa',
            'Network': 'Red',
            'ProviderType': 'Tipo del Proveedor',
            'Relationship': 'Parentesco',
            'CostPerClaim': 'Costo por trámite',
            'CostPerClaimant': 'Costo por caso',
            'ClaimsPerClaimant': 'Trámites por caso',
            'UtilizationRatio': 'Porcentaje de utilización',
            'ClaimsReportedByPaidDate': ' ',
            'ClaimsReportedByIncurredDate': ' ',
            'IncludeClient': 'Incluir Cliente',
            'ExcludeClient': 'Excluir Cliente',
            'Portfolio': 'Portafolio',
            'Industry': 'Industria',
            'None': 'Ninguna',
            'Chart': 'Gráfico',
            'Table': 'Tabla',
            'ShowLegend': 'Mostrar leyenda',
            'DateRange': 'to'
        }
    }
};
