export const locale = {
    lang: 'es-mx',
    data: {
        'ClaimCostDiag': {
            'ClaimDiagnosis': 'Tipo de Trámites v Diagnóstico',
            'SelectTop': 'Selección Top',
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'ClaimType': 'Tipo de Reclamación',
            'TotalPaidClaim': 'Total pagado',
            'ClaimantCount': 'Total casos',
            'ClaimCount': 'Número de trámites',  
            'ClaimsPerClaimant': 'Trámites por caso',
            'CostPerClaim': 'Costo por Trámite',
            'CostPerClaimant': 'Costo por Asegurado',
            'ChangeInCost': '% cambio en costo',
            'Claim': 'Trámite',
            'Claimant': 'Caso',
            'ClaimsTotal': 'Total de Trámites',
            'Count': 'Contar',
            'ClaimsReportedByPaidDate': ' ',
            'ClaimsReportedByIncurredDate': ' ',
            'CountBasis': 'Contar Base',
            'Sort': 'Ordenar',
            'Show': 'Mostrar',
            'Hide': 'Ocultar',
            'ShowHide': 'Mostrar/Ocultar',
            'DiagnosisLevel': 'Diagnostico',
            'Category': 'Categoría',
            'Chapter': 'Padecimientos',
            'ShownAsPer': 'Se muestra según',
            'TopDiagnoses': 'Top Diagnósticos', 
            'AverageCost' :  'Costo de trámite promedio',
            'FilterNA': 'Excluir NA',
            'DateRange': 'to'      
        }
    }
};
