<aon-summary-card [height]="scoreCardheight" [width]="scoreCardwidth" [size]="'lg'">
    <div style="display: flex;flex-direction:column   ;justify-content: space-around;">
        <aon-h3 style="color: #001b5a;">
            {{ categoryChartTitle }}
        </aon-h3>
    </div>
    <aon-summary-card-content colorindex="1" top="90px" [width]="scoreCardwidth">
        <fwb-error-message [isError]="isError" [message]="errorMsg"></fwb-error-message>
        <div *ngIf="!isError">
            <aon-expandable-bar-chart height="650px" width="100%" style="display: flex;justify-content:center;">
                <aon-bar-content [maxbarheight]="maxbarheight" [textwidth]="textwidth"
                    text=" {{c.category}}       ({{c.value}})" *ngFor="let c of questions" [isexpand]="c.isexpanded"
                    (onexpandcollapseclick)="expandParentHeight(c.category)">
                    <div *ngFor="let item of c.questions; index as i" class="questionRow">
                        <div [ngClass]="(i %2 == 0) ? 'alternativeRow ' : 'normalRow'">
                            <div style="width: 80%;float: left;margin-top: 10px;margin-left: 20px;
                        margin-bottom: -10px; ">
                                <aon-p-lg>{{item.questionText}}</aon-p-lg>
                            </div>
                            <question-field [data]="item" style="width: 20%;"></question-field>
                        </div>
                    </div>
                </aon-bar-content>
            </aon-expandable-bar-chart>

        </div>
    </aon-summary-card-content>
</aon-summary-card>