import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RESULTS_STATE } from 'app/constants/cost_projection';

@UntilDestroy()
@Component({
  selector: 'app-cost-projection-results',
  templateUrl: './cost-projection-results.component.html',
  styleUrls: ['./cost-projection-results.component.scss']
})
export class CostProjectionResultsComponent implements OnInit {

  constructor(private _fb: FormBuilder) { }

  @Input() inputForm: FormGroup = null;

  private _resultsState = RESULTS_STATE.INITIAL;
  @Input('resultsState') 
  set name(resultsState: string) {
    this._resultsState = resultsState;
    if (this._resultsState === RESULTS_STATE.LOADED) {
       this._recalculatePartialResults();
    }
  }
  public get resultsState(): string { return this._resultsState; }

  @Input() resultsForm: FormGroup = null;

  public RESULTS_STATE = RESULTS_STATE;

  public isHistoricalExperienceSectionExpanded = true;
  public isClaimsProjectionExpanded = true;

  ngOnInit(): void {
    this._initTrendsAndAdjustmentsTableSubscriptions();
  }

  private _initTrendsAndAdjustmentsTableSubscriptions(): void {
    // To cater for client's hccPMPM input changes
    this.resultsForm.get('claimsProjection.hccPMPM.client').valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(clientHccPMPM => {
        // Reflect client's HCC PMPM to that of country's 
        this.resultsForm.get('claimsProjection.hccPMPM.country')
          .patchValue(this.resultsForm.get('claimsProjection.hccPMPM.client').value);

        const finalPMPM = clientHccPMPM + this.resultsForm.get('claimsProjection.adjustedPMPM.client').value;
        this.resultsForm.get('claimsProjection.finalPMPM.client').patchValue(finalPMPM);

        const credibility = this.resultsForm.get('claimsProjection.credibility.client').value;
        const credibilityWeightedClaimPMPM = finalPMPM * (credibility / 100);

        this.resultsForm.get('claimsProjection.credibilityWeightedClaimPMPM.client').patchValue(credibilityWeightedClaimPMPM);

        this._recalculatePartialResults();
      });
    
    // To cater for country's hccPMPM input changes
    this.resultsForm.get('claimsProjection.hccPMPM.country').valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(countryHccPMPM => {
        const finalPMPM = countryHccPMPM + this.resultsForm.get('claimsProjection.adjustedPMPM.country').value;
        this.resultsForm.get('claimsProjection.finalPMPM.country').patchValue(finalPMPM);

        const credibility = this.resultsForm.get('claimsProjection.credibility.country').value;
        const credibilityWeightedClaimPMPM = finalPMPM * (credibility / 100);

        this.resultsForm.get('claimsProjection.credibilityWeightedClaimPMPM.country').patchValue(credibilityWeightedClaimPMPM);

        this._recalculatePartialResults();
      });

    this.resultsForm.markAllAsTouched();
  }

  private _recalculatePartialResults(): void {
    const credibility = this.resultsForm.get('claimsProjection.credibility.client').value;
    const clientCredibility = credibility / 100;
    const countryCredibility = 1 - clientCredibility;

    const clientFinalPMPM = this.resultsForm.get('claimsProjection.finalPMPM.client').value;
    const countryFinalPMPM = this.resultsForm.get('claimsProjection.finalPMPM.country').value;

    // Claims PMPM
    const claimsPMPM = clientCredibility * clientFinalPMPM + countryCredibility * countryFinalPMPM;
    this.resultsForm.get('insights.pmpm.claims').patchValue(claimsPMPM);

    // Total Claims
    const projectedMembershipSumTotal = this.inputForm.get('membership.projectedSumTotal').value;
    const totalClaims = projectedMembershipSumTotal * claimsPMPM;
    this.resultsForm.get("insights.total.claims").patchValue(totalClaims);

    // Premium Needed
    const stopLossRatio = this.inputForm.get('targetLossRatio').value / 100;
    const totalPremiumNeeded = totalClaims / stopLossRatio;
    this.resultsForm.get("insights.premiumNeeded").patchValue(totalPremiumNeeded);

    // Surplus / Shortfall
    const totalCurrentPremium = this.resultsForm.get('insights.total.premium').value;
    const surplusShortfall = totalCurrentPremium - totalPremiumNeeded;
    this.resultsForm.get("insights.summary.surplusShortfall").patchValue(surplusShortfall);

    // Premium Increase Needed
    const premiumIncreaseNeeded = surplusShortfall / totalCurrentPremium * -100;
    this.resultsForm.get("insights.summary.premiumIncreaseNeeded").patchValue(premiumIncreaseNeeded);
  }
}
