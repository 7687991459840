export const locale = {
  lang: 'es-ec',
  data: {
    'ClaimType': {
      'ClaimHeading': 'Tipo de Servicio',
      'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
      'ClaimType': 'Tipo de Servicio',
      'TotalPaidClaim': 'Total pagado de reclamo',
      'ClaimantCount': 'Parentesco de Demandantes',
      'ClaimCount': 'Número de Eventos',
      'ClaimsPerClaimant': 'Reclamos por Reclamante',
      'ClaimsPerHeadcount': 'Reclamos Por Plantilla',
      'CostPerClaim': 'Costo Por Reclamo',
      'CostPerClaimant': 'Costo Por Reclamante',
      'CostPerHeadcount': 'Costo Por Plantilla',
      'ChangeInCost': '% Cambio en Costo',
      'Claim': 'Reclamo',
      'Claimant': 'Reclamante',
      'Headcount': 'Plantilla',
      'ClaimsTotal': 'Total de Reclamaciones',
      'Count': 'Contar',
      'ClaimsReportedByPaidDate': 'Reclamaciones reportadas por fecha de pago',
      'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
      'CountBasis': 'Contar Base',
      'Sort': 'Orden',
      'Show': 'Mostrar',
      'Hide': 'Ocultar',
      'ShowHide': 'Mostrar/Esconder',
      'Average': 'Promedio',
      'PeriodAverage': 'Periodo promedio',
      'PlanYear': 'Año Del Plan'
    }
  }
};
