export const locale = {
    lang: 'it',
    data: {
  'ExportCart': {
    'ExportCart': 'Esporta carrello',
    'CanViewDeleteAddedDashboardsHere': 'È possibile visualizzare/eliminare cruscotti aggiunti qui',
    'Dashboards': 'Cruscotti',
    'ExportAll': 'Esporta tutto',
    'ClearAll': 'Cancella tutto',
    'Name': 'Nome',
    'Export': 'Esporta',
    'ExportTitle': 'Esporta titolo',
    'Preview': 'Anteprima',
    'Comments': 'Commenti',
    'FiltersSelected': 'Filtro/i selezionato/i',
    'NoFilterSelected': 'Nessun filtro selezionato',
    'AdditionalFiltersSelected': 'Filtri addizionali selezionati',
    'FiltersMightNotApply': 'I filtri potrebbero non essere applicabili'
  }
}};