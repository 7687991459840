import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundToDecimalPlace'
})
export class RoundToDecimalPlacePipe implements PipeTransform {

    transform(value: number, decimalPlace: number): any {
        if (isNaN(value)) {
            return '';
        }

        return Math.round(value * Math.pow(10, decimalPlace)) / Math.pow(10, decimalPlace);
    }
}
