<aon-navigation-summary-card [height]="cardheight" [width]="cardwidth" style="color:white!important;margin-left:25px;">
    <aon-navigation-summary-card-header [headerheight]="navigationheight" [headerwidth]="'100%'"
        (onnextclick)="onNext($event)" (onprevclick)="onPrev($event)">
        <div style="display: flex;flex-direction:column;justify-content: flex-start;">
            <div style="display: flex;flex-direction:row;justify-content: flex-start;">
                <aon-h3 style="color: #001b5a;">
                    {{ charttitle }}
                </aon-h3>
            </div>
            <div style="display: flex;flex-direction:row;justify-content: flex-start;">
                <div style="text-align: left;color: #001b5a;">
                    <aon-p-elg>
                        {{ chartdescription }}
                    </aon-p-elg>
                </div>
            </div>
        </div>
    </aon-navigation-summary-card-header>
    <aon-navigation-summary-card-content top="'100px'">
        <fwb-error-message [isError]="isError" [message]="errorMsg"></fwb-error-message>
        <div *ngIf="!isError">
            <div class=" margintop30">
                <div style="display:flex; flex: 1; margin-top: -20px;">
                    <aon-group-bar-chart *ngIf="loaded" [width]="'100%'" [height]="'620px'" [tickformat]="tickformat"
                        [charttitle]="emptyChartTitle" [yaxistitle]="yaxistitle" [tooltip]="tooltip"
                        [ticksize]="ticksize" [maxyaxisnumber]="maxyaxisnumber" [titleposition]="titleposition"
                        [data]="ratiocomparisondata">
                    </aon-group-bar-chart>
                </div>
            </div>
        </div>
    </aon-navigation-summary-card-content>
</aon-navigation-summary-card>