import { Component, OnInit, OnChanges, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { Store } from '@ngrx/store';
import { State } from 'app/_reducers';
import { FWBService } from 'app/fwb/services/fwb.service';
import { User } from 'app/models/user.model';
import { navigation } from 'app/navigation/navigation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PensionCategoriesQuestions } from 'app/fwb/constants/pension-score-chart-data';
import { PensionScoreQuestionData } from 'app/fwb/constants/pension-question-data';
import * as _ from 'lodash';
import { PensionService } from 'app/fwb/services/pension.service';

@Component({
  selector: 'pension-question-card',
  templateUrl: './pension-question-card.component.html',
  styleUrls: ['./pension-question-card.component.scss']
})
export class PensionQuestionCardComponent implements OnInit {
  chartId = "questionCardComponent";
  scoreCardheight = "750px";
  scoreCardwidth = "100%";
  cardheightnumber = 660;
  categoryData: any[];
  questionsData: any[];
  questionsDetails: any[];
  selectedQuestion: number = 5;
  fwb: any;
  textwidth = 40;
  categoryChartTitle = "Category Drilldown";
  fuseConfig: any;
  navigationlocal: any;
  maxbarheight = 80;
  colorindex: number = 3;
  contenttop: string = "70px";
  public user: User;

  questions: any;// = PensionScoreQuestionData;
  categories: any; //= PensionCategoriesQuestions;
  isLoading = true;

  serviceCategoryData: any = null;
  serviceQuestionData: any = null;
  isError = false;
  errorMsg = "No Data Found.";
  constructor(
    private _pensionService: PensionService, 
    private _fwbService: FWBService,
    private store: Store<State>,
    private _router: Router
  ) {
    // Set the defaults
    this.navigationlocal = navigation;

  } 
  ngOnInit() {
   

    this.getQuestions();
    this.getCategory();
    // this.changeQuestion(this.selectedQuestion);
  }


  getCategory() {
    this.serviceCategoryData = null;
    this._pensionService.getScoreCategories("").toPromise().then((resp) => {
      this.serviceCategoryData = resp;
      console.log(this.serviceCategoryData);
      this.setCategories();
      this.errorMsg = "";
      this.isError = false;
  }).catch(error => {
      this.isError = true;
      this.errorMsg = error.message;
  }).finally(() => {
      this.isLoading = false;
    })
  }

  getQuestions() {
    this.serviceQuestionData = null;
    this._pensionService.getScoreQuestions("").toPromise().then((resp) => {
      this.serviceQuestionData = resp;
      console.log(this.serviceQuestionData);
      this.setQuesions();
    }).catch((e) => {
      console.log(e);

    }).finally(() => {
      this.isLoading = false;
    })
  }

  setCategories() {
    this.categories = this.serviceCategoryData.result;
    // this.categories = clientChartDataByRating;
  }

  setQuesions() {
    this.questions = this.serviceQuestionData.result;
    this.setCategoryData();
  }

  ngOnChanges() {
    // this.changeQuestion(this.selectedQuestion);
  }

  getUser() {

  }
  
  expandParentHeight(categoryname) {

    let expandBy: number = 0;
    let selectedCategories: number = 0;
    this.questions.forEach((a) => {
      if (a.name == categoryname) {
        a.isexpanded = !a.isexpanded;
      }

      if (a.isexpanded == true) { 
        expandBy += 1;
      }
    })
    if (expandBy >= 1) {
      selectedCategories = expandBy * 900;
    }
    console.log("expand height called" + categoryname);
    this.scoreCardheight = 900 + selectedCategories + "px";
    console.log("Parent drilldown card expanded by  " + selectedCategories + ". Current Parent height : " + this.cardheightnumber);
  }


  setCategoryData() {
    this.questionsData = []; 
  } 
}
