import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OfflineService {

  constructor(private httpClient: HttpClient) { }

  getQueue() {
    return this.httpClient.get<any>(environment.api + 'api/offline');
  }

  submit(clientId, clientName, countryId, countryName){
    return this.httpClient.post<any>(environment.api + 'api/offline/', 
    { clientId: clientId, clientName: clientName, countryId:countryId, countryName: countryName });
  }
}
