export const locale = {
  lang: 'es-ec',
  data: {
      'DistributionByClaimType': {
           'TotalPaidClaim' : 'Total pagado de reclamo',
           'ClaimCount' : 'Número de Eventos',
           'AverageCost' : 'Costo Promedio',
           'Dimensions' : 'Dimensión',
           'AgeBand' : 'Rango de Edad',
           'Gender' : 'Género',
           'Relationship': 'Parentesco',  
           'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
           'ClaimsReportedByPaidDate' :  'Reclamaciones reportadas por fecha de pago',
            'ClaimsReportedByIncurredDate' :  'Reclamaciones informadas por fecha incurrida',
            'ClaimTypeBy': 'Tipo de Servicio por',
            'DateRange': 'to'
        }
  }
};
