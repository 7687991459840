<div class="rowDiv flex-row-simple">
    <div style="flex: 2;    height:100%;">

        <aon-summary-card [width]="scoreCardwidth" [height]="scoreCardheight">
            <aon-summary-card-header>
                <aon-h3 style="color: #001b5a;">
                    <fa-icon style="padding-right: 5px;" [icon]="['fal', 'tachometer']"></fa-icon>
                    Category Score
                </aon-h3>
            </aon-summary-card-header>
            <aon-summary-card-content [top]="contenttop">
                <div style=" margin-left:40px; color: #001b5a; ">
                    <aon-h5>Breakdown by Category </aon-h5>
                </div>
                <fwb-error-message [isError]="isError" [message]="errorMsg"></fwb-error-message>
                <div *ngIf="!isError">
                    <div style="margin-top:-60px;  ">
                        <aon-group-bar-chart [data]="categories"  [xaxistitle]="xaxistitle"
                        [height]="'500px'"  [ticksize]="tickSize" [yaxistitle]="''" [charttitle]="''"></aon-group-bar-chart>
                    </div>
                </div>
            </aon-summary-card-content>
        </aon-summary-card>
    </div>
    <div style="flex: 1; width:26%;height:100%; margin-left: -25px;">
        <aon-summary-card width="98%" [height]="scoreCardheight">
            <aon-summary-card-content colorindex="1">
                <fwb-error-message [isError]="isError" [message]="errorMsg"></fwb-error-message>
                <div *ngIf="!isError">
                    <div style=" margin-bottom:15px;  ">
                        <div style="  margin-top:15px; color: #001b5a; ">
                            <aon-h5>Current Score </aon-h5>
                            <span class="large" style="margin-top:15px;    color: #001b5a; ">
                                705
                            </span>
                        </div>
                    </div>
                    <div class=" border-bottom   ">
                    </div>
                    <div style=" margin-bottom:15px;  ">
                        <div style=" margin-top:15px;  color: #4374e6; ">
                            <aon-h5>Revised Score </aon-h5>
                            <span class="large" style=" margin-top:15px;   color: #4374e6; ">
                                720
                            </span>
                        </div>
                    </div>
                    <div class=" border-bottom   ">
                    </div>
                </div>
            </aon-summary-card-content>
        </aon-summary-card>
    </div>
</div>