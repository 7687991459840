import { Component, OnInit, OnDestroy, OnChanges } from "@angular/core";
import { User } from "app/models/user.model";
import { FuseConfigService } from "@fuse/services/config.service";
import { Store, select } from "@ngrx/store";
import { Router } from "@angular/router";
import { navigation } from "app/navigation/navigation";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GridOptions } from "ag-grid-community";
import { clientChartDataByRating, clientTableColumnDefs, clientTableDataByRating, globalColumnDefs, globalDataByRanking } from "app/fwb/constants/pension-score-chart-data";
import { FwbExecutiveService } from 'app/fwb/services/fwb-executive.service';

@Component({
    selector: "pension-score",
    templateUrl: "./pension-score.component.html",
    styleUrls: ["./pension-score.component.css"],
})
export class PensionScoreComponent implements OnInit, OnChanges {
    chartId = "PensionScoreChartId";
    height: any = "390px";
    groupheight: any = "420px";
    width: any = "750px";
    cardheight: any = "590px";
    cardwidth: any = "99%";
    tooltip: boolean = true;
    titleposition = "centre";
    charttitle = "Pension Score";
    xaxistitle = "";
    yaxistitle = "";
    color = "white";
    emptyChartTitle = " ";
    maxyaxisnumber: number = 2;
    data: any;
    fuseConfig: any;
    navigation: any;
    gridlines: any;
    isTableData: boolean = true;
    isGlobeData: boolean = false;
    isGroupedData: boolean = false;
    isHeaderVisible: boolean = false;
    tableData: any;
    groupedData: any;
    gridOptions: GridOptions;
    gridApi: any;
    gridColumnApi: any;
    columnDefs = clientTableColumnDefs;
    countryColumnDefs = globalColumnDefs;

    public user: User;
    globeData: any;
    isLoading = true;
    serviceData: any ;
    isError = false;
    errorMsg = "No Data Found.";
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _executiveService: FwbExecutiveService,
        private _router: Router
    ) {
        // Set the defaults
        this.navigation = navigation;
        this.gridOptions = <GridOptions>{
            context: {
                componentParent: this,
            },
            defaultColDef: {
                resizable: true,
            },
            enableSorting: true,
            // headerHeight: 0,
            suppressHorizontalScroll: true,
            enableFilter: false,
        };
    } 
    ngOnChanges() {  
        this.getUser();
        this.gridlines = Object([
            // { name: "Average", value: 95, color: "#325C71", thickness: 1 },
        ]); 
        this.getData();
        this.setData();
        console.log(this.tableData);
    }

    
    getData() {
        this.serviceData = null;
        this._executiveService.getPensionScore("").toPromise().then((resp) => {
            this.serviceData = resp;
            console.log(this.serviceData);
            this.setData();
            this.errorMsg = "";
            this.isError = false;
        }).catch(error => {
            this.isError = true;
            this.errorMsg = error.message;
        }).finally(() => {
            this.isLoading = false;
        })
    }
 
    setData() {
        this.groupedData = this.serviceData.resultRating;
        this.tableData = this.serviceData.resultCountry;
        this.data = this.serviceData.resultCountry;
        this.globeData = this.serviceData.resultAllCountries;
        console.log(this.globeData); 
    }

    ngOnInit() { 
        this.getUser();
        this.gridlines = Object([
            // { name: "Average", value: 95, color: "#325C71", thickness: 1 },
        ]);

        this.getData();
        this.setData(); 
    }

    getUser() {
    }

 
    goToLearnMore() {
        this._router.navigate(["/fwb/pensionscore"]);
    }
    toggleTable(option) {
        if (option == "table") {
            this.isTableData = true;
            this.isGroupedData = false;
            this.isGlobeData = false;
        } else
            if (option == "globe") {
                this.isGroupedData = false;
                this.isTableData = false;
                this.isGlobeData = true;
            } else {
                this.isTableData = false;
                this.isGlobeData = false;

                if (option == "bar") {
                    this.isGroupedData = false;
                } else {
                    this.isGroupedData = true;
                }
            }
    } 
}
