<div style="display: inline-block" style="width: 100%">
    <svg width="100%" height="25px " style="overflow: visible;" id='rectBarSVG'>
        <g class="bartext">
            <rect height="25" [attr.width]="getWidth()" [attr.fill]="barColor"></rect>
            <line *ngIf="templateID == 'ClaimCostByTopProvider' && benchwidth > 0" [attr.transform]="getBenchPos()" x1="0" y1="0" x2="0"
                y2="32" stroke-dasharray="2" style='stroke:rgb(92, 85, 85); ;stroke-width:1;' />
            <text font-size="14px" transform="translate(5, 18)">
                    <tspan *ngIf="unit !== ''" [attr.x]="getWidth()">{{getPercentText()}} </tspan>
                <tspan *ngIf="unit == ''" [attr.x]="getWidth()">{{getPercentText() | numberFormat}} </tspan>
            </text>
        </g>
    </svg>
</div>