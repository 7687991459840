import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor(private httpClient: HttpClient) { }

  public getLogResult()
  {
    return this.httpClient.get<any>(environment.api + 'api/wellone/Cache/log-detail');
  }

  public triggerCache()
  {
    return this.httpClient.get<any>(environment.api + 'api/wellone/Cache/trigger');
  }
  
 
}
