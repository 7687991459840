import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const downloadBlob = (): ((source: Observable<HttpResponse<Blob>>) => Observable<HttpResponse<Blob>>) => {
  return (source: Observable<HttpResponse<Blob>>): Observable<HttpResponse<Blob>> =>
    source.pipe(
      map((response: HttpResponse<Blob>) => {
        const contentDisposition: string = response.headers.get('Content-Disposition') || '';
        const filename = /(?:filename=")(.+)(")/.exec(contentDisposition)?.[1] || '';

        // Create object URL
        const url = window.URL.createObjectURL(response.body as Blob);

        // Invoke download with virtual link tag
        const link = document.createElement('a');
        link.download = filename;
        link.href = url;
        link.click();

        // Cleanup
        link.remove();
        window.URL.revokeObjectURL(url);

        return response;
      }),
    );
};