import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DashboardTemplateBase } from '../dashboard-template-base.component';
import { DataService } from 'app/dashboards/data.service';
import { HttpClient } from '@angular/common/http';
import { FilterOptions } from 'app/dashboards/shared/models/FilterOptions';
import { PulseEventName } from 'app/dashboards/shared/models/PulseEventName';
import { DimensionOrderService } from 'app/dashboards/dimension-order.service';
import { environment } from 'environments/environment';
import { locale as navigationEnglish } from 'app/dashboards/templates/double-quadrant-pro-diag/i18n/en';
import { locale as navigationSpanishCL } from 'app/dashboards/templates/double-quadrant-pro-diag/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/dashboards/templates/double-quadrant-pro-diag/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/dashboards/templates/double-quadrant-pro-diag/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/dashboards/templates/double-quadrant-pro-diag/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/dashboards/templates/double-quadrant-pro-diag/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/dashboards/templates/double-quadrant-pro-diag/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/dashboards/templates/double-quadrant-pro-diag/i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NumberFormattingService } from 'app/services/number-formatting.service';
import { formatNumber } from '@angular/common';
import { countryLATEM } from 'app/constants/countries_LATAM';
import { EMEA_COUNTRIES } from 'app/constants/countries_EMEA';
import { CLAIM_BASIS } from 'app/models/basis.model';
@Component({
    selector: 'app-double-quadrant-pro-diag',
    templateUrl: './double-quadrant-pro-diag.component.html',
    styleUrls: ['./double-quadrant-pro-diag.component.css']
})
export class DoubleQuadrantProDiagComponent
    implements OnInit, DashboardTemplateBase {
    ClaimantOption = ClaimantOption;
    isFilterOptionsLoading = true;
    data: any;
    properties: any;
    imageUrlBase = '';
    filterOptions: FilterOptions = new FilterOptions();
    isLoadingProvider = false;
    isLoadingDiagnoses = false;
    showFilter = false;
    filterNA = false;
    currentProvider = '';
    currentDiagnosis = '';
    // shownAsPerProvider = '';
    // shownAsPerDiagnosis = '';
    datalistProv: any = [];
    datalistDiag: any = [];
    finalTopListBindProv: any = [];
    finalTopListBindDiag: any = [];
    @Output() action = new EventEmitter<any>();
    parentLoaded = false;
    currentContainerIsDiagnosis: boolean;
    currentTimePeriodLable = '';
    localeSelected = 'en';
    comparableTimePeriodLabel = '';
    chartListPro: any = [];
    chartListDiag: any = [];
    ProviderName = 'Provider Name';
    DiagChapter = 'Diag Chapter';
    columnName = 'claimsTotal';
    colors = ['cornflowerblue', 'goldenrod', 'orange', 'cyan',
        'aquamarine', 'lightgreen', 'coral', 'plum', 'chartreuse',
        'lightsalmon', 'blueviolet', 'lightseagreen', 'azure',
        'rosybrown', 'saddlebrown', 'green', 'blue', 'teal', 'purple',
        'brown', 'darkmagenta', 'red', 'yellow', 'orange'];
    selectedvaluesList: any[] = [];
    valuesList: any[] = [];
    excludeList = [];
    CLAIM_BASIS = CLAIM_BASIS;

    initData(): void {
        if (typeof this.data.shownAsPerProvider === 'undefined') {
            this.data.shownAsPerProvider = '';
        }

        if (typeof this.data.shownAsPerDiagnosis === 'undefined') {
            this.data.shownAsPerDiagnosis = '';
        }

        this.comparableTimePeriodLabel = this.properties.rollingPeriod == 3 ? ' (C)' : '';
        this.datalistProv = this.data.providerList.current;
        this.datalistDiag = this.data.diagList.current;
        if (this.currentTimePeriodLable == null || this.currentTimePeriodLable == '') {
            this.currentTimePeriodLable =
                this.data.providerList.currentPeriod != null && typeof this.datalistProv[0] !== 'undefined'
                    ? this.data.providerList.currentPeriod.fromDateLabel + ' ' + this._translateService.instant("DoubleQuad.DateRange") + ' ' + this.data.providerList.currentPeriod.toDateLabel + this.comparableTimePeriodLabel
                    : '';
        }
        if (this.currentTimePeriodLable == null || this.currentTimePeriodLable == '') {
            this.currentTimePeriodLable =
                this.data.diagList.currentPeriod != null && typeof this.datalistDiag[0] !== 'undefined'
                    ? this.data.diagList.currentPeriod.fromDateLabel + ' ' + this._translateService.instant("DoubleQuad.DateRange") + ' ' + this.data.diagList.currentPeriod.toDateLabel + this.comparableTimePeriodLabel
                    : '';
        }
        var list = this.dimensionOrderService.sortByColumn(
            this.datalistProv, this.columnName);
        this.finalTopListBindProv = list.reverse().slice(0, this.properties.doubleQuadrantProDiagTopFilter);

        var list2 = this.dimensionOrderService.sortByColumn(
            this.datalistDiag, this.columnName);
        this.finalTopListBindDiag = list2.reverse().slice(0, this.properties.doubleQuadrantProDiagTopFilter);
        var maxDiagCost = Math.max.apply(
            null,
            this.finalTopListBindDiag.map(a =>
                this.properties.claimTypeOption == ClaimantOption.Claim ? a.averageCostPerClaim : a.averageCostPerClaimant)
        );
        var maxDiagCount = Math.max.apply(
            null,
            this.finalTopListBindDiag.map(a =>
                this.properties.claimTypeOption == ClaimantOption.Claim ? a.claimsCount : a.claimantCount)
        );
        var maxProviderCost = Math.max.apply(
            null,
            this.finalTopListBindProv.map(a =>
                this.properties.claimTypeOption == ClaimantOption.Claim ? a.averageCostPerClaim : a.averageCostPerClaimant)
        );
        var maxProviderCount = Math.max.apply(
            null,
            this.finalTopListBindProv.map(a =>
                this.properties.claimTypeOption == ClaimantOption.Claim ? a.claimsCount : a.claimantCount)
        );
        this.chartListPro['maxX'] = (120 * maxProviderCount) / 100;
        this.chartListPro['maxY'] = (120 * maxProviderCost) / 100;
        this.chartListDiag['maxX'] = (120 * maxDiagCount) / 100;
        this.chartListDiag['maxY'] = (120 * maxDiagCost) / 100;
        this.createChartBindList(this.finalTopListBindProv, this.chartListPro, this.currentProvider, []);

        this.createChartBindList(this.finalTopListBindDiag, this.chartListDiag, this.currentDiagnosis, []);
        setTimeout(() => (this.parentLoaded = true), 100);
    }

    constructor(
        private _dataService: DataService,
        private httpClient: HttpClient,
        private dimensionOrderService: DimensionOrderService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _numberFormatService: NumberFormattingService
    ) { }

    ngOnInit() {
        this._fuseTranslationLoaderService.loadTranslations(
          navigationEnglish,
          navigationSpanishCL,
          navigationSpanishCO,
          navigationSpanishEC,
          navigationSpanishMX,
          navigationSpanishPE,
          navigationPortugueseBR,
          navigationItalianIT,
        );

        if (this._translateService != null) {
            this._translateService.onLangChange.subscribe(
                (event: LangChangeEvent) => {
                    this.initData();
                }
            );
        }
    }
    ngOnDestroy() {
        //this._translateService.onLangChange.unsubscribe();
    }

    createChartBindList(listOfData: any, chartListReturn: any, current: any, excludelistLocal: any) {
        var colorIndex = 0;
        this.selectedvaluesList = [];
        this.valuesList = [];
        var clist = [];
        var decimalValue = this.properties.country === 'Chile' ? 2 : 1;
        listOfData.forEach(d => {
            if(this.hasComplianceRule()
                && !(d.claimantCount < this.properties.claimantFilterValue))
            {
                this.valuesList.push(d.dimension);
            }

            var obj = {};
            obj['name'] =  this.hasComplianceRule()
                && d.claimantCount < this.properties.claimantFilterValue
              ? 'NA'
              : d.dimension
            obj['actualName'] = d.dimension;
            obj['z'] = d.claimsTotal;
            obj['color'] = (current == '' || current == undefined) ? this.colors[colorIndex] :
                (current == d.dimension ? this.colors[colorIndex] : '#7cb5ec');
            colorIndex++;
            obj['x'] = this.properties.claimTypeOption == ClaimantOption.Claim
                ? d.claimsCount
                : d.claimantCount;
            var yOb = this.properties.claimTypeOption == ClaimantOption.Claim
                ? d.averageCostPerClaim
                : d.averageCostPerClaimant;
            obj['y'] = yOb;
            obj['xLable'] =
                this.properties.claimTypeOption == ClaimantOption.Claim
                    ? d.claimsCount
                    : d.claimantCount;
            obj['yLable'] = formatNumber(parseFloat(yOb.toFixed(decimalValue)), 'en-US');
            obj['zLable'] = countryLATEM.indexOf(this.properties.country) >= 0 ?
                this._numberFormatService.ToAbbrevNumberStringLatem(d.claimsTotal, 4) :
                this._numberFormatService.ToAbbrevNumberString(d.claimsTotal, 4);
            obj['claimOrClaimant'] =
                this.properties.claimTypeOption == ClaimantOption.Claim
                    ? this._translateService.instant('DoubleQuad.Claim')
                    : this._translateService.instant('DoubleQuad.Claimant');

            if (excludelistLocal.length > 0
                && excludelistLocal.indexOf(d.dimension) >= 0) {
                return;
            }

            clist.push(obj);

            if(this.hasComplianceRule()
                && d.claimantCount < this.properties.claimantFilterValue){
                return;
            }

            this.selectedvaluesList.push(d.dimension);
        });

        chartListReturn['chartData'] = clist;
        chartListReturn['labelXaxis'] = this.properties.claimTypeOption == ClaimantOption.Claim
            ? this._translateService.instant('DoubleQuad.ClaimCount')
            : this._translateService.instant('DoubleQuad.ClaimantCount');
        chartListReturn['labelYaxis'] = this.properties.claimTypeOption == ClaimantOption.Claim
            ? this._translateService.instant('DoubleQuad.CostPerClaim')
            : this._translateService.instant('DoubleQuad.CostPerClaimant');
        chartListReturn['labelZaxis'] = this._translateService.instant('DoubleQuad.ClaimsTotal');
    }

    hasComplianceRule() : boolean {
        return EMEA_COUNTRIES.includes(this.properties.country);
    }

    ClaimsUnit = {
        M: 1000000,
        K: 1000
    };
    format(num, dec) {
        let number = num
            ? num.toLocaleString(undefined, {
                maximumFractionDigits: dec
            })
            : '';
        return number;
    }
    diagSelect(event: any) {
        var selection = event.detail;
        this.excludeList = [];
        this.valuesList.map(d => {
            if (selection.indexOf(d) < 0) {
                this.excludeList.push(d);
            }
        });
        this.createChartBindList(this.finalTopListBindDiag, this.chartListDiag, this.currentDiagnosis, this.excludeList);

    }
    filterChanged(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
            data: {
                properties: {
                    name: filterName,
                    value: typeof val === 'object' ? val.data.join('||') : val
                }
            }
        });
        if (this.currentDiagnosis == '' && this.currentProvider == '') {
            this.initData();
        }
        else {
            this.resetAll();
        }
    }

    filterNAChange(val, filterName: string) {
        this.properties.isExcludeNA = !this.properties.isExcludeNA;
        let filterVal: any;
        if (this.properties.isExcludeNA == false) {
            filterVal = '';
        }
        else if (this.properties.isExcludeNA == true) {
            filterVal = 'claimant';
        }
        if (this.currentDiagnosis === '' && this.currentProvider === '') {

            this.action.emit({
                type: PulseEventName.FILTLER_CHANGED,
                data: {
                    properties: {
                        name: filterName,
                        value: filterVal
                    }
                }
            });
        } else if (this.currentDiagnosis !== '' && this.currentProvider === '') {
            this.action.emit({
                type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
                data: {
                    properties: {
                        name: filterName,
                        value: typeof filterVal === 'object' ? filterVal.data.join('||') : filterVal
                    }
                }
            });
            this.selectDiagFilterPro(this.currentDiagnosis);
        } else if (this.currentDiagnosis === '' && this.currentProvider != '') {
            this.action.emit({
                type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
                data: {
                    properties: {
                        name: filterName,
                        value: typeof filterVal === 'object' ? filterVal.data.join('||') : filterVal
                    }
                }
            });
            this.selectProviderFilterDiag(this.currentProvider);
        }
    }
    filterTopList() {
        this.initData();
    }
    resetAll() {
        this.currentDiagnosis = '';
        this.currentProvider = '';
        this.data.shownAsPerDiagnosis = '';
        this.data.shownAsPerProvider = '';
        this.isLoadingProvider = true;
        this.isLoadingDiagnoses = true;
        this.properties.secondaryDimension = '';
        this.properties.secondaryDimensionValue = '';
        this.properties.dimension = this.ProviderName;
        this._dataService
            .getTier3Data(this.properties)
            .toPromise()
            .then(r => {
                this.data.providerList = r;
                this.properties.dimension = this.DiagChapter;
                this._dataService
                    .getTier3Data(this.properties)
                    .toPromise()
                    .then(re => {
                        this.data.diagList = re;
                        this.initData();
                        this.isLoadingProvider = false;
                        this.isLoadingDiagnoses = false
                    });
            });
    }
    selectDiagnosis(eventData: any) {
        if (this.currentDiagnosis == eventData.actualName) {
            this.resetAll();
        } else {

            this.currentDiagnosis = eventData.actualName;
            this.data.shownAsPerDiagnosis = eventData.name;
            this.selectDiagFilterPro(this.currentDiagnosis);
        }
    }
    selectDiagFilterPro(currentDiagnosisVal: any) {
        this.isLoadingProvider = true;
        this.currentProvider = '';
        this.properties.dimension = this.ProviderName;
        this.properties.secondaryDimension = this.DiagChapter;
        this.properties.secondaryDimensionValue = currentDiagnosisVal.replace(
            '\'',
            '\'\''
        );
        this._dataService
            .getTier3Data(this.properties)
            .toPromise()
            .then(re => {
                this.data.providerList = re;
                this.initData();
                this.isLoadingProvider = false;
            });
    }
    selectProviders(eventData: any) {
        if (this.currentProvider == eventData.actualName) {
            this.resetAll();
        } else {

            this.currentProvider = eventData.actualName;
            this.data.shownAsPerProvider = eventData.name;
            this.selectProviderFilterDiag(this.currentProvider);
        }
    }
    selectProviderFilterDiag(currentProviderVal: any) {
        this.isLoadingDiagnoses = true;
        this.currentDiagnosis = '';
        this.properties.dimension = this.DiagChapter;
        this.properties.secondaryDimension = this.ProviderName;
        this.properties.secondaryDimensionValue = currentProviderVal.replace(
            '\'',
            '\'\''
        );
        this._dataService
            .getTier3Data(this.properties)
            .toPromise()
            .then(re => {
                this.data.diagList = re;
                this.initData();
                this.isLoadingDiagnoses = false;
            });
    }
    additionalFilterSelectionChanged(val) {
        this.action.emit(val);
    }
}
export enum ClaimantOption {
    Claim = 'Claim',
    Claimant = 'Claimant'
}