export const locale = {
	lang: 'pt-br',
	data: {
		'ClaimType': {
			'ClaimHeading': 'Tipo de Evento',
			'AllAmountsDisplayedInLocalCurrency': 'Todos os valores estão em moeda local',
			'ClaimType': 'Tipo de Sinistro',
			'TotalPaidClaim': 'Total de Sinistro',
			'ClaimantCount': 'Contagem de Usuário',
			'ClaimCount': 'Contagem de Sinistros',
			'ClaimsPerClaimant': 'Sinistro Por Usuário',
			'ClaimsPerHeadcount': 'Sinistro Por Headcount',
			'CostPerClaim': 'Custo Por Sinistro',
			'CostPerClaimant': 'Custo Por Usuário',
			'CostPerHeadcount': 'Custo Por Headcount',
			'ChangeInCost': 'Mudança Ano I vs. Ano II',
			'Claim': 'Sinistro',
			'Claimant': 'Usuário',
			'Headcount': 'Headcount',
			'ClaimsTotal': 'Sinistro Total',
			'Count': 'Contar',
			'ClaimsReportedByPaidDate': 'Sinistro Reportado por Data de Pagamento',
			'ClaimsReportedByIncurredDate': 'Sinistro Reportado por Data de Atendimento',
			'CountBasis': 'Base de Contagem',
			'Sort': 'Ordenar',
			'Show': 'Mostrar',
			'Hide': 'Esconder',
			'ShowHide': 'Mostrar/Esconder',
			'Average': 'Média',
			'PeriodAverage': 'Média do Período',
			'PlanYear': 'Plano Ano'
		}
	}
};
