export const locale = {
    lang: 'en',
    data: {
        'HealthDiseaseProfile': {
            'HealthProfile': 'Health Profile',
            'Top Diseases': 'Top Diseases', 
            'TopChronicDiseases': 'Top Chronic Diseases',    
            'TopInfectiousDiseases': 'Top Infectious Diseases',
            'TopOtherDiseases': 'Top Diseases (Other)',               
            'PaidClaim': 'Paid Claims',
            'AverageCost': 'Average Cost',
            'ClaimantCount': 'Claimant Count', 
            'Utilization': 'Utilization',
            'Chronic': 'Chronic', 
            'Infectious': 'Infectious',
            'Other': 'Other',
            'Current': 'Current',
            'Prior': 'Prior',
            'ClaimsReportedByPaidDate': 'Claims reported by paid date',
            'ClaimsReportedByIncurredDate': 'Claims reported by incurred date',
            'DateRange': 'to',
            'DiagnosisGroup': 'Diagnosis Group',
            'Actionable': 'Actionables',
            'Trauma/accidents': 'Trauma/Accidents',
            'Cancer': 'Cancer',
            'Mental health': 'Mental Health',
            'Obstetrics': 'Obstetrics',
            'Others': 'Others',
            'Preventable': 'Preventable',
            'Intervenable': 'Intervenable',
            'CategoryOption': 'Category',
            'Category': 'Category',
            'Chapter': 'Chapter',
            'Sort': 'Sort By',
            'None': 'None'
        }
    }
};
