import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { List } from 'linqts';
import { DashboardTemplateBase } from '../dashboard-template-base.component';
import { FilterOptions } from 'app/dashboards/shared/models/FilterOptions'
import { PulseEventName } from 'app/dashboards/shared/models/PulseEventName';
import { locale as navigationEnglish } from "app/dashboards/templates/health-disease-profile/i18n/en";
import { locale as navigationSpanishCL } from "app/dashboards/templates/health-disease-profile/i18n/es-cl";
import { locale as navigationSpanishCO } from "app/dashboards/templates/health-disease-profile/i18n/es-co";
import { locale as navigationSpanishEC } from "app/dashboards/templates/health-disease-profile/i18n/es-ec";
import { locale as navigationSpanishMX } from "app/dashboards/templates/health-disease-profile/i18n/es-mx";
import { locale as navigationSpanishPE } from "app/dashboards/templates/health-disease-profile/i18n/es-pe";
import { locale as navigationPortugueseBR } from "app/dashboards/templates/health-disease-profile/i18n/pt-br";
import { locale as navigationItalianIT } from "app/dashboards/templates/health-disease-profile/i18n/it";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { TranslateService } from "@ngx-translate/core";
import { CategoryChapterOption } from '../claim-cost-top-provider/claim-cost-top-provider.component';
import { APAC_COUNTRIES } from 'app/constants/countries_APAC';
import { CLAIM_BASIS } from 'app/models/basis.model';
import { indexOf } from 'lodash';


@Component({
  selector: 'app-health-disease-profile',
  templateUrl: './health-disease-profile.component.html',
  styleUrls: ['./health-disease-profile.component.css']
})
export class HealthDiseaseProfileComponent implements OnInit, DashboardTemplateBase {

  @Output() action = new EventEmitter<any>();
  @Input() isExport = false;

  apacCountries = APAC_COUNTRIES;
  CategoryChapterOption = CategoryChapterOption;
  SortOption = SortOption;
  isFilterOptionsLoading = true;

  imageUrlBase = '';
  properties: any;
  data: any;
  isLoading = false;
  Highcharts: typeof Highcharts = Highcharts;
  client: string;
  country: string;
  showFilter = false;
  categories: Category[] = [];
  categoriesData: Category[] = [];
  filterOptions: FilterOptions = new FilterOptions();
  isLinked = false;
  eligibleForBenchmark = false;
  priorDateRange = '';
  currentDateRange = '';
  utilizationBenchmarks = {};
  claimsBenchmarks = {};
  comparable: string = "";
  translatePrefix = "HealthDiseaseProfile.";
  barHeight = "30px";
  barFontSize = "13px";

  diagGrps = "";
  diagGroupOptionList = ['CHRONIC', 'INFECTIOUS', 'TRAUMA/ACCIDENTS', 'CANCER', 'MENTAL HEALTH', 'OBSTETRICS', 'OTHERS'];
  actionableOptionList = ['PREVENTABLE', 'INTERVENABLE', 'NONE'];
  categoryOptionList = [];

  translatedDiagGroupOptionList = [];
  translatedActionableOptionList = [];

  selectedDiagGroupOptionList = [];
  selectedActionableOptionList = [];
  selectedCategoryOptionList = [];

  CLAIM_BASIS = CLAIM_BASIS;

  constructor(
    private _translateService: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
  ) { }

  ngOnInit() {
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationSpanishCL,
      navigationSpanishCO,
      navigationSpanishEC,
      navigationSpanishMX,
      navigationSpanishPE,
      navigationPortugueseBR,
      navigationItalianIT,
    );

    this.translatedDiagGroupOptionList = this.diagGroupOptionList.map(diagGroupOption => this.toTranslateFormat(diagGroupOption).toUpperCase());
    this.translatedActionableOptionList = this.actionableOptionList.map(actionableOption => this.toTranslateFormat(actionableOption).toUpperCase());

    this._translateService.onLangChange.subscribe(() => {
      this.translatedDiagGroupOptionList = this.diagGroupOptionList.map(diagGroupOption => this.toTranslateFormat(diagGroupOption).toUpperCase());
      this.translatedActionableOptionList = this.actionableOptionList.map(actionableOption => this.toTranslateFormat(actionableOption).toUpperCase());
    });

    if (this.diagGrps == '' && this.properties.diagGrps) {
      this.diagGrps = this.properties.diagGrps;
      this.onfilterSelectionChanged(this.diagGrps, 'diagGrps');
    }

    this.properties.sort = SortOption.ClaimTotal;

    if (this.selectedCategoryOptionList.length < 1 && this.properties.diagCats) {
      this.selectedCategoryOptionList = this.properties.diagCats.split('||');
    }

    if (this.selectedActionableOptionList.length < 1 && this.properties.actionables) {
      this.selectedActionableOptionList = this.properties.actionables.split('||');
    }

  }

  initData() {
    this.priorDateRange = this.data.priorDateRange.fromDateLabel === this.data.priorDateRange.toDateLabel ? this.data.priorDateRange.fromDateLabel : this.data.priorDateRange.fromDateLabel + ' ' + this._translateService.instant("HealthDiseaseProfile.DateRange") + ' ' + this.data.priorDateRange.toDateLabel;
    this.currentDateRange = this.data.currentDateRange.fromDateLabel === this.data.currentDateRange.toDateLabel ? this.data.currentDateRange.fromDateLabel : this.data.currentDateRange.fromDateLabel + ' ' + this._translateService.instant("HealthDiseaseProfile.DateRange") + ' ' + this.data.currentDateRange.toDateLabel;

    this.comparable = this.properties.rollingPeriod == 3 ? " (C)" : "";
    if (!this.properties.diagGrps) {
      this.properties.diagGrps = '';
    }

    if (!this.properties.actionables) {
      this.properties.actionables = '';
    }

    if (!this.properties.diagCats) {
      this.properties.diagCats = '';
    }

    this.manageResults(this.data.data);

    if (this.eligibleForBenchmark) {
      this.data.utilizationBenchmarks.forEach(b => {
        this.utilizationBenchmarks[b.dimension] = b.benchmarkValue;
      });

      this.data.claimBenchmarks.forEach(b => {
        this.claimsBenchmarks[b.dimension] = b.benchmarkValue;
      });
    }
  }

  manageResults(r) {
    this.transformData(r);
    var elements: any = document.getElementsByTagName("animate");
    for (var i = 0; i < elements.length; i++) {
      elements[i].beginElement();
    }
  }

  getSVGPercentage(val, max) {
    if (max == 0 || max == null) {
      return 0;
    }
    return '' + (val / max) * 100 + '%'
  }

  Types = {
    Chronic: 1,
    Infectious: 2,
    Other: 3
  }

  ClaimsUnit = {
    M: 1000000,
    K: 1000,
  }

  activeClaimsUnit = 'M';
  percentUnit = '%';

  transformData(raw) {
    this.categoriesData = [];
    var unit = this.ClaimsUnit.M;
    if (raw[0] != null) {
      unit = raw[0].totalClaims > 1000000 ? this.ClaimsUnit.M : this.ClaimsUnit.K;
      this.activeClaimsUnit = raw[0].totalClaims > 1000000 ? 'M' : 'K';
    }

    var currentPeriod = ((Math.max.apply(
      null,
      raw.map(a => { return a.r12; })
    ) as number) - 1);
    var curTotalClaims = Math.max(...raw.filter(r => r.r12 === currentPeriod).map(i => i.totalClaims));
    var preTotalClaims = Math.max(...raw.filter(r => r.r12 !== currentPeriod).map(i => i.totalClaims));
    raw.forEach(r => {
      var c = this.categoriesData.filter(d => d.name == r.dimension)[0];

      if (!c) {
        c = new Category();
        c.name = r.dimension;
        c.dimension = r.dimension;
        c.benchmark = 0.99;
        c.paidclaims = r.paidClaimAmt > 0 ? r.paidClaimAmt : 0;
        c.averagecost = r.averageCost;
        c.claimantcount = r.claimants;
        this.categoriesData.push(c);
      }

      if (r.r12 === currentPeriod) {
        c.current.paidClaim = r.paidClaimAmt > 0 ? r.paidClaimAmt : 0;
        c.current.totalClaims = curTotalClaims;
        c.current.averageCost = r.averageCost;
        c.current.claimantCount = r.claimants;
        c.current.prevalence = r.prevalence;
      } else {
        c.prior.paidClaim = r.paidClaimAmt > 0 ? r.paidClaimAmt : 0;
        c.prior.totalClaims = preTotalClaims;
        c.prior.averageCost = r.averageCost;
        c.prior.claimantCount = r.claimants;
        c.prior.prevalence = r.prevalence;
      }

      if (!c.current.totalClaims) {
        c.current.totalClaims = curTotalClaims;
      }

      if (!c.prior.totalClaims) {
        c.prior.totalClaims = preTotalClaims;
      }


    });

    if (this.properties.id == 'DiseaseProfile') {
      this.filterCategories();
    }

    this.selectTopCategories();
  }

  selectTopCategories() {
    this.categories = new List<Category>(this.categoriesData)
      .Take(7)
      .ToArray();

    if (this.properties.diag === CategoryChapterOption.DiagCat) {
      this.categoryOptionList = new List<Category>(this.categoriesData)
        .Take(7)
        .Select(p => p.dimension)
        .ToArray();

      this.properties['diagCats'] = this.categoryOptionList.join('||');
      this.selectedCategoryOptionList = [];//this.properties.diagCats.split('||');
    }

  }

  filterCategories() {

    if (this.properties.sort === SortOption.ClaimTotal) {
      this.categories = new List<Category>(this.categoriesData)
        .OrderByDescending(x => x.paidclaims)
        .ToArray();
    }
    else if (this.properties.sort === SortOption.AverageCost) {
      this.categories = new List<Category>(this.categoriesData)
        .OrderByDescending(x => x.averagecost)
        .ToArray();
    }
    else {
      this.categories = new List<Category>(this.categoriesData)
        .OrderByDescending(x => x.claimantcount)
        .ToArray();
    }

    this.selectTopCategories();
  }

  format(num, dec) {
    if (!dec) dec = 0;
    return num ? num.toLocaleString(undefined, { maximumFractionDigits: dec }) : '';
  }

  selectionChanged($event, filterName: string) {
    this.action.emit({
      type: PulseEventName.FILTLER_CHANGED,
      data: {
        properties: {
          name: filterName,
          value: $event.data
        }
      }
    });
  }

  categoryOptionChanged($event, filterName: string) {
    if ($event == CategoryChapterOption.DiagCat) {
      this.properties.diagCatsOptions = '';
      this.selectedCategoryOptionList = [];
    }

    this.action.emit({
      type: PulseEventName.FILTLER_CHANGED,
      data: {
        properties: {
          name: filterName,
          value: $event
        }
      }
    });

  }

  filterChanged(val, filterName: string) {
    this.action.emit({
      type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
      data: {
        properties: {
          name: filterName,
          value: typeof val === 'object' ? val.data.join('||') : val
        }
      }
    });

    if (filterName == 'sort') {
      if (this.properties.id == 'DiseaseProfile') {
        this.filterCategories();
      }
    }
  }

  navigateToDiseaseProfile(cat) {
    this.diagGrps = cat[0].toUpperCase() + cat.toLowerCase().slice(1);

    if (this.properties.id == 'HealthProfile') {
      this.action.emit({
        type: PulseEventName.FILTLER_CHANGED_WITH_NAVIGATE,
        data: {
          to: 'HealthDiseaseProfile',
          id: 'DiseaseProfile',
          properties: {
            id: 'DiseaseProfile',
            name: 'Top ' + cat + ' Diseases',
            category: cat,
            client: this.properties.client,
            country: this.properties.country,
            filterName: 'diagGrps',
            filterValue: this.diagGrps,
            filterCategory: this.diagGrps,
          }
        }
      });
    }
  }

  additionalFilterSelectionChanged(val) {
    this.action.emit(val);
  }

  filterSelectionChanged(val, filterName: string) {
    const selections = val.data.map(val => filterName === 'diagCatsOptions'
      ? this.diagGroupOptionList[indexOf(this.translatedDiagGroupOptionList,val)]
      : this.actionableOptionList[indexOf(this.translatedActionableOptionList,val)]).join('||')

    this.action.emit({
      type: PulseEventName.FILTLER_CHANGED,
      data: {
        properties: {
          name: filterName,
          value: selections,
        },
      },
    });
  }

  onfilterSelectionChanged(diagGroupOption, filterName: string) {
    if (filterName === 'diagGrps' && this.properties.diag === CategoryChapterOption.DiagChapter) {
      this.categoryOptionChanged(CategoryChapterOption.DiagCat, 'diag');

      setTimeout(() => {
        this.categoryOptionChanged(CategoryChapterOption.DiagChapter, 'diag');
      }, 500);
    }

    this.action.emit({
      type: PulseEventName.FILTLER_CHANGED,
      data: {
        properties: {
          name: filterName,
          value: diagGroupOption,
          category: diagGroupOption
        },
      },
    });
  }

  toTranslateFormat(text: string) {
    var translatedText = "";
    if (text) {
      translatedText = this._translateService.instant(this.translatePrefix + text[0].toUpperCase() + text.toLowerCase().slice(1));
    }

    return translatedText.startsWith(this.translatePrefix)
        ? text
        : translatedText;
  }
}

export class Category {
  name: string;
  dimension: string;
  benchmark: number;
  paidclaims: number;
  averagecost: number;
  claimantcount: number;
  current: any = {};
  prior: any = {};
}

export enum SortOption {
  ClaimTotal = 'claimsTotal',
  ClaimantCount = 'claimantcount',
  AverageCost = 'averagecost'
}