import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import { formatNumber } from '@angular/common';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.css']
})
export class ColumnComponent implements OnInit, OnChanges {

  chart: Chart;
  options: Options;
  @Input() dataList: any;
  @Input() textC: string;
  @Input() barColor: string;
  @Input() categories: any;
  @Input() marginLeft: number = null;
  @Input() plotXpos: any = [];
  @Input() maxX: any;
  @Input() plotYLine: any;
  @Input() dotListName:any;
  @Input() dotColor:any="#FF803E";
  @Input() dataListDot:any;
  @Input() barShow:boolean;
  @Input() lineShow:boolean;
  @Input() isCountryChile:boolean = false;
     
  ngOnInit() {
    this.initialize();
    this.chart = new Chart(this.options);
  }
  ngOnChanges() {
    this.initialize();
    this.chart = new Chart(this.options);
  }

  initialize() {    
    Highcharts.setOptions({
      lang: {
        thousandsSep: ","
      }
    });
    this.options = {
      chart: {
        type: 'column',
        height: 155,
        marginLeft: this.marginLeft
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        min: 0,
        max: this.maxX,
        labels: {          
          rotation: -90,
          enabled: this.lineShow ? true :false,
          style: {
            color: 'black',
            fontFamily: 'Helvetica Now Text, Arial, Open Sans, Helvetica, sans-serif'
          }
        },
        visible: true,
        categories: this.categories,
        plotLines: this.plotXpos
      },
      yAxis: [{
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 1,
        title: {
          text: this.textC,
          style: {
            fontFamily: 'Helvetica Now Text, Arial, Open Sans, Helvetica, sans-serif',
            color: 'black'
          }
        },
        labels: {
          enabled: false
        }, 
      }, {
        opposite: true,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 1,
        title: {
          text: "",
          style: {
            fontFamily: 'Helvetica Now Text, Arial, Open Sans, Helvetica, sans-serif',
            color: 'black'
          }
        },
        labels: {
          enabled: false
        }
      }
      ],
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        headerFormat: "{point.x}<br>",
        pointFormat:  this.isCountryChile ? "<b>{series.name}</b>: {point.y:,.2f}" : "<b>{series.name}</b>: {point.y:,.0f}<br>",
         style: {
          color: 'black',
          fontSize: '14px',
          textOutline: 'none',  
          fontWeight: '400',
          fontFamily: 'Helvetica Now Text, Arial, Open Sans, Helvetica, sans-serif'
        }
      },
      series: [{        
        type: 'column',
        visible:this.barShow,
        color: this.barColor,
        pointWidth: 20, pointPadding: .3,
        name: this.textC,
        data: this.dataList

      },
      {
        type: 'line',
        name: this.dotListName,
        color: this.dotColor,
        data: this.dataListDot,
        lineWidth: 0,
        marker: {
          symbol: 'circle',
          radius: 3,
        },
        zIndex:5,
        states: {
          hover: {
            enabled: false,
            lineWidth: 0
          }
        }
      },
      {
        type: 'line',
        visible:this.lineShow,
        name: this.textC,
        color: this.barColor,
        data: this.dataList,
      }]
    }
  }
}