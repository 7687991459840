import {
  Component,
  OnInit,
  Output,
  ElementRef,
  EventEmitter
} from "@angular/core";
import { DashboardTemplateBase } from "../dashboard-template-base.component";
import { DataService } from "app/dashboards/data.service";
import { HttpClient } from "@angular/common/http";
import { FilterOptions } from "app/dashboards/shared/models/FilterOptions";
import { PulseEventName } from "app/dashboards/shared/models/PulseEventName";
import { DimensionOrderService } from "app/dashboards/dimension-order.service";
import { locale as navigationEnglish } from "app/dashboards/templates/loss-ratio/i18n/en";
import { locale as navigationSpanishCL } from "app/dashboards/templates/loss-ratio/i18n/es-cl";
import { locale as navigationSpanishCO } from "app/dashboards/templates/loss-ratio/i18n/es-co";
import { locale as navigationSpanishEC } from "app/dashboards/templates/loss-ratio/i18n/es-ec";
import { locale as navigationSpanishMX } from "app/dashboards/templates/loss-ratio/i18n/es-mx";
import { locale as navigationSpanishPE } from "app/dashboards/templates/loss-ratio/i18n/es-pe";
import { locale as navigationPortugueseBR } from "app/dashboards/templates/loss-ratio/i18n/pt-br";
import { locale as navigationItalianIT } from "app/dashboards/templates/loss-ratio/i18n/it";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { CLAIM_BASIS } from "app/models/basis.model";

@Component({
  selector: 'app-loss-ratio',
  templateUrl: './loss-ratio.component.html',
  styleUrls: ['./loss-ratio.component.css']
})
export class LossRatioComponent implements OnInit, DashboardTemplateBase {
  ShowHideOption = ShowHideOption;
  ClaimPMPMOption = ClaimPMPMOption;
  LossRatioOption = LossRatioOption;
  PeriodOption = PeriodOption;

  isFilterOptionsLoading = true;

  // Color Palette
  primary_color = '#c7c2c2eb'
  secondary_color = "#12A88A";
  tertiary_color = "#D10058";
  quaternary_color= "#29B0C3";

  data: any;
  properties: any;
  imageUrlBase = "";
  filterOptions: FilterOptions = new FilterOptions();
  TargetLossRatio: number;
  showFilter: boolean = false;
  finalTopListBind: any = [];
  finalTopListChart: any = [];
  datalist: any = [];
  chart1: string = '';
  datalistDiag: any = [];
  parentLoaded = false;
  chartList: any = [];
  dataListLossColumn: any;
  dataListLossLine: any;
  categories1: any;
  MaxofLossY: any;
  MaxofClaimPreY: any;
  claimPMPMSelected: boolean = false;
  plotLinesX: any[] = [];
  isCountryChile: boolean = false;
  dataListExpense: any[] = [];
  dataListIncome: any[] = [];
  dataListOrange: any[] = [];
  dataListBlue: any[] = [];
  MonthlyName: string;
  CummulativeName: string;
  legendOrangeText: string;
  legendBlueText: string;
  CLAIM_BASIS = CLAIM_BASIS;

  @Output() action = new EventEmitter<any>();

  initData(): void {
    // this.categories1 = this.data.labelList;
    this.categories1 = this.data.labelList.map(x => (x.fromDateLabel === x.toDateLabel ? x.fromDateLabel : x.fromDateLabel + ' ' + this._translateService.instant("LossRatio.DateRange") + ' ' + x.toDateLabel));
    this.isCountryChile = this.properties.country === 'Chile' ? true : false;
    this.TargetLossRatio = this.properties.targetLossRatio;
    this.claimPMPMSelected = this.properties.claimPMPM === ClaimPMPMOption.ClaimsPMPM ? true : false;
    this.MonthlyName = this._translateService.instant("LossRatio.MonthlyLossRatio");
    this.CummulativeName = this._translateService.instant("LossRatio.CumulativeLossRatio");
    this.legendOrangeText = this.properties.lossRatio == LossRatioOption.LossRatio ?
      this._translateService.instant("LossRatio.Claims") :
      this._translateService.instant("LossRatio.ClaimLabel");
    this.legendBlueText = this.properties.lossRatio == LossRatioOption.LossRatio ?
      this._translateService.instant("LossRatio.Premium") :
      this._translateService.instant("LossRatio.PremimumLabel");
    if (this.properties.lossRatio == LossRatioOption.LossRatio) {
      this.dataListBlue = [];
      this.dataListBlue = this.data.premiumList.map((d) => {
        // if (d > 0) return d
        // else return null
        return d;
      });
      this.dataListOrange = [];
      this.dataListOrange = this.data.claimList.map((d) => {
        // if (d > 0) return d
        // else return null
        return d;
      });
      var maxPremium = Math.max.apply(
        null,
        this.dataListBlue.map(a => a)
      );
      var maxClaims = Math.max.apply(
        null,
        this.dataListOrange.map(a => a)
      );
      this.MaxofClaimPreY = maxPremium > maxClaims ? Math.ceil(((120 * maxPremium) / 100)) : Math.ceil(((120 * maxClaims) / 100));
    }
    else if (this.properties.lossRatio == LossRatioOption.CombinedLR) {
      var orangeList = this.data.claimList.map(function (v, i) { return v + this[i]; }, this.data.expenseList);
      var blueList = this.data.premiumList.map(function (v, i) { return v + this[i]; }, this.data.incomeList);
      blueList = blueList.map((d) => {
        // if (d > 0) return d
        // else return null
        return d;
      });
      orangeList = orangeList.map((d) => {
        // if (d > 0) return d
        // else return null
        return d;
      });
      var maxPremium = Math.max.apply(
        null,
        blueList.map(a => a)
      );
      var maxClaims = Math.max.apply(
        null,
        orangeList.map(a => a)
      );
      this.MaxofClaimPreY = maxPremium > maxClaims ? Math.ceil(((120 * maxPremium) / 100)) : Math.ceil(((120 * maxClaims) / 100));
      var i = 0;
      this.dataListOrange = [];
      this.dataListOrange = orangeList.map(d => {
        var obj = {};
        obj["y"] = d;
        obj["CPText"] = this._translateService.instant("LossRatio.Claims");
        obj["EIText"] = this._translateService.instant("LossRatio.Expense");
        obj["CPVal"] = this.data.claimList[i];
        obj["EIVal"] = this.data.expenseList[i];
        i++;
        return obj;
      })
      var j = 0;
      this.dataListBlue = [];
      this.dataListBlue = blueList.map(d => {
        var obj = {};
        obj["y"] = d;
        obj["CPText"] = this._translateService.instant("LossRatio.Premium");
        obj["EIText"] = this._translateService.instant("LossRatio.Income");
        obj["CPVal"] = this.data.premiumList[j];
        obj["EIVal"] = this.data.incomeList[j];
        j++;
        return obj;
      })
    }
    this.dataListLossLine = this.data.lossRatioCummulativePercentage.map((d) => {
      if (d > 0) return d
      else return null
    });
    this.dataListLossColumn = this.data.lossRatioPercentageList.map((d) => {
      // if (d > 0) return d
      // else return null
      return d;
    });
    var maxLossLineY = Math.max.apply(
      null,
      this.dataListLossLine.map(a => a)
    );
    var maxLossBarY = Math.max.apply(
      null,
      this.dataListLossColumn.map(a => a)
    );
    this.MaxofLossY = maxLossLineY > maxLossBarY ? Math.ceil(((120 * maxLossLineY) / 100)) : Math.ceil(((120 * maxLossBarY) / 100))
    this.plotLinesX = this.data.lossRatioPlanYearStartDateIndexList.map((da) => {
      var plotObj = {
        color: 'black',
        width: 2,
        value: da - 0.5,
        zIndex: 5,
        dashStyle: "Dot",
      }
      return plotObj
    });
    setTimeout(() => this.parentLoaded = true, 100);
  }
  constructor(
    private _dataService: DataService,
    private _translateService: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private elementRefClaimDiag: ElementRef,
    private httpClient: HttpClient,
    private dimensionOrderService: DimensionOrderService
  ) { }

  ngOnInit() {
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationSpanishCL,
      navigationSpanishCO,
      navigationSpanishEC,
      navigationSpanishMX,
      navigationSpanishPE,
      navigationPortugueseBR,
      navigationItalianIT,
    );
  }

  filterChanged(val, filterName: string) {
    this.action.emit({
      type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
      data: {
        properties: {
          name: filterName,
          value: typeof val === 'object' ? val.data.join('||') : val
        }
      }
    });
  }
  selectionChanged(val, filterName: string) {
    this.action.emit({
      type: PulseEventName.FILTLER_CHANGED,
      data: {
        properties: {
          name: filterName,
          value: val
        }
      }
    });
  }

  additionalFilterSelectionChanged(val) {
    this.action.emit(val);
  }
}
export enum ShowHideOption {
  Show = "Show",
  Hide = "Hide"
}
export enum ClaimPMPMOption {
  ClaimsTotal = "Claims Total",
  ClaimsPMPM = "Claims PMPM"
}
export enum LossRatioOption {
  LossRatio = "Loss Ratio",
  CombinedLR = "Combined LR"
}
export enum PeriodOption {
  Monthly = 1,
  YearBasis = 2,
  YearBasisC = 3,
  Rolling3 = 4,
  Rolling6 = 5,
  Rolling9 = 6,
  Rolling12 = 7,
}
