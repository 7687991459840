export const locale = {
	lang: 'pt-br',
	data: {
		'ClaimCostTopProvider': {
			'ClaimTopProviders': 'Sinistro - por Maiores Prestadores',
			'ClaimCostByTopDiagnoses': 'Sinistro - por Maiores Diagnósticos',
			'ProviderDiag': 'Prestador vs. Diagnóstico',
			'AllAmountsDisplayedInLocalCurrency': 'Todos os valores estão em moeda local',
			'TopProvider': 'Maiores Prestadores',
			'ProviderName': 'Nome do Prestador',
			'TopDiagnoses': 'Maiores Diagnósticos',
			'DiagnosisName': 'Nome do Diagnóstico',
			'TotalPaidClaim': 'Total de Sinistro',
			'ClaimantCount': 'Contagem de Usuário',
			'ClaimCount': 'Contagem de Sinistros',
			'ClaimsPerClaimant': 'Sinistro Por Usuário',
			'CostPerClaim': 'Custo Por Sinistro',
			'CostPerClaimant': 'Custo Por Usuário',
			'%ChangeinCost': 'Mudança Ano I vs. Ano II',
			'Claim': 'Sinistro',
			'Claimant': 'Usuário',
			'ClaimsTotal': 'Sinistro Total',
			'Count': 'Contar',
			'AverageCost': 'Custo Médio',
			'ClaimsReportedByPaidDate': 'Sinistro Reportado por Data de Pagamento',
			'ClaimsReportedByIncurredDate': 'Sinistro Reportado por Data de Atendimento',
			'Benchmark': 'Referência',
			'IncludeClient': 'Incluir Cliente',
			'ExcludeClient': 'Excluir Cliente',
			'Portfolio': 'Portfólio',
			'Industry': 'Indústria',
			'None': 'Nenhum',
			'SelectTop': 'Selecionar Maiores',
			'FilterNA': 'Excluir NA',
			'CountBasis': 'Base de Contagem',
			'Sort': 'Ordenar',
			'Reset': 'Limpar',
			'DiagnosisLevel': 'Nível de Diagnóstico',
			'Category': 'Categoria',
			'Chapter': 'Capítulo',
			'ShownAsPer': 'Mostrado como por',
			'DateRange': 'to'
		}
	}
};
