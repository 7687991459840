<h2 mat-dialog-title>Save 'User Inputs' & 'Key Insights'?</h2>
<mat-dialog-content>
    <form [formGroup]="saveForm">
        <mat-form-field appearance="fill">
            <input type="text" matInput formControlName='name'>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
  <button mat-button  color="accent" (click)="onSave()" [disabled]="isSaveDisabled" placeholder="Enter Save Name">Save</button>
</mat-dialog-actions>
