import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WellOneNotificationService {

  @Output() onNotifyingWellOneCacheRefresh: EventEmitter<void>;

  @Output() onStartingWellOneCacheRefresh: EventEmitter<void>;

  @Output() onCompletingWellOneCacheRefresh: EventEmitter<void>;

  constructor(private _httpClient: HttpClient) {
    this.onNotifyingWellOneCacheRefresh = new EventEmitter<void>();
    this.onStartingWellOneCacheRefresh = new EventEmitter<void>();
    this.onCompletingWellOneCacheRefresh = new EventEmitter<void>();
  }

  getWellOneDataRefreshStatus(): any {
    return this._httpClient.get<any>(environment.api + 'api/wellone/notification/cache/refresh/status/get');
  }
}
