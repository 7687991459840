export const locale = {
    lang: 'es-mx',
    data: {
        'ClaimsOverview': {
            'Prior': 'Vigencia Anterior',
            'Current': 'Vigencia Actual',
            'ClaimType': 'Causa',
            'Relationship': 'Parentesco',
            'Entity': 'Empresa / Filial',
            'Network': 'Network',
            'Location': 'Localización',
            'ClaimsOverview': 'Resumen',
            'TotalPaidClaim': 'Total pagado',
            'Total': 'Total',
            'NOOFCLAIMS': 'No de trámites',
            'NOOFCLAIMANTS': 'No de casos',
            'Claim': 'Trámite',
            'Claimant': 'Reclamante',
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'Count': 'Count',
            'ClaimsReportedByPaidDate': ' ',
            'ClaimsReportedByIncurredDate': ' ',
            'DateRange': 'to'
        }
    }
};
