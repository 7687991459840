import { Injectable } from '@angular/core';
import { formatNumber } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NumberFormattingService {

    constructor() {

    }

    public ToAbbrevNumberString(originalNumber: number, precision: number = 4, locale: string = 'en-US'): string {
        const numberScale = this.retrieveScaleForNumber(originalNumber);
        const numberAbbrev = this.retrieveAbbrevForScale(numberScale);
        
        if(precision < 1){
            precision = 1;
        }
        let shortenedNumberString = (originalNumber / numberScale).toPrecision(precision);

        if (locale) {
            shortenedNumberString = formatNumber(Number(shortenedNumberString), locale, '1.1-1');
        }

        return shortenedNumberString + numberAbbrev;
    }

    public ToAbbrevNumberStringLatem(originalNumber: number, precision: number = 5, locale: string = 'en-US'): string {
        const numberScale = this.retrieveScaleForNumberLatem(originalNumber);
        const numberAbbrev = this.retrieveAbbrevForScale(numberScale);

        if(precision < 1){
            precision = 1;
        }
        let shortenedNumberString = (originalNumber / numberScale).toPrecision(precision);

        if (locale) {
            shortenedNumberString = formatNumber(Number(shortenedNumberString), locale, '1.1-1');
        }

        return shortenedNumberString + numberAbbrev;
    }

    private retrieveScaleForNumber(originalNumber: number): NumberScale {
        if (originalNumber >= NumberScale.Billion) {
            return NumberScale.Billion;
        }

        if (originalNumber >= NumberScale.Million) {
            return NumberScale.Million;
        }

        if (originalNumber >= NumberScale.Thousand) {
            return NumberScale.Thousand;
        }

        return NumberScale.One;
    }

    private retrieveScaleForNumberLatem(originalNumber: number): LatemNumberScale {
        if (originalNumber >= LatemNumberScale.Billion) {
            return LatemNumberScale.Billion;
        }

        if (originalNumber >= LatemNumberScale.Million) {
            return LatemNumberScale.Million;
        }

        if (originalNumber >= LatemNumberScale.Thousand) {
            return LatemNumberScale.Thousand;
        }

        return LatemNumberScale.One;
    }

    private retrieveAbbrevForScale(numberScale: NumberScale|LatemNumberScale): NumberScaleAbbrev {
        switch (numberScale) {
            case NumberScale.Billion:
                return NumberScaleAbbrev.Billion;
            case NumberScale.Million:
                return NumberScaleAbbrev.Million;
            case NumberScale.Thousand:
                return NumberScaleAbbrev.Thousand;
            default:
                return NumberScaleAbbrev.One;
        }
    }
}

enum NumberScale {
    Billion = 1000000000,
    Million = 1000000,
    Thousand = 1000,
    One = 1
}

enum LatemNumberScale {
    Billion = 1000000000000,
    Million = 1000000,
    Thousand = 1000,
    One = 1
}

enum NumberScaleAbbrev {
    Billion = 'B',
    Million = 'M',
    Thousand = 'K',
    One = ''
}
