import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RESULTS_STATE } from 'app/constants/cost_projection';
import { CLAIM_BASIS } from 'app/models/basis.model';
import { CostProjectionInputs, Membership, MembershipBreakdown, TimePeriod } from 'app/models/cost-projection/cost-projection-inputs.model';
import { CostProjectionResults } from 'app/models/cost-projection/cost-projection-results.model';
import { CostProjectionService } from 'app/services/cost-projection.service';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import { pairwise, startWith } from 'rxjs/operators';
import { adjustmentValidatorGroup, monthsValidatorGroup, percentage100ValidatorGroup, percentageValidatorGroup, positiveNumberValidatorGroup } from './constants/cost-projection-validator-groups';
import { CostProjectionLoadDialogComponent } from './dialogs/cost-projection-load-dialog/cost-projection-load-dialog.component';
import { CostProjectionSaveDialogComponent } from './dialogs/cost-projection-save-dialog/cost-projection-save-dialog.component';
import { addMinutes } from 'date-fns';
import { CostProjectionClaimBasisMismatchDialogComponent } from './dialogs/cost-projection-claim-basis-mismatch-dialog/cost-projection-claim-basis-mismatch-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-cost-projection',
  templateUrl: './cost-projection.component.html',
  styleUrls: ['./cost-projection.component.scss']
})
export class CostProjectionComponent implements OnInit {
  public minDate: Date = new Date(moment(new Date()).subtract(10, 'year').toDate());
  public maxDate: Date = new Date();

  public resultsState = RESULTS_STATE.INITIAL;
  public RESULTS_STATE = RESULTS_STATE;

  private _user: any;
  private _initialInputForm: CostProjectionInputs;

  constructor(
    private _userService: UserService,
    private _fb: FormBuilder,
    private _costProjectionService: CostProjectionService,
    private _dialog: MatDialog,
    private _toasterService: ToasterService) { }

  // #region Input and Results Form Template
  public inputForm = this._fb.group({
    claimBasis: 0,
    timePeriod: this._fb.group({
      claimsPeriod: this._fb.group({
          start: [this.minDate, [Validators.required]],
          end: [this.maxDate, [Validators.required]]
      }),
      exclusionPeriod: this._fb.group({
        isApplicable: false,
        start: new Date(moment(this.minDate).add(1, 'M').toDate()),
        end: new Date(moment(this.maxDate).subtract(1, 'M').toDate())
      }),
      projectionPeriod: this._fb.group({
        start: [new Date(moment(this.minDate).add(1, 'M').toDate()), [Validators.required]],
        end: [new Date(moment(this.maxDate).subtract(1, 'M').toDate()), [Validators.required]]
      }),
      claimBasis: CLAIM_BASIS.PAID_BASIS,
      laggedMonths: [2, monthsValidatorGroup]
    }),
    membership: this._fb.group({
      historicalSumTotal: 0,
      projectedSumTotal: [0, positiveNumberValidatorGroup],
      projectedSumPercentage: [0, percentage100ValidatorGroup],
      currentPremiumPMPM: 0,
      historicalAdultLifeAdjustment: 0,
      projectedAdultLifeAdjustment: 0,
      adultLifeAdjustment: 0,
      breakdown: this._fb.array([])
    }),
    trendsAndAdjustments: this._fb.group({
      paidToIncurredTrendAssumption: this._fb.group({ 
        client: [5, percentageValidatorGroup],
        country: [5, percentageValidatorGroup],
        notes: ''
      }),
      forwardTrend: this._fb.group({ 
        client: [5, percentageValidatorGroup],
        country: [5, percentageValidatorGroup],
        notes: ''
      }),
      adjustments: this._fb.array([
        this._fb.group({ 
          client: [100, adjustmentValidatorGroup], 
          country: [100, adjustmentValidatorGroup], 
          notes: '' 
        })
      ]),
      credibility: this._fb.group({ 
        client: [90, percentageValidatorGroup], 
        country: 10, 
        notes: '' 
      })
    }),
    targetLossRatio: [85, percentageValidatorGroup]
  });

  public resultsForm = this._fb.group({
    insights: this._fb.group({
      pmpm: this._fb.group({
        premium: 111500,
        claims: 95719
      }),
      total: this._fb.group({
        premium: 3688420000,
        claims: 3166370794.97
      }),
      premiumNeeded: 3725142111.73,
      summary: this._fb.group({
        surplusShortfall: -36722112,
        premiumIncreaseNeeded: 1
      })
    }),
    historicalExperience: this._fb.group({
      historicalMembers: this._fb.group({
        client: 34400,
        country: 5160000
      }),
      historicalClaims: this._fb.group({
        client: 3247599898,
        country: 487139984751
      }),
      historicalOutliers: this._fb.group({
        client: 1025179936,
        country: 153776990400
      }),
      historicalExperienceExclHCC: this._fb.group({
        client: 2222419962,
        country: 333362994351
      }),
      historicalPMPM: this._fb.group({
        client: 64605,
        country: 64605
      }),
      adjustmentFromPaidToIncurred: this._fb.group({
        client: 65133,
        country: 65133
      })
    }),
    claimsProjection: this._fb.group({
      monthsToTrendForward: this._fb.group({
        client: 2,
        country: 2
      }),
      trendPMPM: this._fb.group({
        client: 65665.40,
        country: 65665.79
      }),
      adultLifeAdjustments: this._fb.group({
        client: 0,
        country: 0
      }),
      additionalAdjustments: this._fb.group({
        client: 0,
        country: 0
      }),
      adjustedPMPM	: this._fb.group({
        client: 0.978671,
        country: 1
      }),
      hccPMPM	: this._fb.group({
        client: [null, positiveNumberValidatorGroup],
        country: [null, positiveNumberValidatorGroup]
      }),
      finalPMPM	: this._fb.group({
        client: 65665.40,
        country: 65665.79
      }),
      credibility	: this._fb.group({
        client: 90,
        country: 10
      }),
      credibilityWeightedClaimPMPM	: this._fb.group({
        client: 86194,
        country: 9525
      }),
    })
  });
  // #endregion

  ngOnInit(): void {
    this._user = this._userService.user;
    this._retrieveCostProjectionInput();
    this._initUserOptionsChangeSubscription();
  }

  // #region General - Init User Options Change Subscription

  private _initUserOptionsChangeSubscription(): void {
    this._userService.userChanged
    .pipe(untilDestroyed(this))
    .subscribe(() => {
      this._user = this._userService.user;
      this._retrieveCostProjectionInput();
    });
  }

  // #endregion
  
  // #region General - Save and Load Functionalities

  public onSave(): void {
    if (this.inputForm.invalid || this.resultsForm.invalid) {
      return;
    }

    const saveDialogRef = this._dialog.open(CostProjectionSaveDialogComponent, <MatDialogConfig>{
      disableClose: true,
      width: '600px'
    });

    saveDialogRef.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result: any) => {
        if (!result) {
          return;
        }

        this._costProjectionService
          .saveUserCostProjection(
            result.name, 
            this._user.client.tier1Id, 
            this._user.country.tier2Id, 
            <CostProjectionInputs> this.inputForm.value, 
            <CostProjectionResults> this.resultsForm.value)
          .subscribe(() => {
            this._toasterService.showMessage('Scenario Saved Successfully', ToasterServiceModes.OK);
          });
      });
  }

  public onLoad(): void {
    const loadDialogRef = this._dialog.open(CostProjectionLoadDialogComponent, <MatDialogConfig>{
      disableClose: true,
      width: '600px'
    });

    loadDialogRef.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((rowData: any) => {
        if (!rowData) {
          return;
        }

        rowData.inputs = <CostProjectionInputs>JSON.parse(rowData.inputs);

        // Check if User Saved's Claim Basis is same as User Setting's Claim Basis
        if (this._user.claimPaid !== rowData.inputs.claimBasis) {
          this._notifyLoadedCostProjectionClaimBasisMismatch(
            this._user.claimPaid, 
            rowData.inputs.claimBasis
          );
          return;
        }

        // Format All Dates upon Loading 
        rowData.inputs.timePeriod.claimsPeriod.start = this._formatUtcDate(rowData.inputs.timePeriod.claimsPeriod.start);
        rowData.inputs.timePeriod.claimsPeriod.end = this._formatUtcDate(rowData.inputs.timePeriod.claimsPeriod.end);

        rowData.inputs.timePeriod.exclusionPeriod.start = this._formatUtcDate(rowData.inputs.timePeriod.exclusionPeriod.start);
        rowData.inputs.timePeriod.exclusionPeriod.end = this._formatUtcDate(rowData.inputs.timePeriod.exclusionPeriod.end);

        rowData.inputs.timePeriod.projectionPeriod.start = this._formatUtcDate(rowData.inputs.timePeriod.projectionPeriod.start);
        rowData.inputs.timePeriod.projectionPeriod.end = this._formatUtcDate(rowData.inputs.timePeriod.projectionPeriod.end);

        // Populate Inputs and Results Form
        this._initInputForm(rowData.inputs);
        this.resultsForm.patchValue(JSON.parse(rowData.results), { onlySelf: true, emitEvent: false });

        this.resultsState = RESULTS_STATE.LOADED;
      }, () => {
        this.resultsState = RESULTS_STATE.ERROR;
      });
  }

  public get isSaveShown(): boolean {
    return this.resultsState === this.RESULTS_STATE.LOADED;
  }

  public get isSaveDisabled(): boolean {
    return this.inputForm.invalid || 
    this.resultsForm.invalid;
  }

  private _notifyLoadedCostProjectionClaimBasisMismatch(userClaimBasis: string, savedClaimBasis: string): void {
    this._dialog.open(CostProjectionClaimBasisMismatchDialogComponent, <MatDialogConfig>{
      disableClose: true,
      width: '600px',
      data: { userClaimBasis, savedClaimBasis }
    });
  }

  // #endregion

  // #region General - Download Hcc File Functionality

  public onDownloadHccFile(): void {
    if (this.inputForm.invalid) {
      return;
    }

    this._costProjectionService.retrieveCostProjectionCalcFile(
      this._user.client.tier1Id, 
      this._user.country.tier2Id, 
      this.inputForm.value,
      this.resultsForm.value
    ).subscribe();
  }

  public get isDownloadHccFileShown(): boolean {
    return this.inputForm.valid && this.resultsState === this.RESULTS_STATE.LOADED;
  }

  // #endregion

  // #region Input Form - Retrieve and Init Data

  private _retrieveCostProjectionInput(): void {
    if (!this._user.client || !this._user.country) {
      return;
    }

    this._costProjectionService.retrieveCostProjectionInputs(
      this._user.client.tier1Id, 
      this._user.country.tier2Id,
      this._user.claimPaid,
      this._user.timePeriod.maxDate)
      .subscribe((results: CostProjectionInputs) => {
        this._formatInitialTimePeriodDates(results.timePeriod);
        this._initInputForm(results);
        this._initTimePeriodTableSubscriptions();
      });
  }

  private _formatInitialTimePeriodDates(timePeriod: TimePeriod): void {
    timePeriod.claimsPeriod.end = this._formatUtcDate(timePeriod.claimsPeriod.end);
    timePeriod.exclusionPeriod.end = this._formatUtcDate(timePeriod.exclusionPeriod.end);
    timePeriod.projectionPeriod.end = this._formatUtcDate(timePeriod.projectionPeriod.end);
  }

  
  private _formatUtcDate(dateString: string): string {
    const date = new Date(dateString);
    return (addMinutes(date, date.getTimezoneOffset())).toISOString();
  }

  private _initInputForm(inputFormModel: CostProjectionInputs): void {
    this._initialInputForm = {...inputFormModel};

    this._initInputFormMembershipBreakdowns(inputFormModel.membership.breakdown);
    this._initInputFormAdjustments(inputFormModel.trendsAndAdjustments.adjustments);
    this.inputForm.setValue(inputFormModel, { onlySelf: true, emitEvent: false });
  }

  // #endregion

  // #region Input Form - Events - onUserReset, onUserUpdate, onUserSubmit

  public onUserResetInputForm(): void {
    this.resultsState = this.RESULTS_STATE.INITIAL;
    this._initInputForm(this._initialInputForm);
  }

  public onUserUpdateInputForm(): void {
    if (this.resultsState === this.RESULTS_STATE.CALCULATING || 
      this.resultsState === this.RESULTS_STATE.LOADED) {
        this.resultsState = this.RESULTS_STATE.RECALCULATION_REQUIRED;
      }
  }

  public onUserSubmitInputForm(): void {
    this.resultsState = this.RESULTS_STATE.CALCULATING;

    this._costProjectionService.computeCostProjectionResults(
      this._user.client.tier1Id, 
      this._user.country.tier2Id,
      <CostProjectionInputs> this.inputForm.value
    )
    .subscribe((results: CostProjectionResults) => {
      this.resultsForm.patchValue(results, { onlySelf: true, emitEvent: false });
      this.resultsState = this.RESULTS_STATE.LOADED;
    });
  }

  // #endregion

  // #region Input Form - Time Periods

  // To retrieve and update updated membership breakdown upon change to time period section
  private _initTimePeriodTableSubscriptions(): void {
    this.inputForm.get('timePeriod').valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(() => {
      this._validateTimePeriods();

      if (this.inputForm.get('timePeriod').valid) {
        this._costProjectionService.retrieveCostProjectionInputsMembershipBreakdown(
          this._user.client.tier1Id, 
          this._user.country.tier2Id,
          <TimePeriod> this.inputForm.get('timePeriod').value
        ).subscribe((results: Membership) => {
          this.inputForm.get('membership').patchValue(results, { onlySelf: true, emitEvent: false });
          this._initInputFormMembershipBreakdowns(results.breakdown);
        });
      }
    });
  }

  private _validateTimePeriods(): void {
    const claimsPeriodStartControl = this.inputForm.get('timePeriod.claimsPeriod.start');
    const claimsPeriodEndControl = this.inputForm.get('timePeriod.claimsPeriod.end');
    const exclusionPeriodApplicablicability = this.inputForm.get('timePeriod.exclusionPeriod.isApplicable');
    const exclusionPeriodStartControl = this.inputForm.get('timePeriod.exclusionPeriod.start');
    const exclusionPeriodEndControl = this.inputForm.get('timePeriod.exclusionPeriod.end');
    const projectionPeriodStartControl = this.inputForm.get('timePeriod.projectionPeriod.start');
    const projectionPeriodEndControl = this.inputForm.get('timePeriod.projectionPeriod.end');

    // To clear all errors for time periods (start & end dates)
    claimsPeriodStartControl.setErrors(null);
    claimsPeriodEndControl.setErrors(null);
    exclusionPeriodStartControl.setErrors(null);
    exclusionPeriodEndControl.setErrors(null);
    projectionPeriodStartControl.setErrors(null);
    projectionPeriodEndControl.setErrors(null);

    // To validate claims period start date is earlier than its end date 
    if (moment(claimsPeriodStartControl.value).toDate().getTime() > moment(claimsPeriodEndControl.value).toDate().getTime()) {
      claimsPeriodStartControl.setErrors({ 'invalidDate': true });
      claimsPeriodEndControl.setErrors({ 'invalidDate': true });
    }

    // If exclusion period is applicable
    if (exclusionPeriodApplicablicability.value) {
      // To validate exclusion period start date is ealier than its end date 
      if (moment(exclusionPeriodStartControl.value).toDate().getTime() > moment(exclusionPeriodEndControl.value).toDate().getTime()) {
        exclusionPeriodStartControl.setErrors({ 'invalidDate': true });
        exclusionPeriodEndControl.setErrors({ 'invalidDate': true });
      }

      // To validate exclusion period start date is later than claims period start date
      if (moment(exclusionPeriodStartControl.value).toDate().getTime() <= moment(claimsPeriodStartControl.value).toDate().getTime()) {
        exclusionPeriodStartControl.setErrors({ 'invalidDate': true });
      }

      // To validate exclusion period end date is earlier than claims period end date
      if (moment(exclusionPeriodEndControl.value).toDate().getTime() >= moment(claimsPeriodEndControl.value).toDate().getTime()) {
        exclusionPeriodEndControl.setErrors({ 'invalidDate': true });
      }
    }

    // To validate projection period start date is earlier than its end date 
    if (moment(projectionPeriodStartControl.value).toDate().getTime() > moment(projectionPeriodEndControl.value).toDate().getTime()) {
      projectionPeriodStartControl.setErrors({ 'invalidDate': true });
      projectionPeriodEndControl.setErrors({ 'invalidDate': true });
    }

    // To validate projection period start date is later than claims period end date 
    if (moment(projectionPeriodStartControl.value).toDate().getTime() <= moment(claimsPeriodStartControl.value).toDate().getTime()) {
      projectionPeriodStartControl.setErrors({ 'invalidDate': true });
    }
  }

  // #endregion

  // #region Input Form - Membership

  private _initInputFormMembershipBreakdowns(membershipBreakdowns: MembershipBreakdown[]): void {
    const membershipBreakdownFormArray = (<FormArray>this.inputForm.get('membership.breakdown'));
    
    // Remove all elements in form array
    while (membershipBreakdownFormArray.length) {
      membershipBreakdownFormArray.removeAt(0);
    }

    // Patch updated values
    membershipBreakdownFormArray.patchValue(membershipBreakdowns, { onlySelf: true, emitEvent: false });

    // Update each element's validators
    membershipBreakdowns.forEach(membershipBreakdown => {
      membershipBreakdownFormArray.push(
        this._fb.group({ 
          name: membershipBreakdown.name, 
          historicalTotal: membershipBreakdown.historicalTotal, 
          historicalPercentage: membershipBreakdown.historicalPercentage, 
          historicalAdultLifeFactor: membershipBreakdown.historicalAdultLifeFactor,
          projectedTotal: [membershipBreakdown.projectedTotal, [Validators.required, Validators.min(0)]], 
          originalProjectedTotal: membershipBreakdown.originalProjectedTotal,
          projectedPercentage: [membershipBreakdown.projectedPercentage, percentageValidatorGroup],
          projectedTotalPremium: membershipBreakdown.projectedTotalPremium,
          projectedAdultLifeFactor: membershipBreakdown.projectedAdultLifeFactor
        })
      );
    });

    this._initMembershipTableSubscriptions();
  }

  private _initMembershipTableSubscriptions(): void {
    this.inputForm.get('membership.projectedSumTotal').valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(projectedSumTotal => {
      // To cater to total membership input change
      this._computeMembershipTableProjectedSumTotalChange(projectedSumTotal);
    });

    const membershipBreakdownControls = (<FormArray>this.inputForm.get('membership.breakdown')).controls;
    for (let i = 0; i < membershipBreakdownControls.length; i++) {
      membershipBreakdownControls[i].valueChanges
      .pipe(startWith(membershipBreakdownControls[i].value), pairwise(), untilDestroyed(this))
      .subscribe(([prev, next]: [any, any]) => {
        // To cater to membership row's membership - percentage input change
        if (prev.projectedPercentage !== next.projectedPercentage) {
          this._computeMembershipTableProjectedPercentageChange(next);
          return;
        }

        // To cater to membership row's membership - total member input change
        this._computeMembershipTableProjectedTotalChange(next);
      });
    }
  }

  // To cater to total membership input change
  private _computeMembershipTableProjectedSumTotalChange(projectedSumTotal: number): void {
    const membershipBreakdownControls = (<FormArray>this.inputForm.get('membership.breakdown')).controls;
    membershipBreakdownControls.forEach(membershipBreakdownControl => {
      const projectedPercentage = membershipBreakdownControl.get('projectedPercentage')?.value;
      const newProjectedTotal = projectedSumTotal * (projectedPercentage / 100);
      membershipBreakdownControl.patchValue({ projectedTotal: Math.ceil(newProjectedTotal) }, { onlySelf: true, emitEvent: false });
    });
  }

  // To cater to membership row's membership - percentage input change
  private _computeMembershipTableProjectedPercentageChange(next: any): void {
    const projectedBreakdownSumTotal = this.inputForm.get('membership.projectedSumTotal').value;
    const membershipBreakdownControls = (<FormArray>this.inputForm.get('membership.breakdown')).controls;
    const rowAffectedControl = membershipBreakdownControls.find(ctrl => ctrl.value.name === next.name);

    // Update row projected total
    const newProjectedPercentage = next?.projectedPercentage;
    const newProjectedTotal = projectedBreakdownSumTotal * (newProjectedPercentage / 100);
    rowAffectedControl.patchValue({ projectedTotal: Math.ceil(newProjectedTotal) }, { onlySelf: true, emitEvent: false });

    this._updateProjectedProjectedSumPercentage();
  } 

  private _updateProjectedProjectedSumPercentage(): void {
    let newProjectedSumPercentage = 0;

    const membershipBreakdownControls = (<FormArray>this.inputForm.get('membership.breakdown')).controls;
    membershipBreakdownControls.forEach(membershipBreakdownControl => {
      newProjectedSumPercentage += membershipBreakdownControl.value?.projectedPercentage;
    });

    this.inputForm.get('membership').patchValue({
        projectedSumPercentage: Math.round(newProjectedSumPercentage * 100) / 100
    }, {
        onlySelf: true, emitEvent: false
    });
  }

  // To cater to membership row's membership - total member input change
  private _computeMembershipTableProjectedTotalChange(next: any): void {
    const projectedBreakdownSumPercentage = this.inputForm.get('membership.projectedSumPercentage').value;

    if (Math.round(projectedBreakdownSumPercentage) !== 100) {
      return;
    }

    // update all membership rows' total portionally
    const membershipBreakdownControls = (<FormArray>this.inputForm.get('membership.breakdown')).controls;
    membershipBreakdownControls.forEach(membershipBreakdownControl => {
      const newProjectedTotal = membershipBreakdownControl.get('projectedPercentage')?.value 
      * (next.projectedTotal / next.projectedPercentage);
      membershipBreakdownControl.patchValue({ projectedTotal: Math.ceil(newProjectedTotal) }, { onlySelf: true, emitEvent: false });
    });

    this._updateProjectedProjectedSumTotal();
  }

  private _updateProjectedProjectedSumTotal(): void {
    let newProjectedSumTotal = 0;

    const membershipBreakdownControls = (<FormArray>this.inputForm.get('membership.breakdown')).controls;
    membershipBreakdownControls.forEach(membershipBreakdownControl => {
      newProjectedSumTotal += membershipBreakdownControl.value?.projectedTotal;
    });

    this.inputForm.get('membership').patchValue({ projectedSumTotal: Math.ceil(newProjectedSumTotal) }, { onlySelf: true, emitEvent: false });
  }

  // #endregion

  // #region Input Form - Adjustments

  private _initInputFormAdjustments(adjustments: any[]): void {
    const adjustmentsFormArray = (<FormArray>this.inputForm.get('trendsAndAdjustments.adjustments'));
    
    // Remove all elements in form array
    while (adjustmentsFormArray.length) {
      adjustmentsFormArray.removeAt(0);
    }

    // Patch updated values
    adjustmentsFormArray.patchValue(adjustments, { onlySelf: true, emitEvent: false });

    // Update each element's validators
    adjustments.forEach(adjustment => {
      adjustmentsFormArray.push(
        this._fb.group({ 
          client: [adjustment.client, [Validators.required, Validators.min(0), Validators.max(300)]], 
          country: [adjustment.country, [Validators.required, Validators.min(0), Validators.max(300)]], 
          notes: adjustment.notes
        })
      );
    });
  }

  // #endregion

}

