export const locale = {
    lang: 'en',
    data: {
        'ExportCart': {
            'ExportCart': 'Export Cart',
            'CanViewDeleteAddedDashboardsHere': 'Can view/delete added dashboards here',
            'Dashboards': 'Dashboards',
            'ExportAll': 'Export All',
            'ClearAll': 'Clear All',
            'Name': 'Name',
            'Export': 'Export',
            'ExportTitle': 'Export Title',
            'Preview': 'Preview',
            'Comments': 'Comments',
            'FiltersSelected': 'Filter(s) Selected',
            'NoFilterSelected': 'No Filter Selected',
            'AdditionalFiltersSelected': 'Additional Filters Selected',
            'FiltersMightNotApply': 'Filters Might Not Apply'
        }
    }
};