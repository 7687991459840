import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { AdminService } from 'app/services/admin.service';
import { UserService } from 'app/services/user.service';
import { User } from 'app/models/user.model'
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as navigationEnglish } from 'app/admin/useradmin/i18n/en';
import { locale as navigationSpanishCL } from 'app/admin/useradmin/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/admin/useradmin/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/admin/useradmin/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/admin/useradmin/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/admin/useradmin/i18n/es-pe';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { List } from 'linqts';
import { Router } from '@angular/router';
import { WelloneDashboardService } from 'app/wellone/services/dashboard.service';
import { environment } from 'environments/environment';
import { GlobalFilterService } from 'app/wellone/services/globalfilter.service';
import { DashboardGraphModel, HealthScoreModel, FinancialModel, ParticipationModel, DashboardModel } from 'app/wellone/models/dashboard.model';
import { WelloneParameterModel } from 'app/wellone/models/welloneparameter.model';
import { WelloneConstant } from 'app/wellone/models/welloneconstant.model';
import { WelloneService } from 'app/wellone/services/wellone.service';

@Component({
  selector: 'app-wellone-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {


  dashboardResult: DashboardModel;
  isLoading: boolean = false;
  user: User;
  newLayoutUrl: string;
  errorMsg: string;
  colorindex: number = 3;
  particiationcolorindex: number = 7;
  healthscorecolorindex: number = 4;
  financialcolorindex: number = 2;
  dashboardParams: WelloneParameterModel;
  chartxaxisType: string = "d";
  chartxaxisNumber: number = 1
  healthchartxaxisformat: string = "%d-%b";
  financialchartxaxisformat: string = "%d-%b";
  participationchartxaxisformat: string = "%d-%b";
  participationYaxisNumber: number;
  selectedChallengeTabValue: string = 'FEATURED';
  healthScoreWidth = "600px";
  xaxisStart: number = 1;
  cardWidth : string = "100%";
  participationChartWidth:string  ='100%';
  selectedChallengeId:string;
  participationYaxisTitle: string = "Rate (%)";
  constructor(private dashboardService: WelloneDashboardService, private userService: UserService,
    private globalFilterService: GlobalFilterService,
    private _router: Router,
    private toasterService: ToasterService,
    private _welloneService: WelloneService
  ) {
    this.newLayoutUrl = '/wellone/';
  }



  ngOnInit() {
    this.user = this.userService.getCurrentUser();
    this.getDashboardResult();

    // user change event
    this.userService.userChanged.subscribe(user => {
      this.user = this.userService.getCurrentUser();
      if (this.user) {
        if (this.user.client) {
          this.getDashboardResult();
        }
      }
    });


    // filter change
    this.globalFilterService.filterApplyClick.subscribe(res => {
      this.getDashboardResult();
    });


  }


  private getDashboardResult() {
    this.errorMsg = undefined;
    let beforeDate: Date = new Date();
    this.isLoading = true;

    this.dashboardParams = this._welloneService.getParamInfo();
    this.dashboardResult = null;
    console.log('Dashboard  Request Params : ' + this.dashboardParams.Tenant_record_id);
    //let dashboardParams = { 'Tenant_record_id': tennatId, 'Logged_uid': this.user.userName };
    this.dashboardService.getDashboardResult(this.dashboardParams).toPromise().then((res: any) => {
      {
        this.isLoading = false;
        if (res.status === WelloneConstant.Success) {
          let dashboardResult = JSON.parse(JSON.stringify(res.result));
          dashboardResult.healthScoreResult.healthScoreList.forEach(row => {
            row.name = new Date(row.name);
            row.value = parseFloat(row.value.toFixed(2));
          });

          dashboardResult.healthScoreResult.healthScore = Math.round(dashboardResult.healthScoreResult.healthScore);
          dashboardResult.healthScoreResult.customTickArr = this.getHealthScoreCustomTickArr(dashboardResult);
          dashboardResult.financialResult.financialAvgScore = Math.round(dashboardResult.financialResult.financialAvgScore);
          dashboardResult.financialResult.financialScoreList.forEach(row => {
            row.name = new Date(row.name);
            row.value = parseFloat(row.value.toFixed(2));
          });
          dashboardResult.financialResult.customTickArr = this.getFinancialScoreCustomTickArr(dashboardResult);
          dashboardResult.participationResult.participationPercentage = Math.round(dashboardResult.participationResult.participationPercentage);
          dashboardResult.participationResult.participationList.forEach(row => {
            row.name = new Date(row.name);
            row.value = parseFloat(row.value.toFixed(2));
          });
          dashboardResult.participationResult.customTickArr = this.getParticipationScoreCustomTickArr(dashboardResult);
          this.participationYaxisNumber = new List<any>(dashboardResult.participationResult.participationList).Max(x => x.value);
          this.participationYaxisNumber = Math.round(this.participationYaxisNumber) + 20;
          if (this.participationYaxisNumber > 100) {
            this.participationYaxisNumber = 100;
          }


          this.dashboardResult = dashboardResult;
        }
        else {
          this.errorMsg = res.message;
        }


        var afterDate: Date = new Date();
        var diff = (beforeDate.getTime() - afterDate.getTime());
        console.log('lapse in millisecond ' + diff);
        var lapseInSecond = (diff / 1000);
        console.log('lapse in second ' + lapseInSecond);
      }
    }).catch(error => {
      this.isLoading = false;
      this.errorMsg = error.message;
      console.log(error);
    });

  }

  private addDays(scoreDate: Date, days: number): Date {
    var result = new Date(scoreDate);
    result.setDate(result.getDate() + days);
    return result;
  }

  private getTickSize(dashboardResult: any): any {
    var result = { healthChartTickSize: 7, financialChartTickSize: 6, participationChartTickSize: 6 };
    switch (this.dashboardParams.GlobalFilters.TimePeriod) {
      case WelloneConstant.Week:
        result.healthChartTickSize = 7;
        result.financialChartTickSize = 7;
        result.participationChartTickSize = 7;
        break;
      case WelloneConstant.Month:
        result.healthChartTickSize = 5;
        result.financialChartTickSize = 5;
        result.participationChartTickSize = 5;
        break;
      case WelloneConstant.Quarter:
        result.healthChartTickSize = 6;
        result.financialChartTickSize = 6;
        result.participationChartTickSize = 6;
        break;
      case WelloneConstant.All:
        var monthNum = Math.round(dashboardResult.healthScoreResult.healthScoreList.length / 30);
        if (monthNum <= 12) {
          result.healthChartTickSize = (monthNum > 3) ? 5 : 6;
          result.financialChartTickSize = (monthNum > 3) ? 5 : 4;
        }
        if (monthNum > 12) {
          result.healthChartTickSize = 6;
          result.financialChartTickSize = 6;
        }

        monthNum = Math.round(dashboardResult.participationResult.participationList.length / 30);
        if (monthNum <= 12) {
          result.participationChartTickSize = (monthNum > 3) ? 5 : 6;
        }
        if (monthNum > 12) {
          result.participationChartTickSize = 6;
        }
        break;
      default:
        result.healthChartTickSize = 7;
        result.financialChartTickSize = 7;
        result.participationChartTickSize = 7;
        break;
    }
    return result;
  }

  private getHealthScoreCustomTickArr(data: any): any[] {
    var dashboardResult = JSON.parse(JSON.stringify(data))
    let result: any[] = [];
    let tickDetail = this.getTickSize(dashboardResult);
    let healthScoreArr = new List<any>(dashboardResult.healthScoreResult.healthScoreList).OrderByDescending(x => x.name).ToArray();
    let days = Math.round(healthScoreArr.length / tickDetail.healthChartTickSize);
    if (healthScoreArr !== null && healthScoreArr.length >= 7) {
      var scoreDate = new Date(healthScoreArr[0].name);
      result.push(scoreDate);
      var count = 0;
      var tickSize = 0;
      while (tickSize < (tickDetail.healthChartTickSize - 1)) {
        result.push(this.addDays(scoreDate, -1 * days));
        count = count + days;
        scoreDate = healthScoreArr[count].name;
        tickSize = tickSize + 1;
      }
      result = new List<any>(result).OrderBy(x => x).ToArray();
    }

    var years = new List<any>(healthScoreArr).Select(x => (new Date(x.name)).getFullYear()).Distinct().ToArray();
    this.healthchartxaxisformat = years.length === 1 ? '%d-%b' : '%b-%y'

    if (days === 1) {
      result.splice(0, 1);
    }

    return result;
  }

  private getFinancialScoreCustomTickArr(data: any): any[] {
    var dashboardResult = JSON.parse(JSON.stringify(data))
    let result: any[] = [];
    let tickDetail = this.getTickSize(dashboardResult);
    let financialScoreList = new List<any>(dashboardResult.financialResult.financialScoreList).OrderByDescending(x => x.name).ToArray();
    let days = Math.round(financialScoreList.length / tickDetail.financialChartTickSize);
    if (financialScoreList !== null && financialScoreList.length >= 7) {
      var scoreDate = new Date(financialScoreList[0].name);
      result.push(scoreDate);
      var count = 0;
      var tickSize = 0;
      while (tickSize < (tickDetail.financialChartTickSize - 1)) {
        result.push(this.addDays(scoreDate, -1 * days));
        count = count + days;
        scoreDate = financialScoreList[count].name;
        tickSize = tickSize + 1;
      }
      result = new List<any>(result).OrderBy(x => x).ToArray();
    }

    var years = new List<any>(financialScoreList).Select(x => (new Date(x.name)).getFullYear()).Distinct().ToArray();
    this.financialchartxaxisformat = years.length === 1 ? '%d-%b' : '%b-%y';

    if (days === 1) {
      result.splice(0, 1);
    }

    return result;
  }

  private getParticipationScoreCustomTickArr(data: any): any[] {
    var dashboardResult = JSON.parse(JSON.stringify(data))
    let result: any[] = [];
    let tickDetail = this.getTickSize(dashboardResult);
    let participationList = new List<any>(dashboardResult.participationResult.participationList).OrderByDescending(x => x.name).ToArray();
    let days = Math.round(participationList.length / tickDetail.participationChartTickSize);
    if (participationList !== null && participationList.length >= 7) {
      var scoreDate = new Date(participationList[0].name);
      result.push(scoreDate);
      var count = 0;
      var tickSize = 0;
      while (tickSize < (tickDetail.participationChartTickSize - 1)) {
        result.push(this.addDays(scoreDate, -1 * days));
        count = count + days;
        scoreDate = participationList[count].name;
        tickSize = tickSize + 1;
      }
      result = new List<any>(result).OrderBy(x => x).ToArray();
    }

    var years = new List<any>(participationList).Select(x => (new Date(x.name)).getFullYear()).Distinct().ToArray();
    this.participationchartxaxisformat = years.length === 1 ? '%d-%b' : '%b-%y'

    if (days === 1) {
      result.splice(0, 1);
    }

    return result;
  }

  healthScoreClick(event): void {
    this.scrollToTop();
    this._router.navigate([this.newLayoutUrl + 'health/score/detail']);
  }

  stepUpChallengeTabChange(event): void {
    this.scrollToTop();
    this.selectedChallengeTabValue = event.detail.trim();
  }

  stepUpChallengeClick(event): void {
    this.scrollToTop();
    this._router.navigate([this.newLayoutUrl + 'challenge/detail'], { queryParams: { id: event.id } });
  }

  redirectToParticipation() {
    this.scrollToTop();
    this._router.navigate([this.newLayoutUrl + 'participation']);
  }

  redirectToFinancialScore() {
    this.scrollToTop();
    this._router.navigate([this.newLayoutUrl + 'financial/score']);
  }

  scrollToTop() {
    document.querySelector('.aon-layout-content').scrollTo(0, 0)
  }

  formatDate(date: string) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const newDate = new Date(Date.parse(date));
    return newDate.getDate() + ' ' + months[newDate.getMonth()] + ', ' + newDate.getFullYear();
  }

  navigateChallengePage(event:any) : void
  {
    if(this.selectedChallengeId)
    {
      this.scrollToTop();
      this._router.navigate([this.newLayoutUrl + 'challenge/detail'], { queryParams: { id: this.selectedChallengeId } });
    }
    
  }

  onChallengeSelection(event:any) : void{
    this.selectedChallengeId = event.detail.trim();
  }

}
