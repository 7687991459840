import { Component, OnInit } from '@angular/core';
import { locale as navigationEnglish } from 'app/admin/clientgroupadmin/i18n/en';
import { locale as navigationSpanishCL } from 'app/admin/clientgroupadmin/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/admin/clientgroupadmin/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/admin/clientgroupadmin/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/admin/clientgroupadmin/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/admin/clientgroupadmin/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/admin/clientgroupadmin/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/admin/clientgroupadmin/i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AdminService } from 'app/services/admin.service';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { TranslateService , LangChangeEvent} from '@ngx-translate/core';

@Component({
  selector: 'app-clientgroupadmin',
  templateUrl: './clientgroupadmin.component.html',
  styleUrls: ['./clientgroupadmin.component.css']
})
export class ClientgroupadminComponent implements OnInit {

  constructor(
      private _translateService: TranslateService,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private _adminService: AdminService,
      private _toasterService: ToasterService) { }

  clientGroupColumnDefs: any[];
  clientColumnDefs: any[];

  isClientGroupListLoaded: boolean;
  isAddingClientGroup: boolean;
  isSavingClientGroupName: boolean;
  isClientListLoaded: boolean;

  newClientGroupName: string;
  selectedClientGroup: any;
  clientGroupList: any[];

  selectedClientFilterString: string;
  unselectedClientFilterString: string;

  filteredSelectedClientList: any[];
  filteredUnselectedClientList: any[];

  selectedClientList: any[];
  unselectedClientList: any[];

  ngOnInit() {
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationSpanishCL,
      navigationSpanishCO,
      navigationSpanishEC,
      navigationSpanishMX,
      navigationSpanishPE,
      navigationPortugueseBR, 
      navigationItalianIT,
    );

    this.clientGroupColumnDefs = [];
    this.clientColumnDefs = [];

    this.isClientGroupListLoaded = false;
    this.isAddingClientGroup = false;
    this.isSavingClientGroupName = false;
    this.isClientListLoaded = false;

    this.newClientGroupName = '';
    this.selectedClientGroup = null;
    this.clientGroupList = [];

    this.selectedClientFilterString = '';
    this.unselectedClientFilterString = '';

    this.filteredSelectedClientList = [];
    this.filteredUnselectedClientList = [];

    this.selectedClientList = [];
    this.unselectedClientList = [];

    this.getClientGroupList();

    this.setClientGroupColumnDefs();
    this.setClientColumnDefs();

    this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.setClientGroupColumnDefs();
        this.setClientColumnDefs();
    });
  }

  private setClientGroupColumnDefs(): void {
    this.clientGroupColumnDefs = [
        { headerName: this._translateService.instant('Name'), field: 'name', sort: 'asc' }
    ];
  }

  private setClientColumnDefs(): void {
    this.clientColumnDefs = [
        { headerName: this._translateService.instant('Name'), field: 'name', sort: 'asc' }
    ];
  }

  private getClientGroupList(): void {
      this.isClientGroupListLoaded = false;
      this._adminService.getClientGroups().toPromise().then(clientGroupList => {
        this.clientGroupList = clientGroupList;
        this.isClientGroupListLoaded = true;
      });
  }

  onAddClientGroup(): void {
    this.isAddingClientGroup = true;
  }

  onSaveAddedClientGroup(): void {
    this._adminService.saveClientGroup(this.newClientGroupName).toPromise().then((result: any) => {
        this.getClientGroupList();
        this._toasterService.showMessage(result, ToasterServiceModes.OK);
    }).catch((error: any) => {
        this._toasterService.showMessage(error.error, ToasterServiceModes.NOK);        
    }).finally(() => {
        this.isAddingClientGroup = false;
    });

    this.getClientGroupList();
  }

  onDeleteClientGroup($event): void {
    this._adminService.deleteClientGroup($event.data.tier1Id).toPromise().then((result: any) => {
        this.selectedClientGroup = null;
        this._toasterService.showMessage(result, ToasterServiceModes.OK);
    }).catch((error: any) => {
        this._toasterService.showMessage(error.error, ToasterServiceModes.NOK);        
    }).finally(() => {
        this.getClientGroupList();
    });
  }

  onClientGroupSelectionChanged($event): void {
    this.isClientListLoaded = false;
    this.selectedClientGroup = $event.data;

    this.filteredSelectedClientList = [];
    this.filteredUnselectedClientList = [];

    this.selectedClientList = [];
    this.unselectedClientList = [];

    this.populateSelectedGroupClientList(this.selectedClientGroup.tier1Id);
  }

  private populateSelectedGroupClientList(tier1Id: number): void {
      this._adminService.getGroupClientList(tier1Id).toPromise().then((result: any) => {
        this.filteredSelectedClientList = [...result.selectedGroupClientList];
        this.filteredUnselectedClientList = [...result.unselectedGroupClientList];

        this.selectedClientList = [...result.selectedGroupClientList];
        this.unselectedClientList = [...result.unselectedGroupClientList];
      }).catch((error: any) => {
        this._toasterService.showMessage(error.error, ToasterServiceModes.NOK);
      }).finally(() => {
        this.isClientListLoaded = true;
      });
  }

  onSelectGroupClient(selectedClient): void {
      let indexToBeRemoved = this.filteredUnselectedClientList.indexOf(selectedClient);
      this.filteredUnselectedClientList.splice(indexToBeRemoved, 1);
      this.filteredSelectedClientList.push(selectedClient);

      indexToBeRemoved = this.unselectedClientList.findIndex((unselectedClient) => unselectedClient.tier1Id === selectedClient.tier1Id); 
      
      this.unselectedClientList.splice(indexToBeRemoved, 1);
      this.selectedClientList.push(selectedClient);

      this.onFilterSelectedClientList();
      this.onFilterUnselectedClientList();
  }

  onUnselectGroupClient(unselectedClient): void {
    let indexToBeRemoved = this.filteredSelectedClientList.indexOf(unselectedClient);
    this.filteredSelectedClientList.splice(indexToBeRemoved, 1);
    this.filteredUnselectedClientList.push(unselectedClient);

    indexToBeRemoved = this.selectedClientList.findIndex((selectedClient) => selectedClient.tier1Id === unselectedClient.tier1Id); 

    this.selectedClientList.splice(indexToBeRemoved, 1);
    this.unselectedClientList.push(unselectedClient);

    this.onFilterSelectedClientList();
    this.onFilterUnselectedClientList();
  }

  onSaveGroupClient(): void {
      this.isSavingClientGroupName = true;
      const selectedTier1IdList: number[] = this.selectedClientList.map((selectedClient) => selectedClient.tier1Id);
      this._adminService.saveGroupClientList(this.selectedClientGroup.tier1Id, selectedTier1IdList).toPromise().then((results: string) => {
        this.selectedClientFilterString = '';
        this.unselectedClientFilterString = '';
          this.populateSelectedGroupClientList(this.selectedClientGroup.tier1Id);
          this._toasterService.showMessage(results, ToasterServiceModes.OK);
      }).catch((error: any) => {
        this._toasterService.showMessage(error.error, ToasterServiceModes.NOK);
      }).finally(() => {
        this.isSavingClientGroupName = false;
      });
  }

  onFilterSelectedClientList(): void {
      if (this.selectedClientFilterString.trim().toLowerCase().length === 0) {
        this.filteredSelectedClientList = [...this.selectedClientList];
      }

      this.filteredSelectedClientList = this.selectedClientList.filter((selectedClient) => {
          return selectedClient.name.trim().toLowerCase().indexOf(this.selectedClientFilterString.trim().toLowerCase()) >= 0;
      });

      this.filteredSelectedClientList = this.filteredSelectedClientList.sort((prev, next) => prev.name > next.name ? 1 : -1);
      this.selectedClientList = this.selectedClientList.sort((prev, next) => prev.name > next.name ? 1 : -1);
  }

  onFilterUnselectedClientList(): void {
    if (this.unselectedClientFilterString.trim().toLowerCase().length === 0) {
      this.filteredUnselectedClientList = [...this.unselectedClientList];
    }

    this.filteredUnselectedClientList = this.unselectedClientList.filter((unselectedClient) => {
        return unselectedClient.name.trim().toLowerCase().indexOf(this.unselectedClientFilterString.trim().toLowerCase()) >= 0;
    });

    this.filteredUnselectedClientList = this.filteredUnselectedClientList.sort((prev, next) => prev.name > next.name ? 1 : -1);
    this.unselectedClientList = this.unselectedClientList.sort((prev, next) => prev.name > next.name ? 1 : -1);    
  } 
}
