export const locale = {
    lang: 'it',
    data: {
  'DistributionByClaimType': {
    'TotalPaidClaim': 'Sinistro totale pagato',
    'ClaimCount': 'Conteggio Sinistri',
    'AverageCost': 'Costo medio',
    'Dimensions': 'Dimensione',
    'AgeBand': "Fascia d'età",
    'Gender': 'Genere',
    'Relationship': 'Relazione',
    'AllAmountsDisplayedInLocalCurrency': 'Tutti gli importi visualizzati in valuta locale',
    'ClaimsReportedByPaidDate': 'Sinistri per data di pagamento',
    'ClaimsReportedByIncurredDate': 'Sinistri per data di accadimento',
    'ClaimTypeBy': 'Per tipo di sinistro',
    'DateRange': 'Intervallo Di Date'
  }
}};