<div fxLayout='column'>
    <div fxLayout='row'>
        <div fxFlex='90'>
            <aon-h4>Country's Benchmark Settings</aon-h4>
        </div>
        <div fxFlex='10'>
            <aon-button-default (onclick)='saveBenchmarkLimitList()'>Save</aon-button-default>
        </div>
    </div>
    <div fxLayout='row'>
        <div fxFlex>
            <table>
                <thead>
                    <tr>
                        <th>Country</th>
                        <th style='width: 600px'>Minimum Member Count</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let countryBenchmark of countryBenchmarkList">
                        <td>{{countryBenchmark.name}}</td>
                        <td>
                            <aon-textbox
                                type='number'
                                [value]='countryBenchmark.benchmarkLimit'
                                (onchange)='countryBenchmark.benchmarkLimit = $event.detail.value'
                            ></aon-textbox>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div fxLayout='row'>
        <div fxFlex='90'>
        </div>
        <div fxFlex='10'>
            <aon-button-default (onclick)='saveBenchmarkLimitList()'>Save</aon-button-default>
        </div>
    </div>
</div>