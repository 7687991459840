<div
    id="storyContainer"
    class="page-layout blank contentStoryPadding"
    fxLayout="column"
    fusePerfectScrollbar
>
    <div
        id="storyCon1Half"
        fxLayout="row"
        fxLayoutGap="12px"
        fxLayoutAlign="start center"
        style="height: 42px; margin-top: -5px; margin-bottom: 5px"
    >
        <mat-form-field
            id="storyConTitle"
            *ngIf="!storyState.isReadOnly"
            fxFlex
            floatLabel="never"
        >
            <input
                id="storyConTileInput"
                matInput
                placeholder="Story Title"
                style="font-weight: bold"
                [(ngModel)]="storyState.name"
                autocomplete="off"
                [ngModelOptions]="{ standalone: true }"
            />
        </mat-form-field>
        <h3 id="storyConName" *ngIf="storyState.isReadOnly">
            <strong>{{ storyState.name }}</strong>
        </h3>
        <span fxFlex></span>
        <mat-spinner
            id="storyConSpin"
            *ngIf="isLoading"
            class="mat-accent"
            style="margin: 0 auto"
            diameter="20"
        >
        </mat-spinner>

        <!-- preview navigation controls -->
        <ng-container
            id="storyConPreNav"
            *ngIf="
                storyState.activeViewMode == viewModes.single &&
                storyState.dashboardList.length > 0
            "
        >
            <button
                id="storyConBtnTogThumb"
                mat-icon-button
                style="max-height: 36px"
                (click)="storyState.thumbnailNav = !storyState.thumbnailNav"
                [ngClass]="{ 'mat-accent': storyState.thumbnailNav }"
            >
                <mat-icon>power_input</mat-icon>
            </button>
            <button
                id="storyConBtnViewPre"
                mat-icon-button
                style="max-height: 36px"
                (click)="viewPrevious()"
                [disabled]="isLoading"
            >
                <mat-icon>chevron_left</mat-icon>
            </button>
            <span id="currentDashboard">{{ storyState.dashIndex + 1 }}</span> /
            <span id="totalDashboardCount">{{
                storyState.dashboardList.length
            }}</span>
            &nbsp;&nbsp;
            <button
                id="storyConBtnViewNext"
                mat-icon-button
                style="max-height: 36px"
                (click)="viewNext()"
                [disabled]="isLoading"
            >
                <mat-icon>chevron_right</mat-icon>
            </button>
        </ng-container>
        <button
            id="storyConBtnViewSingle"
            mat-icon-button
            style="max-height: 36px"
            (click)="viewSingle()"
            [ngClass]="{
                'mat-accent': storyState.activeViewMode == viewModes.single
            }"
        >
            <mat-icon>crop_5_4</mat-icon>
        </button>
        <!-- <button mat-icon-button style="max-height: 36px" (click)="viewStream()"
      [ngClass]="{ 'mat-accent': storyState.activeViewMode == viewModes.stream }">
      <mat-icon>view_stream</mat-icon>
    </button> -->
        <button
            id="storyConBtnViewTile"
            mat-icon-button
            style="max-height: 36px"
            (click)="viewTile()"
            [ngClass]="{
                'mat-accent': storyState.activeViewMode == viewModes.tile
            }"
        >
            <mat-icon>view_module</mat-icon>
        </button>
        <button
            id="storyConBtnAllEx"
            mat-raised-button
            *ngIf="storyState.dashboardList.length > 0"
            style="max-height: 36px"
            (click)="addAllToExport()"
        >
            {{ "Storyboard.AddAll" | translate }}
        </button>
        <button
            id="storyConBtnLibrary"
            mat-raised-button
            *ngIf="!this.storyState.isReadOnly"
            style="max-height: 36px"
            (click)="toggleLibrary()"
            [ngClass]="{ 'mat-accent': !storyState.showStoryBoard }"
        >
            {{ "Storyboard.Library" | translate }}
        </button>
        <button
            id="storyConBtnSave"
            mat-raised-button
            *ngIf="!this.storyState.isReadOnly"
            class="mat-primary"
            style="max-height: 36px"
            [disabled]="!storyValid() || isLoading"
            (click)="save()"
        >
            {{ "Storyboard.Save" | translate }}
        </button>
    </div>
    <div fxLayout="row" fxLayoutGap="12px">
        <div
            fxFlex
            id="mainstoryboard"
            (hidden)="(storyState.showStoryBoard)"
            style="overflow: hidden; background-color: rgb(245, 245, 245)"
        >
            <div [fxLayout]="storyState.layoutStoryBoard">
                <div [hidden]="storyState.activeViewMode == 'tile'">
                    <ng-container
                        *ngFor="
                            let wb of storyState.workbookList;
                            let i = index
                        "
                    >
                        <mat-drawer-container
                            class="mat-drawer-light-backdrop"
                            [style.position]="
                                storyState.activeViewMode == viewModes.single &&
                                wb.id != storyState.activeWorkbook.id
                                    ? 'absolute'
                                    : 'relative'
                            "
                            [style.top]="
                                storyState.activeViewMode == viewModes.single &&
                                wb.id != storyState.activeWorkbook.id
                                    ? '-9999px'
                                    : '0px'
                            "
                            [style.left]="
                                storyState.activeViewMode == viewModes.single &&
                                wb.id != storyState.activeWorkbook.id
                                    ? '9999px'
                                    : '0px'
                            "
                            hasBackdrop="true"
                        >
                            <mat-drawer
                                #drawer
                                mode="over"
                                style="min-width: 240px"
                            >
                                <div fxLayout="row">
                                    <span fxFlex></span>
                                    <button
                                        id="mainstoryClose"
                                        mat-icon-button
                                        class="mat-accent"
                                        (click)="drawer.toggle()"
                                    >
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                                <div
                                    *ngIf="!isAddCustomView"
                                    fxLayout="column"
                                    style="padding: 0px 14px"
                                >
                                    <strong>{{
                                        "Storyboard.CustomViews" | translate
                                    }}</strong>
                                    <br />
                                    <button
                                        [id]="'mainstoryView' + i"
                                        mat-button
                                        (click)="
                                            drawer.toggle(); resetCustomView(wb)
                                        "
                                    >
                                        <span>{{
                                            "Storyboard.ResetCustomView"
                                                | translate
                                        }}</span>
                                        &nbsp;
                                        <mat-icon
                                            >settings_backup_restore</mat-icon
                                        >
                                    </button>

                                    <!-- YOU CAN USE DASHLIST BUT FILTER BY WORKBOOK  -->
                                    <span
                                        *ngIf="
                                            !wb.filters ||
                                            wb.filters.length == 0
                                        "
                                        style="width: 100%; text-align: center"
                                    >
                                        <br />
                                        {{
                                            "Storyboard.NoCustomViewFound"
                                                | translate
                                        }}
                                        <br /><br />
                                        <button
                                            [id]="'mainstoryAdd' + i"
                                            mat-button
                                            class="mat-accent"
                                            (click)="isAddCustomView = true"
                                        >
                                            {{ "Storyboard.ADD" | translate }}
                                        </button>
                                    </span>
                                    <div
                                        fxLayout="row"
                                        *ngFor="let f of wb.filters"
                                    >
                                        <span fxFlex="70">
                                            <button
                                                [id]="'mainstoryTogName' + i"
                                                mat-menu-item
                                                [value]="f"
                                                (click)="
                                                    drawer.toggle();
                                                    applyCustomView(wb, f)
                                                "
                                            >
                                                <span>{{ f.name }}</span>
                                            </button>
                                        </span>
                                        <span fxFlex="30">
                                            <button
                                                [id]="'mainstoryDlt' + i"
                                                mat-icon-button
                                                [value]="f"
                                                (click)="
                                                    deleteDashboardCustomView(
                                                        i,
                                                        f,
                                                        wb.filters
                                                    )
                                                "
                                            >
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <div
                                    *ngIf="isAddCustomView"
                                    fxLayout="column"
                                    style="padding: 0px 14px"
                                >
                                    <strong>{{
                                        "Storyboard.SaveCustomView" | translate
                                    }}</strong>
                                    <br /><br />
                                    <mat-form-field>
                                        <input
                                            [id]="'mainstoryinputName' + i"
                                            matInput
                                            style="width: 250px"
                                            data-placeholder="{{
                                                'Storyboard.CustomViewName'
                                                    | translate
                                            }}"
                                            [(ngModel)]="wb.filterName"
                                            autocomplete="off"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                        />
                                    </mat-form-field>
                                    <mat-form-field>
                                        <textarea
                                            [id]="'mainstoryTxtCom' + i"
                                            matInput
                                            style="width: 250px"
                                            data-placeholder="{{
                                                'Storyboard.Comments'
                                                    | translate
                                            }}"
                                            autocomplete="off"
                                            [(ngModel)]="wb.filterComments"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                        ></textarea>
                                    </mat-form-field>
                                    <div fxLayout="row">
                                        <button
                                            [id]="'mainstoryBtnCancel' + i"
                                            mat-button
                                            class="mat-accent"
                                            (click)="
                                                closeDashboardCustomView(wb);
                                                isAddCustomView = false;
                                                drawer.toggle()
                                            "
                                        >
                                            {{
                                                "Storyboard.CANCEL" | translate
                                            }}
                                        </button>
                                        <span fxFlex></span>
                                        <button
                                            [id]="'mainstoryBtnSave' + i"
                                            mat-button
                                            class="mat-accent"
                                            (click)="
                                                saveDashboardCustomView(wb);
                                                drawer.toggle()
                                            "
                                        >
                                            {{ "Storyboard.SAVE" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </mat-drawer>

                            <mat-drawer-content>
                                <mat-card style="padding: 0px">
                                    <div
                                        fxLayout="row"
                                        fxLayoutAlign="start end"
                                    >
                                        <button
                                            id="mainstoryBtnBookmark"
                                            *ngIf="
                                                activeViewMode != viewModes.tile
                                            "
                                            mat-icon-button
                                            matTooltip="{{
                                                'Storyboard.ApplyCustomView'
                                                    | translate
                                            }}"
                                            (click)="
                                                isAddCustomView = false;
                                                drawer.toggle();
                                                loadDashboardCustomViews(wb)
                                            "
                                        >
                                            <mat-icon class="s-20"
                                                >bookmarks</mat-icon
                                            >
                                        </button>
                                        <button
                                            id="mainstoryBtnAddCus"
                                            *ngIf="
                                                activeViewMode != viewModes.tile
                                            "
                                            mat-icon-button
                                            matTooltip="{{
                                                'Storyboard.SaveCustomView'
                                                    | translate
                                            }}"
                                            (click)="
                                                isAddCustomView = true;
                                                drawer.toggle()
                                            "
                                        >
                                            <mat-icon class="s-20"
                                                >save</mat-icon
                                            >
                                        </button>
                                        <button
                                            id="mainstoryAddExport"
                                            mat-icon-button
                                            matTooltip="{{
                                                'Storyboard.AddToExport'
                                                    | translate
                                            }}"
                                            (click)="
                                                addToExport(
                                                    this.storyState.dashIndex
                                                )
                                            "
                                        >
                                            <mat-icon class="s-20"
                                                >playlist_add</mat-icon
                                            >
                                        </button>
                                        <button
                                            *ngIf="
                                                wb.type === 'TABLEAU' &&
                                                activeViewMode != viewModes.tile
                                            "
                                            id="mainstoryDownloadImage"
                                            mat-icon-button
                                            matTooltip="{{
                                                'Storyboard.DownloadImage'
                                                    | translate
                                            }}"
                                            (click)="
                                                openDownloadImageDialog(wb)
                                            "
                                        >
                                            <mat-icon class="s-20"
                                                >photo</mat-icon
                                            >
                                        </button>
                                        <span fxFlex></span>
                                        <ng-container
                                            *ngIf="
                                                isWindowFullScreen &&
                                                storyState.activeViewMode ==
                                                    viewModes.single &&
                                                storyState.dashboardList
                                                    .length > 0
                                            "
                                        >
                                            <mat-spinner
                                                *ngIf="isLoading"
                                                class="mat-accent"
                                                style="margin: 0 auto"
                                                diameter="20"
                                            >
                                            </mat-spinner>
                                            <button
                                                [id]="'mainstoryBtnPow' + i"
                                                mat-icon-button
                                                style="max-height: 36px"
                                                (click)="
                                                    storyState.thumbnailNav =
                                                        !storyState.thumbnailNav
                                                "
                                                [ngClass]="{
                                                    'mat-accent':
                                                        storyState.thumbnailNav
                                                }"
                                            >
                                                <mat-icon>power_input</mat-icon>
                                            </button>
                                            <button
                                                [id]="
                                                    'mainstoryBtnChevronL' + i
                                                "
                                                mat-icon-button
                                                style="max-height: 36px"
                                                (click)="viewPrevious()"
                                            >
                                                <mat-icon
                                                    >chevron_left</mat-icon
                                                >
                                            </button>
                                            <span style="line-height: 32px">
                                                {{ storyState.dashIndex + 1 }} /
                                                {{
                                                    storyState.dashboardList
                                                        .length
                                                }}
                                                &nbsp;&nbsp;
                                            </span>
                                            <button
                                                [id]="
                                                    'mainstoryBtnChevronR' + i
                                                "
                                                mat-icon-button
                                                style="max-height: 36px"
                                                (click)="viewNext()"
                                            >
                                                <mat-icon
                                                    >chevron_right</mat-icon
                                                >
                                            </button>
                                        </ng-container>
                                        <div
                                            *ngIf="
                                                !isWindowFullScreen &&
                                                storyState.activeViewMode !=
                                                    'tile'
                                            "
                                        >
                                            <ng-container
                                                *ngIf="
                                                    !this.storyState.isReadOnly
                                                "
                                            >
                                                <button
                                                    [id]="
                                                        'mainstoryBtnMoveUp' + i
                                                    "
                                                    mat-icon-button
                                                    matTooltip="Move up"
                                                    (click)="
                                                        moveUp(
                                                            storyState.dashIndex
                                                        )
                                                    "
                                                >
                                                    <mat-icon class="s-20"
                                                        >keyboard_arrow_up</mat-icon
                                                    >
                                                </button>
                                                <button
                                                    [id]="
                                                        'mainstoryBtnMoveDown' +
                                                        i
                                                    "
                                                    mat-icon-button
                                                    matTooltip="Move down"
                                                    (click)="
                                                        moveDown(
                                                            storyState.dashIndex
                                                        )
                                                    "
                                                >
                                                    <mat-icon class="s-20"
                                                        >keyboard_arrow_down</mat-icon
                                                    >
                                                </button>
                                            </ng-container>
                                            <span fxFlex></span>
                                            <!-- <button *ngIf="storyState.activeViewMode != viewModes.single" mat-icon-button matTooltip="View single"
                        (click)="viewDashboard(i); viewSingle();">
                        <mat-icon class="s-20">crop_5_4</mat-icon>
                      </button> -->
                                            <button
                                                id="mainstoryFullScreen"
                                                mat-icon-button
                                                matTooltip="Full-screen"
                                                (click)="goFullScreen()"
                                            >
                                                <mat-icon class="s-20"
                                                    >fullscreen</mat-icon
                                                >
                                            </button>
                                        </div>
                                        <button
                                            [id]="'mainstoryBtnRemoveDash' + i"
                                            *ngIf="!storyState.isReadOnly"
                                            mat-icon-button
                                            (click)="removeDashboard()"
                                        >
                                            <mat-icon class="s-20"
                                                >delete</mat-icon
                                            >
                                        </button>
                                    </div>
                                    <div
                                        style="overflow: auto"
                                        [hidden]="
                                            storyState.activeViewMode == 'tile'
                                        "
                                    >
                                        <div
                                            *ngIf="
                                                !wb.type || wb.type == 'TABLEAU'
                                            "
                                            id="wb-{{ wb.id }}"
                                            class="tableau-container"
                                            [style.height]="
                                                getTableauContainerHeight()
                                            "
                                            (mouseleave)="updateFocus()"
                                        >
                                            <!-- TABLEAU WRAPPER -->
                                        </div>
                                        <div
                                            *ngIf="wb.type == 'CUSTOM'"
                                            id="wb-{{ wb.id }}"
                                            [style.height]="
                                                getTableauContainerHeight()
                                            "
                                        >
                                            <!-- HIGHCHART WRAPPER -->
                                            <app-dashboardcontainer
                                                [id]="'mainstoryDash' + i"
                                                style="
                                                    font-family: 'Helvetica Now Text',
                                                        Arial, Helvetica,
                                                        sans-serif;
                                                "
                                                template="{{ wb.id }}"
                                                (onNavigate)="
                                                    setindexCustom($event)
                                                "
                                                [properties]="
                                                    storyState.dashboardProperties
                                                "
                                                [clientGroupFilterOptions]="
                                                    clientGroupFilterOptions
                                                "
                                            >
                                            </app-dashboardcontainer>
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="
                                            !isWindowFullScreen &&
                                            storyState.activeViewMode == 'tile'
                                        "
                                    >
                                        <ng-container
                                            *ngIf="!storyState.isReadOnly"
                                        >
                                            <button
                                                [id]="'mainstoryBtnAUp' + i"
                                                mat-icon-button
                                                matTooltip="Move up"
                                                (click)="moveUp(i)"
                                            >
                                                <mat-icon class="s-20"
                                                    >keyboard_arrow_up</mat-icon
                                                >
                                            </button>
                                            <button
                                                [id]="'mainstoryBtnADown' + i"
                                                mat-icon-button
                                                matTooltip="Move down"
                                                (click)="moveDown(i)"
                                            >
                                                <mat-icon class="s-20"
                                                    >keyboard_arrow_down</mat-icon
                                                >
                                            </button>
                                        </ng-container>
                                        <span fxFlex></span>
                                        <button
                                            [id]="'mainstoryBtnVSingle' + i"
                                            *ngIf="
                                                activeViewMode !=
                                                viewModes.single
                                            "
                                            mat-icon-button
                                            matTooltip="View single"
                                            (click)="
                                                viewIndex = i; viewSingle()
                                            "
                                        >
                                            <mat-icon class="s-20"
                                                >crop_5_4</mat-icon
                                            >
                                        </button>
                                        <button
                                            [id]="'mainstoryBtnFull' + i"
                                            mat-icon-button
                                            matTooltip="Full-screen"
                                            (click)="
                                                storyState.dashIndex = i;
                                                goFullScreen(i)
                                            "
                                        >
                                            <mat-icon class="s-20"
                                                >fullscreen</mat-icon
                                            >
                                        </button>
                                    </div>
                                </mat-card>
                            </mat-drawer-content>
                        </mat-drawer-container>
                    </ng-container>
                </div>
                <!-- Tile view -->
                <ng-container
                    id="storyConTileView"
                    *ngIf="storyState.activeViewMode == 'tile'"
                >
                    <ng-container
                        *ngFor="
                            let dash of storyState.dashboardList;
                            let i = index
                        "
                    >
                        <mat-card
                            style="padding: 0px; margin: 0px 12px 8px 0px"
                        >
                            <div fxLayout="row" fxLayoutAlign="start end">
                                <button
                                    id="storyConTileViewBtnAdd"
                                    mat-icon-button
                                    matTooltip="{{
                                        'Storyboard.AddToExport' | translate
                                    }}"
                                    (click)="addToExport(i)"
                                >
                                    <mat-icon class="s-20"
                                        >playlist_add</mat-icon
                                    >
                                </button>
                                <span fxFlex></span>
                                <button
                                    id="storyConTileViewBtnDlt"
                                    *ngIf="!storyState.isReadOnly"
                                    mat-icon-button
                                    (click)="removeDashboard(i)"
                                >
                                    <mat-icon class="s-20">delete</mat-icon>
                                </button>
                            </div>
                            <div>
                                <img
                                    id="storyConTileViewImg"
                                    class="img"
                                    style="
                                        margin-top: 0px;
                                        height: 180px;
                                        width: 220px;
                                    "
                                    src="{{
                                        dash.imageBase64String
                                            ? dash.imageBase64String
                                            : dash.src
                                    }}"
                                />
                            </div>
                            <div fxKayout="row">
                                <span fxFlex></span>
                                <button
                                    id="storyConTileViewBtnSingleDash"
                                    *ngIf="activeViewMode != viewModes.single"
                                    mat-icon-button
                                    matTooltip="View single"
                                    (click)="viewDashboard(i); viewSingle()"
                                >
                                    <mat-icon class="s-20">crop_5_4</mat-icon>
                                </button>
                                <button
                                    id="storyConTileViewFull"
                                    mat-icon-button
                                    matTooltip="Full-screen"
                                    (click)="viewDashboard(i); goFullScreen(i)"
                                >
                                    <mat-icon class="s-20">fullscreen</mat-icon>
                                </button>
                            </div>
                        </mat-card>
                    </ng-container>
                </ng-container>
            </div>
            <!-- Thumbnail navigation -->
            <div
                id="storyConThumbNav"
                *ngIf="
                    storyState.dashboardList.length > 0 &&
                    storyState.activeViewMode === 'single'
                "
                [hidden]="!storyState.thumbnailNav"
                style="padding-top: 10px"
            >
                <div class="container" fxLayout="row" fxLayoutAlign="left">
                    <div
                        class="clickable move-to-left-nav-button"
                        (click)="scrollToPrevious()"
                    >
                        <button
                            id="storyConBtnNavBef"
                            style="top: 38%"
                            mat-icon-button
                        >
                            <mat-icon>navigate_before</mat-icon>
                        </button>
                    </div>
                    <div
                        id="storyConDashList"
                        fxFlex
                        style="height: auto; overflow: scroll"
                        class="single-view-scrollable-region"
                    >
                        <div fxLayout="row" fxLayoutGap="12px">
                            <div
                                *ngFor="
                                    let lib of storyState.dashboardList;
                                    let i = index
                                "
                                (click)="viewDashboard(i)"
                            >
                                <mat-card
                                    [id]="'mainstoryDashItem' + i"
                                    matTooltip="{{ lib.name }}"
                                    matTooltipPosition="above"
                                    class="single-view-card"
                                    [ngStyle]="{
                                        border:
                                            storyState.dashIndex === i
                                                ? '1.5px solid darkcyan'
                                                : ''
                                    }"
                                    style="cursor: pointer; width: 192px"
                                >
                                    <mat-card-content>
                                        <img
                                            [id]="'mainstoryimgDashItem' + i"
                                            class="img"
                                            style="
                                                margin-top: 0px;
                                                height: 80px;
                                                width: 100%;
                                            "
                                            src="{{
                                                lib.imageBase64String
                                                    ? lib.imageBase64String
                                                    : lib.src
                                            }}"
                                        />
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                    <div
                        id="storyConBtnNavNext"
                        class="clickable"
                        (click)="scrollToNext()"
                    >
                        <button
                            id="storyConBtnNavigateNext"
                            style="top: 38%"
                            mat-icon-button
                        >
                            <mat-icon>navigate_next</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Library -->
        <div
            *ngIf="!storyState.isReadOnly"
            [fxFlex]="storyState.libraryFlex"
            fxLayout="column"
            fxLayoutGap="8px"
        >
            <library
                id="storyConBtnLib"
                #dashLibrary
                [selection]="storyState.dashboardList"
                (onAdd)="onAdd($event)"
            ></library>
        </div>
    </div>
</div>
<div
    style="
        position: absolute;
        top: 0;
        text-align: center;
        height: 100%;
        width: 100%;
        z-index: 80;
        background-color: white;
    "
    *ngIf="isLoadingOverlay"
></div>
<div
    style="
        position: absolute;
        top: 0;
        text-align: center;
        height: 100%;
        width: 100%;
        z-index: 81;
    "
    *ngIf="isLoadingOverlay"
>
    <br />
    <div id="storyConSpin" style="margin: 0 auto; width: 200px" fxLayout="row">
        <strong>Loading Story... </strong> &nbsp; &nbsp;
        <mat-spinner class="mat-accent" diameter="20"></mat-spinner>
    </div>
</div>
<div
    style="
        position: absolute;
        top: 0;
        text-align: center;
        height: 100%;
        width: 100%;
        z-index: 80;
        background-color: white;
    "
    *ngIf="isApplyingCustomViewOverlay"
></div>
<div
    style="
        position: absolute;
        top: 0;
        text-align: center;
        height: 100%;
        width: 100%;
        z-index: 81;
    "
    *ngIf="isApplyingCustomViewOverlay"
>
    <br />
    <div id="storyConSpin" style="margin: 0 auto; width: 200px" fxLayout="row">
        <strong>Applying Custom View... Please Wait...</strong> &nbsp; &nbsp;
        <mat-spinner class="mat-accent" diameter="20"></mat-spinner>
    </div>
</div>
<div class="processing-overlay" *ngIf="isLoadingExportAllOverlay">
    <br />
    <div
        id="storyBoardSpin"
        style="margin: 0 auto; width: 400px"
        fxLayout="row"
    >
        <strong>{{ exportAllMessage }} </strong> &nbsp; &nbsp;
        <mat-spinner class="mat-accent" diameter="20"></mat-spinner>
    </div>
</div>
