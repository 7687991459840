import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from "ag-grid-community";
import { AdminService } from 'app/services/admin.service';
import { AgmatcheckboxComponent } from 'app/shared/agmatcheckbox/agmatcheckbox.component';
import { AgheadermatcheckboxComponent } from 'app/shared/agheadermatcheckbox/agheadermatcheckbox.component';
import { UserService } from 'app/services/user.service';
import { GlobalfiltersService } from 'app/services/globalfilters.service';
import { User } from 'app/models/user.model'
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as navigationEnglish } from 'app/admin/useradmin/i18n/en';
import { locale as navigationSpanishCL } from 'app/admin/useradmin/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/admin/useradmin/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/admin/useradmin/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/admin/useradmin/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/admin/useradmin/i18n/es-pe';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { List } from 'linqts';
import { WelloneDashboardService } from 'app/wellone/services/dashboard.service';

import { DashboardGraphModel, HealthScoreModel, FinancialModel, ParticipationModel, DashboardModel } from 'app/wellone/models/dashboard.model';

import { ParticipationViewModel } from 'app/wellone/models/participation.model';
import { ParticipationService } from 'app/wellone/services/participation.service';
import { WelloneParameterModel } from 'app/wellone/models/welloneparameter.model';
import { WelloneConstant } from 'app/wellone/models/welloneconstant.model';
import { GlobalFilterService } from 'app/wellone/services/globalfilter.service';
import { WelloneService } from 'app/wellone/services/wellone.service';
import * as moment from 'moment';

@Component({
  selector: 'app-wellone-health-score-detail',
  templateUrl: './participation.component.html',
  styleUrls: ['./participation.component.scss']
})
export class ParticipationPageComponent implements OnInit {


  dashboardResult: DashboardModel;
  participationResult:ParticipationViewModel;
  isLoading: boolean = false;
  isDataAvailable:boolean = false;
  loaded: boolean = false;
  errorMsg:string;
  user: User;
  selectedTab = 2;
  x = -1;
  data: any = null;
  slicedTableData: any[] = [];
  isActiveSelected = true;

  lineChartData: any[] = [];
  participantlineChartData: any[] = [];

  buLineChartData: any[] = [];

  captionText = 'View More';

  buTableData: any[] = [];

  tableData: any[] = [];  
  showChart = true;
  chartIconBgColor = '#E4E4E4';
  tableIconBgColor = 'white';
  blankArr:any[] =[];
  sortIcon = ['fal', 'angle-down'];

  noOfTableRowsShown = 10;

  isCurrentTabSelected = true;

  participationOverTimeColumn = 'dateSortable';
  isParticipationOverTimeDescendingOrder = true;
  participantParams:WelloneParameterModel;
  changePercentage = 0;
  timePeriod = 4; // All
  maxDate = '';

  constructor(
    private participationService: ParticipationService, 
    private userService: UserService,
    private globalFilterService: GlobalFilterService,
    private toasterService:ToasterService,
    private welloneService: WelloneService
  ) { }

  ngOnInit() { 
    this.participationResult = new ParticipationViewModel();
    this.participationResult.OverallOrBUOption = 2;
    this.participationResult.PercentageOrCountOption = 1;
    this.participationResult.ParticipationLevelValue = 2;  
    this.participationResult.ParticipationLevelRankingOrder = 1;  
    this.participationResult.ParticipationLevelHasBottomRankingUsers = false;
    this.loadParticipantData();
   
     // filter change
    this.globalFilterService.filterApplyClick.subscribe(res => {
      this.loadParticipantData();
    });
  }

  private loadParticipantData(): void 
  {
    this.data = undefined;
    this.errorMsg = undefined;
    this.participationResult.ParticipationChartCustomTicks = [];
    this.participantParams = this.welloneService.getParamInfo();
    this.participationService.getParticipationResult(this.participantParams).toPromise().then((res) => {     
        this.participationResult.ParticipationChartCustomTicks  = this.getParticipationCustomTickArr(res);
        this.data = res;
        console.log(this.data);
        this.loaded = true;
        this.isDataAvailable = this.data.totalOverall > 0;
        this.populateCharts();
        this.setBannerText();
    }).catch(error => {
      this.isLoading = false;
    //  this.errorMsg = error.message;
      console.log(error);         
    });
  }
  maxYAxisNum:number;

  setBannerText() {
    this.maxDate = this.formatDate(this.data.maxScoreDate, 0);
    if (this.participantParams.GlobalFilters.TimePeriod === WelloneConstant.Week) {
      this.maxDate = this.formatDate(this.data.maxScoreDate, 7);
      this.percChange(this.data.totalParticipationPercentage_WeekAgo, this.data.totalParticipationPercentage);
    }
    else if (this.participantParams.GlobalFilters.TimePeriod === WelloneConstant.Month) {
      this.maxDate = this.formatDate(this.data.maxScoreDate, 30);
      this.percChange(this.data.totalParticipationPercentage_MonthAgo, this.data.totalParticipationPercentage);
    }
    else if (this.participantParams.GlobalFilters.TimePeriod === WelloneConstant.Quarter) {
      this.maxDate = this.formatDate(this.data.maxScoreDate, 90);
      this.percChange(this.data.totalParticipationPercentage_QtrAgo, this.data.totalParticipationPercentage);
    }
    else {
      this.maxDate = this.formatDate(this.data.minScoreDate, 0);
      this.percChange(this.data.totalParticipationPercentage_Inception, this.data.totalParticipationPercentage);
    }

  }

  percChange(old, latest) {
    if (old != 0) {
      let temp = (latest - old) / old;
      this.changePercentage = +((temp*100).toFixed(1));
    }
    else this.changePercentage = 0;  
  }

  formatDate(responseDate, subtractDays) {
    let date = new Date(responseDate);
    let calculatedDate = new Date();
    if (subtractDays != 0) {
      calculatedDate.setDate(date.getDate() - subtractDays);
    }
    else {
      return moment(date).format('DD-MMM-YYYY');
    }
    
    return moment(calculatedDate).format('DD-MMM-YYYY');
  }

  private populateCharts() {
      this.lineChartData = this.processChartDateTimeValue(this.data);
      let maxNum = 0;
      this.lineChartData.forEach(row => {
        var maxNo = new List<any>(row.seriesData).Max(x => x.yVal);
        if (maxNo > maxNum)
        {
          maxNum = maxNo;
        }
      });
      this.maxYAxisNum = Math.round(maxNum * 1.1);

      this.tableData = this.data.participationTable.slice(0, this.noOfTableRowsShown);
  }

  private processChartDateTimeValue(chart: any) {
    let chartData = [];
    let colorArr = [0,2,4,7,8];

    if (this.participationResult.OverallOrBUOption === 1 
      && this.participationResult.PercentageOrCountOption === 1) {
      chartData = chart.overallPercentageChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 1 
      && this.participationResult.PercentageOrCountOption === 2) {
      chartData = chart.overallCountChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 2 
      && this.participationResult.PercentageOrCountOption === 1
      && this.participationResult.ParticipationLevelRankingOrder === 1
      && this.participationResult.ParticipationLevelValue === 1) {
        chartData = chart.topBusinessUnitPercentageActiveChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 2 
      && this.participationResult.PercentageOrCountOption === 1
      && this.participationResult.ParticipationLevelRankingOrder === 1
      && this.participationResult.ParticipationLevelValue === 2) {
        chartData = chart.topBusinessUnitPercentageEnrolledChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 2 
      && this.participationResult.PercentageOrCountOption === 2
      && this.participationResult.ParticipationLevelRankingOrder === 1
      && this.participationResult.ParticipationLevelValue === 1) {
        chartData = chart.topBusinessUnitCountActiveChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 2 
      && this.participationResult.PercentageOrCountOption === 2
      && this.participationResult.ParticipationLevelRankingOrder === 1
      && this.participationResult.ParticipationLevelValue === 2) {
        chartData = chart.topBusinessUnitCountEnrolledChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 2 
      && this.participationResult.PercentageOrCountOption === 2
      && this.participationResult.ParticipationLevelRankingOrder === 1
      && this.participationResult.ParticipationLevelValue === 3) {
        chartData = chart.topBusinessUnitCountEligibleChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 2 
      && this.participationResult.PercentageOrCountOption === 1
      && this.participationResult.ParticipationLevelRankingOrder === 2
      && this.participationResult.ParticipationLevelValue === 1 
      && !this.participationResult.ParticipationLevelHasBottomRankingUsers) {
        chartData = chart.bottomBusinessUnitPercentageActiveChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 2 
      && this.participationResult.PercentageOrCountOption === 1
      && this.participationResult.ParticipationLevelRankingOrder === 2
      && this.participationResult.ParticipationLevelValue === 2
      && !this.participationResult.ParticipationLevelHasBottomRankingUsers) {
        chartData = chart.bottomBusinessUnitPercentageEnrolledChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 2 
      && this.participationResult.PercentageOrCountOption === 2
      && this.participationResult.ParticipationLevelRankingOrder === 2
      && this.participationResult.ParticipationLevelValue === 1
      && !this.participationResult.ParticipationLevelHasBottomRankingUsers) {
        chartData = chart.bottomBusinessUnitCountActiveChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 2 
      && this.participationResult.PercentageOrCountOption === 2
      && this.participationResult.ParticipationLevelRankingOrder === 2
      && this.participationResult.ParticipationLevelValue === 2
      && !this.participationResult.ParticipationLevelHasBottomRankingUsers) {
        chartData = chart.bottomBusinessUnitCountEnrolledChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 2 
      && this.participationResult.PercentageOrCountOption === 2
      && this.participationResult.ParticipationLevelRankingOrder === 2
      && this.participationResult.ParticipationLevelValue === 3
      && !this.participationResult.ParticipationLevelHasBottomRankingUsers) {
        chartData = chart.bottomBusinessUnitCountEligibleChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 2 
      && this.participationResult.PercentageOrCountOption === 1
      && this.participationResult.ParticipationLevelRankingOrder === 2
      && this.participationResult.ParticipationLevelValue === 1 
      && this.participationResult.ParticipationLevelHasBottomRankingUsers) {
        chartData = chart.bottomBusinessUnitPercentageActiveExcludesZeroChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 2 
      && this.participationResult.PercentageOrCountOption === 1
      && this.participationResult.ParticipationLevelRankingOrder === 2
      && this.participationResult.ParticipationLevelValue === 2
      && this.participationResult.ParticipationLevelHasBottomRankingUsers) {
        chartData = chart.bottomBusinessUnitPercentageEnrolledExcludesZeroChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 2 
      && this.participationResult.PercentageOrCountOption === 2
      && this.participationResult.ParticipationLevelRankingOrder === 2
      && this.participationResult.ParticipationLevelValue === 1
      && this.participationResult.ParticipationLevelHasBottomRankingUsers) {
        chartData = chart.bottomBusinessUnitCountActiveExcludesZeroChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 2 
      && this.participationResult.PercentageOrCountOption === 2
      && this.participationResult.ParticipationLevelRankingOrder === 2
      && this.participationResult.ParticipationLevelValue === 2
      && this.participationResult.ParticipationLevelHasBottomRankingUsers) {
        chartData = chart.bottomBusinessUnitCountEnrolledExcludesZeroChartData;
    }
    else if (this.participationResult.OverallOrBUOption === 2 
      && this.participationResult.PercentageOrCountOption === 2
      && this.participationResult.ParticipationLevelRankingOrder === 2
      && this.participationResult.ParticipationLevelValue === 3
      && this.participationResult.ParticipationLevelHasBottomRankingUsers) {
        chartData = chart.bottomBusinessUnitCountEligibleExcludesZeroChartData;
    }
    // if(this.participationResult.OverallOrBUOption === 1)
    // {
    //     if(this.participationResult.PercentageOrCountOption === 1)
    //     {
    //         chartData = new List<any>(chart.overallPercentageOrCountChartData).Where(x =>   
    //           x.name === WelloneConstant.ActiveEligible || x.name === WelloneConstant.EnrolledEligible).ToArray();
    //     }
    //     else
    //     {
    //       chartData = new List<any>(chart.overallPercentageOrCountChartData).Where(x =>   
    //         x.name === WelloneConstant.Active || x.name === WelloneConstant.Enrolled || x.name === WelloneConstant.Eligible).ToArray();
    //     }
    // }
    // else
    // {
    //   let value = '';
    //   if(this.participationResult.ParticipationLevelValue === 1)
    //   {
    //     value = (this.participationResult.PercentageOrCountOption === 1) ? WelloneConstant.ActiveEligible : WelloneConstant.Active;
    //   }
    //   else if(this.participationResult.ParticipationLevelValue === 2)
    //   {
    //     value = (this.participationResult.PercentageOrCountOption === 1) ? WelloneConstant.EnrolledEligible : WelloneConstant.Enrolled;
    //   }
    //   else if(this.participationResult.ParticipationLevelValue === 3)
    //   {
    //     value = WelloneConstant.Eligible;
    //   }
    //   var finalData = [];

    //   var count = 0;

    //   const businessUnitPercentageOrCountChartData = 
    //     this.participationResult.ParticipationLevelRankingOrder === 1 ?
    //     chart.topBusinessUnitPercentageOrCountChartData :
    //     (this.participationResult.ParticipationLevelHasBottomRankingUsers && value !== WelloneConstant.Eligible ? chart.bottomBusinessUnitWithUsersPercentageOrCountChartData : chart.bottomBusinessUnitPercentageOrCountChartData)

    //     businessUnitPercentageOrCountChartData.forEach(row => {
    //     var seriesRow = new List<any>(row.partcipationBUSeries).FirstOrDefault(x => x.name === value);
    //     var newRow = {
    //         colorindex: count++,
    //         name:row.businessUnit,
    //         isDashedLine:false,
    //         seriesData:seriesRow !== null ? seriesRow.seriesData : []
    //     };
    //     finalData.push(newRow);
    //   });
    //   chartData = JSON.parse(JSON.stringify(finalData)); 
    // }
    for (let i = 0; i < chartData.length; i++) {
        const series = chartData[i];
        series.colorindex = colorArr[i];
        for (let j = 0; j < series.seriesData.length; j++) {
            const seriesDataRecord = series.seriesData[j];
            seriesDataRecord.xVal = new Date(seriesDataRecord.xVal);
        }
    }

    return chartData;
  }

  toggleParticipationOverTimeSort(columnName: string) {
    if (columnName === this.participationOverTimeColumn) {
        this.isParticipationOverTimeDescendingOrder = !this.isParticipationOverTimeDescendingOrder;
    }
    else {
        this.participationOverTimeColumn = columnName;
        this.isParticipationOverTimeDescendingOrder = true;
    }

    this.tableData = this.data.participationTable
        .sort((prev, next) => {
            if (this.isParticipationOverTimeDescendingOrder) {
                return prev[columnName] < next[columnName] ? 1 : (prev[columnName] === next[columnName] ? 0 : -1);
            }

            return prev[columnName] > next[columnName] ? 1 : (prev[columnName] === next[columnName] ? 0 : -1);
        })
        .slice(0, this.noOfTableRowsShown);
  }

  viewMore() {
    this.noOfTableRowsShown = this.noOfTableRowsShown += 10;

    this.tableData = this.data.participationTable.slice(0, this.noOfTableRowsShown);
  }

  private getParticipationCustomTickArr(data: any): any[] {
    var participationResult = JSON.parse(JSON.stringify(data))
    let result: any[] = [];
    let tickNum = this.getTickSize(participationResult.overallCountChartData[0].seriesData);
    let participationList = new List<any>(participationResult.overallCountChartData[0].seriesData).OrderByDescending(x => new Date(x.xVal)).ToArray();
    let days = Math.round(participationList.length / tickNum);
    if (participationList !== null && participationList.length >= 7) {
      var scoreDate = new Date(participationList[0].xVal);
      result.push(scoreDate);
      var count = 0;
      var tickSize = 0;
      while (tickSize < (tickNum - 1)) {
        result.push(this.addDays(scoreDate, -1 * days));
        count = count + days;
        scoreDate = participationList[count].xVal;
        tickSize = tickSize + 1;
      }
      result = new List<any>(result).OrderBy(x => x).ToArray();
    }

    return result;
  }

  private addDays(scoreDate: Date, days: number): Date {
    var result = new Date(scoreDate);
    result.setDate(result.getDate() + days);
    return result;
  }

  private getTickSize(data: any): any {
    var result = 7;
    switch (this.participantParams.GlobalFilters.TimePeriod) {
      case WelloneConstant.Week:
        result = 7;
        break;
      case WelloneConstant.Month:
        result = 6;
        break;
      case WelloneConstant.Quarter:
       result = 8;
        break;
      case WelloneConstant.All:
        var monthNum = Math.round(data.length / 30);
        if (monthNum <= 12) {
          result = (monthNum > 3) ? 8 : 6;
          
        }
        if (monthNum > 12) {
          result = 6;          
        }

        monthNum = Math.round(data.length / 30);
        if (monthNum <= 12) {
          result = (monthNum > 3) ? 8 : 6;
        }
        if (monthNum > 12) {
          result = 8;
        }
        break;
      default:
        result  = 8;
        break;
    }
    return result;
  }

  chartIconClick() {
    this.showChart = true;
    this.chartIconBgColor = '#E4E4E4';
    this.tableIconBgColor = 'white';
  }

  tableIconClick() {
    this.noOfTableRowsShown = 10;
    this.showChart = false;
    this.chartIconBgColor = 'white';
    this.tableIconBgColor = '#E4E4E4';
  }

  onPercentageOrCountChange() : void{
    
    setTimeout(() => {
      this.populateCharts();
    }, 100);
    
  }
  percentageClickEvent(event): void
  {
    this.participationResult.PercentageOrCountOption = 1;
    if (this.participationResult.ParticipationLevelValue === 3) {
      this.participationResult.ParticipationLevelValue = 1;
    }
    this.onPercentageOrCountChange()
  }

  countClickEvent(event): void
  {
    this.participationResult.PercentageOrCountOption = 2;
    this.onPercentageOrCountChange();
  }

  onOptionChange($event) {
    this.noOfTableRowsShown = 10;
    this.selectedTab = parseInt($event.target.getAttribute('value'));
    this.participationResult.OverallOrBUOption =  parseInt($event.target.getAttribute('value'));
    this.populateCharts();
  }

  onActiveInactiveChange(event) {
    this.noOfTableRowsShown = 10;
    this.participationResult.ParticipationLevelValue = event.detail;
    this.isActiveSelected = event.detail === 1;    
    this.populateCharts();
   
  }

  onTopBottom5Change(event) {
    this.noOfTableRowsShown = 10;
    this.participationResult.ParticipationLevelRankingOrder = event.detail;
    this.populateCharts();
  }

  onBottom5HasUsersChange() {
    this.participationResult.ParticipationLevelHasBottomRankingUsers = !this.participationResult.ParticipationLevelHasBottomRankingUsers;
    this.populateCharts();
  }
}
