import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/navigation/navigation';
import { fuseConfig } from 'app/fuse.config';
import { GlobalFilterService } from 'app/wellone/services/globalfilter.service';
import { List } from 'linqts';
import { WelloneConstant } from 'app/wellone/models/welloneconstant.model';
import { debounce } from "lodash";
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { WellOneNotificationService } from 'app/wellone/services/well-one-notification.service';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { LoadingDialogComponent } from 'app/shared/loading-dialog/loading-dialog.component';

@Component({
    selector: 'aon-layout-component',
    templateUrl: './aon-layout.component.html',
    styleUrls: ['./aon-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class AonLayoutComponent implements OnInit, OnDestroy {
    privacylink = "https://www.aon.com/about-aon/privacy.jsp";
    termslink = "https://www.aon.com/about-aon/terms-and-conditions.jsp";
    copyrightlinktext = " Copyright 2020 AON PLC";
    navigation: any;
    isFilterOpen: boolean = false;
    countryBUList: any[] = [];
    countryList: string[] = [];
    businessUnitList: string[] = [];
    timeperiod: string = WelloneConstant.All;
    timePeriodList: any[] = [
        { 'key': WelloneConstant.Week, 'value': WelloneConstant.Week },
        { 'key': WelloneConstant.Month, 'value': WelloneConstant.Month },
        { 'key': WelloneConstant.Quarter, 'value': WelloneConstant.Quarter },
        { 'key': WelloneConstant.All, 'value': WelloneConstant.SinceJoin }
    ];
    innerWidth: any;
    left = '';
    overlayWidth = '1600px';
    sideMargin = '0px';

    filterShow: boolean = true;

    // Private
    private _unsubscribeAll: Subject<any>;
    private _dataRefreshDialogRef: MatDialogRef<LoadingDialogComponent>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */


    constructor(
        private _fuseConfigService: FuseConfigService,
        private globalFilterService: GlobalFilterService,
        private _wellOneNotificationService: WellOneNotificationService,
        private _dialog: MatDialog,
        private _router: Router
    ) {
        // Set the defaults
        this.navigation = navigation;
        this.onResize = debounce(this.onResize, 150, { leading: false, trailing: true })

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.globalFilterService.hideFilterEvent.subscribe((res) => {
            this.filterShow = res;
        });

        this._wellOneNotificationService.onNotifyingWellOneCacheRefresh.subscribe(() => {
            if (this._router.url.indexOf('new-layout') < 0) {
                return;
            }

            this._dialog.open(DialogComponent, {
                minWidth: "60vh",
                minHeight: "30vh",
                disableClose: true,
                data: {
                    title: 'Information',
                    message: 'WellOne data refresh will be starting in 10 mins. The site will be unavailable for some time. Thank you.',
                    isnobuttonvisible: false,
                    yesbuttontext: 'Ok'
                }
            });
        });

        this._wellOneNotificationService.onStartingWellOneCacheRefresh.subscribe(() => {
            if (this._router.url.indexOf('new-layout') < 0) {
                return;
            }

            this.openDataRefreshDialog();
        });

        this._wellOneNotificationService.onCompletingWellOneCacheRefresh.subscribe(() => {
            if (this._router.url.indexOf('new-layout') < 0) {
                return;
            }

            this._dataRefreshDialogRef.close();

            location.reload();
        });
    }
    fuseScrollBarOptions: any = { suppressScrollX: true, suppressScrollY: false, updateOnRouteChange: true };

    /**
     * On init
     */
    ngOnInit(): void {
        let newConfig = JSON.parse(JSON.stringify(fuseConfig));
        newConfig.layout.style = 'aon-new-layout'
        newConfig.layout.navbar.hidden = false;
        newConfig.layout.footer.hidden = false;
        newConfig.layout.toolbar.hidden = false;
        newConfig.layout.sidepanel.hidden = false;
        this.innerWidth = window.innerWidth;
        this.calculatePos(this.innerWidth);
        this._fuseConfigService.setConfig(newConfig);
        //this.setCountryBUList(); 
        //this.checkIfWellOneSiteInDataRefresh();
    }

    countryChanged(event: any): void {
        var selectedCountryList = event.detail;
        if (selectedCountryList.length > 0) {
            this.businessUnitList = new List<any>(this.countryBUList).Where(x => (new List<any>(selectedCountryList).Contains(x.country))).Select(x => x.businessUnit).Distinct().ToArray();
        }
        else {
            this.businessUnitList = new List<any>(this.countryBUList).Select(x => x.businessUnit).Distinct().ToArray();
        }

    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.calculatePos(event.target.innerWidth);
    }

    calculatePos(width) {
        this.innerWidth = width;

        if (this.innerWidth > 1600) {
            let temp = (this.innerWidth - 1600) / 2;
            let posLeft = temp + 1500;
            this.sideMargin = temp.toString() + 'px';

            this.left = posLeft.toString() + 'px';
            this.overlayWidth = '1600px';
        }
        else {
            this.sideMargin = '0px';
            this.left = '90%';
            this.overlayWidth = '100vw';
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    setCountryBUList(): void {
        var filterParams = { 'Tenant_record_id': '1E95B64A-E681-4564-8D3C-A9E05CE9EA3B', 'Logged_uid': 'gkuhn3' };
        this.globalFilterService.getFilterCountryBUList(filterParams).toPromise().then((res: any) => {
            this.countryBUList = res;
            this.countryList = new List<any>(this.countryBUList).Select(x => x.country).Distinct().ToArray();
            this.businessUnitList = new List<any>(this.countryBUList).Select(x => x.businessUnit).Distinct().ToArray();
        }).catch(error => {
            console.log(error);
        });
    }

    filterApply(event: any): void {
        this.globalFilterService.UpdateGlobalFilterValues(event.detail);
        this.isFilterOpen = !this.isFilterOpen;
        this.fuseScrollBarOptions = { suppressScrollX: true, suppressScrollY: this.isFilterOpen, updateOnRouteChange: true };
    }
    filterReset(event: any): void {
        this.countryList = new List<any>(this.countryBUList).Select(x => x.country).Distinct().ToArray();
        this.businessUnitList = new List<any>(this.countryBUList).Select(x => x.businessUnit).Distinct().ToArray();
        this.timePeriodList = null;
        setTimeout(() => {
            this.timeperiod = WelloneConstant.All;
            this.timePeriodList = [
                { 'key': WelloneConstant.Week, 'value': WelloneConstant.Week },
                { 'key': WelloneConstant.Month, 'value': WelloneConstant.Month },
                { 'key': WelloneConstant.Quarter, 'value': WelloneConstant.Quarter },
                { 'key': WelloneConstant.All, 'value': WelloneConstant.SinceJoin }
            ];
        });
        this.globalFilterService.ResetGlobalFilterValues();
    }

    filterClick(event: any): void {
        this.isFilterOpen = !this.isFilterOpen;
    }

    checkIfWellOneSiteInDataRefresh(): void {
        if (location.href.indexOf('new-layout') >= 0) {
            return;
        }

        this._wellOneNotificationService.getWellOneDataRefreshStatus().toPromise().then(res => {
            if (!res.result) {
                this.openDataRefreshDialog();
            }
        });
    }

    openDataRefreshDialog(): void {
        this._dataRefreshDialogRef = this._dialog.open(LoadingDialogComponent, {
            minWidth: "100vw",
            minHeight: "100vh",
            disableClose: true,
            data: {
                message: 'WellOne data refresh in progress. Please wait. Thank you.',
            }
        });
    }
}
