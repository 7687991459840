import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more'; //module
HC_more(Highcharts); //init module
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import { formatNumber } from '@angular/common';
import { TitleCasePipe } from '@angular/common';
import { countryLATEM } from 'app/constants/countries_LATAM';
import { NumberFormattingService } from 'app/services/number-formatting.service';
@Component({
  selector: 'app-dual-bar',
  templateUrl: './dual-bar.component.html',
  styleUrls: ['./dual-bar.component.css']
})
export class DualBarComponent implements OnInit, OnChanges {
  chart: Chart;
  options: Options;
  @Input() categories: any;
  @Input() dataListBar1: any;
  @Input() dataListBar2: any;
  @Input() dataListLine: any = [];
  @Input() nameBar1: string = '';
  @Input() nameBar2: string = '';
  @Input() nameLine: string = '';
  @Input() symbol: string = '';
  @Input() decimalPlaces: number = 0;
  @Input() height = 455;
  @Input() country: string;
  @Input() colorBar1 = '#29B0C3';
  @Input() colorBar2 = '#101E7F';
  @Input() showDataLabel: boolean = true;

  ngOnInit() {
    this.initialize();
    this.chart = new Chart(this.options);
  }
  ngOnChanges() {
    this.initialize();
    this.chart = new Chart(this.options);
  }
  constructor(private _numberFormatService: NumberFormattingService) {
  }
  initialize() {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
        //numericSymbols: ["K", "M", "B", "T", "P", "E"]
      }
    });
    const decimalInfo = '0.0-' + this.decimalPlaces.toString();
    const parent = this;

    this.options = {
      chart: {
        type: 'column',
        height: this.height
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        labels: {
          enabled: true,
          rotation: this.categories.length > 10 ? -90 : 0,
          style: {
            color: 'black',
            fontSize: '14px',
            textOutline: 'none',
            fontWeight: '400',
            fontFamily: 'Helvetica Now Text, Arial, Open Sans, Helvetica, sans-serif'
          },
          // formatter: function(){
          //   var xLabel =   new TitleCasePipe().transform(this.value.toString());
          //   return xLabel;
          // }
        },
        categories: this.categories
      },
      yAxis: {
        //height: '110%',
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 1,
        min: 0,
        title: {
          text: ''
        },
        labels: {
          formatter: function () {
            return formatNumber(this.value, 'en-US') + parent.symbol;
            // return countryLATEM.indexOf(parent.country) >= 0 ?
            // parent._numberFormatService.ToAbbrevNumberStringLatem(this.value, 4) + parent.symbol :
            // parent._numberFormatService.ToAbbrevNumberString(this.value, 4)    + parent.symbol        
          },
          style: {
            color: 'black',
            fontSize: '14px',
            textOutline: 'none',
            fontWeight: '400',
            fontFamily: 'Helvetica Now Text, Arial,Open Sans, Helvetica, sans-serif'
          }
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        shared: true,
        useHTML: true,
        headerFormat: '{point.x}<br>',
        pointFormat: this.decimalPlaces == 0 ? '<b>{series.name}</b>: {point.y:,.0f}' + this.symbol + '<br>' :
          (this.decimalPlaces == 1 ? ('<b>{series.name}</b>: {point.y:,.1f}' + this.symbol + '<br>') :
            ('<b>{series.name}</b>: {point.y:,.2f}' + this.symbol + '<br>')),

        style: {
          color: 'black',
          fontSize: '14px',
          textOutline: 'none',
          fontWeight: '400',
          fontFamily: 'Helvetica Now Text, Arial, Open Sans, Helvetica, sans-serif'
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0,
          // pointWidth:20,
          dataLabels: {
            enabled: this.showDataLabel,
            formatter: function () {
              if (this.y > 0) {
                return formatNumber(this.y, 'en-US', decimalInfo) + parent.symbol;

              }
            },
          }
        },
        line: {
          dataLabels: {
            enabled: true,
            align: 'center',
            verticalAlign: 'bottom',
            y: -2,
            formatter: function () {
              if (this.y > 0) {
                return formatNumber(this.y, 'en-US', decimalInfo) + parent.symbol;
              }
            },
            style: {
              fontSize: '13px',
              fontWeight: 'bold',
              color: '#FF803E',
              textOutline: 'none',
              fontFamily: 'Helvetica Now Text, Arial, Open Sans, Helvetica, sans-serif'
            }
          }
        }

      },
      series: [
        {
          type: 'column',
          name: this.nameBar1,
          color: this.colorBar1,
          data: this.dataListBar1,
          dataLabels: {
            align: 'center',
            overflow: 'allow',
            crop: false,
            style: {
              fontSize: '13px',
              color: this.colorBar1,
              textOutline: 'none',
              fontWeight: 'bold',
              fontFamily: 'Helvetica Now Text, Arial, Open Sans, Helvetica, sans-serif'
            }
          }
        }, {
          type: 'column',
          name: this.nameBar2,
          color: this.colorBar2,
          data: this.dataListBar2,
          dataLabels: {
            align: 'center',
            overflow: 'allow',
            crop: false,
            style: {
              fontSize: '13px',
              color: this.colorBar2,
              textOutline: 'none',
              fontWeight: 'bold',
              fontFamily: 'Helvetica Now Text, Arial,Open Sans, Helvetica, sans-serif'
            }
          }
        },
        {
          type: 'line',
          name: this.nameLine,
          color: '#FF803E',
          data: this.dataListLine,
          lineWidth: 0,
          zIndex: 5,
          marker: {
            symbol: 'line',
            radius: 7,
          },
          states: {
            hover: {
              enabled: false,
              lineWidth: 0
            }
          }
        }
      ]
    }
  }

}
