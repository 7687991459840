import {
    Component,
    OnInit,
    SimpleChanges,
    Input,
    Output,
    EventEmitter,
    OnChanges
} from "@angular/core";
import { GridOptions, RowComp } from "ag-grid-community";
import { AgmatcheckboxComponent } from "../agmatcheckbox/agmatcheckbox.component";
import { AgheadermatcheckboxComponent } from "../agheadermatcheckbox/agheadermatcheckbox.component";
import { AgmaticonactionComponent } from "../agmaticonaction/agmaticonaction.component";

@Component({
    selector: "agcustom-grid",
    templateUrl: "./agcustom-grid.component.html",
    styleUrls: ["./agcustom-grid.component.css"]
})
export class AgcustomGridComponent implements OnInit, OnChanges {
    ngOnChanges(changes: SimpleChanges): void {
        
        if(this.maxHeight && this.maxHeight < 46 * this.dataList.length) {
            this.gridOptions.getRowHeight=() => { return this.maxHeight / this.dataList.length + 2 };
            this.gridApi.resetRowHeights();
        }

        if (typeof changes.columnDefs !== "undefined") {
            if (
                typeof changes.columnDefs.previousValue !== "undefined" &&
                typeof changes.columnDefs.currentValue !== "undefined"
            ) {
                if (changes.columnDefs.currentValue.length > 0) {
                    if (
                        this.columnDefs[0].field != "isSelected" &&
                        this.select
                    ) {
                        this.columnDefs.unshift(this.selectColDef);
                    }
                    if (this.move) {
                        this.columnDefs = this.columnDefs.concat(
                            this.moveUpColDef
                        );
                        this.columnDefs = this.columnDefs.concat(
                            this.moveDownColDef
                        );
                    }
                    if (this.edit) {
                        this.columnDefs = this.columnDefs.concat(
                            this.editColDef
                        );
                    }
                    if (this.delete) {
                        this.columnDefs = this.columnDefs.concat(
                            this.deleteColDef
                        );
                    }
                    if (this.visibility) {
                        this.columnDefs = this.columnDefs.concat(
                            this.visibilityColDef
                        );
                    }
                    if (this.getRowClass) {
                        this.gridOptions.getRowClass = this.getRowClass;
                    }

                    var newColumnDefs = JSON.parse(
                        JSON.stringify(this.columnDefs)
                    );
                    this.gridOptions.api.setColumnDefs([]);
                    this.gridOptions.api.setColumnDefs(newColumnDefs);
                    setTimeout(() => {
                        this.gridOptions.api.sizeColumnsToFit();
                    }, 50);
                }
            }
        }
    }

    @Input() dataList: any[] = [];
    @Input() columnDefs: any[] = [];
    @Input() move = false;
    @Input() edit = false;
    @Input() view = false;
    @Input() delete = false;
    @Input() select = false;
    @Input() visibility = false;
    @Input() pagination = false;
    @Input() paginationPageSize = 10;
    @Input() getRowClass: any;
    @Input() maxHeight: any;
    @Input() rowDragManagedValue = false;

    @Output() onReady: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() onMove: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output() onView: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output() onVisibleToggle: EventEmitter<any> = new EventEmitter<any>();

    gridOptions: any;
    gridApi: any;

    selectColDef = {
        headerName: " ",
        width: 100,
        suppressSizeToFit: true,
        cellRenderer: "checkboxRenderer",
        field: "isSelected",
        filter: false
        // headerComponent: "checkboxHeaderRenderer",
        // onHeaderChange: val => {
        //     this.selectAllToggle(val);
        // }
    };

    moveUpColDef = {
        headerName: " ",
        field: "up",
        width: 80,
        suppressSizeToFit: true,
        filter: false,
        cellRendererFramework: AgmaticonactionComponent,
        cellRendererParams: {
            action: row => {
                var index = this.dataList.indexOf(row.data);
                if (index > 0) {
                    this.dataList.splice(index, 1);
                    this.dataList.splice(index - 1, 0, row.data);
                }
                this.gridOptions.rowData = this.dataList;
                this.onMove.emit({ direction: "down", data: this.dataList });
            },
            icon: "keyboard_arrow_up"
        }
    };

    moveDownColDef = {
        headerName: " ",
        field: "down",
        width: 80,
        suppressSizeToFit: true,
        filter: false,
        cellRendererFramework: AgmaticonactionComponent,
        cellRendererParams: {
            action: row => {
                var index = this.dataList.indexOf(row.data);
                if (index < this.dataList.length - 1) {
                    this.dataList.splice(index, 1);
                    this.dataList.splice(index + 1, 0, row.data);
                }
                this.gridOptions.rowData = this.dataList;
                this.onMove.emit({ direction: "down", data: this.dataList });
            },
            icon: "keyboard_arrow_down"
        }
    };

    editColDef = {
        headerName: " ",
        field: "edit",
        width: 80,
        suppressSizeToFit: true,
        filter: false,
        cellRendererFramework: AgmaticonactionComponent,
        cellRendererParams: {
            action: row => this.onEdit.emit({ data: row.data }),
            icon: "edit"
        }
    };

    viewColDef = {
        headerName: " ",
        field: "view",
        width: 80,
        suppressSizeToFit: true,
        filter: false,
        cellRendererFramework: AgmaticonactionComponent,
        cellRendererParams: {
            action: row => this.onView.emit({ data: row.data }),
            icon: "pageview"
        }
    };

    deleteColDef = {
        headerName: " ",
        field: "delete",
        width: 80,
        suppressSizeToFit: true,
        filter: false,
        cellRendererFramework: AgmaticonactionComponent,
        cellRendererParams: {
            action: row => this.onDelete.emit({ data: row.data }),
            icon: "delete"
        }
    };

    visibilityColDef = {
        headerName: " ",
        field: "visibility",
        width: 80,
        suppressSizeToFit: true,
        filter: false,
        cellRendererFramework: AgmaticonactionComponent,
        cellRendererParams: {
            action: row => { 
                row.data.isVisible = !row.data.isVisible;
                row.icon = row.data.isVisible ? 'visibility' : 'visibility_off';
                this.onVisibleToggle.emit({ data: row.data }); 
            },
            icon: "visibility",
            isVisibiityToggle: true
        }       
    };

    constructor() {}

    ngOnInit() {
        this.gridOptions = <GridOptions>{
            onFirstDataRendered: params => {
                params.api.sizeColumnsToFit();
            },
            defaultColDef: {
                filter: true, // set filtering on for all cols,
                sortable: true
            },
            frameworkComponents: {
                checkboxRenderer: AgmatcheckboxComponent,
                checkboxHeaderRenderer: AgheadermatcheckboxComponent,
                iconRenderer: AgmaticonactionComponent
            },
            pagination: this.pagination,
            paginationPageSize: this.paginationPageSize,
            suppressMenuHide: true
        };

        if (this.columnDefs.length > 0) {
            if (this.columnDefs[0].field !== 'isSelected' && this.select) {
                this.columnDefs.unshift(this.selectColDef);
            }
        }
        
        if (this.move) {
            this.columnDefs = this.columnDefs.concat(this.moveUpColDef);
            this.columnDefs = this.columnDefs.concat(this.moveDownColDef);
        }
        if (this.edit) {
            this.columnDefs = this.columnDefs.concat(this.editColDef);
        }
        if (this.view) {
            this.columnDefs = this.columnDefs.concat(this.viewColDef);
        }
        if (this.visibility) {
            this.columnDefs = this.columnDefs.concat(this.visibilityColDef);
        }
        if (this.delete) {
            this.columnDefs = this.columnDefs.concat(this.deleteColDef);
        }
        if (this.getRowClass) {
            this.gridOptions.getRowClass = this.getRowClass;
        }

    }

    onSelectionChanged() {
        const selectedNodes = this.gridApi.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);
        var sel = selectedData[0];
        this.selectionChanged.emit({ data: sel });
    }

    onRowDragEnd(event) {
        this.dataList.splice(event.overIndex, 0, this.dataList.splice(this.dataList.indexOf(event.node.data), 1)[0]);
        this.redrawRows();
        this.onMove.emit({ direction: 'drag', data: this.dataList });
    }

    onGridReady(params) {
        this.gridApi = params.api;
    }

    public toggleSelectAll(isSelectAll: boolean) {
        this.dataList.forEach(c => {
            c.isSelected = isSelectAll;
        });
        this.gridApi.refreshCells();
    }

    public clearSelection() {
        console.log("clearSelection");
        this.gridApi.deselectAll();
        this.gridApi.refreshCells();
    }

    public setSelectedIndex(index) {
        console.log("setSelectedIndex " + index);
        this.gridApi.getRowNode(index).setSelected(true);
        this.gridApi.refreshCells();
    }

    public redrawRows() {
        this.gridOptions.rowData = this.dataList;
        this.gridApi.refreshCells();
    }

    public showLoadingOverLay(show: boolean) {
        if (show) {
            this.gridApi.showLoadingOverlay();
        } else {
            this.gridApi.hideOverlay();
        }
    }
}
