<div id="navBarVertStyle-2" class="navbar-header" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo" id="navBarStyle2Logo">
        <img id="navBarStyle2ImgLogo"class="logo-icon" src="assets/images/logos/fuse.svg">
        <span class="logo-text">FUSE</span>
    </div>

    <button id="navBarStyle2BtnMenu" mat-icon-button class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()" fxHide.lt-lg>
        <mat-icon>menu</mat-icon>
    </button>

    <button id="navBarStyle2BtnArrowBack" mat-icon-button class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon>arrow_back</mat-icon>
    </button>

</div>

<div id="navBarStyle2Fuse" sclass="navbar-content" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}"
     [ngClass]="fuseConfig.layout.navbar.primaryBackground">
    <fuse-navigation id="navBarStyle2FuseNav" layout="vertical"></fuse-navigation>
</div>