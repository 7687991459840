import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FinancialScoreService {

  constructor(private httpClient: HttpClient) { }

  public getFinancialResult(financialParams: any)
  {
    return this.httpClient.post<any>(environment.api + 'api/Financial/financial-score-detail', financialParams);
  }
  
 
}
