export const locale = {
    lang: 'en',
    data: {
        'ageBand': {
            'AgeBandHeading': 'Enrollment By Age Band',
            'Benchmark': 'Benchmark',
            'IncludeClient': 'Include Client',
            'ExcludeClient': 'Exclude Client',
            'Portfolio': 'Portfolio',
            'Industry': 'Industry',
            'None': 'None',
            'MemberCount': 'Member Count Total',
            'AverageAge': 'Average Age',
            'AverageFamilySize': 'Average Family Size',
            'RelationshipDistribution': 'Relationship Distribution',
            'Split': 'Split',
            'PctOfTotalClient': '% of Total Client',
            'EnrollmentTrend': 'Enrollment Trend',
            'Male': 'Male',
            'Female': 'Female',
            'PctOfPopulation': 'of Population',
            'Employee': 'Employee',
            'Child': 'Child',
            'Sibling': 'Sibling',
            'Dimensions': 'Dimension',
            'Gender': 'Gender',
            'Relationship': 'Relationship',
            'Measure': 'Measure',
            'Number': 'Number',
            'PctDistribution': '% Distribution',
            'Dependent': 'Dependent',
            'DateRange': 'to',
            'Others': 'Others',
        }
    }
};
