export const locale = {
    lang: 'es-cl',
    data: {
        'ExecutiveSummary': {
            'ExecutiveSummary': 'Resumen Ejecutivo',            
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'GeneralTrend': 'Tendencia General',
            'Rolling12Basis': '12 Meses Corridos',
            'CurrentPMPM': 'PMPM Actual', 
            'PriorPMPM' : 'Promedio por Usuario Mensual Anterior', 
            'OverallTrend' : 'Tendencia Total', 
            'AverageCost': 'Costo Promedio',
            'Utilization': 'Uso',
            'ServicesReceived': 'Servicios Recibidos',
            'PlanPerformance': 'Desempeño del Plan',
            'YearToDateBasis': 'Año Corrido',
            'YearToDateCBasis': 'Año (C) Corrido',
            'LossRatio': 'Siniestralidad',
            'Headcount': 'POBLACIÓN TOTAL',
            'ClaimsReportedByPaidDate' :  'Reembolsos reportados por fecha de pago',
            'ClaimsReportedByIncurredDate' :  'Reembolsos informadas por fecha incurrida',
            'Claims': 'REEMBOLSOS',
            'Premium': 'PRIMAS',            
            'CombinedLossRatio':'Siniestralidad Combinada',
            'ClaimLabel': 'REEMBOLSOS',
            'PremimumLabel':'PRIMAS SIN FACTOR STOP LOSS',
            'DateRange': 'to'
        }
    }
};
