export const locale = {
    lang: 'en',
    data: {
        'ClaimAgeBand': {
            'ClaimsByAgeBand' : 'Claims By Age Band',            
            'AllAmountsDisplayedInLocalCurrency' : 'All amounts displayed in local currency',  
            'CountBasis':'Count Basis',         
            'Claim' : 'Claim', 
            'Claimant' : 'Claimant',  
            'AgeBandSplit'  :  'Age Band Split'  ,
            'None' : 'None',
            'Gender' : 'Gender',
            'Relationship' : 'Relationship',                
            'Sort' : 'Sort',
            'ClaimsTotal' : 'Claims Total',
            'Count' : 'Count',            
            'AverageCost' :  'Average Cost',
            'DataTable' : 'Data Table',
            'TopDiagnoses' : 'Top Diagnoses',
            'TopProviders' : 'Top Providers',                     
            'TotalPaidClaim' : 'Total Paid Claims', 
            'ClaimantCount':'Claimant Count',
            'ClaimCount':'Claim Count',      
            'ClaimsPMPM' : 'Claims PMPM', 
            'CostPerClaim': 'Cost Per Claim',
            'CostPerClaimant': 'Cost Per Claimant',             
            'ClaimsReportedByPaidDate': 'Claims reported by paid date',
            'ClaimsReportedByIncurredDate': 'Claims reported by incurred date',            
            'FilterNA':'Exclude NA',       
            'Display':'Show As Table',
            'ShowLegend':'Show Legend',          
            'ShownAsPer':'Shown as per',
            'AgeBand': 'Age Band',
            'DateRange': 'to'
        }
    }
};
