import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TopNavbarModule } from 'app/layout/components/topnavbar/topnavbar.module';
import { AonLayoutComponent } from 'app/layout/aon-layout/aon-layout.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [
        AonLayoutComponent
    ],
    imports: [
        RouterModule,
        FuseSharedModule,
        FuseSidebarModule,
        FontAwesomeModule,
        BrowserModule,
        TopNavbarModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        AonLayoutComponent
    ]
})
export class AonLayoutModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
        library.addIconPacks(far);
        library.addIconPacks(fal);
    }
}
