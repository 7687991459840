export const WelloneConstant = {    
    Week : "7 Days",
    Month : "30 Days",
    Quarter : "3 Months",
    All: "All",
    TennantRecordId:'1E95B64A-E681-4564-8D3C-A9E05CE9EA3B',
    LoggedUID:'gkuhn3',
    Success:'Success',
    Error: 'Error',
    ActiveEligible:'Active %',
    EnrolledEligible:'Enrolled %',
    Active:'Active',
    Enrolled:'Enrolled',
    Eligible: 'Eligible',
    ByAge: 'BY AGE',
    ByBusinessUnit: 'BY BUSINESS UNIT',
    ByLocation:'BY COUNTRY',
    OnNotifyingWellOneCacheRefresh: 'onNotifyingWellOneCacheRefresh',
    OnStartingWellOneCacheRefresh: 'onStartingWellOneCacheRefresh',
    OnCompletingWellOneCacheRefresh: 'onCompletingWellOneCacheRefresh',
    SinceJoin:'Since Join'
}
