export const locale = {
    lang: 'es-cl',
    data: {
        'DiseaseDetail': {
            'DiseaseDetailHeading': 'Prevalencia De La Enfermedad',
            'AllAmountInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'SelectTop': 'Seleccionar Top',
            'Diagnosis': 'Detalle de la Prestación',
            'ClaimsTotalHeader': 'Monto Reembolsado Total',    
            'DiagnosisLevel': 'Nivel de Diagnóstico',
            'Category': 'Categoría',               
            'Chapter': 'Capítulo',
            'ClaimsTotal': 'Monto Reembolsado Total',
            'DigClaimantCount': 'Cantidad de Reclamantes', 
            'DiseaseProgression': 'Progresión por Prestación a través del Tiempo',
            'Age': 'Etario', 
            'CostPerClaimant': 'Reembolsado por asegurado',
            'CostPerClaim': 'Costo por Reclamo',
            'ClaimsPerClaimant': 'Monto Reembolsado por reclamante',
            'TotalCost': 'Monto Reembolsado Total',
            'ClaimantCount': 'Cantidad de Reclamantes',
            'Prevalence': 'Prevalencia',
            'Benchmark': 'Benchmark',
            'IncludeClient': 'Incluir cliente',
            'ExcludeClient': 'Excluir cliente',
            'Portfolio': 'portafolio',
            'Industry': 'Industria',
            'None': 'No',
            'AgeBand': 'Rango Etario',
            'Gender': 'Género',
            'Relationship': 'Parentesco',
            'ClaimType' : 'Prestación',
            'ClaimsReportedByPaidDate': 'Reembolsos reportados por fecha de pago',
            'ClaimsReportedByIncurredDate': 'Reembolsos reportadas por fecha incurrida',            
            'ShownAsPer':'Se muestra según',
            'DateRange':'to',
            'DiagnosisGroup': 'Grupo de diagnóstico',
            'Actionable': 'Accionables',
            'Chronic': 'Crónico',
            'Infectious': 'Infeccioso',
            'Trauma/Accidents': 'Accidentes /Trauma',
            'Cancer': 'Cáncer',
            'Mental Health': 'Salud Mental',
            'Obstetrics': 'Obstétricos',
            'Others': 'Otros',
            'Preventable': 'Prevenible',
            'Intervenable': 'Intervenible'
        }
    }
};