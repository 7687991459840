<div *ngIf="data && associatedCoMorbidityList" id="coMorbidityDashboard" style="min-height: 795px; padding: 8px; overflow: auto; position: relative;"
  fxLayout="row">
  <div fxFlex fxLayout="column">
    <div id="coMorbidityHeader" class="dash-header">
        <span id="coMorbidityHeaderText" *ngIf="properties.coMorbidityDiag === 'Diabetes'">{{ 'CoMorbidity.Diabetes' | translate }} +
            {{ 'CoMorbidity.CoMorbidity' | translate }} </span>
        <span id="coMorbidityHeaderText" *ngIf="properties.coMorbidityDiag === 'Hyperlipidemia'">{{ 'CoMorbidity.Hyperlipidemia' | translate }} +
            {{ 'CoMorbidity.CoMorbidity' | translate }} </span>
        <span id="coMorbidityHeaderText" *ngIf="properties.coMorbidityDiag === 'Hypertension'">{{ 'CoMorbidity.Hypertension' | translate }} +
            {{ 'CoMorbidity.CoMorbidity' | translate }} </span>
        <button id="coMorbidityHeaderBtn" mat-icon-button style="float: right" (click)="showFilter = !showFilter"
            *ngIf="!isFilterOptionsLoading">
            <app-filter-button></app-filter-button>
        </button>
    </div>
    <div fxLayout="row" fxLayoutGap="20px" class="paddingUpperBlock" style="height: 200px;">
      <div class="tilePurple" fxFlex="25" style="padding-top:5px;">
        <div fxLayout="row">
          <div fxFlex="50" style="text-align: center;">
            <img src="{{imageUrlBase}}/assets/aonicons/Diabetes-01.svg" style="height: 100px; margin-top: 20px;">
          </div>
          <div fxFlex>
            <div class="headerPurple" *ngIf="properties.coMorbidityDiag === 'Diabetes'">{{ 'CoMorbidity.Diabetes' | translate }}</div>
            <div class="headerPurple" *ngIf="properties.coMorbidityDiag === 'Hyperlipidemia'">{{ 'CoMorbidity.Hyperlipidemia' | translate }}</div>
            <div class="headerPurple" *ngIf="properties.coMorbidityDiag === 'Hypertension'">{{ 'CoMorbidity.Hypertension' | translate }}</div>
            <div style="color: rgb(108, 43, 135); font-size:12px;">{{ 'CoMorbidity.PercentageOfTotalClaims' | translate }}</div>
            <div style="margin-top:-2px;">&nbsp;</div>
            <span class="perText">{{conditionPercentage}}%</span>
          </div>
        </div>
      </div>
      <div class="tileGrey" fxFlex="45">
        <div fxLayout="row">
          <div fxFlex="30" style="text-align: center;">
            <img src="{{imageUrlBase}}/assets/aonicons/AssociatedCo-morbidities.svg" 
            style="height: 100px; margin-top: 20px;">
          </div>
          <div fxFlex="70" style="padding-bottom: 2px; margin-bottom: -10px;">
            <div fxLayout="row" class="headerGrey">{{ 'CoMorbidity.AssociatedCo' | translate }}</div>
            <div style=" height: 120px; overflow-x: scroll;">
              <div fxLayout="row">
                <div *ngIf="associatedCoMorbidityList[0]" [fxFlex]="50"> <span class="dot"></span><span
                    style="color: white; font-size: 12px;">{{associatedCoMorbidityList[0]}}</span></div>
                <div *ngIf="associatedCoMorbidityList[5]" [fxFlex]="50"> <span class="dot"></span><span
                    style="color: white; font-size: 12px;">{{associatedCoMorbidityList[5]}}</span></div>
              </div>
              <div fxLayout="row">
                <div *ngIf="associatedCoMorbidityList[1]" [fxFlex]="50"> <span class="dot"></span><span
                    style="color: white; font-size: 12px;">{{associatedCoMorbidityList[1]}}</span></div>
                <div *ngIf="associatedCoMorbidityList[6]" [fxFlex]="50"> <span class="dot"></span><span
                    style="color: white; font-size: 12px;">{{associatedCoMorbidityList[6]}}</span></div>
              </div> 
              <div fxLayout="row">
                <div *ngIf="associatedCoMorbidityList[2]" [fxFlex]="50"> <span class="dot"></span><span
                    style="color: white; font-size: 12px;">{{associatedCoMorbidityList[2]}}</span></div>
                <div *ngIf="associatedCoMorbidityList[7]" [fxFlex]="50"> <span class="dot"></span><span
                    style="color: white; font-size: 12px;">{{associatedCoMorbidityList[7]}}</span></div>
              </div> 
              <div fxLayout="row">
                <div *ngIf="associatedCoMorbidityList[3]" [fxFlex]="50"> <span class="dot"></span><span
                    style="color: white; font-size: 12px;">{{associatedCoMorbidityList[3]}}</span></div>
                <div *ngIf="associatedCoMorbidityList[8]" [fxFlex]="50"> <span class="dot"></span><span
                    style="color: white; font-size: 12px;">{{associatedCoMorbidityList[8]}}</span></div>
              </div> 
              <div fxLayout="row">
                <div *ngIf="associatedCoMorbidityList[4]" [fxFlex]="50"> <span class="dot"></span><span
                    style="color: white; font-size: 12px;">{{associatedCoMorbidityList[4]}}</span></div>
                <div *ngIf="associatedCoMorbidityList[9]" [fxFlex]="50"> <span class="dot"></span><span
                    style="color: white; font-size: 12px;">{{associatedCoMorbidityList[9]}}</span></div>
              </div>        
            </div>
          </div>
        </div>
      </div>
      <div class="tileLightBlue" fxFlex="30">
        <div fxLayout="row">
          <div fxFlex="100" class="headerLightBlue" *ngIf="properties.coMorbidityDiag === 'Diabetes'">{{ 'CoMorbidity.Diabetes' | translate }} + </div>
          <div fxFlex="100" class="headerLightBlue" *ngIf="properties.coMorbidityDiag === 'Hyperlipidemia'">{{ 'CoMorbidity.Hyperlipidemia' | translate }} + </div>
          <div fxFlex="100" class="headerLightBlue" *ngIf="properties.coMorbidityDiag === 'Hypertension'">{{ 'CoMorbidity.Hypertension' | translate }} + </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="100" class="headerLightBlue"> {{ 'CoMorbidity.AssociatedCo' | translate }}</div>
        </div>
        <div fxLayout="row">
          <div fxFlex="50">&nbsp;</div>
          <div fxFlex="50">
            <div style="color: rgb(1, 143, 179); font-size:12px; top:0px; position:relative">{{ 'CoMorbidity.PercentageOfTotalClaims' | translate }}</div>
          </div>
        </div>       
        
        <div fxLayout="row"  >
          <div fxFlex="50" style="text-align: center;">
            <img src="{{imageUrlBase}}/assets/aonicons/total-01.svg" style="height: 85px; padding: 5px; margin-top:-15px;">
          </div>
          <div fxFlex >
            <div fxLayout="column">             
              
              <div ><span style="bottom: 10px;position: relative;" class="perText">{{conditionMorbiditiesPercentage}}%</span></div>
            </div>            
          </div>
        </div>
      </div>

    </div>
    <div fxLayout="row" fxLayoutGap="20px" class="paddingOtherBlock" style="height: 190px;">
      <div fxFlex="40" class="tileWhite">
        <div fxLayout="row" style="padding-top:20px;">
          <div fxFlex="50" style="text-align: center;">
            <img src="{{imageUrlBase}}/assets/aonicons/supervisor_account.svg" style="height: 150px; margin: -30px 10px 10px 10px;">
            <!-- <span class="material-icons" style="height: 90px; margin: 20px 10px 10px 10px;">
              supervisor_account
              </span>  -->
          </div>
          <div fxFlex="50" style="padding-top: 10px;">
            <div class="claimVal">{{claimantCount | number}}</div>
            <div class="unitTxt">{{ 'CoMorbidity.Claimants' | translate}}</div>
          </div>
        </div>
      </div>
      <div fxFlex="60" class="tileWhite">
        <div fxLayout="row">
          <div fxFlex="60" style="height: 160px">
            <app-pie-image [title]='genderTitle' [label1]='maleLabel' [label2]='femaleLabel' [imageURL]="imageurl" [percentage]="malePercentage" [percentage2]="femalePercentage" [colorPurple]="colorPurple"
              [colorBlue]="colorBlue">
            </app-pie-image>
          </div>
          <div fxFlex="40">
            <div style="font-size:20px; font-weight:bold; padding-top:35px;">{{ 'CoMorbidity.AverageAge' | translate}}
            </div>
            <div style="padding: 5px;"><span class="claimVal">{{averageAge}}</span>
              <span class="unitTxt"> &nbsp;{{ 'CoMorbidity.Years' | translate}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" class="paddingOtherBlock">
      <div [fxFlex]="100" fxLayout="column" class="tileWhite">
        <ng-container *ngIf="parentLoaded">
          <div id="coLegends" style="padding:10px">
            <div fxLayout="row" style="font-size: 15px;">
              <div fxFlex="10" [ngStyle]="{'color': colorBlue}" style="font-size: 18px; font-weight: bold;">
                {{'CoMorbidity.ClaimType' | translate}}</div>             
            </div>
          </div>          
          <div>
            <app-bar-stacked style="height:100px;" 
            [headerText] ="topBarTitle"
            [isShowPercentageSymbol] ="showPercentage"
            [showAllPercentage] ="showPercentage"
              [stackBarList]="conditionstackBarList"></app-bar-stacked>
          </div>
           <div>
            <app-bar-stacked style="height:100px;" 
            [headerText] ="bottomBarTitle"
            [isShowPercentageSymbol] ="showPercentage"
            [showAllPercentage] ="showPercentage"
              [stackBarList]="conditionComorbiditiesstackBarList"></app-bar-stacked>
          </div>
             
        </ng-container>
      </div>
      
    </div>
   
  </div>
  <div style="width: 200px; padding: 80px 0px 0px 16px; border-left: 1px solid #EEE" fxLayout="column">
    <div id="entityDivisionFilters" *ngIf="showFilter">
      <app-additional-filters [country]="properties.country" (action)="additionalFilterSelectionChanged($event)"
        [filterOptions]='filterOptions' [isClientName]='true' [isEntity]='true' [isPolicyType]='true'></app-additional-filters>
    </div>
    <div id="coMorbidityFilters" fxFlex="55" *ngIf="!showFilter">
      <div id="coMorbidityBtn1" fxLayout="row" fxLayoutAlign="center center" class="dash-option"
        [ngClass]="{'dash-option-selected': properties.coMorbidityDiag == DiagnosisOptionsCo.Diabetes}"
        (click)="filterChanged(DiagnosisOptionsCo.Diabetes, 'coMorbidityDiag')">
        <span>{{ 'CoMorbidity.Diabetes' | translate }}</span>
      </div>
      <div id="coMorbidityBtn1" fxLayout="row" fxLayoutAlign="center center" class="dash-option"
        [ngClass]="{'dash-option-selected': properties.coMorbidityDiag == DiagnosisOptionsCo.Hyperlipidemia}"
        (click)="filterChanged(DiagnosisOptionsCo.Hyperlipidemia, 'coMorbidityDiag')">
        <span>{{ 'CoMorbidity.Hyperlipidemia' | translate }}</span>
      </div>
      <div id="coMorbidityBtn1" fxLayout="row" fxLayoutAlign="center center" class="dash-option"
        [ngClass]="{'dash-option-selected': properties.coMorbidityDiag == DiagnosisOptionsCo.Hypertension}"
        (click)="filterChanged(DiagnosisOptionsCo.Hypertension, 'coMorbidityDiag')">
        <span>{{ 'CoMorbidity.Hypertension' | translate }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" >
        <div fxLayout="column">
          <br />
          <div *ngFor="let leg of legendList" style="margin-bottom: 5px;">
            <svg width="15"  height="15">
                <rect width="15"  height="15" [attr.fill]="leg.color"></rect>
                    </svg>
                    <span class="legendElp">{{(leg.name)}}</span> <br>
        </div>
        </div>
      </div>
      
    </div>
  </div>
</div>