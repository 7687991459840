
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import * as Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more"; //module
HC_more(Highcharts); 

@Component({
  selector: 'app-column-line',
  templateUrl: './column-line.component.html',
  styleUrls: ['./column-line.component.css']
})
export class ColumnLineComponent implements OnInit, OnChanges {

  chart: Chart;
  options: Options;
  @Input() dataList: any;
  @Input() dataList2: any;
  @Input() unit: string;
  @Input() textC: string;
  @Input() barColor: string;
  @Input() dotColor: string;
  @Input() benchMark: any;
  @Input() categories: any;
  @Input() ShowLossBar:boolean ;
  @Input() MaxOfY: number;
  @Input() Monthly:boolean;  
  @Input() plotLinesX:any;
  @Input() MonthlyName:any;  
  @Input() CummulativeName:any;
  ngOnInit() {
    this.initialize();
    this.chart = new Chart(this.options);
  }
  ngOnChanges() {
    this.initialize();
    this.chart = new Chart(this.options);
  }

  initialize() {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      }
    });
    var lengthList = this.categories.length >12 ? true :false
    this.options = {
      chart: {
        type: '',
        height: 300,
        marginLeft: 90
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        labels: {
          enabled: true,
          rotation: this.categories.length>10 ? -90 :0,
           style: {
            fontSize:  this.Monthly ?'11px' :'13px',
            fontWeight: '400',
            color: 'black',
            textOutline: 'none',
            fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif'
          }
        },
        visible: true,
        categories: this.categories,
        plotLines: this.plotLinesX
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 1,
        softMin:0,
        max:this.MaxOfY,
        
        title: {
          text: this.textC
        },

        labels: {
          enabled: true,
          formatter: function () {
            return this.value + '%'
          }, style: {
            fontSize: this.Monthly ?'11px' :'13px',
            fontWeight: '400',
            color: 'black',
            textOutline: 'none',
            fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif'
          }

        },
        plotLines: [{
          color: 'rgb(148, 145, 145)',
          width: 1,
          value: this.benchMark,
          zIndex: 5,
          label: {
            text: this.benchMark.toString() + '%'
          },
          dashStyle:'Dash',
        },
        {
          value: 0,
          width: 1,
          color: '#aaa',
          zIndex: 10
        }
      ]
      },
      // to hide series name
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        shared:true,
        headerFormat: "{point.x}<br>",
        pointFormat: "<b>{series.name}</b>: {point.y:,.1f} %<br>",
        style: {
          color: 'black',
          fontSize: '14px',
          textOutline: 'none', 
          fontWeight: '400',          
          fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif'
        }
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1
            }
          }
        },
        line: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              if(this.y >0)
              return this.y.toFixed(1) + '%'
              
            },
            y: -5,
            style: {
              fontSize:  this.Monthly ?'11px' :'13px',
              fontWeight: '400',
              color: 'black',
              textOutline: 'none',              
             fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif'
            }

          }
        },
        column: {
          dataLabels: {
            enabled: false,
            //enabled: true,            
            // inside:true, verticalAlign:'bottom',
            //  // rotation: 90 ,
            //   formatter: function () {
            //     return this.y +'%' 
            //   },
            //   style: {
            //     fontSize: '11px',
            //     fontWeight: 'bold',
            //     color: 'black',
            //     textOutline: 'none',
            // } 
          }
        }

      },
      series: [{
        type: 'column',
        name: this.MonthlyName,
        visible: this.ShowLossBar,
        pointWidth: 24,pointPadding: .3,
        color: this.barColor,
        data: this.dataList
      },
      {
        type: 'line',
        name: this.CummulativeName,
        color: this.dotColor,
        data: this.dataList2,
        lineWidth: 0,
        marker: {
          symbol: 'circle',
          radius: 9,
        },
        states: {
          hover: {
            enabled: false,
            lineWidth: 0
          }
        }

      }]
    }
  }
}