export const locale = {
    lang: 'es-ec',
    data: {
      'AddFilter': {
          'ClientName': 'Nombre del Cliente',
          'Entity': 'Entidad',
          'Division': 'Division', 
          'Plan': 'Plan',
          'TimePeriod': 'Periodo de tiempo',                
          'PolicyType': 'Tipo de poliza',
          'AgeBand': 'Rango de Edad',
          'Gender': 'Genero',
          'ClaimType': 'Tipo de Servicio',
          'ClaimType2': 'Tipo de Servicio 2',
          'ClaimType3': 'Tipo de Servicio 3',
          'Relationship': 'Parentesco',
          'DiagnosisCategory': 'Diagnóstico',
          'DiagnosisChapter': 'Agrupador CIE - 10',
          'Location': 'Ubicación',
          'IsChronic': 'Crónico',
          'Carrier': 'Portador',
          'LOB': 'Línea de Negocio',
          'SubLOB': 'NA',
          'Network': 'Red',
          'ProviderName': 'Nombre del Prestador',
          'ProviderLocation': 'Ubicación del Proveedor',
          'ProviderType': 'Tipo de proveedor'
      }
  }
};
