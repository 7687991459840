import { Component, OnInit } from '@angular/core';
import { LibraryService } from 'app/services/library.service';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-libraryaddadmin',
  templateUrl: './libraryaddadmin.component.html',
  styleUrls: ['./libraryaddadmin.component.css']
})
export class LibraryaddadminComponent implements OnInit {
    libraryFilter: string;

    unselectedLibraries: any[];

    unselectedLibraryGridColDefs: any[] = [
        { headerName: 'Name', field: 'name' },
        { headerName: 'Created At', field: 'createdAt' },
        { headerName: 'Updated At', field: 'updatedAt' }
    ];

    constructor(
        private _router: Router,
        private _libraryService: LibraryService,
        private _toasterService: ToasterService) { }

    ngOnInit() {
        this.libraryFilter = '';

        this.unselectedLibraries = [];

        this._libraryService.listUnselectedLibraries().toPromise().then(result => {
            console.log(result);
            this.unselectedLibraries = result;
        });
    }

    onAddWorkbooks() {
        const selectedWorkbookList: any[] = [];

        this.unselectedLibraries.forEach(unselectedLibrary => {
            unselectedLibrary.libraryItemList.forEach(libraryItem => {
                if (libraryItem.isSelected) {
                    selectedWorkbookList.push(libraryItem);
                }
            });
        });

        this._libraryService.addLibraryItems(selectedWorkbookList).toPromise().then((result: boolean) => {
            if (result) {
                this._toasterService.showMessage('Library Items Successfully Added. Redirecting...', ToasterServiceModes.OK);
                setTimeout(() => {
                    this._router.navigate(['/admin/library']);
                }, 2000);
            }
            else {
                this._toasterService.showMessage('Error. Adding of Library Items Failed.', ToasterServiceModes.NOK);
            }
        });
    }
}
