export const locale = {
    lang: 'es-co',
    data: {
      'ClaimCostTopProvider': {
        'ClaimTopProviders' : 'Gasto - Por Top Prestadores',        
        'ClaimCostByTopDiagnoses':'Gasto - Por Top Diagnósticos',
        'ProviderDiag' : 'Prestador v Diagnóstico',
        'AllAmountsDisplayedInLocalCurrency' : 'Todas las cantidades mostradas en moneda local',
        'TopProvider' : 'Proveedores principales',
        'ProviderName': 'Nombre del Prestador', 
        'TopDiagnoses': 'Top Diagnósticos',
        'DiagnosisName': 'Nombre del Dignóstico' ,          
        'TotalPaidClaim' : 'Costo Total Pagado', 
        'ClaimantCount': 'Número de Reclamantes',
        'ClaimCount': 'Número de Reclamos',            
        'ClaimsPerClaimant' : 'Reclamos Por Reclamante', 
        'CostPerClaim': 'Costo Por Reclamo',
        'CostPerClaimant': 'Costo Por Reclamante', 
        '%ChangeinCost':'% De cambio en el costo',
        'Claim' : 'Reclamo', 
        'Claimant' : 'Reclamante', 
        'ClaimsTotal':'Total Reclamos',
        'Count': 'Contar',
        'AverageCost': 'Costo Promedio',
        'ClaimsReportedByPaidDate': 'Reclamaciones reportadas por fecha de pago',
        'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
        'Benchmark':'Benchmark',
        'IncludeClient': 'Incluir Cliente',
        'ExcludeClient': 'Excluir Cliente',
        'Portfolio': 'Portafolio',
        'Industry': 'Industria',
        'None': 'Ninguna',
        'SelectTop': 'Seleccionar Top',
        'FilterNA': 'Excluir NA',
        'CountBasis': 'Contar Base',
        'Sort': 'Ordenar',
        'Reset':'Reiniciar',            
        'DiagnosisLevel':'Nivel de Diagnóstico',
        'Category':'Categoría',
        'Chapter':'Capítulo',
        'ShownAsPer':'Se muestra según',
        'DateRange':'to'
        
    }
}
};
