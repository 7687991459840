export const locale = {
  lang: 'it',
  data: {
  'Storyboard': {
    'AddAll': 'Inserire tutti',
    'Save': 'Salva',
    'Export': 'Esporta',
    'Library': 'Biblioteca',
    'Comments': 'Commenti',
    'CustomViews': 'Viste personalizzate',
    'CustomViewName': 'Nome vista personalizzata',
    'ApplyCustomView': 'Applica vista personalizzata',
    'SaveCustomView': 'Salva visualizzazione personalizzata',
    'ResetCustomView': 'Ripristina visualizzazione personalizzata',
    'NoCustomViewFound': 'Nessuna visualizzazione personalizzata trovata',
    'AddToExport': 'Aggiungi a export',
    'DownloadImage': 'Scarica immagine',
    'ADD': 'ADD',
    'SAVE': 'SALVA',
    'CANCEL': 'CANCEL'
  }
}};