export const locale = {
    lang: 'it',
    data: {
  'ageBand': {
    'AgeBandHeading': 'Anagrafiche per fascia di età',
    'Benchmark': 'Benchmark',
    'IncludeClient': 'Includere cliente',
    'ExcludeClient': 'Escludi cliente',
    'Portfolio': 'Portafoglio',
    'Industry': 'Industria',
    'None': 'Nessuno',
    'MemberCount': 'Conteggio Iscritti Totale',
    'AverageAge': 'Età media',
    'AverageFamilySize': 'Dimensione famiglia media',
    'RelationshipDistribution': 'Distribuzione delle relazioni',
    'Split': 'Diviso',
    'PctOfTotalClient': '% del totale clienti',
    'EnrollmentTrend': 'Trend Iscritti',
    'Male': 'Uomo',
    'Female': 'Femmina',
    'PctOfPopulation': 'di Popolazione',
    'Employee': 'Dipendente',
    'Child': 'Bambino',
    'Sibling': 'Fratello',
    'Dimensions': 'Dimensione',
    'Gender': 'Genere',
    'Relationship': 'Relazione',
    'Measure': 'Misura',
    'Number': 'Numero',
    'PctDistribution': '% di distribuzione',
    'Dependent': 'Dipendente',
    'DateRange': 'Intervallo Di Date',
    'Others': 'Altri'
  }
}};