import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(private httpClient: HttpClient) { }

  public getQueue()
  {      
    return this.httpClient.get<any>(environment.api + 'api/export/queue');
  }

  public downloadFile(fileId, userId) {
    let promise = new Promise((resolve, reject) => {
      var url = environment.api + 'api/export/download/' + fileId + '/' + userId;
      var iframe;
      iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);
      // clean up iframe
      iframe.onload = function () {
        this.parentNode.removeChild(this);
      };

      var counter = 0;
      var token = '';

      var interval = setInterval(() => {
        token = this.getCookie(fileId);
        console.log(token);
        if (token) {
          clearInterval(interval);
          this.deleteCookie(fileId);
          resolve(); // promise success
        };

        counter++;
        if (counter === 30000) {
          clearInterval(interval);
          this.deleteCookie(fileId);
          reject(); // promise fail
        }
      }, 2000);
    });
    return promise;
  }

  deleteCookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  };

  getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
        }
        return "";
  };
  
  exportToPpt(languageId: string, emailAddress: string, clientName: string, titleName: string, dashboardList: any[])
  {      
    return this.httpClient.post<any>(environment.api + 'api/export/ppt', { languageId: languageId, emailAddress: emailAddress, siteName: environment.siteName, clientName: clientName, titleName: titleName, dashboardInputsList: dashboardList });
  };

  exportToImage(workbookId: string, viewId: string, filterList: string[], filterString: string)
  {
    console.log(workbookId);
    return this.httpClient.post<any>(environment.api + 'api/export/image', { siteName: environment.siteName, workbookId: workbookId, viewId: viewId, filterList: filterList, filterString: filterString });
  };
}

