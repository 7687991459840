export const locale = {
  lang: 'es-mx',
  data: {
    'Storyboard': {
        'AddAll': 'Agregar todos',
        'Save': 'Guardar',
        'Export': 'Exportar',
        'Library': 'Librería',
        'Comments': 'Comentarios',
        'CustomViews': 'Vistas personalizadas',
        'CustomViewName': 'Nombre vista personalizada',
        'ApplyCustomView': 'Aplicar vista personalizada',
        'SaveCustomView': 'Guardar vista personalizada',
        'ResetCustomView': 'Restaurar vista personalizada',
        'NoCustomViewFound': 'No se encontró la vista personalizada',
        'AddToExport': 'Agregar a exportación',
        'DownloadImage': 'Descargar imagen',
        'ADD': 'AGREGAR',
        'SAVE': 'GUARDAR',
        'CANCEL': 'CANCELAR'
    }
  }
};
