<div
    id="doubleQDashboard"
    style="min-height: 795px; padding: 8px; overflow: auto; position: relative;"
    fxLayout="row"
>
    <div
        fxFlex
        fxLayout="column"
    >
        <div
            id="doubleQHeader"
            class="dash-header"
        >
            <span id="doubleQTxtHeader">{{ 'DoubleQuad.DoubleQuadHeading' | translate }}</span>
            <button
                id="doubleQBtnHeader"
                mat-icon-button
                style="float: right"
                (click)="showFilter = !showFilter"
                *ngIf="!isFilterOptionsLoading"
            >
                <app-filter-button
                    [isClientName]='true'
                    [isEntity]='true'
                    [isPolicyType]='true'
                    [isPlan]='true'
                    [isClaimType]='true'
                    [isClaimType2]='true'
                    [isClaimType3]='true'
                    [isProviderType]='true'
                    [isGender]='true'
                    [isRelationship]='true'
                    [isAgeBand]='true'
                    [isLocation]='true'
                    [isLOB]='true'
                    [isSubLOB]='true'
                ></app-filter-button>
            </button>
        </div>
        <div fxLayout="row">
            <div
                id="doubleQHeaderCurrency"
                style="font-size: 13px; font-style: italic; padding: 3px"
                fxFlex="90%"
            >
                {{ 'DoubleQuad.AllAmountsDisplayedInLocalCurrency' | translate }}
            </div>
            <div
                id="doubleQHeaderPeriod"
                style="font-size: 13px; font-style: italic; padding: 3px"
                fxFlex="10%"
            >
                {{ currentTimePeriodLable }} {{comparableTimePeriodLabel}}
            </div>
        </div>
        <div fxLayout="row">
            <div [fxFlex]="40">
                <div
                    id="doubleQTitleDiag"
                    class="innerHeader"
                >{{ 'DoubleQuad.Diagnoses' | translate }}
                </div>
                <div
                    *ngIf="data"
                    id="doubleQSelectPro"
                    class="innerNote"
                    [style.visibility]='data.shownAsPerProvider !="" ? "visible" : "hidden" '
                >
                    {{ 'DoubleQuad.ShownAsPer' | translate }} &nbsp; "{{data.shownAsPerProvider}}"
                </div>
            </div>
            <div
                id="doubleQSelectTop"
                [fxFlex]="20"
            >
                <span>{{ 'DoubleQuad.SelectTop' | translate }}</span>
                <input
                    type="number"
                    [(ngModel)]="properties.doubleQuadrantProDiagTopFilter"
                    class="topInput"
                    min="0"
                    (ngModelChange)="filterTopList()"
                >
            </div>
            <div [fxFlex]="40">
                <div
                    id="doubleQTitlePro"
                    class="innerHeader"
                >{{ 'DoubleQuad.Providers' | translate }}
                </div>
                <div
                    *ngIf="data"
                    id="doubleQSelectDiag"
                    class="innerNote"
                    [style.visibility]='data.shownAsPerDiagnosis !="" ? "visible" : "hidden" '
                >
                    {{ 'DoubleQuad.ShownAsPer' | translate }} &nbsp; "{{data.shownAsPerDiagnosis}}"
                </div>
            </div>
        </div>
        <div fxLayout="row">
            <div [fxFlex]="50">
                <tableau-spinner
                    *ngIf="isLoadingDiagnoses"
                    style="position: absolute; top: 300px; left: 25%; z-index: 90"
                ></tableau-spinner>
                <ng-container
                    id="doubleQChartDiag"
                    *ngIf="parentLoaded && chartListDiag "
                >
                    <app-bubble
                        (actionBubble)="selectDiagnosis($event)"
                        [dataList]="chartListDiag.chartData"
                        [labelXaxis]="chartListDiag.labelXaxis"
                        [labelYaxis]="chartListDiag.labelYaxis"
                        [labelZaxis]="chartListDiag.labelZaxis"
                        [maxX]="chartListDiag.maxX"
                        [maxY]="chartListDiag.maxY"
                        [doubleQuadrantDash]="true"
                        [country]="properties.country"
                    ></app-bubble>
                </ng-container>
            </div>
            <div [fxFlex]="50">
                <tableau-spinner
                    *ngIf="isLoadingProvider"
                    style="position: absolute; top: 300px; left: 65%; z-index: 90"
                ></tableau-spinner>
                <ng-container
                    id="doubleQChartPro"
                    *ngIf="parentLoaded && chartListPro  "
                >
                    <app-bubble
                        (actionBubble)="selectProviders($event)"
                        [dataList]="chartListPro.chartData"
                        [labelXaxis]="chartListPro.labelXaxis"
                        [labelYaxis]="chartListPro.labelYaxis"
                        [labelZaxis]="chartListPro.labelZaxis"
                        [maxX]="chartListPro.maxX"
                        [maxY]="chartListPro.maxY"
                        [doubleQuadrantDash]="true"
                        [country]="properties.country"
                    ></app-bubble>
                </ng-container>
            </div>
        </div>
        <div
            fxLayout="row"
            style="margin-top: 10px"
        >
            <div [fxFlex]="80"></div>
            <div
                id="doubleQFooterNote"
                [fxFlex]="20"
                style="text-align: right; margin-right: 10px;"
            >
                {{properties.claimPaid === CLAIM_BASIS.PAID_BASIS ?
                ( 'DoubleQuad.ClaimsReportedByPaidDate' | translate)
                : ( 'DoubleQuad.ClaimsReportedByIncurredDate' | translate )}}
            </div>
        </div>
    </div>
    <div
        style="width: 200px; padding: 80px 0px 0px 16px; border-left: 1px solid #EEE"
        fxLayout="column"
    >
        <div
            id="entityDivisionFilters"
            *ngIf="showFilter"
        >
            <app-additional-filters
                [country]="properties.country"
                (action)="additionalFilterSelectionChanged($event)"
                [filterOptions]='filterOptions'
                [isClientName]='true'
                [isEntity]='true'
                [isPolicyType]='true'
                [isPlan]='true'
                [isClaimType]='true'
                [isClaimType2]='true'
                [isClaimType3]='true'
                [isProviderType]='true'
                [isGender]='true'
                [isRelationship]='true'
                [isAgeBand]='true'
                [isLocation]='true'
                [isLOB]='true'
                [isSubLOB]='true'
            >
            </app-additional-filters>
        </div>
        <div
            id="doubleQFilters"
            fxFlex="55"
            *ngIf="!showFilter"
        >

            <div
                id="doubleQBtnFilterNA"
                fxLayout="row"
                fxLayoutAlign="center center"
                style="margin-top:5px;"
                (click)="filterNAChange('filterNA', 'filterNA')"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.isExcludeNA === true }"
            >
                <span>{{ 'DoubleQuad.FilterNA' | translate }}</span>
            </div>
            <div
                id="doubleQTxtCountBasis"
                fxLayout="row"
                style="margin-top:10px; margin-bottom: 5px;"
            >
                <span>{{ 'DoubleQuad.CountBasis' | translate }}</span>
            </div>
            <div
                id="doubleQBtnClaim"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.claimTypeOption == ClaimantOption.Claim}"
                (click)="filterChanged(ClaimantOption.Claim, 'claimTypeOption')"
            >
                <span>{{ 'DoubleQuad.Claim' | translate }}</span>
            </div>
            <div
                id="doubleQBtnClaimant"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.claimTypeOption == ClaimantOption.Claimant}"
                (click)="filterChanged(ClaimantOption.Claimant, 'claimTypeOption')"
            >
                <span>{{ 'DoubleQuad.Claimant' | translate }}</span>
            </div>
            <div
                id="doubleQBtnDiagnoses"
                fxLayout="row"
                style="margin-top:20px; margin-bottom: 10px;"
            >
                <span>{{ 'DoubleQuad.Diagnoses' | translate }}</span>
            </div>
            <div id="doubleQBtnMultiSelect">
                <aon-multi-select
                    scrollable=true
                    width='100%'
                    [selectedvalues]='selectedvaluesList'
                    (onselectionchange)='diagSelect($event)'
                >
                    <aon-multi-select-option
                        *ngFor="let diag of valuesList"
                        width='100%'
                        [value]='diag'
                    >{{diag}}</aon-multi-select-option>
                </aon-multi-select>
            </div>
        </div>
    </div>
</div>