import { Component, OnInit, Input } from '@angular/core';
import { NumberFormattingService } from 'app/services/number-formatting.service';

@Component({
  selector: 'charts-bar-solo',
  templateUrl: './bar-solo.component.html',
  styleUrls: ['./bar-solo.component.css']
})
export class BarSoloComponent implements OnInit {

  @Input() value = 0;
  @Input() value2 = 0;
  @Input() valueDecimalPlace = 0;
  @Input() unit = 'M';
  @Input() max = 0;
  @Input() barColor = 'red';
  @Input() height = '50px';
  @Input() country = '';
  @Input() benchmark = -1;
  @Input() frontSize = '16px';
    constructor( private _numberFormatService: NumberFormattingService) { 
  }
  ngOnInit() {
  }

  getSVGPercentage() {
    if(this.max == 0 ||this.max == null )
    {
      return 0;
    }
    return '' + (this.value / this.max) * 100 + '%'
  }

  getSVGPercentage2() {
    if(this.max == 0 ||this.max == null )
    {
      return    0 ;
    }
    return '' + (this.value2 / this.max) * 100 + '%'
  }

  getSVGPercentageBenchmark() {
    if(this.max == 0 ||this.max == null )
    {
      return    0 ;
    }
    return '' + (this.benchmark / this.max) * 100 + '%'
  }

  getSVGPercentValueAbove10() {
    if(this.value2 == 0||this.value2 == null  )
    {
      return 0;
    }
    var interimValue =  parseFloat(((this.value / this.value2) * 100).toFixed(0)) ;
    
    if(interimValue >= 10)
      {
        return this.isCountryLATEM() ?  
      this._numberFormatService.ToAbbrevNumberStringLatem(interimValue, this.valueDecimalPlace )  :
      this._numberFormatService.ToAbbrevNumberString(interimValue, this.valueDecimalPlace ) ;
    // ` ${ this._numberFormatService.ToAbbrevNumberString(this.value, this.valueDecimalPlace )} (${((this.value / (this.max)) * 100).toFixed(1)} %)`;
      }
      else
      {return 0;}
  }
  getSVGPercentValueAbove90() {
    if(this.value2 == 0||this.value2 == null || this.value ==0  )
    {
      return 0;
    }
    return parseFloat(((this.value / this.value2) * 100).toFixed(0)) <= 80 ;
  }
  
  private isCountryLATEM(): boolean
  {
      const countryLATEM = ['Chile', 'Colombia', 'Ecuador', 'Mexico', 'Peru'];

      return countryLATEM.indexOf(this.country) >= 0;
  }

  getValue2Formatted()
  { 
    if(this.unit == '%'){
      return this.value2 + '%';
    }
    return this.isCountryLATEM() ?  
    this._numberFormatService.ToAbbrevNumberStringLatem(this.value2 )  :
    this._numberFormatService.ToAbbrevNumberString(this.value2 ) ;
  }
  getValueFormatted()
  { 
    if(this.unit == '%'){
      return this.value + '%';
    }
    return this.isCountryLATEM() ?  
    this._numberFormatService.ToAbbrevNumberStringLatem(this.value )  :
    this._numberFormatService.ToAbbrevNumberString(this.value ) ;
  }

}
