import { Component, OnInit, Input, OnChanges, ElementRef, Output, EventEmitter } from '@angular/core';
import { COLOR_PALETTE } from 'app/constants/color-palette';
import * as d3 from 'd3';

@Component({
  selector: 'app-donut-pop',
  templateUrl: './donut-pop.component.html',
  styleUrls: ['./donut-pop.component.css']
})
export class DonutPopComponent implements OnInit, OnChanges {
  constructor(private elementRef: ElementRef) { }
  @Input() elementRefPre: ElementRef; //for legend
  @Input() totalDountData: any;
  @Input() datalist: any;

  @Input() count: number;

  @Output() countChanged: EventEmitter<number> = new EventEmitter();


  pie: any;
  color: any;
  shrink: any;
  radius: any;
  labelr: any;
  svgLeg: any;
  svg: any

  ngOnChanges() {
    d3.select(this.elementRef.nativeElement).select('#donutPop').select('svg').remove();
    this.drawDonut();
  }
  ngOnInit() {
    d3.select(this.elementRef.nativeElement).select('#donutPop').select('svg').remove();
    this.drawDonut();
  }
  drawDonut() {
    var data = this.datalist;
    var width = 450,
      height = 350; this.color = d3.scaleOrdinal()
       .range(COLOR_PALETTE);
    this.svg = d3.select(this.elementRef.nativeElement).select('#donutPop').append("svg:svg")
      .data([data]).attr("width", width).attr("height", height)
      .append("svg:g").attr("transform", "translate(" + (width / 2) + "," + (height / 2) + ")");

    this.shrink = 70;
    this.radius = Math.max(width, height) / 2 - this.shrink;
    this.labelr = this.radius + 15;
    this.pie = d3.pie().value(function (d) { return d.value; }).sort(null);;
    var arc = d3.arc()
      .outerRadius(this.radius)
      .innerRadius(this.radius * .65)
    var arcOver = d3.arc()
      .innerRadius(this.radius * .7)
      .outerRadius(this.radius + 10);

    // select paths, use arc generator to draw
    var arcs = this.svg.selectAll("g.slice").data(this.pie).enter().append("svg:g").
      attr("class", "slice");
    arcs.append("svg:path")
      .attr("fill", (d, i) => { return this.color(i); }
      )
      .attr("d", function (d) {
        return arc(d);
      })
      .on("mouseenter", function (d) {
        d3.select(this)
          .attr("stroke", "white")
          .transition()
          .duration(1000)
          .attr("d", arcOver)
          .attr("stroke-width", 6);
      })
      .on("mouseleave", function (d) {
        d3.select(this).transition()
          .attr("d", arc)
          .attr("stroke", "none");
      }).on("click", ((drect) => {
        this.count = drect.data.label;
        this.countChanged.emit(this.count);
      }));
    //text on arc
    var label = this.svg.selectAll(".arc")
      .data(this.pie(data))
      .enter().append("g")
      .attr("class", "arc");

    d3.selectAll('.labelDonut').remove();
    label.append('text')
      .attr('transform', d => {
        var c = arc.centroid(d);
        let x = c[0];
        let y = c[1];
        let h = Math.sqrt(x * x + y * y);
        return "translate(" + (x / h * this.labelr) + ',' +
          (y / h * this.labelr) + ")";
      })
      .attr("dy", ".35em")
      .attr("text-anchor", function (d) {
        return (d.endAngle + d.startAngle) / 2 > Math.PI ?
          "end" : "start";
      })
      .attr('class', 'labelDonut')
      .attr('font-size', '15px')
      .attr("font-weight", 'bold')
      .text(d => {
        if (d.endAngle - d.startAngle < 5 * Math.PI / 180) { return "" }
        else
          return d.data.value + '%'
      });

    d3.selectAll('.donut-totalPercent').remove();
    label.append("text")
      .attr("class", "donut-totalPercent")
      .attr("text-anchor", "middle")
      .attr('font-size', '2.5em')
      .text(this.totalDountData)
      .attr("line-height", '1')
      .attr("font-weight", 'bold').attr("dy", '.5em').attr('fill', '#101E7F');

   // this.legendBind(data);
  }

  // legendBind(data: any[]) {

  //   var legendData = data
  //   .map(d => {
  //     if (d.value > 0)
  //       return d.label;
  //   })
  //   var widthL = 200,
  //     heightL = 350;
  //   d3.select(this.elementRefPre.nativeElement).select('#legendsPre').select('svg').remove();
  //   let legend = d3.select(this.elementRefPre.nativeElement).select('#legendsPre')
  //     .append("svg")
  //     .attr("width", widthL).attr("height", heightL)
  //     .selectAll('g')
  //     .data(legendData)
  //     .enter()
  //     .append("g")
  //     .attr("transform", function (d, i) { return "translate(5," + i * 30 + ")"; })

  //   legend.append("rect")
  //     .attr("width", 18)
  //     .attr("height", 18)
  //     .style('fill', (d, i) => this.color(i));

  //   legend.append("text")
  //     .attr("x", 24)
  //     .attr("y", 9)
  //     .attr("dy", ".35em")
  //     //css for ellpsis not working  here
  //     .text((d, i) => {
  //       if (d.length > 20) { return d.slice(0, 19) + '...'; }
  //       else
  //         return d;
  //     });
  // }

}
