import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/_reducers';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { WorkbookTypeEnum } from 'app/story/storycontainer/storycontainer.component';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public cartItems$ = new BehaviorSubject([]);
  private cartItems = [];

  constructor(private httpClient: HttpClient,
    private store: Store<State>) {
    if (typeof localStorage.getItem("cart") === 'undefined') {
      localStorage.setItem("cart", JSON.stringify([]));
    }

    if (localStorage.getItem("cart") === null) {
      localStorage.setItem("cart", JSON.stringify([]));
    }

    this.cartItems = JSON.parse(localStorage.getItem("cart"));
    this.cartItems$.next(this.cartItems);
  }

  addItem(dashboardId: string, dashboardName: string, dashboardData: any, dashboardProperties: any, contentUrl: string, workbookId: string, viewId: string, imageBase64String: string, comments: string, filterName: string, filterList: any[], filterListString: string, workbookTypeEnum: WorkbookTypeEnum) {
    this.cartItems.push({ dashboardId: dashboardId, dashboardName: dashboardName, dashboardData: dashboardData, dashboardProperties: dashboardProperties, contentUrl: contentUrl, workbookId: workbookId, viewId: viewId, imageBase64String: imageBase64String, comments: comments, filterName: filterName, filterList: filterList, filterListString: filterListString, workbookType: workbookTypeEnum });
    this.cartItems$.next(this.cartItems);

    try {
      localStorage.setItem("cart", JSON.stringify(this.cartItems));
    }
    catch { }
  }

  insertItem(dashboardIndex: number, item: any) {
    this.cartItems.splice(dashboardIndex, 0, item);
    this.cartItems$.next(this.cartItems);

    try {
      localStorage.setItem("cart", JSON.stringify(this.cartItems));
    }
    catch { }
  }

  removeItem(dashboardIndex: number) {
    this.cartItems.splice(dashboardIndex, 1);
    this.cartItems$.next(this.cartItems);

    try {
      localStorage.setItem("cart", JSON.stringify(this.cartItems));
    }
    catch { }
  }

  setItems(cartItems: any[]) {
    this.cartItems.splice(0);
    cartItems.forEach(cartItem => {
      this.cartItems.push(cartItem);
    });
    this.cartItems$.next(this.cartItems);

    try {
      localStorage.setItem("cart", JSON.stringify(this.cartItems));
    }
    catch { }
  }

  getItems() {
    return JSON.parse(JSON.stringify(this.cartItems));
  }

  getObservableItems(): Observable<any[]> {
    return of(this.cartItems);
  }

  clearItems() {
    this.cartItems.splice(0);
    this.cartItems$.next(this.cartItems);

    localStorage.setItem("cart", JSON.stringify([]));
  }
}
