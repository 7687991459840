
export const locale = {
    lang: 'pt-br',
    data: {
        'UserAdmin': {
            'UserAdministration': 'ADMINISTRAÇÃO DE USUÁRIOS',
            'HelpText': 'Pode se acessado por gerentes regionais/locais. Utilizado por gerentes regionais para alocar gerentes locais.',
            'CountryManagers': 'Gerentes Nacionais',
            'Users': 'Usuários',
            'Countries': 'Países',
            'Clients': 'Clientes',
            'Save': 'Salvar',
            'SelectAll': 'SELECIONAR TODOS',
            'Add': 'ADICIONAR',
            'Success': 'Sucesso',
            'userColumnDefs': [
                {
                    headerName: 'Email',
                    field: 'email',
                    sort: 'asc'
                },
                {
                    headerName: 'Nome',
                    field: 'NomeUsuário',
                    sort: 'asc'
                }
            ],      
            'storyColumnDefs': [
                { headerName: 'Nome', field: 'name', sort: 'asc' },
                { headerName: 'Criado por', field: 'createdBy', sort: 'asc' }
            ],
            'clientColumnDefs': [
                {
                    headerName: 'Nome',
                    field: 'name',
                    sort: 'asc'
                }
            ],
            'countryColumnDefs': [
                {
                  headerName: 'Nome',
                  field: 'name',
                  sort: 'asc'
                }
            ],
            'userCountryColumnDefs': [
                {
                  headerName: 'Nome',
                  field: 'name',
                  sort: 'asc'
                }
            ]
        }
    }
};