import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { TableauService } from 'app/services/tableau.service';
import { FormControl } from '@angular/forms';
import 'rxjs/add/operator/debounceTime';
import { WorkbookTypeEnum } from '../storycontainer/storycontainer.component';
import { environment } from 'environments/environment';

@Component({
  selector: 'library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.css']
})
export class LibraryComponent implements OnInit {
  //Any tableau url related, addons or site names
  library: any[];
  projects: any[];
  isLoading = false;
  inputFilter = new FormControl();
  isDisabledAdd = false;
  profile = environment.profile;

  @Input() selection: any[] = [];
  @Output() onAdd: EventEmitter<any> = new EventEmitter<any>(); 

  constructor(private tableauService: TableauService) { }

  ngOnInit() {
    this.isLoading = true;
    this.tableauService.getTableauProjects().toPromise().then(projects => {
      this.projects = projects;
      this.tableauService.getLibrary().toPromise().then((res: any) => {
        if (res) {
            const globalProject = this.projects.find(p => p.name === 'Global');

            res.forEach(w => {
                w.workbook.type = WorkbookTypeEnum.Tableau;
            });
            this.library = res;

            if (this.profile.project.title === 'Pulse') {
                this.library = this.library.concat([
                    {
                    id: "ExecutiveSummary",
                    name: "Executive Summary (Asia)",
                    src: "/assets/charts/ExecSummary.png",
                    project: {
                        id: globalProject.guid
                    },
                    workbook: {
                        id: "ExecutiveSummary",
                        type: WorkbookTypeEnum.Custom,
                    },
                    hidden: false,
                    nonTableauChartObj: null
                    },
                    {
                        id: "ExecutiveSummaryROW",
                        name: "Executive Summary (ROW)",
                        src: "/assets/charts/ExecSummary.png",
                        project: {
                        id: globalProject.guid
                        },
                        workbook: {
                        id: "ExecutiveSummaryROW",
                        type: WorkbookTypeEnum.Custom,
                        },
                        hidden: false,
                        nonTableauChartObj: null
                    },
                    {
                    id: "HealthProfile",
                    name: "Health Profile",
                    src: "/assets/charts/Health.png",
                    project: {
                        id: globalProject.guid
                    },
                    workbook: {
                        id: "HealthDiseaseProfile",
                        type: WorkbookTypeEnum.Custom,
                    },
                    hidden: false,
                    nonTableauChartObj: null
                    },
                    {
                    id: "DiseaseProfile",
                    name: "Top Diseases",
                    src: "/assets/charts/Disease.png",
                    project: {
                        id: globalProject.guid
                    },
                    workbook: {
                        id: "HealthDiseaseProfile",
                        type: WorkbookTypeEnum.Custom,
                    },
                    hidden: false,
                    nonTableauChartObj: null
                    },
                    {
                    id: "PrevalenceDiseaseProfile",
                    name: "Disease Detail",
                    src: "/assets/charts/PravalanceDisease.png",
                    project: {
                        id: globalProject.guid
                    },
                    workbook: {
                        id: "PrevalenceDiseaseProfile",
                        type: WorkbookTypeEnum.Custom,
                    },
                    hidden: false,
                    nonTableauChartObj: null
                    },{
                    id: "AverageCost",
                    name: "Average Cost Impact",
                    src: "/assets/charts/AvgCost.png",
                    project: {
                        id: globalProject.guid
                    },
                    workbook: {
                        id: "AverageCostUtilization",
                        type: WorkbookTypeEnum.Custom,
                    },
                    hidden: false,
                    nonTableauChartObj: null
                    },{
                    id: "Utilization",
                    name: "Utilization Impact",
                    src: "/assets/charts/Util.png",
                    project: {
                        id: globalProject.guid
                    },
                    workbook: {
                        id: "AverageCostUtilization",
                        type: WorkbookTypeEnum.Custom,
                    },
                    hidden: false,
                    nonTableauChartObj: null
                    },
                    {
                        id: "PerMemberCosts",
                        name: "Per Member Costs",
                        src: "/assets/charts/PerMemberCosts.png",
                        project: {
                        id: globalProject.guid
                        },
                        workbook: {
                        id: "PerMemberCosts",
                        type: WorkbookTypeEnum.Custom,
                        },
                        hidden: false,
                        nonTableauChartObj: null
                    },
                    {
                        id: "ClaimCostByTopProvider",
                        name: "Claims Cost - by Top Providers",
                        src: "/assets/charts/ClaimsCostByTopProviders.png",
                        project: {
                            id: globalProject.guid
                        },
                        workbook: {
                            id: "ClaimCostProviderDiagnosis",
                            type: WorkbookTypeEnum.Custom,
                        },
                        hidden: false,
                        nonTableauChartObj: null
                        },{
                        id: "ClaimCostByTopDiagnoses",
                        name: "Claims Cost - by Top Diagnoses",
                        src: "/assets/charts/ClaimsCostByTopProviders.png",
                        project: {
                            id: globalProject.guid
                        },
                        workbook: {
                            id: "ClaimCostProviderDiagnosis",
                            type: WorkbookTypeEnum.Custom,
                        },
                        hidden: false,
                        nonTableauChartObj: null
                        },
                        {
                            id: "ClaimsOverview",
                            name: "Claims Overview",
                            src: "/assets/charts/ClaimsOverview.png",
                            project: {
                            id: globalProject.guid
                            },
                            workbook: {
                            id: "ClaimsOverview",
                            type: WorkbookTypeEnum.Custom,
                            },
                            hidden: false,
                            nonTableauChartObj: null
                        },
                        {
                            id: "ProviderVDiagnosis",
                            name: "Provider v Diagnosis",
                            src: "/assets/charts/ProviderVsDiagnosis.png",
                            project: {
                            id: globalProject.guid
                            },
                            workbook: {
                            id: "ClaimCostProviderDiagnosis",
                            type: WorkbookTypeEnum.Custom,
                            },
                            hidden: false,
                            nonTableauChartObj: null
                        }, 
                        {
                            id: "ClaimTypeVDiagnosis",
                            name: "Claim Type v Diagnosis",
                            src: "/assets/charts/ClaimTypeVsDiagnosis.png",
                            project: {
                                id: globalProject.guid
                            },
                            workbook: {
                                id: "ClaimTypeDiagnosis",
                                type: WorkbookTypeEnum.Custom,
                            },
                            hidden: false,
                            nonTableauChartObj: null
                            }, 
                            {
                                id: "ClaimType",
                                name: "Claim Type",
                                src: "/assets/charts/ClaimType.png",
                                project: {
                                id: globalProject.guid
                                },
                                workbook: {
                                id: "ClaimType",
                                type: WorkbookTypeEnum.Custom,
                                },
                                hidden: false,
                                nonTableauChartObj: null
                            }, {
                                id: "ClaimByAgeBand",
                                name: "Claims By Age Band",
                                src: "/assets/charts/ClaimsByAgeBand.png",
                                project: {
                                id: globalProject.guid
                                },
                                workbook: {
                                id: "ClaimByAgeBand",
                                type: WorkbookTypeEnum.Custom,
                                },
                                hidden: false,
                                nonTableauChartObj: null
                            } , {
                                id: "LossRatio",
                                name: "Loss Ratio",
                                src: "/assets/charts/LossRatio.png",
                                project: {
                                id: globalProject.guid
                                },
                                workbook: {
                                id: "LossRatio",
                                type: WorkbookTypeEnum.Custom,
                                },
                                hidden: false,
                                nonTableauChartObj: null
                            } ,
                            {
                                id: "EnrollmentByTime",
                                name: "Enrollment By Time",
                                src: "/assets/charts/EnrollmentByTime.png",
                                project: {
                                id: globalProject.guid
                                },
                                workbook: {
                                id: "EnrollmentByTime",
                                type: WorkbookTypeEnum.Custom,
                                },
                                hidden: false,
                                nonTableauChartObj: null
                            },
                            {
                                id: "EnrollmentByAgeBand",
                                name: "Enrollment By Age Band",
                                src: "/assets/charts/EnrollmentByAgeBand.png",
                                project: {
                                id: globalProject.guid
                                },
                                workbook: {
                                id: "EnrollmentByAgeBand",
                                type: WorkbookTypeEnum.Custom,
                                },
                                hidden: false,
                                nonTableauChartObj: null
                            },
                            {
                                id: "DistributionByClaimType",
                                name: "Claim Type by Dimension",
                                src: "/assets/charts/ClaimTypeByDimension.png",
                                project: {
                                id: globalProject.guid
                                },
                                workbook: {
                                id: "DistributionByClaimType",
                                type: WorkbookTypeEnum.Custom,
                                },
                                hidden: false,
                                nonTableauChartObj: null
                            }
                            ,
                            {
                                id: "DoubleQuadrantProDiag",
                                name: "Provider - Double Quadrant Analysis",
                                src: "/assets/charts/Quadrant.png", 
                                project: {
                                id: globalProject.guid
                                },
                                workbook: {
                                id: "DoubleQuadrantProDiag",
                                type: WorkbookTypeEnum.Custom,
                                },
                                hidden: false,
                                nonTableauChartObj: null
                            },{
                                id: "CoMorbidity",
                                name: "Co-Morbidity",
                                src: "/assets/charts/CoMorbidity.png", 
                                project: {
                                id: globalProject.guid
                                },
                                workbook: {
                                id: "CoMorbidity",
                                type: WorkbookTypeEnum.Custom,
                                },
                                hidden: false,
                                nonTableauChartObj: null
                            }
                            
                ]);
            }

            this.updateSelection();
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.isLoading = false;
      });
    });
    this.inputFilter.valueChanges.debounceTime(500)
    .subscribe( result => { 
      this.library.forEach(lib => {
        lib.hidden = lib.name.toLowerCase().indexOf(result.toLowerCase()) < 0;
      })
    });
  }

  public updateSelection() {
    this.library.forEach(lib => {
      var match = this.selection.filter(function(s) 
      { return s.name == lib.name && s.workbook.id == lib.workbook.id })[0];
      if(match) console.log('match');
      lib.isAdded = match ? true: false; 
    })
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes.selection !== 'undefined') {
      if (this.library) {
        this.library.forEach(lib => {
          console.log(lib.id)
          var match = this.selection.filter(function(s) { return s.id == lib.id })[0];
          if(match) console.log('match');
          lib.isAdded = match ? true: false; 
        })
      }
    }
  }

  addDashboard(dash) {
    this.onAdd.emit({ data: dash });
    dash.isAdded = true;
  }
}
