import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { FilterOptions } from './shared/models/FilterOptions';
import { CLAIM_BASIS } from 'app/models/basis.model';
import { Observable } from 'rxjs/observable';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private httpClient: HttpClient) { }

  getData(componentType: any, properties: any): any {
    console.log(componentType);
    switch (componentType) {
      case 'ExecutiveSummary': {
        return this.getExecutiveSummaryAsiaData(properties);
      }
      case 'ExecutiveSummaryROW': {
        return this.getExecutiveSummaryExceptAsiaData(properties);
      }
      case 'ExecutiveSummaryExceptAsia': {

      }
      case 'HealthDiseaseProfile': {

      }
      case 'PrevalenceDiseaseProfile': {

      }
      case 'AverageCostUtilization': {

      }
      case 'PerMemberCosts': {
        return this.getPerMemberCostsData(properties);
      }
      default: return null;
    }
  }

  params = {
    COUNTRY: 'Country',
    CLIENTNAME: 'ClientName',
    DIMENSION: 'Dimension',
    SECONDARYDIMENSION: 'SecondaryDimension',
    SECONDARYDIMENSIONVALUE: 'SecondaryDimensionValue',
    SORT: 'Sort',
    FILTERNA: 'FilterNA',
    CLAIMTYPEOPTION: 'ClaimTypeOption',
    BENCHINCLUDE: 'BenchmarkInclude',
    BENCHLEVEL: 'BenchmarkLevel'
  }

  getAverageCostData(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/average-costs', params);
  }

  getUtilizationImpact(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/utilization/impact', params);
  }

  getUtilizationData(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/utilization', params);
  }

  getUtilizationKeyImpacts(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/utilization/key-impacts', params);
  }

  getPerMemberCostsData(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/permember-costs', params);
  }

  getPerMemberCostsKeyImpacts(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/permember-costs/key-impacts', params);
  }

  getExecutiveSummaryAsiaData(properties: any): any {
    const params = this.convertToParams(properties);
    params.ClaimPaid = CLAIM_BASIS.PAID_BASIS;
    params.RollingPeriod = 7;
    return this.httpClient.post<any>(environment.api + 'api/data/executive-summary-asia', params);
  }

  getExecutiveSummaryExceptAsiaData(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/executive-summary-except-asia', params);
  }

  getClaimsOverview(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/claims-overview', params);
  }

  getFilterOptions(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<FilterOptions>(environment.api + 'api/data/filters', params);
  }

  updateFilterOptions(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<FilterOptions>(environment.api + 'api/data/filters/update', params);
  }

  getHealthProfileData(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/health-profile', params);
  }

  getDiseaseProfileData(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/disease-profile', params);
  }

  getTier3Data(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/tier3-data', params);
  }

  getTier3Benchmark(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/tier3-benchmark-cost', params);
  }

  getLossRatio(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/loss-ratio', params);
  }
  getEnrollmentByAgeBand(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/enrollment-byageband', params);
  }

  getEnrollmentByTime(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/enrollment-bytime', params);
  }

  getTier3DataWith2Dimensions(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/tier3-claim-type', params);
  }

  getTier3ClaimByAgeBand(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/tier3-ByAgeBand', params);
  }

  getClaimTypeData(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/claim-type', params);
  }

  getTier3SingleDimensionData(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/tier3-single-dimension', params);
  }

  getTier3DiseaseProgressionData(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/tier3-disease-progression', params);
  }

  getCoMorbidityData(properties: any): any {
    const params = this.convertToParams(properties);
    return this.httpClient.post<any>(environment.api + 'api/data/co-morbidity', params);
  }

  getIsDisplayBenchmark(properties: any): Observable<boolean> {
    const params = this.convertToParams(properties);
    return this.httpClient.post<boolean>(environment.api + 'api/data/display-benchmark', params);
  }

  convertToParams(properties) {
    const params: any = {
      Country: properties.country,
      DashboardId: properties.dashboardId,
      StoryId: properties.storyId,
      StoryName: properties.storyName,
      PeriodSelection: properties.periodSelection,
      RollingPeriod: properties.rollingPeriod,
      ClaimPaid: properties.claimPaid,
      MaxDate: properties.maxDate,
      MaxClaimPaidDate: properties.maxClaimPaidDate,
      MaxAdmitDate: properties.maxAdmitDate,
      Period1StartDate: properties.period1StartDate,
      Period2StartDate: properties.period2StartDate,
      Period3StartDate: properties.period3StartDate,
      Period4StartDate: properties.period4StartDate,
    }

    params.ClientName = '';

    if (properties.client != null) {
      if (properties.client.isGroup) {
        const clients = properties.client.groupFilterString;
        params.ClientName = clients;
      } else {
        params.ClientName = properties.client.name;
      }
    }

    if (properties.dimension) {
      params.Dimension = properties.dimension;
    }

    if (properties.secondaryDimension) {
      params.SecondaryDimension = properties.secondaryDimension;
    }
    if (properties.thirdDimension) {
      params.ThirdDimension = properties.thirdDimension;
    }

    if (properties.sort) {
      params.Sort = properties.sort;
    }

    if (properties.filterNA) {
      params.FilterNA = properties.filterNA;
    }

    if (properties.claimTypeOption) {
      params.ClaimTypeOption = properties.claimTypeOption;
    }

    if (properties.benchmarkInclude && properties.benchmarkLevel) {
      params.BenchmarkInclude = properties.benchmarkInclude;
      params.BenchmarkLevel = properties.benchmarkLevel;
    }

    if (properties.mode) {
      params.Mode = properties.mode;
    }

    if (typeof properties.diagGrps !== 'undefined' && !properties.diagGrps.startsWith('ALL||')) {
      params.DiagGrps = encodeURIComponent(properties.diagGrps);
    }

    if (typeof properties.diagCatsOptions !== 'undefined') {
      params.DiagCatsOptions = properties.diagCatsOptions;
    }

    if (typeof properties.diagCats !== 'undefined' && !properties.diagCats.startsWith('ALL||')) {
      params.DiagCats = encodeURIComponent(properties.diagCats);
    }

    if (typeof properties.actionables !== 'undefined' && !properties.actionables.startsWith('ALL||')) {
      params.Actionables = encodeURIComponent(properties.actionables);
    }

    if (properties.claimPMPM) {
      params.ClaimPMPM = properties.claimPMPM;
    }

    if (properties.lossRatio) {
      params.LossRatio = properties.lossRatio;
    }

    if (properties.period) {
      params.Period = properties.period;
    }

    if (properties.targetLossRatio) {
      params.TargetLossRatio = properties.targetLossRatio;
    }
    if (properties.selectedClaimType) {
      params.SelectedClaimType = properties.selectedClaimType;
    }

    if (properties.claimTypeDimensionfilter != null) {
      params.ClaimType = properties.claimTypeDimensionfilter;
    }

    if (properties.dimensionValues) {
      params.DimensionValues = properties.dimensionValues;
    }

    if (properties.secondaryDimensionValue) {
      params.SecondaryDimensionValue = properties.secondaryDimensionValue;
      if (properties.secondaryDimensionValue.includes('\'\'')) {
        params.SecondaryDimensionValue = properties.secondaryDimensionValue.replace('\'\'', '\'');
      }
    }

    if (properties.secondaryDimensionValues) {
      params.SecondaryDimensionValues = properties.secondaryDimensionValues;
    }

    if (properties.thirdDimensionValue) {
      params.ThirdDimensionValue = properties.thirdDimensionValue;
    }

    if (properties.thirdDimensionValues) {
      params.ThirdDimensionValues = properties.thirdDimensionValues;
    }

    if (typeof properties.coMorbidityDiag) {
      params.CoMorbidityDiag = properties.coMorbidityDiag;
    }

    if (typeof properties.aClientNames !== 'undefined' && !properties.aClientNames.startsWith('ALL||')) {
      params.ClientNames = encodeURIComponent(properties.aClientNames);
    }

    if (typeof properties.aEntities !== 'undefined' && !properties.aEntities.startsWith('ALL||')) {
      params.Entities = encodeURIComponent(properties.aEntities);
    }

    if (typeof properties.aDivisions !== 'undefined' && !properties.aDivisions.startsWith('ALL||')) {
      params.Divisions = encodeURIComponent(properties.aDivisions);
    }

    if (typeof properties.aPlans !== 'undefined' && !properties.aPlans.startsWith('ALL||')) {
      params.Plans = encodeURIComponent(properties.aPlans);
    }

    if (typeof properties.aTimePeriods !== 'undefined' && !properties.aTimePeriods.startsWith('ALL||')) {
      params.TimePeriods = encodeURIComponent(properties.aTimePeriods);
    }

    if (typeof properties.aTimePeriodsLossRatio !== 'undefined' && !properties.aTimePeriodsLossRatio.startsWith('ALL||')) {
      params.TimePeriodsLossRatio = encodeURIComponent(properties.aTimePeriodsLossRatio);
    }

    if (typeof properties.aTimePeriodsEnrollment !== 'undefined' && !properties.aTimePeriodsEnrollment.startsWith('ALL||')) {
      params.TimePeriodsEnrollment = encodeURIComponent(properties.aTimePeriodsEnrollment);
    }

    if (typeof properties.aPolicyTypes !== 'undefined' && !properties.aPolicyTypes.startsWith('ALL||')) {
      params.PolicyTypes = encodeURIComponent(properties.aPolicyTypes);
    }

    if (typeof properties.aPolicyTypes !== 'undefined' && properties.aPolicyTypes.startsWith('ALL||') && properties.country === 'Chile') {
      params.PolicyTypes = encodeURIComponent(properties.aPolicyTypes);
    }

    if (typeof properties.aAgeBands !== 'undefined' && !properties.aAgeBands.startsWith('ALL||')) {
      params.AgeBands = encodeURIComponent(properties.aAgeBands);
    }

    if (typeof properties.aGenders !== 'undefined' && !properties.aGenders.startsWith('ALL||')) {
      params.Genders = encodeURIComponent(properties.aGenders);
    }

    if (typeof properties.aClaimTypes !== 'undefined' && !properties.aClaimTypes.startsWith('ALL||')) {
      params.ClaimTypes = encodeURIComponent(properties.aClaimTypes);
    }

    if (typeof properties.aClaimTypes2 !== 'undefined' && !properties.aClaimTypes2.startsWith('ALL||')) {
      params.ClaimTypes2 = encodeURIComponent(properties.aClaimTypes2);
    }

    if (typeof properties.aClaimTypes3 !== 'undefined' && !properties.aClaimTypes3.startsWith('ALL||')) {
      params.ClaimTypes3 = encodeURIComponent(properties.aClaimTypes3);
    }

    if (typeof properties.aRelationships !== 'undefined' && !properties.aRelationships.startsWith('ALL||')) {
      params.Relationships = encodeURIComponent(properties.aRelationships);
    }

    if (typeof properties.aDiagCats !== 'undefined' && !properties.aDiagCats.startsWith('ALL||')) {
      params.DiagCats = encodeURIComponent(properties.aDiagCats);
    }

    if (typeof properties.aDiagChapters !== 'undefined' && !properties.aDiagChapters.startsWith('ALL||')) {
      params.DiagChapters = encodeURIComponent(properties.aDiagChapters);
    }

    if (typeof properties.aLocations !== 'undefined' && !properties.aLocations.startsWith('ALL||')) {
      params.Locations = encodeURIComponent(properties.aLocations);
    }

    if (typeof properties.aChronics !== 'undefined' && !properties.aChronics.startsWith('ALL||')) {
      params.Chronics = encodeURIComponent(properties.aChronics);
    }

    if (typeof properties.aCarriers !== 'undefined' && !properties.aCarriers.startsWith('ALL||')) {
      params.Carriers = encodeURIComponent(properties.aCarriers);
    }

    if (typeof properties.aLobs !== 'undefined' && !properties.aLobs.startsWith('ALL||')) {
      params.Lobs = encodeURIComponent(properties.aLobs);
    }

    if (typeof properties.aSubLobs !== 'undefined' && !properties.aSubLobs.startsWith('ALL||')) {
      params.SubLobs = encodeURIComponent(properties.aSubLobs);
    }

    if (typeof properties.aNetworks !== 'undefined' && !properties.aNetworks.startsWith('ALL||')) {
      params.Networks = encodeURIComponent(properties.aNetworks);
    }

    if (typeof properties.aProviderNames !== 'undefined' && !properties.aProviderNames.startsWith('ALL||')) {
      params.ProviderNames = encodeURIComponent(properties.aProviderNames);
    }

    if (typeof properties.aProviderLocations !== 'undefined' && !properties.aProviderLocations.startsWith('ALL||')) {
      params.ProviderLocations = encodeURIComponent(properties.aProviderLocations);
    }

    if (typeof properties.aProviderTypes !== 'undefined' && !properties.aProviderTypes.startsWith('ALL||')) {
      params.ProviderTypes = encodeURIComponent(properties.aProviderTypes);
    }

    params.ClaimsByAgeBandsAgeBand = encodeURIComponent(properties.claimsByAgeBandsAgeBand); // properties.claimsByAgeBandsAgeBand;
    params.ClaimsByAgeBandsGender = properties.claimsByAgeBandsGender;
    params.ClaimsByAgeBandsRelationship = properties.claimsByAgeBandsRelationship;
    params.ClaimsByAgeBandsDiagChapter = properties.claimsByAgeBandsDiagChapter;
    params.ClaimsByAgeBandsProviderName = properties.claimsByAgeBandsProviderName;

    return params;
  }
}
