export const locale = {
    lang: 'es-co',
    data: {
        'ExecutiveSummary': {            
            'ExecutiveSummary': 'Resumen Ejecutivo',            
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'GeneralTrend': 'Tendencia General',
            'Rolling12Basis': '12 Meses Corridos',
            'CurrentPMPM': 'Incurrido por miembro por mes', 
            'PriorPMPM' : 'Promedio por Usuario Mensual Anterior', 
            'OverallTrend' : 'Otras tendencias', 
            'AverageCost': 'Costo Promedio',
            'Utilization': 'Utilización',
            'ServicesReceived': 'Servicios Recibidos',
            'PlanPerformance': 'Desempeño del Plan',
            'YearToDateBasis': 'Base del año hasta la fecha',
            'ClaimsReportedByPaidDate' :  'Reclamaciones reportadas por fecha de pago',
            'ClaimsReportedByIncurredDate' :  'Reclamaciones informadas por fecha incurrida',
            'LossRatio': 'Siniestralidad',
            'Headcount': 'POBLACIÓN TOTAL',
            'Claims': 'RECLAMOS',
            'Premium': 'PRIMAS',            
            'CombinedLossRatio':'Siniestralidad Combinada',
            'DateRange': 'to'
        }
    }
};

