<h1 class="text-mono">Manage Cost Projection Users</h1>

<div class="cost-projection">
    <div>
        <h2>Unassigned</h2>
        <agcustom-grid *ngIf="userSummary" [dataList]="userSummary.unassigned" [columnDefs]="costProjectionUserColumnDefs" 
        (selectionChanged)="onAssign($event.data)">
        </agcustom-grid>
    </div>
    <div>
        <h2>Assigned</h2>
        <agcustom-grid *ngIf="userSummary" [dataList]="userSummary.assigned" [columnDefs]="costProjectionUserColumnDefs" 
        (selectionChanged)="onUnassign($event.data)">
        </agcustom-grid>
    </div>
</div>