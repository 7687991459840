export const locale = {
    lang: 'en',
    data: {
        'ExportQueue': {
            'ExportQueue': 'Export Queue',
            'CanViewExportQueueAndStatusHere': 'Can View Export Queue And Status Here',
            'Name': 'Name',
            'FileName': 'File Name',
            'Status': 'Status',
            'CreatedOn': 'Created On',
            'Scheduled': 'Scheduled',
            'Processing': 'Processing',
            'Succeeded': 'Succeeded',
            'Failed': 'Failed'
        }
    }
};
