export const locale = {
    lang: 'en',
    data: {
        'ClaimsOverview': {
            'Prior': 'Prior',
            'Current': 'Current',
            'ClaimType': 'Claim Type',
            'Relationship': 'Relationship',
            'Entity': 'Entity',
            'Network': 'Network',
            'Location': 'Location',
            'ClaimsOverview': 'Claims Overview',
            'Total': 'Total',
            'TotalPaidClaim': 'Total Paid Claim',
            'NOOFCLAIMS': 'No Of Claims',
            'NOOFCLAIMANTS': 'No Of Claimants',
            'Claim': 'Claim',
            'Claimant': 'Claimant',
            'Count': 'Count',
            'AllAmountsDisplayedInLocalCurrency': 'All amounts displayed in local currency',
            'ClaimsReportedByPaidDate': 'Claims reported by paid date',
            'ClaimsReportedByIncurredDate': 'Claims reported by incurred date',
            'DateRange': 'to'
        }
    }
};
