export const locale = {
    lang: 'es-pe',
    data: {
        'ClaimAgeBand': {
            'ClaimsByAgeBand': 'Atenciones por rango etario',
            'AllAmountsDisplayedInLocalCurrency': 'Moneda local',
            'CountBasis':'Contar Base',
            'Claim': 'Atención',
            'Claimant': 'Paciente',
            'AgeBandSplit'  :  'Rango etario divido'  , 
            'None': 'Ninguno',
            'Gender': 'Género',
            'Relationship': 'Parentesco',
            'Sort': 'Ordenar',
            'ClaimsTotal': 'Total de siniestros',
            'Count' : 'Contar',            
            'AverageCost': 'Costo Promedio',
            'DataTable': 'Tabla de datos',
            'TopDiagnoses': 'Top Diagnósticos',
            'TopProviders': 'Top Proveedors',
            'TotalPaidClaim': 'Gasto pagado total',
            'ClaimantCount': 'Cuenta de paciente',
            'ClaimCount': 'Número de atenciones',
            'ClaimsPMPM': 'Siniestros PMPM',
            'CostPerClaim': 'Costo por atención',
            'CostPerClaimant': 'Costo por paciente',
            'ClaimsReportedByPaidDate': 'Siniestros informados por fecha de ocurrencia',
            'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
            'FilterNA': 'Excluir NA',
            'Display':'Mostrar Como Tabla',
            'ShowLegend':'Mostrar leyenda',
            'ShownAsPer':'Se muestra según',
            'AgeBand': 'Rango Etario',
            'DateRange': 'to'
        }
}
};
