export const environment = {
    production: true,
    hmr: false,
    api: '/api/',
    useTableauToken: true,
    tableau: '#{OD_Env_Tableau}',
    tableauToken: '#{OD_Env_TableauToken}',
    tableauLibrary: '#{OD_Env_TableauLibrary}',
    siteName: '#{OD_Env_SiteName}',
    sitePath: '#{OD_Env_SitePath}',
    dashboardImageExtension: '.png',
    imageUrlBase: '#{OD_Env_ImageUrlBase}',
    profile: {
        project: {
            title: 'Pulse'
        },
        login: {
            isExternalUserLoginExtad: true,
            externalUserLoginPlaceholder: 'Email'
        },
        user: {
            userOptions: {
                isTranslationOptionShown: true,
                isTimePeriodShown: true,
                isClaimPaidShown: true,
                isPlanYearShown: false,
            },
            toolbar: {
                isTranslationShown: true
            }
        },
        admin: {

        },
        superadmin: {
            users: {
                isTBSUserIdShown: true,
                isCreateExternalUserExtad: true
            },
            clients: {
                isTBSClientIdShown: true
            }
        },
        tableau: {
            options: {
                isDashboardRefresh: false
            }
        },
        newLayoutUsers: ['amanda.singer@aon.com', 'svc-apnh000004@aon.com', 'james.white@aon.com', 'wern.sern.tan@aon.com', 'ivan.oh@aon.com', 'christopher.khoo@aon.com', 'geoffrey.kuhn@aon.com', 'james.platt@aon.com']
    }
};
