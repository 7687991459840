export const locale = {
	lang: 'pt-br',
	data: {
		'ageBand': {
			'AgeBandHeading': 'Adesão por Faixa Etária',
			'Benchmark': 'Referência',
			'IncludeClient': 'Incluir Cliente',
			'ExcludeClient': 'Excluir Cliente',
			'Portfolio': 'Portfólio',
			'Industry': 'Indústria',
			'None': 'Nenhum',
			'MemberCount': 'Usuário Contagem Total',
			'AverageAge': 'Idade Média',
			'AverageFamilySize': 'Tamanho Médio de Família',
			'RelationshipDistribution': 'Distribuição por Parentesco',
			'Split': 'Dividir',
			'PctOfTotalClient': '% do Total do Cliente',
			'EnrollmentTrend': 'Tendência de Inscrição',
			'Male': 'Masculino',
			'Female': 'Feminino',
			'PctOfPopulation': 'da População',
			'Employee': 'Funcionária(o)',
			'Child': 'Child',
			'Sibling': 'Sibling',
			'Dimensions': 'Dimensão',
			'Gender': 'Gênero',
			'Relationship': 'Relacionamento',
			'Measure': 'Medida',
			'Number': 'Número',
			'PctDistribution': '% Distribuição',
			'Dependent': 'Dependente',
			'DateRange': 'to',
			'Others': 'Outros',
		}
	}
};