import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HealthScoreService {

  constructor(private httpClient: HttpClient) { }

  public getHealthScoreResult(dashboardParams: any)
  {
    return this.httpClient.post<any>(environment.api + 'api/HealthScore/health-score-detail', dashboardParams);
  }

 
  
 
}
