<div fxLayout='column'>
    <div fxLayout='row'>
        <div fxFlex>
            <aon-textbox placeholder='Current Password' type='password' (onchange)='currentPassword = $event.detail.value'></aon-textbox>
        </div>
    </div>
    <div fxLayout='row'>
        <div fxFlex>
            <aon-textbox placeholder='New Password' type='password' (onchange)='newPassword = $event.detail.value'></aon-textbox>
        </div>
    </div>
    <div fxLayout='row'>
        <div fxFlex>
            <aon-textbox placeholder='Confirm New Password' type='password' (onchange)='confirmNewPassword = $event.detail.value'></aon-textbox>
        </div>
    </div>
    <div fxLayout='row' style='height:40px'></div>
    <div fxLayout='row' style="text-align: end;">
        <aon-button-default width='150px' [disabled]='currentPassword.trim().length < 8 || newPassword.trim().length < 8 || confirmNewPassword.trim().length < 8 || newPassword.trim() !== confirmNewPassword.trim()' (onclick)='onApply()'>Apply</aon-button-default>
        <aon-button-default width='150px' (onclick)='onClose()' style='margin-left: 20px'>Close</aon-button-default>
    </div>
</div>
