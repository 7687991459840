export const locale = {
    lang: 'es-pe',
    data: {
      'DoubleQuad': {
          'DoubleQuadHeading':'Proveedor - Análisis de doble cuadrante',           
          'Providers':'Proveedores', 
          'Diagnoses':'Diagnósticos' ,
          'AllAmountsDisplayedInLocalCurrency': 'Moneda local',
          'ClaimantCount': 'Cuenta de paciente',
          'ClaimCount': 'Número de atenciones',
          'CostPerClaim': 'Costo por atención',
          'CostPerClaimant': 'Costo por paciente',
          'Claim': 'Atención',
          'Claimant': 'Paciente',
          'ClaimsTotal': 'Total de siniestros',
           'Count': 'Contar',
          'ClaimsReportedByPaidDate': 'Siniestros informados por fecha de ocurrencia',
          'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',          
          'SelectTop': 'Seleccione Top',
          'FilterNA': 'Excluir NA',
          'CountBasis': 'Contar Base',
          'Sort': 'Ordenar',
          'Reset': 'Reiniciar',
          'ShownAsPer':'Se muestra según',
          'DateRange': 'to'
          
      }
  }
};