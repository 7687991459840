import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserService } from '../services/user.service';
import { tap } from 'rxjs/operators';
import { HttpstatusService } from 'app/services/httpstatus.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private _userService: UserService,
        private _httpstatusService: HttpstatusService
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = this._userService.user;
        if (currentUser && currentUser.token) {
            const cloned = request.clone({
                headers: request.headers.set('Authorization', 'Bearer ' + currentUser.token)
            });

            return next.handle(cloned).pipe( tap(() => {},
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status >= 400) {
                            this._httpstatusService.notifyHttpErrorResponse(err);
                        }   
                    }
                }));
        }

        return next.handle(request).pipe( tap(() => {},
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status >= 400) {
                        this._httpstatusService.notifyHttpErrorResponse(err);
                    }   
                }
            }));
    }
}
