export const fwbPlanYearList = [
    { text: 2020, value: 2020 },
    { text: 2019, value: 2019 },
    { text: 2018, value: 2018 },
    { text: 2017, value: 2017 },
    { text: 2016, value: 2016 },
    { text: 2015, value: 2015 }];

export const ExecutiveSummary_RetirementAdequacyData = [
    {
        name: 'Below Minimum - Target Met',
        value: 7.9,
        colorindex: 0,
        striped: false
    },
    {
        name: 'Below Minimum - Target Not Met',
        value: 4.9,
        colorindex: 0,
        striped: true
    },
    {
        name: 'Minimum - Target Met',
        value: 9.5,
        colorindex: 2,
        striped: false
    },
    {
        name: 'Minimum - Target Not Met',
        value: 5.9,
        colorindex: 2,
        striped: true
    },
    {
        name: 'Moderate - Target Met',
        value: 23.1,
        colorindex: 4,
        striped: false
    },
    {
        name: 'Moderate - Target Not Met',
        value: 14.1,
        colorindex: 4,
        striped: true
    },
    {
        name: 'Comfortable - Target Met',
        value: 11.6,
        colorindex: 7,
        striped: false
    },
    {
        name: 'Comfortable - Target Not Met',
        value: 7.1,
        colorindex: 7,
        striped: true
    },
    {
        name: 'Above Comfortable - Target Met',
        value: 9.9,
        colorindex: 8,
        striped: false
    },
    {
        name: 'Above Comfortable - Target Not Met',
        value: 6.0,
        colorindex: 8,
        striped: true
    },
];

export const AgeBand_BenefitScoreData = [
    {
        name: '15-19',
        value: 475,
        colorindex: 16,
        isexpanded: false
    },
    {
        name: '20-24',
        value: 565,
        colorindex: 16,
        isexpanded: false
    },
    {
        name: '25-29',
        value: 649,
        colorindex: 16,
        isexpanded: false
    },
    {
        name: '30-34',
        value: 456,
        colorindex: 16,
        isexpanded: false
    },
    {
        name: '35-39',
        value: 538,
        colorindex: 16,
        isexpanded: false
    },
    {
        name: '40-44',
        value: 780,
        colorindex: 16,
        isexpanded: false
    },
    {
        name: '45-49',
        value: 458,
        colorindex: 16,
        isexpanded: false
    },
    {
        name: '50-54',
        value: 569,
        colorindex: 16,
        isexpanded: false
    },
    {
        name: '55+',
        value: 775,
        colorindex: 16,
        isexpanded: false
    },
];
export const Location_BenefitScoreData = [
    {
        name: 'Singapore',
        salary: '> 100K',
        value: 574,
        colorindex: 16
    },
    {
        name: 'Krakow',
        salary: '50 - 100K',
        value: 845,
        colorindex: 16
    },
    {
        name: 'Sydney',
        salary: '< 50 K',
        value: 583,
        colorindex: 16
    },
    {
        name: 'California',
        salary: '> 100K',
        value: 458,
        colorindex: 16
    },
];

export const SalaryBand_BenefitScoreData = [
    {
        name: '> 100K',
        value: 485,
        isexpanded: false
    },
    {
        name: '50 - 100K',
        value: 393,
        isexpanded: false
    },
    {
        name: '< 50K',
        value: 572,
        isexpanded: false
    },
];

export const VizColorConstants = [
    '#8CF2DE',
    '#38CBD2',
    '#00A3C7',
    '#007BB6',
    '#00519B',
    '#012774',
    '#65257E',
    '#A21F79',
    '#FF7D2E',
    '#003650',
    '#361443',
    '#001B5A'
];
export const InterfaceColorEnums =
    [
        '#011641',
        '#007BB6',
        '#009DE9',
        '#005983',
        '#9CDFFF',
        '#CFF0FF',
        '#69CFFF',
        '#2E2E2E',
        '#4C4D4F',
        '#696969',
        '#C0C0C0',
        '#E4E4E4',
        '#F2F2F2',
        '#F8F8F8',
        'rgba(255,255,255,0.87)',
        'rgba(255,255,255,0.60)',
        '#FFFFFF',
    ];
export const FeedbackPerformanceColorEnums =
    [
        '#F24F4D',
        '#FFA600',
        '#15B797',
    ];
export const DrilldownAgeBand =
    [
        {
            category: '20-29',
            values: [
                { name: 'Male', value: 470, colorindex: 1 },
                { name: 'Female', value: 390, colorindex: 0 },
            ],
        },
        {
            category: '30-39',
            values: [
                { name: 'Male', value: 960, colorindex: 1 },
                { name: 'Female', value: 610, colorindex: 0 },
            ],
        },
        {
            category: '40-49',
            values: [
                { name: 'Male', value: 820, colorindex: 0 },
                { name: 'Female', value: 660, colorindex: 1 },
            ],
        },
        {
            category: '50-59',
            values: [
                { name: 'Male', value: 930, colorindex: 0 },
                { name: 'Female', value: 720, colorindex: 1 },
            ],
        },
        {
            category: '60-69',
            values: [
                { name: 'Female', value: 610, colorindex: 0 },
                { name: 'Male', value: 1180, colorindex: 1 },
            ],
        },
        {
            category: '70-79',
            values: [
                { name: 'Female', value: 650, colorindex: 0 },
                { name: 'Male', value: 930, colorindex: 1 },
            ],
        },
        {
            category: '80-89',
            values: [
                { name: 'Male', value: 640, colorindex: 1 },
                { name: 'Female', value: 430, colorindex: 0 },
            ],
        },
    ];


export const DrilldownWithTotalAgeBand =
    [
        {
            category: '20-29',
            values: [
                { name: 'Female', value: 390, colorindex: 0 },
                { name: 'Male', value: 470, colorindex: 1 },
            ],
        },
        {
            category: '30-39',
            values: [
                { name: 'Female', value: 610, colorindex: 0 },
                { name: 'Male', value: 960, colorindex: 1 },
                { name: 'Grand_Total', value: 1570, colorindex: 4 },
            ],
        },
        {
            category: '40-49',
            values: [
                { name: 'Male', value: 820, colorindex: 0 },
                { name: 'Female', value: 660, colorindex: 1 },
                { name: 'Grand_Total', value: 1480, colorindex: 4 },
            ],
        },
        {
            category: '50-59',
            values: [
                { name: 'Male', value: 930, colorindex: 0 },
                { name: 'Female', value: 720, colorindex: 1 },
                { name: 'Grand_Total', value: 1650, colorindex: 4 },
            ],
        },
        {
            category: '60-69',
            values: [
                { name: 'Female', value: 610, colorindex: 0 },
                { name: 'Male', value: 1180, colorindex: 1 },
                { name: 'Grand_Total', value: 1790, colorindex: 4 },
            ],
        },
        {
            category: '70-79',
            values: [
                { name: 'Female', value: 650, colorindex: 0 },
                { name: 'Male', value: 930, colorindex: 1 },
                { name: 'Grand_Total', value: 1580, colorindex: 4 },
            ],
        },
        {
            category: '80-89',
            values: [
                { name: 'Male', value: 640, colorindex: 1 },
                { name: 'Female', value: 430, colorindex: 0 },
                { name: 'Grand_Total', value: 1070, colorindex: 4 },
            ],
        },
    ];
export const DrilldownSalaryBand =
    [
        {
            category: '100K',
            values: [
                { name: 'Male', value: 970, colorindex: 1 },
                { name: 'Female', value: 960, colorindex: 0 },
            ],
        },
        {
            category: '50-100K',
            values: [
                { name: 'Male', value: 610, colorindex: 1 },
                { name: 'Female', value: 650, colorindex: 0 },
            ],
        },
        {
            category: '50K',
            values: [
                { name: 'Male', value: 858, colorindex: 1 },
                { name: 'Female', value: 848, colorindex: 0 },
            ],
        },
    ];


export const LocationTableColumnDefs = [
    {
        headerName: 'Location',
        field: 'name',
        pinned: 'left',


        width: 250,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;font-size:600;',
    },
    {
        headerName: 'Salary',
        field: 'salary',
        pinned: 'left',


        width: 250,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;font-size:600;',
    },
    {
        headerName: 'Score',
        field: 'value',

        flex: 1,
        width: 110,
        pinned: 'left',
        cellClass: 'grid-cell-left numeral',
    },

];


export const BenefitScore_Overtime_Location = [
    {
        category: 'London',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 680,
            },
            {
                name: 'Current',
                value: 940,
            },
        ],
    },
    {
        category: 'Krakow',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 950,
            },
            {
                name: 'Current',
                value: 878,
            },
        ],
    }, {
        category: 'Singapore',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 845,
            },
            {
                name: 'Current',
                value: 854,
            },
        ],
    },
    {
        category: 'California',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 640,
            },
            {
                name: 'Current',
                value: 690,
            },
        ],
    },
    {
        category: 'Brasilia',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 870,
            },
            {
                name: 'Current',
                value: 820,
            },
        ],
    },
    {
        category: 'Sydney',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 960,
            },
            {
                name: 'Current',
                value: 920,
            },
        ],
    },
    {
        category: 'Bangalore',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 870,
            },
            {
                name: 'Current',
                value: 820,
            },
        ],
    }
];
export const BenefitScore_Overtime_SalaryBand = [
    {
        category: '100 K',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 790,
            },
            {
                name: 'Current',
                value: 830,
            },
        ],
    },
    {
        category: '50-100 K',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 850,
            },
            {
                name: 'Current',
                value: 978,
            },
        ],
    },
    {
        category: '<50 K',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 760,
            },
            {
                name: 'Current',
                value: 818,
            },
        ],
    },
];

export const BenefitScore_Overtime_Gender = [
    {
        category: 'Male',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 880,
            },
            {
                name: 'Current',
                value: 940,
            },
        ],
    },
    {
        category: 'Female',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 750,
            },
            {
                name: 'Current',
                value: 878,
            },
        ],
    },
];


export const BenefitScore_Overtime = [
    {
        category: '20-29',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 180,
            },
            {
                name: 'Current',
                value: 240,
            },
        ],
    },
    {
        category: '30-39',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 750,
            },
            {
                name: 'Current',
                value: 578,
            },
        ],
    },
    {
        category: '40-49',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 745,
            },
            {
                name: 'Current',
                value: 654,
            },
        ],
    },
    {
        category: '50-59',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 840,
            },
            {
                name: 'Current',
                value: 890,
            },
        ],
    },
    {
        category: '60-69',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 770,
            },
            {
                name: 'Current',
                value: 720,
            },
        ],
    },
];

export const BenefitScoreChartCategories = [
    {
        category: 'Dependent Care',
        values: [
            {
                name: 'Prior', striped: true,
                value: 180,
                colorindex: 1

            },
            {
                name: 'Current',
                value: 240,
                iserror: true,
                colorindex: 1
            },
        ],
    },
    {
        category: 'Retirement',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 750,
            },
            {
                name: 'Current', colorindex: 1,
                value: 578,
            },
        ],
    },
    {
        category: 'Financial',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 745,
            },
            {
                name: 'Current', colorindex: 1,
                value: 654,
            },
        ],
    },
    {
        category: 'Life  & Health',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 840,
            },
            {
                name: 'Current', colorindex: 1,
                value: 890,
            },
        ],
    },
    {
        category: 'Travel',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 470,
            },
            {
                name: 'Current', colorindex: 1,
                value: 310,
            },
        ],
    },
    {
        category: 'Entertainment',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 580,
            },
            {
                name: 'Current', colorindex: 1,
                value: 670,
            },
        ],
    },
    {
        category: 'Other',
        values: [
            {
                name: 'Prior', striped: true, colorindex: 1,
                value: 650,
            },
            {
                name: 'Current', colorindex: 1,
                value: 630,
            },
        ],
    },
];

export const BenefitCategories = [
    {
        name: 'Dependent Care', isexpanded: false, expandheight: 500,
        value: 240,
        questions: [
            {
                id: 1, name: '1', questionSelected: false,
                description: 'Childcare vouchers', value: 200, colorindex: 11
            },
            {
                id: 2, name: '2', questionSelected: true,
                description: 'Child care /elderly emergancy cover', value: 2500, colorindex: 11
            },
            {
                id: 3, name: '3', questionSelected: true,
                description: 'Elder / care Advice',
                value: 2200, colorindex: 11
            },
            {
                id: 4, name: '4', questionSelected: true,
                description: 'Kidspass',
                value: 1800, colorindex: 11
            },
        ]
    },
    {
        name: 'Retirement', isexpanded: false, expandheight: 500,
        value: 578,
        questions: [
            {
                id: 6, name: '6', questionSelected: true, description:
                    'Retirement Modelling Tools',
                value: 895, colorindex: 11
            },
            {
                id: 7, name: '7', questionSelected: false,
                description: 'Pre-retirement/Annuity Broking Service', value: 976, colorindex: 11
            },
            {
                id: 8, name: '8', questionSelected: true,
                description: 'AE Qualifying Pension', value: 600, colorindex: 11
            },
        ]
    },
    {
        name: 'Financial', isexpanded: false, expandheight: 1900,
        value: 654,
        questions: [
            {
                id: 9, name: '9', questionSelected: true,
                description: 'Discount Lending e.g. Neyber', value: 709, colorindex: 11
            },
            {
                id: 11, name: '11', questionSelected: true, category: 'Financial',
                description: 'Tax Return Service', value: 870, colorindex: 11
            },
            {
                id: 12, name: '12', questionSelected: true, category: 'Financial',
                description: 'Workplace ISA/LISA/General Investment Account', value: 611, colorindex: 11
            },
            {
                id: 13, name: '13', questionSelected: false, category: 'Financial',
                description: 'Income Protection EE funded', value: 591, colorindex: 11
            },
            {
                id: 14, name: '14', questionSelected: false, category: 'Financial',
                description: 'Income Protection ER funded', value: 538, colorindex: 11
            },
            {
                id: 15, name: '15', questionSelected: true, category: 'Financial',
                description: 'Financial Management Dashboard', value: 752, colorindex: 11
            },
            {
                id: 16, name: '16', questionSelected: true, category: 'Financial',
                description: 'Cashflow Modelling (Payslip and Tax codes)', value: 989, colorindex: 11
            },
            {
                id: 17, name: '17', questionSelected: true, category: 'Financial',
                description: 'Budgeting Tools', value: 994, colorindex: 11
            },
            {
                id: 18, name: '18', questionSelected: true, category: 'Financial',
                description: 'Annual Allowance/Lifetime Allowance Modelling', value: 727, colorindex: 11
            },
            {
                id: 19, name: '19', questionSelected: true, category: 'Financial',
                description: 'Financial Education (top tips, retirment planning etc)', value: 913, colorindex: 11
            },
            {
                id: 20, name: '20', questionSelected: true, category: 'Financial',
                description: 'Financial advice', value: 986, colorindex: 11
            },

        ]
    },
    {
        name: 'Life  & Health', isexpanded: false, expandheight: 2400,
        value: 890,
        questions: [
            {
                id: 21, name: '21', questionSelected: false, category: 'Life  & Health',
                description: 'Critical illness', value: 806, colorindex: 11
            },
            {
                id: 22, name: '22', questionSelected: false, category: 'Life  & Health',
                description: 'Disability Cover', value: 761, colorindex: 11
            },
            {
                id: 23, name: '23', questionSelected: true, category: 'Life  & Health',
                description: 'Dental Plan', value: 751, colorindex: 11
            },
            {
                id: 24, name: '24', questionSelected: true, category: 'Life  & Health',
                description: 'Health screening', value: 899, colorindex: 11
            },
            {
                id: 25, name: '25', questionSelected: true, category: 'Life  & Health',
                description: 'Death-in-Service', value: 861, colorindex: 11
            },
            {
                id: 26, name: '26', questionSelected: true, category: 'Life  & Health',
                description: 'Health Cash Plan All', value: 799, colorindex: 11
            },
            {
                id: 27, name: '27', questionSelected: false, category: 'Life  & Health',
                description: 'Employee Assistance Programme ', value: 623, colorindex: 11
            },
            {
                id: 28, name: '28', questionSelected: true, category: 'Life  & Health',
                description: 'Private GP', value: 864, colorindex: 11
            },
            {
                id: 29, name: '29', questionSelected: true, category: 'Life  & Health',
                description: 'Private Medical Insurance All', value: 730, colorindex: 11
            },
            {
                id: 30, name: '30', questionSelected: true, category: 'Life  & Health',
                description: 'Private Medical Insurance EE', value: 515, colorindex: 11
            },
            {
                id: 31, name: '31', questionSelected: false, category: 'Life  & Health',
                description: 'Private Medical Insurance Family', value: 426, colorindex: 11
            },
            {
                id: 32, name: '32', questionSelected: true, category: 'Life  & Health',
                description: 'Private Medical Insurance PR', value: 437, colorindex: 11
            },
            {
                id: 33, name: '33', questionSelected: true, category: 'Life  & Health',
                description: 'Private Medical Insurance Single Family', value: 867, colorindex: 11
            },
            {
                id: 34, name: '34', questionSelected: true, category: 'Life  & Health',
                description: 'Personal Accident', value: 882, colorindex: 11
            },

        ]
    },
    {
        name: 'Travel', isexpanded: false, expandheight: 1200,
        value: 310,
        questions: [
            {
                id: 35, name: '35', questionSelected: false, category: 'Travel',
                description: 'Car beakdown cover', value: 654, colorindex: 11
            },
            {
                id: 36, name: '36', questionSelected: true, category: 'Travel',
                description: 'Travel Insurance', value: 871, colorindex: 11
            },
            {
                id: 37, name: '37', questionSelected: true, category: 'Travel',
                description: 'Holiday Trade All', value: 668, colorindex: 11
            },
            {
                id: 38, name: '38', questionSelected: true, category: 'Travel',
                description: 'Holiday Travel Accounts', value: 612, colorindex: 11
            },
            {
                id: 39, name: '39', questionSelected: false, category: 'Travel',
                description: 'Travel Insurance All', value: 932, colorindex: 11
            },
            {
                id: 40, name: '40', questionSelected: true, category: 'Travel',
                description: 'Travel Insurance Family', value: 624, colorindex: 11
            },
            {
                id: 41, name: '41', questionSelected: true, category: 'Travel',
                description: 'Travel Insurance Single', value: 656, colorindex: 11
            },
            {
                id: 42, name: '42', questionSelected: true, category: 'Travel',
                description: 'Travel Insurance Single Family', value: 451, colorindex: 11
            },

        ]
    },
    {
        name: 'Entertainment', isexpanded: false, expandheight: 900,
        value: 670,
        questions: [
            {
                id: 43, name: '43', questionSelected: false, category: 'Entertainment',
                description: 'Discount vouchers', value: 941, colorindex: 11
            },
            {
                id: 44, name: '44', questionSelected: false, category: 'Entertainment',
                description: 'Home Technology', value: 618, colorindex: 11
            },
            {
                id: 45, name: '45', questionSelected: true, category: 'Entertainment',
                description: 'Magazine subscriptions', value: 401, colorindex: 11
            },
            {
                id: 46, name: '46', questionSelected: true, category: 'Entertainment',
                description: 'Mobile phone', value: 584, colorindex: 11
            },
            {
                id: 47, name: '47', questionSelected: true, category: 'Entertainment',
                description: 'Retail Vouchers', value: 545, colorindex: 11
            },
            {
                id: 48, name: '48', questionSelected: true, category: 'Entertainment',
                description: 'Season ticket loan EE', value: 859, colorindex: 11
            },
            {
                id: 49, name: '49', questionSelected: true, category: 'Entertainment',
                description: 'Wine', value: 894, colorindex: 11
            },

        ]
    },
    {
        name: 'Other', isexpanded: false, expandheight: 700,
        value: 630,
        questions: [
            {
                id: 50, name: '50', questionSelected: true, category: 'Other',
                description: 'Will Writing Service', value: 897, colorindex: 11
            },
            {
                id: 51, name: '51', questionSelected: true, category: 'Other',
                description: 'Identity Protection', value: 614, colorindex: 11
            },
            {
                id: 52, name: '52', questionSelected: true, category: 'Other',
                description: 'Drawdown solution', value: 673, colorindex: 11
            },
            {
                id: 53, name: '53', questionSelected: false, category: 'Other',
                description: 'Share Scheme', value: 987, colorindex: 11
            },

        ]
    },

];

export const BenefitQuestions = [
    {
        id: 1, name: '1',
        description: 'I could handle a major unexpected expense', value: 200, colorindex: 11
    },
    {
        id: 2, name: '2',
        description: 'I am securing my financial future', value: 2500, colorindex: 11
    },
    {
        id: 3, name: '3', description: 'Because of money situation, I feel like I will never have the things i want in life',
        value: 2200, colorindex: 11
    },
    {
        id: 4, name: '4', description: 'I can enjoy life because of the way I\'m managing my money',
        value: 1800, colorindex: 11
    },
    {
        id: 5, name: '5', description: 'I am just getting by financially',
        value: 2900, colorindex: 11
    },
    {
        id: 6, name: '6', description: 'I am concerned that the money I have or will save won\'t last',
        value: 895, colorindex: 11
    },
    {
        id: 7, name: '7', description: 'Giving a gift for a wedding, birthday or other occasion would put a strain on my finances for the month',
        value: 976, colorindex: 11
    },
    {
        id: 8, name: '8', description: 'I have money left over at the end of the month',
        value: 600, colorindex: 11
    },
    {
        id: 9, name: '9', description: 'I am behind with my finances',
        value: 709, colorindex: 11
    },
    {
        id: 10, name: '10', description: 'My finances control my life',
        value: 777, colorindex: 11
    },
];


export const columnDefsAgeBand = [
    {
        headerName: 'Country',
        field: 'category',
        pinned: 'left',
        width: 230,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;font-size:600;',
    },
    {
        headerName: '19-29',
        field: 'ageband19_29',
        pinned: 'left',
        width: 180,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;font-size:600;',
    },
    {
        headerName: '29-39',
        field: 'ageband29_39',
        pinned: 'left',
        width: 180,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;font-size:600;',
    },
    {
        headerName: '39-49',
        field: 'ageband39_49',
        pinned: 'left',
        width: 180,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;font-size:600;',
    },
    {
        headerName: '49-59',
        field: 'ageband49_59',
        pinned: 'left',
        width: 180,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;font-size:600;',
    },
    {
        headerName: '60 & above',
        field: 'ageband60',
        pinned: 'left',
        width: 175,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;font-size:600;',
    },
    {
        headerName: 'Total',
        field: 'total',
        pinned: 'left',
        width: 175,
        cellClass: 'grid-cell-left ',
        style: 'font-family:large;font-size:600;',
    },
];

export const BenefitScoreLocationDataByAgeBand = [
    {
        name: 'London',
        ageband19_29: 421,
        ageband29_39: 517,
        ageband39_49: 345,
        ageband49_59: 464,
        ageband60: 454,
        colorindex: 4,
    },
    {
        name: 'Krakow',
        ageband19_29: 897,
        ageband29_39: 754,
        ageband39_49: 345,
        ageband49_59: 464,
        ageband60: 854,
        colorindex: 4,
    },
    {
        name: 'Singapore',
        ageband19_29: 575,
        ageband29_39: 457,
        ageband39_49: 345,
        ageband49_59: 464,
        ageband60: 575,
        colorindex: 4,
    },
    {
        name: 'California',
        ageband19_29: 757,
        ageband29_39: 474,
        ageband39_49: 345,
        ageband49_59: 457,
        ageband60: 456,
        colorindex: 4,
    },
    {
        name: 'Brasilia',
        ageband19_29: 586,
        ageband29_39: 577,
        ageband39_49: 656,
        ageband49_59: 563,
        ageband60: 566,
        colorindex: 4,
    },
    {
        name: 'Bangalore',
        ageband19_29: 462,
        ageband29_39: 264,
        ageband39_49: 435,
        ageband49_59: 424,
        ageband60: 834,
        colorindex: 4,
    },
    {
        name: 'Sydney',
        ageband19_29: 721,
        ageband29_39: 617,
        ageband39_49: 345,
        ageband49_59: 423,
        ageband60: 522,
        colorindex: 4,
    },
];

export const columnDefsSalaryBand = [
    {
        headerName: 'Country',
        field: 'category',
        pinned: 'left',
        width: 300,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;font-size:600;',
    },
    {
        headerName: '50 K',
        field: 'salaryband50K',
        pinned: 'left',
        width: 275,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;font-size:600;',
    },
    {
        headerName: '50 - 100 K',
        field: 'salaryband50_100K',
        pinned: 'left',
        width: 275,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;font-size:600;',
    },
    {
        headerName: '100 K',
        field: 'salaryband100K',
        pinned: 'left',
        width: 275,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;font-size:600;',
    },
    {
        headerName: 'Total',
        field: 'total',
        pinned: 'left',
        width: 275,
        cellClass: 'grid-cell-left ',
        style: 'font-family:large;font-size:600;',
    },
];

export const BenefitScoreLocationDataBySalaryBand = [
    {
        name: 'London',
        salaryband100K: 345,
        salaryband50_100K: 654,
        salaryband50K: 546,
        colorindex: 4,
    },
    {
        name: 'Krakow',
        salaryband100K: 474,
        salaryband50_100K: 565,
        salaryband50K: 535,
        colorindex: 4,
    },
    {
        name: 'Singapore',
        salaryband100K: 536,
        salaryband50_100K: 754,
        salaryband50K: 346,
        colorindex: 4,
    },
    {
        name: 'California',
        salaryband100K: 643,
        salaryband50_100K: 455,
        salaryband50K: 345,
        colorindex: 4,
    },
    {
        name: 'Brasilia',
        salaryband100K: 345,
        salaryband50_100K: 546,
        salaryband50K: 564,
        colorindex: 4,
    },
    {
        name: 'Bangalore',
        salaryband100K: 364,
        salaryband50_100K: 546,
        salaryband50K: 531,
        colorindex: 4,
    },
    {
        name: 'Sydney',
        salaryband100K: 650,
        salaryband50_100K: 485,
        salaryband50K: 584,
        colorindex: 4,
    },
];

export const columnDefsGender = [
    {
        headerName: 'Country',
        field: 'category',
        pinned: 'left',
        width: 360,
        cellClass: 'grid-cell-left ',
        style: 'font-family:large;font-size:600;',
    },
    {
        headerName: 'Male',
        field: 'genderMale',
        pinned: 'left',
        width: 320,
        cellClass: 'grid-cell-left ',
        style: 'font-family:large;font-size:600;',
    },
    {
        headerName: 'Female',
        field: 'genderFemale',
        pinned: 'left',
        width: 320,
        cellClass: 'grid-cell-left ',
        style: 'font-family:large;font-size:600;',
    },
    {
        headerName: 'Total',
        field: 'total',
        pinned: 'left',
        width: 300,
        cellClass: 'grid-cell-left ',
        style: 'font-family:large;font-size:600;',
    },
];
export const BenefitScoreLocationDataByGender = [
    {
        name: 'London',
        genderMale: 386,
        genderFemale: 499,
        colorindex: 4,
    },
    {
        name: 'Krakow',
        genderMale: 679,
        genderFemale: 533,
        colorindex: 4,
    },
    {
        name: 'Singapore',
        genderMale: 634,
        genderFemale: 649,
        colorindex: 4,
    },
    {
        name: 'California',
        genderMale: 458,
        genderFemale: 583,
        colorindex: 4,
    },
    {
        name: 'Brasilia',
        genderMale: 528,
        genderFemale: 485,
        colorindex: 4,
    },
    {
        name: 'Bangalore',
        genderMale: 558,
        genderFemale: 538,
        colorindex: 4,
    },
    {
        name: 'Sydney',
        genderMale: 468,
        genderFemale: 583,
        colorindex: 4,
    },
];