export const locale = {
	lang: 'pt-br',
	data: {
		'EnrollmentOverTime': {
			'EnrollmentOverTimeHeading': 'Adesão por Tempo',
			'Male': 'Masculino',
			'Female': 'Feminino',
			'NA': 'NA',
			'Dimensions': 'Dimensão',
			'AgeBand': 'Faixa Etária',
			'Gender': 'Gênero',
			'Relationship': 'Relacionamento',
			'IsEmployee': 'Titular vs Dependente',
			'Employee': 'Funcionária(o)',
			'Dependent': 'Dependente',
			'Monthly': 'Mensal',
			'YearToDateBasis': 'Base do Ano-até-Hoje',
			'YearToDateBasisC': 'Base (C) do Ano-até-Hoje',
			'Rolling3Basis': 'Base dos Últimos 3 meses',
			'Rolling6Basis': 'Base dos Últimos 6 meses',
			'Rolling9Basis': 'Base dos Últimos 9 meses',
			'Rolling12Basis': 'Base dos Últimos 12 meses',
			'DateRange': 'to'
		}
	}
};