export const locale = {
    lang: 'es-ec',
    data: {
        'ExportCart': {
            'ExportCart': 'Exportar carrito',
            'CanViewDeleteAddedDashboardsHere': 'Puede ver/eliminar los dashboards agregados al carrito',
            'Dashboards': 'Dashboards',
            'ExportAll': 'Exportar todos',
            'ClearAll': 'Eliminar todos',
            'Name': 'Nombre',
            'Export': 'Exportar',
            'ExportTitle': 'Exportar título',
            'Preview': 'Vista preliminar',
            'Comments': 'Comentarios',
            'FiltersSelected': 'Filtros seleccionados',
            'NoFilterSelected': 'No hay filtros seleccionados',
            'AdditionalFiltersSelected': 'Filtros adicionales seleccionados',
            'FiltersMightNotApply': 'Los filtros pueden no aplicarse'
        }
    }
};