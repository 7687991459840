export const locale = {
    lang: 'it',
    data: {
  'StoriesAdmin': {
    'StoriesAdministration': 'AMMINISTRAZIONE DI STORIE',
    'Stories': 'Storie',
    'NewStory': 'NUOVA STORIA',
    'Users': 'Utenti',
    'Save': 'SALVA',
    'SelectAll': 'SELEZIONA TUTTO',
    'Success': 'Successo',
    'Name': 'Nome',
    'CreatedBy': 'Creato da',
    'CreatedOn': 'Creato il',
    'userColumnDefs': [{headerName: 'Email', field: 'email', sort: 'asc'}, {headerName: 'Nome Utente', field: 'userName', sort: 'asc'}]
  }
}};