import { Component, OnInit, Input, OnChanges, ElementRef } from "@angular/core";

import { TitleCasePipe } from "@angular/common";
import * as Highcharts from 'highcharts';

@Component({
    selector: "app-pulse-donut",
    templateUrl: "./pulse-donut.component.html",
    styleUrls: ["./pulse-donut.component.css"]
})

export class PulseDonutComponent implements OnInit, OnChanges {
    @Input() datalist: any = [];
    @Input() param: string;
    @Input() propertyID: any;
    @Input() elementRefParent: any;
    @Input() totalPercent: any;
    @Input() suffixSymbolForTotal: string = "%";
    @Input() suffixSymbolForLabel: string = "%";
    @Input() hideLabels: boolean = false;
    @Input() maxlabelLength: number = 10;
    @Input() donutWidth: number = 450; 
    @Input() donutHeight: number = 350; 
    @Input() isRoundOffdonutValueEnabled:  boolean = true;


    Highcharts: typeof Highcharts = Highcharts;
    chartRef:any;
    chartCallback:any;
    chartOptions:any;
    hcUpdateFlag = false;
    chartConstructor ="chart";
    donutData:any[];
    constructor(){
        var self = this;
        this.chartCallback = chart => {
            // saving chart reference
            self.chartRef = chart;
        };
    }
    ngOnChanges() {
        if (typeof this.datalist[this.param] === 'undefined') {
            return;
        }

       this.setChart();
    }

    ngOnInit() {
        this.setChart();
    }

    setChart(){
        var data = this.formatdonut(this.datalist[this.param]);
        if(data === undefined){
            data = [];
        }
        this.chartOptions = this.getChartOptions(data);
        this.updateHC()
        
    }

    updateHC() {
        this.hcUpdateFlag = true;
    }
    formatdonut(data: any) {
        this.donutData = [];
        if (!data) return;
        for (var key in data) {
            if (!(data[key] == null)) {
                let val = Math.round(data[key] * 10) / 10;
                if(this.isRoundOffdonutValueEnabled == true)
                {
                 if (Math.abs(val) > 0)
                    {
                        this.donutData.push({
                        name: key,
                        //new TitleCasePipe().transform(key),
                        y:val,
                        value: val
                    });
                }
                }else { 
                    this.donutData.push({
                        name: key,
                        y:val,
                        value: val
                    });
                }
            }
        }
        return this.donutData;
    }
    getChartOptions(dataArr){
        var totalText = this.totalPercent + " " + this.suffixSymbolForTotal;
        var titleText = '<div style="font-size:2em; font-weight:bold; line-height:1; color:#101E7F">'+totalText+'</div>';
        if(dataArr.length === 0){
            titleText ='';
        }

        var chartOptions = {
            chart: {
                renderTo: 'container',
                type: 'pie'
            },
            title: {
                text: titleText ,
                align: 'center',
                verticalAlign: 'middle'                
            },
            yAxis: {
                title: {
                    text: ''
                }
            },
            plotOptions: {
                pie: {
                    shadow: false
                }
            },
            tooltip: {
                formatter: function() {
                    return '<b>'+ this.point.name +'</b>: '+ this.y +' %';
                }
            },
            credits: {
                enabled: false
              },
            series: [{
                name: 'Pie',
                data: dataArr,
                size: '70%',
                innerSize: '70%',
                showInLegend:false,
                dataLabels: {
                    enabled: true,
                    formatter: function() {
                        return this.y +' % '+ this.point.name;
                    }
                }
            }]    
        }
        return chartOptions;
    }
}