// Section 1
import { Action } from '@ngrx/store'
import { CLAIM_BASIS } from 'app/models/basis.model';
import { TimePeriod } from 'app/models/time-period';
import { User } from './../models/user.model'

// Section 2
export const LOGIN_USER = '[USER] Login';
export const LOGOUT_USER = '[USER] Logout';
export const SET_USER_CLIENT = '[USER] SetUserClient';
export const SET_USER_COUNTRY = '[USER] SetUserCountry';
export const SET_USER_LANGUAGE = '[USER] SetUserLanguage';
export const SET_USER_TIME_PERIOD = '[USER] SetUserTimePeriod';
export const SET_USER_ROLLING_PERIOD = '[USER] SetUserRollingPeriod';
export const SET_USER_PERIOD_SELECTION = '[USER] SetUserPeriodSelection';
export const SET_CLAIM_PAID = '[USER] SetUserClaimPaid';
export const SET_PLAN_YEAR = '[USER] SetPlanYear';

// Section 3
export class LoginUser implements Action {
    readonly type = LOGIN_USER

    constructor(public payload: User) { }
}

export class LogoutUser implements Action {
    readonly type = LOGOUT_USER

    constructor(public payload: number) { }
}

export class SetUserClient implements Action {
    readonly type = SET_USER_CLIENT

    constructor(public payload: string) { }
}

export class SetUserTimePeriod implements Action {
    readonly type = SET_USER_TIME_PERIOD

    constructor(public payload: string) { }
}

export class SetUserCountry implements Action {
    readonly type = SET_USER_COUNTRY

    constructor(public payload: string) { }
}

export class SetUserLanguage implements Action {
    readonly type = SET_USER_LANGUAGE

    constructor(public payload: string) { }
}

export class SetUserRollingPeriod implements Action {
    readonly type = SET_USER_ROLLING_PERIOD

    constructor(public payload: number) { }
}


export class SetUserPeriodSelection implements Action {
    readonly type = SET_USER_PERIOD_SELECTION

    constructor(public payload: number) { }
}

export class SetClaimPaid implements Action {
    readonly type = SET_CLAIM_PAID

    constructor(public payload: CLAIM_BASIS) { }
}

export class SetPlanYear implements Action {
    readonly type = SET_PLAN_YEAR

    constructor(public payload: number) { }
}

// Section 4
export type Actions = LoginUser | LogoutUser | SetUserClient | SetUserCountry | SetUserLanguage | SetUserTimePeriod | SetUserRollingPeriod | SetUserPeriodSelection | SetClaimPaid | SetPlanYear;
