<div class="flex-column-simple" *ngIf="!isChartVisible">
    <div class="flex-row-simple" style="margin: auto;">
        <div fxLayout="column" fxFlex="50" fxLayoutGap="12px" style="padding: 18px;" *ngIf="!isChartVisible">
            <mat-spinner class="mat-accent" diameter="30"></mat-spinner>
        </div>
    </div>
</div>
<div class="contentPadding columnDiv" *ngIf="isChartVisible">
    <div class="rowDiv">
        <div style="display:flex; justify-content: space-between">
          
                <div>
                    <aon-h1 style="color: #001b5a;">Retirement Adequacy</aon-h1>
                </div>
                <div class="vertical-line marginLeft20"></div>
                <div class="marginLeft20 marginTop20">
                    <aon-p-lg>
                        from 1 July, 2019 through 1 July, 2020
                    </aon-p-lg>
                </div>
         
        </div>

            <div style="display:flex; justify-content: flex-end">

                <div style=" position: relative;  z-index:300;
                color: #001b5a;margin-top: 5px; margin-left: 300px; width:350px;">
                    <div style="display: flex; flex-direction:row; justify-content: space-around">
                    <div>  <aon-h5 style="color: #001b5a;">    Lifestyle   </aon-h5>  </div>
                    <div>
                        <aon-switch (ontoggle)="togglebyTarget()" [switchon]="!toggleTarget" width='150px'>                    
                        </aon-switch> 
                    </div>
                    <div style="margin-left: -100px;"> 
                        <aon-h5 style="color: #001b5a;">  Replacement Ratio
                        </aon-h5>
                    </div> 
                    </div>
                </div>
            </div> 
    </div>
    
        <!-- <retirement-target> </retirement-target> -->
      <div class="rowDiv" style="width: 100%;"> 
    
  
        <div class="columnDiv " *ngIf="toggleTarget"  style="width: 100%;"> 
            <div   >

            <retirement-summary> </retirement-summary>
            </div> 
             <div style="margin-top:30px"  >

            <retirement-drill-down> </retirement-drill-down> 
            </div>
        </div>
        <div class="columnDiv " *ngIf="!toggleTarget"  style="width: 100%;"> 
           
            <div   >

                <retirement-replacement> </retirement-replacement>
                </div> 
                 <div style="margin-top:10px; width:99%"  >
                    
                <retirement-scenario >  </retirement-scenario> 
                </div>
        </div>
    </div>
</div>