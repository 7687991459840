export const locale = {
    lang: 'it',
    data: {
  'EnrollmentOverTime': {
    'EnrollmentOverTimeHeading': 'Andamento anagrafiche nel tempo',
    'Male': 'Uomo',
    'Female': 'Femmina',
    'NA': 'NA',
    'Dimensions': 'Dimensione',
    'AgeBand': "Fascia d'età",
    'Gender': 'Genere',
    'Relationship': 'Relazione',
    'IsEmployee': 'Dipendenti vs Familiari',
    'Employee': 'Dipendente',
    'Dependent': 'Dipendente',
    'Monthly': 'Mensile',
    'YearToDateBasis': "Base dall'inizio dell'anno",
    'YearToDateBasisC': "Base dall'inizio dell'anno (C)",
    'Rolling3Basis': 'Rolling base 3',
    'Rolling6Basis': 'Rolling base 6',
    'Rolling9Basis': 'Rolling base 9',
    'Rolling12Basis': 'Rolling base 12',
    'DateRange': 'Intervallo Di Date'
  }
}};