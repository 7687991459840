export const locale = {
    lang: 'es-mx',
    data: {
      'ClaimCostTopProvider': {
        'ClaimTopProviders' : 'Costo de Trámite- Por Top de Proveedores',        
        'ClaimCostByTopDiagnoses':'Gasto - Por Top Diagnósticos',
        'ProviderDiag' : 'Proveedores vs diagnóstico',
        'AllAmountsDisplayedInLocalCurrency' : 'Todas las cantidades mostradas en moneda local',
        'TopProvider' : 'Proveedores principales',
        'ProviderName': 'Nombre del proveedor', 
        'TopDiagnoses': 'Top Diagnósticos',
        'DiagnosisName': 'Nombre de Diagnóstico' ,          
        'TotalPaidClaim' : 'Total pagado', 
        'ClaimantCount': 'Total casos',
        'ClaimCount': 'Número de trámites',            
        'ClaimsPerClaimant' : 'Trámites por caso', 
        'CostPerClaim': 'Costo por Trámite',
        'CostPerClaimant': 'Costo por caso', 
        '%ChangeinCost':'% De cambio en el costo',
        'Claim' : 'Trámite', 
        'Claimant' : 'Caso', 
        'ClaimsTotal':'Total pagado',
        'Count': 'Total trámites',
        'AverageCost': 'Costo de trámite promedio',
        'ClaimsReportedByPaidDate': ' ',
        'ClaimsReportedByIncurredDate': ' ',
        'Benchmark':'Análisis comparativo',
        'IncludeClient': 'Incluir Cliente',
        'ExcludeClient': 'Excluir Cliente',
        'Portfolio': 'Portafolio',
        'Industry': 'Industria',
        'None': 'Ninguna',
        'SelectTop': 'Selección Top',
        'FilterNA': 'Excluir NA',
        'CountBasis': 'Contar Base',
        'Sort': 'Ordenar',
        'Reset':'Reiniciar',            
        'DiagnosisLevel':'Diagnostico',
        'Category':'Categoría',
        'Chapter':'Padecimientos',
        'ShownAsPer':'Se muestra según',
        'DateRange': 'to'
        
    }
}
};
