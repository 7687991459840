export class userActionLog {
    Message: string = "";
    MessageTemplate: string = "";
    Level: string = "";
    TimeStamp: Date =new Date();
    Exception: string = "";
    Properties: string = "";
    LogEvent: string = "";
    Login: string = "";
    UserId: string = "";
    UserName: string = "";
    ClientId: string = "";
    ClientName: string = "";
    Country: string = "";
    Role: string = "";
    StoryName: string = "";
    ExportDetails: string = "";
    StoryId: number = 0;
    CustomViewCreation: string = "";
    DashboardName: string = "";
    ExportId: number = 0;
    ActionName: string = "";
    LanguageName: string = "";
}

export enum LogActionType {
    SignUp = "Sign Up",
    UserLogin = "User Login",
    ViewDashboard = "View Dashboard",
    EditDashboard = "Edit Dashbboard",
    UserOptionsSelected = "Client Selected",
    AddStory = "Add Story",
    EditStory = "Edit Story",
    UpdateStory = "Update Story",
    ViewStory = "View Story",
    ExportPPT = "Export PPT",
    ExportImage = "Export Image",
    ExportCustomView = "Export Custom View",
    CreateCustomView = "Create Custom View"
}

export enum LoginType{
    Internal = "Internal",
    External = "External"
}

export  class localStory {
    storiesId : number;
    name : string;
   }