<div id="librayComp">
    <mat-form-field id="librayCompSearch" style="width: 100%;">
        <input id="librayCompSearchInput" matInput [formControl]="inputFilter" autocomplete="off">
        <mat-icon id="librayCompSearchIcon" matSuffix>search</mat-icon>
    </mat-form-field>
    <mat-spinner *ngIf="isLoading" class="mat-accent" style="margin: 0 auto" diameter="40"></mat-spinner>

    <ng-container *ngFor="let p of projects">
        <div *ngIf="!isLoading" style="border-bottom: 1px solid lightgray; text-transform: uppercase; margin-bottom: 8px; color:grey">{{p.name}}</div>
        <div id="librayContainer"fxLayout="row wrap">
            <ng-container *ngFor="let lib of library; let i = index">
                <ng-container *ngIf="lib.project.id == p.guid">
                    <mat-card *ngIf="!lib.hidden" style="width: 154px; margin-bottom: 0.5em; margin-right: 0.5em; padding: 3px" [style.border]="lib.isAdded? '1px solid #039be5' : ''">
                        <div style="font-size: 12px; font-weight: bold; height: 20px; line-height: 2; white-space: nowrap; overflow: hidden; text-overflow: ellipsis" [matTooltip]="lib.name" matTooltipPosition="above">{{lib.name}}</div>
                        <img id="libraryImg" alt="{{lib.name}}" src="{{lib.imageBase64String ? lib.imageBase64String : lib.src}}" style="margin-right: 2px;  height: 130px; width: 220px;">
                        <div fxLayout="row" fxLayoutAlign="end end">
                            <div fxFlex style="font-size: 12px; color: #CCC; height: 22px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis" [matTooltip]="lib.workbook.name" matTooltipPosition="above">{{lib.workbook.name}}</div>
                            <button id="libraryBtnAdd" mat-icon-button class="mat-accent" matTooltip="Add" (click)="addDashboard(lib)" [disabled]="lib.isAdded || isDisabledAdd">
                                <mat-icon>add</mat-icon>
                              </button>
                        </div>
                    </mat-card>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</div>