export const locale = {
    lang: 'en',
    data: {
        'ClaimCostDiag': {
            'ClaimDiagnosis':'Claim Type v Diagnosis',
            'SelectTop':'Select Top',
            'AllAmountsDisplayedInLocalCurrency': 'All amounts displayed in local currency',
            'ClaimType':'Claim Type',          
            'TotalPaidClaim' : 'Total Paid Claims', 
            'ClaimantCount':'Claimant Count',
            'ClaimCount':'Claim Count',            
            'ClaimsPerClaimant' : 'Claims Per Claimant', 
            'CostPerClaim': 'Cost Per Claim',
            'CostPerClaimant': 'Cost Per Claimant', 
            'ChangeInCost':'% Change in Cost',
            'Claim' : 'Claim', 
            'Claimant' : 'Claimant', 
            'ClaimsTotal':'Claims Total',
            'Count':'Count',
            'ClaimsReportedByPaidDate': 'Claims reported by paid date',
            'ClaimsReportedByIncurredDate': 'Claims reported by incurred date',
            'CountBasis':'Count Basis',
            'Sort':'Sort',
            'Show':'Show',
            'Hide':'Hide',
            'ShowHide':'Show/Hide Disease Quadrant',
            'DiagnosisLevel':'Diagnosis Level',
            'Category':'Category',
            'Chapter':'Chapter',            
           'ShownAsPer':'Shown as per',           
           'TopDiagnoses' : 'Top Diagnoses',           
           'AverageCost' :  'Average Cost',
           'FilterNA': 'Exclude NA',
           'DateRange': 'to'
}
    }
};
