import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  OnInit,
  OnChanges,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
import { filter } from 'rxjs/internal/operators/filter';
import { MatOption } from '@angular/material/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pulse-select',
  templateUrl: './pulse-select.component.html',
  styleUrls: ['./pulse-select.component.css']
})
export class PulseSelectComponent implements AfterViewInit, OnDestroy, OnInit, OnChanges {

  @Input() placeholder = '';
  @Input() options: any[];
  @Input() selection: any[] = [];
  @Input() tooltip = '';
  backupSelection: any[] = [];

  readonly ALL = 'ALL';

  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onApply: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(CdkVirtualScrollViewport, { static: true })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  @ViewChildren(MatOption)
  matOptions: QueryList<MatOption>;

  scroll$: Subscription = undefined;

  constructor(private cd: ChangeDetectorRef, readonly sd: ScrollDispatcher) { }

  ngOnInit(): void {
    if (this.options.length > 0) {
      this._selectAll();
    }
  }

  ngOnChanges(): void {
    if (this.options.length > 0) {
      this._selectAll();
    }
  }

  ngAfterViewInit(): void {
    this.scroll$ = this.sd
      .scrolled()
      .pipe(filter(scrollable => this.cdkVirtualScrollViewPort === scrollable))
      .subscribe(() => {
        let needUpdate = false;

        this.matOptions.forEach(option => {
          const selected = this.selection.includes(option.value);
    
          if (selected && !option.selected) {
            option.select();
            needUpdate = true;
            return;
          } 
          
          if (!selected && option.selected) {
            option.deselect();
            needUpdate = true;
            return;
          }
        });
    
        if (!needUpdate) {
          return;
        }

        this.cd.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.scroll$?.unsubscribe();
  }

  public toggleAll(event): void {
    if (event.isUserInput) {
      // Exists ALL
      if (this.selection.includes(this.ALL)) {
        this.selection = [];
      } else {
        // Just selected ALL
        this._selectAll();
      }
    }
  }

  public selectionChange(event): void {
    if (event.isUserInput) {
      const value = event.source.value;

      const index = this.selection.findIndex(x => x === value);

      if (index === -1) {
        this.selection.push(value);
      } else {
        this.selection.splice(index, 1);
      }
    }
  }

  public openedChange(isOpen): void {
    if (isOpen) {
      this.cdkVirtualScrollViewPort.scrollToIndex(0);
      this.cdkVirtualScrollViewPort.checkViewportSize();

      this.backupSelection = [... this.selection];
    }
  }

  public apply(): void {
    this.backupSelection = [... this.selection];
    this.selectionChanged.emit({ data: this.selection });
  }

  public cancel(): void {
    this.selection = [... this.backupSelection];
  }

  private _selectAll(): void {
    this.selection = [
      this.ALL,
      ...this.options,
    ];
  }
}
