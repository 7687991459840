export const locale = {
    lang: 'it',
    data: {
  'AddFilter': {
    'ClientName': 'Nome cliente',
    'Entity': 'Entità Legale',
    'Division': 'Divisione',
    'Plan': 'Piano',
    'TimePeriod': 'Periodo di tempo',
    'PolicyType': 'Tipo di polizza',
    'AgeBand': "Fascia d'età",
    'Gender': 'Genere',
    'ClaimType': 'Sinistri oer assistito attivo',
    'ClaimType2': 'Sinistri oer assistito attivo 2',
    'ClaimType3': 'Sinistri oer assistito attivo 3',
    'Relationship': 'Relazione',
    'DiagnosisCategory': 'Categoria Diagnosi',
    'DiagnosisChapter': 'Capitolo Diagnosi',
    'Location': 'Localita',
    'IsChronic': 'è cronico',
    'Carrier': 'Compagnia',
    'LOB': 'LOB',
    'SubLOB': 'Sub LOB',
    'Network': 'Rete Convenzionata',
    'ProviderName': 'Nome Provider',
    'ProviderLocation': 'Posizione dells Struttura',
    'ProviderType': 'Tipo di Struttura'
  }
}};