import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TableauService {

    // put inside environment

    constructor(private httpClient: HttpClient) { }

    public getToken() {
        return this.httpClient.get<any>(environment.tableauToken);
    }

    public getLibrary() {
        return this.httpClient.get<any>(environment.tableauLibrary);
    }

    public getDashboardUrlPath(token, dashUrl) {
        if (environment.useTableauToken) {
            return environment.tableau + '/trusted/' + token + environment.sitePath + dashUrl + this.getTableauAddOnsPath();
        } 
        // Leave this here for Global Parameters implementation
        // return environment.tableau + environment.sitePath + dashUrl + "?Client Name (Parameter)=AMDOCS" + this.tableauAddOnsPath 
        return environment.tableau + environment.sitePath + dashUrl + this.getTableauAddOnsPath();

    }

    public getTableauProjects() {
        return this.httpClient.get<any>(environment.api + 'api/tableau/projects');
    }  

    public getAllTableauProjects() {
        return this.httpClient.get<any>(environment.api + 'api/tableau/projects/all');
    }

    private getTableauAddOnsPath(): string {
        if (environment.profile.tableau.options.isDashboardRefresh) {
            return '&:embed=yes&:showVizHome=no&:tabs=no&:toolbar=yes&:apiID=handler0&:refresh=yes';
        }

        return '&:embed=yes&:showVizHome=no&:tabs=no&:toolbar=yes&:apiID=handler0';
    }

}
