import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as navigationEnglish } from 'app/admin/superadmin/i18n/en';
import { locale as navigationSpanishCL } from 'app/admin/superadmin/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/admin/superadmin/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/admin/superadmin/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/admin/superadmin/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/admin/superadmin/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/admin/superadmin/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/admin/superadmin/i18n/it';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';


import { ClientModel } from 'app/models/client.model';
import { CountryModel } from 'app/models/country.model';
import { UserModel, UserRoleModel } from 'app/models/user.model';
import { UserService } from 'app/services/user.service';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-superadmin',
  templateUrl: './superadmin.component.html',
  styleUrls: ['./superadmin.component.css']
})
export class SuperadminComponent implements OnInit {

  clientName: string;
  isClientSaveLoad = false;
  isCountrySaveLoad = false;
  isCountryEnabledStatusLoading = false;
  searchUserResults = [];
  rmGridApi;
  rmGridOptions;
  rmList = [];
  usersList = [];
  selectedRm: any;
  selectedClient: any;
  mode = 'users';
  isLoadingCountry = false;
  isLoadingRm = false;
  isLoadingClients = false;
  isNewClient = false;
  isNewCountry = false;
  countryName = '';
  countryTableauProjectId: any;
  selectedClientId = 0;
  selectedCountryId = 0;
  selectedCountryEnabled = false;
  countryGridApi;
  countryGridOptions;
  countryClientList = [];
  countryList = [];
  filteredCountryList = [];
  public clientModelObj: ClientModel;
  public countryModelObj: CountryModel;
  message = '';
  bulkmessage = '';
  bulkmessageList = [];
  countryViewColumnDefs: any;
  userList = [];
  tableauProjects = [];
  isNewUser = false;
  isAONEmail = false;
  userSaveLoading = false;  
  userRoleList = UserRoleModel;
  isEditUser = false;
  selectedUserRow = null;
  userModelObj = new UserModel();
  selectedUserRole: any;
  userRoleSaveLoading = false;
  userDeleteLoading = false;
  bulkUserTextArea = '';
  bulkClientTextArea = '';
  tbsUserId = '';
  isTbsUserIdInputLoading = false;
  tbsClientId = '';
  isTbsClientIdInputLoading = false;
  profile: any;
  isAdmin: boolean;
  isRegionalManager: boolean;

  public rmColumnDefs = [];
  public countryColumnDefs = [];
  public clientColumnDefs = [
    { 
      headerName: 'Name', 
      field: 'name', 
      sort: 'asc',
      width: 500
    },
    { 
        headerName: 'Is Group?', 
        field: 'isGroup',
        cellRenderer: params => {
            return `<input type='checkbox' disabled readonly ${params.value ? 'checked' : ''} />`;
        }
    },
    { 
        headerName: 'Created', 
        field: 'createdOn'
    }
  ];
  public countryClientColumnDefs = [];
  public userColumnDefs = [];

  clientList = [];
  isLoadingUsers: boolean;

  constructor(
    private _translateService: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    public adminService: AdminService,
    public dialog: MatDialog,
    public toasterService: ToasterService,
    public userService: UserService) { 
        this.profile = environment.profile;
        this.userModelObj.isExtad = this.profile.superadmin.users.isCreateExternalUserExtad;
        this.isAdmin = this.userService.isAdmin();
        this.isRegionalManager = this.userService.isRegionalManager();
    }

  onClientDelete($event) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: 'Confirm Deletion of ' + $event.data.name, message: 'Are you sure? All mapping will be deleted as well.' }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (typeof result === 'undefined') {
            return;
        }

        if (result === false) {
            return;
        }
        
        this.adminService.deleteClient($event.data.tier1Id).toPromise().then(() => {
            this.toasterService.showMessage('Record deleted.');

            this.adminService.getClients().toPromise().then((clientList: any) => {
                this.clientList = clientList;
            }); 
        });
    });
  }
  dateFormatter(params) {
    if (params.value !== null && params.value !== undefined){
      return moment(params.value).format('DD-MMM-YYYY');
    }
    return params.value;
    
  }

  onCountryDelete($event) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: 'Confirm Deletion of ' + $event.data.name, message: 'Are you sure? All mapping will be deleted as well.' }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (typeof result === 'undefined') {
            return;
        }

        if (result === false) {
            return;
        }

        this.adminService.deleteCountry($event.data.tier2Id).toPromise().then(() => {           
            this.toasterService.showMessage('Record deleted.');
            
            this.adminService.getCountries().toPromise().then((countryList: any) => {
              this.countryList = countryList;
              this.filteredCountryList = JSON.parse(JSON.stringify(countryList));
              this.loadEnabledCountryList();
            }); 
        });
    });
  }

  onRmDelete($event) {   
        const rm = $event.data;

        const dialogRef = this.dialog.open(DialogComponent, {
            data: { title: 'Confirm Deletion of ' + rm.userName, message: 'Are you sure? All mapping will be deleted as well.' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (typeof result === 'undefined') {
                return;
            }
    
            if (result === false) {
                return;
            }
    
            this.removeRm(rm);
            if (this.selectedRm.id === rm.id) {
                this.selectedRm = null;
            }
        });
  }

  addRm() {
    for (var i = 0; i < this.searchUserResults.length; i++) {
      this.adminService.assignRegionalManager(this.searchUserResults[i]).then((result: any) => {
        if (this.searchUserResults.length - i == 0) {
          this.adminService.getRegionalManagers().toPromise().then(data => {
            this.rmList = data;
          });
          this.searchUserResults = [];
        }
      });
    }
  }

  onRmSelectionChanged($event) {
    this.isLoadingCountry = true;
    this.selectedRm = $event.data;
    delete this.selectedClient;

    this.adminService.getUserCountries(this.selectedRm.id).toPromise().then(data => {
      this.isLoadingCountry = false;
      if (data) {
        const cIds = data.map(c => c.tier2Id);
        this.filteredCountryList.forEach(c => {
          c.isSelected = cIds.indexOf(c.tier2Id) >= 0;
        });
        this.filteredCountryList = JSON.parse(JSON.stringify(this.filteredCountryList));
      }
    });
  }


  onClientSelectionChanged($event) {
    this.message = '';
    this.isNewClient = false;
    this.isLoadingCountry = true;
    this.selectedClient = $event.data;
    delete this.selectedRm;

    this.adminService.getClientCountries(this.selectedClient.tier1Id).toPromise().then(data => {
      const cIds = data.map(c => c.tier2Id);
      this.filteredCountryList.forEach(c => {
        c.isSelected = cIds.indexOf(c.tier2Id) >= 0;
      });
    this.isTbsClientIdInputLoading = true;
    this.userService.getTbsClientId(this.selectedClient.tier1Id).toPromise().then((tbsClientId: string) => {
        this.tbsClientId = tbsClientId;
    }).catch(() => { 
        this.toasterService.showMessage('Unable to retrieve TBS Client Id', ToasterServiceModes.NOK); 
    }).finally(() => {
        this.isTbsClientIdInputLoading = false;
    });

      this.filteredCountryList = JSON.parse(JSON.stringify(this.filteredCountryList));
      this.selectedClientId = this.selectedClient.tier1Id;
      this.clientName = this.selectedClient.name;
      this.isLoadingCountry = false;
    });

  }

  onCountrySelectionChange($event) {
    this.isCountrySaveLoad = true;
    var selectedRow = $event.data;
    this.selectedCountryId = selectedRow.tier2Id;
    this.countryName = selectedRow.name;
    this.countryTableauProjectId = selectedRow.tableauProjectId;
    this.isNewCountry = true;
    this.selectedCountryEnabled = false;

    this.getClientsByCountry();
    this.getCountryEnabledStatus();
  }
  
  getClientsByCountry() {
    this.adminService.getClientsByCountry(this.selectedCountryId).toPromise().then(data => {
      this.isCountrySaveLoad = false;
      this.countryClientList = [... this.clientList];
      this.countryClientList.forEach(cc => {
        cc.isSelected = data.filter(d => d.tier1Id === cc.tier1Id).length > 0 ? true : false;
        //if(cc.isSelected ) console.log('wee');
      })
    });
  }

  getCountryEnabledStatus() {
      this.isCountryEnabledStatusLoading = true;
      this.adminService.getTier2EnabledStatus(this.selectedCountryId).toPromise().then(isEnabled => {
          this.selectedCountryEnabled = isEnabled;
          this.isCountryEnabledStatusLoading = false;
      });
  }

  setCountryEnabledStatus() {
      this.isCountryEnabledStatusLoading = true;  
      this.adminService.updateTier2EnabledStatus(this.selectedCountryId, this.selectedCountryEnabled).toPromise().then(isSuccess => {
        this.isCountryEnabledStatusLoading = false;
        this.loadCountryList();
      }, error => {
        this.isCountryEnabledStatusLoading = false;
      });
  }

  addNewClient() {
    this.closeCard();
    this.selectedClientId = 0;
    this.selectedClient = null;
    this.isNewClient = true;
  }

  closeCard() {
    this.clientName = '';
    this.countryName = '';
    this.tbsUserId = '';
    this.isTbsUserIdInputLoading = false;
    this.tbsClientId = '';
    this.isTbsClientIdInputLoading = false;
    delete this.countryTableauProjectId;
    this.isNewCountry = false;
    this.isNewClient = false;
    this.isNewUser = false;
    this.selectedClient = null;
    this.selectedCountryId = 0;
    this.selectedClientId = 0;
    this.message = '';
    this.selectedUserRow = null;
      this.isEditUser = false;
    this.countryList.forEach(c => {
      c.isSelected = false;
    });
    this.filteredCountryList.forEach(c => {
        c.isSelected = false;
      });
    this.filteredCountryList = JSON.parse(JSON.stringify(this.filteredCountryList));
  }

  settingClick(data) {
    this.mode = data;
    this.selectedRm = null;
    this.closeCard();
    if (data === 'users') {
      this.isLoadingUsers = true;
      this.adminService.getAllUsers().toPromise().then(data => {
        this.usersList = data;
        this.isLoadingUsers = false;
      });
    }
  }

  saveClients() {
    this.isLoadingCountry = true;
    const sel = this.filteredCountryList.filter(c => c.isSelected).map(c => c.tier2Id);
    this.message = '';
    this.isClientSaveLoad = true;
    this.clientModelObj = new ClientModel();
    this.clientModelObj.id = this.selectedClientId;
    this.clientModelObj.name = this.clientName;
    this.clientModelObj.countryIdList = sel;
    this.adminService.saveClient(this.clientModelObj).toPromise().then(data => {
      data = data.resp;
      if (data.isSuccess) {
        this.selectedClientId = 0;
        this.clientName = '';
        this.clientList = data.result;
        var cIds = [];
        this.filteredCountryList.forEach(c => {
          c.isSelected = cIds.indexOf(c.tier2Id) >= 0;
        });
        this.filteredCountryList = JSON.parse(JSON.stringify(this.filteredCountryList));
      }
      this.message = data.message;
      this.isClientSaveLoad = false;
      this.isLoadingCountry = false;
      this.toasterService.showMessage('Success', ToasterServiceModes.OK);
    });
  }

  saveCountry() {
    this.message = '';
    this.isCountrySaveLoad = true;
    this.countryModelObj = new CountryModel();
    this.countryModelObj.id = this.selectedCountryId;
    this.countryModelObj.name = this.countryName;
    this.countryModelObj.tableauProjectId = this.countryTableauProjectId;

    this.adminService.saveCountry(this.countryModelObj).toPromise().then(data => {
      data = data.resp;
      if (data.isSuccess) {
        this.selectedCountryId = 0;
        this.countryName = '';
        delete this.countryTableauProjectId;
        this.countryList = JSON.parse(JSON.stringify(data.result));
      }
      this.message = data.message;
      this.loadCountryList();
      this.isCountrySaveLoad = false;
      this.toasterService.showMessage('Success', ToasterServiceModes.OK);
    }).catch(ret => {
      console.log(ret);
      this.message = ret.error.resp ? ret.error.resp.message : 'Failed';
      this.isCountrySaveLoad = false;
    });
  }

  saveCountryClients() {
    this.isCountrySaveLoad = true;
    var sel = this.countryClientList.filter(c => c.isSelected).map(c => c.tier1Id);
    this.adminService.saveCountryClients(this.selectedCountryId, sel).toPromise().then(data => {
      this.isCountrySaveLoad = false;
      this.toasterService.showMessage('Success', ToasterServiceModes.OK);
    }).catch(ret => {
      console.log(ret);
      this.message = ret.error.resp ? ret.error.resp.message : 'Failed';
      this.isCountrySaveLoad = false;
    });
  }

  addNewCountry() {
    this.closeCard();
    this.countryName = '';
    this.clientName = '';
    this.isNewCountry = true;
    this.isNewClient = false;
    this.selectedClient = null;
    this.message = '';

  }

  removeRm(rm) {
    this.adminService.removeRegionalManager(rm).then((result: any) => {
      this.adminService.getRegionalManagers().toPromise().then(data => {
        this.rmList = data;
      });
    });
  }

  saveCountrySelection() {
    this.isLoadingCountry = true;
    const sel = this.filteredCountryList.filter(c => c.isSelected).map(c => c.tier2Id);
    if (this.mode == 'rm') {
      this.adminService.saveManagerCountries(this.selectedRm.id, sel).then((result: any) => {
        this.toasterService.showMessage('Success', ToasterServiceModes.OK);
        this.isLoadingCountry = false;
      });
    } else {
      this.adminService.saveClientCountries(this.selectedClient.tier1Id, sel).then((result: any) => {
        this.toasterService.showMessage('Success', ToasterServiceModes.OK);
        this.isLoadingCountry = false;
      });
    }
  }

  saveUserBulk() {
    this.bulkmessageList = [];
    this.bulkmessage = '';
    var uNames = this.bulkUserTextArea.split(',');
    var userList = [];
    uNames = uNames.filter(u => u && u.trim() != '');
    var isInvalid = false;
    uNames.forEach(u => {
      u = u.trim();
      if (!u || u.length == 0) return;

      var userObj = new UserModel();
      this.userModelObj.isExtad = this.profile.superadmin.users.isCreateExternalUserExtad;
      if (this.isAONEmail) {
        userObj.email = u;
        if (!this.userService.validateEmail(userObj.email)) {
          this.bulkmessage = 'Invalid email: ' + userObj.email;
          isInvalid = true;
          return;
        }
      } else {
        userObj.userName = u;
        if (userObj.userName.indexOf('@') >= 0) {
          isInvalid = true;
          this.bulkmessage = 'Invalid Aon Id: ' + userObj.userName;
          return;
        }
      }
      userList.push(userObj);
    });

    if (userList.length > 0 && !isInvalid) {
      this.userSaveLoading = true;
      this.adminService.saveUserBulk(userList).toPromise().then(data => {
        this.bulkmessage = data.message;
        this.bulkmessageList = data.result;
        this.userSaveLoading = false;
        if (data.isSuccess) {
          this.adminService.getUserList().toPromise().then((result: any) => {
            this.userList = result;
            this.bulkmessage = 'Users saved successfully.';
          });
          this.bulkUserTextArea = '';
          this.bulkmessage = '';
          this.toasterService.showMessage('Success', ToasterServiceModes.OK);
        }
      }).catch(err => {
        this.bulkmessage = 'Error while saving users.';
      }).finally(() => {
        this.userSaveLoading = false;
      });
    }
  }

  saveClientBulk() {
    this.bulkmessageList = [];
    this.bulkmessage = '';
    var cNames = this.bulkClientTextArea.split('\n');
    cNames = cNames.filter(u => u && u.trim() != '');
    if (cNames.length > 0) {
      this.isClientSaveLoad = true;
      this.adminService.saveClientBulk(cNames).toPromise().then(data => {
        console.log(data);
        this.bulkmessage = data.message;
        this.bulkmessageList = data.result;
        if (data.isSuccess) {
          this.adminService.getClients().toPromise().then((result: any) => {
            this.clientList = result;
            this.bulkmessage = 'Client saved successfully.';
          });
          this.bulkUserTextArea = '';
          this.bulkmessage = '';
          this.toasterService.showMessage('Success', ToasterServiceModes.OK);
        }
      }).catch(err => {
        this.bulkmessage = 'Error while saving clients.';
      }).finally(() => {
        this.isClientSaveLoad = false;
      });
    }
  }

  saveUser() {
    if (this.userModelObj.isExternal) {
        this.userModelObj.password = '';
    }

    if ((this.isAONEmail || this.userModelObj.isExternal && !this.userModelObj.isExtad)
      && !this.userService.validateEmail(this.userModelObj.email)) {
      this.message = 'Invalid email.';
      return;
    }

    this.userSaveLoading = true;
    this.message = '';

    this.adminService.saveUser(this.userModelObj).toPromise().then(data => {
      this.message = data.message;
      this.userSaveLoading = false;
      if (data.isSuccess) {
        this.adminService.getUserList().toPromise().then((result: any) => {
          this.userList = result;
          this.message = 'User saved successfully.';
        });
        this.userModelObj = new UserModel();
        this.userModelObj.isExtad = this.profile.superadmin.users.isCreateExternalUserExtad;
        this.toasterService.showMessage('Success', ToasterServiceModes.OK);
      }
    }).catch(err => {
      this.message = 'Error while saving user.';
    }).finally(() => {
      this.userSaveLoading = false;
    });
  }

  addUser(){
    this.userModelObj = new UserModel();
    this.userModelObj.isExtad = this.profile.superadmin.users.isCreateExternalUserExtad;
    this.isNewUser = true;
    this.message = '';
    this.isAONEmail = false;
    this.userSaveLoading = false;
     this.selectedUserRow = null;
      this.isEditUser = false;
      this.selectedUserRole = null;
  }

  resetUserCard() {
    this.userModelObj.userName = '';
    this.userModelObj.email = '';
    this.userModelObj.password = '';
  }

  loadCountryList() {
    this.isLoadingCountry = true;
    this.adminService.getCountries().toPromise().then(data => {
      this.countryList = JSON.parse(JSON.stringify(data));
      this.filteredCountryList = JSON.parse(JSON.stringify(data));
      this.loadEnabledCountryList();
      this.adminService.getTableauProjectsByT2Ids(this.countryList.map(c => c.tier2Id)).toPromise().then(result => {
        result.forEach(t => {
          const match = this.countryList.filter(c => c.tier2Id == t.tier2Id)[0];
          if (match) {
            match.tableauProjectId = t.tableauProjectId;
          }
        });
        this.isLoadingCountry = false;
      });
    });
  }

  loadEnabledCountryList() {
      this.adminService.getEnabledTier2List().toPromise().then(tier2IdList => {
        this.filteredCountryList = this.filteredCountryList.filter(c => tier2IdList.indexOf(c.tier2Id) >= 0);
      });
  }
  
  ngOnInit(){
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationSpanishCL,
      navigationSpanishCO,
      navigationSpanishEC,
      navigationSpanishMX,
      navigationSpanishPE,
      navigationPortugueseBR,
      navigationItalianIT,
    );

    this.adminService.getRegionalManagers().toPromise().then(data => {
      this.rmList = data;
    });

    this.countryClientColumnDefs = [... this.clientColumnDefs];
    this.countryClientColumnDefs.push({ 
        headerName: 'Selected', 
        field: 'isSelected'
    });

    this.loadCountryList();

    this.adminService.getClients().toPromise().then((result: any) => {
      this.clientList = result;
    }); 

    this.adminService.getUserList().toPromise().then((result: any) => {
      this.userList = result;
    });

    this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.rmColumnDefs = event.translations.SuperAdmin.rmColumnDefs;
      this.countryColumnDefs = event.translations.SuperAdmin.countryColumnDefs;
      this.countryViewColumnDefs = event.translations.SuperAdmin.countryColumnDefs;
      this.userColumnDefs = JSON.parse(JSON.stringify(event.translations.SuperAdmin.rmColumnDefs));
      this.userColumnDefs[2].valueFormatter = this.dateFormatter;
    });

    this.adminService.getTableauProjects().toPromise().then(result => {
      this.tableauProjects = result.filter(t => !t.isShared); // shared projects do not need to be assigned
      this.tableauProjects.unshift({
        name: '',
        tableauProjectId: null
      });
    })

    var currentTranslation = this._translateService.store.translations[this._translateService.currentLang];
    this.rmColumnDefs = currentTranslation.SuperAdmin.rmColumnDefs;
    
    this.countryColumnDefs = JSON.parse(JSON.stringify(currentTranslation.SuperAdmin.countryColumnDefs));
    this.countryViewColumnDefs = JSON.parse(JSON.stringify(currentTranslation.SuperAdmin.countryColumnDefs));
    this.userColumnDefs = JSON.parse(JSON.stringify(currentTranslation.SuperAdmin.rmColumnDefs));
    this.userColumnDefs[2].valueFormatter = this.dateFormatter;
    this.adminService.getUserRoleList().toPromise().then(data => {
      this.userRoleList = data;
    });
  }

  onUserSelectionChanged($evt){
    this.selectedUserRow = $evt.data;
      this.isEditUser = true;
      this.selectedUserRole = this.selectedUserRow.role;
      this.userModelObj = new UserModel();
      this.userModelObj.isExtad = this.profile.superadmin.users.isCreateExternalUserExtad;
     this.isNewUser = false;
     this.isTbsUserIdInputLoading = true;
     this.userService.getTbsUserId(this.selectedUserRow.id).toPromise()
        .then((result: string) => {
            this.tbsUserId = result; 
        }, (error: HttpErrorResponse) => {
            this.toasterService.showMessage(error.message, ToasterServiceModes.NOK);
        }).finally(() => {
            this.isTbsUserIdInputLoading = false;
        });
  }

    saveUserRole(){
        if (this.selectedUserRole === 'admin') {
            const dialogRef = this.dialog.open(DialogComponent, {
                data: { title: 'Confirm Promotion of user to Admin: ' + this.selectedUserRow.userName, message: 'Are you sure?' }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (typeof result === 'undefined') {
                    return;
                }
    
                if (result === false) {
                    return;
                }
    
                this.userRoleSaveLoading = true;
                this.message = '';
                this.adminService.saveUserRoleMapping({ 'UserId': this.selectedUserRow.id, 'Role': this.selectedUserRole }).toPromise().then(data => {
                    if (data === 'Success'){
                        this.message = 'Data saved succesfully';
                        this.adminService.getUserList().toPromise().then((userList: any) => {
                            this.userList = userList;       
                        });
                        this.selectedUserRow.role = this.selectedUserRole;
                        this.toasterService.showMessage('Success', ToasterServiceModes.OK);
                    }
                    else {
                        this.message = data.reason;
                    }
                    this.userRoleSaveLoading = false;
                }).catch(err => {
                    this.message = 'Error while saving user role.';
                }).finally(() => {
                    this.userRoleSaveLoading = false;
                });
            });
        } else {
            this.userRoleSaveLoading = true;
            this.message = '';
            this.adminService.saveUserRoleMapping({ 'UserId': this.selectedUserRow.id, 'Role': this.selectedUserRole }).toPromise().then(data => {
                if (data === 'Success'){
                    this.message = 'Data saved succesfully';
                    this.adminService.getUserList().toPromise().then((result: any) => {
                    this.userList = result;       
                    });
                    this.selectedUserRow.role = this.selectedUserRole;
                    this.toasterService.showMessage('Success', ToasterServiceModes.OK);
                }
                else {
                    this.message = data.reason;
                }
                this.userRoleSaveLoading = false;
            }).catch(err => {
                this.message = 'Error while saving user role.';
            }).finally(() => {
                this.userRoleSaveLoading = false;
            });
        }
    }

    deleteUser() {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: { title: 'Confirm Deletion of user: ' + this.selectedUserRow.userName, message: 'Are you sure? All mapping will be deleted as well.' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (typeof result === 'undefined') {
                return;
            }

            if (result === false) {
                return;
            }

            this.userDeleteLoading = true;

            this.adminService.deleteUser(this.selectedUserRow.id).toPromise().then(() => {
                this.toasterService.showMessage('User sucessfully deleted.');
                this.adminService.getUserList().toPromise().then((userList: any) => {
                    this.userList = userList;
                });
            }).catch(() => {
                this.toasterService.showMessage('Error deleting user', ToasterServiceModes.NOK);
            }).finally(() => {
                this.userDeleteLoading = false;
            });
        });
    }

  saveTbsUserId() {
    this.isTbsUserIdInputLoading = true;
    this.adminService.assignUserToTbsUserId(this.selectedUserRow.id, this.tbsUserId.trim()).toPromise().then(() => {
        this.toasterService.showMessage('TBS user id is successfully saved to this user');
    }).catch(() => {
        this.toasterService.showMessage('Error saving TBS user id to user', ToasterServiceModes.NOK);
    }).finally(() => {
        this.isTbsUserIdInputLoading = false;
    });
  }

  saveTbsClientId() {
      this.isTbsClientIdInputLoading = true;
      this.adminService.assignClientToTbsClientId(this.selectedClient.tier1Id, this.tbsClientId.trim()).toPromise().then(() => {
        this.toasterService.showMessage('TBS user id is successfully saved to this client');
      }).catch(() => {
          this.toasterService.showMessage('Error saving TBS client id to client');
      }).finally(() => {
        this.isTbsClientIdInputLoading = false;
      });
  }
}
