import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';
import { DashboardTemplateBase } from '../dashboard-template-base.component';
import { DataService } from 'app/dashboards/data.service';
import { locale as navigationEnglish } from 'app/dashboards/templates/executive-summary-row/i18n/en';
import { locale as navigationSpanishCL } from 'app/dashboards/templates/executive-summary-row/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/dashboards/templates/executive-summary-row/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/dashboards/templates/executive-summary-row/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/dashboards/templates/executive-summary-row/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/dashboards/templates/executive-summary-row/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/dashboards/templates/executive-summary-row/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/dashboards/templates/executive-summary-row/i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DimensionOrderService } from 'app/dashboards/dimension-order.service';
import { FilterOptions } from 'app/dashboards/shared/models/FilterOptions';
import { PulseEventName } from 'app/dashboards/shared/models/PulseEventName';
import { CLAIM_BASIS } from 'app/models/basis.model';

@Component({
  selector: 'app-executive-summary-row',
  templateUrl: './executive-summary-row.component.html',
  styleUrls: ['./executive-summary-row.component.css']
})
export class ExecutiveSummaryRowComponent implements OnInit, DashboardTemplateBase {

  @Output() action = new EventEmitter<any>();
  filterOptions: FilterOptions = new FilterOptions();

  isFilterOptionsLoading = true;

  imageUrlBase = '';
  data: any;
  properties: any;

  //topDateRange = '';
  //bottomDateRange = '';

  topDateFrom = '';
  topDateTo = '';
  bottomDateFrom = '';
  bottomDateTo = '';

  current = {};
  prior = {};
  planPerformance = new PlanPerformance();

  showFilter = false;
  overallImpact;
  averageCostImpact;
  utilizationImpact;
  mixOfServicesImpact;
  isLoading = false;
  LossCombined = LossCombined;
  pieRatioData = 0;
  CLAIM_BASIS = CLAIM_BASIS;

  Components = {
    AverageCost: 'AverageCostUtilization',
    PermemberCost: 'PerMemberCosts'
  }

  constructor(private dataService: DataService,
    private dimensionsService: DimensionOrderService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService) { }

  initData(): void {
    //this.topDateRange = this.data.topDateRange;
    //this.bottomDateRange = this.data.bottomDateRange;

    this.topDateFrom = this.data.topDateFrom;
    this.topDateTo = this.data.topDateTo;
    this.bottomDateFrom = this.data.bottomDateFrom;
    this.bottomDateTo = this.data.bottomDateTo;

    this.current = this.data.executiveSummaryCurrent;
    this.prior = this.data.executiveSummaryPrior;
    this.planPerformance = this.data.planPerformance;
    this.overallImpact = this.data.overallImpact;
    this.averageCostImpact = this.data.averageCostImpact;
    this.utilizationImpact = this.data.utilizationImpact;
    this.mixOfServicesImpact = this.data.mixOfServicesImpact;
    this.pieRatioData = this.properties.combinedLossRatio == LossCombined.CombinedLossRatio ?
      this.planPerformance.combinedLossRatio : this.planPerformance.lossRatio;
  }

  ngOnInit() {
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationSpanishCL, 
      navigationSpanishCO,
      navigationSpanishEC,
      navigationSpanishMX,
      navigationSpanishPE,
      navigationPortugueseBR,
      navigationItalianIT,
    );
  }

  format(num, dec) {
    if (!dec) dec = 0;
    return num ? num.toLocaleString(undefined, { maximumFractionDigits: dec }) : '';
  }

  selectionChanged($event, filterName: string) {
    this.action.emit({
      type: PulseEventName.FILTLER_CHANGED,
      data: {
        properties: {
          name: filterName,
          value: $event.data
        }
      }
    });
  }
  additionalFilterSelectionChanged(val) {
    this.action.emit(val);
  }
  navigateTo(template: string, name: string, id?: string) {
    this.action.emit({
      type: PulseEventName.NAVIGATE,
      data: {
        to: template,
        id: id,
        properties: {
          name: name
        }
      }
    });
  }
  filterChanged(val, filterName: string) {
    this.action.emit({
      type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
      data: {
        properties: {
          name: filterName,
          value: val
        }
      },
    });
    this.pieRatioData = this.properties.combinedLossRatio == LossCombined.CombinedLossRatio ?
      this.planPerformance.combinedLossRatio : this.planPerformance.lossRatio;

  }
}

export class PlanPerformance {
  public pmpm: number;
  public lossRatio: number;
  public combinedLossRatio: number;
  public headcount: number;
  public claims: number;
  public premium: number;
}
export enum LossCombined {
  LossRatio = "LossRatio",
  CombinedLossRatio = "CombinedLossRatio"
}