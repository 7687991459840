import { Component, OnInit, OnDestroy } from "@angular/core";
import { User } from "app/models/user.model";
import { FuseConfigService } from "@fuse/services/config.service";
import { Store, select } from "@ngrx/store";
import { Router } from "@angular/router";
import { navigation } from "app/navigation/navigation";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FwbExecutiveService } from 'app/fwb/services/fwb-executive.service';

@Component({
    selector: "demographics-score",
    templateUrl: "./demographics-score.component.html",
    styleUrls: ["./demographics-score.component.css"],
})
export class DemographicsScoreComponent implements OnInit {
    chartId = "demographics";
    height: any = "350px";
    width: any = "650px";
    cardheight: any = "590px";
    cardwidth: any = "97%";
    tooltip: boolean = true;
    titleposition = "left";
    charttitle = "Demographics";
    yaxistitle = "";
    color = "white";
    maxyaxisnumber: number = 1000;
    dataAgeBand: any;
    dataGender: any;
    fuseConfig: any;
    navigation: any;

    legendsubtitle: string = "Total Employees";
    legendTrim: number = 0;
    legendtitle = "";
    legendPosition = "left";
    middleText: any = "1012";
    showLegend: boolean = true;
    isAgeBandSelected: boolean = true;
    serviceData: any ;
    public user: User;
    isLoading = true;
    isError = false;
    errorMsg = "No Data Found.";
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _executiveService: FwbExecutiveService,
        private _router: Router
    ) {
        // Set the defaults
        this.navigation = navigation;
    }
 
    ngOnInit() { 
        this.getUser();
        this.getData();
        this.setData();
      
    }

    toggleHorizontalTab() {
        this.isAgeBandSelected = !this.isAgeBandSelected;
    }

    getData() {
        this.serviceData = null;
        this._executiveService.getDemographics("").toPromise().then((resp) => {
            this.serviceData = resp;
            console.log(this.serviceData);
            this.setData();
            this.errorMsg = "";
            this.isError = false;
        }).catch(error => {
            this.isError = true;
            this.errorMsg = error.message;
        }).finally(() => {
            this.isLoading = false;
        })
    }
 
    setData() {
        this.dataAgeBand = Object(this.getAgebandData());
        this.dataGender = Object(this.getGenderData());
    }

    getAgebandData() {
        return this.serviceData.resultAgeBand;
    }
    getGenderData() {
        return this.serviceData.resultGender; 
    }
 
    getUser() {
    }
    
    goToLearnMore() {
        this._router.navigate(["/fwb/demographics"]);
    }
}
