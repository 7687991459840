import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PensionService {

  constructor(private httpClient: HttpClient) { }

  getScoreSummary(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/pensionscore/summary?'+params)
  }

  getScoreCategories(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/pensionscore/categories?'+params)
  }

  getScoreQuestions(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/pensionscore/questions?'+params)
  }

 
}
