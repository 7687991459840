<aon-top-navbar
    [appname]="appname"
    [titletext]="clientName"
    [appnamewidth]="appNameWidth"
    [titlewidth]="titleWidth"
>
    <!-- Export -->
    <aon-navbar-link>
        <app-export-button class="topnavbar__custom-btn"></app-export-button>
    </aon-navbar-link>
    <aon-navbar-menu-divider width="10px"></aon-navbar-menu-divider>

    <!-- Select Client -->
    <aon-navbar-link>
        <app-select-client-button class="topnavbar__custom-btn"></app-select-client-button>
    </aon-navbar-link>
    <aon-navbar-menu-divider width="10px"></aon-navbar-menu-divider>

    <aon-navbar-menu
        *ngIf="!appCategory.isFWB && ( userRole.Admin || userRole.RegionalManager || userRole.CountryManager || userRole.Normal)"
        (onmenuchange)="storyChange($event)"
    >
        <aon-navbar-menu-label>
            <fa-icon [icon]="['fas', 'book']"></fa-icon>
        </aon-navbar-menu-label>

        <aon-navbar-menu-items
            height="600px"
            search="true"
            searchplaceholdertext="Search Story"
        >
            <aon-navbar-menu-item
                *ngFor="let story of storyList"
                [value]="story"
            >
                <aon-navbar-menu-item-label>
                    {{ story.name || story.title}}
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>
        </aon-navbar-menu-items>
    </aon-navbar-menu>

    <aon-navbar-menu
        (onmenuchange)="languageChange($event)"
        *ngIf="!appCategory.isFWB"
    >
        <aon-navbar-menu-label>
            {{selectedLanguage.id | uppercase }}
            <fa-icon
                style="padding-left:5px;"
                [icon]="['fal', 'globe-americas']"
            ></fa-icon>

        </aon-navbar-menu-label>
        <aon-navbar-menu-items height="600px">
            <aon-navbar-menu-item
                *ngFor="let lang of languages"
                [value]="lang"
            >
                <aon-navbar-menu-item-label>
                    <div style="display: flex; flex-direction:row;">
                        <div style="margin-right:10px;">
                            <img
                                class="flag"
                                style="width: 40px"
                                [src]="'assets/icons/flags/'+lang.flag+'.png'"
                            >
                        </div>
                        <div style="margin-top:5px;">{{lang.title}}</div>
                    </div>
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>
        </aon-navbar-menu-items>
    </aon-navbar-menu>

    <aon-navbar-menu-divider width=".5%"></aon-navbar-menu-divider>
    <aon-navbar-menu
        (onmenuchange)="dashboardChange($event)"
        *ngIf="!appCategory.isFWB"
    >
        <aon-navbar-menu-label>
            <fa-icon [icon]="['fal', 'user']"></fa-icon>

        </aon-navbar-menu-label>
        <aon-navbar-menu-items height="620px">
            <aon-navbar-menu-item value="0">
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right:5px;"
                        [icon]="['fas', 'user-circle']"
                    ></fa-icon>
                    {{ user?.displayName}}
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>
            <aon-horizontal-divider></aon-horizontal-divider>
            <ng-container *ngIf="isCostProjectionAllowed">
                <aon-navbar-menu-item value="1">
                    <aon-navbar-menu-item-label>
                        <fa-icon
                            style="padding-right:5px;"
                            [icon]="['fal', 'calculator']"
                        ></fa-icon>
                        Cost Projection
                    </aon-navbar-menu-item-label>
                </aon-navbar-menu-item>
                <aon-horizontal-divider></aon-horizontal-divider>
            </ng-container>
            <aon-navbar-menu-item value="2">
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right:5px;"
                        [icon]="['fal', 'building']"
                    ></fa-icon>
                    Select Client
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>

            <aon-navbar-menu-item value="3">
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right:5px;"
                        [icon]="['fal', 'sign-out-alt']"
                    ></fa-icon>
                    {{ 'Toolbar.Logout' | translate }}
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>
            <aon-horizontal-divider></aon-horizontal-divider>

            <aon-navbar-menu-item value="11">
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right:5px;"
                        [icon]="['fal', 'shopping-cart']"
                    ></fa-icon>
                    {{ 'Nav.Export.Cart' | translate }}
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>
            <aon-navbar-menu-item value="12">
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right:5px;"
                        [icon]="['fal', 'list']"
                    ></fa-icon>
                    {{ 'Nav.Export.Queue' | translate }}
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>
            <aon-horizontal-divider></aon-horizontal-divider>

            <aon-navbar-menu-item
                value="4"
                *ngIf="userRole.Admin || userRole.RegionalManager || userRole.CountryManager"
            >
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right:5px;"
                        [icon]="['fal', 'layer-group']"
                    ></fa-icon>
                    {{ 'Nav.Admin.StoryBoard' | translate }}
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>
            <aon-navbar-menu-item
                value="5"
                *ngIf="userRole.Admin || userRole.RegionalManager || userRole.CountryManager"
            >
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right:5px;"
                        [icon]="['fal', 'layer-group']"
                    ></fa-icon>
                    {{ 'Nav.Admin.Stories' | translate }}
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>
            <aon-navbar-menu-item
                value="6"
                *ngIf="userRole.Admin || userRole.RegionalManager || userRole.CountryManager"
            >
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right:5px;"
                        [icon]="['fal', 'user-friends']"
                    ></fa-icon>
                    {{ 'Nav.Admin.Users' | translate }}
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>

            <aon-navbar-menu-item
                value="7"
                *ngIf="userRole.Admin || userRole.RegionalManager || userRole.CountryManager"
            >
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right:5px;"
                        [icon]="['fal', 'address-book']"
                    ></fa-icon>
                    {{ 'Nav.Admin.ClientGroup' | translate }}
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>

            <aon-navbar-menu-item
                value="8"
                *ngIf="userRole.Admin || userRole.RegionalManager"
            >
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right:5px;"
                        [icon]="['fal', 'book-reader']"
                    ></fa-icon>
                    {{ 'Nav.Admin.Library' | translate }}
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>

            <aon-navbar-menu-item
                value="9"
                *ngIf="userRole.Admin || userRole.RegionalManager || userRole.CountryManager"
            >
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right:5px;"
                        [icon]="['fal', 'calculator']"
                    ></fa-icon>
                    Manage Cost Projection
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>

            <aon-navbar-menu-item
                value="10"
                *ngIf="userRole.Admin || userRole.RegionalManager"
            >
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right:5px;"
                        [icon]="['fal', 'lock-alt']"
                    ></fa-icon>
                    {{ 'Nav.Admin.Settings' | translate }}
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>
        </aon-navbar-menu-items>
    </aon-navbar-menu>

    <aon-navbar-menu
        (onmenuchange)="dashboardChange($event)"
        *ngIf="appCategory.isFWB"
    >
        <aon-navbar-menu-label>
            <fa-icon [icon]="['fal', 'user']"></fa-icon>
        </aon-navbar-menu-label>
        <aon-navbar-menu-items height="600px">
            <aon-navbar-menu-item value="0">
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right: 5px;"
                        [icon]="['fas', 'user-circle']"
                    ></fa-icon>
                    {{ user?.displayName }}
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>
            <aon-horizontal-divider></aon-horizontal-divider>
            <aon-navbar-menu-item value="2">
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right: 5px;"
                        [icon]="['fal', 'building']"
                    ></fa-icon>
                    Select Options
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>

            <aon-navbar-menu-item value="3">
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right: 5px;"
                        [icon]="['fal', 'sign-out-alt']"
                    ></fa-icon>
                    {{ "Toolbar.Logout" | translate }}
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>
            <aon-horizontal-divider></aon-horizontal-divider>
            <aon-navbar-menu-item
                value="6"
                *ngIf="
                    userRole.Admin ||
                    userRole.RegionalManager ||
                    userRole.CountryManager
                "
            >
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right: 5px;"
                        [icon]="['fal', 'user-friends']"
                    ></fa-icon>
                    {{ "Nav.Admin.Users" | translate }}
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>

            <aon-navbar-menu-item
                value="9"
                *ngIf="userRole.Admin || userRole.RegionalManager"
            >
                <aon-navbar-menu-item-label>
                    <fa-icon
                        style="padding-right: 5px;"
                        [icon]="['fal', 'lock-alt']"
                    ></fa-icon>
                    {{ "Nav.Admin.Settings" | translate }}
                </aon-navbar-menu-item-label>
            </aon-navbar-menu-item>
        </aon-navbar-menu-items>
    </aon-navbar-menu>
</aon-top-navbar>