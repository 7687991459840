import {
  Component, OnInit, ViewEncapsulation, Input, OnDestroy, OnChanges, SimpleChanges,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { User } from 'app/models/user.model';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { navigation } from 'app/navigation/navigation';

@Component({
  selector: 'app-fwbscore',
  templateUrl: './fwbscore.component.html',
  styleUrls: ['./fwbscore.component.css']
})
export class FWBScoreComponent
  implements OnInit, OnChanges, OnDestroy {
  isChartVisible: boolean = true;
  @Input() navTabIndex = 3;
  @Input() title: string = '';
  dataByPlanYear: any[];
  demographicsData: any[] = [];
  public user: User;
  toLoadDashboard: boolean;
  queryParam: {
    clientId: any;
    clientName: any;
    planyear: any;
    userName: any;
    country: any;
    country_key: any;
    questionGroupId: any;
    scoreTemplateType: string;
  };
  // navTabIndex: any;
  legendtitleGender = ['Male', 'Female', 'Grand Total'];
  legendtitleAgeBand = [
    '20-29',
    '30-39',
    '40-49',
    '50-59',
    '60-69',
    '70-79',
    '80-89',
  ];
  view: any[] = [];
  view2: any[] = [];
  pensionPlanYearstitle: string = '';
  public filterValues: any = {
    location: '',
    businessUnit: '',
    activeRetireeFilter: '',
  };
  rawDemographicsData: any;
  dataLoaded: boolean;
  ageBandData: any[];
  ageDataByGender: any[];
  membershipData: any;
  locationData: any;
  countryData: any;
  businessUnitData: any;
  locationCategories: any;
  businessCategories: any;
  membershipCategories: any;
  options = [
    { key: true, name: 'Age Band' },
    { key: false, name: 'Gender' },
  ];
  selectedOption = true;
  isChartDemographicsVisible: boolean = false;
  fuseConfig: any;
  navigation: any;
  genders = ['Male', 'Female', 'Grand_Total'];
  ageBands = [
    'ageBand_20_29',
    'ageBand_30_39',
    'ageBand_40_49',
    'ageBand_50_59',
    'ageBand_60_69',
    'ageBand_70_79',
    'ageBand_80_89',
  ];
  chartId = 'demographicsByAgeBands';
  height: any = '500px';
  width: any = '1450px';
  cardheight: any = '600px';
  cardwidth: any = '98%';
  tooltip: boolean = true;
  titleposition = 'left';
  emptyChartTitle: string = '';
  charttitle = 'Demographics';
  yaxistitle = '';
  color = 'white';
  maxyaxisnumber: number = 1000;
  dataAgeBand: any;
  dataGender: any;

  maxTotalAgeBand: number = 0;
  maxTotalGender: number = 0;
  // Private
  private _unsubscribeAll: Subject<any>;
  selectedOptionName: string = 'Age Band';
  demographicDataStackedByAgeBand: any[];
  demographicDataStackedByGender: any[];
  businessDataBarChartData: any;
  locationDataBarChartData: {
    icon: string;
    name: string;
    value: number;
    colorindex: number;
  }[];
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _router: Router
  ) {
    // Set the defaults
    this.navigation = navigation;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._unsubscribeAll.unsubscribe();
  }
  ngOnInit() {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        var newConfig = config;
        newConfig.layout.style = 'aon-new-layout';
        newConfig.layout.navbar.hidden = false;
        newConfig.layout.footer.hidden = false;
        newConfig.layout.toolbar.hidden = false;
        newConfig.layout.sidepanel.hidden = false;
        this.fuseConfig = newConfig;
      });

    this.getUser();
    this.isChartDemographicsVisible = true;
  }

  onOptionChange($event) {
    this.selectedOptionName = $event.detail;
  }


  getUser() {
    // this.store.pipe(select(getUser)).subscribe(
    //     (userGet) => {
    //         this.user = userGet;
    //         this.dashboardService.user = userGet;
    //     },
    //     (error) => console.log('Error: ' + error)
    // );
  }

}
