export const locale = {
    lang: 'pt-br',
    data: {
        'ExecutiveSummary': {           
		'ExecutiveSummary': 'Sumário Executivo', 
		'AllAmountsDisplayedInLocalCurrency': 'Todos os valores estão em moeda local', 
		'GeneralTrend': 'Tendência Geral', 
		'Rolling12Basis': 'Base dos Últimos 12 meses', 
		'CurrentPMPM': 'PMPM Atual', 
		'PriorPMPM': 'PMPM Anterior', 
		'OverallTrend': 'Tendência Geral', 
		'AverageCost': 'Custo Médio', 
		'Utilization': 'Frequência', 
		'ServicesReceived': 'Serviços Recebidos', 
		'PlanPerformance': 'Performance por Plano', 
		'YearToDateBasis': 'Base do Ano-até-Hoje', 
		'YearToDateCBasis': 'Base (C) do Ano-até-Hoje', 
		'ClaimsReportedByPaidDate': 'Sinistro Reportado por Data de Pagamento', 
		'ClaimsReportedByIncurredDate': 'Sinistro Reportado por Data de Atendimento', 
		'LossRatio': 'Sinistralidade', 
		'Headcount': 'HEADCOUNT', 
		'Claims': 'SINISTRO', 
		'Premium': 'PRÊMIO', 
		'CombinedLossRatio': 'Sinistralidade Combinada',
		'DateRange': 'to'
        }
    }
};
