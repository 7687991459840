import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/_reducers';
import * as UserActions from './../_actions/user.actions';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { Router } from '@angular/router';
import { Roles as _roles } from 'app/models/roles.model';
import { User } from 'app/models/user.model';
import { StoryService } from './story.service';
import { CLAIM_BASIS } from 'app/models/basis.model';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: any;
  @Output() userChanged: EventEmitter<User> = new EventEmitter();

  constructor(
    private _fuseNavigationService: FuseNavigationService,
    private httpClient: HttpClient,
    private store: Store<State>,
    private _router: Router,
    private storyService: StoryService) {

    this.userChanged = new EventEmitter();
    const u = localStorage.getItem('user');
    if (typeof u === 'undefined') {
      this.logout();
    }
    if (u !== 'undefined') {
      const user = JSON.parse(u);
      this.setUserStore(user);
    }
  }

  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  };

  isUserLoggedIn() {
    let u = localStorage.getItem('user');

    if (typeof u === 'undefined') {
      return false;
    }
    else if (u === null) {
      return false;
    }
    else if (u === 'null') {
      return false;
    }
    else {
      return true;
    }
  }

  isAdminOrRegionalManagerRole() {
    var role = this.getUserRole();
    return role == _roles.admin || role == _roles.regionalManager;
  }

  isNormalUser() {
    return this.getUserRole() == _roles.normal;
  }

  isCountryManager() {
    return this.getUserRole() == _roles.countryManager;
  }

  isRegionalManager() {
    return this.getUserRole() == _roles.regionalManager;
  }

  isAdmin() {
    return this.getUserRole() == _roles.admin;
  }

  setUserAfterLogin(): void {
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  getCurrentUser() {
    if (!this.user || !this.user.token) return null;
    return this.user;
  }

  getUserRole() {
    if (!this.user || !this.user.token) return null;
    var tp = this.parseJwt(this.user.token);
    var role = tp['role'];
    return role;
  }

  private setUserStore(user) {
    this.user = user;
    this.store.dispatch(new UserActions.LoginUser(user));
    localStorage.setItem('user', JSON.stringify(this.user));
    setTimeout(() => this.userChanged.emit(user), 100);
  }

  public setUserSitePermissions() {
    if (this.isAdmin()) {
      this._fuseNavigationService.setCurrentNavigation('adminnavigation');
    }
    else if (this.isRegionalManager()) {
      this._fuseNavigationService.setCurrentNavigation('rmnavigation');
    }
    else if (this.isCountryManager()) {
      this._fuseNavigationService.setCurrentNavigation('cmnavigation');
    }
    else {
      this._fuseNavigationService.setCurrentNavigation('usernavigation');
    }
  }

  public updateLanguage(language) {
    this.store.dispatch(new UserActions.SetUserLanguage(language));
    this.store.select('user').subscribe(res => {
      console.log("Set Client Country and Client");
      console.log(res);
      localStorage.setItem('user', JSON.stringify(res));
    });
  }

  public updateClientCountry(client, country) {
    this.store.dispatch(new UserActions.SetUserClient(client));
    this.store.dispatch(new UserActions.SetUserCountry(country));
    this.store.select('user').subscribe(res => {
      localStorage.setItem('user', JSON.stringify(res));
    });
  }

  public updateTimePeriod(timeperiod) {
    this.store.dispatch(new UserActions.SetUserTimePeriod(timeperiod));
    this.store.select('user').subscribe(res => {
      localStorage.setItem('user', JSON.stringify(res));
    });
  }

  public updateRollingPeriod(rollingPeriodSelection: number) {
    this.store.dispatch(new UserActions.SetUserRollingPeriod(rollingPeriodSelection));
    this.store.select('user').subscribe(res => {
      localStorage.setItem('user', JSON.stringify(res));
    });
  }


  public updatePeriodSelection(periodSelection: number) {
    this.store.dispatch(new UserActions.SetUserPeriodSelection(periodSelection));
    this.store.select('user').subscribe(res => {
      localStorage.setItem('user', JSON.stringify(res));
    });
  }

  public updateClaimPaid(claimPaidSelection: CLAIM_BASIS) {
    this.store.dispatch(new UserActions.SetClaimPaid(claimPaidSelection));
    this.store.select('user').subscribe(res => {
      localStorage.setItem('user', JSON.stringify(res));
    });
  }

  public updatePlanYear(selectedPlanYear: number) {
    this.store.dispatch(new UserActions.SetPlanYear(selectedPlanYear));
    this.store.select('user').subscribe(res => {
      localStorage.setItem('user', JSON.stringify(res));
    });
  }
  public loginAD(userName: string, password: string) {
    this.clearStoreUser();
    let promise = new Promise((resolve, reject) => {
      this.httpClient.post(environment.api + 'api/access/ad', { username: userName, password: password })
        .toPromise().then((res: any) => {
          let data = JSON.parse(JSON.stringify(res));
          if (data && data.token) {
            this.setUserStore({ 
              userId: data.userId, 
              displayName: data.displayName, 
              userName: data.userName, 
              email: data.email, 
              token: data.token, 
              tbsUserId: data.tbsUserId, 
              isCostProjectionAllowed: data.isCostProjectionAllowed,
              language: 'en' });
            resolve(data);
          } else {
            reject('Authentication failed.');
          }
        }).catch(ex => {
          if (ex.status === 401) {
            const e = JSON.parse(JSON.stringify(ex.error));
            reject(e.errorMsg);
          }
          else if (ex.status === 500) {
            reject('Something went wrong with the server. Please contact administrator.');
          }
          else {
            reject(ex.statusText + ' (' + ex.status + ') - ' + ex.message);
          }
        });

    });
    return promise;
  }

  public loginExternal(email: string, password: string) {
    this.clearStoreUser();
    let promise = new Promise((resolve, reject) => {
      this.httpClient.post(environment.api + 'api/access/external', { email: email, password: password })
        .toPromise().then((res: any) => {
          let data = JSON.parse(JSON.stringify(res));
          if (data && data.token) {
            this.setUserStore({
               userId: data.userId, 
               displayName: data.displayName, 
               userName: data.userName, 
               email: data.email, 
               token: data.token, 
               tbsUserId: data.tbsUserId, 
               isCostProjectionAllowed: data.isCostProjectionAllowed,
               language: 'en' });
            resolve(data);
          } else {
            reject('Authentication failed.');
          }
        }).catch(ex => {
          if (ex.status === 401) {
            const e = JSON.parse(JSON.stringify(ex.error));
            reject(e.errorMsg);
          }
          else if (ex.status === 500) {
            reject('Something went wrong with the server. Please contact administrator.');
          }
          else {
            reject(ex.statusText + ' (' + ex.status + ') - ' + ex.message);
          }
        });
    });

    return promise;
  }

  public loginImpersonate(userName: string) {
    //this.clearStoreUser();
    const httpOptions = {
      headers: new HttpHeaders()
    };

    const currentUser = this.user;
    if (currentUser && currentUser.token) {
      httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + currentUser.token);
    }

    let promise = new Promise((resolve, reject) => {
      this.httpClient.post(environment.api + 'api/access/impersonate', { username: userName }, httpOptions)
        .toPromise().then((res: any) => {
          let data = JSON.parse(JSON.stringify(res));
          if (data && data.token) {
            this.clearStoreUser();
            this.setUserStore({ 
              userId: data.userId, 
              displayName: data.displayName, 
              userName: data.userName, 
              email: data.email, 
              token: data.token, 
              tbsUserId: data.tbsUserId, 
              isCostProjectionAllowed: data.isCostProjectionAllowed,
              language: 'en' });
            resolve(data);
          } else {
            reject('Authentication failed.');
          }
        }).catch(ex => {
          if (ex.status === 401) {
            const e = JSON.parse(JSON.stringify(ex.error));
            reject(e.errorMsg);
          }
          else if (ex.status === 500) {
            reject('Something went wrong with the server. Please contact administrator.');
          }
          else {
            reject(ex.statusText + ' (' + ex.status + ') - ' + ex.message);
          }
        });

    });
    return promise;
  }

  public loginImpersonateExtad(userName: string) {
    const httpOptions = {
      headers: new HttpHeaders()
    };

    const currentUser = this.user;
    if (currentUser && currentUser.token) {
      httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + currentUser.token);
    }

    let promise = new Promise((resolve, reject) => {
      this.httpClient.post(environment.api + 'api/access/impersonate-extad', { username: userName }, httpOptions)
        .toPromise().then((res: any) => {
          let data = JSON.parse(JSON.stringify(res));
          if (data && data.token) {
            this.clearStoreUser();
            this.setUserStore({ 
              userId: data.userId, 
              displayName: data.displayName, 
              userName: data.userName, 
              email: data.email, 
              token: data.token, 
              tbsUserId: data.tbsUserId, 
              isCostProjectionAllowed: data.isCostProjectionAllowed,
              language: 'en' });
            resolve(data);
          } else {
            reject('Authentication failed.');
          }
        }).catch(ex => {
          if (ex.status === 401) {
            const e = JSON.parse(JSON.stringify(ex.error));
            reject(e.errorMsg);
          }
          else if (ex.status === 500) {
            reject('Something went wrong with the server. Please contact administrator.');
          }
          else {
            reject(ex.statusText + ' (' + ex.status + ') - ' + ex.message);
          }
        });

    });
    return promise;
  }

  public signupExternal(email: string, userName: string, password: string) {
    let promise = new Promise<void>((resolve, reject) => {
      this.httpClient.post(environment.api + 'api/access/signup', { email: email, username: userName, password: password })
        .toPromise().then((res: any) => {
          let data = JSON.parse(JSON.stringify(res));
          resolve();
        }).catch(error => {
          let e = JSON.parse(JSON.stringify(error));
          reject(e.reason);
        });

    });
    return promise;
  }

  public logout() {
    const httpOptions = {
      headers: new HttpHeaders()
    };

    const currentUser = this.user;
    if (currentUser && currentUser.token) {
      httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + currentUser.token);
    }

    this.httpClient.post(environment.api + 'api/access/logout', null, httpOptions).toPromise().then(() => {
      this.storyService.clearSessionStoryInfo();
      this.clearStoreUser();
      window.location.href = '/';
    });
  }

  private clearStoreUser() {

    //this.setUserStore(null);
    localStorage.removeItem('user');
    localStorage.removeItem('story');
    localStorage.removeItem('cart');
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  getUserCountries() {
    return this.httpClient.get<any>(environment.api + 'api/admin/t2');
  }

  getUserCountriesByClientId(clientId) {
    return this.httpClient.get<any>(environment.api + 'api/admin/t2/' + clientId);
  }

  getUserClients() {
    return this.httpClient.get<any>(environment.api + 'api/admin/t1');
  }

  addUser(userName) {
    return this.httpClient.post<any>(environment.api + 'api/access/add', { userName: userName });
  }

  getTbsUserId(userId: string) {
    return this.httpClient.post<any>(environment.api + 'api/admin/user/' + userId + '/getTbsUserId', null);
  }

  getTbsClientId(tier1Id: number) {
    return this.httpClient.post<any>(environment.api + 'api/admin/tier1Id/' + tier1Id + '/getTbsClientId', null);
  }

  getUserSites(userId: string) {
    return this.httpClient.post<any>(environment.api + 'api/user/site/list?userId=' + userId, null);
  }

  getClientTimePeriod(clientId: number, client: string, isClientGroup: boolean, country: string) {
    const data = {
      clientId,
      client,
      isClientGroup,
      country,
    }

    return this.httpClient.post<any>(environment.api + 'api/data/TimePeriodModel', data);
  }

  resetPassword(email: string, password: string, token: string) {
    return this.httpClient.put<any>(environment.api + `api/access/password/reset?email=${email}&token=${encodeURIComponent(token)}`,
      { currentPassword: '', newPassword: password });
  }

  forgetPassword(email: string) {
    return this.httpClient.post<any>(environment.api + `api/access/password/forget?email=${email}`, null);
  }

  changePassword(currentPassword: string, newPassword: string) {
    return this.httpClient.put<any>(environment.api + `api/access/password/change`,
      { currentPassword: currentPassword, newPassword: newPassword });
  }
}
