import { Action } from '@ngrx/store'
import { User } from '../models/user.model'
import * as UserActions from '../_actions/user.actions'

// // Section 1
// const initialState: User = {
//
// }

// Section 2
export function userReducer(state: User, action: UserActions.Actions) {

    // Section 3
    switch (action.type) {
        case UserActions.LOGIN_USER:
            state = { ...state };
            return state = action.payload;
        case UserActions.LOGOUT_USER:
            return state = null;
        case UserActions.SET_USER_CLIENT:
            state.client = action.payload;
            return state;
        case UserActions.SET_USER_COUNTRY:
            state.country = action.payload;
            return state;
        case UserActions.SET_USER_LANGUAGE:
            state.language = action.payload;
            return state;
        case UserActions.SET_USER_ROLLING_PERIOD:
            state.rollingPeriod = action.payload;
            return state;
        case UserActions.SET_USER_PERIOD_SELECTION:
            state.periodSelection = action.payload;
            return state;
        
        case UserActions.SET_USER_TIME_PERIOD:
            state.timePeriod = action.payload;
            return state;
        case UserActions.SET_CLAIM_PAID:
            state.claimPaid = action.payload;
            return state;
        case UserActions.SET_PLAN_YEAR:
            state.planYear = action.payload;
            return state;
        default:
            return state;
    }
}