export const locale = {
    lang: 'es-cl',
    data: {
        'HealthDiseaseProfile': {       
            'Top Diseases': 'Principales Enfermedades',     
            'HealthProfile': 'Perfil de salud',
            'TopChronicDiseases': 'Principales Enfermedades Crónicas', 
            'TopInfectiousDiseases': 'Top Condiciones Infecciosas',        
            'TopOtherDiseases': 'Principales Enfermedades (Otras)',     
            'PaidClaim': 'Monto Reembolsado',
            'AverageCost': 'Costo Promedio',
            'ClaimantCount': 'Cantidad de Reclamantes', 
            'Utilization':'Utilización',
            'Chronic': 'Crónico', 
            'Infectious': 'Infeccioso',
            'Other': 'Otro',
            'Current': 'Actual',
            'Prior': 'Anterior',
            'ClaimsReportedByPaidDate': 'Reclamaciones reportadas por fecha de pago',
            'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
            'DateRange': 'to',
            'DiagnosisGroup': 'Grupo de diagnóstico',
            'Actionable': 'Accionables',
            'Cancer': 'Cáncer',
            'Mental health': 'Salud Mental',
            'Trauma/accidents': 'Accidentes /Trauma',
            'Obstetrics': 'Obstétricos',
            'Others': 'Otros',
            'Preventable': 'Prevenible',
            'Intervenable': 'Intervenible',
            'CategoryOption': 'Category',
            'Category': 'Category',
            'Chapter': 'Chapter',
            'Sort' : 'Ordenar'
        }
    }
};