<div
    id="navBarVertStyle-1"
    class="navbar-top"
    [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
>

    <div class="logo">
        <img
            id="navBarLogo"
            width="60"
            src="assets/images/logos/logo.svg"
        >

    </div>

    <div class="buttons">

        <button
            id="navBarBtnmenu"
            mat-icon-button
            class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()"
            fxHide.lt-lg
        >
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button
            id="navBarBtnArrowack"
            mat-icon-button
            class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()"
            fxHide.gt-md
        >
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div
    id="navBarScroll"
    class="navbar-scroll-container"
    [ngClass]="fuseConfig.layout.navbar.primaryBackground"
    fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{suppressScrollX: true}"
>

    <div
        class="user"
        fxLayout="column"
        [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
    >

        <div
            id="navBarDisplayName"
            class="h3 username"
        >{{user?.displayName}}</div>
        <div
            id="navBarEmail"
            class="h5 email hint-text mt-8"
        >{{user?.email}}</div>
        <!-- <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <img class="avatar" src="assets/images/avatars/Velazquez.jpg">
        </div> -->

    </div>

    <div
        id="navBarFuse"
        class="navbar-content"
    >
        <fuse-navigation
            id="navBarFuseNav"
            class="material2"
            layout="vertical"
        ></fuse-navigation>
    </div>

</div>