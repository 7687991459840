<!-- SIDE PANEL -->
<ng-container
    id="VL1SidePanelLeft"
    *ngIf="
        !fuseConfig.layout.sidepanel.hidden &&
        fuseConfig.layout.sidepanel.position === 'left'
    "
>
    <!-- PANEL CONTENT -->
</ng-container>
<!-- / SIDE PANEL -->

<div id="main">
    <!-- TOOLBAR: Above -->
    <ng-container
        id="VL1TBAbove"
        *ngIf="fuseConfig.layout.toolbar.position === 'above'"
    >
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Above -->

    <div id="container-1" class="container-full-width">
        <!-- NAVBAR: Left -->
        <ng-container
            id="VL1TBLeft"
            *ngIf="fuseConfig.layout.navbar.position === 'left'"
        >
            <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR: Left -->

        <div id="container-2" class="container-full-width">
            <!-- TOOLBAR: Below fixed -->
            <ng-container
                *ngIf="fuseConfig.layout.toolbar.position === 'below-fixed'"
            >
                <ng-container *ngTemplateOutlet="toolbar"></ng-container>
            </ng-container>
            <!-- / TOOLBAR: Below fixed -->

            <div
                id="container-3"
                class="container-full-width"
                fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{
                    suppressScrollX: true,
                    updateOnRouteChange: true
                }"
            >
                <!-- TOOLBAR: Below static -->
                <ng-container
                    id="VL1TBStatic"
                    *ngIf="
                        fuseConfig.layout.toolbar.position === 'below-static'
                    "
                >
                    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
                </ng-container>
                <!-- / TOOLBAR: Below static -->

                <!-- CONTENT -->
                <content id="VL1Content"></content>
                <!-- / CONTENT -->

                <!-- FOOTER: Below static -->
                <ng-container
                    id="VL1FooterStatic"
                    *ngIf="fuseConfig.layout.footer.position === 'below-static'"
                >
                    <ng-container *ngTemplateOutlet="footer"></ng-container>
                </ng-container>
                <!-- / FOOTER: Below static -->
            </div>

            <!-- FOOTER: Below fixed -->
            <ng-container
                id="VL1FotterBelow"
                *ngIf="fuseConfig.layout.footer.position === 'below-fixed'"
            >
                <ng-container *ngTemplateOutlet="footer"></ng-container>
            </ng-container>
            <!-- / FOOTER: Below fixed -->
        </div>

        <!-- NAVBAR: Right -->
        <ng-container
            id="VL1RightNB"
            *ngIf="fuseConfig.layout.navbar.position === 'right'"
        >
            <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR: Right -->
    </div>

    <!-- FOOTER: Above -->
    <ng-container
        id="VL1FooterAbove"
        *ngIf="fuseConfig.layout.footer.position === 'above'"
    >
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-container>
    <!-- FOOTER: Above -->
</div>

<!-- SIDE PANEL -->
<ng-container
    id="VL1SidePAnelRight"
    *ngIf="
        !fuseConfig.layout.sidepanel.hidden &&
        fuseConfig.layout.sidepanel.position === 'right'
    "
>
    <!-- PANEL CONTENT -->
</ng-container>
<!-- / SIDE PANEL -->

<!-- QUICK PANEL -->
<fuse-sidebar
    id="VL1Fuse"
    name="quickPanel"
    position="right"
    class="quick-panel"
>
    <quick-panel id="VL1FuseQP"></quick-panel>
</fuse-sidebar>
<!-- / QUICK PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template id="VL1TBPos" #toolbar>
    <toolbar
        id="VL1TB"
        *ngIf="!fuseConfig.layout.toolbar.hidden"
        [ngClass]="
            fuseConfig.layout.toolbar.customBackgroundColor === true
                ? fuseConfig.layout.toolbar.position +
                  ' ' +
                  fuseConfig.layout.toolbar.background
                : fuseConfig.layout.toolbar.position
        "
    >
    </toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template id="VL1FooterPos" #footer>
    <footer
        id="VL1FP"
        *ngIf="!fuseConfig.layout.footer.hidden"
        [ngClass]="
            fuseConfig.layout.footer.customBackgroundColor === true
                ? fuseConfig.layout.footer.position +
                  ' ' +
                  fuseConfig.layout.footer.background
                : fuseConfig.layout.footer.position
        "
    ></footer>
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template id="VL1LNav" #leftNavbar>
    <fuse-sidebar
        id="VL1FuseLNav"
        name="navbar"
        class="navbar-fuse-sidebar"
        [folded]="fuseConfig.layout.navbar.folded"
        lockedOpen="gt-md"
        *ngIf="!fuseConfig.layout.navbar.hidden"
    >
        <navbar
            [variant]="fuseConfig.layout.navbar.variant"
            class="left-navbar"
        ></navbar>
    </fuse-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template id="VL1RNav" #rightNavbar>
    <fuse-sidebar
        id="VL1FuseRNav"
        name="navbar"
        position="right"
        class="navbar-fuse-sidebar"
        [folded]="fuseConfig.layout.navbar.folded"
        lockedOpen="gt-md"
        *ngIf="!fuseConfig.layout.navbar.hidden"
    >
        <navbar
            [variant]="fuseConfig.layout.navbar.variant"
            class="right-navbar"
        ></navbar>
    </fuse-sidebar>
</ng-template>
<!-- / RIGHT NAVBAR -->
