<div
    id="claimTypeDashboard"
    *ngIf="data"
    style="min-height: 795px; padding: 8px; overflow: auto; position: relative;"
    fxLayout="row"
>
    <div
        fxFlex
        fxLayout="column"
    >
        <div
            id="claimTypeHeader"
            class="dash-header"
        >
            <span id="claimTypeHeaderText">{{ 'ClaimType.ClaimHeading' | translate }} </span>
            <button
                id="claimTypeHeaderBtn"
                mat-icon-button
                style="float: right"
                (click)="showFilter = !showFilter"
                *ngIf="!isFilterOptionsLoading"
            >
                <app-filter-button
                    [isClientName]='true'
                    [isEntity]='true'
                    [isPolicyType]='true'
                    [isPlan]='true'
                    [isClaimType]='true'
                    [isClaimType2]='true'
                    [isClaimType3]='true'
                    [isProviderType]='true'
                    [isGender]='true'
                    [isRelationship]='true'
                    [isAgeBand]='true'
                    [isLocation]='true'
                    [isLOB]='true'
                    [isSubLOB]='true'
                    [isTimePeriod]='true'
                ></app-filter-button>
            </button>
        </div>
        <div fxLayout="row">
            <div
                id="claimTypeHeaderCurrency"
                style="font-size: 14px; font-style: italic; padding: 3px"
                fxFlex
            >
                {{ 'ClaimType.AllAmountsDisplayedInLocalCurrency' | translate }}
            </div>
        </div>
        <div
            fxLayout="row"
            class="gridpadding"
            style="margin-top: 5px"
            *ngIf="data.finalTopListBind"
        >
            <div
                id="claimTypeTable"
                [fxFlex]="100"
            >
                <div
                    id="claimTypeTableHeader"
                    fxLayout="row"
                    class="tableheader"
                >
                    <div [fxFlex]="20">{{ 'ClaimType.ClaimType' | translate }} </div>
                    <div [fxFlex]="15">{{ 'ClaimType.TotalPaidClaim' | translate }}</div>
                    <div [fxFlex]="5"></div>
                    <div [fxFlex]="15">
                        {{countBasisHeader | translate}}
                    </div>
                    <div [fxFlex]="15">{{ claimsPerCountBasisHeader | translate }}</div>
                    <div [fxFlex]="15">
                        {{costPerCountBasisHeader | translate}}
                    </div>
                    <div [fxFlex]="15">{{ 'ClaimType.ChangeInCost' | translate }}</div>
                </div>
                <div
                    fxLayout="row"
                    class="tablerow dashed padNoneTableRow"
                    *ngFor="let finalTopListBindRecord of data.finalTopListBind"
                    (click)="selectClaim($event, finalTopListBindRecord)"
                    [ngClass]="(data.currentClaim == finalTopListBindRecord.dimension && data.currentClaim !== '')? 'grayRow' : ''"
                >
                    <div
                        [fxFlex]="20"
                        style="text-align: center !important;font-size: 12px;"
                    >
                        {{finalTopListBindRecord.dimension}}
                    </div>
                    <div [fxFlex]="15">
                        <ng-container *ngIf="parentLoaded">
                            <app-bar
                                [barColor]="barColorPurple"
                                [value]="finalTopListBindRecord.claimsTotal"
                                [max]="maxClaim"
                                [unit]="'ProviderUnit'"
                                [valueDecimalPlace]="4"
                                [country]="properties.country"
                            ></app-bar>
                        </ng-container>
                    </div>
                    <div [fxFlex]="5"></div>
                    <div
                        [fxFlex]="15"
                        style="text-align: center !important "
                    >
                        {{getCountBasisByClaimTypeValue(finalTopListBindRecord) | roundToDecimalPlace: 0 | numberFormat
                        }}
                    </div>
                    <div
                        [fxFlex]="15"
                        style="text-align: center !important "
                    >
                        {{ getClaimsPerCountBasisPerClaimType(finalTopListBindRecord) | roundToDecimalPlace: 1 |
                        numberFormat}}
                    </div>
                    <div
                        [fxFlex]="15"
                        style="text-align: center !important "
                    >
                        <ng-container>
                            <app-bar
                                style="width: 100%"
                                [barColor]="barColorBlue"
                                [valueDecimalPlace]="(properties.country === 'Chile' ? 2 : 0)"
                                [value]="getCostPerCountBasisPerClaimType(finalTopListBindRecord)"
                                [max]="maxCost"
                                [unit]="unitCost"
                                [country]="properties.country"
                            ></app-bar>
                        </ng-container>
                    </div>
                    <div
                        [fxFlex]="15"
                        style="padding-right:5px; text-align: center !important "
                    >
                        <div
                            [fxFlex]="70"
                            style="text-align: right; margin-top:1px; "
                            *ngIf="!(finalTopListBindRecord.yoYChange === 0)"
                        >
                            <span>{{(finalTopListBindRecord.yoYChange | roundToDecimalPlace: 1 | numberFormat )+ ' %'}}
                            </span>
                        </div>
                        <div
                            [fxFlex]="30"
                            style="text-align: left; "
                            *ngIf="!(finalTopListBindRecord.yoYChange === 0)"
                        >
                            <mat-icon
                                style="color:rgb(216, 211, 211); "
                                *ngIf="finalTopListBindRecord.yoYChange>0"
                            >arrow_upward</mat-icon>
                            <mat-icon
                                style="color:rgb(216, 211, 211); "
                                *ngIf="finalTopListBindRecord.yoYChange<0"
                            >arrow_downward</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="parentLoaded && (properties.showHide == ShowHideOption.Show)">
            <div
                fxLayout="row"
                style="margin-top: -10px;"
            >
                <div [fxFlex]="70"></div>
                <div
                    [fxFlex]="30"
                    style="text-align: center;"
                >
                    <button
                        id="claimTypeBtnPlan"
                        mat-icon-button
                        (click)="showPlotLine = !showPlotLine"
                        style="margin-top: -2px;"
                    >
                        <mat-icon>border_vertical</mat-icon>
                    </button> {{ 'ClaimType.ShowHide' | translate }} &nbsp;{{ 'ClaimType.PlanYear' | translate }}
                    &nbsp;& &nbsp;{{'ClaimType.PeriodAverage' | translate }}
                </div>
            </div>
            <ng-container id="claimTypeCharts">
                <div
                    fxLayout="row"
                    style="margin-top: -10px;"
                >
                    <div
                        id="claimTypeChartClaimTotal"
                        [fxFlex]="100"
                    >
                        <app-column
                            [dataList]="dataListClaimTotal"
                            [textC]="chartPurpleText"
                            [barColor]="barColorPurple"
                            [isCountryChile]="isCountryChile"
                            [maxX]="maxX"
                            [categories]="categories"
                            [marginLeft]="marginLeft"
                            [plotXpos]="showPlotLine === true? plotXposClaim :[]"
                            [dataListDot]="showPlotLine === true?listClaimAvg:[]"
                            [barShow]="true"
                            [lineShow]="false"
                            [dotListName]="chartPurpleAvgText"
                        ></app-column>
                    </div>
                </div>
                <div fxLayout="row">
                    <div
                        id="claimTypeChartCost"
                        [fxFlex]="100"
                    >
                        <app-column
                            [dataList]="dataListCost"
                            [textC]="chartBlueText"
                            [barColor]="barColorBlue"
                            [maxX]="maxX"
                            [isCountryChile]="isCountryChile"
                            [categories]="categories"
                            [marginLeft]="marginLeft"
                            [plotXpos]="showPlotLine === true?plotXposCost :[]"
                            [dataListDot]="showPlotLine === true?listCostAvg:[]"
                            [barShow]="true"
                            [lineShow]="false"
                            [dotListName]="chartBlueAvgText"
                        >
                        </app-column>
                    </div>
                </div>
                <div fxLayout="row">
                    <div
                        id="claimTypeChartCount"
                        [fxFlex]="100"
                    >
                        <app-column
                            [textC]="chartLineText"
                            [dataList]="dataListCountLine"
                            [maxX]="maxX"
                            [categories]="categories"
                            [isCountryChile]="isCountryChile"
                            [barColor]="lineColorPurple"
                            [plotXpos]="showPlotLine === true? plotXposCountLine :[]"
                            [marginLeft]="marginLeft"
                            [dataListDot]="showPlotLine === true?listCountAvg:[]"
                            [barShow]="false"
                            [lineShow]="true"
                            [dotListName]="chartLineAvgText"
                        ></app-column>
                    </div>
                </div>
            </ng-container>
        </div>
        <div fxLayout="row">
            <div [fxFlex]="80"></div>
            <div
                id="claimTypeFooterNote"
                [fxFlex]="20"
                style="text-align: right; margin-right: 10px;"
            >
                {{properties.claimPaid === CLAIM_BASIS.PAID_BASIS ? ( 'ClaimType.ClaimsReportedByPaidDate' | translate )
                : (
                'ClaimType.ClaimsReportedByIncurredDate' | translate )}}
            </div>
        </div>
    </div>
    <div
        style="width: 200px; padding: 80px 0px 0px 16px; border-left: 1px solid #EEE"
        fxLayout="column"
    >
        <div
            id="entityDivisionFilters"
            *ngIf="showFilter"
        >
            <app-additional-filters
                [country]="properties.country"
                (action)="additionalFilterSelectionChanged($event)"
                [filterOptions]='filterOptions'
                [isClientName]='true'
                [isEntity]='true'
                [isPolicyType]='true'
                [isPlan]='true'
                [isClaimType]='true'
                [isClaimType2]='true'
                [isClaimType3]='true'
                [isProviderType]='true'
                [isGender]='true'
                [isRelationship]='true'
                [isAgeBand]='true'
                [isLocation]='true'
                [isLOB]='true'
                [isSubLOB]='true'
                [isTimePeriod]='true'
            ></app-additional-filters>
        </div>
        <div
            id="claimTypeFilters"
            fxFlex="55"
            *ngIf="!showFilter"
        >
            <div
                id="claimTypeLegend"
                fxLayout="row"
                style="margin-bottom:15px;"
            >
                <svg width="15"  height="15">
                    <rect width="15"
                         height="15"
                        fill="#FF803E"
                    ></rect>
                </svg>
                <span style="margin-left:5px;">{{ 'ClaimType.PeriodAverage' | translate }}</span> <br>
            </div>
            <div
                id="claimTypeTxtCountBasis"
                fxLayout="row"
                style="margin-top:10px; margin-bottom: 5px;"
            >
                <span>{{ 'ClaimType.CountBasis' | translate }}</span>
            </div>
            <div
                id="claimTypeBtnClaim"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.claimTypeOption == ClaimantOption.Claim}"
                (click)="filterChanged(ClaimantOption.Claim, 'claimTypeOption')"
            >
                <span>{{ 'ClaimType.Claim' | translate }}</span>
            </div>
            <div
                id="claimTypeBtnClaimant"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.claimTypeOption == ClaimantOption.Claimant}"
                (click)="filterChanged(ClaimantOption.Claimant, 'claimTypeOption')"
            >
                <span>{{ 'ClaimType.Claimant' | translate }}</span>
            </div>
            <div
                id="claimTypeBtnHeadcount"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.claimTypeOption == ClaimantOption.Headcount}"
                (click)="filterChanged(ClaimantOption.Headcount, 'claimTypeOption')"
            >
                <span>{{ 'ClaimType.Headcount' | translate }}</span>
            </div>
            <div
                id="claimTypeTxtShowHide"
                fxLayout="row"
                style="margin-top:15px; margin-bottom: 15px;"
            >
                <span>{{ 'ClaimType.ShowHide' | translate }}</span>
            </div>
            <div
                id="claimTypeBtnShow"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.showHide == ShowHideOption.Show}"
                (click)="showHideFilter(ShowHideOption.Show, 'showHide')"
            >
                <span>{{ 'ClaimType.Show' | translate }}</span>
            </div>
            <div
                id="claimTypeBtnHide"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.showHide == ShowHideOption.Hide}"
                (click)="showHideFilter(ShowHideOption.Hide, 'showHide')"
            >
                <span>{{ 'ClaimType.Hide' | translate }}</span>
            </div>
        </div>
    </div>
</div>