import { Component, ViewChild, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';

const moment = _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const CAL_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-monthyearpicker',
  templateUrl: './monthyearpicker.component.html',
  styleUrls: ['./monthyearpicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: CAL_FORMATS},
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthyearpickerComponent),
      multi: true,
    },
  ]
})
export class MonthyearpickerComponent implements ControlValueAccessor {

  @Input() title: string;
  @Input() minDt: Date;
  @Input() maxDt: Date;
  @Input() dateValue: Date;
  @Input() disableDatePicker: Boolean;

  @Output() dateValueChange = new EventEmitter<Date>();

  @ViewChild(MatDatepicker) picker;
  constructor() {  }
  writeValue(obj: any): void {
    this.dateValue = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disableDatePicker = isDisabled;
  }

  monthSelected(params) {
    this.dateValue = params;
    this._onChange(params);
    this._onTouched();
    this.dateValueChange.emit(params);
    this.picker.close();
  }

  dateInputClicked() {
    if(!this.disableDatePicker) {
      this.picker.open();
    }
  }

  private _onChange = (_value: any) => { };
  private _onTouched = () => { };
}
