<aon-navigation-summary-card [height]="cardheight" [width]="cardwidth" style="color:white!important;margin-left:25px;">
    <aon-navigation-summary-card-header [headerheight]="navigationheight" [headerwidth]="'100%'"
        (onnextclick)="onNext($event)" (onprevclick)="onPrev($event)" [previcondisabled]="disablePrevArrow"
        [nexticondisabled]="disableNextArrow">
        <div style="display: flex;flex-direction:column;justify-content: space-between; width:90%;">
            <div style="display: flex;flex-direction:row ;justify-content: space-between;">
                <div>

                    <aon-h3 style="color: #001b5a;">
                        <fa-icon style="padding-right: 5px;" [icon]="['fal', 'users']"></fa-icon>
                        {{ charttitle }}
                    </aon-h3>
                </div>

                <div style="color: #001b5a;margin-top: 5px; float: right; width:300px;">
                    <div style="display: flex; flex-direction:row; justify-content: space-around">
                        <aon-h5 style="color: #001b5a;">
                            By Question
                        </aon-h5>
                        <aon-switch (ontoggle)="togglebyTotal()" [switchon]="!toggleTotal" width='150px'>
                        </aon-switch>
                    </div>
                </div>
            </div>
            <div style="display: flex;flex-direction:row  ;margin-left:20px;justify-content: flex-start;">

                <div>
                    <aon-horizontaltab-list>
                        <aon-horizontaltab [selected]="isAgeBandSelected" (click)="toggleHorizontalTab('Age Band')">Age
                            Band
                        </aon-horizontaltab>
                        <aon-horizontaltab [selected]="isLocationSelected" (click)="toggleHorizontalTab('Location')">
                            Location
                        </aon-horizontaltab>
                        <!-- <aon-horizontaltab (click)="toggleHorizontalTab()">Gender</aon-horizontaltab> -->
                        <aon-horizontaltab [selected]="isSalaryBandSelected"
                            (click)="toggleHorizontalTab('Salary Band')">
                            Salary
                            Band</aon-horizontaltab>
                        <aon-horizontaltab [selected]="isOverTimeSelected" (click)="toggleHorizontalTab('Over Time')">
                            Over
                            Time
                        </aon-horizontaltab>
                    </aon-horizontaltab-list>
                </div>
            </div>
        </div>
    </aon-navigation-summary-card-header>
    <aon-navigation-summary-card-content top="'100px'">
        <div class=" margintop30">
            <div *ngIf="isAgeBandSelected"   [style.display]="isAgeBandSelected ? 'block' : 'none'"   style="flex: 1;margin-top: 10px;">
                <aon-expandable-bar-chart   [data]="agebandData"
                    [height]="expandableAgeBandheight">
                    <aon-bar-content text="{{item.category}}" [height]="stackedheightAgeBand" value="{{item.value}}"
                        [maxbarheight]="'60'" [isexpand]="item.isexpanded"
                        (click)="expandParentHeightForAgeBand(item.category)" *ngFor="let item of agebandData">
                        <div style="margin-top: -6%; margin-left:10%;height:100%; width:80%;">
                            <aon-group-horizontal-bar-chart   [maxyaxisnumber]="scaleMaxAgeBand" [charttitle]="''"  
                               [yaxistitle]="stackedyaxistitle" [xaxistitle]="stackedxaxistitle" [data]="item.values" ></aon-group-horizontal-bar-chart>                  
                        </div>
                    </aon-bar-content>
                </aon-expandable-bar-chart>
            </div>


            <div class=" margintop20" [style.display]="isLocationSelected ? 'block' : 'none'"    style="flex: 1; ">

                <div style="display: flex;
                    width: 95%;
                    justify-content: flex-end; ">
                    <div
                        style="   margin-top: 20px;   min-width: 95px;     align-content: center;  vertical-align: middle;    ">
                        <aon-p-lg>
                            Group By
                        </aon-p-lg>
                    </div>
                    <aon-select style="width: 300px !important;  margin-top: 10px;" scrollable="true"
                        [selectedvalue]="selectedOptionName" (onselectionchange)="onOptionChange($event)">
                        <aon-select-option *ngFor="let option of options" [value]="option.name">{{ option.name }}
                        </aon-select-option>
                    </aon-select>
                </div>
                <div style="display: flex; 
                    justify-content: flex-end; margin-top: 20px;">
                    <ag-grid-angular #myGrid style="width: 98%; height: 100%; overflow-x: hidden;"
                        class="ag-theme-material grid" [rowData]="locationData" [showHeader]="isHeaderVisible"
                        [columnDefs]="columnDefs" [gridOptions]="gridOptions">
                    </ag-grid-angular>
                </div>

            </div>
            <div class=" margintop20" *ngIf="isSalaryBandSelected"   style="flex: 1;">
                <aon-expandable-bar-chart [data]="salaryBandData"
                [height]="expandableAgeBandheight">
                    <aon-bar-content text="{{item.category}}" [height]="stackedSalaryheight" value="{{item.value}}" 
                    [maxbarheight]="'60'"    [isexpand]="item.isexpanded" (click)="expandParentHeightForSalaryBand(item.category)"
                        *ngFor="let item of salaryBandData">
                        <div style="margin-top: -6%; margin-left:10%;height:555px; width:80%;">
                            <aon-group-horizontal-bar-chart [maxyaxisnumber]="scaleMax"   [charttitle]="''"    [height]="stackedheight" [yaxistitle]="stackedyaxistitle"
                             [xaxistitle]="stackedxaxistitle" [data]="item.values" ></aon-group-horizontal-bar-chart>
                        </div>
                    </aon-bar-content>
                </aon-expandable-bar-chart>
            </div>
            <div class=" margintop20" [style.display]="isOverTimeSelected ? 'block' : 'none'" style="flex: 1;">

                <div style="display: flex;width: 95%;justify-content: flex-end; ">
                    <div  style=" margin-top: 20px; min-width: 95px; align-content: center; vertical-align: middle;    ">
                        <aon-p-lg>
                            Group By
                        </aon-p-lg>
                    </div>
                    <aon-select style="width: 300px !important;  margin-top: 10px;" scrollable="true"
                        [selectedvalue]="selectedOvertimeOptionName"
                        (onselectionchange)="onOvertimeOptionChange($event)">
                        <aon-select-option *ngFor="let option of overtimeOptions" [value]="option.name">
                            {{ option.name }}
                        </aon-select-option>
                    </aon-select>
                </div>
                <div style="display:flex; flex: 1; margin-top: -20px;" *ngIf="isOverTimeSelected">
                    <aon-group-bar-chart [chartid]="chartId" [width]="'98%'" [height]="'620px'" [ticksize]="tickSize"
                        [charttitle]="emptyChartTitle" [yaxistitle]="yaxistitle" [tooltip]="tooltip"
                        [maxyaxisnumber]="maxyaxisnumber" [titleposition]="titleposition" [data]="overtimedata"
                        [categorycolors]="categorycolors">
                    </aon-group-bar-chart>
                </div>
            </div>
        </div>
    </aon-navigation-summary-card-content>
</aon-navigation-summary-card>