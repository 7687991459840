<div class="title">
    <div>
        <h2 class="title__text">User Inputs</h2>
    </div>
</div>
<div class="action-buttons">
    <div>
        <button mat-raised-button (click)="resetForm()">Reset</button>
        <button mat-raised-button (click)="submitForm()" color="accent" [disabled]="inputForm.invalid">Calculate</button>
    </div>
</div>
<form>
    <ng-container [ngTemplateOutlet]="timePeriod" >
    </ng-container>
    <ng-container [ngTemplateOutlet]="membership">
    </ng-container>
    <ng-container [ngTemplateOutlet]="trendsAndAdjustments">
    </ng-container>
    <ng-container [ngTemplateOutlet]="targetLossRatio">
    </ng-container>
</form>
<div class="action-buttons">
    <div>
        <button mat-raised-button (click)="resetForm()">Reset</button>
        <button mat-raised-button (click)="submitForm()" color="accent" [disabled]="inputForm.invalid">Calculate</button>
    </div>
</div>

<ng-template #timePeriod [formGroup]='inputForm'>
    <h3 class="section-header" (click)='isTimePeriodSectionExpanded = !isTimePeriodSectionExpanded'>
        Time Period <span class="material-icons">{{isTimePeriodSectionExpanded ? 'expand_less' : 'expand_more'}}</span>
    </h3>
    <div formGroupName='timePeriod' *ngIf="isTimePeriodSectionExpanded">
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>Start</th>
                    <th>End</th>
                </tr>
            </thead>
            <tbody>
                <tr class="time-period-table__row" formGroupName='claimsPeriod'>
                    <th>Claims Period</th>
                    <td>
                        <app-monthyearpicker
                            id='claimsPeriodStart'
                            title="Start Date"
                            [minDt]="minDate"
                            [maxDt]="maxDate"
                            formControlName='start'
                        >Loading</app-monthyearpicker>
                    </td>
                    <td>
                        <app-monthyearpicker
                            id='claimsPeriodEnd'
                            title="End Date"
                            [minDt]="minDate"
                            [maxDt]="maxDate"
                            formControlName='end'
                        >Loading</app-monthyearpicker>
                    </td>
                </tr>
                <tr class="time-period-table__row" formGroupName='exclusionPeriod'>
                    <th>
                        Exclusion Period 
                        <input type="checkbox" formControlName='isApplicable' title="Is Applicable?">
                    </th>
                    <td>
                        <app-monthyearpicker
                            id='exclusionPeriodStart'
                            title="Start Date"
                            [minDt]="minDate"
                            [maxDt]="maxDate"
                            [dateValue]="maxDate"
                            formControlName='start' 
                            [disableDatePicker]="!inputForm.get('timePeriod.exclusionPeriod.isApplicable').value"
                        >Loading</app-monthyearpicker>
                    </td>
                    <td>
                        <app-monthyearpicker
                            id='exclusionPeriodEnd'
                            title="End Date"
                            [minDt]="minDate"
                            [maxDt]="maxDate"
                            [dateValue]="maxDate"
                            formControlName='end' 
                            [disableDatePicker]="!inputForm.get('timePeriod.exclusionPeriod.isApplicable').value"
                        >Loading</app-monthyearpicker>
                    </td>
                </tr>
                <tr class="time-period-table__row" formGroupName='projectionPeriod'>
                    <th>Projection Period</th>
                    <td>
                        <app-monthyearpicker
                            id='projectionPeriodStart'
                            title="Start Date"
                            [minDt]="minDate"
                            [maxDt]="projectionMaxDate"
                            [dateValue]="maxDate"
                            formControlName='start'
                        >Loading</app-monthyearpicker>
                    </td>
                    <td>
                        <app-monthyearpicker
                            id='projectionPeriodEnd'
                            title="End Date"
                            [minDt]="minDate"
                            [maxDt]="projectionMaxDate"
                            [dateValue]="maxDate"
                            formControlName='end'
                        >Loading</app-monthyearpicker>
                    </td>
                </tr>
                <tr class="time-period-table__row">
                    <th>Lagged Month(s)</th>
                    <td>
                        <mat-form-field appearance="fill" class="input_wrapper__text">
                            <input type="number" matInput formControlName='laggedMonths'>
                        </mat-form-field>
                    </td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #membership [formGroup]='inputForm'>
    <h3 class="section-header" (click)='isMembershipSectionExpanded = !isMembershipSectionExpanded'>
        Membership <span class="material-icons">{{isMembershipSectionExpanded ? 'expand_less' : 'expand_more'}}</span>
    </h3>
    <div formGroupName='membership' *ngIf="isMembershipSectionExpanded">
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th colspan="2">Historical</th>
                    <th colspan="2">Projected</th>
                </tr>
                <tr class="membership-table__row">
                    <th></th>
                    <td>%</td>
                    <td>Value</td>
                    <td>%</td>
                    <td>Value</td>
                </tr>
                <tr class="membership-table__total-row">
                    <th>Total</th>
                    <td>100%</td>
                    <td>{{this.inputForm.get('membership.historicalSumTotal').value | number}}</td>
                    <td>
                        <mat-form-field appearance="fill" class="input_wrapper__text">
                            <input type="number" matInput formControlName='projectedSumPercentage' readonly>
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="fill" class="input_wrapper__text">
                            <input type="number" matInput formControlName='projectedSumTotal'>
                        </mat-form-field>
                    </td>
                </tr>
            </thead>
            <tbody formGroupName='breakdown'>
                <tr class="membership-table__row" 
                *ngFor="let breakdownRecord of inputForm.get('membership.breakdown').controls; let i = index" 
                [formGroupName]="i">
                    <th>{{inputForm.get('membership.breakdown').controls[i].get('name').value}}</th>
                    <td>
                        {{inputForm.get('membership.breakdown').controls[i].get('historicalPercentage').value | number:'0.2'}}%
                    </td>
                    <td>
                        {{inputForm.get('membership.breakdown').controls[i].get('historicalTotal').value | number:'0.0-0'}}
                    </td>
                    <td>
                        <mat-form-field appearance="fill" class="input_wrapper__text">
                            <input type="number" matInput formControlName='projectedPercentage'>
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="fill" class="input_wrapper__text">
                            <input type="number" matInput formControlName='projectedTotal'>
                        </mat-form-field>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #trendsAndAdjustments [formGroup]='inputForm'>
    <h3 class="section-header" (click)='isTrendsAndAdjustmentsExpanded = !isTrendsAndAdjustmentsExpanded'>
        Trends and Adjustments <span class="material-icons">{{isTrendsAndAdjustmentsExpanded ? 'expand_less' : 'expand_more'}}</span>
    </h3>
    <div formGroupName='trendsAndAdjustments' *ngIf="isTrendsAndAdjustmentsExpanded">
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>Client</th>
                    <th>Country</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr class="trends-and-adjustment-table__row" formGroupName='paidToIncurredTrendAssumption'>
                    <th>Paid to Incurred Trend Assumption</th>
                    <td>
                        <mat-form-field appearance="fill" class="input_wrapper__text">
                            <input type="number" matInput formControlName='client'>
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="fill" class="input_wrapper__text">
                            <input type="number" matInput formControlName='country'>
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </td>
                    <td></td>
                </tr>
                <tr class="trends-and-adjustment-table__row" formGroupName='paidToIncurredTrendAssumption'>
                    <th></th>
                    <td colspan="2">
                        <mat-form-field appearance="fill" class="input_wrapper__textarea">
                            <textarea matInput formControlName='notes' placeholder="Notes (Optional)" rows=1></textarea>
                        </mat-form-field>
                    </td>
                    <td></td>
                </tr>
                <tr class="trends-and-adjustment-table__row" formGroupName='forwardTrend'>
                    <th>Forward Trend</th>
                    <td>
                        <mat-form-field appearance="fill" class="input_wrapper__text">
                            <input type="number" matInput formControlName='client'>
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="fill" class="input_wrapper__text">
                            <input type="number" matInput formControlName='country'>
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </td>
                    <td></td>
                </tr>
                <tr class="trends-and-adjustment-table__row" formGroupName='forwardTrend'>
                    <th></th>
                    <td colspan="2">
                        <mat-form-field appearance="fill" class="input_wrapper__textarea">
                            <textarea matInput formControlName='notes' placeholder="Notes (Optional)" rows=1></textarea>
                        </mat-form-field>
                    </td>
                    <td></td>
                </tr>
                <tr class="trends-and-adjustment-table__row">
                    <th></th>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <ng-container formGroupName="adjustments">
                    <ng-container *ngFor="let adjustmentRecord of inputForm.get('trendsAndAdjustments.adjustments').controls; let i = index">
                        <tr class="trends-and-adjustment-table__row">
                            <th>
                                Adj #{{i + 1}} 
                                <mat-icon *ngIf="inputForm.get('trendsAndAdjustments.adjustments').controls.length > 1" 
                                    (click)="removeOtherAdjustment(i)" class="trends-and-adjustment-table__row__delete" aria-hidden="false">
                                    close
                                </mat-icon>
                            </th>
                            <td [formGroupName]="i">
                                <mat-form-field appearance="fill" class="input_wrapper__text">
                                    <input type="number" matInput formControlName='client'>
                                    <span matSuffix>%</span>
                                </mat-form-field>
                            </td>
                            <td [formGroupName]="i">
                                <mat-form-field appearance="fill" class="input_wrapper__text">
                                    <input type="number" matInput formControlName='country'>
                                    <span matSuffix>%</span>
                                </mat-form-field>
                            </td>
                            <td></td>
                        </tr>
                        <tr class="trends-and-adjustment-table__row">
                            <th></th>
                            <td [formGroupName]="i" colspan="2">
                                <mat-form-field appearance="fill" class="input_wrapper__textarea">
                                    <textarea matInput formControlName='notes' placeholder="Notes (Optional)" rows=1></textarea>
                                </mat-form-field>
                            </td>
                            <td></td>
                        </tr>
                    </ng-container>
                </ng-container>
                <tr class="trends-and-adjustment-table__row">
                    <th></th>
                    <td colspan="2" class="trends-and-adjustment-table__row__add-adjustment" (click)='addOtherAdjustment()'>
                        + Add Adjustment
                    </td>
                    <td></td>
                </tr>
                <tr class="trends-and-adjustment-table__row">
                    <th></th>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr class="trends-and-adjustment-table__row" formGroupName="credibility">
                    <th>Credibility</th>
                    <td>
                        <mat-form-field appearance="fill" class="input_wrapper__text">
                            <input type="number" matInput formControlName='client'>
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </td>
                    <td>
                        {{this.inputForm.get('trendsAndAdjustments.credibility.country').value  | number}}%
                    </td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #targetLossRatio [formGroup]='inputForm'>
    <div class="target-loss-ratio">
        <div>
            <h3>Target Loss Ratio: </h3>
        </div>
        <div class="target-loss-ratio__input">
            <mat-form-field appearance="fill" class="input_wrapper__text">
                <input type="number" matInput formControlName='targetLossRatio'>
                <span matSuffix>%</span>
            </mat-form-field>
        </div>
    </div>
</ng-template>