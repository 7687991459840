import { AfterViewInit, Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AdditionalFiltersService } from '../additional-filters.service';
import { Subscription } from 'rxjs';
import { PulseEventName } from '../models/PulseEventName';
import { locale as navigationEnglish } from 'app/dashboards/shared/additional-filters/i18n/en';
import { locale as navigationSpanishCL } from 'app/dashboards/shared/additional-filters/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/dashboards/shared/additional-filters/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/dashboards/shared/additional-filters/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/dashboards/shared/additional-filters/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/dashboards/shared/additional-filters/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/dashboards/shared/additional-filters/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/dashboards/shared/additional-filters/i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { PulseSelectComponent } from '../pulse-select/pulse-select.component';
import { MatOption } from '@angular/material/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-additional-filters',
  templateUrl: './additional-filters.component.html',
  styleUrls: ['./additional-filters.component.css']
})
export class AdditionalFiltersComponent implements AfterViewInit, OnInit {
    @Input() filterOptions: any = null;

    @Input() country = '';

    @Input() isClientName = false;
    @Input() isEntity = false;
    @Input() isDivison = false;
    @Input() isPlan = false;
    @Input() isTimePeriod = false;
    @Input() isTimePeriodLossRatio = false;
    @Input() isTimePeriodEnrollment = false;
    @Input() isPolicyType = false;
    @Input() isAgeBand = false;
    @Input() isGender = false;
    @Input() isClaimType = false;
    @Input() isClaimType2 = false;
    @Input() isClaimType3 = false;
    @Input() isRelationship = false;
    @Input() isDiagCat = false;
    @Input() isDiagChapter = false;
    @Input() isLocation = false;
    @Input() isChronic = false;
    @Input() isCarrier = false;
    @Input() isLOB = false;
    @Input() isSubLOB = false;
    @Input() isNetwork = false;
    @Input() isProviderName = false;
    @Input() isProviderLocation = false;
    @Input() isProviderType = false;

    @Output() action = new EventEmitter<any>();

    @ViewChild('policyType') policyTypeSelect: PulseSelectComponent;

    filterProperties: any = null;

    aClientNames: string[] = [];
    aEntities: string[] = [];
    aDivisions: string[] = [];
    aPlans: string[] = [];
    aTimePeriods: string[] = [];
    aTimePeriodsLossRatio: string[] = [];
    aTimePeriodsEnrollment: string[] = [];
    aPolicyTypes: string[] = [];
    aAgeBands: string[] = [];
    aGenders: string[] = [];
    aClaimTypes: string[] = [];
    aClaimTypes2: string[] = [];
    aClaimTypes3: string[] = [];
    aRelationships: string[] = [];
    aDiagCats: string[] = [];
    aDiagChapters: string[] = [];
    aLocations: string[] = [];
    aChronics: string[] = [];
    aCarriers: string[] = [];
    aLobs: string[] = [];
    aSubLobs: string[] = [];
    aNetworks: string[] = [];
    aProviderNames: string[] = [];
    aProviderLocations: string[] = [];
    aProviderTypes: string[] = [];

    updateAdditionalFiltersSubscription: Subscription;

    constructor(private _additionalFiltersService: AdditionalFiltersService,    
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService) {

    }

    ngOnInit(): void {
        this._fuseTranslationLoaderService.loadTranslations(
          navigationEnglish,
          navigationSpanishCL,
          navigationSpanishCO,
          navigationSpanishEC,
          navigationSpanishMX,
          navigationSpanishPE,
          navigationPortugueseBR,
          navigationItalianIT,
        );

        this.filterProperties = this._additionalFiltersService.get();
        this.updateSelection();

        this.updateAdditionalFiltersSubscription = this._additionalFiltersService.updateAdditionalFilters
        .subscribe(filterProperties => {
            this.filterProperties = filterProperties;
            this.updateSelection();
        });
    }

    ngAfterViewInit(): void {
        this.filterProperties = this._additionalFiltersService.get();

        if (!this.isPolicyType || this.filterProperties['aPolicyTypes'] || this.country !== 'Chile') {
            return;
        }

        this.policyTypeSelect.matOptions.changes
            .pipe(filter(o => !this.filterProperties['aPolicyTypes']))
            .subscribe(() => {
                this.policyTypeSelect.selection = this._additionalFiltersService.retrievePolicyTypeChileSelections();
            });
    }

    ngOnDestroy(): void {
        //this.updateAdditionalFiltersSubscription.unsubscribe();       
    }

    updateSelection(): void {
        this.filterProperties = this._additionalFiltersService.get();

        if (typeof this.filterProperties['aClientNames'] !== 'undefined') {
            this.aClientNames = this.filterProperties['aClientNames'];
        }

        if (typeof this.filterProperties['aEntities'] !== 'undefined') {
            this.aEntities = this.filterProperties['aEntities'];
        }

        if (typeof this.filterProperties['aDivisions'] !== 'undefined') {
            this.aDivisions = this.filterProperties['aDivisions'];
        }

        if (typeof this.filterProperties['aPlans'] !== 'undefined') {
            this.aPlans = this.filterProperties['aPlans'];
        }

        if (typeof this.filterProperties['aPolicyTypes'] !== 'undefined') {
            this.aPolicyTypes = this.filterProperties['aPolicyTypes'];
        }

        if (typeof this.filterProperties['aPolicyTypes'] !== 'undefined' && this.country === 'Chile') {
            if (this.filterProperties['aPolicyTypes'].indexOf('ALL') >= 0) {
                this.aPolicyTypes = this._additionalFiltersService.retrieveAllPolicyTypeChileSelections();
            }
        }
    
        if (typeof this.filterProperties['aPolicyTypes'] === 'undefined' && this.country === 'Chile') {
            this.aPolicyTypes = this._additionalFiltersService.retrievePolicyTypeChileSelections();
        }

        if (typeof this.filterProperties['aAgeBands'] !== 'undefined') {
            this.aAgeBands = this.filterProperties['aAgeBands'];
        }

        if (typeof this.filterProperties['aGenders'] !== 'undefined') {
            this.aGenders = this.filterProperties['aGenders'];
        }

        if (typeof this.filterProperties['aClaimTypes'] !== 'undefined') {
            this.aClaimTypes = this.filterProperties['aClaimTypes'];
        }

        if (typeof this.filterProperties['aClaimTypes2'] !== 'undefined') {
            this.aClaimTypes2 = this.filterProperties['aClaimTypes2'];
        }

        if (typeof this.filterProperties['aClaimTypes3'] !== 'undefined') {
            this.aClaimTypes3 = this.filterProperties['aClaimTypes3'];
        }

        if (typeof this.filterProperties['aRelationships'] !== 'undefined') {
            this.aRelationships = this.filterProperties['aRelationships'];
        }

        if (typeof this.filterProperties['aDiagCats'] !== 'undefined') {
            this.aDiagCats = this.filterProperties['aDiagCats'];
        }

        if (typeof this.filterProperties['aDiagChapters'] !== 'undefined') {
            this.aDiagChapters = this.filterProperties['aDiagChapters'];
        }

        if (typeof this.filterProperties['aLocations'] !== 'undefined') {
            this.aLocations = this.filterProperties['aLocations'];
        }    
        
        if (typeof this.filterProperties['aChronics'] !== 'undefined') {
            this.aChronics = this.filterProperties['aChronics'];
        }

        if (typeof this.filterProperties['aCarriers'] !== 'undefined') {
            this.aCarriers = this.filterProperties['aCarriers'];
        }

        if (typeof this.filterProperties['aLobs'] !== 'undefined') {
            this.aLobs = this.filterProperties['aLobs'];
        }   
        
        if (typeof this.filterProperties['aSubLobs'] !== 'undefined') {
            this.aSubLobs = this.filterProperties['aSubLobs'];
        }

        if (typeof this.filterProperties['aLocations'] !== 'undefined') {
            this.aLocations = this.filterProperties['aLocations'];
        }    
        
        if (typeof this.filterProperties['aProviderNames'] !== 'undefined') {
            this.aProviderNames = this.filterProperties['aProviderNames'];
        }

        if (typeof this.filterProperties['aProviderLocations'] !== 'undefined') {
            this.aProviderLocations = this.filterProperties['aProviderLocations'];
        }

        if (typeof this.filterProperties['aProviderTypes'] !== 'undefined') {
            this.aProviderTypes = this.filterProperties['aProviderTypes'];
        }     

        if (typeof this.filterProperties['aTimePeriods'] !== 'undefined') {
            this.aTimePeriods = this.filterProperties['aTimePeriods'];
        }    

        if (typeof this.filterProperties['aTimePeriodsLossRatio'] !== 'undefined') {
            this.aTimePeriodsLossRatio = this.filterProperties['aTimePeriodsLossRatio'];
        }   

        if (typeof this.filterProperties['aTimePeriodsEnrollment'] !== 'undefined' && this.isTimePeriodEnrollment) {
            this.aTimePeriodsEnrollment = this.filterProperties['aTimePeriodsEnrollment'];
        } 
    }

    reset(): void {
        this.aClientNames = [];
        this.aEntities = [];
        this.aDivisions = [];
        this.aPlans = [];
        this.aTimePeriods = [];
        this.aTimePeriodsLossRatio = [];
        this.aTimePeriodsEnrollment = [];
        this.aPolicyTypes = [];
        this.aAgeBands = [];
        this.aGenders = [];
        this.aClaimTypes = [];
        this.aClaimTypes2 = [];
        this.aClaimTypes3 = [];
        this.aRelationships = [];
        this.aDiagCats = [];
        this.aDiagChapters = [];
        this.aLocations = [];
        this.aChronics = [];
        this.aCarriers = [];
        this.aLobs = [];
        this.aSubLobs = [];
        this.aNetworks = [];
        this.aProviderNames = [];
        this.aProviderLocations = [];
        this.aProviderTypes = [];

        this._additionalFiltersService.set({});

        this.action.emit({
            type: PulseEventName.ADDITIONAL_FILTER_RESET_ALL,
            data: null
        });
    }

    selectionChanged($event: any, filterName: string): void {
        this.action.emit({
            type: PulseEventName.ADDITIONAL_FILTER_CHANGED,
            data: {
                properties: {
                    name: filterName,
                    value: typeof $event === 'object' ? $event.data.join('||') : $event
                }
            }
        });

        this.filterProperties[filterName] = $event.data;

        this._additionalFiltersService.set({...this.filterProperties});
    }
}
