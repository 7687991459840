import { Component, OnInit } from '@angular/core';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { CacheService } from 'app/wellone/services/cache.service';
import { GlobalFilterService } from 'app/wellone/services/globalfilter.service';
import { OnDestroy } from '@angular/core';


@Component({
  selector: 'app-wellone-cache-managment',
  templateUrl: './cachemgmt.component.html',
  styleUrls: ['./cachemgmt.component.css']
})
export class CachemgmtComponent implements OnInit, OnDestroy {
  isLoading = false;  
  errorMsg:string;
  cacheList = [];
  cacheColumnDefs :any;
  cacheInterval:any;
  constructor(private cacheService: CacheService,
    private globalFilterService: GlobalFilterService,
    private toasterService: ToasterService
    ) { 

      this.cacheColumnDefs =   [
        {
          headerName: 'User',
          field: 'userId',
          sort: 'asc'
        },
        { 
          headerName: 'Status', 
          field: 'status',
        },
        {
            headerName: 'Date', field: 'createdOn'
        }
    ];

    
    }

  ngOnInit() {
    setTimeout(() => {     
          this.globalFilterService.showFilter(false); 
    }, 300);

    this.loadCacheData();
   this.cacheInterval =  setInterval(() =>{
      this.loadCacheData();
    }, 10000);

  }

  triggerCacheClick() : void
  {
    this.isLoading = true;
      this.cacheService.triggerCache().toPromise().then((res: any) => {
        this.isLoading = false;
       this.toasterService.showMessage('Cache trigger successfully.');
      }).catch(error => {
        this.toasterService.showMessage('Error occured while cache trigger.');
        this.isLoading = false;
      });
  }

  private loadCacheData(): void 
  {
    this.errorMsg = undefined;    
    this.cacheService.getLogResult().toPromise().then((res: any) => {
      
      this.cacheList = JSON.parse(JSON.stringify(res));
      console.log(this.cacheList);
    }).catch(error => {
      
      this.errorMsg = error.message;
    });
  }

  ngOnDestroy() 
  {
		  clearInterval(this.cacheInterval);
  }
 

}
