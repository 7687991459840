import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    ViewChild
} from "@angular/core";
import { DashboardTemplateBase } from "../dashboard-template-base.component";
import { FilterOptions } from "app/dashboards/shared/models/FilterOptions";
import { PulseEventName } from "app/dashboards/shared/models/PulseEventName";

import { locale as navigationEnglish } from "app/dashboards/templates/distribution-by-claim-type/i18n/en";
import { locale as navigationSpanishCL } from "app/dashboards/templates/distribution-by-claim-type/i18n/es-cl";
import { locale as navigationSpanishCO } from "app/dashboards/templates/distribution-by-claim-type/i18n/es-co";
import { locale as navigationSpanishEC } from "app/dashboards/templates/distribution-by-claim-type/i18n/es-ec";
import { locale as navigationSpanishMX } from "app/dashboards/templates/distribution-by-claim-type/i18n/es-mx";
import { locale as navigationSpanishPE } from "app/dashboards/templates/distribution-by-claim-type/i18n/es-pe";
import { locale as navigationPortugueseBR } from "app/dashboards/templates/distribution-by-claim-type/i18n/pt-br";
import { locale as navigationItalianIT } from "app/dashboards/templates/distribution-by-claim-type/i18n/it";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { CLAIM_BASIS } from "app/models/basis.model";

@Component({
    selector: "app-distribution-by-claim-type",
    templateUrl: "./distribution-by-claim-type.component.html",
    styleUrls: ["./distribution-by-claim-type.component.css"]
})
export class DistributionByClaimTypeComponent
    implements OnInit, DashboardTemplateBase {

    isFilterOptionsLoading = true;

    data: any;
    properties: any;
    imageUrlBase = "";
    filterOptions: FilterOptions = new FilterOptions();
    GenderRelOptions = GenderRelOptions;
    showFilter: boolean = false;
    dataList: any = [];
    dimensionList: any = [];
    secondDimensions: any = [];
    displaydata: any = [];
    displaydata2: any = [];
    tableHeading: any = [];
    displayedColumns: any = [];
    columns: any = [];
    dynamicTable: any[][] = new Array();
    dynamicTableRow: any[] = new Array();
    legendList: any = [];
    @Output() action = new EventEmitter<any>();
    CLAIM_BASIS = CLAIM_BASIS;

    initData(): any {
        this.dataList = this.data.claimTypeTable;
        this.dimensionList = this.data.distinctFirstDimensions;
        this.secondDimensions = this.data.distinctSecondDimensions;
        this.tableHeading = this.data.tableheaders;

        this.dataList.map((d) => {
            var objlegend = { name: '', color: '' }
            // objlegend.name = d.planYear;
            objlegend.name = d.timePeriod.fromDateLabel + ' ' + this._translateService.instant("DistributionByClaimType.DateRange") + ' ' + d.timePeriod.toDateLabel;
            if (d.isCurrent) {
                objlegend.color = '#101E7F';
            } else {
                objlegend.color = "#29B0C3";
            }

            if (this.legendList.length < 2) {
                this.legendList.push(objlegend);
            }
        });
    }
    constructor(
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) { }

    ngOnInit() {
        this._fuseTranslationLoaderService.loadTranslations(
          navigationEnglish,
          navigationSpanishCL,
          navigationSpanishCO,
          navigationSpanishEC,
          navigationSpanishMX,
          navigationSpanishPE,
          navigationPortugueseBR,
          navigationItalianIT,
        );
    }

    changeSorting(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED,
            data: {
                properties: {
                    name: filterName,
                    value: val
                }
            }
        });
    }

    selectionChanged(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED,
            data: {
                properties: {
                    name: filterName,
                    value: val
                }
            }
        });
    }

    filterChanged(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
            data: {
                properties: {
                    name: filterName,
                    value: typeof val === 'object' ? val.data.join('||') : val
                }
            }
        });
    }

    additionalFilterSelectionChanged(val) {
        this.action.emit(val);
    }
}

export enum GenderRelOptions {
    AgeBand = "Age Band",
    Gender = "Gender",
    Relationship = "Relationship"
}
