import { Injectable } from '@angular/core';

import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { CostProjectionUserSummary } from 'app/models/cost-projection/admin/cost-projection-users.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private httpClient: HttpClient) { }

  public getCountries() {
    return this.httpClient.get<any>(environment.api + 'api/admin/t2');
  }

  public getEnabledCountries() {
    return this.httpClient.get<any>(environment.api + 'api/admin/t2/enabled');
  }
  
  public createGET(url) {
    return this.httpClient.get<any>(url);
  }

  public createPOSTPromise(resolve, reject, url, dataObj?) {
    console.log(dataObj);
    this.httpClient.post(url, dataObj)
      .toPromise().then((res: any) => {
        console.log(res)
        let data = res;
        resolve(data);
      }).catch(error => {
        reject(error);
      });
  }

  public getClients() {
    return this.httpClient.get<any>(environment.api + 'api/admin/t1');
  }

  public getClientGroups() {
      return this.httpClient.get<any>(environment.api + 'api/admin/t1/groups/list');
  }
  
  public getClientsByCountry(clientId) {
    return this.httpClient.get<any>(environment.api + 'api/admin/t1/' + clientId);
  }

  public getClientsByUserCountry(userId, countryId) {
    return this.httpClient.get<any>(environment.api + 'api/admin/t1/' + userId + '/' + countryId);
  }

  public getTier2EnabledStatus(countryId) {
    return this.httpClient.get<boolean>(environment.api + 'api/admin/country/' + countryId + '/enabled');
  }

  public updateTier2EnabledStatus(countryId, isEnabled) {
    return this.httpClient.get<boolean>(environment.api + 'api/admin/country/' + countryId + '/enabled/' + isEnabled);
  }

  public getEnabledTier2List() {
    return this.httpClient.get<number[]>(environment.api + 'api/admin/country/list/enabled');
  }


  public getUserMappingsByStory(storyId) {
    return this.httpClient.get<any>(environment.api + 'api/admin/stories/' + storyId + '/userMapping')
  }

  public updateUserMappingsByStoryUser(storyId, selectedUserIdList, unselectedUserIdList) {
    return this.httpClient.post<any>(environment.api + 'api/admin/stories/' + storyId + '/updateUsers', { selectedUserIds: selectedUserIdList, unselectedUserIds: unselectedUserIdList })
  }

  public search(filter): Observable<any[]> {
    // return this.httpClient.get<any>('https://jsonplaceholder.typicode.com/users')
    return this.httpClient.get<any>(environment.api + 'api/admin/users?search=' + filter)
  }

  public getRegionalManagers() {
    return this.httpClient.get<any>( environment.api + 'api/admin/users/regionalmanager');
  }

  public getCountryManagers() {
    return this.httpClient.get<any>( environment.api + 'api/admin/users/countrymanager');
  }

  public saveClient(param)
  {      
      return this.httpClient.post<any>(environment.api + 'api/admin/client/save', param);
  }

  public saveClientBulk(param) {
    return this.httpClient.post<any>(environment.api + 'api/admin/client/bulk', param);
  }
  
  public saveClientGroup(clientName: string)
  {
    return this.httpClient.post<any>(environment.api + 'api/admin/client/group/save?clientName=' + clientName.trim(), null);
  }

  public getGroupClientList(groupTier1Id: number)
  {
    return this.httpClient.get<any>(environment.api + 'api/admin/client/group/' + groupTier1Id + '/list');
  }

  public saveGroupClientList(groupTier1Id: number, selectedClientTier1IdList: number[])
  {
    return this.httpClient.post<any>(environment.api + 'api/admin/client/group/' + groupTier1Id + '/save', selectedClientTier1IdList);
  } 

  public deleteClientGroup(groupTier1Id: number)
  {
    return this.httpClient.post<any>(environment.api + 'api/admin/client/group/' + groupTier1Id + '/delete', null);
  }
     
  public saveCountry(param)
  {      
    return this.httpClient.post<any>(environment.api + 'api/admin/country/save', param);
  }

  public saveCountryClients(tier2Id, tier1IdList)
  {      
    return this.httpClient.post<any>(environment.api + 'api/admin/country/clients/' + tier2Id + '/save', tier1IdList);
  }

  public deleteClient(t1Id) {
    return this.httpClient.post<any>(environment.api + 'api/admin/t1/' + t1Id + '/delete', {});
  }

  public deleteCountry(t2Id) {
    return this.httpClient.post<any>(environment.api + 'api/admin/t2/' + t2Id + '/delete', {});
  }

  public saveUser(param) {
    return this.httpClient.post<any>(environment.api + 'api/access/user/create', param);
  }

  public deleteUser(userId: string): any {
    return this.httpClient.post<any>(environment.api + 'api/access/user/' + userId + '/delete', null);
  }

  public saveUserBulk(param) {
    return this.httpClient.post<any>(environment.api + 'api/access/user/create/bulk', param);
  }

  getUserList(){
    return this.httpClient.get<any>(environment.api + 'api/admin/userList');
  }

  getUserRoleList(){
    return this.httpClient.get<any>(environment.api + 'api/access/userRoleList');
  }
  public saveUserRoleMapping(param)
  {      
      return this.httpClient.post<any>(environment.api + 'api/access/role', param);
  }
  
  getAllUsers() {
    return this.httpClient.get<any>(environment.api + 'api/admin/users/all');
  }
  
  getTableauProjects() {
    return this.httpClient.get<any>(environment.api + 'api/tableau/projects/local');
  }

  getTableauProjectsByT2Ids(tier2Ids) {
    return this.httpClient.post<any>(environment.api + 'api/tableau/projects/t2', tier2Ids);
  }

  updateTableauProjects(tableauProjects: any[]) {
    return this.httpClient.post<any>(environment.api + 'api/tableau/projects/local', tableauProjects);
  }

  public assignRegionalManager(user) {
    let promise = new Promise((resolve, reject) => {
      this.createPOSTPromise(
        resolve, 
        reject, 
        environment.api + 'api/admin/assign/' + user.id + '/regionalmanager')
    });
    return promise;
  }

  public removeRegionalManager(user) {
    let promise = new Promise((resolve, reject) => {
      this.createPOSTPromise(
        resolve, 
        reject, 
        environment.api + 'api/admin/remove/' + user.id + '/regionalmanager')
    });
    return promise;
  }

  public removeCountryManager(user) {
    return this.httpClient.post<any>(environment.api + 'api/admin/remove/' + user.id + '/countrymanager', '');
  }

  public removeNormalUser(user) {
    return this.httpClient.post<any>(environment.api + 'api/admin/removeuser-t1t2/' + user.id, '');
  }

  public assignCountryManagers(user) {
    let promise = new Promise((resolve, reject) => {
      this.createPOSTPromise(
        resolve, 
        reject, 
        environment.api + 'api/admin/assign/' + user.id + '/countrymanager')
    });
    return promise;
  }

  saveManagerCountries(userId, countryIdList) {
    console.log(countryIdList);
    let promise = new Promise((resolve, reject) => {
      this.createPOSTPromise(
        resolve, 
        reject, 
        environment.api + 'api/admin/assign/t2/admin/' + userId, 
        countryIdList)
    });
    return promise;
  }

  saveClientCountries(clientId, selectedCountryIdList) {
    console.log(selectedCountryIdList);
    let promise = new Promise((resolve, reject) => {
      this.createPOSTPromise(
        resolve, 
        reject, 
        environment.api + 'api/admin/t1t2/' + clientId, 
        selectedCountryIdList)
    });
    return promise;
  }

  saveUserClients(userId, countryId, clientIdList) {
    let promise = new Promise((resolve, reject) => {
      this.createPOSTPromise(
        resolve, 
        reject, 
        environment.api + 'api/admin/user-t1t2/' + countryId  + '/' + userId, 
        clientIdList)
    });
    return promise;
  }

  public IsValidRole(userId, role) {
    return this.httpClient.get<any>(environment.api + 'api/admin/isvalid/' + userId + '/' + role);
  }

  getUserCountries(userId) {
    return this.httpClient.get<any>(environment.api + 'api/admin/t2/admin/' + userId);
  }

  getClientCountries(tier1Id) {
    return this.httpClient.get<any>(environment.api + 'api/admin/t1t2/' + tier1Id);
  }

  getAssignedUsers(){
    console.log('getAssignedUsers');
    return this.httpClient.get<any>(environment.api + 'api/admin/users/assigned');
  }

  assignUserToTbsUserId(userId: string, tbsUserId: string) {
    return this.httpClient.post<any>(environment.api + 'api/admin/user/' + userId + '/tbsuser/' + tbsUserId, null);
  }

  assignClientToTbsClientId(tier1Id: number, tbsClientId: string) {

    if (typeof tbsClientId === 'undefined') {
        tbsClientId = ' ';
    }  

    if (tbsClientId === null) {
        tbsClientId = ' ';
    }  

    if (tbsClientId.length === 0) {
        tbsClientId = ' ';
    }  
    return this.httpClient.post<any>(environment.api + 'api/admin/tier1Id/' + tier1Id + '/tbsclient/' + tbsClientId, null);
  }

  getCountryBencharkLimitList(): any {
    return this.httpClient.get<any>(environment.api + 'api/admin/country/benchmark/list');
  }

  updateCountryBencharkLimitList(countryBenchmarkList: any[]): any {
    return this.httpClient.post<any>(environment.api + 'api/admin/country/benchmark/list', countryBenchmarkList);
  }

  // #region Admin - Cost Projection
  
  public assignCostProjectionUser(userId: string): Observable<any> {
    return this.httpClient.post<any>(environment.api + `api/admin/cost-projection/user/${userId}/assign`, null);
  }

  public unassignCostProjectionUser(userId: string): Observable<any> {
    return this.httpClient.delete<any>(environment.api + `api/admin/cost-projection/user/${userId}/unassign`);
  }

  public retrieveCostProjectionUsers(): Observable<CostProjectionUserSummary> {
    return this.httpClient.get<CostProjectionUserSummary>(environment.api + `api/admin/cost-projection/users`);
  }

  // #endregion
}
