
import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
@Component({
  selector: 'app-scatter-disease',
  templateUrl: './scatter-disease.component.html',
  styleUrls: ['./scatter-disease.component.css']
})
export class ScatterDiseaseComponent implements OnInit {

  constructor() { }
  chart: Chart;
  options: Options;
  @Input() dataList: any;
  @Input() diseaseProgressionList : any;
  @Input() yAxisLable:any;
  @Input() categorie:any;
  ngOnInit() {
    
    this.initialize();
    this.chart = new Chart(this.options);
   }
  ngOnChanges() {
    this.initialize();
    this.chart = new Chart(this.options);
  }
  initialize() {
    this.options = {
      chart: {
        type: 'scatter',
        zoomType: 'xy',
        height: 300,
        width:783,
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        title: {
          text: ''
        },
        min: 2016,
        max: 2020,
        tickInterval: 1,
        startOnTick: false,
        endOnTick: false,
        showLastLabel: false,
        showFirstLabel: false,
        categories:this.categorie
      },
      yAxis: [{
        gridLineWidth: 1,
        minorGridLineWidth: 1,
        min: 0,
        //max: 10000,
        tickInterval: 1000,
        lineWidth: 1,
        title: {
          text: this.yAxisLable
        },
        
      }],
      plotOptions: {
        scatter: {
          dataLabels: {
            enabled: true,
            align: "left",
            padding: 5,
            style: {
              color: '#848285',
            }, formatter: function () {
              return this.point.name;
              ;
            }
          },
          marker: {
            symbol: 'circle',
            radius: 10,
          },
           tooltip: {
             headerFormat: '',
               pointFormat: 
               '<div style="text-align: left; font-size: 13px !important; " >'+'<b>Period Lable </b> :{point.x}</div><br>' +
               '<div style="text-align: left; font-size: 13px !important; " ><b>'+this.yAxisLable +'</b>:{point.y}</div><br>',
               
           }
        }, series: {
          states: {
            inactive: {
              opacity: 1
            }
          }
        }
      },
      tooltip: {
        enabled: true
      },
      // to hide series name
      legend: {
        enabled: true,
        reversed:true
      },

      series: this.diseaseProgressionList
    }
  }
}