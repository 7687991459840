import { Component, OnInit } from '@angular/core';
import { AdminService } from 'app/services/admin.service';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { TableauService } from 'app/services/tableau.service';

@Component({
  selector: 'app-tableauprojectmgmt',
  templateUrl: './tableauprojectmgmt.component.html',
  styleUrls: ['./tableauprojectmgmt.component.css']
})
export class TableauprojectmgmtComponent implements OnInit {
  isLoadingUpdate = false;
  tableauProjects = [];
  allTableauProjects = [];
  constructor(private adminService: AdminService,
    private toasterService: ToasterService,
    private tableauService: TableauService) { }

  ngOnInit() {
    this.adminService.getTableauProjects().toPromise().then(result => {
      this.tableauProjects = result;
    });

    this.tableauService.getAllTableauProjects().toPromise().then(result => {
        this.allTableauProjects = result.projects.project;
        this.allTableauProjects.unshift({
            contentPermissions: 'ManagedByOwner',
            createdAt: '2018-10-29T12:47:18Z',
            description: '',
            id: '',
            name: '',
            owner: {id: 'd8183203-d68a-4253-b7b6-c1c2957ec7f4'},
            updatedAt: '2018-10-29T12:47:18Z'
        });
    });
  }

  update(){
    this.isLoadingUpdate = true;
    this.adminService.updateTableauProjects(this.tableauProjects).toPromise().then(result => {
      this.adminService.getTableauProjects().toPromise().then(result => {
        this.tableauProjects = result;
      });
      this.toasterService.showMessage('Records updated.', ToasterServiceModes.OK);
      this.isLoadingUpdate = false;
    });
  }

}
