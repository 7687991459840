<div
    id="enAgeDashboard"
    style="min-height: 795px; padding: 8px; overflow: auto; position: relative;"
    fxLayout="row"
>
    <div
        fxFlex
        fxLayout="column"
    >
        <div
            id="enAgeHeader"
            class="dash-header"
        >
            <span id="enAgeTxtHeader">{{'ageBand.AgeBandHeading' | translate}}</span>
            <button
                id="enAgeBtnHeader"
                mat-icon-button
                style="float: right"
                (click)="showFilter = !showFilter"
                *ngIf="!isFilterOptionsLoading"
            >
                <app-filter-button
                    [isClientName]='true'
                    [isEntity]='true'
                    [isPolicyType]='true'
                    [isPlan]='true'
                    [isProviderType]='true'
                    [isGender]='true'
                    [isRelationship]='true'
                    [isAgeBand]='true'
                    [isLocation]='true'
                    [isLOB]='true'
                    [isSubLOB]='true'
                ></app-filter-button>
            </button>
        </div>
        <div
            id="enAgeUpperHeader"
            fxLayout="row"
            class="tableheader"
        >
            <div [fxFlex]="50">
                <div [fxFlex]="25"></div>
                <div [fxFlex]="25">{{'ageBand.MemberCount' | translate}}</div>
                <div [fxFlex]="25">{{'ageBand.AverageAge' | translate}}</div>
                <div [fxFlex]="25">{{'ageBand.AverageFamilySize' | translate}}</div>
            </div>
            <div [fxFlex]="50">{{'ageBand.RelationshipDistribution' | translate}}</div>
        </div>
        <div fxLayout="row">
            <div
                id="enAgePeriodTableData"
                [fxFlex]="50"
                style="margin-top: 10px;"
            >
                <div
                    fxLayout="row"
                    class="tablerowCurrent "
                    *ngFor="let data of dataFirstTable"
                >
                    <div
                        [fxFlex]=""
                        class="dotedRight"
                    >
                        {{data.Provider}} {{data.ComparablePeriod}}</div>
                    <div
                        [fxFlex]="25"
                        class="dotedRight"
                    >
                        {{data.MemberCountTotal | roundToDecimalPlace: 0 | numberFormat}} </div>
                    <div
                        [fxFlex]="25"
                        class="dotedRight"
                    >
                        {{data.AverageAge | roundToDecimalPlace: 1 | numberFormat}}</div>
                    <div
                        [fxFlex]="25"
                        class="dotedheight"
                    >{{data.AVerageFamilySize | roundToDecimalPlace: 1 |
                        numberFormat}}</div>
                </div>
            </div>
            <div [fxFlex]="50">
                <ng-container
                    id="enAgeChartStack"
                    *ngIf="parentLoaded"
                >
                    <app-bar-stacked
                        style="height:100px;"
                        [stackBarList]="stackBarList"
                    ></app-bar-stacked>
                </ng-container>
            </div>
        </div>
        <div
            id="enAgeTableHeading"
            class="genderHead"
        >
            {{properties.dimension == GenderRelOption.Gender ? ('ageBand.Gender' | translate) : ('ageBand.Relationship'
            | translate)}} {{'ageBand.Split' | translate}} {{properties.measure == MeasureOption.Number ? '(#)':'(%)'}}
        </div>
        <div
            fxLayout="row"
            class="gridpadding"
            style="margin-top: 5px"
        >
            <div
                id="enAgeTable"
                [fxFlex]="100"
            >
                <div
                    id="enAgeTableHeader"
                    fxLayout="row"
                    class="tableheader dashed"
                    style="border-top: 1px solid black;"
                >
                    <div [fxFlex]="5"></div>
                    <div [fxFlex]="60"></div>
                    <div [fxFlex]="15">{{'ageBand.MemberCount' | translate}}</div>
                    <div [fxFlex]="10">{{'ageBand.PctOfTotalClient' | translate}}</div>
                    <div [fxFlex]="10">{{'ageBand.EnrollmentTrend' | translate}}</div>
                </div>
                <div
                    fxLayout="row"
                    class="tablerow dashed padNoneTableRow"
                    *ngFor="let data of enrollmentAgeBandSplitList"
                >
                    <div
                        [fxFlex]="5"
                        class="claimPad"
                    >{{data.ageBand}}</div>
                    <div [fxFlex]="60">
                        <ng-container *ngIf="parentLoaded">
                            <app-bar-negative-stack
                                style="display:flex;margin-left:45px;padding-top:3px;"
                                [barColor]="barColorGray"
                                [MeasureOption]="properties.measure == MeasureOption.Number ? MeasureOption.Number : MeasureOption.Distribution"
                                [value]="data.leftValue"
                                [value2]="data.rightValue"
                                [maxLeftValue]="maxLeftValue"
                                [maxRightValue]="maxRightValue"
                                [percentLeft]="data.leftPercentage"
                                [percentRight]="data.rightPercentage"
                                [isBenchmarkShown]="eligibleForBenchmark && properties.benchmarkLevel != 3"
                                [benchmarkLeftValue]="data.leftBenchmarkValue"
                                [benchmarkRightValue]="data.rightBenchmarkValue"
                                [maxLeftPercentage]="maxLeftPercentage"
                                [maxRightPercentage]="maxRightPercentage"
                            ></app-bar-negative-stack>
                        </ng-container>
                    </div>
                    <div
                        [fxFlex]="15"
                        class="claimPad"
                    >{{data.memberCountTotal | roundToDecimalPlace: 0 |
                        numberFormat}}</div>
                    <div
                        [fxFlex]="10"
                        class="claimPad"
                    >{{data.totalClientPercentage | roundToDecimalPlace: 1 |
                        numberFormat}}% </div>
                    <div [fxFlex]="10">{{data.enrollmentTrendPercentage | roundToDecimalPlace: 1 | numberFormat}}%</div>

                </div>
                <div
                    fxLayout="row"
                    style="margin-top: 10px"
                ></div>
            </div>
        </div>
        <div
            id="enAgeTxtFooter"
            fxLayout="row"
            style="margin-top: 5px;"
        >
            <div [fxFlex]="27"></div>
            <div
                id="enAgeTxtLeft"
                [fxFlex]="12"
                fxLayoutAlign="center start"
            >
                {{properties.dimension == GenderRelOption.Gender ? ('ageBand.Female' | translate): ('ageBand.Employee' |
                translate)}}
            </div>
            <div
                id="enAgeTxtRight"
                [fxFlex]="12"
                fxLayoutAlign="center start"
            >
                {{properties.dimension == GenderRelOption.Gender ? ('ageBand.Male' | translate): ('ageBand.Dependent' |
                translate)}}
            </div>
            <div
                *ngIf="properties.dimension == GenderRelOption.Gender"
                id="enAgeTxtOthers"
                [fxFlex]="12"
                fxLayoutAlign="center start"
            >
                {{'ageBand.Others' | translate}}
            </div>
        </div>
        <div
            id="enAgeValFooter"
            fxLayout="row"
            style="margin: 5px 0 20px;"
        >
            <div
                id="enAgeSymbol"
                [fxFlex]="27"
                style="text-align: right;"
            >
                {{properties.measure == MeasureOption.Number ? '# ':'% '}}{{'ageBand.PctOfPopulation' | translate}}
            </div>
            <div
                id="enAgeValLeft"
                [fxFlex]="12"
                fxLayoutAlign="center start"
            >
                {{properties.measure == MeasureOption.Number ? (enrollmentAgeBandSplitLeftTotalValue |
                roundToDecimalPlace: 0 | numberFormat) : (enrollmentAgeBandSplitLeftTotalPercentage |
                roundToDecimalPlace: 1 | numberFormat)}} {{properties.measure == MeasureOption.Number
                ? '':'%'}}
            </div>
            <div
                id="enAgevalRight"
                [fxFlex]="12"
                fxLayoutAlign="center start"
            >
                {{properties.measure == MeasureOption.Number ? (enrollmentAgeBandSplitRightTotalValue |
                roundToDecimalPlace: 0 | numberFormat): (enrollmentAgeBandSplitRightTotalPercentage |
                roundToDecimalPlace: 1 | numberFormat)}} {{properties.measure == MeasureOption.Number
                ? '':'%'}}
            </div>
            <div
                *ngIf="properties.dimension == GenderRelOption.Gender"
                id="enAgeValOthers"
                [fxFlex]="12"
                fxLayoutAlign="center start"
            >
                {{properties.measure == MeasureOption.Number ? (enrollmentAgeBandSplitOthersTotalValue |
                roundToDecimalPlace: 0 | numberFormat) : (enrollmentAgeBandSplitOthersTotalPercentage |
                roundToDecimalPlace: 1 | numberFormat)}} {{properties.measure == MeasureOption.Number
                ? '':'%'}}
            </div>
        </div>
    </div>
    <div
        style="width: 200px; padding: 40px 0px 0px 16px; border-left: 1px solid #EEE"
        fxLayout="column"
    >
        <div
            id="entityDivisionFilters"
            *ngIf="showFilter"
        >
            <app-additional-filters
                [country]="properties.country"
                (action)="additionalFilterSelectionChanged($event)"
                [filterOptions]='filterOptions'
                [isClientName]='true'
                [isEntity]='true'
                [isPolicyType]='true'
                [isPlan]='true'
                [isProviderType]='true'
                [isGender]='true'
                [isRelationship]='true'
                [isAgeBand]='true'
                [isLocation]='true'
                [isLOB]='true'
                [isSubLOB]='true'
            ></app-additional-filters>
        </div>
        <div
            id="enAgeFilters"
            fxFlex="55"
            *ngIf="!showFilter"
        >
            <div id="enAgeLegend">
                <div
                    fxLayout="row"
                    *ngFor="let leg of legendList"
                    style="margin-bottom: 5px;"
                >
                    <svg width="15"  height="15">
                        <rect width="15"
                             height="15"
                            [attr.fill]="leg.color"
                        ></rect>
                    </svg>
                    <span class="legendElp">{{(leg.name)}}</span> <br>
                </div>
            </div>
            <div
                id="enAgeTxtDimensions"
                fxLayout="row"
                style="margin-top:40px; margin-bottom: 5px;"
            >
                <span>{{'ageBand.Dimensions' | translate}} </span>
            </div>
            <div
                id="enAgeBtnGender"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.dimension == GenderRelOption.Gender}"
                (click)="selectionChanged(GenderRelOption.Gender, 'dimension')"
            >
                <span>{{'ageBand.Gender' | translate}} </span>
            </div>
            <div
                id="enAgeBtnRelationship"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.dimension == GenderRelOption.Relationship}"
                (click)="selectionChanged(GenderRelOption.Relationship, 'dimension')"
            >
                <span>{{'ageBand.Relationship' | translate}}</span>
            </div>
            <div
                id="enAgeBtnMeasure"
                fxLayout="row"
                style="margin-top:15px; margin-bottom: 5px;"
            >
                <span>{{'ageBand.Measure' | translate}} </span>
            </div>
            <div
                id="enAgeBtnNumber"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.measure == MeasureOption.Number}"
                (click)="filterChanged(MeasureOption.Number, 'measure')"
            >
                <span>{{'ageBand.Number' | translate}} </span>
            </div>
            <div
                id="enAgeBtnPctDistribution"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.measure == MeasureOption.Distribution}"
                (click)="filterChanged(MeasureOption.Distribution, 'measure')"
            >
                <span>{{'ageBand.PctDistribution' | translate}} </span>
            </div>

            <ng-container *ngIf="eligibleForBenchmark">
                <div
                    id="enAgeBenchmark"
                    *ngIf="properties.measure == MeasureOption.Distribution"
                >
                    <div
                        id="enAgeBtnBenchmark"
                        fxLayout="row"
                    >
                        <span>{{ 'ageBand.Benchmark' | translate }}
                            <button
                                id="enAgeBtnBenchIcon"
                                mat-icon-button
                                (click)="showBenchmark = !showBenchmark"
                            >
                                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                            </button></span>
                    </div>
                    <div *ngIf="showBenchmark">
                        <div
                            id="enAgeBtnIncludeClient"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            (click)="selectionChanged(1, 'benchmarkInclude')"
                            class="dash-option"
                            [ngClass]="{'dash-option-selected': properties.benchmarkInclude == 1 }"
                        >
                            <span>{{ 'ageBand.IncludeClient' | translate }}</span>
                        </div>
                        <div
                            id="enAgeBtnExcludeClient"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            (click)="selectionChanged(2, 'benchmarkInclude')"
                            class="dash-option"
                            [ngClass]="{'dash-option-selected': properties.benchmarkInclude == 2 }"
                        >
                            <span>{{ 'ageBand.ExcludeClient' | translate }}</span>
                        </div>
                        <div
                            id="enAgeBtnPortfolio"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            style="margin-top:10px; "
                            (click)="selectionChanged(1, 'benchmarkLevel')"
                            class="dash-option"
                            [ngClass]="{'dash-option-selected': properties.benchmarkLevel == 1 }"
                        >
                            <span> {{ 'ageBand.Portfolio' | translate }}</span>
                        </div>
                        <div
                            id="enAgeBtnIndustry"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            (click)="selectionChanged(2, 'benchmarkLevel')"
                            class="dash-option"
                            [ngClass]="{'dash-option-selected': properties.benchmarkLevel == 2 }"
                        >
                            <span>{{ 'ageBand.Industry' | translate }}</span>
                        </div>
                        <div
                            id="enAgeBtnNone"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            (click)="selectionChanged(3, 'benchmarkLevel')"
                            class="dash-option"
                            [ngClass]="{'dash-option-selected': properties.benchmarkLevel == 3 }"
                        >
                            <span>{{ 'ageBand.None' | translate }}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>