export const locale = {
    lang: 'es-cl',
    data: {
          'ClaimCostTopProvider': {
            'ClaimTopProviders' : 'Monto Reembolsado - Top Prestadores',            
            'ClaimCostByTopDiagnoses':'Gasto - Por Top Detalle Prestaciones',
            'ProviderDiag' : 'Prestadores por detalle de prestación',
            'AllAmountsDisplayedInLocalCurrency' : 'Todas las cantidades mostradas en moneda local',
            'TopProvider' : 'Prestadores principales',
            'ProviderName': 'Nombre Prestador', 
            'TopDiagnoses': 'Top Detalle Prestaciones',
            'DiagnosisName': 'Detalle de la Prestación' ,          
            'TotalPaidClaim' : 'Total Monto Reembolsado', 
            'ClaimantCount': 'Cantidad de asegurados',
            'ClaimCount': 'Cantidad de prestaciones',            
            'ClaimsPerClaimant' : 'Monto reembolsado por asegurado', 
            'CostPerClaim': 'Reembolso por prestación',
            'CostPerClaimant': 'Reembolso por asegurado', 
            '%ChangeinCost':'% De cambio en el costo',
            'Claim' : 'Reclamo', 
            'Claimant' : 'Reclamante', 
            'ClaimsTotal':'Monto Reembolsado Total',
            'Count': 'Contar',
            'AverageCost': 'Costo Promedio',
            'ClaimsReportedByPaidDate': 'Reembolsos reportados por fecha de pago',
            'ClaimsReportedByIncurredDate': 'Reembolsos reportadas por fecha incurrida',
            'Benchmark':'Benchmark',
            'IncludeClient': 'Incluir Cliente',
            'ExcludeClient': 'Excluir Cliente',
            'Portfolio': 'Portafolio',
            'Industry': 'Industria',
            'None': 'No',
            'SelectTop': 'Seleccionar Top',
            'FilterNA': 'Excluir NA',
            'CountBasis': 'Contar Base',
            'Sort': 'Ordenar',
            'Reset':'Reiniciar',            
            'DiagnosisLevel':'Nivel de Diagnóstico',
            'Category':'Categoría',
            'Chapter':'Capítulo',
            'ShownAsPer':'Se muestra según',
            'DateRange':'to'
            
        }
    }
};
