export const locale = {
    lang: 'it',
    data: {
  'ClaimType': {
    'ClaimHeading': 'Tipo sinistro',
    'AllAmountsDisplayedInLocalCurrency': 'Tutti gli importi visualizzati in valuta locale',
    'ClaimType': 'Sinistri oer assistito attivo',
    'TotalPaidClaim': 'Sinistri totali pagati',
    'ClaimantCount': 'Numero teste colpite',
    'ClaimCount': 'Conteggio Sinistri',
    'ClaimsPerClaimant': 'Sinistri per assistito attivo',
    'ClaimsPerHeadcount': 'Sinistri per numero di teste',
    'CostPerClaim': 'Costo per sinistro',
    'CostPerClaimant': 'Costo per assistito attivo',
    'CostPerHeadcount': 'Costo per testa',
    'ChangeInCost': '% di variazione del costo',
    'Claim': 'Sinistri',
    'Claimant': 'Testa colpita',
    'Headcount': 'Numero assistiti',
    'ClaimsTotal': 'Sinistri totali',
    'Count': 'Conteggio',
    'ClaimsReportedByPaidDate': 'Sinistri per data di pagamento',
    'ClaimsReportedByIncurredDate': 'Sinistri per data di accadimento',
    'CountBasis': 'Base del conteggio',
    'Sort': 'Ordina',
    'Show': 'Mostrare',
    'Hide': 'Nascondere',
    'ShowHide': 'Mostra/nascondi',
    'Average': 'Media',
    'PeriodAverage': 'Periodo medio',
    'PlanYear': 'Anno del piano'
  }
}};