export const locale = {
    lang: 'es-co',
    data: {
      'DoubleQuad': {
          'DoubleQuadHeading':'Prestador - Análisis de Doble Cuadrante',           
          'Providers':'Prestadores', 
          'Diagnoses':'Diagnósticos' ,
          'AllAmountsDisplayedInLocalCurrency' : 'Todas las cantidades mostradas en moneda local',
                    'ClaimantCount': 'Número de Reclamantes',
                  'ClaimCount': 'Número de Reclamos',             
                  'CostPerClaim': 'Costo Por Reclamo',
                  'CostPerClaimant': 'Costo Por Reclamante',  
                  'Claim' : 'Reclamo', 
                  'Claimant' : 'Reclamante', 
                  'ClaimsTotal':'Total Reclamos',
          'Count': 'Contar',
          'ClaimsReportedByPaidDate': 'Reclamaciones reportadas por fecha de pago',
          'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
          'SelectTop': 'Seleccionar Top',
              'FilterNA': 'Excluir NA',
          'CountBasis': 'Contar Base',
              'Sort': 'Ordenar',
              'Reset':'Reiniciar',
          'ShownAsPer':'Se muestra según',
          'DateRange': 'to'
          
      }
  }
};
