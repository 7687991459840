export class FilterOptions {
    clientNames: any[];
    entities: any[];
    divisions: any[];
    plans: any[];
    timePeriods: any[];
    policyTypes: any[];
    ageBands: any[];
    genders: any[];
    claimTypes: any[];
    claimTypes2: any[];
    claimTypes3: any[];
    relationships: any[];
    diagCats: any[];
    diagChapters: any[];
    locations: any[];
    chronics: any[];
    carriers: any[];
    lobs: any[];
    subLobs: any[];
    networks: any[];
    providerNames: any[];
    providerLocations: any[];
    providerTypes: any[];
}
