<div id="highSpoc" class="page-layout blank" style="padding: 8px">
    <div
        id="svg-spinner-container"
        *ngIf="isLoading"
        style="
            background: rgba(255, 255, 255, 0.6);
            position: absolute;
            top: 280px;
            left: 48%;
            z-index: 90;
        "
    >
        <svg id="svg-spinner" viewBox="0 0 50 50">
            <style>
                #tail {
                    fill: url(#fade);
                }

                #head {
                    fill: #616570;
                }

                stop {
                    stop-color: #616570;
                }
            </style>
            <linearGradient
                id="fade"
                x2="50"
                y1="25"
                y2="25"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stop-opacity="0"></stop>
                <stop offset=".15" stop-opacity=".04"></stop>
                <stop offset=".3" stop-opacity=".16"></stop>
                <stop offset=".45" stop-opacity=".36"></stop>
                <stop offset=".61" stop-opacity=".64"></stop>
                <stop offset=".76"></stop>
            </linearGradient>
            <path
                id="head"
                d="M0 25a25 25 0 1 0 50 0h-3.9a21.1 21.1 0 1 1-42.2 0"
            ></path>
            <path
                id="tail"
                d="M50 25a25 25 0 0 0-50 0h3.9a21.1 21.1 0 1 1 42.2 0"
            ></path>
        </svg>
    </div>

    <mat-card>
        <mat-card-content
            id="highSpocMat"
            style="width: 1500px; height: 800px; margin: 0 auto"
            fxLayout="row"
        >
            <div id="highSpocLeftDash" fxFlex fxLayout="column">
                <div
                    id="highSpocHeadTxt"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    style="
                        height: 50px;
                        color: white;
                        font-weight: bold;
                        font-size: 36px;
                        background-color: #101e7f;
                    "
                >
                    <span>Health Profile</span>
                </div>
                <div id="highSpocHeaders" fxLayout="row" fxFlex="10">
                    <div fxFlex="25"></div>
                    <div fxFlex class="colheader" fxLayoutAlign="center center">
                        <img
                            src="/assets/aonicons/Edit-M.png"
                            style="height: 44px"
                        /><span>Paid Claim</span>
                    </div>
                    <div fxFlex class="colheader" fxLayoutAlign="center center">
                        <img
                            src="/assets/aonicons/Finance-M.png"
                            style="height: 60px"
                        /><span>Average Cost</span>
                    </div>
                    <div fxFlex class="colheader" fxLayoutAlign="center center">
                        <img
                            src="/assets/aonicons/Team-M.png"
                            style="height: 44px"
                        /><span>Claimant Count</span>
                    </div>
                    <div fxFlex class="colheader" fxLayoutAlign="center center">
                        <img
                            src="/assets/aonicons/Global-M.png"
                            style="height: 44px"
                        /><span>Prevalence</span>
                    </div>
                </div>
                <div
                    fxFlex
                    fxLayout="row"
                    *ngFor="let cat of categories"
                    style="border-top: 1px solid #ccc"
                >
                    <div
                        fxFlex="25"
                        class="colheader"
                        fxLayout="column"
                        fxLayoutAlign="center center"
                    >
                        <span>{{ cat.name }}</span>
                    </div>
                    <div fxFlex fxLayout="column" fxLayoutAlign="center center">
                        <charts-bar-solo
                            [value]="cat.current.paidClaim"
                            [max]="categories[0].current.paidClaim * 6"
                            [barColor]="'#101E7F'"
                        ></charts-bar-solo>
                        <br />
                        <charts-bar-solo
                            [value]="cat.prior.paidClaim"
                            [max]="categories[0].current.paidClaim * 6"
                            [barColor]="'#29B0C3'"
                        ></charts-bar-solo>
                    </div>
                    <div fxFlex fxLayout="column" fxLayoutAlign="center center">
                        <div
                            style="
                                font-weight: bold;
                                font-size: 32px;
                                color: #101e7f;
                            "
                        >
                            {{
                                cat.current.averageCost.toLocaleString(
                                    undefined,
                                    { maximumFractionDigits: 2 }
                                )
                            }}
                        </div>
                        <br />
                        <div
                            style="
                                font-weight: bold;
                                font-size: 32px;
                                color: #29b0c3;
                            "
                        >
                            {{
                                cat.prior.averageCost.toLocaleString(
                                    undefined,
                                    { maximumFractionDigits: 2 }
                                )
                            }}
                        </div>
                    </div>
                    <div fxFlex fxLayout="column" fxLayoutAlign="center center">
                        <div
                            style="
                                font-weight: bold;
                                font-size: 32px;
                                color: #101e7f;
                            "
                        >
                            {{
                                cat.current.claimantCount.toLocaleString(
                                    undefined,
                                    { maximumFractionDigits: 2 }
                                )
                            }}
                        </div>
                        <br />
                        <div
                            style="
                                font-weight: bold;
                                font-size: 32px;
                                color: #29b0c3;
                            "
                        >
                            {{
                                cat.prior.claimantCount.toLocaleString(
                                    undefined,
                                    { maximumFractionDigits: 2 }
                                )
                            }}
                        </div>
                    </div>
                    <div fxFlex fxLayout="column" fxLayoutAlign="center center">
                        <div fxLayout="row">
                            <app-pie
                                style="max-height: 190px"
                                [percentage]="
                                    (cat.current.prevalence * 100).toFixed(0)
                                "
                            >
                            </app-pie>
                            <div
                                fxLayout="column"
                                fxLayoutAlign="end end"
                                style="padding: 8px; text-align: center"
                            >
                                <span
                                    style="
                                        font-size: 18px;
                                        font-weight: bold;
                                        color: #29b0c3;
                                    "
                                    >{{
                                        (
                                            cat.prior.prevalence * 100
                                        ).toLocaleString(undefined, {
                                            maximumFractionDigits: 0
                                        })
                                    }}%</span
                                >
                            </div>
                            <div style="padding: 8px; text-align: center">
                                <span style="font-size: 10px">Benchmark</span
                                ><br /><span style="font-size: 18px"
                                    >{{
                                        (cat.benchmark * 100).toLocaleString(
                                            undefined,
                                            { maximumFractionDigits: 0 }
                                        )
                                    }}%</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div id="highSpocLegend" fxLayout="row">
                    <div fxFlex="10"></div>
                    <div class="legend">
                        <svg width="15" height="15">
                            <rect width="15" height="15" fill="#101E7F"></rect>
                        </svg>
                        Current <br />
                        <svg width="15" height="15">
                            <rect width="15" height="15" fill="#29B0C3"></rect>
                        </svg>
                        Prior
                    </div>
                </div>
            </div>
            <div
                id="highSpocFilters"
                style="
                    width: 200px;
                    padding: 80px 0px 0px 16px;
                    border-left: 1px solid #eee;
                "
                fxLayout="column"
            >
                <mat-form-field style="width: 100%">
                    <mat-label>Entity</mat-label>
                    <mat-select>
                        <mat-option value="IBM1"> IBM 1 </mat-option>
                        <mat-option value="IBM1"> IBM 2 </mat-option>
                    </mat-select> </mat-form-field
                ><br />
                <mat-form-field>
                    <mat-label>Division</mat-label>
                    <mat-select>
                        <mat-option value="IBM1"> IBM 1 </mat-option>
                        <mat-option value="IBM1"> IBM 2 </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-card-content>
    </mat-card>
    <a id="highSpocLink1" routerLink="/story/hc/Philippines/IBM">IBM</a>
    &nbsp;&nbsp;&nbsp;
    <a
        id="highSpocLink2"
        routerLink="/story/hc/Philippines/FMC PHILIPPINES, INC."
        >FMC PHILIPPINES, INC.</a
    >
    &nbsp;&nbsp;&nbsp;
    <a id="highSpocLink3" routerLink="/story/hc/Philippines/Amazon">Amazon</a>
</div>
