import { Injectable } from '@angular/core';
import { User } from 'app/models/user.model';
import { GlobalFiltersModel } from 'app/models/globalfilters.model';
import { LangChangeEvent } from '@ngx-translate/core';
import { LibraryService } from './library.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class GlobalfiltersService {

    PARAM_LANG = 'Param_Translation';
    PARAM_CLIENT = 'Param_Client';
    PARAM_COUNTRY = 'Param_Country';
    PARAM_ROLLINGWINDOW = 'Param_RollingWindow';
    PARAM_CURRENTFLAG = 'Param_CurrentFlag';
    PARAM_CLAIMPAID = 'Param_ClaimPaid';

    PARAM_MAXDATE = 'Param_MaxDate';
    PARAM_MAXCLAIMPAIDDATE = 'Param_MaxClaimPaidDate';
    PARAM_MAXADMITDATE = 'Param_MaxAdmitDate';
    PARAM_PERIOD1STARTDATE = 'Param_Period1StartDate';
    PARAM_PERIOD2STARTDATE = 'Param_Period2StartDate';
    PARAM_PERIOD3STARTDATE = 'Param_Period3StartDate';
    PARAM_PERIOD4STARTDATE = 'Param_Period4StartDate';

    constructor(private _libraryService: LibraryService) {

    }

    public getGlobalFilters(user: User): GlobalFiltersModel {
        const globalFilters = new GlobalFiltersModel();

        globalFilters.clientName = this.getClientName(user);
        globalFilters.clientCountry = this.getClientCountry(user);
        globalFilters.language = this.getLanguage(user);
        globalFilters.rollingPeriod = user.rollingPeriod;
        globalFilters.periodSelection = user.periodSelection;
        globalFilters.claimPaid = user.claimPaid;
        globalFilters.tbsUserId = user.tbsUserId;

        if (user.timePeriod) {
            globalFilters.period1StartDate = user.timePeriod.period1StartDate !== null ? moment(new Date(user.timePeriod.period1StartDate)).format('YYYY/MM/DD') : null;
            globalFilters.period2StartDate = user.timePeriod.period2StartDate !== null ? moment(new Date(user.timePeriod.period2StartDate)).format('YYYY/MM/DD') : null;
            globalFilters.period3StartDate = user.timePeriod.period3StartDate !== null ? moment(new Date(user.timePeriod.period3StartDate)).format('YYYY/MM/DD') : null;
            globalFilters.period4StartDate = user.timePeriod.period4StartDate !== null ? moment(new Date(user.timePeriod.period4StartDate)).format('YYYY/MM/DD') : null;
            globalFilters.maxDate = user.timePeriod.maxDate !== null ? moment(new Date(user.timePeriod.maxDate)).format('YYYY/MM/DD') : null;
            globalFilters.maxClaimPaidDate = user.timePeriod.maxClaimPaidDate !== null ? moment(new Date(user.timePeriod.maxClaimPaidDate)).format('YYYY/MM/DD') : null;
            globalFilters.maxAdmitDate = user.timePeriod.maxAdmitDate !== null ? moment(new Date(user.timePeriod.maxAdmitDate)).format('YYYY/MM/DD') : null;
        }

        globalFilters.tbsClientId = this.getTbsClientId(user);



        return globalFilters;
    }

    public getGlobalFiltersString(user: User, workbookId: string, isForTableauAPI: boolean): string {
        const globalFilterArray = [];
        const globalFilters = this.getGlobalFilters(user);

        const libraryDictionary = this._libraryService.getLibraryDictionaryInSession();

        if (typeof libraryDictionary[workbookId] === 'undefined') {
            return '';
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.ClientParam] !== 'undefined' && globalFilters.clientName) {
            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.ClientParam].paramNameUser) + '=' + globalFilters.clientName);
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.Country] !== 'undefined' && globalFilters.clientCountry) {
            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.Country].paramNameUser) + '=' + globalFilters.clientCountry);
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.Translation] !== 'undefined' && globalFilters.language) {
            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.Translation].paramNameUser) + '=' + globalFilters.language);
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.RollingWindow] !== 'undefined' && globalFilters.rollingPeriod) {
            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.RollingWindow].paramNameUser) + '=' + globalFilters.rollingPeriod);
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.CurrentFlag] !== 'undefined' && globalFilters.periodSelection) {
            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.CurrentFlag].paramNameUser) + '=' + globalFilters.periodSelection);
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.ClaimPaid] !== 'undefined') {
            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.ClaimPaid].paramNameUser) + '=' + (globalFilters.claimPaid ? 'True' : 'False'));
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.MaxDate] !== 'undefined') {
            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.MaxDate].paramNameUser) + '=' + globalFilters.maxDate);
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.Period1StartDate] !== 'undefined') {
            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.Period1StartDate].paramNameUser) + '=' + globalFilters.period1StartDate);
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.Period2StartDate] !== 'undefined') {
            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.Period2StartDate].paramNameUser) + '=' + globalFilters.period2StartDate);
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.Period3StartDate] !== 'undefined') {
            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.Period3StartDate].paramNameUser) + '=' + globalFilters.period3StartDate);
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.Period4StartDate] !== 'undefined') {
            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.Period4StartDate].paramNameUser) + '=' + globalFilters.period4StartDate);
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.MaxClaimPaidDate] !== 'undefined') {
            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.MaxClaimPaidDate].paramNameUser) + '=' + globalFilters.maxClaimPaidDate);
        }
        if (typeof libraryDictionary[workbookId][GlobalFilterName.MaxAdmitDate] !== 'undefined') {
            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.MaxAdmitDate].paramNameUser) + '=' + globalFilters.maxAdmitDate);
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.ClientFilter] !== 'undefined' && globalFilters.clientName) {
            const clientNameListString = globalFilters.clientName.split('|').join(',');

            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.ClientFilter].paramNameUser) + '=' + clientNameListString);
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.TbsUserId] !== 'undefined' && globalFilters.tbsUserId) {
            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.TbsUserId].paramNameUser) + '=' + globalFilters.tbsUserId);
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.TbsClientId] !== 'undefined' && globalFilters.tbsClientId) {
            globalFilterArray.push((isForTableauAPI ? 'vf_' : '') + encodeURI(libraryDictionary[workbookId][GlobalFilterName.TbsClientId].paramNameUser) + '=' + globalFilters.tbsClientId);
        }

        return globalFilterArray.join('&');
    }

    public getGlobalFiltersObject(user: User, workbookId: string, isForTableauAPI: boolean): any {
        const globalFilterObject = {};
        const globalFilters = this.getGlobalFilters(user);

        const libraryDictionary = this._libraryService.getLibraryDictionaryInSession();

        if (typeof libraryDictionary[workbookId] === 'undefined') {
            return {};
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.ClientParam] !== 'undefined' && globalFilters.clientName) {
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.ClientParam].paramNameUser] = decodeURI(globalFilters.clientName);
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.Country] !== 'undefined' && globalFilters.clientCountry) {
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.Country].paramNameUser] = globalFilters.clientCountry;
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.Translation] !== 'undefined' && globalFilters.language) {
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.Translation].paramNameUser] = globalFilters.language;
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.RollingWindow] !== 'undefined' && globalFilters.rollingPeriod) {
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.RollingWindow].paramNameUser] = globalFilters.rollingPeriod;
        }

        /*
        if (typeof libraryDictionary[workbookId][GlobalFilterName.CurrentFlag] !== 'undefined' && globalFilters.periodSelection) {
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.CurrentFlag].paramNameUser] = globalFilters.periodSelection;
        }*/

        if (typeof libraryDictionary[workbookId][GlobalFilterName.ClaimPaid] !== 'undefined') {
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.ClaimPaid].paramNameUser] = (globalFilters.claimPaid ? 'True' : 'False');
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.MaxDate] !== 'undefined') {
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.MaxDate].paramNameUser] = globalFilters.maxDate;
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.Period1StartDate] !== 'undefined') {
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.Period1StartDate].paramNameUser] = globalFilters.period1StartDate;
        }
        if (typeof libraryDictionary[workbookId][GlobalFilterName.Period2StartDate] !== 'undefined') {
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.Period2StartDate].paramNameUser] = globalFilters.period2StartDate;
        }
        if (typeof libraryDictionary[workbookId][GlobalFilterName.Period3StartDate] !== 'undefined') {
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.Period3StartDate].paramNameUser] = globalFilters.period3StartDate;
        }
        if (typeof libraryDictionary[workbookId][GlobalFilterName.Period4StartDate] !== 'undefined') {
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.Period4StartDate].paramNameUser] = globalFilters.period4StartDate;
        }
        if (typeof libraryDictionary[workbookId][GlobalFilterName.MaxClaimPaidDate] !== 'undefined') {
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.MaxClaimPaidDate].paramNameUser] = globalFilters.maxClaimPaidDate;
        }
        if (typeof libraryDictionary[workbookId][GlobalFilterName.MaxAdmitDate] !== 'undefined') {
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.MaxAdmitDate].paramNameUser] = globalFilters.maxAdmitDate;
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.ClientFilter] !== 'undefined' && globalFilters.clientName) {
            const clientNameListString = globalFilters.clientName.split('|').join(',');
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.ClientFilter].paramNameUser] = clientNameListString;
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.TbsUserId] !== 'undefined' && globalFilters.tbsUserId) {
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.TbsUserId].paramNameUser] = globalFilters.tbsUserId;
        }

        if (typeof libraryDictionary[workbookId][GlobalFilterName.TbsClientId] !== 'undefined' && globalFilters.tbsClientId) {
            globalFilterObject[(isForTableauAPI ? 'vf_' : '') + libraryDictionary[workbookId][GlobalFilterName.TbsClientId].paramNameUser] = globalFilters.tbsClientId;
        }

        return globalFilterObject;
    }

    getLanguageParameter(event: LangChangeEvent) {
        return {
            name: this.PARAM_LANG,
            value: this.getLanguageByLangCode(event.lang)
        }
    }

    private getClientName(user: User) {
        if (typeof user.client === 'undefined') {
            return '';
        } else if (user.client === null) {
            return '';
        } else {
            return this.getEncodedString(user.client.groupFilterString);
        }
    }

    private getTbsClientId(user: User) {
        if (typeof user.client === 'undefined') {
            return '';
        } else if (user.client === null) {
            return '';
        } else {
            return this.getEncodedString(user.client.tbsClientId);
        }
    }

    private getClientCountry(user: User) {
        if (typeof user.country === 'undefined') {
            return '';
        }
        else if (user.country === null) {
            return '';
        }
        else {
            return this.getEncodedString(user.country.name);
        }
    }

    /*
    private getCurrentStartDate(user: User) {
        if (typeof user.country === 'undefined') {
            return '';
        }
        else if (user.country === null) {
            return '';
        }
        else {
            return this.getEncodedString(user.timePeriod.currentStartDate);
        }
    }*/

    private getLanguage(user: User) {
        switch (user.language) {
            case 'en':
                return TableauLanguageMappings.en;
            default:
                return user.language;
        }

        return '';
    }

    getLanguageByLangCode(lang) {
        switch (lang) {
            case 'en':
                return TableauLanguageMappings.en;
            default:
                return lang;
        }
    }

    getEncodedString(string) {
        string = string.replace(/&/gi, '%26').replace(/ /gi, '%20').replace(/,/gi, '%5C%2C').split('.').join('%5C%2E');
        return string;
    }
}

export class TableauLanguageMappings {
    public static en = 'en-us';
}

export enum GlobalFilterName {
    ClientParam = 'Client (Param)',
    ClientFilter = 'Client (Filter)',
    Country = 'Country',
    Translation = 'Translation',
    RollingWindow = 'Rolling Window',
    CurrentFlag = 'Current Flag',
    ClaimPaid = 'Claim Paid',
    MaxDate = 'Max Date',
    MaxClaimPaidDate = 'Max Claim Paid Date',
    MaxAdmitDate = 'Max Admit Date',
    Period1StartDate = 'Period 1 Start Date',
    Period2StartDate = 'Period 2 Start Date',
    Period3StartDate = 'Period 3 Start Date',
    Period4StartDate = 'Period 4 Start Date',
    TbsUserId = 'TBS User Id',
    TbsClientId = 'TBS Client Id'
}
