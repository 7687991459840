import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { RouterModule } from "@angular/router";
import { UseradminComponent } from "./useradmin/useradmin.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SuperadminComponent } from "./superadmin/superadmin.component";
import { StoriesadminComponent } from "./storiesadmin/storiesadmin.component";
import { SearchBarComponent } from "../shared/search-bar/search-bar.component";

import { JwtInterceptor } from "../_helpers/jwt.interceptor";

import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "app/shared/shared.module";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { RouteAuthGuardGuard } from "app/_guards/route-auth-guard.guard";
import { Roles as roles } from "app/models/roles.model";
import { TableauprojectmgmtComponent } from "./tableauprojectmgmt/tableauprojectmgmt.component";
import { ClientgroupadminComponent } from "./clientgroupadmin/clientgroupadmin.component";
import { LibraryadminComponent } from "./libraryadmin/libraryadmin.component";
import { LibraryaddadminComponent } from "./libraryadmin/libraryaddadmin/libraryaddadmin.component";

import { SiteadminComponent } from './superadmin/siteadmin/siteadmin.component';
import { BenchmarkLimitAdminComponent } from './superadmin/benchmark-limit-admin/benchmark-limit-admin.component';
import { CostProjectionAdminComponent } from './cost-projection-admin/cost-projection-admin.component';

const routes = [
    {
        path: "admin/user",
        component: UseradminComponent,
        canActivate: [RouteAuthGuardGuard],
        data: {
            roles: [roles.admin, roles.regionalManager, roles.countryManager],
        },
    },
    {
        path: "admin/stories",
        component: StoriesadminComponent,
        canActivate: [RouteAuthGuardGuard],
        data: {
            roles: [roles.admin, roles.regionalManager, roles.countryManager],
        },
    },
    {
        path: "admin/clientgroupadmin",
        component: ClientgroupadminComponent,
        canActivate: [RouteAuthGuardGuard],
        data: {
            roles: [roles.admin, roles.regionalManager, roles.countryManager],
        },
    },
    {
        path: "admin/cost-projection",
        component: CostProjectionAdminComponent,
        canActivate: [RouteAuthGuardGuard],
        data: {
            roles: [roles.admin, roles.regionalManager, roles.countryManager],
        },
    },
    {
        path: "admin/settings",
        component: SuperadminComponent,
        canActivate: [RouteAuthGuardGuard],
        data: { roles: [roles.admin, roles.regionalManager] },
    },
    {
        path: "admin/library",
        component: LibraryadminComponent,
        canActivate: [RouteAuthGuardGuard],
        data: { roles: [roles.admin, roles.regionalManager] },
    },
    {
        path: "admin/library/add",
        component: LibraryaddadminComponent,
        canActivate: [RouteAuthGuardGuard],
        data: { roles: [roles.admin, roles.regionalManager] },
    },
];

@NgModule({
    declarations: [
        UseradminComponent,
        SuperadminComponent,
        StoriesadminComponent,
        SearchBarComponent,
        TableauprojectmgmtComponent,
        ClientgroupadminComponent,
        LibraryadminComponent,
        LibraryaddadminComponent,
        SiteadminComponent,
        BenchmarkLimitAdminComponent,
        CostProjectionAdminComponent,
    ],
    imports: [
        SharedModule,
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        TranslateModule,
        MatSlideToggleModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [RouterModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    ],
})
export class AdminModule { }
