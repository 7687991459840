<div
    [style.height]="this.height"
    style="display: inline-block"
    style="width: 100%"
>
    <svg width="100%" height="100%">
        <linearGradient id="barsolo-grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style="stop-color: #ccc" />
            <stop offset="100%" style="stop-color: #eee" />
        </linearGradient>
        <g class="bars" height="80%">
            <rect
                fill="none"
                width="100%"
                height="80%"
                class="hover-black"
                y="10%"
            ></rect>
            <rect
                fill="url(#barsolo-grad1)"
                [attr.width]="
                    getSVGPercentage2() == 0 ? 0 : getSVGPercentage2()
                "
                height="80%"
                y="10%"
            ></rect>
            <rect
                [attr.fill]="barColor"
                [attr.width]="getSVGPercentage()"
                height="80%"
                y="10%"
            ></rect>
            <text
                *ngIf="getSVGPercentValueAbove10() && this.unit != '%'"
                fill="#DDD"
                y="50%"
                transform="translate(-2, 8)"
                font-size="frontSize"
                font-weight="bold"
            >
                <tspan [attr.x]="getSVGPercentage()" text-anchor="end">
                    {{ value2 == 0 ? 0 : ((value / value2) * 100).toFixed(0) }}%
                </tspan>
            </text>
            <text
                fill="#333"
                y="50%"
                transform="translate(5, 8)"
                font-size="frontSize"
                font-weight="bold"
                *ngIf="getSVGPercentValueAbove90()"
            >
                <tspan [attr.x]="getSVGPercentage()" text-anchor="start">
                    {{ value == 0 ? 0 : getValueFormatted() }}
                </tspan>
            </text>
            <text
                fill="#777"
                y="90%"
                transform="translate(-5,-5)"
                font-family="sans-serif"
                font-size="10px"
            >
                <tspan [attr.x]="getSVGPercentage2()" text-anchor="end">
                    {{ value2 == 0 ? 0 : getValue2Formatted() }}
                </tspan>
            </text>
        </g>
        <line
            *ngIf="benchmark > 0"
            [attr.x1]="getSVGPercentageBenchmark()"
            y1="-20%"
            [attr.x2]="getSVGPercentageBenchmark()"
            y2="120%"
            style="stroke: #f25d00; stroke-width: 3px"
        />
    </svg>
</div>
