export const locale = {
    lang: 'es-co',
    data: {
        'ClaimAgeBand': {
            'ClaimsByAgeBand' : 'Reclamos por Rango Etario',            
            'AllAmountsDisplayedInLocalCurrency' : 'Todas las cantidades mostradas en moneda local',
            'CountBasis':'Contar Base',
            'Claim' : 'Reclamo', 
            'Claimant' : 'Reclamante',  
            'AgeBandSplit'  :  'Distribución Rango Etario'  , 
            'None' : 'Ninguna',
            'Gender' : 'Género',
            'Relationship' : 'Parentesco',                
            'Sort' : 'Ordenar',
            'ClaimsTotal' : 'Total Reclamos',
            'Count' : 'Contar',            
            'AverageCost' :  'Costo Promedio',
            'DataTable' : 'Tabla de datos',
            'TopDiagnoses' : 'Top Diagnósticos',
            'TopProviders' : 'Top Prestadores',                     
            'TotalPaidClaim' : 'Costo Total Pagado', 
            'ClaimantCount':'Número de Reclamantes',
            'ClaimCount':'Número de Reclamos',                
            'ClaimsPMPM' : 'Reclamos PMPM', 
            'CostPerClaim': 'Costo Por Reclamo',
            'CostPerClaimant': 'Costo Por Reclamante',             
            'ClaimsReportedByPaidDate': 'Reclamaciones reportadas por fecha de pago',
            'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
            'FilterNA': 'Excluir NA',
            'Display':'Mostrar Como Tabla',
            'ShowLegend':'Mostrar leyenda',
            'ShownAsPer':'Se muestra según',
            'AgeBand': 'Rango Etario',
            'DateRange': 'to'
        }
}
};
