export const locale = {
    lang: 'es-ec',
    data: {
      'DoubleQuad': {
          'DoubleQuadHeading':'Prestador - Análisis de doble cuadrante',           
          'Providers':'Prestadores', 
          'Diagnoses':'Diagnósticos' ,
          'AllAmountsDisplayedInLocalCurrency' : 'Todas las cantidades mostradas en moneda local',
          'ClaimantCount': 'Parentesco de Demandantes',
          'ClaimCount': 'Número de Eventos',            
          'ClaimsPerClaimant' : 'Reclamos por Reclamante', 
          'CostPerClaim': 'Costo por reclamo',
          'Claim' : 'Reclamo', 
          'Claimant' : 'Reclamante', 
          'ClaimsTotal':'Total de Reclamaciones',
           'Count': 'Contar',
          'ClaimsReportedByPaidDate': 'Reclamaciones reportadas por fecha de pago',
          'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
          'SelectTop': 'Seleccione top',
          'FilterNA': 'Excluir NA',
          'CountBasis': 'Contar Base',
          'Sort': 'Ordenar',
          'Reset':'Reiniciar',
          'ShownAsPer':'Se muestra según',
          'DateRange': 'to'
          
      }
  }
};
