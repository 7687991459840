export const locale = {
	lang: 'pt-br',
	data: {
		'AvgCostUtil': {
			'AverageCostImpact': 'Impacto de Custo Médio',
			'UtilizationImpact': 'Impacto de Frequência',
			'AllAmountsDisplayedInLocalCurrency': 'Todos os valores estão em moeda local',
			'OverallTrend': 'Tendência Geral',
			'AverageCost': 'Custo Médio',
			'Utilization': 'Frequência',
			'FreqOfUse': 'Frequências de Utilização',
			'ServicesReceived': 'Serviços Recebidos',
			'KeyImpacts': 'Impactos Chave',
			'AverageCostPerClaim': 'Custo Médio Por Sinistro',
			'Prior': 'Anterior',
			'Current': 'Atual',
			'YoYChange': 'Mudança Ano I vs. Ano II',
			'Benchmark': 'Referência',
			'ClaimType': 'Tipo de Sinistro',
			'Network': 'Rede',
			'ProviderType': 'Tipo de Prestador',
			'Relationship': 'Relacionamento',
			'CostPerClaim': 'Custo Por Sinistro',
			'CostPerClaimant': 'Custo Por Usuário',
			'ClaimsPerClaimant': 'Sinistro Por Usuário',
			'UtilizationRatio': 'Taxa de Frequência',
			'ClaimsReportedByPaidDate': 'Sinistro Reportado por Data de Pagamento',
			'ClaimsReportedByIncurredDate': 'Sinistro Reportado por Data de Atendimento',
			'IncludeClient': 'Incluir Cliente',
			'ExcludeClient': 'Excluir Cliente',
			'Portfolio': 'Portfólio',
			'Industry': 'Operadora',
			'None': 'Nenhum',
			'Chart': 'Gráfico',
			'Table': 'Tabela',
			'ShowLegend': 'Mostrar Legenda',
			'DateRange': 'to'
		}
	}
};
