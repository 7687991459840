export const locale = {
    lang: 'pt-br',
    data: {
        'ExportCart': {
            'ExportCart': 'Exportar Carrinho',
            'CanViewDeleteAddedDashboardsHere': 'Pode visualizar/apagar painéis adicionados aqui',
            'Dashboards': 'Painéis',
            'ExportAll': 'Exportar Todos',
            'ClearAll': 'Limpar Todos',
            'Name': 'Nome',
            'Export': 'Exportar',
            'ExportTitle': 'Exportar Título',
            'Preview': 'Prévia',
            'Comments': 'Comentários',
            'FiltersSelected': 'Filtro(s) Selecionados',
            'NoFilterSelected': 'Nenhum Filtro Selecionado',
            'AdditionalFiltersSelected': 'Filtros Adicionais Selecionados',
            'FiltersMightNotApply': 'Filtros Podem Não Ser Aplicáveis'
        }
    }
};