<div
  id="exportCartComp"
  fxLayout="column"
  fxFlexFill
  fxLayoutGap="12px"
  class="contentPadding"
>
  <div id="exportCartHeading">
    <h1 class="text-mono">{{ 'ExportCart.ExportCart' | translate }}</h1>
    <p>{{ 'ExportCart.CanViewDeleteAddedDashboardsHere' | translate }}</p>
  </div>
  <button
    id="exportCartBtnRturn"
    mat-raised-button
    *ngIf="storyOpened"
    class="mat-accent"
    style="min-width: 200px; max-width: 270px"
    (click)="navigateToStoryOpened()"
  >
    Return to Story: {{this.storyOpened.name.length > 15 ? this.storyOpened.name.substring(0, 15) + '...' :
    this.storyOpened.name}}
  </button>
  <div
    fxLayout="row"
    fxLayoutGap="12px"
    class="export-area"
  >
    <mat-card
      id="exportCart1Half"
      fxFlex="60"
      fxLayout="column"
    >
      <div fxLayout="row">
        {{ 'ExportCart.Dashboards' | translate }} &nbsp;<strong> ({{cartItems.length}})</strong>
        <span fxFlex></span>
        <button
          id="exportCartBtnClearAll"
          mat-button
          class="mat-accent"
          (click)="clearCartItems()"
          [disabled]='cartItemsGrid.length === 0'
        >
          {{ 'ExportCart.ClearAll' | translate }}
        </button>
        <button
          id="exportCartBtnExportAll"
          mat-raised-button
          class="mat-primary"
          (click)="onToggleExportSectionShown()"
          [disabled]='cartItemsGrid.length === 0'
        >
          {{ 'ExportCart.ExportAll' | translate }}
        </button>
      </div>
      <div
        id="exportCartExEnable"
        fxLayout="row"
        *ngIf="isExportSectionEnabled"
        style="margin-top: 10px"
      >
        <mat-form-field
          fxFlex
          style="margin-right: 20px"
        >
          <input
            id="exportCartExportTitle"
            matInput
            data-placeholder="{{ 'ExportCart.ExportTitle' | translate }}"
            style="font-weight: bold"
            [(ngModel)]="exportTitle"
            [ngModelOptions]="{standalone: true}"
            autocomplete="off"
          >
        </mat-form-field>
        <mat-spinner
          *ngIf="isExporting"
          diameter=40
        ></mat-spinner>
        <button
          id="exportCartBtnExport"
          *ngIf="!isExporting"
          mat-raised-button
          class="mat-accent fixed-height"
          style="width: 140px"
          [disabled]="exportTitle.trim().length == 0 && cartItemsGrid.length === 0"
          (click)="exportCartItems()"
        >
          {{ 'ExportCart.Export' | translate }}
        </button>
      </div>
      <div
        class="export-area__card-container"
        id="cartagGrid"
      >
        <agcustom-grid
          #cartItemGrid
          class="export-area__grid"
          [dataList]="cartItemsGrid"
          [columnDefs]="dashboardColumnDefs"
          [rowDragManagedValue]="rowDragManagedValue"
          [delete]="true"
          [move]="true"
          (selectionChanged)="onDashboardSelectionChanged($event)"
          (onDelete)="onRemoveCartItem($event)"
          (onMove)="onMoveCartItem($event)"
        >
        </agcustom-grid>
      </div>
    </mat-card>
    <mat-card
      id="exportCartSelectDash"
      fxFlex="40"
      *ngIf="selectedDashboard"
    >
      <div fxLayout="row">
        <div
          id="exportCartPre"
          style="overflow: hidden"
          fxFlex="40"
        >
          <strong><u>{{ 'ExportCart.Preview' | translate }}</u></strong><br>
          <img
            id="exportCartPreDiag"
            *ngIf='selectedDashboard.imageBase64String'
            style="width: 100%; cursor: pointer"
            [src]="selectedDashboard.imageBase64String"
            (click)="onOpenDashboardPreviewDialog()"
          >
          <span
            id="exportCartPreDiagTxt"
            *ngIf='selectedDashboard.imageBase64String == null'
            style='cursor: pointer'
            (click)="onOpenDashboardPreviewDialog()"
          >
            <a>Click here to view preview</a>
          </span>
        </div>
        <div
          id="exportCartComment"
          style="overflow: hidden; padding-left: 20px"
          fxFlex="60"
        >
          <strong *ngIf='selectedDashboard.comments'><u>{{ 'ExportCart.Comments' | translate }}</u></strong>
          <br *ngIf='selectedDashboard.comments' />
          <span *ngIf='selectedDashboard.comments'>{{selectedDashboard.comments}}</span>
          <br *ngIf='selectedDashboard.comments' /><br *ngIf='selectedDashboard.comments' />
          <strong><u>{{ 'ExportCart.FiltersSelected' | translate }}</u></strong>
          <br *ngIf='selectedDashboard.comments' /><br *ngIf='selectedDashboard.comments' />
          <span *ngIf="selectedDashboard.filterList.length === 0">
            <br>
            <b>{{ 'ExportCart.NoFilterSelected' | translate }}</b>
          </span>
          <span *ngFor="let f of selectedDashboard.filterList; index as fi">
            <br>
            <span><b>({{(fi+1)}}) {{f.name}}:</b> </span>
            <span *ngIf="isArray(f.selectedValues)">
              <span *ngFor="let v of f.selectedValues; index as vi">
                <span style="color: blue">({{(vi+1)}})</span> {{v}}
              </span>
            </span>
            <span *ngIf="!isArray(f.selectedValues)">
              {{f.selectedValues}}
            </span>
          </span>
          <div *ngIf="additionalFilterList.length > 0">
            <br><br>
            <span *ngIf="selectedDashboard.filterList.length === 0">
              <b>{{ 'ExportCart.AdditionalFiltersSelected' | translate }}</b><small style='padding-left: 5px'>({{
                'ExportCart.FiltersMightNotApply' | translate }})</small>
            </span>
            <span *ngFor="let f of additionalFilterList; index as fi">
              <br>
              <span><b>({{(fi+1)}}) {{f.name}}:</b> </span>
              <span *ngIf="isArray(f.selectedValues)">
                <span *ngFor="let v of f.selectedValues; index as vi">
                  <span style="color: blue">({{(vi+1)}})</span> {{v}}
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>