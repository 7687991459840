export const locale = {
    lang: 'en',
    data: {
        'ExecutiveSummary': {
            'ExecutiveSummary': 'Executive Summary',            
            'AllAmountsDisplayedInLocalCurrency': 'All Amounts Displayed in Local Currency',
            'GeneralTrend': 'General Trend',
            'Rolling12Basis': 'Rolling 12 Basis',
            'CurrentPMPM': 'Current PMPM', 
            'PriorPMPM' : 'Prior PMPM', 
            'OverallTrend' : 'Overall Trend', 
            'AverageCost': 'Average Cost',
            'Utilization': 'Utilization',
            'ServicesReceived': 'Services Received',
            'PlanPerformance': 'Plan Performance',
            'YearToDateBasis': 'Year-To-Date Basis',
            'YearToDateCBasis': 'Year-To-Date (C) Basis',
            'ClaimsReportedByPaidDate' :  'Claims reported by paid date',
            'ClaimsReportedByIncurredDate' :  'Claims reported by incurred date',
            'LossRatio': 'Loss Ratio',
            'Headcount': 'HEADCOUNT',
            'Claims': 'CLAIMS',
            'Premium': 'PREMIUM',
            'CombinedLossRatio':'Combined Loss Ratio',
            'ClaimLabel': 'CLAIMS + EXPENSES',
            'PremimumLabel':'PREMIUM + INCOME',
            'DateRange': 'to'
        }
    }
};
