export const locale = {
    lang: 'es-mx',
    data: {
        'PerMemberCosts': {
            'PerMemberCosts': 'Costo por Asegurado',
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'KeyImpacts': 'Impactos clave',
            'CurrentPmpm': 'Gasto mensual x asegurado',
            'BenchmarkPmpm': 'Comparativo costo promedio',
            'PerMemberPerMonthCost': 'Costo por Asegurado por mes',
            'Prior': 'Vigencia Anterior',
            'Current': 'Vigencia Actual',
            'YoYChange': 'Cambio año tras año',
            'Benchmark': 'Análisis comparativo',
            'IncludeClient': 'Incluir Cliente',
            'ExcludeClient': 'Excluir Cliente',
            'Portfolio': 'Portafolio',
            'Industry': 'Industria',
            'None': 'Ninguna',
            'AgeBand': 'Rango de Edad',
            'Gender': 'Género',
            'Relationship': 'Parentesco',
            'ClaimsReportedByPaidDate': ' ',
            'ClaimsReportedByIncurredDate': ' ',
            'Chart':'Gráfico',
            'Table':'Tabla',
            'DateRange': 'to'
        }
    }
};
