import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DashboardTemplateBase } from '../dashboard-template-base.component';
import { DimensionOrderService } from 'app/dashboards/dimension-order.service';
import { DataService } from 'app/dashboards/data.service';
import { UserService } from 'app/services/user.service';
import { User } from 'app/models/user.model';
import { formatNumber } from '@angular/common';

import { locale as navigationEnglish } from 'app/dashboards/templates/per-member-costs/i18n/en';
import { locale as navigationSpanishCL } from 'app/dashboards/templates/per-member-costs/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/dashboards/templates/per-member-costs/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/dashboards/templates/per-member-costs/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/dashboards/templates/per-member-costs/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/dashboards/templates/per-member-costs/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/dashboards/templates/per-member-costs/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/dashboards/templates/per-member-costs/i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { FilterOptions } from 'app/dashboards/shared/models/FilterOptions';
import { PulseEventName } from 'app/dashboards/shared/models/PulseEventName';
import { APAC_COUNTRIES } from 'app/constants/countries_APAC';
import { CLAIM_BASIS } from 'app/models/basis.model';

@Component({
    selector: 'app-per-member-costs',
    templateUrl: './per-member-costs.component.html',
    styleUrls: ['./per-member-costs.component.css']
})
export class PerMemberCostsComponent implements OnInit, DashboardTemplateBase {
    @Output() action = new EventEmitter<any>();
    isFilterOptionsLoading = true;
    imageUrlBase = '';
    properties: any;
    data: any;
    apacCountries = APAC_COUNTRIES;
    PortfolioIndustryNoneOption = PortfolioIndustryNoneOption;
    AgeBandGenderRelationshipOption = AgeBandGenderRelationshipOption;
    ChartTable = ChartTable;
    comparable: string = "";
    user: User;
    globalSettings: any;
    isIncludeClient: boolean;
    portfolioIndustryNoneOption: PortfolioIndustryNoneOption;
    ageBandGenderRelationshipOption: AgeBandGenderRelationshipOption;
    dateRange = '';
    positiveKeyImpacts: any[] = [];
    negativeKeyImpacts: any[] = [];
    currentPMPM: number;
    benchmarkPMPM: number;
    pmcItemsGrid: PerMemberCosts[] = [];
    showFilter = false;
    noneSelected = false;
    isLoading = false;
    filterOptions = new FilterOptions();
    showBM: boolean = true;
    categories: any = [];
    dataListBar1Prior: any[] = [];
    dataListBar2Current: any[] = [];
    dataListLinebenchmark: any[] = [];
    parentLoaded = false;
    currentDateRange: any;
    priorDateRange: any;
    benchmarkLabel: any;
    eligibleForBenchmark = false;
    CLAIM_BASIS = CLAIM_BASIS;

    initData(): void {
        //[Andro]: Sorting of costs
        // this.dateRange = this.data.dateRange;
        this.dateRange = this.data.dateRange.fromDateLabel + ' ' + this._translateService.instant("PerMemberCosts.DateRange") + ' ' + this.data.dateRange.toDateLabel;
        this.currentPMPM = this.data.impact.executiveSummaryCurrent.pmpm;
        this.benchmarkPMPM = this.data.benchmarkPMPM.benchmarkValue;
        this.comparable = this.properties.rollingPeriod == 3 ? " (C)" : "";
        // this.currentDateRange = this.data.currentDateRange + this.comparable;
        this.currentDateRange = this.data.currentDateRange.fromDateLabel + ' ' + this._translateService.instant("PerMemberCosts.DateRange") + ' ' + this.data.currentDateRange.toDateLabel + this.comparable;
        // this.priorDateRange = this.data.priorDateRange + this.comparable;
        this.priorDateRange = this.data.priorDateRange.fromDateLabel + ' ' + this._translateService.instant("PerMemberCosts.DateRange") + ' ' + this.data.priorDateRange.toDateLabel + this.comparable;
        this.benchmarkLabel = this._translateService.instant("PerMemberCosts.Benchmark");
        this.noneSelected = this.properties.benchmarkLevel === PortfolioIndustryNoneOption.None;
        this.pmcItemsGrid = this.data.costs;

        this.categories = [];
        this.dataListBar1Prior = [];
        this.dataListBar2Current = [];
        this.dataListLinebenchmark = [];
        this.data.costs.map(d => {
            this.categories.push(d.dimension);
            this.dataListBar1Prior.push(d.prior);
            this.dataListBar2Current.push(d.current);
            this.dataListLinebenchmark.push(d.benchmark);
        });
        this.isLoading = false;
        const sorted = this.data.keyImpact.sort((a, b) => {
            return b.impact - a.impact;
        });
        this.positiveKeyImpacts = sorted.slice(0, 2);
        //this.negativeKeyImpacts = sorted.splice(-2);
        // lower values mean higher negative impact
        this.negativeKeyImpacts = sorted.splice(-2).sort((a, b) => {
            return a.impact - b.impact;
        }).filter(a => a.impact < 0);
        setTimeout(() => this.parentLoaded = true, 100);
    }

    constructor(
        private _dimensionOrderService: DimensionOrderService,
        private _dataService: DataService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService) { }

    ngOnInit(): void {
        this._fuseTranslationLoaderService.loadTranslations(
          navigationEnglish,
          navigationSpanishCL,
          navigationSpanishCO,
          navigationSpanishEC,
          navigationSpanishMX, 
          navigationSpanishPE,
          navigationPortugueseBR,
          navigationItalianIT,
        );

        this.isIncludeClient = true;
        this.portfolioIndustryNoneOption = this.PortfolioIndustryNoneOption.Portfolio;
        this.ageBandGenderRelationshipOption = this.AgeBandGenderRelationshipOption.AgeBand;
    }

    additionalFilterSelectionChanged(val) {
        this.action.emit(val);
    }

    selectionChanged(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED,
            data: {
                properties: {
                    name: filterName,
                    value: val
                }
            }
        });
    }
}

export interface PerMemberCosts {
    dimension: string;
    current: number;
    prior: number;
    yoYChange: number;
}

export enum PortfolioIndustryNoneOption {
    Portfolio = 1,
    Industry = 2,
    None = 3
}

export enum AgeBandGenderRelationshipOption {
    AgeBand = 'Age Band',
    Gender = 'Gender',
    Relationship = 'Relationship'
}
export enum ChartTable {
    Chart = 'Chart',
    Table = 'Table'
}
