export const locale = {
    lang: 'it',
    data: {
  'CoMorbidity': {
    'CoMorbidityHeading': 'CO-MORBIDITÀ',
    'AllAmountsDisplayedInLocalCurrency': 'Tutti gli importi visualizzati in valuta locale',
    'Diabetes': 'Diabete',
    'Hyperlipidemia': 'Iperlipidemia',
    'Hypertension': 'Ipertensione',
    'AssociatedCo': 'Comorbilità associate',
    'Claimants': 'Teste colpite',
    'AverageAge': 'Età media',
    'Years': 'anni',
    'Female': 'Femmina',
    'Male': 'Uomo',
    'Gender': 'Genere',
    'ClaimType': 'Sinistri oer assistito attivo',
    'TotalCost': 'Costo totale',
    'CoMorbidity': 'Co-morbidities',
    'PercentageOfTotalClaims': '% del totale sinistri'
  }
}};