import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FwbWellbeingService {

  constructor(private httpClient: HttpClient) { }

  getQuestions(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/wellbeing/questions')
  }

  getLocations(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/wellbeing/locations')
  }

  getDrilldown(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/wellbeing/drilldown')
  } 

  getCategories(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/wellbeing/categories')
  } 

  getOvertime(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/wellbeing/overtime')
  } 

}
