import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { CommonModule } from '@angular/common';
import { BarSoloComponent } from './bar-solo/bar-solo.component';
import { PieComponent } from './pie/pie.component';
import { ChartModule } from 'angular-highcharts';
import { RadialshapeComponent } from './radialshape/radialshape.component';
import { BubbleComponent } from './bubble/bubble.component';
import { DonutComponent } from './donut/donut.component';
import { FormsModule } from '@angular/forms';
import { BarComponent } from './bar/bar.component';
import { ScatterComponent } from './scatter/scatter.component';
import { DonutPopComponent } from './donut-pop/donut-pop.component';
import { ScatterDiseaseComponent } from './scatter-disease/scatter-disease.component';
import { ColumnComponent } from './column/column.component';
import { LineComponent } from './line/line.component';
import { ColumnStackComponent } from './column-stack/column-stack.component';
import { SharedModule } from '../shared/shared.module';
import { BarInsidebarComponent } from './bar-insidebar/bar-insidebar.component';
import { ColumnLineComponent } from './column-line/column-line.component';
import { BarStackedComponent } from './bar-stacked/bar-stacked.component';
import { BarNegativeStackComponent } from './bar-negative-stack/bar-negative-stack.component';
import { ColumnStackAgeComponent } from './column-stack-age/column-stack-age.component';
import { DualBarComponent } from './dual-bar/dual-bar.component';
import { PieImageComponent } from './pie-image/pie-image.component';
import {  PulseDonutComponent } from './pulse-donut/pulse-donut.component';

@NgModule({
  imports: [
    CommonModule,
    ChartModule,
    FormsModule ,
    SharedModule,
    HighchartsChartModule
  ],
  declarations: [
    BarSoloComponent,
    PieComponent,
    RadialshapeComponent,
    BubbleComponent,
    DonutComponent,
    BarComponent,
    ScatterComponent,
    DonutPopComponent,
    ScatterDiseaseComponent,
    ColumnComponent,
    LineComponent,
    ColumnStackComponent,
    BarInsidebarComponent,
    ColumnLineComponent,
    BarStackedComponent,
    BarNegativeStackComponent,
    ColumnStackAgeComponent,
    DualBarComponent,
    PieImageComponent,
    PulseDonutComponent
  ],
  exports: [
    BarSoloComponent,
    PieComponent,
    RadialshapeComponent,
    BubbleComponent,
    DonutComponent,
    BarComponent,
    ScatterComponent,
    DonutPopComponent,
    ScatterDiseaseComponent,
    ColumnComponent,
    LineComponent,
    ColumnStackComponent,
    BarInsidebarComponent,
    ColumnLineComponent,
    BarStackedComponent,
    BarNegativeStackComponent,
    ColumnStackAgeComponent,
    DualBarComponent,
    PieImageComponent,
    PulseDonutComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ChartsModule { }
