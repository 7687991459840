import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { SampleComponent } from './sample.component';
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared/shared.module';

import {MatGridListModule} from '@angular/material/grid-list';
const routes = [
    {
        path     : 'home',
        component: SampleComponent
    }
];

@NgModule({
    declarations: [
        SampleComponent,
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        MaterialModule ,
        MatGridListModule,
        SharedModule
    ],
    exports     : [
        SampleComponent
    ]
})

export class SampleModule
{
}
