export const locale = {
	lang: 'pt-br',
	data: {
		'PerMemberCosts': {
			'PerMemberCosts': 'Custos por Usuários',
			'AllAmountsDisplayedInLocalCurrency': 'Todos os valores estão em moeda local',
			'KeyImpacts': 'Impactos Chave',
			'CurrentPmpm': 'PMPM Atual',
			'BenchmarkPmpm': 'PMPM REFERÊNCIA',
			'PerMemberPerMonthCost': 'Custo Por Usuário Por Mês',
			'Prior': 'Anterior',
			'Current': 'Atual',
			'YoYChange': 'Mudança Ano I vs. Ano II',
			'Benchmark': 'Referência',
			'IncludeClient': 'Incluir Cliente',
			'ExcludeClient': 'Excluir Cliente',
			'Portfolio': 'Portfólio',
			'Industry': 'Operadora',
			'None': 'Nenhum',
			'AgeBand': 'Faixa Etária',
			'Gender': 'Gênero',
			'Relationship': 'Relacionamento',
			'ClaimsReportedByPaidDate': 'Sinistro Reportado por Data de Pagamento',
			'ClaimsReportedByIncurredDate': 'Sinistro Reportado por Data de Atendimento',
			'Chart': 'Gráfico',
			'Table': 'Tabela',
			'DateRange': 'to'
		}
	}
};