import { Component } from '@angular/core';

@Component({
  selector: 'app-agmaticonaction',
  template: `
  <div class="text-center">
    <button mat-icon-button class="mat-accent ag-hover-to-show" [disabled]="isDisabled()" (click)="onClick($event)">
      <mat-icon class="material-icons">{{params.icon}}</mat-icon>
    </button>
  </div>`
})
export class AgmaticonactionComponent {

  constructor() { }

  params;

  agInit(params): void {
    this.params = params;
    if (!params.action) {
      throw new Error('Missing action parameter for ActionCellRendererComponent');
    }
    if (params.isVisibiityToggle) {
        this.params.icon = this.params.data.isVisible ? 'visibility' : 'visibility_off';
    }
  }

  onClick(event: any): void {
    event.stopPropagation();
    this.params.action(this.params);
  }

  isDisabled() {
    if(this.params.isDisabled) {
      return this.params.isDisabled(this.params);
    } else {
      return false;
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}

export type CellAction = (params) => void;