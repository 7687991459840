<div class="flex-column-simple" *ngIf="!isChartVisible">
    <div class="flex-row-simple" style="margin: auto;">
        <div fxLayout="column" fxFlex="50" fxLayoutGap="12px" style="padding: 18px;" *ngIf="!isChartVisible">
            <mat-spinner class="mat-accent" diameter="30"></mat-spinner>
        </div>
    </div>
</div>
<div class="contentPadding columnDiv" *ngIf="isChartVisible">
    <div class="rowDiv">
        <div>
            <aon-h1 style="color: #001b5a;">Financial Wellbeing Score</aon-h1>
        </div>
        <div class="vertical-line marginLeft20"></div>
        <div class="marginLeft20 marginTop20">
            <aon-p-lg>
                from 1 July, 2019 through 1 July, 2020
            </aon-p-lg>
        </div>
    </div>

    <div class=" ">
        <fwb-top-summary>

        </fwb-top-summary>
    </div>
    <div class="drilldown " style="margin-top: 10px; color:white!important;">
        <score-drill-down>

        </score-drill-down>
    </div>
</div>