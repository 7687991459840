<div class="flex-column-simple" *ngIf="!isChartDemographicsVisible">
    <div class="flex-row-simple" style="margin: auto;">
        <div fxLayout="column" fxFlex="50" fxLayoutGap="12px" style="padding: 18px;"
            *ngIf="!isChartDemographicsVisible">
            <mat-spinner class="mat-accent" diameter="30"></mat-spinner>
        </div>
    </div>
</div>
<div class="contentPadding columnDiv" *ngIf="isChartDemographicsVisible">
    <div class="rowDiv">
        <div>
            <aon-h1 style="color: #001b5a;">Demographics</aon-h1>
        </div>
        <div class="vertical-line marginLeft20"></div>
        <div class="marginLeft20 marginTop20">
            <aon-p-lg>
                from 1 July, 2019 through 1 July, 2020
            </aon-p-lg>
        </div>
    </div>

    <div class=" ">
        <aon-summary-card [height]="cardheight" [width]="cardwidth">
            <aon-summary-card-header>
                <aon-h3 style="color: #001b5a;">
                    Age Band & Gender Breakdown
                </aon-h3>
            </aon-summary-card-header>
            <aon-summary-card-content contentwidth="98%" top="'20px'">
                
                <fwb-error-message [isError]="isError" [message]="errorMsg"></fwb-error-message>
                <div *ngIf="!isError">
                <div style="display:flex;flex-direction:column;" *ngIf="loaded">
                    <div class="flex-row-simple" style="width: 300px !important; margin-left: 76%;">
                        <div style="
                          margin-top: 10px;
                          min-width: 95px;
                          align-content: center;
                          vertical-align: middle;
                      ">
                            <aon-p-lg>
                                Group By
                            </aon-p-lg>
                        </div>
                        <aon-select style="width: 300px !important;" scrollable="true"
                            [selectedvalue]="selectedOptionName" (onselectionchange)="onOptionChange($event)">
                            <aon-select-option *ngFor="let option of options" [value]="option.name">{{ option.name }}
                            </aon-select-option>
                        </aon-select>
                    </div>

                    <div style="flex: 1;margin-top: -65px !important;">
                        <demographicsChartsComponent *ngIf="selectedOption" [demographicsData]="ageBandData"
                            [scaleMax]="maxTotalAgeBand" [title]="'DemographicsAgeBandId'"
                            [legendtitle]="legendtitleGender" [keys]="genders">
                        </demographicsChartsComponent>
                    </div>
                    <div style="flex: 1; margin-top: 15px !important;">
                        <demographic-by-gender *ngIf="!selectedOption" [demographicsData]="ageDataByGender"
                            [scaleMax]="maxTotalGender" [title]="'DemographicsGenderId2'"
                            [legendtitle]="legendtitleAgeBand" [keys]="ageBands">
                        </demographic-by-gender>
                    </div>
                </div>

                </div>
            </aon-summary-card-content>
        </aon-summary-card>
    </div>

    <div class="rowDiv chartRow" style="margin-top: 25px !important;">
        <div style="flex: 3;">
            <activesVsRetirees-chart  [isError]="isSalaryError" [errorMsg]="errorSalaryMsg"> </activesVsRetirees-chart>
        </div>
        <div style="flex: 3;">
            <location-chart [title]="'Location'" [inputSeries]="locationDataBarChartData" [isError]="isLocationError" [errorMsg]="errorLocationMsg"></location-chart>
        </div>
        <div style="flex: 3;">
            <location-chart [title]="'Business Unit'" [inputSeries]="businessDataBarChartData"  [isError]="isLocationError" [errorMsg]="errorLocationMsg"></location-chart>
        </div>
    </div>
</div>