<div class="title">
    <h2 class="title__text">Key Insights</h2>
</div>

<div
    *ngIf="resultsState !== RESULTS_STATE.LOADED"
    [ngSwitch]="resultsState"
    class="result-status"
>
    <div *ngSwitchCase="RESULTS_STATE.INITIAL" class="result-status--initial">
        <span class="material-icons">arrow_back</span> To get started, fill up
        the 'User Inputs' section and click 'Calculate'.
    </div>
    <div
        *ngSwitchCase="RESULTS_STATE.RECALCULATION_REQUIRED"
        class="result-status--recalculation-required"
    >
        Recalculation Required. There is/are change(s) made to 'User Inputs'
        section.
    </div>
    <div
        *ngSwitchCase="RESULTS_STATE.CALCULATING"
        class="result-status--calculating"
    >
        Calculation in Progress... Please Wait.
    </div>
    <div
        *ngSwitchCase="RESULTS_STATE.ERROR"
        class="result-status--error"
    >
        Calculation Error... Do Adjust Dates in Time Period Before Calculating Again.
    </div>
</div>

<form *ngIf="resultsState === RESULTS_STATE.LOADED">
    <ng-container [ngTemplateOutlet]="insights"> </ng-container>
    <div class="title">
        <h2 class="title__text">Projection</h2>
    </div>
    <ng-container [ngTemplateOutlet]="historicalExperience"> </ng-container>
    <ng-container [ngTemplateOutlet]="claimsProjection"> </ng-container>
    <ng-container [ngTemplateOutlet]="info"> </ng-container>
</form>

<ng-template #insights [formGroup]="resultsForm">
    <div>
        <table class="insights-table">
            <thead>
                <tr>
                    <th></th>
                    <th>Current Premium ($)</th>
                    <th>Expected Claims ($)</th>
                </tr>
            </thead>
            <tbody>
                <tr class="insights-table__row">
                    <th>PMPM (Per member per month)</th>
                    <td>
                        ${{
                            this.resultsForm.get("insights.pmpm.premium").value
                                | number: "0.0-2"
                        }}
                    </td>
                    <td>
                        ${{
                            this.resultsForm.get("insights.pmpm.claims").value
                                | number: "0.0-2"
                        }}
                    </td>
                </tr>
                <tr class="insights-table__row">
                    <th>Total</th>
                    <td>
                        ${{
                            this.resultsForm.get("insights.total.premium").value
                                | number: "0.0-0"
                        }}
                    </td>
                    <td>
                        ${{
                            this.resultsForm.get("insights.total.claims").value
                                | number: "0.0-0"
                        }}
                    </td>
                </tr>
                <tr class="insights-table__row">
                    <th></th>
                    <td></td>
                    <td></td>
                </tr>
                <tr class="insights-table__row">
                    <th></th>
                    <td></td>
                    <td></td>
                </tr>
                <tr class="insights-table__row">
                    <th>Premium Needed</th>
                    <td colspan="2">
                        ${{
                            this.resultsForm.get("insights.premiumNeeded").value
                                | number: "0.0-0"
                        }}
                    </td>
                </tr>
                <tr class="insights-table__row">
                    <th></th>
                    <td></td>
                    <td></td>
                </tr>
                <tr class="insights-table__row">
                    <th></th>
                    <th>Surplus/shortfall</th>
                    <th>Premium Increase Needed</th>
                </tr>
                <tr class="insights-table__row">
                    <th></th>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "insights.summary.surplusShortfall"
                            ).value | number: "0.0-0"
                        }}
                    </td>
                    <td>
                        <strong>
                        {{
                            this.resultsForm.get(
                                "insights.summary.premiumIncreaseNeeded"
                            ).value | number: "0.0-1"
                        }}%
                        </strong>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #historicalExperience [formGroup]="resultsForm">
    <h3 class="section-header" (click)="isHistoricalExperienceSectionExpanded = !isHistoricalExperienceSectionExpanded">
        Historical Experience
        <span class="material-icons">{{
            isHistoricalExperienceSectionExpanded
                ? "expand_less"
                : "expand_more"
        }}</span>
    </h3>
    <div *ngIf="isHistoricalExperienceSectionExpanded">
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>Client</th>
                    <th>Country</th>
                </tr>
            </thead>
            <tbody>
                <tr class="historical-experience-table__row">
                    <th>Historical Members (lagged)</th>
                    <td>
                        {{
                            this.resultsForm.get(
                                "historicalExperience.historicalMembers.client"
                            ).value | number
                        }}
                    </td>
                    <td>
                        {{
                            this.resultsForm.get(
                                "historicalExperience.historicalMembers.country"
                            ).value | number
                        }}
                    </td>
                </tr>
                <tr class="historical-experience-table__row">
                    <th>Historical Claims</th>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "historicalExperience.historicalClaims.client"
                            ).value | number: "0.0-0"
                        }}
                    </td>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "historicalExperience.historicalClaims.country"
                            ).value | number: "0.0-0"
                        }}
                    </td>
                </tr>
                <tr class="historical-experience-table__row">
                    <th>Historical Outliers/HCC</th>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "historicalExperience.historicalOutliers.client"
                            ).value | number: "0.0-0"
                        }}
                    </td>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "historicalExperience.historicalOutliers.country"
                            ).value | number: "0.0-0"
                        }}
                    </td>
                </tr>
                <tr class="historical-experience-table__row">
                    <th>Historical Experience Excl HCC</th>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "historicalExperience.historicalExperienceExclHCC.client"
                            ).value | number: "0.0-0"
                        }}
                    </td>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "historicalExperience.historicalExperienceExclHCC.country"
                            ).value | number: "0.0-0"
                        }}
                    </td>
                </tr>
                <tr class="historical-experience-table__row">
                    <th>Historical PMPM</th>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "historicalExperience.historicalPMPM.client"
                            ).value | number: "0.0-2"
                        }}
                    </td>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "historicalExperience.historicalPMPM.country"
                            ).value | number: "0.0-2"
                        }}
                    </td>
                </tr>
                <tr class="historical-experience-table__row">
                    <th>Adjustment from Paid to Incurred</th>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "historicalExperience.adjustmentFromPaidToIncurred.client"
                            ).value | number: "0.0-2"
                        }}
                    </td>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "historicalExperience.adjustmentFromPaidToIncurred.country"
                            ).value | number: "0.0-2"
                        }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #claimsProjection [formGroup]="resultsForm">
    <h3 class="section-header" (click)="isClaimsProjectionExpanded = !isClaimsProjectionExpanded">
        Claims Projection
        <span class="material-icons">{{
            isClaimsProjectionExpanded
                ? "expand_less"
                : "expand_more"
        }}</span>
    </h3>
    <div *ngIf="isClaimsProjectionExpanded">
        <table formGroupName="claimsProjection">
            <thead>
                <tr>
                    <th></th>
                    <th>Client</th>
                    <th>Country</th>
                </tr>
            </thead>
            <tbody>
                <tr class="historical-experience-table__row">
                    <th>Months to Trend Forward</th>
                    <td>
                        {{
                            this.resultsForm.get(
                                "claimsProjection.monthsToTrendForward.client"
                            ).value | number
                        }}
                    </td>
                    <td>
                        {{
                            this.resultsForm.get(
                                "claimsProjection.monthsToTrendForward.country"
                            ).value | number
                        }}
                    </td>
                </tr>
                <tr class="historical-experience-table__row">
                    <th>Trended PMPM</th>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "claimsProjection.trendPMPM.client"
                            ).value | number: "0.0-2"
                        }}
                    </td>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "claimsProjection.trendPMPM.country"
                            ).value | number: "0.0-2"
                        }}
                    </td>
                </tr>
                <tr class="historical-experience-table__row">
                    <th></th>
                    <td></td>
                    <td></td>
                </tr>
                <tr class="historical-experience-table__row">
                    <th>Adult Life Adjustment</th>
                    <td>
                        {{
                            this.resultsForm.get(
                                "claimsProjection.adultLifeAdjustments.client"
                            ).value | number
                        }}%
                    </td>
                    <td>
                        {{
                            this.resultsForm.get(
                                "claimsProjection.adultLifeAdjustments.country"
                            ).value | number
                        }}%
                    </td>
                </tr>
                <tr class="historical-experience-table__row">
                    <th>Additional Adjustment</th>
                    <td>
                        {{
                            this.resultsForm.get(
                                "claimsProjection.additionalAdjustments.client"
                            ).value | number
                        }}%
                    </td>
                    <td>
                        {{
                            this.resultsForm.get(
                                "claimsProjection.additionalAdjustments.country"
                            ).value | number
                        }}%
                    </td>
                </tr>
                <tr class="historical-experience-table__row">
                    <th>Adjusted PMPM</th>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "claimsProjection.adjustedPMPM.client"
                            ).value | number: "0.0-2"
                        }}
                    </td>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "claimsProjection.adjustedPMPM.country"
                            ).value | number: "0.0-2"
                        }}
                    </td>
                </tr>
                <tr class="historical-experience-table__row">
                    <th></th>
                    <td></td>
                    <td></td>
                </tr>
                <tr
                    class="historical-experience-table__row"
                    formGroupName="hccPMPM"
                >
                    <th>HCC PMPM</th>
                    <td>
                        <mat-form-field appearance="fill" class="input_wrapper__text">
                            <input
                                type="number"
                                matInput
                                formControlName="client"
                            />
                            <span matPrefix>$&nbsp;</span>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="fill" class="input_wrapper__text--readonly">
                            <input
                                type="number"
                                matInput
                                formControlName="country"
                                readonly
                            />
                            <span matPrefix>$&nbsp;</span>
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="historical-experience-table__row">
                    <th>Final PMPM</th>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "claimsProjection.finalPMPM.client"
                            ).value | number: "0.0-2"
                        }}
                    </td>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "claimsProjection.finalPMPM.country"
                            ).value | number: "0.0-2"
                        }}
                    </td>
                </tr>
                <tr class="historical-experience-table__row">
                    <th></th>
                    <td></td>
                    <td></td>
                </tr>
                <tr class="historical-experience-table__row">
                    <th>Credibility-weighted Claims PMPM</th>
                    <td>
                        ${{
                            this.resultsForm.get(
                                "insights.pmpm.claims"
                            ).value | number: "0.0-2"
                        }}
                    </td>
                    <td></td>
                </tr>
                <tr class="historical-experience-table__row">
                    <th></th>
                    <td></td>
                    <td></td>
                </tr>
                <tr class="historical-experience-table__row-footer">
                    <td colspan="3">
                        Slight differences in values displayed due to rounding off after computation
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #info>
    <div>
        <table>
            <thead>
                <tr class="info-table__header-row">
                    <th colspan="2">* Information</th>
                </tr>
            </thead>
            <tbody>
                <tr class="info-table__row">
                    <th>HCC</th>
                    <td>High Costs Claimants</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>
