import {
    Component,
    OnInit,
    Output,
    ElementRef,
    EventEmitter,
    ViewChild
} from '@angular/core';
import { DashboardTemplateBase } from '../dashboard-template-base.component';
import { DataService } from 'app/dashboards/data.service';
import { HttpClient } from '@angular/common/http';
import { FilterOptions } from 'app/dashboards/shared/models/FilterOptions';
import { PulseEventName } from 'app/dashboards/shared/models/PulseEventName';
import { DimensionOrderService } from 'app/dashboards/dimension-order.service';
import { locale as navigationEnglish } from 'app/dashboards/templates/claim-type-diagnosis/i18n/en';
import { locale as navigationSpanishCL } from 'app/dashboards/templates/claim-type-diagnosis/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/dashboards/templates/claim-type-diagnosis/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/dashboards/templates/claim-type-diagnosis/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/dashboards/templates/claim-type-diagnosis/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/dashboards/templates/claim-type-diagnosis/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/dashboards/templates/claim-type-diagnosis/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/dashboards/templates/claim-type-diagnosis/i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NumberFormattingService } from 'app/services/number-formatting.service';
import { locale } from 'moment';
import { NumberFormatPipe } from 'app/pipes/number/number-format.pipe';
import { formatNumber } from '@angular/common';
import { countryLATEM } from 'app/constants/countries_LATAM';
import { APAC_COUNTRIES } from 'app/constants/countries_APAC';
import { CLAIM_BASIS } from 'app/models/basis.model';

@Component({
    selector: 'app-claim-type-diagnosis',
    templateUrl: './claim-type-diagnosis.component.html',
    styleUrls: ['./claim-type-diagnosis.component.css']
})
export class ClaimTypeDiagnosisComponent
    implements OnInit, DashboardTemplateBase {
    apacCountries = APAC_COUNTRIES
    isFilterOptionsLoading = true;
    ShowHideOption = ShowHideOption;
    ClaimantOption = ClaimantOption;
    CategoryChapterOption = CategoryChapterOption;
    SortOption = SortOption;
    data: any;
    properties: any;
    imageUrlBase = '';
    unitCost = '';
    filterOptions: FilterOptions = new FilterOptions();
    topFilterClaim: number = 10;
    showFilter: boolean = false;
    // finalTopListBind: any = [];
    finalTopListChart: any = [];
    // currentClaim = '';
    // currentDiagnosis: any = '';
    // shownAsPerCurrentClaim = '';
    // shownAsPerCurrentDiagnosis: any = '';
    datalist: any = [];
    datalistDiag: any = [];
    parentLoaded = false;
    claimType = 'claim type';
    currentTimePeriodLable = '';
    isLoadingClaimType = false;
    isLoadingDiagnoses = false;
    orderDiagListTopLoaded = false;
    colors: any[];
    @Output() action = new EventEmitter<any>();
    filterNA: boolean = false;
    comparablePeriod: string = '';
    topDiagTotal: number;
    maxClaimTotal: any;
    // orderDiagListTop: any[];
    maxCount: any;
    maxClaimCost: any;
    barColorPurple = '#101E7F';
    barColorBlue = '#A70070';
    barColorGray = '#29B0C3';
    CLAIM_BASIS = CLAIM_BASIS;

    initData(): void {
        if (typeof this.data.shownAsPerCurrentClaim === 'undefined') {
            this.data.shownAsPerCurrentClaim = '';
        }

        if (typeof this.data.shownAsPerCurrentDiagnosis === 'undefined') {
            this.data.shownAsPerCurrentDiagnosis = '';
        }

        if (typeof this.data.finalTopListBind === 'undefined') {
            this.data.finalTopListBind = [];
        }

        if (typeof this.data.orderDiagListTop === 'undefined') {
            this.data.orderDiagListTop = [];
        }

        this.orderDiagListTopLoaded = true;

        if (typeof this.data.currentClaim === 'undefined') {
            this.data.currentClaim = '';
        }

        if (typeof this.data.currentDiagnosis === 'undefined') {
            this.data.currentDiagnosis = '';
        }

        this.comparablePeriod = this.properties.rollingPeriod == 3 ? ' (C)' : '';
        // tslint:disable-next-line: max-line-length
        this.colors = ['cornflowerblue', 'goldenrod', 'orange', 'green', 'blue', 'lightgreen', 'teal', 'plum', 'purple', 'brown', 'blueviolet', 'lightseagreen', 'darkmagenta', 'rosybrown', 'saddlebrown'];
        this.datalist = this.data.claimList.current;
        this.datalistDiag = this.data.diagList.current;
        this.currentTimePeriodLable = '';

        if (this.data.claimList.currentPeriod !== null && this.data.claimList.currentPeriod.length !== 0) {
            if (this.data.claimList.currentPeriod.fromDateLabel === this.data.claimList.currentPeriod.toDateLabel) {
                this.currentTimePeriodLable = this.data.claimList.currentPeriod.fromDateLabel;
            }
            else {
                this.currentTimePeriodLable = this.data.claimList.currentPeriod.fromDateLabel + ' ' + this._translateService.instant("ClaimCostDiag.DateRange") + ' ' + this.data.claimList.currentPeriod.toDateLabel;
            }
        }

        if (this.currentTimePeriodLable == null) {

            /*
            this.currentTimePeriodLable =
                (this.data.diagList.currentPeriod !== null && this.data.diagList.currentPeriod.length !== 0)
                    ? // this.data.diagList.current[0].periodLable
                    this.data.diagList.currentPeriod.fromDateLabel + ' ' + this._translateService.instant("ClaimCostDiag.DateRange")+ ' '  + this.data.diagList.currentPeriod.toDateLabel
                    : '';
            */
            if (this.data.diagList.currentPeriod !== null && this.data.diagList.currentPeriod.length !== 0) {
                if (this.data.diagList.currentPeriod.fromDateLabel === this.data.diagList.currentPeriod.toDateLabel) {
                    this.currentTimePeriodLable = this.data.diagList.currentPeriod.fromDateLabel;
                }
                else {
                    this.currentTimePeriodLable = this.data.diagList.currentPeriod.fromDateLabel + ' ' + this._translateService.instant("ClaimCostDiag.DateRange") + ' ' + this.data.diagList.currentPeriod.toDateLabel;
                }
            }

        }
        this.data.finalTopListBind = this.datalist.slice(0, this.topFilterClaim);
        this.refreshDiagnoses();
        setTimeout(() => (this.parentLoaded = true), 100);
    }
    refreshDiagnoses() {
        var orderDiagList = this.dimensionOrderService
            .sortByColumn(this.datalistDiag, this.properties.sort)
            .reverse();
        this.data.orderDiagListTop = orderDiagList.slice(0, this.properties.claimTypeDiagnosisTopFilter);
        this.topDiagTotal = 0;
        this.datalistDiag.map(element => { this.topDiagTotal += element.claimsTotal; });
        this.maxClaimTotal = Math.max.apply(null, this.data.orderDiagListTop.map(a => { return a.claimsTotal; })
        );
        this.maxCount = Math.max.apply(null,
            this.data.orderDiagListTop.map(a => {
                return this.properties.claimTypeOption == ClaimantOption.Claim
                    ? a.claimsCount
                    : a.claimantCount;
            })
        );
        this.maxClaimCost = Math.max.apply(
            null,
            this.data.orderDiagListTop.map(a => {
                return this.properties.claimTypeOption == ClaimantOption.Claim
                    ? a.averageCostPerClaim
                    : a.averageCostPerClaimant;
            })
        );
        this.maxClaimTotal = this.maxClaimTotal + this.maxClaimTotal * 0.1;
        this.maxCount = this.maxCount + this.maxCount * 0.1;
        this.maxClaimCost = this.maxClaimCost + this.maxClaimCost * 0.1;

    }
    constructor(
        private _dataService: DataService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private elementRefClaimDiag: ElementRef,
        private httpClient: HttpClient,
        private dimensionOrderService: DimensionOrderService,
        private _numberFormatService: NumberFormattingService
    ) { }

    ngOnInit() {
        this._fuseTranslationLoaderService.loadTranslations(
          navigationEnglish,
          navigationSpanishCL,
          navigationSpanishCO,
          navigationSpanishEC,
          navigationSpanishMX,
          navigationSpanishPE,
          navigationPortugueseBR,
          navigationItalianIT,
        );

        if (this._translateService != null) {
            this._translateService.onLangChange.subscribe(
                (event: LangChangeEvent) => {
                    this.initData();
                }
            );
        }
    }
    ngOnDestroy() {
        //this._translateService.onLangChange.unsubscribe();
    }
    showHideFilter(val, filterName: string) {
        if (val == ShowHideOption.Hide) {
            this.topFilterClaim = 20;
        } else {
            this.topFilterClaim = 10;
        }
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
            data: {
                properties: {
                    name: filterName,
                    value: typeof val === 'object' ? val.data.join('||') : val
                }
            }
        });
        this.initData();
    }
    filterChanged(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
            data: {
                properties: {
                    name: filterName,
                    value: typeof val === 'object' ? val.data.join('||') : val
                }
            }
        });
        this.initData();
    }
    filterTopList() {
        this.initData();
    }
    selectClaim(event: any, data: any) {
        if (this.data.currentClaim === data.dimension) {
            this.resetAll();
        } else {
            this.isLoadingDiagnoses = true;
            this.data.currentClaim = data.dimension;
            this.data.shownAsPerCurrentClaim = data.dimension;
            this.data.shownAsPerCurrentDiagnosis = '';
            this.data.currentDiagnosis = '';
            this.properties.dimension =
                this.properties.diag == CategoryChapterOption.DiagCat
                    ? CategoryChapterOption.DiagCat
                    : CategoryChapterOption.DiagChapter;
            this.properties.secondaryDimension = this.claimType;
            this.properties.secondaryDimensionValue = data.dimension.replace(
                '\'',
                '\'\''
            );
            this._dataService
                .getTier3Data(this.properties)
                .toPromise()
                .then(re => {
                    this.data.diagList = re;
                    this.initData();
                    this.isLoadingDiagnoses = false;
                });
        }
    }
    selectDiagnosis(event: any, data: any) {
        if (this.data.currentDiagnosis == data.dimension) {
            this.resetAll();
        } else {
            this.isLoadingClaimType = true;
            this.data.currentClaim = '';
            this.data.currentDiagnosis = '';
            this.data.shownAsPerCurrentDiagnosis = data.dimension;
            this.properties.dimension = this.claimType;
            this.properties.secondaryDimension =
                this.properties.diag === CategoryChapterOption.DiagCat
                    ? CategoryChapterOption.DiagCat
                    : CategoryChapterOption.DiagChapter;
            this.properties.secondaryDimensionValue = this.data.currentDiagnosis.replace(
                '\'',
                '\'\''
            );
            this._dataService
                .getTier3Data(this.properties)
                .toPromise()
                .then(re2 => {
                    this.data.claimList = re2;
                    this.initData();
                    this.isLoadingClaimType = false;
                });
        }
    }
    selectionChanged(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED,
            data: {
                properties: {
                    name: filterName,
                    value: val
                }
            }
        });
    }
    resetAll() {
        this.isLoadingClaimType = true;
        this.isLoadingDiagnoses = true;
        this.data.currentDiagnosis = '';
        this.data.currentClaim = '';
        this.data.shownAsPerCurrentClaim = '';
        this.data.shownAsPerCurrentDiagnosis = '';
        this.properties.dimension = this.claimType;
        this.properties.secondaryDimension = '';
        this.properties.secondaryDimensionValue = '';
        this._dataService
            .getTier3Data(this.properties)
            .toPromise()
            .then(re => {
                this.data.claimList = re;
                this.properties.dimension = this.properties.diag == CategoryChapterOption.DiagCat
                    ? CategoryChapterOption.DiagCat
                    : CategoryChapterOption.DiagChapter;
                this._dataService.getTier3Data(this.properties)
                    .toPromise()
                    .then(res => {
                        this.data.diagList = res;
                        this.initData();
                        this.isLoadingClaimType = false;
                        this.isLoadingDiagnoses = false;
                    });
            });
    }
    diagnosisContainerSelectionChanged(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
            data: {
                properties: {
                    name: filterName,
                    value: typeof val === 'object' ? val.data.join('||') : val
                }
            }
        });
        this.resetAll();
    }
    filterChangedSort(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
            data: {
                properties: {
                    name: filterName,
                    value: typeof val === 'object' ? val.data.join('||') : val
                }
            }
        });
        if ((this.data.currentDiagnosis == '' && this.data.currentClaim === '')
            || (this.data.currentDiagnosis === '' && this.data.currentClaim !== '')) {
            this.refreshDiagnoses();
        }
        else if (this.data.currentClaim === '' && this.data.currentDiagnosis !== '') {
            this.resetAll();
        }

    }
    filterNAChange(val, filterName: string) {
        this.properties.isExcludeNA = !this.properties.isExcludeNA;
        let filterVal: any;
        if (this.properties.isExcludeNA === false) {
            filterVal = '';
        }
        else if (this.properties.isExcludeNA === true) {
            filterVal = ClaimantOption.Claimant.toLowerCase();
        }
        if (this.data.currentDiagnosis === '' && this.data.currentClaim === '') {
            this.action.emit({
                type: PulseEventName.FILTLER_CHANGED,
                data: {
                    properties: {
                        name: filterName,
                        value: filterVal
                    }
                }
            });
        } else {
            this.action.emit({
                type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
                data: {
                    properties: {
                        name: filterName,
                        value: typeof filterVal === 'object' ? filterVal.data.join('||') : filterVal
                    }
                }
            });
            if (this.data.currentDiagnosis === '' && this.data.currentClaim !== '') {
                this.isLoadingDiagnoses = true;
                this._dataService
                    .getTier3Data(this.properties)
                    .toPromise()
                    .then(re => {
                        this.data.diagList = re;
                        this.initData();
                        this.isLoadingDiagnoses = false;
                    });
            }

        }
    }
    additionalFilterSelectionChanged(val) {
        this.action.emit(val);
    }
}
export enum ShowHideOption {
    Show = 'Show',
    Hide = 'Hide'
}
export enum ClaimantOption {
    Claim = 'Claim',
    Claimant = 'Claimant'
}
export enum CategoryChapterOption {
    DiagCat = 'Diag Cat',
    DiagChapter = 'Diag Chapter'
}
export enum SortOption {
    ClaimTotal = 'claimsTotal',
    CountClaim = 'claimsCount',
    CountClaimant = 'claimantCount',
    AvergareCostlaim = 'averageCostPerClaim',
    AvergareCostlaimant = 'averageCostPerClaimant'
}
