import {
    Component,
    OnInit,
    ElementRef,
    Output,
    EventEmitter
} from '@angular/core';
import { DashboardTemplateBase } from '../dashboard-template-base.component';
import { DataService } from 'app/dashboards/data.service';
import { HttpClient } from '@angular/common/http';
import { formatNumber } from '@angular/common';
import { locale as navigationEnglish } from 'app/dashboards/templates/claims-overview/i18n/en';
import { locale as navigationSpanishCL } from 'app/dashboards/templates/claims-overview/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/dashboards/templates/claims-overview/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/dashboards/templates/claims-overview/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/dashboards/templates/claims-overview/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/dashboards/templates/claims-overview/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/dashboards/templates/claims-overview/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/dashboards/templates/claims-overview/i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { map, reduce } from 'rxjs/operators';
import { FilterOptions } from 'app/dashboards/shared/models/FilterOptions';
import { DimensionOrderService } from 'app/dashboards/dimension-order.service';
import { PulseEventName } from 'app/dashboards/shared/models/PulseEventName';
import { countryLATEM } from 'app/constants/countries_LATAM';
import { NumberFormattingService } from 'app/services/number-formatting.service';
import { CLAIM_BASIS } from 'app/models/basis.model';
import { COLOR_PALETTE } from 'app/constants/color-palette';

@Component({
    selector: 'app-claims-overview',
    templateUrl: './claims-overview.component.html',
    styleUrls: ['./claims-overview.component.css']
})
export class ClaimsOverviewComponent implements OnInit, DashboardTemplateBase {
    @Output() action = new EventEmitter<any>();
    ClaimTypeOption = ClaimTypeOption;
    PortfolioIndustryNoneOption = PortfolioIndustryNoneOption;

    isFilterOptionsLoading = true;

    imageUrlBase = '';
    data: any;
    properties: any;
    isAvgCost: boolean;
    showFilter: boolean = false;
    claimOverviewItemsGrid: any = [];
    BenchmarkInclude: number = 1;
    isIncludeClient: boolean = true;
    prior: any;
    current: any;
    priorDonutDataId: any = 'priorDonutDataid';
    currentDonutDataId: any = 'currentDonutDataid';
    priorDonutData: any;
    currentDonutData: any;
    donutData: any;
    totalPriorPercentDonutLabel: any;
    totalCurrentPercentDonutLabel: any;
    totalPriorPercent: any;
    totalCurrentPercent: any;
    param: string;
    filterOptions: FilterOptions = new FilterOptions();
    noneSelected = false;
    parentLoaded = false;
    hideDonutLabels: boolean = true;
    isRoundOffdonutValueEnabled: boolean = false;
    priorOverviews: overviewDataType[];
    isLoadingDonut = true;
    isLoadingOnFilterClick = false;
    dimensions: any = [];
    priorData: any = [];
    priorDataSorted: any = [];
    currentData: any = [];
    overviewPriorLegends: any = [];
    overviewCurrentLegends: any = [];
    legendColors: any = [];
    sampleDonutData: any;
    filterClick: string;
    currentpropertyID: string;
    priorpropertyID: string;
    currentTimePeriodLabel: string;
    priorTimePeriodLabel: string;
    currentDataTotal: any;
    priorDataTotal: any;
    topFilter = 10;
    currentComparablePeriod: string;
    priorComparablePeriod: string;
    CLAIM_BASIS = CLAIM_BASIS;

    constructor(
        private _dimensionOrderService: DimensionOrderService,
        private elementRefClaim: ElementRef,
        private _dataService: DataService,
        private httpClient: HttpClient,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _numberFormattingService: NumberFormattingService
    ) { }

    initData(): void {
        this.isLoadingOnFilterClick = true;
        this.isLoadingDonut = true;
        this.legendColors = COLOR_PALETTE;
        this.priorDonutData = { [this.properties.dimension]: {} };
        this.priorDataSorted = [...this.data.prior];

        this.currentDonutData = {
            [this.properties.dimension]: {}
        };

        if (typeof this.data.current !== 'undefined' && typeof this.data.prior !== 'undefined') {
            if (this.data.current.length > 0 && this.data.prior.length > 0) {
                const currentDimensionNameList = this.data.current.map(c => c.dimension);
                this.data.prior = this.data.prior.sort((prev: any, next: any): number => {
                    let prevSortIndex = currentDimensionNameList.indexOf(prev.dimension);
                    let nextSortIndex = currentDimensionNameList.indexOf(next.dimension);

                    if (prevSortIndex < 0) { prevSortIndex = 9999; }
                    if (nextSortIndex < 0) { nextSortIndex = 9999; }

                    return prevSortIndex - nextSortIndex;
                });
            }
        }

        if (typeof this.data.current === 'undefined') {
            this.currentData = [];
            this.currentDataTotal = [];
            return;
        } else {
            this.totalCurrentPercent = this.data.current.reduce(
                (a, b) => a + b.claimsTotal,
                0
            );

            const abbrevNumberString = this.isCountryLatem() ?
                this._numberFormattingService.ToAbbrevNumberStringLatem(this.totalCurrentPercent) :
                this._numberFormattingService.ToAbbrevNumberString(this.totalCurrentPercent);

            this.currentClaimsUnit =
                this.getAbbrevScale(abbrevNumberString);

            const currentDonutlist = (this.data.current).slice(0, this.topFilter);
            currentDonutlist.forEach((im, index) => {
                this.currentDonutData[this.properties.dimension][im.dimension] =
                    (im.claimsTotal / this.totalCurrentPercent) * 100;
                this.overviewCurrentLegends[index] = this.legendColors[index];
            });

            if (this.data.currentPeriod != null || this.data.currentPeriod !== '') {
                if (this.data.currentPeriod.fromDateLabel === this.data.currentPeriod.toDateLabel) {

                    const tempCurrentLabel = this.data.currentPeriod.fromDateLabel;
                    this.currentTimePeriodLabel = (tempCurrentLabel == null || typeof tempCurrentLabel === 'undefined') ? '' : tempCurrentLabel;
                    this.currentComparablePeriod = (tempCurrentLabel == null || typeof tempCurrentLabel === 'undefined') ? '' : this.properties.rollingPeriod === 3 ? ' (C)' : '';

                } else {

                    // const tempCurrentLable =  this.data.current.find(a => a.periodLable == null || a.periodLable === undefined  ? null : a.periodLable.length > 0) ;
                    const tempCurrentLabel = this.data.currentPeriod.fromDateLabel + ' ' + this._translateService.instant("ClaimsOverview.DateRange") + ' ' + this.data.currentPeriod.toDateLabel;
                    this.currentTimePeriodLabel = (tempCurrentLabel == null || typeof tempCurrentLabel === 'undefined') ? '' : tempCurrentLabel;
                    this.currentComparablePeriod = (tempCurrentLabel == null || typeof tempCurrentLabel === 'undefined') ? '' : this.properties.rollingPeriod === 3 ? ' (C)' : '';
                }

            }
            this.currentData = this.data.current;
            this.currentDataTotal = this.data.currentTotal;
            this.totalCurrentPercent = this.getAbbrevNumber(abbrevNumberString);
        }

        if (typeof this.data.prior === 'undefined') {
            this.priorData = [];
            this.priorDataTotal = [];
            return;
        } else {
            this.totalPriorPercent = this.data.prior.reduce(
                (a, b) => a + b.claimsTotal,
                0
            );

            const abbrevNumberString = this.isCountryLatem() ?
                this._numberFormattingService.ToAbbrevNumberStringLatem(this.totalPriorPercent) :
                this._numberFormattingService.ToAbbrevNumberString(this.totalPriorPercent);

            this.priorClaimsUnit = this.getAbbrevScale(abbrevNumberString);
            const priorDonutlist = (this.data.prior).slice(0, this.topFilter);
            priorDonutlist.forEach((im, index) => {
                this.priorDonutData[this.properties.dimension][im.dimension] = (im.claimsTotal / this.totalPriorPercent) * 100;
                this.overviewPriorLegends[index] = this.legendColors[index];
            });


            if (this.data.priorPeriod != null || this.data.priorPeriod != '') {
                let tempPriorLabel = '';

                if (this.data.priorPeriod.fromDateLabel === this.data.priorPeriod.toDateLabel) {
                    tempPriorLabel = this.data.priorPeriod.fromDateLabel;
                }
                else {
                    tempPriorLabel = this.data.priorPeriod.fromDateLabel + ' ' + this._translateService.instant("ClaimsOverview.DateRange") + ' ' + this.data.priorPeriod.toDateLabel;
                }

                this.priorTimePeriodLabel = (tempPriorLabel == null || typeof tempPriorLabel == 'undefined') ? '' : tempPriorLabel;
                this.priorComparablePeriod = (tempPriorLabel == null || typeof tempPriorLabel == 'undefined') ? '' : this.properties.rollingPeriod == 3 ? ' (C)' : '';
            }
            this.priorData = this.data.prior;
            this.priorDataTotal = this.data.priorTotal;
            this.totalPriorPercent = this.getAbbrevNumber(abbrevNumberString);
        }

        this.isLoadingOnFilterClick = false;
        this.isLoadingDonut = false;
        setTimeout(() => (this.parentLoaded = true), 100);
    }
    ngOnInit() {
        this._fuseTranslationLoaderService.loadTranslations(
          navigationEnglish,
          navigationSpanishCL,
          navigationSpanishCO,
          navigationSpanishEC,
          navigationSpanishMX,
          navigationSpanishPE,
          navigationPortugueseBR,
          navigationItalianIT,
        );
    }

    formatabs(num, dec) {
        let number = num
            ? num.toLocaleString(undefined, { maximumFractionDigits: dec })
            : '';
        return Math.abs(number);
    }

    format(num, dec) {
        let number = num
            ? num.toLocaleString(undefined, { maximumFractionDigits: dec })
            : '';
        return number;
    }

    selectionChanged(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED,
            data: {
                properties: {
                    name: filterName,
                    value: val
                }
            }
        });
    }

    currentClaimsUnit = '';
    priorClaimsUnit = '';

    ClaimsUnit = {
        M: 1000000,
        K: 1000
    };

    additionalFilterSelectionChanged(val) {
        this.action.emit(val);
    }

    isCountryLatem(): boolean {
        return countryLATEM.indexOf(this.properties.country) >= 0;
    }

    getAbbrevNumber(abbrev: string): string {
        if (abbrev.endsWith('B') || abbrev.endsWith('M') || abbrev.endsWith('K')) {
            return (abbrev.substring(0, abbrev.length - 1));
        }

        return (abbrev);
    }

    getAbbrevScale(abbrev: string): string {
        if (abbrev.endsWith('B') || abbrev.endsWith('M') || abbrev.endsWith('K')) {
            return abbrev.substring(abbrev.length - 1, abbrev.length);
        }

        return '';
    }
}

export class Category {
    name: string;
    current: any = {};
    prior: any = {};
}

export enum ClaimTypeOption {
    ClaimType = 'Claim Type',
    Relationship = 'Relationship',
    Entity = 'Entity',
    Network = 'Network',
    Location = 'Location',
}
export enum PortfolioIndustryNoneOption {
    Portfolio = 1,
    Industry = 2,
    None = 3
}
export interface overviewDataType {
    dimension: number;
    totalCost: number;
    dimColor: string;
}
