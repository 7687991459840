import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { Store } from '@ngrx/store';
import { State } from 'app/_reducers';
import { FWBService } from 'app/fwb/services/fwb.service';
import { User } from 'app/models/user.model';
import { navigation } from 'app/navigation/navigation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BenefitService } from 'app/fwb/services/benefit.service';

@Component({
  selector: 'question-card',
  templateUrl: './question-card.component.html',
  styleUrls: ['./question-card.component.scss']
})
export class QuestionCardComponent implements OnInit {
  chartId = "questionCardComponent";
  scoreCardheight = "750px";
  cardheightnumber = 660;
  categoryData: any[];
  questionsData: any[];
  questionsDetails: any[];
  selectedQuestion: number = 5;
  fwb: any;
  scoreCardwidth = "100%";
  maxbarheight = 80;
  categoryChartTitle = "Category Drilldown";
  fuseConfig: any;
  navigationlocal: any;

  colorindex: number = 3;
  contenttop: string = "70px";
  public user: User;

  serviceData: any;
  isLoading = true;
  isError = false;
  errorMsg = "No Data Found.";
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fwbService: FWBService,
    private _BenefitService: BenefitService,
    private store: Store<State>,
    private _router: Router
  ) {
    // Set the defaults
    this.navigationlocal = navigation;
  } 
  
  ngOnInit() { 
    this.getData(); 
  }

  getData() {
    this.serviceData = null;
    this._BenefitService.getScoreQuestions("").toPromise().then((resp) => {
      this.serviceData = resp;
      console.log(this.serviceData);
      this.setData();
      this.errorMsg = "";
      this.isError = false;
    }).catch(error => {
      this.isError = true;
      this.errorMsg = error.message;
    }).finally(() => {
      this.isLoading = false;
    })
  }

  setData() {
    this.categoryData = this.serviceData.resultQuestions;
  }
  ngOnChanges() {
    // this.changeQuestion(this.selectedQuestion);
  }

  getUser() {

  }
   
  expandParentHeight($event) {

    let expandBy: number = 0;
    let selectedCategories: number = 700;
    this.categoryData.forEach((a) => {
      if (a.name == $event.detail.text) {
        a.isexpanded = !a.isexpanded;
      }

      if (a.isexpanded == true) {
        selectedCategories += Math.floor(a.expandheight);
        expandBy += 1;
      }
    })
    if (expandBy > 1) {
      selectedCategories = expandBy * 2200;
    }
    // console.log("expand height called" + $event.detail.text);
    this.scoreCardheight = 800 + selectedCategories + "px";

  }

}
