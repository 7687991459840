import { Injectable } from '@angular/core';
import { UserService } from '../../services/user.service';
import { WelloneConstant } from '../models/welloneconstant.model';
import { WelloneParameterModel } from '../models/welloneparameter.model';
import { GlobalFilterService } from './globalfilter.service';

@Injectable({
  providedIn: 'root'
})
export class WelloneService {

  constructor(
    private _userService: UserService,
    private _globalFilterService: GlobalFilterService) { }

  getParamInfo(): WelloneParameterModel {
    const user = this._userService.user;

    const welloneParams = new WelloneParameterModel();
    welloneParams.Logged_uid = WelloneConstant.LoggedUID;
    welloneParams.Tenant_record_id = WelloneConstant.TennantRecordId;

    if (user.tbsUserId) {
      welloneParams.Logged_uid = user.tbsUserId;
    }

    if (user.client) {
      if (user.client.tbsClientId) {
        welloneParams.Tenant_record_id = user.client.tbsClientId;
      }
    }

    welloneParams.GlobalFilters = this._globalFilterService.GetGlobalFilterValues();

    return welloneParams;
  }

  
}
