export const locale = {
    lang: 'en',
    data: {
        'ClaimCostTopProvider': {
            'ClaimTopProviders':'Claims Cost - by Top Providers',
            'ClaimCostByTopDiagnoses':'Claims Cost - by Top Diagnoses',
            'ProviderDiag':'Provider vs Diagnosis',
            'AllAmountsDisplayedInLocalCurrency': 'All amounts displayed in local currency',
            'TopProvider':'Top Providers',
            'ProviderName':'Provider Name', 
            'TopDiagnoses':'Top Diagnoses',
            'DiagnosisName':'Diagnosis Name' ,          
            'TotalPaidClaim' : 'Total Paid Claims', 
            'ClaimantCount':'Claimant Count',
            'ClaimCount':'Claim Count',            
            'ClaimsPerClaimant' : 'Claims Per Claimant', 
            'CostPerClaim': 'Cost Per Claim',
            'CostPerClaimant': 'Cost Per Claimant', 
            '%ChangeinCost':'% Change in Cost',
            'Claim' : 'Claim', 
            'Claimant' : 'Claimant', 
            'ClaimsTotal':'Claims Total',
            'Count':'Count',
            'AverageCost':'Average Cost',
            'ClaimsReportedByPaidDate': 'Claims reported by paid date',
            'ClaimsReportedByIncurredDate': 'Claims reported by incurred date',
            'Benchmark':'Benchmark',
            'IncludeClient': 'Include Client',
            'ExcludeClient': 'Exclude Client',
            'Portfolio': 'Portfolio',
            'Industry': 'Industry',
            'None': 'None',
            'SelectTop':'Select Top',
            'FilterNA':'Exclude NA',
            'CountBasis':'Count Basis',
            'Sort':'Sort',
            'Reset':'Reset',
            'DiagnosisLevel':'Diagnosis Level',
            'Category':'Category',
            'Chapter':'Chapter',          
            'ShownAsPer':'Shown as per',
            'DateRange':'to'

        }
    }
};
