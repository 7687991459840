import { Component, Input, OnChanges, SimpleChanges, OnInit, } from "@angular/core";

@Component({
    selector: "demographicsChartsComponent",
    templateUrl: "./demographicsChart.component.html",
    styleUrls: ["./demographicsChart.component.scss"],
})
export class DemographicsChartsComponent implements OnInit, OnChanges {
    @Input() demographicsData: any;
    @Input() view: any[] = [1300, 350];
    @Input() title: string = "";
    @Input() scaleMax = 100;
    @Input() keys: [];
    @Input() legendtitle: [];
    chartId = "FinancialWellbeing";
    height: any = "430px";
    width: any = "97%";
    cardheight: any = "520px";
    cardwidth: any = "97%";
    tooltip: boolean = true;
    resize: boolean = true;
    titleposition = "left";
    charttitle = "Financial Wellbeing Score";
    yaxistitle = "";
    color = "white";
    maxyaxisnumber: number = 1000;
    data: any;
    fuseConfig: any;
    navigation: any;
    emptyChartTitle = " ";
    public multi: any[] = [];
    public categorycolors: any[] = [0, 2, 4];
    tickformat : string = "0.0f";
    // options
    showXAxis: boolean = true;
    showYAxis: boolean = true;
    gradient: boolean = false;
    showLegend: boolean = true;
    showXAxisLabel: boolean = true;
    xAxisLabel: string = "";
    showYAxisLabel: boolean = true;
    barPadding = 10;
    yAxisLabel: string = "";
    yScaleMax = 0.2;
    showDataLabel = true;
    colorScheme = {
        domain: [
            "#086972",
            "#01a9b4",
            "#87dfd6",
            "#184d47",
            "#96bb7c",
            "#eebb4d",
            "#ffa600",
        ],
    };

    constructor() {
        Object.assign(this.multi);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            this.reloadChart();
        }
    }

    ngOnInit() {
        this.reloadChart();
    }

    public reloadChart() {
        this.yScaleMax = this.scaleMax;
        this.multi = this.demographicsData;
        console.log("this.multi", this.multi);
        console.log("yScaleMax", this.yScaleMax);
        /* if(this.multi.length > 0) {
        this.xAxisLabel = this.multi[0].name;
    } */
        Object.assign(this.multi);
    }

    onSelect(data): void {
        console.log("Item clicked", JSON.parse(JSON.stringify(data)));
    }

    onActivate(data): void {
        // console.log('Activate', JSON.parse(JSON.stringify(data)));
    }

    onDeactivate(data): void {
        // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }
}
