<div
    id="superAdminComp"
    fxLayout="column"
    fxFlexFill
    fxLayoutGap="12px"
    class="contentPadding"
>
    <div id="superAdminHeader">
        <h1 class="text-mono">{{ "SuperAdmin.Settings" | translate }}</h1>
        <p>{{ "SuperAdmin.HelpText" | translate }}</p>
    </div>
    <div fxLayout="row" fxLayoutGap="12px">
        <div
            id="superAdminBtnList"
            fxFlex="20"
            style="padding: 0px"
            fxLayout="column"
        >
            <button
                id="superAdminBtnUsers"
                mat-button
                (click)="settingClick('users')"
                style="text-align: left; font-size: 12px"
                [ngClass]="mode == 'users' ? 'mat-accent' : ''"
            >
                {{ "SuperAdmin.Users" | translate }}
            </button>
            <button
                *ngIf="isAdmin"
                id="superAdminBtnCountries"
                mat-button
                (click)="settingClick('countries')"
                style="text-align: left; font-size: 12px"
                [ngClass]="mode == 'countries' ? 'mat-accent' : ''"
            >
                {{ "SuperAdmin.Countries" | translate }}
            </button>
            <button
                id="superAdminBtnClients"
                mat-button
                (click)="settingClick('clients')"
                style="text-align: left; font-size: 12px"
                [ngClass]="mode == 'clients' ? 'mat-accent' : ''"
            >
                {{ "SuperAdmin.Clients" | translate }}
            </button>
            <button
                *ngIf="isAdmin"
                id="superAdminBtnRegional"
                mat-button
                (click)="settingClick('rm')"
                style="text-align: left; font-size: 12px"
                [ngClass]="mode == 'rm' ? 'mat-accent' : ''"
            >
                {{ "SuperAdmin.RegionalManagers" | translate }}
            </button>
            <button
                *ngIf="isAdmin"
                id="superAdminBtnTableu"
                mat-button
                (click)="settingClick('tableauprojects')"
                style="text-align: left; font-size: 12px"
                [ngClass]="mode == 'tableauprojects' ? 'mat-accent' : ''"
            >
                Tableau Projects
            </button>
            <button
                *ngIf="isAdmin"
                id="superAdminBtnOffline"
                mat-button
                (click)="settingClick('offline')"
                style="text-align: left; font-size: 12px"
                [ngClass]="mode == 'offline' ? 'mat-accent' : ''"
            >
                Offline
            </button>
            <button
                id="superAdminBtnSite"
                mat-button
                (click)="settingClick('site')"
                style="text-align: left; font-size: 12px"
                [ngClass]="mode == 'site' ? 'mat-accent' : ''"
            >
                Site
            </button>
            <button
                id="superAdminBtnBenchmarkLimit"
                mat-button
                (click)="settingClick('benchmarkLimit')"
                style="text-align: left; font-size: 12px"
                [ngClass]="mode == 'site' ? 'mat-accent' : ''"
            >
                Benchmark Settings
            </button>
            <mat-divider></mat-divider>
        </div>
        <mat-card
            id="superAdminRegionalMang"
            fxFlex="40"
            fxLayout="column"
            *ngIf="mode == 'rm'"
        >
            <strong>{{ "SuperAdmin.RegionalManagers" | translate }}</strong>
            <div fxLayout="row">
                <app-search-bar
                    [searchResults]="searchUserResults"
                    fxFlex
                ></app-search-bar>
                <button
                    id="superAdminBtnRegAdd"
                    mat-button
                    class="mat-accent fixed-height"
                    (click)="addRm()"
                    style="margin-top: 12px"
                >
                    {{ "SuperAdmin.Add" | translate }}
                </button>
            </div>
            <div
                id="superAdminBtnRegGrid"
                style="overflow: hidden; min-height: 400px"
            >
                <agcustom-grid
                    style="height: 300px"
                    [dataList]="rmList"
                    [columnDefs]="rmColumnDefs"
                    [delete]="true"
                    (selectionChanged)="onRmSelectionChanged($event)"
                    (onDelete)="onRmDelete($event)"
                >
                </agcustom-grid>
            </div>
        </mat-card>
        <mat-card
            id="superAdminUser"
            fxFlex="40"
            fxLayout="column"
            *ngIf="mode == 'users'"
        >
            <div fxLayout="row">
                <strong>{{ "SuperAdmin.Users" | translate }}</strong>
                <span fxFlex></span>
                <mat-spinner
                    *ngIf="isLoadingCountry"
                    class="mat-accent"
                    diameter="30"
                ></mat-spinner>
                <button
                    id="superAdminBtnCreate"
                    mat-button
                    class="mat-accent fixed-height"
                    (click)="addUser()"
                >
                    {{ "SuperAdmin.CreateAccount" | translate }}
                </button>
            </div>
            <div
                id="superAdminUserGrid"
                style="overflow: hidden; min-height: 400px"
            >
                <agcustom-grid
                    [dataList]="userList"
                    (selectionChanged)="onUserSelectionChanged($event)"
                    [columnDefs]="userColumnDefs"
                    [pagination]="true"
                >
                </agcustom-grid>
            </div>
        </mat-card>
        <mat-card
            id="superAdminRoles"
            fxFlex="40"
            style="height: 600px"
            *ngIf="isEditUser && mode == 'users'"
        >
            <strong>{{ "SuperAdmin.Roles" | translate }} : </strong
            >{{ selectedUserRow.email }} ({{
                selectedUserRow.userName
            }})<br /><br />
            <span
                >Assign country managers and regional managers with country
                assignments in other admin screens.</span
            >
            <br /><br />
            <div id="superAdminRadioRole">
                <mat-radio-group
                    [(ngModel)]="selectedUserRole"
                    class="userRole-radio-group"
                    fxLayout="column"
                >
                    <mat-radio-button
                        class="userRole-radio-button"
                        [checked]="
                            role.name.toString() ===
                            selectedUserRow.role.toString()
                        "
                        [disabled]="
                            role.name === 'countrymanager' ||
                            role.name === 'regionalmanager' ||
                            !isAdmin ||
                            (role.name === 'admin' &&
                                selectedUserRow.email ===
                                    selectedUserRow.userName)
                        "
                        *ngFor="let role of userRoleList"
                        [value]="role.name"
                    >
                        {{ role.displayName }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <br />
            <div id="superAdminRoleBtns" fxLayout="row">
                <mat-spinner
                    *ngIf="userRoleSaveLoading"
                    class="mat-accent"
                    diameter="30"
                ></mat-spinner>
                <span
                    style="overflow: hidden; overflow-wrap: break-word"
                    fxFlex
                    >{{ message }}</span
                >
                <button
                    id="superAdminBtnCancelRole"
                    mat-button
                    [disabled]="userRoleSaveLoading || !isAdmin"
                    class="mat-accent fixed-height"
                    style="margin-top: 12px"
                    (click)="closeCard()"
                >
                    {{ "SuperAdmin.Cancel" | translate }}
                </button>
                <button
                    id="superAdminBtnSaveRole"
                    mat-raised-button
                    class="mat-accent fixed-height"
                    style="margin-top: 12px"
                    [disabled]="userRoleSaveLoading || !isAdmin"
                    (click)="saveUserRole()"
                >
                    {{ "SuperAdmin.Save" | translate }}
                </button>
            </div>
            <br *ngIf="profile.superadmin.users.isTBSUserIdShown" />
            <mat-divider
                *ngIf="profile.superadmin.users.isTBSUserIdShown"
            ></mat-divider>
            <br *ngIf="profile.superadmin.users.isTBSUserIdShown" />
            <mat-form-field
                *ngIf="profile.superadmin.users.isTBSUserIdShown"
                style="width: 100%"
            >
                <input
                    fxFlex
                    [disabled]="
                        isTbsUserIdInputLoading ||
                        (!isAdmin && selectedUserRole === 'admin')
                    "
                    matInput
                    placeholder="TBS User Id"
                    class="mat-primary"
                    [(ngModel)]="tbsUserId"
                    autocomplete="off"
                />
            </mat-form-field>
            <br *ngIf="profile.superadmin.users.isTBSUserIdShown" />
            <div
                fxLayout="row"
                *ngIf="profile.superadmin.users.isTBSUserIdShown"
            >
                <mat-spinner
                    *ngIf="isTbsUserIdInputLoading"
                    class="mat-accent"
                    diameter="30"
                ></mat-spinner>
                <span fxFlex></span>
                <button
                    id="superAdminBtnCancelTbsUserId"
                    [disabled]="
                        isTbsUserIdInputLoading ||
                        (!isAdmin && selectedUserRole === 'admin')
                    "
                    mat-button
                    [disabled]="userRoleSaveLoading"
                    class="mat-accent fixed-height"
                    style="margin-top: 12px"
                    (click)="closeCard()"
                >
                    {{ "SuperAdmin.Cancel" | translate }}
                </button>
                <button
                    id="superAdminBtnSaveTbsUserId"
                    [disabled]="
                        isTbsUserIdInputLoading ||
                        (!isAdmin && selectedUserRole === 'admin')
                    "
                    mat-raised-button
                    class="mat-accent fixed-height"
                    style="margin-top: 12px"
                    [disabled]="userRoleSaveLoading"
                    (click)="saveTbsUserId()"
                >
                    {{ "SuperAdmin.Save" | translate }}
                </button>
            </div>
            <br
                *ngIf="
                    selectedUserRow &&
                    isAdmin &&
                    selectedUserRow.role.toString() !== 'admin'
                "
            />
            <mat-divider
                *ngIf="
                    selectedUserRow &&
                    isAdmin &&
                    selectedUserRow.role.toString() !== 'admin'
                "
            ></mat-divider>
            <br
                *ngIf="
                    selectedUserRow &&
                    isAdmin &&
                    selectedUserRow.role.toString() !== 'admin'
                "
            />
            <strong
                *ngIf="
                    selectedUserRow &&
                    isAdmin &&
                    selectedUserRow.role.toString() !== 'admin'
                "
                >{{ "SuperAdmin.DeleteAccount" | translate }}: </strong
            ><span
                *ngIf="
                    selectedUserRow &&
                    isAdmin &&
                    selectedUserRow.role.toString() !== 'admin'
                "
                >{{ selectedUserRow.email }} ({{
                    selectedUserRow.userName
                }})</span
            >
            <mat-spinner
                *ngIf="userDeleteLoading"
                class="mat-accent"
                diameter="30"
            ></mat-spinner>
            <br
                *ngIf="
                    selectedUserRow &&
                    isAdmin &&
                    selectedUserRow.role.toString() !== 'admin'
                "
            /><br *ngIf="isAdmin" />
            <div
                fxLayout="row"
                *ngIf="
                    selectedUserRow &&
                    isAdmin &&
                    selectedUserRow.role.toString() !== 'admin'
                "
            >
                <button
                    id="superAdminBtnDeleteUser"
                    mat-raised-button
                    class="mat-accent fixed-height"
                    style="margin-top: 12px"
                    [disabled]="userDeleteLoading || !isAdmin"
                    (click)="deleteUser()"
                >
                    {{ "SuperAdmin.Delete" | translate }}
                </button>
            </div>
        </mat-card>
        <!-- user create -->
        <mat-card
            id="superAdminUserCreate"
            fxFlex="40"
            style="min-height: 600px"
            fxLayout="column"
            *ngIf="isNewUser && mode == 'users'"
        >
            <strong>{{ "SuperAdmin.User" | translate }}</strong
            ><br />
            <div fxLayout="row">
                <mat-radio-group
                    id="superAdminRadioInEx"
                    [disabled]="userSaveLoading"
                    [(ngModel)]="userModelObj.isExternal"
                    (change)="resetUserCard()"
                >
                    <mat-radio-button
                        class="example-radio-button"
                        [value]="false"
                    >
                        Internal Aon &nbsp;
                    </mat-radio-button>
                    <mat-radio-button
                        class="example-radio-button"
                        [value]="true"
                    >
                        External &nbsp;
                    </mat-radio-button>
                </mat-radio-group>
                <span fxFlex></span>
                <mat-radio-group
                    id="superAdminRadioUserEmail"
                    *ngIf="!userModelObj.isExternal"
                    [disabled]="userSaveLoading"
                    [(ngModel)]="isAONEmail"
                    (change)="resetUserCard()"
                >
                    <mat-radio-button
                        class="example-radio-button"
                        [value]="false"
                    >
                        User Name &nbsp;
                    </mat-radio-button>
                    <mat-radio-button
                        class="example-radio-button"
                        [value]="true"
                    >
                        Email
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <br />
            <br />
            <mat-form-field
                id="superAdminInputID"
                *ngIf="!isAONEmail && !userModelObj.isExternal"
            >
                <input
                    [disabled]="userSaveLoading"
                    matInput
                    [placeholder]="
                        !isAONEmail && !userModelObj.isExternal
                            ? 'Aon ID'
                            : 'EXTAD ID'
                    "
                    class="mat-primary"
                    [(ngModel)]="userModelObj.userName"
                    autocomplete="off"
                />
            </mat-form-field>

            <mat-form-field
                id="superAdminInputEmail"
                *ngIf="isAONEmail || userModelObj.isExternal"
            >
                <input
                    [disabled]="userSaveLoading"
                    matInput
                    placeholder="Email"
                    class="mat-primary"
                    [(ngModel)]="userModelObj.email"
                    autocomplete="off"
                />
            </mat-form-field>

            <!-- <mat-form-field id="superAdminInputPass" *ngIf="userModelObj.isExternal && !profile.superadmin.users.isCreateExternalUserExtad">
        <input [disabled]="userSaveLoading" matInput placeholder="Password" class="mat-primary"
          [(ngModel)]="userModelObj.password" autocomplete="off" [type]="!togglePW ? 'password' : 'text'">
        <mat-icon matSuffix (click)="togglePW = !togglePW">{{!togglePW ? 'visibility_off' : 'visibility_on'}}</mat-icon>
      </mat-form-field> -->

            <div fxLayout="row">
                <mat-spinner
                    *ngIf="userSaveLoading"
                    class="mat-accent"
                    diameter="30"
                ></mat-spinner>
                <span
                    style="overflow: hidden; overflow-wrap: break-word"
                    fxFlex
                    >{{ message }}</span
                >
                <button
                    id="superAdminBtnUserCancel"
                    mat-button
                    [disabled]="userSaveLoading"
                    class="mat-accent fixed-height"
                    style="margin-top: 12px"
                    (click)="closeCard()"
                >
                    {{ "SuperAdmin.Cancel" | translate }}
                </button>
                <button
                    id="superAdminBtnUserSave"
                    mat-raised-button
                    class="mat-accent fixed-height"
                    style="margin-top: 12px"
                    [disabled]="
                        userEmail === '' ||
                        userEmail === null ||
                        userSaveLoading
                    "
                    (click)="saveUser()"
                >
                    {{ "SuperAdmin.Save" | translate }}
                </button>
            </div>
            <br />
            <div
                id="superAdminExternal"
                *ngIf="!userModelObj.isExternal"
                fxLayout="column"
            >
                <mat-divider></mat-divider> <br />
                <strong>Bulk Create</strong><br />
                Enter comma delimited user emails/id <br />
                <div id="superAdminExUsEmail" fxLayout="row">
                    <span fxFlex></span>
                    <mat-radio-group
                        *ngIf="!userModelObj.isExternal"
                        [disabled]="userSaveLoading"
                        [(ngModel)]="isAONEmail"
                        (change)="resetUserCard()"
                    >
                        <mat-radio-button
                            class="example-radio-button"
                            [value]="false"
                        >
                            User Name &nbsp;
                        </mat-radio-button>
                        <mat-radio-button
                            class="example-radio-button"
                            [value]="true"
                        >
                            Email
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-form-field id="superAdminTxtAreauser">
                    <mat-label>Users</mat-label>
                    <textarea
                        matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="5"
                        cdkAutosizeMaxRows="10"
                        [(ngModel)]="bulkUserTextArea"
                    ></textarea>
                </mat-form-field>
                <div fxLayout="row" id="superAdminSaveLoad">
                    <mat-spinner
                        *ngIf="userSaveLoading"
                        class="mat-accent"
                        diameter="30"
                    ></mat-spinner>
                    <span
                        style="overflow: hidden; overflow-wrap: break-word"
                        fxFlex
                        >{{ bulkmessage }}</span
                    ><br />
                    <button
                        id="superAdminBtnLoadCancel"
                        mat-button
                        [disabled]="userSaveLoading"
                        class="mat-accent fixed-height"
                        style="margin-top: 12px"
                        (click)="closeCard()"
                    >
                        {{ "SuperAdmin.Cancel" | translate }}
                    </button>
                    <button
                        id="superAdminBtnLoadSave"
                        mat-raised-button
                        class="mat-accent fixed-height"
                        style="margin-top: 12px"
                        [disabled]="
                            userEmail === '' ||
                            userEmail === null ||
                            userSaveLoading
                        "
                        (click)="saveUserBulk()"
                    >
                        {{ "SuperAdmin.Save" | translate }} BULK
                    </button>
                </div>
                <span
                    *ngFor="let bm of bulkmessageList"
                    style="overflow: hidden; overflow-wrap: break-word"
                    >{{ bm }}</span
                >
            </div>
        </mat-card>

        <mat-card
            id="superAdminClient"
            fxFlex="40"
            fxLayout="column"
            *ngIf="mode == 'clients'"
        >
            <div fxLayout="row">
                <strong>{{ "SuperAdmin.Clients" | translate }}</strong>
                <span fxFlex></span>
                <button
                    id="superAdminBtnNewClient"
                    mat-button
                    class="mat-accent fixed-height"
                    (click)="addNewClient()"
                >
                    {{ "SuperAdmin.Create" | translate }}
                </button>
            </div>
            <div id="superAdminGridClient" style="overflow: hidden">
                <agcustom-grid
                    [dataList]="clientList"
                    [columnDefs]="clientColumnDefs"
                    [delete]="isAdmin"
                    [pagination]="true"
                    [select]="false"
                    (selectionChanged)="onClientSelectionChanged($event)"
                    (onDelete)="onClientDelete($event)"
                >
                </agcustom-grid>
            </div>
        </mat-card>
        <div
            id="superAdminNewClient"
            fxFlex="40"
            fxLayoutGap="12px"
            fxLayout="column"
            *ngIf="isNewClient && mode == 'clients'"
        >
            <mat-card fxLayout="column">
                <strong>{{ "SuperAdmin.NewClient" | translate }}</strong
                ><br />
                <div fxLayout="row">
                    <mat-form-field fxFlex id="superAdminInputName">
                        <strong>
                            <input
                                matInput
                                placeholder="{{
                                    'SuperAdmin.Name' | translate
                                }}"
                                class="mat-primary"
                                [(ngModel)]="clientName"
                                (ngModelChange)="
                                    clientName = $event.toUpperCase()
                                "
                                [ngModelOptions]="{ standalone: true }"
                            />
                        </strong>
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                    <div>
                        {{ message }}
                    </div>
                    <div fxFlex></div>
                    <mat-spinner
                        *ngIf="isClientSaveLoad"
                        class="mat-accent"
                        diameter="30"
                    ></mat-spinner>
                    <button
                        id="superAdminBtnCancelNewClient"
                        mat-button
                        class="mat-accent fixed-height"
                        (click)="closeCard()"
                    >
                        {{ "SuperAdmin.Cancel" | translate }}
                    </button>
                    <button
                        id="superAdminBtnSaveNewClient"
                        mat-raised-button
                        class="mat-accent fixed-height"
                        [disabled]="clientName === '' || clientName === null"
                        (click)="saveClients()"
                    >
                        {{ "SuperAdmin.Save" | translate }}
                    </button>
                </div>
            </mat-card>
            <mat-card>
                <div id="bulkclient" style="width: 100%" fxLayout="column">
                    <strong>Bulk Create</strong><br />
                    Enter client names in separate lines<br />
                    <mat-form-field id="superAdminTxtClient">
                        <mat-label>Client Names</mat-label>
                        <textarea
                            matInput
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="5"
                            cdkAutosizeMaxRows="10"
                            [(ngModel)]="bulkClientTextArea"
                        ></textarea>
                    </mat-form-field>
                    <div fxLayout="row">
                        <mat-spinner
                            *ngIf="userSaveLoading"
                            class="mat-accent"
                            diameter="30"
                        ></mat-spinner>
                        <span
                            style="overflow: hidden; overflow-wrap: break-word"
                            fxFlex
                            >{{ bulkmessage }}</span
                        ><br />
                        <button
                            id="superAdminBtnCloseCard"
                            mat-button
                            [disabled]="isClientSaveLoad"
                            class="mat-accent fixed-height"
                            style="margin-top: 12px"
                            (click)="closeCard()"
                        >
                            {{ "SuperAdmin.Cancel" | translate }}
                        </button>
                        <button
                            id="superAdminBtnBulk"
                            mat-raised-button
                            class="mat-accent fixed-height"
                            style="margin-top: 12px"
                            [disabled]="
                                userEmail === '' ||
                                userEmail === null ||
                                isClientSaveLoad
                            "
                            (click)="saveClientBulk()"
                        >
                            {{ "SuperAdmin.Save" | translate }} BULK
                        </button>
                    </div>
                    <span
                        *ngFor="let bm of bulkmessageList"
                        style="overflow: hidden; overflow-wrap: break-word"
                        >{{ bm }}</span
                    >
                </div>
            </mat-card>
        </div>
        <div
            fxFlex="80"
            fxLayout="column"
            *ngIf="mode == 'countries'"
            fxLayoutGap="12px"
        >
            <div fxLayout="row" fxFlex fxLayoutGap="12px">
                <mat-card
                    id="superAdminCountries"
                    fxFlex="50"
                    fxLayout="column"
                    *ngIf="mode == 'countries'"
                    fxLayoutGap="12px"
                >
                    <div fxLayout="row">
                        <strong>{{
                            "SuperAdmin.Countries" | translate
                        }}</strong>
                        <span fxFlex></span>
                        <button
                            id="superAdminBtnAddNewCountry"
                            mat-button
                            class="mat-accent fixed-height"
                            (click)="addNewCountry()"
                        >
                            {{ "SuperAdmin.Create" | translate }}
                        </button>
                    </div>
                    <div
                        id="superAdminGridCountryList"
                        style="overflow: hidden"
                    >
                        <agcustom-grid
                            [dataList]="countryList"
                            [columnDefs]="countryViewColumnDefs"
                            [select]="false"
                            [delete]="true"
                            [pagination]="true"
                            [paginationPageSize]="5"
                            (selectionChanged)="
                                onCountrySelectionChange($event)
                            "
                            (onDelete)="onCountryDelete($event)"
                        >
                        </agcustom-grid>
                    </div>
                </mat-card>
                <div
                    id="superAdminEnable"
                    fxLayout="column"
                    fxFlex="50"
                    *ngIf="isNewCountry && mode == 'countries'"
                    fxLayoutGap="12px"
                >
                    <mat-card
                        *ngIf="selectedCountryId !== 0"
                        style="height: 100px"
                        fxLayout="column"
                    >
                        <span id="superAdminEnableChk">
                            <strong style="margin-right: 20px">{{
                                "SuperAdmin.EnableCountry" | translate
                            }}</strong>
                            <uui-checkbox
                                [checked]="selectedCountryEnabled"
                                (change)="
                                    selectedCountryEnabled =
                                        !selectedCountryEnabled
                                "
                            ></uui-checkbox>
                        </span>
                        <br />
                        <div fxLayout="row">
                            <div fxFlex></div>
                            <mat-spinner
                                *ngIf="isCountrySaveLoad"
                                class="mat-accent"
                                diameter="30"
                            ></mat-spinner>
                            <button
                                id="superAdminBtnUpdate"
                                mat-button
                                class="mat-accent fixed-height"
                                (click)="setCountryEnabledStatus()"
                            >
                                <span *ngIf="selectedCountryId !== 0">{{
                                    "SuperAdmin.Update" | translate
                                }}</span>
                            </button>
                        </div>
                    </mat-card>

                    <mat-card
                        id="superAdminBtnNewCountry"
                        style="height: 300px"
                        fxLayout="column"
                        fxFlex
                    >
                        <span *ngIf="selectedCountryId === 0"
                            ><strong>{{
                                "SuperAdmin.NewCountry" | translate
                            }}</strong></span
                        >
                        <span *ngIf="selectedCountryId !== 0"
                            ><strong>{{
                                "SuperAdmin.Update" | translate
                            }}</strong></span
                        >
                        <br />
                        <mat-form-field id="superAdminInputCountryName">
                            <strong>
                                <input
                                    matInput
                                    placeholder="{{
                                        'SuperAdmin.Name' | translate
                                    }}"
                                    class="mat-primary"
                                    [(ngModel)]="countryName"
                                    [ngModelOptions]="{ standalone: true }"
                                />
                            </strong>
                        </mat-form-field>
                        <br />
                        <mat-form-field
                            id="superAdminTabProjects"
                            *ngIf="false"
                        >
                            <mat-select
                                placeholder="Tableau Project"
                                [(ngModel)]="countryTableauProjectId"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                <mat-option
                                    *ngFor="let p of tableauProjects"
                                    [value]="p.tableauProjectId"
                                >
                                    {{ p.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <br />
                        <div fxLayout="row">
                            <div fxLayout>
                                {{ message }}
                            </div>
                            <div fxFlex></div>
                            <mat-spinner
                                *ngIf="isCountrySaveLoad"
                                class="mat-accent"
                                diameter="30"
                            ></mat-spinner>
                            <button
                                mat-button
                                class="mat-accent fixed-height"
                                [disabled]="countryName === ''"
                                (click)="closeCard()"
                            >
                                {{ "SuperAdmin.Cancel" | translate }}
                            </button>
                            <button
                                id="superAdminBtnTabSaveCountry"
                                mat-button
                                class="mat-accent fixed-height"
                                (click)="saveCountry()"
                            >
                                <span *ngIf="selectedCountryId === 0">{{
                                    "SuperAdmin.Save" | translate
                                }}</span>
                                <span *ngIf="selectedCountryId !== 0">{{
                                    "SuperAdmin.Update" | translate
                                }}</span>
                            </button>
                        </div>
                    </mat-card>

                    <!--             <mat-card fxLayout="column">
                    <div fxLayout="row">
                    <strong>{{countryName}} {{ 'SuperAdmin.Clients' | translate }}</strong>
                    <span fxFlex></span>
                    <mat-spinner *ngIf="isCountrySaveLoad" class="mat-accent" diameter="30"></mat-spinner>
                    <button id="superAdminBtnGetClientsByCountry" mat-button class="mat-accent fixed-height" (disabled)="isCountrySaveLoad"
                        (click)="getClientsByCountry()">
                        RESET
                    </button>
                    <button id="superAdminBtnSaveCountryClients" mat-raised-button class="mat-accent fixed-height" (disabled)="isCountrySaveLoad"
                        (click)="saveCountryClients()">
                        SAVE
                    </button>
                    </div>
                    <agcustom-grid id="superAdminGridCountryClient" [dataList]="countryClientList" [columnDefs]="countryClientColumnDefs" [pagination]="true"
                    [select]="true" (selectionChanged)="onCountryClientSelectionChanged($event)"
                    (onDelete)="onClientDelete($event)">
                    </agcustom-grid>
                </mat-card> -->
                </div>
            </div>
            <div fxLayout="row" fxFlex fxLayoutGap="12px">
                <div *ngIf="isNewCountry && mode == 'countries'" fxFlex>
                    <mat-card fxLayout="column">
                        <div fxLayout="row">
                            <strong
                                >{{ countryName }}
                                {{ "SuperAdmin.Clients" | translate }}</strong
                            >
                            <span fxFlex></span>
                            <mat-spinner
                                *ngIf="isCountrySaveLoad"
                                class="mat-accent"
                                diameter="30"
                            ></mat-spinner>
                            <button
                                id="superAdminBtnGetClientsByCountry"
                                mat-button
                                class="mat-accent fixed-height"
                                (disabled)="(isCountrySaveLoad)"
                                (click)="getClientsByCountry()"
                            >
                                RESET
                            </button>
                            <button
                                id="superAdminBtnSaveCountryClients"
                                mat-raised-button
                                class="mat-accent fixed-height"
                                (disabled)="(isCountrySaveLoad)"
                                (click)="saveCountryClients()"
                            >
                                SAVE
                            </button>
                        </div>
                        <agcustom-grid
                            id="superAdminGridCountryClient"
                            [dataList]="countryClientList"
                            [columnDefs]="countryClientColumnDefs"
                            [pagination]="true"
                            [select]="true"
                            (onDelete)="onClientDelete($event)"
                        >
                        </agcustom-grid>
                    </mat-card>
                </div>
            </div>
        </div>

        <div
            id="superAdminMode"
            fxFlex="40"
            fxLayout="column"
            fxLayoutGap="12px"
            *ngIf="
                (mode == 'rm' && selectedRm) ||
                (mode == 'clients' && selectedClient)
            "
        >
            <mat-card
                fxLayout="column"
                *ngIf="mode == 'clients' && selectedClient"
            >
                <span *ngIf="isAdmin"
                    ><strong>{{
                        "SuperAdmin.Update" | translate
                    }}</strong></span
                >
                <br *ngIf="isAdmin" />
                <mat-form-field id="superAdminModeInput" *ngIf="isAdmin">
                    <strong>
                        <input
                            matInput
                            placeholder="{{ 'SuperAdmin.Name' | translate }}"
                            class="mat-primary"
                            [(ngModel)]="clientName"
                            (ngModelChange)="
                                clientName = clientName.toUpperCase()
                            "
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </strong>
                </mat-form-field>
                <div fxLayout="row" *ngIf="isAdmin">
                    <span fxFlex></span>
                    <button
                        id="superAdminModeBtnCancel"
                        mat-button
                        (disabled)="(isClientSaveLoad)"
                        class="mat-accent fixed-height"
                        (click)="closeCard()"
                    >
                        CANCEL
                    </button>
                    <button
                        id="superAdminModeBtnUpdate"
                        mat-raised-button
                        (disabled)="(isClientSaveLoad)"
                        class="mat-accent fixed-height"
                        (click)="saveClients()"
                    >
                        UPDATE
                    </button>
                </div>
                <br *ngIf="profile.superadmin.clients.isTBSClientIdShown" />
                <br *ngIf="profile.superadmin.clients.isTBSClientIdShown" />
                <mat-form-field
                    *ngIf="profile.superadmin.clients.isTBSClientIdShown"
                    style="width: 100%"
                >
                    <input
                        fxFlex
                        [disabled]="isTbsClientIdInputLoading"
                        matInput
                        placeholder="TBS Client Id (Optional)"
                        class="mat-primary"
                        [(ngModel)]="tbsClientId"
                        autocomplete="off"
                    />
                </mat-form-field>
                <div
                    *ngIf="profile.superadmin.clients.isTBSClientIdShown"
                    fxLayout="row"
                >
                    <mat-spinner
                        *ngIf="isTbsClientIdInputLoading"
                        class="mat-accent"
                        diameter="30"
                    ></mat-spinner>
                    <span fxFlex></span>
                    <button
                        id="superAdminTBSClientCancel"
                        mat-button
                        (disabled)="(isTbsClientIdInputLoading)"
                        class="mat-accent fixed-height"
                        (click)="closeCard()"
                    >
                        CANCEL
                    </button>
                    <button
                        id="superAdminTBSClientUpdate"
                        mat-raised-button
                        (disabled)="(isTbsClientIdInputLoading)"
                        class="mat-accent fixed-height"
                        (click)="saveTbsClientId()"
                    >
                        UPDATE
                    </button>
                </div>
            </mat-card>
            <mat-card id="superAdminModeRM">
                <div fxLayout="row">
                    <p>
                        <strong>Countries: </strong
                        >{{
                            mode == "rm"
                                ? selectedRm.userName
                                : selectedClient.name
                        }}
                    </p>
                    <span fxFlex></span>
                    <mat-spinner
                        *ngIf="isLoadingCountry"
                        class="mat-accent"
                        diameter="30"
                    ></mat-spinner>
                    <button
                        id="superAdminModeRMSave"
                        mat-raised-button
                        (disabled)="(isLoadingCountry)"
                        class="mat-accent fixed-height"
                        (click)="saveCountrySelection()"
                    >
                        {{ "SuperAdmin.Save" | translate }}
                    </button>
                </div>
                <div id="superAdminModeRMGrid" style="overflow: hidden">
                    <agcustom-grid
                        [dataList]="filteredCountryList"
                        [columnDefs]="countryColumnDefs"
                        [select]="true"
                        [pagination]="true"
                        [paginationPageSize]="6"
                    >
                    </agcustom-grid>
                </div>
            </mat-card>
        </div>
        <mat-card
            id="superAdminTableu"
            fxFlex="40"
            *ngIf="mode == 'tableauprojects'"
        >
            <app-tableauprojectmgmt></app-tableauprojectmgmt>
        </mat-card>
        <mat-card id="superAdminOffline" fxFlex="80" *ngIf="mode == 'offline'">
            <app-offline [isSubmit]="false"></app-offline>
        </mat-card>
        <div fxFlex *ngIf="mode == 'site'">
            <app-siteadmin
                [isAdmin]="isAdmin"
                [isRegionalManager]="isRegionalManager"
            ></app-siteadmin>
        </div>
        <div fxFlex *ngIf="mode == 'benchmarkLimit'">
            <app-benchmark-limit-admin></app-benchmark-limit-admin>
        </div>
    </div>
</div>
