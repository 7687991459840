export const locale = {
    lang: 'es-ec',
    data: {
        'ClaimsOverview': {
            'Prior': 'Anterior año',
            'Current': 'Actual',
            'ClaimType': 'Tipo de Servicio',
            'Relationship': 'Parentesco',
            'Entity': 'Entidad',
            'Network': 'Network',
            'Location': 'Localización',
            'ClaimsOverview': 'Resumen de reclamos',
            'TotalPaidClaim': 'Total pagado de reclamo',
            'Total': 'Total',
            'NOOFCLAIMS': 'No de reclamos',
            'NOOFCLAIMANTS': 'No de Reclamantes',
            'Claim': 'Reclamo',
            'Claimant': 'Reclamante',
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'Count': 'Count',
            'ClaimsReportedByPaidDate': 'Reclamaciones reportadas por fecha de pago',
            'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
            'DateRange': 'to'
        }
    }
};
