import {
    Component,
    OnInit,
    Output,
    ElementRef,
    EventEmitter
} from "@angular/core";
import { FilterOptions } from "app/dashboards/shared/models/FilterOptions";
import { DataService } from "app/dashboards/data.service";
import { DashboardTemplateBase } from "../dashboard-template-base.component";
import { PulseEventName } from "app/dashboards/shared/models/PulseEventName";
import { locale as navigationEnglish } from "app/dashboards/templates/enrollment-by-age-band/i18n/en";
import { locale as navigationSpanishCL } from "app/dashboards/templates/enrollment-by-age-band/i18n/es-cl";
import { locale as navigationSpanishCO } from "app/dashboards/templates/enrollment-by-age-band/i18n/es-co";
import { locale as navigationSpanishEC } from "app/dashboards/templates/enrollment-by-age-band/i18n/es-ec";
import { locale as navigationSpanishMX } from "app/dashboards/templates/enrollment-by-age-band/i18n/es-mx";
import { locale as navigationSpanishPE } from "app/dashboards/templates/enrollment-by-age-band/i18n/es-pe";
import { locale as navigationPortugueseBR } from "app/dashboards/templates/enrollment-by-age-band/i18n/pt-br";
import { locale as navigationItalianIT } from "app/dashboards/templates/enrollment-by-age-band/i18n/it";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { COLOR_PALETTE } from 'app/constants/color-palette';

@Component({
    selector: "app-enrollment-by-age-band",
    templateUrl: "./enrollment-by-age-band.component.html",
    styleUrls: ["./enrollment-by-age-band.component.css"]
})
export class EnrollmentByAgeBandComponent
    implements OnInit, DashboardTemplateBase {

    isFilterOptionsLoading = true;

    data: any;
    properties: any;
    imageUrlBase = "";
    barColorPurple = "#101E7F";
    showFilter: boolean = false;
    parentLoaded = false;
    filterOptions: FilterOptions = new FilterOptions();
    GenderRelOption = GenderRelOption;
    MeasureOption = MeasureOption;
    currentRelationshipSplit: any;
    priorRelationshipSplit: any;
    dataFirstTable: any;
    datalist: any;
    stackBarList: any = [];
    enrollmentAgeBandSplitList: any = [];
    enrollmentAgeBandSplitLeftTotalValue: any;
    enrollmentAgeBandSplitLeftTotalPercentage: any;
    enrollmentAgeBandSplitRightTotalValue: any;
    enrollmentAgeBandSplitRightTotalPercentage: any;
    enrollmentAgeBandSplitOthersTotalValue: any;
    enrollmentAgeBandSplitOthersTotalPercentage: any;
    maxRightValue: any;
    maxLeftValue: any;
    maxRightPercentage: any;
    maxLeftPercentage: any;
    legendList: any = [];
    eligibleForBenchmark = false;

    @Output() action = new EventEmitter<any>();
    initData(): any {
        this.datalist = this.data.current;
        this.currentRelationshipSplit = this.data.currentRelationshipSplit;
        this.priorRelationshipSplit = this.data.priorRelationshipSplit;
        var currentUpperTable =
            this.data.currentLabel === null ? '' : this.data.currentLabel.fromDateLabel === null ? '' :
                this.data.currentLabel.fromDateLabel === this.data.currentLabel.toDateLabel ? this.data.currentLabel.fromDateLabel :
                    this.data.currentLabel.fromDateLabel + ' ' + this._translateService.instant("ageBand.DateRange") + ' ' + this.data.currentLabel.toDateLabel

        var dataCurrentUpperTable = {
            //Provider: this.data.currentLabel.fromDateLabel === null ? '' : 
            //          this.data.currentLabel.fromDateLabel + ' ' + this._translateService.instant("ageBand.DateRange")+ ' '  + this.data.currentLabel.toDateLabel, 
            Provider: currentUpperTable,
            MemberCountTotal: this.data.currentMemberCount,
            AverageAge: this.data.currentAverageAge,
            AVerageFamilySize: this.data.currentAverageFamilySize,
            ComparablePeriod: this.data.currentLabel == "" || this.data.currentLabel == null ? "" : this.properties.rollingPeriod == 3 ? " (C)" : ""
        };

        var priorUpperTable =
            this.data.priorLabel === null ? '' : this.data.priorLabel.fromDateLabel === null ? '' :
                this.data.priorLabel.fromDateLabel === this.data.priorLabel.toDateLabel ? this.data.priorLabel.fromDateLabel :
                    this.data.priorLabel.fromDateLabel + ' ' + this._translateService.instant("ageBand.DateRange") + ' ' + this.data.priorLabel.toDateLabel;

        var dataPriorUpperTable = {
            //Provider: this.data.currentLabel.fromDateLabel === null ? '' :
            //            this.data.priorLabel.fromDateLabel === this.data.priorLabel.toDateLabel ? this.data.priorLabel.fromDateLabel :
            //            this.data.priorLabel.fromDateLabel + ' ' + this._translateService.instant("ageBand.DateRange")+ ' '  + this.data.priorLabel.toDateLabel,
            Provider: priorUpperTable,
            MemberCountTotal: this.data.priorMemberCount,
            AverageAge: this.data.priorAverageAge,
            AVerageFamilySize: this.data.priorAverageFamilySize,
            ComparablePeriod: this.data.priorLabel == "" || this.data.priorLabel == null ? "" : this.properties.rollingPeriod == 3 ? " (C)" : ""
        };
        console.log(dataCurrentUpperTable);
        console.log(dataPriorUpperTable);

        this.dataFirstTable = [];
        this.dataFirstTable.push(dataCurrentUpperTable);
        this.dataFirstTable.push(dataPriorUpperTable);
        var colors = COLOR_PALETTE;
        var i = 0;
        this.stackBarList = [];
        this.legendList = [];
        var relationshipSplitNameList = this.data.relationshipSplitNameList;
        relationshipSplitNameList.map(d => {
            var objlegend = { name: "", color: "" };
            objlegend.name = d;
            objlegend.color = colors[i];
            var seriesObj = {
                type: undefined,
                name: d,
                data: [],
                pointWidth: 30,
                color: colors[i]
            };
            var perDarList = [];
            var curObj = this.currentRelationshipSplit.filter(c => {
                if (c.name == d) return c;
            });
            if (curObj.length > 0) {
                perDarList.push(curObj[0].memberCount);
            } else {
                perDarList.push(0);
            }
            var priorObj = this.priorRelationshipSplit.filter(p => {
                if (p.name == d) return p;
            });
            if (priorObj.length > 0) {
                perDarList.push(priorObj[0].memberCount);
            } else {
                perDarList.push(0);
            }
            var safeObj = false
            for (var ij = 0; ij < perDarList.length; ij++) {
                if (perDarList[ij] > 0) {
                    safeObj = true;
                }
            }
            if (safeObj) {
                seriesObj.data = perDarList;
                this.legendList.push(objlegend);
                this.stackBarList.push(seriesObj);
                i++;
            }
        });
        this.enrollmentAgeBandSplitList = this.data.enrollmentAgeBandSplitList;
        this.maxLeftValue = Math.ceil(Math.max.apply(
            null,
            (this.enrollmentAgeBandSplitList.map(a => a.leftValue)).concat(this.enrollmentAgeBandSplitList.map(a => a.leftPercentage === 0 ? 0 : (a.leftBenchmarkValue * 100) / a.leftPercentage * a.leftValue))
        ));
        this.maxRightValue = Math.ceil(Math.max.apply(
            null,
            (this.enrollmentAgeBandSplitList.map(a => a.rightValue)).concat(this.enrollmentAgeBandSplitList.map(a => a.rightPercentage === 0 ? 0 : (a.rightBenchmarkValue * 100) / a.rightPercentage * a.rightValue))
        ));
        this.maxLeftPercentage = Math.ceil(Math.max.apply(
            null,
            (this.enrollmentAgeBandSplitList.map(a => a.leftPercentage)).concat(this.enrollmentAgeBandSplitList.map(a => a.leftBenchmarkValue * 100))
        ));
        this.maxRightPercentage = Math.ceil(Math.max.apply(
            null,
            (this.enrollmentAgeBandSplitList.map(a => a.rightPercentage)).concat(this.enrollmentAgeBandSplitList.map(a => a.rightBenchmarkValue * 100))
        ));

        this.enrollmentAgeBandSplitLeftTotalValue = this.data.enrollmentAgeBandSplitLeftTotalValue;
        this.enrollmentAgeBandSplitLeftTotalPercentage = this.data.enrollmentAgeBandSplitLeftTotalPercentage;
        this.enrollmentAgeBandSplitRightTotalValue = this.data.enrollmentAgeBandSplitRightTotalValue;
        this.enrollmentAgeBandSplitRightTotalPercentage = this.data.enrollmentAgeBandSplitRightTotalPercentage;
        this.enrollmentAgeBandSplitOthersTotalValue = this.data.enrollmentAgeBandSplitOthersTotalValue;
        this.enrollmentAgeBandSplitOthersTotalPercentage = this.data.enrollmentAgeBandSplitOthersTotalPercentage;

        setTimeout(() => (this.parentLoaded = true), 100);
    }

    constructor(
        private _translateService: TranslateService,
        private _dataService: DataService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) { }

    ngOnInit() {
        this._fuseTranslationLoaderService.loadTranslations(
          navigationEnglish,
          navigationSpanishCL,
          navigationSpanishCO,
          navigationSpanishEC,
          navigationSpanishMX,
          navigationSpanishPE,
          navigationPortugueseBR,
          navigationItalianIT,
        );
    }

    selectionChanged(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED,
            data: {
                properties: {
                    name: filterName,
                    value: typeof val === 'object' ? val.data.join('||') : val
                }
            }
        });
    }

    additionalFilterSelectionChanged(val) {
        this.action.emit(val);
    }

    filterChanged(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
            data: {
                properties: {
                    name: filterName,
                    value: typeof val === 'object' ? val.data.join('||') : val
                }
            }
        });
    }
}

export enum GenderRelOption {
    Gender = "Gender",
    Relationship = "Relationship"
}

export enum MeasureOption {
    Number = "Number",
    Distribution = "%Distribution"
}
