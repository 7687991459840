import { Component, OnInit, HostListener } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { UserService } from '../services/user.service';
import { Router } from "@angular/router";
import { LibraryService } from 'app/services/library.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { userActionLog } from '../models/log.model';
import { languages } from 'app/shared/languages';
import { CartService } from 'app/services/cart.service';
import { ActivatedRoute } from "@angular/router";
import { environment } from 'environments/environment';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { CLAIM_BASIS } from 'app/models/basis.model';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    public userName: string;
    public email: string;
    public password: string;
    public errorMessage: string;
    public isLoading: boolean;
    public isLoadingCountry: boolean;
    public showCountrySelect: boolean;
    public showAdminLinks: boolean;
    public clientList: any[];
    public countryList: any[];
    public languageList: any[];
    public language = 'en';
    public aonLogin = false;
    public externalLogin = false;
    public isResetPassword = false;
    public resetPassword = '';
    public resetPasswordConfirm = '';
    public resetPasswordError = false;
    public resetPasswordConfirmError = false;
    public resetPasswordSame = true;
    public resetPasswordEmail = '';
    public resetPasswordToken = '';
    public isForgotPassword = false;
    public passwordRequirementLanguage = 'English';

    public profile = environment.profile;
    client: any;
    country: any;
    clientFilter = new FormControl();
    logModel: userActionLog = new userActionLog();
    public signup = false;
    loginSuccess = '';
    public signupSuccess = '';
    impersonateUsername = '';
    impersonateUsernameExtad = '';
    /**
      * Constructor    *
      
      * @param {FuseNavigationService} _fuseNavigationService
      */
    constructor(private _router: Router,
        private _cartService: CartService,
        private _fuseConfigService: FuseConfigService,
        private _userService: UserService,
        private _translateService: TranslateService,
        private _libraryService: LibraryService,
        private _route: ActivatedRoute,
        private _toasterService: ToasterService,
        private _dialog: MatDialog,
    ) {
        this._fuseConfigService.config = {
            layout: {
                toolbar: {
                    hidden: true
                },
                navbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                }
            }
        };

        this.impersonateUsername = this._route.snapshot.queryParamMap.get('impersonate');
        this.impersonateUsernameExtad = this._route.snapshot.queryParamMap.get('impersonate-extad');
        if (this.impersonateUsername || this.impersonateUsernameExtad) {
            this.impersonateUser();
        }

        this.resetPasswordEmail = this._route.snapshot.queryParamMap.get('email');
        this.resetPasswordToken = this._route.snapshot.queryParamMap.get('token');
        if (this.resetPasswordEmail && this.resetPasswordToken) {
            this.showResetPassword();
        }

        this._route.queryParamMap.subscribe(queryParams => {
            this.impersonateUsername = queryParams.get('impersonate');
            this.impersonateUsernameExtad = queryParams.get('impersonate-extad');
            if (this.impersonateUsername || this.impersonateUsernameExtad) {
                this.impersonateUser();
            }

            this.resetPasswordEmail = this._route.snapshot.queryParamMap.get('email');
            this.resetPasswordToken = this._route.snapshot.queryParamMap.get('token');
            if (this.resetPasswordEmail && this.resetPasswordToken) {
                this.showResetPassword();
            }
        });
    }

    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            if (this.aonLogin) {
                this.loginAD();
            } else if (this.externalLogin) {
                this.loginExternal();
            }
        }
    }

    ngOnInit() {
        this._translateService.use("en");
        this.languageList = languages;
    }

    impersonateUser(): void {
        if (this.impersonateUsername === null && this.impersonateUsernameExtad === null) {
            return;
        }

        if (this.impersonateUsername !== null) {
            this._userService.loginImpersonate(this.impersonateUsername).then(() => {
                this._cartService.clearItems();

                this._userService.getUserClients().toPromise().then(data => {
                    this.clientList = data;
                    this.isLoading = false;
                    this.showCountrySelect = true;
                    this.showAdminLinks = this._userService.isAdmin() || this._userService.isRegionalManager() || this._userService.isCountryManager();
                });

                this._userService.updateRollingPeriod(7);
                //this._userService.updatePeriodSelection(1);
                this._userService.updateClaimPaid(CLAIM_BASIS.PAID_BASIS);

                this._libraryService.getLibraryDictionary().toPromise().then(libraryDictionary => {
                    this._libraryService.setLibraryDictionaryInSession(libraryDictionary);
                });
                this.loginSuccess = 'User sign in successful';
            }).catch(error => {
                this.errorMessage = error;
            }).finally(() => {
                this.isLoading = false;
            });
        }

        if (this.impersonateUsernameExtad !== null) {
            this._userService.loginImpersonateExtad(this.impersonateUsernameExtad).then(() => {
                this._cartService.clearItems();

                this._userService.getUserClients().toPromise().then(data => {
                    this.clientList = data;
                    this.isLoading = false;
                    this.showCountrySelect = true;
                    this.showAdminLinks = this._userService.isAdmin() || this._userService.isRegionalManager() || this._userService.isCountryManager();
                });

                this._userService.updateRollingPeriod(7);
                //this._userService.updatePeriodSelection(1);
                this._userService.updateClaimPaid(CLAIM_BASIS.PAID_BASIS);

                this._libraryService.getLibraryDictionary().toPromise().then(libraryDictionary => {
                    this._libraryService.setLibraryDictionaryInSession(libraryDictionary);
                });
                this.loginSuccess = 'User sign in successful';
            }).catch(error => {
                this.errorMessage = error;
            }).finally(() => {
                this.isLoading = false;
            });
        }
    }

    showResetPassword() {
        this.isResetPassword = true;
        this.aonLogin = false;
        this.externalLogin = false;
        this.showCountrySelect = false;
    }

    loginAD() {
        delete this.signupSuccess;
        delete this.errorMessage;
        this.isLoading = true;

        this._userService.loginAD(this.userName, this.password).then((res: Response) => {
            this._cartService.clearItems();

            this._userService.getUserClients().toPromise().then(data => {
                this.clientList = data;
                this.isLoading = false;
                this.showCountrySelect = true;
                this.showAdminLinks = this._userService.isAdmin() || this._userService.isRegionalManager() || this._userService.isCountryManager();
            });

            this._userService.updateRollingPeriod(7);
            //this._userService.updatePeriodSelection(1);
            this._userService.updateClaimPaid(CLAIM_BASIS.PAID_BASIS);

            this._libraryService.getLibraryDictionary().toPromise().then(libraryDictionary => {
                this._libraryService.setLibraryDictionaryInSession(libraryDictionary);
            });
            this.loginSuccess = 'User sign in successful';
        }).catch(error => {
            this.errorMessage = error;
        }).finally(() => {
            this.isLoading = false;
        });
    }

    loginExternal() {
        delete this.signupSuccess;
        delete this.errorMessage;
        this.isLoading = true;
        this._userService.loginExternal(this.email, this.password).then((res: Response) => {
            this._cartService.clearItems();



            this._userService.getUserClients().toPromise().then(data => {
                this.clientList = data;
                this.isLoading = false;
                this.showCountrySelect = true;
                this.showAdminLinks = this._userService.isAdmin() || this._userService.isRegionalManager() || this._userService.isCountryManager();
            });

            this._userService.updateRollingPeriod(7);
            //this._userService.updatePeriodSelection(1);
            this._userService.updateClaimPaid(CLAIM_BASIS.PAID_BASIS);

            this._libraryService.getLibraryDictionary().toPromise().then(libraryDictionary => {
                this._libraryService.setLibraryDictionaryInSession(libraryDictionary);
                this.loginSuccess = 'User sign in successful';
            });
        }).catch(error => {
            this.errorMessage = error;
        }).finally(() => {
            this.isLoading = false;
        });
    }

    signupExternal() {
        this.isLoading = true;
        this._userService.signupExternal(this.email, this.userName, this.password).then(() => {
            this.isLoading = false;
            this.signup = false;
            this.errorMessage = '';
            this.signupSuccess = 'Sign up successful! Please login using your credentials.';
        }).catch(error => {
            this.errorMessage = error;
        }).finally(() => {
            this.isLoading = false;
        });
    }

    onResetPassword() {
        if (!this.resetPassword || !this.resetPasswordConfirm || this.resetPassword !== this.resetPasswordConfirm || this.resetPasswordError || this.resetPasswordConfirmError) {
            return;
        }

        this.isLoading = true;

        this._userService.resetPassword(this.resetPasswordEmail, this.resetPassword, this.resetPasswordToken).toPromise()
            .then(() => {
                this.resetPasswordEmail = '';
                this.resetPassword = '';
                this.resetPasswordConfirm = '';
                this.resetPasswordToken = '';
                this.isResetPassword = false;
                this._toasterService.showMessage('Reset Password Successful', ToasterServiceModes.OK);
            }, () => {
                this._toasterService.showMessage('Reset Password Failed', ToasterServiceModes.NOK);
            }).finally(() => {
                this.isLoading = false;
            });
    }

    onForgotPassword() {
        this.isLoading = true;

        this._userService.forgetPassword(this.email).toPromise()
            .then(() => {
                this.isForgotPassword = false;
                this.email = '';
                this._toasterService.showMessage('Reset Password Email Sent Successfully', ToasterServiceModes.OK);
            }, () => {
                this._toasterService.showMessage('Reset Password Email Sent Failed', ToasterServiceModes.NOK);
            }).finally(() => {
                this.isLoading = false;
            });
    }

    filterContains(val, match) {
        return val.toLowerCase().indexOf(match) >= 0;
    }

    reset() {
        delete this.password;
        delete this.userName;
        delete this.email;
        delete this.errorMessage;
        delete this.signupSuccess;
    }

    checkResetPassword(event: any): void {
        this.resetPasswordError = false;

        if (!event.detail.value) {
            this.resetPassword = '';
            this.resetPasswordError = true;
        }

        this.resetPassword = event.detail.value.trim();
        this.resetPasswordSame = this.resetPassword === this.resetPasswordConfirm;

        const regex = /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,25}$/;
        const isPassed = this.resetPassword.match(regex);

        this.resetPasswordError = isPassed ? false : true;
    }

    checkResetPasswordConfirm(event: any): void {
        this.resetPasswordConfirmError = false;

        if (!event.detail.value) {
            this.resetPasswordConfirm = '';
            this.resetPasswordConfirmError = true;
        }

        this.resetPasswordConfirm = event.detail.value.trim();
        this.resetPasswordSame = this.resetPassword === this.resetPasswordConfirm;

        const regex = /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,25}$/;
        const isPassed = this.resetPasswordConfirm.match(regex);

        this.resetPasswordConfirmError = isPassed ? false : true;
    }

    changePasswordRequirementLanguage(language: string): void {
        this.passwordRequirementLanguage = language;
    }

    onClickDisclaimer(): void {
        this._dialog.open(DisclaimerComponent, {
            width: '800px',
        });
    }
}
