import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { FuseConfigService } from "@fuse/services/config.service";
import { select, Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { navigation } from "app/navigation/navigation";
import { takeUntil, take } from "rxjs/operators";
import { User } from "app/models/user.model";
import { UserService } from "app/services/user.service";

@Component({
  selector: "executive",
  templateUrl: "./executive.component.html",
  styleUrls: ["./executive.component.css"],
  // encapsulation: ViewEncapsulation.None,
})
export class ExecutiveComponent implements OnInit {

  data: any;
  fuseConfig: any;
  navigationlocal: any;
  isExecutiveVisible: boolean = false;
  colorIndex: number = 3;
  cardheight: any = "520px";
  cardwidth: any = "98%";
  contentheight: any = "75%";
  contenttop: any = "60px";
  public user: User;
 
  constructor( 
    private userService: UserService,
    private _router: Router
  ) {
    // Set the defaults
    this.navigationlocal = navigation;
  }
  
  ngOnInit() {
    this.user = this.userService.getCurrentUser();

    this.userService.userChanged.subscribe(user => {
      this.user = this.userService.getCurrentUser();
      if (this.user) {
        if (this.user.client) {
          // this.getDashboardResult();
        }
      } else {
        this.userService.logout();
      }
    });
    this.isExecutiveVisible = true; 
  }

}
