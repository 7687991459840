import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { LibraryComponent } from './library/library.component';
import { StoryboardComponent } from './storyboard/storyboard.component';
import { RouteAuthGuardGuard } from 'app/_guards/route-auth-guard.guard';
import { Roles as roles } from 'app/models/roles.model';
import { StorycontainerComponent } from './storycontainer/storycontainer.component';
import { HighchartspocComponent } from './highchartspoc/highchartspoc.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartsModule } from 'app/charts/charts.module';
import { DashboardsModule } from 'app/dashboards/dashboards.module';

const routes = [
    {
        path: 'story-v1/editor',
        component: StoryboardComponent,
        canActivate: [RouteAuthGuardGuard],
        data: { roles: [roles.admin, roles.regionalManager] }
    },
    {
        path: 'story-v1/:mode/:id',
        component: StoryboardComponent,
        canActivate: [RouteAuthGuardGuard],
        data: {
            params: [
                {
                    key: 'mode',
                    value: 'editor',
                    roles: [roles.admin, roles.regionalManager]
                },
                {
                    key: 'mode',
                    value: 'view'
                }
            ]
        }
    },
    {
        path: 'story/hc',
        component: HighchartspocComponent,
    },
    {
        path: 'story/hc/:country/:client',
        component: HighchartspocComponent,
    },
    {
        path: 'story/editor',
        component: StorycontainerComponent,
        canActivate: [RouteAuthGuardGuard],
        data: { roles: [roles.admin, roles.regionalManager, roles.countryManager] }
    },
    {
        path: 'story/:mode/:id',
        component: StorycontainerComponent,
        canActivate: [RouteAuthGuardGuard],
        data: {
            params: [
                {
                    key: 'mode',
                    value: 'editor',
                    roles: [roles.admin, roles.regionalManager, roles.countryManager]
                },
                {
                    key: 'mode',
                    value: 'view'
                }
            ]
        }
    }
];

@NgModule({
    declarations: [
        StoryboardComponent,
        LibraryComponent,
        StorycontainerComponent,
        HighchartspocComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        MaterialModule,
        SharedModule,
        HighchartsChartModule,
        ChartsModule,
        DashboardsModule
    ],
    exports: [RouterModule]
})

export class StoryModule {
}
