export const locale = {
    lang: 'es-pe',
    data: {
        'LossRatio': {
            'LossRatioHeading' : 'Siniestralidad',
            'AllAmountsDisplayedInLocalCurrency': 'Moneda local',
            'Loss': 'Siniestralidad',
            'LossRatioType': 'Tipo de Siniestralidad',
            'CombinedLR': 'Siniestralidad Combinada',
            'ClaimsTotalPMPM': 'Total de siniestros/PMPM',
            'ClaimsPMPM': 'Siniestros PMPM',
            'ClaimsTotal': 'Total de siniestros',
            'TargetLossRatio': 'Siniestralidad objetivo',
            'ClaimsReportedByPaidDate': 'Siniestros informados por fecha de ocurrencia',
            'ClaimsReportedByIncurredDate' :  'Reclamaciones reportadas por fecha incurrida',
            'Monthly': 'Por Mes',
            'YearToDateBasis': 'Year-To-Date (Base Completa)',
            'YearToDateBasisC': 'Year-To-Date (C)',
            'Rolling3Basis': '3 meses rodantes',
            'Rolling6Basis': '6 meses rodantes',
            'Rolling9Basis': '9 meses rodantes',
            'Rolling12Basis': '12 meses rodantes',
            'Show': 'Mostrar',
            'Hide': 'Ocultar',
            'ShowHide': 'Mostrar / ocultar',
            'Claims' : 'Atenciones',
            'Premium' : 'Primas',
            'Income':'Ingresos',
            'Expense':'Gastos',
            'ClaimLabel': 'Atenciones + Gastos',
            'PremimumLabel':'Primas + Ingresos',
            'MonthlyLossRatio':'Por mes de siniestralidad',
            'CumulativeLossRatio':'Acumulativa de siniestralidad',
            'DateRange': 'to'

        }
}
};
