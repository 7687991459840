// Section 1
import { Action } from '@ngrx/store'

// Section 2 
export const SET_BENEFIT_QUESTION = '[FWB] SetBenefitQuestion';
export const Toggle_BenefitQuestion_Selection = '[FWB] ToggleBenefitQuestionSelection';
export const Toggle_Retirement_Lifestyle_Scenario = '[FWB] ToggleRetirementLifestyleScenario';
export const Toggle_Retirement_Replacement_Scenario = '[FWB] ToggleRetirementReplacementScenario';

// Section 3  
export class SetBenefitQuestion implements Action {
    readonly type = SET_BENEFIT_QUESTION

    constructor(public payload: number) { }
}
export class ToggleBenefitQuestionSelection implements Action {
    readonly type = Toggle_BenefitQuestion_Selection

    constructor(public payload: boolean) { }
}
export class ToggleRetirementLifestyleScenario implements Action {
    readonly type = Toggle_Retirement_Lifestyle_Scenario

    constructor(public payload: number) { }
}

export class ToggleRetirementReplacementScenario implements Action {
    readonly type = Toggle_Retirement_Replacement_Scenario 
    constructor(public payload: number) { }
}

// Section 4
export type Actions = SetBenefitQuestion | ToggleBenefitQuestionSelection |ToggleRetirementLifestyleScenario | ToggleRetirementReplacementScenario;
