<aon-master-page-layout width="1600px">
  <div class="aon-layout">
    <div class="aon-layout-header">
      <top-nav-bar></top-nav-bar>
    </div>
    <div
      class="aon-layout-content columnDiv"
      fusePerfectScrollbar
      fusePerfectScrollbarOptions="{ suppressScrollX: true, updateOnRouteChange : true}"
    >

      <div
        *ngIf="filterShow"
        [style.left]="this.left"
        class="filterDiv"
      >
        <div style="float:right; padding-right:32px; margin-top:40px;">
          <aon-button-icon (onclick)='filterClick($event)'>
            <fa-icon
              size="1x"
              [icon]="['fal', 'sliders-h']"
              [styles]="{'stroke': 'white', 'color': 'white', 'margin-top' : '15%'}"
            ></fa-icon>
          </aon-button-icon>
        </div>


      </div>
      <div [hidden]="!isFilterOpen">
        <div
          class="filterOverlay"
          [style.width]="this.overlayWidth"
          [style.left]="this.sideMargin"
        >
          <div class="filterOverlayContent">
            <div style='height: 100vh; float:right;'>
              <aon-filter-panel (onclose)='filterClick($event)'>
                <aon-filter-nav-section-tablist
                  selectedtab='CURRENT'
                  firsttabtext='CURRENT'
                  secondtabtext='SAVED'
                >
                  <aon-filter-nav-section-tab name='CURRENT'>
                    <aon-filter-panel-current
                      add-filter-below-text="Add filter below"
                      (onreset)='filterReset($event)'
                      (onapply)='filterApply($event)'
                    >
                      <aon-filter-panel-category
                        (oncategoryupdate)="countryChanged($event)"
                        text='Country'
                        value='country'
                      >
                        <aon-filter-panel-category-option
                          *ngFor="let countryRow of countryList"
                          [value]="countryRow"
                        >
                          {{countryRow}}
                        </aon-filter-panel-category-option>
                      </aon-filter-panel-category>
                      <aon-filter-panel-category
                        text='Business Unit'
                        value='BU'
                      >
                        <aon-filter-panel-category-option
                          *ngFor="let businessUnitRow of businessUnitList"
                          [value]="businessUnitRow"
                        >
                          {{businessUnitRow}}
                        </aon-filter-panel-category-option>
                      </aon-filter-panel-category>
                      <aon-filter-panel-category
                        *ngIf="timePeriodList !== null"
                        text='Interval'
                        search="false"
                        multiselect=false
                        value="timeperiod"
                      >
                        <aon-filter-panel-category-option
                          *ngFor="let timePeriodRow of timePeriodList"
                          [value]="timePeriodRow.key"
                          [selected]="timePeriodRow.key === timeperiod"
                          multiselect=false
                        >
                          {{timePeriodRow.value}}
                        </aon-filter-panel-category-option>
                      </aon-filter-panel-category>
                    </aon-filter-panel-current>
                  </aon-filter-nav-section-tab>
                  <aon-filter-nav-section-tab name='SAVED'>
                    <aon-filter-panel-saved>
                      <aon-filter-panel-saved-item
                        saveid=1
                        name='Chris Test'
                        nooffilter=3
                        isdefault=true
                        isnew=true
                      >
                        <aon-filter-panel-saved-category
                          name='Filter Category A'
                          value='Category A'
                        >
                          <aon-filter-panel-saved-category-option
                            name='Testing 2'
                            value='Testing 2'
                          >
                          </aon-filter-panel-saved-category-option>
                        </aon-filter-panel-saved-category>
                        <aon-filter-panel-saved-category
                          name='Filter Category B'
                          value='Category B'
                        >
                          <aon-filter-panel-saved-category-option
                            name='Testing 1'
                            value='Testing 1'
                          >
                          </aon-filter-panel-saved-category-option>
                          <aon-filter-panel-saved-category-option
                            name='Testing 2'
                            value='Testing 2'
                          >
                          </aon-filter-panel-saved-category-option>
                        </aon-filter-panel-saved-category>
                      </aon-filter-panel-saved-item>
                      <aon-filter-panel-saved-item
                        saveid=2
                        name='Chris Test 2'
                        nooffilter=6
                        isnew=true
                      >
                        <aon-filter-panel-saved-category
                          name='Filter Category A'
                          value='Category A'
                        >
                          <aon-filter-panel-saved-category-option
                            name='Testing 1'
                            value='Testing 1'
                          >
                          </aon-filter-panel-saved-category-option>
                          <aon-filter-panel-saved-category-option
                            name='Testing 2'
                            value='Testing 2'
                          >
                          </aon-filter-panel-saved-category-option>
                          <aon-filter-panel-saved-category-option
                            name='Testing 3'
                            value='Testing 3'
                          >
                          </aon-filter-panel-saved-category-option>
                        </aon-filter-panel-saved-category>
                        <aon-filter-panel-saved-category
                          name='Filter Category B'
                          value='Category B'
                        >
                          <aon-filter-panel-saved-category-option
                            name='Testing 3'
                            value='Testing 3'
                          >
                          </aon-filter-panel-saved-category-option>
                          <aon-filter-panel-saved-category-option
                            name='Testing 4'
                            value='Testing 4'
                          >
                          </aon-filter-panel-saved-category-option>
                          <aon-filter-panel-saved-category-option
                            name='Testing 5'
                            value='Testing 5'
                          >
                          </aon-filter-panel-saved-category-option>
                        </aon-filter-panel-saved-category>
                      </aon-filter-panel-saved-item>
                    </aon-filter-panel-saved>
                  </aon-filter-nav-section-tab>
                </aon-filter-nav-section-tablist>
              </aon-filter-panel>
            </div>
          </div>
        </div>
      </div>

      <router-outlet></router-outlet>

      <div class="aon-layout-footer">
        <aon-footer
          [privacylink]="privacylink"
          [termslink]="termslink"
          [copyrightlinktext]="copyrightlinktext"
        >
        </aon-footer>
      </div>
    </div>
  </div>

</aon-master-page-layout>