import { Component, OnInit, Input, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ViewChild, ViewContainerRef, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ExecutiveSummaryComponent, LossCombined } from '../templates/executive-summary/executive-summary.component';
import { HealthDiseaseProfileComponent } from '../templates/health-disease-profile/health-disease-profile.component';
import { DashboardTemplateBase } from '../templates/dashboard-template-base.component';
import { PrevalenceOfDiseaseComponent } from '../templates/prevalence-of-disease/prevalence-of-disease.component';
import { AverageCostUtilComponent, CostOption } from '../templates/average-cost-util/average-cost-util.component';
import { CostDetailOption } from '../templates/prevalence-of-disease/prevalence-of-disease.component';
import { DataService } from '../data.service';
import { PerMemberCostsComponent } from '../templates/per-member-costs/per-member-costs.component';
import { PulseEventName } from '../shared/models/PulseEventName';
import { objectEach } from 'highcharts';
import { environment } from 'environments/environment';
import { ClaimCostTopProviderComponent } from '../templates/claim-cost-top-provider/claim-cost-top-provider.component';
import { ClaimsOverviewComponent } from '../templates/claims-overview/claims-overview.component';
import { ClaimTypeDiagnosisComponent } from '../templates/claim-type-diagnosis/claim-type-diagnosis.component';
import { ClaimTypeComponent } from '../templates/claim-type/claim-type.component';
import { ClaimByAgeBandComponent } from '../templates/claim-by-age-band/claim-by-age-band.component';
import { ExecutiveSummaryRowComponent } from '../templates/executive-summary-row/executive-summary-row.component';
import { LossRatioComponent } from '../templates/loss-ratio/loss-ratio.component';
import { JsonPipe } from '@angular/common';
import { EnrollmentByTimeComponent } from '../templates/enrollment-by-time/enrollment-by-time.component';
import { EnrollmentByAgeBandComponent } from '../templates/enrollment-by-age-band/enrollment-by-age-band.component';
import { DistributionByClaimTypeComponent } from '../templates/distribution-by-claim-type/distribution-by-claim-type.component';
import { DoubleQuadrantProDiagComponent } from '../templates/double-quadrant-pro-diag/double-quadrant-pro-diag.component';
import { CoMorbidityComponent, DiagnosisOptionsCo } from '../templates/co-morbidity/co-morbidity.component';
import { LoggerService } from 'app/services/logger.service';
import { userActionLog, LogActionType } from 'app/models/log.model';
import * as Highcharts from 'highcharts';
import { AdditionalFiltersService } from '../shared/additional-filters.service';
declare var DATA_INJECTED;

@Component({
    selector: 'app-dashboardcontainer',
    templateUrl: './dashboardcontainer.component.html',
    styleUrls: ['./dashboardcontainer.component.css']
})
export class DashboardcontainerComponent implements OnInit {
    constructor(
        private dataService: DataService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private _loggerService: LoggerService,
        private _additionalFiltersService: AdditionalFiltersService
    ) { }

    @Input() template: string;
    @Input() properties: any;
    @Input() id: any;
    @Input() isExportSite = false;
    @Input() clientGroupFilterOptions = null;
    logModel: userActionLog = new userActionLog();

    dashboardBase: DashboardTemplateBase;
    changeFilterLogDictionary: any;
    isLoading = false;
    @Output() onNavigate = new EventEmitter<any>();

    @ViewChild('templateInsert', { read: ViewContainerRef, static: true })
    private templateInsert: ViewContainerRef;

    templateNotFound = false;

    clonedProperties: any = null;

    ngOnInit() {
        if (this.isExportSite) {
            Highcharts.setOptions({
                chart: {
                    animation: false,
                },
                plotOptions: {
                    series: {
                        animation: {
                            duration: 0
                        }
                    }
                },
                drilldown: {
                    animation: {
                        duration: 0
                    }
                }
            });
        }

        this.populateProperties();
    }

    populateProperties() {
        if (typeof this.properties === 'undefined') {
            this.properties = {};
        }

        const curStory = this._loggerService.getCurrentStory();
        this.properties.storyId = '';
        this.properties.storyName = '';

        if (typeof curStory !== 'undefined' && curStory !== null) {
            this.properties.storyId = curStory.storiesId;
            this.properties.storyName = curStory.name;
        }
        this.properties.dimension = DimensionOptions.ClaimType;
        this.properties.secondaryDimension = '';
        this.properties.secondaryDimensionValue = '';
        this.properties.thirdDimension = '';
        this.properties.thirdDimensionValue = '';
        this.properties.sort = SortOption.ClaimTotal;
        this.properties.diagCatsOptions = '';
        this.properties.diag = CategoryChapterOption.DiagCat;
        this.properties.isExcludeNA = false;
        this.properties.filterNA = 'false';
        this.properties.claimTypeOption = ClaimantOption.Claim;
        this.properties.benchmarkInclude = 1;
        this.properties.benchmarkLevel = 1;
        this.properties.showHide = ShowHideOption.Show;
        this.properties.claimPMPM = ClaimPMPMOption.ClaimsTotal;
        this.properties.lossRatio = LossRatioOption.LossRatio;
        this.properties.period = PeriodOption.Rolling12;
        this.properties.targetLossRatio = 80;
        this.properties.measure = MeasureOption.Number;
        this.properties.dataAgeSplit = DimensionOptions.Relationship;
        this.properties.dataDiagPro = DataDiagProOption.DataTable;
        this.properties.claimsTotal = ClaimClaimantsTotalOption.ClaimsTotal;
        this.properties.claimantFilterValue = this.getClaimantFilterValue();
        this.properties.chartTable = ChartTable.Chart;
        this.properties.donutchartDimension = DimensionOptions.ClaimType;
        this.properties.FILTLER_CHANGED = false;
        this.properties.selectedClaimType = '';
        this.properties.executiveLR = LossCombined.LossRatio;
        this.properties.combinedLossRatio = LossCombined.CombinedLossRatio;
        this.properties.coMorbidityDiag = DiagnosisOptionsCo.Diabetes;

        this.properties.claimsByAgeBandsAgeBand = '';
        this.properties.claimsByAgeBandsGender = '';
        this.properties.claimsByAgeBandsRelationship = '';
        this.properties.claimsByAgeBandsDiagChapter = '';
        this.properties.claimsByAgeBandsProviderName = '';

        this.properties.claimCostTopProviderTopFilter = 20;
        this.properties.claimTypeDiagnosisTopFilter = 10;
        this.properties.doubleQuadrantProDiagTopFilter = 10;
        this.properties.prevalenceOfDiseaseTopFilter = 10;

        this.properties.isLegendShown = false;

        this.changeFilterLogDictionary = {};

        this.clonedProperties = JSON.parse(JSON.stringify(this.properties));

        if (!this.isExportSite) {
            this.appendComponentToBody();

            this.dashboardBase.isFilterOptionsLoading = true;
            this.isLoading = true;

            Promise.all([
                this.dataService.getFilterOptions(this.properties).toPromise(),
                this.dataService.getIsDisplayBenchmark(this.properties).toPromise(),
            ])
                .then(([
                    filters,
                    eligibleForBenchmark,
                ]) => {
                    // Filters
                    this._additionalFiltersService.setInitialOptions(filters);
                    this.dashboardBase.filterOptions = filters;
                    setTimeout(() => {
                        this.dashboardBase.isFilterOptionsLoading = false;
                    });

                    // Eligible for Benchmark
                    this.dashboardBase.eligibleForBenchmark = eligibleForBenchmark;
                })
                .then(() => this.initDashboardComponent())
                .finally(() => this.dashboardBase.isFilterOptionsLoading = false);
        }
    }

    getDashboardData() {
        this.isLoading = true;
        this.dashboardBase.imageUrlBase = environment.imageUrlBase;
        this.properties.dashboardId = this.template;

        if (typeof this.properties.storedDashboardIds === 'undefined') {
            this.properties.storedDashboardIds = [];
        }

        if (typeof this.properties[this.properties.id] === 'undefined') {
            this.properties[this.properties.id] = JSON.parse(JSON.stringify(this.properties));
        }

        this.dashboardBase.properties = this.properties[this.properties.id];
        this.properties[this.properties.id].dashboardId = this.template;

        this.properties.storedDashboardIds.forEach(id => {
            if (typeof this.properties[this.properties.id] !== 'undefined') {
                delete this.properties[this.properties.id][id];
            }
        });

        this.properties[this.properties.id].eligibleForBenchmark = this.dashboardBase.eligibleForBenchmark;

        this.properties.storedDashboardIds.push(this.properties.id);

        this.resetAdditionalFiltersForDashboard();
        this.populateAdditionalFiltersForDashboard();

        switch (this.template) {
            case 'ExecutiveSummary': {
                this.dataService
                    .getExecutiveSummaryAsiaData(this.properties[this.properties.id])
                    .toPromise()
                    .then(r => {
                        this.dashboardBase.data = r;
                        this.dashboardBase.initData();
                        this.isLoading = false;
                    });
                //this.logUserAction("ExecutiveSummary");
                break;
            }
            case 'ExecutiveSummaryROW': {
                this.dataService
                    .getExecutiveSummaryExceptAsiaData(this.properties[this.properties.id])
                    .toPromise()
                    .then(r => {
                        this.dashboardBase.data = r;
                        this.dashboardBase.initData();
                        this.isLoading = false;
                    });
                //this.logUserAction("ExecutiveSummaryROW");
                break;
            }
            case 'HealthDiseaseProfile': {
                this.isLoading = true;
                this.properties[this.properties.id].diagCats = '';
                this.properties[this.properties.id].dimension =
                    [
                        DimensionOptions.AgeBand,
                        DimensionOptions.Relationship,
                        DimensionOptions.Gender,
                        DimensionOptions.ClaimType
                    ].indexOf(this.properties[this.properties.id].dimension) <= -1
                        ? DimensionOptions.ClaimType
                        : this.properties[this.properties.id].dimension;

                if (this.properties[this.properties.id].id === 'HealthProfile') {
                    this.dataService
                        .getHealthProfileData(this.properties[this.properties.id])
                        .toPromise()
                        .then(r => {
                            this.dashboardBase.data = r;
                            this.dashboardBase.initData();
                            this.isLoading = false;
                        });
                } else if (this.properties[this.properties.id].id === 'DiseaseProfile') {
                    this.properties[this.properties.id].dimension = this.properties[this.properties.id].diag;
                    this.properties[this.properties.id].diagGrps = this.properties[this.properties.id].diagGrps || 'Chronic';
                    this.properties[this.properties.id].category = this.properties[this.properties.id].category || 'Chronic';

                    this.dataService
                        .getDiseaseProfileData(this.properties[this.properties.id])
                        .toPromise()
                        .then(r => {
                            this.dashboardBase.data = r;
                            this.dashboardBase.initData();
                            this.isLoading = false;
                        });
                }
                //this.logUserAction("HealthDiseaseProfile");

                break;
            }
            case 'EnrollmentByTime': {
                this.isLoading = false;
                const countryListASIA = [
                    'Hong Kong', 'Malaysia', 'Philippines', 'Singapore', 'Thailand'
                ];
                this.properties[this.properties.id].dimension =
                    [
                        DimensionOptions.AgeBand,
                        DimensionOptions.Gender,
                        DimensionOptions.Relationship,
                        DimensionOptions.Employee
                    ].indexOf(this.properties[this.properties.id].dimension) <= -1
                        ? DimensionOptions.Relationship
                        : this.properties[this.properties.id].dimension;

                this.dataService
                    .getEnrollmentByTime(this.properties[this.properties.id])
                    .toPromise()
                    .then(r => {
                        this.dashboardBase.data = r;
                        this.dashboardBase.initData();
                        this.isLoading = false;
                    });
                //this.logUserAction("EnrollmentByTime");
                break;
            }
            case 'EnrollmentByAgeBand': {
                if (this.properties[this.properties.id].id == 'EnrollmentByAgeBand') {
                    this.properties[this.properties.id].dimension =
                        [
                            DimensionOptions.Gender,
                            DimensionOptions.Relationship
                        ].indexOf(this.properties[this.properties.id].dimension) <= -1
                            ? DimensionOptions.Relationship
                            : this.properties[this.properties.id].dimension;
                    this.dataService
                        .getEnrollmentByAgeBand(this.properties[this.properties.id])
                        .toPromise()
                        .then(r => {
                            this.dashboardBase.data = r;
                            this.dashboardBase.initData();
                            this.isLoading = false;
                        });
                }
                //this.logUserAction("EnrollmentByAgeBand");
                break;
            }
            case 'PrevalenceDiseaseProfile': {

                this.isLoading = true;

                //to set initial
                this.properties[this.properties.id].mode =
                    [
                        CostDetailOption.CostPerClaim,
                        CostDetailOption.CostPerClaimant,
                        CostDetailOption.Age,
                        CostDetailOption.ClaimantCount,
                        CostDetailOption.ClaimsPerClaimant,
                        CostDetailOption.TotalCost,
                        CostDetailOption.Prevalence

                    ].indexOf(this.properties[this.properties.id].mode) <= -1
                        ? CostDetailOption.CostPerClaim
                        : this.properties[this.properties.id].mode;
                if (typeof this.properties[this.properties.id].secondaryDimension == 'undefined') {
                    this.properties[this.properties.id].secondaryDimension = CategoryChapterOption.DiagCat;
                    this.properties[this.properties.id].secondaryDimensionValue = "";
                }
                if (typeof this.properties[this.properties.id].thirdDimension == 'undefined') {
                    this.properties[this.properties.id].thirdDimension = ""
                    this.properties[this.properties.id].thirdDimensionValue = ""
                }

                const props = this.properties[this.properties.id];

                Promise.all([
                    this.dataService.getTier3Data({ ...props, dimension: props.diag }).toPromise(),
                    this.dataService.getTier3SingleDimensionData({ ...props, dimension: props.donutchartDimension }).toPromise(),
                    this.dataService.getTier3DiseaseProgressionData({ ...props, dimension: DimensionOptions.AgeBand }).toPromise(),
                ]).then(([
                    diagList,
                    tier3SingleDimensionData,
                    diseaseProgression,
                ]) => {
                    this.dashboardBase.data = {
                        diagList,
                        tier3SingleDimensionData,
                        diseaseProgression,
                    };
                    this.dashboardBase.initData();
                    this.isLoading = false;
                });

                break;
            }
            case "ClaimTypeDiagnosis": {
                if (this.properties[this.properties.id].id == "ClaimTypeVDiagnosis") {
                    this.isLoading = true;
                    var finalData = {};
                    this.properties[this.properties.id].dimension = DimensionOptions.ClaimType;
                    this.properties[this.properties.id].currentDiagnosis = '';
                    this.properties[this.properties.id].currentClaim = '';
                    this.properties[this.properties.id].secondaryDimension = '';
                    this.properties[this.properties.id].secondaryDimensionValue = '';
                    this.dataService
                        .getTier3Data(this.properties[this.properties.id])
                        .toPromise()
                        .then(r => {
                            finalData['claimList'] = r;
                            this.properties[this.properties.id].dimension = this.properties[this.properties.id].diag;
                            this.properties[this.properties.id].secondaryDimension = '';
                            this.properties[this.properties.id].secondaryDimensionValue = '';
                            this.dataService
                                .getTier3Data(this.properties[this.properties.id])
                                .toPromise()
                                .then(re => {
                                    finalData['diagList'] = re;
                                    this.dashboardBase.data = finalData;
                                    this.dashboardBase.initData();
                                    this.isLoading = false;
                                });
                        });
                }
                //this.logUserAction("ClaimTypeDiagnosis");

                break;
            }
            case 'ClaimsOverview': {
                if (this.properties[this.properties.id].id == 'ClaimsOverview') {
                    this.isLoading = true;
                    this.properties[this.properties.id].dimension =
                        [
                            DimensionOptions.ClaimType,
                            DimensionOptions.Relationship,
                            DimensionOptions.Entity,
                            DimensionOptions.Network,
                            DimensionOptions.Location,
                        ].indexOf(this.properties[this.properties.id].dimension) <= -1
                            ? DimensionOptions.ClaimType
                            : this.properties[this.properties.id].dimension;
                    this.dataService
                        .getClaimsOverview(this.properties[this.properties.id])
                        .toPromise()
                        .then(r => {
                            this.dashboardBase.data = r;
                            this.dashboardBase.initData();
                            this.isLoading = false;
                        });
                }
                //this.logUserAction("ClaimsOverview");

                break;
            }
            case 'ClaimByAgeBand': {
                if (this.properties[this.properties.id].id === 'ClaimByAgeBand') {
                    this.isLoading = true;
                    let finalData = {};

                    this.properties[this.properties.id].claimsByAgeBandsAgeBand = '';
                    this.properties[this.properties.id].claimsByAgeBandsGender = '';
                    this.properties[this.properties.id].claimsByAgeBandsRelationship = '';
                    this.properties[this.properties.id].claimsByAgeBandsDiagChapter = '';
                    this.properties[this.properties.id].claimsByAgeBandsProviderName = '';

                    this.properties[this.properties.id].dimension = this.properties[this.properties.id].dataDiagPro == DataDiagProOption.DataTable ? this.properties[this.properties.id].dataAgeSplit : this.properties[this.properties.id].dataDiagPro;
                    this.properties[this.properties.id].dimension =
                        [DimensionOptions.AgeBand,
                        DimensionOptions.Gender,
                        DimensionOptions.Relationship,
                        DimensionOptions.DiagChapter,
                        DimensionOptions.ProviderName
                        ].indexOf(this.properties[this.properties.id].dimension) <= -1
                            ?
                            DimensionOptions.Relationship
                            : this.properties[this.properties.id].dimension;
                    this.dataService
                        .getTier3Data(this.properties[this.properties.id])
                        .toPromise()
                        .then(r => {
                            finalData['dataList'] = r;
                            this.properties[this.properties.id].dimension = DimensionOptions.AgeBand;
                            this.properties[this.properties.id].secondaryDimension = this.properties[this.properties.id].dataAgeSplit;
                            this.dataService
                                .getTier3ClaimByAgeBand(this.properties[this.properties.id])
                                .toPromise()
                                .then(ag => {
                                    finalData['ageBandList'] = ag;
                                    this.dashboardBase.data = finalData;
                                    this.dashboardBase.initData();
                                    this.isLoading = false;
                                });
                        });
                }
                //this.logUserAction("ClaimByAgeBand");
                break;
            }
            case 'ClaimType': {
                if (this.properties[this.properties.id].id == 'ClaimType') {
                    this.isLoading = true;
                    this.properties[this.properties.id].dimension = DimensionOptions.ClaimType;
                    this.dataService
                        .getClaimTypeData(this.properties[this.properties.id])
                        .toPromise()
                        .then(r => {
                            this.dashboardBase.data = r;
                            this.dashboardBase.initData();
                            this.isLoading = false;
                        });
                }
                break;
            }
            case 'ClaimCostProviderDiagnosis': {
                this.isLoading = true;
                if (this.properties[this.properties.id].id == 'ClaimCostByTopProvider') {
                    this.isLoading = true;
                    this.properties[this.properties.id].dimension = DimensionOptions.ProviderName;
                    this.properties[this.properties.id].secondaryDimension = '';
                    this.properties[this.properties.id].secondaryDimensionValue = '';
                    this.dataService
                        .getTier3Data(this.properties[this.properties.id])
                        .toPromise()
                        .then(r => {
                            this.dashboardBase.data = r;
                            this.properties[this.properties.id].dimension = 'none';
                            this.properties[this.properties.id].secondaryDimension = '';
                            this.properties[this.properties.id].secondaryDimensionValue = '';
                            this.dataService
                                .getTier3Benchmark(this.properties[this.properties.id])
                                .toPromise()
                                .then(re => {
                                    this.dashboardBase.data.benchmarkPMPM = re;
                                    this.properties[this.properties.id].dimension = DimensionOptions.ProviderName;
                                    this.dashboardBase.initData();
                                    this.isLoading = false;
                                });
                        });


                } else if (this.properties[this.properties.id].id == "ClaimCostByTopDiagnoses") {
                    this.isLoading = true;
                    this.properties[this.properties.id].dimension = this.properties[this.properties.id].diag;
                    this.properties[this.properties.id].secondaryDimension = "";
                    this.properties[this.properties.id].secondaryDimensionValue = "";
                    this.dataService
                        .getTier3Data(this.properties[this.properties.id])
                        .toPromise()
                        .then(r => {
                            this.dashboardBase.data = r;
                            this.dashboardBase.initData();
                            this.isLoading = false;
                        });

                } else if (this.properties[this.properties.id].id == 'ProviderVDiagnosis') {
                    this.isLoading = true;
                    var finalData = {};
                    this.properties[this.properties.id].dimension = DimensionOptions.ProviderName;
                    this.properties[this.properties.id].secondaryDimension = '';
                    this.properties[this.properties.id].secondaryDimensionValue = '';
                    this.dataService
                        .getTier3Data(this.properties[this.properties.id])
                        .toPromise()
                        .then(r => {
                            finalData['providerList'] = r;
                            this.properties[this.properties.id].dimension = DimensionOptions.DiagChapter;
                            this.properties[this.properties.id].secondaryDimension = '';
                            this.properties[this.properties.id].secondaryDimensionValue = '';
                            this.dataService
                                .getTier3Data(this.properties[this.properties.id])
                                .toPromise()
                                .then(re => {
                                    finalData['diagList'] = re;
                                    this.dashboardBase.data = finalData;
                                    this.dashboardBase.initData();
                                    this.isLoading = false;
                                });
                        });
                }
                break;
            }
            case 'AverageCostUtilization': {
                // some dimensions are not present in all
                this.properties[this.properties.id].dimension =
                    [
                        DimensionOptions.ClaimType,
                        DimensionOptions.Network,
                        DimensionOptions.ProviderType,
                        DimensionOptions.Relationship
                    ].indexOf(this.properties[this.properties.id].dimension) <= -1
                        ? DimensionOptions.ClaimType
                        : this.properties[this.properties.id].dimension;

                if (this.properties[this.properties.id].id == 'AverageCost') {
                    this.properties[this.properties.id].mode =
                        [
                            CostOption.CostPerClaim,
                            CostOption.CostPerClaimant
                        ].indexOf(this.properties[this.properties.id].mode) <= -1
                            ? CostOption.CostPerClaim
                            : this.properties[this.properties.id].mode;

                    this.dataService
                        .getAverageCostData(this.properties[this.properties.id])
                        .toPromise()
                        .then(r => {
                            this.dashboardBase.data = r;
                            this.dashboardBase.initData();
                            this.isLoading = false;
                        });
                    //this.logUserAction("AverageCost");
                } else if (this.properties[this.properties.id].id == 'Utilization') {
                    this.properties[this.properties.id].mode =
                        [
                            CostOption.CostPerClaimant,
                            CostOption.UtilizationRatio,
                            CostOption.FreqOfUse
                        ].indexOf(this.properties[this.properties.id].mode) <= -1
                            ? CostOption.ClaimsPerClaimant
                            : this.properties[this.properties.id].mode;

                    this.dataService
                        .getUtilizationData(this.properties[this.properties.id])
                        .toPromise()
                        .then(r => {
                            this.dashboardBase.data = r;
                            this.dashboardBase.initData();
                            this.isLoading = false;
                        });
                }
                //this.logUserAction("Utilization");
                break;
            }
            case 'PerMemberCosts': {
                this.properties[this.properties.id].dimension =
                    [
                        DimensionOptions.AgeBand,
                        DimensionOptions.Gender,
                        DimensionOptions.Relationship
                    ].indexOf(this.properties[this.properties.id].dimension) <= -1
                        ? DimensionOptions.Relationship
                        : this.properties[this.properties.id].dimension;
                this.dataService
                    .getPerMemberCostsData(this.properties[this.properties.id])
                    .toPromise()
                    .then(r => {
                        this.dashboardBase.data = r;
                        this.dashboardBase.initData();
                        this.isLoading = false;
                    });
                // this.logUserAction("PerMemberCosts");

                break;
            }
            case 'LossRatio': {
                this.dataService
                    .getLossRatio(this.properties[this.properties.id])
                    .toPromise()
                    .then(r => {
                        this.dashboardBase.data = r;
                        this.dashboardBase.initData();
                        this.isLoading = false;
                    });
                // this.logUserAction("LossRatio");
                break;
            }
            case 'DistributionByClaimType': {

                this.properties[this.properties.id].dimension = DimensionOptions.ClaimType;
                this.properties[this.properties.id].secondaryDimension =
                    [
                        DimensionOptions.AgeBand,
                        DimensionOptions.Gender,
                        DimensionOptions.Relationship
                    ].indexOf(this.properties[this.properties.id].secondaryDimension) <= -1
                        ? DimensionOptions.Relationship
                        : this.properties[this.properties.id].secondaryDimension;
                this.dataService
                    .getTier3DataWith2Dimensions(this.properties[this.properties.id])
                    .toPromise()
                    .then(r => {
                        this.dashboardBase.data = r;
                        this.dashboardBase.initData();
                        this.isLoading = false;
                    });
                // this.logUserAction("DistributionByClaimType");
                break;
            }
            case 'DoubleQuadrantProDiag': {
                if (this.properties[this.properties.id].id == 'DoubleQuadrantProDiag') {
                    this.isLoading = true;
                    var finalData = {};
                    this.properties[this.properties.id].dimension = DimensionOptions.ProviderName;
                    this.properties[this.properties.id].secondaryDimension = '';
                    this.properties[this.properties.id].secondaryDimensionValue = '';
                    this.dataService
                        .getTier3Data(this.properties[this.properties.id])
                        .toPromise()
                        .then(r => {
                            finalData['providerList'] = r;
                            this.properties[this.properties.id].dimension = DimensionOptions.DiagChapter;
                            this.dataService
                                .getTier3Data(this.properties[this.properties.id])
                                .toPromise()
                                .then(re => {
                                    finalData['diagList'] = re;
                                    this.dashboardBase.data = finalData;
                                    this.dashboardBase.initData();
                                    this.isLoading = false;
                                });
                        });
                }
                //this.logUserAction("DoubleQuadrantProDiag");
                break;

            }
            case 'CoMorbidity': {
                if (this.properties[this.properties.id].id === 'CoMorbidity') {
                    this.isLoading = true;

                    this.isLoading = false;
                    this.dataService
                        .getCoMorbidityData(this.properties[this.properties.id])
                        .toPromise()
                        .then(r => {
                            this.dashboardBase.data = r;
                            this.dashboardBase.initData();
                            this.isLoading = false;
                            console.log(r);
                        });
                }
                break;
            }
            default:
                return null;
        }
    }

    appendComponentToBody() {
        if (typeof this.properties[this.properties.id] === 'undefined') {
            this.properties[this.properties.id] = JSON.parse(JSON.stringify(this.clonedProperties));
        }

        const viewContainerRef = this.templateInsert;
        viewContainerRef.clear();

        const componentType = TemplateMapper[this.template];

        if (!componentType) {
            this.templateNotFound = false;
            return;
        }

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            componentType
        );
        const componentRef = viewContainerRef.createComponent(componentFactory);
        this.dashboardBase = <DashboardTemplateBase>componentRef.instance;
        this.dashboardBase.properties = this.properties[this.properties.id];
    }

    initDashboardComponent() {
        this.getDashboardData();

        if (this.dashboardBase.action) {
            this.dashboardBase.action.subscribe(event =>
                this.actionHandler(event)
            );
        }
    }

    actionHandler(event) {
        if (event.type === PulseEventName.NAVIGATE) {
            if (event.data.properties) {
                const details = this.navigateEventPropsUpdate(event);
                this.onNavigate.emit(details);
            }
        } else if (event.type === PulseEventName.FILTLER_CHANGED) {
            if (event.data.properties) {
                this.filterChangedEventPropsUpdate(event);
            }

            this.getDashboardData();
        } else if (event.type === PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH) {
            if (event.data.properties) {
                this.filterChangedWithoutRefreshEventPropsUpdate(event);
            }
        } else if (event.type === PulseEventName.ADDITIONAL_FILTER_CHANGED) {
            if (event.data.properties) {
                this.additionalFilterChangedPropsUpdate(event)
            }

            this.dashboardBase.filterOptions = null;

            this.dataService
                .updateFilterOptions(this.properties)
                .toPromise()
                .then(r => {
                    this.dashboardBase.filterOptions = r;
                }, r => {
                    this.dashboardBase.filterOptions = r;
                });

            this.getDashboardData();
        } else if (event.type === PulseEventName.ADDITIONAL_FILTER_RESET_ALL) {
            this.resetAdditionalFilters();
        } else if (event.type === PulseEventName.FILTLER_CHANGED_WITH_NAVIGATE) {
            const details = this.filterChangedWithNavigatePropsUpdate(event);
            this.onNavigate.emit(details);
        }
    }

    navigateEventPropsUpdate(event) {
        const { name, category } = event.data.properties;

        this.properties.name = name;
        this.properties.category = category;
        this.properties.id = event.data.id;

        if (!this.properties[this.properties.id]) {
            this.properties[this.properties.id] = JSON.parse(JSON.stringify(this.properties));
        }

        const details = {
            name,
            id: event.data.id
        };
        console.log("Navigation details: ");
        console.log(details);

        return details;
    }

    filterChangedEventPropsUpdate(event) {
        const { name, value, category } = event.data.properties;

        this.properties[this.properties.id][name] = value;
        this.changeFilterLogDictionary[name] = value;

        if (category) {
            this.properties[this.properties.id].category = category;
        }
    }

    filterChangedWithoutRefreshEventPropsUpdate(event) {
        const { name, value } = event.data.properties;

        this.properties[this.properties.id][name] = value;
        this.changeFilterLogDictionary[name] = value;
    }

    additionalFilterChangedPropsUpdate(event) {
        const { name, value } = event.data.properties;

        this.properties[name] = value;
        this.properties[this.properties.id][name] = value;
    }

    filterChangedWithNavigatePropsUpdate(event) {
        const navDetails = this.navigateEventPropsUpdate(event);

        const { filterName, filterValue, filterCategory } = event.data.properties;

        this.properties[this.properties.id][filterName] = filterValue;
        this.changeFilterLogDictionary[filterName] = filterValue;

        if (filterCategory) {
            this.properties[this.properties.id].category = filterCategory;
        }

        return navDetails;
    }

    resetAdditionalFilters(): void {
        this.dashboardBase.showFilter = false;

        delete this.properties['aClientNames'];
        delete this.properties['aEntities'];
        delete this.properties['aDivisions'];
        delete this.properties['aPlans'];
        delete this.properties['aTimePeriods'];
        delete this.properties['aTimePeriodsLossRatio'];
        delete this.properties['aTimePeriodsEnrollment'];
        delete this.properties['aPolicyTypes'];
        delete this.properties['aAgeBands'];
        delete this.properties['aGenders'];
        delete this.properties['aClaimTypes'];
        delete this.properties['aClaimTypes2'];
        delete this.properties['aClaimTypes3'];
        delete this.properties['aRelationships'];
        delete this.properties['aDiagCats'];
        delete this.properties['aDiagChapters'];
        delete this.properties['aLocations'];
        delete this.properties['aChronics'];
        delete this.properties['aCarriers'];
        delete this.properties['aLobs'];
        delete this.properties['aSubLobs'];
        delete this.properties['aNetworks'];
        delete this.properties['aProviderNames'];
        delete this.properties['aProviderLocations'];
        delete this.properties['aProviderTypes'];

        this.resetAdditionalFiltersForDashboard();

        setTimeout(() => {
            this.dashboardBase.filterOptions = {...this._additionalFiltersService.getInitialOptions()};
            this.dashboardBase.showFilter = true;
        });

        this.getDashboardData();
    }

    resetAdditionalFiltersForDashboard(): void {
        delete this.properties[this.properties.id]['aClientNames'];
        delete this.properties[this.properties.id]['aEntities'];
        delete this.properties[this.properties.id]['aDivisions'];
        delete this.properties[this.properties.id]['aPlans'];
        delete this.properties[this.properties.id]['aTimePeriods'];
        delete this.properties[this.properties.id]['aTimePeriodsLossRatio'];
        delete this.properties[this.properties.id]['aTimePeriodsEnrollment'];
        delete this.properties[this.properties.id]['aPolicyTypes'];
        delete this.properties[this.properties.id]['aAgeBands'];
        delete this.properties[this.properties.id]['aGenders'];
        delete this.properties[this.properties.id]['aClaimTypes'];
        delete this.properties[this.properties.id]['aClaimTypes2'];
        delete this.properties[this.properties.id]['aClaimTypes3'];
        delete this.properties[this.properties.id]['aRelationships'];
        delete this.properties[this.properties.id]['aDiagCats'];
        delete this.properties[this.properties.id]['aDiagChapters'];
        delete this.properties[this.properties.id]['aLocations'];
        delete this.properties[this.properties.id]['aChronics'];
        delete this.properties[this.properties.id]['aCarriers'];
        delete this.properties[this.properties.id]['aLobs'];
        delete this.properties[this.properties.id]['aSubLobs'];
        delete this.properties[this.properties.id]['aNetworks'];
        delete this.properties[this.properties.id]['aProviderNames'];
        delete this.properties[this.properties.id]['aProviderLocations'];
        delete this.properties[this.properties.id]['aProviderTypes'];
    }

    populateAdditionalFiltersForDashboard(): void {
        if (typeof this.properties['aClientNames'] !== 'undefined') {
            this.properties[this.properties.id]['aClientNames'] = this.properties['aClientNames'];
        }

        if (typeof this.properties['aEntities'] !== 'undefined') {
            this.properties[this.properties.id]['aEntities'] = this.properties['aEntities'];
        }

        if (typeof this.properties['aDivisions'] !== 'undefined') {
            this.properties[this.properties.id]['aDivisions'] = this.properties['aDivisions'];
        }

        if (typeof this.properties['aPlans'] !== 'undefined') {
            this.properties[this.properties.id]['aPlans'] = this.properties['aPlans'];
        }

        if (typeof this.properties['aTimePeriods'] !== 'undefined') {
            this.properties[this.properties.id]['aTimePeriods'] = this.properties['aTimePeriods'];
        }

        if (typeof this.properties['aTimePeriodsLossRatio'] !== 'undefined') {
            this.properties[this.properties.id]['aTimePeriodsLossRatio'] = this.properties['aTimePeriodsLossRatio'];
        }

        if (typeof this.properties['aTimePeriodsEnrollment'] !== 'undefined') {
            this.properties[this.properties.id]['aTimePeriodsEnrollment'] = this.properties['aTimePeriodsEnrollment'];
        }

        if (typeof this.properties['aPolicyTypes'] !== 'undefined') {
            this.properties[this.properties.id]['aPolicyTypes'] = this.properties['aPolicyTypes'];
        }

        if (typeof this.properties['aAgeBands'] !== 'undefined') {
            this.properties[this.properties.id]['aAgeBands'] = this.properties['aAgeBands'];
        }

        if (typeof this.properties['aGenders'] !== 'undefined') {
            this.properties[this.properties.id]['aGenders'] = this.properties['aGenders'];
        }

        if (typeof this.properties['aClaimTypes'] !== 'undefined') {
            this.properties[this.properties.id]['aClaimTypes'] = this.properties['aClaimTypes'];
        }

        if (typeof this.properties['aClaimTypes2'] !== 'undefined') {
            this.properties[this.properties.id]['aClaimTypes2'] = this.properties['aClaimTypes2'];
        }

        if (typeof this.properties['aClaimTypes3'] !== 'undefined') {
            this.properties[this.properties.id]['aClaimTypes3'] = this.properties['aClaimTypes3'];
        }

        if (typeof this.properties['aRelationships'] !== 'undefined') {
            this.properties[this.properties.id]['aRelationships'] = this.properties['aRelationships'];
        }

        if (typeof this.properties['aDiagCats'] !== 'undefined') {
            this.properties[this.properties.id]['aDiagCats'] = this.properties['aDiagCats'];
        }

        if (typeof this.properties['aDiagChapters'] !== 'undefined') {
            this.properties[this.properties.id]['aDiagChapters'] = this.properties['aDiagChapters'];
        }

        if (typeof this.properties['aLocations'] !== 'undefined') {
            this.properties[this.properties.id]['aLocations'] = this.properties['aLocations'];
        }

        if (typeof this.properties['aChronics'] !== 'undefined') {
            this.properties[this.properties.id]['aChronics'] = this.properties['aChronics'];
        }

        if (typeof this.properties['aCarriers'] !== 'undefined') {
            this.properties[this.properties.id]['aCarriers'] = this.properties['aCarriers'];
        }

        if (typeof this.properties['aLobs'] !== 'undefined') {
            this.properties[this.properties.id]['aLobs'] = this.properties['aLobs'];
        }

        if (typeof this.properties['aSubLobs'] !== 'undefined') {
            this.properties[this.properties.id]['aSubLobs'] = this.properties['aSubLobs'];
        }

        if (typeof this.properties['aNetworks'] !== 'undefined') {
            this.properties[this.properties.id]['aNetworks'] = this.properties['aNetworks'];
        }

        if (typeof this.properties['aProviderNames'] !== 'undefined') {
            this.properties[this.properties.id]['aProviderNames'] = this.properties['aProviderNames'];
        }

        if (typeof this.properties['aProviderLocations'] !== 'undefined') {
            this.properties[this.properties.id]['aProviderLocations'] = this.properties['aProviderLocations'];
        }

        if (typeof this.properties['aProviderTypes'] !== 'undefined') {
            this.properties[this.properties.id]['aProviderTypes'] = this.properties['aProviderTypes'];
        }
    }

    getClaimantFilterValue(): number {
        if (this.properties.country != null) {
            const countryLATEM = ['Chile', 'Colombia', 'Ecuador', 'Mexico', 'Peru'];

            return countryLATEM.indexOf(this.properties.country) >= 0 ? 0 : 5;
        }

        return 5;
    }

    extractDataForExport() {
        return JSON.parse(
            JSON.stringify({
                chartData: this.dashboardBase.data,
                chartProperties: this.properties[this.properties.id],
                changeFilterLogDictionary: this.changeFilterLogDictionary
            })
        );
    }

    loadDataForExport(
        template: string,
        chartData: any,
        chartProperties: any,
        changeFilterLogDictionary: any
    ) {
        const viewContainerRef = this.templateInsert;
        viewContainerRef.clear();

        const componentType = TemplateMapper[template];

        if (!componentType) {
            this.templateNotFound = false;
            return;
        }

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            componentType
        );
        const componentRef = viewContainerRef.createComponent(componentFactory);

        this.dashboardBase = <DashboardTemplateBase>componentRef.instance;
        if (template == 'HealthDiseaseProfile' || template == 'PrevalenceDiseaseProfile') {
            this.dashboardBase.isExport = true;
        }
        this.dashboardBase.properties = chartProperties;
        this.dashboardBase.imageUrlBase = environment.imageUrlBase;
        this.dashboardBase.eligibleForBenchmark = chartProperties.eligibleForBenchmark;

        setTimeout(() => {
            this.dashboardBase.filterOptions = this.dashboardBase.properties.filterOptions;

            this.dashboardBase.data = chartData;
            this.dashboardBase.initData();
            //this.properties = chartProperties;
            this.changeFilterLogDictionary = changeFilterLogDictionary;
        });
    }
}

export const AdditionalFilterList = [
    'aClientNames', 'aEntities', 'aDivisions', 'aPlans', 'aTimePeriods', 'aTimePeriodsLossRatio', 'aTimePeriodsEnrollment', 'aPolicyTypes', 'aAgeBands', 'aGenders', 'aClaimTypes',
    'aClaimTypes2', 'aClaimTypes3', 'aRelationships', 'aDiagCats', 'aDiagChapters', 'aLocations', 'aChronics', 'aCarriers', 'aLobs', 'aSubLobs', 'aNetworks', 'aProviderNames',
    'aProviderLocations', 'aProviderTypes'
];

export const TemplateMapper = {
    ExecutiveSummary: ExecutiveSummaryComponent,
    ExecutiveSummaryROW: ExecutiveSummaryRowComponent,
    HealthDiseaseProfile: HealthDiseaseProfileComponent,
    PrevalenceDiseaseProfile: PrevalenceOfDiseaseComponent,
    AverageCostUtilization: AverageCostUtilComponent,
    PerMemberCosts: PerMemberCostsComponent,
    ClaimCostProviderDiagnosis: ClaimCostTopProviderComponent,
    ClaimsOverview: ClaimsOverviewComponent,
    ClaimTypeDiagnosis: ClaimTypeDiagnosisComponent,
    ClaimType: ClaimTypeComponent,
    ClaimByAgeBand: ClaimByAgeBandComponent,
    LossRatio: LossRatioComponent,
    EnrollmentByTime: EnrollmentByTimeComponent,
    EnrollmentByAgeBand: EnrollmentByAgeBandComponent,
    DistributionByClaimType: DistributionByClaimTypeComponent,
    DoubleQuadrantProDiag: DoubleQuadrantProDiagComponent,
    CoMorbidity: CoMorbidityComponent
};

export enum PortfolioIndustryNoneOption {
    Portfolio = 1,
    Industry = 2,
    None = 3
}

export enum AgeBandGenderRelationshipOption {
    AgeBand = 'Age Band',
    Gender = 'Gender',
    Relationship = 'Relationship'
}

export enum DimensionOptions {
    AgeBand = 'Age Band',
    Gender = 'Gender',
    ClaimType = 'Claim Type',
    Network = 'Network',
    Location = 'Location',
    ProviderType = 'Provider Type',
    Relationship = 'Relationship',
    Employee = 'Employee',
    ProviderName = 'Provider Name',
    ProviderLocation = 'Provider Location',
    Plan = 'Plan',
    PolicyType = 'Policy Type',
    LOB = 'LOB',
    SubLOB = 'Sub LOB',
    ClaimType2 = 'Claim Type 2',
    DiagCat = 'Diag Cat',
    DiagChapter = 'Diag Chapter',
    None = 'None',
    Entity = 'Entity'
}

export enum SortOption {
    ClaimTotal = 'claimsTotal',
    CountClaim = 'claimsCount',
    CountClaimant = 'claimantCount',
    AvergareCostlaim = 'averageCostPerClaim',
    AvergareCostlaimant = 'averageCostPerClaimant',
    ChangeInCost = '%ChangeInCost'
}
export enum ClaimantOption {
    Claim = 'Claim',
    Claimant = 'Claimant'
}
export enum ShowHideOption {
    Show = 'Show',
    Hide = 'Hide'
}
export enum CategoryChapterOption {
    DiagCat = 'Diag Cat',
    DiagChapter = 'Diag Chapter'
}
export enum ClaimPMPMOption {
    ClaimsTotal = 'Claims Total',
    ClaimsPMPM = 'Claims PMPM'
}
export enum LossRatioOption {
    LossRatio = 'Loss Ratio',
    CombinedLR = 'Combined LR'
}
export enum PeriodOption {
    Monthly = 1,
    YearBasis = 2,
    YearBasisC = 3,
    Rolling3 = 4,
    Rolling6 = 5,
    Rolling9 = 6,
    Rolling12 = 7
}
export enum MeasureOption {
    Number = 'Number',
    Distribution = '%Distribution'
}
export enum DataDiagProOption {
    DataTable = 'DataTable',
    TopDiagnoses = 'Diag Chapter',
    TopProviders = 'Provider Name'
}
export enum ClaimClaimantsTotalOption {
    ClaimsTotal = "claimsTotal",
    ClaimantsCount = "claimantCount"
}
export enum ChartTable {
    Chart = 'Chart',
    Table = 'Table'
}