import {
    Component,
    OnInit,
    ViewChild,
    Inject,
    ChangeDetectorRef,
    ViewContainerRef,
    ComponentFactoryResolver
} from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { AdminService } from '../../services/admin.service';
import { AgmatcheckboxComponent } from '../../shared/agmatcheckbox/agmatcheckbox.component';
import { AgheadermatcheckboxComponent } from '../../shared/agheadermatcheckbox/agheadermatcheckbox.component';
import { UserService } from 'app/services/user.service';
import { CartService } from 'app/services/cart.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { TableauService } from 'app/services/tableau.service';
import { ExportService } from 'app/services/export.service';
import { Store } from '@ngrx/store';
import { State } from '../../_reducers';
import { Router, NavigationEnd } from '@angular/router';
import { GlobalfiltersService } from 'app/services/globalfilters.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { locale as enLocale } from './i18n/en';
import { locale as esLocaleCL } from './i18n/es-cl';
import { locale as esLocaleCO } from './i18n/es-co';
import { locale as esLocaleEC } from './i18n/es-ec';
import { locale as esLocaleMX } from './i18n/es-mx';
import { locale as esLocalePE } from './i18n/es-pe';
import { locale as ptLocaleBR } from './i18n/pt-br';
import { locale as itLocale } from './i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { filter } from 'rxjs/operators';
import { WorkbookTypeEnum } from 'app/story/storycontainer/storycontainer.component';
import {
    DashboardcontainerComponent,
    TemplateMapper
} from 'app/dashboards/dashboardcontainer/dashboardcontainer.component';
import { DashboardTemplateBase } from 'app/dashboards/templates/dashboard-template-base.component';
import { userActionLog, LogActionType } from 'app/models/log.model';
import { LoggerService } from 'app/services/logger.service';

@Component({
    selector: 'app-exportcart',
    templateUrl: './exportcart.component.html',
    styleUrls: ['./exportcart.component.css']
})
export class ExportcartComponent implements OnInit {
    storyOpened: any;
    logModel: userActionLog = new userActionLog();

    constructor(
        private _router: Router,
        private _ref: ChangeDetectorRef,
        private _cartService: CartService,
        private _exportService: ExportService,
        private _globalFiltersService: GlobalfiltersService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        public dialog: MatDialog,
        private store: Store<State>,
        private _loggerService: LoggerService
    ) {
        this._cartService.getObservableItems().subscribe(cartItems => {
            console.log('SUBSCRIBE FUNC');
            this.cartItems = cartItems;
        });

        this._translateService.onLangChange.subscribe(
            (event: LangChangeEvent) => {
                this.setCartColDef();
            }
        );

        const currStorySession = sessionStorage.pulseCurrentStory;
        if (currStorySession) {
            this.storyOpened = JSON.parse(currStorySession);
        }
    }

    navigateToStoryOpened() {
        console.log(
            this.storyOpened,
            '/story/' + this.storyOpened.mode + '/' + this.storyOpened.id
        );
        this._router.navigate([
            '/story/' + this.storyOpened.mode + '/' + this.storyOpened.id
        ]);
    }

    ngOnInit() {
        this._fuseTranslationLoaderService.loadTranslations(
          enLocale,
          esLocaleCL,
          esLocaleCO,
          esLocaleEC,
          esLocaleMX,
          esLocalePE,
          ptLocaleBR,
          itLocale,
        );

        this.refreshCartItems();
        this.setCartColDef();

        this.store.select('user').subscribe(res => {
            this.user = res;
            this.clientName =
                this.user.client == null ? '' : this.user.client.name;
        });

        this.isExportSectionEnabled = false;
        this.isExporting = false;
        this.exportTitle = '';
        // this.exportTitle = this.getExportTitleDefault();
    }

    user: any;
    clientName: string;
    isExportSectionEnabled: boolean;
    isExporting: boolean;
    cartItems: any[];
    cartItemsGrid: any[];
    selectedDashboard: any;
    dashboardColumnDefs = [];
    exportTitle: string;
    rowDragManagedValue: boolean = false;
    additionalFilterList: any[] = [];

    setCartColDef() {
        const colDefs = [
            {
                headerName: this._translateService.instant('ExportCart.Name'),
                field: 'dashboardName',
                rowDrag: true,
                cellRenderer: cell => {
                    if (cell.data.filterName) {
                        return (
                            cell.data.dashboardName +
                            ' - ' +
                            cell.data.filterName
                        );
                    } else {
                        return cell.data.dashboardName;
                    }
                }
            }
        ];

        this.dashboardColumnDefs = colDefs;
        this.rowDragManagedValue = true;
    }

    isArray(obj) {
        return Array.isArray(obj);
    }

    refreshCartItems() {
        this.cartItemsGrid = this._cartService.getItems();
    }

    onToggleExportSectionShown() {
        this.isExportSectionEnabled = !this.isExportSectionEnabled;
    }

    onDashboardSelectionChanged($event) {
        this.selectedDashboard = $event.data;
        this.processAdditionalFilterList();
    }

    processAdditionalFilterList() {
        this.additionalFilterList = [];

        if (this.selectedDashboard.workbookType === WorkbookTypeEnum.Tableau) {
            return;
        }

        const properties = this.selectedDashboard.dashboardProperties;

        if (typeof properties['aClientNames'] !== 'undefined') {
            const list = String(properties['aClientNames']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Client Name', selectedValues: list });
            }
        }

        if (typeof properties['aEntities'] !== 'undefined') {
            const list = String(properties['aEntities']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Entity', selectedValues: list });
            }
        }

        if (typeof properties['aDivisions'] !== 'undefined') {
            const list = String(properties['aDivisions']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Division', selectedValues: list });
            }
        }

        if (typeof properties['aPlans'] !== 'undefined') {
            const list = String(properties['aPlans']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Plan', selectedValues: list });
            }
        }     
        
        if (typeof properties['aTimePeriods'] !== 'undefined') {
            const list = String(properties['aTimePeriods']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Time Period', selectedValues: list });
            }
        } 

        if (typeof properties['aPolicyTypes'] !== 'undefined') {
            const list = String(properties['aPolicyTypes']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Policy Type', selectedValues: list });
            }
        } 

        if (typeof properties['aAgeBands'] !== 'undefined') {
            const list = String(properties['aAgeBands']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Age Band', selectedValues: list });
            }
        } 

        if (typeof properties['aGenders'] !== 'undefined') {
            const list = String(properties['aGenders']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Gender', selectedValues: list });
            }
        } 

        if (typeof properties['aClaimTypes'] !== 'undefined') {
            const list = String(properties['aClaimTypes']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Claim Type', selectedValues: list });
            }
        } 

        if (typeof properties['aClaimTypes2'] !== 'undefined') {
            const list = String(properties['aClaimTypes2']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Claim Type 2', selectedValues: list });
            }
        } 

        if (typeof properties['aClaimTypes3'] !== 'undefined') {
            const list = String(properties['aClaimTypes3']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Claim Type 3', selectedValues: list });
            }
        } 


        if (typeof properties['aRelationships'] !== 'undefined') {
            const list = String(properties['aRelationships']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Relationship', selectedValues: list });
            }
        } 

        if (typeof properties['aDiagCats'] !== 'undefined') {
            const list = String(properties['aDiagCats']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Diagnosis Category', selectedValues: list });
            }
        }  
        
        if (typeof properties['aDiagChapters'] !== 'undefined') {
            const list = String(properties['aDiagChapters']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Diagnosis Chapter', selectedValues: list });
            }
        }   

        if (typeof properties['aLocations'] !== 'undefined') {
            const list = String(properties['aLocations']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Location', selectedValues: list });
            }
        }

        if (typeof properties['aChronics'] !== 'undefined') {
            const list = String(properties['aChronics']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Is Chronic', selectedValues: list });
            }
        }

        if (typeof properties['aCarriers'] !== 'undefined') {
            const list = String(properties['aCarriers']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Carrier', selectedValues: list });
            }
        }

        if (typeof properties['aLobs'] !== 'undefined') {
            const list = String(properties['aLobs']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'LOB', selectedValues: list });
            }
        }

        if (typeof properties['aSubLobs'] !== 'undefined') {
            const list = String(properties['aSubLobs']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Sub LOB', selectedValues: list });
            }
        }

        if (typeof properties['aNetworks'] !== 'undefined') {
            const list = String(properties['aNetworks']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Network', selectedValues: list });
            }
        }

        if (typeof properties['aProviderNames'] !== 'undefined') {
            const list = String(properties['aProviderNames']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Provider Name', selectedValues: list });
            }
        }

        if (typeof properties['aProviderLocations'] !== 'undefined') {
            const list = String(properties['aProviderLocations']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Provider Location', selectedValues: list });
            }
        }

        if (typeof properties['aProviderTypes'] !== 'undefined') {
            const list = String(properties['aProviderTypes']).split('||');
            if (list.length > 0 && list.indexOf('ALL') < 0) {
                this.additionalFilterList.push({ name: 'Provider Type', selectedValues: list });
            }
        }
    }

    onMoveCartItem($event) {
        this._cartService.setItems($event.data);
        this.refreshCartItems();
    }

    onRemoveCartItem($event) {
        var index = this.cartItemsGrid.indexOf($event.data);

        if (this.selectedDashboard === $event.data) {
            this.selectedDashboard = null;
        }

        this._cartService.removeItem(index);
        this.refreshCartItems();
    }

    clearCartItems() {
        this._cartService.clearItems();
        this.refreshCartItems();
    }

    onOpenDashboardPreviewDialog(): void {
        let globalFiltersString: string;

        if (this.selectedDashboard.workbookType === WorkbookTypeEnum.Tableau) {
            globalFiltersString = this._globalFiltersService.getGlobalFiltersString(
                this.user,
                this.selectedDashboard.workbookId,
                true
            );
        } else {
            globalFiltersString = null;
        }

        const dialogRef = this.dialog.open(ExportCartDashboardDialog, {
            minWidth: '80vw',
            minHeight: '50vh',
            data: {
                selectedDashboard: this.selectedDashboard,
                globalFiltersString: globalFiltersString
            }
        });

        dialogRef.afterClosed().subscribe(result => {});
    }


    exportCartItems() {
        this.isExporting = true;
        const dashboardList = [];
        const exportLogdashboardList = [];

        const globalFiltersString = this._globalFiltersService.getGlobalFiltersString(
            this.user,
            this.cartItems[0].workbookId,
            true
        );

        for (let i = 0; i < this.cartItems.length; i++) {
            const cartItem = this.cartItems[i];
            let combinedFiltersString = '';
            if (cartItem.workbookType === WorkbookTypeEnum.Tableau) {
                combinedFiltersString = '';

                if (globalFiltersString && cartItem.filterListString) {
                    combinedFiltersString =
                        globalFiltersString + '&' + cartItem.filterListString;
                }

                if (
                    globalFiltersString &&
                    cartItem.filterListString.length === 0
                ) {
                    combinedFiltersString = globalFiltersString;
                }

                if (
                    globalFiltersString.length === 0 &&
                    cartItem.filterListString
                ) {
                    combinedFiltersString = cartItem.filterListString;
                }

                dashboardList.push({
                    workbookId: cartItem.workbookId,
                    title: cartItem.dashboardName,
                    viewId: cartItem.viewId,
                    filterName: cartItem.filterName,
                    filterList: cartItem.filterList,
                    filterString: combinedFiltersString,
                    comments: cartItem.comments,
                    chartData: null,
                    chartType: cartItem.workbookType
                });
                
            
            exportLogdashboardList.push({
                workbookId: cartItem.workbookId,
                title: cartItem.dashboardName,
                viewId: cartItem.viewId,
                filterName: cartItem.filterName,
                filterList: cartItem.filterList,
                filterString: combinedFiltersString,
                comments: cartItem.comments,
                chartData: null,
                chartType: cartItem.workbookType
            });
            }

            if (cartItem.workbookType === WorkbookTypeEnum.Custom) {
                dashboardList.push({
                    workbookId: cartItem.workbookId,
                    title: cartItem.dashboardName,
                    viewId: cartItem.viewId,
                    filterName: cartItem.filterName,
                    filterList: cartItem.filterList,
                    filterString: null,
                    comments: cartItem.comments,
                    chartData: JSON.stringify({
                        data: cartItem.dashboardData,
                        properties: cartItem.dashboardProperties
                    }),
                    chartType: cartItem.workbookType
                }); 
            
            exportLogdashboardList.push({
                workbookId: cartItem.workbookId,
                title: cartItem.dashboardName,
                viewId: cartItem.viewId,
                filterName: cartItem.filterName,
                filterList: cartItem.filterList,
                filterString: combinedFiltersString,
                comments: cartItem.comments,
                chartData: JSON.stringify({ 
                    properties: cartItem.dashboardProperties
                }),
                chartType: cartItem.workbookType
            });
            }

            
            exportLogdashboardList.push({
                workbookId: cartItem.workbookId,
                title: cartItem.dashboardName,
                viewId: cartItem.viewId,
                filterName: cartItem.filterName,
                filterList: cartItem.filterList,
                filterString: combinedFiltersString,
                comments: cartItem.comments,
                chartData: JSON.stringify({ 
                    properties: cartItem.dashboardProperties
                }),
                chartType: cartItem.workbookType
            });

        }

        this._exportService
            .exportToPpt(
                this.user.language,
                this.user.Email,
                this.clientName,
                this.exportTitle,
                dashboardList
            )
            .toPromise()
            .then(data => {
                this.exportTitle = '';
                this.isExportSectionEnabled = false;
                this.isExporting = false;
                if (data) {
                    this._router.navigate(['/export/queue']);
                } else {
                    alert('Export Fail');
                }
            });

        if (this.user.client && this.user.country) {
            this._loggerService.logNormalPptExport(this.user.client.tier1Id, this.user.country.tier2Id, { emailAddress: this.user.email, siteName: environment.siteName, clientName: this.clientName, titleName: this.exportTitle, dashboardInputsList: dashboardList}).toPromise();
        }
    }
}

@Component({
    selector: 'export-cart-dashboard-dialog',
    templateUrl: './exportcartdashboarddialog/exportcartdashboarddialog.html'
})
export class ExportCartDashboardDialog {
    isLoading: boolean = true;
    dashboardImage: string;
    dashboardType: WorkbookTypeEnum;
    workbookTypeEnum = WorkbookTypeEnum;

    @ViewChild(DashboardcontainerComponent, { static: true })
    private dashboardcontainerComponent: DashboardcontainerComponent;
    logModel: userActionLog = new userActionLog();

    constructor(
        private _tableauService: TableauService,
        private _exportService: ExportService,
        private componentFactoryResolver: ComponentFactoryResolver,
        public dialogRef: MatDialogRef<ExportCartDashboardDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        console.log(data.selectedDashboard);

        this.isLoading = true;
        this.dashboardType = data.selectedDashboard.workbookType;

        if (this.dashboardType === WorkbookTypeEnum.Tableau) {
            const globalFiltersString = data.globalFiltersString;
            const filterListString = data.selectedDashboard.filterListString;

            let combinedFiltersString = '';

            if (globalFiltersString && filterListString) {
                combinedFiltersString =
                    globalFiltersString + '&' + filterListString;
            }

            if (globalFiltersString && filterListString.length === 0) {
                combinedFiltersString = globalFiltersString;
            }

            if (globalFiltersString.length === 0 && filterListString) {
                combinedFiltersString = filterListString;
            }

            this._exportService
                .exportToImage(
                    data.selectedDashboard.workbookId,
                    data.selectedDashboard.viewId,
                    data.selectedDashboard.filterList,
                    combinedFiltersString
                )
                .toPromise()
                .then((imageString: string) => {
                    this.dashboardImage = imageString;
                });
        }

        if (this.dashboardType === WorkbookTypeEnum.Custom) {
            setTimeout(() => {
                const changeFilterLogDictionary = {};
                data.selectedDashboard.filterList.forEach(filter => {
                    changeFilterLogDictionary[filter.name] =
                        filter.selectedValues;
                });
                this.dashboardcontainerComponent.loadDataForExport(
                    data.selectedDashboard.workbookId,
                    data.selectedDashboard.dashboardData,
                    data.selectedDashboard.dashboardProperties,
                    changeFilterLogDictionary
                );
                this.isLoading = false;
            });
 
        }
    }

    onImageLoad() {
        this.isLoading = false;
    }
}
