import { Component, OnInit } from '@angular/core';
import { CostProjectionUser, CostProjectionUserSummary } from 'app/models/cost-projection/admin/cost-projection-users.model';
import { AdminService } from 'app/services/admin.service';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';

@Component({
  selector: 'app-cost-projection-admin',
  templateUrl: './cost-projection-admin.component.html',
  styleUrls: ['./cost-projection-admin.component.scss']
})
export class CostProjectionAdminComponent implements OnInit {

  public userSummary: CostProjectionUserSummary = null;

  public costProjectionUserColumnDefs = [
    { headerName: 'Username', field: 'name' },
    { headerName: 'Email', field: 'email' }
  ];

  constructor(
    private _adminService: AdminService,
    private _toasterService: ToasterService,
    ) { }

  ngOnInit(): void {
    this._adminService.retrieveCostProjectionUsers().subscribe((userSummary: CostProjectionUserSummary) =>{
      this.userSummary = userSummary;
    });
  }

  public onAssign(selectedUser: CostProjectionUser): void {
    // Remove selected user from unassigned list
    const userToBeAssignedIndex = this.userSummary.unassigned.findIndex(u => u.id === selectedUser.id);
    this.userSummary.unassigned.splice(userToBeAssignedIndex, 1);
    this.userSummary.unassigned = [...this.userSummary.unassigned];

    // Add selected user to assigned list
    this.userSummary.assigned.push(selectedUser);
    this.userSummary.assigned.sort(this._sortUserByName);
    this.userSummary.assigned = [...this.userSummary.assigned];

    // Add selected user to DB's allowed list
    this._adminService.assignCostProjectionUser(selectedUser.id)
      .subscribe(() => {
        this._toasterService
          .showMessage(
            `Success. ${selectedUser.name} (${selectedUser.email}) is granted access to Cost Projection.`,
            ToasterServiceModes.OK
            );
      }, () => {
        this._toasterService
          .showMessage(
            `Failed. ${selectedUser.name} (${selectedUser.email}) is not being granted access.`,
            ToasterServiceModes.NOK
            );
      });
  }

  public onUnassign(selectedUser: CostProjectionUser): void {
    // Remove selected user from assigned list
    const userToBeUnassignedIndex = this.userSummary.assigned.findIndex(u => u.id === selectedUser.id);
    this.userSummary.assigned.splice(userToBeUnassignedIndex, 1);
    this.userSummary.assigned = [...this.userSummary.assigned];

    // Add selected user to unassigned list
    this.userSummary.unassigned.push(selectedUser);
    this.userSummary.unassigned.sort(this._sortUserByName);
    this.userSummary.unassigned = [...this.userSummary.unassigned];

    // Remove selected user from DB's allowed list
    this._adminService.unassignCostProjectionUser(selectedUser.id)
      .subscribe(() => {
        this._toasterService
          .showMessage(
            `Success. ${selectedUser.name} (${selectedUser.email}) is revoked access to Cost Projection.`,
            ToasterServiceModes.OK
            );
      }, () => {
        this._toasterService
          .showMessage(
            `Failed. ${selectedUser.name} (${selectedUser.email}) is not being revoked access.`,
            ToasterServiceModes.NOK
            );
      });
  }

  private _sortUserByName(prev: CostProjectionUser, next: CostProjectionUser): number {
    if (prev.name < next.name) {
      return -1;
    }

    if (prev.name > next.name) {
      return 1;
    }

    return 0;
  }

}
