import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef, } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.css']
})
export class SearchSelectComponent implements OnInit {

  @Input() placeholder: string = 'test';
  @Input() dataList: any[] = [];
  @Input() isGrouping = false;
  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('searchInput', { static: true }) private searchInputRef: ElementRef<HTMLElement>;
  
  property = 'name';
  filteredDataList: any[];
  inputFilter = new FormControl();
  value: any;
  
  constructor() { }

  ngOnInit() {

    this.filteredDataList = this.dataList;

    this.inputFilter.valueChanges
    .debounceTime(800)
    .subscribe( result => { 
      this.filteredDataList = 
        this.dataList.filter(
          c => c.name.toLowerCase().indexOf(result.toLowerCase()) >= 0)
    });
  }

  ngOnChanges(){
    this.filteredDataList = this.dataList;
  }
  
  onSelectionChange(){
    this.selectionChange.emit({ data: this.value });
  }

  onInputChange(event: any){
    event.stopPropagation();
  }

  onOpenedChange(isOpened: boolean) {
    if (isOpened && typeof this.value === 'undefined') {
        setTimeout(() => {
            console.log(this.searchInputRef.nativeElement);
            this.searchInputRef.nativeElement.focus();
            this.searchInputRef.nativeElement.click();
        });
    }
  }

    openedChange(opened: boolean) {
        console.log(opened ? 'opened' : 'closed');
    }

  public setValue(value) {
    this.value = value;
  }

  compareFn(v1, v2) {
    return v1 && v2 && v1.name == v2.name;
  }
}
