import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class StoryService {

  constructor(private httpClient: HttpClient, 
    private _fuseNavigationService: FuseNavigationService) { }
  public currentStoryId;
  public adhocStoryList = [];
  
  public saveStory(param:any)
  {      
      return this.httpClient.post<any>(environment.api + 'api/story/save', param);
  }

  public getUserStories() {
    return this.httpClient.get<any>(environment.api + 'api/story/list')
  }

  public updateStoryNavigation(stories) {
    let storyMenuModel = {
      id: 'stories',
      title: 'STORIES',
      type: 'group',
      children: []
    };

    storyMenuModel.children = [];
            
    for(var count=0; count< stories.length; count++)
    {
      storyMenuModel.children.push({
          id: stories[count].storiesId,
          title: stories[count].name,
          type: 'item',
          icon: 'layers',
          url: '/story/view/'+stories[count].storiesId
      });
    }

    var sessionAdhocStoryList = sessionStorage.adhocStoryList;
    if(sessionAdhocStoryList) {
      this.adhocStoryList = JSON.parse(sessionAdhocStoryList);
      this.adhocStoryList.forEach(s => {
        var match = storyMenuModel.children.filter(c => c.id == s.id)[0];
        if(!match) {
          storyMenuModel.children.push(s);
        }
      })
    }

    this._fuseNavigationService.updateNavigationItem('stories', storyMenuModel);
  }

  public getAssignedUserStories() {
    return this.httpClient.get<any>(environment.api + 'api/story/assigned/list')
  }
  
  public deleteStoryById(storyId) {
    return this.httpClient.get<any>(environment.api + 'api/story/' + storyId + '/delete')
  }

  public getStoryById(id)
  {      
      return this.httpClient.get<any>(environment.api + 'api/story/' + id);
  }

  setCurrentOpenStory(story, mode = 'view') {
    var currentStory = sessionStorage.pulseCurrentStory ? JSON.parse(sessionStorage.pulseCurrentStory): {};
    if(!currentStory.id || currentStory.id != story.storiesId) {
      delete sessionStorage.storyIndex;
    }

    sessionStorage.pulseCurrentStory = JSON.stringify({
      name: story.name,
      mode: mode,
      id: story.storiesId
    });

    this.currentStoryId = story.storiesId;
    var storiesNav = this._fuseNavigationService.getNavigationItem('stories');
    
    var match = storiesNav.children.filter(c => c.id == story.storiesId)[0];
  
    if(!match) {
      var sNav = {
        id: story.storiesId,
        name: story.name ,
        title: story.name + '*',
        type: 'item',
        icon: 'layers',
        url: '/story/view/' + story.storiesId
      };
      storiesNav.children.push(sNav);

      var matchAdhoc = this.adhocStoryList.filter(c => c.id == story.storiesId)[0];
      if(!matchAdhoc) {
        this.adhocStoryList.push(sNav);
        sessionStorage.adhocStoryList = JSON.stringify(this.adhocStoryList);
      }
    }
  }

  setStoryIndex(index) {
    sessionStorage.storyIndex = index;
  }

  getStoryIndex() {
    var index = sessionStorage.storyIndex ? parseInt(sessionStorage.storyIndex) : 0; 
    return index;
  }

  clearCurrentOpenStory() {
    delete sessionStorage.pulseCurrentStory;
    delete sessionStorage.storyIndex;
  }

  clearSessionStoryInfo() {
    delete sessionStorage.pulseCurrentStory;
    delete sessionStorage.storyIndex;
    delete sessionStorage.adhocStoryList;
  }
}
