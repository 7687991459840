<div id="userAdminComp" fxLayout="column" fxFlexFill fxLayoutGap="12px" class="contentPadding">
  <div id="userAdminHeader">
    <h1 class="text-mono">{{ 'UserAdmin.UserAdministration' | translate }}</h1>
    <p>{{ 'UserAdmin.HelpText' | translate }}</p>
  </div>
  <div fxLayout="row" fxLayoutGap="12px">
    <div fxLayout="column" fxFlex="40" fxLayoutGap="12px">
      <mat-expansion-panel id="userAdminExpansion" *ngIf="isShowCMCard" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{ 'UserAdmin.CountryManagers' | translate }}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <app-search-bar id="userAdminSearch" [searchResults]="searchCMUserResults" fxFlex></app-search-bar>
          <button id="userAdminBtnAdd"  mat-button class="mat-accent fixed-height"  style="margin-top: 12px;" (click)="addCM()">
            {{ 'UserAdmin.Add' | translate }}
          </button>
        </div>
        <span *ngFor="let msg of cmMessages" class="error-text">{{msg}}<br></span>
        <div id="userAdminGrid"style="overflow: hidden">
          <agcustom-grid  #cmGrid [dataList]="cmList" [columnDefs]="userColumnDefs" [delete]="true" [getRowClass]="getRowClass"
            (selectionChanged)="onCMSelectionChanged($event)" (onDelete)="onCMDelete($event)" [pagination]="true" [paginationPageSize]="10">
          </agcustom-grid>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel id="userAdminExpansion" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{ 'UserAdmin.Users' | translate }}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
            <app-search-bar id="userAdminSearchNorm" [searchResults]="searchNormalUserResults" fxFlex></app-search-bar>
            <button id="userAdminBtnAddNorm" mat-button class="mat-accent fixed-height"  style="margin-top: 12px;" (click)="addNormalUser()">
              {{ 'UserAdmin.Add' | translate }}
            </button>
          </div>
        <agcustom-grid id="userAdminGridNorm" #userGrid [dataList]="userList" [columnDefs]="userColumnDefs" [delete]="true"
          (selectionChanged)="onUserSelectionChanged($event)" (onDelete)="onNormalUserDelete($event)" [pagination]="true" [paginationPageSize]="10">
        </agcustom-grid>
      </mat-expansion-panel>
    </div>
    <mat-card id="userAdminSelectedCMUser" fxFlex="25" *ngIf="selectedCM || selectedUser">
      <div fxLayout="row">
        <p><strong>{{ 'UserAdmin.Countries' | translate }}: </strong>{{selectedCM ? selectedCM.userName : selectedUser? selectedUser.userName: ''}}</p>
        <span fxFlex></span>
        <mat-spinner *ngIf="isLoadingCountry" class="mat-accent" diameter="30"></mat-spinner>
        <button id="userAdminBtnCMSelectAll" mat-button *ngIf="selectedCM" (disabled)="isLoadingCountry" class="mat-accent fixed-height" (click)="onSelectAllCMCountry()">
            {{ 'UserAdmin.SelectAll' | translate }}
        </button>
        <button id="userAdminBtnCM" mat-button *ngIf="selectedCM" (disabled)="isLoadingCountry" class="mat-accent fixed-height" (click)="saveCMCountry()">
          {{ 'UserAdmin.Save' | translate }}
        </button>
      </div>
      <div id="userAdminGridCountryUser" style="overflow: hidden" *ngIf="selectedUser">
        <agcustom-grid #countryGridNormalUser [dataList]="countryList" [columnDefs]="userCountryColumnDefs" [select]="false"
          (selectionChanged)="onCountrySelectionChanged($event)" [pagination]="true" [paginationPageSize]="12">
        </agcustom-grid>
      </div>
      <div id="userAdminGridCountryCM"style="overflow: hidden" *ngIf="selectedCM">
        <agcustom-grid #countryGridCMUser [dataList]="countryList" [columnDefs]="countryColumnDefs" [select]="true"
          (selectionChanged)="onCountrySelectionChanged($event)" [pagination]="true" [paginationPageSize]="12">
        </agcustom-grid>
      </div>
    </mat-card>


    <mat-card id="userAdminSelectedUserCountry" fxFlex="35" *ngIf="selectedUser && selectedCountry">
      <div fxLayout="row">
        <p><strong>{{ 'UserAdmin.Clients' | translate }}: </strong>{{selectedCountry.name}}</p>
        <span fxFlex></span>
        <mat-spinner *ngIf="isLoadingClient" class="mat-accent" diameter="30"></mat-spinner>
        <button id="userAdminBtnSelectAll" mat-button (disabled)="isLoadingClient" class="mat-accent fixed-height" (click)="onSelectAllClient()">
            {{ 'UserAdmin.SelectAll' | translate }}
          </button>
        <button id="userAdminBtnSave" mat-button (disabled)="isLoadingClient" class="mat-accent fixed-height" (click)="saveUserCountryClient()">
          {{ 'UserAdmin.Save' | translate }}
        </button>
      </div>
      <div id="userAdminGridClient" style="overflow: hidden">
        <agcustom-grid #clientGrid [dataList]="clientList" [columnDefs]="clientColumnDefs" [select]="true" [pagination]="true" [paginationPageSize]="10">
        </agcustom-grid>
      </div>
    </mat-card>

  </div>
</div>