import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FwbExecutiveService {

  constructor(private httpClient: HttpClient) { }

  getFwbScore(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/executive/fwb-score')
  }

  getDemographics(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/executive/demographics?' + params)
  }

  getBenefitScore(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/executive/benefit-score?' + params)
  }

  getPensionScore(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/executive/pension-score?' + params)
  }

  getRetirement(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/executive/retirement-adequacy?' + params)
  }
}
