
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pie-image',
  templateUrl: './pie-image.component.html',
  styleUrls: ['./pie-image.component.css']
})
export class PieImageComponent implements OnInit, OnChanges {

    @Input() title = 'Gender';
    @Input() label1 = 'Male';
    @Input() label2 = 'Female';
    @Input() percentage = 0;
    @Input() percentage2 = 0;
    @Input() imageURL = '';
    @Input() colorPurple = "rgb(108, 43, 135);";
    @Input() colorBlue = "rgb(1, 143, 179)"
    
    constructor() { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        
    }

    getSVGDashArray() {
        return '' + this.percentage + ' ' + (100 - this.percentage)
    }
    getSVGDashArray2() {
        return '' + this.percentage2 + ' ' + (100 - this.percentage2)
    }
    getoffset2(){
        return '' + (100 - this.percentage + 25);
    }
}
