<aon-summary-card [height]="cardheight" [width]="cardwidth">
    <aon-summary-card-header>
        <div style="display: flex;flex-direction:column;justify-content: flex-start;">
            <div style="display: flex;flex-direction:row;justify-content: flex-start;">
                <aon-h3 style="color: #001b5a;">
                    {{ charttitle }}
                </aon-h3>
            </div>
            <div style="display: flex;flex-direction:row;justify-content: flex-start;">
                <div style="text-align: left;color: #001b5a;">
                    <aon-p-elg>
                        {{ chartdescription }}
                    </aon-p-elg>
                </div>
            </div>
        </div>
    </aon-summary-card-header>
    <aon-summary-card-content top="60px" [contentwidth]="contentwidth">
        <fwb-error-message [isError]="isError" [message]="errorMsg"></fwb-error-message>
        <div *ngIf="!isError && loaded">
            <div class="columnDiv">
                <div style="display:flex; flex-direction: row; justify-content: flex-end;">
                    <div style="width: 350px;margin-right: 20px;">
                        <aon-select style="width: 300px !important;  margin-top: 10px;" scrollable="true"
                            [selectedvalue]="currentScenarioNumber" (onselectionchange)="onOptionChange($event)">
                            <aon-select-option *ngFor="let option of options" [value]="option.key">{{ option.name }}
                            </aon-select-option>
                        </aon-select>
                    </div>
                </div>
                <div style="   display: flex;
      flex-wrap: wrap; flex-direction: row wrap; margin-top: -20px;
      justify-content: space-around;
      align-content: flex-start;">
                    <aon-stacked-horizontal-bar-chart [chartid]="title" [width]="width" [height]="height"
                        [charttitle]="emptyChartTitle" [yaxistitle]="yaxistitle" [tooltip]="tooltip"
                        [tickformat]="tickformat" [xaxistitle]="yaxistitle" [maxyaxisnumber]="maxyaxisnumber"
                        [legendposition]="legendposition" [increaseYaxisLableLength]="'60'"
                        [data]="retirementbycategories" [keys]="keysNames">
                    </aon-stacked-horizontal-bar-chart>
                    <!-- <aon-stacked-vertical-bar-chart 
                    [chartid]="title" [width]="width" [height]="height" [charttitle]="emptyChartTitle"
                    [yaxistitle]="yaxistitle" [tooltip]="tooltip" [tickformat]="tickformat" [xaxistitle]="yaxistitle"
                    [maxyaxisnumber]="maxyaxisnumber"  [legendposition]="legendposition"
                    [data]="retirementbycategories" [keys]="keys"  >
                </aon-stacked-vertical-bar-chart>  -->
                </div>
                <div style="flex: 1; display: flex;  flex-direction: row;justify-content:flex-start; ">
                    <div style=" margin-left:100px; margin-top: 10px;display: flex; justify-content: center; flex:3;">
                        <aon-legend [data]="keys" [legendposition]="'horizontal'"></aon-legend>
                    </div>
                    <div style=" margin-top: 10px;display: flex; justify-content: center;flex:3;">
                        <aon-legend [data]="targetLegends" [legendposition]="'horizontal'"></aon-legend>
                    </div>
                </div>
            </div>
            <div style="flex: 1; display: flex;  flex-direction: row;justify-content:space-around;margin-top: -10px; ">
              <div class="legendColumn marginRight5">
                <div class="padingLeft2 marginRight5">
                  <aon-p-sm class="paddingBottom0 hydrated"><svg width="12" height="12">
                      <circle r=".35rem" cx="6" cy="6" width="12" height="12" stroke="#00A3C7" stroke-width="5px"
                        fill="#00A3C7"></circle>
                    </svg>&nbsp;&nbsp;</aon-p-sm>
                </div>
                <div class="marginTop2">
                  <aon-p-sm>
                    Minimum - Covers all needs with some left for fun ($ 10,000 single)
                  </aon-p-sm>
                </div>
              </div>
              <div class="legendColumn marginRight5">
                <div class="padingLeft2 marginRight5">
                  <aon-p-sm class="paddingBottom0 hydrated"><svg width="13" height="13">
                      <circle r=".35rem" cx="6" cy="6" width="13" height="13" stroke="#00519B" stroke-width="5px"
                        fill="#00519B">
                      </circle>
                    </svg>&nbsp;&nbsp;</aon-p-sm>
                </div>
                <div class="marginTop2">
                  <aon-p-sm>
                    Moderate - More finanial security and flexibility ($ 20,000 single)
                  </aon-p-sm>
                </div>
              </div>
              <div class="legendColumn marginRight5">
                <div class="padingLeft2 marginRight5">
                  <aon-p-sm class="paddingBottom0 hydrated"><svg width="14" height="14">
                      <circle r=".35rem" cx="6" cy="6" width="14" height="14" stroke="#A21F79" stroke-width="5px"
                        fill="#A21F79"></circle>
                    </svg>&nbsp;&nbsp;</aon-p-sm>
                </div>
                <div class="marginTop2">
                  <aon-p-sm>
                    Comfortable - More financial freedom and some luxuries ($ 30,000 single)
                  </aon-p-sm>
                </div>
              </div>
            
            </div>
        </div>
    </aon-summary-card-content>
</aon-summary-card>