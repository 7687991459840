import {
  Component, OnInit, ViewEncapsulation, Input, OnDestroy, OnChanges, SimpleChanges,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { User } from "app/models/user.model";
import { Router } from "@angular/router";
import { FuseConfigService } from "@fuse/services/config.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { navigation } from "app/navigation/navigation";

@Component({
  selector: 'retirement',
  templateUrl: './retirement.component.html',
  styleUrls: ['./retirement.component.css'],
})
export class RetirementComponent
  implements OnInit, OnChanges {
  
  
    height: any = "500px";
    width: any = "1450px";
    cardheight: any = "600px";
    cardwidth: any = "98%";
    tooltip: boolean = true;
  isChartVisible: boolean = true;
  
  toggleTarget: boolean = true;

  public user: User; 
  fuseConfig: any;
  navigation: any;
 
    
  // Private
  private _unsubscribeAll: Subject<any>; 
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _router: Router
  ) {
    // Set the defaults
    this.navigation = navigation;
  }
  ngOnChanges(changes: SimpleChanges): void {
     
  }
  ngOnInit() {
    this.getUser();
  }
  togglebyTarget() {
    this.toggleTarget = !this.toggleTarget;

  }  
  
  getUser() {
     
  }
 
   
}
