import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { FuseConfigService } from "@fuse/services/config.service";
import { Store, select } from "@ngrx/store";
// import { DashboardState } from "app/maindashboards/reducers";
// import { DashboardHttpService } from "app/maindashboards/maindashboards.service";
// import { UploadFileService } from "app/services/upload-file.service";
import { navigation } from "app/navigation/navigation";
// import { getUser } from "app/main/auth/auth.selectors";
import { User } from "app/models/user.model";
import { takeUntil } from "rxjs/operators";
import { BenefitScore_Overtime, BenefitScore_Overtime_Location, BenefitScore_Overtime_SalaryBand } from 'app/fwb/constants/fwb-charts-data';

@Component({
    selector: "demographic-breakdown",
    templateUrl: "./demographicBreakdown.component.html",
    styleUrls: ["./demographicBreakdown.component.css"],
    encapsulation: ViewEncapsulation.None,
})
export class DemographicBreakdownComponent implements OnInit, OnDestroy {
    chartId = "FinancialWellbeing";
    height: any = "470px";
    //width: any = "95%";
    width: any = "830px";
    cardheight: any = "490px";
    cardwidth: any = "97%";
    tooltip: boolean = true;
    resize: boolean = true;
    titleposition = "left";
    charttitle = "Demographic Breakdown";
    yaxistitle = "";
    color = "white";
    maxyaxisnumber: number = 1000;
    data: any;
    //fuseConfig: any;
    navigation: any;
    emptyChartTitle = " ";
    categorycolors: any[];
    options = [
        { key: "AgeBand", name: "Age Band" },
        { key: "Gender", name: "Gender" },
    ];
    selectedOption = true;
    public user: User;
    locationDataBarChartData: { icon: string; name: string; value: number; colorindex: number; }[];
    selectedOptionName: string = "Age Band";
    constructor() { }
    // Private
    private _unsubscribeAll: Subject<any>;
    ngOnInit() {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // Subscribe to config changes

        this.categorycolors = [];
        this.categorycolors.push({ name: "Financial Score", colorindex: 1 });

        this.setData();
    }

    
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this._unsubscribeAll.unsubscribe();
    }
    
    onOptionChange($event) {
        this.selectedOptionName = $event.detail;
        this.setData();
    }

    setData() {
        this.selectedOption = true;
        if (this.selectedOptionName == "Gender") {
            this.getDataByGender();
        }  else {
            this.getDataByAgeBand();
        }
    }

    getDataByGender() {
        this.data = [{
            category: "Male",
            values: [ { name: "Score", value: 940 } ],
        },
        {
            category: "Female",
            values: [ { name: "Score", value: 878 } ],
        }];
    }

    getDataByAgeBand() {
        this.data = [
            {
                category: "20-29",
                values: [ { name: "Financial Score", value: 240 } ],
            },
            {
                category: "30-39",
                values: [ { name: "Financial Score", value: 578 } ],
            },
            {
                category: "40-49",
                values: [ { name: "Financial Score", value: 654 } ],
            },
            {
                category: "50-59",
                values: [ { name: "Financial Score", value: 890 } ],
            },
            {
                category: "60-69",
                values: [ { name: "Financial Score", value: 720 } ],
            },
        ];
    }
}
