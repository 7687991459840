export const locale = {
    lang: 'pt-br',
    data: {
        'HealthDiseaseProfile': {            
		'HealthProfile': 'Perfil de Saúde', 
		'Top Diseases': 'Maiores Doenças',     
		'TopChronicDiseases': 'Maiores Doenças Crônicas', 
		'TopInfectiousDiseases': 'Maiores Doenças Infecciosas', 
		'TopOtherDiseases': 'Maiores Doenças (Outras)', 
		'PaidClaim': 'Sinistro Pago', 
		'AverageCost': 'Custo Médio', 
		'ClaimantCount': 'Contagem de Usuário', 
		'Utilization': 'Frequência', 
		'Chronic': 'Crônica', 
		'Infectious': 'Infecciosa', 
		'Other': 'Outra', 
		'Current': 'Atual', 
		'Prior': 'Anterior', 
		'ClaimsReportedByPaidDate': 'Sinistro Reportado por Data de Pagamento', 
		'ClaimsReportedByIncurredDate': 'Sinistro Reportado por Data de Atendimento',
		'DateRange': 'to',
		'DiagnosisGroup': 'Diagnosis Group',
		'Actionable': 'Actionables',
		'Cancer': 'Cancer',
		'Mental health': 'Mental Health',
		'Trauma/accidents': 'Trauma/Accidents',
		'Obstetrics': 'Obstetrics',
		'Others': 'Others',
		'Preventable': 'Preventable',
		'Intervenable': 'Intervenable',
		'CategoryOption': 'Category',
        'Category': 'Category',
        'Chapter': 'Chapter',
		'Sort': 'Ordenar'
        }
    }
};

