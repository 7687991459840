<div fxLayout="column" *ngIf="isLoading" style="overflow: hidden;">
    <div fxLayout="row" class="fwbloadingOverlay">
        <div class="fwbloadingContent">
            Loading...
        </div>
    </div>
</div>
<aon-summary-card [height]="cardheight" [width]="cardwidth" *ngIf="!isLoading">
    <aon-summary-card-header>
        <aon-h3 style="color: #001b5a;">
            <fa-icon style="padding-right: 5px;" [icon]="['fal', 'tachometer']"></fa-icon>
            {{ charttitle }}
        </aon-h3>
    </aon-summary-card-header>
    <aon-summary-card-content top="60px">
        <fwb-error-message [isError]="isError" [message]="errorMsg"></fwb-error-message>
        <div *ngIf="!isError">
            <div style="display: flex; flex-direction: row;">
                <div>
                    <aon-h1 style="color: #001b5a;">705 </aon-h1>
                </div>

                <div style="margin-top: 20px; margin-left: 5px;">
                    <aon-p-elg>
                        690
                    </aon-p-elg>
                </div>
            </div>
            <div style="text-align: left;">
                <aon-p-lg>
                    See how your benefits offered compares to the average by
                    category.
                </aon-p-lg>
            </div>

            <div *ngIf="isTableData">
                <div class="marginTop10">
                    <ag-grid-angular style="width: 95%; height: 70%; overflow-x: hidden;" class="ag-theme-material grid"
                        [rowData]="tableData" [showHeader]="isHeaderVisible" [columnDefs]="columnDefs"
                        [gridOptions]="gridOptions">
                    </ag-grid-angular>
                </div>

            </div>
        </div>
    </aon-summary-card-content>
    <aon-summary-card-footer>
        <aon-button-default (onclick)="goToLearnMore()" id="btn-prominent1">Learn More</aon-button-default>
    </aon-summary-card-footer>
</aon-summary-card>