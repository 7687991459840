<div
    id="userOptionComp"
    [class.user-option-comp--dialog]="isDialog"
    [hidden]="isOfflineMode"
>
    <div
        class="user-options-overlay-dialog"
        *ngIf="isDialog && isUserOptionsLoading && this.selectedSite.value === 'pulse'"
    >
        <aon-loading-spinner message='Refreshing User Options...'></aon-loading-spinner>
    </div>
    <div fxLayout="row">
        <aon-h4 style="padding-top:5px;">Select Options</aon-h4>
        <span fxFlex></span>
        <mat-spinner
            *ngIf="isLoadingCountry"
            class="mat-accent"
            style="margin: 0 auto"
            diameter="30"
        ></mat-spinner>
    </div>
    <br>
    <div style="padding: 0px 30px">
        <div
            class="user-options-overlay"
            *ngIf="!isDialog && isUserOptionsLoading"
        >
            <aon-loading-spinner message='Refreshing User Options...'></aon-loading-spinner>
        </div>
        <div
            class="selectBottom"
            *ngIf="showSites && this.siteList.length > 1"
        >
            <aon-input-title>Site *</aon-input-title>
            <aon-select
                returntype='object'
                id="userOptionSite"
                scrollable=true
                [selectedvalue]='selectedSite'
                search=true
                returntype='object'
                (onselectionchange)='onSiteChange($event)'
            >
                <aon-select-option
                    *ngFor="let site of siteList"
                    [value]='site'
                >
                    {{site.name}}<span *ngIf="!site.isAvailable"> (Data Refresh In Progress)</span>
                </aon-select-option>
            </aon-select>
        </div>
        <div
            class="selectBottom"
            *ngIf="clientList !== null"
        >
            <aon-input-title>Client *</aon-input-title>
            <aon-select
                #clientSelect
                returntype='object'
                id="userOptionClient"
                scrollable=true
                [selectedvalue]='selectedvalueClient'
                search=true
                (onselectionchange)='onClientChange($event)'
            >
                <cdk-virtual-scroll-viewport
                    [itemSize]="20"
                    class="select-option-viewport"
                >
                    <aon-select-option
                        *cdkVirtualFor="let clientObj of clientList"
                        [value]='clientObj'
                    >
                        <span *ngIf="clientObj.isGroup"><strong>{{clientObj.name}}</strong></span>
                        <span
                            *ngIf="clientObj.isGroupMember"
                            style="font-style: italic"
                        >-- {{clientObj.name}}</span>
                        <span *ngIf="!clientObj.isGroup && !clientObj.isGroupMember">{{clientObj.name}}</span>
                    </aon-select-option>
                </cdk-virtual-scroll-viewport>
            </aon-select>
        </div>
        <div
            class="selectBottom"
            *ngIf="clientList !== null"
        >
            <aon-input-title>Country *</aon-input-title>
            <aon-select
                returntype='object'
                id="userOptionCountry"
                scrollable=true
                [selectedvalue]='selectedvalueCountry'
                search=true
                (onselectionchange)='onCountryChange($event)'
            >
                <aon-select-option
                    *ngFor="let countryObj of countryList"
                    [value]='countryObj'
                >{{countryObj.name}}
                </aon-select-option>
            </aon-select>
        </div>
        <ng-container
            id="userOptionPeriodSelection"
            *ngIf="showPeriodSelection && clientList !== null"
        >
            <div
                fxLayout="column"
                fxLayoutGap="10px"
            >
                <div fxLayout="column">
                    <div
                        fxLayout="row"
                        fxLayoutGap="10px"
                        fxflex="100"
                    >
                        <div
                            fxLayout="column"
                            fxflex="50"
                            class="selectBottom"
                        >
                            <aon-input-title>{{ 'UserOptions.Basis' | translate }}</aon-input-title>
                            <aon-select
                                *ngIf="showClaimPaid"
                                scrollable=true
                                [selectedvalue]='claimsPaidSelection'
                                (onselectionchange)='onClaimsPaidChange($event)'
                            >
                                <aon-select-option
                                    *ngFor="let claimPaid of claimPaidList"
                                    [value]='claimPaid.value'
                                >
                                    {{ 'UserOptions.' + claimPaid.text | translate }}
                                </aon-select-option>
                            </aon-select>
                        </div>
                        <div
                            fxLayout="column"
                            fxflex="50"
                            class="selectBottom"
                        >
                            <aon-input-title>{{ 'UserOptions.TimePeriod' | translate }}</aon-input-title>
                            <aon-select
                                *ngIf="showRollingPeriod"
                                scrollable=true
                                [selectedvalue]='rollingPeriodSelection'
                                (onselectionchange)='onRollingPeriodChange($event)'
                            >
                                <aon-select-option
                                    *ngFor="let period of rollingPeriodList"
                                    [value]='period.value'
                                >
                                    {{ 'UserOptions.' + period.text | translate }}</aon-select-option>
                            </aon-select>
                        </div>
                    </div>

                </div>
                <div fxLayout="row">
                    <aon-input-error [show]='showPeriodError'>{{periodErrorMsg}}</aon-input-error>
                </div>
                <div
                    fxLayout="row"
                    fxLayoutGap="10px"
                >
                    <div
                        fxLayout="column"
                        fxflex="50"
                    >
                        <aon-input-title>{{ 'UserOptions.MaxDate' | translate }}</aon-input-title>
                        <app-monthyearpicker
                            id='mypMaxDate'
                            title="Max Date"
                            [minDt]="minMaxDate"
                            [maxDt]="maxMaxDate"
                            [dateValue]="maxDate"
                            (dateValueChange)="onMaxDateChanged($event)"
                        >Loading</app-monthyearpicker>
                    </div>
                    <div
                        fxLayout="column"
                        fxflex="50"
                    >
                        <div style="padding-top:50px">
                            <aon-checkbox
                                id='cbUsePriorPeriodCb'
                                [checkboxId]='usePriorPeriodCb'
                                [selected]='usePriorPeriod'
                                (ontoggle)='onToggleUsePriorPeriodSelected()'
                            >
                                {{ 'UserOptions.UsePriorYear' | translate}}
                            </aon-checkbox>
                        </div>
                    </div>
                </div>

            </div>

            <div fxLayout="column">
                <aon-input-title>{{ 'UserOptions.PlanYear' | translate }}</aon-input-title>

            </div>
            <div fxLayout="row">
                <div
                    fxLayout="row"
                    fxLayoutGap="10px"
                >
                    <div fxLayout="column">
                        <app-monthyearpicker
                            id='mypPeriod1Date'
                            title="Period 1"
                            [minDt]="minMaxDate"
                            [maxDt]="maxMaxDate"
                            [disableDatePicker]="usePriorPeriod"
                            [dateValue]="period1StartDate"
                            (dateValueChange)="onPeriod1DateChanged($event)"
                        >Loading</app-monthyearpicker>
                    </div>
                    <div fxLayout="column">
                        <app-monthyearpicker
                            id='mypPeriod2Date'
                            title="Period 2"
                            [minDt]="minMaxDate"
                            [maxDt]="maxMaxDate"
                            [dateValue]="period2StartDate"
                            (dateValueChange)="onPeriod2DateChanged($event)"
                        >Loading</app-monthyearpicker>
                    </div>
                    <div fxLayout="column">
                        <app-monthyearpicker
                            id='mypPeriod3Date'
                            title="Period 3"
                            [minDt]="minMaxDate"
                            [maxDt]="maxMaxDate"
                            [dateValue]="period3StartDate"
                            (dateValueChange)="onPeriod3DateChanged($event)"
                        >Loading</app-monthyearpicker>
                    </div>
                    <div fxLayout="column">
                        <app-monthyearpicker
                            id='mypPeriod4Date'
                            title="Period 4"
                            [minDt]="minMaxDate"
                            [maxDt]="maxMaxDate"
                            [disableDatePicker]="!usePriorPeriod"
                            [dateValue]="period4StartDate"
                            (dateValueChange)="onPeriod4DateChanged($event)"
                        >Loading</app-monthyearpicker>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container
            id="userOptionPlanYear"
            *ngIf="showPlanYear && clientList !== null"
        >
            <aon-input-title>{{ 'UserOptions.PlanYear' | translate }}</aon-input-title>
            <div class="selectBottom">
                <aon-select
                    *ngIf="showPlanYear"
                    scrollable=true
                    [selectedvalue]='selectedPlanYear'
                    (onselectionchange)='onPlanYearChange($event)'
                >
                    <aon-select-option
                        *ngFor="let planYear of planYearList"
                        [value]='planYear.value'
                    >
                        {{ planYear.text }}</aon-select-option>
                </aon-select>
            </div>
        </ng-container>
        <ng-container
            id="userOptionLanguage"
            *ngIf="showLanguage"
        >
            <aon-input-title>Language</aon-input-title>
            <div class="selectBottom">
                <aon-select
                    scrollable=true
                    [selectedvalue]='language'
                    (onselectionchange)='onLanguageChange($event)'
                >
                    <aon-select-option
                        *ngFor="let lang of languageList"
                        [value]='lang.id'
                    >
                        {{lang.title}}</aon-select-option>
                </aon-select>
            </div>
        </ng-container>
        <ng-container
            id="userOptionStory"
            *ngIf="showStories"
        >
            <div class="selectBottom">
                <aon-input-title>Story</aon-input-title>
                <aon-select
                    returntype='object'
                    scrollable=true
                    [selectedvalue]='defaultStory'
                    (onselectionchange)='onStoryChange($event)'
                >
                    <aon-select-option
                        *ngFor="let story of defaultStoryList"
                        [value]='story'
                    >
                        {{story.name}}</aon-select-option>
                </aon-select>
            </div>
        </ng-container>
    </div>
    <br><br><br>
    <div fxLayout="row">
        <div *ngIf="showAdminLinks && isAdmin">
            <aon-button-default
                id="userOptionBtnAdmin"
                (click)="admin()"
            >
                Admin
            </aon-button-default>
            &nbsp;&nbsp;
        </div>
        <aon-button-default
            id="userOptionReset"
            *ngIf="showReset"
            (click)="getClientTimePeriod()"
        >
            Reset
        </aon-button-default>
        <aon-button-default
            id="userOptionBtnOff"
            *ngIf="isOfflineWb()"
            (click)="isOfflineMode = true"
        >
            Offline
        </aon-button-default>
        <span fxFlex></span>
        <aon-button-subtle
            id="userOptionLogout"
            *ngIf="showLogout"
            (click)="logout()"
        >
            Logout
        </aon-button-subtle>
        <aon-button-default
            id="userOptionCancel"
            *ngIf="showCancel"
            (click)="cancel()"
        >
            Cancel
        </aon-button-default>
        &nbsp;&nbsp;
        <aon-button-default
            id="userOptionContinue"
            (click)="continue()"
            [disabled]='!client || !country || showPeriodError || (showStories && defaultStory === null) || isLoadingCountry'
        >
            Continue
        </aon-button-default>
    </div>
</div>
<div
    *ngIf="isOfflineMode"
    style="position: relative;"
>
    <button
        id="userOptionBtnBack"
        mat-icon-button
        class="mat-accent"
        style="position: absolute; top: 8px; right: 20px;"
        (click)="isOfflineMode = false"
    >
        <mat-icon aria-label="Back">arrow_left</mat-icon> Back
    </button>
    <div id="userOptionAppOffline">
        <app-offline
            [client]="client"
            [country]="country"
        ></app-offline>
    </div>
</div>