export const locale = {
    lang: 'es-co',
    data: {
        'DistributionByClaimType': {
             'TotalPaidClaim' : 'Costo Total Pagado',
             'ClaimCount' : 'Número de Reclamos',
             'AverageCost' : 'Costo Promedio',
             'Dimensions' : 'Dimensión',
             'AgeBand' : 'Rango Etario',
             'Gender' : 'Género', 
             'Relationship': 'Parentesco', 
             'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'ClaimsReportedByPaidDate' :  'Reclamaciones reportadas por fecha de pago',
            'ClaimsReportedByIncurredDate' :  'Reclamaciones informadas por fecha incurrida',
            'ClaimTypeBy': 'Tipo de Servicio por',
            'DateRange': 'to'
          }
    }
};
