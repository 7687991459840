import { Component, OnInit } from '@angular/core';
import { BenefitScoreChartCategories } from 'app/fwb/constants/fwb-charts-data';
import { BenefitService } from 'app/fwb/services/benefit.service';

@Component({
  selector: 'benefit-top-summary',
  templateUrl: './benefit-top-summary.component.html',
  styleUrls: ['./benefit-top-summary.component.scss']
})
export class BenefitTopSummaryComponent implements OnInit {

  height: any = "380px";
  width: any = "98%";
  scoreCardheight: any = "540px";
  scoreCardwidth: any = "95%";
  cardheight: any = "490px";
  cardwidth: any = "98%";
  tickSize: number = 6;
  xaxistitle = "";
  charttitle = "";

  fuseConfig: any;
  navigation: any;
  //Local state 
  questionsData: any[];
  questionsDetails: any[];
  isChartVisible: boolean = true;
  contenttop: string = "70px";
  categories: any[]
  serviceData: any;
  isLoading = true;
  isError = false;
  errorMsg = "No Data Found.";
  constructor(
    private _BenefitService: BenefitService,

  ) { }

  ngOnInit(): void {
    // this.categories = BenefitScoreChartCategories;
    // this.categories = this._BenefitService.getScoreCategories("");
    this.getData();
  }


  getData() {
    this.serviceData = null;
    this._BenefitService.getScoreCategories("").toPromise().then((resp) => {
      this.serviceData = resp;
      console.log(this.serviceData);
      this.setData();
      this.errorMsg = "";
      this.isError = false;
    }).catch(error => {
      this.isError = true;
      this.errorMsg = error.message;
    }).finally(() => {
      this.isLoading = false;
    })
  }

  setData() {
    this.categories = this.serviceData.resultScore;
  }

}
