import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService, ToasterServiceModes } from './toaster.service';


@Directive()
@Injectable({
  providedIn: 'root'
})
export class HttpstatusService {

    @Output() httpStatusEmitter: EventEmitter<HttpErrorResponse>;

    constructor(private _toasterService: ToasterService) {
        this.httpStatusEmitter = new EventEmitter<HttpErrorResponse>();

        // this.httpStatusEmitter.subscribe((httpError: HttpErrorResponse) => {
        //     this._toasterService.showMessage(httpError.status + ' - ' + httpError.message, ToasterServiceModes.NOK);
        // });
    }

    notifyHttpErrorResponse(httpError: HttpErrorResponse): void {
        this.httpStatusEmitter.emit(httpError);
    }

    getHttpErrorResponseEmitter(): EventEmitter<HttpErrorResponse> {
        return this.httpStatusEmitter;
    }
}
