import { Component, Input, OnChanges, SimpleChanges, OnInit, } from "@angular/core";
import { Retirement_TargetByCategories } from 'app/fwb/constants/retirement-chart-data';

import { FuseConfigService } from '@fuse/services/config.service';
import { Store } from '@ngrx/store';
import { State } from 'app/_reducers';
import { Router } from '@angular/router';
import { FWBService } from 'app/fwb/services/fwb.service';
import { RetirementService } from 'app/fwb/services/retirement.service';
@Component({
  selector: 'retirement-summary',
  templateUrl: './retirement-summary.component.html',
  styleUrls: ['./retirement-summary.component.css']
})
export class RetirementSummaryComponent implements OnInit, OnChanges {
  retirementbycategories: any;
  view: any[] = [1300, 350];
  title: string = "";

  keys: any[] = [];
  ratiokeys: any[] = [];
  legendtitle: [];
  contentwidth: any = "97%";
  cardheight: any = "575px";
  height: any = "475px";
  width: any = "98%";
  legendposition = "none";
  cardwidth: any = "97%";
  tooltip: boolean = true;
  resize: boolean = true;
  titleposition = "left";
  charttitle = "Target Lifestyle";
  yaxistitle = "";
  color = "white";
  maxyaxisnumber: number = 0.5;
  data: any;
  fuseConfig: any;
  navigation: any;
  emptyChartTitle = " ";
  public multi: any[] = [];
  public categorycolors: any[] = [0, 2, 4];
  currentScenarioNumber: number = 5;
  public fwb: any = {
    question: 5,
    toggleQuestionSelection: false,
    lifestylescenario: 5,
    replacementscenario: 5
  };
  loaded = false;
  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = "";
  showYAxisLabel: boolean = true;
  barPadding = 10;
  yAxisLabel: string = "";
  tickformat: string = ".0%";
  scaleMax = 0.1;
  yScaleMax = 1;
  showDataLabel = true;
  colorScheme = {
    domain: [
      "#086972",
      "#01a9b4",
      "#87dfd6",
      "#184d47",
      "#96bb7c",
      "#eebb4d",
      "#ffa600",
    ],
  };
  keysNames: any[] = [];
  targetLegends = [
    { name: 'Target Met', value: 20.6, colorindex: 3, striped: false, shape: "square" },
    { name: 'Target Un Met', value: 20.6, colorindex: 3, striped: true, shape: "square" },
  ];
  serviceData: any =null;
  isLoading = true;
  isError = false;
  errorMsg = "No Data Found.";
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fwbService: FWBService,
    private _retirementService: RetirementService,
    private store: Store<State>,
    private _router: Router) {

    this.store.select('fwb').forEach(res => {
      if (res == undefined) {
        console.log("this.fwb is undefined");
      } else {
        this.currentScenarioNumber = isNaN(res.lifestylescenario) ? 5 : (res.lifestylescenario);
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.setData();
    }
  }

  ngOnInit() {
    // if (this.serviceData === null) {
      this.getData();
    // }
  }

  getData() {
    this.serviceData = null;
    this._retirementService.getSummary("").toPromise().then((resp) => {
      this.serviceData = resp;
      console.log(this.serviceData);
      this.setData();
      this.errorMsg = "";
      this.isError = false;
      this.loaded = true;
  }).catch(error => {
      this.isError = true;
      this.errorMsg = error.message;
  }).finally(() => {
      this.isLoading = false;
    })
  }

  setData() {
    this.retirementbycategories = this.serviceData.result;
    this.keys = [];
    this.retirementbycategories[0].values.forEach(a => {
      this.keysNames.push(a.name);
      if (!a.striped) { this.keys.push(a); }
    });
  }
  updateScenario() {
    this._fwbService.updateLifestyleScenario(this.currentScenarioNumber);
  }



}
