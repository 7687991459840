export const locale = {
    lang: 'en',
    data: {
        'LossRatio': {
            'LossRatioHeading' : 'Loss Ratio',
            'AllAmountsDisplayedInLocalCurrency' : 'All amounts displayed in local currency',
            'Loss' : 'LOSS RATIO',
            'LossRatioType' : 'Loss Ratio Type',
            'CombinedLR' : 'Combined LR',
            'ClaimsTotalPMPM' : 'Claims Total/PMPM',
            'ClaimsPMPM' : 'Claims PMPM',
            'ClaimsTotal' : 'Claims Total',
            'TargetLossRatio' : 'Target Loss Ratio',
            'ClaimsReportedByPaidDate' :  'Claims reported by paid date',
            'ClaimsReportedByIncurredDate' :  'Claims reported by incurred date',
             'Monthly' : 'Monthly',
            'YearToDateBasis' : 'Year-To-Date Basis',
            'YearToDateBasisC' : 'Year-To-Date Basis (C)',
            'Rolling3Basis' : 'Rolling 3 Basis',
            'Rolling6Basis' : 'Rolling 6 Basis',
            'Rolling9Basis' : 'Rolling 9 Basis',
            'Rolling12Basis' : 'Rolling 12 Basis',
            'Show' : 'Show',
            'Hide' : 'Hide',
            'ShowHide' : 'Show/Hide',            
            'Claims' : 'Claims',
            'Premium' : 'Premium',
            'Income':'Income',
            'Expense':'Expenses',
            'ClaimLabel': 'Claims + Expenses',
            'PremimumLabel':'Premium + Income',
            'MonthlyLossRatio':'Monthly Loss Ratio',
            'CumulativeLossRatio':'Cumulative Loss Ratio',
            'DateRange': 'to'

        }
    }
};
