<mat-toolbar id="toolbarComp" class="p-0 mat-elevation-z1" style="height: 38px;">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div id="toolbar10" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button id="toolbarBtnMenu" mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div id="toolbarSeprator" class="toolbar-separator" style="height: 38px;" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img id="toolbarImgLogo" class="logo-icon" src="assets/images/logos/fuse.svg">
                </div>
            </div>
            <span id="toolbarClientName" style="margin-left: 12px; color:#1E2129; font-weight: 400; font-size: 15px">{{clientName}}</span>
            <!-- <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div> -->

        </div>

        <div id="toolbar01" class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <button id="toolbarBtnCart" mat-button fxHide fxShow.gt-xs
                    class="language-button" style="height: 38px;"
                    [matMenuTriggerFor]="cartMenu">
                <mat-icon class="s-18" [matBadge]="cartItems.length" matBadgePosition="above after" 
                    matBadgeColor="warn" [matBadgeHidden]="cartItems.length === 0">
                    add_shopping_cart
                </mat-icon>
            </button>

            <mat-menu id="toolbarCartMenu" #cartMenu="matMenu" [overlapTrigger]="false">
                <div style="max-height: 350px; overflow-y: auto">
                    <div id="toolbarCartItems" *ngFor="let cart of cartItems" style="font-size: 12px; padding: 8px 14px; border-bottom: 1px solid #EEE">
                        {{cart.dashboardName}} {{ cart.filterName ? ' - ' + cart.filterName :''}}
                    </div>
                </div>
                <button id="toolbarBtnShopCart" mat-menu-item class="mat-accent" (click)="viewCart()">
                    <mat-icon class="text-accent" class="s-18">add_shopping_cart</mat-icon>
                    <span class="text-accent; font-weight: bold">{{ 'Toolbar.Cart' | translate }}</span>
                </button>
            </mat-menu>

            <div class="toolbar-separator" style="height: 38px;"></div>

            <button id="toolbarBtnSettings" mat-button class="user-button" (click)="viewOptions()" style="height: 38px;"> 
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon class="s-18">settings</mat-icon>
                </div>
            </button>

            <!-- <div class="toolbar-separator"></div> -->

            <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

            <div class="toolbar-separator" style="height: 38px;"></div>

            <button *ngIf="profile.user.toolbar.isTranslationShown" id="toolbarBtnSelectLang" mat-button fxHide fxShow.gt-xs
                    class="language-button" style="height: 38px;"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img *ngIf="false" class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

            <mat-menu id="toolbarMenuLang" #languageMenu="matMenu" [overlapTrigger]="false" style="height: 38px;">

                <button id="toolbarBtnLangTitle" mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" style="width: 40px" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs style="height: 38px;"></div>

            <button id="toolbarBtnUser" mat-button [matMenuTriggerFor]="userMenu" style="height: 38px;"
                    class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{user?.displayName}}</span>
                    <mat-icon id="toolbarBtnUserIcon" class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu id="toolbarMenuExit" #userMenu="matMenu" [overlapTrigger]="false">

                <!-- <button mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>{{ 'Toolbar.MyProfile' | translate }}</span>
                </button> -->

                <button *ngIf='isExternalUser' mat-menu-item class="" (click)='viewChangePassword()'>
                    <mat-icon>person</mat-icon>
                    <span>Change Password</span>
                </button>

                <button id="toolbarBtnLogOut" mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>{{ 'Toolbar.Logout' | translate }}</span>
                </button>

            </mat-menu>

        </div>

    </div>

</mat-toolbar>
