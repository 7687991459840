export const locale = {
    lang: 'it',
    data: {
      'Nav': {
        'Stories': 'STORIE',
        'Export': {
          'Main': 'Export',
          'Cart': 'Cart',
          'Queue': 'Queue'
        },
        'Admin': {
          'Main': 'ADMIN',
          'Users': 'Users',
          'Stories': 'Stories',
          'Settings': 'Settings',
          'StoryBoard': 'Story Board',
          'Library': 'Library',
          'ClientGroup': 'Client Group'
        },
      }
    }
};