export const locale = {
    lang: 'es-co',
    data: {
      'ClaimCostDiag': {
        'ClaimDiagnosis':'Número de Reclamos v Diagnóstico',
        'SelectTop':'Seleccionar Top',
        'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
        'ClaimType':'Tipo de Servicio',              
        'TotalPaidClaim' : 'Costo Total Pagado', 
        'ClaimantCount':'Número de Reclamantes',
        'ClaimCount':'Recuento de reclamos',            
        'ClaimsPerClaimant' : 'Reclamos Por Reclamante', 
        'CostPerClaim': 'Costo Por Reclamo',
        'CostPerClaimant': 'Costo Por Reclamante', 
        'ChangeInCost':'% Variación del Costo',
        'Claim' : 'Reclamo', 
        'Claimant' : 'Reclamante', 
        'ClaimsTotal':'Total Reclamos',
        'Count':'Contar',
        'ClaimsReportedByPaidDate': 'Reclamaciones reportadas por fecha de pago',
        'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
        'CountBasis':'Contar Base',
        'Sort':'Ordenar',
        'Show':'Mostrar',
        'Hide':'Ocultar',
        'ShowHide':'Mostrar/Ocultar',
        'DiagnosisLevel':'Nivel de Diagnóstico',
        'Category':'Categoría',
        'Chapter':'Capítulo',
        'ShownAsPer':'Se muestra según',
        'TopDiagnoses' : 'Top Diagnósticos',                
        'AverageCost': 'Costo Promedio',
        'FilterNA': 'Excluir NA',
        'DateRange': 'to'         
    }
}
};
