import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { userActionLog, localStory } from '../models/log.model';
import { User } from 'app/models/user.model'; 
 
@Injectable({
    providedIn: "root"
})
export class LoggerService {
    user : User ;
    story : localStory ;
        constructor(
        private httpClient: HttpClient) {
        
        let u = localStorage.getItem('user');
        if (u) {
            this.user = JSON.parse(u);
        }
        let s = localStorage.getItem('story');
        if (s) {
            this.story = JSON.parse(s);
        }

        }
        
    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(window.atob(base64));
    }; 
    
    getUserRole() {
        if(!this.user || !this.user.token) return null;
        var tp = this.parseJwt(this.user.token);
        var role = tp['role']; 
        return role;
    }

    getCurrentStory(): localStory {
        let s = localStorage.getItem('story');
        if (s) {
        return JSON.parse(s);
        }
    } 

    public logStoryViewed(clientId: number, carrierName: string, countryId: number, countryName: string, storyId: number) {
        return this.httpClient.post<any>(environment.api + 'api/logging/storyviewed', { clientId, carrierName, countryId, countryName, storyId });
    }   

    public logDashboardViewed(clientId: number, carrierName: string, countryId: number, countryName: string, storyId: number, workbookId: string, dashboardId: string, dashboardName: string) {
        return this.httpClient.post<any>(environment.api + 'api/logging/dashboardviewed', { clientId, carrierName, countryId, countryName, storyId, workbookId, dashboardId, dashboardName });
    }

    public logNormalPptExport(carrierId: number, countryId: number, ppt: object) {
        return this.httpClient.post<any>(environment.api + 'api/logging/normalpptexport?tier1Id=' + carrierId + '&tier2Id=' + countryId, ppt);
    }
}
