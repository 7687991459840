import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserCostProjection } from 'app/models/cost-projection/user-cost-projection.model';
import { CostProjectionService } from 'app/services/cost-projection.service';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';

@Component({
  selector: 'app-cost-projection-load-dialog',
  templateUrl: './cost-projection-load-dialog.component.html',
  styleUrls: ['./cost-projection-load-dialog.component.scss']
})
export class CostProjectionLoadDialogComponent implements OnInit {

  public costProjectionList: UserCostProjection[] = null;

  public loadCostProjectionColumnDefs = [
    { headerName: 'Name', field: 'name' },
    { 
      headerName: 'Date', field: 'date', sort: 'desc',
      cellRenderer: (row) => {
        return moment(row.data.createdOn).format('MM/DD/YYYY HH:mm:ss');
      }
    }
  ];

  private _user: any;

  constructor(
    private _dialogRef: MatDialogRef<CostProjectionLoadDialogComponent>,
    private _userService: UserService,
    private _costProjectionService: CostProjectionService,
    private _toasterService: ToasterService) { }

  ngOnInit(): void {
    this._user = this._userService.user;
    this._costProjectionService
      .retrieveUserCostProjections(this._user.client.tier1Id, this._user.country.tier2Id)
      .subscribe((costProjectionList: UserCostProjection[]) => {
        this.costProjectionList = costProjectionList;
      });
  }

  public onLoad(row: any): void {
    this._dialogRef.close(row.data);
  }

  public onDelete(row: any): void {
    this._costProjectionService.deleteUserCostProjection(row.data.id).subscribe(() => {
      const userCostProjectionIndex = this.costProjectionList
        .findIndex(record => record.id === row.data.id);
      this.costProjectionList.splice(userCostProjectionIndex, 1);
      this.costProjectionList = [...this.costProjectionList];

      this._toasterService.showMessage('Saved cost projection is successfully deleted.', ToasterServiceModes.OK);
    }, () => {
      this._toasterService.showMessage('Failed. Saved cost projection is not deleted.', ToasterServiceModes.NOK);
    });
  }

}
