<div id="libAdminComp" fxLayout="column" fxFlexFill fxLayoutGap="12px" class="contentPadding">
        <div fxLayout="row">
            <div>
                <h1 id="libAdminHeader" class="text-mono">Library Administration</h1>
            </div>
            <div style="margin-left: 10px; padding-top: 15px">
                <button id="libAdminBtnAdd" mat-raised-button class="mat-primary" (click)="onRedirectToAddLibraryItem()">Add</button>
            </div>
            <div fxFlex></div>
        </div>
        <div fxLayout="row" fxLayoutGap="12px">
            <mat-card id="libAdmin1Half" fxFlex="70">
                <div style="overflow: hidden">
                    <div fxLayout="column">
                        <mat-form-field id="libAdminFilterLib" >
                            <mat-label>Library</mat-label>
                            <mat-select [(ngModel)]="libraryFilter">
                                <mat-option value="">
                                All
                                </mat-option>
                                <mat-option *ngFor="let selectedLibrary of selectedLibraries" [value]="selectedLibrary.name">
                                {{selectedLibrary.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxLayout="column" *ngFor="let selectedLibrary of selectedLibraries">
                        <div *ngIf="libraryFilter.length === 0 || libraryFilter === selectedLibrary.name">
                            <h4><strong>{{selectedLibrary.name}}</strong> ({{selectedLibrary.libraryItemList.length}})</h4>
                            <agcustom-grid [dataList]="selectedLibrary.libraryItemList" [columnDefs]="selectedLibraryGridColDefs" [view]="true" [visibility]="true" [delete]="true" (onView)="onViewLibraryItem($event)" (onVisibleToggle)="onLibraryItemVisibilityToggle($event)" (onDelete)="onLibraryItemDelete($event)"></agcustom-grid>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card id="libAdmin2Half" fxFlex="30" style="height: 100%" *ngIf="currentLibrary">
                <div style="overflow: hidden">
                    <h4><strong>{{currentLibrary.name}}</strong> ({{currentLibrary.guid}})</h4>
                </div>
                <div id="libAdminTable" style="overflow: hidden; padding-top: 20px">
                    <table style="width: 100%">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Actual</th>
                                <th>User Defined</th>
                                <th style="text-align: center">Is Selected?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let globalParam of currentLibraryGlobalParamList">
                                <td>{{globalParam.name}}</td>
                                <td>{{globalParam.paramName}}</td>
                                <td *ngIf="!globalParam.isEditable">{{globalParam.paramNameUser}} <i *ngIf="globalParam.isSelected" class="material-icons" style="cursor: pointer; color: blue" (click)="globalParam.isEditable = true">edit</i></td>
                                <td *ngIf="globalParam.isEditable">
                                    <mat-form-field fxFlex>
                                        <input matInput [(ngModel)]="globalParam.paramNameUser">
                                    </mat-form-field>
                                    <i class="material-icons" style="color: blue; width: 30px; padding-top: 20px; cursor: pointer" (click)="saveUserDefinedGlobalParamName(globalParam)">save</i>
                                    <i class="material-icons" style="width: 30px; padding-top: 20px; cursor: pointer" (click)="resetUserDefinedGlobalParamName(globalParam)">undo</i>                                    
                                </td>
                                <td *ngIf="globalParam.isSelected" style="cursor: pointer; text-align: center" (click)="onToggleGlobalParamSelection(globalParam)">
                                    <i class="material-icons" style="color: green">check</i>
                                </td>
                                <td *ngIf="!globalParam.isSelected" style="cursor: pointer; text-align: center" (click)="onToggleGlobalParamSelection(globalParam)">
                                    <i class="material-icons" style="color: red">close</i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="padding-top: 30px">
                    <div fxFlex='100' fxLayoutAlign="center center">
                        <button id="libAdminBtnSave" mat-raised-button class="mat-primary" (click)="saveGlobalParams()">Save</button>
                        <button id="libAdminClose" mat-raised-button class="mat-secondary" style="margin-left: 5px" (click)="closeLibraryItemDetails()">Close</button>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
