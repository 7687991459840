export const locale = {
    lang: 'it',
    data: {
  'UserOptions': {
    'Monthly': 'Mensile',
    'YTDFull': 'Da inizio anno completo',
    'YTDComparable': 'Da inizio anno confrontabile',
    'Rolling3Months': 'Rolling 3 mesi',
    'Rolling6Months': 'Rolling 6 mesi',
    'Rolling9Months': 'Rolling 9 mesi',
    'Rolling12Months': 'Rolling 12 mesi',
    'Current': 'Attuale',
    'Current-1': 'Attuale -1',
    'PaidBasis': 'Base pagamento',
    'IncurredIncomplete': 'Per accadimento e incompleti',
    'IncurredCompleted': 'Per accadimento e completi',
    'TimePeriod': 'Periodo di tempo',
    'Basis': 'Basi',
    'MaxDate': 'Data massima',
    'PlanYear': 'Anno',
    'UsePriorYear': "Utilizzare l'anno precedente",
    'ErrorMessagePeriod1OverlapPeriod2': 'Il periodo 1 si sovrappone al periodo 2.',
    'ErrorMessagePeriod2OverlapPeriod3': 'Il periodo 2 si sovrappone al periodo 3.',
    'ErrorMessagePeriod3OverlapPeriod4': 'Il periodo 3 si sovrappone al periodo 4.',
    'ErrorMessagePeriod1Missing': 'Manca il periodo 1.',
    'ErrorMessagePeriod2Missing': 'Manca il periodo 2.',
    'ErrorMessagePeriod3Missing': 'Manca il periodo 3.',
    'ErrorMessageMaxDateEarlierThanPeriod1': 'La data massima è precedente alla data di inizio del periodo 1.'
  }
}};