import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-color-ranking',
  templateUrl: './color-ranking.component.html',
  styleUrls: ['./color-ranking.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ColorRankingComponent {

  public params: any;
  isAlert: boolean = false;
  displayAlert: boolean = false;

  isOneStar: boolean = false;
  isTwoStar: boolean = false;
  isThreeStar: boolean = false;
  isNotApplicable: boolean = false;

  agInit(params: any): void {
    this.params = params;
    console.log(this.params);
    if (this.params.colDef.field == "design" ||
      this.params.colDef.field == "governance" ||
      this.params.colDef.field == "engagement" || this.params.colDef.field == "value") {

      if (this.params.value == 999) {
        this.isAlert = true;
      } else if (this.params.value == 99) {
        this.isNotApplicable = true;
      }
      if (this.params.value == 3) {
        this.isThreeStar = true;
      } else if (this.params.value == 2) {
        this.isTwoStar = true;
      } else {
        this.isOneStar = true;
      }
    }
  }
  counter(i: number) {
    return new Array(i);
  }
}