import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-useroptions-dialog',
  templateUrl: './useroptions-dialog.component.html',
  styleUrls: ['./useroptions-dialog.component.css']
})
export class UseroptionsDialogComponent implements OnInit {

    public profile = environment.profile;

    constructor(public dialogRef: MatDialogRef<UseroptionsDialogComponent>) { }

    ngOnInit() {
    }

    onCancel($event) {
        this.dialogRef.close();
    }

    onOptionsSelected($event) {
        this.dialogRef.close();
    }
    
    onAdmin($event) {
        this.dialogRef.close();
    }
}
