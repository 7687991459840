import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private snackBar: MatSnackBar) { }

    showMessage(message, mode: ToasterServiceModes = null): void {
        const snackbar = this.snackBar.open(message, null, {
            duration: mode && mode === ToasterServiceModes.NOK ? 6000 : 3000,
            verticalPosition: 'top',
            panelClass: this.getPanelClass(mode),
            horizontalPosition: 'end'
        });

        document.addEventListener('keypress', (event) => {
            if (event.key === 'q') {
                snackbar.dismiss();
            }
        });
    }

    getPanelClass(mode: ToasterServiceModes): string {
        switch(mode) {
            case ToasterServiceModes.OK: {
                return 'toaster-green';
            }
            case ToasterServiceModes.NOK: {
                return 'toaster-red';
            }
            default: return '';
        }
    }

    dismissAll(): void {

    }
}

export enum ToasterServiceModes {
  OK = 1,
  NOK = 2,
  WARN = 3
}
