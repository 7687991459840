export const locale = {
    lang: 'en',
    data: {
        'EnrollmentOverTime': {
            'EnrollmentOverTimeHeading' : 'Enrollment By Time',
            'Male' : 'Male',
            'Female' : 'Female',
            'NA' : 'NA',
            'Dimensions' : 'Dimension',
            'AgeBand' : 'Age Band',
            'Gender' : 'Gender',
            'Relationship' : 'Relationship',
            'IsEmployee' : 'EE vs Dep',
            'Employee' : 'Employee',
            'Dependent' : 'Dependent',
            'Monthly' : 'Monthly',
            'YearToDateBasis' : 'Year-To-Date Basis',
            'YearToDateBasisC' : 'Year-To-Date Basis (C)',
            'Rolling3Basis' : 'Rolling 3 Basis',
            'Rolling6Basis' : 'Rolling 6 Basis',
            'Rolling9Basis' : 'Rolling 9 Basis',
            'Rolling12Basis' : 'Rolling 12 Basis',
            'DateRange':'to'

        }
    }
};















