export const locale = {
    lang: 'en',
    data: {
        'ClaimType': {
            'ClaimHeading': 'Claim Type',
            'AllAmountsDisplayedInLocalCurrency': 'All amounts displayed in local currency',
            'ClaimType': 'Claim Type',
            'TotalPaidClaim': 'Total Paid Claims',
            'ClaimantCount': 'Claimant Count',
            'ClaimCount': 'Claim Count',
            'ClaimsPerClaimant': 'Claims Per Claimant',
            'ClaimsPerHeadcount': 'Claims Per Headcount',
            'CostPerClaim': 'Cost Per Claim',
            'CostPerClaimant': 'Cost Per Claimant',
            'CostPerHeadcount': 'Cost Per Headcount',
            'ChangeInCost': '% Change in Cost',
            'Claim': 'Claim',
            'Claimant': 'Claimant',
            'Headcount': 'Headcount',
            'ClaimsTotal': 'Claims Total',
            'Count': 'Count',
            'ClaimsReportedByPaidDate': 'Claims reported by paid date',
            'ClaimsReportedByIncurredDate': 'Claims reported by incurred date',
            'CountBasis': 'Count Basis',
            'Sort': 'Sort',
            'Show': 'Show',
            'Hide': 'Hide',
            'ShowHide': 'Show/Hide',
            'Average': 'Average',
            'PeriodAverage': 'Period Average',
            'PlanYear': 'Plan Year'
        }
    }
};
