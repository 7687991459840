<div class="contentPadding" fxLayout="column">
    <div fxLayout="row">
        <div fxFlex='100'>
            <aon-h5 class="subtitle">Challenges</aon-h5>
        </div>
    </div>
    <div fxLayout="row">
        <div fxFlex='100'>
            <aon-h1 class="title">Step Up! Challenge</aon-h1>
        </div>
    </div>
    <div *ngIf="false" fxLayout="row" style="margin-top: 15px;">
        <div class='font-white' fxFlex='100'>
            <aon-bar-summary width="100%" height="140px" colorindex="17">
                <aon-bar-summary-content width="45%" style='padding-left: 20px; padding-right: 20px'>
                    <div fxLayout='row'>
                        <div style='width: 110px; margin-top: -5px;'>
                            <fa-icon
                            style="font-size: 80px;"
                            [icon]="['fal', 'check-circle']"></fa-icon>
                        </div>
                        <div fxFlex style='padding-top: 10px'>
                            <aon-p-lg-bold>Target Met!</aon-p-lg-bold>
                            <aon-p-lg>Target: 1.5% increase in Body scores for employees 55+</aon-p-lg>
                        </div>
                    </div>
                </aon-bar-summary-content>
                <aon-vertical-line></aon-vertical-line>
                <aon-bar-summary-content width="35%">
                    <div fxLayout='row'>
                        <div style='width: 110px; margin-top: 5px'>
                            <aon-p-sm-bold>Results</aon-p-sm-bold>
                            <aon-h1>2.1%</aon-h1>
                        </div>
                        <div fxFlex style='padding-top: 65px'>
                            <aon-p-lg>Increase in Body scores for employees 55+</aon-p-lg>
                        </div>
                    </div>
                </aon-bar-summary-content>
              </aon-bar-summary>
        </div>
    </div>
    <div fxLayout='row' style='margin-top:30px'>
        <div fxFlex='48'>
            <aon-summary-card width="99%" height="450px">
                <aon-summary-card-content contentwidth='90%'>
                  <div fxLayout='column'style='width: 100%'>
                    <div fxLayout='row'>
                        <div fxFlex='50'>
                            <div>
                                <aon-h3 style="color:#001B5A;">
                                    Key Metrics</aon-h3>
                            </div>
                            <div>
                                <aon-donut-chart
                                    width="350px" height="350px"
                                    legendposition="none"
                                    tooltip="false"  middletext="76%" legendsubtitle='Completion Rate' [data]='keyMetricsChartData'>
                                    </aon-donut-chart>
                            </div>
                        </div>
                        <div fxFlex></div>
                        <div fxFlex='45'>
                            <div style='text-align: center; margin-top: 10px;'>
                                <aon-p-sm>Active dates: 05/03 - 05/06/2020</aon-p-sm>
                            </div>
                            <div>
                                <table class='grid' style='width:100%; margin-top: 70px'>
                                    <tr *ngFor="let keyMetricsTableRow of keyMetricsTableData">
                                        <td><aon-p-sm>{{keyMetricsTableRow.category}}</aon-p-sm></td>
                                        <td style='text-align: right;'><aon-p-lg>{{keyMetricsTableRow.quantity | number}}</aon-p-lg></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                  </div>
                </aon-summary-card-content>
              </aon-summary-card>
        </div>
        <div fxFlex></div>
        <div fxFlex='50'>
            <aon-summary-card width="100%" height="450px">
                <aon-summary-card-content contentwidth='90%'>
                  <div fxLayout='column'style='width: 100%'>
                    <div fxLayout='row'>
                        <div fxFlex='100'>
                            <div>
                                <aon-h3 style="color:#001B5A;">
                                    Health Score Performance <aon-tooltip-default text='Tooltip Here'><fa-icon 
                                        style="font-size: 20px;" 
                                        [icon]="['fal', 'info-circle']"></fa-icon></aon-tooltip-default></aon-h3>
                            </div>
                            <div>
                                <table class='grid' style='width:100%; margin-top: 30px'>
                                    <thead>
                                        <tr>
                                            <th colspan='2' style="width: 140px">Category</th>
                                            <th>Score</th>
                                            <th style="width: 60px" colspan='2'>Performance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let healthScorePerformanceTableRow of healthScorePerformanceTableData">
                                            <td class='icon' style='padding-top: 5px; width: 40px'><fa-icon 
                                                style="font-size: 20px;"
                                                [icon]="healthScorePerformanceTableRow.icon"></fa-icon></td>
                                            <td><aon-p-sm>{{healthScorePerformanceTableRow.category}}</aon-p-sm></td>
                                            <td><aon-p-lg>{{healthScorePerformanceTableRow.score}}</aon-p-lg></td>
                                            <td style="width: 60px"><aon-p-lg>{{healthScorePerformanceTableRow.performance | number: '.1'}}%</aon-p-lg></td>
                                            <td class='icon' style='padding-top: 0;'>
                                                <fa-icon 
                                                *ngIf="healthScorePerformanceTableRow.performance > 0"  
                                                style="font-size: 16px;"
                                                [icon]="['fal', 'arrow-up']"></fa-icon>
                                                <fa-icon 
                                                *ngIf="healthScorePerformanceTableRow.performance < 0"  
                                                style="font-size: 16px;"
                                                [icon]="['fal', 'arrow-down']"></fa-icon>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                  </div>
                </aon-summary-card-content>
            </aon-summary-card>
        </div>
    </div>
    <div fxLayout='row' style='margin-top:30px'>
        <div fxFlex>
            <aon-summary-card width="99%" height="800px">
                <aon-summary-card-content contentwidth='97%'>
                  <div fxLayout='column'style='width: 100%'>
                    <div fxLayout='row'>
                        <div fxFlex>
                            <div>
                                <aon-h3 style="color:#001B5A;">Participation Breakdown</aon-h3>
                            </div>
                            <div>
                                <aon-p-lg>Select a row or segment to view details and category scores.</aon-p-lg>
                            </div>
                        </div>
                    </div>
                    <div fxLayout='row' *ngIf="false">
                        <div fxFlex></div>
                        <div (click)="toggleViewCompletionRatesEnabled()">
                            <aon-p-lg class="clickable">View Completion Rates</aon-p-lg>
                        </div>
                        <div style='margin-left: 10px;'>
                            <aon-switch [switchon]='isViewCompletionRatesEnabled' (ontoggle)='toggleViewCompletionRatesEnabled()'></aon-switch>
                        </div>
                    </div>
                    <div fxLayout='row'>
                        <table class='participation-breakdown'>
                            <thead>
                                <tr>
                                    <th>Group</th>
                                    <th style="width: 300px" *ngFor="let ageGroupKey of participationBreakdownData.xCategories">{{ageGroupKey}}</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let businessUnitKey of participationBreakdownData.yCategories">
                                <tr>
                                    <th [ngClass]="{'selected': participationBreakdownData.data[businessUnitKey].selected, 'highlight': participationBreakdownData.data[businessUnitKey].highlight }">
                                        <div fxLayout='row' (click)="toggleSelection(participationBreakdownData.data[businessUnitKey])">
                                            <div class="clickable">{{businessUnitKey}}</div>
                                            <div fxFlex class="clickable"></div>
                                            <div class="clickable">
                                                <fa-icon *ngIf="participationBreakdownData.data[businessUnitKey].selected" [icon]="['fal', 'chevron-up']"></fa-icon>
                                                <fa-icon *ngIf="!participationBreakdownData.data[businessUnitKey].selected" [icon]="['fal', 'chevron-down']"></fa-icon>
                                            </div>
                                        </div>                                        
                                    </th>
                                    <td *ngFor="let ageGroupKey of participationBreakdownData.xCategories">
                                        <aon-percentage-bar-chart 
                                        width='250px' 
                                        [firstpercentagevalue]='participationBreakdownData.data[businessUnitKey].data[ageGroupKey].participationPercentage' 
                                        [secondpercentagevalue]='participationBreakdownData.data[businessUnitKey].data[ageGroupKey].completionRate' 
                                        [highlight]='participationBreakdownData.data[businessUnitKey].highlight' 
                                        [selected]='participationBreakdownData.data[businessUnitKey].data[ageGroupKey].selected' 
                                        [secondpercentageenabled]='isViewCompletionRatesEnabled'></aon-percentage-bar-chart>
                                    </td>
                                </tr>
                                <tr *ngIf="participationBreakdownData.data[businessUnitKey].selected">
                                    <td class='participation-breakdown-selection-details' colspan="5">
                                        <div fxLayout='row' class='content' fxLayoutAlign='start center'>
                                            <div class='bold'>
                                                <span>{{selectedBusinessUnitLabel}}, ages {{selectedAgeGroupLabel}}</span>
                                            </div>
                                            <div fxFlex></div>
                                            <div>
                                                <table class='left-right-navigation-table'>
                                                    <tr>
                                                        <td (click)="goToPrevAgeGroup()"><fa-icon [icon]="['fal', 'chevron-left']"></fa-icon></td>
                                                        <td (click)="goToNextAgeGroup()"><fa-icon [icon]="['fal', 'chevron-right']"></fa-icon></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div fxLayout='row' class='content padding-top-20px' fxLayoutAlign='start start'>
                                            <div fxFlex>
                                                <div><span class="bold">{{selectedAgeGroup.totalUsers}}</span> total users</div>
                                                <div class="padding-top-15px"><span class="bold">{{selectedAgeGroup.challengeParticipants}}</span> challenge participants</div>
                                                <div *ngIf="false" class="padding-top-15px"><span class="bold">{{selectedAgeGroup.completedChallenges}}</span> completed challenges</div>
                                                <div *ngIf="false" class="padding-top-15px"><span class="bold">{{selectedAgeGroup.completionRate}}%</span> completion rate</div>
                                            </div>
                                            <div class="content-card" style="width: 250px;">
                                                <div fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="margin-right: 15px;">
                                                        <fa-icon [icon]="['fal', 'wave-triangle']"></fa-icon>
                                                    </div>
                                                    <div fxFlex>
                                                        <span class='bold'>Average Score</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div fxFlex style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.averageScore}}</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.averageScorePercentage}}</span>
                                                    </div>
                                                    <div style='padding-top: 2px; padding-left: 10px'>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.averageScorePercentage > 0" [icon]="['fal', 'arrow-up']"></fa-icon>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.averageScorePercentage < 0" [icon]="['fal', 'arrow-down']"></fa-icon>
                                                    </div>
                                                    <div style='padding-top: 5px; padding-left: 10px'>
                                                        <span style='font-size: 14px;'>since {{selectedAgeGroup.averageScoreFromDate}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="content-card" style="width: 250px;">
                                                <div fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="margin-right: 15px;">
                                                        <fa-icon [icon]="['fal', 'apple-alt']"></fa-icon>
                                                    </div>
                                                    <div fxFlex>
                                                        <span class='bold'>Lifestyle Score</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div fxFlex style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.lifestyleScore}}</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.lifestyleScorePercentage}}</span>
                                                    </div>
                                                    <div style='padding-top: 2px; padding-left: 10px'>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.lifestyleScorePercentage > 0" [icon]="['fal', 'arrow-up']"></fa-icon>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.lifestyleScorePercentage < 0" [icon]="['fal', 'arrow-down']"></fa-icon>
                                                    </div>
                                                    <div style='padding-top: 5px; padding-left: 10px'>
                                                        <span style='font-size: 14px;'>since {{selectedAgeGroup.lifestyleScoreFromDate}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="content-card" style="width: 250px;">
                                                <div fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="margin-right: 15px;">
                                                        <fa-icon [icon]="['fal', 'running']"></fa-icon>
                                                    </div>
                                                    <div fxFlex>
                                                        <span class='bold'>Body Score</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div fxFlex style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.bodyScore}}</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.bodyScorePercentage}}</span>
                                                    </div>
                                                    <div style='padding-top: 2px; padding-left: 10px'>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.bodyScorePercentage > 0" [icon]="['fal', 'arrow-up']"></fa-icon>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.bodyScorePercentage < 0" [icon]="['fal', 'arrow-down']"></fa-icon>
                                                    </div>
                                                    <div style='padding-top: 5px; padding-left: 10px'>
                                                        <span style='font-size: 14px;'>since {{selectedAgeGroup.bodyScoreFromDate}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="content-card" style="width: 250px;">
                                                <div fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="margin-right: 15px;">
                                                        <fa-icon [icon]="['fal', 'head-side-brain']"></fa-icon>
                                                    </div>
                                                    <div fxFlex>
                                                        <span class='bold'>Mind Score</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div fxFlex style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.mindScore}}</span>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 15px;" fxLayout='row' fxLayoutAlign='start center'>
                                                    <div style="padding-left: 5px;">
                                                        <span style="font-size: 24px;">{{selectedAgeGroup.mindScorePercentage}}</span>
                                                    </div>
                                                    <div style='padding-top: 2px; padding-left: 10px'>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.mindScorePercentage > 0" [icon]="['fal', 'arrow-up']"></fa-icon>
                                                        <fa-icon style='color: black' *ngIf="selectedAgeGroup.mindScorePercentage < 0" [icon]="['fal', 'arrow-down']"></fa-icon>
                                                    </div>
                                                    <div style='padding-top: 5px; padding-left: 10px'>
                                                        <span style='font-size: 14px;'>since {{selectedAgeGroup.mindScoreFromDate}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                  </div>
                </aon-summary-card-content>
            </aon-summary-card>
        </div>
    </div>
</div>
