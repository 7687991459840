import {
    Component, OnInit, Input, Output, OnChanges, EventEmitter, ViewChild
} from "@angular/core";
import * as Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more"; //module
HC_more(Highcharts); //init module
import { Chart } from "angular-highcharts";
import { Options } from "highcharts";
import { countryLATEM } from 'app/constants/countries_LATAM';
import { NumberFormattingService } from "app/services/number-formatting.service";

@Component({
    selector: "app-bubble",
    templateUrl: "./bubble.component.html",
    styleUrls: ["./bubble.component.css"]
})
export class BubbleComponent implements OnInit, OnChanges {
    constructor(private _numberFormatService: NumberFormattingService) {
    }
    Highcharts: typeof Highcharts = Highcharts;
    @Input() dataList: any;
    @Input() maxX: any;
    @Input() maxY: any;
    @Input() labelXaxis: any;
    @Input() labelYaxis: any;
    @Input() labelZaxis: any;
    @Input() country: string;
    @Input() doubleQuadrantDash = false;//to chk doubleQuadrantDash
    @Output() actionBubble = new EventEmitter<any>();
    chart: Chart;
    options: Options;
    ngOnInit() {
        this.initialize();
        this.chart = new Chart(this.options);
    }
    ngOnChanges() {
        this.initialize();
        this.chart = new Chart(this.options);
    }
    initialize() {
       const parent = this;
        this.options = {
            chart: {
                type: "bubble",
                height: this.doubleQuadrantDash ? 630 : null
            },

            credits: {
                enabled: false
            },
            title: {
                text: ""
            },
            xAxis: {
                title: {
                    text: this.labelXaxis,
                    style: {
                        color: 'black',
                        fontSize: '14px',
                        textOutline: 'none',
                        fontWeight: 'bold',
                        fontFamily: 'Helvetica Now Text, Arial, Open Sans, Helvetica, sans-serif'
                    }
                },
                min: 0,
               // max: this.maxX,
                labels: {
                    formatter: function(){
                        return countryLATEM.indexOf(parent.country) >= 0 ?
                        parent._numberFormatService.ToAbbrevNumberStringLatem(this.value, 4) :
                        parent._numberFormatService.ToAbbrevNumberString(this.value, 4)            
                    },
                    style: {
                        color: 'black',
                        fontSize: '14px',
                        textOutline: 'none',
                        fontWeight: '400',
                        fontFamily: 'Helvetica Now Text, Arial,Open Sans, Helvetica, sans-serif'
                    }
                }
            },
            yAxis:
            {
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                min: 0,
                //max: this.maxY,
                lineWidth: 1,
                title: {
                    text: this.labelYaxis,
                    style: {
                        color: 'black',
                        fontSize: '14px',
                        textOutline: 'none',
                        fontWeight: 'bold',
                        fontFamily: 'Helvetica Now Text, Arial,Open Sans, Helvetica, sans-serif'
                    }
                },
                labels: {
                    formatter: function(){
                        return countryLATEM.indexOf(parent.country) >= 0 ?
                        parent._numberFormatService.ToAbbrevNumberStringLatem(this.value, 4) :
                        parent._numberFormatService.ToAbbrevNumberString(this.value, 4)            
                    },
                    style: {
                        color: 'black',
                        fontSize: '14px',
                        textOutline: 'none',
                        fontWeight: '400',
                        fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif'
                    }
                }
            },

            plotOptions: {
                bubble: {
                    minSize: 5,
                    maxSize: 40
                },
                map: {
                    states: {
                        hover: {
                            color: "#EEDD66"
                        }
                    }
                },
                series: {
                    allowPointSelect: true,
                    events: {
                        click: e => {
                            this.actionBubble.emit(e.point);
                        }
                    },
                    dataLabels: {
                        allowOverlap: false,
                        enabled: true,
                        align: "right",
                        padding: 10,
                        style: {
                            color: 'black',
                            fontSize: '13px',
                            textOutline: 'none',
                            fontFamily: 'Helvetica Now Text, Arial, Open Sans, Helvetica, sans-serif'
                        },
                        formatter: function () {
                             return this.point.name;
                        }
                    },
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    }
                }
            },
            tooltip: {
                enabled: true,
                useHTML: true,
                headerFormat: "<table>",
                pointFormat:
                    '<tr style="text-align: left; font-weight:bold; font-size: 13px !important; " ><th  colspan="2"><b>{point.name}</b></th></tr>' +
                    '<tr style="text-align: left; font-size: 13px !important; " ><th>' + this.labelXaxis + ' :</th><td>{point.xLable} </td></tr>' +
                    '<tr style="text-align: left; font-size: 13px !important; " ><th>' + this.labelYaxis + ':</th><td>{point.yLable} </td></tr>' +
                    '<tr style="text-align: left; font-size: 13px !important; " ><th>' + this.labelZaxis + ':</th><td>{point.zLable}</td></tr>',
                footerFormat: "</table>"
            },
            legend: {
                enabled: false
            },
            series: [
                {
                    type: undefined,
                    data: this.dataList,
                    colorByPoint: true
                }
            ]
        };

    }
}