export const locale = {
    lang: 'it',
    data: {
  'ClaimAgeBand': {
    'ClaimsByAgeBand': 'Sinistri per fascia di età',
    'AllAmountsDisplayedInLocalCurrency': 'Tutti gli importi visualizzati in valuta locale',
    'CountBasis': 'Base del conteggio',
    'Claim': 'Sinistro',
    'Claimant': 'Testa colpita',
    'AgeBandSplit': 'Divisione in fasce di età',
    'None': 'Nessuno',
    'Gender': 'Genere',
    'Relationship': 'Relazione',
    'Sort': 'Ordina',
    'ClaimsTotal': 'Sinistri totali',
    'Count': 'Conteggio',
    'AverageCost': 'Costo medio',
    'DataTable': 'Tabella dati',
    'TopDiagnoses': 'diagnosi top',
    'TopProviders': 'Strutture sanitarie top',
    'TotalPaidClaim': 'Sinistri totali pagati',
    'ClaimantCount': 'Numero teste colpite',
    'ClaimCount': 'Conteggio Sinistri',
    'ClaimsPMPM': 'Sinistri PMPM',
    'CostPerClaim': 'Costo per sinistro',
    'CostPerClaimant': 'Costo per assistito attivo',
    'ClaimsReportedByPaidDate': 'Sinistri per data di pagamento',
    'ClaimsReportedByIncurredDate': 'Sinistri per data di accadimento',
    'FilterNA': 'Escludi NA',
    'Display': 'Mostra come tabella',
    'ShowLegend': 'Mostra legenda',
    'ShownAsPer': 'Indicato come per',
    'AgeBand': "Fascia d'età",
    'DateRange': 'Intervallo Di Date'
  }
}};