export const locale = {
    lang: 'es-pe',
    data: {
      'AddFilter': {
          'ClientName':'nombre del cliente',
          'Entity':'Entidad',
          'Division':'División', 
          'Plan':'Plan',
          'TimePeriod':'Periodo de tiempo',                
          'PolicyType':'Tipo de política',
          'AgeBand':'Banda de edad',
          'Gender':'Género',
          'ClaimType':'Tipo de reclamo',
          'ClaimType2':'Reclamación tipo 2',
          'ClaimType3':'Reclamación tipo 3',
          'Relationship':'Relación',
          'DiagnosisCategory':'Categoría de diagnóstico',
          'DiagnosisChapter':'Diagnosis Chapter',
          'Location':'Ubicación',
          'IsChronic':'Es crónico',
          'Carrier':'Portador',
          'LOB':'LOB',
          'SubLOB':'Sub LOB',
          'Network':'Red',
          'ProviderName':'Nombre del proveedor',
          'ProviderLocation':'Ubicación del proveedor',
          'ProviderType':'Tipo de proveedor'
      }
  }
};