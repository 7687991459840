export const locale = {
    lang: 'es-ec',
    data: {
      'ClaimAgeBand': {
        'ClaimsByAgeBand' : 'Reclamos por Rango de Edad',            
        'AllAmountsDisplayedInLocalCurrency' : 'Todas las cantidades mostradas en moneda local',
        'CountBasis':'Contar Base',
        'Claim' : 'Reclamo', 
        'Claimant' : 'Reclamante',  
        'AgeBandSplit'  :  'Rango de Edad dividido'  ,       
        'None' : 'Ninguna',
        'Gender' : 'Genero',
        'Relationship' : 'Parentesco',                
        'Sort' : 'Orden',
        'ClaimsTotal' : 'Total de Reclamaciones',
        'Count' : 'Contar',            
        'AverageCost' :  'Costo Promedio',
        'DataTable' : 'Tabla de datos',
        'TopDiagnoses' : 'Top Diagnósticos',
        'TopProviders' : 'Top Prestadores',                     
        'TotalPaidClaim' : 'Total pagado de reclamo', 
        'ClaimantCount':'Parentesco de Demandantes',  
        'ClaimCount':'Número de Eventos',            
        'ClaimsPMPM' : 'Reclamos por Miembro por Mes', 
        'CostPerClaim': 'Costo Por Reclamo',
        'CostPerClaimant': 'Costo Por Reclamante',             
        'ClaimsReportedByPaidDate': 'Reclamaciones reportadas por fecha de pago',
        'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
        'FilterNA': 'Excluir NA',
        'Display':'Mostrar Como Tabla',
        'ShowLegend':'Mostrar leyenda',
        'ShownAsPer':'Se muestra según',
        'AgeBand': 'Rango Etario',
        'DateRange': 'to'
    }
}
};
