import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ExecutiveComponent } from "./components/executive/executive.component";
import { DemographicsComponent } from "./components/demographics/demographics.component";
import { environment } from "environments/environment";
import { PensionScoreComponent } from "./components/executive/cards-section/pension-score/pension-score.component";
import { DemographicsScoreComponent } from "./components/executive/cards-section/demographics-score/demographics-score.component";
import { BenefitScoreComponent } from "./components/executive/cards-section/benefit-score/benefit-score.component";
import { RetirementAdequacyComponent } from "./components/executive/cards-section/retirement-adequacy/retirement-adequacy.component";
import { StarRatingComponent } from "./shared/star-rating/star-rating.component";
import { LocationChart } from "./components/demographics/chart/location/locationChart.component";
import { DemographicByGenderComponent } from "./components/demographics/chart/demographic-by-gender/demographic-by-gender.component";
import { ActivesVsRetireesChart } from "./components/demographics/chart/activesVsRetirees/activesVsRetirees.component";
import { DemographicsChartsComponent } from "./components/demographics/chart/demographic/demographicsChart.component";
import { AgGridModule } from "ag-grid-angular";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MaterialModule } from "app/material.module";
import { ColorRankingComponent } from './shared/color-ranking/color-ranking.component';
import { RetirementComponent } from './components/retirement/retirement.component';
import { TargetComponent } from './components/retirement/target/target.component';
import { RetirementSummaryComponent } from './components/retirement/retirement-summary/retirement-summary.component';
import { RetirementDrillDownComponent } from './components/retirement/retirement-drill-down/retirement-drill-down.component';
import { FwbTopSummaryComponent } from './components/benefit-wellbeing-score/fwb-top-summary/fwb-top-summary.component';
import { ScoreDrillDownComponent } from './components/benefit-wellbeing-score/score-drill-down/score-drill-down.component';
import { FWBScoreComponent } from "./components/benefit-wellbeing-score/fwbscore/fwbscore.component";
import { FinancialWellbeingScoreComponent } from "./components/executive/cards-section/financial-wellbeing-score/financial-wellbeing-score.component";
import { BenefitDashboardComponent } from './components/benefit-dashboard/benefit-dashboard.component';
import { QuestionCardComponent } from './components/benefit-dashboard/question-card/question-card.component';
import { BenefitTopSummaryComponent } from './components/benefit-dashboard/benefit-top-summary/benefit-top-summary.component';
import { RetirementReplacementComponent } from './components/retirement/retirement-replacement/retirement-replacement.component';
import { RetirementScenarioComponent } from './components/retirement/retirement-scenario/retirement-scenario.component';
import { PensionDashboardComponent } from './components/pension-dashboard/pension-dashboard.component';
import { PensionTopSummaryComponent } from './components/pension-dashboard/pension-top-summary/pension-top-summary.component';
import { PensionQuestionCardComponent } from './components/pension-dashboard/pension-question-card/pension-question-card.component';
import { QuestionFieldComponent } from './components/pension-dashboard/question-field/question-field.component';
import { FwbErrorMessageComponent } from './shared/fwb-error-message/fwb-error-message.component';

const routes = [
    {
        path: "fwb",
        children: [
            {
                path: "executive",
                component: ExecutiveComponent,
            },
            {
                path: "demographics",
                component: DemographicsComponent,
                data: {
                    roles: [],
                },
            },
            {
                path: "wellbeingscore",
                component: FWBScoreComponent,
                data: {
                    roles: [],
                },
            },
            {
                path: "benefitscore",
                component: BenefitDashboardComponent,
                data: {
                    roles: [],
                },
            },
            {
                path: "pensionscore",
                component: PensionDashboardComponent,
                data: {
                    roles: [],
                },
            },
            {
                path: "retirement",
                component: RetirementComponent,
                data: {
                    roles: [],
                },
            },
        ],
    },
];

@NgModule({
    declarations: [
        ExecutiveComponent,
        DemographicsComponent,
        PensionScoreComponent,
        DemographicsScoreComponent,
        BenefitScoreComponent,
        RetirementAdequacyComponent,
        StarRatingComponent,
        LocationChart,
        DemographicByGenderComponent,
        ActivesVsRetireesChart,
        LocationChart,
        DemographicsChartsComponent,
        ColorRankingComponent,
        RetirementComponent,
        TargetComponent,
        RetirementSummaryComponent,
        RetirementDrillDownComponent,
        FwbTopSummaryComponent,
        ScoreDrillDownComponent,
        FWBScoreComponent,
        FinancialWellbeingScoreComponent,
        BenefitDashboardComponent,
        QuestionCardComponent,
        BenefitTopSummaryComponent,
        RetirementReplacementComponent,
        RetirementScenarioComponent,
        PensionDashboardComponent,
        PensionTopSummaryComponent,
        PensionQuestionCardComponent,
        QuestionFieldComponent,
        FwbErrorMessageComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MaterialModule,
        FontAwesomeModule,
        AgGridModule.withComponents([StarRatingComponent, ColorRankingComponent])
    ],
    entryComponents: [ExecutiveComponent, DemographicsComponent, RetirementComponent, FWBScoreComponent, BenefitDashboardComponent, PensionDashboardComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [RouterModule, ExecutiveComponent, DemographicsComponent, RetirementComponent, FWBScoreComponent, BenefitDashboardComponent],
})
export class FWBModule { }
