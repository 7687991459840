import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CLAIM_BASIS } from 'app/models/basis.model';

@Component({
  selector: 'app-cost-projection-claim-basis-mismatch-dialog',
  templateUrl: './cost-projection-claim-basis-mismatch-dialog.component.html',
  styleUrls: ['./cost-projection-claim-basis-mismatch-dialog.component.scss']
})
export class CostProjectionClaimBasisMismatchDialogComponent {

  public claimBasisText: {[key in CLAIM_BASIS] : string} = {
    [CLAIM_BASIS.INCURRED_INCOMPLETE]: 'Incurred and Incomplete',
    [CLAIM_BASIS.PAID_BASIS]: 'Paid Basis',
    [CLAIM_BASIS.INCURRED_COMPLETED]: 'Incurred and Completed',
  };

  constructor(
    private _dialogRef: MatDialogRef<CostProjectionClaimBasisMismatchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { userClaimBasis: CLAIM_BASIS, savedClaimBasis: CLAIM_BASIS }) { }
}
