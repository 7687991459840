import { CLAIM_BASIS } from './basis.model';

export interface User {
    userId: string;
    displayName: string;
    userName: string;
    email: string;
    language: string;
    isCostProjectionAllowed: boolean;
    client?: any;
    country?: any;
    tbsUserId: string;
    token: string;
    rollingPeriod: number;
    periodSelection: number;
    claimPaid: CLAIM_BASIS;
    planYear: number;
    timePeriod: any;
}

export class UserModel {
    userName: string;
    email: string;
    password: string;
    isExternal: boolean = false;
    isExtad: boolean = false;
}

export class UserRoleModel {
    id: string;
    name: string;
    displayName: string;
    normalizedName: string;
}
