import { Component, OnInit, OnChanges, Input, SimpleChanges, } from "@angular/core";
import { DemographicsService } from 'app/fwb/services/demographics.service';

@Component({
    selector: "demographic-by-gender",
    templateUrl: "./demographic-by-gender.component.html",
    styleUrls: ["./demographic-by-gender.component.css"],
})
export class DemographicByGenderComponent implements OnInit, OnChanges {
    @Input() demographicsData: any[];
    @Input() view: any[] = [1300, 350];
    @Input() title: string = "";
    @Input() scaleMax = 100;
    @Input() keys: [];
    @Input() legendtitle: [];
    chartId = "FinancialWellbeing";
    height: any = "430px";
    //width: any = "95%";
    width: any = "95%";
    cardheight: any = "520px";
    cardwidth: any = "1250px";
    tooltip: boolean = true;
    resize: boolean = true;
    titleposition = "left";
    charttitle = "Financial Wellbeing Score";
    yaxistitle = "";
    xaxistitle = "";
    color = "white";
    maxyaxisnumber: number = 1000;
    data: any;
    fuseConfig: any;
    navigation: any;
    emptyChartTitle = "";
    public multi: any[] = [];
    public categorycolors: any[] = [];
    tickformat =".0f";
    // options
    showXAxis: boolean = true;
    showYAxis: boolean = true;
    gradient: boolean = false;
    showLegend: boolean = true;
    showXAxisLabel: boolean = true;
    xAxisLabel: string = "";
    showYAxisLabel: boolean = true;
    barPadding = 10;
    yAxisLabel: string = "";
    yScaleMax = 0.2;
    showDataLabel = true;
    colorScheme = {
        domain: [
            "#086972",
            "#01a9b4",
            "#87dfd6",
            "#184d47",
            "#96bb7c",
            "#eebb4d",
            "#ffa600",
        ],
    };

    serviceData: any = null;
    isLoading = true;
    isError = false;
    errorMsg = "No Data Found.";
    constructor(
        private _demographicService: DemographicsService,) {
        // Object.assign(this.multi);
    }

    ngOnInit() {
        if (this.serviceData === null) {
          this.getData();
        }
      }
    
      getData() {
        this.serviceData = null;
        this._demographicService.getBreakdown("").toPromise().then((resp) => {
          this.serviceData = resp;
          console.log(this.serviceData);
          this.setData();
          this.errorMsg = "";
          this.isError = false;
      }).catch(error => {
          this.isError = true;
          this.errorMsg = error.message;
      }).finally(() => {
          this.isLoading = false;
        })
      }
    
      setData() { 
        this.yScaleMax = this.scaleMax;
      }
    
    public ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            this.reloadChart();
        }
    }
 

    public reloadChart() {
        this.yScaleMax = this.scaleMax; 
    }

    onSelect(data): void {
        console.log("Item clicked", JSON.parse(JSON.stringify(data)));
    }

    onActivate(data): void {
        // console.log('Activate', JSON.parse(JSON.stringify(data)));
    }

    onDeactivate(data): void {
        // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }
}
