<div fxLayout="column" *ngIf="isLoading" style="overflow: hidden;">
    <div fxLayout="row" class="fwbloadingOverlay">
        <div class="fwbloadingContent">
            Loading...
        </div>
    </div>
</div>
<aon-summary-card width="99%" [height]="scoreCardheight" *ngIf="!isLoading">
    <div style="display: flex;flex-direction:column   ;justify-content: space-around;">
        <aon-h3 style="color: #001b5a;">
            {{ categoryChartTitle }}
        </aon-h3>
    </div>
    <aon-summary-card-content colorindex="1" top="90px" [width]="scoreCardwidth">
        <fwb-error-message [isError]="isError" [message]="errorMsg"></fwb-error-message>
        <div *ngIf="!isError">
            <aon-expandable-bar-chart height="650px" width="100%">
                <aon-bar-content [maxbarheight]="maxbarheight" text=" {{c.category}}       ({{c.value}})"
                    *ngFor="let c of categoryData" [isexpand]="c.isexpanded"
                    (onexpandcollapseclick)="expandParentHeight($event)">
                    <div *ngFor="let item of c.values;index as i" class="questionRow">
                        <div [ngClass]="(i % 2 == 0) ? 'alternativeRow ' : 'normalRow'">
                            <div style="width: 80%;float: left;margin-top: 10px;margin-left: 20px;
                        margin-bottom: -10px; ">
                                <aon-p-lg>{{item.name}}</aon-p-lg>
                            </div>
                            <div style="height: 68px;text-align: center;
                        align-content: flex-end;display: flex;justify-content: flex-end;float:right;margin-top: 20px;
                        margin-bottom: -20px; margin-right: 20px; ">
                                <aon-switch-with-label [switchon]="item.value" width='150px'></aon-switch-with-label>
                            </div>
                        </div>
                    </div>
                </aon-bar-content>
            </aon-expandable-bar-chart>
        </div>
    </aon-summary-card-content>
</aon-summary-card>