<mat-form-field
  matTooltipPosition='above'
  [matTooltip]='tooltip'
  style="width: 100%"
>
  <mat-label>{{placeholder}} <span
      *ngIf="tooltip"
      class="material-icons"
    >info</span></mat-label>
  <mat-select
    #sel
    multiple="multiple"
    [placeholder]="placeholder"
    [value]="selection"
    (openedChange)="openedChange($event)"
  >
    <mat-select-trigger style="min-width: 160px;">
      <span *ngIf="selection.length == 1">
        {{selection}}
      </span>
      <span *ngIf="selection.indexOf(ALL) >= 0">
        ALL
      </span>
      <span *ngIf="selection.indexOf(ALL) < 0 && selection.length > 1">
        (Multiple)
      </span>
    </mat-select-trigger>

    <mat-option
      [value]="ALL"
      (onSelectionChange)="toggleAll($event)"
    >{{ALL}}</mat-option>

    <cdk-virtual-scroll-viewport
      class="options-modal"
      [itemSize]="48"
    >
      <ng-container *cdkVirtualFor="let opt of options">
        <mat-option
          [value]="opt"
          (onSelectionChange)="selectionChange($event)"
        >{{opt}}</mat-option>
      </ng-container>
    </cdk-virtual-scroll-viewport>

    <div>
      <button
        mat-button
        class="mat-accent fixed-height"
        (click)="cancel(); sel.close()"
      >CANCEL</button>
      <button
        mat-button
        style="float: right"
        class="mat-accent fixed-height"
        (click)="apply(); sel.close()"
      >APPLY</button>
    </div>
  </mat-select>
</mat-form-field>