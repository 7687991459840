export const locale = {
    lang: 'es-cl',
    data: {
        'DistributionByClaimType': {
             'TotalPaidClaim' : 'Total Monto Reembolsado ',
             'ClaimCount' : 'Cantidad de Prestaciones',
             'AverageCost' : 'Costo Promedio',
             'Dimensions' : 'Dimensión',
             'AgeBand' : 'Rango Etario',
             'Gender' : 'Género',
             'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'Relationship': 'Parentesco',  
             'ClaimsReportedByPaidDate' :  'Reclamaciones reportadas por fecha de pago',
            'ClaimsReportedByIncurredDate' :  'Reclamaciones informadas por fecha incurrida',
            'ClaimTypeBy': 'Prestación por',
            'DateRange': 'to'
          }
    }
};
