<mat-form-field style="width: 100%">
  <mat-select [placeholder]="placeholder" [(ngModel)]="value" [compareWith]="compareFn"
    (selectionChange)="onSelectionChange()" (openedChange)="onOpenedChange($event)">
    <mat-form-field style="position: absolute; top: 0px; width: 100%;" class="padded">
      <input matInput [formControl]="inputFilter" (keydown)="onInputChange($event)" #searchInput>
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <div style="height: 44px"></div>
    <mat-option *ngFor="let d of filteredDataList" [value]="d">
      <span *ngIf="!isGrouping">{{d[property]}}</span>
      <span *ngIf="isGrouping && !d.isGroup && !d.isGroupMember">{{d[property]}}</span>
      <span *ngIf="isGrouping && d.isGroup"><b>{{d[property]}}</b></span>
      <span *ngIf="isGrouping && d.isGroupMember" style="font-style: italic">---{{d[property]}}</span>
    </mat-option>
  </mat-select>
</mat-form-field>