import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'app/services/cart.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss']
})
export class ExportButtonComponent implements OnInit, OnDestroy {
  public cartItems$: Observable<any[]> = undefined;
  public exportSummary: string = '';

  private _cartItemsSubscription$: Subscription;

  constructor(private _cartService: CartService, private _router: Router) { }

  ngOnInit(): void {
    this.cartItems$ = this._cartService.cartItems$;

    this._cartItemsSubscription$ = this.cartItems$.subscribe({
      next: (cartItems) => {
        this.exportSummary = cartItems
          .map((item, i) => `${i + 1}. ${item.dashboardName}`)
          .join('\n');
      },
    })
  }

  ngOnDestroy(): void {
    this._cartItemsSubscription$?.unsubscribe();
  }

  public handleClickCart(): void {
    this._router.navigateByUrl(`export/cart`);
  }
}
