import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import * as Highcharts from 'highcharts';
@Component({
  selector: 'app-column-stack-age',
  templateUrl: './column-stack-age.component.html',
  styleUrls: ['./column-stack-age.component.css']
})

export class ColumnStackAgeComponent implements OnInit, OnChanges {

  chart: Chart;
  options: Options;
  @Input() dataList: any;
  @Input() unit: string;
  @Output() actionStack = new EventEmitter<any>();
  stackClick: boolean = false;
  @Input() categories: any;
  ngOnInit() {
    this.initialize();
    this.chart = new Chart(this.options);
  }
  ngOnChanges() {
    this.initialize();
    this.chart = new Chart(this.options);
  }
  initialize() {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ","
      }
    });
    this.options = {
      chart: {
        type: 'column',
        height: 270
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        labels: {
          enabled: true,
          style: {
            color: 'black',
            fontSize: '14px',
            textOutline: 'none', 
            fontWeight: '400',
            fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif'
          }
        },
        visible: true,
        categories: this.categories,
        //lineColor: 'transparent'
      },
      yAxis: {
        visible: true,
        lineWidth: 1,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: ''
        },
        labels: {
          style: {
            color: 'black',
            fontSize: '14px',
            textOutline: 'none',
            fontWeight: '400',
            fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif'
          }
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            style: {
              color: 'black',
              fontSize: '14px',
              textOutline: 'none',
              fontWeight: '700',
              fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif',
              textShadow: '0px 0px 5px white',
            },
            formatter: function () {
              if (this.percentage > 15)
                return this.percentage.toFixed(0) + '%';
              else return "";
            }
          },
          pointWidth: 50,
        },

        series: {
          allowPointSelect: true,
          point: {
            events: {
              click: e => {
                this.stackClick = true;
                this.actionStack.emit(e.point);
              }
            }
          }
        }
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        headerFormat: "<b>{series.name}</b><br>" ,
         pointFormat: " {point.y:,.0f} ({point.percentage:.0f}%)" ,
        style: {
          color: 'black',
          fontSize: '14px',
          textOutline: 'none',
          fontWeight: '400',
          fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif'
        }
      },
      series: this.dataList
    }
  }
}