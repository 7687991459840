import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParticipationService {

    constructor(private httpClient: HttpClient) { }

    public getParticipationResult(dashboardParams: any): any
    {
      return this.httpClient.post<any>(environment.api + 'api/Participation/participation-detail', dashboardParams);
    }
}
