export const locale = {
    lang: 'es-ec',
    data: {
        'PerMemberCosts': {
            'PerMemberCosts': 'Costos por Miembro',
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'KeyImpacts': 'Impactos claves',
            'CurrentPmpm': 'INCURRIDO POR MIEMBRO POR MES',
            'BenchmarkPmpm': 'BENCHMARK PMPM',
            'PerMemberPerMonthCost': 'Costo Por Miembro Por Mes',
            'Prior': 'Anterior año',
            'Current': 'Actual',
            'YoYChange': 'Cambio Año a Año',
            'Benchmark': 'Benchmark',
            'IncludeClient': 'Incluir Cliente',
            'ExcludeClient': 'Excluir Cliente',
            'Portfolio': 'Portafolio',
            'Industry': 'Industria',
            'None': 'Ninguna',
            'AgeBand': 'Rango de Edad',
            'Gender': 'Género',
            'Relationship': 'Parentesco',
            'ClaimsReportedByPaidDate': 'Reclamos informados por fecha de pago',
            'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
            'Chart':'Gráfico',
            'Table':'Tabla',
            'DateRange': 'to'
        }
    }
};
