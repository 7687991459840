import { Action } from '@ngrx/store'
import * as FWBActions from '../actions/fwb.actions';
import { FWB } from '../models/fwb.model';

// // Section 1

// Section 2
export function fwbReducer(state: FWB, action: FWBActions.Actions) {

    // Section 3
    switch (action.type) {
        case FWBActions.SET_BENEFIT_QUESTION:
            state = { ...state };
            state.question = action.payload;
            return state;
            case FWBActions.Toggle_BenefitQuestion_Selection:
                state = { ...state };
                state.togglequestionselection = action.payload;
                return state;
            case FWBActions.Toggle_Retirement_Lifestyle_Scenario:
                state = { ...state };
                state.lifestylescenario = action.payload;
                return state;
            case FWBActions.Toggle_Retirement_Replacement_Scenario:
                state = { ...state };
                state.replacementscenario = action.payload;
                return state;
        default:
            return state;
    }
}