export const locale = {
    lang: 'es-pe',
    data: {
        'HealthDiseaseProfile': {            
            'HealthProfile': 'Perfil de salud',
            'Top Diseases': 'Principales Enfermedades',     
            'TopChronicDiseases': 'Principales enfermedades crónicas',
            'TopInfectiousDiseases': 'Top enfermedades infecciosas',
            'TopOtherDiseases': 'Principales enfermedades (otras)',
            'PaidClaim': 'Siniestros pagados',
            'AverageCost': 'Costo promedio',
            'ClaimantCount': 'Cuenta de Paciente',
            'Utilization': 'Utilización',
            'Chronic': 'Crónico',
            'Infectious': 'Enfermedad infecciosa',
            'Other': 'Otro',
            'Current': 'Actual',
            'Prior': 'Anterior',
            'ClaimsReportedByPaidDate': 'Siniestros informados por fecha de ocurrencia',
            'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
            'DateRange': 'to',
            'DiagnosisGroup': 'Grupo de diagnóstico',
            'Actionable': 'Accionables',
            'Cancer': 'Cáncer',
            'Obstetrics': 'Obstétricos',
            'Others': 'Otros',
            'Preventable': 'Prevenible',
            'Intervenable': 'Intervenible',
            'Mental health': 'Salud Mental',
            'Trauma/accidents': 'Accidentes /Trauma',
            'CategoryOption': 'Category',
            'Category': 'Category',
            'Chapter': 'Chapter',
            'Sort': 'Ordenar'
        }
    }
};

