export const COLOR_PALETTE = [
    "#73E2D8",
    "#0084BB",
    "#0055A8",
    "#29B0C3",
    "#D10058",
    "#EA2238",
    "#F25D00",
    "#FFA600",
    "#8ABD45",
    "#12A88A",

    // Color from previous design as backup
    "#73E2D8",
    "#0084BB",
    "#D10058",
    "#EA2238",
    "#F25D00",
    "#FFA600",
    "#8ABD45",
    "#12A88A",
];