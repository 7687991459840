export const locale = {
    lang: 'es-cl',
    data: {
        'ClaimType': {
            'ClaimHeading': 'Prestación',
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'ClaimType': 'Prestación',
            'TotalPaidClaim': 'Total Monto Reembolsado',
            'ClaimantCount': 'Cantidad de asegurados',
            'ClaimCount': 'Cantidad de Prestaciones',
            'ClaimsPerClaimant': 'Prestaciones por asegurado',
            'ClaimsPerHeadcount': 'Prestaciones por Plantilla',
            'CostPerClaim': 'Reembolso por prestación',
            'CostPerClaimant': 'Reembolso por asegurado',
            'CostPerHeadcount': 'Costo Por Plantilla',
            'ChangeInCost': '% Variación del Costo',
            'Claim': 'Reclamo',
            'Claimant': 'Reclamante',
            'Headcount': 'Plantilla',
            'ClaimsTotal': 'Monto Reembolsado Total',
            'Count': 'Contar',
            'ClaimsReportedByPaidDate': 'Reembolsos reportados por fecha de pago',
            'ClaimsReportedByIncurredDate': 'Reembolsos reportadas por fecha incurrida',
            'CountBasis': 'Contar Base',
            'Sort': 'Ordenar',
            'Show': 'Mostrar',
            'Hide': 'Ocultar',
            'ShowHide': 'Mostrar/Ocultar',
            'Average': 'Promedio',
            'PeriodAverage': 'Periodo promedio',
            'PlanYear': 'Año Del Plan'
        }
    }
};
