export const locale = {
    lang: 'it',
    data: {
  'HealthDiseaseProfile': {
    'HealthProfile': 'Profilo sanitario',
    'Top Diseases': 'malattie top',
    'TopChronicDiseases': 'malattie croniche top',
    'TopInfectiousDiseases': 'malattie infettive top',
    'TopOtherDiseases': 'malattie top (altre)',
    'PaidClaim': 'Sinistri pagati',
    'AverageCost': 'Costo medio',
    'ClaimantCount': 'Numero teste colpite',
    'Utilization': 'Utilizzo',
    'Chronic': 'MALATTIE CRONICHE',
    'Infectious': 'MALATTIE INFETTIVE E PARASSITARIE',
    'Other': 'Altro',
    'Current': 'Attuale',
    'Prior': 'precedente',
    'ClaimsReportedByPaidDate': 'Sinistri per data di pagamento',
    'ClaimsReportedByIncurredDate': 'Sinistri per data di accadimento',
    'DateRange': 'Intervallo Di Date',
    'DiagnosisGroup': 'Gruppo di diagnosi',
    'Actionable': 'Actionables',
    'Trauma/accidents': 'TRAUMATISMI E INCIDENTI',
    'Cancer': 'MALATTIE ONCOLOGICHE',
    'Mental health': 'DISTURBI MENTALI',
    'Obstetrics': 'OSTETRICIA E GINECOLOGIA',
    'Others': 'MALATTIE NON CLASSIFICATE',
    'Preventable': 'Prevenibile',
    'Intervenable': 'Intervenibile',
    'CategoryOption': 'Categoria',
    'Category': 'Categoria',
    'Chapter': 'Prestazione',
    'Sort': 'Ordina per',
    'None': 'Nessuno'
  }
}};