<div fxLayout="column" fxFlexFill fxLayoutGap="12px" style="padding: 18px">
    <div fxLayout="row">
        <h1 class="text-mono">Library Administration > Add Workbooks</h1>
    </div>
    <div fxLayout="row" fxLayoutGap="12px">
        <mat-card fxFlex="70">
            <div style="overflow: hidden">
                <div fxLayout="column">
                    <mat-form-field>
                        <mat-label>Library</mat-label>
                        <mat-select [(ngModel)]="libraryFilter">
                            <mat-option value="">
                            All
                            </mat-option>
                            <mat-option *ngFor="let unselectedLibrary of unselectedLibraries" [value]="unselectedLibrary.name">
                            {{unselectedLibrary.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="column" *ngFor="let unselectedLibrary of unselectedLibraries">
                    <div *ngIf="libraryFilter.length === 0 || libraryFilter === unselectedLibrary.name">
                        <h4><strong>{{unselectedLibrary.name}}</strong> ({{unselectedLibrary.libraryItemList.length}})</h4>
                        <agcustom-grid [dataList]="unselectedLibrary.libraryItemList" [columnDefs]="unselectedLibraryGridColDefs" [select]="true"></agcustom-grid>
                    </div>
                </div>
            </div>
        </mat-card>
        <mat-card fxFlex="30" style="height: 100px">
            <div style="overflow: hidden">
                <div fxLayout="column">
                    <button mat-raised-button class="mat-primary" (click)="onAddWorkbooks()">
                    Add
                    </button>
                </div>
            </div>
        </mat-card>
    </div>
</div>
