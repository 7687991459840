export const locale = {
    lang: 'en',
    data: {
        'DiseaseDetail': {
            'DiseaseDetailHeading': 'Prevalence Of Disease',
            'AllAmountInLocalCurrency': 'All amounts displayed in local currency',
            'SelectTop': 'Select Top',
            'Diagnosis': 'Diagnosis',
            'ClaimsTotalHeader': 'Claims Total', 
            'ClaimantTotalHeader' : 'Claimant Count',
            'DiagnosisLevel': 'Diagnosis Level',
            'Category': 'Category',               
            'Chapter': 'Chapter',
            'ClaimsTotal': 'Claims Total',
            'DigClaimantCount': 'Claimant Count', 
            'DiseaseProgression': 'Disease Progression Over Time',
            'Age': 'Age', 
            'CostPerClaimant': 'Cost Per Claimant',
            'CostPerClaim': 'Cost Per Claim',
            'ClaimsPerClaimant': 'Claims Per Claimant',
            'TotalCost': 'Total Cost',
            'ClaimantCount': 'Claimant Count',
            'Prevalence': 'Prevalence',
            'Benchmark': 'Benchmark',
            'IncludeClient': 'Include Client',
            'ExcludeClient': 'Exclude Client',
            'Portfolio': 'Portfolio',
            'Industry': 'Industry',
            'None': 'None',
            'AgeBand' : 'Age Band',
            'Gender' : 'Gender',
            'Relationship' : 'Relationship',
            'ClaimType' : 'Claim Type',
            'ClaimsReportedByPaidDate': 'Claims reported by paid date',
            'ClaimsReportedByIncurredDate': 'Claims reported by incurred date',            
            'ShownAsPer':'Shown as per',
            'DateRange':'to',
            'DiagnosisGroup': 'Diagnosis Group',
            'Actionable': 'Actionables',
            'Chronic': 'Chronic',
            'Infectious': 'Infectious',
            'Trauma/Accidents': 'Trauma/Accidents',
            'Cancer': 'Cancer',
            'Mental Health': 'Mental Health',
            'Obstetrics': 'Obstetrics',
            'Others': 'Others',
            'Preventable': 'Preventable',
            'Intervenable': 'Intervenable'
        }
    }
};
