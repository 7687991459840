<div
    id="claimAgeDashboard"
    *ngIf="data"
    style="min-height: 795px; padding: 8px; overflow: auto; position: relative;"
    fxLayout="row"
>
    <div
        fxFlex
        fxLayout="column"
    >
        <div
            id="claimAgeHeader"
            class="dash-header"
        >
            <span>{{'ClaimAgeBand.ClaimsByAgeBand' | translate}}</span>
            <button
                id="claimAgeHeaderBtn"
                mat-icon-button
                style="float: right"
                (click)="showFilter = !showFilter"
                *ngIf="!isFilterOptionsLoading"
            >
                <app-filter-button
                    [isClientName]='true'
                    [isEntity]='true'
                    [isPolicyType]='true'
                    [isPlan]='true'
                    [isClaimType]='true'
                    [isClaimType2]='true'
                    [isClaimType3]='true'
                    [isProviderType]='true'
                    [isGender]='true'
                    [isRelationship]='true'
                    [isAgeBand]='true'
                    [isLocation]='true'
                    [isLOB]='true'
                    [isSubLOB]='true'
                ></app-filter-button>
            </button>
        </div>
        <div
            id="claimAgeHeaderNote"
            fxLayout="row"
        >
            <div
                id="claimAgeHeaderNoteCurrency"
                class="upperNote"
                fxFlex="35"
            >
                {{ 'ClaimAgeBand.AllAmountsDisplayedInLocalCurrency' | translate }}
            </div>
            <div
                *ngIf="data"
                id="claimAgeSelectUpper"
                class="upperNoteShown"
                [style.visibility]='data.shownAsPerLowerTableSelectedRow !="" ? "visible" : "hidden" '
                fxFlex="55"
            >
                {{ 'ClaimAgeBand.ShownAsPer' | translate }} &nbsp; "{{data.shownAsPerLowerTableSelectedRow}}"
            </div>
            <div
                id="claimAgeHeaderPeriod"
                class="upperNote"
                fxFlex="10"
            >
                {{ currentTimePeriodLable }} {{comparablePeriodLabel}}
            </div>
        </div>
        <tableau-spinner
            *ngIf="isLoadingStackChart"
            style="position: absolute; top: 180px; left: 48%; z-index: 90"
        ></tableau-spinner>
        <div>
            <ng-container
                id="claimAgeStackChart"
                *ngIf="parentLoaded"
            >
                <div fxLayout="row">
                    <div [fxFlex]="100">
                        <app-column-stack-age
                            (actionStack)="selectAge($event)"
                            [categories]="categories"
                            [dataList]="stackBarList"
                        ></app-column-stack-age>
                    </div>
                </div>
            </ng-container>
        </div>
        <tableau-spinner
            *ngIf="isLoadingBottomTable"
            style="position: absolute; top: 480px; left: 48%; z-index: 90"
        ></tableau-spinner>

        <div
            fxLayout="row"
            class="gridpadding"
        >
            <div
                id="claimAgeTable"
                [fxFlex]="100"
            >
                <div
                    id="claimAgeTableHeader"
                    fxLayout="row"
                    class="tableheader"
                >
                    <div [fxFlex]="dataTableSelected ? 20 :30">{{dataTableColumn | translate}}</div>
                    <div
                        *ngIf="dataTableSelected"
                        [fxFlex]="20"
                    >
                        {{'ClaimAgeBand.ClaimsPMPM' | translate}}
                    </div>
                    <div [fxFlex]="dataTableSelected ? 20 :18">{{'ClaimAgeBand.TotalPaidClaim' | translate}}</div>
                    <div
                        *ngIf="!dataTableSelected"
                        [fxFlex]="6"
                    ></div>
                    <div [fxFlex]="20">
                        {{properties.claimTypeOption == ClaimantOption.Claim ? ('ClaimAgeBand.ClaimCount' | translate
                        ):('ClaimAgeBand.ClaimantCount' | translate ) }}
                    </div>
                    <div
                        *ngIf="!dataTableSelected"
                        [fxFlex]="3"
                    ></div>
                    <div [fxFlex]="20">
                        {{properties.claimTypeOption == ClaimantOption.Claim ? ('ClaimAgeBand.CostPerClaim' | translate
                        ) :('ClaimAgeBand.CostPerClaimant' | translate )}}
                    </div>
                    <div
                        *ngIf="!dataTableSelected"
                        [fxFlex]="3"
                    ></div>
                </div>
                <div
                    fxLayout="row"
                    class="tablerow dashed"
                    *ngFor="let finalTopListBindRecord of data.finalTopListBind"
                    (click)="selectTableRow($event, finalTopListBindRecord)"
                    [ngClass]="[((data.lowerTableSelectedRow == finalTopListBindRecord.dimension  && finalTopListBindRecord.dimension != 'Total')? 'grayRow' : ''),(finalTopListBindRecord.dimension == 'Total' ? 'boldTotal' : '') ,(dataTableSelected ? '' :'padNoneTableRow')]"
                >
                    <div
                        [fxFlex]="dataTableSelected ? 20 :30"
                        class="claimPad"
                    >
                        {{((properties.dataDiagPro !=
                        DataDiagProOption.DataTable)&&(finalTopListBindRecord.claimantCount
                        <
                            properties.claimantFilterValue
                            ))
                            ? 'NA'
                            :
                            (finalTopListBindRecord.dimension)
                            }}
                            </div
                        >
                            <div
                                *ngIf="dataTableSelected"
                                [fxFlex]="20"
                            >
                                {{finalTopListBindRecord.pmpm | roundToDecimalPlace: (properties.country === 'Chile' ? 2
                                : 0) | numberFormat}}
                            </div>
                            <div
                                [fxFlex]="20"
                                *ngIf="dataTableSelected"
                            >
                                {{finalTopListBindRecord.claimsTotal | roundToDecimalPlace: 0 | numberFormat}}
                            </div>
                            <div
                                [fxFlex]="18"
                                *ngIf="!dataTableSelected"
                            >
                                <ng-container *ngIf="parentLoaded">
                                    <app-bar
                                        [barColor]="barColorPurple"
                                        [value]="finalTopListBindRecord.claimsTotal"
                                        [max]="maxClaimTotal"
                                        [fullTotalForPercentage]="topProviderTotal"
                                        [unit]="'MaxAndFullTotalForPercentage'"
                                        [valueDecimalPlace]="4"
                                        [country]="properties.country"
                                    ></app-bar>
                                </ng-container>
                            </div>
                            <div
                                [fxFlex]="6"
                                *ngIf="!dataTableSelected"
                            ></div>
                            <div
                                [fxFlex]="20"
                                *ngIf="dataTableSelected"
                            >
                                {{properties.claimTypeOption == ClaimantOption.Claim ?
                                (finalTopListBindRecord.claimsCount | roundToDecimalPlace: 0 | numberFormat) :
                                (finalTopListBindRecord.claimantCount | roundToDecimalPlace: 0 | numberFormat) }}
                            </div>
                            <div
                                [fxFlex]="20"
                                *ngIf="!dataTableSelected"
                            >
                                <ng-container *ngIf="parentLoaded">
                                    <app-bar
                                        [barColor]="barColorGray"
                                        [value]="properties.claimTypeOption == ClaimantOption.Claim ? finalTopListBindRecord.claimsCount : finalTopListBindRecord.claimantCount"
                                        [max]="maxCount"
                                        [unit]="unitCost"
                                        [valueDecimalPlace]="0"
                                        [country]="properties.country"
                                    ></app-bar>
                                </ng-container>
                            </div>
                            <div
                                [fxFlex]="3"
                                *ngIf="!dataTableSelected"
                            ></div>
                            <div
                                [fxFlex]="20"
                                *ngIf="dataTableSelected"
                            >
                                {{properties.claimTypeOption == ClaimantOption.Claim ?
                                (finalTopListBindRecord.averageCostPerClaim | roundToDecimalPlace: (properties.country
                                === 'Chile' ? 2 : 0) | numberFormat) : (finalTopListBindRecord.averageCostPerClaimant |
                                roundToDecimalPlace: (properties.country === 'Chile' ? 2
                                : 0) | numberFormat)}}
                            </div>
                            <div
                                [fxFlex]="20"
                                *ngIf="!dataTableSelected"
                            >
                                <ng-container>
                                    <app-bar
                                        [barColor]="barColorBlue"
                                        [value]="properties.claimTypeOption == ClaimantOption.Claim ? finalTopListBindRecord.averageCostPerClaim  : finalTopListBindRecord.averageCostPerClaimant"
                                        [max]="maxClaimCost"
                                        [unit]="unitCost"
                                        [valueDecimalPlace]="0"
                                        [country]="properties.country"
                                    ></app-bar>
                                </ng-container>
                            </div>
                            <div
                                [fxFlex]="3"
                                *ngIf="!dataTableSelected"
                            ></div>
                    </div>
                </div>
            </div>
            <div fxLayout="row">
                <div
                    [fxFlex]="35"
                    style="padding: 3px"
                ></div>
                <div
                    *ngIf="data"
                    id="claimAgeSelectLower"
                    fxFlex="45"
                    class="upperNoteShown"
                    [style.visibility]='data.shownAsPerUpperStackedSelectedBar !="" ? "visible" : "hidden" '
                >
                    {{ 'ClaimAgeBand.ShownAsPer' | translate }} &nbsp; "{{data.shownAsPerUpperStackedSelectedBar}}"
                </div>
                <div
                    id="claimAgeFooterNote"
                    [fxFlex]="20"
                    style="text-align: right; margin-right: 10px;"
                >
                    {{properties.claimPaid === CLAIM_BASIS.PAID_BASIS ? ( 'ClaimAgeBand.ClaimsReportedByPaidDate' |
                    translate ) : (
                    'ClaimAgeBand.ClaimsReportedByIncurredDate' | translate )}}
                </div>
            </div>
        </div>
        <div
            style="width: 200px; padding: 40px 0px 0px 16px; border-left: 1px solid #EEE"
            fxLayout="column"
        >
            <div
                id="entityDivisionFilters"
                *ngIf="showFilter"
            >
                <app-additional-filters
                    [country]="properties.country"
                    (action)="additionalFilterSelectionChanged($event)"
                    [filterOptions]='filterOptions'
                    [isClientName]='true'
                    [isEntity]='true'
                    [isPolicyType]='true'
                    [isPlan]='true'
                    [isClaimType]='true'
                    [isClaimType2]='true'
                    [isClaimType3]='true'
                    [isProviderType]='true'
                    [isGender]='true'
                    [isRelationship]='true'
                    [isAgeBand]='true'
                    [isLocation]='true'
                    [isLOB]='true'
                    [isSubLOB]='true'
                ></app-additional-filters>
            </div>
            <div
                id="claimAgeFilters"
                fxFlex="55"
                *ngIf="!showFilter"
            >
                <div
                    *ngIf="((properties.dataAgeSplit == AgeBandSplitOption.Gender) || (properties.dataAgeSplit == AgeBandSplitOption.Relationship))">
                    <div
                        id="claimAgeBtnShowLegend"
                        fxLayout="row"
                    >
                        <span>{{'ClaimAgeBand.ShowLegend' | translate}}</span>
                        <button
                            mat-icon-button
                            style="float: right;margin-top: -10px;"
                            (click)="properties.isLegendShown  = !properties.isLegendShown"
                        >
                            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                        </button>
                    </div>
                    <div
                        id="claimAgeLegends"
                        *ngIf="properties && properties.isLegendShown"
                    >
                        <div
                            fxLayout="row"
                            *ngFor="let leg of legendList"
                            style="margin-bottom:5px;"
                        >
                            <svg width="15"  height="15">
                                <rect width="15"
                                     height="15"
                                    [attr.fill]="leg.color"
                                ></rect>
                            </svg>
                            <span class="legendElp">{{leg.name}}</span> <br>
                        </div>
                    </div>
                </div>
                <div
                    id="claimAgeTxtAgeBandSplit"
                    fxLayout="row"
                    style="margin-top:10px; margin-bottom: 5px;"
                >
                    <span>{{'ClaimAgeBand.AgeBandSplit' | translate}}</span>
                </div>
                <div
                    id="claimAgeBtnNone"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{'dash-option-selected': properties.dataAgeSplit == AgeBandSplitOption.AgeBand}"
                    (click)="selectionChanged(AgeBandSplitOption.AgeBand, 'dataAgeSplit')"
                >
                    <span>{{'ClaimAgeBand.None' | translate}}</span>
                </div>

                <div
                    id="claimAgeBtnGender"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{'dash-option-selected': properties.dataAgeSplit == AgeBandSplitOption.Gender}"
                    (click)="selectionChanged(AgeBandSplitOption.Gender, 'dataAgeSplit')"
                >
                    <span>{{'ClaimAgeBand.Gender' | translate}}</span>
                </div>
                <div
                    id="claimAgeBtnRelationship"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{'dash-option-selected': properties.dataAgeSplit == AgeBandSplitOption.Relationship}"
                    (click)="selectionChanged(AgeBandSplitOption.Relationship, 'dataAgeSplit')"
                >
                    <span>{{'ClaimAgeBand.Relationship' | translate}}</span>
                </div>
                <div
                    id="claimAgeTxtCountBasis"
                    fxLayout="row"
                    style="margin-top:10px; margin-bottom: 5px;"
                >
                    <span>{{'ClaimAgeBand.CountBasis' | translate}}</span>
                </div>
                <div
                    id="claimAgeBtnClaim"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{'dash-option-selected': properties.claimTypeOption == ClaimantOption.Claim}"
                    (click)="selectionForClaimAndClaimantChanged(ClaimantOption.Claim, 'claimTypeOption')"
                >
                    <span>{{'ClaimAgeBand.Claim' | translate}}</span>
                </div>
                <div
                    id="claimAgeBtnClaimant"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{'dash-option-selected': properties.claimTypeOption == ClaimantOption.Claimant}"
                    (click)="selectionForClaimAndClaimantChanged(ClaimantOption.Claimant, 'claimTypeOption')"
                >
                    <span>{{'ClaimAgeBand.Claimant' | translate}}</span>
                </div>
                <div *ngIf="properties.dataDiagPro != DataDiagProOption.DataTable">
                    <div
                        id="claimAgeTxtSort"
                        fxLayout="row"
                        style="margin-top:10px; margin-bottom: 5px;"
                    >
                        <span>{{'ClaimAgeBand.Sort' | translate}}</span>
                    </div>
                    <div
                        id="claimAgeBtnClaimsTotal"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.sort == SortOption.ClaimTotal}"
                        (click)="filterChanged(SortOption.ClaimTotal, 'sort')"
                    >
                        <span>{{ 'ClaimAgeBand.ClaimsTotal' | translate }}</span>
                    </div>
                    <div
                        id="claimAgeCountBtnCount"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': (properties.sort == SortOption.CountClaim) || (properties.sort == SortOption.CountClaimant)}"
                        (click)="filterChanged( properties.claimTypeOption == ClaimantOption.Claim ? 'claimsCount': 'claimantCount', 'sort')"
                    >
                        <span>{{ 'ClaimAgeBand.Count' | translate }}</span>
                    </div>
                    <div
                        id="claimAgeBtnAvgCost"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': (properties.sort == 'averageCostPerClaim') || (properties.sort == 'averageCostPerClaimant')}"
                        (click)="filterChanged( properties.claimTypeOption == ClaimantOption.Claim ? 'averageCostPerClaim' : 'averageCostPerClaimant', 'sort')"
                    >
                        <span>{{ 'ClaimAgeBand.AverageCost' | translate }}</span>
                    </div>
                </div>
                <div
                    id="claimAgeBtnDisplay"
                    fxLayout="row"
                    style="margin-top:10px; margin-bottom: 5px;"
                >
                    <span>{{'ClaimAgeBand.Display' | translate}}</span>
                </div>
                <div
                    id="claimAgeBtnDataTable"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{'dash-option-selected': properties.dataDiagPro == DataDiagProOption.DataTable}"
                    (click)="selectionChanged(DataDiagProOption.DataTable, 'dataDiagPro')"
                >
                    <span>{{'ClaimAgeBand.DataTable' | translate}}</span>
                </div>
                <div
                    id="claimAgeBtnTopDiag"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{'dash-option-selected': properties.dataDiagPro == DataDiagProOption.TopDiagnoses}"
                    (click)="selectionChanged(DataDiagProOption.TopDiagnoses, 'dataDiagPro')"
                >
                    <span>{{'ClaimAgeBand.TopDiagnoses' | translate}}</span>
                </div>
                <div
                    id="claimAgeBtnTopProv"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{'dash-option-selected': properties.dataDiagPro == DataDiagProOption.TopProviders}"
                    (click)="selectionChanged(DataDiagProOption.TopProviders, 'dataDiagPro')"
                >
                    <span>{{'ClaimAgeBand.TopProviders' | translate}}</span>
                </div>
                <div
                    id="claimAgeFilterNATxt"
                    fxLayout="row"
                    style="margin-top:10px; margin-bottom: 5px;"
                    *ngIf="properties.dataDiagPro != DataDiagProOption.DataTable"
                >
                    <span>{{'ClaimAgeBand.FilterNA' | translate}}</span>
                </div>
                <div
                    id="claimAgeBtnFilterNA"
                    *ngIf="properties.dataDiagPro != DataDiagProOption.DataTable"
                    style="margin-top:5px; "
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    style="margin-top:5px;margin-bottom: 5px;"
                    (click)="filterNAChange('filterNA', 'filterNA')"
                    class="dash-option"
                    [ngClass]="{'dash-option-selected': properties.isExcludeNA === true }"
                >
                    <span>{{ 'ClaimAgeBand.FilterNA' | translate }}</span>
                </div>
            </div>
        </div>
    </div>