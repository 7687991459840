import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'app/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class RouteAuthGuardGuard implements CanActivate {

  constructor(private router: Router, private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    var user = this.userService.user;
    if (user) {
      const role = this.userService.getUserRole();

      if(route.data.params) {
        route.data.params.forEach(p => {
          var val = route.params[p.key];
          if(p.value == val) {
            if (p.roles && p.roles.indexOf(role) === -1) {
              this.userService.logout();
              return false;
            }
          }
        });
      } else if (route.data.roles && route.data.roles.indexOf(role) === -1) {
        this.userService.logout();
        return false;
      }
      return true;
    }
    this.userService.logout();
    return false;
  }
}
