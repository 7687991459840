export const locale = {
    lang: 'es-mx',
    data: {
        'CoMorbidity': {
            'CoMorbidityHeading':'CO-MORBILIDADES',
            'CoMorbidity':'Co-morbilidades',            
            'AssociatedCo': 'Co-morbilidades asociadas' ,
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'Diabetes':'Diabetes',          
            'Hyperlipidemia' : 'Hiperlipidemia', 
            'Hypertension':'Hipertensión',
            'Claimants' : 'Reclamantes',
            'AverageAge' : 'Edad Promedio',
            'Years':'años',
            'Female' : 'Femenino',            
            'Male' : 'Masculino',
            'Gender' : 'Género',
            'ClaimType': 'Tipo de Reclamación',
            'TotalCost': 'Total de Trámites',
            'PercentageOfTotalClaims': '% total de reclamaciones'
        }
}
};
