import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../_reducers';
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import { takeUntil } from 'rxjs/operators';

import { languages } from 'app/shared/languages';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as navigationEnglish } from 'app/layout/components/toolbar/i18n/en';
import { locale as navigationSpanishCL } from 'app/layout/components/toolbar/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/layout/components/toolbar/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/layout/components/toolbar/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/layout/components/toolbar/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/layout/components/toolbar/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/layout/components/toolbar/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/layout/components/toolbar/i18n/it';
import { TranslateService } from '@ngx-translate/core';
import { UserIdleService } from 'angular-user-idle';

import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { Router } from "@angular/router";
import { CartService } from 'app/services/cart.service';
import { MatDialog } from '@angular/material/dialog';
import { UseroptionsComponent } from 'app/login/useroptions/useroptions.component';
import { UseroptionsDialogComponent } from 'app/login/useroptions/useroptions-dialog/useroptions-dialog.component';

import { environment } from 'environments/environment';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    user: User;
    clientName = '';
    cartItems: any[];
    profile: any;
    isExternalUser: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _cartService: CartService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _userIdleService: UserIdleService,
        private _router: Router,
        private store: Store<State>,
        public userService: UserService,
        public dialog: MatDialog,
    ) {
        this.profile = environment.profile;

        // Set the defaults
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = languages;

        var defaultSelectedLanguage = this._translateService.currentLang;
        this.selectedLanguage = this.languages.find(function (language) {
            return language.id == defaultSelectedLanguage;
        });

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._fuseTranslationLoaderService.loadTranslations(
          navigationEnglish,
          navigationSpanishCL, 
          navigationSpanishCO,
          navigationSpanishEC,
          navigationSpanishMX,
          navigationSpanishPE,
          navigationPortugueseBR,
          navigationItalianIT,
        );

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        this.store.select('user').subscribe(res => {
            this.user = res;
            this.isExternalUser = this.user.email === this.user.userName;
            if (this.user) {
                this._translateService.currentLang = this.user.language;
                this.clientName = this.user.client ? this.user.client.name : '';
            }
        });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { 'id': this._translateService.currentLang });

        this._cartService.getObservableItems().subscribe(cartItems => {
            console.log(cartItems);
            this.cartItems = cartItems;
        });

        this.userService.userChanged.subscribe(user => {
            console.log('user updated', user);
            if (user) {
                this.user = user;
                this.isExternalUser = this.user.email === this.user.userName;
                this.clientName = this.user.client ? this.user.client.name : '';
            }
        });

        this._userIdleService.onTimeout().subscribe(() => {
            console.log('User session timeout');
            this._userIdleService.stopWatching();
            this.logout();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);

        this.userService.updateLanguage(lang.id);
    }

    viewCart() {
        console.log('viewCart');
        this._router.navigate(['/export/cart']);
    }

    viewOptions() {
        const dialogRef = this.dialog.open(UseroptionsDialogComponent, {
            minWidth: '60vh',
            minHeight: '350px'
        });
    }

    viewChangePassword() {
        const dialogRef = this.dialog.open(ChangePasswordComponent, {
            minWidth: '60vh',
            minHeight: '350px'
        });
    }

    logout() {
        this.userService.logout();
    }
}
