export const locale = {
    lang: 'es-mx',
    data: {
        'ExecutiveSummary': {          
            'ExecutiveSummary': 'Resumen Ejecutivo',            
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'GeneralTrend': 'Tendencia General',
            'Rolling12Basis': 'Continuos 12 Básicos',
            'CurrentPMPM': 'Gasto mensual x asegurado', 
            'PriorPMPM' : 'Gasto mensual x asegurado anterior', 
            'OverallTrend' : 'Tendencia General', 
            'AverageCost': 'Var. Costo promedio',
            'Utilization': 'Utilización',
            'ServicesReceived': 'Servicios Recibidos',
            'PlanPerformance': 'Plan de Rendimiento',
            'YearToDateBasis': 'Año completo a la fecha (completas) básico',
            'YearToDateCBasis': 'Año completo a la fecha (comparable) básico',
            'ClaimsReportedByPaidDate' :  'Reclamaciones reportadas por fecha de pago',
            'ClaimsReportedByIncurredDate' :  'Reclamaciones informadas por fecha incurrida',
            'LossRatio': 'Siniestralidad',
            'Headcount': 'TOTAL ASEGURADOS',
            'Claims': 'MONTO PAGADO',
            'Premium': 'PRIMAS',            
            'CombinedLossRatio':'Siniestralidad Combinada',
            'ClaimLabel': 'TRAMITES + GASTOS',
            'PremimumLabel':'PRIMAS',
            'DateRange': 'to'
        }
    }
};
