export const locale = {
    lang: 'en',
    data: {
        'DistributionByClaimType': {
            'TotalPaidClaim' : 'Total Paid Claim',
            'ClaimCount' : 'Claim Count',
            'AverageCost' : 'Average Cost',
            'Dimensions' : 'Dimension',
            'AgeBand' : 'Age Band',
            'Gender' : 'Gender',
            'Relationship' : 'Relationship',
            'AllAmountsDisplayedInLocalCurrency': 'All amounts displayed in local currency',
            'ClaimsReportedByPaidDate' :  'Claims reported by paid date',
            'ClaimsReportedByIncurredDate' :  'Claims reported by incurred date',
            'ClaimTypeBy': 'Claim Type By',
            'DateRange': 'to'
         }
    }
};















