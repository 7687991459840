<div fxFlexFill fxLayout="column">
  <h1 class="text-mono">Offline Tableau</h1>
  <div fxLayout="row">
    {{client?.name}} {{client? ':' : ''}} {{country?.name}}
  </div>
  <div fxLayout="row" style="width: 100%">
    <span fxFlex></span>
    <a [disabled]="isLoadingQueue || isLoadingDownload" mat-button class="mat-primary" target="_blank"
      href="https://www.tableau.com/products/reader/download">
      Tableau Reader
    </a>
    &nbsp;&nbsp;
    <a [disabled]="isLoadingQueue || isLoadingDownload" mat-button class="mat-primary" target="_blank"
      href="/assets/offline/TableauOffline_Secure.zip">
      AonPulse Offline Tool
    </a>
    <mat-spinner *ngIf="isLoadingQueue || isLoadingDownload" class="mat-accent" diameter="30"></mat-spinner>
    &nbsp;
    <button *ngIf="isSubmit" [disabled]="isLoadingQueue || isLoadingDownload" mat-raised-button class="mat-accent"
      (click)="submit()">
      Submit
    </button>
  </div>
  <div style="max-height: 400px; overflow-y: scroll">
    <agcustom-grid #qGrid [dataList]="offlineQueue" [columnDefs]="queueColDefs">
    </agcustom-grid>
  </div>
</div>