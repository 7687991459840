<aon-summary-card [height]="cardheight" [width]="cardwidth">
    <aon-summary-card-header>
        <aon-h3 style="color: #001b5a;"> {{ title }}</aon-h3>
    </aon-summary-card-header>
    <aon-summary-card-content top="70px">

        <fwb-error-message [isError]="isError" [message]="errorMsg"></fwb-error-message>
        <div *ngIf="!isError">
            <div style="margin-top: 3px;">
                <aon-horizontal-bar-chart [data]="inputSeries" [xaxistitle]="legendTitle" [width]="width"
                    [height]="height" [displayicons]="displayicons" [xScaleMax]="xScaleMax">
                </aon-horizontal-bar-chart>
            </div>
        </div>
    </aon-summary-card-content>
</aon-summary-card>