export const locale = {
    lang: 'it',
    data: {
  'AvgCostUtil': {
    'AverageCostImpact': 'Impatto costo medio',
    'UtilizationImpact': "Impatto sull'utilizzo",
    'AllAmountsDisplayedInLocalCurrency': 'Tutti gli importi visualizzati in valuta locale',
    'OverallTrend': 'Trend generale',
    'AverageCost': 'Costo medio',
    'Utilization': 'Utilizzo',
    'FreqOfUse': 'Frequenza di utilizzo',
    'ServicesReceived': 'Prestazioni erogate',
    'KeyImpacts': 'Impatti chiave',
    'AverageCostPerClaim': 'Costo medio per sinistro',
    'Prior': 'precedente',
    'Current': 'Attuale',
    'YoYChange': 'Cambia YoY',
    'Benchmark': 'Benchmark',
    'ClaimType': 'Sinistri oer assistito attivo',
    'Network': 'Rete Convenzionata',
    'ProviderType': 'Tipo di Struttura',
    'Relationship': 'Relazione',
    'CostPerClaim': 'Costo per sinistro',
    'CostPerClaimant': 'Costo per assistito attivo',
    'ClaimsPerClaimant': 'Sinistri per assistito attivo',
    'UtilizationRatio': 'Rapporto di utilizzo',
    'ClaimsReportedByPaidDate': 'Sinistri per data di pagamento',
    'ClaimsReportedByIncurredDate': 'Sinistri per data di accadimento',
    'IncludeClient': 'Includere cliente',
    'ExcludeClient': 'Escludi cliente',
    'Portfolio': 'Portafoglio',
    'Industry': 'Industria',
    'None': 'Nessuno',
    'Chart': 'Grafico',
    'Table': 'Tabella',
    'ShowLegend': 'Mostra legenda',
    'DateRange': 'Intervallo Di Date'
  }
}};