export const locale = {
	lang: 'pt-br',
	data: {
		'DistributionByClaimType': {
			'TotalPaidClaim': 'Total de Sinistro',
			'ClaimCount': 'Contagem de Sinistros',
			'AverageCost': 'Custo Médio',
			'Dimensions': 'Dimensão',
			'AgeBand': 'Faixa Etária',
			'Gender': 'Gênero',
			'Relationship': 'Relacionamento',
			'AllAmountsDisplayedInLocalCurrency': 'Todos os valores estão em moeda local',
			'ClaimsReportedByPaidDate': 'Sinistro Reportado por Data de Pagamento',
			'ClaimsReportedByIncurredDate': 'Sinistro Reportado por Data de Atendimento',
			'ClaimTypeBy': 'Tipo de Evento por',
			'DateRange': 'to'
		}
	}
};