export const locale = {
    lang: 'es-mx',
    data: {
        'EnrollmentOverTime': {
            'EnrollmentOverTimeHeading' : 'Asegurados por periodo',
            'Male' : 'Masculino',
            'Female' : 'Femenino',
            'NA' : 'ON',
            'AgeBand' : 'Rango de Edad',
            'Dimensions' : 'Dimensión',
            'Gender' : 'Género',
            'Relationship' : 'Parentesco',
            'IsEmployee' : 'EE vs Dep',
            'Employee' : 'Asegurado',
            'Dependent' : 'Dependiente',
            'Monthly' : 'Por Mes',
            'YearToDateBasis' : 'Año completo a la fecha (completas) básico',
            'YearToDateBasisC' : 'Año completo a la fecha (C) básico',
            'Rolling3Basis' : 'Continuos 3 Básicos',
            'Rolling6Basis' : 'Continuos 6 Básicos',
            'Rolling9Basis' : 'Continuos 9 Básicos',
            'Rolling12Basis' : 'Continuos 12 Básicos',
            'ClaimsReportedByPaidDate' :  'Reclamaciones reportadas por fecha de pago',
            'ClaimsReportedByIncurredDate' :  'Reclamaciones informadas por fecha incurrida',
            'DateRange':'to'
        }
}
};
