import { CLAIM_BASIS } from './basis.model';

export class GlobalFiltersModel {
    clientName: string;
    clientCountry: string;
    language: string;
    claimType: string;
    rollingPeriod: number;
    periodSelection: number;
    claimPaid: CLAIM_BASIS;
    tbsUserId: string;
    tbsClientId: string;

    maxDate: string;
    maxClaimPaidDate: string;
    maxAdmitDate: string;
    period1StartDate: string;
    period2StartDate: string;
    period3StartDate: string;
    period4StartDate: string;
}