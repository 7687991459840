<div  class="columnDiv contentPadding">
  <mat-card id="superAdminRegionalMang" fxLayout="column">
    <aon-h2>Cache Log Managment</aon-h2>
    <div fxLayout="row">
      <br />
    </div>
    <div fxLayout="row">
      <div fxFlex></div>
      <aon-button-default [disabled] ="" width='auto' (click)="triggerCacheClick()" id='btn-prominent'>Trigger Cache</aon-button-default>    
    </div>
    <div fxLayout="row">
      <br />
    </div>
    <div style="overflow: hidden; min-height: 600px">
      <agcustom-grid [dataList]="cacheList"
      [columnDefs]="cacheColumnDefs">
    </agcustom-grid>
    </div>
  </mat-card>
</div>
