export const locale = {
	lang: 'pt-br',
	data: {
		'DiseaseDetail': {
			'DiseaseDetailHeading': 'Predominância de Doenças',
			'AllAmountInLocalCurrency': 'Todos os valores estão em moeda local',
			'SelectTop': 'Selecionar Maiores',
			'Diagnosis': 'Diagnóstico',
			'ClaimsTotalHeader': 'Sinistro Total',
			'ClaimantTotalHeader': 'Contagem de Usuário',
			'DiagnosisLevel': 'Nível de Diagnóstico',
			'Category': 'Categoria',
			'Chapter': 'Capítulo',
			'ClaimsTotal': 'Sinistro Total',
			'DigClaimantCount': 'Contagem de Usuário',
			'DiseaseProgression': 'Progressão de Doença por Tempo',
			'Age': 'Idade',
			'CostPerClaimant': 'Custo Por Usuário',
			'CostPerClaim': 'Custo Por Sinistro',
			'ClaimsPerClaimant': 'Sinistro Por Usuário',
			'TotalCost': 'Custo Total',
			'ClaimantCount': 'Contagem de Usuário',
			'Prevalence': 'Prevalência',
			'Benchmark': 'Referência',
			'IncludeClient': 'Incluir Cliente',
			'ExcludeClient': 'Excluir Cliente',
			'Portfolio': 'Portfólio',
			'Industry': 'Operadora',
			'None': 'Nenhum',
			'AgeBand': 'Faixa Etária',
			'Gender': 'Gênero',
			'Relationship': 'Relacionamento',
			'ClaimType': 'Tipo de Sinistro',
			'ClaimsReportedByPaidDate': 'Sinistro Reportado por Data de Pagamento',
			'ClaimsReportedByIncurredDate': 'Sinistro Reportado por Data de Atendimento',
			'ShownAsPer': 'Mostrar como exemplo',
			'DateRange': 'to',
			'DiagnosisGroup': 'Grupo de diagnóstico',
			'Actionable': 'Tratáveis ou Crônicas',
			'Chronic': 'Chronic',
			'Infectious': 'Infectious',
			'Trauma/Accidents': 'Trauma/Accidents',
			'Cancer': 'Cancer',
			'Mental Health': 'Mental Health',
			'Obstetrics': 'Obstetrics',
			'Others': 'Others',
			'Preventable': 'Preventable',
			'Intervenable': 'Intervenable'
		}
	}
};