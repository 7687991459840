<div style="display: inline-block; width: 100%;">
    <svg height="20px " style="overflow: visible;">
        <g class="bartext">
            <text font-size="14px" transform="translate(0, 15)">
                <tspan *ngIf="MeasureOption != 'Number'" [attr.x]="maleTextCordinates()">{{getMalePercentText()}}
                </tspan>
                <tspan *ngIf="MeasureOption == 'Number'" [attr.x]="maleTextCordinates()">
                    {{getMalePercentText() | roundToDecimalPlace: 0 | numberFormat}} </tspan>
            </text>
            <rect height="20" [attr.width]="getWidth()" [attr.fill]="'#a585ba'" [attr.x]='maleCordinates()' y='0'>
            </rect>
            <line [attr.transform]="getBenchPos()" [attr.x1]="lineCordinates()" y1="0" [attr.x2]="lineCordinates()"
                y2="32" stroke-dasharray="2" style='stroke:#72727252; stroke-width:1;' />
            <line *ngIf="isBenchmarkShown && MeasureOption != 'Number'" [attr.x1]="leftBenchmarkXCoordinates()" transform="translate(0, -5)" y1="0" [attr.x2]="leftBenchmarkXCoordinates()"
                y2="30" stroke-dasharray="2" style='stroke:rgb(255,0,0); stroke-width:1;' />
            <text *ngIf="isBenchmarkShown && MeasureOption != 'Number' && benchmarkLeftValue > 0" transform="translate(-35, 0)" [attr.x]="leftBenchmarkXCoordinates()" y="27" style="fill:rgb(255,0,0);font-size: 10px">{{benchmarkLeftValue | percent:'1.0-1'}}</text>
        </g>
        <g class="bartext">
            <rect height="20" [attr.width]="getWidth2()" [attr.fill]="'#c9cac8'" [attr.x]="lineCordinates()"></rect>
            <text font-size="14px" transform="translate(5, 15)">
                <tspan *ngIf="MeasureOption != 'Number'" [attr.x]="femaleTextCordinates()">{{getFemalePercentText()}}
                </tspan>
                <tspan *ngIf="MeasureOption == 'Number'" [attr.x]="femaleTextCordinates()">
                    {{getFemalePercentText() | roundToDecimalPlace: 0 | numberFormat}} </tspan>
            </text>
            <line *ngIf="isBenchmarkShown && MeasureOption != 'Number'" [attr.x1]="rightBenchmarkXCoordinates()" transform="translate(0, -5)" y1="0" [attr.x2]="rightBenchmarkXCoordinates()" 
            y2="30" stroke-dasharray="2" style='stroke:rgb(255,0,0); stroke-width:1;' />
            <text *ngIf="isBenchmarkShown && MeasureOption != 'Number'" transform="translate(5, 0)" [attr.x]="rightBenchmarkXCoordinates()" y="27" style="fill:rgb(255,0,0);font-size: 10px">{{benchmarkRightValue | percent:'1.0-1'}}</text>
        </g>
    </svg>
</div>