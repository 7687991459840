<div
    id="healthDashboard"
    style="min-height: 795px; padding: 8px; position: relative"
>
    <tableau-spinner
        *ngIf="isLoading"
        style="position: absolute; top: 280px; left: 48%; z-index: 90"
    ></tableau-spinner>
    <div fxFlex fxLayout="column">
        <div
            id="healthHeader"
            class="dash-header"
            *ngIf="this.properties.id == 'HealthProfile'"
        >
            {{ "HealthDiseaseProfile.HealthProfile" | translate }}
            <button
                id="healthHeaderBtn"
                mat-icon-button
                style="float: right"
                (click)="showFilter = !showFilter"
                *ngIf="!isFilterOptionsLoading"
            >
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
        </div>
        <div
            id="healthChronic"
            class="dash-header"
            *ngIf="
                this.properties.id != 'HealthProfile' &&
                this.properties.category
            "
        >
            {{ "HealthDiseaseProfile.Top
            Diseases" | translate }} ({{
                toTranslateFormat(this.properties.category)
            }})
            <button
                mat-icon-button
                style="float: right"
                (click)="showFilter = !showFilter"
                *ngIf="!isFilterOptionsLoading"
            >
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
        </div>
        <div
            id="healthChronic"
            class="dash-header"
            *ngIf="
                this.properties.id != 'HealthProfile' &&
                !this.properties.category
            "
        >
            {{ "HealthDiseaseProfile.Top
            Diseases" | translate }}
            <button
                mat-icon-button
                style="float: right"
                (click)="showFilter = !showFilter"
                *ngIf="!isFilterOptionsLoading"
            >
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
        </div>
        <!-- <div id="healthInfectious" class="dash-header" *ngIf="this.properties.name != 'Health Profile' &&  this.properties.isChronic == 'Infectious'">{{'HealthDiseaseProfile.TopInfectiousDiseases' | translate}}
            <button id="healthInfectiousBtn" mat-icon-button style="float: right" (click)="showFilter = !showFilter" *ngIf="!isFilterOptionsLoading">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
        </div>
        <div id="healthTop" class="dash-header" *ngIf="this.properties.name != 'Health Profile' &&   this.properties.isChronic != 'Infectious' && this.properties.isChronic != 'Chronic'">{{'HealthDiseaseProfile.TopOtherDiseases' | translate}}
            <button id="healthTopBtn" mat-icon-button style="float: right" (click)="showFilter = !showFilter" *ngIf="!isFilterOptionsLoading">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
        </div> -->
        <div
            id="healthTitle"
            fxLayout="row"
            fxFlex="10"
            style="font-size: 20px"
        >
            <div fxFlex="15"></div>
            <div
                id="healthPaid"
                fxFlex
                class="colheader"
                fxLayoutAlign="center center"
            >
                <img
                    src="{{ imageUrlBase }}/assets/aonicons/Edit-M.png"
                    style="height: 44px"
                /><span>{{
                    "HealthDiseaseProfile.PaidClaim" | translate
                }}</span>
            </div>
            <div
                id="healthAvg"
                fxFlex="15"
                class="colheader"
                fxLayoutAlign="center center"
            >
                <img
                    src="{{ imageUrlBase }}/assets/aonicons/Finance-M.png"
                    style="height: 60px"
                /><span>{{
                    "HealthDiseaseProfile.AverageCost" | translate
                }}</span>
            </div>
            <div
                id="healthClaimantCount"
                fxFlex="15"
                class="colheader"
                fxLayoutAlign="center center"
            >
                <img
                    src="{{ imageUrlBase }}/assets/aonicons/Team-M.png"
                    style="height: 44px"
                /><span>{{
                    "HealthDiseaseProfile.ClaimantCount" | translate
                }}</span>
            </div>
            <div
                id="healthPrevalence"
                fxFlex
                class="colheader"
                fxLayoutAlign="center center"
            >
                <img
                    src="{{ imageUrlBase }}/assets/aonicons/Global-M.png"
                    style="height: 44px; padding-left: 2px"
                /><span>{{
                    "HealthDiseaseProfile.Utilization" | translate
                }}</span>
            </div>
        </div>
        <!-- <div id="healthTable" > -->
        <div
            fxLayout="row"
            *ngFor="let cat of categories"
            style="border-top: 1px solid #ccc; height: 75px"
        >
            <div
                class="{{
                    properties.id == 'HealthProfile' ? 'clickable' : ''
                }}"
                fxFlex="15"
                class="colheader"
                fxLayout="column"
                fxLayoutAlign="{{
                    properties.id == 'HealthProfile'
                        ? 'center center'
                        : 'center left'
                }}"
                (click)="navigateToDiseaseProfile(cat.name)"
            >
                <span
                    style="font-size: 16px"
                    *ngIf="properties.id == 'HealthProfile'"
                    >{{ toTranslateFormat(cat.name) }}</span
                >
                <span
                    style="font-size: 12px"
                    *ngIf="properties.id != 'HealthProfile'"
                    >{{ toTranslateFormat(cat.name) }}</span
                >
            </div>
            <div
                fxFlex
                fxLayout="column"
                fxLayoutAlign="center center"
                style="border-right: 1px dotted lightgray; padding: 0px 2px"
            >
                <div fxLayout="row">
                    <div fxLayout="column">
                        <div style="padding: 0px">
                            <charts-bar-solo
                                style="width: 100%"
                                [height]="barHeight"
                                [frontSize]="barFontSize"
                                [value]="
                                    cat.current.paidClaim == null ||
                                    cat.current.paidClaim == 0
                                        ? 0
                                        : cat.current.paidClaim
                                "
                                [country]="properties.country"
                                [value2]="
                                    cat.current.totalClaims > 0
                                        ? cat.current.totalClaims
                                        : 0
                                "
                                [max]="
                                    cat.current.totalClaims >
                                    cat.prior.totalClaims
                                        ? cat.current.totalClaims
                                        : cat.prior.totalClaims
                                "
                                [unit]="activeClaimsUnit"
                                [barColor]="'#101E7F'"
                                [valueDecimalPlace]="5"
                            >
                            </charts-bar-solo>
                        </div>
                        <div style="padding: 0px">
                            <charts-bar-solo
                                style="width: 100%"
                                [height]="barHeight"
                                frontSize="barFontSize"
                                [value]="
                                    cat.prior.paidClaim == null ||
                                    cat.prior.paidClaim == 0
                                        ? 0
                                        : cat.prior.paidClaim
                                "
                                [country]="properties.country"
                                [value2]="
                                    cat.prior.totalClaims > 0
                                        ? cat.prior.totalClaims
                                        : 0
                                "
                                [max]="
                                    cat.current.totalClaims >
                                    cat.prior.totalClaims
                                        ? cat.current.totalClaims
                                        : cat.prior.totalClaims
                                "
                                [unit]="activeClaimsUnit"
                                [barColor]="'#29B0C3'"
                                [valueDecimalPlace]="5"
                            >
                            </charts-bar-solo>
                        </div>
                    </div>
                    <div
                        fxLayout="column"
                        style="padding: 8px; text-align: center"
                    >
                        <span
                            style="
                                font-size: 16px;
                                font-weight: bold;
                                color: #f25d00;
                            "
                            >{{ claimsBenchmarks[cat.name] | percent }}</span
                        >
                    </div>
                </div>
            </div>
            <div fxFlex="15" fxLayout="column" fxLayoutAlign="center center">
                <div style="font-weight: bold; font-size: 20px; color: #101e7f">
                    {{
                        cat.current.averageCost
                            | roundToDecimalPlace
                                : (properties.country === "Chile" ? 2 : 0)
                            | numberFormat
                    }}
                </div>
                <div style="font-weight: bold; font-size: 20px; color: #29b0c3">
                    {{
                        cat.prior.averageCost
                            | roundToDecimalPlace
                                : (properties.country === "Chile" ? 2 : 0)
                            | numberFormat
                    }}
                </div>
            </div>
            <div fxFlex="15" fxLayout="column" fxLayoutAlign="center center">
                <div style="font-weight: bold; font-size: 20px; color: #101e7f">
                    {{
                        cat.current.claimantCount
                            | roundToDecimalPlace: 0
                            | numberFormat
                    }}
                </div>
                <div style="font-weight: bold; font-size: 20px; color: #29b0c3">
                    {{
                        cat.prior.claimantCount
                            | roundToDecimalPlace: 0
                            | numberFormat
                    }}
                </div>
            </div>
            <div
                fxFlex
                fxLayout="column"
                fxLayoutAlign="center center"
                style="border-right: 1px dotted lightgray; padding: 0px 2px"
            >
                <div fxLayout="row" fxLayoutAlign="left center">
                    <div fxLayout="column">
                        <div style="padding: 0px">
                            <charts-bar-solo
                                style="width: 100%"
                                [height]="barHeight"
                                [value]="
                                    cat.current.prevalence == null ||
                                    cat.current.prevalence == 0
                                        ? 0
                                        : (
                                              cat.current.prevalence * 100
                                          ).toFixed(0)
                                "
                                [country]="properties.country"
                                [value2]="100"
                                [benchmark]="
                                    utilizationBenchmarks[cat.name] * 100
                                "
                                [max]="100"
                                [unit]="percentUnit"
                                [barColor]="'#101E7F'"
                            ></charts-bar-solo>
                        </div>
                    </div>
                    <div
                        fxLayout="column"
                        style="padding: 8px; text-align: center"
                    >
                        <span
                            style="
                                font-size: 16px;
                                font-weight: bold;
                                color: #f25d00;
                            "
                            >{{
                                utilizationBenchmarks[cat.name] | percent
                            }}</span
                        >
                        <span fxFlex></span>
                        <span
                            style="
                                font-size: 16px;
                                font-weight: bold;
                                color: #29b0c3;
                            "
                            >{{ cat.prior.prevalence | percent }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div id="healthFooterNote" fxLayout="row">
            <span fxFlex></span>
            {{
                properties.claimPaid === CLAIM_BASIS.PAID_BASIS ||
                apacCountries.includes(properties.country)
                    ? ("HealthDiseaseProfile.ClaimsReportedByPaidDate"
                      | translate)
                    : ("HealthDiseaseProfile.ClaimsReportedByIncurredDate"
                      | translate)
            }}
        </div>
    </div>
    <div
        style="
            width: 200px;
            padding: 80px 0px 0px 16px;
            border-left: 1px solid #eee;
        "
        fxLayout="column"
    >
        <div id="healthLegends">
            <svg width="15" height="15">
                <rect width="15" height="15" fill="#101E7F"></rect>
            </svg>
            {{ currentDateRange }} {{ comparable }}<br />
            <svg width="15" height="15">
                <rect width="15" height="15" fill="#29B0C3"></rect>
            </svg>
            {{ priorDateRange }} {{ comparable }}<br />

            <ng-container
                *ngIf="
                    eligibleForBenchmark && properties.id === 'HealthProfile'
                "
            >
                <svg width="15" height="15" style="margin-top: 19px">
                    <rect width="15" height="15" fill="#F25D00"></rect>
                </svg>
                <span> Benchmark</span>
                <br />
            </ng-container>
        </div>
        <div style="margin-top: 10px" *ngIf="showFilter">
            <app-additional-filters
                id="entityDivisionFilters"
                [country]="properties.country"
                (action)="additionalFilterSelectionChanged($event)"
                [filterOptions]="filterOptions"
                [isClientName]="true"
                [isEntity]="true"
                [isAgeBand]="true"
                [isRelationship]="true"
                [isClaimType]="true"
                [isGender]="true"
            ></app-additional-filters>
        </div>
        <div style="margin-top: 10px" *ngIf="!showFilter">
            <div
                id="prevalDiagGrps"
                style="margin-top: 20px"
                *ngIf="this.properties.id !== 'HealthProfile'"
            >
                <div
                    id="topDiseaseBtnCategory"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{
                        'dash-option-selected':
                            properties.diag == CategoryChapterOption.DiagCat
                    }"
                    (click)="
                        categoryOptionChanged(
                            CategoryChapterOption.DiagCat,
                            'diag'
                        )
                    "
                >
                    <span
                        >{{ "HealthDiseaseProfile.Category" | translate }}
                    </span>
                </div>
                <div
                    id="topDiseaseBtnChapter"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{
                        'dash-option-selected':
                            properties.diag == CategoryChapterOption.DiagChapter
                    }"
                    (click)="
                        categoryOptionChanged(
                            CategoryChapterOption.DiagChapter,
                            'diag'
                        )
                    "
                >
                    <span
                        >{{ "HealthDiseaseProfile.Chapter" | translate }}
                    </span>
                </div>
                <div
                    id="CategorySelection"
                    style="margin-top: 20px; max-width: 160px"
                    *ngIf="properties.diag == CategoryChapterOption.DiagChapter"
                >
                    <pulse-select
                        [selection]="selectedCategoryOptionList"
                        placeholder="{{
                            'HealthDiseaseProfile.Category' | translate
                        }}"
                        [options]="categoryOptionList"
                        (selectionChanged)="
                            filterSelectionChanged($event, 'diagCatsOptions')
                        "
                        [hidden]="isExport"
                    >
                    </pulse-select>
                    <div [hidden]="!isExport">
                        <span>{{
                            "HealthDiseaseProfile.Category" | translate
                        }}</span>
                        <br />
                        <span *ngIf="selectedCategoryOptionList.length > 0">{{
                            selectedCategoryOptionList.join("||")
                        }}</span>
                        <span *ngIf="selectedCategoryOptionList.length == 0"
                            >All</span
                        >
                    </div>
                </div>
                <div
                    id="topDiseaseSort"
                    fxLayout="row"
                    style="margin-top: 10px; margin-bottom: 5px"
                >
                    <span>{{ "HealthDiseaseProfile.Sort" | translate }}</span>
                </div>
                <div
                    id="topDiseaseSortClaimsTotal"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{
                        'dash-option-selected':
                            properties.sort == SortOption.ClaimTotal
                    }"
                    (click)="filterChanged(SortOption.ClaimTotal, 'sort')"
                >
                    <span>{{
                        "HealthDiseaseProfile.PaidClaim" | translate
                    }}</span>
                </div>
                <div
                    id="topDiseaseSortAverageCost"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{
                        'dash-option-selected':
                            properties.sort == SortOption.AverageCost
                    }"
                    (click)="filterChanged(SortOption.AverageCost, 'sort')"
                >
                    <span>{{
                        "HealthDiseaseProfile.AverageCost" | translate
                    }}</span>
                </div>
                <div
                    id="topDiseaseSortClaimsTotal"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{
                        'dash-option-selected':
                            properties.sort == SortOption.ClaimantCount
                    }"
                    (click)="filterChanged(SortOption.ClaimantCount, 'sort')"
                >
                    <span>{{
                        "HealthDiseaseProfile.ClaimantCount" | translate
                    }}</span>
                </div>
                <div [hidden]="isExport">
                    <mat-form-field style="width: 100%">
                        <mat-label>{{
                            "HealthDiseaseProfile.DiagnosisGroup" | translate
                        }}</mat-label>
                        <mat-select
                            placeholder="{{
                                'HealthDiseaseProfile.DiagnosisGroup'
                                    | translate
                            }}"
                            [(ngModel)]="this.diagGrps"
                            [ngModelOptions]="{ standalone: true }"
                        >
                            <mat-option
                                [value]="p"
                                *ngFor="let p of diagGroupOptionList"
                                (click)="
                                    onfilterSelectionChanged(p, 'diagGrps')
                                "
                            >
                                <span>{{ toTranslateFormat(p) }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div [hidden]="!isExport">
                    <span>{{
                        "HealthDiseaseProfile.DiagnosisGroup" | translate
                    }}</span>
                    <br />
                    <span *ngIf="this.diagGrps == ''">All</span>
                    <span *ngIf="this.diagGrps !== ''">{{
                        this.diagGrps
                    }}</span>
                </div>
            </div>
            <div
                id="prevalActionables"
                style="margin-top: 20px; max-width: 160px"
            >
                <pulse-select
                    [selection]="selectedActionableOptionList"
                    placeholder="{{
                        'HealthDiseaseProfile.Actionable' | translate
                    }}"
                    [options]="translatedActionableOptionList"
                    (selectionChanged)="
                        filterSelectionChanged($event, 'actionables')
                    "
                    [hidden]="isExport"
                >
                </pulse-select>
                <div [hidden]="!isExport">
                    <span>{{
                        "HealthDiseaseProfile.Actionable" | translate
                    }}</span>
                    <br />
                    <span *ngIf="selectedActionableOptionList.length > 0">{{
                        selectedActionableOptionList.join("||")
                    }}</span>
                    <span *ngIf="selectedActionableOptionList.length == 0"
                        >All</span
                    >
                </div>
            </div>
        </div>
    </div>
</div>
