import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RetirementService {

  constructor(private httpClient: HttpClient) { }

  getDrilldown(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/retirement/drilldown?'+params)
  }

  getReplacement(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/retirement/replacement?'+params)
  }

  getScenario(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/retirement/scenarios?'+params)
  }

  getSummary(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/retirement/summary?'+params)
  }

}
