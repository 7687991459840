export const locale = {
  lang: 'es-ec',
  data: {
    'ageBand': {
      'AgeBandHeading': 'Enrolamiento por rango de edad',
      'Benchmark': 'Benchmark',
      'IncludeClient': 'Incluir Cliente',
      'ExcludeClient': 'Excluir Cliente',
      'Portfolio': 'Portafolio',
      'Industry': 'Industria',
      'None': 'Ninguna',
      'MemberCount': 'Total de afiliados',
      'AverageAge': 'Edad promedio',
      'AverageFamilySize': 'Promedio de Miembros por familia',
      'RelationshipDistribution': 'Distribucion de parentesco',
      'Split': 'División',
      'PctOfTotalClient': '% del Total de Afiliados',
      'EnrollmentTrend': 'Tendencia de Afiliados',
      'Male': 'Masculino',
      'Female': 'Femenino',
      'PctOfPopulation': 'de población',
      'Employee': 'Empleado',
      'Child': 'Niño',
      'Sibling': 'Hermana',
      'Dimensions': 'Dimension',
      'Gender': 'Género',
      'Relationship': 'Parentesco',
      'Measure': 'Medida',
      'Number': 'Numero',
      'PctDistribution': '% Del Total',
      'Dependent': 'Dependiente',
      'DateRange': 'to',
      'Others': 'Otros',
    }
  }
};
