<aon-summary-card [height]="cardheight" [width]="cardwidth">
    <aon-summary-card-header>
        <aon-h3 style="color: #001b5a;">
          <fa-icon style="padding-right: 5px;" [icon]="['fal', 'sitemap']"></fa-icon>
          {{ charttitle }}
        </aon-h3>
    </aon-summary-card-header>
    <aon-summary-card-content top="70px">
        <div style="text-align: left;">
            <aon-p-lg>
                See how your financial scores are distributed in the organization.
            </aon-p-lg>
        </div>
        <div>
            <div class="marginTop10">
                <table style="width: 100%; border-collapse: collapse;">
                    <thead>
                      <tr>
                        <th (click)="toggleSort('buname')" style="width: 60%; padding-left: 15px; cursor: pointer;">
                            Business Unit <fa-icon size="1x" [icon]="sortIcon" [styles]="{'stroke': '#696969', 'color': '#696969'}"></fa-icon>
                        </th>
                        <th (click)="toggleSort('financialscore')" style="width: 40%; cursor: pointer;">
                            Financial Score <fa-icon size="1x" [icon]="sortIcon" [styles]="{'stroke': '#696969', 'color': '#696969'}"></fa-icon>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of buTableData">
                        <td style="padding-left: 15px;">{{ data.buName }}</td>
                        <td >{{ data.financialscore }}</td>
                      </tr>
                    </tbody>
                  </table>
            </div>
        </div>
    </aon-summary-card-content>
</aon-summary-card>