export const locale = {
    lang: 'es-pe',
    data: {
        'ClaimCostDiag': {
            'ClaimDiagnosis': 'Tipo de atención v Diagnóstico',
            'SelectTop': 'Seleccione Top',
            'AllAmountsDisplayedInLocalCurrency': 'Moneda local',
            'ClaimType': 'Tipo de atención',
            'TotalPaidClaim': 'Pago total de siniestro',
            'ClaimantCount': 'Cuenta de Paciente',
            'ClaimCount': 'Número de atenciones',
            'ClaimsPerClaimant': 'Siniestros por paciente',
            'CostPerClaim': 'Costo por atención',
            'CostPerClaimant': 'Costo por paciente',
            'ChangeInCost': '% Variación del costo',
            'Claim': 'Atención',
            'Claimant': 'Paciente',
            'ClaimsTotal': 'Total de siniestros',
            'Count': 'Contar',
            'ClaimsReportedByPaidDate': 'Siniestros informados por fecha de ocurrencia',
            'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
            'CountBasis': 'Contar Base',
            'Sort': 'Ordenar',
            'Show': 'Mostrar',
            'Hide': 'Ocultar',
            'ShowHide': 'Mostrar / ocultar',
            'DiagnosisLevel': 'Nivel de diagnóstico',
            'Category': 'Categoría',
            'Chapter': 'Agrupador',
            'ShownAsPer': 'Se muestra según',
            'TopDiagnoses': 'Top Diagnósticos',
            'AverageCost': 'Costo Promedio',
            'FilterNA': 'Excluir NA',
            'DateRange': 'to'         
        }
    }
};
