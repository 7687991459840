<div
    id="exeRowDashboard"
    style="min-height: 795px; padding: 8px; overflow: auto; position: relative"
>
    <tableau-spinner
        *ngIf="isLoading"
        style="position: absolute; top: 280px; left: 48%; z-index: 90"
    ></tableau-spinner>
    <div fxLayout="row">
        <div fxFlex fxLayout="column">
            <div id="exeRowHeader" class="dash-header">
                {{ "ExecutiveSummary.ExecutiveSummary" | translate }}
                <button
                    id="exeRowHeaderBtn"
                    mat-icon-button
                    style="float: right"
                    (click)="showFilter = !showFilter"
                    *ngIf="!isFilterOptionsLoading"
                >
                    <app-filter-button
                        [isClientName]="true"
                        [isEntity]="true"
                        [isPolicyType]="true"
                    ></app-filter-button>
                </button>
            </div>
            <ng-container
                id="exeRowLeftDash"
                *ngIf="!isLoading && current && prior"
            >
                <div
                    id="exeRowCurreny"
                    style="font-size: 14px; font-style: italic; padding: 3px"
                >
                    {{
                        "ExecutiveSummary.AllAmountsDisplayedInLocalCurrency"
                            | translate
                    }}
                </div>
                <!--
                    <div id="exeRow1HalfHeading1" class="dash-section-header" *ngIf="properties.rollingPeriod == 2">{{'ExecutiveSummary.GeneralTrend' | translate}}: {{'ExecutiveSummary.YearToDateBasis' | translate}} ({{topDateRange}})</div>
                <div id="exeRow1HalfHeading2" class="dash-section-header" *ngIf="properties.rollingPeriod == 3">{{'ExecutiveSummary.GeneralTrend' | translate}}: {{'ExecutiveSummary.YearToDateCBasis' | translate}} ({{topDateRange}})</div>
                <div id="exeRow1HalfHeading3" class="dash-section-header" *ngIf="properties.rollingPeriod != 2 && properties.rollingPeriod != 3">{{'ExecutiveSummary.GeneralTrend' | translate}}: {{'ExecutiveSummary.Rolling12Basis' | translate}} ({{topDateRange}})</div>
                -->
                <div
                    id="exeRow1HalfHeading1"
                    class="dash-section-header"
                    *ngIf="properties.rollingPeriod == 2"
                >
                    {{ "ExecutiveSummary.GeneralTrend" | translate }}:
                    {{ "ExecutiveSummary.YearToDateBasis" | translate }} ({{
                        topDateFrom
                    }}
                    {{ "ExecutiveSummary.DateRange" | translate }}
                    {{ topDateTo }})
                </div>
                <div
                    id="exeRow1HalfHeading2"
                    class="dash-section-header"
                    *ngIf="properties.rollingPeriod == 3"
                >
                    {{ "ExecutiveSummary.GeneralTrend" | translate }}:
                    {{ "ExecutiveSummary.YearToDateCBasis" | translate }} ({{
                        topDateFrom
                    }}
                    {{ "ExecutiveSummary.DateRange" | translate }}
                    {{ topDateTo }})
                </div>
                <div
                    id="exeRow1HalfHeading3"
                    class="dash-section-header"
                    *ngIf="
                        properties.rollingPeriod != 2 &&
                        properties.rollingPeriod != 3
                    "
                >
                    {{ "ExecutiveSummary.GeneralTrend" | translate }}:
                    {{ "ExecutiveSummary.Rolling12Basis" | translate }} ({{
                        topDateFrom
                    }}
                    {{ "ExecutiveSummary.DateRange" | translate }}
                    {{ topDateTo }})
                </div>
                <div fxLayout="row">
                    <div fxLayout="column" style="padding: 20px 60px">
                        <br />
                        <div
                            id="exeRowCurrent"
                            class="dashbox"
                            fxLayout="column"
                        >
                            <div
                                id="exeRowCurrentTxt"
                                class="title"
                                style="background-color: #101e7f"
                            >
                                {{ "ExecutiveSummary.CurrentPMPM" | translate }}
                            </div>
                            <div
                                id="exeRowCurrentVal"
                                fxFlex
                                fxLayoutAlign="center center"
                                class="bignum"
                                style="color: #101e7f"
                            >
                                {{
                                    current.pmpm
                                        | roundToDecimalPlace
                                            : (properties.country === "Chile"
                                                  ? 2
                                                  : 0)
                                        | numberFormat
                                }}
                            </div>
                        </div>
                        <br />
                        <div id="exeRowPrior" class="dashbox" fxLayout="column">
                            <div
                                id="exeRowPriorTxt"
                                class="title"
                                style="background-color: #474a5e"
                            >
                                {{ "ExecutiveSummary.PriorPMPM" | translate }}
                            </div>
                            <div
                                id="exeRowPriorVal"
                                fxFlex
                                fxLayoutAlign="center center"
                                class="bignum"
                                style="color: #474a5e"
                            >
                                {{
                                    prior.pmpm
                                        | roundToDecimalPlace
                                            : (properties.country === "Chile"
                                                  ? 2
                                                  : 0)
                                        | numberFormat
                                }}
                            </div>
                        </div>
                    </div>
                    <div id="exeRowOverAll" fxFlex fxLayout="column">
                        <br />
                        <div
                            id="exeRowTrend"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            style="margin-bottom: -50px"
                        >
                            <div
                                style="
                                    color: white;
                                    line-height: 26px;
                                    text-align: center;
                                    background: #101e7f;
                                    z-index: 20;
                                    border-radius: 18px;
                                    height: 90px;
                                    width: 38%;
                                    padding: 18px;
                                "
                            >
                                <span style="font-size: 20px">{{
                                    "ExecutiveSummary.OverallTrend" | translate
                                }}</span
                                ><br />
                                <span style="font-size: 40px; font-weight: bold"
                                    >{{
                                        overallImpact * 100
                                            | roundToDecimalPlace: 1
                                    }}%</span
                                >
                            </div>
                        </div>
                        <div id="exeRowPaths" fxLayout="row">
                            <div
                                fxFlex
                                fxLayoutAlign="center center"
                                style="position: relative"
                            >
                                <svg
                                    height="90"
                                    width="200"
                                    viewbox="0 0 200 200"
                                    preseveAspectRatio="none"
                                    style="background: white; z-index: 20"
                                >
                                    <path
                                        d="M100,200 V50 C100,50 100,5 150,5 H200"
                                        fill="none"
                                        stroke="#CCC"
                                        stroke-dasharray="8"
                                        stroke-width="2"
                                    ></path>
                                </svg>
                                <svg
                                    height="90"
                                    width="100%"
                                    viewbox="0 0 100 100"
                                    style="position: absolute"
                                >
                                    <line
                                        x1="50%"
                                        y1="5"
                                        x2="100%"
                                        y2="5"
                                        fill="none"
                                        stroke="#CCC"
                                        stroke-dasharray="8"
                                        stroke-width="2"
                                    />
                                </svg>
                            </div>
                            <div
                                fxFlex
                                fxLayoutAlign="center center"
                                style="position: relative"
                            >
                                <svg
                                    height="90"
                                    width="200"
                                    viewbox="0 0 200 200"
                                    preseveAspectRatio="none"
                                >
                                    <path
                                        d="M100,0 V200"
                                        fill="none"
                                        stroke="#CCC"
                                        stroke-dasharray="8"
                                        stroke-width="2"
                                    />
                                </svg>
                            </div>
                            <div
                                fxFlex
                                fxLayoutAlign="center center"
                                style="position: relative"
                            >
                                <svg
                                    height="90"
                                    width="200"
                                    viewbox="0 0 200 200"
                                    style="
                                        transform: rotateY(180deg);
                                        background: white;
                                        z-index: 20;
                                    "
                                >
                                    <path
                                        d="M100,200 V50 C100,50 100,5 150,5 H200"
                                        fill="none"
                                        stroke="#CCC"
                                        stroke-dasharray="8"
                                        stroke-width="2"
                                    />
                                </svg>
                                <svg
                                    height="90"
                                    width="100%"
                                    viewbox="0 0 100 100"
                                    style="position: absolute"
                                >
                                    <line
                                        x1="0"
                                        y1="5"
                                        x2="50%"
                                        y2="5"
                                        fill="none"
                                        stroke="#CCC"
                                        stroke-dasharray="8"
                                        stroke-width="2"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div
                                fxFlex
                                fxLayoutAlign="center center"
                                fxLayout="column"
                            >
                                <div
                                    id="exeRowAvg"
                                    class="click-area"
                                    style="
                                        color: #474a5e;
                                        position: relative;
                                        text-align: center;
                                    "
                                    fxLayoutAlign="center center"
                                    (click)="
                                        navigateTo(
                                            Components.AverageCost,
                                            'Average Cost Impact',
                                            'AverageCost'
                                        )
                                    "
                                >
                                    <app-radialshape
                                        [points]="6"
                                        [fill]="'#CCC'"
                                    ></app-radialshape>
                                    <div
                                        style="
                                            z-index: 20;
                                            position: absolute;
                                            line-height: 30px;
                                        "
                                    >
                                        <span
                                            style="
                                                font-size: 36px;
                                                font-weight: bold;
                                            "
                                            >{{
                                                averageCostImpact * 100
                                                    | roundToDecimalPlace: 1
                                            }}%</span
                                        ><br />
                                        <span style="font-size: 20px">{{
                                            "ExecutiveSummary.AverageCost"
                                                | translate
                                        }}</span
                                        ><br />
                                    </div>
                                </div>
                                <svg id="exeRowAvgSh" height="30" width="200">
                                    <defs>
                                        <radialGradient id="grad1">
                                            <stop
                                                offset="0%"
                                                style="stop-color: #aaa"
                                            />
                                            <stop
                                                offset="60%"
                                                style="stop-color: white"
                                            />
                                        </radialGradient>
                                    </defs>
                                    <ellipse
                                        cx="100"
                                        cy="15"
                                        rx="100"
                                        ry="25"
                                        fill="url(#grad1)"
                                    />
                                </svg>
                            </div>
                            <div
                                fxFlex
                                fxLayoutAlign="center center"
                                fxLayout="column"
                            >
                                <div
                                    id="exeRowUtil"
                                    class="click-area"
                                    style="
                                        color: #474a5e;
                                        position: relative;
                                        text-align: center;
                                    "
                                    fxLayoutAlign="center center"
                                    (click)="
                                        navigateTo(
                                            Components.AverageCost,
                                            'Utilization Impact',
                                            'Utilization'
                                        )
                                    "
                                >
                                    <app-radialshape
                                        [points]="6"
                                        [fill]="'#CCC'"
                                    ></app-radialshape>
                                    <div
                                        style="
                                            z-index: 20;
                                            position: absolute;
                                            line-height: 30px;
                                        "
                                    >
                                        <span
                                            style="
                                                font-size: 36px;
                                                font-weight: bold;
                                            "
                                            >{{
                                                utilizationImpact * 100
                                                    | roundToDecimalPlace: 1
                                            }}%</span
                                        ><br />
                                        <span style="font-size: 20px">{{
                                            "ExecutiveSummary.Utilization"
                                                | translate
                                        }}</span
                                        ><br />
                                    </div>
                                </div>
                                <svg id="exeRowUtilSh" height="30" width="200">
                                    <ellipse
                                        cx="100"
                                        cy="15"
                                        rx="100"
                                        ry="25"
                                        fill="url(#grad1)"
                                    />
                                </svg>
                            </div>
                            <div
                                fxFlex
                                fxLayoutAlign="center center"
                                fxLayout="column"
                            >
                                <div
                                    id="exeRowMix"
                                    style="
                                        color: #474a5e;
                                        position: relative;
                                        text-align: center;
                                    "
                                    fxLayoutAlign="center center"
                                >
                                    <app-radialshape
                                        [points]="6"
                                        [fill]="'#CCC'"
                                    ></app-radialshape>
                                    <div
                                        style="
                                            z-index: 20;
                                            position: absolute;
                                            line-height: 30px;
                                        "
                                    >
                                        <span
                                            style="
                                                font-size: 36px;
                                                font-weight: bold;
                                            "
                                            >{{
                                                mixOfServicesImpact * 100
                                                    | roundToDecimalPlace: 1
                                            }}%</span
                                        ><br />
                                        <span style="font-size: 20px">{{
                                            "ExecutiveSummary.ServicesReceived"
                                                | translate
                                        }}</span
                                        ><br />
                                    </div>
                                </div>
                                <svg id="exeRowMixSh" height="30" width="200">
                                    <ellipse
                                        cx="100"
                                        cy="15"
                                        rx="100"
                                        ry="25"
                                        fill="url(#grad1)"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 2nd half -->
                <!--
                <div id="exeRow2halfHeading1" class="dash-section-header" *ngIf="properties.rollingPeriod == 2">{{'ExecutiveSummary.PlanPerformance' | translate}}: {{'ExecutiveSummary.YearToDateBasis' | translate}} ({{bottomDateRange}})</div>
                <div id="exeRow2halfHeading2" class="dash-section-header" *ngIf="properties.rollingPeriod == 3">{{'ExecutiveSummary.PlanPerformance' | translate}}: {{'ExecutiveSummary.YearToDateCBasis' | translate}} ({{bottomDateRange}})</div>
                <div id="exeRow2halfHeading3" class="dash-section-header" *ngIf="properties.rollingPeriod != 2 && properties.rollingPeriod != 3">{{'ExecutiveSummary.PlanPerformance' | translate}}: {{'ExecutiveSummary.Rolling12Basis' | translate}} ({{bottomDateRange}})</div>
                -->
                <div
                    id="exeRow2halfHeading1"
                    class="dash-section-header"
                    *ngIf="properties.rollingPeriod == 2"
                >
                    {{ "ExecutiveSummary.PlanPerformance" | translate }}:
                    {{ "ExecutiveSummary.YearToDateBasis" | translate }} ({{
                        bottomDateFrom
                    }}
                    {{ "ExecutiveSummary.DateRange" | translate }}
                    {{ bottomDateTo }})
                </div>
                <div
                    id="exeRow2halfHeading2"
                    class="dash-section-header"
                    *ngIf="properties.rollingPeriod == 3"
                >
                    {{ "ExecutiveSummary.PlanPerformance" | translate }}:
                    {{ "ExecutiveSummary.YearToDateCBasis" | translate }} ({{
                        bottomDateFrom
                    }}
                    {{ "ExecutiveSummary.DateRange" | translate }}
                    {{ bottomDateTo }})
                </div>
                <div
                    id="exeRow2halfHeading3"
                    class="dash-section-header"
                    *ngIf="
                        properties.rollingPeriod != 2 &&
                        properties.rollingPeriod != 3
                    "
                >
                    {{ "ExecutiveSummary.PlanPerformance" | translate }}:
                    {{ "ExecutiveSummary.Rolling12Basis" | translate }} ({{
                        bottomDateFrom
                    }}
                    {{ "ExecutiveSummary.DateRange" | translate }}
                    {{ bottomDateTo }})
                </div>
                <div id="exeRow2Half" fxLayout="row" style="color: #474a5e">
                    <div
                        id="exeRowLossRatio"
                        style="
                            padding: 0px 40px;
                            position: relative;
                            height: 220px;
                        "
                    >
                        <br />
                        <div
                            style="
                                position: absolute;
                                bottom: 55px;
                                left: 140px;
                                font-size: 16px;
                                font-weight: bold;
                            "
                        >
                            {{ "ExecutiveSummary.LossRatio" | translate }}
                        </div>
                        <app-pie
                            [percentage]="
                                planPerformance
                                    ? (pieRatioData * 100
                                      | roundToDecimalPlace: 1)
                                    : 0
                            "
                        >
                        </app-pie>
                        <svg id="exeRowLossRatioShadow" height="30" width="300">
                            <ellipse
                                cx="150"
                                cy="15"
                                rx="150"
                                ry="25"
                                fill="url(#grad1)"
                            />
                        </svg>
                    </div>
                    <div
                        id="exeRowHeadcount"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="center center"
                    >
                        <img
                            src="{{
                                imageUrlBase
                            }}/assets/aonicons/People-Clients-M.png"
                            style="height: 100px"
                        />
                        <div style="font-size: 36px; font-weight: bold">
                            {{
                                planPerformance.headcount
                                    | roundToDecimalPlace: 0
                                    | numberFormat
                            }}
                        </div>
                        <span style="font-size: 20px">{{
                            "ExecutiveSummary.Headcount" | translate
                        }}</span>
                    </div>
                    <div
                        id="exeRowClaims"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="center center"
                        *ngIf="planPerformance"
                    >
                        <img
                            src="{{ imageUrlBase }}/assets/aonicons/Edit-M.png"
                            style="height: 100px"
                        />
                        <div
                            style="font-size: 36px; font-weight: bold"
                            *ngIf="properties.country !== 'Chile'"
                        >
                            {{
                                (properties.combinedLossRatio ==
                                LossCombined.CombinedLossRatio
                                    ? planPerformance.combinedClaims
                                    : planPerformance.claims
                                ) | toAbbrevNumberString: properties.country
                            }}
                        </div>
                        <div
                            style="font-size: 36px; font-weight: bold"
                            *ngIf="properties.country === 'Chile'"
                        >
                            {{
                                (properties.combinedLossRatio ==
                                LossCombined.CombinedLossRatio
                                    ? planPerformance.combinedClaims
                                    : planPerformance.claims
                                )
                                    | roundToDecimalPlace: 0
                                    | numberFormat
                            }}
                        </div>
                        <span style="font-size: 20px">{{
                            "ExecutiveSummary.Claims" | translate
                        }}</span>
                    </div>
                    <div
                        id="exeRowPremium"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="center center"
                        *ngIf="planPerformance"
                    >
                        <img
                            src="{{
                                imageUrlBase
                            }}/assets/aonicons/Savings-1.svg"
                            style="height: 100px"
                        />
                        <div
                            style="font-size: 36px; font-weight: bold"
                            *ngIf="properties.country !== 'Chile'"
                        >
                            {{
                                (properties.combinedLossRatio ==
                                LossCombined.CombinedLossRatio
                                    ? planPerformance.combinedPremium
                                    : planPerformance.premium
                                ) | toAbbrevNumberString: properties.country
                            }}
                        </div>
                        <div
                            style="font-size: 36px; font-weight: bold"
                            *ngIf="properties.country === 'Chile'"
                        >
                            {{
                                (properties.combinedLossRatio ==
                                LossCombined.CombinedLossRatio
                                    ? planPerformance.combinedPremium
                                    : planPerformance.premium
                                )
                                    | roundToDecimalPlace: 0
                                    | numberFormat
                            }}
                        </div>
                        <span style="font-size: 20px">{{
                            properties.combinedLossRatio ==
                            LossCombined.CombinedLossRatio
                                ? ("ExecutiveSummary.PremimumLabel" | translate)
                                : ("ExecutiveSummary.Premium" | translate)
                        }}</span>
                    </div>
                </div>
                <div id="exeRow2FooterNote" fxLayout="row">
                    <span fxFlex></span>
                    {{
                        properties.claimPaid === CLAIM_BASIS.PAID_BASIS
                            ? ("ExecutiveSummary.ClaimsReportedByPaidDate"
                              | translate)
                            : ("ExecutiveSummary.ClaimsReportedByIncurredDate"
                              | translate)
                    }}
                </div>
            </ng-container>
        </div>
        <!-- FILTERS -->
        <div
            style="
                width: 200px;
                padding: 80px 0px 0px 16px;
                border-left: 1px solid #eee;
            "
            fxLayout="column"
        >
            <div id="entityDivisionFilters" *ngIf="showFilter">
                <app-additional-filters
                    [country]="properties.country"
                    (action)="additionalFilterSelectionChanged($event)"
                    [filterOptions]="filterOptions"
                    [isClientName]="true"
                    [isEntity]="true"
                    [isPolicyType]="true"
                ></app-additional-filters>
            </div>
            <div
                id="exeRowFilters"
                fxFlex="55"
                *ngIf="!showFilter"
                style="margin-top: 420px"
            >
                <div
                    id="exeRowBtnLR"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{
                        'dash-option-selected':
                            properties.combinedLossRatio ==
                            LossCombined.LossRatio
                    }"
                    (click)="
                        filterChanged(
                            LossCombined.LossRatio,
                            'combinedLossRatio'
                        )
                    "
                >
                    <span>{{ "ExecutiveSummary.LossRatio" | translate }}</span>
                </div>
                <div
                    id="exeRowBtnCLR"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{
                        'dash-option-selected':
                            properties.combinedLossRatio ==
                            LossCombined.CombinedLossRatio
                    }"
                    (click)="
                        filterChanged(
                            LossCombined.CombinedLossRatio,
                            'combinedLossRatio'
                        )
                    "
                >
                    <span>{{
                        "ExecutiveSummary.CombinedLossRatio" | translate
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
