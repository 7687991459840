<div id="clientGroupComp" fxLayout="column" fxFlexFill fxLayoutGap="12px" class="contentPadding">
    <div fxLayout="row">
      <h1 id="clientGroupHeader" class="text-mono">{{ 'ClientGroupAdmin.ClientGroupAdministration' | translate }}</h1>
    </div>
    <div fxLayout="row" fxLayoutGap="12px">
      <mat-card id="clientGroupName" fxFlex="60">
        <div style="overflow: hidden">
          <div id="clientGroupAdminName" fxLayout="row">
            <p><strong>{{ 'ClientGroupAdmin.GroupName' | translate }}</strong></p>
            <span fxFlex></span>
            <mat-spinner *ngIf="!isClientGroupListLoaded" class="mat-accent" diameter="30"></mat-spinner>
            <button id="clientGroupBtnNewClient" mat-button (disabled)="!isClientGroupListLoaded" class="mat-accent fixed-height" (click)="onAddClientGroup()">
              {{ 'ClientGroupAdmin.NewClientGroup' | translate }}
            </button>
          </div>
          <div id="clientGroupNew" fxLayout="row" *ngIf="isAddingClientGroup">
            <mat-form-field id="clientGroupInputNew" fxFlex style="margin-right: 20px">
            <input matInput data-placeholder="{{ 'ClientGroupAdmin.NewClientGroupName' | translate }}" style="font-weight: bold" [(ngModel)]="newClientGroupName"
                [ngModelOptions]="{standalone: true}" autocomplete="off">
            </mat-form-field>
            <mat-spinner *ngIf="isSavingClientGroupName" diameter=40></mat-spinner>
            <button id="clientGroupBtnSave" mat-raised-button class="mat-accent fixed-height" style="width: 140px" [disabled]="newClientGroupName.trim().length == 0 || isSavingClientGroupName" (click)="onSaveAddedClientGroup()">
                {{ 'ClientGroupAdmin.Save' | translate }}
            </button>
          </div>
          <agcustom-grid id="clientGroupGrid" #storiesGrid [dataList]="clientGroupList" [columnDefs]="clientGroupColumnDefs" [delete]="true"
            (selectionChanged)="onClientGroupSelectionChanged($event)"
            (onDelete)="onDeleteClientGroup($event)">
          </agcustom-grid>
        </div>
      </mat-card>
      <mat-card id="clientGroupSelected"  fxFlex="20" *ngIf="selectedClientGroup">
        <div style="overflow: hidden">
          <div id="clientGroupClients" fxLayout="row">
            <h3 class="text-mono">{{ 'ClientGroupAdmin.SelectedClients' | translate }} ({{selectedClientList.length}})</h3>
            <span fxFlex></span>
            <button id="clientGroupBtnSaveClient" mat-button (disabled)="!isClientListLoaded" class="mat-accent fixed-height"
            (click)="onSaveGroupClient()">
            {{ 'ClientGroupAdmin.Save' | translate }}
            </button>
          </div>

          <div fxLayout="row">
            <mat-form-field id="clientGroupFilterClient" fxFlex style="margin-right: 20px">
                <input matInput data-placeholder="{{ 'ClientGroupAdmin.Search' | translate }}" style="font-weight: bold" [(ngModel)]="selectedClientFilterString"
                [ngModelOptions]="{standalone: true}" autocomplete="off" (ngModelChange)="onFilterSelectedClientList()">
            </mat-form-field>
          </div>

          <div fxLayout="row">
            <mat-action-list id="clientGroupFilterList" style="width: 100%">
              <button mat-list-item style="width: 100%; border-bottom: 1px solid #DDD" *ngFor="let filteredSelectedClient of filteredSelectedClientList"
                (click)="onUnselectGroupClient(filteredSelectedClient)"> {{filteredSelectedClient.name}} </button>
            </mat-action-list>
          </div>
        </div>
      </mat-card>
      <mat-card id="clientGroupUnselect" fxFlex="20" *ngIf="selectedClientGroup">
        <div style="overflow: hidden">
          <h3 class="text-mono">{{ 'ClientGroupAdmin.UnselectedClients' | translate }} ({{unselectedClientList.length}})</h3>
          <div fxLayout="row">
            <mat-form-field id="clientGroupInputClient" fxFlex style="margin-right: 20px">
                <input matInput data-placeholder="{{ 'ClientGroupAdmin.Search' | translate }}" style="font-weight: bold" [(ngModel)]="unselectedClientFilterString"
                [ngModelOptions]="{standalone: true}" autocomplete="off" (ngModelChange)="onFilterUnselectedClientList()">
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row">
          <mat-action-list id="clientGroupListUnselect" style="width: 100%">
            <button mat-list-item style="width: 100%; border-bottom: 1px solid #DDD"
              *ngFor="let filteredUnselectedClient of filteredUnselectedClientList"
              (click)="onSelectGroupClient(filteredUnselectedClient)"> {{filteredUnselectedClient.name}} </button>
          </mat-action-list>
        </div>
      </mat-card>
    </div>
  </div>
