export const locale = {
    lang: 'it',
    data: {
  'ClaimCostTopProvider': {
    'ClaimTopProviders': 'Costo dei sinistri - in base alle principali strutture sanitarie',
    'ClaimCostByTopDiagnoses': 'Costo dei sinistri - in base alle diagnosi principali',
    'ProviderDiag': 'Struttura vs Diagnosi',
    'AllAmountsDisplayedInLocalCurrency': 'Tutti gli importi visualizzati in valuta locale',
    'TopProvider': 'Strutture sanitarie top',
    'ProviderName': 'Nome Provider',
    'TopDiagnoses': 'diagnosi top',
    'DiagnosisName': 'Nome diagnosi',
    'TotalPaidClaim': 'Sinistri totali pagati',
    'ClaimantCount': 'Numero teste colpite',
    'ClaimCount': 'Conteggio Sinistri',
    'ClaimsPerClaimant': 'Sinistri per assistito attivo',
    'CostPerClaim': 'Costo per sinistro',
    'CostPerClaimant': 'Costo per assistito attivo',
    '%ChangeinCost': '% di variazione del costo',
    'Claim': 'Sinistri',
    'Claimant': 'Testa colpita',
    'ClaimsTotal': 'Sinistri totali',
    'Count': 'Conteggio',
    'AverageCost': 'Costo medio',
    'ClaimsReportedByPaidDate': 'Sinistri per data di pagamento',
    'ClaimsReportedByIncurredDate': 'Sinistri per data di accadimento',
    'Benchmark': 'Benchmark',
    'IncludeClient': 'Includere cliente',
    'ExcludeClient': 'Escludi cliente',
    'Portfolio': 'Portafoglio',
    'Industry': 'Industria',
    'None': 'Nessuno',
    'SelectTop': 'Seleziona top',
    'FilterNA': 'Escludi NA',
    'CountBasis': 'Base del conteggio',
    'Sort': 'Ordina',
    'Reset': 'Ripristina',
    'DiagnosisLevel': 'Livello di diagnosi',
    'Category': 'Categoria',
    'Chapter': 'Prestazione',
    'ShownAsPer': 'Indicato come per',
    'DateRange': 'Intervallo Di Date'
  }
}};