import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { WelloneConstant } from 'app/wellone/models/welloneconstant.model';
import { WellOneNotificationService } from 'app/wellone/services/well-one-notification.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  constructor(
    private wellOneNotificationService: WellOneNotificationService) { }

  private hubConnection: signalR.HubConnection;

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
                            .withUrl(environment.api + 'notificationHub')
                            .build();
    this.hubConnection
      .start()
      .then(() => { 
        console.log('Connection started'); 
        this.addNotifyingWellOneCacheRefreshListener();
        this.addStartingWellOneCacheRefreshListener();
        this.addCompletingWellOneCacheRefreshListener();
      })
      .catch(err => console.log('Error while starting connection: ' + err))
  }

  public addNotifyingWellOneCacheRefreshListener = () => {
    this.hubConnection.on(WelloneConstant.OnNotifyingWellOneCacheRefresh, () => {
      console.log(WelloneConstant.OnNotifyingWellOneCacheRefresh);
      this.wellOneNotificationService.onNotifyingWellOneCacheRefresh.emit();
    });
  }

  public addStartingWellOneCacheRefreshListener = () => {
    this.hubConnection.on(WelloneConstant.OnStartingWellOneCacheRefresh, () => {
      console.log(WelloneConstant.OnStartingWellOneCacheRefresh);
      this.wellOneNotificationService.onStartingWellOneCacheRefresh.emit();
    });
  }

  public addCompletingWellOneCacheRefreshListener = () => {
    this.hubConnection.on(WelloneConstant.OnCompletingWellOneCacheRefresh, () => {
      console.log(WelloneConstant.OnCompletingWellOneCacheRefresh);
      this.wellOneNotificationService.onCompletingWellOneCacheRefresh.emit();
    });
  }
}
