import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  message = '';
  title = 'Confirm';
  nobuttontext = 'No';
  isnobuttonvisible = true;
  yesbuttontext = 'Yes';
  isyesbuttonvisible = true;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public options: any) { 
      if (options.message) this.message = options.message;
      if (options.title) this.title = options.title;
      if (options.nobuttontext) this.nobuttontext = options.nobuttontext;
      if (typeof options.isnobuttonvisible !== 'undefined') this.isnobuttonvisible = options.isnobuttonvisible;
      if (options.yesbuttontext) this.yesbuttontext = options.yesbuttontext;
      if (typeof options.isyesbuttonvisible !== 'undefined') this.isyesbuttonvisible = options.isyesbuttonvisible;
    }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

