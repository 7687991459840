import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DashboardTemplateBase } from '../dashboard-template-base.component';
import { DataService } from 'app/dashboards/data.service';
import { HttpClient } from '@angular/common/http';
import { FilterOptions } from 'app/dashboards/shared/models/FilterOptions';
import { PulseEventName } from 'app/dashboards/shared/models/PulseEventName';
import { DimensionOrderService } from 'app/dashboards/dimension-order.service';
import { environment } from 'environments/environment';
import { locale as navigationEnglish } from 'app/dashboards/templates/claim-cost-top-provider/i18n/en';
import { locale as navigationSpanishCL } from 'app/dashboards/templates/claim-cost-top-provider/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/dashboards/templates/claim-cost-top-provider/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/dashboards/templates/claim-cost-top-provider/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/dashboards/templates/claim-cost-top-provider/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/dashboards/templates/claim-cost-top-provider/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/dashboards/templates/claim-cost-top-provider/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/dashboards/templates/claim-cost-top-provider/i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NumberFormattingService } from 'app/services/number-formatting.service';
import { APAC_COUNTRIES } from 'app/constants/countries_APAC';
import { CLAIM_BASIS } from 'app/models/basis.model';
@Component({
    selector: 'app-claim-cost-top-provider',
    templateUrl: './claim-cost-top-provider.component.html',
    styleUrls: ['./claim-cost-top-provider.component.css']
})
export class ClaimCostTopProviderComponent implements OnInit, DashboardTemplateBase {
    apacCountries = APAC_COUNTRIES
    isFilterOptionsLoading = true;
    SortOption = SortOption;
    ClaimantOption = ClaimantOption;
    PortfolioIndustryNoneOption = PortfolioIndustryNoneOption;
    CategoryChapterOption = CategoryChapterOption;
    data: any;
    properties: any;
    imageUrlBase = '';
    filterOptions: FilterOptions = new FilterOptions();
    topFilterDiag: number = 10;
    unitCost = '';
    maxCostClaim = 321000;
    maxCostClaimant = 321000;
    maxDiagCostClaim = 1;
    maxDiagCostClaimant = 1;
    isLoadingProvider = false;
    isLoadingDiagnoses = false;
    benchmarkClaimant = 2430;
    benchmarkClaim = 2430;
    showFilter: boolean = false;
    showBenchmark: boolean = false;
    filterNA: boolean = false;
    barColorPurple = '#101E7F';
    barColorBlue = '#A70070';
    finalTopListBindLoaded = false;
    finalTopListBindDiagLoaded = false;
    finalTopListBind: any = [];
    finalTopListBindDiag: any = [];
    totalDiagnosisofPaidCliams: any;
    totalProviderofCostClaim: any;
    totalProviderofCostClaimant: any;
    totalDiagnosisofCostClaim: any;
    totalDiagnosisofCostClaimant: any;
    datalist: any = [];
    datalistprovDiag: any = [];
    @Output() action = new EventEmitter<any>();
    benchwidthClaim: any;
    benchwidthClaimant: any;
    parentLoaded = false;
    currentTimePeriodLable = '';
    maxClaimTotal: number = 1;
    topProviderTotal: number = 1;
    barRectanglelement: HTMLElement;
    maxWidth: number = 250;
    localeSelected: string = 'en';
    comparablePeriod: string = '';
    eligibleForBenchmark = false;
    CLAIM_BASIS = CLAIM_BASIS;

    initData(): void {
        if (typeof this.data.shownAsPerCurrentProvider === 'undefined') {
            this.data.shownAsPerCurrentProvider = '';
        }

        if (typeof this.data.shownAsPerCurrentDiagnosis === 'undefined') {
            this.data.shownAsPerCurrentDiagnosis = '';
        }

        if (typeof this.data.currentProvider === 'undefined') {
            this.data.currentProvider = '';
        }

        if (typeof this.data.currentDiagnosis === 'undefined') {
            this.data.currentDiagnosis = '';
        }

        if (typeof this.data.currentContainerIsDiagnosis === 'undefined') {
            this.data.currentContainerIsDiagnosis = false;
        }

        if (typeof this.data.finalTopListBind === 'undefined') {
            this.data.finalTopListBind = [];

        }

        this.finalTopListBindLoaded = true;

        if (typeof this.data.finalTopListBindDiag === 'undefined') {
            this.data.finalTopListBindDiag = [];
        }

        this.finalTopListBindDiagLoaded = true;

        this.comparablePeriod =
            this.properties.rollingPeriod == 3 ? ' (C)' : '';
        if (this.properties.id === 'ProviderVDiagnosis') {

            if (typeof this.data.providerList.costPerClaimTotal !== 'undefined') {
                this.totalProviderofCostClaim = this.data.providerList.costPerClaimTotal;
            }

            if (typeof this.data.diagList.costPerClaimTotal !== 'undefined') {
                this.totalDiagnosisofCostClaim = this.data.diagList.costPerClaimTotal;
            }

            this.totalProviderofCostClaimant = this.data.providerList.costPerClaimantTotal;
            this.totalDiagnosisofCostClaimant = this.data.diagList.costPerClaimantTotal;
            if (
                this.currentTimePeriodLable == null ||
                this.currentTimePeriodLable === ''
            ) {
                if (this.data.providerList.current != null) {
                    if (this.data.providerList.currentPeriod.fromDateLabel === this.data.providerList.currentPeriod.toDateLabel) {
                        this.currentTimePeriodLable = this.data.providerList.currentPeriod.fromDateLabel;
                    } else {
                        this.currentTimePeriodLable = this.data.providerList.currentPeriod.fromDateLabel + ' ' + this._translateService.instant("ClaimCostTopProvider.DateRange") + ' ' + this.data.providerList.currentPeriod.toDateLabel;
                    }
                } else {
                    this.currentTimePeriodLable = '';
                }
                /*
                this.currentTimePeriodLable =
                    this.data.providerList.current != null
                        ? // this.data.providerList.current[0].periodLable
                        this.data.providerList.currentPeriod.fromDateLabel + ' ' + this._translateService.instant("ClaimCostTopProvider.DateRange")+ ' '  + this.data.providerList.currentPeriod.toDateLabel
                        : '';
                */
            }

            if (
                this.currentTimePeriodLable == null ||
                this.currentTimePeriodLable === ''
            ) {
                /*
                this.currentTimePeriodLable =
                    this.data.diagList.current != null
                        ? // this.data.diagList.current[0].periodLable
                        this.data.diagList.currentPeriod.fromDateLabel + ' ' + this._translateService.instant("ClaimCostTopProvider.DateRange")+ ' '  + this.data.diagList.currentPeriod.toDateLabel
                        : '';
                */
                if (this.data.diagList.current != null) {
                    if (this.data.diagList.currentPeriod.fromDateLabel === this.data.diagList.currentPeriod.toDateLabel) {
                        this.currentTimePeriodLable = this.data.diagList.currentPeriod.fromDateLabel;
                    } else {
                        this.currentTimePeriodLable = this.data.diagList.currentPeriod.fromDateLabel + ' ' + this._translateService.instant("ClaimCostTopProvider.DateRange") + ' ' + this.data.diagList.currentPeriod.toDateLabel;
                    }
                } else {
                    this.currentTimePeriodLable = '';
                }
            }
            this.datalist = this.data.providerList.current;
            this.datalistprovDiag = this.data.diagList.current;

            this.maxDiagCostClaim = Math.max.apply(
                null,
                this.datalistprovDiag.map(a => a.averageCostPerClaim)
            );
            this.maxDiagCostClaimant = Math.max.apply(
                null,
                this.datalistprovDiag.map(a => a.averageCostPerClaimant)
            );
            this.maxCostClaim = Math.max.apply(
                null,
                this.datalist.map(a => a.averageCostPerClaim)
            );
            this.maxCostClaimant = Math.max.apply(
                null,
                this.datalist.map(a => a.averageCostPerClaimant)
            );

            if (this.maxDiagCostClaim > 1) {
                this.maxDiagCostClaim += this.maxDiagCostClaim * 0.3;
            }
            if (this.maxDiagCostClaimant > 1) {
                this.maxDiagCostClaimant += this.maxDiagCostClaimant * 0.3;
            }
            if (this.maxCostClaim > 1) {
                this.maxCostClaim += this.maxCostClaim * 0.3;
            }
            if (this.maxCostClaimant > 1) {
                this.maxCostClaimant += this.maxCostClaimant * 0.3;
            }
            if (this.data.currentDiagnosis === '' && this.data.currentProvider === '') {
                this.filterTopListDiag(this.datalistprovDiag);
                this.filterTopList(this.topFilterDiag);
            } else if (
                this.data.currentDiagnosis === '' &&
                this.data.currentProvider !== ''
            ) {
                this.filterTopListDiag(this.datalistprovDiag);
            } else if (
                this.data.currentDiagnosis !== '' &&
                this.data.currentProvider === ''
            ) {
                this.filterTopList(this.topFilterDiag);
            }
        } else if (
            this.properties.id === 'ClaimCostByTopProvider' ||
            this.properties.id === 'ClaimCostByTopDiagnoses'
        ) {
            this.data.currentDiagnosis = '';
            this.data.currentProvider = '';
            this.data.shownAsPerCurrentProvider = '';
            this.data.shownAsPerCurrentDiagnosis = '';
            this.properties.secondaryDimension = '';
            this.properties.secondaryDimensionValue = '';
            this.isLoadingDiagnoses = false;
            this.data.currentContainerIsDiagnosis = false;

            if (typeof this.data.costPerClaimTotal !== 'undefined') {
                this.totalProviderofCostClaim = this.data.costPerClaimTotal;
            }
            this.totalProviderofCostClaimant = this.data.costPerClaimantTotal;
            if (this.properties.id === 'ClaimCostByTopDiagnoses') {
                this.properties.dimension = this.properties.diag;
            }
            if (this.properties.id === 'ClaimCostByTopProvider') {
                this.properties.dimension = 'provider name';
                this.benchmarkClaim =
                    this.properties.benchmarkLevel !== 3
                        ? this.data.benchmarkPMPM.benchmarksClaim != null
                            ? this.data.benchmarkPMPM.benchmarksClaim[0] != null
                                ? this.data.benchmarkPMPM.benchmarksClaim[0]
                                    .benchmarkValue
                                : 0
                            : 0
                        : 0;

                this.benchmarkClaimant =
                    this.properties.benchmarkLevel !== 3
                        ? this.data.benchmarkPMPM.benchmarksClaim[0] != null
                            ? this.data.benchmarkPMPM.benchmarksClaimant != null
                                ? this.data.benchmarkPMPM.benchmarksClaimant[0]
                                    .benchmarkValue
                                : 0
                            : 0
                        : 0;
            }

            this.datalist = this.data.current;
            if (
                this.data.current.length > 0 ||
                this.currentTimePeriodLable == null ||
                this.currentTimePeriodLable === ''
            ) {
                if (this.data.currentPeriod.fromDateLabel === this.data.currentPeriod.toDateLabel) {
                    this.currentTimePeriodLable =
                        this.data.current != null
                            ? this.data.currentPeriod.fromDateLabel
                            : '';
                } else {
                    this.currentTimePeriodLable =
                        this.data.current != null
                            ? //this.data.current[0].periodLable
                            this.data.currentPeriod.fromDateLabel + ' ' + this._translateService.instant("ClaimCostTopProvider.DateRange") + ' ' + this.data.currentPeriod.toDateLabel
                            : '';
                }

            }

            this.maxClaimTotal = Math.max.apply(
                null,
                this.data.current.map(a => a.claimsTotal)
            );

            this.maxCostClaim = Math.max.apply(
                null,
                this.data.current.map(a => a.averageCostPerClaim)
            );
            this.maxCostClaimant = Math.max.apply(
                null,
                this.data.current.map(a => a.averageCostPerClaimant)
            );

            if (this.maxClaimTotal > 1) {
                this.maxClaimTotal += this.maxClaimTotal * 0.3;
            }
            if (this.maxCostClaim > 1) {
                this.maxCostClaim += this.maxCostClaim * 0.3;
            }
            if (this.maxCostClaimant > 1) {
                this.maxCostClaimant += this.maxCostClaimant * 0.3;
            }
            this.barRectanglelement = document.getElementById(
                'rectBarSVG'
            ) as HTMLElement;
            if (this.barRectanglelement != null) {
                if (this.barRectanglelement.clientWidth > 0) {
                    this.maxWidth = this.barRectanglelement.clientWidth;
                }
            }
            if (this.properties.id === 'ClaimCostByTopProvider') {
                if (
                    this.benchmarkClaim == null ||
                    this.benchmarkClaim == 0 ||
                    this.properties['benchmarkLevel'] == 3
                ) {
                    this.benchwidthClaim = (1).toFixed(1);
                } else {
                    this.benchwidthClaim = (
                        (this.benchmarkClaim / this.maxCostClaim) *
                        this.maxWidth
                    ).toFixed(1);
                }
                if (
                    this.benchmarkClaimant == null ||
                    this.benchmarkClaimant === 0 ||
                    this.properties['benchmarkLevel'] === 3
                ) {
                    this.benchwidthClaimant = (1).toFixed(1);
                } else {
                    this.benchwidthClaimant = (
                        (this.benchmarkClaimant / this.maxCostClaimant) *
                        this.maxWidth
                    ).toFixed(1);
                }
            }
            this.filterTopList(this.properties.claimCostTopProviderTopFilter);
        }

        setTimeout(() => (this.parentLoaded = true), 100);
    }

    constructor(
        private _dataService: DataService,
        private httpClient: HttpClient,
        private dimensionOrderService: DimensionOrderService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _numberFormatService: NumberFormattingService
    ) { }

    ngOnInit() {
        this._fuseTranslationLoaderService.loadTranslations(
          navigationEnglish,
          navigationSpanishCL,
          navigationSpanishCO,
          navigationSpanishEC,
          navigationSpanishMX,
          navigationSpanishPE,
          navigationPortugueseBR,
          navigationItalianIT,
        );

        this._translateService.onLangChange.subscribe(
            (event: LangChangeEvent) => {
                this.localeSelected = event.lang;
                if (this.localeSelected == 'en') {
                    this.localeSelected = 'en-US';
                }
            }
        );
    }
    selectionForClaimAndClaimantChanged(val, filterName: string) {
        //chknboth cases with filterna
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
            data: {
                properties: {
                    name: filterName,
                    value: typeof val === 'object' ? val.data.join('||') : val
                }
            }
        });
        if (this.properties.id === 'ProviderVDiagnosis') {
            if (this.data.currentDiagnosis === '' && this.data.currentProvider === '') {
                this.initData();
            } else {
                this.resetBoth();
            }
        }
        if (
            this.properties.id === 'ClaimCostByTopProvider' ||
            this.properties.id === 'ClaimCostByTopDiagnoses'
        ) {
            this.initData();
        }
    }
    filterNAChange(val, filterName: string) {
        this.properties.isExcludeNA = !this.properties.isExcludeNA;
        this.initData();
    }
    selectionChanged(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED,
            data: {
                properties: {
                    name: filterName,
                    value: val
                }
            }
        });
    }
    ClaimsUnit = {
        M: 1000000,
        K: 1000
    };
    filterChanged(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
            data: {
                properties: {
                    name: filterName,
                    value: typeof val === 'object' ? val.data.join('||') : val
                }
            }
        });

        if (filterName == 'sort') {
            if (this.properties.id == 'ProviderVDiagnosis') {
                if (this.data.currentDiagnosis == '' && this.data.currentProvider == '') {
                    this.filterTopListDiag(this.datalistprovDiag);
                    this.filterTopList(this.topFilterDiag);
                } else if (
                    this.data.currentDiagnosis == '' &&
                    this.data.currentProvider != ''
                ) {
                    this.filterTopListDiag(this.datalistprovDiag);
                } else if (
                    this.data.currentDiagnosis != '' &&
                    this.data.currentProvider == ''
                ) {
                    this.filterTopList(this.topFilterDiag);
                }
            }
            if (
                this.properties.id == 'ClaimCostByTopProvider' ||
                this.properties.id == 'ClaimCostByTopDiagnoses'
            ) {
                this.filterTopList(this.properties.claimCostTopProviderTopFilter);
            }
        }
    }

    getPercentage(num) {
        let number = num
            ? '(' + num.toLocaleString(undefined, {
                maximumFractionDigits: 1
            }) + ' %)'
            : '';
        return number;
    }
    format(num, dec) {
        let number = num
            ? num.toLocaleString(undefined, {
                maximumFractionDigits: dec
            })
            : '';
        return number;
    }
    filterTopList(count) {
        this.topProviderTotal = 0;
        const list = this.dimensionOrderService.sortByColumn(
            this.datalist,
            this.properties.sort
        );
        console.log(list);
        let listFilteredNA = list.filter(diag =>
            this.properties.isExcludeNA === true ? diag.claimantCount >= this.properties.claimantFilterValue : true
        );

        if (this.properties.isExcludeNA) {
            listFilteredNA = listFilteredNA.filter(l => l.dimension !== 'NA');
        }

        this.data.finalTopListBind = listFilteredNA.reverse().slice(0, count);
        this.datalist.map(element => {
            this.topProviderTotal += element.claimsTotal;
        });
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
            data: {
                properties: {
                    name: '',
                    value: ''
                }
            }
        });
    }
    filterTopListDiag(datalistproVDiag: any[]) {
        const listDiag = this.dimensionOrderService.sortByColumn(
            datalistproVDiag,
            this.properties.sort
        );

        console.log(listDiag);
        let listDiagFilteredNA = listDiag.filter(diag =>
            this.properties.isExcludeNA === true ? diag.claimantCount >= this.properties.claimantFilterValue : true
        );

        if (this.properties.isExcludeNA) {
            listDiagFilteredNA = listDiagFilteredNA.filter(l => l.dimension !== 'NA');
        }

        this.data.finalTopListBindDiag = listDiagFilteredNA
            .reverse()
            .slice(0, this.topFilterDiag);
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
            data: {
                properties: {
                    name: '',
                    value: ''
                }
            }
        });
    }
    selectProvider(event: any, data: any) {
        if (this.properties.id == 'ProviderVDiagnosis') {
            if (this.data.currentProvider == data.dimension) {
                this.resetBoth();
            } else {
                this.data.currentProvider = data.dimension;
                this.data.shownAsPerCurrentProvider = !this.apacCountries.includes(this.properties.country)
                    && data.claimantCount < this.properties.claimantFilterValue ? 'NA' : data.dimension;
                this.selectProviderFilterDiag(this.data.currentProvider);
            }
        }
    }
    selectProviderFilterDiag(currentProviderVal: any) {
        this.isLoadingDiagnoses = true;
        this.data.currentDiagnosis = '';
        this.properties.dimension = 'diag chapter';
        this.properties.secondaryDimension = 'provider name';
        this.properties.secondaryDimensionValue = currentProviderVal.replace(
            '\'',
            '\'\''
        );
        this.data.currentContainerIsDiagnosis = false;
        this._dataService
            .getTier3Data(this.properties)
            .toPromise()
            .then(re => {
                this.data.diagList = re;
                this.initData();
                this.isLoadingDiagnoses = false;
            });
    }

    resetBoth() {
        if (this.properties.id == 'ProviderVDiagnosis') {
            this.data.currentDiagnosis = '';
            this.data.currentProvider = '';
            this.data.shownAsPerCurrentProvider = '';
            this.data.shownAsPerCurrentDiagnosis = '';
            this.isLoadingDiagnoses = true;
            this.isLoadingProvider = true;
            this.properties.secondaryDimension = '';
            this.properties.secondaryDimensionValue = '';
            this.properties.dimension = 'provider name';
            this.data.currentContainerIsDiagnosis = true;
            this._dataService
                .getTier3Data(this.properties)
                .toPromise()
                .then(re => {
                    this.data.providerList = re;
                    this.initData();
                    this.properties.dimension = 'diag chapter';
                    this.data.currentContainerIsDiagnosis = false;
                    this._dataService
                        .getTier3Data(this.properties)
                        .toPromise()
                        .then(rediag => {
                            this.data.diagList = rediag;
                            this.initData();
                            this.isLoadingDiagnoses = false;
                            this.isLoadingProvider = false;
                        });
                });
        }
    }
    selectDiagnosis(event: any, data: any) {
        if (this.properties.id == 'ProviderVDiagnosis') {
            if (this.data.currentDiagnosis == data.dimension) {
                this.resetBoth();
            } else {
                this.data.currentDiagnosis = data.dimension;
                this.data.shownAsPerCurrentDiagnosis = !this.apacCountries.includes(this.properties.country)
                    && data.claimantCount < this.properties.claimantFilterValue ? 'NA' : data.dimension;
                this.selectDiagnosisFilterProvider(this.data.currentDiagnosis);
            }
        }
    }
    selectDiagnosisFilterProvider(currentDiagnosisVal: any) {
        this.data.currentProvider = '';
        this.isLoadingProvider = true;
        this.properties.dimension = 'provider name';
        this.properties.secondaryDimension = 'diag chapter';
        this.properties.secondaryDimensionValue = currentDiagnosisVal.replace(
            '\'',
            '\'\''
        );
        this.data.currentContainerIsDiagnosis = true;
        this._dataService
            .getTier3Data(this.properties)
            .toPromise()
            .then(re => {
                this.data.providerList = re;
                this.initData();
                this.isLoadingProvider = false;
            });
    }
    //nothing extra then make it selection chnages function
    diagnosisSelectionChanged(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED,
            data: {
                properties: {
                    name: filterName,
                    value: val
                }
            }
        });
    }
    additionalFilterSelectionChanged(val) {
        this.action.emit(val);
    }
}
export enum SortOption {
    ClaimTotal = 'claimsTotal',
    CountClaim = 'claimsCount',
    CountClaimant = 'claimantCount',
    AvergareCostlaim = 'averageCostPerClaim',
    AvergareCostlaimant = 'averageCostPerClaimant',
    ChangeInCost = '%ChangeInCost'
}
export enum ClaimantOption {
    Claim = 'Claim',
    Claimant = 'Claimant'
}
export enum PortfolioIndustryNoneOption {
    Portfolio = 1,
    Industry = 2,
    None = 3
}
export enum CategoryChapterOption {
    DiagCat = 'Diag Cat',
    DiagChapter = 'Diag Chapter'
}
