<div class="rowDiv flex-row-simple">
    <div style="flex: 2; height: 100%">
        <aon-summary-card [width]="scoreCardwidth" [height]="scoreCardheight">
            <aon-summary-card-header>
                <aon-h3 style="color: #001b5a">
                    <fa-icon
                        style="padding-right: 5px"
                        [icon]="['fal', 'users']"
                    ></fa-icon>
                    Survey Results
                </aon-h3>
            </aon-summary-card-header>
            <aon-summary-card-content [top]="contenttop">
                <div style="margin-left: 40px; color: #001b5a">
                    <aon-h5>Results by Question </aon-h5>
                </div>
                <div style="width: 100%; height: 85%; margin-top: -60px">
                    <aon-bar-chart
                        *ngIf="loaded"
                        [chartid]="chartId"
                        [data]="questionsData"
                        [height]="'100%'"
                        [gridlines]="gridlines"
                        [xaxistitle]="xaxistitle"
                        [yaxistitle]="''"
                        [maxyaxisnumber]="maxyaxisnumber"
                        [charttitle]="''"
                    >
                    </aon-bar-chart>
                </div>
            </aon-summary-card-content>
        </aon-summary-card>
    </div>
    <div style="flex: 1; width: 26%; height: 100%; margin-left: -25px">
        <aon-summary-card
            [colorindex]="colorindex"
            width="99%"
            [height]="scoreCardheight"
        >
            <aon-summary-card-content colorindex="1">
                <div *ngFor="let q of questionsData" style="color: #cff0ff">
                    <div
                        [ngClass]="
                            q.colorindex == 2
                                ? 'flex-row-simple  border-bottom highlight-row '
                                : 'flex-row-simple  border-bottom'
                        "
                        style="
                            font-family: 'Helvetica Now Text';
                            font-size: 16px;
                            padding-bottom: 10px;
                            padding-top: 10px;
                        "
                    >
                        <div class="flex-row-simple">
                            <div class="flex-row-simple">
                                <div style="margin-right: 10px">
                                    {{ q.id }})
                                </div>
                                <div>{{ q.description }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </aon-summary-card-content>
        </aon-summary-card>
    </div>
</div>
