export const locale = {
    lang: 'es-ec',
    data: {
      'ClaimCostDiag': {
        'ClaimDiagnosis':'Tipo de Reclamo v Diagnostico',
        'SelectTop':'Seleccione top',
        'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
        'ClaimType':'Tipo de Servicio',          
        'TotalPaidClaim' : 'Total pagado de reclamo', 
        'ClaimantCount':'Parentesco de Demandantes',
        'ClaimCount':'Recuento de reclamos',            
        'ClaimsPerClaimant' : 'Reclamos por Reclamante', 
        'CostPerClaim': 'Costo Por Reclamo',
        'CostPerClaimant': 'Costo Por Reclamante', 
        'ChangeInCost':'% Cambio en Costo',
        'Claim' : 'Reclamo', 
        'Claimant' : 'Reclamante', 
        'ClaimsTotal':'Total de Reclamaciones',
        'Count':'Contar',
        'ClaimsReportedByPaidDate': 'Reclamaciones reportadas por fecha de pago',
        'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
        'CountBasis':'Contar Base',
        'Sort':'Orden',
        'Show':'Mostrar',
        'Hide':'Ocultar',
        'ShowHide':'Mostrar/Esconder',
        'DiagnosisLevel':'Nivel Diagnostico',
        'Category':'Categoría',
        'Chapter':'Cuadro',
        'ShownAsPer':'Se muestra según',
        'TopDiagnoses': 'Top Diagnósticos',            
        'AverageCost': 'Costo Promedio',
        'FilterNA': 'Excluir NA',
        'DateRange': 'to'         
    }
}
};
