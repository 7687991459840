import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { FWB } from '../models/fwb.model';
import { Store } from '@ngrx/store';
import { State } from 'app/_reducers';
import * as FWBActions from '../actions/fwb.actions';
import { Observable, of } from 'rxjs';
import { BenefitQuestions, fwbPlanYearList } from '../constants/fwb-charts-data';

@Injectable({
    providedIn: 'root'
})
export class FWBService {

    @Output() fwbStateChanged: EventEmitter<FWB> = new EventEmitter();
    public fwb: any = {
        question: 1,
        toggleQuestionSelection: true
    };

    constructor(private httpClient: HttpClient,
        private store: Store<State>) {

        this.fwbStateChanged = new EventEmitter();
        let fwbObj = localStorage.getItem('fwb');
        this.fwb = {
            question: 1
        };
        if (fwbObj != 'undefined') {
            var fwbLocal = JSON.parse(fwbObj);
            this.setFWBStore(fwbLocal);
        } else {
            this.setFWBStore(this.fwb);
        }

    }

    private clearStoreFwb() {
        localStorage.removeItem('fwb');
    }

    private setFWBStore(fwbParam) {
        this.fwb = fwbParam;
        localStorage.setItem('fwb', JSON.stringify(this.fwb));
        setTimeout(() => this.fwbStateChanged.emit(this.fwb), 100);
    }

    public toggleQuestionSelection(fwbObj): any {
        this.fwb = fwbObj; 
        localStorage.setItem('fwb', JSON.stringify(this.fwb));
        setTimeout(() => this.fwbStateChanged.emit(this.fwb), 100);
        this.store.dispatch(new FWBActions.ToggleBenefitQuestionSelection(fwbObj.toggleQuestionSelection));
        return this.fwb;
    }

    public updateQuestion(selectedQuestion: number): any {
        this.fwb = { question: selectedQuestion };
        localStorage.setItem('fwb', JSON.stringify(this.fwb));
        setTimeout(() => this.fwbStateChanged.emit(this.fwb), 100);
        this.store.dispatch(new FWBActions.SetBenefitQuestion(selectedQuestion));
        return this.fwb;
    }

    public updateLifestyleScenario(selectedScenario: number): any {
        this.fwb = { lifestylescenario: selectedScenario };
        localStorage.setItem('fwb', JSON.stringify(this.fwb));
        setTimeout(() => this.fwbStateChanged.emit(this.fwb), 100);
        this.store.dispatch(new FWBActions.ToggleRetirementLifestyleScenario(selectedScenario));
        return this.fwb;
    }

    public updateReplacementScenario(selectedScenario: number): any {
        this.fwb = { replacementscenario: selectedScenario };
        localStorage.setItem('fwb', JSON.stringify(this.fwb));
        setTimeout(() => this.fwbStateChanged.emit(this.fwb), 100);
        this.store.dispatch(new FWBActions.ToggleRetirementReplacementScenario(selectedScenario));
        return this.fwb;
    }

    public getPlanYears(userParams: any) {
        //Return mock data until API is integrated
        return fwbPlanYearList;
        //Real API
        // return this.httpClient.post<any>(environment.api + 'api/fwb/planyears', userParams);
    }

    public getSelectedQuestion(): Observable<any> {
        //Return mock data until API is integrated
        return of(this.fwb.question);
        //Real API
        // return this.httpClient.post<any>(environment.api + 'api/fwb/benefitquestion', userParams);
    }

    changeQuestion(question) {
        var questionsData = BenefitQuestions;
        questionsData[question].colorindex = 2;
    }

}
