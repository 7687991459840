import {
  Component, OnInit, ViewEncapsulation, Input, OnDestroy, OnChanges, SimpleChanges,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { User } from 'app/models/user.model';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { navigation } from 'app/navigation/navigation';
import { DemographicsService } from 'app/fwb/services/demographics.service';

@Component({
  selector: 'demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.css'], 
})
export class DemographicsComponent
  implements OnInit, OnChanges {
  @Input() navTabIndex = 3;
  @Input() title: string = '';
  dataByPlanYear: any[];
  demographicsData: any[] = [];
  public user: User;
  toLoadDashboard: boolean;
  queryParam: {
    clientId: any;
    clientName: any;
    planyear: any;
    userName: any;
    country: any;
    country_key: any;
    questionGroupId: any;
    scoreTemplateType: string;
  };
  // navTabIndex: any;
  legendtitleGender = ['Male', 'Female', 'Grand Total'];
  legendtitleAgeBand = [
    '20-29',
    '30-39',
    '40-49',
    '50-59',
    '60-69',
    '70-79',
    '80-89',
  ];
  view: any[] = [];
  view2: any[] = [];
  pensionPlanYearstitle: string = '';
  public filterValues: any = {
    location: '',
    businessUnit: '',
    activeRetireeFilter: '',
  };
  rawDemographicsData: any;
  dataLoaded: boolean;
  ageBandData: any[];
  ageDataByGender: any[];
  membershipData: any;
  locationData: any;
  countryData: any;
  businessUnitData: any;
  locationCategories: any;
  businessCategories: any;
  membershipCategories: any;
  options = [
    { key: true, name: 'Age Band' },
    { key: false, name: 'Gender' },
  ];
  selectedOption = true;
  isChartDemographicsVisible: boolean = false;
  fuseConfig: any;
  navigation: any;
  genders = ['Male', 'Female', 'Grand_Total'];
  ageBands = [
    '20_29',
    '30_39',
    '40_49',
    '50_59',
    '60_69',
    '70_79',
    '80_89',
  ]; 
  chartId = 'demographicsByAgeBands';
  height: any = '400px';
  width: any = '1450px';
  cardheight: any = '490px';
  cardwidth: any = '98%';
  tooltip: boolean = true;
  titleposition = 'left';
  emptyChartTitle: string = '';
  charttitle = 'Demographics';
  yaxistitle = '';
  color = 'white';
  maxyaxisnumber: number = 1000;
  dataAgeBand: any;
  dataGender: any;

  maxTotalAgeBand: number = 0;
  maxTotalGender: number = 0;
  // Private
  private _unsubscribeAll: Subject<any>;
  selectedOptionName: string = 'Age Band';
  demographicDataStackedByAgeBand: any[];
  demographicDataStackedByGender: any[];
  businessDataBarChartData: any;
  locationDataBarChartData: any[];
  salaryChartData: any[];
  serviceLocationData: any = null;
  serviceBUData: any = null;
  serviceSalaryData: any = null;
  serviceData: any = null;
  isLoading = true;
  isError = false;
  errorMsg = "No Data Found.";
  
  isBULoading = true;
  isBUError = false;
  errorBUMsg = "No Data Found.";
 
  isLocationLoading = true;
  isLocationError = false;
  errorLocationMsg = "No Data Found.";
  loaded = false;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _demographicService: DemographicsService,
    private _router: Router
  ) {
    // Set the defaults
    this.navigation = navigation;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }
 
  ngOnInit() {
     
    this.getUser();
    this.getData();
    this.getBusinesssUnitData();
    this.getLocationData(); 
    this.isChartDemographicsVisible = true;
    this.setStackedData();
  }

  getLocationData() {
    this.serviceLocationData = null;
    this._demographicService.getLocation("").toPromise().then((resp) => {
      this.serviceLocationData = resp;
      console.log(this.serviceLocationData);
      this.setLocationData();
      this.errorLocationMsg = "";
      this.isLocationError = false;
  }).catch(error => {
      this.isLocationError = true;
      this.errorLocationMsg = error.message;
  }).finally(() => {
      this.isLocationLoading = false;
    })
  }
 

  getBusinesssUnitData() {
    this.serviceBUData = null;
    this._demographicService.getBusinessUnit("").toPromise().then((resp) => {
      this.serviceBUData = resp;
      console.log(this.serviceBUData);
      this.setBusinesssUnitData();
      this.errorBUMsg = "";
      this.isBUError = false;
  }).catch(error => {
      this.isBUError = true;
      this.errorBUMsg = error.message;
  }).finally(() => {
      this.isBULoading = false;
    })
  }

  getData() {
    this.serviceData = null;
    this._demographicService.getBreakdown("").toPromise().then((resp) => {
      this.serviceData = resp;
      console.log(this.serviceData);
      this.setData();
      this.errorMsg = "";
      this.isError = false;
      this.loaded = true;
  }).catch(error => {
      this.isError = true;
      this.errorMsg = error.message;
  }).finally(() => {
      this.isLoading = false;
    })
  }

  setData() {
    this.ageBandData = this.serviceData.resultAgeBand;
    this.ageDataByGender = this.serviceData.resultGender;
  }
  onOptionChange($event) {
    this.selectedOptionName = $event.detail;
    this.setStackedData();
  }


  setStackedData() {
    if (this.selectedOptionName == 'Age Band') {
      this.selectedOption = true;
      // this.getStackedDataAgeBand();
    } else {
      this.selectedOption = false;
      // this.getStackedDataGender(); 
    }
  }
 
  setLocationData() {
    this.locationDataBarChartData = this.serviceLocationData.resultLocation;
  
  }

  setSalaryData() {
    this.salaryChartData = this.serviceSalaryData.resultSalaryband;
  }
  setBusinesssUnitData() {
    this.businessDataBarChartData = this.serviceBUData.resultBU;
    
  }


  getUser() {
    // this.store.pipe(select(getUser)).subscribe(
    //     (userGet) => {
    //         this.user = userGet;
    //         this.dashboardService.user = userGet;
    //     },
    //     (error) => console.log('Error: ' + error)
    // );
  } 
}
