import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DemographicsService {
  constructor(private httpClient: HttpClient) { }

  getBreakdown(params) {
    return this.httpClient.get<any>(environment.api + 'api/demographic/breakdown?' + params)
  }


  getLocation(params) {
    return this.httpClient.get<any>(environment.api + 'api/demographic/location?' + params)
  }

  getBusinessUnit(params) {
    return this.httpClient.get<any>(environment.api + 'api/demographic/businessunit?' + params)
  }

  getSalaryBand(params) {
    return this.httpClient.get<any>(environment.api + 'api/demographic/salaryband?' + params)
  }
}
