<aon-summary-card [height]="cardheight" [width]="cardwidth" class="individual-chart">
    <aon-summary-card-header>
        <aon-h3 style="color: #001b5a;">
            <fa-icon style="padding-right: 5px;" [icon]="['fal', 'users']"></fa-icon>
            {{ charttitle }}
        </aon-h3>
    </aon-summary-card-header>

    <aon-summary-card-content top="70px">
        <div style="text-align: left;">
            <aon-p-lg>
                View the distribution of employees by age band or gender.
            </aon-p-lg>
        </div>
        <div class="flex-row-simple">
            <aon-horizontaltab-list style="float: left;">
                <aon-horizontaltab selected="isAgeBandSelected" (click)="toggleHorizontalTab()">Age Band
                </aon-horizontaltab>
                <aon-horizontaltab (click)="toggleHorizontalTab()">Gender</aon-horizontaltab>
            </aon-horizontaltab-list>
        </div>
        <div class="flex-row-simple">
            <div *ngIf="isAgeBandSelected" style="flex: 1;">
                <aon-donut-chart [chartId]="'agebandDonutId'" [width]="width" [height]="height"
                    [legendTrim]="legendTrim" [legendtitle]="legendtitle" [legendposition]="legendPosition"
                    [tooltip]="tooltip" [showLegend]="showLegend" [middletext]="middleText" [data]="dataAgeBand"
                    [legendsubtitle]="legendsubtitle">
                </aon-donut-chart>
            </div>
            <div *ngIf="!isAgeBandSelected" style="flex: 1;">
                <aon-donut-chart [chartId]="'genderDonutId'" [width]="width" [height]="height" [legendTrim]="legendTrim"
                    [legendtitle]="legendtitle" [legendposition]="legendPosition" [tooltip]="tooltip"
                    [showLegend]="showLegend" [middletext]="middleText" [legendsubtitle]="legendsubtitle"
                    [data]="dataGender">
                </aon-donut-chart>
            </div>
        </div>
    </aon-summary-card-content>
</aon-summary-card>