import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ExportService } from 'app/services/export.service';
import { AgmaticonactionComponent } from 'app/shared/agmaticonaction/agmaticonaction.component';
import * as moment from 'moment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as enLocale } from './i18n/en';
import { locale as esLocaleCL } from './i18n/es-cl';
import { locale as esLocaleCO } from './i18n/es-co';
import { locale as esLocaleEC } from './i18n/es-ec';
import { locale as esLocaleMX } from './i18n/es-mx';
import { locale as esLocalePE } from './i18n/es-pe';
import { locale as ptLocaleBR } from './i18n/pt-br';
import { locale as itLocale } from './i18n/it';
import { UserService } from 'app/services/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-exportqueue',
  templateUrl: './exportqueue.component.html',
  styleUrls: ['./exportqueue.component.css']
})
export class ExportqueueComponent implements OnInit, OnDestroy {
  intervalId: any;
  exportQueueList: any[] = [];
  queueColDefs = [];
  isLoadingQueue = false;
  isLoadingDownload = false;
  @ViewChild('qGrid', { static: true }) qGrid;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _exportService: ExportService,
    private _userService: UserService,
    private _translateService: TranslateService,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this.isLoadingQueue = true;
    this._fuseTranslationLoaderService.loadTranslations(
      enLocale,
      esLocaleCL,
      esLocaleCO,
      esLocaleEC,
      esLocaleMX,
      esLocalePE,
      ptLocaleBR,
      itLocale,
    );

    this._exportService.getQueue().toPromise().then(data => {
      this.exportQueueList = data;
      this.isLoadingQueue = false;
    });
    
    this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setQueueColDef();
    });
    this.setQueueColDef();

    const refreshPeriod = this._route.snapshot.queryParams['refresh'];

    if (!refreshPeriod) {
        this.intervalId = setInterval(() => {
        this.isLoadingQueue = true;
        this._exportService.getQueue().toPromise().then(data => {
                this.exportQueueList = data;
                this.isLoadingQueue = false;
            });
        }, 10000);
    }
    else {
        const refreshPeriodNumber = parseInt(refreshPeriod, 0);

        this.intervalId = setInterval(() => {
            this.isLoadingQueue = true;
            this._exportService.getQueue().toPromise().then(data => {
                    this.exportQueueList = data;
                    this.isLoadingQueue = false;
                });
            }, refreshPeriodNumber);
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  setQueueColDef() {
    var colDefs = [
      { headerName: this._translateService.instant('ExportQueue.Name'), field: 'titleName' },
      { headerName: this._translateService.instant('ExportQueue.FileName'), field: 'filename' },
      { headerName: this._translateService.instant('ExportQueue.Status'), field: 'exportStatus', width: 150, suppressSizeToFit: true,
        cellRenderer: (cell) => {
            return this._translateService.instant('ExportQueue.' + cell.data.exportStatus);
        }
      },
      {
        headerName: this._translateService.instant('ExportQueue.CreatedOn'), field: 'createdOn', sort: 'desc', width: 200, suppressSizeToFit: true,
        cellRenderer: (cell) => {
            var momentDate = moment.utc(cell.data.createdOn).toDate();
            return moment(momentDate).local().format('MM/DD/YYYY HH:mm');
        }
      },
      {
        width: 80,
        suppressSizeToFit: true,
        filter: false,
        cellRendererFramework: AgmaticonactionComponent,
        cellRendererParams: {
          action: (row) => this.onDownload({ data: row.data }),
          icon: 'save_alt',
          isDisabled: (row) => this.isDisabledRow({ data: row.data })
        }
      }
    ]
    this.queueColDefs = colDefs;
  }

  isDisabledRow(row) {
    return row.data.exportStatus != 'Succeeded';
  }

  onDownload($event) {
    this.qGrid.showLoadingOverLay(true);
    this.isLoadingDownload = true;
    const currentUser = this._userService.getCurrentUser();
    console.log('onDownload', $event.data);
    this._exportService.downloadFile($event.data.exportGuid, currentUser.userId).then(() => {
      this.isLoadingDownload = false;
      this.qGrid.showLoadingOverLay(false);
    });
  }

}
