export const locale = {
    lang: 'pt-br',
    data: {
        'StoriesAdmin': {
            'StoriesAdministration': 'ADMINISTRAÇÃO DE HISTÓRIAS',
            'Stories': 'Histórias',
            'NewStory': 'NOVA HISTÓRIA',
            'Users': 'Usuários',
            'Save': 'Salvar',
            'SelectAll': 'SELECIONAR TODOS',
            'Success': 'Sucesso',
            'Name': 'Nome',
            'CreatedBy': 'Criado por',
            'CreatedOn': 'Criado em',
            'userColumnDefs': [
                { headerName: 'Email', field: 'email', sort: 'asc' },
                { headerName: 'NomeUsuário', field: 'NomeUsuário', sort: 'asc' }
            ]
        }
    }
};
