<aon-summary-card [height]="cardheight" [width]="cardwidth">
    <aon-summary-card-header>
        <aon-h3 style="color: #001b5a;">
            {{ charttitle }}
        </aon-h3>
    </aon-summary-card-header>
    <aon-summary-card-content top="30px" [contentwidth]="contentwidth">
        <fwb-error-message [isError]="isError" [message]="errorMsg"></fwb-error-message>
        <div *ngIf="!isError">
            <div style="width:100%;">
                <aon-stacked-vertical-bar-chart *ngIf="loaded" [chartid]="chartId" [width]="width" [height]="height" [charttitle]="''"
                    [subcategorynames]="subcategorynames" [yaxistitle]="yaxistitle" [tooltip]="tooltip"
                    [tickformat]="tickformat" [xaxistitle]="yaxistitle" [maxyaxisnumber]="scaleMax"
                    [data]="retirementbycategories" [keys]="keys" [legendposition]="legendposition">
                </aon-stacked-vertical-bar-chart>
            </div>
            <div style="flex: 1; display: flex;  flex-direction: row;justify-content:center; ">
                <div style=" margin-left:100px; margin-top: 10px;display: flex; justify-content: center; flex:3;">
                    <aon-legend [data]="legendkeys" [legendposition]="'horizontal'"></aon-legend>
                </div>
            </div>
     
        </div>
    </aon-summary-card-content>
</aon-summary-card>