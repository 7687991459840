
export const Retirement_TargetByCategories = [
    {
        category: 'Below Minimum',
        values: [
            {
                name: 'Below Minimum (Not Met)',
                key: 'BM1Striped',
                value: 0,
                colorindex: 0,
                striped: true
            },
            {
                name: 'Minimum (Not Met)',
                key: 'Min1Striped',
                value: 0,
                colorindex: 2,
                striped: true
            },
            {
                name: 'Moderate (Not Met)',
                key: 'Mod1Striped',
                value: 0,
                colorindex: 4,
                striped: true
            },
            {
                name: 'Comfortable (Not Met)',
                key: 'Com1Striped',
                value: 0,
                colorindex: 7,
                striped: true
            },
            {
                name: 'Above Comfortable (Not Met)',
                key: 'AboveComfortableStriped',
                value: 0,
                colorindex: 8,
                striped: true
            },
            {
                name: 'Below Minimum',
                key: 'BelowMinimum',
                value: 0.04,
                colorindex: 0,
                striped: false
            },
            {
                name: 'Minimum',
                key: 'Minimum',
                value: .01,
                colorindex: 2,
                striped: false
            },
            {
                name: 'Moderate',
                key: 'Moderate',
                value: 0,
                colorindex: 4,
                striped: false
            },
            {
                name: 'Comfortable',
                key: 'Comfortable',
                value: 0,
                colorindex: 7,
                striped: false
            },
            {
                name: 'Above Comfortable',
                key: 'AboveComfortable',
                value: 0,
                colorindex: 8,
                striped: false
            },
        ]
    },
    {
        category: 'Minimum',
        values: [
            {
                name: 'Below Minimum (Not Met)',
                key: 'BM1Striped',
                value: 0,
                colorindex: 0,
                striped: true
            },
            {
                name: 'Minimum (Not Met)',
                key: 'Min1Striped',
                value: 0,
                colorindex: 2,
                striped: true
            },
            {
                name: 'Moderate (Not Met)',
                key: 'Mod1Striped',
                value: 0,
                colorindex: 4,
                striped: true
            },
            {
                name: 'Comfortable (Not Met)',
                key: 'Com1Striped',
                value: 0,
                colorindex: 7,
                striped: true
            },
            {
                name: 'Above Comfortable (Not Met)',
                key: 'Above1Striped',
                value: 0,
                colorindex: 8,
                striped: false
            },
            {
                name: 'Below Minimum',
                key: 'BelowMinimum',
                value: .01,
                colorindex: 0,
                striped: true
            },
            {
                name: 'Minimum',
                key: 'Minimum',
                value: .20,
                colorindex: 2,
                striped: false
            },
            {
                name: 'Moderate',
                key: 'Moderate',
                value: 0,
                colorindex: 4,
                striped: false
            },
            {
                name: 'Comfortable',
                key: 'Comfortable',
                value: 0,
                colorindex: 7,
                striped: false
            },
            {
                name: 'Above Comfortable',
                key: 'AboveComfortable',
                value: 0,
                colorindex: 8,
                striped: false
            }
        ]
    },
    {
        category: 'Moderate',
        values: [
            {
                name: 'Below Minimum (Not Met)',
                key: 'BM1Striped',
                value: 0,
                colorindex: 0,
                striped: true
            },
            {
                name: 'Minimum (Not Met)',
                key: 'Min1Striped',
                value: 0,
                colorindex: 2,
                striped: true
            },
            {
                name: 'Moderate (Not Met)',
                key: 'Mod1Striped',
                value: 0,
                colorindex: 4,
                striped: true
            },
            {
                name: 'Comfortable (Not Met)',
                key: 'Com1Striped',
                value: 0,
                colorindex: 7,
                striped: true
            },
            {
                name: 'Above Comfortable (Not Met)',
                key: 'Above1Striped',
                value: 0,
                colorindex: 8,
                striped: false
            },
            {
                name: 'Below Minimum',
                key: 'BelowMinimum',
                value: 0,
                colorindex: 1,
                striped: false
            },
            {
                name: 'Minimum',
                key: 'Min1Striped',
                value: .05,
                colorindex: 1,
                striped: true
            },
            {
                name: 'Moderate',
                key: 'Moderate',
                value: .25,
                colorindex: 4,
                striped: false
            },
            {
                name: 'Comfortable',
                key: 'Comfortable',
                value: .01,
                colorindex: 7,
                striped: false
            },
            {
                name: 'Above Comfortable',
                key: 'AboveComfortable',
                value: 0,
                colorindex: 8,
                striped: false
            }
        ]
    },
    {
        category: 'Comfortable',
        values: [
            {
                name: 'Below Minimum (Not Met)',
                key: 'BM1Striped',
                value: 0,
                colorindex: 0,
                striped: true
            },
            {
                name: 'Minimum (Not Met)',
                key: 'Min1Striped',
                value: 0,
                colorindex: 2,
                striped: true
            },
            {
                name: 'Moderate (Not Met)',
                key: 'Mod1Striped',
                value: 0,
                colorindex: 4,
                striped: true
            },
            {
                name: 'Comfortable (Not Met)',
                key: 'Com1Striped',
                value: 0,
                colorindex: 7,
                striped: true
            },
            {
                name: 'Above Comfortable (Not Met)',
                key: 'Above1Striped',
                value: 0,
                colorindex: 8,
                striped: false
            },
            {
                name: 'Below Minimum',
                key: 'BelowMinimum',
                value: 0,
                colorindex: 0,
                striped: false
            },
            {
                name: 'Minimum',
                key: 'Minimum',
                value: 0,
                colorindex: 2,
                striped: false
            },
            {
                name: 'Moderate',
                key: 'Mod1Striped',
                value: .05,
                colorindex: 4,
                striped: true
            },
            {
                name: 'Comfortable',
                key: 'Comfortable',
                value: .10,
                colorindex: 7,
                striped: false
            },
            {
                name: 'Above Comfortable',
                key: 'AboveComfortable',
                value: 0,
                colorindex: 8,
                striped: false
            }
        ]
    },
    {
        category: 'Above Comfortable',
        values: [
            {
                name: 'Below Minimum (Not Met)',
                key: 'BM1Striped',
                value: 0,
                colorindex: 0,
                striped: true
            },
            {
                name: 'Minimum (Not Met)',
                key: 'Min1Striped',
                value: 0,
                colorindex: 2,
                striped: true
            },
            {
                name: 'Moderate (Not Met)',
                key: 'Mod1Striped',
                value: 0,
                colorindex: 4,
                striped: true
            },
            {
                name: 'Comfortable (Not Met)',
                key: 'Com1Striped',
                value: 0,
                colorindex: 7,
                striped: true
            },
            {
                name: 'Above Comfortable (Not Met)',
                key: 'Above1Striped',
                value: 0,
                colorindex: 8,
                striped: false
            },
            {
                name: 'Below Minimum',
                key: 'BelowMinimum',
                value: 0,
                colorindex: 0,
                striped: false
            },
            {
                name: 'Minimum',
                key: 'Minimum',
                value: 0,
                colorindex: 2,
                striped: false
            },
            {
                name: 'Moderate',
                key: 'Mod1Striped',
                value: 0,
                colorindex: 4,
                striped: true
            },
            {
                name: 'Comfortable',
                key: 'Com1Striped',
                value: .03,
                colorindex: 7,
                striped: true
            },
            {
                name: 'Above Comfortable',
                key: 'AboveComfortable',
                value: .05,
                colorindex: 8,
                striped: false
            }
        ]
    },

];


export const Retirement_RatioByCategories = [
    {
        category: 'Original',
        values: [
            {
                name: 'Highly Unlikely',
                key: 'HighlyUnlikely',
                value: .15,
                colorindex: 0,
                striped: false
            },
            {
                name: 'Unlikely',
                key: 'Unlikely',
                value: .25,
                colorindex: 2,
                striped: false
            },
            {
                name: 'Moderate',
                key: 'Moderate',
                value: .30,
                colorindex: 4,
                striped: false
            }, {
                name: 'Likely',
                key: 'Likely',
                value: .20,
                colorindex: 7,
                striped: false
            }, {
                name: 'Highly Likely',
                key: 'HighlyLikely',
                value: .10,
                colorindex: 8,
                striped: false
            },
        ]
    },
    {
        category: 'Scenario 1',
        values: [
            {
                name: 'Highly Unlikely',
                key: 'HighlyUnlikely',
                value: .13,
                colorindex: 0,
                striped: false
            },
            {
                name: 'Unlikely',
                key: 'Unlikely',
                value: .22,
                colorindex: 2,
                striped: false
            },
            {
                name: 'Moderate',
                key: 'Moderate',
                value: .27,
                colorindex: 4,
                striped: false
            }, {
                name: 'Likely',
                key: 'Likely',
                value: .25,
                colorindex: 7,
                striped: false
            }, {
                name: 'Highly Likely',
                key: 'HighlyLikely',
                value: .13,
                colorindex: 8,
                striped: false
            },
        ]
    },
    {
        category: 'Scenario 2',
        values: [
            {
                name: 'Highly Unlikely',
                key: 'HighlyUnlikely',
                value: .11,
                colorindex: 0,
                striped: false
            },
            {
                name: 'Unlikely',
                key: 'Unlikely',
                value: .19,
                colorindex: 2,
                striped: false
            },
            {
                name: 'Moderate',
                key: 'Moderate',
                value: .24,
                colorindex: 4,
                striped: false
            }, {
                name: 'Likely',
                key: 'Likely',
                value: .30,
                colorindex: 7,
                striped: false
            }, {
                name: 'Highly Likely',
                key: 'HighlyLikely',
                value: .16,
                colorindex: 8,
                striped: false
            },
        ]
    },
    {
        category: 'Scenario 3',
        values: [
            {
                name: 'Highly Unlikely',
                key: 'HighlyUnlikely',
                value: .09,
                colorindex: 0,
                striped: false
            },
            {
                name: 'Unlikely',
                key: 'Unlikely',
                value: .16,
                colorindex: 2,
                striped: false
            },
            {
                name: 'Moderate',
                key: 'Moderate',
                value: .21,
                colorindex: 4,
                striped: false
            }, {
                name: 'Likely',
                key: 'Likely',
                value: .35,
                colorindex: 7,
                striped: false
            }, {
                name: 'Highly Likely',
                key: 'HighlyLikely',
                value: .19,
                colorindex: 8,
                striped: false
            },
        ]
    },
    {
        category: 'Scenario 4',
        values: [
            {
                name: 'Highly Unlikely',
                key: 'HighlyUnlikely',
                value: .07,
                colorindex: 0,
                striped: true
            },
            {
                name: 'Unlikely',
                key: 'Unlikely',
                value: .13,
                colorindex: 2,
                striped: true
            },
            {
                name: 'Moderate',
                key: 'Moderate',
                value: .18,
                colorindex: 4,
                striped: true
            }, {
                name: 'Likely',
                key: 'Likely',
                value: .40,
                colorindex: 7,
                striped: true
            }, {
                name: 'Highly Likely',
                key: 'HighlyLikely',
                value: .22,
                colorindex: 8,
                striped: true
            },
        ]
    },
    {
        category: 'Scenario 5',
        values: [
            {
                name: 'Highly Unlikely',
                key: 'HighlyUnlikely',
                value: .05,
                colorindex: 0,
                striped: false
            },
            {
                name: 'Unlikely',
                key: 'Unlikely',
                value: .10,
                colorindex: 2,
                striped: false
            },
            {
                name: 'Moderate',
                key: 'Moderate',
                value: .15,
                colorindex: 4,
                striped: false
            }, {
                name: 'Likely',
                key: 'Likely',
                value: .45,
                colorindex: 7,
                striped: false
            }, {
                name: 'Highly Likely',
                key: 'HighlyLikely',
                value: .25,
                colorindex: 8,
                striped: false
            },
        ]
    },

];



export const RetirementRatioComparison = [
    {
        category: 'Highly Unlikely',
        values: [
            {
                name: 'Original', striped: false, colorindex: 0,
                value: .15,
            },
            {
                name: 'Scenario', striped: true, colorindex: 0,
                value: .05,
            },
        ],
    },
    {
        category: 'Unlikely',
        values: [
            {
                name: 'Original', striped: false, colorindex: 2,
                value: .25,
            },
            {
                name: 'Scenario', striped: true, colorindex: 2,
                value: .10,
            },
        ],
    },
    {
        category: 'Moderate',
        values: [
            {
                name: 'Original', striped: false, colorindex: 4,
                value: .30,
            },
            {
                name: 'Scenario', striped: true, colorindex: 4,
                value: .15,
            },
        ],
    },
    {
        category: 'Likely',
        values: [
            {
                name: 'Original', striped: false, colorindex: 7,
                value: .20,
            },
            {
                name: 'Scenario', striped: true, colorindex: 7,
                value: .45,
            },
        ],
    },
    {
        category: 'Highly Likely',
        values: [
            {
                name: 'Original', striped: false, colorindex: 8,
                value: .01,
            },
            {
                name: 'Scenario', striped: true, colorindex: 8,
                value: .25,
            },
        ],
    },
];


export const RetirementScenarioOptions = [
    { key: 1, name: 'Scenario 1', description: 'Retirement savings increases 2% from current levels.' },
    { key: 2, name: 'Scenario 2', description: 'Retirement savings increases 5% from current levels.' },
    { key: 3, name: 'Scenario 3', description: 'Retirement savings increases 7% from current levels.' },
    { key: 4, name: 'Scenario 4', description: 'Retirement savings increases 10% from current levels.' },
    { key: 5, name: 'Scenario 5', description: 'Retirement savings increases 15% from current levels.' },
];