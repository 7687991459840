export const locale = {
    lang: 'pt-br',
    data: {
        'ClaimAgeBand': {
		'ClaimsByAgeBand': 'Sinistro por Faixa Etária', 
		'AllAmountsDisplayedInLocalCurrency': 'Todos os valores estão em moeda local', 
		'CountBasis': 'Base de Contagem', 
		'Claim': 'Sinistro', 
		'Claimant': 'Usuário', 
		'AgeBandSplit': 'Divisão de Faixa Etária', 
		'None': 'Nenhum', 
		'Gender': 'Gênero', 
		'Relationship': 'Relacionamento', 
		'Sort': 'Ordenar', 
		'ClaimsTotal': 'Sinistro Total', 
		'Count': 'Contar', 
		'AverageCost': 'Custo Médio', 
		'DataTable': 'Tabela de Dados', 
		'TopDiagnoses': 'Maiores Diagnósticos', 
		'TopProviders': 'Maiores Prestadores', 
		'TotalPaidClaim': 'Total de Sinistro', 
		'ClaimantCount': 'Contagem de Usuário', 
		'ClaimCount': 'Contagem de Sinistros', 
		'ClaimsPMPM': 'Sinistro PMPM', 
		'CostPerClaim': 'Custo Por Sinistro', 
		'CostPerClaimant': 'Custo Por Usuário', 
		'ClaimsReportedByPaidDate': 'Sinistro Reportado por Data de Pagamento', 
		'ClaimsReportedByIncurredDate': 'Sinistro Reportado por Data de Atendimento', 
		'FilterNA': 'Excluir NA', 
		'Display': 'Mostrar Como Tabela', 
		'ShowLegend': 'Mostrar Legenda', 
		'ShownAsPer': 'Mostrado como por', 
		'AgeBand': 'Faixa Etária',
		'DateRange': 'to'
        }
}
};
