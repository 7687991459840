export const locale = {
    lang: 'es-co',
    data: {
      'AddFilter': {
          'ClientName': 'Nombre del cliente',
          'Entity': 'Entidad',
          'Division': 'División', 
          'Plan': 'Plan',
          'TimePeriod': 'Periodo de tiempo',                
          'PolicyType': 'Tipo de Póliza',
          'AgeBand': 'Rango Etario',
          'Gender': 'Género',
          'ClaimType': 'Tipo de Servicio',
          'ClaimType2': 'Tipo de Servicio 2',
          'ClaimType3': 'Tipo de Servicio 3',
          'Relationship': 'Parentesco',
          'DiagnosisCategory': 'Categoría de Diagnóstico',
          'DiagnosisChapter': 'Capítulo de Diagnóstico',
          'Location': 'Ubicación',
          'IsChronic': 'Crónica',
          'Carrier': 'Portador',
          'LOB': 'Línea de Negocio',
          'SubLOB': 'NA',
          'Network': 'Red',
          'ProviderName': 'Nombre del Prestador',
          'ProviderLocation': 'Ubicación del Prestador',
          'ProviderType': 'Tipo de Prestador'
      }
  }
};
