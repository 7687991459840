<div *ngIf="financialScoreObj" class="columnDiv contentPadding">
    <div>&nbsp;<br /></div>
    <div>
        <div class="rowDiv">
            <div>
                <div class="rowDiv">
                    <div style="padding-right:16px; padding-top: 12px;">
                        <fa-icon [icon]="['fal', 'money-bill']" [styles]="{'font-size': '32px', 'color': '#001B5A'}">
                        </fa-icon>
                    </div>
                    <div>
                        <aon-h1 style="color:#001B5A;"> Financial Score</aon-h1>
                    </div>
                </div>
            </div>
            <div class="vertical-line marginLeft20"></div>
            <div class="marginLeft20 marginTop20">
                <aon-p-elg class="fontWeight500">
                    As of {{ financialScoreObj.currentTabResult.scoreDateFormat }} CT
                </aon-p-elg>
            </div>
        </div>
    </div>
    <div>
        <aon-horizontaltab-list (onchange)="financialScoreTabClick($event)">
            <aon-horizontaltab value=1 [selected]="financialScoreObj.isCurrentTabSelected">CURRENT</aon-horizontaltab>
            <aon-horizontaltab value=2 [selected]="!financialScoreObj.isCurrentTabSelected">HISTORY</aon-horizontaltab>
        </aon-horizontaltab-list>
    </div>
    <div>
        &nbsp;
        <br />
    </div>
    <div *ngIf="financialScoreObj.isCurrentTabSelected">
        <div>
            <aon-bar-summary width="99%" height="140px" colorindex="11">
                <aon-bar-summary-content width="15%" top="60px">
                    <div class="columnDiv marginLeft20">
                        <div>
                            <aon-p-lg class="whiteColorText">
                                Average Financial Score
                            </aon-p-lg>
                        </div>
                        <div class="negativeMarginTop20">
                            <aon-h1 class="whiteColorText">
                                {{ financialScoreObj.currentTabResult.averageScore  }}</aon-h1>
                        </div>
                    </div>
                </aon-bar-summary-content>
                <aon-vertical-line></aon-vertical-line>
                <aon-bar-summary-content width="80%" top="60px">
                    <div class="columnDiv" style = "margin-top: 15px;">
                        <div class="marginTop20">
                            <aon-h4 class="whiteColorText">
                                Your financial score has changed {{ changePercentage }}% since {{ maxDate }}
                                <!-- {{ financialScoreObj.currentTabResult.description }} -->
                            </aon-h4>
                        </div>
                    </div>
                </aon-bar-summary-content>
            </aon-bar-summary>
        </div>
        <div>
            <br />
        </div>
        <div>
            <aon-summary-card width="99%" [height]="currentTabSummaryCardHeight">
                <aon-summary-card-header>
                    <aon-h3 style="color:#001B5A;">
                        User Distribution by Score Level</aon-h3>
                </aon-summary-card-header>
                <aon-summary-card-content contentwidth="97.5%" top="60px" yaxisoverflow="false">
                    <div class="columnDiv">
                        <div>
                            <br />
                        </div>
                        <div>
                            <aon-p-elg>Select a segment below to see score range details</aon-p-elg>
                        </div>

                        <div *ngIf="financialScoreObj.currentTabResult.scoreRangeList.length > 0">
                            <aon-expandable-bar-chart width="98%" 
                                [data]="financialScoreObj.currentTabResult.scoreRangeList" height="500px">
                                <aon-bar-content [text]="row.name" [value]="row.value" [isexpand]="row.isexpand"
                                    (onexpandcollapseclick)="barExpandCollapseClick($event)"
                                    *ngFor="let row of financialScoreObj.currentTabResult.scoreRangeList;">
                                    <aon-summary-card height="600px">
                                        <aon-summary-card-header>
                                            <aon-h3 style="color:#001B5A;">
                                                {{ row.scoreRange}}</aon-h3>
                                        </aon-summary-card-header>
                                        <aon-summary-card-content top="60px" contentwidth="97%">
                                            <div class="columnDiv" style="width:96%">
                                                <div>
                                                    <br />
                                                </div>
                                                <div>
                                                    <aon-horizontaltab-list
                                                        (onchange)="financialScoreRangeTabClick($event, row)">
                                                        <aon-horizontaltab value=1 [selected]="row.selectedTabIndex ===1">BY AGE
                                                        </aon-horizontaltab>
                                                        <aon-horizontaltab value=2 [selected]="row.selectedTabIndex === 2" >BY BUSINESS UNIT</aon-horizontaltab>
                                                        <aon-horizontaltab value=3 [selected]="row.selectedTabIndex === 3" >BY COUNTRY</aon-horizontaltab>
                                                    </aon-horizontaltab-list>
                                                </div>
                                                <div>
                                                    <br />
                                                </div>
                                                <div style="width:25%">                        
                                                    <aon-select *ngIf="row.selectedTabIndex === 2" 
                                                    returntype='number' width="100%"
                                                        [selectedvalue]="row.selectedBusinessUnitIndex"
                                                        (onselectionchange)='onScoreBUDisplayChange($event, row)'>
                                                        <aon-select-option value=1>Top 10
                                                        </aon-select-option>
                                                        <aon-select-option value=2>Bottom 10</aon-select-option>
                                                    </aon-select>
                                                    <aon-select *ngIf="row.selectedTabIndex === 3" 
                                                    returntype='number' width="100%"
                                                        [selectedvalue]="row.selectedCountryIndex"
                                                        (onselectionchange)='onScoreCountryDisplayChange($event, row)'>
                                                        <aon-select-option value=1>Top 10
                                                        </aon-select-option>
                                                        <aon-select-option value=2>Bottom 10</aon-select-option>
                                                    </aon-select>
                                                </div>
                                                <div>
                                                    <aon-horizontal-bar-chart displayicons="false"
                                                        *ngIf="row.selectedTabIndex === 1 && row.ageList.length > 0"
                                                        [data]="row.ageList" width="99%"
                                                        [height]="getHeight(row.ageList)" xaxistitle="Number of Users">
                                                    </aon-horizontal-bar-chart>
                                                    <aon-horizontal-bar-chart displayicons="false"
                                                        *ngIf="row.selectedTabIndex === 2 && row.selectedBusinessUnitIndex === 1 && row.topBusinessUnitList.length > 0"
                                                        [data]="row.topBusinessUnitList" width="99%"
                                                        [height]="getHeight(row.topBusinessUnitList)"
                                                        [ytextmaxcomputedlength]="180" 
                                                        xaxistitle="Number of Users">
                                                    </aon-horizontal-bar-chart>
                                                    <aon-horizontal-bar-chart displayicons="false"
                                                        *ngIf="row.selectedTabIndex === 2 && row.selectedBusinessUnitIndex === 2 && row.bottomBusinessUnitList.length > 0"
                                                        [data]="row.bottomBusinessUnitList" width="99%"
                                                        [height]="getHeight(row.bottomBusinessUnitList)" 
                                                        [ytextmaxcomputedlength]="180" 
                                                        xaxistitle="Number of Users">
                                                    </aon-horizontal-bar-chart>
                                                    <aon-horizontal-bar-chart displayicons="false"
                                                        *ngIf="row.selectedTabIndex === 3 && row.selectedCountryIndex === 1 && row.topCountryList.length > 0"
                                                        [data]="row.topCountryList" width="99%"
                                                        [height]="getHeight(row.topCountryList)" xaxistitle="Number of Users">
                                                    </aon-horizontal-bar-chart>
                                                    <aon-horizontal-bar-chart displayicons="false"
                                                        *ngIf="row.selectedTabIndex === 3 && row.selectedCountryIndex === 2 && row.bottomCountryList.length > 0"
                                                        [data]="row.bottomCountryList" width="99%"
                                                        [height]="getHeight(row.bottomCountryList)" xaxistitle="Number of Users">
                                                    </aon-horizontal-bar-chart>
                                                </div>
                                                <div>
                                                    <br />                                                    
                                                </div>
                                                <div>
                                                    <aon-p-elg 
                                                        *ngIf="row.selectedTabIndex === 1 && row.ageList.length === 0" >
                                                        Privacy filter applied for selection less than 10 people
                                                    </aon-p-elg>

                                                    <aon-p-elg
                                                        *ngIf="row.selectedTabIndex === 2 && row.selectedBusinessUnitIndex === 1  && row.topBusinessUnitList.length === 0" >
                                                        Privacy filter applied for selection less than 10 people
                                                    </aon-p-elg>

                                                    <aon-p-elg
                                                        *ngIf="row.selectedTabIndex === 2 && row.selectedBusinessUnitIndex === 2  && row.bottomBusinessUnitList.length === 0" >
                                                        Privacy filter applied for selection less than 10 people
                                                    </aon-p-elg>

                                                    <aon-p-elg
                                                        *ngIf="row.selectedTabIndex === 3 && row.selectedCountryIndex === 1  && row.topCountryList.length === 0" >
                                                        Privacy filter applied for selection less than 10 people
                                                    </aon-p-elg>

                                                    <aon-p-elg
                                                        *ngIf="row.selectedTabIndex === 3 && row.selectedCountryIndex === 2  && row.bottomCountryList.length === 0" >
                                                        Privacy filter applied for selection less than 10 people
                                                    </aon-p-elg>
                                                </div>
                                            </div>
                                        </aon-summary-card-content>
                                    </aon-summary-card>
                                </aon-bar-content>
                                <aon-bar-content isxaxis="true"></aon-bar-content>
                            </aon-expandable-bar-chart>
                        </div>
                        <div *ngIf="financialScoreObj.currentTabResult.scoreRangeList.length === 0">
                            <aon-p-elg>Privacy filter applied for selection less than 10 people</aon-p-elg>
                        </div>
                    </div>
                </aon-summary-card-content>
            </aon-summary-card>
        </div>
        <div>
            <br />
        </div>
        <div>
            <aon-summary-card width="99%" [height]="currentTabScoreCardHeight">
                <aon-summary-card-header>
                    <aon-h3 style="color:#001B5A;">
                        Score Details</aon-h3>
                </aon-summary-card-header>
                <aon-summary-card-content contentwidth="97.5%" top="60px" contentheight="100%" yaxisoverflow="false">
                    <div class="columnDiv">
                        <div>
                            <br />
                        </div>
                        <div>
                            <aon-horizontaltab-list  (onchange)="financialScoreDetailTabClick($event)">
                                <aon-horizontaltab *ngFor="let row of financialScoreObj.currentTabResult.tabList;" 
                                  [value]="row.value" [selected]="financialScoreObj.selectedScoreDetailTabIndex === row.value">{{row.name}}</aon-horizontaltab>
                                
                            </aon-horizontaltab-list>
                        </div>
                        <div>
                            <br />
                        </div>
                        <div style="width:25%">                        
                            <aon-select *ngIf="financialScoreObj.selectedScoreDetailTabIndex === 2" 
                            returntype='number' width="100%"
                                [selectedvalue]="financialScoreObj.selectedBUIndex"
                                (onselectionchange)='onScoreBUDisplayChange($event)'>
                                <aon-select-option value=1>Top 10 
                                </aon-select-option>
                                <aon-select-option value=2>Bottom 10</aon-select-option>
                            </aon-select>
                            <aon-select *ngIf="financialScoreObj.selectedScoreDetailTabIndex === 3" 
                            returntype='number' width="100%"
                                [selectedvalue]="financialScoreObj.selectedCountryIndex"
                                (onselectionchange)='onScoreCountryDisplayChange($event)'>
                                <aon-select-option value=1>Top 10
                                </aon-select-option>
                                <aon-select-option value=2>Bottom 10</aon-select-option>
                            </aon-select>
                            
                        </div>
                        <div *ngIf="financialScoreObj.selectedScoreDetailTabIndex === 2 || financialScoreObj.selectedScoreDetailTabIndex === 3">
                            <br />
                        </div>
                        <div style="width: 98%;">
                            <aon-horizontal-bar-chart displayicons="false"
                                *ngIf="financialScoreObj.selectedScoreDetailTabIndex === 1 && financialScoreObj.currentTabResult.ageList.length > 0"
                                [data]="financialScoreObj.currentTabResult.ageList" width="98%"
                                [height]="getHeight(financialScoreObj.currentTabResult.ageList)"
                                xaxistitle="Average Score">
                            </aon-horizontal-bar-chart>

                            <aon-horizontal-bar-chart displayicons="false"
                                *ngIf="financialScoreObj.selectedScoreDetailTabIndex === 2 && financialScoreObj.selectedBUIndex === 1 && financialScoreObj.currentTabResult.topBusinessUnitList.length > 0"
                                [data]="financialScoreObj.currentTabResult.topBusinessUnitList" width="98%"
                                [height]="getHeight(financialScoreObj.currentTabResult.topBusinessUnitList)"
                                [ytextmaxcomputedlength]="180" 
                                xaxistitle="Average Score">
                            </aon-horizontal-bar-chart>
                            <aon-horizontal-bar-chart displayicons="false"
                                *ngIf="financialScoreObj.selectedScoreDetailTabIndex === 2 && financialScoreObj.selectedBUIndex === 2 && financialScoreObj.currentTabResult.bottomBusinessUnitList.length > 0"
                                [data]="financialScoreObj.currentTabResult.bottomBusinessUnitList" width="98%"
                                [height]="getHeight(financialScoreObj.currentTabResult.bottomBusinessUnitList)" 
                                [ytextmaxcomputedlength]="180" 
                                xaxistitle="Average Score">
                            </aon-horizontal-bar-chart>
                            <aon-horizontal-bar-chart displayicons="false"
                                *ngIf="financialScoreObj.selectedScoreDetailTabIndex === 3 && financialScoreObj.selectedCountryIndex === 1 && financialScoreObj.currentTabResult.topCountryList.length > 0"
                                [data]="financialScoreObj.currentTabResult.topCountryList" width="98%"
                                [height]="getHeight(financialScoreObj.currentTabResult.topCountryList)"
                                xaxistitle="Average Score">
                            </aon-horizontal-bar-chart>
                            <aon-horizontal-bar-chart displayicons="false"
                                *ngIf="financialScoreObj.selectedScoreDetailTabIndex === 3 && financialScoreObj.selectedCountryIndex === 2 && financialScoreObj.currentTabResult.bottomCountryList.length > 0"
                                [data]="financialScoreObj.currentTabResult.bottomCountryList" width="98%"
                                [height]="getHeight(financialScoreObj.currentTabResult.bottomCountryList)"
                                xaxistitle="Average Score">
                            </aon-horizontal-bar-chart>
                        </div>
                        <div>
                            <aon-p-elg 
                            *ngIf="financialScoreObj.selectedScoreDetailTabIndex === 1 && financialScoreObj.currentTabResult.ageList.length === 0" >
                            Privacy filter applied for selection less than 10 people
                            </aon-p-elg>

                            <aon-p-elg
                             *ngIf="financialScoreObj.selectedScoreDetailTabIndex === 2 && financialScoreObj.selectedBUIndex === 1  && financialScoreObj.currentTabResult.topBusinessUnitList.length === 0" >
                             Privacy filter applied for selection less than 10 people
                            </aon-p-elg>

                            <aon-p-elg
                             *ngIf="financialScoreObj.selectedScoreDetailTabIndex === 2 && financialScoreObj.selectedBUIndex === 2  && financialScoreObj.currentTabResult.bottomBusinessUnitList.length === 0" >
                             Privacy filter applied for selection less than 10 people
                            </aon-p-elg>

                            <aon-p-elg
                            *ngIf="financialScoreObj.selectedScoreDetailTabIndex === 3 && financialScoreObj.selectedCountryIndex === 1  && financialScoreObj.currentTabResult.topCountryList.length === 0" >
                            Privacy filter applied for selection less than 10 people
                           </aon-p-elg>

                           <aon-p-elg
                            *ngIf="financialScoreObj.selectedScoreDetailTabIndex === 3 && financialScoreObj.selectedCountryIndex === 2  && financialScoreObj.currentTabResult.bottomCountryList.length === 0" >
                            Privacy filter applied for selection less than 10 people
                           </aon-p-elg>
                        </div>
                    </div>
                </aon-summary-card-content>
            </aon-summary-card>
        </div>
    </div>
    <div *ngIf="!financialScoreObj.isCurrentTabSelected">
        <div>
            <aon-summary-card width="99%" height="700px">
                <aon-summary-card-header>
                    <aon-h3 style="color:#001B5A;">
                        Financial Score Over Time</aon-h3>
                </aon-summary-card-header>
                <aon-summary-card-content contentwidth="97.5%" top="60px" yaxisoverflow="false">
                    <div class="columnDiv">
                        <div>
                            <br />
                        </div>
                        <div>
                            <div class="columnDiv" style="width:100%">
                                <div>
                                    <aon-h5>
                                        Display
                                    </aon-h5>
                                </div>
                                <div class="rowDiv">
                                    <div style="width:20%;">
                                        <aon-select returntype='number' width="100%"
                                        [selectedvalue]="financialScoreObj.historyTabResult.BUSelectedIndex"
                                        (onselectionchange)='onBUDisplayChange($event)'>
                                        <aon-select-option value=1>Top 5
                                        </aon-select-option>
                                        <aon-select-option value=2>Bottom 5</aon-select-option>
                                    </aon-select>
                                    </div>                                    
                                    <div *ngIf="financialScoreObj.historyTabResult.BUSelectedIndex === 2"  style='margin-left:20px; margin-top: 10px;'>
                                        <aon-switch [switchon] ="financialScoreObj.historyTabResult.ParticipationLevelHasBottomRankingUsers" (ontoggle)="onBottom5HasUsersChange()">Excludes Zeroes?</aon-switch>
                                      </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <br />
                        </div>
                        <div>
                            <aon-h3>
                                {{
                                    financialScoreObj.historyTabResult.BUSelectedIndex === 1 
                                    ? '5 Top Business Units': '5 Bottom Business Units'
                                }}
                            </aon-h3>
                        </div>
                        <div>
                            <br />
                        </div>
                        <div style="height: 480px; width: 100%; margin-left:-35px; margin-top:20px;">
                            <aon-multi-line-chart
                                *ngIf="financialScoreObj && financialScoreObj.historyTabResult.topBusinessUnitList.length > 0 && financialScoreObj.historyTabResult.BUSelectedIndex === 1"
                                tooltip=true [data]="financialScoreObj.historyTabResult.topBusinessUnitList"
                                xaxisticksize="d" tooltipleftposition="82%" tooltiptopposition="200px"
                                [xaxistickformat] = "'%b-%y'"
                                [yaxismaxnumber] = "chartMaxYNumber"
                                yaxistitle="Score" [customtickdata]="financialScoreObj.historyTabResult.CustomTickArr"
                                width="87%" height="98%">
                            </aon-multi-line-chart>

                            <aon-multi-line-chart
                                *ngIf="!financialScoreObj.historyTabResult.ParticipationLevelHasBottomRankingUsers && financialScoreObj.historyTabResult.bottomBusinessUnitList.length > 0 && financialScoreObj.historyTabResult.BUSelectedIndex === 2"
                                tooltip=true [data]="financialScoreObj.historyTabResult.bottomBusinessUnitList"
                                xaxisticksize="d" tooltipleftposition="82%" tooltiptopposition="200px"
                                [yaxismaxnumber] = "chartMaxYNumber"
                                [xaxistickformat] = "'%b-%y'"
                                [customtickdata]="financialScoreObj.historyTabResult.CustomTickArr" yaxistitle="Score"
                                width="87%" height="98%">
                            </aon-multi-line-chart>
                            <aon-multi-line-chart
                                *ngIf="financialScoreObj.historyTabResult.ParticipationLevelHasBottomRankingUsers && financialScoreObj.historyTabResult.bottomWithoutZeroBusinessUnitList.length > 0 && financialScoreObj.historyTabResult.BUSelectedIndex === 2"
                                tooltip=true [data]="financialScoreObj.historyTabResult.bottomWithoutZeroBusinessUnitList"
                                xaxisticksize="d" tooltipleftposition="82%" tooltiptopposition="200px"
                                [yaxismaxnumber] = "chartMaxYNumber"
                                [xaxistickformat] = "'%b-%y'"
                                [customtickdata]="financialScoreObj.historyTabResult.CustomTickArr" yaxistitle="Score"
                                width="87%" height="98%">
                            </aon-multi-line-chart>
                            <aon-p-elg  *ngIf="financialScoreObj.historyTabResult.topBusinessUnitList.length === 0 && financialScoreObj.historyTabResult.BUSelectedIndex === 1">Privacy filter applied for selection less than 10 people</aon-p-elg>
                            <aon-p-elg *ngIf="financialScoreObj.historyTabResult.bottomBusinessUnitList.length === 0 && financialScoreObj.historyTabResult.BUSelectedIndex === 2">Privacy filter applied for selection less than 10 people</aon-p-elg>
                        </div>
                    
                    </div>
                </aon-summary-card-content>
            </aon-summary-card>
        </div>
        <div>
            <br />
        </div>
        <div>
            <aon-summary-card width="99%" height="900px">
                <aon-summary-card-header>
                    <aon-h3 style="color:#001B5A;">
                        Change in Scores
                    </aon-h3>
                </aon-summary-card-header>
                <aon-summary-card-content contentwidth="97.5%" top="60px" yaxisoverflow="false">
                    <div class="columnDiv">
                        <div>
                            <br />
                        </div>                       
                        <!-- <div>
                            <aon-p-elg>{{ getPeriodDetail()}}.</aon-p-elg>
                        </div>
                        <div>
                            <br />
                        </div> -->
                        <div class="rowDiv">
                            <div style="flex:1; border-radius:5px; border: 1px solid rgb(155,155,155);">
                                <aon-bar-chart *ngIf="financialScoreObj.historyTabResult.participationList.length > 0"
                                    (onbarclick)="participartionBarClick($event)" yaxistitle="Participants" width="98%"
                                    [gridlines]="financialScoreObj.historyTabResult.blankArr" maxyaxisnumber="300"
                                    xaxistitle="Change in Score" charttitle="" showbarvalue="true"
                                    [data]="financialScoreObj.historyTabResult.participationList" height="620px">
                                </aon-bar-chart>

                                <div class="marginLeft20 marginTop20"
                                    *ngIf="financialScoreObj.historyTabResult.participationList.length === 0">
                                    <aon-p-elg>Privacy filter applied for selection less than 10 people</aon-p-elg>
                                </div>
                            </div>
                            <div style="flex:1">
                                <aon-navigation-summary-card colorindex="12" height="720px" width="98%">
                                    <aon-navigation-summary-card-header
                                        [naviconshown]="false"
                                        [previcondisabled]="prevIconDisabled()" headerheight="100px"
                                        [nexticondisabled]="nextIconDisabled()" (onprevclick)="prevIconClick($event)"
                                        (onnextclick)="nextIconClick($event)">
                                        <div
                                            style="display: flex; flex-direction:column; justify-content:space-between">

                                            <aon-h3 *ngIf="financialScoreObj.historyTabResult.selectedParticipantRow">
                                                {{ financialScoreObj.historyTabResult.selectedParticipantRow.name }}
                                                Point Change
                                            </aon-h3>
                                            <aon-h3 *ngIf="!financialScoreObj.historyTabResult.selectedParticipantRow">
                                                0 Point Change
                                            </aon-h3>
                                            <div style="margin-left: 20px;">
                                                <aon-p-lg
                                                    *ngIf="financialScoreObj.historyTabResult.selectedParticipantRow">
                                                    <b style="color:#696969">{{ financialScoreObj.historyTabResult.selectedParticipantRow.value | number:'1.0':'en-US'}}
                                                        Participants</b>
                                                </aon-p-lg>
                                            </div>
                                            <div *ngIf="!financialScoreObj.historyTabResult.selectedParticipantRow">
                                                <br />
                                            </div>
                                            <div style="width: 98%;">
                                                <aon-horizontaltab-list
                                                    (onchange)="participantDemoGraphicTabChanged($event)">
                                                    <aon-horizontaltab
                                                        *ngFor="let tabRow of financialScoreObj.historyTabResult.participantTabList;"
                                                        [value]="tabRow.value" [selected]="tabRow.value === financialScoreObj.selectedParticipantTabIndex">
                                                        {{ tabRow.name}}
                                                    </aon-horizontaltab>
                                                </aon-horizontaltab-list>
                                            </div>
                                        </div>
                                    </aon-navigation-summary-card-header>
                                    <aon-navigation-summary-card-content top="190px" style="padding-bottom: 0%;"
                                        contentheight="95%" contentwidth="98%">
                                        <div style="width:35%">
                                            <aon-select *ngIf="financialScoreObj.selectedParticipantTabIndex === 2" 
                                            returntype='number' width="100%"
                                                [selectedvalue]="financialScoreObj.historyTabResult.participationSelectedBUIndex"
                                                (onselectionchange)='onParticipationBUDisplayChange($event)'>
                                                <aon-select-option value=1>Top 10
                                                </aon-select-option>
                                                <aon-select-option value=2>Bottom 10</aon-select-option>
                                            </aon-select>
                                        </div>
                                        <div *ngIf="financialScoreObj.historyTabResult.selectedParticipantRow"
                                            style="width: 98%; height: 65%;" [ngClass]="{'overflow-y-clip': financialScoreObj.selectedParticipantTabIndex === 1, 'overflow-y-auto': financialScoreObj.selectedParticipantTabIndex === 2}">
                                            
                                                <aon-pyramid-chart
                                                    *ngIf="financialScoreObj.selectedParticipantTabIndex === 1 && financialScoreObj.historyTabResult.selectedParticipantRow.participantAgeList.length > 0"
                                                    colorindex="2"
                                                    [height]="getHeight(financialScoreObj.historyTabResult.selectedParticipantRow.participantAgeList)"
                                                    [data]="financialScoreObj.historyTabResult.selectedParticipantRow.participantAgeList" 
                                                    isnegativeshown ="false" 
                                                    displayicons=true width="98%"  xaxistitle="Participants">
                                                </aon-pyramid-chart>
                                                <aon-pyramid-chart
                                                    *ngIf="financialScoreObj.selectedParticipantTabIndex === 2 && financialScoreObj.historyTabResult.participationSelectedBUIndex === 1 && financialScoreObj.historyTabResult.selectedParticipantRow.topParticipantBusinessUnitList.length > 0"
                                                    colorindex="2"
                                                    [height]="getHeight(financialScoreObj.historyTabResult.selectedParticipantRow.topParticipantBusinessUnitList)"
                                                    [data]="financialScoreObj.historyTabResult.selectedParticipantRow.topParticipantBusinessUnitList" 
                                                    isnegativeshown ="false" 
                                                    displayicons=true width="98%" xaxistitle="Participants">
                                                </aon-pyramid-chart>

                                                <aon-pyramid-chart
                                                    *ngIf="financialScoreObj.selectedParticipantTabIndex === 2 && financialScoreObj.historyTabResult.participationSelectedBUIndex === 2 && financialScoreObj.historyTabResult.selectedParticipantRow.bottomParticipantTopBusinessUnitList.length > 0"
                                                    colorindex="2"
                                                    [height]="getHeight(financialScoreObj.historyTabResult.selectedParticipantRow.bottomParticipantTopBusinessUnitList)"
                                                    [data]="financialScoreObj.historyTabResult.selectedParticipantRow.bottomParticipantTopBusinessUnitList" 
                                                    isnegativeshown ="false" 
                                                    displayicons=true width="98%" xaxistitle="Participants">
                                                </aon-pyramid-chart>

                                                <div class="marginLeft20 marginTop20"
                                                    >
                                                    <aon-p-elg *ngIf="financialScoreObj.historyTabResult.selectedParticipantRow.participantAgeList.length === 0 && financialScoreObj.selectedParticipantTabIndex === 1"> Privacy filter applied for selection less than 10 people</aon-p-elg>
                                                    <aon-p-elg *ngIf="financialScoreObj.historyTabResult.participationSelectedBUIndex === 1 && financialScoreObj.historyTabResult.selectedParticipantRow.topParticipantBusinessUnitList.length === 0 && financialScoreObj.selectedParticipantTabIndex === 2"> Privacy filter applied for selection less than 10 people</aon-p-elg>
                                                    <aon-p-elg *ngIf="financialScoreObj.historyTabResult.participationSelectedBUIndex === 2 && financialScoreObj.historyTabResult.selectedParticipantRow.bottomParticipantTopBusinessUnitList.length === 0 && financialScoreObj.selectedParticipantTabIndex === 2"> Privacy filter applied for selection less than 10 people</aon-p-elg>
                                                </div>
                                            

                                        </div>
                                        <div *ngIf="!financialScoreObj.historyTabResult.selectedParticipantRow"
                                            style="height: 70%; width: 100%; ">
                                            <div style="overflow:hidden;">
                                                <div class="marginLeft20 marginTop20">
                                                    <aon-p-elg>Privacy filter applied for selection less than 10 people</aon-p-elg>
                                                </div>
                                            </div>
                                        </div>
                                    </aon-navigation-summary-card-content>
                                </aon-navigation-summary-card>
                            </div>
                        </div>
                    </div>
                </aon-summary-card-content>
            </aon-summary-card>
        </div>
    </div>

</div>
<div class='loadingOverlayNew' fxLayout="column" *ngIf="!financialScoreObj && isLoading" style="overflow: hidden;">
    <aon-loading-spinner></aon-loading-spinner>
</div>


<div class="contentPadding" fxLayout="column" style="height: 83vh;" *ngIf="!financialScoreObj && loaded">
    <aon-h1 class="title" style="margin-top:50px;"> Privacy filter applied for selection less than 10 people.</aon-h1>
</div>


<div class="columnDiv contentPadding" *ngIf="!financialScoreObj && errorMsg">
    <div>
        <aon-p-elg style="color:red;">{{errorMsg}}</aon-p-elg>
    </div>
</div>