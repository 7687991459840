import { Component, OnInit, Input, ElementRef } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-radialshape',
  templateUrl: './radialshape.component.html',
  styleUrls: ['./radialshape.component.css']
})
export class RadialshapeComponent implements OnInit {

  constructor(private elementRef:ElementRef) { }

  @Input() width = 160;
  @Input() height = 160;
  @Input() points = 6;
  @Input() fill = 'gray';

  ngOnChanges() {
    this.generateShape();
  }

  ngOnInit() {
    this.generateShape();
  }

  generateShape() {
    
    const width = this.width;
    const height = this.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const numPoints = this.points;
    const radius = height > width ? height / 2 : width / 2;

    d3.select(this.elementRef.nativeElement).select('#viz').select('svg').remove();

    var svg = d3.select(this.elementRef.nativeElement).select('#viz')
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const points = d3.range(numPoints)
      .map(i => {
        const angle = i / numPoints * Math.PI * 2 + Math.PI;
        return {
          x: Math.sin(angle) * radius + centerX,
          y: Math.cos(angle) * radius + centerY
        };
      });

    const wheelLines = d3.range(numPoints).map(i => ([
      points[i].x, points[i].y
    ]));

    var lineGenerator = d3.line();
    var pathString = lineGenerator(wheelLines);
    
    svg.append('path')
      .attr('d', pathString)
      .attr('fill', this.fill);
  }

}
