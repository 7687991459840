export const locale = {
    lang: 'es-mx',
    data: {
      'AddFilter': {
          'ClientName': 'Nombre de cliente',
          'Entity': 'Entidad',
          'Division': 'División', 
          'Plan': 'Plan',
          'TimePeriod': 'Periodo de tiempo',                
          'PolicyType': 'Tipo de póliza',
          'AgeBand': 'Rango de Edad',
          'Gender': 'Género',
          'ClaimType': 'Tipo de Reclamación',
          'ClaimType2': 'Tipo de Reclamación 2',
          'ClaimType3': 'Tipo de Reclamación 3',
          'Relationship': 'Parentesco',
          'DiagnosisCategory': 'Categoria de Diagnóstico',
          'DiagnosisChapter': 'Capítulo de Diagnóstico',
          'Location': 'Ubicación',
          'IsChronic': 'Crónico',
          'Carrier': 'Portador',
          'LOB': 'Sub-Tipo',
          'SubLOB': 'Tipo de pago',
          'Network': 'Red',
          'ProviderName': 'Nombre del proveedor',
          'ProviderLocation': 'Ubicación del Proveedor',
          'ProviderType': 'Tipo del Proveedor'
      }
  }
};
