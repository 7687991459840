export const locale = {
    lang: 'en',
    data: {
        'StoriesAdmin': {
            'StoriesAdministration': 'STORIES ADMINISTRATION',
            'Stories': 'Stories',
            'NewStory': 'NEW STORY',
            'Users': 'Users',
            'Save': 'SAVE',
            'SelectAll': 'SELECT ALL',
            'Success': 'Success',
            'Name': 'Name',
            'CreatedBy': 'Created By',
            'CreatedOn': 'Created On',
            'userColumnDefs': [
                { headerName: 'Email', field: 'email', sort: 'asc' },
                { headerName: 'UserName', field: 'userName', sort: 'asc' }
            ]
        }
    }
};
