<strong>Tableau Projects</strong>
<br><br>
<ng-container *ngFor="let proj of tableauProjects">
    {{proj.name}}<br>
    <mat-select placeholder="Tableau Project" [(ngModel)]="proj.guid"
        [ngModelOptions]="{standalone: true}">
        <mat-option [value]="p.id" *ngFor="let p of allTableauProjects">
            <span *ngIf="p.id">{{p.name}} ({{p.id}})</span>
        </mat-option>
    </mat-select>
    <br><br>
</ng-container>
<div layout="row">
  <span fxFlex></span>
  <button mat-raised-button [disabled]="isLoadingUpdate" class="mat-accent fixed-height" (click)="update()">
    Update
  </button>
</div>