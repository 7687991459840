export const locale = {
    lang: 'es-cl',
    data: {
        'ClaimsOverview': {
            'Prior': 'Anterior',
            'Current': 'Actual',
            'ClaimType': 'Prestación',
            'Relationship': 'Parentesco',
            'Entity': 'Razón Social',
            'Network': 'Network',
            'Location': 'Grupo',
            'Total': 'Total',
            'ClaimsOverview': 'Resumen de prestaciones',
            'TotalPaidClaim': 'Total Monto Reembolsado',
            'NOOFCLAIMS': 'No de prestaciones',
            'NOOFCLAIMANTS': 'No de asegurados',
            'Claim': 'Reclamo',
            'Claimant': 'Reclamante',
            'Count': 'Count',
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'ClaimsReportedByPaidDate': 'Reembolsos reportados por fecha de pago',
            'ClaimsReportedByIncurredDate': 'Reembolsos reportadas por fecha incurrida',
            'DateRange': 'to'
        }
    }
};
