import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent implements OnInit {

  message: string = 'Loading...'

  constructor(
    public dialogRef: MatDialogRef<LoadingDialogComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public options: any) { 
      if (options.message) this.message = options.message;
    }

  ngOnInit(): void {
    this._changeDetectorRef.detectChanges();
  }

}
