<div style="min-height: 795px; padding: 8px; overflow: auto; position: relative;" fxLayout="row">
  <div fxFlex fxLayout="column">
    <div class="dash-header">
      <span>Frequency of Use & Average Cost</span>
      <button mat-icon-button style="float: right" (click)="showFilter = !showFilter" *ngIf="!isFilterOptionsLoading">
        <app-filter-button [isClientName]='true' [isEntity]='true' [isPolicyType]='true' [isPlan]='true' [isClaimType]='true'
        [isGender]='true' [isRelationship]='true' [isAgeBand]='true' [isLocation]='true'></app-filter-button>
      </button>
    </div>

    <div fxLayout="row" class="gridpadding">
      <div [fxFlex]="100" class="dashed">
        <div fxLayout="row">
          <div [fxFlex]="100" style="margin-top: 10px;font-size: 15px;">
            <div fxLayout="row" class="tablerowCurrent " *ngFor="let list of dataList" fxLayoutAlign="center center">
              <div fxFlex="20%" fxLayoutAlign="center center">
                <div [fxFlex] *ngIf="list.isCurrent" class="dotedRight" style="font-size: 20px;">{{list.dimensionName }}
                </div>
              </div>
              <div fxFlex="60%" fxLayoutAlign="center center">
                <div class="dotedRight" [fxFlex] style=" flex-direction: column;"
                  [ngClass]="list.isCurrent?'purpleClass':'blueClass'">
                  <ng-container *ngIf="true">
                    <app-bar [barColor]="list.isCurrent?purpleClass:blueClass" [value]="list.percentageShare" [max]="70"
                      [fullTotalForPercentage]="allClaimsTotal" [unit]="''"
                      [valueDecimalPlace]="2"></app-bar>
                  </ng-container>

                </div>
              </div>
              <div fxFlex="20%" fxLayoutAlign="center center" style="display:inline!important;">
                <div [fxFlex] style="text-align: center; font-size: 12px;" *ngIf="!(list.percentageChange === 0)">
                  <mat-icon style="vertical-align: sub;font-weight: bolder;" style="color:rgb(216, 211, 211);"
                    *ngIf="list.percentageChange>0">arrow_upward</mat-icon>
                  <mat-icon style="vertical-align: sub;font-weight: bolder;" style="color:rgb(216, 211, 211);"
                    *ngIf="list.percentageChange<0">arrow_downward</mat-icon>
                  {{list.percentageChange.toFixed(1)}} %
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div fxLayout="row" class="frequency-header" *ngIf="(properties.showHide == ShowHideOption.Show)">
      <span>Frequency of Use</span>
    </div>

    <div fxLayout="row" class="gridpadding" *ngIf="(properties.showHide == ShowHideOption.Show)">
      <div [fxFlex]="100">
        <app-line></app-line>
      </div>
    </div>

  </div>
  <div style="width: 200px; padding: 15px 0px 0px 16px; border-left: 1px solid #EEE" fxLayout="column">
    <div id="entityDivisionFilters" *ngIf="showFilter">
      <app-additional-filters (action)="additionalFilterSelectionChanged($event)" [filterOptions]='filterOptions'
        [isClientName]='true' [isEntity]='true' [isPolicyType]='true' [isPlan]='true' [isClaimType]='true'
        [isGender]='true' [isRelationship]='true' [isAgeBand]='true' [isLocation]='true'></app-additional-filters>
    </div>
    <div fxFlex="55" *ngIf="!showFilter">
      <div fxLayout="row" *ngFor="let leg of legendList" style="margin-bottom: 10px; margin-top:15%;">
        <svg width="15" height="15">
          <rect width="15" height="15"[attr.fill]="leg.color"></rect> 
        </svg>
        <span style="margin-left:5px;">{{(leg.name)}}</span> <br>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="dash-option" style="margin-top: 20%;"
        [ngClass]="{'dash-option-selected': properties.showHide == ShowHideOption.Show}"
        (click)="showHideFilter(ShowHideOption.Show, 'showHide')">
        <span>Show</span></div>
      <div fxLayout="row" fxLayoutAlign="center center" class="dash-option"
        [ngClass]="{'dash-option-selected': properties.showHide == ShowHideOption.Hide}"
        (click)="showHideFilter(ShowHideOption.Hide, 'showHide')">
        <span>Hide</span>
      </div>
    </div>
  </div>
</div>