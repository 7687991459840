import { Component, OnInit,Input } from '@angular/core';
import { NumberFormattingService } from 'app/services/number-formatting.service';

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.css']
})
export class BarComponent implements OnInit {
    @Input() barColor = 'red';
    @Input() value = 0;
    @Input() unit = '';
    @Input() max = 0;
    @Input() benchwidth = 0;
    @Input() templateID = '';
    @Input() valueDecimalPlace = 0;
    @Input() fullTotalForPercentage = 0;
    @Input() country = '';

    barRectanglelement: HTMLElement;
    constructor( private _numberFormatService: NumberFormattingService) { 
    }

    ngOnInit() {
        
    }

    getBenchPos(){ 
        return ` translate(${this.benchwidth}, 1)`;
    }

    getWidth(){
        return '' + ((this.value / this.max) * 100) + '%'
    }

    getPercentText(){
        if (this.unit === '')
        { 
            let newVal = (this.value === undefined || this.value === null) ? 0: this.value
            return (newVal).toFixed(this.valueDecimalPlace); 
        }
        else if (this.unit === 'ProviderUnit')
        { 
        return this.isCountryLATEM() ?  
            ` ${ this._numberFormatService.ToAbbrevNumberStringLatem(this.value, this.valueDecimalPlace )} (${((this.value / (this.max)) * 100).toFixed(1)} %)` :
            ` ${ this._numberFormatService.ToAbbrevNumberString(this.value, this.valueDecimalPlace )} (${((this.value / (this.max)) * 100).toFixed(1)} %)`;
        }
        else if (this.unit === 'MaxAndFullTotalForPercentage')
        { 
            if (this.fullTotalForPercentage === 0 )
            {
                return this.isCountryLATEM() ? 
                    ` ${ this._numberFormatService.ToAbbrevNumberStringLatem(this.value, this.valueDecimalPlace )} ` :
                    ` ${ this._numberFormatService.ToAbbrevNumberString(this.value, this.valueDecimalPlace )} `;
            }

            return this.isCountryLATEM() ?  
                ` ${ this._numberFormatService.ToAbbrevNumberStringLatem(this.value, this.valueDecimalPlace )} (${((this.value / (this.fullTotalForPercentage)) * 100).toFixed(1)} %)` :
                ` ${ this._numberFormatService.ToAbbrevNumberString(this.value, this.valueDecimalPlace )} (${((this.value / (this.fullTotalForPercentage)) * 100).toFixed(1)} %)`;
        }
        else 
        { 
            return this.isCountryLATEM() ? 
                ` ${ this._numberFormatService.ToAbbrevNumberStringLatem(this.value, this.valueDecimalPlace )} ${this.unit} (${((this.value / (this.max)) * 100).toFixed(1)} %)` :
                ` ${ this._numberFormatService.ToAbbrevNumberString(this.value, this.valueDecimalPlace )} ${this.unit} (${((this.value / (this.max)) * 100).toFixed(1)} %)`;
        }
    }

    private isCountryLATEM(): boolean
    {
        const countryLATEM = ['Chile', 'Colombia', 'Ecuador', 'Mexico', 'Peru'];

        return countryLATEM.indexOf(this.country) >= 0;
    }
}
