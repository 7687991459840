<div class="page-layout blank" style="padding: 6px 12px" fusePerfectScrollbar>


    <!-- Top Navigation BEGIN-->
    
        <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
            <mat-form-field fxFlex>
                <input id="SearchOrStoryName" matInput placeholder="Search.." class="mat-primary" [(ngModel)]="title" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <span fxFlex></span>
    
            <span *ngIf="dashboardList.length > 0" style="margin-right: 20px">
                {{dashIndex + 1}} / {{dashboardList.length}}
            </span>
            
            <button mat-raised-button style="max-height: 36px" (click)="previous()">
                    <mat-icon class="example-icon">chevron_left</mat-icon>
            </button>
            <button mat-raised-button style="max-height: 36px" (click)="forward()">
                    <mat-icon class="example-icon">chevron_right</mat-icon>
            </button>
            <button mat-raised-button style="max-height: 36px" (click)="changePreviewMode()">
               Preview 
            </button>
            <button mat-raised-button style="max-height: 36px" (click)="export()">
                Queue
            </button>
            <button  *ngIf="isAdminMode" mat-raised-button class="mat-primary" style="max-height: 36px" (click)="save()">
                Save
            </button>
        </div>
    
    <!-- Top Navigation   -->
    
    
    <!-- Chart Container BEGIN--> 
    
    
    <div *ngIf="!isAdminMode" fxLayout="row" fxLayoutGap="12px">
        <div fxFlex fxLayout="column" fxLayoutGap="8px">
            <div *ngFor="let dash of dashboardList; let i = index">
                <mat-card *ngIf="i == dashIndex">
                    <div fxLayout="row" fxLayoutAlign="end end">
                        <!-- <button mat-icon-button class="mat-accent">
                                <mat-icon>save</mat-icon>
                            </button> -->
                        <button mat-button (click)="dash.isPrint = !dash.isPrint"
                            [ngClass]="dash.isPrint ? 'mat-accent' : ''">
                            <mat-icon>print</mat-icon>
                        </button>
                    </div>
                    <iframe [src]="dash.url | safe" style="border: none; width: 100%; height: 75vh"></iframe>
                </mat-card>
            </div>
        </div>
    </div>
    <!-- Chart Container END --> 
    
    <!-- Thumbnails Filter : Export / Story Builder. BEGIN-->
    
     
      <ul  *ngIf="isAdminMode"  class="flex-container"  fxLayoutGap="12px" style="  box-sizing: border-box; display: flex; place-content: space-around; align-items: left;">
            <li  class=" fuse-card ThumbnailDashboards"  *ngFor="let lib of library; let i = index"  >
                    <mat-checkbox class="mat-accent"  (click)="addDashboard(i)" >              
                            <mat-card-header> 
                                <mat-card-title class="dashboardName">{{lib.name}}</mat-card-title> 
                            </mat-card-header>  
                           
                      <img  alt="{{lib.name}}"  src="{{lib.imageBase64String}}" style="width:150px;margin-top:7%; margin-left:15%"  >
                    </mat-checkbox> 
                    <!-- <mat-icon class="example-icon">favorite</mat-icon>
                    <mat-icon class="example-icon">delete</mat-icon> -->
            </li>
             
          </ul>
     
    <!-- Thumbnails Filter : Export / Story Builder. END-->
    
    
     