import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fwb-error-message',
  templateUrl: './fwb-error-message.component.html',
  styleUrls: ['./fwb-error-message.component.scss']
})
export class FwbErrorMessageComponent implements OnInit {
  @Input() isError = false;
  @Input() message = "No Data Found.";
  constructor() { }

  ngOnInit(): void {
  }

}
