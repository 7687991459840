<div
    id="claimProDiagDashboard"
    style="min-height: 795px; padding: 8px; overflow: auto; position: relative;"
    fxLayout="row"
>
    <div
        fxFlex
        fxLayout="column"
    >
        <div
            id="claimProDiagHeader"
            class="dash-header"
        >
            <span *ngIf="properties.id == 'ClaimCostByTopProvider'">{{ 'ClaimCostTopProvider.ClaimTopProviders' |
                translate }}</span>
            <span *ngIf="properties.id == 'ClaimCostByTopDiagnoses'">{{ 'ClaimCostTopProvider.ClaimCostByTopDiagnoses' |
                translate }}</span>
            <span *ngIf="properties.id == 'ProviderVDiagnosis'">{{ 'ClaimCostTopProvider.ProviderDiag' | translate
                }}</span>
            <button
                id="claimProDiagHeaderBtn"
                mat-icon-button
                style="float: right"
                (click)="showFilter = !showFilter"
                *ngIf="!isFilterOptionsLoading"
            >
                <app-filter-button
                    [isClientName]='true'
                    [isEntity]='true'
                    [isPolicyType]='true'
                    [isPlan]='true'
                    [isClaimType]='true'
                    [isClaimType2]='true'
                    [isClaimType3]='true'
                    [isProviderType]='true'
                    [isGender]='true'
                    [isRelationship]='true'
                    [isAgeBand]='true'
                    [isLocation]='true'
                    [isLOB]='true'
                    [isSubLOB]='true'
                ></app-filter-button>
            </button>
        </div>
        <div
            id="claimProDiagHeaderNote"
            fxLayout="row"
        >
            <div
                id="claimProDiagHeaderNoteCurrency"
                class="upperNote"
                fxFlex="35"
            >
                {{ 'ClaimCostTopProvider.AllAmountsDisplayedInLocalCurrency' | translate }}
            </div>
            <div
                *ngIf="data"
                id="claimProDiagSelectUpper"
                class="upperNoteShown"
                [style.visibility]='data.shownAsPerCurrentDiagnosis !="" ? "visible" : "hidden" '
                fxFlex="55"
            >
                <!-- {{ 'ClaimCostTopProvider.ShownAsPer' | translate }} &nbsp; "{{data.shownAsPerCurrentDiagnosis}}" -->
            </div>
            <div
                id="claimProDiagHeaderPeriod"
                class="upperNote"
                fxFlex="10"
            >
                {{ currentTimePeriodLable }} {{comparablePeriod}}
            </div>
        </div>
        <div
            fxLayout="row"
            class="gridpadding"
            [ngClass]="[((properties.id == 'ClaimCostByTopProvider' || (properties.id =='ClaimCostByTopDiagnoses'))) ? 'TopProviderHeight' : '']"
        >
            <div
                id="claimProDiagTableHeadingPro"
                *ngIf="properties.id == 'ProviderVDiagnosis'"
                class="sideTitleBar tableheader"
                [fxFlex]="2"
            >
                {{ 'ClaimCostTopProvider.TopProvider' | translate }}
            </div>
            <tableau-spinner
                *ngIf="isLoadingProvider"
                style="position: absolute; top: 280px; left: 48%; z-index: 90"
            >
            </tableau-spinner>
            <div
                id="claimProDiagTable"
                [fxFlex]="properties.id == 'ProviderVDiagnosis' ? 98 : 100"
            >
                <div
                    id="claimProDiagTableHeader"
                    fxLayout="row"
                    class="tableheader"
                >
                    <div [fxFlex]="30">{{properties.id == 'ClaimCostByTopDiagnoses'?
                        ('ClaimCostTopProvider.DiagnosisName' | translate) :'ClaimCostTopProvider.ProviderName' |
                        translate }}</div>
                    <div [fxFlex]="18">{{ 'ClaimCostTopProvider.TotalPaidClaim' | translate }}</div>
                    <div [fxFlex]="10"> {{properties.claimTypeOption == ClaimantOption.Claim ?
                        ('ClaimCostTopProvider.ClaimCount' | translate ):('ClaimCostTopProvider.ClaimantCount' |
                        translate ) }} </div>
                    <div [fxFlex]="15">{{ 'ClaimCostTopProvider.ClaimsPerClaimant' | translate }}</div>
                    <div [fxFlex]="15">{{properties.claimTypeOption == ClaimantOption.Claim ?
                        ('ClaimCostTopProvider.CostPerClaim' | translate ) :('ClaimCostTopProvider.CostPerClaimant' |
                        translate )}} </div>
                    <div [fxFlex]="12">{{ 'ClaimCostTopProvider.%ChangeinCost' | translate }}</div>
                </div>
                <div *ngIf="finalTopListBindLoaded">
                    <div
                        fxLayout="row"
                        class="tablerow dashed padNoneTableRow"
                        *ngFor="let finalTopListBindRecord of data.finalTopListBind"
                        [ngClass]="(data.currentProvider == finalTopListBindRecord.dimension && data.currentProvider !== ''&& !data.currentContainerIsDiagnosis)? 'grayRow' : ''"
                        (click)="selectProvider($event, finalTopListBindRecord)"
                    >
                        <div
                            [fxFlex]="30"
                            [ngClass]="((properties.id == 'ClaimCostByTopProvider' || (properties.id =='ClaimCostByTopDiagnoses'))) ?'claimPad':''"
                            style="font-size: 14px;"
                        >
                            {{ !apacCountries.includes(properties.country) && (finalTopListBindRecord.claimantCount <
                                properties.claimantFilterValue
                                )
                                ? 'NA'
                                :
                                (finalTopListBindRecord.dimension)}}
                                </div
                            >
                                <div
                                    [fxFlex]="18"
                                    *ngIf="((properties.id == 'ClaimCostByTopProvider' || (properties.id =='ClaimCostByTopDiagnoses')))"
                                >
                                    <ng-container *ngIf="parentLoaded">
                                        <div style="width:50%;">
                                            <app-bar
                                                style="width: 100%"
                                                [barColor]="barColorPurple"
                                                [value]="finalTopListBindRecord.claimsTotal"
                                                [max]="maxClaimTotal"
                                                [fullTotalForPercentage]="topProviderTotal"
                                                [unit]="'MaxAndFullTotalForPercentage'"
                                                [valueDecimalPlace]="4"
                                            >
                                            </app-bar>
                                        </div>
                                    </ng-container>
                                </div>
                                <div
                                    [fxFlex]="18"
                                    *ngIf="properties.id == 'ProviderVDiagnosis'"
                                    style="text-align: center !important "
                                >
                                    {{finalTopListBindRecord.claimsTotal | roundToDecimalPlace: 0 | numberFormat}}
                                </div>
                                <div
                                    [fxFlex]="10"
                                    style="text-align: center !important "
                                    *ngIf="((properties.id == 'ClaimCostByTopProvider' || (properties.id =='ClaimCostByTopDiagnoses')))"
                                >
                                    {{properties.claimTypeOption == ClaimantOption.Claim ? (
                                    finalTopListBindRecord.claimsCount | roundToDecimalPlace: 0 | numberFormat) :
                                    (finalTopListBindRecord.claimantCount | roundToDecimalPlace: 0 | numberFormat) }}
                                    <span font-size="12px">
                                        {{ getPercentage(properties.claimTypeOption == ClaimantOption.Claim ?
                                        finalTopListBindRecord.claimsPercentage :
                                        finalTopListBindRecord.claimantsPercentage )}}
                                    </span>
                                </div>
                                <div
                                    [fxFlex]="10"
                                    style="text-align: center !important "
                                    *ngIf="properties.id == 'ProviderVDiagnosis'"
                                >
                                    {{properties.claimTypeOption == ClaimantOption.Claim ? (
                                    finalTopListBindRecord.claimsCount | roundToDecimalPlace: 0 | numberFormat) :
                                    (finalTopListBindRecord.claimantCount | roundToDecimalPlace: 0 | numberFormat) }}
                                </div>

                                <div
                                    [fxFlex]="15"
                                    style="text-align: center !important "
                                >
                                    {{finalTopListBindRecord.claimsPerClaimaint | roundToDecimalPlace: 1 |
                                    numberFormat}}</div>
                                <div
                                    [fxFlex]="15"
                                    style="text-align: center !important "
                                >
                                    <app-bar
                                        style="width: 100%"
                                        [barColor]="barColorBlue"
                                        [valueDecimalPlace]="properties.country === 'Chile' ? 2 : 0"
                                        [value]="properties.claimTypeOption == ClaimantOption.Claim ? finalTopListBindRecord.averageCostPerClaim  : finalTopListBindRecord.averageCostPerClaimant"
                                        [max]="properties.claimTypeOption == ClaimantOption.Claim ? maxCostClaim : maxCostClaimant"
                                        [unit]="unitCost"
                                        [benchwidth]="!eligibleForBenchmark ? 0 : properties.claimTypeOption == ClaimantOption.Claim
                                            ? benchwidthClaim 
                                            : benchwidthClaimant"
                                        [templateID]="properties.id"
                                        [country]="properties.country"
                                    ></app-bar>
                                </div>
                                <div
                                    [fxFlex]="12"
                                    style="padding-right:5px; text-align: center !important "
                                >
                                    <div
                                        [fxFlex]="70"
                                        style="text-align: right; margin-top:1px; "
                                        *ngIf="!(finalTopListBindRecord.yoYChange === 0)"
                                    >
                                        <span>{{(finalTopListBindRecord.yoYChange | roundToDecimalPlace: 1 |
                                            numberFormat )+ ' %'}} </span>
                                    </div>
                                    <div
                                        [fxFlex]="30"
                                        style="text-align: left; "
                                        *ngIf="!(data.yoYChange === 0)"
                                    >
                                        <mat-icon
                                            style="color:rgb(216, 211, 211); "
                                            *ngIf="finalTopListBindRecord.yoYChange>0"
                                        >arrow_upward</mat-icon>
                                        <mat-icon
                                            style="color:rgb(216, 211, 211); "
                                            *ngIf="finalTopListBindRecord.yoYChange<0"
                                        >arrow_downward</mat-icon>
                                    </div>
                                </div>
                        </div>
                        <div
                            fxLayout="row"
                            *ngIf="((properties.id == 'ClaimCostByTopProvider' || (properties.id =='ClaimCostByTopDiagnoses'))) "
                            style="margin-top: 10px"
                        >
                            <div [fxFlex]="65"></div>
                            <div
                                [fxFlex]="15"
                                *ngIf="((properties.id == 'ClaimCostByTopProvider') && (properties.benchmarkLevel == 3))"
                                style="font-weight: bold;"
                            > </div>
                            <div
                                id="claimProDiagBench"
                                [fxFlex]="15"
                                *ngIf="
                                eligibleForBenchmark 
                                && ((properties.id == 'ClaimCostByTopProvider') 
                                && (properties.benchmarkLevel != 3))"
                                style="font-weight: bold;"
                            >
                                {{ 'ClaimCostTopProvider.Benchmark' | translate }}:
                                {{(properties.claimTypeOption == ClaimantOption.Claim ? benchmarkClaim :
                                benchmarkClaimant) | roundToDecimalPlace: 0 | numberFormat}}
                            </div>
                            <div
                                [fxFlex]="15"
                                *ngIf="(properties.id =='ClaimCostByTopDiagnoses')"
                            ></div>
                            <div
                                id="claimProDiagFooterNote"
                                [fxFlex]="20"
                                style="text-align: right;"
                            >
                                {{properties.claimPaid === CLAIM_BASIS.PAID_BASIS ? (
                                'ClaimCostTopProvider.ClaimsReportedByPaidDate' |
                                translate ) : ( 'ClaimCostTopProvider.ClaimsReportedByIncurredDate' | translate )}}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div
                fxLayout="row"
                *ngIf="properties.id == 'ProviderVDiagnosis'"
                class="gridpadding "
                style="margin-top: 2px"
            >
                <div
                    id="claimProDiagTableHeadingDiag"
                    [fxFlex]="2"
                    class="sideTitleBar tableheader"
                >
                    {{ 'ClaimCostTopProvider.TopDiagnoses' | translate }}
                </div>
                <tableau-spinner
                    *ngIf="isLoadingDiagnoses"
                    style="position: absolute; top: 480px; left: 48%; z-index: 90"
                ></tableau-spinner>
                <div
                    id="claimProDiagTableDiag"
                    [fxFlex]="98"
                >
                    <div
                        id="claimProDiagTableDiagHeader"
                        fxLayout="row"
                        class="tableheader"
                    >
                        <div [fxFlex]="33">{{ 'ClaimCostTopProvider.DiagnosisName' | translate }}</div>
                        <div [fxFlex]="15">{{ 'ClaimCostTopProvider.TotalPaidClaim' | translate }}</div>
                        <div [fxFlex]="10">{{properties.claimTypeOption == ClaimantOption.Claim ? (
                            'ClaimCostTopProvider.ClaimCount' | translate ):( 'ClaimCostTopProvider.ClaimantCount' |
                            translate ) }}
                        </div>
                        <div [fxFlex]="15">{{ 'ClaimCostTopProvider.ClaimsPerClaimant' | translate }}</div>
                        <div [fxFlex]="15">{{properties.claimTypeOption == ClaimantOption.Claim ? (
                            'ClaimCostTopProvider.CostPerClaim' | translate ) :( 'ClaimCostTopProvider.CostPerClaimant'
                            | translate )}}
                        </div>
                        <div [fxFlex]="12">{{ 'ClaimCostTopProvider.%ChangeinCost' | translate }}</div>
                    </div>
                    <div *ngIf="finalTopListBindDiagLoaded">
                        <div
                            fxLayout="row"
                            class="tablerow dashed padNoneTableRow"
                            *ngFor="let finalTopListBindDiagRecord of data.finalTopListBindDiag"
                            [ngClass]="(data.currentDiagnosis == finalTopListBindDiagRecord.dimension && data.currentDiagnosis !== '' && data.currentContainerIsDiagnosis)? 'grayRow' : ''"
                            (click)="selectDiagnosis($event, finalTopListBindDiagRecord)"
                        >
                            <div
                                [fxFlex]="33"
                                style="font-size: 14px;"
                            >
                                {{ !apacCountries.includes(properties.country) &&
                                (finalTopListBindDiagRecord.claimantCount
                                <
                                    properties.claimantFilterValue
                                    )
                                    ? 'NA'
                                    :
                                    (finalTopListBindDiagRecord.dimension)}}
                                    </div
                                >
                                    <div [fxFlex]="15">
                                        {{finalTopListBindDiagRecord.claimsTotal | roundToDecimalPlace: 0 |
                                        numberFormat}}
                                    </div>
                                    <div
                                        [fxFlex]="10"
                                        style="text-align: center !important "
                                        *ngIf="((properties.id == 'ClaimCostByTopProvider' || (properties.id =='ClaimCostByTopDiagnoses')))"
                                    >
                                        {{properties.claimTypeOption == ClaimantOption.Claim ? (
                                        finalTopListBindDiagRecord.claimsCount | roundToDecimalPlace: 0 | numberFormat)
                                        : (finalTopListBindDiagRecord.claimantCount | roundToDecimalPlace: 0 |
                                        numberFormat) }}
                                        <span font-size="12px">
                                            {{ getPercentage(properties.claimTypeOption == ClaimantOption.Claim ?
                                            finalTopListBindDiagRecord.claimsPercentage :
                                            finalTopListBindDiagRecord.claimantsPercentage )}}
                                        </span>
                                    </div>
                                    <div
                                        [fxFlex]="10"
                                        style="text-align: center !important "
                                        *ngIf="properties.id == 'ProviderVDiagnosis'"
                                    >
                                        {{properties.claimTypeOption == ClaimantOption.Claim ? (
                                        finalTopListBindDiagRecord.claimsCount | roundToDecimalPlace: 0 | numberFormat)
                                        : (finalTopListBindDiagRecord.claimantCount | roundToDecimalPlace: 0 |
                                        numberFormat) }}
                                    </div>
                                    <div [fxFlex]="15">{{finalTopListBindDiagRecord.claimsPerClaimaint |
                                        roundToDecimalPlace: 1 | numberFormat}}</div>
                                    <div [fxFlex]="15">
                                        <ng-container *ngIf="parentLoaded">
                                            <div style="width:50%;">
                                                <app-bar
                                                    style="width: 100%"
                                                    [barColor]="barColorBlue"
                                                    [valueDecimalPlace]="properties.country === 'Chile' ? 2 : 0"
                                                    [value]="properties.claimTypeOption == ClaimantOption.Claim ? finalTopListBindDiagRecord.averageCostPerClaim  : finalTopListBindDiagRecord.averageCostPerClaimant"
                                                    [max]="properties.claimTypeOption == ClaimantOption.Claim ? maxDiagCostClaim : maxDiagCostClaimant"
                                                    [unit]="unitCost"
                                                    [country]="properties.country"
                                                ></app-bar>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div
                                        [fxFlex]="12"
                                        style="padding-right:5px; padding-top:1px; text-align: center !important "
                                    >
                                        <div
                                            [fxFlex]="70"
                                            style="text-align: right; "
                                            *ngIf="!(finalTopListBindDiagRecord.yoYChange === 0)"
                                        >
                                            <span>{{(finalTopListBindDiagRecord.yoYChange | roundToDecimalPlace: 1 | numberFormat )+ ' %'
                                                }}</span>
                                        </div>
                                        <div
                                            [fxFlex]="30"
                                            style="text-align: left; "
                                            *ngIf="!(finalTopListBindDiagRecord.yoYChange === 0)"
                                        >
                                            <mat-icon
                                                style="color:rgb(216, 211, 211); "
                                                *ngIf="finalTopListBindDiagRecord.yoYChange>0"
                                            >arrow_upward</mat-icon>
                                            <mat-icon
                                                style="color:rgb(216, 211, 211); "
                                                *ngIf="finalTopListBindDiagRecord.yoYChange<0"
                                            >arrow_downward</mat-icon>
                                        </div>
                                    </div>
                            </div>
                            <div
                                fxLayout="row"
                                style="margin-top: 5px"
                            >
                                <div
                                    [fxFlex]="35"
                                    style="padding: 3px"
                                ></div>
                                <div
                                    *ngIf="data"
                                    id="claimProDiagSelectLower"
                                    fxFlex="45"
                                    class="upperNoteShown"
                                    [style.visibility]='data.shownAsPerCurrentProvider !="" ? "visible" : "hidden" '
                                >
                                    <!-- {{ 'ClaimCostTopProvider.ShownAsPer' | translate }} &nbsp; "{{data.shownAsPerCurrentProvider}}" -->
                                </div>
                                <div
                                    id="claimProDiagFooterNoteDiag"
                                    [fxFlex]="20"
                                    style="text-align: right;"
                                >
                                    {{properties.claimPaid === CLAIM_BASIS.PAID_BASIS ? (
                                    'ClaimCostTopProvider.ClaimsReportedByPaidDate'
                                    | translate ) : ( 'ClaimCostTopProvider.ClaimsReportedByIncurredDate' | translate
                                    )}}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div
                style="width: 200px; padding: 40px 0px 0px 16px; border-left: 1px solid #EEE"
                fxLayout="column"
            >
                <div
                    id="entityDivisionFilters"
                    *ngIf="showFilter"
                >
                    <app-additional-filters
                        [country]="properties.country"
                        (action)="additionalFilterSelectionChanged($event)"
                        [filterOptions]='filterOptions'
                        [isClientName]='true'
                        [isEntity]='true'
                        [isPolicyType]='true'
                        [isPlan]='true'
                        [isClaimType]='true'
                        [isClaimType2]='true'
                        [isClaimType3]='true'
                        [isProviderType]='true'
                        [isGender]='true'
                        [isRelationship]='true'
                        [isAgeBand]='true'
                        [isLocation]='true'
                        [isLOB]='true'
                        [isSubLOB]='true'
                    ></app-additional-filters>
                </div>
                <div
                    id="claimProDiagFilters"
                    fxFlex="55"
                    *ngIf="!showFilter"
                >
                    <div
                        *ngIf="((properties.id == 'ClaimCostByTopProvider' || (properties.id =='ClaimCostByTopDiagnoses')))">
                        <div
                            id="claimProDiagBtnSelectTop"
                            fxLayout="row"
                        >
                            <span>{{ 'ClaimCostTopProvider.SelectTop' | translate }}</span>
                            <input
                                type="number"
                                [(ngModel)]="properties.claimCostTopProviderTopFilter"
                                class="topInput"
                                min="0"
                                (ngModelChange)="filterTopList(properties.claimCostTopProviderTopFilter)"
                            >
                        </div>
                    </div>
                    <div
                        id="claimProDiagBtnFilterNA"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        style="margin-top:5px;"
                        (click)="filterNAChange('filterNA', 'filterNA')"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.isExcludeNA === true }"
                    >
                        <span>{{ 'ClaimCostTopProvider.FilterNA' | translate }}</span>
                    </div>
                    <div
                        id="claimProDiagTxtCountBasis"
                        fxLayout="row"
                        style="margin-top:10px; margin-bottom: 5px;"
                    >
                        <span>{{ 'ClaimCostTopProvider.CountBasis' | translate }}</span>
                    </div>
                    <div
                        id="claimProDiagBtnClaim"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.claimTypeOption == ClaimantOption.Claim}"
                        (click)="selectionForClaimAndClaimantChanged(ClaimantOption.Claim, 'claimTypeOption') ;"
                    >
                        <span>{{ 'ClaimCostTopProvider.Claim' | translate }}</span>
                    </div>
                    <div
                        id="claimProDiagBtnClaimant"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.claimTypeOption == ClaimantOption.Claimant}"
                        (click)="selectionForClaimAndClaimantChanged(ClaimantOption.Claimant, 'claimTypeOption'); "
                    >
                        <span>{{ 'ClaimCostTopProvider.Claimant' | translate }}</span>
                    </div>
                    <div *ngIf="(properties.id =='ClaimCostByTopDiagnoses')">
                        <div
                            id="claimProDiagTxtDiagnosisLevel"
                            fxLayout="row"
                            style="margin-top:10px; margin-bottom: 5px;"
                        >
                            <span>{{ 'ClaimCostTopProvider.DiagnosisLevel' | translate }}</span>
                        </div>
                        <div
                            id="claimProDiagBtnCategory"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            class="dash-option"
                            [ngClass]="{'dash-option-selected': properties.diag == CategoryChapterOption.DiagCat}"
                            (click)="diagnosisSelectionChanged(CategoryChapterOption.DiagCat, 'diag')"
                        >
                            <span>{{ 'ClaimCostTopProvider.Category' | translate }}</span>
                        </div>
                        <div
                            id="claimProDiagBtnChapter"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            class="dash-option"
                            [ngClass]="{'dash-option-selected': properties.diag== CategoryChapterOption.DiagChapter}"
                            (click)="diagnosisSelectionChanged(CategoryChapterOption.DiagChapter, 'diag')"
                        >
                            <span>{{ 'ClaimCostTopProvider.Chapter' | translate }}</span>
                        </div>
                    </div>
                    <div
                        id="claimProDiagTxtSort"
                        fxLayout="row"
                        style="margin-top:10px; margin-bottom: 5px;"
                    >
                        <span>{{ 'ClaimCostTopProvider.Sort' | translate }}</span>
                    </div>
                    <div
                        id="claimProDiagBtnClaimsTotal"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.sort == SortOption.ClaimTotal}"
                        (click)="filterChanged(SortOption.ClaimTotal, 'sort')"
                    >
                        <span>{{ 'ClaimCostTopProvider.ClaimsTotal' | translate }}</span>
                    </div>
                    <div
                        id="claimProDiagBtnCount"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': (properties.sort == SortOption.CountClaim) || (properties.sort == SortOption.CountClaimant)}"
                        (click)="filterChanged( properties.claimTypeOption == ClaimantOption.Claim ? 'claimsCount': 'claimantCount', 'sort')"
                    >
                        <span>{{ 'ClaimCostTopProvider.Count' | translate }}</span>
                    </div>
                    <div
                        id="claimProDiagBtn"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': (properties.sort == 'averageCostPerClaim') || (properties.sort == 'averageCostPerClaimant')}"
                        (click)="filterChanged( properties.claimTypeOption == ClaimantOption.Claim ? 'averageCostPerClaim' : 'averageCostPerClaimant', 'sort')"
                    >
                        <span>{{ 'ClaimCostTopProvider.AverageCost' | translate }}</span>
                    </div>

                    <div
                        id="claimProDiagBtnChangeinCost"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.sort == 'yoYChange'}"
                        (click)="filterChanged('yoYChange', 'sort')"
                    >
                        <span>{{ 'ClaimCostTopProvider.%ChangeinCost' | translate }}</span>
                    </div>

                    <ng-container *ngIf="eligibleForBenchmark">
                        <div *ngIf="properties.id == 'ClaimCostByTopProvider'">
                            <div
                                id="claimProDiagBtnBenchmarkMenu"
                                fxLayout="row"
                            >
                                <span>{{ 'ClaimCostTopProvider.Benchmark' | translate }}
                                    <button
                                        mat-icon-button
                                        (click)="showBenchmark = !showBenchmark"
                                    >
                                        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                                    </button></span>
                            </div>
                            <div *ngIf="showBenchmark">
                                <div
                                    id="claimProDiagBtnIncludeClient"
                                    fxLayout="row"
                                    fxLayoutAlign="center center"
                                    (click)="selectionChanged(1, 'benchmarkInclude')"
                                    class="dash-option"
                                    [ngClass]="{'dash-option-selected': properties.benchmarkInclude == 1 }"
                                >
                                    <span>{{ 'ClaimCostTopProvider.IncludeClient' | translate }}</span>
                                </div>
                                <div
                                    id="claimProDiagBtnExcludeClient"
                                    fxLayout="row"
                                    fxLayoutAlign="center center"
                                    (click)="selectionChanged(2, 'benchmarkInclude')"
                                    class="dash-option"
                                    [ngClass]="{'dash-option-selected': properties.benchmarkInclude == 2 }"
                                >
                                    <span>{{ 'ClaimCostTopProvider.ExcludeClient' | translate }}</span>
                                </div>
                                <div
                                    id="claimProDiagBtnPortfolio"
                                    fxLayout="row"
                                    fxLayoutAlign="center center"
                                    style="margin-top:10px; "
                                    (click)="selectionChanged(1, 'benchmarkLevel')"
                                    class="dash-option"
                                    [ngClass]="{'dash-option-selected': properties.benchmarkLevel == 1 }"
                                >
                                    <span> {{ 'ClaimCostTopProvider.Portfolio' | translate }}</span>
                                </div>
                                <div
                                    id="claimProDiagBtnIndustry"
                                    fxLayout="row"
                                    fxLayoutAlign="center center"
                                    (click)="selectionChanged(2, 'benchmarkLevel')"
                                    class="dash-option"
                                    [ngClass]="{'dash-option-selected': properties.benchmarkLevel == 2 }"
                                >
                                    <span>{{ 'ClaimCostTopProvider.Industry' | translate }}</span>
                                </div>
                                <div
                                    id="claimProDiagBtnNone"
                                    fxLayout="row"
                                    fxLayoutAlign="center center"
                                    (click)="selectionChanged(3, 'benchmarkLevel')"
                                    class="dash-option"
                                    [ngClass]="{'dash-option-selected': properties.benchmarkLevel == 3 }"
                                >
                                    <span>{{ 'ClaimCostTopProvider.None' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>