export const locale = {
    lang: 'en',
    data: {
        'Nav': {
            'Stories': 'STORIES',
            'Export': {
                'Main': 'Export',
                'Cart': 'Cart',
                'Queue': 'Queue'
            },
            'Admin': { 
                'Main': 'ADMIN',
                'Users': 'Users',
                'Stories': 'Stories',
                'Settings': 'Settings',
                'StoryBoard': 'Story Board',
                'Library': 'Library',
                'ClientGroup': 'Client Group'
            }
        }
    }
};
