
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DashboardTemplateBase } from '../dashboard-template-base.component';
import { DataService } from 'app/dashboards/data.service';
import { HttpClient } from '@angular/common/http';
import { FilterOptions } from 'app/dashboards/shared/models/FilterOptions';
import { PulseEventName } from 'app/dashboards/shared/models/PulseEventName';
import { DimensionOrderService } from 'app/dashboards/dimension-order.service';
import { locale as navigationEnglish } from 'app/dashboards/templates/claim-type/i18n/en';
import { locale as navigationSpanishCL } from 'app/dashboards/templates/claim-type/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/dashboards/templates/claim-type/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/dashboards/templates/claim-type/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/dashboards/templates/claim-type/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/dashboards/templates/claim-type/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/dashboards/templates/claim-type/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/dashboards/templates/claim-type/i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NumberFormattingService } from 'app/services/number-formatting.service';
import { formatNumber } from '@angular/common';
import { isFunction } from 'lodash';
import { CLAIM_BASIS } from 'app/models/basis.model';

@Component({
  selector: 'app-claim-type',
  templateUrl: './claim-type.component.html',
  styleUrls: ['./claim-type.component.css']
})
export class ClaimTypeComponent implements OnInit, DashboardTemplateBase {
  ShowHideOption = ShowHideOption;
  ClaimantOption = ClaimantOption;

  isFilterOptionsLoading = true;

  LATAM_COUNTRIES = ['Chile', 'Brazil', 'Colombia', 'Peru', 'Mexico', 'Ecuador'];

  data: any;
  properties: any;
  imageUrlBase = '';
  filterOptions: FilterOptions = new FilterOptions();
  unitClaim = 'M';
  unitCost = '';
  maxClaim: number;
  maxCost: number;
  showFilter: boolean = false;
  parentLoaded = false;
  marginLeft: number = 50;
  datalist: any;
  //finalTopListBind: any;
  @Output() action = new EventEmitter<any>();
  chartPurpleText: string;
  chartBlueText: string;
  chartLineText: string;
  chartPurpleAvgText: string;
  chartBlueAvgText: string;
  chartLineAvgText: string;
  // Marine
  barColorPurple = '#0084BB';
  // Aqua
  lineColorPurple = '#73E2D8';
  // Lime
  barColorBlue = '#8ABD45';
  maxX: number;
  plotXposClaim: any = [];
  plotXposCost: any = [];
  plotXposCountLine: any = [];
  dataListClaimTotal: any = [];
  dataListCost: any = [];
  dataListCountLine: any = []
  plotClaimTotal: [];
  plotCost: [];
  plotCountLine: [];
  categories: [];
  tempList: [];
  topFilter = 4;
  //currentClaim = '';  
  listClaimAvg: any = [];
  listCostAvg: any = [];
  listCountAvg: any = [];
  isCountryChile: boolean = false;
  decimalValue = 0;
  showPlotLine = true;
  CLAIM_BASIS = CLAIM_BASIS;

  initData(): void {
    if (typeof this.data.finalTopListBind === 'undefined') {
      this.data.finalTopListBind = [];
    }

    if (typeof this.data.currentClaim === 'undefined') {
      this.data.currentClaim = '';
    }
    this.topFilter = this.isLATAMCountry() ? 4 : 6;
    this.datalist = this.data.tier3Info.current;
    this.maxClaim = this.data.tier3Info.sumClaimTotal;
    this.isCountryChile = this.properties.country === 'Chile' ? true : false;
    this.decimalValue = this.isCountryChile ? 2 : 0;
    this.maxCost = this.maxCostValue;
    this.categories = this.data.xAxisCategories;
    this.maxX = this.categories.length - 1;
    this.dataListClaimTotal = this.data.claimsPaidTotalValues;
    this.dataListCost = this.dataListCostValue;
    this.dataListCountLine = this.dataListCountLineValue;
    this.listClaimAvg = this.data.claimsPaidAverageValues;
    this.listCostAvg = this.listCostAvgValue;
    this.listCountAvg = this.listCountAvgValue;
    var listCostDistinctAvg = this.listCostDistinctAvgValue;
    var listCountDistinctAvg = this.listCountDistinctAvgValue;
    var l = 0;
    this.plotXposClaim = this.data.avgSegmentationDateIndices.map((da) => {
      var textLabel = formatNumber(parseFloat(this.data.claimsPaidDistinctAverageValues[l].toFixed(this.decimalValue)), 'en-US');
      var plotObj = {
        color: 'black',
        width: 2,
        value: da - 0.5,
        zIndex: 5,
        dashStyle: 'Solid',
        label: {
          text: textLabel,
          rotation: 0,
          x: this.categories.length - 1 == da ? -10 : 10,
          style: {
            fontFamily: 'Helvetica Now Text, Arial, Helvetica, sans-serif',
            color: '#FF803E'
          }
        }
      }
      l++;
      return plotObj
    });
    var m = 0;
    this.plotXposCost = this.data.avgSegmentationDateIndices.map((da) => {
      var textLabel = formatNumber(parseFloat(listCostDistinctAvg[m].toFixed(this.decimalValue)), 'en-US');
      var plotObj = {
        color: 'black',
        width: 2,
        value: da - 0.5,
        zIndex: 5,
        dashStyle: 'Solid',
        label: {
          text: textLabel,
          rotation: 0,
          x: this.categories.length - 1 === da ? -10 : 10,
          style: {
            fontFamily: 'Helvetica Now Text, Arial, Helvetica, sans-serif',
            color: '#FF803E'
          }
        }
      }
      m++;
      return plotObj
    });
    var n = 0;

    this.plotXposCountLine = this.data.avgSegmentationDateIndices.map((da) => {
      var textLabel = formatNumber(parseFloat(listCountDistinctAvg[n].toFixed(this.decimalValue)), 'en-US');
      var plotObj = {
        color: 'black',
        width: 2,
        value: da - 0.5,
        zIndex: 5,
        dashStyle: 'Solid',
        label: {
          text: textLabel,
          rotation: 0,
          x: this.categories.length - 1 === da ? -10 : 10,
          style: {
            fontFamily: 'Helvetica Now Text, Arial, Helvetica, sans-serif',
            color: '#FF803E'
          }
        }
      }
      n++;
      return plotObj
    });

    this.tempList = this.dimensionOrderService.sortByColumn(
      this.datalist, 'dimension');
    this.filterData();

    this.chartLineText = this._translateService.instant(this.countBasisChartYAxisLabel);
    this.chartLineAvgText = this.chartLineText + ' ' + this._translateService.instant('ClaimType.Average');
    this.chartBlueText = this._translateService.instant(this.costPerCountBasisHeader);
    this.chartBlueAvgText = this.chartBlueText + ' ' + this._translateService.instant('ClaimType.Average');
    this.chartPurpleText = this._translateService.instant('ClaimType.ClaimsTotal');
    this.chartPurpleAvgText = this.chartPurpleText + ' ' + this._translateService.instant('ClaimType.Average');

    setTimeout(() => (this.parentLoaded = true), 100);
  }

  constructor(
    private _dataService: DataService, private httpClient: HttpClient,
    private _translateService: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private dimensionOrderService: DimensionOrderService,) { }

  ngOnInit(): void {
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationSpanishCL,
      navigationSpanishCO,
      navigationSpanishEC,
      navigationSpanishMX,
      navigationSpanishPE,
      navigationPortugueseBR,
      navigationItalianIT,
    ); 

    if (this._translateService != null) {
      this._translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.initData();
        }
      );
    }
  }

  selectionChanged(val, filterName: string): void {
    this.action.emit({
      type: PulseEventName.FILTLER_CHANGED,
      data: {
        properties: {
          name: filterName,
          value: val
        }
      }
    });
  }

  filterData(): void {
    this.data.finalTopListBind = this.tempList.slice(0, this.topFilter);
  }

  showHideFilter(val, filterName: string): void {
    if (val === ShowHideOption.Hide) {
      this.topFilter = 20;
      this.filterData();
    } else {
      this.topFilter = this.isLATAMCountry() ? 4 : 6;
      this.filterData();
    }
    this.action.emit({
      type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
      data: {
        properties: {
          name: filterName,
          value: typeof val === 'object' ? val.data.join('||') : val
        }
      }
    });
  }

  resetClaimType(): void {
    this.data.currentClaim = '';
    this.properties.dimension = 'claim type';
    this.properties.selectedClaimType = '';
    this._dataService
      .getClaimTypeData(this.properties)
      .toPromise()
      .then(re => {
        this.data = re;
        this.initData();
      });
  }

  selectClaim(event: any, data: any): void {
    if (this.data.currentClaim === data.dimension) {
      this.resetClaimType();
    }
    else {
      this.data.currentClaim = data.dimension;
      this.properties.dimension = 'claim type';
      this.properties.selectedClaimType = data.dimension;
      this._dataService
        .getClaimTypeData(this.properties)
        .toPromise()
        .then(re => {
          re['finalTopListBind'] = [];
          re['currentClaim'] = data.dimension;
          this.data = re;
          this.initData();
        });
    }
  }

  filterChanged(val, filterName: string): void {
    this.action.emit({
      type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
      data: {
        properties: {
          name: filterName,
          value: typeof val === 'object' ? val.data.join('||') : val
        }
      }
    });

    this.initData();
  }

  additionalFilterSelectionChanged(val): void {
    this.action.emit(val);
  }

  getClaimsPerCountBasisPerClaimType(currentData): number {
    const {
      claimsPerClaimaint,
      claimsCount,
    } = currentData;

    return this._claimantOptionSwitch({
      claim: claimsPerClaimaint,
      claimant: claimsPerClaimaint,
      headcount: claimsCount / this.data.totalHeadcount,
    });
  }

  getCostPerCountBasisPerClaimType(currentData): number {
    const {
      averageCostPerClaim: claim,
      averageCostPerClaimant: claimant,
      claimsTotal,
    } = currentData;

    return this._claimantOptionSwitch({
      claim,
      claimant,
      headcount: claimsTotal / this.data.totalHeadcount,
    });
  }

  getCountBasisByClaimTypeValue(currentData): number {
    const {
      claimsCount: claim,
      claimantCount: claimant,
    } = currentData;

    return this._claimantOptionSwitch({
      claim,
      claimant,
      headcount: claim,
    });
  }

  get countBasisHeader(): string {
    return this._claimantOptionSwitch({
      claim: 'ClaimType.ClaimCount',
      claimant: 'ClaimType.ClaimantCount',
      headcount: 'ClaimType.ClaimCount',
    });
  }

  get countBasisChartYAxisLabel(): string {
    return this._claimantOptionSwitch({
      claim: 'ClaimType.ClaimCount',
      claimant: 'ClaimType.ClaimantCount',
      headcount: 'ClaimType.Headcount',
    });
  }

  get costPerCountBasisHeader(): string {
    return this._claimantOptionSwitch({
      claim: 'ClaimType.CostPerClaim',
      claimant: 'ClaimType.CostPerClaimant',
      headcount: 'ClaimType.CostPerHeadcount',
    });
  }

  get claimsPerCountBasisHeader(): string {
    return this._claimantOptionSwitch({
      claim: 'ClaimType.ClaimsPerClaimant',
      claimant: 'ClaimType.ClaimsPerClaimant',
      headcount: 'ClaimType.ClaimsPerHeadcount',
    });
  }

  get maxCostValue(): number {
    return this._claimantOptionSwitch({
      claim: this.data.tier3Info.costPerClaimTotal,
      claimant: this.data.tier3Info.costPerClaimantTotal,
      headcount: () => (this.data.tier3Info.current || []).reduce((a, c) => {
        return a + this.getCostPerCountBasisPerClaimType(c);
      }, 0),
    });
  }

  get dataListCostValue(): number {
    return this._claimantOptionSwitch({
      claim: this.data.costPerClaimValues,
      claimant: this.data.costPerClaimantValues,
      headcount: this.data.costPerHeadcountValues,
    });
  }

  get dataListCountLineValue(): number {
    return this._claimantOptionSwitch({
      claim: this.data.claimsCountValues,
      claimant: this.data.claimantsCountValues,
      headcount: this.data.headcountValues,
    });
  }

  get listCostAvgValue(): number {
    return this._claimantOptionSwitch({
      claim: this.data.costPerClaimAverageValues,
      claimant: this.data.costPerClaimantAverageValues,
      headcount: this.data.costPerHeadcountAverageValues,
    });
  }

  get listCountAvgValue(): number {
    return this._claimantOptionSwitch({
      claim: this.data.claimsCountAverageValues,
      claimant: this.data.claimantsCountAverageValues,
      headcount: this.data.headcountAverageValues,
    });
  }

  get listCostDistinctAvgValue(): number {
    return this._claimantOptionSwitch({
      claim: this.data.costPerClaimDistinctAverageValues,
      claimant: this.data.costPerClaimantDistinctAverageValues,
      headcount: this.data.costPerHeadcountDistinctAverageValues,
    });
  }

  get listCountDistinctAvgValue(): number {
    return this._claimantOptionSwitch({
      claim: this.data.claimsCountDistinctAverageValues,
      claimant: this.data.claimantsCountDistinctAverageValues,
      headcount: this.data.headcountDistinctAverageValues,
    });
  }

  private _claimantOptionSwitch<T>({
    claim,
    claimant,
    headcount,
    defaultCase,
  }: {
    claim: T | (() => T),
    claimant: T | (() => T),
    headcount: T | (() => T),
    defaultCase?: T | (() => T),
  }): T {
    switch (this.properties.claimTypeOption) {
      case ClaimantOption.Claim:
        return this._getGenericObjFunc(claim);
      case ClaimantOption.Claimant:
        return this._getGenericObjFunc(claimant);
      case ClaimantOption.Headcount:
        return this._getGenericObjFunc(headcount);
      default:
        return defaultCase && this._getGenericObjFunc(defaultCase) || this._getGenericObjFunc(claim);
    }
  }

  private _getGenericObjFunc<T>(input: T | (() => T)): T {
    return isFunction(input) ? (input as () => T)() : input as T;
  }

  private isLATAMCountry(): boolean {
    return this.LATAM_COUNTRIES.indexOf(this.properties.country) >= 0;
  }
}
export enum ShowHideOption {
  Show = 'Show',
  Hide = 'Hide'
}
export enum ClaimantOption {
  Claim = 'Claim',
  Claimant = 'Claimant',
  Headcount = 'Headcount',
}