import { Component, OnInit } from '@angular/core';
import { LibraryService } from 'app/services/library.service';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-libraryadmin',
  templateUrl: './libraryadmin.component.html',
  styleUrls: ['./libraryadmin.component.css']
})
export class LibraryadminComponent implements OnInit {
    libraryFilter: string;

    currentLibrary: any;
    currentLibraryGlobalParamList: any[];

    selectedLibraries: any[];

    selectedLibraryGridColDefs: any[] = [
        { headerName: 'Workbook Name', field: 'name' },
        { headerName: 'No of Filters Selected', field: 'noOfFilter' },
        { headerName: 'No of Stories', field: 'noOfStory' },
        { headerName: 'Created At', field: 'createdAt' },
        { headerName: 'Updated At', field: 'updatedAt' }
    ];

    constructor(
        private _router: Router,
        private _libraryService: LibraryService,
        private _toasterService: ToasterService) { }

    ngOnInit() {
        this.libraryFilter = '';

        this.currentLibrary = null;
        this.currentLibraryGlobalParamList = [];

        this.selectedLibraries = [];

        this.populateSelectedLibraries();
    }

    private populateSelectedLibraries(): void {
        this._libraryService.listSelectedLibraries().toPromise().then(result => {
            console.log(result);
            this.selectedLibraries = result;
        });
    }

    onRedirectToAddLibraryItem() {
        this._router.navigate(['admin/library/add']);
    }

    onViewLibraryItem(event: any) {
        console.log(event.data);
        this.currentLibrary = event.data;
        this.currentLibraryGlobalParamList = [];

        this._libraryService.listLibraryItemGlobalParams(this.currentLibrary).subscribe((result: any[]) => {
            this.currentLibraryGlobalParamList = result;
        });
    }

    onLibraryItemVisibilityToggle(event: any) {
        console.log(event.data);
        this._libraryService.updateLibraryItemVisibility(event.data).subscribe(result => {
            if (result) {
                this._toasterService.showMessage('Library item\'s visibilty successfully updated.', ToasterServiceModes.OK);
            }
            else {
                this._toasterService.showMessage('Updating Library item\'s visibilty Failed.', ToasterServiceModes.NOK);
            }
            this.updateLibraryDictionaryInSession();
        });
    }

    onLibraryItemDelete(event: any) {
        console.log(event.data);
        if (event.data.noOfStory > 0) {
            this._toasterService.showMessage('Cannot delete Library item still used in Stories.', ToasterServiceModes.NOK);
            return;
        }

        this._libraryService.deleteLibraryItem(event.data).subscribe(result => {
            if (result) {
                this._toasterService.showMessage('Library item successfully deleted.', ToasterServiceModes.OK);
                this.updateLibraryDictionaryInSession();
                this.selectedLibraries = [];
                this.populateSelectedLibraries();
            }
            else {
                this._toasterService.showMessage('Deletion of Library item Failed.', ToasterServiceModes.NOK);
            }
        });
    }

    onToggleGlobalParamSelection(globalParam: any) {
        globalParam.isSelected = !globalParam.isSelected;

        if (!globalParam.isSelected) {
            globalParam.paramNameUser = globalParam.paramName;
            globalParam.isEditable = false;
        }
    }

    saveUserDefinedGlobalParamName(globalParam: any) {
        if (typeof globalParam.paramNameUser === 'undefined') {
            return;
        }

        if (globalParam.paramNameUser === null) {
            return;
        }

        if (globalParam.paramNameUser.trim().length === 0) {
            return;
        }

        globalParam.paramNameUser = globalParam.paramNameUser.trim();
        globalParam.isEditable = false;
    }

    resetUserDefinedGlobalParamName(globalParam: any) {
        globalParam.paramNameUser = globalParam.paramName;
        globalParam.isEditable = false;
    }

    saveGlobalParams() {
        this._libraryService.saveLibraryItemGlobalParams(this.currentLibraryGlobalParamList).subscribe(result => {
            if (result) {
                this.populateSelectedLibraries();
                this._libraryService.getLibraryDictionary().subscribe(libraryDictionary => {
                    this._libraryService.setLibraryDictionaryInSession(libraryDictionary);
                });
                this._toasterService.showMessage('Global Params successfully saved.', ToasterServiceModes.OK);
            }
            else {
                this._toasterService.showMessage('Saving of Global Params Failed.', ToasterServiceModes.NOK);
            }
        });
    }

    closeLibraryItemDetails() {
        this.currentLibrary = null;
        this.currentLibraryGlobalParamList = [];
    }

    private updateLibraryDictionaryInSession() {
        this._libraryService.getLibraryDictionary().toPromise().then(libraryDictionary => {
            this._libraryService.setLibraryDictionaryInSession(libraryDictionary);
        });
    }
}
