<div style="width:98%;">
    <aon-stacked-horizontal-bar-chart [width]="width" width="width" [height]="height" [yaxistitle]="yaxistitle"
        [charttitle]="emptyChartTitle" [xaxistitle]="xaxistitle" [tooltip]="tooltip" [maxyaxisnumber]="scaleMax"
        [titleposition]="titleposition" [data]="demographicsData" [keys]="keys" [legendtitle]="legendtitle"
        [tickformat]="tickformat" [lablefontsize]="'13'" [categorycolors]="categorycolors">
    </aon-stacked-horizontal-bar-chart>
</div>

<!-- <aon-stacked-horizontal-bar-chart [width]="stackedwidth" [charttitle]="stackedcharttitle"
[yaxistitle]="stackedyaxistitle" [xaxistitle]="stackedxaxistitle"
[tooltip]="stackedtooltip" [titleposition]="stackedtitleposition"
[data]="expandedAgeBandData" [keys]="stackedkeys" [legendtitle]="stackedlegendtitle"
[categorycolors]="stackedcategorycolors" [maxyaxisnumber]="scaleMax"
>
</aon-stacked-horizontal-bar-chart> -->