import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../_reducers';
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import { languages } from 'app/shared/languages';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as navigationEnglish } from 'app/layout/components/toolbar/i18n/en';
import { locale as navigationSpanishCL } from 'app/layout/components/toolbar/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/layout/components/toolbar/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/layout/components/toolbar/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/layout/components/toolbar/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/layout/components/toolbar/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/layout/components/toolbar/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/layout/components/toolbar/i18n/it';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { UserIdleService } from 'angular-user-idle';
import * as _ from 'lodash';
import { navigation } from 'app/navigation/navigation';
import { Router } from '@angular/router';
import { CartService } from 'app/services/cart.service';
import { MatDialog } from '@angular/material/dialog';
import { UseroptionsComponent } from 'app/login/useroptions/useroptions.component';
import { UseroptionsDialogComponent } from 'app/login/useroptions/useroptions-dialog/useroptions-dialog.component';
import { environment } from 'environments/environment';
import { ChangePasswordComponent } from '../change-password/change-password.component';
@Component({
    selector: 'top-nav-bar',
    templateUrl: './topnavbar.component.html',
    styleUrls: ['./topnavbar.component.scss'],
})
export class TopNavbarComponent implements OnInit {

    appname: string = environment.profile.project.title;
    appNameWidth: string = '10%';
    titleWidth: string = '45%';
    categoryList: any;
    storyList: any[];
    clientName: string = '';
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    user: User;
    cartItems: any[];
    profile: any;
    appCategory = {
        isPulse: false,
        isWellOne: false,
        isFWB: false,
    };
    isCostProjectionAllowed = false;

    appNavBar = {
        pulse: {
            menuLabel: 'Category',
            icon: 'angle-down',
            menuItems: [
                {
                    id: '1', name: 'Enrollment', icon: 'users',
                },
                {
                    id: '2', name: 'Financial', icon: 'money-bill',
                },
                {
                    id: '3', name: 'Health', icon: 'heart-rate',
                },
                {
                    id: '4', name: 'Network', icon: 'globe',
                },
            ],
        },
        wellone: {
            menuLabel: 'Category', icon: 'angle-down',
            menuItems: [
                {
                    id: '1', name: 'Enrollment', icon: 'users',
                },
                {
                    id: '2', name: 'Financial', icon: 'money-bill',
                },
                {
                    id: '3', name: 'Health', icon: 'heart-rate',
                },
                {
                    id: '4', name: 'Network', icon: 'globe',
                },
            ],
        },
        fwb: {
            menuLabel: 'Insight', icon: 'angle-down',
            menuItems: [
                {
                    id: '1', name: 'Demographics', icon: 'users',
                },
                {
                    id: '2', name: 'Financial Wellbeing Score', icon: 'hand-holding-usd',
                },
                {
                    id: '3', name: 'Pension Score', icon: 'donate',
                },
                {
                    id: '4', name: 'Financial Benefit Score', icon: 'tachometer',
                },
                {
                    id: '5', name: 'Retirement Adequacy', icon: 'piggy-bank',
                },
            ],
        },
    };
    userRole = {
        Admin: false,
        RegionalManager: false,
        CountryManager: false,
        Normal: false,
    };
    newLayoutUrl: string;
    fwbLayoutUrl: string = 'fwb';
    constructor(
        private _cartService: CartService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _userIdleService: UserIdleService,
        private _fuseNavigationService: FuseNavigationService,
        private _router: Router,
        private store: Store<State>,
        public userService: UserService,
        public dialog: MatDialog
    ) {
        this.appCategory.isWellOne = environment.profile.project.title === 'Well One Analytics';
        if (environment.profile.project.title === 'Pulse') {

            this.appCategory.isPulse = true;
            let route: string = this._router.url;
            var childPath = route.split('/')[1];
            if (childPath === '') {
                childPath = window.location.pathname.split('/')[1];
            }
            if (childPath == 'fwb') {
                this.appCategory.isFWB = true;
                this.appCategory.isPulse = false;
            }
            else if (childPath === 'new-layout') {
                this.appCategory.isPulse = false;
                this.appCategory.isWellOne = true;
            }
        }
        if (this.appCategory.isPulse) {
            this.newLayoutUrl = '/';
            this.appNameWidth = '7%';
            this.titleWidth = '50%';
        } else if (this.appCategory.isWellOne) {
            this.newLayoutUrl = '/new-layout/';
            this.appNameWidth = '10%';
            this.titleWidth = '45%';
        } /* else if (this.appCategory.isFWB) {
            // =  "Financial Wellbeing";
            this.newLayoutUrl = "/fwb/";
            this.appNameWidth = "10%";
            this.titleWidth = "45%";
        }

        if (this.appCategory.isWellOne) {
            this.appname = "Well One";
        } */
    }

    ngOnInit(): void {
        this.userRole.Admin = this.userService.isAdmin();
        this.userRole.RegionalManager = this.userService.isRegionalManager();
        this.userRole.CountryManager = this.userService.isCountryManager();
        this.userRole.Normal = this.userService.isNormalUser();
        this.isCostProjectionAllowed = this.userService.user.isCostProjectionAllowed;
        
        this.languages = languages;
        var defaultSelectedLanguage = this._translateService.currentLang;
        this.selectedLanguage = this.languages.find(function (language) {
            return language.id == defaultSelectedLanguage;
        });

        this._fuseTranslationLoaderService.loadTranslations(
          navigationEnglish,
          navigationSpanishCL,
          navigationSpanishCO,
          navigationSpanishEC,
          navigationSpanishMX,
          navigationSpanishPE,
          navigationPortugueseBR,
          navigationItalianIT,
        );

        this.appname = environment.profile.project.title;
        if (environment.profile.project.title === 'Pulse') {

            this.appCategory.isPulse = true;
            let route: string = this._router.url;
            var childPath = route.split('/')[1];

            if (childPath === '') {
                childPath = window.location.pathname.split('/')[1];
            }
            if (childPath == 'fwb') {
                this.appCategory.isFWB = true;
                this.appCategory.isPulse = false;
            }
            else if (childPath === '/new-layout/') {
                this.appCategory.isPulse = false;
                this.appCategory.isWellOne = true;
            }
        }
        if (this.appCategory.isPulse) {
            this.newLayoutUrl = '/';
            this.appNameWidth = '7%';
            this.titleWidth = '50%';
        } else if (this.appCategory.isWellOne) {
            this.newLayoutUrl = 'new-layout';
            this.appNameWidth = '10%';
            this.titleWidth = '45%';
        } else if (this.appCategory.isFWB) {
            // =  "Financial Wellbeing";
            this.newLayoutUrl = '/fwb/';
            this.appNameWidth = '10%';
            this.titleWidth = '45%';
        }
        /*         if (this.appCategory.isFWB) {
                    this.appname = "Financial Wellbeing";
                }
                if (this.appCategory.isWellOne) {
                    this.appname = "Well One";
                } */
        this.categoryList = this.getCategoryList(this.appname);
        var storiesNav = this._fuseNavigationService.getNavigationItem(
            'stories'
        );
        this.storyList = storiesNav.children;

        this._cartService.getObservableItems().subscribe((cartItems) => {
            this.cartItems = cartItems;
        });

        this.store.select('user').subscribe((res) => {
            this.user = res;
            if (this.user) {
                this._translateService.currentLang = this.user.language;
                this.clientName = this.user.client ? this.user.client.name : '';
            }
        });

        this.userService.userChanged.subscribe((user) => {
            console.log('user updated', user);
            if (user) {
                this.user = user;
                this.clientName = this.user.client ? this.user.client.name : '';
            }
        });

        this._userIdleService.onTimeout().subscribe(() => {
            console.log('User session timeout');
            this._userIdleService.stopWatching();
            this.logout();
        });
    }

    logout() {
        this.userService.logout();
    }

    setHomePage(event) {
        if (!this.storyList) {
            return;
        }

        if (this.storyList.length === 0) {
            return;
        }

        this._router.navigate([this.newLayoutUrl + this.storyList[0].url]);
    }
    summaryClick(event) {
        if (this.appCategory.isFWB) {
            this._router.navigate([this.newLayoutUrl + 'executive']);
        }
    }

    exportMenuChange(event): void {
        switch (event.detail) {
            case '1':
                this._router.navigate([this.newLayoutUrl + 'export/cart']);
                break;
            case '2':
                this._router.navigate([this.newLayoutUrl + 'export/queue']);
                break;

            default:
                break;
        }
    }

    storyChange(event): void {
        var selectedRow: any = event.detail;
        let storyUrl = this.newLayoutUrl + selectedRow.url;
        this._router.navigate([storyUrl]);
    }

    getCategoryList(projectTitle) {
        switch (projectTitle) {
            case 'Pulse': {
                return this.appNavBar['pulse'];
                break;
            }
            case 'Well One': {
                return this.appNavBar['wellone'];
                break;
            }
            case 'Financial Wellbeing': {
                this.appNameWidth = '17%';
                this.titleWidth = '25%';
                this.clientName = 'Company ABC';
                return this.appNavBar['fwb'];
                break;
            }
        }
    }

    categoryClick(event: any): void {
        var selectedItem = event.detail;
        if (selectedItem === '1') {
            this._router.navigate([this.newLayoutUrl + 'participation']);
        }
        if (selectedItem === '2') {
            this._router.navigate([this.newLayoutUrl + 'financial/score']);
        }
        if (selectedItem === '3') {
            this._router.navigate([this.newLayoutUrl + 'health/score/detail']);
        }

    }

    categoryChange(event): void {
        if (this.appCategory.isFWB) {

            switch (event.detail) {
                case '1':
                    this._router.navigate(['/fwb/demographics']);
                    break;
                case '2':
                    this._router.navigate(['/fwb/wellbeingscore']);
                    break;
                case '3':
                    this._router.navigate(['/fwb/pensionscore']);
                    break;
                case '4':
                    this._router.navigate(['/fwb/benefitscore']);
                    break;
                case '5':
                    this._router.navigate(['/fwb/retirement']);
                    break;
                default:
                    break;
            }
        }
    }

    dashboardChange(event): void {
        if (this.appCategory.isFWB) {
            switch (event.detail) {
                case '2':
                    this.viewOptions();
                    break;
                case '3':
                    this.logout();
                    break;
                case '4':
                    this._router.navigate(['/story/editor']);
                    break;
                case '5':
                    this._router.navigate(['/admin/stories']);
                    break;
                case '6':
                    this._router.navigate(['/admin/user']);
                    break;
                case '7':
                    this._router.navigate(['/admin/clientgroupadmin']);
                    break;
                case '8':
                    this._router.navigate(['/admin/benefitmanagement']);
                    break;
                case '10':
                    this._router.navigate(['/admin/settings']);
                    break;

                default:
                    break;
            }
        } else {

            switch (event.detail) {
                case '0':
                    this.isExternalUser();
                    this.viewChangePassword();
                    break;
                case '1':
                    this._router.navigate([this.newLayoutUrl + 'cost-projection']);
                    break;
                case '2':
                    this.viewOptions();
                    break;
                case '3':
                    this.logout();
                    break;
                case '4':
                    this._router.navigate([this.newLayoutUrl + 'story/editor']);
                    break;
                case '5':
                    this._router.navigate([this.newLayoutUrl + 'admin/stories']);
                    break;
                case '6':
                    this._router.navigate([this.newLayoutUrl + 'admin/user']);
                    break;
                case '7':
                    this._router.navigate([this.newLayoutUrl + 'admin/clientgroupadmin']);
                    break;
                case '8':
                    this._router.navigate([this.newLayoutUrl + 'admin/library']);
                    break;
                case '9':
                    this._router.navigate(['/admin/cost-projection']);
                    break;
                case '10':
                    this._router.navigate(['/admin/settings']);
                    break;
                case '11':
                    this._router.navigate([this.newLayoutUrl + 'export/cart']);
                    break;
                case '12':
                    this._router.navigate([this.newLayoutUrl + 'export/queue']);
                    break;
                default:
                    break;
            }
        }
    }

    catchAllChartMenuChange(event): void {
        this._router.navigate([this.newLayoutUrl + 'story/catchall']);
    }

    public viewOptions(): void {
        const dialogRef = this.dialog.open(UseroptionsDialogComponent, {
            minWidth: '60vh',
            minHeight: '350px'
        });
    }

    viewChangePassword() {
        const dialogRef = this.dialog.open(ChangePasswordComponent, {
            minWidth: '60vh',
            minHeight: '350px'
        });
    }

    languageChange(event): void {
        this.setLanguage(event.detail);
    }

    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);

        this.userService.updateLanguage(lang.id);
    }

    isExternalUser(): boolean {
        return this.userService.user.userName === this.userService.user.email;
    }
}
