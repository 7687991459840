import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { CostProjectionComponent } from './cost-projection.component';
import { CostProjectionInputsComponent } from './cost-projection-inputs/cost-projection-inputs.component';
import { CostProjectionResultsComponent } from './cost-projection-results/cost-projection-results.component';
import { CostProjectionSaveDialogComponent } from './dialogs/cost-projection-save-dialog/cost-projection-save-dialog.component';
import { CostProjectionLoadDialogComponent } from './dialogs/cost-projection-load-dialog/cost-projection-load-dialog.component';
import { RouterModule } from '@angular/router';
import { CostProjectionClaimBasisMismatchDialogComponent } from './dialogs/cost-projection-claim-basis-mismatch-dialog/cost-projection-claim-basis-mismatch-dialog.component';

const routes = [
  {
      path: 'cost-projection',
      component: CostProjectionComponent,
  }
];

@NgModule({
  declarations: [
    CostProjectionComponent,
    CostProjectionInputsComponent,
    CostProjectionResultsComponent,
    CostProjectionSaveDialogComponent,
    CostProjectionLoadDialogComponent,
    CostProjectionClaimBasisMismatchDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
  ]
})
export class CostProjectionModule { }
