export const locale = {
    lang: 'es-ec',
    data: {
        'StoriesAdmin': {
            'StoriesAdministration': 'Administración de historias',
            'Stories': 'Historias',
            'NewStory': 'Nueva historia',
            'Users': 'Usuarios',
            'Save': 'Guardar',
            'SelectAll': 'Seleccionar todo',
            'Success': 'Éxito',
            'Name': 'Nombre (Nombre historia)',
            'CreatedBy': 'Creado por',
            'CreatedOn': 'Creado en (Fecha)',
            'userColumnDefs': [
                { headerName: 'Email', field: 'email', sort: 'asc'},
                { headerName: 'Nombre de usuario', field: 'userName', sort: 'asc' }
            ]
        }
    }
};
