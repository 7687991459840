import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DashboardTemplateBase } from '../dashboard-template-base.component';
import { locale as navigationEnglish } from 'app/dashboards/templates/average-cost-util/i18n/en';
import { locale as navigationSpanishCL } from 'app/dashboards/templates/average-cost-util/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/dashboards/templates/average-cost-util/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/dashboards/templates/average-cost-util/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/dashboards/templates/average-cost-util/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/dashboards/templates/average-cost-util/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/dashboards/templates/average-cost-util/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/dashboards/templates/average-cost-util/i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterOptions } from 'app/dashboards/shared/models/FilterOptions';
import { PulseEventName } from 'app/dashboards/shared/models/PulseEventName';
import { APAC_COUNTRIES } from 'app/constants/countries_APAC';
import { CLAIM_BASIS } from 'app/models/basis.model';
import { COLOR_PALETTE } from 'app/constants/color-palette';

@Component({
  selector: 'app-average-cost-util',
  templateUrl: './average-cost-util.component.html',
  styleUrls: ['./average-cost-util.component.css']
})
export class AverageCostUtilComponent implements OnInit, DashboardTemplateBase {
  @Output() action = new EventEmitter<any>();
  ClaimTypeOption = ClaimTypeOption;
  CostOption = CostOption;
  PortfolioIndustryNoneOption = PortfolioIndustryNoneOption;

  apacCountries = APAC_COUNTRIES;
  isFilterOptionsLoading = true;

  imageUrlBase = '';
  data: any;
  properties: any;
  isAvgCost: boolean;
  isLoading = false;
  showFilter: boolean = false;
  showBM: boolean = true;
  costOption: CostOption = this.CostOption.CostPerClaim;
  dateRange = '';
  positiveKeyImpacts: any[] = [];
  negativeKeyImpacts: any[] = [];
  avgItemsGrid: any = [];
  BenchmarkInclude: number = 1;
  isIncludeClient: boolean = true;
  portfolioIndustryNoneOption: PortfolioIndustryNoneOption = PortfolioIndustryNoneOption.Portfolio;
  donutData: any;
  averageCostImpact: number;
  overallImpact: number;
  utilizationImpact: number;
  mixOfServicesImpact: number;
  param: string;
  filterOptions: FilterOptions = new FilterOptions();
  noneSelected = false;
  parentLoaded = false;
  isLoadingKeyMetrics = true;
  isLoadingDonut = true;
  isLoadingBenchmark = true;
  dimensions: any = [];
  totalPercent: any;
  legendColors: any;
  ChartTable = ChartTable;
  categories: any = [];
  dataListBar1Prior: any[] = [];
  dataListBar2Current: any[] = [];
  dataListLinebenchmark: any[] = [];
  currentDateRange: any;
  priorDateRange: any;
  benchmarkLabel: any;
  utilRatio: boolean = false;
  utilClaimsPerClaimant = false;
  utilFreqOfUse = false;
  decimalPlaces = 0;
  legendBindingList = [];
  ShowLegend = false;
  eligibleForBenchmark = false;
  CLAIM_BASIS = CLAIM_BASIS;

  constructor(
    private _translateService: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) { }

  initData(): void {
    this.isAvgCost = (this.properties.id == "AverageCost") ? true : false;
    this.utilRatio = this.properties.id == "Utilization" && this.properties.mode == CostOption.UtilizationRatio;
    this.utilClaimsPerClaimant = this.properties.id == "Utilization" && this.properties.mode == CostOption.ClaimsPerClaimant;
    this.utilFreqOfUse = this.properties.id == "Utilization" && this.properties.mode == CostOption.FreqOfUse;

    if (this.isPercentage) {
      this.decimalPlaces = 0;
    }
    else {
      this.decimalPlaces = this.properties.country === 'Chile' ? 2 : (this.isAvgCost ? 0 : (this.utilClaimsPerClaimant ? 1 : (this.utilFreqOfUse ? 2 : 0)))
    }

    const sorted = this.data.keyImpact.sort((a, b) => {
      return b.impact - a.impact;
    });

    this.legendColors = COLOR_PALETTE;
    this.positiveKeyImpacts = sorted.slice(0, 2).filter(a => a.impact >= 0);
    // lower values mean higher negative impact
    this.negativeKeyImpacts = sorted.sort((a, b) => {
      return a.impact - b.impact;
    }).slice(0, 2).filter(a => a.impact < 0);
    this.avgItemsGrid = this.isAvgCost ? this.data.costs : this.data.util;
    const comparable = this.properties.rollingPeriod == 3 ? " (C)" : "";
    this.dateRange = this.data.dateRange.fromDateLabel + ' ' + this._translateService.instant("AvgCostUtil.DateRange") + ' ' + this.data.dateRange.toDateLabel + comparable;
    this.currentDateRange = this.data.currentDateRange.fromDateLabel + ' ' + this._translateService.instant("AvgCostUtil.DateRange") + ' ' + this.data.currentDateRange.toDateLabel + comparable;
    this.priorDateRange = this.data.priorDateRange.fromDateLabel + ' ' + this._translateService.instant("AvgCostUtil.DateRange") + ' ' + this.data.priorDateRange.toDateLabel + comparable;

    this.benchmarkLabel = this._translateService.instant("AvgCostUtil.Benchmark");
    this.categories = [];
    this.dataListBar1Prior = [];
    this.dataListBar2Current = [];
    this.dataListLinebenchmark = [];

    if (this.isPercentage) {
      this.avgItemsGrid.map(d => {
        this.categories.push(d.dimension);
        this.dataListBar1Prior.push(d.prior * 100);
        this.dataListBar2Current.push(d.current * 100);
        this.dataListLinebenchmark.push(d.benchmark * 100);
      });
    } else {
      if (typeof this.avgItemsGrid !== 'undefined') {
        this.avgItemsGrid.map(d => {
          this.categories.push(d.dimension);
          this.dataListBar1Prior.push(d.prior);
          this.dataListBar2Current.push(d.current);
          this.dataListLinebenchmark.push(d.benchmark);
        })
      }

    }
    this.averageCostImpact = this.data.impact.averageCostImpact;
    this.overallImpact = this.data.impact.overallImpact;
    this.utilizationImpact = this.data.impact.utilizationImpact;
    this.mixOfServicesImpact = this.data.impact.mixOfServicesImpact;

    this.donutData = {
      [this.properties.dimension]: {}
    };
    this.legendBindingList = [];
    var i = 0;
    this.data.dimensionImpact.forEach(im => {
      if (im.impact !== 0) {
        let val = Math.round(im.impact * 10) / 10;
        if (Math.abs(val) > 0) {

          this.donutData[this.properties.dimension][im.dimension] = im.impact;
          var objlegend = { name: "", color: "" };
          objlegend.name = im.dimension;
          objlegend.color = this.legendColors[i];
          this.legendBindingList.push(objlegend);
          i++;
        }
      }
    });
    if (this.properties.id == "Utilization") {
      this.totalPercent = Math.round(this.utilizationImpact * 100 * Math.pow(10, 1)) / Math.pow(10, 1);
    }
    if (this.properties.id == "AverageCost") {
      this.totalPercent = Math.round(this.averageCostImpact * 100 * Math.pow(10, 1)) / Math.pow(10, 1);
    }

    this.noneSelected = this.properties.benchmarkLevel === PortfolioIndustryNoneOption.None;
    setTimeout(() => this.parentLoaded = true, 100);

    if (typeof this.properties.isLegendShown === 'undefined') {
      this.properties.isLegendShown = false;
    }
  }

  ngOnInit() {
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationSpanishCL,
      navigationSpanishCO,
      navigationSpanishEC,
      navigationSpanishMX,
      navigationSpanishPE,
      navigationPortugueseBR,
      navigationItalianIT,
    );
  }

  formatabs(num, dec) {
    let number = num ? num.toLocaleString(undefined, { maximumFractionDigits: dec }) : '';
    return Math.abs(number);
  }

  format(num, dec) {
    let number = num ? num.toLocaleString(undefined, { maximumFractionDigits: dec }) : '';
    return number;
  }

  selectionChanged(val, filterName: string) {
    this.action.emit({
      type: PulseEventName.FILTLER_CHANGED,
      data: {
        properties: {
          name: filterName,
          value: val
        }
      }
    });
  }

  additionalFilterSelectionChanged(val) {
    this.action.emit(val);
  }

  private get isPercentage(): boolean {
    return this.utilRatio;
  }
}

export interface averageCost {
  Dimension: string;
  Current: number;
  Prior: number;
  YoYChange: number;
  Benchmark: number;
}
export enum ClaimTypeOption {
  ClaimType = 'Claim Type',
  Network = 'Network',
  ProviderType = 'Provider Type',
  Relationship = 'Relationship'
}
export enum CostOption {
  CostPerClaim = 'Cost Per Claim',
  ClaimsPerClaimant = 'Claims Per Claimant',
  CostPerClaimant = 'Cost Per Claimant',
  UtilizationRatio = 'Utilization Ratio',
  FreqOfUse = 'Freq Of Use'
}
export enum PortfolioIndustryNoneOption {
  Portfolio = 1,
  Industry = 2,
  None = 3
}
export interface avgCostUtilDataType {
  overallImpact: number,
  averageCostImpact: number,
  utilizationImpact: number,
  mixOfServicesImpact: number
}
export enum ChartTable {
  Chart = 'Chart',
  Table = 'Table'
}