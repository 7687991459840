<div id="enTimeDashboard" style="min-height: 795px; padding: 8px; overflow: auto; position: relative;" fxLayout="row">
    <div fxFlex fxLayout="column">
        <div id="enTimeHeader" class="dash-header">
            <span id="enTimeHeaderTxt" >{{'EnrollmentOverTime.EnrollmentOverTimeHeading' | translate}}</span>
            <button id="enTimeHeaderBtn" mat-icon-button style="float: right" (click)="showFilter = !showFilter" *ngIf="!isFilterOptionsLoading">
                <app-filter-button [isClientName]='true'[isEntity]='true' [isPolicyType]='true' [isPlan]='true' [isGender]='true'
                [isRelationship]='true' [isAgeBand]='true' [isLocation]='true' [isLOB]='true' [isSubLOB]='true' [isTimePeriodEnrollment]='true'></app-filter-button>
            </button>
        </div>

        <div>
            <ng-container id="enTimeChart" *ngIf="parentLoaded">
                <div fxLayout="row">
                    <div [fxFlex]="100" style="margin-top: 2%;">
                        <app-column-stack [categories]="categories1" [seriesData]="seriesDataList" [Monthly]="properties.period === PeriodOption.Monthly" [isStackLabels]="true"></app-column-stack>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div style="width: 200px; padding: 40px 0px 0px 16px; border-left: 1px solid #EEE" fxLayout="column">
        <div id="entityDivisionFilters" *ngIf="showFilter">
            <app-additional-filters [country]="properties.country" (action)="additionalFilterSelectionChanged($event)" [filterOptions]='filterOptions' [isClientName]='true'[isEntity]='true' [isPolicyType]='true' [isPlan]='true' [isGender]='true'
                [isRelationship]='true' [isAgeBand]='true' [isLocation]='true' [isLOB]='true' [isSubLOB]='true' [isTimePeriodEnrollment]='true'></app-additional-filters>
        </div>
        <div id="enTimeFilters" fxFlex="55" *ngIf="!showFilter">
            <div id="enTimeLegends" >
            <div fxLayout="row" *ngFor="let leg of legendList" style="margin-bottom: 5px;">
                <svg width="15"  height="15">
                    <rect width="15"  height="15" [attr.fill]="leg.color"></rect>
                        </svg>
                        <span class="legendElp">{{(leg.name)}}</span> <br>
            </div>
            </div>
            <div style="margin-top: 10px;">
                <div id="enTimeTxtDimensions" fxLayout="row" style="margin-top:15px; margin-bottom: 5px;">
                    <span>{{'EnrollmentOverTime.Dimensions' | translate}}</span>
                </div>
                <div id="enTimeBtnAgeBand" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.dimension == GenderRelOptions.AgeBand}" (click)="selectionChanged(GenderRelOptions.AgeBand, 'dimension')">
                    <span>{{'EnrollmentOverTime.AgeBand' | translate}}</span></div>

                <div id="enTimeBtnGender" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.dimension == GenderRelOptions.Gender}" (click)="selectionChanged(GenderRelOptions.Gender, 'dimension')">
                    <span>{{'EnrollmentOverTime.Gender' | translate}}</span>
                </div>
                <div id="enTimeBtnRelationship" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.dimension == GenderRelOptions.Relationship}" (click)="selectionChanged(GenderRelOptions.Relationship, 'dimension')">
                    <span>{{'EnrollmentOverTime.Relationship' | translate}}</span>
                </div>
                <div id="enTimeBtnEmployee" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.dimension == GenderRelOptions.Employee}" (click)="selectionChanged(GenderRelOptions.Employee, 'dimension')">
                    <span>{{'EnrollmentOverTime.IsEmployee' | translate}}</span>
                </div>
                <div id="legendsAgeband" fxLayout="row">
                </div>
                <div id="enTimeBtnMonthly" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.period == PeriodOption.Monthly}" (click)="selectionChanged(PeriodOption.Monthly, 'period')" style="margin-top:30px">
                    <span>{{'EnrollmentOverTime.Monthly' | translate}}</span>
                </div>
                <div id="enTimeBtnYearToDateBasis" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.period == PeriodOption.YearBasis}" (click)="selectionChanged(PeriodOption.YearBasis, 'period')">
                    <span>{{'EnrollmentOverTime.YearToDateBasis' | translate}}</span>
                </div>
                <div id="enTimeBtnYearToDateBasisC" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.period == PeriodOption.YearBasisC}" (click)="selectionChanged(PeriodOption.YearBasisC, 'period')">
                    <span>{{'EnrollmentOverTime.YearToDateBasisC' | translate}}</span>
                </div>
                <div id="enTimeBtnRolling3" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.period == PeriodOption.Rolling3}" (click)="selectionChanged(PeriodOption.Rolling3, 'period')">
                    <span>{{'EnrollmentOverTime.Rolling3Basis'  | translate}}</span>
                </div>
                <div id="enTimeBtnRolling6" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.period == PeriodOption.Rolling6}" (click)="selectionChanged(PeriodOption.Rolling6, 'period')">
                    <span>{{'EnrollmentOverTime.Rolling6Basis'| translate}}</span>
                </div>
                <div id="enTimeBtnRolling9" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.period == PeriodOption.Rolling9}" (click)="selectionChanged(PeriodOption.Rolling9, 'period')">
                    <span>{{'EnrollmentOverTime.Rolling9Basis' | translate}}</span>
                </div>
                <div id="enTimeBtnRolling12" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.period == PeriodOption.Rolling12}" (click)="selectionChanged(PeriodOption.Rolling12, 'period')">
                    <span>{{'EnrollmentOverTime.Rolling12Basis' | translate}}</span>
                </div>
            </div>
        </div>
    </div>
</div>