<div id="claimDiagDashboard" style="min-height: 795px; padding: 8px; overflow: auto; position: relative;" fxLayout="row">
    <div fxFlex fxLayout="column">
        <div id="claimDiagHeader" class="dash-header">
            <span id="claimDiagHeaderTxt">{{ 'ClaimCostDiag.ClaimDiagnosis' | translate }}</span>
            <button id="claimDiagHeaderBtn" mat-icon-button style="float: right" (click)="showFilter = !showFilter" *ngIf="!isFilterOptionsLoading">
                <app-filter-button [isClientName]='true' [isEntity]='true' [isPolicyType]='true' [isPlan]='true' [isClaimType]='true' [isClaimType2]='true'
                [isClaimType3]='true' [isProviderType]='true' [isGender]='true' [isRelationship]='true' [isAgeBand]='true' [isLocation]='true' [isLOB]='true' [isSubLOB]='true'></app-filter-button>
            </button>
        </div>
        <div id="claimDiagHeaderNote" fxLayout="row">
            <div id="claimDiagHeaderNoteCurrency" class="upperNote" fxFlex="35">
                {{ 'ClaimCostDiag.AllAmountsDisplayedInLocalCurrency' | translate }}
            </div>
            <div *ngIf="data" id="claimDiagSelectUpper" class="upperNoteShown" [style.visibility]='data.shownAsPerCurrentDiagnosis !="" ? "visible" : "hidden" ' fxFlex="55">
                {{ 'ClaimCostDiag.ShownAsPer' | translate }} &nbsp; "{{data.shownAsPerCurrentDiagnosis}}"
            </div>
            <div id="claimDiagHeaderPeriod" class="upperNote" fxFlex="10">
                {{ currentTimePeriodLable }}
            </div>
        </div>
        <tableau-spinner *ngIf="isLoadingClaimType" style="position: absolute; top: 180px; left: 48%; z-index: 90">
        </tableau-spinner>
        <div fxLayout="row" class="gridpadding" style="margin-top: 5px" *ngIf="parentLoaded">
            <div id="claimDiagTable" [fxFlex]="100">
                <div id="claimDiagTableHeader" fxLayout="row" class="tableheader">
                    <div [fxFlex]="20">{{ 'ClaimCostDiag.ClaimType' | translate }} </div>
                    <div [fxFlex]="20">{{ 'ClaimCostDiag.TotalPaidClaim' | translate }}</div>
                    <div [fxFlex]="14">
                        {{properties.claimTypeOption == ClaimantOption.Claim ? ('ClaimCostDiag.ClaimCount' | translate ):('ClaimCostDiag.ClaimantCount' | translate ) }}
                    </div>
                    <div [fxFlex]="16">{{ 'ClaimCostDiag.ClaimsPerClaimant' | translate }}</div>
                    <div [fxFlex]="18">
                        {{properties.claimTypeOption == ClaimantOption.Claim ? ('ClaimCostDiag.CostPerClaim' | translate ) :('ClaimCostDiag.CostPerClaimant' | translate )}}
                    </div>
                    <div [fxFlex]="12">{{ 'ClaimCostDiag.ChangeInCost' | translate }}</div>
                </div>
                <div fxLayout="row" class="tablerow dashed padNoneTableRow" *ngFor="let finalTopListBindRecord of data.finalTopListBind" (click)="selectClaim($event, finalTopListBindRecord)" [ngClass]="(data.currentClaim == finalTopListBindRecord.dimension && data.currentClaim !== '')? 'grayRow' : ''">
                    <div [fxFlex]="20" style="font-size: 15px;">
                        {{finalTopListBindRecord.dimension }} </div>
                    <div [fxFlex]="20">
                        {{ properties.country === 'Thailand' ? (finalTopListBindRecord.claimsTotal | roundToDecimalPlace: 0 | numberFormat) : (finalTopListBindRecord.claimsTotal | toAbbrevNumberString:properties.country)}}
                    </div>
                    <div [fxFlex]="14" matTooltipClass="multi-line-tooltipCD" [matTooltip]='("ClaimCostDiag.ClaimCount" | translate)  + ": " + (finalTopListBindRecord.claimsCount | roundToDecimalPlace: 0 | numberFormat) +"&#13;"+ ("ClaimCostDiag.ClaimantCount" | translate)  + ": " + (finalTopListBindRecord.claimantCount | roundToDecimalPlace: 0 | numberFormat)'>
                        {{properties.claimTypeOption == ClaimantOption.Claim ? (finalTopListBindRecord.claimsCount | roundToDecimalPlace: 0 | numberFormat) : (finalTopListBindRecord.claimantCount | roundToDecimalPlace: 0 | numberFormat)}}
                    </div>
                    <div [fxFlex]="16">{{finalTopListBindRecord.claimsPerClaimaint | roundToDecimalPlace: 1 | numberFormat}}</div>
                    <div [fxFlex]="18">
                        {{(properties.claimTypeOption == ClaimantOption.Claim ? finalTopListBindRecord.averageCostPerClaim : finalTopListBindRecord.averageCostPerClaimant) | roundToDecimalPlace: (properties.country === 'Chile' ? 2 : 0) | numberFormat }}
                    </div>
                    <div [fxFlex]="12" style="padding-right:5px; text-align: center !important ">
                        <div [fxFlex]="70" style="text-align: right ;margin-top:3px;" *ngIf="!(data.yoYChange === 0)">
                            <span>{{finalTopListBindRecord.yoYChange | roundToDecimalPlace: 1 | numberFormat}} %</span>
                        </div>
                        <div [fxFlex]="30" style="text-align: left; " *ngIf="!(data.yoYChange === 0)">
                            <mat-icon style="color:rgb(216, 211, 211); " *ngIf="finalTopListBindRecord.yoYChange>0">arrow_upward
                            </mat-icon>
                            <mat-icon style="color:rgb(216, 211, 211); " *ngIf="finalTopListBindRecord.yoYChange<0">arrow_downward
                            </mat-icon>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" style="margin-top: 10px">
                </div>
            </div>
        </div>
        <div fxLayout="row" *ngIf="properties.showHide == ShowHideOption.Show">
            <div [fxFlex]="70"></div>
            <div id="claimDiagSelectTop" [fxFlex]="30">
                <span>{{ 'ClaimCostDiag.SelectTop' | translate }}</span>
                <input type="number" [(ngModel)]="properties.claimTypeDiagnosisTopFilter" class="topInput" min="0" (ngModelChange)="refreshDiagnoses()">
                <span>&nbsp; ({{ 'ClaimCostDiag.ClaimsTotal' | translate }})</span></div>
        </div>
        <div *ngIf="properties.showHide !== ShowHideOption.Show" fxLayout="row" style="margin-top: 10px">
            <div [fxFlex]="80"></div>
            <div id="claimDiagHideFooterNote" [fxFlex]="20" style="text-align: right; margin-right: 10px;">
                {{properties.claimPaid === CLAIM_BASIS.PAID_BASIS ? ( 'ClaimCostDiag.ClaimsReportedByPaidDate' | translate ) : ( 'ClaimCostDiag.ClaimsReportedByIncurredDate' | translate )}}
            </div>
        </div>

        <tableau-spinner *ngIf="isLoadingDiagnoses && (properties.showHide == ShowHideOption.Show)" style="position: absolute; top: 480px; left: 48%; z-index: 90"></tableau-spinner>
        <div [style.visibility]="properties.showHide == ShowHideOption.Show ? 'visible' : 'hidden'">
            <div fxLayout="row" class="gridpadding">
                <div id="claimDiagTableDiag" [fxFlex]="100">
                    <div id="claimDiagTableDiagHeader" fxLayout="row" class="tableheader">
                        <div [fxFlex]="30">{{'ClaimCostDiag.TopDiagnoses' | translate}}</div>
                        <div [fxFlex]="18">{{'ClaimCostDiag.TotalPaidClaim' | translate}}</div>
                        <div [fxFlex]="6"></div>
                        <div [fxFlex]="20">
                            {{properties.claimTypeOption == ClaimantOption.Claim ? ('ClaimCostDiag.ClaimCount' | translate ):('ClaimCostDiag.ClaimantCount' | translate ) }}
                        </div>
                        <div [fxFlex]="3"></div>
                        <div [fxFlex]="20">
                            {{properties.claimTypeOption == ClaimantOption.Claim ? ('ClaimCostDiag.CostPerClaim' | translate ) :('ClaimCostDiag.CostPerClaimant' | translate )}}
                        </div>
                        <div [fxFlex]="3"></div>
                    </div>
                    <div *ngIf="orderDiagListTopLoaded">
                        <div fxLayout="row" class="tablerow dashed padNoneTableRow" *ngFor="let orderDiagListTopRecord of data.orderDiagListTop" (click)="selectDiagnosis($event, orderDiagListTopRecord)" [ngClass]="(data.currentDiagnosis == orderDiagListTopRecord.dimension && data.currentDiagnosis !== '')? 'grayRow' : ''">
                            <div [fxFlex]="30" style="font-size: 14px;" class="claimPad">
                                {{(!apacCountries.includes(properties.country) && (orderDiagListTopRecord.claimantCount
                                < properties.claimantFilterValue )) ? 'NA': (orderDiagListTopRecord.dimension) }} </div>
                                    <div [fxFlex]="18">
                                        <ng-container *ngIf="parentLoaded">
                                            <app-bar [barColor]="barColorPurple" [value]="orderDiagListTopRecord.claimsTotal" [max]="maxClaimTotal" [fullTotalForPercentage]="topDiagTotal" [unit]="'MaxAndFullTotalForPercentage'" [valueDecimalPlace]="4" [country]="properties.country"></app-bar>
                                        </ng-container>
                                    </div>
                                    <div [fxFlex]="6"></div>
                                    <div [fxFlex]="20">
                                        <ng-container *ngIf="parentLoaded">
                                            <app-bar [barColor]="barColorGray" [value]="properties.claimTypeOption == ClaimantOption.Claim ? orderDiagListTopRecord.claimsCount : orderDiagListTopRecord.claimantCount" [max]="maxCount" [unit]="unitCost" [valueDecimalPlace]="0" [country]="properties.country"></app-bar>
                                        </ng-container>
                                    </div>
                                    <div [fxFlex]="3"></div>
                                    <div [fxFlex]="20">
                                        <ng-container>
                                            <app-bar [barColor]="barColorBlue" [value]="properties.claimTypeOption == ClaimantOption.Claim ? orderDiagListTopRecord.averageCostPerClaim  : orderDiagListTopRecord.averageCostPerClaimant" [max]="maxClaimCost" [unit]="unitCost" [valueDecimalPlace]="0" [country]="properties.country"></app-bar>
                                        </ng-container>
                                    </div>
                                    <div [fxFlex]="3"></div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div *ngIf="properties.showHide == ShowHideOption.Show" fxLayout="row">
                <div [fxFlex]="35" style="padding: 3px"></div>
                <div *ngIf="data" id="claimDiagSelectLower" fxFlex="45" class="upperNoteShown" [style.visibility]='(data.shownAsPerCurrentClaim !="" && (properties.showHide == ShowHideOption.Show)) ? "visible" : "hidden" '>
                    {{ 'ClaimCostDiag.ShownAsPer' | translate }} &nbsp; "{{data.shownAsPerCurrentClaim}}"
                </div>
                <div id="claimDiagFooterNote" [fxFlex]="20" style="text-align: right; margin-right: 10px;">
                    {{properties.claimPaid === CLAIM_BASIS.PAID_BASIS ? ( 'ClaimCostDiag.ClaimsReportedByPaidDate' | translate ) : ( 'ClaimCostDiag.ClaimsReportedByIncurredDate' | translate )}}
                </div>
            </div>
        </div>
        <div style="width: 200px; padding: 80px 0px 0px 16px; border-left: 1px solid #EEE" fxLayout="column">
            <div id="entityDivisionFilters" *ngIf="showFilter">
                <app-additional-filters [country]="properties.country" (action)="additionalFilterSelectionChanged($event)" [filterOptions]='filterOptions' [isClientName]='true' [isEntity]='true' [isPolicyType]='true' [isPlan]='true' [isClaimType]='true' [isClaimType2]='true'
                    [isClaimType3]='true' [isProviderType]='true' [isGender]='true' [isRelationship]='true' [isAgeBand]='true' [isLocation]='true' [isLOB]='true' [isSubLOB]='true'>
                </app-additional-filters>
            </div>
            <div id="claimDiagFilter" fxFlex="55" *ngIf="!showFilter">
                <div id="claimDiagTxtCountBasis" fxLayout="row" style="margin-top:10px; margin-bottom: 5px;">
                    <span>{{ 'ClaimCostDiag.CountBasis' | translate }}</span>
                </div>
                <div id="claimDiagBtnClaim" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.claimTypeOption == ClaimantOption.Claim}" (click)="filterChanged(ClaimantOption.Claim, 'claimTypeOption')">
                    <span>{{ 'ClaimCostDiag.Claim' | translate }}</span>
                </div>
                <div id="claimDiagBtnClaimant" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.claimTypeOption == ClaimantOption.Claimant}" (click)="filterChanged(ClaimantOption.Claimant, 'claimTypeOption')">
                    <span>{{ 'ClaimCostDiag.Claimant' | translate }}</span>
                </div>
                <div id="claimDiagTxtShowHide" fxLayout="row" style="margin-top:15px; margin-bottom: 15px;">
                    <span>{{ 'ClaimCostDiag.ShowHide' | translate }}</span>
                </div>
                <div id="claimDiagBtnShow" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.showHide == ShowHideOption.Show}" (click)="showHideFilter(ShowHideOption.Show, 'showHide')">
                    <span>{{ 'ClaimCostDiag.Show' | translate }}</span></div>

                <div id="claimDiagBtnHide" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.showHide == ShowHideOption.Hide}" (click)="showHideFilter(ShowHideOption.Hide, 'showHide')">
                    <span>{{ 'ClaimCostDiag.Hide' | translate }}</span>
                </div>
                <div id="claimDiagTxtDiagnosisLevel" fxLayout="row" style="margin-top:15px; margin-bottom: 15px;">
                    <span>{{ 'ClaimCostDiag.DiagnosisLevel' | translate }}</span>
                </div>
                <div id="claimDiagBtnCategory" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.diag == CategoryChapterOption.DiagCat}" (click)="diagnosisContainerSelectionChanged(CategoryChapterOption.DiagCat, 'diag')">
                    <span>{{ 'ClaimCostDiag.Category' | translate }}</span>
                </div>
                <div id="claimDiagBtnChapter" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.diag == CategoryChapterOption.DiagChapter}" (click)="diagnosisContainerSelectionChanged(CategoryChapterOption.DiagChapter, 'diag')">
                    <span>{{ 'ClaimCostDiag.Chapter' | translate }}</span>
                </div>
                <div id="claimDiagTxtSort" fxLayout="row" style="margin-top:15px; margin-bottom: 5px;">
                    <span>{{'ClaimCostDiag.Sort' | translate}}</span>
                </div>
                <div id="claimDiagBtnClaimsTotal" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': properties.sort == SortOption.ClaimTotal}" (click)="filterChangedSort(SortOption.ClaimTotal, 'sort')">
                    <span>{{ 'ClaimCostDiag.ClaimsTotal' | translate }}</span>
                </div>
                <div id="claimDiagBtnCount" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': (properties.sort == SortOption.CountClaim) || (properties.sort == SortOption.CountClaimant)}" (click)="filterChangedSort( properties.claimTypeOption == ClaimantOption.Claim ? SortOption.CountClaim: SortOption.CountClaimant, 'sort')">
                    <span>{{ 'ClaimCostDiag.Count' | translate }}</span>
                </div>
                <div id="claimDiagBtnAverageCost" fxLayout="row" fxLayoutAlign="center center" class="dash-option" [ngClass]="{'dash-option-selected': (properties.sort == SortOption.AvergareCostlaim) || (properties.sort == SortOption.AvergareCostlaimant)}" (click)="filterChangedSort( properties.claimTypeOption == ClaimantOption.Claim ? SortOption.AvergareCostlaim : SortOption.AvergareCostlaimant, 'sort')">
                    <span>{{ 'ClaimCostDiag.AverageCost' | translate }}</span>
                </div>
                <div id="claimDiagBtnFilterNA" style="margin-top:5px;" fxLayout="row" fxLayoutAlign="center center" style="margin-top:15px;" (click)="filterNAChange('filterNA', 'filterNA')" class="dash-option" [ngClass]="{'dash-option-selected': properties.isExcludeNA === true }">
                    <span>{{ 'ClaimCostDiag.FilterNA' | translate }}</span>
                </div>
            </div>
        </div>
    </div>