import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DimensionOrderService {

  constructor(private httpClient: HttpClient) { }

  public cache: any = {};

  getDimensionsByCountry(country) {
    this.cache = sessionStorage.getDimensionsByCountry;
    const promise = new Promise((resolve, reject) => {
      if (!this.cache || this.cache.country !== country) {
        this.httpClient.get<any>(environment.api + 'api/dimensions', { params: { country } })
          .toPromise()
          .then(r => {
            this.cache = {
              country: country,
              data: r
            }
            resolve(r);
          });
      } else {
        resolve(this.cache.data);
      }
    });
    return promise;
  }

  SortAgeBand(list: any) {
    list.sort((a, b) => {
      const genreA = a.dimension.split('-');
      const genreB = b.dimension.split('-');

      let comparison = 0;
      if (parseInt(genreA[0]) > parseInt(genreB[0])) {
        comparison = 1;
      } else if (parseInt(genreA[0]) < parseInt(genreB[0])) {
        comparison = -1;
      }
      return comparison;
    })
  }
  orderDatabyRank(dimensionsOrderList, datalist, selectedOption) {
    dimensionsOrderList.filter(d => d.dimensionType == selectedOption).forEach((item) => {
      datalist.forEach(im => {
        if (item.field == im.dimension)
          im.rank = item.rank;
      });
    });
    var sortedAvgItems = datalist.sort((a, b) => {
      return a.rank - b.rank;
    });
    return sortedAvgItems;
  }

  sortByColumn(datalist, selectedOption) {
    var sortedAvgItems = datalist.sort((a, b) => {
      return a[selectedOption] - b[selectedOption];
    });
    return sortedAvgItems;
  }
}
