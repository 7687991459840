import { Component, ViewEncapsulation, NgModule } from "@angular/core";
@Component({
    selector: "star-rating",
    templateUrl: "./star-rating.component.html",
    styleUrls: ["./star-rating.component.css"],
    encapsulation: ViewEncapsulation.None,
})
export class StarRatingComponent {
    public params: any;
    isAlert: boolean = false;
    displayAlert: boolean = false;

    isOneStar: boolean = false;
    isTwoStar: boolean = false;
    isThreeStar: boolean = false;

    agInit(params: any): void {
        this.params = params;

        if (this.params.colDef.field == "value") {
            this.isAlert = false;
            if (this.params.value == 3) {
                this.isThreeStar = true;
            } else if (this.params.value == 2) {
                this.isThreeStar = true;
            } else {
                this.isOneStar = true;
            }
        } else if (this.params.colDef.field == "iserror") {
            if (this.params.value == true) {
                this.isAlert = true;
                this.displayAlert = true;
            } else if (this.params.value == false) {
                this.isAlert = true;
            } else {
            }
            console.log("this.params");
            console.log(this.params.value);
        }
    }
    counter(i: number) {
        return new Array(i);
    }
}
