import { Component, OnInit } from '@angular/core';
import { RetirementRatioComparison, RetirementScenarioOptions } from 'app/fwb/constants/retirement-chart-data';
import { FuseConfigService } from '@fuse/services/config.service';
import { Store } from '@ngrx/store';
import { State } from 'app/_reducers';
import { Router } from '@angular/router';
import { FWBService } from 'app/fwb/services/fwb.service';
import { RetirementService } from 'app/fwb/services/retirement.service';
@Component({
  selector: 'retirement-scenario',
  templateUrl: './retirement-scenario.component.html',
  styleUrls: ['./retirement-scenario.component.scss']
})
export class RetirementScenarioComponent implements OnInit {
  height: any = "600px";
  width: any = "98%";
  cardheightnumber: number = 700;
  cardheight: any = "670px";
  cardwidth: any = "99%";
  tooltip: boolean = true;
  titleposition = "left";
  emptyChartTitle = "";
  chartSubTitle = "";
  yaxistitle = "";
  color = "white";
  maxyaxisnumber: number = 0.5;
  // categorycolors: any[] = [2, 3];
  navigationheight = "80px";
  ticksize: number = 6;
  scaleMax: number = 1;
  tickformat: string = ".0%";
  ratiocomparisondata: any[];
  currentScenarioNumber: number = 1;

  charttitle = "Comparison with Scenario";
  chartdescription = " ";
  options: any[] = [];
  serviceData: any = null;
  isLoading = true;
  isError = false;
  errorMsg = "No Data Found.";
  loaded = false;
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fwbService: FWBService,
    private _retirementService: RetirementService,
    private store: Store<State>,
    private _router: Router) {
    this.store.select('fwb').forEach(res => {
      if (res == undefined) {
        console.log("this.fwb is undefined");
      } else {
        this.currentScenarioNumber = isNaN(res.replacementscenario) ? 5 : (res.replacementscenario);
        if (this.serviceData !== null) {   this.setData();}
      }
    });
  }

  ngOnInit() {
    // if (this.serviceData === null) {
      this.getData();
    // }
  }

  getData() {
    this.serviceData = null;
    this._retirementService.getScenario("").toPromise().then((resp) => {
      this.serviceData = resp;
      console.log(this.serviceData);
      this.setData();
      this.errorMsg = "";
      this.isError = false;
      this.loaded = true;
  }).catch(error => {
      this.isError = true;
      this.errorMsg = error.message;
  }).finally(() => {
      this.isLoading = false;
    })
  }

  setData() {
    // this.ratiocomparisondata = RetirementRatioComparison;
    this.options = this.serviceData.resultScenarios;
    this.ratiocomparisondata = this.serviceData.resultReplacement;
    this.updateText();

  }

  updateScenario() {
    this._fwbService.updateReplacementScenario(this.currentScenarioNumber);
  }



  onPrev($event) {
    if (this.currentScenarioNumber == 1) {
      this.currentScenarioNumber = 5;
    } else {
      this.currentScenarioNumber -= 1;
    }
    this._fwbService.updateReplacementScenario(this.currentScenarioNumber);
    console.log($event.detail);
  }

  updateText() {

    this.charttitle = this.options[this.currentScenarioNumber - 1].name;
    this.chartdescription = this.options[this.currentScenarioNumber - 1].description;
  }

  onNext($event) {

    if (this.currentScenarioNumber == 5) {
      this.currentScenarioNumber = 1;
    } else {
      this.currentScenarioNumber += 1;
    }
    this._fwbService.updateReplacementScenario(this.currentScenarioNumber);
    console.log($event.detail);
  }

}
