export const locale = {
	lang: 'pt-br',
	data: {
		'DoubleQuad': {
			'DoubleQuadHeading': 'Prestador - Análise de Dobro Quadrante',
			'AllAmountsDisplayedInLocalCurrency': 'Todos os valores estão em moeda local',
			'Providers': 'Prestadores',
			'Diagnoses': 'Diagnósticos',
			'ClaimantCount': 'Contagem de Usuário',
			'ClaimCount': 'Contagem de Sinistros',
			'CostPerClaim': 'Custo Por Sinistro',
			'CostPerClaimant': 'Custo Por Usuário',
			'Claim': 'Sinistro',
			'Claimant': 'Usuário',
			'ClaimsTotal': 'Sinistro Total',
			'Count': 'Contar',
			'ClaimsReportedByPaidDate': 'Sinistro Reportado por Data de Pagamento',
			'ClaimsReportedByIncurredDate': 'Sinistro Reportado por Data de Atendimento',
			'SelectTop': 'Selecionar Maiores',
			'FilterNA': 'Excluir NA',
			'CountBasis': 'Base de Contagem',
			'Sort': 'Ordenar',
			'Reset': 'Limpar',
			'ShownAsPer': 'Mostrar como exemplo',
			'DateRange': 'to'
		}
	}
};