export const locale = {
    lang: 'es-pe',
    data: {
        'ClaimType': {
            'ClaimHeading': 'Tipo de atención',
            'AllAmountsDisplayedInLocalCurrency': 'Moneda local',
            'ClaimType': 'Tipo de siniestro',
            'TotalPaidClaim': 'Pago total de siniestro',
            'ClaimantCount': 'Cuenta de Paciente',
            'ClaimCount': 'Número de atenciones',
            'ClaimsPerClaimant': 'Siniestros por paciente',
            'ClaimsPerHeadcount': 'Siniestros Por Plantilla',
            'CostPerClaim': 'Costo por atención',
            'CostPerClaimant': 'Costo por paciente',
            'CostPerHeadcount': 'Costo Por Plantilla',
            'ChangeInCost': '% Variación del costo',
            'Claim': 'Atención',
            'Claimant': 'Paciente',
            'Headcount': 'Plantilla',
            'ClaimsTotal': 'Total de siniestros',
            'Count': 'Contar',
            'ClaimsReportedByPaidDate': 'Siniestros informados por fecha de ocurrencia',
            'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
            'CountBasis': 'Contar Base',
            'Sort': 'Ordenar',
            'Show': 'Mostrar',
            'Hide': 'Ocultar',
            'ShowHide': 'Mostrar / ocultar',
            'Average': 'Promedio',
            'PeriodAverage': 'Periodo promedio',
            'PlanYear': 'Año Del Plan'
        }
    }
};
