export const locale = {
    lang: 'pt-br',
    data: {
        'Nav': {
            'Stories': 'HISTÓRIAS',
            'Export': {
                'Main': 'Exportar',
                'Cart': 'Carrinho',
                'Queue': 'Fila'
            },
            'Admin': { 
                'Main': 'ADMIN',
                'Users': 'Usuários',
                'Stories': 'Histórias',
                'Settings': 'Configurações',
                'StoryBoard': 'Painél de Histórias',
                'Library': 'Livraria',
                'ClientGroup': 'Grupo de Cliente'
            }
        }
    }
};
