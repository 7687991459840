<form fxFlex name="myForm">
  <mat-form-field fxFlexFill>
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let user of searchResults;let index=index;" [selectable]="selectable" [removable]="true">
        {{user.email}}
        <mat-icon matChipRemove (click)="removeChip(index)">cancel</mat-icon>
      </mat-chip>
      <input [matChipInputFor]="chipList" #searchBarInput [matAutocomplete]="auto" [formControl]="myControl"
        [matChipInputAddOnBlur]="false">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngIf="isSearchLoading">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-option>
      <mat-option *ngFor="let user of filteredOptions | async" [value]="user">
        <span>{{ user.email }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>