export const locale = {
    lang: 'es-pe',
    data: {
        'EnrollmentOverTime': {
            'EnrollmentOverTimeHeading': 'Inscripciones por periodo',
            'Male' : 'Masculino',
            'Female' : 'Femenino',
            'NA': 'ON',
            'AgeBand': 'Rango etario',
            'Dimensions' : 'Dimensión',
            'Gender' : 'Género',
            'Relationship' : 'Parentesco',
            'IsEmployee' : 'EE vs Dep',
            'Employee' : 'Trabajador',
            'Dependent' : 'Dependiente',
            'Monthly': 'Por Mes',
            'YearToDateBasis': 'Year-To-Date (Base Completa)',
            'YearToDateBasisC': 'Year-To-Date (C)',
            'Rolling3Basis': '3 meses rodantes',
            'Rolling6Basis': '6 meses rodantes',
            'Rolling9Basis': '9 meses rodantes',
            'Rolling12Basis': '12 meses rodantes',
            'ClaimsReportedByPaidDate': 'Siniestros informados por fecha de ocurrencia',
            'ClaimsReportedByIncurredDate' :  'Reclamaciones informadas por fecha incurrida',
            'DateRange':'to'
        }
    }
};