import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { User } from 'app/models/user.model';
import { UserService } from 'app/services/user.service';
import { WelloneParameterModel } from 'app/wellone/models/welloneparameter.model';
import { FlexChartService } from 'app/wellone/services/flexchart.service';
import { GlobalFilterService } from 'app/wellone/services/globalfilter.service';
import { BarPlotConstants } from './models/bar-plot-constants';
import { DataModel } from './models/data-model';
import { OptionModel } from './models/option-model';

@Component({
  selector: 'app-catchall',
  templateUrl: './catchall.component.html',
  styleUrls: ['./catchall.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatchallComponent implements OnInit {
 //@Input() displayTableDataArray: displayTableDataArray[];
 public options: OptionModel[];
 public displayTableDataArray: any[] = [];
 public rawTableDataArray: DataModel[] = [];
 public filteredRawTableDataArray: DataModel[] = [];
 public detailLevel1: string[] = [];
 public attrName1: string;
 public detailLevel2: string[] = [];
 public attrName2: string;
 public detailLevel3: string[] = [];
 public attrName3: string = "Health Score";
 public detailLevel1Raw: any[] = [];
 public detailLevel2Raw: any[] = [];
 public detailLevel3Raw: string[] = [];
 public attrNameArr: string[] = [];
 public countryFilter: string = "";
 public numRow: number = 5;
 public flexChartParams: WelloneParameterModel;
 public modifier: number = 10;
 public loadingMessage = BarPlotConstants.ProcessingData;
 public user: User;

 public sortArr: boolean[] = [false, false, true];
 public showSortArr: boolean[] = [false, false, true];

 isLoading: boolean = true;

 constructor(private flexChartService: FlexChartService, private userService: UserService, private globalFilterService: GlobalFilterService, private ref: ChangeDetectorRef) { }

 ngOnInit(): void {
   // this.detailLevel1Raw = ["Country","Business Unit","Age"];
   // this.detailLevel2Raw = ["Country","Business Unit","Age"];
   this.detailLevel3Raw = ["Health Score","Financial Score","Life Style Score", "Body Score", "Feeling Score", "Eligble Users", "Active / Eligible Users"];

   // this.detailLevel1 = Object.assign([], this.detailLevel1Raw);
   // this.detailLevel2 = Object.assign([], this.detailLevel2Raw);
   this.detailLevel3 = Object.assign([], this.detailLevel3Raw);
   this.getFlexChartData();

   setTimeout(() => {     
     this.globalFilterService.showFilter(false); 
   }, 400);
   

 }

 onAttrName1Change($event): void{
   this.isLoading = true;
   this.ref.detach();
   this.attrName1 = $event.detail;
   for (let index = 0; index < this.detailLevel2Raw.length; index++) {
     this.detailLevel2[index] = this.detailLevel2Raw[index].displayName;
   }
   this.detailLevel2 = this.detailLevel2.filter(dl => dl != this.attrName1);
   this.getGroupAttrArr();
   this.ref.markForCheck();
   this.ref.reattach();
   this.isLoading = false;
 }

 onAttrName2Change($event): void{
   this.isLoading = true;
   this.ref.detach();
   this.attrName2 = $event.detail;
   for (let index = 0; index < this.detailLevel2Raw.length; index++) {
     this.detailLevel1[index] = this.detailLevel2Raw[index].displayName;
   }
   this.detailLevel1 = this.detailLevel1.filter(dl => dl != this.attrName2);
   this.getGroupAttrArr();
   this.ref.markForCheck();
   this.ref.reattach();
   this.isLoading = false;
 }

 onAttrName3Change($event): void{
   this.ref.detach();
   this.attrName3 = $event.detail;
   this.getGroupAttrArr();
   this.ref.markForCheck();
   this.ref.reattach();
 }

 groupbyAttr(){
   this.filteredRawTableDataArray = Object.assign([], this.rawTableDataArray);
   this.formDisplayArr();
   this.filteredRawTableDataArray = this.filteredRawTableDataArray.filter(x => x.metric !=0);
   if(this.attrNameArr.length > 1){
     this.displayTableDataArray = Object.entries(this.groupByTwoAttr(this.filteredRawTableDataArray));
     console.log(this.displayTableDataArray);
     for (let i = 0; i < this.displayTableDataArray.length; i++) {
       this.displayTableDataArray[i][1] = this.removeDuplicate(this.displayTableDataArray[i][1]);
     }
   }else if(this.attrNameArr.length > 0){
     this.displayTableDataArray = Object.entries(this.groupByOneAttr(this.filteredRawTableDataArray));
   }
   this.applyPrivacy();
   var maxValue = Math.max.apply(null,[].concat.apply([],this.displayTableDataArray.map(item => item[1].map(i => this.toInt(i.metric, i.userCount)))));
   this.setModifier(maxValue);
 }

 groupByOneAttr(array){
   if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'wellone_flex_2'){
     return array.reduce((r, a) => {
       r[a.flex2] = r[a.flex2] || [];
       if(r[a.flex2].length > 0){
         r[a.flex2][0].metric += a.metric;
         r[a.flex2][0].userCount += a.userCount;
       }else{
           r[a.flex2].push({'metric': a.metric, 'userCount': a.userCount});
       }
       return r;
     }, Object.create(null));
   }else if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'wellone_flex_3'){
     return array.reduce((r, a) => {
       r[a.flex3] = r[a.flex3] || [];
       if(r[a.flex3].length > 0){
         r[a.flex3][0].metric += a.metric;
         r[a.flex3][0].userCount += a.userCount;
       }else{
           r[a.flex3].push({'metric': a.metric, 'userCount': a.userCount});
       }
       return r;
     }, Object.create(null));
   }else if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'wellone_flex_4'){
    return array.reduce((r, a) => {
      r[a.flex4] = r[a.flex4] || [];
      if(r[a.flex4].length > 0){
        r[a.flex4][0].metric += a.metric;
        r[a.flex4][0].userCount += a.userCount;
      }else{
          r[a.flex4].push({'metric': a.metric, 'userCount': a.userCount});
      }
      return r;
    }, Object.create(null));
  }else if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'wellone_flex_1'){
    return array.reduce((r, a) => {
      r[a.flex1] = r[a.flex1] || [];
      if(r[a.flex1].length > 0){
        r[a.flex1][0].metric += a.metric;
        r[a.flex1][0].userCount += a.userCount;
      }else{
          r[a.flex1].push({'metric': a.metric, 'userCount': a.userCount});
      }
      return r;
    }, Object.create(null));
  }else if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'wellone_flex_5'){
    return array.reduce((r, a) => {
      r[a.flex5] = r[a.flex5] || [];
      if(r[a.flex5].length > 0){
        r[a.flex5][0].metric += a.metric;
        r[a.flex5][0].userCount += a.userCount;
      }else{
          r[a.flex5].push({'metric': a.metric, 'userCount': a.userCount});
      }
      return r;
    }, Object.create(null));
    //default country filter
  }else if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'country'){
    return array.reduce((r, a) => {
      r[a.countryName] = r[a.countryName] || [];
      if(r[a.countryName].length > 0){
        r[a.countryName][0].metric += a.metric;
        r[a.countryName][0].userCount += a.userCount;
      }else{
          r[a.countryName].push({'metric': a.metric, 'userCount': a.userCount});
      }
      return r;
    }, Object.create(null));
  }else{
     return array.reduce((r, a) => {
       r[a.ageBand] = r[a.ageBand] || [];
       if(r[a.ageBand].length > 0){
         r[a.ageBand][0].metric += a.metric;
         r[a.ageBand][0].userCount += a.userCount;
       }else{
           r[a.ageBand].push({'metric': a.metric, 'userCount': a.userCount});
       }
       return r;
     }, Object.create(null));
   }
   
 }

 groupByTwoAttr(array){
   if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'wellone_flex_5'){
     return array.reduce((r, a) => {
         r[a.flex5] = r[a.flex5] || [];
         a.userCount = a.userCount;
         r[a.flex5].push(a);
       return r;
     }, Object.create(null));
   }else if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'wellone_flex_4'){
     return array.reduce((r, a) => {
         r[a.flex4] = r[a.flex4] || [];
         a.userCount = a.userCount;
         r[a.flex4].push(a);
         return r;
     }, Object.create(null));
   }else if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'wellone_flex_3'){
    return array.reduce((r, a) => {
        r[a.flex3] = r[a.flex3] || [];
        a.userCount = a.userCount;
        r[a.flex3].push(a);
        return r;
    }, Object.create(null));
  }else if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'wellone_flex_2'){
    return array.reduce((r, a) => {
        r[a.flex2] = r[a.flex2] || [];
        a.userCount = a.userCount;
        r[a.flex2].push(a);
        return r;
    }, Object.create(null));
  }else if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'wellone_flex_1'){
    return array.reduce((r, a) => {
        r[a.flex1] = r[a.flex1] || [];
        a.userCount = a.userCount;
        r[a.flex1].push(a);
        return r;
    }, Object.create(null));
  }else if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'country'){
    return array.reduce((r, a) => {
        r[a.countryName] = r[a.countryName] || [];
        a.userCount = a.userCount;
        r[a.countryName].push(a);
        return r;
    }, Object.create(null));
  }else{
     return array.reduce((r, a) => {
         r[a.ageBand] = r[a.ageBand] || [];
         a.userCount = a.userCount;
         r[a.ageBand].push(a);
         return r;
     }, Object.create(null));
   }
 }

 removeDuplicate(array){
   var result;
   if(this.displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_1'){
     result = array.reduce((r, a) => {
       r[a.flex1] = r[a.flex1] || [];
       if(r[a.flex1].length > 0){
         r[a.flex1][0].metric += a.metric;
         r[a.flex1][0].userCount += a.userCount;
       }else{
           r[a.flex1].push({'flex1': a.flex1, 'flex2': a.flex2,'flex3': a.flex3, 'flex4': a.flex4,'flex5': a.flex5,  'ageBand': a.ageBand,'countryName': a.countryName,'metric': a.metric, 'userCount': a.userCount});
       }
       return r;
     }, Object.create(null));
   }else if(this.displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_2'){
     result = array.reduce((r, a) => {
       r[a.flex2] = r[a.flex2] || [];
       if(r[a.flex2].length > 0){
         r[a.flex2][0].metric += a.metric;
         r[a.flex2][0].userCount += a.userCount;
       }else{
           r[a.flex2].push({'flex1': a.flex1, 'flex2': a.flex2,'flex3': a.flex3, 'flex4': a.flex4,'flex5': a.flex5,  'ageBand': a.ageBand,'countryName': a.countryName,'metric': a.metric, 'userCount': a.userCount});
       }
       return r;
     }, Object.create(null));
   }else if(this.displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_3'){
    result = array.reduce((r, a) => {
      r[a.flex3] = r[a.flex3] || [];
      if(r[a.flex3].length > 0){
        r[a.flex3][0].metric += a.metric;
        r[a.flex3][0].userCount += a.userCount;
      }else{
          r[a.flex3].push({'flex1': a.flex1, 'flex2': a.flex2,'flex3': a.flex3, 'flex4': a.flex4,'flex5': a.flex5,  'ageBand': a.ageBand,'countryName': a.countryName,'metric': a.metric, 'userCount': a.userCount});
      }
      return r;
    }, Object.create(null));
  }else if(this.displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_4'){
    result = array.reduce((r, a) => {
      r[a.flex4] = r[a.flex4] || [];
      if(r[a.flex4].length > 0){
        r[a.flex4][0].metric += a.metric;
        r[a.flex4][0].userCount += a.userCount;
      }else{
          r[a.flex4].push({'flex1': a.flex1, 'flex2': a.flex2,'flex3': a.flex3, 'flex4': a.flex4,'flex5': a.flex5,  'ageBand': a.ageBand,'countryName': a.countryName,'metric': a.metric, 'userCount': a.userCount});
      }
      return r;
    }, Object.create(null));
  }else if(this.displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_5'){
    result = array.reduce((r, a) => {
      r[a.flex5] = r[a.flex5] || [];
      if(r[a.flex5].length > 0){
        r[a.flex5][0].metric += a.metric;
        r[a.flex5][0].userCount += a.userCount;
      }else{
          r[a.flex5].push({'flex1': a.flex1, 'flex2': a.flex2,'flex3': a.flex3, 'flex4': a.flex4,'flex5': a.flex5,  'ageBand': a.ageBand,'countryName': a.countryName,'metric': a.metric, 'userCount': a.userCount});
      }
      return r;
    }, Object.create(null));
  }else if(this.displayNameToFlexIndex(this.attrNameArr[1]) == 'country'){
    result = array.reduce((r, a) => {
      r[a.countryName] = r[a.countryName] || [];
      if(r[a.countryName].length > 0){
        r[a.countryName][0].metric += a.metric;
        r[a.countryName][0].userCount += a.userCount;
      }else{
          r[a.countryName].push({'flex1': a.flex1, 'flex2': a.flex2,'flex3': a.flex3, 'flex4': a.flex4,'flex5': a.flex5,  'ageBand': a.ageBand,'countryName': a.countryName,'metric': a.metric, 'userCount': a.userCount});
      }
      return r;
    }, Object.create(null));
  }else{
     result = array.reduce((r, a) => {
       r[a.ageBand] = r[a.ageBand] || [];
       if(r[a.ageBand].length > 0){
         r[a.ageBand][0].metric += a.metric;
         r[a.ageBand][0].userCount += a.userCount;
       }else{
           r[a.ageBand].push({'flex1': a.flex1, 'flex2': a.flex2,'flex3': a.flex3, 'flex4': a.flex4,'flex5': a.flex5,  'ageBand': a.ageBand,'countryName': a.countryName,'metric': a.metric, 'userCount': a.userCount});
       }
       return r;
     }, Object.create(null));
   }
   var resultArr :DisplayModel[] = [];
   for (const key in result) {
     if (Object.prototype.hasOwnProperty.call(result, key)) {
       resultArr.push(result[key][0]);
     }
   }
   return resultArr;
 }

 getGroupAttrArr(){
   this.attrNameArr = [];
   if(this.attrName1 != null && this.attrName1.length > 0){
     this.attrNameArr.push(this.attrName1);
   }

   if(this.attrName2 != null && this.attrName2 != 'Select' && this.attrName2.length > 0){
     this.attrNameArr.push(this.attrName2);
   }
   this.groupbyAttr();
 }

 getChartWidth(value){
   return value*10;
 }

 onCountryFilterChange(event: any){
   this.ref.detach();
   this.countryFilter = event.detail.value;
   //attrName1
   if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'wellone_flex_1'){
    this.filteredRawTableDataArray = this.rawTableDataArray.filter(a => a.flex1 != null && a.flex1.toLowerCase().indexOf(this.countryFilter.toLowerCase()) !== -1);
   }else if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'wellone_flex_2'){
    this.filteredRawTableDataArray = this.rawTableDataArray.filter(a => a.flex2 != null && a.flex2.toLowerCase().indexOf(this.countryFilter.toLowerCase()) !== -1);
   }else if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'wellone_flex_3'){
    this.filteredRawTableDataArray = this.rawTableDataArray.filter(a => a.flex3 != null && a.flex3.toLowerCase().indexOf(this.countryFilter.toLowerCase()) !== -1);
   }else if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'wellone_flex_4'){
    this.filteredRawTableDataArray = this.rawTableDataArray.filter(a => a.flex4 != null && a.flex4.toLowerCase().indexOf(this.countryFilter.toLowerCase()) !== -1);
   }else if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'wellone_flex_5'){
    this.filteredRawTableDataArray = this.rawTableDataArray.filter(a => a.flex5 != null && a.flex5.toLowerCase().indexOf(this.countryFilter.toLowerCase()) !== -1);
   }else if(this.displayNameToFlexIndex(this.attrNameArr[0]) == 'country'){
    this.filteredRawTableDataArray = this.rawTableDataArray.filter(a => a.countryName != null && a.countryName.toLowerCase().indexOf(this.countryFilter.toLowerCase()) !== -1);
   }else{
    this.filteredRawTableDataArray = this.rawTableDataArray.filter(a => a.ageBand != null && a.ageBand.toLowerCase().indexOf(this.countryFilter.toLowerCase()) !== -1);
   }
   this.getGroupAttrArr();
   this.ref.markForCheck();
   this.ref.reattach();
 }

 onNumRowChange(event: any){
   this.ref.detach();
   this.numRow = event.detail.value;
   if(this.numRow > 0){
     this.getGroupAttrArr();
   }else{
     this.numRow = 0;
   }
   this.ref.markForCheck();
   this.ref.reattach();
 }

 //can rewrite when there is time
 onKeydown(event: KeyboardEvent): boolean {
   if (!((event.keyCode > 95 && event.keyCode < 106)
     || (event.keyCode > 47 && event.keyCode < 58)
     || event.keyCode === 8)) {
       return false;
   }
   return true;
 }

 reset(event: any){
   this.numRow = 5;
   this.countryFilter = "";
   this.attrName1 = "";
   this.attrName2 = "";
   this.attrName3 = "Health Score";
   this.attrNameArr= [];
   for (let index = 0; index < this.detailLevel1Raw.length; index++) {
     this.detailLevel1[index] = this.detailLevel1Raw[index].displayName;
   }
   for (let index = 0; index < this.detailLevel2Raw.length; index++) {
    this.detailLevel2[index] = this.detailLevel2Raw[index].displayName;
  }
   this.filteredRawTableDataArray = Object.assign([], this.rawTableDataArray);
 }

 getFlexChartData(){
  this.flexChartParams = new WelloneParameterModel();
  this.userService.userChanged.subscribe(user => {
    this.user = this.userService.getCurrentUser();
    if (this.user) {
      if (this.user.client) {
        // this.getDashboardResult();
        this.flexChartParams.Tenant_record_id = this.user.client.tbsClientId;
      }else{
        this.flexChartParams.Tenant_record_id = "1E95B64A-E681-4564-8D3C-A9E05CE9EA3B";
      }
      
      this.flexChartParams.Logged_uid = "gkuhn3"
      this.flexChartParams.GlobalFilters = this.globalFilterService.GetGlobalFilterValues();
      this.getCatchAllFlexMappingResult();
      this.flexChartService.getFlexChartData(this.flexChartParams).toPromise().then((res: any) => {
        setTimeout(() => {
          this.loadingMessage = BarPlotConstants.CompletingDataProcessing;
          this.ref.detectChanges();
        }, 5000);
   
        console.log('load');
        this.ref.detach();
        this.parseResponse(res);
      }).catch(error => {
        this.isLoading = false;
        console.log(error);         
      });
    } else {
      this.userService.logout();
    }
  });
 }

 getCatchAllFlexMappingResult(){
   this.flexChartService.getCatchAllFlexMappingResult(this.flexChartParams).toPromise().then((res: any) => {
     setTimeout(() => {
       this.loadingMessage = BarPlotConstants.CompletingDataProcessing;
       this.ref.detectChanges();
     }, 5000);
     this.ref.detach();
     this.detailLevel2Raw = res;
     this.detailLevel2Raw.push({displayName: 'Age', id:'', tbsSourceColumn: '', tbsSourceTable: '',welloneFlexField: 'age'})
     this.detailLevel2Raw.push({displayName: 'Country', id:'', tbsSourceColumn: '', tbsSourceTable: '',welloneFlexField: 'country'})
     this.detailLevel1Raw = Object.assign([], this.detailLevel2Raw);
     this.detailLevel2Raw.unshift({displayName: 'Select', id:'', tbsSourceColumn: '', tbsSourceTable: '',welloneFlexField: ''})
     for (let index = 0; index < this.detailLevel1Raw.length; index++) {
       this.detailLevel1[index] = this.detailLevel1Raw[index].displayName;
     }
     for (let index = 0; index < this.detailLevel2Raw.length; index++) {
      this.detailLevel2[index] = this.detailLevel2Raw[index].displayName;
    }
   }).catch(error => {
     this.isLoading = false;
     console.log(error);         
   });
 }
 

 parseResponse(res: any){
     if (typeof Worker !== 'undefined') {
       // Create a new
       const worker = new Worker('./workers/bar-plot-data.worker', { type: 'module' });
       worker.onmessage = ({ data }) => {
         this.rawTableDataArray = data as DataModel[];
         this.filteredRawTableDataArray = Object.assign([], this.rawTableDataArray);
         this.ref.markForCheck();
         this.ref.reattach();
         //set default value
         this.attrName1 = this.detailLevel1Raw[this.detailLevel1Raw.length - 1].displayName;
         this.attrName2 = this.detailLevel2Raw[this.detailLevel2Raw.length - 2].displayName;
         this.detailLevel2 = this.detailLevel2.filter(dl => dl != this.attrName1);
         this.detailLevel1 = this.detailLevel1.filter(dl => dl != this.attrName2);
         this.getGroupAttrArr();
         this.sortTable(2);
         this.isLoading = false;
       };
       worker.postMessage(res);
     } else {
       // Web workers are not supported in this environment.
       // You should add a fallback so that your program still executes correctly.
     }
 }

 toInt(num1, num2){
   if(num2 == null){
     return 0;
   }else if(num2 == 0){
     num2 = 1;
   }
   return Math.round(num1/num2);
 }

 setModifier(num){
   if(num <= 1000){
     this.modifier = 10;
   }else{
     var digits = Math.ceil(num / 1000);
     this.modifier = 10 * digits;
   }
 }

 displayNameToFlexIndex(displayName){
   return this.detailLevel2Raw.filter(x => x.displayName == displayName)[0].welloneFlexField;
 }

 formDisplayArr(){
   switch (this.attrName3) {
     case this.detailLevel3Raw[0]:
       this.filteredRawTableDataArray.forEach(c => 
        {c.metric = c.totalHealthScore;
        c.userCount = c.healthScoreUserCount});
       return;
     case this.detailLevel3Raw[1]:
       this.filteredRawTableDataArray.forEach(c => 
        {c.metric = c.totalFinancialScore;
        c.userCount = c.financialScoreUserCount});
       return;
     case this.detailLevel3Raw[2]:
       this.filteredRawTableDataArray.forEach(c => 
        {c.metric = c.totalLifestyleScore;
        c.userCount = c.healthScoreUserCount});
       return;
     case this.detailLevel3Raw[3]:
       this.filteredRawTableDataArray.forEach(c => 
        {c.metric = c.totalBodyScore;
        c.userCount = c.healthScoreUserCount});
       return;
     case this.detailLevel3Raw[4]:
       this.filteredRawTableDataArray.forEach(c => 
        {c.metric = c.totalMindScore;
        c.userCount = c.healthScoreUserCount});
       return;
     case this.detailLevel3Raw[5]:
      this.filteredRawTableDataArray.forEach(c => 
        {c.metric = c.eligibleUserCount;
        c.userCount = 0});
      return;
     case this.detailLevel3Raw[6]:
      this.filteredRawTableDataArray.forEach(c => 
        {c.metric = c.activeUserCount;
          c.userCount = c.eligibleUserCount});
      return;
     default:
       this.filteredRawTableDataArray.forEach(c => 
        {c.metric = c.totalHealthScore;
        c.userCount = c.healthScoreUserCount});
       return;
   }
 }

 applyPrivacy(){
  for (let i = 0; i < this.displayTableDataArray.length; i++) {
    //if item less than 10, then remove it from the display
    this.displayTableDataArray[i][1] = this.displayTableDataArray[i][1].filter(item => item.userCount > 9 || item.userCount == 0);
    if(this.displayTableDataArray[i][1].length < 1){
      this.displayTableDataArray.splice(i , 1);
    }
  }
}

 sortTable(triggerIndex : number){
   this.sortArr[triggerIndex] = !this.sortArr[triggerIndex];

   //show & no show sort icons
   for (let index = 0; index < this.showSortArr.length; index++) {
     if(index == triggerIndex){
      this.showSortArr[index] = true;
     }else{
      this.showSortArr[index] = false;
     }
   }

   if(this.attrNameArr.length > 1){
     if(triggerIndex == 0){
       if(!this.sortArr[0]){
         this.displayTableDataArray.sort((prev, next) => prev[0] < next[0] ? 1 : -1);
       }else{
         this.displayTableDataArray.sort((prev, next) => prev[0] > next[0] ? 1 : -1);
       }
     }else if(triggerIndex == 2){
       for (let index = 0; index < this.displayTableDataArray.length; index++) {
         if(!this.sortArr[2]){
           this.displayTableDataArray[index][1].sort((prev, next) => this.toInt(prev.metric, prev.userCount) < this.toInt(next.metric, next.userCount) ? 1 : -1);
         }else{
           this.displayTableDataArray[index][1].sort((prev, next) => this.toInt(prev.metric, prev.userCount) > this.toInt(next.metric, next.userCount) ? 1 : -1);
         }
       }
     }else{
       for (let index = 0; index < this.displayTableDataArray.length; index++) {
         if(this.displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_1'){
           if(!this.sortArr[1]){
             this.displayTableDataArray[index][1].sort((prev, next) => prev.flex1 < next.flex1 ? 1 : -1);
           }else{
             this.displayTableDataArray[index][1].sort((prev, next) => prev.flex1 > next.flex1 ? 1 : -1);
           }
         }else if(this.displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_2'){
           if(!this.sortArr[1]){
             this.displayTableDataArray[index][1].sort((prev, next) => prev.flex2 < next.flex2? 1 : -1);
           }else{
             this.displayTableDataArray[index][1].sort((prev, next) => prev.flex2 > next.flex2 ? 1 : -1);
           }
         }else if(this.displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_3'){
          if(!this.sortArr[1]){
            this.displayTableDataArray[index][1].sort((prev, next) => prev.flex3 < next.flex3? 1 : -1);
          }else{
            this.displayTableDataArray[index][1].sort((prev, next) => prev.flex3 > next.flex3 ? 1 : -1);
          }
        }else if(this.displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_4'){
          if(!this.sortArr[1]){
            this.displayTableDataArray[index][1].sort((prev, next) => prev.flex4 < next.flex4? 1 : -1);
          }else{
            this.displayTableDataArray[index][1].sort((prev, next) => prev.flex4 > next.flex4 ? 1 : -1);
          }
        }else if(this.displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_5'){
          if(!this.sortArr[1]){
            this.displayTableDataArray[index][1].sort((prev, next) => prev.flex5 < next.flex5? 1 : -1);
          }else{
            this.displayTableDataArray[index][1].sort((prev, next) => prev.flex5 > next.flex5 ? 1 : -1);
          }
        }else if(this.displayNameToFlexIndex(this.attrNameArr[1]) == 'country'){
          if(!this.sortArr[1]){
            this.displayTableDataArray[index][1].sort((prev, next) => prev.countryName < next.countryName? 1 : -1);
          }else{
            this.displayTableDataArray[index][1].sort((prev, next) => prev.countryName > next.countryName ? 1 : -1);
          }
        }else{
           if(!this.sortArr[1]){
             this.displayTableDataArray[index][1].sort((prev, next) => prev.ageBand < next.ageBand ? 1 : -1);
           }else{
             this.displayTableDataArray[index][1].sort((prev, next) => prev.ageBand > next.ageBand ? 1 : -1);
           }
         }

       }
     }
   }else if(this.attrNameArr.length > 0){
     if(triggerIndex == 0){
       if(!this.sortArr[0]){
         this.displayTableDataArray.sort((prev, next) => prev[0] < next[0] ? 1 : -1);
       }else{
         this.displayTableDataArray.sort((prev, next) => prev[0] > next[0] ? 1 : -1);
       }
     }else{
       if(!this.sortArr[2]){
         this.displayTableDataArray.sort((prev, next) => this.toInt(prev[1][0].metric, prev[1][0].userCount) < this.toInt(next[1][0].metric, next[1][0].userCount) ? 1 : -1);
       }else{
         this.displayTableDataArray.sort((prev, next) => this.toInt(prev[1][0].metric, prev[1][0].userCount) > this.toInt(next[1][0].metric, next[1][0].userCount) ? 1 : -1);
       }
     }
   }
   
 }

}

export class DisplayModel{
  flex1: string;
  flex2: string;
  flex3: string;
  flex4: string;
  flex5: string;
  countryName: string;
  ageBand: string;
  metric: number;
  userCount: number;
}
