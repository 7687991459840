import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  constructor(private _httpClient: HttpClient) { }

  public getLibraryDictionary() {
    return this._httpClient.get<any>(environment.api + 'api/library/dictionary/selected');
  }

  public listUnselectedLibraries() {
    return this._httpClient.get<any>(environment.api + 'api/library/list/unselected');
  }

  public listSelectedLibraries() {
    return this._httpClient.get<any>(environment.api + 'api/library/list/selected');
  }

  public addLibraryItems(libraryItems: any[]) {
    return this._httpClient.post<boolean>(environment.api + 'api/library/item/add', libraryItems);
  }

  public deleteLibraryItem(libraryItem: any) {
    return this._httpClient.post<boolean>(environment.api + 'api/library/item/delete', libraryItem);
  }

  public updateLibraryItemVisibility(libraryItem: any) {
      return this._httpClient.post<boolean>(environment.api + 'api/library/item/updateVisibility', libraryItem);
  }

  public listLibraryItemGlobalParams(libraryItem: any) {
    return this._httpClient.post<any[]>(environment.api + 'api/library/item/params/list', libraryItem);
  }

  public saveLibraryItemGlobalParams(libraryItems: any[]) {
    return this._httpClient.post<boolean>(environment.api + 'api/library/item/params/save', libraryItems);
  }  

  public setLibraryDictionaryInSession(libraryDictionary: any): void {
    sessionStorage.setItem('LibraryDictionary', JSON.stringify(libraryDictionary));
  }

  public getLibraryDictionaryInSession(): any {
    return JSON.parse(sessionStorage.getItem('LibraryDictionary'));
  }
}
