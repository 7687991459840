<aon-summary-card [height]="cardheight" [width]="cardwidth">
    <aon-summary-card-header>
        <aon-h3 style="color: #001b5a;">
            {{ charttitle }}
        </aon-h3>
    </aon-summary-card-header>
    <aon-summary-card-content top="60px" [contentwidth]="contentwidth">
        <div style="   display: flex;
        flex-wrap: wrap; flex-direction: row wrap;
        justify-content: space-around;
        align-content: flex-start;">

            <div style="flex: 4;">
                <div class="donutTitle">Minimum</div>

                <aon-donut-chart [chartid]="'minimum1'" [width]="width" [height]="height" [legendtitle]="legendtitle"
                    [legendposition]="'none'" [tooltip]="tooltip" [middletext]="'40%'" [data]="dataMin"
                    [legendsubtitle]="legendsubtitleMin">
                </aon-donut-chart>
            </div>
            <div style="flex: 4;">
                <div class="donutTitle">Moderate</div>
                <aon-donut-chart [chartid]="'moderate1'" [width]="width" [height]="height" [legendtitle]="legendtitle"
                    [legendposition]="'none'" [tooltip]="tooltip" [middletext]="'45%'" [data]="dataMax"
                    [legendsubtitle]="legendsubtitleMod">
                </aon-donut-chart>
            </div>
            <div style="flex: 4;">
                <div class="donutTitle">Comfortable</div>
                <aon-donut-chart [chartid]="'comfortable1'" [width]="width" [height]="height"
                    [legendtitle]="legendtitle" [legendposition]="'none'" [tooltip]="tooltip" [middletext]="'15%'"
                    [data]="dataCom" [legendsubtitle]="legendsubtitleCom">
                </aon-donut-chart>
            </div>

            <div style="flex: 3;height:100%;">
                <div class=" border-bottom">
                    <div class="legendClass" style="  display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-content: flex-start;">

                        <div class="legendColumn marginRight5">
                            <div class="padingLeft2 marginRight5">
                                <aon-p-sm class="paddingBottom0 hydrated"><svg width="12" height="12">
                                        <circle r=".35rem" cx="6" cy="6" width="12" height="12" stroke="#00A3C7"
                                            stroke-width="5px" fill="#00A3C7"></circle>
                                    </svg>&nbsp;&nbsp;</aon-p-sm>
                            </div>
                            <div class="marginTop2">
                                <aon-p-sm>
                                    Minimum - Covers all needs with some left for fun ($ 10,000 single)
                                </aon-p-sm>
                            </div>
                        </div>
                        <div class="legendColumn marginRight5">
                            <div class="padingLeft2 marginRight5">
                                <aon-p-sm class="paddingBottom0 hydrated"><svg width="13" height="13">
                                        <circle r=".35rem" cx="6" cy="6" width="13" height="13" stroke="#A21F79"
                                            stroke-width="5px" fill="#A21F79">
                                        </circle>
                                    </svg>&nbsp;&nbsp;</aon-p-sm>
                            </div>
                            <div class="marginTop2">
                                <aon-p-sm>
                                    Moderate - More finanial security and flexibility ($ 20,000 single)
                                </aon-p-sm>
                            </div>
                        </div>
                        <div class="legendColumn marginRight5">
                            <div class="padingLeft2 marginRight5">
                                <aon-p-sm class="paddingBottom0 hydrated"><svg width="14" height="14">
                                        <circle r=".35rem" cx="6" cy="6" width="14" height="14" stroke="#FF7D2E"
                                            stroke-width="5px" fill="#FF7D2E"></circle>
                                    </svg>&nbsp;&nbsp;</aon-p-sm>
                            </div>
                            <div class="marginTop2">
                                <aon-p-sm>
                                    Comfortable - More financial freedom and some luxuries ($ 30,000 single)
                                </aon-p-sm>
                            </div>
                        </div>

                        <!-- <div class="legendColumn marginRight5">
                        <div class="padingLeft2 marginRight5">
                            <aon-p-sm class="paddingBottom0 hydrated"><svg width="12" height="12">
                                    <circle r="1rem" cx="6" cy="6" width="12" height="12" stroke="#A21F79"
                                        fill="url(#diagonalHatch2)"></circle>
                                </svg>&nbsp;&nbsp;</aon-p-sm>
                        </div>
                        <div class="marginTop2">
                            <aon-p-sm>
                                Employees are on track to reach or exceed their chosen income level
                            </aon-p-sm>
                        </div>
                    </div>
                    <div class="legendColumn marginRight5">
                        <div class="padingLeft2 marginRight5">
                            <aon-p-sm class="paddingBottom0 hydrated"><svg width="12" height="12">
                                    <circle r="1rem" cx="6" cy="6" width="12" height="12" stroke="#A21F79"
                                        fill="url(#diagonalHatch2)"></circle>
                                </svg>&nbsp;&nbsp; </aon-p-sm>
                        </div>
                        <div class="marginTop2">
                            <aon-p-sm>
                                Employees are likely to fall short of desired income level
                            </aon-p-sm>
                        </div>
                    </div> -->
                    </div>
                    <!-- <aon-legend height="90%" [data]="legendTarget" [legendposition]="'vertical'"></aon-legend> -->
                </div>
                <div class=" border-bottom" style="margin-top: 40px;">
                    <aon-legend height="90%" [data]="legendTargetLabel" [legendposition]="'vertical'"></aon-legend>
                </div>

            </div>

        </div>
    </aon-summary-card-content>
</aon-summary-card>