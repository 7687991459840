export const locale = {
    lang: 'es-ec',
    data: {
      'ClaimCostTopProvider': {
        'ClaimTopProviders' : 'Costo de Reclamo por los principales Prestadores',        
        'ClaimCostByTopDiagnoses':'Gasto - Por Top Diagnósticos',
        'ProviderDiag' : 'Proveedor v Diagnostico',
        'AllAmountsDisplayedInLocalCurrency' : 'Todas las cantidades mostradas en moneda local',
        'TopProvider' : 'Proveedores principales',
        'ProviderName': 'Nombre del Prestador', 
        'TopDiagnoses': 'Top Diagnósticos',
        'DiagnosisName': 'Nombre Diagnostico' ,          
        'TotalPaidClaim' : 'Total pagado de reclamo', 
        'ClaimantCount': 'Parentesco de Demandantes',
        'ClaimCount': 'Número de Eventos',            
        'ClaimsPerClaimant' : 'Reclamos por Reclamante', 
        'CostPerClaim': 'Costo por reclamo',
        'CostPerClaimant': 'Costo por reclamante', 
        '%ChangeinCost':'% De cambio en el costo',
        'Claim' : 'Reclamo', 
        'Claimant' : 'Reclamante', 
        'ClaimsTotal':'Total de Reclamaciones',
        'Count': 'Contar',
        'AverageCost': 'Costo Promedio',
        'ClaimsReportedByPaidDate': 'Reclamaciones reportadas por fecha de pago',
        'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
        'Benchmark':'Benchmark',
        'IncludeClient': 'Incluir Cliente',
        'ExcludeClient': 'Excluir Cliente',
        'Portfolio': 'Portafolio',
        'Industry': 'Industria',
        'None': 'Ninguna',
        'SelectTop': 'Seleccione top',
        'FilterNA': 'Excluir NA',
        'CountBasis': 'Contar Base',
        'Sort': 'Orden',
        'Reset':'Reiniciar',            
        'DiagnosisLevel':'Nivel Diagnostico',
        'Category':'Categoría',
        'Chapter':'Cuadro',
        'ShownAsPer':'Se muestra según',
        'DateRange':'to'
        
    }
}
};
