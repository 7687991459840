
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more"; //module
HC_more(Highcharts); //init module
import { Chart } from "angular-highcharts";
import { Options } from "highcharts";
import { formatNumber } from '@angular/common';
import { countryLATEM } from 'app/constants/countries_LATAM';
import { NumberFormattingService } from "app/services/number-formatting.service";
@Component({
  selector: 'app-bar-insidebar',
  templateUrl: './bar-insidebar.component.html',
  styleUrls: ['./bar-insidebar.component.css']
})
export class BarInsidebarComponent implements OnInit, OnChanges {
  chart: Chart;
  options: Options;
  lengthList: boolean;
  @Input() categories: any;
  @Input() dataListIn: any;
  @Input() dataListOut: any;
  @Input() MaxOfY: number;
  @Input() claimPMPMSelected: boolean;
  @Input() Monthly: boolean;
  @Input() plotLinesX: any;
  @Input() decimalPlaces: number = 0;
  @Input() isCountryChile: boolean = false;
  @Input() country: string;
  @Input() combinedLRSelected:boolean = false;  
  @Input() seriesBarName:string;
  @Input() seriesLineName:string;
  @Input() colorBar;
  @Input() colorLine;
  decimalInfo = '0.0-' + this.decimalPlaces.toString();
  ngOnInit() {
    this.initialize();
    this.chart = new Chart(this.options);
  }
  ngOnChanges() {
   
    this.checkNullOrUndefinedSet();
    this.initialize();
    this.chart = new Chart(this.options);
  }

  checkNullOrUndefinedSet(){
    
      for(var count =0; count < this.dataListIn.length; count++)
      {
        var currentRow = this.dataListIn[count];
        if(currentRow.y === undefined)
        {
          if(currentRow === null || currentRow === undefined){
            currentRow = 0;
          }
        }
        else
        {
          if(currentRow.y === null || currentRow.y === undefined){
            currentRow.y = 0;
          }
        }       
      }
      for(var count =0; count < this.dataListOut.length; count++)
      {
        var currentRow = this.dataListOut[count];
        if(currentRow.y === undefined)
        {
          if(currentRow === null || currentRow === undefined){
            currentRow = 0;
          }
        }
        else
        {
          if(currentRow.y === null || currentRow.y === undefined){
            currentRow.y = 0;
          }
        }  
      }    
  }


  constructor(private _numberFormatService: NumberFormattingService) {
  }
  initialize() {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
        //numericSymbols: ["K","M","B","T","P","E"]
      }
    });
    this.lengthList = this.categories.length > 12 ? true : false;
    const pointWide = this.Monthly ? 24 : (((this.categories.length === 12) || (this.categories.length === 6)) ? 70 : null);
    const claimPMPM = this.claimPMPMSelected;
    const decimalInfo = '0.0-' + this.decimalPlaces.toString();
    const parent = this;

    this.options = {
      chart: {
        type: 'column',
        height: 300,
        marginLeft: 90
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        labels: {
          enabled: false
        },
        categories: this.categories,
        plotLines: this.plotLinesX
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 1,
        softMin: 0,
        max: this.MaxOfY,
        title: {
          text: ''
        },
        labels: {
          formatter: function(){
              return countryLATEM.indexOf(parent.country) >= 0 ?
              parent._numberFormatService.ToAbbrevNumberStringLatem(this.value, 4) :
              parent._numberFormatService.ToAbbrevNumberString(this.value, 4)            
          },
          style: {
            fontSize: this.Monthly ? '11px' : '13px',
            color: 'black',
            textOutline: 'none',
            fontWeight: '400',
            fontFamily: 'Helvetica Now Text, Arial,Open Sans, Helvetica, sans-serif'
          }
        },
        plotLines: [
        {
          value: 0,
          width: 1,
          color: '#aaa',
          zIndex: 10
        }
      ]
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: true,
         shared: true,
        useHTML: true,        
        headerFormat: "{point.x}<br> <br>",
        pointFormat:  this.combinedLRSelected ?
        ((claimPMPM && this.isCountryChile == true) ?
         "<b>Total</b>: {point.y:,.2f}<br> <b>{point.CPText}</b>: {point.CPVal:,.2f} <br><b>{point.EIText}</b>: {point.EIVal:,.2f} <br><br>":
         
         "<b>Total</b>: {point.y:,.0f}<br> <b>{point.CPText}</b>: {point.CPVal:,.0f} <br><b>{point.EIText}</b>: {point.EIVal:,.0f} <br><br>")
         : ((claimPMPM && this.isCountryChile == true) ? "<b>{series.name}</b>: {point.y:,.2f} <br>" : "<b>{series.name}</b>: {point.y:,.0f}<br>")      
         ,
          style: {
          color: 'black',
          fontSize: '14px',
          textOutline: 'none',
          fontWeight: '400',
          fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif'
        }
      },
      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (this.y > 0) {
                if (claimPMPM) {
                  return formatNumber(this.y, 'en-US', decimalInfo);
                }
                else {
                  return parent.getLabelText(this.y);
                }
              }             
            }
          }
        }, line: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              
              if (this.y > 0) {
                if (claimPMPM) {
                  return formatNumber(this.y, 'en-US', decimalInfo);
                }
                else {
                  return parent.getLabelText(this.y);
                }
              }
                         
            },
            y: 30,
            style: {
              fontSize: this.Monthly ? '11px' : '13px',
              fontWeight: 'bold',
              color:  this.colorLine,
              textOutline: 'none',              
              fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif',
              textShadow: '0px 0px 5px white',
            }
          }
        }
      },
      series: [{
        type: 'column',
        name: this.seriesBarName,
        color:this.colorBar,
        data: this.dataListOut,
        pointWidth: pointWide,
        pointPadding: .3,
        //pointPlacement: -0.2,
        dataLabels: {
          enabled: true,
          y: this.lengthList ? -30 : 0,
          align: 'center',
          rotation: this.lengthList ? -90 : 0,
          style: {
            fontSize: this.Monthly ? '11px' : '13px',
            color: this.colorBar,
            textOutline: 'none',
            fontWeight: 'bold',
          }
        }
      }, {
        type: 'line',
        name: this.seriesLineName,
        color: this.colorLine,
        data: this.dataListIn,
        lineWidth: 3,
        marker: {
          symbol: 'circle',
          radius: 7,
        },
      }]

    }
  }

  getLabelText(yval: any) {
    if (this.isCountryChile) {
      return countryLATEM.indexOf(this.country) >= 0 ?
        this._numberFormatService.ToAbbrevNumberStringLatem(yval, 4) :
        this._numberFormatService.ToAbbrevNumberString(yval, 4)
    }
    else {
      return countryLATEM.indexOf(this.country) >= 0 ?
        this._numberFormatService.ToAbbrevNumberStringLatem(yval, 3) :
        this._numberFormatService.ToAbbrevNumberString(yval, 3)
    }
  }
}
