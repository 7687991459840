import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';

@Component({
  selector: 'app-scatter',
  templateUrl: './scatter.component.html',
  styleUrls: ['./scatter.component.css']
})
export class ScatterComponent implements OnInit {

  constructor() { }

  chart: Chart;
  options: Options;
  @Input() dataList: any;
  ngOnInit() {
    this.initialize();
    this.chart = new Chart(this.options);
  }
  initialize() {
    this.options = {
      chart: {
        type: 'scatter',
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        title: {
          text: 'Claimant Count'
        },
        min: 0,
        max: 150,
        tickInterval: 10,
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true
      },
      yAxis: [{
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        min: 0,
        max: 16000,
        tickInterval: 2000,
        lineWidth: 1,
        title: {
          text: "Cost Per Claimant"
        },
        labels: {
          formatter: function () {
            if (this.value > 1000) {
              let p: string = (this.value / 1000) + "K";
              return p;
            }
            else return this.value.toString();
          }
        }
      }],
      plotOptions: {
        scatter: {
          dataLabels: {
            enabled: true,
            align: "left",
            padding: 5,
            style: {
              color: '#848285',
            }, formatter: function () {
              return this.point.name;
              ;
            }
          },
          marker: {
            radius: 5,
          },
          // tooltip: {
          //   //  headerFormat: '<b>{series.name}</b><br>',
          //   //  pointFormat: '{point.x} , {point.y} '
          // }
        }, series: {
          states: {
            inactive: {
              opacity: 1
            }
          }
        }

      },
      tooltip: {
        enabled: false
      },
      // to hide series name
      legend: {
        enabled: false
      },

      series: [
        {
          type: undefined,
          marker: {
            symbol: 'circle',
            radius: 5,
          },
          name: 'claim',
          color: '#525457',
          data: [{ x: 30, y: 1000, name: "Cholelithiasis" }, { x: 50, y: 2000, name: "Essential Hypertension" }

          ]

        },
        {
          type: undefined,
          marker: {
            symbol: 'circle',
            radius: 8,
          },
          name: 'claim',
          color: '#7a0e75',
          data: [{ x: 60.2, y: 10000, name: "Hypertensive Heart disease " }
            , { x: 80, y: 4100, name: "Diabeties" }
          ]
        },
        {
          type: undefined,
          marker: {
            symbol: 'circle',
            radius: 12,
          },
          name: 'claim',
          color: '#1365d1',
          data: [{ x: 45, y: 9900, name: "Pneumonia" }, { x: 100, y: 5000, name: "Gastro" }
          ]
        },
        {
          type: undefined,
          marker: {
            symbol: 'circle',
            radius: 7,
          },
          name: 'claim',
          color: '#ac87b8',
          data: [{ x: 69, y: 8000, name: "infection" }
          ]
        }
      ]
    }
  }
}