export const locale = {
    lang: 'en',
    data: {
        'PerMemberCosts': {
            'PerMemberCosts': 'Per Member Costs',
            'AllAmountsDisplayedInLocalCurrency': 'All amounts displayed in local currency',
            'KeyImpacts': 'Key Impacts',
            'CurrentPmpm': 'CURRENT PMPM',
            'BenchmarkPmpm': 'BENCHMARK PMPM',
            'PerMemberPerMonthCost': 'Per Member Per Month Cost',
            'Prior': 'Prior',
            'Current': 'Current',
            'YoYChange': 'YoY Change',
            'Benchmark': 'Benchmark',
            'IncludeClient': 'Include Client',
            'ExcludeClient': 'Exclude Client',
            'Portfolio': 'Portfolio',
            'Industry': 'Industry',
            'None': 'None',
            'AgeBand': 'Age Band',
            'Gender': 'Gender',
            'Relationship': 'Relationship',
            'ClaimsReportedByPaidDate': 'Claims Reported by Paid Date',
            'ClaimsReportedByIncurredDate': 'Claims reported by incurred date', 
            'Chart':'Chart',
            'Table':'Table',
            'DateRange': 'to'
        }
    }
};
