import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-challengeselectiondialog',
  templateUrl: './challengeselectiondialog.component.html',
  styleUrls: ['./challengeselectiondialog.component.scss']
})
export class ChallengeselectiondialogComponent implements OnInit {
    selectedChallengeId: string = null;
    challengeList: any[] = null;

    newChallengeId: string = null;

    constructor(public dialogRef: MatDialogRef<ChallengeselectiondialogComponent>,
        @Inject(MAT_DIALOG_DATA) public options: any) {
            this.selectedChallengeId = options.selectedChallengeId;
            this.newChallengeId = options.selectedChallengeId;
            this.challengeList = options.challengeList;
        }

    ngOnInit(): void {

    }

    onSelectionChange(selection: any): void {
        this.newChallengeId = selection.detail;
    }

    apply(): void {
        this.dialogRef.close(this.newChallengeId);
    }

    cancel(): void {
        this.dialogRef.close(null);
    }

}
