import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as navigationEnglish } from 'app/admin/storiesadmin/i18n/en';
import { locale as navigationSpanishCL } from 'app/admin/storiesadmin/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/admin/storiesadmin/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/admin/storiesadmin/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/admin/storiesadmin/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/admin/storiesadmin/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/admin/storiesadmin/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/admin/storiesadmin/i18n/it';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { UserService } from 'app/services/user.service';
import { StoryService } from 'app/services/story.service';
import * as moment from 'moment';
import { AgmaticonactionComponent } from 'app/shared/agmaticonaction/agmaticonaction.component';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { userActionLog, LogActionType } from 'app/models/log.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-storiesadmin',
  templateUrl: './storiesadmin.component.html',
  styleUrls: ['./storiesadmin.component.css']
})
export class StoriesadminComponent implements OnInit {
  logModel: userActionLog = new userActionLog();

  searchUserResults = [];
  currentUser: any;
  selectedCountryName = '';
  countrySelectionList = [];

  userList = [];
  clonedUserList = [];
  isUserListLoaded = false;

  storyList = [];
  isStoryListLoaded = false;
  selectedStory: any;
  isShowAssignUser = false;
  isEditor = false;

  userColumnDefs = [];
  storyColumnDefs = [];

  isStoryUserSelectAll = false;

  @ViewChild('storiesGrid', { static: true }) storiesGrid;
  @ViewChild('storyUsersGrid') storyUsersGrid;

  constructor(
    private _translateService: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    public adminService: AdminService,
    public userService: UserService,
    public storyService: StoryService,
    public router: Router,
    public toasterService: ToasterService,
    public dialog: MatDialog) {

  }

  ngOnInit() {
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationSpanishCL,
      navigationSpanishCO,
      navigationSpanishEC,
      navigationSpanishMX,
      navigationSpanishPE, 
      navigationPortugueseBR,
      navigationItalianIT,
    );

    this.isStoryListLoaded = false;
    this.storyService.getUserStories().toPromise().then(data => {
      if (data) {
        this.isStoryListLoaded = true;
        this.storyList = data;
      }
    });

    this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log('LangChangeEvent');
      console.log(event);
      this.userColumnDefs = event.translations.StoriesAdmin.userColumnDefs;
      this.setStoryColDef();
    });
    this.setStoryColDef();
    const currentTranslation = this._translateService.store.translations[this._translateService.currentLang];
    this.userColumnDefs = currentTranslation.StoriesAdmin.userColumnDefs;

    this.isEditor = this.userService.isAdmin() || this.userService.isRegionalManager();
    this.isShowAssignUser = this.userService.isAdmin() || this.userService.isRegionalManager() || this.userService.isCountryManager();
    this.currentUser = this.userService.user;
  }

  setStoryColDef() {
    this.storyColumnDefs = [
      { headerName: this._translateService.instant('StoriesAdmin.Name'), field: 'name' },
      { headerName: this._translateService.instant('StoriesAdmin.CreatedBy'), field: 'createdBy' },
      {
        headerName: this._translateService.instant('StoriesAdmin.CreatedOn'), field: 'createdOn', sort: 'desc', width: 200, suppressSizeToFit: true,
        cellRenderer: (cell) => {
          const momentDate = moment.utc(cell.data.createdOn).toDate();
          return moment(momentDate).local().format('MM/DD/YYYY HH:mm');
        }
      },
      {
        width: 80,
        suppressSizeToFit: true,
        filter: false,
        cellRendererFramework: AgmaticonactionComponent,
        cellRendererParams: {
          action: (row) => this.onEditStory({ data: row.data }),
          icon: 'edit',
          isDisabled: (row) => this.isDisabledEdit({ data: row.data })
        }
      }
    ]
  }

  isDisabledEdit(row) {
    return (!this.userService.isAdmin() && row.data.createdBy.indexOf(this.currentUser.email) === -1 && !row.data.isEditable);
  }

  onAddStory() {
    delete this.storyService.currentStoryId;

    this.router.navigate(['/story/editor/']);
  }

  onEditStory($event) {
    const storyToBeEdited = $event.data;
    this.storyService.setCurrentOpenStory(storyToBeEdited, 'editor');

    this.router.navigate(['/story/editor/' + storyToBeEdited.storiesId]);

    localStorage.setItem('story', JSON.stringify(storyToBeEdited));
  }

  onViewStory($event) {
    const storyToBeViewed = $event.data;

    this.storyService.setCurrentOpenStory(storyToBeViewed, 'view');

    this.router.navigate(['/story/view/' + storyToBeViewed.storiesId]);

    localStorage.setItem('story', JSON.stringify(storyToBeViewed));
  }

  onStorySelectionChanged($event) {
    this.isStoryUserSelectAll = false;
    this.isUserListLoaded = false;
    this.selectedStory = $event.data;
    this.adminService.getUserMappingsByStory(this.selectedStory.storiesId).toPromise().then(data => {
      if (data) {
        this.isUserListLoaded = true;
        this.countrySelectionList = data.countrySelectionList;
        this.userList = data.userMappingList;
        this.clonedUserList = JSON.parse(JSON.stringify(this.userList));
      }
    });
    localStorage.setItem('story', JSON.stringify(this.selectedStory));

  }

  onStorySelectionSelectAll() {
    this.isStoryUserSelectAll = !this.isStoryUserSelectAll;
    this.storyUsersGrid.toggleSelectAll(this.isStoryUserSelectAll);
  }

  onStoryDeletion($event) {
    const storyToBeDeleted = $event.data;

    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: 'Confirm Deletion of ' + storyToBeDeleted.name, message: 'Are you sure? All mapping will be deleted as well.' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result === 'undefined') {
        return;
      }

      if (result === false) {
        return;
      }

      this.storiesGrid.showLoadingOverLay(true);
      this.isStoryListLoaded = false;

      this.storyService.deleteStoryById(storyToBeDeleted.storiesId).toPromise().then(data => {
        this.selectedStory = null;
      }).finally(() => {
        this.storyService.getUserStories().toPromise().then(data => {
          if (data) {
            this.storyList = data;
          }
          this.isStoryListLoaded = true;
          this.storiesGrid.showLoadingOverLay(false);
        });
      });
    });
  }

  onCountrySelectionChange() {
    if (this.selectedCountryName === '') {
      this.userList = JSON.parse(JSON.stringify(this.clonedUserList));
      return;
    }

    this.userList = [];

    this.clonedUserList.forEach(user => {
      if (user.countryList.indexOf(this.selectedCountryName) >= 0) {
        this.userList.push(user);
      }
    });
  }

  onUpdateStoryUsers() {
    this.isUserListLoaded = false;

    const finalUserList = this.clonedUserList.map(clu => {
      const match = this.userList.filter(ul => ul.userId === clu.userId)[0];
      if (match) {
        return match;
      } else {
        return clu;
      }
    });



    const selectedUserIdList = finalUserList.filter(user => user.isSelected).map(user => user.userId);
    const unselectedUserIdList = finalUserList.filter(user => !user.isSelected).map(user => user.userId);


    this.adminService.updateUserMappingsByStoryUser(this.selectedStory.storiesId, selectedUserIdList, unselectedUserIdList).toPromise().then(() => {

      this.storyService.getAssignedUserStories().toPromise().then(data => {
        this.storyService.updateStoryNavigation(data);
      });

      this.adminService.getUserMappingsByStory(this.selectedStory.storiesId).toPromise().then(data => {
        if (data) {
          this.isUserListLoaded = true;
          this.selectedCountryName = '';
          this.countrySelectionList = data.countrySelectionList;
          this.userList = data.userMappingList;
          this.clonedUserList = JSON.parse(JSON.stringify(this.userList));
          this.toasterService.showMessage(this._translateService.instant('StoriesAdmin.Success'), ToasterServiceModes.OK);
        }
      });
    });


    localStorage.setItem('story', JSON.stringify(this.selectedStory));
  }
}