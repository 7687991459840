import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toAddOrMinusOperator'
})
export class ToAddOrMinusOperatorPipe implements PipeTransform {

    transform(operator: string, trailingOperand: number): string {
        if (operator !== '+' && operator !== '-') {
            return '';
        }

        const operandOperator = this.getAddOrMinusOperatorForOperand(trailingOperand);

        if (operator === '+' && operandOperator === '+') {
            return '+';
        }

        if (operator === '-' && operandOperator === '-') {
            return '+';
        }

        return '-';
    }

    private getAddOrMinusOperatorForOperand(operand: number) {
        return operand >= 0 ? '+' : '-';
    }

}
