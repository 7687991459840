<div
    id="perMemDashboard"
    style="min-height: 795px; padding: 8px; overflow: auto; position: relative"
    fxLayout="row"
>
    <tableau-spinner
        *ngIf="isLoading"
        style="position: absolute; top: 280px; left: 48%; z-index: 90"
    ></tableau-spinner>
    <div fxFlex fxLayout="column">
        <div id="perMemHeader" class="dash-header">
            {{ "PerMemberCosts.PerMemberCosts" | translate }}
            <button
                id="perMemHeaderBtn"
                mat-icon-button
                style="float: right"
                (click)="showFilter = !showFilter"
            >
                <app-filter-button
                    [isClientName]="true"
                    [isEntity]="true"
                    [isPolicyType]="true"
                ></app-filter-button>
            </button>
        </div>
        <div fxLayout="row" style="width: 100%">
            <div
                id="perMemHeaderCurrency"
                style="font-size: 14px; font-style: italic; padding: 3px"
            >
                {{
                    "PerMemberCosts.AllAmountsDisplayedInLocalCurrency"
                        | translate
                }}
            </div>
            <div fxFlex></div>
            <div
                id="perMemHeaderPeriod"
                *ngIf="dateRange && properties.chartTable === ChartTable.Table"
                style="font-size: 14px; padding: 3px"
            >
                {{ dateRange }} {{ comparable }}
            </div>
        </div>
        <div
            fxLayout="row"
            style="margin-top: 15px"
            style="padding: 12px 0px"
            fxLayoutAlign="center center"
        >
            <div id="perMemImpact" fxFlex fxLayout="column">
                <div
                    fxLayout="row"
                    fxLayoutGap="15px"
                    style="text-align: center"
                >
                    <div
                        id="perMemKeyImpactsPluse"
                        class="dashbox"
                        fxFlex="50%"
                    >
                        <div
                            class="title"
                            style="background-color: #101e7f"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                        >
                            {{ "PerMemberCosts.KeyImpacts" | translate }} (+)
                        </div>
                        <div
                            fxLayout="row"
                            *ngFor="let positiveKeyImpact of positiveKeyImpacts"
                            style="
                                padding: 8px;
                                font-weight: bold;
                                line-height: 16px;
                            "
                        >
                            <div fxFlex="40%">
                                {{ positiveKeyImpact.dimension }}
                            </div>
                            <div fxFlex="30%">
                                {{ positiveKeyImpact.secondaryDimension }}
                            </div>
                            <div fxFlex="30%">
                                {{
                                    positiveKeyImpact.impact
                                        | roundToDecimalPlace: 1
                                }}%
                            </div>
                        </div>
                    </div>
                    <div
                        cid="perMemKeyImpactsMinus"
                        class="dashbox"
                        fxFlex="50%"
                    >
                        <div class="title" style="background-color: #8b8b8d">
                            {{ "PerMemberCosts.KeyImpacts" | translate }} (-)
                        </div>
                        <div
                            fxLayout="row"
                            *ngFor="let negativeKeyImpact of negativeKeyImpacts"
                            style="
                                padding: 8px;
                                font-weight: bold;
                                line-height: 16px;
                            "
                        >
                            <div fxFlex="40%">
                                {{ negativeKeyImpact.dimension }}
                            </div>
                            <div fxFlex="30%">
                                {{ negativeKeyImpact.secondaryDimension }}
                            </div>
                            <div fxFlex="30%">
                                {{
                                    negativeKeyImpact.impact
                                        | roundToDecimalPlace: 1
                                }}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="perMemCurBench" fxFlex="40%" fxLayout="column">
                <div
                    fxLayout="column"
                    style="padding: 0px 36px; text-align: center"
                >
                    <div id="perMemcurrent" fxLayout="row">
                        <div id="perMemcurrentImage" fxFlex="20">
                            <img
                                src="{{
                                    imageUrlBase
                                }}/assets/aonicons/Calendar of Events-2.png"
                                style="height: 80px"
                            />
                        </div>
                        <div
                            id="perMemcurrentTxt"
                            fxFlex
                            style="
                                font-size: 22px;
                                padding-top: 25px;
                                font-weight: bold;
                                color: #8b8b8d;
                            "
                        >
                            {{ "PerMemberCosts.CurrentPmpm" | translate }}
                        </div>
                        <div
                            id="perMemcurrentVal"
                            fxFlex="20"
                            style="
                                text-align: right;
                                font-size: 26px;
                                padding-top: 25px;
                                font-weight: bold;
                                color: #101e7f;
                            "
                        >
                            {{
                                currentPMPM
                                    | roundToDecimalPlace
                                        : (properties.country === "Chile"
                                              ? 2
                                              : 0)
                                    | numberFormat
                            }}
                        </div>
                    </div>
                    <div
                        id="perMemBenchmarkPMPM"
                        fxLayout="row"
                        *ngIf="
                            eligibleForBenchmark &&
                            properties.benchmarkLevel != 3
                        "
                    >
                        <div id="perMemBenchmarkPMPMIg" fxFlex="20">
                            <img
                                src="{{
                                    imageUrlBase
                                }}/assets/aonicons/Gauge-Measure-1.png"
                                style="height: 80px"
                            />
                        </div>
                        <div
                            id="perMemBenchmarkPMPMTxt"
                            fxFlex
                            style="
                                font-size: 22px;
                                padding-top: 25px;
                                font-weight: bold;
                                color: #8b8b8d;
                            "
                        >
                            {{ "PerMemberCosts.BenchmarkPmpm" | translate }}
                        </div>
                        <div
                            id="perMemBenchmarkPMPMVal"
                            fxFlex="20"
                            style="
                                text-align: right;
                                font-size: 26px;
                                padding-top: 25px;
                                font-weight: bold;
                                color: #555;
                            "
                        >
                            {{
                                benchmarkPMPM
                                    | roundToDecimalPlace
                                        : (properties.country === "Chile"
                                              ? 2
                                              : 0)
                                    | numberFormat
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div
                id="perMemSubHeading"
                style="font-weight: bold; font-size: 18px; margin-left: 15px"
            >
                {{ "PerMemberCosts.PerMemberPerMonthCost" | translate }}
            </div>
            <hr id="line" />
        </div>
        <div fxFlex fxLayout="row">
            <div
                [fxFlex]="noneSelected ? 20 : 10"
                fxLayout="column"
                *ngIf="properties.chartTable == ChartTable.Table"
            ></div>
            <div
                id="perMemTable"
                [fxFlex]="noneSelected ? 60 : 70"
                fxLayout="column"
                *ngIf="properties.chartTable == ChartTable.Table"
            >
                <div
                    id="perMemTableHeader"
                    fxFlex
                    fxLayout="row"
                    class="tableheader"
                >
                    <div
                        *ngIf="
                            properties.dimension ==
                            AgeBandGenderRelationshipOption.AgeBand
                        "
                        [fxFlex]="noneSelected ? 25 : 20"
                    >
                        {{ "PerMemberCosts.AgeBand" | translate }}
                    </div>
                    <div
                        *ngIf="
                            properties.dimension ==
                            AgeBandGenderRelationshipOption.Gender
                        "
                        [fxFlex]="noneSelected ? 25 : 20"
                    >
                        {{ "PerMemberCosts.Gender" | translate }}
                    </div>
                    <div
                        *ngIf="
                            properties.dimension ==
                            AgeBandGenderRelationshipOption.Relationship
                        "
                        [fxFlex]="noneSelected ? 25 : 20"
                    >
                        {{ "PerMemberCosts.Relationship" | translate }}
                    </div>
                    <div [fxFlex]="noneSelected ? 25 : 20">
                        {{ "PerMemberCosts.Prior" | translate }}
                    </div>
                    <div [fxFlex]="noneSelected ? 25 : 20">
                        {{ "PerMemberCosts.Current" | translate }}
                    </div>
                    <div [fxFlex]="noneSelected ? 25 : 20">
                        {{ "PerMemberCosts.YoYChange" | translate }}
                    </div>
                    <div
                        fxFlex="20"
                        *ngIf="eligibleForBenchmark && !noneSelected"
                    >
                        {{ "PerMemberCosts.Benchmark" | translate }}
                    </div>
                </div>
                <div
                    fxFlex
                    fxLayout="row"
                    class="tablerow"
                    *ngFor="let xo of pmcItemsGrid"
                >
                    <div [fxFlex]="noneSelected ? 25 : 20">
                        {{ xo.dimension }}
                    </div>
                    <div [fxFlex]="noneSelected ? 25 : 20">
                        {{
                            xo.prior
                                | roundToDecimalPlace
                                    : (properties.country === "Chile" ? 2 : 0)
                                | numberFormat
                        }}
                    </div>
                    <div [fxFlex]="noneSelected ? 25 : 20">
                        {{
                            xo.current
                                | roundToDecimalPlace
                                    : (properties.country === "Chile" ? 2 : 0)
                                | numberFormat
                        }}
                    </div>
                    <div [fxFlex]="noneSelected ? 25 : 20">
                        {{
                            xo.yoYChange
                                | roundToDecimalPlace: 1
                                | numberFormat
                        }}%
                    </div>
                    <div
                        fxFlex="20"
                        *ngIf="eligibleForBenchmark && !noneSelected"
                    >
                        {{
                            xo.benchmark
                                | roundToDecimalPlace
                                    : (properties.country === "Chile" ? 2 : 0)
                                | numberFormat
                        }}
                    </div>
                </div>
            </div>

            <div
                [fxFlex]="2"
                fxLayout="column"
                *ngIf="properties.chartTable == ChartTable.Chart"
            ></div>
            <div
                [fxFlex]="78"
                fxLayout="column"
                *ngIf="properties.chartTable == ChartTable.Chart"
            >
                <ng-container id="perMemDualBarChart" *ngIf="parentLoaded">
                    <app-dual-bar
                        [categories]="categories"
                        [dataListBar1]="dataListBar1Prior"
                        [dataListBar2]="dataListBar2Current"
                        [dataListLine]="
                            !eligibleForBenchmark || noneSelected
                                ? []
                                : dataListLinebenchmark
                        "
                        [decimalPlaces]="properties.country === 'Chile' ? 2 : 0"
                        [nameBar1]="priorDateRange"
                        [nameBar2]="currentDateRange"
                        [nameLine]="
                            !eligibleForBenchmark || noneSelected
                                ? ''
                                : benchmarkLabel
                        "
                        [country]="properties.country"
                    ></app-dual-bar>
                    <div
                        id="perMemLegendsBar"
                        fxLayout="row"
                        *ngIf="data.costs.length > 0"
                    >
                        <div fxFlex="40"></div>
                        <div fxFlex="18">
                            <svg width="15" height="15">
                                <rect
                                    width="15"
                                    height="15"
                                    fill="#29B0C3"
                                ></rect>
                            </svg>
                            {{ priorDateRange }}
                        </div>
                        <div fxFlex="18">
                            <svg width="15" height="15">
                                <rect
                                    width="15"
                                    height="15"
                                    fill="#101E7F"
                                ></rect>
                            </svg>
                            {{ currentDateRange }}
                        </div>
                        <div
                            fxFlex="14"
                            *ngIf="eligibleForBenchmark && !noneSelected"
                        >
                            <svg width="15" height="15">
                                <rect
                                    width="15"
                                    height="15"
                                    fill="#FF803E"
                                ></rect>
                            </svg>
                            {{ benchmarkLabel }}
                        </div>
                        <div fxFlex></div>
                    </div>
                </ng-container>
            </div>
            <ng-container *ngIf="eligibleForBenchmark">
                <div>
                    <button
                        id="perMemBtnBench"
                        mat-icon-button
                        (click)="showBM = !showBM"
                        style="margin-top: -5px"
                    >
                        <mat-icon aria-label="Side nav toggle icon"
                            >menu</mat-icon
                        >
                    </button>
                </div>
                <div fxFlex="20%" fxLayout="column" style="padding: 0 25px">
                    <div
                        id="perMemBenchOption"
                        style="margin-top: 10px"
                        *ngIf="showBM"
                    >
                        <div fxLayout="row" style="height: 50px"></div>
                        <div
                            id="perMemIncludeClient"
                            fxLayout="row"
                            (click)="selectionChanged(1, 'benchmarkInclude')"
                            fxLayoutAlign="center center"
                            class="dash-option"
                            [ngClass]="{
                                'dash-option-selected':
                                    properties.benchmarkInclude == 1
                            }"
                        >
                            <span>{{
                                "PerMemberCosts.IncludeClient" | translate
                            }}</span>
                        </div>
                        <div
                            id="perMemExcludeClient"
                            fxLayout="row"
                            (click)="selectionChanged(2, 'benchmarkInclude')"
                            fxLayoutAlign="center center"
                            class="dash-option"
                            [ngClass]="{
                                'dash-option-selected':
                                    properties.benchmarkInclude == 2
                            }"
                        >
                            <span>{{
                                "PerMemberCosts.ExcludeClient" | translate
                            }}</span>
                        </div>
                        <div fxLayout="row" style="height: 50px"></div>
                        <div
                            id="perMemPortfolio"
                            fxLayout="row"
                            (click)="selectionChanged(1, 'benchmarkLevel')"
                            fxLayoutAlign="center center"
                            class="dash-option"
                            [ngClass]="{
                                'dash-option-selected':
                                    properties.benchmarkLevel == 1
                            }"
                        >
                            <span>{{
                                "PerMemberCosts.Portfolio" | translate
                            }}</span>
                        </div>
                        <div
                            id="perMemIndustry"
                            fxLayout="row"
                            (click)="selectionChanged(2, 'benchmarkLevel')"
                            fxLayoutAlign="center center"
                            class="dash-option"
                            [ngClass]="{
                                'dash-option-selected':
                                    properties.benchmarkLevel == 2
                            }"
                        >
                            <span>{{
                                "PerMemberCosts.Industry" | translate
                            }}</span>
                        </div>
                        <div
                            id="perMemNone"
                            fxLayout="row"
                            (click)="selectionChanged(3, 'benchmarkLevel')"
                            fxLayoutAlign="center center"
                            class="dash-option"
                            [ngClass]="{
                                'dash-option-selected':
                                    properties.benchmarkLevel == 3
                            }"
                        >
                            <span>{{ "PerMemberCosts.None" | translate }}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div fxLayout="row">
            <div fxFlex fxLayout="column"></div>
            <div
                id="perMemFooterNote"
                fxLayout="column"
                style="margin-right: 20px"
            >
                {{
                    properties.claimPaid === CLAIM_BASIS.PAID_BASIS ||
                    apacCountries.includes(properties.country)
                        ? ("PerMemberCosts.ClaimsReportedByPaidDate"
                          | translate)
                        : ("PerMemberCosts.ClaimsReportedByIncurredDate"
                          | translate)
                }}
            </div>
        </div>
    </div>
    <div
        style="
            width: 200px;
            padding: 80px 0px 0px 16px;
            border-left: 1px solid #eee;
        "
        fxLayout="column"
    >
        <div
            id="entityDivisionFilters"
            [style.visibility]="showFilter ? 'visible' : 'hidden'"
        >
            <app-additional-filters
                [country]="properties.country"
                (action)="additionalFilterSelectionChanged($event)"
                [filterOptions]="filterOptions"
                [isClientName]="true"
                [isEntity]="true"
                [isPolicyType]="true"
            >
            </app-additional-filters>
        </div>
        <div
            id="perMemBtnAgeBand"
            fxLayout="row"
            (click)="
                selectionChanged(
                    AgeBandGenderRelationshipOption.AgeBand,
                    'dimension'
                )
            "
            fxLayoutAlign="center center"
            class="dash-option"
            [ngClass]="{
                'dash-option-selected':
                    properties.dimension ==
                    AgeBandGenderRelationshipOption.AgeBand
            }"
        >
            <span>{{ "PerMemberCosts.AgeBand" | translate }}</span>
        </div>
        <div
            id="perMemBtnGender"
            fxLayout="row"
            (click)="
                selectionChanged(
                    AgeBandGenderRelationshipOption.Gender,
                    'dimension'
                )
            "
            fxLayoutAlign="center center"
            class="dash-option"
            [ngClass]="{
                'dash-option-selected':
                    properties.dimension ==
                    AgeBandGenderRelationshipOption.Gender
            }"
        >
            <span>{{ "PerMemberCosts.Gender" | translate }}</span>
        </div>
        <div
            id="perMemBtnRel"
            fxLayout="row"
            (click)="
                selectionChanged(
                    AgeBandGenderRelationshipOption.Relationship,
                    'dimension'
                )
            "
            fxLayoutAlign="center center"
            class="dash-option"
            [ngClass]="{
                'dash-option-selected':
                    properties.dimension ==
                    AgeBandGenderRelationshipOption.Relationship
            }"
        >
            <span>{{ "PerMemberCosts.Relationship" | translate }}</span>
        </div>
        <div
            id="perMemBtnChart"
            fxLayout="row"
            style="margin-top: 15px"
            (click)="selectionChanged(ChartTable.Chart, 'chartTable')"
            fxLayoutAlign="center center"
            class="dash-option"
            [ngClass]="{
                'dash-option-selected':
                    properties.chartTable == ChartTable.Chart
            }"
        >
            <span>{{ "PerMemberCosts.Chart" | translate }}</span>
        </div>
        <div
            id="perMemBtnTable"
            fxLayout="row"
            (click)="selectionChanged(ChartTable.Table, 'chartTable')"
            fxLayoutAlign="center center"
            class="dash-option"
            [ngClass]="{
                'dash-option-selected':
                    properties.chartTable == ChartTable.Table
            }"
        >
            <span>{{ "PerMemberCosts.Table" | translate }}</span>
        </div>
    </div>
</div>
