export const locale = {
    lang: 'es-cl',
    data: {
        'AddFilter': {
            'ClientName': 'Nombre Cliente',
            'Entity': 'Razón Social',
            'Division': 'División', 
            'Plan': 'Plan',
            'TimePeriod': 'Periodo de tiempo',                
            'PolicyType': 'Cobertura',
            'AgeBand': 'Rango Etario',
            'Gender': 'Genero',
            'ClaimType': 'Prestación',
            'ClaimType2': 'Prestación 2',
            'ClaimType3': 'Prestación 3',
            'Relationship': 'Parentesco',
            'DiagnosisCategory': 'Categoría de Diagnóstico',
            'DiagnosisChapter': 'Capítulo de Diagnóstico',
            'Location': 'Grupo',
            'IsChronic': 'Crónico',
            'Carrier': 'Portador',
            'LOB': 'Sist. Previsional',
            'SubLOB': 'NA',
            'Network': 'Prestadores',
            'ProviderName': 'Nombre Prestador',
            'ProviderLocation': 'Ubicación Prestador',
            'ProviderType': 'Tipo Prestador'
        }
    }
};
