export const locale = {
    lang: 'es-co',
    data: {
        'ClaimType': {
            'ClaimHeading': 'Tipo de Servicio',
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'ClaimType': 'Tipo de Servicio',
            'TotalPaidClaim': 'Costo Total Pagado',
            'ClaimantCount': 'Número de Reclamantes',
            'ClaimCount': 'Número de Reclamos',
            'ClaimsPerClaimant': 'Reclamos Por Reclamante',
            'ClaimsPerHeadcount': 'Reclamos Por Plantilla',
            'CostPerClaim': 'Costo Por Reclamo',
            'CostPerClaimant': 'Costo Por Reclamante',
            'CostPerHeadcount': 'Costo Por Plantilla',
            'ChangeInCost': '% Variación del Costo',
            'Claim': 'Reclamo',
            'Claimant': 'Reclamante',
            'Headcount': 'Plantilla',
            'ClaimsTotal': 'Total Reclamos',
            'Count': 'Contar',
            'ClaimsReportedByPaidDate': 'Reclamaciones reportadas por fecha de pago',
            'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
            'CountBasis': 'Contar Base',
            'Sort': 'Ordenar',
            'Show': 'Mostrar',
            'Hide': 'Ocultar',
            'ShowHide': 'Mostrar/Ocultar',
            'Average': 'Promedio',
            'PeriodAverage': 'Periodo promedio',
            'PlanYear': 'Año Del Plan'
        }
    }
};
