export const locale = {
    lang: 'pt-br',
    data: {
        'ClientGroupAdmin': {
            'ClientGroupAdministration': 'ADMINISTRAÇÃO DE GRUPO DE CLIENTE',
            'ClientGroups': 'Grupos de Cliente',
            'NewClientGroup': 'NOVO GRUPO DE CLIENTE',
            'NewClientGroupName': 'Novo Nome de Grupo de Cliente',
            'Clients': 'Clientes',
            'Save': 'Salvar',
            'Success': 'Sucesso',
            'Name': 'Nome',
            'CreatedBy': 'Criado por',
            'CreatedOn': 'Criado em',
            'GroupName': 'Nome do Grupo',
            'ClientName': 'Nome do Cliente',
            'SelectedClients': 'Cliente Selecionados',
            'UnselectedClients': 'Clientes Não Selecionados',
            'Search': 'Procurar',
            'clientGroupColumnDefs': [
                { headerName: 'Nome', field: 'name', sort: 'asc' }
            ],
            'clientColumnDefs': [
                { headerName: 'Nome', field: 'name', sort: 'asc' }
            ]
        }
    }
};