export const locale = {
    lang: 'es-co',
    data: {
        'UserOptions': {
            'Monthly': 'Mensual',
            'YTDFull': 'YTD completo',
            'YTDComparable': 'YTD Comparable',
            'Rolling3Months': '3 Meses Corridos',
            'Rolling6Months': '6 Meses Corridos',
            'Rolling9Months': '9 Meses Corridos',
            'Rolling12Months': '12 Meses Corridos',
            'Current': 'Actual',
            'Current-1': 'Actual - 1',
            'PaidBasis': 'Base pagada',
            'IncurredIncomplete': 'Incurrido e incompleto',
            'IncurredCompleted': 'Incurrido y completado',
            'TimePeriod': 'Periodo de tiempo',
            'Basis': 'Base',
            'MaxDate': 'Max Date',
            'PlanYear': 'Ano del plan',
            'UsePriorYear': 'Use Prior Year',
            'ErrorMessagePeriod1OverlapPeriod2': 'Period 1 overlaps with period 2.',
            'ErrorMessagePeriod2OverlapPeriod3': 'Period 2 overlaps with period 3.',
            'ErrorMessagePeriod3OverlapPeriod4': 'Period 3 overlaps with period 4.',
            'ErrorMessagePeriod1Missing': 'Period 1 is missing.',
            'ErrorMessagePeriod2Missing': 'Period 2 is missing.',
            'ErrorMessagePeriod3Missing': 'Period 3 is missing.',
            'ErrorMessageMaxDateEarlierThanPeriod1': 'Max date is earlier than period 1 start date.'
        }
    }
};