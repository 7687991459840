import { Component, OnInit, OnDestroy } from "@angular/core";
import { User } from "app/models/user.model";
import { FuseConfigService } from "@fuse/services/config.service";
import { Store, select } from "@ngrx/store";
import { Router } from "@angular/router";
import { navigation } from "app/navigation/navigation";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ExecutiveSummary_RetirementAdequacyData } from "app/fwb/constants/fwb-charts-data";
import { FwbExecutiveService } from 'app/fwb/services/fwb-executive.service';

@Component({
    selector: "retirement-adequacy",
    templateUrl: "./retirement-adequacy.component.html",
    styleUrls: ["./retirement-adequacy.component.css"],
})
export class RetirementAdequacyComponent implements OnInit {
    chartId = "demographics";
    height: any = "350px";
    width: any = "350px";
    cardheight: any = "590px";
    cardwidth: any = "99%";
    tooltip: boolean = true;
    titleposition = "left";
    charttitle = "Retirement Adequacy";
    yaxistitle = "";
    color = "white";
    maxyaxisnumber: number = 1000;
    data: any;
    fuseConfig: any;
    navigation: any;
    legendTrim: number = 0;
    legendtitle = "";
    legendPosition = "none";
    middleText = "68.7%";
    legendsubtitle: string = "Target Met";
    showLegend: boolean = true;
    isRetirementsSelected: boolean = true;
    keys: any[] = [];
    targetKeys: any[] = [];
    public user: User;

    targetMetTotal: number = 0;
    serviceData: any;
    isLoading = true;
    isError = false;
    errorMsg = "No Data Found.";
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _executiveService: FwbExecutiveService,
        private _router: Router
    ) {
        // Set the defaults
        this.navigation = navigation;
    } 
    ngOnInit() { 
        this.getUser();
        this.getData();
        this.getLegends(); 

        this.legendsubtitle = "Target Met";
    }

    getUser() {
    } 
    goToLearnMore() {
        this._router.navigate(["/fwb/retirement"]);
    }
 
    getData() {
        this.serviceData = null;
        this._executiveService.getRetirement("").toPromise().then((resp) => {
            this.serviceData = resp;
            console.log(this.serviceData);
            this.setData();
            this.errorMsg = "";
            this.isError = false;
        }).catch(error => {
            this.isError = true;
            this.errorMsg = error.message;
        }).finally(() => {
            this.isLoading = false;
        })
    }

    setData() { 
        this.data = this.serviceData.resultTargetMet;   
         
        this.middleText = this.serviceData.totalMet + "%";
    }
    

    getLegends() {
        this.targetKeys = [
            {
                name: "Target Met", shape: "square",
            },
            {
                name: "Target Not Met", shape: "square", striped: true,
            },
        ]
        this.keys = [
            {
                name: "Below Minimum",
                key: "BelowMinimum",
                value: 0,
                colorindex: 0,
                striped: false
            },
            {
                name: "Minimum",
                key: "Minimum",
                value: 0,
                colorindex: 2,
                striped: false
            },
            {
                name: "Moderate",
                key: "Mod1Striped",
                value: 0,
                colorindex: 4,
                striped: true
            },
            {
                name: "Comfortable",
                key: "Com1Striped",
                value: .03,
                colorindex: 7,
                striped: true
            },
            {
                name: "Above Comfortable",
                key: "AboveComfortable",
                value: .05,
                colorindex: 8,
                striped: false
            }];
    }
}
