<div class="optionlayout">

    <div *ngIf="isBooleanField" style="height: 68px;text-align: center;
    align-content: flex-end;display: flex;justify-content: flex-end;float:right;margin-top: 20px;
    margin-bottom: -20px;">
        <aon-switch-with-label [switchon]="booleanresponse" width='150px'> </aon-switch-with-label>
    </div>
    <div *ngIf="isNumberField">
        <aon-textbox [id]='txtInputTest' width='70px'
            style=" text-align: center;align-content: flex-end;
            display: flex;justify-content: flex-end;float:right;margin-top: 10px;
            margin-bottom: -10px;"
            [value]='numberFormattedValue'></aon-textbox>
    </div>

    <div *ngIf="isDateField">
        <aon-textbox [id]='txtDateInputTest' width='150px'
            style=" text-align: center;align-content: flex-end;
            display: flex;justify-content: flex-end;float:right;margin-top: 10px;
            margin-bottom: -10px;"
            [value]='dateFormattedValue'></aon-textbox>
    </div>
    <div *ngIf="isOptionsField">
        <div style="width: 220px;"> 
            <aon-select style=" margin-top: 10px;margin-bottom: -10px;" scrollable="true"
                    [selectedvalue]="selectedOptionNumber" >
                    <aon-select-option *ngFor="let option of options" [value]="option.key">{{ option.name }}
                    </aon-select-option>
            </aon-select> 
    </div>
    </div>
</div>