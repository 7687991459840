export const locale = {
    lang: 'it',
    data: {
  'ClaimsOverview': {
    'Prior': 'precedente',
    'Current': 'Attuale',
    'ClaimType': 'Sinistri oer assistito attivo',
    'Relationship': 'Relazione',
    'Entity': 'Entità Legale',
    'Network': 'Rete Convenzionata',
    'Location': 'Localita',
    'ClaimsOverview': 'Panoramica dei sinistri',
    'Total': 'Totale',
    'TotalPaidClaim': 'Sinistro totale pagato',
    'NOOFCLAIMS': 'No. di sinistri',
    'NOOFCLAIMANTS': 'No di teste colpite da sinistri',
    'Claim': 'Sinistri',
    'Claimant': 'Testa colpita',
    'Count': 'Conteggio',
    'AllAmountsDisplayedInLocalCurrency': 'Tutti gli importi visualizzati in valuta locale',
    'ClaimsReportedByPaidDate': 'Sinistri per data di pagamento',
    'ClaimsReportedByIncurredDate': 'Sinistri per data di accadimento',
    'DateRange': 'Intervallo Di Date'
  }
}};