import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from "ag-grid-community";
import { AdminService } from '../../services/admin.service';
import { AgmatcheckboxComponent } from '../../shared/agmatcheckbox/agmatcheckbox.component';
import { AgheadermatcheckboxComponent } from '../../shared/agheadermatcheckbox/agheadermatcheckbox.component';
import { UserService } from 'app/services/user.service';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as navigationEnglish } from 'app/admin/useradmin/i18n/en';
import { locale as navigationSpanishCL } from 'app/admin/useradmin/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/admin/useradmin/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/admin/useradmin/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/admin/useradmin/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/admin/useradmin/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/admin/useradmin/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/admin/useradmin/i18n/it';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-useradmin',
  templateUrl: './useradmin.component.html',
  styleUrls: ['./useradmin.component.css']
})
export class UseradminComponent implements OnInit {

  cmList = [];
  userList = [];
  clientList = [];
  countryList = [];
  selectedUser: any;
  selectedCM: any;
  selectedCountry: any;
  searchCMUserResults: any[];
  searchNormalUserResults: any[] = [];
  isLoadingCountry = false;
  isShowCMCard = false;
  cmMessages = [];
  msgUnableToAssignCM = 'Unable to assign as country manager: ';
  isLoadingUserList: boolean;
  isLoadingClient: boolean;

  isSelectAllCMCountry = false;
  isSelectAllUserClient = false;

  constructor(
    private _translateService: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    public adminService: AdminService, 
    public userService: UserService,
    public toasterService: ToasterService,
    public dialog: MatDialog) {

  }

  public userColumnDefs = [];
  public clientColumnDefs = [];
  public countryColumnDefs = [];
  public userCountryColumnDefs = [];


  ngOnInit() {
   
    this._fuseTranslationLoaderService.loadTranslations(
        navigationEnglish,
        navigationSpanishCL,
        navigationSpanishCO,
        navigationSpanishEC,
        navigationSpanishMX,
        navigationSpanishPE,
        navigationPortugueseBR,
        navigationItalianIT,
      );

    this.isShowCMCard = this.userService.isAdminOrRegionalManagerRole();
    this.searchCMUserResults = [];

    this.adminService.getEnabledCountries().toPromise().then(data => {
      this.countryList = data;
    });

    this.adminService.getAssignedUsers().toPromise().then(data => {
      this.userList = data;
    });

    if (this.isShowCMCard) {
      this.adminService.getCountryManagers().toPromise().then(data => {
        this.cmList = data;
      });
    }

    this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.userColumnDefs = event.translations.UserAdmin.userColumnDefs;
      this.clientColumnDefs = event.translations.UserAdmin.clientColumnDefs;
      this.countryColumnDefs = event.translations.UserAdmin.countryColumnDefs;
      this.userCountryColumnDefs = event.translations.UserAdmin.userCountryColumnDefs;
    });

    var currentTranslation = this._translateService.store.translations[this._translateService.currentLang];
    this.userColumnDefs = currentTranslation.UserAdmin.userColumnDefs;
    this.clientColumnDefs = currentTranslation.UserAdmin.clientColumnDefs;
    this.countryColumnDefs = currentTranslation.UserAdmin.countryColumnDefs;
    this.userCountryColumnDefs = currentTranslation.UserAdmin.userCountryColumnDefs;
  }

  addCM() {
    this.cmMessages = [];
    this.searchCMUserResults.forEach(u => u.isPending = true);
    var searchFiltered = this.searchCMUserResults.filter((cm) =>
      this.cmList.filter(cl => cl.userName == cm.userName).length == 0
    );
    console.log(searchFiltered);
    searchFiltered.forEach(u => {
      this.adminService.IsValidRole(u.id, 'countrymanager').toPromise().then(res => {
        if (res) {
          this.cmList = this.cmList.concat([u]);
          setTimeout(() => { this.cmGrid.setSelectedIndex(this.cmList.length - 1) }, 40);
        } else {
          this.cmMessages.push(this.msgUnableToAssignCM + u.email);
        }
      })
    });
    
    this.searchCMUserResults = [];
  }

  addNormalUser() {
    this.searchNormalUserResults.forEach(u => u.isPending = true);
    var searchFiltered = this.searchNormalUserResults.filter((cm) =>
      this.cmList.filter(cl => cl.userName == cm.userName).length == 0
    );
    this.userList = this.userList.concat(searchFiltered);
    this.searchNormalUserResults = [];
    setTimeout(() => { this.userGrid.setSelectedIndex(this.userList.length - 1) }, 40);
  }

  getRowClass = function (params) {
    if (params.node.data.isPending) {
      return 'agcustom-pending-row';
    }
  }

  @ViewChild('cmGrid') cmGrid;
  @ViewChild('userGrid', { static: true }) userGrid;
  @ViewChild('countryGridNormalUser') countryGridNormal;
  @ViewChild('countryGridCMUser') countryGridCM;
  @ViewChild('clientGrid') clientGrid;

  onCMSelectionChanged($event) {
    if (!$event.data) { return; }
    this.isSelectAllCMCountry = false;
    this.isSelectAllUserClient = false;

    delete this.selectedUser;
    delete this.selectedCountry;

    this.isLoadingCountry = true;
    this.userGrid.clearSelection();
    if (this.countryGridCM) { this.countryGridCM.clearSelection(); }
    this.selectedCM = $event.data;
    this.adminService.getUserCountries(this.selectedCM.id).toPromise().then(data => {
      if (data) {
        const cIds = data.map(c => c.tier2Id);
        this.countryList.forEach(c => {
          c.isSelected = cIds.indexOf(c.tier2Id) >= 0;
        });
      } else {
        this.countryList.forEach(c => {
          c.isSelected = false;
        });
      }
      this.countryList = JSON.parse(JSON.stringify(this.countryList));
      this.isLoadingCountry = false;
    });
  }

  onSelectAllCMCountry() {
      this.isSelectAllCMCountry = !this.isSelectAllCMCountry;
      this.countryGridCM.toggleSelectAll(this.isSelectAllCMCountry);
  }

  onSelectAllClient() {
      this.isSelectAllUserClient = !this.isSelectAllUserClient;
      this.clientGrid.toggleSelectAll(this.isSelectAllUserClient);
  }

  onCMDelete($event) {
    const cm = $event.data;

    const dialogRef = this.dialog.open(DialogComponent, {
        data: { title: 'Confirm Deletion of ' + cm.userName, message: 'Are you sure? All mapping will be deleted as well.' }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (typeof result === 'undefined') {
            return;
        }

        if (result === false) {
            return;
        }

        console.log(cm);
        this.cmGrid.showLoadingOverLay(true);
        if (this.countryGridCM) { this.countryGridCM.clearSelection(); }
        if (this.selectedCM && this.selectedCM.id === cm.id) {
          delete this.selectedCM;
        }
        if (cm.isPending) {
          const c = this.cmList.filter(c => c.id === cm.id)[0];
          const cmIndex = this.cmList.indexOf(c);
          console.log(cmIndex);
          this.cmList.splice(cmIndex, 1);
          this.cmList = JSON.parse(JSON.stringify(this.cmList));
        } else {
          const pendingCM = this.cmList.filter(c => c.isPending);
          this.adminService.removeCountryManager(cm).toPromise().then(res => {
            this.adminService.getCountryManagers().toPromise().then(data => {
              this.cmList = data.concat(pendingCM); ;
            });
          }).finally(() => {
            this.cmGrid.showLoadingOverLay(false);
          });
        }
    });
  }

  onNormalUserDelete($event) {
    const nu = $event.data;

    const dialogRef = this.dialog.open(DialogComponent, {
        data: { title: 'Confirm Deletion of ' + nu.userName, message: 'Are you sure? All mapping will be deleted as well.' }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (typeof result === 'undefined') {
            return;
        }

        if (result === false) {
            return;
        }

        this.isLoadingUserList = true;
        this.userGrid.showLoadingOverLay(true);
        if (this.countryGridNormal) { this.countryGridNormal.clearSelection(); }
        if (this.selectedUser && this.selectedUser.id === nu.id) {
          delete this.selectedUser;
        }
        this.adminService.removeNormalUser(nu).toPromise().then(res => {
          this.adminService.getAssignedUsers().toPromise().then(data => {
            this.userList = data;
            this.isLoadingUserList = false;
          });
        }).finally(() => {
          this.userGrid.showLoadingOverLay(false);
        });
    });
  }

  onUserSelectionChanged($event) {
    if (!$event.data) { return; }

    this.isSelectAllCMCountry = false;
    this.isSelectAllUserClient = false;

    delete this.selectedCM;
    delete this.selectedCountry;

    if (this.cmGrid) { this.cmGrid.clearSelection(); }
    if (this.countryGridNormal) { this.countryGridNormal.clearSelection(); }
    this.selectedUser = $event.data;
    this.adminService.getUserCountries(this.selectedUser.id).toPromise().then(data => {
      if (!data) { return; }
      var cIds = data.map(c => c.tier2Id);
      this.countryList.forEach(c => {
        c.isSelected = cIds.indexOf(c.tier2Id) >= 0;
      });
      this.countryList = JSON.parse(JSON.stringify(this.countryList));
      this.isLoadingCountry = false;
    });
  }

  onCountrySelectionChanged($event) {
    if (!$event.data) { return; }
    this.selectedCountry = $event.data;

    if (this.selectedCountry && !this.selectedCM) {
      this.adminService.getClientsByCountry(this.selectedCountry.tier2Id).toPromise().then(data => {
        this.clientList = data;
        this.adminService.getClientsByUserCountry(this.selectedUser.id, this.selectedCountry.tier2Id)
        .toPromise().then(data => {
          var cIds = data.map(c => c.tier1Id);
          this.clientList.forEach(c => {
            c.isSelected = cIds.indexOf(c.tier1Id) >= 0;
          });
          this.clientList = JSON.parse(JSON.stringify(this.clientList));
        });
      })
    }
  }

  saveCMCountry() {
    this.isLoadingCountry = false;
    var pendingCM = this.cmList.filter(c => c.isPending && c.id != this.selectedCM.id);
    this.adminService.assignCountryManagers(this.selectedCM).then((result: any) => {
      var sel = this.countryList.filter(c => c.isSelected).map(c => c.tier2Id);
      this.adminService.saveManagerCountries(this.selectedCM.id, sel).then((result: any) => {
        this.adminService.getCountryManagers().toPromise().then(data => {
          this.cmList = data.concat(pendingCM);
          this.isLoadingCountry = false;
        });
        this.adminService.getAssignedUsers().toPromise().then(data => {
          this.userList = data;
        });
        this.toasterService.showMessage(this._translateService.instant('UserAdmin.Success'), ToasterServiceModes.OK);
      });
    });
  }

  saveUserCountryClient() {
    this.isLoadingClient = true;
    var clientIdList = this.clientList.filter(c => c.isSelected).map(c => c.tier1Id);
    this.adminService
      .saveUserClients(this.selectedUser.id, this.selectedCountry.tier2Id, clientIdList)
      .then((result: any) => {
        this.adminService.getClientsByUserCountry(this.selectedUser.id, this.selectedCountry.tier2Id)
        .toPromise().then(data => {
          var cIds = data.map(c => c.tier1Id);
          this.clientList.forEach(c => {
            c.isSelected = cIds.indexOf(c.tier1Id) >= 0;
          });
          this.clientList = JSON.parse(JSON.stringify(this.clientList));
          this.isLoadingClient = false;
          this.toasterService.showMessage(this._translateService.instant('UserAdmin.Success'), ToasterServiceModes.OK);
        });
      });
  }

}
