import { Component, OnInit, Input } from "@angular/core";
import { SiteService } from 'app/services/site.service';
import { SiteaccessService } from 'app/services/siteaccess.service';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { List } from 'linqts';

@Component({
    selector: "app-siteadmin",
    templateUrl: "./siteadmin.component.html",
    styleUrls: ["./siteadmin.component.scss"],
})
export class SiteadminComponent implements OnInit {
    @Input() isRegionalManager: boolean;
    @Input() isAdmin: boolean;

    selectedUser: any = null;
    userList: any[] = null;
    userColumnDefs = [
        { 
          headerName: 'Name', 
          field: 'userName',
        },
        {
            headerName: 'Email',
            field: 'email',
            sort: 'asc'
        }
    ];
    selectedUserAccessList: any[] = null;

    siteList: any[] = null;
    siteName: string = '';
    siteValue: string = '';
    siteUrl: string = '';
    siteColumnDefs = [
        { 
            headerName: 'Name', 
            field: 'name',
            sort: 'asc'
        },
        {
            headerName: 'Value',
            field: 'value',
        },
        {
            headerName: 'Url',
            field: 'siteUrl',
        }        
    ];
    selectedSite: any = null;
    isUpdateSiteState: boolean = false;
    isDeleteSiteState: boolean = false;
    isAddSiteState: boolean = false;

    siteToManageClient: any = null;
    siteClientViewModel: any = null;
    selectedClientSearchString: string = '';
    unselectedClientSearchString: string = '';
    
    constructor(
        private siteAccessService: SiteaccessService,
        private siteService: SiteService,
        private toasterService: ToasterService) {}

    ngOnInit(): void {
        this.listUsers();
        if (this.isAdmin || this.isRegionalManager) {
            this.listSites();
        }
    }

    onSelectedTabChange(event: any): void {
        console.log(event);
        if (this.selectedUser && event.tab.textLabel === 'User Access') {
            this.onUserSelectionChanged({ data: this.selectedUser });
        }
    }

    //User Access
    listUsers(): void {
        this.siteAccessService.listUsers().toPromise().then(userList => {
            this.userList = userList;
        });
    }

    onUserSelectionChanged(event: any): void {
        console.log(event);
        this.selectedUser = event.data;
        this.selectedUserAccessList = null;

        this.siteAccessService.retrieveUserAccess(this.selectedUser.id).toPromise().then(userAccessResult => {
            this.selectedUserAccessList = userAccessResult.userSiteAccessRecordList;
        });
    }

    onToggleSiteAccessSelected(siteAccessRecord: any): void {
        siteAccessRecord.isSelected = !siteAccessRecord.isSelected;

        if (!siteAccessRecord.isSelected && siteAccessRecord.isDefault) {
            siteAccessRecord.isDefault = false;
        }
    }

    onToggleSiteAccessDefault(siteAccessRecord: any): void {
        siteAccessRecord.isDefault = !siteAccessRecord.isDefault;

        if (siteAccessRecord.isDefault) {
            this.selectedUserAccessList.forEach(currentSiteAccessRecord => {
                currentSiteAccessRecord.isDefault = siteAccessRecord === currentSiteAccessRecord;

                if (currentSiteAccessRecord.isDefault && !siteAccessRecord.isSelected) {
                    currentSiteAccessRecord.isSelected = true;
                }
            });
        }
    }

    onApplyUserSiteAccess(): void {
        this.siteAccessService.updateUserAccess(this.selectedUser.id, this.selectedUserAccessList).toPromise().then(() => {
            this.toasterService.showMessage('User Access Successfully Updated.'); 
        }, () => {
            this.toasterService.showMessage('Error. User Access Failed to Update.', ToasterServiceModes.NOK); 
        });
    }

    //Manage Site
    listSites(): void {
        this.siteService.listSites().toPromise().then(siteList => {
            this.siteList = siteList;
        });
    }

    onAddSiteState(event: any): void {
        this.siteName = '';
        this.siteValue = '';
        this.siteUrl = '';

        this.isAddSiteState = true;
        this.isUpdateSiteState = false;
        this.isDeleteSiteState = false;
    }

    onUpdateSiteState(event: any): void {
        this.isAddSiteState = false;
        this.isUpdateSiteState = true;
        this.isDeleteSiteState = false;

        this.selectedSite = JSON.parse(JSON.stringify(event.data));
    }

    onDeleteSiteState(event: any): void {
        this.isAddSiteState = false;
        this.isUpdateSiteState = false;
        this.isDeleteSiteState = true;

        this.selectedSite = JSON.parse(JSON.stringify(event.data));
    }

    onCancelSiteState(): void {
        this.isAddSiteState = false;
        this.isUpdateSiteState = false;
        this.isDeleteSiteState = false;

        this.selectedSite = null;
    }

    onAddSite(): void {
        if (this.siteName == null || this.siteName.trim().length === 0) {
            this.toasterService.showMessage('Error. Site Name cannot be empty.', ToasterServiceModes.NOK); 
            return;
        }

        if (this.siteValue == null || this.siteValue.trim().length === 0) {
            this.toasterService.showMessage('Error. Site Value cannot be empty.', ToasterServiceModes.NOK); 
            return;
        }

        if (this.siteUrl == null || this.siteUrl.trim().length === 0) {
            this.toasterService.showMessage('Error. Site Url cannot be empty.', ToasterServiceModes.NOK); 
            return;
        }

        this.siteService.addSite(this.siteName, this.siteValue, this.siteUrl).toPromise().then(() => {
            this.listSites();
            this.onCancelSiteState();
            this.toasterService.showMessage('Site Successfully Added.'); 
        }, () => {
            this.toasterService.showMessage('Error. Adding Site Failed.', ToasterServiceModes.NOK); 
        });
    }

    onUpdateSite(): void {
        if (this.selectedSite.name == null || this.selectedSite.name.trim().length === 0) {
            this.toasterService.showMessage('Error. Site Name cannot be empty.', ToasterServiceModes.NOK); 
            return;
        }

        if (this.selectedSite.value == null || this.selectedSite.value.trim().length === 0) {
            this.toasterService.showMessage('Error. Site Value cannot be empty.', ToasterServiceModes.NOK); 
            return;
        }

        if (this.selectedSite.siteUrl == null || this.selectedSite.siteUrl.trim().length === 0) {
            this.toasterService.showMessage('Error. Site Url cannot be empty.', ToasterServiceModes.NOK); 
            return;
        }

        this.siteService.updateSite(this.selectedSite).toPromise().then(() => {
            this.listSites();
            this.onCancelSiteState();
            this.toasterService.showMessage('Site Successfully Updated.'); 
        }, () => {
            this.toasterService.showMessage('Error. Updating of Site Failed.', ToasterServiceModes.NOK); 
        });
    }

    onDeleteSite(): void {
        this.siteService.deleteSite(this.selectedSite.id).toPromise().then(() => {
            this.listSites();
            this.onCancelSiteState();
            this.toasterService.showMessage('Site Successfully Deleted.'); 
        }, () => {
            this.toasterService.showMessage('Error. Deletion of Site Failed.', ToasterServiceModes.NOK); 
        });
    }

    //Manage Site - Client Mapping
    onSiteChange(event): void {
        this.siteToManageClient = event.detail;
        this.siteClientViewModel = null;
        this.siteService.getSiteClientList(this.siteToManageClient.id).toPromise().then((res) => {
            console.log(res);
            this.siteClientViewModel = res;
        });
    }

    onSelectClient(client): void {
        const unselectedIndex = this.siteClientViewModel.unselectedClientList.indexOf(client);
        const selectedClient = this.siteClientViewModel.unselectedClientList[unselectedIndex];
        this.siteClientViewModel.unselectedClientList.splice(unselectedIndex, 1);
        this.siteClientViewModel.selectedClientList.push(selectedClient);

        this.siteService.updateSiteClientList(this.siteToManageClient.id, this.siteClientViewModel).toPromise().then(() => {
            this.toasterService.showMessage('Selected Client Updated.'); 
        });

        const selectedClientList = new List<any>(this.siteClientViewModel.selectedClientList);
        this.siteClientViewModel.selectedClientList = selectedClientList.OrderBy(c => c.name).ToArray();
    }

    onUnselectClient(client): void {
        const selectedIndex = this.siteClientViewModel.selectedClientList.indexOf(client);
        const unselectedClient = this.siteClientViewModel.selectedClientList[selectedIndex];
        this.siteClientViewModel.selectedClientList.splice(selectedIndex, 1);
        this.siteClientViewModel.unselectedClientList.push(unselectedClient);

        this.siteService.updateSiteClientList(this.siteToManageClient.id, this.siteClientViewModel).toPromise().then(() => {
            this.toasterService.showMessage('Unselected Client Updated.'); 
        });

        const unselectedClientList = new List<any>(this.siteClientViewModel.unselectedClientList);
        this.siteClientViewModel.unselectedClientList = unselectedClientList.OrderBy(c => c.name).ToArray();
    }
}
