<mat-toolbar id="footerSection">

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

        <span style="font-size: 0.60em">V 1.0.0-PreAlpha</span>
        <span fxFlex></span>

        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a id="footerBtnDoc" mat-button routerLink="/documentation/getting-started/introduction">Documentation</a>
            <span>&bull;</span>
            <a id="footerBtnChange" mat-button routerLink="/documentation/changelog">Changelog</a>
        </div>

    </div>

</mat-toolbar>
