<div fxLayout="column">
    <div fxLayout="row">
        <div fxFlex='100'>
            <aon-h1 class="subtitle">Select Challenge:</aon-h1>
        </div>
    </div>
    <div fxLayout="row">
        <div fxFlex='100'>
            <aon-select (onselectionchange)='onSelectionChange($event)' [search]='true' returntype='string' [selectedvalue]='selectedChallengeId' width='100%'>
                <aon-select-option *ngFor="let challenge of challengeList" [value]='challenge.id'>{{challenge.challengeName}} ({{challenge.participantCount}})</aon-select-option>
            </aon-select>
        </div>
    </div>
    <div fxLayout="row" style='margin-top: 40px'>
        <div fxFlex='100' style="text-align: right;">
            <aon-button-subtle style="margin-right: 10px;" (onclick)='cancel()' width='100px'>Cancel</aon-button-subtle>
            <aon-button-default style="margin-left: 10px;" [disabled]='selectedChallengeId === newChallengeId' (onclick)='apply()'  width='100px'>Apply</aon-button-default>
        </div>
    </div>
</div>
