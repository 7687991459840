<div fxLayout='column'>
    <div fxLayout='row'>
        <div fxFlex>
            <mat-tab-group (selectedTabChange)='onSelectedTabChange($event)'>
                <mat-tab label="User Access" *ngIf="isAdmin || isRegionalManager">
                    <div fxLayout="row" fxLayoutGap="12px">
                        <div fxFlex='49'>
                            <mat-card>
                                <h4 *ngIf="!userList">Loading...</h4>
                                <agcustom-grid *ngIf="userList" style="height: 300px" [dataList]="userList" [columnDefs]="userColumnDefs" 
                                (selectionChanged)="onUserSelectionChanged($event)">
                                </agcustom-grid>                                
                            </mat-card>
                        </div>
                        <div fxFlex='2'></div>
                        <div fxFlex='49'>
                            <mat-card *ngIf="selectedUserAccessList">
                                <h4 *ngIf="selectedUserAccessList.length === 0">Loading...</h4>
                                <div *ngIf="selectedUserAccessList.length > 0">
                                    <h4>User's Site Access</h4>
                                    <span><b>Username: </b> {{this.selectedUser.userName}}</span>
                                    <div style='margin-top: 20px;'>
                                        <table style='width: 100%'>
                                            <thead>
                                                <tr>
                                                    <th style="width: 50%">Site</th>
                                                    <th style="width: 50%">Default?</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let selectedUserAccess of selectedUserAccessList">
                                                <tr>
                                                    <td>
                                                        <aon-checkbox [selected]='selectedUserAccess.isSelected' (ontoggle)='onToggleSiteAccessSelected(selectedUserAccess)'>{{selectedUserAccess.name}}</aon-checkbox>
                                                    </td>
                                                    <td>
                                                        <aon-checkbox [selected]='selectedUserAccess.isDefault' (ontoggle)='onToggleSiteAccessDefault(selectedUserAccess)'></aon-checkbox>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table> 
                                    </div>
                                    <div style='margin-top: 20px; text-align: center;'>
                                        <aon-button-default (onclick)='onApplyUserSiteAccess()'>Apply</aon-button-default>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Manage Site" *ngIf="isAdmin">
                    <div fxLayout="row">
                        <div fxFlex='69'>
                            <div fxLayout="row">
                                <mat-card fxFlex>
                                    <div style='text-align: right'>
                                        <aon-button-ghost width='auto' (onclick)='onAddSiteState()'>Add Site</aon-button-ghost>
                                    </div>
                                    <div>
                                        <agcustom-grid *ngIf="siteList" style="height: 300px" [dataList]="siteList" [columnDefs]="siteColumnDefs" 
                                        [delete]=true (selectionChanged)="onUpdateSiteState($event)" (onDelete)="onDeleteSiteState($event)">
                                        </agcustom-grid>        
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                        <div fxFlex='2'></div>
                        <div fxFlex='29'>
                            <div *ngIf="isAddSiteState" fxLayout="row">
                                <mat-card fxFlex>
                                    <div>
                                        <span>Add Site</span>
                                    </div>
                                    <div style='margin-top: 20px;'>
                                        <aon-input-title>Name</aon-input-title>
                                    </div>      
                                    <div>
                                        <aon-textbox [value]='siteName' (onchange)='siteName = $event.detail.value'></aon-textbox>
                                    </div>           
                                    <div style='margin-top: 20px;'>
                                        <aon-input-title>Value</aon-input-title>
                                    </div>      
                                    <div>
                                        <aon-textbox [value]='siteValue' (onchange)='siteValue = $event.detail.value'></aon-textbox>
                                    </div>     
                                    <div style='margin-top: 20px;'>
                                        <aon-input-title>Url</aon-input-title>
                                    </div>      
                                    <div>
                                        <aon-textbox [value]='siteUrl' (onchange)='siteUrl = $event.detail.value'></aon-textbox>
                                    </div>
                                    <div style='margin-top: 50px; text-align: center;'>
                                        <aon-button-subtle style="margin-right: 5px;" width='auto' (onclick)='onCancelSiteState()'>Cancel</aon-button-subtle>
                                        <aon-button-default style="margin-left: 5px;" width='auto' (onclick)='onAddSite()'>Add</aon-button-default>
                                    </div>                   
                                </mat-card>
                            </div>
                            <div *ngIf="isUpdateSiteState" fxLayout="row">
                                <mat-card fxFlex>
                                    <div>
                                        <span>Update Site</span>
                                    </div>
                                    <div style='margin-top: 20px;'>
                                        <aon-input-title>Name</aon-input-title>
                                    </div>      
                                    <div>
                                        <aon-textbox [value]='selectedSite.name' (onchange)='selectedSite.name = $event.detail.value'></aon-textbox>
                                    </div>           
                                    <div style='margin-top: 20px;'>
                                        <aon-input-title>Value</aon-input-title>
                                    </div>      
                                    <div>
                                        <aon-textbox [value]='selectedSite.value' (onchange)='selectedSite.value = $event.detail.value'></aon-textbox>
                                    </div>     
                                    <div style='margin-top: 20px;'>
                                        <aon-input-title>Url</aon-input-title>
                                    </div>      
                                    <div>
                                        <aon-textbox [value]='selectedSite.siteUrl' (onchange)='selectedSite.siteUrl = $event.detail.value'></aon-textbox>
                                    </div>
                                    <div style='margin-top: 50px; text-align: center;'>
                                        <aon-button-subtle style="margin-right: 5px;" width='auto' (onclick)='onCancelSiteState()'>Cancel</aon-button-subtle>
                                        <aon-button-default style="margin-left: 5px;" width='auto' (onclick)='onUpdateSite()'>Update</aon-button-default>
                                    </div>                   
                                </mat-card>
                            </div>
                            <div *ngIf="isDeleteSiteState" fxLayout="row">
                                <mat-card fxFlex>
                                    <div>
                                        <span>Delete Site</span>
                                    </div>
                                    <div style='margin-top: 10px;'>
                                        <span>Are you sure you want to delete this site?</span>
                                    </div>
                                    <div style='margin-top: 20px;'>
                                        <aon-input-title>Name</aon-input-title>
                                    </div>      
                                    <div>
                                        <aon-textbox [disabled]=true [value]='selectedSite.name'></aon-textbox>
                                    </div>           
                                    <div style='margin-top: 20px;'>
                                        <aon-input-title>Value</aon-input-title>
                                    </div>      
                                    <div>
                                        <aon-textbox [disabled]=true [value]='selectedSite.value'></aon-textbox>
                                    </div>     
                                    <div style='margin-top: 20px;'>
                                        <aon-input-title>Url</aon-input-title>
                                    </div>      
                                    <div>
                                        <aon-textbox [disabled]=true [value]='selectedSite.siteUrl'></aon-textbox>
                                    </div>
                                    <div style='margin-top: 50px; text-align: center;'>
                                        <aon-button-subtle style="margin-right: 5px;" width='auto' (onclick)='onCancelSiteState()'>Cancel</aon-button-subtle>
                                        <aon-button-default style="margin-left: 5px;" width='auto' (onclick)='onDeleteSite()'>Delete</aon-button-default>
                                    </div>                   
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Manage Site - Client Mapping" *ngIf="isAdmin || isRegionalManager">
                    <div fxLayout="column" style='min-height: 1000px;'>
                        <div fxLayout="row">
                            <div fxFlex>
                                <aon-select id="site-client-sitelist" [selectedvalue]='siteToManageClient' scrollable=true 
                                search=true returntype='object' (onselectionchange)='onSiteChange($event)'>
                                <aon-select-option *ngFor="let site of siteList" [value]='site'>
                                    {{site.name}}
                                </aon-select-option>
                            </aon-select>
                            </div>
                        </div>
                        <div fxLayout="row" *ngIf="!siteClientViewModel && siteToManageClient" style='margin-top: 20px' fxLayoutGap="20px">
                            <mat-card fxFlex>
                                <div fxLayout="column">
                                    <div fxLayout="row">
                                        <div fxFlex>
                                            <aon-loading-spinner></aon-loading-spinner>
                                        </div>   
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                        <div fxLayout="row" *ngIf="siteClientViewModel && siteToManageClient" style='margin-top: 20px' fxLayoutGap="20px">
                            <mat-card fxFlex='50'>
                                <div fxLayout="column">
                                    <div fxLayout="row">
                                        <aon-h5>Unselected ({{siteClientViewModel.unselectedClientList.length}})</aon-h5>
                                    </div>
                                    <div fxLayout="row">
                                        <div fxFlex>
                                            <aon-textbox placeholder='Search' [value]='unselectedClientSearchString' (onchange)='unselectedClientSearchString = $event.detail.value'></aon-textbox>
                                        </div>   
                                    </div>
                                    <div style='cursor: pointer;' fxLayout="row" *ngFor="let unselectedClient of siteClientViewModel.unselectedClientList" (click)='onSelectClient(unselectedClient)'>
                                        <div fxFlex *ngIf="unselectedClient.name.toLowerCase().indexOf(unselectedClientSearchString.trim().toLowerCase()) >= 0">
                                            <div style="padding: 10px; border-bottom: 1px solid gray;">
                                                {{unselectedClient.name}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>
                            <mat-card fxFlex='50'>
                                <div fxLayout="column">
                                    <div fxLayout="row">
                                        <aon-h5>Selected ({{siteClientViewModel.selectedClientList.length}})</aon-h5>
                                    </div>
                                    <div fxLayout="row">
                                        <div fxFlex>
                                            <aon-textbox placeholder='Search' [value]='selectedClientSearchString' (onchange)='selectedClientSearchString = $event.detail.value'></aon-textbox>
                                        </div>   
                                    </div>
                                    <div style='cursor: pointer;' fxLayout="row" *ngFor="let selectedClient of siteClientViewModel.selectedClientList" (click)='onUnselectClient(selectedClient)'>
                                        <div fxFlex *ngIf="selectedClient.name.toLowerCase().indexOf(selectedClientSearchString.trim().toLowerCase()) >= 0">
                                            <div style="padding: 10px; border-bottom: 1px solid gray;">
                                                {{selectedClient.name}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>

    </div>
</div>
