import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MaterialModule } from "./material.module";
import { AdminModule } from "./admin/admin.module";
import { ExportModule } from "./export/export.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OverlayContainer } from '@angular/cdk/overlay';

import { TranslateModule } from "@ngx-translate/core";
import { StoreModule } from "@ngrx/store";

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faGlobeAmericas } from '@fortawesome/pro-light-svg-icons';

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { SampleModule } from "app/main/sample/sample.module";
import { AonLayoutModule } from "app/layout/aon-layout/aon-layout.module";
import { reducers, metaReducers } from "./_reducers";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { LoginComponent } from "./login/login.component";
import { SearchSelectComponent } from "./shared/search-select/search-select.component";

import { StoryModule } from "app/story/story.module";
import { WelloneModule } from 'app/wellone/wellone.module';

import { JwtInterceptor } from "./_helpers/jwt.interceptor";
import { fuseConfig } from "./fuse.config";
import { UseroptionsComponent } from "./login/useroptions/useroptions.component";
import { UseroptionsDialogComponent } from "./login/useroptions/useroptions-dialog/useroptions-dialog.component";

import { SharedModule } from "./shared/shared.module";
import { UserIdleModule } from "angular-user-idle";
import { FWBModule } from "./fwb/fwbmodule.module";

import { CustomOverlayContainer } from './custom-overlay-container';
import { DisclaimerComponent } from './login/disclaimer/disclaimer.component';
import { CostProjectionModule } from "./cost-projection/cost-projection.module";

const appRoutes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'login', component: LoginComponent },
    { path: 'admin/user', redirectTo: 'admin/user' },
];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        SearchSelectComponent,
        UseroptionsComponent,
        UseroptionsDialogComponent,
        DisclaimerComponent,
    ],
    imports: [
        BrowserModule,

        BrowserAnimationsModule,
        HttpClientModule,
        MaterialModule,
        AdminModule,
        ExportModule,
        CostProjectionModule,

        ScrollingModule,

        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        StoreModule.forRoot(reducers, { metaReducers }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        StoreRouterConnectingModule.forRoot({ stateKey: "router" }),

        // Material moment date module
        MatMomentDateModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // Font Awesome
        FontAwesomeModule,

        // App modules
        LayoutModule,
        SampleModule,
        StoryModule,
        SharedModule,
        FWBModule,

        WelloneModule,
        //AonLayoutModule,

        UserIdleModule.forRoot({ idle: 1800, timeout: 2, ping: 2 }),
    ],
    entryComponents: [UseroptionsDialogComponent],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: OverlayContainer, useFactory: () => new CustomOverlayContainer(document) },
    ],
})
export class AppModule {
    constructor(
        private _library: FaIconLibrary,
    ) {
        this._library.addIcons(faGlobeAmericas);
    }
}
