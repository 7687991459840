import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChallengeService {

  constructor(private httpClient: HttpClient) { }

  public getChallengeResult(dashboardParams: any, id: string)
  {
    return this.httpClient.post<any>(environment.api + 'api/Challenge/challenge-detail/' + id, dashboardParams);
  }
}
