export const locale = {
    lang: 'pt-br',
    data: {
        'LossRatio': {
		'LossRatioHeading': 'Sinistralidade', 
		'AllAmountsDisplayedInLocalCurrency': 'Todos os valores estão em moeda local', 
		'Loss': 'Sinistralidade', 
		'LossRatioType': 'Tipo de Sinistralidade', 
		'CombinedLR': 'Sinistralidade Combinada', 
		'ClaimsTotalPMPM': 'Sinistro Total/PMPM', 
		'ClaimsPMPM': 'Sinistro PMPM', 
		'ClaimsTotal': 'Sinistro Total', 
		'TargetLossRatio': 'Sinistralidade Alvo', 
		'ClaimsReportedByPaidDate': 'Sinistro Reportado por Data de Pagamento', 
		'ClaimsReportedByIncurredDate': 'Sinistro Reportado por Data de Atendimento', 
		'Monthly': 'Mensal', 
		'YearToDateBasis': 'Base do Ano-até-Hoje', 
		'YearToDateBasisC': 'Base (C) do Ano-até-Hoje', 
		'Rolling3Basis': 'Base dos Últimos 3 meses', 
		'Rolling6Basis': 'Base dos Últimos 6 meses', 
		'Rolling9Basis': 'Base dos Últimos 9 meses', 
		'Rolling12Basis': 'Base dos Últimos 12 meses', 
		'Show': 'Mostrar', 
		'Hide': 'Esconder', 
		'ShowHide': 'Mostrar/Esconder', 
		'Claims': 'SINISTRO', 
		'Premium': 'PRÊMIO', 
		'Income': 'Receita', 
		'Expense': 'Despesas', 
		'ClaimLabel': 'SINISTRO + DESPESAS', 
		'PremimumLabel': 'PRÊMIO + RECEITA', 
		'MonthlyLossRatio': 'Sinistralidade Mensal', 
		'CumulativeLossRatio': 'Sinistralidade Acumulada',
		'DateRange': 'to'
        }
}
};
