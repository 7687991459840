export const locale = {
    lang: 'it',
    data: {
  'LossRatio': {
    'LossRatioHeading': 'Rapporto Sinistri-Premi',
    'AllAmountsDisplayedInLocalCurrency': 'Tutti gli importi visualizzati in valuta locale',
    'Loss': 'LOSS RATIO',
    'LossRatioType': 'Tipologia di  Loss Ratio',
    'CombinedLR': 'Combined LR',
    'ClaimsTotalPMPM': 'Sinistri totali/PMPM',
    'ClaimsPMPM': 'Sinistri PMPM',
    'ClaimsTotal': 'Sinistri totali',
    'TargetLossRatio': 'Target Loss Ratio',
    'ClaimsReportedByPaidDate': 'Sinistri per data di pagamento',
    'ClaimsReportedByIncurredDate': 'Sinistri per data di accadimento',
    'Monthly': 'Mensile',
    'YearToDateBasis': "Base dall'inizio dell'anno",
    'YearToDateBasisC': "Base dall'inizio dell'anno (C)",
    'Rolling3Basis': 'Rolling base 3',
    'Rolling6Basis': 'Rolling base 6',
    'Rolling9Basis': 'Rolling base 9',
    'Rolling12Basis': 'Rolling base 12',
    'Show': 'Mostrare',
    'Hide': 'Nascondere',
    'ShowHide': 'Mostra/nascondi',
    'Claims': 'Sinistri',
    'Premium': 'Premio',
    'Income': 'Reddito',
    'Expense': 'Spese',
    'ClaimLabel': 'Sinistri + Spese',
    'PremimumLabel': 'Premio lordo',
    'MonthlyLossRatio': 'Loss Ratio mensile',
    'CumulativeLossRatio': 'Loss Ratio cumulativo',
    'DateRange': 'Intervallo Di Date'
  }
}};