import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { TopNavbarComponent } from './topnavbar.component';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { TranslateModule } from '@ngx-translate/core';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { ExportButtonComponent } from '../export-button/export-button.component';
import { SelectClientButtonComponent } from '../select-client-button/select-client-button.component';

@NgModule({
    declarations: [
        TopNavbarComponent,
        ChangePasswordComponent,
        ExportButtonComponent,
        SelectClientButtonComponent,
    ],
    providers: [],
    imports: [
        MatTooltipModule,
        MatDialogModule,
        BrowserModule,
        FontAwesomeModule,
        TranslateModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        TopNavbarComponent
    ]
})
export class TopNavbarModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
        library.addIconPacks(far);
        library.addIconPacks(fal);
    }
}
