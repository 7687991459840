import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DashboardTemplateBase } from '../dashboard-template-base.component';
import { DataService } from 'app/dashboards/data.service';
import { HttpClient } from '@angular/common/http';
import { FilterOptions } from 'app/dashboards/shared/models/FilterOptions';
import { PulseEventName } from 'app/dashboards/shared/models/PulseEventName';
import { DimensionOrderService } from 'app/dashboards/dimension-order.service';
import { locale as navigationEnglish } from 'app/dashboards/templates/claim-by-age-band/i18n/en';
import { locale as navigationSpanishCL } from 'app/dashboards/templates/claim-by-age-band/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/dashboards/templates/claim-by-age-band/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/dashboards/templates/claim-by-age-band/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/dashboards/templates/claim-by-age-band/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/dashboards/templates/claim-by-age-band/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/dashboards/templates/claim-by-age-band/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/dashboards/templates/claim-by-age-band/i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { DimensionOptions } from 'app/dashboards/dashboardcontainer/dashboardcontainer.component';
import { CLAIM_BASIS } from 'app/models/basis.model';
import { COLOR_PALETTE } from 'app/constants/color-palette';

@Component({
    selector: 'app-claim-by-age-band',
    templateUrl: './claim-by-age-band.component.html',
    styleUrls: ['./claim-by-age-band.component.css']
})
export class ClaimByAgeBandComponent implements OnInit, DashboardTemplateBase {
    ClaimantOption = ClaimantOption;
    AgeBandSplitOption = AgeBandSplitOption;
    SortOption = SortOption;
    DataDiagProOption = DataDiagProOption;

    isFilterOptionsLoading = true;

    data: any;
    properties: any;
    imageUrlBase = '';
    filterOptions: FilterOptions = new FilterOptions();
    barColorPurple = '#101E7F';
    barColorBlue = '#A70070';
    barColorGray = '#29B0C3';
    showFilter: boolean = false;
    parentLoaded = false;
    datalist: any;
    maxClaimantTotal: any;
    maxClaimTotal: any;
    maxClaimCost: any;
    maxClaimantCost: any;
    maxCount: any;
    unitCost = '';
    @Output() action = new EventEmitter<any>();
    finalTopListBind: any;
    dataTableSelected = false;
    dataTableColumn: any;
    filterNA: boolean = false;
    categories: any = [];
    legendList: any = [];
    stackBarList: any = [];
    currentContainerIsTable: boolean;
    currentTimePeriodLable = '';
    comparablePeriodLabel = '';
    allClaimsTotal: number = 1;
    showLegend = false;
    topProviderTotal: number = 1;
    lowerTableSelectedRow: string = '';
    upperStackedSelectedBar: string = '';
    isLoadingStackChart = false;
    isLoadingBottomTable = false;
    CLAIM_BASIS = CLAIM_BASIS;

    initData(): void {

        if (typeof this.data.shownAsPerLowerTableSelectedRow === 'undefined') {
            this.data.shownAsPerLowerTableSelectedRow = '';
            if (this.data.lowerTableSelectedRow) {
                this.data.shownAsPerLowerTableSelectedRow = this.data.lowerTableSelectedRow;
            }
        }

        if (typeof this.data.shownAsPerUpperStackedSelectedBar === 'undefined') {
            this.data.shownAsPerUpperStackedSelectedBar = '';
        }

        if (typeof this.data.finalTopListBind === 'undefined') {
            this.data.finalTopListBind = '';
        }

        if (typeof this.data.lowerTableSelectedRow === 'undefined') {
            this.data.lowerTableSelectedRow = '';
        }

        this.datalist = this.data.dataList.current;
        var ageBandList = this.data.ageBandList;
        this.comparablePeriodLabel = this.properties.rollingPeriod == 3 ? ' (C)' : '';

        if (this.data != undefined) {
            if (
                this.data.ageBandList != undefined &&
                this.data.ageBandList.summaryTotals.length > 0
            ) {
                if (this.data.ageBandList.currentPeriod.fromDateLabel === this.data.ageBandList.currentPeriod.toDateLabel) {
                    this.currentTimePeriodLable = this.data.ageBandList.currentPeriod.fromDateLabel;
                }
                else {
                    this.currentTimePeriodLable = this.data.ageBandList.currentPeriod.fromDateLabel + ' ' + this._translateService.instant("ClaimAgeBand.DateRange") + ' ' + this.data.ageBandList.currentPeriod.toDateLabel;
                }


                this.allClaimsTotal = this.data.ageBandList.summaryTotals[0].claimsTotal;
            }
        }

        this.categories = [];
        for (var k in ageBandList.ageBandRankDictionary) {
            this.categories.push(k);
        }

        var colors = COLOR_PALETTE;
        this.legendList = [];
        var j = 0;
        this.stackBarList = [];
        ageBandList.distributedAgeBandSplit.map(d => {
            var safeObj = false
            for (var ij = 0; ij < d.secondDimensionTotal.length; ij++) {
                if (d.secondDimensionTotal[ij] > 0) {
                    safeObj = true;
                }
            }
            if (safeObj) {
                const objlegend = { name: '', color: '' };
                objlegend.name = d.name;
                objlegend.color = colors[j];
                this.legendList.push(objlegend);
                const seriesObj = {
                    type: undefined,
                    name: d.name,
                    data: d.secondDimensionTotal,
                    pointWidth: 50,
                    color: colors[j],
                    visible: this.properties.dataDiagPro === DataDiagProOption.DataTable ?
                        (this.data.lowerTableSelectedRow === '' || d.name === this.data.lowerTableSelectedRow) : true
                };
                this.stackBarList.push(seriesObj);
                j++;
            }
        });

        this.dataTableColumn = this.properties.dataDiagPro == DataDiagProOption.DataTable && this.properties.dataAgeSplit == AgeBandSplitOption.AgeBand
            ? this._translateService.instant('ClaimAgeBand.AgeBand') : this.properties.dataAgeSplit == AgeBandSplitOption.Gender ? this._translateService.instant('ClaimAgeBand.Gender')
                : this.properties.dataAgeSplit == AgeBandSplitOption.Relationship ? this._translateService.instant('ClaimAgeBand.Relationship') : '';
        if (this.properties.dataDiagPro == DataDiagProOption.TopProviders || this.properties.dataDiagPro == DataDiagProOption.TopDiagnoses) {
            this.dataTableColumn = this.properties.dataDiagPro == DataDiagProOption.TopProviders ? this._translateService.instant('ClaimAgeBand.TopProviders') : this.properties.dataDiagPro == DataDiagProOption.TopDiagnoses
                ? this._translateService.instant('ClaimAgeBand.TopDiagnoses') : '';
        }
        if (this.properties.dataDiagPro == DataDiagProOption.DataTable) {
            this.dataTableSelected = true;
            var summary = this.data.ageBandList.summaryTotals;
            if (
                summary.length > 0 &&
                this.datalist.length > 0 &&
                this.datalist[this.datalist.length - 1].dimension !== 'Total'
            ) {
                summary[0].dimension = 'Total';
                this.datalist.push(summary[0]);
            }
            this.data.finalTopListBind = this.datalist;
        } else {
            this.dataTableSelected = false;
            this.filterTop10List();
        }
        setTimeout(() => (this.parentLoaded = true), 100);

        if (typeof this.properties.isLegendShown === 'undefined') {
            this.properties.isLegendShown = false;
        }
    }

    constructor(
        private _dataService: DataService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private httpClient: HttpClient,
        private dimensionOrderService: DimensionOrderService
    ) { }

    ngOnInit() {
        this._fuseTranslationLoaderService.loadTranslations(
          navigationEnglish,
          navigationSpanishCL,
          navigationSpanishCO,
          navigationSpanishEC,
          navigationSpanishMX,
          navigationSpanishPE,
          navigationPortugueseBR,
          navigationItalianIT,
        );
    }

    selectionForClaimAndClaimantChanged(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
            data: {
                properties: {
                    name: filterName,
                    value: typeof val === 'object' ? val.data.join('||') : val
                }
            }
        });
        if ((this.data.lowerTableSelectedRow === '' && this.upperStackedSelectedBar === '')
            || (this.data.lowerTableSelectedRow === '' && this.upperStackedSelectedBar !== '')) {
            this.initData();
        }
        else {
            this.resetStackedBarSelection();
            this.resetDashboard();
        }

    }

    filterChanged(val, filterName: string) {
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
            data: {
                properties: {
                    name: filterName,
                    value: typeof val === 'object' ? val.data.join('||') : val
                }
            }
        });

        if (
            (filterName === 'sort') &&
            this.properties.dataDiagPro !== DataDiagProOption.DataTable
        ) {
            if ((this.data.lowerTableSelectedRow == '' && this.upperStackedSelectedBar == '')
                || (this.data.lowerTableSelectedRow == '' && this.upperStackedSelectedBar != '')) {
                this.filterTop10List();
            }
            else if (this.upperStackedSelectedBar == '' && this.data.lowerTableSelectedRow != '') {
                this.resetStackedBarSelection();
                this.resetDashboard();
            }

        }
    }
    filterTop10List() {
        this.topProviderTotal = 0;
        var list = this.dimensionOrderService.sortByColumn(this.datalist, this.properties.sort);
        this.data.finalTopListBind = list.reverse().slice(0, 10);
        this.datalist.map(element => { this.topProviderTotal += element.claimsTotal; });
        this.maxClaimTotal = Math.max.apply(null, this.data.finalTopListBind.map(a => { return a.claimsTotal; })
        );

        this.maxCount = Math.max.apply(null,
            this.data.finalTopListBind.map(a => {
                return this.properties.claimTypeOption == ClaimantOption.Claim
                    ? a.claimsCount
                    : a.claimantCount;
            })
        );
        this.maxClaimCost = Math.max.apply(
            null,
            this.data.finalTopListBind.map(a => {
                return this.properties.claimTypeOption == ClaimantOption.Claim
                    ? a.averageCostPerClaim
                    : a.averageCostPerClaimant;
            })
        );

        this.maxClaimTotal = this.maxClaimTotal + this.maxClaimTotal * 0.1;
        this.maxCount = this.maxCount + this.maxCount * 0.1;
        this.maxClaimCost = this.maxClaimCost + this.maxClaimCost * 0.1;
    }
    additionalFilterSelectionChanged(val) {
        this.action.emit(val);
    }
    selectionChanged(val, filterName: string) {
        this.resetStackedBarSelection();
        this.data.lowerTableSelectedRow = '';
        this.upperStackedSelectedBar = '';
        this.data.shownAsPerLowerTableSelectedRow = '';
        this.data.shownAsPerUpperStackedSelectedBar = '';
        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED,
            data: {
                properties: {
                    name: filterName,
                    value: val
                }
            }
        });
    }
    filterNAChange(val, filterName: string) {
        this.properties.isExcludeNA = !this.properties.isExcludeNA;
        let filterVal: any;
        if (this.properties.isExcludeNA === false) {
            filterVal = '';
        }
        else if (this.properties.isExcludeNA === true) {
            filterVal = 'claimant';
        }
        if (this.data.lowerTableSelectedRow === '' && this.upperStackedSelectedBar === '') {
            this.action.emit({
                type: PulseEventName.FILTLER_CHANGED,
                data: {
                    properties: {
                        name: filterName,
                        value: filterVal
                    }
                }
            });
        } else {
            this.action.emit({
                type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
                data: {
                    properties: {
                        name: filterName,
                        value: typeof filterVal === 'object' ? filterVal.data.join('||') : filterVal
                    }
                }
            });
            if (this.properties.dataDiagPro === DataDiagProOption.TopDiagnoses ||
                this.properties.dataDiagPro === DataDiagProOption.TopProviders) {
                if (this.data.lowerTableSelectedRow === '' && this.upperStackedSelectedBar !== '') {
                    this.isLoadingBottomTable = true;
                    this._dataService
                        .getTier3Data(this.properties)
                        .toPromise()
                        .then(re => {
                            this.data.dataList = re;
                            this.initData();
                            this.isLoadingBottomTable = false;
                        });
                }
            }

        }
    }
    resetTableRowSelection() {
        this.properties.claimsByAgeBandsDiagChapter = '';
        this.properties.claimsByAgeBandsProviderName = '';
        this.properties.claimsByAgeBandsAgeBand = '';
        this.properties.claimsByAgeBandsGender = '';
        this.properties.claimsByAgeBandsRelationship = '';
    }
    selectTableRow(event: any, data: any) {
        var rowValue: string = data.dimension;
        if (rowValue === 'Total' && (this.properties.dataDiagPro === DataDiagProOption.DataTable)) {
            return;
        }
        else (rowValue != null)
        {
            if (rowValue.search('\'') != -1) {
                rowValue = rowValue.replace('\'', '\'\'');
            }
        }
        this.resetTableRowSelection();
        if (this.data.lowerTableSelectedRow == rowValue) {
            rowValue = '';
            this.resetDashboard();
        }
        else {
            let rowTypeName: string = '';
            this.upperStackedSelectedBar = '';
            this.data.lowerTableSelectedRow = rowValue;
            this.data.shownAsPerLowerTableSelectedRow = (this.properties.dataDiagPro != DataDiagProOption.DataTable) && (data.claimantCount < this.properties.claimantFilterValue) ? 'NA' : rowValue;
            if (this.properties.dataDiagPro === DataDiagProOption.TopDiagnoses) {
                this.properties.claimsByAgeBandsDiagChapter = rowValue;
                rowTypeName = 'claimsByAgeBandsDiagChapter';
            } else if (this.properties.dataDiagPro === DataDiagProOption.TopProviders) {
                this.properties.claimsByAgeBandsProviderName = rowValue;
                rowTypeName = 'claimsByAgeBandsProviderName';
            } else if (this.properties.dataDiagPro === DataDiagProOption.DataTable) {
                if (this.properties.dataAgeSplit === AgeBandSplitOption.AgeBand) {
                    this.properties.claimsByAgeBandsAgeBand = rowValue;
                    rowTypeName = 'claimsByAgeBandsAgeBand';
                }
                if (this.properties.dataAgeSplit === AgeBandSplitOption.Gender) {
                    this.properties.claimsByAgeBandsGender = rowValue;
                    rowTypeName = 'claimsByAgeBandsGender';
                }
                if (this.properties.dataAgeSplit === AgeBandSplitOption.Relationship) {
                    this.properties.claimsByAgeBandsRelationship = rowValue;
                    rowTypeName = 'claimsByAgeBandsRelationship';
                }
            }
            if (rowTypeName.length > 0) {  //set row value, ?
                this.action.emit({
                    type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
                    data: {
                        properties: {
                            name: rowTypeName,
                            value: rowValue
                        }
                    }
                });
                this.isLoadingStackChart = true;

                this.properties.dimension = DimensionOptions.AgeBand;

                this._dataService
                    .getTier3ClaimByAgeBand(this.properties)
                    .toPromise()
                    .then(ag => {
                        this.data.ageBandList = ag;
                        this.initData();
                        this.isLoadingStackChart = false;
                    }
                    );
            }
        }
    }
    resetDashboard() {
        this.isLoadingStackChart = true;
        this.isLoadingBottomTable = true;
        this.upperStackedSelectedBar = '';
        this.data.lowerTableSelectedRow = '';
        this.data.shownAsPerLowerTableSelectedRow = '';
        this.data.shownAsPerUpperStackedSelectedBar = '';
        var finalData = {};
        this.properties.dimension = this.properties.dataDiagPro == DataDiagProOption.DataTable ? this.properties.dataAgeSplit : this.properties.dataDiagPro;
        this.properties.dimension =
            [DimensionOptions.AgeBand,
            DimensionOptions.Gender,
            DimensionOptions.Relationship,
            DimensionOptions.DiagChapter,
            DimensionOptions.ProviderName
            ].indexOf(this.properties.dimension) <= -1 ? DimensionOptions.Relationship : this.properties.dimension;
        this._dataService
            .getTier3Data(this.properties)
            .toPromise()
            .then(r => {
                finalData['dataList'] = r;
                this.properties.dimension = DimensionOptions.AgeBand;
                this.properties.secondaryDimension = this.properties.dataAgeSplit;
                this._dataService
                    .getTier3ClaimByAgeBand(this.properties)
                    .toPromise()
                    .then(ag => {
                        finalData['ageBandList'] = ag;
                        this.data = finalData;
                        this.initData();
                        this.isLoadingStackChart = false;
                        this.isLoadingBottomTable = false;
                    });
            });
    }
    resetStackedBarSelection() {
        this.properties.claimsByAgeBandsDiagChapter = '';
        this.properties.claimsByAgeBandsProviderName = '';
        this.properties.claimsByAgeBandsAgeBand = '';
        this.properties.claimsByAgeBandsGender = '';
        this.properties.claimsByAgeBandsRelationship = '';
    }
    selectAge(event: any) {
        let filterFirstDimension, firstDimensionValue, secondaryDimension,
            secondaryDimensionValues, tertiaryDimension, tertiaryDimensionValue;
        secondaryDimension = 'age band';
        secondaryDimensionValues = event.category;

        let rowValue: string = event.category;
        this.resetStackedBarSelection();
        if (this.upperStackedSelectedBar === rowValue + event.series.name) {
            //this.resetStackedBarSelection();
            this.resetDashboard();
            return;
        } else {
            this.isLoadingBottomTable = true;
            this.data.lowerTableSelectedRow = '';
            this.upperStackedSelectedBar = rowValue + event.series.name;
            if (this.properties.dataAgeSplit === AgeBandSplitOption.AgeBand) {
                this.data.shownAsPerUpperStackedSelectedBar = rowValue;
            }
            else {
                this.data.shownAsPerUpperStackedSelectedBar = rowValue + ',' + event.series.name;
            }

            if (rowValue != null) {
                if (rowValue.search('\'') !== -1) {
                    rowValue = rowValue.replace('\'', '\'\'');
                }
            }
            this.properties.claimsByAgeBandsAgeBand = rowValue;//sec dimension val
            if (this.properties.dataDiagPro === DataDiagProOption.DataTable) {
                if (this.properties.dataAgeSplit === AgeBandSplitOption.AgeBand) {
                    filterFirstDimension = AgeBandSplitOption.AgeBand;
                }
                if (this.properties.dataAgeSplit === AgeBandSplitOption.Gender) {
                    this.properties.claimsByAgeBandsGender = event.series.name;
                    // tertiaryDimension = 'aGenders';
                    // tertiaryDimensionValue = event.series.name;
                    filterFirstDimension = AgeBandSplitOption.Gender;
                }
                if (this.properties.dataAgeSplit === AgeBandSplitOption.Relationship) {
                    this.properties.claimsByAgeBandsRelationship = event.series.name;
                    filterFirstDimension = AgeBandSplitOption.Relationship;
                    // tertiaryDimension = 'aRelationships';
                    // tertiaryDimensionValue = event.series.name;
                }
            }
            else {
                if (this.properties.dataDiagPro === DataDiagProOption.TopDiagnoses) {
                    filterFirstDimension = DataDiagProOption.TopDiagnoses;
                }
                else if (this.properties.dataDiagPro === DataDiagProOption.TopProviders) {
                    filterFirstDimension = DataDiagProOption.TopProviders;
                }

                if (this.properties.dataAgeSplit === AgeBandSplitOption.Gender) {
                    this.properties.claimsByAgeBandsGender = event.series.name;
                    // tertiaryDimension = 'aGenders';
                    // tertiaryDimensionValue = event.series.name;
                }
                if (this.properties.dataAgeSplit === AgeBandSplitOption.Relationship) {
                    this.properties.claimsByAgeBandsRelationship = event.series.name;
                    // tertiaryDimension = 'aRelationships';
                    // tertiaryDimensionValue = event.series.name;
                }
            }
            this.properties.dimension = filterFirstDimension;

            this._dataService
                .getTier3Data(this.properties)
                .toPromise()
                .then(re => {
                    this.data.dataList = re;
                    this.initData();
                    this.isLoadingBottomTable = false;
                });
        }
    }


    ngOnDestroy() {
        //this._translateService.onLangChange.unsubscribe();
    }
}
export enum ClaimantOption {
    Claim = 'Claim',
    Claimant = 'Claimant'
}
export enum AgeBandSplitOption {
    AgeBand = 'Age Band',
    Gender = 'Gender',
    Relationship = 'Relationship'
}
export enum SortOption {
    ClaimTotal = 'claimsTotal',
    CountClaim = 'claimsCount',
    CountClaimant = 'claimantCount',
    AvergareCostlaim = 'averageCostPerClaim',
    AvergareCostlaimant = 'averageCostPerClaimant',
    ChangeInCost = '%ChangeInCost'
}
export enum DataDiagProOption {
    DataTable = 'DataTable',
    TopDiagnoses = 'Diag Chapter',
    TopProviders = 'Provider Name'
}
