import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UseroptionsDialogComponent } from 'app/login/useroptions/useroptions-dialog/useroptions-dialog.component';

@Component({
  selector: 'app-select-client-button',
  templateUrl: './select-client-button.component.html',
  styleUrls: ['./select-client-button.component.scss'],
})
export class SelectClientButtonComponent {
  constructor(public dialog: MatDialog) { }

  public handleSelectClient(): void {
    this.dialog.open(UseroptionsDialogComponent, {
      minWidth: '60vh',
      minHeight: '350px'
    });
  }
}
