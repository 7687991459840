import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { Observable } from 'rxjs';
import { switchMap, debounceTime, startWith, distinctUntilChanged, map, tap, finalize } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';


@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {

  isSearchLoading = false;
  myControl = new FormControl();
  filteredOptions: Observable<any[]>;
  @Input() searchResults: any[] = [];
  @ViewChild('searchBarInput', { static: true }) searchBarInput: ElementRef;

  constructor(public adminService: AdminService) {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        tap(() => this.isSearchLoading = true),
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(val => {
          return this.filter(val || '')
            .pipe(
              finalize(() => this.isSearchLoading = false)
            )
        })
      );
  }

  ngOnInit() {
    console.log(this.searchResults);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    console.log(this.searchResults);
    this.searchResults.push(event.option.value);
    this.searchBarInput.nativeElement.value = '';
    this.myControl.setValue(null);
  }

  removeChip(index) {
    this.searchResults.splice(index, 1);
  }

  filter(val: string): Observable<any[]> {
    return this.adminService.search(val.toLowerCase());
  }

}
