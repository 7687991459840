import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { GridOptions } from 'ag-grid-community';
import {
  AgeBand_BenefitScoreData, DrilldownAgeBand, DrilldownSalaryBand,
  BenefitScore_Overtime, BenefitScoreLocationDataByGender, BenefitScoreLocationDataByAgeBand, BenefitScoreLocationDataBySalaryBand, LocationTableColumnDefs, SalaryBand_BenefitScoreData, BenefitQuestions, columnDefsAgeBand, columnDefsSalaryBand, columnDefsGender, BenefitScore_Overtime_Gender, BenefitScore_Overtime_Location, BenefitScore_Overtime_SalaryBand
} from 'app/fwb/constants/fwb-charts-data';
import { FWBService } from 'app/fwb/services/fwb.service';
import { User } from 'app/models/user.model';
import { navigation } from 'app/navigation/navigation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from 'app/_reducers';
import { FwbWellbeingService } from 'app/fwb/services/fwb-wellbeing.service';
@Component({
  selector: 'score-drill-down',
  templateUrl: './score-drill-down.component.html',
  styleUrls: ['./score-drill-down.component.css']
})

export class ScoreDrillDownComponent implements OnInit {
  chartId = "scoreDrillDown";
  height: any = "600px";
  width: any = "98%";
  cardheightnumber: number = 700;
  cardheight: any = "770px";
  cardwidth: any = "99%";
  tooltip: boolean = true;
  titleposition = "left";
  charttitle = "Breakdown of Question ";
  chartSubTitle = "";
  yaxistitle = "";
  color = "white";
  maxyaxisnumber: number = 1000;
  dataAgeBand: any;
  dataGender: any;
  fuseConfig: any;
  navigation: any;
  contenttop: string = "60px";
  legendsubtitle: string = "Total Employees";
  legendTrim: number = 0;
  legendtitle = "";
  legendPosition = "left";
  middleText: any = "500";
  showLegend: boolean = true;
  isAgeBandSelected: boolean = true;
  isLocationSelected: boolean = false;
  isOverTimeSelected: boolean = false;
  isSalaryBandSelected: boolean = false;
  hbwidth: string = "96%";
  hbheight: string = "500px";
  public user: User;
  expandedData: any[];
  agebandData: any[];
  locationData: any[];
  overtimedata: any[];
  salaryBandData: any[];
  isHeaderVisible: boolean = true;
  setMaxBarChartHeight: boolean = false;
  agebandHeightParent: "100%";
  gridApi: any;
  gridColumnApi: any;
  tickSize: number = 6;
  //Stacked horizontal bar chart
  stackedmaxyaxisnumber: number = 1000;
  emptyChartTitle = " ";
  public multi: any[] = [];
  public expandedChartData: any[] = [];
  public expandedSalaryData: any[] = [];
  public expandedAgeBandData: any[] = [];
  stackedChartWidth = "75%";
  stackedData: any[];
  genderkeys = ["Male", "Female"];
  stackedChartHeight = "90%";
  categorycolors: any[] = [2, 3];
  scaleMax = 50;
  scaleMaxAgeBand = 50;
  maxTotalGender = 500;
  salaryKeys = ["100K", "50k90K", "50K"]
  questionsData: any[];
  expandableAgeBandheight: string = "550px";

  stackedheightAgeBand: string = "200px";
  stackedwidth: string = "80%";
  stackedheight: string = "600px";
  navigationheight: string = "85px";
  stackedSalaryheight = "90px";
  stackedcharttitle: string = "";
  stackedxaxistitle: string = "";
  stackedyaxistitle: string = "";

  stackedtitleposition: "center" | "left" | "right" | "none" = "none";
  stackedtooltip: boolean = true;
  stackedcategorycolors: any[] = [];
  stackedkeys: any[] = ["Male", "Female"];
  stackedlegendtitle: any[] = ["Male", "Female"];
  currentQuestion: number = 1;

  disablePrevArrow: boolean = false;
  disableNextArrow: boolean = false;
  toggleTotal: boolean = true;
  totalText = "Total";
  //Location data table
  gridOptions: GridOptions;
  tableData: any;
  columnDefs = LocationTableColumnDefs;


  options = [
    { key: "AgeBand", name: "Age Band" },
    { key: "Gender", name: "Gender" },
    { key: "SalaryBand", name: "Salary Band" },
  ];
  selectedOption = true;
  //Over time data 
  overtimeOptions = [
    { key: "AgeBand", name: "Age Band" },
    { key: "Gender", name: "Gender" },
    { key: "Location", name: "Location" },
    { key: "SalaryBand", name: "Salary Band" },
  ];
  selectedOvertimeOption = true;
  selectedOvertimeOptionName: string = "Age Band";
  selectedOptionName: string = "Age Band";

  locationDataBarChartData: any[];
  serviceOvertimeData: any = null;
  serviceDrilldownData: any = null;
  serviceQuestionData: any = null;
  servicelocationData: any = null;
  isLoading = true;
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _wellbeingService: FwbWellbeingService,
    private _fwbService: FWBService,
    private store: Store<State>,
    private _router: Router
  ) {
    // Set the defaults
    this.navigation = navigation;
    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this,
      },
      defaultColDef: {
        resizable: true,
      },
      enableSorting: true,
      // headerHeight: 0,
      suppressHorizontalScroll: true,
      enableFilter: false,
    };

    this.store.select('fwb').forEach(res => {
      if (res == undefined) {
        console.log("this.fwb is undefined");
      } else {
        // this.currentQuestion = Math.floor((res.question) == null || typeof res.question ? 5 : res.question);
        // this.toggleTotal = (res.togglequestionselection) == null || typeof res.togglequestionselection ? false : true;
        this.currentQuestion = isNaN(res.question) ? 1 : parseInt(res.question);
        this.toggleTotal = res.togglequestionselection

        this.updateArrows();
      }

    });
    if (this.toggleTotal) {
      this.charttitle = "Breakdown of Total ";
    } else {
      this.charttitle = "Breakdown of Question  " + this.currentQuestion;
    }
  }
  public fwb: any = {
    question: 1,
    toggleQuestionSelection: false
  };

  togglebyTotal() {
    // if (event.detail != null) {
    //   console.log(event.detail);
    // }
    this.toggleTotal = !this.toggleTotal;
    this.updateToggleQuestion();
    if (this.toggleTotal) {
      this.charttitle = "Breakdown of Total ";
    } else {
      this.charttitle = "Breakdown of Question  " + this.currentQuestion;
    }

  }


  updateToggleQuestion() {
    this.fwb = {
      question: this.currentQuestion,
      toggleQuestionSelection: this.toggleTotal
    }

    this._fwbService.updateQuestion(this.currentQuestion);
    this._fwbService.toggleQuestionSelection(this.fwb);
  }

  updateArrows() {
    this.disablePrevArrow = false;
    this.disableNextArrow = false;
  }

  ngOnInit() {


    this.categorycolors = [];
    var obj = { name: "Prior", colorindex: 0 };
    var obj2 = { name: "Current", colorindex: 1 };
    this.categorycolors.push(obj);
    this.categorycolors.push(obj2);


    this.getUser();
    this.toggleHorizontalTab('Age Band');
    this.getDrilldownData();
    this.getQuestionsData();
    this.getOvertimeData();
    this.getLocationData();


    this.updateArrows();

    if (this.toggleTotal) {
      this.charttitle = "Breakdown of Total ";
    } else {
      this.charttitle = "Breakdown of Question  " + this.currentQuestion;
    }
  }

  getOvertimeData() {
    this.serviceOvertimeData = null;
    this._wellbeingService.getOvertime("").toPromise().then((resp) => {
      this.serviceOvertimeData = resp;
      console.log(this.serviceOvertimeData);
      this.setOvertimedata();
    }).catch((e) => {
      console.log(e);

    }).finally(() => {
      this.isLoading = false;
    })
  }


  setOvertimedata() {

    this.selectedOvertimeOption = true;
    if (this.selectedOvertimeOptionName == "Gender") {
      this.overtimedata = this.serviceOvertimeData.resultGender; // BenefitScore_Overtime_Gender;
    } else if (this.selectedOvertimeOptionName == "Salary Band") {
      this.overtimedata = this.serviceOvertimeData.resultSalaryBand; // BenefitScore_Overtime_SalaryBand;
    } else if (this.selectedOvertimeOptionName == "Location") {
      this.overtimedata = this.serviceOvertimeData.resultLocation; //BenefitScore_Overtime_Location;
    } else if (this.selectedOvertimeOptionName == "Age Band") {
      this.overtimedata = this.serviceOvertimeData.resultAgeBand; // BenefitScore_Overtime;
    }

  }

  getQuestionsData() {
    this.serviceQuestionData = null;
    this._wellbeingService.getQuestions("").toPromise().then((resp) => {
      this.serviceQuestionData = resp;
      console.log(this.serviceQuestionData);
      this.setQuestionData();
    }).catch((e) => {
      console.log(e);

    }).finally(() => {
      this.isLoading = false;
    })
  }

  setQuestionData() {
    this.questionsData = this.serviceQuestionData.result;
  }



  getLocationData() {
    this.servicelocationData = null;
    this._wellbeingService.getLocations("").toPromise().then((resp) => {
      this.servicelocationData = resp;
      console.log(this.servicelocationData);
      this.setLocationData();
    }).catch((e) => {
      console.log(e);

    }).finally(() => {
      this.isLoading = false;
    })
  }

  setLocationData() {

    this.selectedOption = true;
    if (this.selectedOptionName == "Gender") {
      this.columnDefs = [];
      this.locationData = this.servicelocationData.resultGender; //this.getLocationDataByGender();

    } else if (this.selectedOptionName == "Salary Band") {
      this.columnDefs = [];
      this.locationData = this.servicelocationData.resultSalaryBand;

    } else if (this.selectedOptionName == "Age Band") {
      this.columnDefs = [];

      this.locationData = this.servicelocationData.resultAgeBand;
    }

    this.prepareLocationTable();
  }



  private prepareLocationTable() {
    var colObj1 = {
      headerName: 'Country',
      field: 'category',
      pinned: 'left',
      width: 230,
      cellClass: 'grid-cell-left classification',
      style: 'font-family:large;font-size:600;',
    };
    this.columnDefs.push(colObj1);
    var totalColCount = this.locationData[0].values.length;
    var colWidth = 180;
    var incColWidth = 0;
    if (totalColCount == 5) {
      colWidth = 180;
    } else if (totalColCount == 3) {
      colWidth = 250;
      incColWidth = 50;
    } else if (totalColCount == 2) {
      colWidth = 300;
      incColWidth = 100;
    }
    for (var j = 0; j < this.locationData[0].values.length; j++) {
      // console.log(this.locationData[0].values[j]);
      var col2 = {
        headerName: '' + this.locationData[0].values[j].name + '',
        field: this.locationData[0].values[j].name,
        pinned: 'left',
        width: colWidth + incColWidth,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;font-size:600;',
      };
      this.columnDefs.push(col2);
    }

    var colObjTotal = {
      headerName: 'Total',
      field: 'total',
      pinned: 'left',
      width: 175 + incColWidth,
      cellClass: 'grid-cell-left classification',
      style: 'font-family:large;font-size:600;',
    };
    this.columnDefs.push(colObjTotal);

    this.locationData.forEach((a, i) => {
      this.locationData[i].total = 0;
      a.values.forEach(element => {
        this.locationData[i].total += Math.floor(element.value);
        this.locationData[i][element.name] = element.value;
      });
      this.locationData[i].total = Math.floor(this.locationData[i].total / totalColCount);
    }
    );
  }

  getDrilldownData() {
    this.serviceDrilldownData = null;
    this._wellbeingService.getDrilldown("").toPromise().then((resp) => {
      this.serviceDrilldownData = resp;
      console.log(this.serviceDrilldownData);
      this.setDrilldownData();
    }).catch((e) => {
      console.log(e);

    }).finally(() => {
      this.isLoading = false;
    })
  }

  setDrilldownData() {
    this.agebandData = Object(this.serviceDrilldownData.resultAgeBand);
    this.salaryBandData = Object(this.serviceDrilldownData.resultSalaryBand);

  }

  toggleHorizontalTab(selectedTab) {
    this.isAgeBandSelected = false;
    this.isLocationSelected = false;
    this.isOverTimeSelected = false;
    this.isSalaryBandSelected = false;
    switch (selectedTab) {
      case "Age Band":
        this.isAgeBandSelected = true;
        if (this.serviceDrilldownData === null || typeof this.serviceDrilldownData === undefined) {
          this.getDrilldownData();
        }
        break;
      case "Location":
        this.isLocationSelected = true;
        this.setLocationData();
        break;
      case "Over Time":
        this.isOverTimeSelected = true;
        break;
      case "Salary Band":
        this.isSalaryBandSelected = true;
        if (this.serviceDrilldownData === null || typeof this.serviceDrilldownData === undefined) {
          this.getDrilldownData();
        }
        break;
      default: this.isAgeBandSelected = true;
        break;
    }
  }

  getUser() {
  }


  goToLearnMore() {
    this._router.navigate(["/fwb/demographics"]);
  }

  onPrev($event) {
    console.log($event.detail);
    if (this.currentQuestion == 1) {
      this.currentQuestion = this.questionsData.length;
    } else {

      this.currentQuestion = this.currentQuestion - 1;
    }
    this._fwbService.updateQuestion(this.currentQuestion);

  }


  onNext($event) {

    if (this.currentQuestion == this.questionsData.length) {
      this.currentQuestion = 1;
    } else {

      this.currentQuestion += 1;
    }
    this._fwbService.updateQuestion(this.currentQuestion);
    console.log($event.detail);
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }


  onOvertimeOptionChange($event) {
    this.selectedOvertimeOptionName = $event.detail;
    this.setOvertimedata();
  }
  onOptionChange($event) {
    this.selectedOptionName = $event.detail;
    this.setLocationData();
  }


  expandParentHeightForSalaryBand(rowItem) {
    let expandBy: number = 0;
    this.salaryBandData.forEach((a) => {
      if (a.category == rowItem) {
        a.isexpanded = !a.isexpanded;
      }

      if (a.isexpanded == true) {
        expandBy += 1;
      }
    })

    this.cardheightnumber = 770 + (600 * expandBy);
    this.cardheight = this.cardheightnumber + "px";
  }

  expandParentHeightForAgeBand(rowItem) {
    let expandBy: number = 0;
    this.agebandData.forEach((a) => {
      if (a.category == rowItem) {
        a.isexpanded = !a.isexpanded;
      }

      if (a.isexpanded == true) {
        expandBy += 1;
      }
    })

    console.log("expand height called" + rowItem);
    this.cardheightnumber = 770 + (450 * expandBy);
    this.cardheight = this.cardheightnumber + "px";
    console.log("Parent drilldown card expanded by * " + expandBy + ". Current Parent height : " + this.cardheightnumber);
  }

}
