import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as d3 from 'd3';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-highchartspoc',
  templateUrl: './highchartspoc.component.html',
  styleUrls: ['./highchartspoc.component.css']
})
export class HighchartspocComponent implements OnInit {
  isLoading = false;
  Highcharts: typeof Highcharts = Highcharts;
  client: string;
  country: string;
  categories: Category[] = [];
  data = {
    categories: [],
    // categories: [
    //   {
    //     name: 'Chronic',
    //     benchmark: 0.94,
    //     current: {
    //       paidClaim: 66.8,
    //       averageCost: 11370,
    //       claimantCount: 5873,
    //       prevalence: 0.32
    //     },
    //     prior: {
    //       paidClaim: 65.7,
    //       averageCost: 10856,
    //       claimantCount: 6053,
    //       prevalence: 0.31
    //     }
    //   },
    //   {
    //     name: 'Infectious',
    //     benchmark: 0.72,
    //     current: {
    //       paidClaim: 23,
    //       averageCost: 4100,
    //       claimantCount: 5616,
    //       prevalence: 0.3
    //     },
    //     prior: {
    //       paidClaim: 24.7,
    //       averageCost: 4276,
    //       claimantCount: 5785,
    //       prevalence: 0.3
    //     }
    //   },
    //   {
    //     name: 'Other',
    //     benchmark: 2,
    //     current: {
    //       paidClaim: 122.4,
    //       averageCost: 11418,
    //       claimantCount: 10723,
    //       prevalence: 0.58
    //     },
    //     prior: {
    //       paidClaim: 128.1,
    //       averageCost: 11279,
    //       claimantCount: 11357,
    //       prevalence: 0.59
    //     }
    //   }
    // ]
  }

  constructor(private route: ActivatedRoute,
    private httpClient: HttpClient) {
    this.route.paramMap.subscribe(params => {
      this.manageRoute(params);
    });
  }

  manageRoute(params) {
    this.isLoading = true;
    this.client = params.get("client");
    this.country = params.get("country");
    console.log(this.country, this.client);

    this.getData().toPromise().then(r => {
      console.log(r);
      this.transformData(r);
      var elements: any = document.getElementsByTagName("animate");
      for (var i = 0; i < elements.length; i++) {
        elements[i].beginElement();
      }
      this.isLoading = false;
    });
  }

  ngOnInit() {
    
    // set the dimensions and margins of the graph
    var width = 450
    var height = 450
    var margin = 40

    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    var radius = Math.min(width, height) / 2 - margin

    // append the svg object to the div called 'my_dataviz'
    var svg = d3.select("#my_dataviz")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    // Create dummy data
    var data = { a: 9, b: 20, c: 30, d: 8, e: 12 }

    // set the color scale
    var color = d3.scaleOrdinal()
      .domain(data)
      .range(["#98abc5", "#8a89a6", "#7b6888", "#6b486b", "#a05d56"])

    // Compute the position of each group on the pie:
    var pie = d3.pie()
      .value(function (d) { return d.value; })
    var data_ready = pie(d3.entries(data))

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll('whatever')
      .data(data_ready)
      .enter()
      .append('path')
      .attr('d', d3.arc()
        .innerRadius(100)         // This is the size of the donut hole
        .outerRadius(radius)
      )
      .attr('fill', function (d) { return (color(d.data.key)) })
      .attr("stroke", "black")
      .style("stroke-width", "2px")
      .style("opacity", 0.7)

  }

  getSVGPercentage(val, max) {
    // console.log(val, max)
    return '' + (val / max) * 100 + '%'
  }

  getData() {
    return this.httpClient.get<any>(environment.api + 'api/values/hc/' + this.country + '/' + this.client)
  }

  transformData(raw) {
    raw.forEach(r => {
      var c = this.categories.filter(d => d.name == r.isChronic)[0];
      if(!c) {
        c = new Category();
        c.name = r.isChronic;
        c.benchmark = 0.99;
        this.categories.push(c);
      }

      if(r.r12 == 1) {
        c.current.paidClaim = (r.paidClaimAmt / 1000000).toFixed(2);
        c.current.averageCost = r.averageCost;
        c.current.claimantCount = r.claimants.toFixed(2);
        c.current.prevalence = r.prevalence.toFixed(2);
      } else {
        c.prior.paidClaim = (r.paidClaimAmt / 1000000).toFixed(2);
        c.prior.averageCost = r.averageCost;
        c.prior.claimantCount = r.claimants.toFixed(2);
        c.prior.prevalence = r.prevalence.toFixed(2);
      }
      console.log(this.categories);
    });
  }
}

export class Category {
  name: string;
  benchmark: number;
  current: any = {};
  prior: any = {};
}
