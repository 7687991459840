export const locale = {
    lang: 'es-cl',
    data: {
        'LossRatio': {
            'LossRatioHeading' : 'Siniestralidad',
            'AllAmountsDisplayedInLocalCurrency' :  'Todas las cantidades mostradas en moneda local',
            'Loss' : 'Siniestralidad',
            'LossRatioType' : 'Tipo de Siniestralidad',
            'CombinedLR' : 'Siniestralidad Combinada',
            'ClaimsTotalPMPM' : 'Monto Reembolsado Total / PMPM',
            'ClaimsPMPM' : 'Monto Reembolsado PMPM',
            'ClaimsTotal' : 'Monto Reembolsado Total',
            'TargetLossRatio' : 'Target Siniestralidad',
            'ClaimsReportedByPaidDate' :  'Reembolsos reportados por fecha de pago',
            'ClaimsReportedByIncurredDate' :  'Reembolsos reportadas por fecha incurrida',
            'Monthly' : 'Por Mes',
            'YearToDateBasis' : 'Año Corrido',
            'YearToDateBasisC' : 'Año Corrido (C))',
            'Rolling3Basis' : '3 Meses Corridos',
            'Rolling6Basis' : '6 Meses Corridos',
            'Rolling9Basis' : '9 Meses Corridos',
            'Rolling12Basis' : '12 Meses Corridos',
            'Show':'mostrar',
             'Hide':'Esconder',
            'ShowHide' : 'Mostrar/Esconder',                        
            'Claims' : 'Reembolsos',
            'Premium' : 'Primas',
            'Income':'Ingresos',
            'Expense':'Gastos',
            'ClaimLabel': 'Reembolsos',
            'PremimumLabel':'Primas Sin Factor Stop Loss',
            'MonthlyLossRatio':'Por mes de siniestralidad',
            'CumulativeLossRatio':'Acumulativa de siniestralidad',
            'DateRange': 'to'

        }
    }
};
