export const locale = {
    lang: 'it',
    data: {
  'DiseaseDetail': {
    'DiseaseDetailHeading': 'Incidenza della malattia',
    'AllAmountInLocalCurrency': 'Tutti gli importi visualizzati in valuta locale',
    'SelectTop': 'Seleziona top',
    'Diagnosis': 'Patologia',
    'ClaimsTotalHeader': 'Costo totale',
    'ClaimantTotalHeader': 'Numero teste colpite',
    'DiagnosisLevel': 'Livello di diagnosi',
    'Category': 'Categoria',
    'Chapter': 'Prestazione',
    'ClaimsTotal': 'Sinistri totali',
    'DigClaimantCount': 'Numero teste colpite',
    'DiseaseProgression': 'Progressione della malattia nel tempo',
    'Age': 'Età',
    'CostPerClaimant': 'Costo per assistito attivo',
    'CostPerClaim': 'Costo per sinistro',
    'ClaimsPerClaimant': 'Sinistri per assistito attivo',
    'TotalCost': 'Costo totale',
    'ClaimantCount': 'Numero teste colpite',
    'Prevalence': 'Incidenza',
    'Benchmark': 'Benchmark',
    'IncludeClient': 'Includere cliente',
    'ExcludeClient': 'Escludi cliente',
    'Portfolio': 'Portafoglio',
    'Industry': 'Industria',
    'None': 'Nessuno',
    'AgeBand': "Fascia d'età",
    'Gender': 'Genere',
    'Relationship': 'Relazione',
    'ClaimType': 'Sinistri oer assistito attivo',
    'ClaimsReportedByPaidDate': 'Sinistri per data di pagamento',
    'ClaimsReportedByIncurredDate': 'Sinistri per data di accadimento',
    'ShownAsPer': 'Indicato come per',
    'DateRange': 'Intervallo Di Date',
    'DiagnosisGroup': 'Gruppo di diagnosi',
    'Actionable': 'Actionables',
    'Chronic': 'Cronico',
    'Infectious': 'Infettivo',
    'Trauma/Accidents': 'Traumi/incidenti',
    'Cancer': 'Cancro',
    'Mental Health': 'Salute mentale',
    'Obstetrics': 'Ostetricia',
    'Others': 'Altri',
    'Preventable': 'Prevenibile',
    'Intervenable': 'Intervenibile'
  }
}};