<div fxLayout="column" *ngIf="isLoading" style="overflow: hidden;">
    <div fxLayout="row" class="fwbloadingOverlay">
        <div class="fwbloadingContent">
            Loading...
        </div>
    </div>
</div>
<aon-summary-card [height]="cardheight" [width]="cardwidth" *ngIf="!isLoading">
    <aon-summary-card-header>
        <aon-h3 style="color: #001b5a;">
            <fa-icon style="padding-right: 5px;" [icon]="['fal', 'piggy-bank']"></fa-icon>
            {{ charttitle }}
        </aon-h3>
    </aon-summary-card-header>
    <aon-summary-card-content top="65px">
        <fwb-error-message [isError]="isError" [message]="errorMsg"></fwb-error-message>
        <div *ngIf="!isError">
            <div style="text-align: left;">
                <aon-p-lg>
                    Assess your employees' likelihood of meeting their target lifestyle.
                </aon-p-lg>
            </div>
            <div style="text-align: left;">
                <div class="flex-row-space-between">
                    <div class="flex-column-simple" style="width: 90%;height:98%;">

                        <aon-legend height="90%" [data]="keys" [legendposition]="'vertical'"></aon-legend>

                        <div class="flex-column-simple" style="margin-top: 50px;">
                            <div class="legendColumn marginRight5">
                                <div class="padingLeft2 marginRight5">
                                    <aon-p-sm class="paddingBottom0 hydrated">
                                        <svg width="10" height="10">
                                            <circle r="1rem" cx="6" cy="6" width="10" height="10" stroke="#00000">
                                            </circle>
                                        </svg>&nbsp;&nbsp;</aon-p-sm>
                                </div>
                                <div class="marginTop2">
                                    <aon-p-sm>
                                        Target Met
                                    </aon-p-sm>
                                </div>
                            </div>
                            <div class="legendColumn marginRight5">
                                <div class="padingLeft2 marginRight5">
                                    <aon-p-sm class="paddingBottom0 hydrated">

                                        <svg width="10" height="10">
                                            <defs>
                                                <pattern id="diagonalHatch2" patternUnits="userSpaceOnUse" width="6"
                                                    height="6" patternTransform="rotate(45)">
                                                    <line x1="0" y="0" x2="0" y2="22" fill="#2E2E2E" stroke="#2E2E2E"
                                                        stroke-width="4" />
                                                </pattern>
                                            </defs>
                                            <circle r="1rem" cx="6" cy="6" width="10" height="10" stroke="#2E2E2E"
                                                fill="url(#diagonalHatch2)"></circle>
                                        </svg>&nbsp;&nbsp; </aon-p-sm>
                                </div>
                                <div class="marginTop2">
                                    <aon-p-sm>
                                        Target Not Met
                                    </aon-p-sm>
                                </div>
                            </div>
                            <!-- <aon-legend height="90%" [data]="targetKeys" [legendposition]="'vertical'"></aon-legend> -->
                        </div>
                    </div>
                    <aon-donut-chart [chartId]="chartId" [width]="width" [height]="height" [legendTrim]="legendTrim"
                        [legendtitle]="legendtitle" [legendposition]="legendPosition" [tooltip]="tooltip"
                        [showLegend]="showLegend" [middletext]="middleText" [legendsubtitle]="legendsubtitle"
                        [data]="data">
                    </aon-donut-chart>
                </div>
            </div>
        </div>
    </aon-summary-card-content>
    <aon-summary-card-footer>
        <aon-button-default (onclick)="goToLearnMore()" id="btn-retirement">Learn More</aon-button-default>
    </aon-summary-card-footer>
</aon-summary-card>