import { Component, OnInit } from '@angular/core';
import { clientChartDataByRating } from 'app/fwb/constants/pension-score-chart-data';
import { PensionService } from 'app/fwb/services/pension.service';

@Component({
  selector: 'pension-top-summary',
  templateUrl: './pension-top-summary.component.html',
  styleUrls: ['./pension-top-summary.component.scss']
})
export class PensionTopSummaryComponent implements OnInit {

  height: any = "380px";
  width: any = "98%";
  scoreCardheight: any = "540px";
  scoreCardwidth: any = "95%";
  cardheight: any = "490px";
  cardwidth: any = "98%";
  tickSize: number = 3;
  xaxistitle = "";
  charttitle = "";

  fuseConfig: any;
  navigation: any;
  //Local state 
  questionsData: any[];
  questionsDetails: any[];
  isChartVisible: boolean = true;
  contenttop: string = "70px";
  categories: any[];
  maxyaxisnumber = 1200;
  loaded = false;

  serviceData: any = null;
  isLoading = true;
  isError = false;
  errorMsg = "No Data Found.";
  constructor(
    private _pensionService: PensionService,) { }

  ngOnInit(): void {
    // this.categories = clientChartDataByRating;
    this.getData();
  }

  getData() {
    this.serviceData = null;
    this._pensionService.getScoreSummary("").toPromise().then((resp) => {
      this.serviceData = resp;
      console.log(this.serviceData);
      this.setData();
      this.errorMsg = "";
      this.isError = false;
      this.loaded = true;
    }).catch(error => {
      this.isError = true;
      this.errorMsg = error.message;
    }).finally(() => {
      this.isLoading = false;
    })
  }

  setData() {
    this.categories = this.serviceData.result;
    // this.categories = clientChartDataByRating;
  }

}
