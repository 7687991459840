export const locale = {
    lang: 'pt-br',
    data: {
        'SuperAdmin': {
            'Settings': 'CONFIGURAÇÕES',
            'HelpText': 'Accessado somente por admins.',
            'RegionalManagers': 'Gerentes Regionais',
            'Countries': 'Países',
            'Clients': 'Clientes',
            'Save': 'Salvar',
            'NewClient':'Novo Cliente',
            'NewCountry':'Novo País',
            'EnableCountry': 'Habilitar País?',
            'Name':'Nome',
            'Create': 'CRIAR',
            'ATUALIZAR': 'UPDATE',
            'Add': 'ADICIONAR',
            'Delete': 'APAGAR',
            'Cancel':'CANCELAR',
            'Users':'Usuários',
            'User':'Usuário',
            'CreateAccount': 'Criar Conta',
            'DeleteAccount': 'Apagar Conta',
            'Roles':'Papéis',
            'rmColumnDefs': [
                {
                  headerName: 'Email',
                  field: 'email',
                  sort: 'asc'
                },
                { 
                  headerName: 'Nome', 
                  field: 'NomeUsuário',
                },
                {
                    headerName: 'Data de Criação', field: 'createdOn'
                }
            ],
            'countryColumnDefs': [
                { 
                  headerName: 'Nome', 
                  field: 'name', 
                  sort: 'asc' 
                }
            ],
            'clientColumnDefs': [
                { 
                  headerName: 'Nome', 
                  field: 'name', 
                  sort: 'asc',
                  width: 500
                },
                { 
                    headerName: 'É um Grupo?', 
                    field: 'isGroup',
                    cellRenderer: params => {
                        return `<input type='checkbox' disabled readonly ${params.value ? 'checked' : ''} />`;
                    }
                },
                { 
                    headerName: 'Criado', 
                    field: 'createdOn'
                },
                { 
                    headerName: 'Selected', 
                    field: 'isSelected'
                }
            ]
        }
    }
};
