export const locale = {
    lang: 'es-pe',
    data: {
        'ExecutiveSummary': {           
            'ExecutiveSummary': 'Resumen Ejecutivo',            
            'AllAmountsDisplayedInLocalCurrency': 'Moneda Local',
            'GeneralTrend': 'Tendencia General',
            'Rolling12Basis': '12 meses móviles',
            'CurrentPMPM': 'PMPM Actual', 
            'PriorPMPM' : 'PMPM Previo', 
            'OverallTrend' : 'Tendencia General', 
            'AverageCost': 'Costo Promedio',
            'Utilization': 'Utilización',
            'ServicesReceived': 'Mix de Servicios',
            'PlanPerformance': 'Rendimiento del plan',
            'YearToDateBasis': 'Year-To-Date (Base Completa)',
            'YearToDateCBasis': 'Year-To-Date (Base Comparable)',
            'ClaimsReportedByPaidDate' :  'Siniestros informados por fecha de ocurrencia',
            'ClaimsReportedByIncurredDate' :  'Reclamaciones informadas por fecha incurrida',
            'LossRatio': 'Siniestralidad',
            'Headcount': 'NUMERO DE AFILIADOS',
            'Claims': 'ATENCIONES',
            'Premium': 'PRIMAS',            
            'CombinedLossRatio':'Siniestralidad Combinada',
            'ClaimLabel': 'ATENCIONES + GASTOS',
            'PremimumLabel':'PRIMAS + INGRESOS',
            'DateRange': 'to'

        }
    }
};
