export const locale = {
    lang: 'pt-br',
    data: {
        'UserOptions': {
            'Monthly': 'Monthly',
            'YTDFull': 'YTD Completo',
            'YTDComparable': 'YTD Comparável',
            'Rolling3Months': 'Últimos 3 meses',
            'Rolling6Months': 'Últimos 6 meses',
            'Rolling9Months': 'Últimos 9 meses',
            'Rolling12Months': 'Últimos 12 meses',
            'Current': 'Atual',
            'Current-1': 'Atual -1',
            'PaidBasis': 'Base por Data de Pagamento',
            'IncurredIncomplete': 'Incorrido e Incompleto',
            'IncurredCompleted': 'Incorrido e Concluído',
            'TimePeriod': 'Período de Tempo',
            'Basis': 'Base',
            'MaxDate': 'Max Date',
            'PlanYear': 'Ano del plan',
            'UsePriorYear': 'Use Prior Year',
            'ErrorMessagePeriod1OverlapPeriod2': 'Period 1 overlaps with period 2.',
            'ErrorMessagePeriod2OverlapPeriod3': 'Period 2 overlaps with period 3.',
            'ErrorMessagePeriod3OverlapPeriod4': 'Period 3 overlaps with period 4.',
            'ErrorMessagePeriod1Missing': 'Period 1 is missing.',
            'ErrorMessagePeriod2Missing': 'Period 2 is missing.',
            'ErrorMessagePeriod3Missing': 'Period 3 is missing.',
            'ErrorMessageMaxDateEarlierThanPeriod1': 'Max date is earlier than period 1 start date.'
        }
    }
};