import { Component, OnInit, OnDestroy, OnChanges } from "@angular/core";

@Component({
    selector: "organisational-breakdown",
    templateUrl: "./organisationalBreakdown.component.html",
    styleUrls: ["./organisationalBreakdown.component.css"],
})
export class OrganisationalBreakdownComponent implements OnInit {

    cardheight: any = "100%";
    cardwidth: any = "96%";
    titleposition = "centre";
    charttitle = "Organizational Breakdown";
    buToggleSort = -1;
    sortIcon = ['fal', 'angle-down'];
    currentColumnSort = 'buname';
    

    buTableData: any[] = [
        {buName: 'Business Unit 01', financialscore: 523},
        {buName: 'Business Unit 02', financialscore: 300},
        {buName: 'Business Unit 03', financialscore: 580},
        {buName: 'Business Unit 03 - Test', financialscore: 400},
        
        {buName: 'Business Unit 05', financialscore: 530},
        {buName: 'Business Unit 06', financialscore: 350},
        {buName: 'Business Unit 07', financialscore: 580},
        {buName: 'Business Unit 08', financialscore: 490},
        
        {buName: 'Business Unit 09', financialscore: 523},
        {buName: 'Business Unit 10', financialscore: 300},
        {buName: 'Business Unit 11', financialscore: 580},
        {buName: 'Business Unit 12', financialscore: 400},

        {buName: 'Business Unit 13', financialscore: 523},
        {buName: 'Business Unit 14', financialscore: 300},
        {buName: 'Business Unit 15', financialscore: 580},
        {buName: 'Business Unit 16', financialscore: 400},

        {buName: 'Business Unit 17', financialscore: 523},
        {buName: 'Business Unit 18', financialscore: 300},
        {buName: 'Business Unit 19', financialscore: 580},
        {buName: 'Business Unit 20', financialscore: 400}
    
      ];
    constructor( ) {}
    
    ngOnInit() { }    

    toggleSort(column: string) {
        this.buToggleSort = this.buToggleSort * -1;
        if(column == 'buname') {
            this.currentColumnSort = 'buname';
            this.buTableData.sort((a,b) => a.buName > b.buName ? this.buToggleSort : this.buToggleSort * -1)
        }
        else if (column == 'financialscore'){
            this.currentColumnSort = 'financialscore';
            this.buTableData.sort((a,b) => a.financialscore > b.financialscore ? this.buToggleSort : this.buToggleSort * -1)
        }        
            
        this.toggleSortIcon();
      }
    
      toggleSortIcon() {
        if (this.sortIcon[1] == 'angle-up') {
          this.sortIcon = ['fal', 'angle-down'];
        } 
        else this.sortIcon = ['fal', 'angle-up'];;
      }
}
