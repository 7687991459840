export const locale = {
    lang: 'es-ec',
    data: {
      'LossRatio': {
        'LossRatioHeading' : 'Siniestralidad',
        'AllAmountsDisplayedInLocalCurrency' :  'Todas las cantidades mostradas en moneda local',
        'Loss' : 'Siniestralidad',
        'LossRatioType' : 'Tipo de Siniestralidad',
        'CombinedLR' : 'Siniestralidad Combinada',
        'ClaimsTotalPMPM' : 'Reclamaciones Totales / Por Miembro por Mes',
        'ClaimsPMPM' : 'Reclamos por Miembro por Mes',
        'ClaimsTotal' : 'Total de Reclamaciones',
        'TargetLossRatio' : 'Siniestralidad Esperada',
        'ClaimsReportedByPaidDate' :  'Reclamaciones reportadas por fecha de pago',
        'ClaimsReportedByIncurredDate' :  'Reclamaciones reportadas por fecha incurrida',
        'Monthly' : 'Por Mes',
        'YearToDateBasis' : 'Año - a - fecha Base (total)',
        'YearToDateBasisC' : 'Año - a - fecha Base (C)',
        'Rolling3Basis' : '3 Meses Corridos',
        'Rolling6Basis' : '6 Meses Corridos',
        'Rolling9Basis' : '9 Meses Corridos',
        'Rolling12Basis' : '12 Meses Corridos',
        'Show':'mostrar',
         'Hide':'Esconder',
        'ShowHide' : 'Mostrar/Esconder',
        'Claims' : 'Reclamos',
        'Premium' : 'Primas',
        'Income':'Ingresos',
        'Expense':'Gastos',
        'ClaimLabel': 'Reclamos + Gastos',
        'PremimumLabel':'Primas + Ingresos',
        'MonthlyLossRatio':'Por mes de siniestralidad',
        'CumulativeLossRatio':'Acumulativa de siniestralidad',
        'DateRange': 'to'

    }
}
};
