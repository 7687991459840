<div
    id="claimOverDashboard"
    style="min-height: 795px; padding: 8px; overflow: auto; position: relative;"
    fxLayout="row"
>
    <div
        fxFlex
        fxLayout="column"
    >
        <div
            id="claimOverHeader"
            class="dash-header"
        >
            <span id="claimOverHeaderTxt">{{'ClaimsOverview.ClaimsOverview' | translate }}</span>
            <button
                id="claimOverHeaderBtn"
                mat-icon-button
                style="float: right"
                (click)="showFilter = !showFilter"
                *ngIf="!isFilterOptionsLoading"
            >
                <!-- <mat-icon aria-label="Side nav toggle icon">menu</mat-icon> -->
                <app-filter-button
                    [isClientName]='true'
                    [isEntity]='true'
                    [isPolicyType]='true'
                    [isPlan]='true'
                    [isClaimType]='true'
                    [isClaimType2]='true'
                    [isClaimType3]='true'
                    [isProviderType]='true'
                    [isGender]='true'
                    [isRelationship]='true'
                    [isAgeBand]='true'
                    [isLocation]='true'
                    [isLOB]='true'
                    [isSubLOB]='true'
                ></app-filter-button>
            </button>
        </div>
        <div fxLayout="row">
            <div
                id="claimOverHeaderCurrencyNote"
                style="font-size: 13px; font-style: italic; padding: 3px"
                fxFlex
            >
                {{ 'ClaimsOverview.AllAmountsDisplayedInLocalCurrency' | translate }}
            </div>
        </div>
        <div fxLayout="column">
            <div
                fxLayout="column"
                style="padding: 0px 5px; text-align: center"
            >
                <div
                    class="chartArea"
                    fxLayout="row"
                >
                    <div
                        class="chartArea__donut"
                        fxFlex="50%"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                    >
                        <ng-container
                            id="claimOverPriorDonut"
                            *ngIf="parentLoaded"
                        >
                            <app-donut
                                [datalist]='priorDonutData'
                                [elementRefParent]="elementRefClaim"
                                [param]="properties.dimension"
                                [suffixSymbolForTotal]="priorClaimsUnit"
                                [maxlabelLength]="'20'"
                                [isRoundOffdonutValueEnabled]="isRoundOffdonutValueEnabled"
                                [donutWidth]="550"
                                [donutHeight]="300"
                                [suffixSymbolForLabel]="'%'"
                                [totalPercent]="totalPriorPercent"
                                [propertyID]="priorDonutDataId"
                            >
                            </app-donut>
                        </ng-container>
                    </div>
                    <div
                        class="chartArea__donut"
                        fxFlex="50%"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                    >
                        <ng-container
                            id="claimOverCurrentDonut"
                            *ngIf="parentLoaded"
                        >

                            <app-donut
                                [datalist]='currentDonutData'
                                [elementRefParent]="elementRefClaim"
                                [param]="properties.dimension"
                                [suffixSymbolForTotal]="currentClaimsUnit"
                                [maxlabelLength]="'20'"
                                [isRoundOffdonutValueEnabled]="isRoundOffdonutValueEnabled"
                                [donutWidth]="550"
                                [donutHeight]="300"
                                [suffixSymbolForLabel]="'%'"
                                [totalPercent]="totalCurrentPercent"
                                [propertyID]="currentDonutDataId"
                            >
                            </app-donut>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div
            *ngIf='true'
            style="font-weight: bold; font-size:16px; margin-left:15px; margin-top:0px; border-bottom: 1px solid #222;"
        >
            <div
                id="claimOverPeriodPrior"
                fxFlex="50%"
                fxLayout="row"
                fxLayoutAlign="center left"
            >
                <div fxLayout="row"> {{priorTimePeriodLabel}} {{currentComparablePeriod}} </div>
            </div>
            <div
                id="claimOverPeriodCurrent"
                fxFlex="50%"
                fxLayout="row"
                fxLayoutAlign="center left"
            >
                <div fxLayout="row"> {{currentTimePeriodLabel}} {{priorComparablePeriod}}</div>
            </div>
        </div>
        <!-- Table to display Claims Overview -->
        <div fxLayout="row">
            <div fxFlex="50">
                <div
                    id="claimOverTablePrior"
                    [fxFlex]="99"
                    fxLayout="column"
                >
                    <div
                        id="claimOvertableHeaderPrior"
                        fxLayout="row"
                        class="tableheader"
                    >
                        <div [fxFlex]="45"></div>
                        <div [fxFlex]="25">{{'ClaimsOverview.TotalPaidClaim' | translate }}</div>
                        <div [fxFlex]="15">{{'ClaimsOverview.NOOFCLAIMS' | translate }}</div>
                        <div [fxFlex]="15">{{'ClaimsOverview.NOOFCLAIMANTS' | translate }}</div>
                    </div>
                    <div
                        fxLayout="row"
                        class="tablerow padNoneTableRow"
                        *ngFor="let xo  of priorData"
                    >
                        <div
                            class="legendElipse  legendBoxpriorDonutDataid"
                            [ngStyle]="priorData.indexOf(xo) < 10 ? {'background-color':overviewPriorLegends[priorData.indexOf(xo)]} : {'background-color': 'lightgray'}"
                        >
                            <svg
                                width="15"
                                height="15"
                            ></svg>
                        </div>
                        <div class="legendElipse  legendBoxpriorDonutDataid">
                            <svg
                                width="15"
                                height="15"
                            ></svg>
                        </div>
                        <div [fxFlex]="45">{{xo.dimension }}</div>
                        <div [fxFlex]="25">{{xo.claimsTotal | roundToDecimalPlace: 0 | numberFormat}}</div>
                        <div [fxFlex]="15">{{xo.claimsCount | roundToDecimalPlace: 0 | numberFormat}}</div>
                        <div [fxFlex]="15">{{xo.claimantCount | roundToDecimalPlace: 0 | numberFormat}}</div>
                    </div>
                    <div
                        fxLayout="row"
                        class="tablerow padNoneTableRow "
                        *ngFor="let xo  of priorDataTotal"
                    >
                        <div
                            class="legendElipse  legendBoxpriorDonutDataid"
                            [ngStyle]="priorData.indexOf(xo) < 10 ? {'background-color':overviewPriorLegends[priorData.indexOf(xo)]} : {'background-color': 'lightgray'}"
                        >
                            <svg
                                width="15"
                                height="15"
                            ></svg>
                        </div>
                        <div
                            [fxFlex]="45"
                            style="font-weight: bold;"
                        >{{'ClaimsOverview.Total' | translate}}</div>
                        <div
                            [fxFlex]="25"
                            style="font-weight: bold;"
                        >{{xo.claimsTotal | roundToDecimalPlace: 0 |
                            numberFormat}}</div>
                        <div
                            [fxFlex]="15"
                            style="font-weight: bold;"
                        >{{xo.claimsCount | roundToDecimalPlace: 0 |
                            numberFormat}}</div>
                        <div
                            [fxFlex]="15"
                            style="font-weight: bold;"
                        >{{xo.claimantCount | roundToDecimalPlace: 0 |
                            numberFormat}}</div>
                    </div>
                </div>
            </div>
            <div fxFlex="50">
                <div
                    id="claimOverTableCurrent"
                    [fxFlex]="99"
                    fxLayout="column"
                >
                    <div
                        id="claimOverTableHeaderCurrent"
                        fxLayout="row"
                        class="tableheader"
                    >
                        <div [fxFlex]="45"></div>
                        <div [fxFlex]="25">{{'ClaimsOverview.TotalPaidClaim' | translate }}</div>
                        <div [fxFlex]="15">{{'ClaimsOverview.NOOFCLAIMS' | translate }}</div>
                        <div [fxFlex]="15">{{'ClaimsOverview.NOOFCLAIMANTS' | translate }}</div>
                    </div>
                    <div
                        fxLayout="row"
                        class="tablerow padNoneTableRow"
                        *ngFor="let xo of currentData "
                    >
                        <div
                            class="legendElipse  legendBoxcurrentDonutDataid"
                            [ngStyle]="currentData.indexOf(xo) < 10 ? {'background-color':overviewCurrentLegends[currentData.indexOf(xo)]} : {'background-color': 'lightgray'}"
                        >
                            <svg
                                width="15"
                                height="15"
                            ></svg>
                        </div>
                        <div class="legendElipse  legendBoxcurrentDonutDataid">
                            <svg
                                width="15"
                                height="15"
                            ></svg>
                        </div>
                        <div [fxFlex]="45">{{xo.dimension }}</div>
                        <div [fxFlex]="25">{{xo.claimsTotal | roundToDecimalPlace: 0 | numberFormat}}</div>
                        <div [fxFlex]="15">{{xo.claimsCount | roundToDecimalPlace: 0 | numberFormat}}</div>
                        <div [fxFlex]="15">{{xo.claimantCount | roundToDecimalPlace: 0 | numberFormat}}</div>
                    </div>
                    <div
                        fxLayout="row"
                        class="tablerow padNoneTableRow"
                        *ngFor="let xo of currentDataTotal"
                    >
                        <div
                            class="legendElipse  legendBoxcurrentDonutDataid"
                            [ngStyle]="currentData.indexOf(xo) < 10 ? {'background-color':overviewCurrentLegends[currentData.indexOf(xo)]} : {'background-color': 'lightgray'}"
                        >
                            <svg
                                width="15"
                                height="15"
                            ></svg>
                        </div>
                        <div
                            [fxFlex]="45"
                            style="font-weight: bold;"
                        >{{'ClaimsOverview.Total' | translate }}</div>
                        <div
                            [fxFlex]="25"
                            style="font-weight: bold;"
                        >{{xo.claimsTotal | roundToDecimalPlace: 0 |
                            numberFormat}}</div>
                        <div
                            [fxFlex]="15"
                            style="font-weight: bold;"
                        >{{xo.claimsCount | roundToDecimalPlace: 0 |
                            numberFormat}}</div>
                        <div
                            [fxFlex]="15"
                            style="font-weight: bold;"
                        >{{xo.claimantCount | roundToDecimalPlace: 0 |
                            numberFormat}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div
            fxLayout="row"
            style="margin-top: 5px"
        >
            <div fxFlex></div>
            <div
                id="claimOverFooterNote"
                style="text-align: right; margin-right: 10px;"
            >
                {{properties.claimPaid === CLAIM_BASIS.PAID_BASIS ? ( 'ClaimsOverview.ClaimsReportedByPaidDate'
                | translate ) : (
                'ClaimsOverview.ClaimsReportedByIncurredDate' | translate )}}
            </div>
        </div>
    </div>
    <!-- FILTERS -->
    <div
        style="width: 300px; padding: 80px 0px 0px 16px; border-left: 1px solid #EEE"
        fxLayout="column"
    >
        <div
            id="entityDivisionFilters"
            *ngIf="showFilter"
        >
            <app-additional-filters
                [country]="properties.country"
                (action)="additionalFilterSelectionChanged($event)"
                [filterOptions]='filterOptions'
                [isClientName]='true'
                [isEntity]='true'
                [isPolicyType]='true'
                [isPlan]='true'
                [isClaimType]='true'
                [isClaimType2]='true'
                [isClaimType3]='true'
                [isProviderType]='true'
                [isGender]='true'
                [isRelationship]='true'
                [isAgeBand]='true'
                [isLocation]='true'
                [isLOB]='true'
                [isSubLOB]='true'
            >
            </app-additional-filters>
        </div>
        <div
            id="claimOverFilters"
            fxFlex="55"
            style="margin-top: 80px"
            *ngIf="!showFilter"
        >
            <div
                id="claimOverBtnClaimType"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.dimension == ClaimTypeOption.ClaimType}"
                (click)="selectionChanged(ClaimTypeOption.ClaimType, 'dimension')"
            >
                <span>{{'ClaimsOverview.ClaimType' | translate}}</span>
            </div>

            <div
                id="claimOverBtnRelationship"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.dimension == ClaimTypeOption.Relationship}"
                (click)="selectionChanged(ClaimTypeOption.Relationship, 'dimension')"
            >
                <span>{{'ClaimsOverview.Relationship' | translate}}</span>
            </div>

            <div
                id="claimOverBtnEntity"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.dimension == ClaimTypeOption.Entity}"
                (click)="selectionChanged(ClaimTypeOption.Entity, 'dimension')"
            >
                <span>{{'ClaimsOverview.Entity' | translate}}</span>
            </div>

            <div
                id="claimOverBtnNetwork"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.dimension == ClaimTypeOption.Network}"
                (click)="selectionChanged(ClaimTypeOption.Network, 'dimension')"
            >
                <span>{{'ClaimsOverview.Network' | translate}}</span>
            </div>

            <div
                id="claimOverBtnLocation"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.dimension == ClaimTypeOption.Location}"
                (click)="selectionChanged(ClaimTypeOption.Location, 'dimension')"
            >
                <span>{{'ClaimsOverview.Location' | translate}}</span>
            </div>
        </div>
    </div>
</div>