import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';

import { Tutorial } from 'app/models/tutorial.model';
import { User } from 'app/models/user.model';
import { tutorialReducer } from './tutorial.reducer';
import { userReducer } from './user.reducer';
import { fwbReducer } from '../fwb/reducers/fwb.reducer';
import { FWB } from 'app/fwb/models/fwb.model';

export interface State {
  readonly tutorial: Tutorial[];
  readonly user: User;
  readonly fwb: FWB;
}

export const reducers: ActionReducerMap<State> = {
  tutorial: tutorialReducer,
  user: userReducer,
  fwb: fwbReducer
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
