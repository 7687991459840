export const locale = {
    lang: 'es-co',
    data: {
        'ExportQueue': {
            'ExportQueue': 'Cola de exportación',
            'CanViewExportQueueAndStatusHere': 'Puede ver el estado y cola de exportación aquí',
            'Name': 'Nombre',
            'FileName': 'Nombre del Archivo',
            'Status': 'Estado',
            'CreatedOn': 'Creado en (Fecha)',
            'Scheduled': 'Programado',
            'Processing': 'En proceso',
            'Succeeded': 'Completado',
            'Failed': 'Ha fallado'
        }
    }
};
