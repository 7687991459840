import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as Highcharts from 'highcharts';
import { User } from 'app/models/user.model';
import { FuseConfigService } from '@fuse/services/config.service';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { navigation } from 'app/navigation/navigation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DemographicsService } from 'app/fwb/services/demographics.service';
@Component({
    selector: 'activesVsRetirees-chart',
    templateUrl: './activesVsRetirees.component.html',
    styleUrls: ['./activesVsRetirees.component.scss'],
})
export class ActivesVsRetireesChart implements OnInit {
    chartId = 'demographicsActiveRetiree';
    height: any = '320px';
    width: any = '320px';
    cardheight: any = '450px';
    cardwidth: any = '480px';
    tooltip: boolean = true;
    titleposition = 'left';
    charttitle = 'Salary Band';
    yaxistitle = '';
    color = 'white';
    maxyaxisnumber: number = 1000;
    data: any;

    fuseConfig: any;
    navigation: any;

    legendsubtitle: string = 'Total employees';
    legendTrim: number = 0;
    legendtitle = '';
    legendPosition: string = 'none';
    middleText: any = '1012';
    showLegend: boolean = true;
    isAgeBandSelected: boolean = true;
    legendData: any[];
    public user: User;
    isError = false;
    errorMsg = "No Data Found.";
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _demographicService: DemographicsService,
        private _router: Router
    ) {
        // Set the defaults
        this.navigation = navigation;
    }
    // Private
    private _unsubscribeAll: Subject<any>;
    salaryChartData: any[];
    serviceSalaryData: any = null;
    isLoading = true;
    
    ngOnInit() {  
        this.getUser();
        this.getSalaryBandData(); 
    }

    toggleHorizontalTab() {
        this.isAgeBandSelected = !this.isAgeBandSelected;
    }

    getSalaryBandData() {
        this.serviceSalaryData = null;
        this._demographicService.getSalaryBand("").toPromise().then((resp) => {
            this.serviceSalaryData = resp;
            console.log(this.serviceSalaryData);
            this.setSalaryData();
            this.errorMsg = "";
            this.isError = false;
        }).catch(error => {
            this.isError = true;
            this.errorMsg = error.message;
        }).finally(() => {
            this.isLoading = false;
        })
    }
    setSalaryData() {
        this.salaryChartData = this.serviceSalaryData.resultSalaryband;
        this.legendData = Object(this.salaryChartData);
        this.legendData.forEach((e)=>{
            e.name = e.name + " (" +e.value + "%)";
        })
    }
    getUser() {

    } 
    goToLearnMore() {
        this._router.navigate(['/fwb/demographics']);
    }
 
}
