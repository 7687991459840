import { Component, OnInit, OnDestroy, OnChanges } from "@angular/core";
import { User } from "app/models/user.model";
import { FuseConfigService } from "@fuse/services/config.service";
import { Store, select } from "@ngrx/store";
import { GridOptions } from "ag-grid-community";

import { Router } from "@angular/router";
import { navigation } from "app/navigation/navigation";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ColorRankingComponent } from "app/fwb/shared/color-ranking/color-ranking.component";
import { FwbExecutiveService } from 'app/fwb/services/fwb-executive.service';


@Component({
    selector: "benefit-score",
    templateUrl: "./benefit-score.component.html",
    styleUrls: ["./benefit-score.component.css"],
})
export class BenefitScoreComponent implements OnInit, OnChanges {
    chartId = "BenefitChartId";
    height: any = "390px";
    width: any = "700px";
    cardheight: any = "590px";
    cardwidth: any = "97%";
    tooltip: boolean = true;
    titleposition = "centre";
    charttitle = "Financial Benefit Score";
    xaxistitle = "";
    yaxistitle = "";
    emptyChartTitle = " ";
    color = "white";
    maxyaxisnumber: number = 2;
    data: any;
    fuseConfig: any;
    navigation: any;
    public user: User;
    tableData: any;
    isTableData: boolean = true;
    isHeaderVisible: boolean = false;

    gridOptions: GridOptions;
    gridApi: any;
    gridColumnApi: any;
    columnDefs = [
        {
            headerName: "Category",
            field: "name",
            pinned: "left",
            width: 275,
            cellClass: "grid-cell-left classification",
            style: "font-family:large;",
        },
        {
            headerName: "Score",
            field: "you",
            flex: 1,
            width: 230,
            pinned: "left",
            cellClass: "grid-cell-left numeral",
        },
        {
            headerName: "You",
            field: "value",
            pinned: "left",
            // minWidth: 400,
            width: 290,
            cellClass: "grid-cell-left classification",
            style: "font-family:large;",
            cellRendererFramework: ColorRankingComponent,
        }, 
    ];
    serviceData: any;
    isLoading = true;
    isError = false;
    errorMsg = "No Data Found.";
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _executiveService: FwbExecutiveService,
        private _router: Router
    ) {
        // Set the defaults
        this.navigation = navigation;
        this.gridOptions = <GridOptions>{
            context: {
                componentParent: this,
            },
            defaultColDef: {
                resizable: true,
            },
            enableSorting: true,
            // headerHeight: 0,
            suppressHorizontalScroll: true,
            enableFilter: false,
            enableColResize: true
        };

    } 
    ngOnInit() { 
        this.getUser(); 
        this.getData();
    }

    getData() {
        this.serviceData = null;
        this._executiveService.getBenefitScore("").toPromise().then((resp) => {
            this.serviceData = resp;
            console.log(this.serviceData);
            this.setData();
            this.errorMsg = "";
            this.isError = false;
        }).catch(error => {
            this.isError = true;
            this.errorMsg = error.message;
        }).finally(() => {
            this.isLoading = false;
        })
    }

    setData() { 
        this.tableData = this.serviceData.result; 
        console.log(this.tableData); 
    }
    
    ngOnChanges() { 
          this.setData(); 
    }

 
    getUser() {
        // this.store.pipe(select(getUser)).subscribe(
        //     (userGet) => {
        //         this.user = userGet;
        //         this.dashboardService.user = userGet;
        //     },
        //     (error) => console.log("Error: " + error)
        // );
    } 
    goToLearnMore() {
        this._router.navigate(["/fwb/benefitscore"]);
    } 
}
