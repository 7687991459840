export const locale = {
    lang: 'es-mx',
    data: {
        'ageBand': {
            'AgeBandHeading': 'Distribución por quinquenio',
            'Benchmark': 'Análisis comparativoa',
            'IncludeClient': 'Incluir Cliente',
            'ExcludeClient': 'Excluir Cliente',
            'Portfolio': 'Portafolio',
            'Industry': 'Industria',
            'None': 'Ninguna',
            'MemberCount': 'Total de asegurados',
            'AverageAge': 'Edad promedio',
            'AverageFamilySize': 'Tamaño medio de la familia',
            'RelationshipDistribution': 'Distribución parentesco',
            'Split': 'Distribución genero',
            'PctOfTotalClient': '% Total Cliente',
            'EnrollmentTrend': 'Tendencia de Asegurados',
            'Male': 'Masculino',
            'Female': 'Femenino',
            'PctOfPopulation': 'de población',
            'Employee': 'Asegurado',
            'Child': 'Niño',
            'Parent': 'Madre',
            'Sibling': 'Hermana',
            'Dimensions': 'Dimensión',
            'Gender': 'Género',
            'Relationship': 'Parentesco',
            'Measure': 'Medida',
            'Number': 'Numero',
            'PctDistribution': '% Total',
            'Dependent': 'Dependiente',
            'DateRange': 'to',
            'Others': 'Otros',
        }
    }
};
