import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DashboardTemplateBase } from '../dashboard-template-base.component';
import { DataService } from 'app/dashboards/data.service';
import { HttpClient } from '@angular/common/http';
import { FilterOptions } from 'app/dashboards/shared/models/FilterOptions';
import { PulseEventName } from 'app/dashboards/shared/models/PulseEventName';
import { DimensionOrderService } from 'app/dashboards/dimension-order.service';
import { locale as navigationEnglish } from 'app/dashboards/templates/co-morbidity/i18n/en';
import { locale as navigationSpanishCL } from 'app/dashboards/templates/co-morbidity/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/dashboards/templates/co-morbidity/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/dashboards/templates/co-morbidity/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/dashboards/templates/co-morbidity/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/dashboards/templates/co-morbidity/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/dashboards/templates/co-morbidity/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/dashboards/templates/co-morbidity/i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NumberFormattingService } from 'app/services/number-formatting.service';
import { formatNumber } from '@angular/common';
import { COLOR_PALETTE } from 'app/constants/color-palette';

@Component({
  selector: 'app-co-morbidity',
  templateUrl: './co-morbidity.component.html',
  styleUrls: ['./co-morbidity.component.css']
})
export class CoMorbidityComponent implements OnInit, DashboardTemplateBase {
  
  isFilterOptionsLoading = true;
  data: any;
  properties: any;
  imageUrlBase = '';
  filterOptions: FilterOptions = new FilterOptions();   
  showFilter: boolean = false; 
  @Output() action = new EventEmitter<any>();
  showPercentage: boolean = true;
  DiagnosisOptionsCo = DiagnosisOptionsCo;
  list = [{
    name: 'Heart Disease 1',
    count: '1 disease'
  }, {
    name: 'Covid-19 Disease',
    count: '1 disease'
  }, {
    name: 'Heart Disease3',
    count: '1 disease'
  }, {
    name: 'Heart Disease4',
    count: '1 disease'
  }, {
    name: 'Heart Disease',
    count: '1 disease'
  }];
  imageurl = '';
  conditionPercentage: number;
  conditionMorbiditiesPercentage: number;

  associatedCoMorbidityList: any[];

  claimantCount: number;
  genderTitle: string;
  maleLabel: string;
  femaleLabel: string;
  femalePercentage: number;
  malePercentage: number;
  averageAge: number;

  categories: any[];
  bar1Label = '';
  bar2Label = '';
  barData1 = [];
  barData2 = [];
  conditionstackBarList = [];
  conditionComorbiditiesstackBarList = [];
  legendList = [];
  parentLoaded = false;
  colorPurple = 'rgb(108, 43, 135)';
  colorBlue = 'rgb(1, 143, 179)';
  colorPurpleLight = 'rgb(165, 134, 183)';
  topBarTitle : string = '';
  bottomBarTitle: string = '';

  
  constructor(
    private _dataService: DataService, private httpClient: HttpClient,
    private _translateService: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private dimensionOrderService: DimensionOrderService, ) { }
  initData(): void{
    this.imageurl = this.imageUrlBase + 'assets/aonicons/People-Clients-M.png';
    
    this.conditionPercentage = this.data.conditionPercentage;
    this.conditionMorbiditiesPercentage = this.data.conditionMorbiditiesPercentage;

    this.claimantCount = this.data.claimantCount;
    this.femalePercentage = this.data.femalePercentage;
    this.malePercentage = this.data.malePercentage;
    this.averageAge = this.data.averageAge;

    this.associatedCoMorbidityList = this.data.associatedCoMorbidityList;

    this.genderTitle = this._translateService.instant('CoMorbidity.Gender');
    this.maleLabel = this._translateService.instant('CoMorbidity.Male');
    this.femaleLabel = this._translateService.instant('CoMorbidity.Female');

    if (this.properties.coMorbidityDiag === 'Diabetes') {
        this.bar1Label = this._translateService.instant('CoMorbidity.Diabetes');
        this.bar2Label = this._translateService.instant('CoMorbidity.Diabetes') + ' + ' + this._translateService.instant('CoMorbidity.AssociatedCo');
    }
    else if (this.properties.coMorbidityDiag === 'Hyperlipidemia') {
        this.bar1Label = this._translateService.instant('CoMorbidity.Hyperlipidemia');
        this.bar2Label = this._translateService.instant('CoMorbidity.Hyperlipidemia') + ' + ' + this._translateService.instant('CoMorbidity.AssociatedCo');
    }
    else {
        this.bar1Label = this._translateService.instant('CoMorbidity.Hypertension');
        this.bar2Label = this._translateService.instant('CoMorbidity.Hypertension') + ' + ' + this._translateService.instant('CoMorbidity.AssociatedCo');
    }
    // this.bar1Label = this.properties.coMorbidityDiag;
    // this.bar2Label = this.properties.coMorbidityDiag + ' + ' + this._translateService.instant('CoMorbidity.AssociatedCo');
    //Replace Later this._translateService.instant("CoMorbidity.AssociatedCo");
    this.categories = this.data.distributionByClaimTypesCategoryList;
    this.barData1 = this.data.distributionByClaimTypesMainValueList;
    this.barData2 = this.data.distributionByClaimTypesAllValueList;
    this.topBarTitle = '<span style="color: rgb(108, 43, 135);font-size:20px; font-weight: bold;">' + this.bar1Label + '</span>';
    this.bottomBarTitle = '<span style="color: rgb(1, 143, 179);font-size:20px; font-weight: bold;">' + this.bar2Label + '</span>';
    this.updateClaimTypeStack();
    setTimeout(() => (this.parentLoaded = true), 100);
  }

  updateClaimTypeStack(): void {
    var colors = COLOR_PALETTE;
    var barArr1 = [];
    var barArr2 = [];   
    var counter = this.data.distributionByClaimTypesMainValueWithCategoryList.length - 1;
    while (counter > -1){
      var obj = {
        name  :  this.data.distributionByClaimTypesMainValueWithCategoryList[counter].categoryName,
        value :  this.data.distributionByClaimTypesMainValueWithCategoryList[counter].amount
      };
      barArr1.push(obj);
      counter--;
    }     
      
    counter = this.data.distributionByClaimTypesAllValueWithCategoryList.length - 1;
    while (counter > -1){
      var obj = {
        name  :  this.data.distributionByClaimTypesAllValueWithCategoryList[counter].categoryName,
        value :  this.data.distributionByClaimTypesAllValueWithCategoryList[counter].amount
      };
      barArr2.push(obj);
      counter--;
    }
   var LATAM_COUNTRIES = ["Chile", "Brazil", "Colombia", "Peru", "Mexico", "Ecuador" ];

    counter = 0;
    var stackBarArr = [];
    var legendArr = [];
    this.conditionstackBarList = [];
    this.conditionComorbiditiesstackBarList = [];
    this.legendList = [];
    this.categories.forEach(row => {
      var legendObj: any = {}  ;
      var colorCode = colors[counter];
      legendObj.name = row;
      legendObj.color = colorCode;
      legendArr.push(legendObj);
      counter = counter + 1;
     });
     this.legendList = legendArr;


  if (LATAM_COUNTRIES.indexOf(this.properties.country) > -1 )
  {   
      barArr1 = barArr1.sort(function(a, b){return a.value - b.value });
      barArr2 =  barArr2.sort(function(a, b){return a.value - b.value });
  }
  

    
  barArr1.forEach(row => {
    var colorCode = this.getColorCode(row.name)
    var seriesObj = {
      type: undefined,
      name: row.name,
      data: [],
      pointWidth: 30,
      color: colorCode
    };

    seriesObj.data.push(Math.round(row.value));
    stackBarArr.push(seriesObj)
    
  });
  this.conditionstackBarList = stackBarArr;

  stackBarArr = [];
  barArr2.forEach(row => {
    var colorCode = this.getColorCode(row.name)
    var seriesObj = {
      type: undefined,
      name: row.name,
      data: [],
      pointWidth: 30,
      color: colorCode
    };

    seriesObj.data.push(Math.round(row.value));
    stackBarArr.push(seriesObj);
    
  });
  this.conditionComorbiditiesstackBarList = stackBarArr;
    
  }

  private getColorCode(categoryName: string): string
  {
    var result = '';
    for (var count = 0; count < this.legendList.length; count++)
    {
      if (this.legendList[count].name === categoryName)
      {
        result = this.legendList[count].color;
        break;
      }
    }    
    return result;
  }
 

  ngOnInit(): void {
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationSpanishCL,
      navigationSpanishCO,
      navigationSpanishEC,
      navigationSpanishMX,
      navigationSpanishPE,
      navigationPortugueseBR,
      navigationItalianIT,
    );

    if (this._translateService != null) {
      this._translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.initData();
        }
      );
    }
  }
  filterChanged(val, filterName: string): void {
    this.action.emit({
        type: PulseEventName.FILTLER_CHANGED,
        data: {
            properties: {
                name: filterName,
                value: typeof val === 'object' ? val.data.join('||') : val
            }
        }
    });
    //this.parentLoaded = false;
   // this.initData();
  }

  additionalFilterSelectionChanged(val): void {
    this.action.emit(val);
  }

}
export enum DiagnosisOptionsCo {
  Diabetes = 'DIABETES',
  Hyperlipidemia = 'HYPERLIPIDEMIA',
  Hypertension = 'HYPERTENSION'
}