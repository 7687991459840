export const locale = {
    lang: 'es-pe',
    data: {
        'DistributionByClaimType': {
            'TotalPaidClaim': 'Pago total de siniestro',
            'ClaimCount': 'Número de atenciones',
            'AverageCost': 'Costo Promedio',
             'Dimensions' : 'Dimensión',
            'AgeBand': 'Rango etario',
            'Gender': 'Género',
            'Relationship': 'Parentesco',
            'AllAmountsDisplayedInLocalCurrency': 'Moneda local',
            'ClaimsReportedByPaidDate': 'Siniestros informados por fecha de ocurrencia',
            'ClaimsReportedByIncurredDate' :  'Reclamaciones informadas por fecha incurrida',
            'ClaimTypeBy': 'Tipo de atención por',
            'DateRange': 'to'
          }
    }
};