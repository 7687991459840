import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stepupchallenge',
  templateUrl: './stepupchallenge.component.html',
  styleUrls: ['./stepupchallenge.component.css']
})
export class StepupchallengeComponent implements OnInit {
    isViewCompletionRatesEnabled = false;
    selectedBusinessUnitLabel = '';
    selectedAgeGroupLabel = '';
    selectedBusinessUnit: any = null;
    selectedAgeGroup: any = null;

    keyMetricsChartData: any = [
        {
          "name": "Completion Rate",
          "value": 72,
          "colorindex": 5
        },
        {
          "name": "Incompletion Rate",
          "value": 38,
          "colorindex": 2
        }
    ];

    keyMetricsTableData: any = [
        { category: 'Eligible Participants', quantity: 1450 },
        { category: 'Challenge Participants', quantity: 745 },
        { category: 'Completed Challenges', quantity: 566 }
    ];

    healthScorePerformanceTableData: any = [
        { icon: ['fal', 'wave-triangle'], category: 'Average', score: 483, performance: 1.9 },
        { icon: ['fal', 'apple-alt'], category: 'Lifestyle', score: 498, performance: 2.3 },
        { icon: ['fal', 'running'], category: 'Body', score: 504, performance: 2.1 },
        { icon: ['fal', 'head-side-brain'], category: 'Mind', score: 384, performance: 0.2 }
    ];

    participationBreakdownData: any = {
        xCategories: ['0-29', '30-39', '40-54', '55+'],
        yCategories: ['Average', 'Business Unit 1', 'Business Unit 2', 'Business Unit 3', 'Business Unit 4'],
        data: {
            'Average': {
                selected: false,
                highlight: true,
                data: {
                    '0-29': {
                        'selected': false,

                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    },
                    '30-39': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    },
                    '40-54': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    },
                    '55+': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    }
                }
            },
            'Business Unit 1': {
                selected: false,
                highlight: false,
                data: {
                    '0-29': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    },
                    '30-39': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    },
                    '40-54': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    },
                    '55+': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    }
                }
            },
            'Business Unit 2': {
                selected: false,
                highlight: false,
                data: {
                    '0-29': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    },
                    '30-39': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    },
                    '40-54': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    },
                    '55+': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    }
                }
            },
            'Business Unit 3': {
                selected: false,
                highlight: false,
                data: {
                    '0-29': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    },
                    '30-39': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    },
                    '40-54': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    },
                    '55+': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    }
                }
            },
            'Business Unit 4': {
                selected: false,
                highlight: false,
                data: {
                    '0-29': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    },
                    '30-39': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    },
                    '40-54': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    },
                    '55+': {
                        'selected': false,
                        
                        'participationPercentage': 50,

                        'totalUsers': 96,
                        'challengeParticipants': 41,
                        'completedChallenges': 32,
                        'completionRate': 78,

                        'averageScore': 544,
                        'averageScorePercentage': 1.9,
                        'averageScoreFromDate': '12/02',

                        'lifestyleScore': 544,
                        'lifestyleScorePercentage': 1.9,
                        'lifestyleScoreFromDate': '12/02',

                        'bodyScore': 544,
                        'bodyScorePercentage': 1.9,
                        'bodyScoreFromDate': '12/02',

                        'mindScore': 544,
                        'mindScorePercentage': 1.9,
                        'mindScoreFromDate': '12/02'
                    }
                }
            }
        }
    };

    toggleSelection(selectedBusinessUnit: any): void {
        this.selectedBusinessUnitLabel = '';
        this.selectedBusinessUnit = null;
        this.selectedAgeGroupLabel = '';
        this.selectedAgeGroup = null;
        this.toggleBusinessUnitSelection(selectedBusinessUnit.selected ? null : selectedBusinessUnit);
    }

    goToPrevAgeGroup(): void {
        const curAgeGroupIndex = this.participationBreakdownData.xCategories.indexOf(this.selectedAgeGroupLabel);

        if (curAgeGroupIndex === 0) {
            this.toggleAgeGroupSelection(this.selectedBusinessUnit, 
                this.participationBreakdownData
                    .data[this.selectedBusinessUnitLabel]
                    .data[this.participationBreakdownData.xCategories[this.participationBreakdownData.xCategories.length - 1]]);
            return;
        }

        this.toggleAgeGroupSelection(this.selectedBusinessUnit, 
            this.participationBreakdownData
                .data[this.selectedBusinessUnitLabel]
                .data[this.participationBreakdownData.xCategories[curAgeGroupIndex - 1]]);
    }

    goToNextAgeGroup(): void {
        const curAgeGroupIndex = this.participationBreakdownData.xCategories.indexOf(this.selectedAgeGroupLabel);

        if (curAgeGroupIndex === this.participationBreakdownData.xCategories.length - 1) {
            this.toggleAgeGroupSelection(this.selectedBusinessUnit, 
                this.participationBreakdownData
                    .data[this.selectedBusinessUnitLabel]
                    .data[this.participationBreakdownData.xCategories[0]]);
            return;
        }

        this.toggleAgeGroupSelection(this.selectedBusinessUnit, 
            this.participationBreakdownData
                .data[this.selectedBusinessUnitLabel]
                .data[this.participationBreakdownData.xCategories[curAgeGroupIndex + 1]]);
    }

    toggleBusinessUnitSelection(selectedBusinessUnit: any): void {
        for (let i = 0; i < this.participationBreakdownData.yCategories.length; i++) {
            const curBusinessUnit = this.participationBreakdownData.data[this.participationBreakdownData.yCategories[i]];
            curBusinessUnit.selected = selectedBusinessUnit === curBusinessUnit;

            if (curBusinessUnit.selected) {
                this.selectedBusinessUnitLabel = this.participationBreakdownData.yCategories[i];
                this.selectedBusinessUnit = curBusinessUnit;
            }

             this.toggleAgeGroupSelection(curBusinessUnit, curBusinessUnit.selected ? curBusinessUnit.data[this.participationBreakdownData.xCategories[0]] : null);
        }
    }

    toggleAgeGroupSelection(selectedBusinessUnit: any, selectedAgeGroup: any): void {
        for (let i = 0; i < this.participationBreakdownData.xCategories.length; i++) {
            const curAgeGroup = selectedBusinessUnit.data[this.participationBreakdownData.xCategories[i]];
            curAgeGroup.selected = selectedAgeGroup === curAgeGroup;

            if (curAgeGroup.selected) {
                this.selectedAgeGroupLabel = this.participationBreakdownData.xCategories[i];
                this.selectedAgeGroup = curAgeGroup;
            }
        }
    }

    toggleViewCompletionRatesEnabled(): void {
        this.isViewCompletionRatesEnabled = !this.isViewCompletionRatesEnabled;
    }

    constructor() { }

    ngOnInit() {
    }

}
