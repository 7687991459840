<h3 style="cursor: pointer; color: blue" (click)="reset()">
    <span class="material-icons">undo</span> Reset
</h3>

<span *ngIf="filterOptions === null"><b>Loading... Please wait...</b></span>

<pulse-select
    *ngIf="isClientName && filterOptions != null"
    [selection]="aClientNames"
    placeholder="{{ 'AddFilter.ClientName' | translate }}"
    [options]="filterOptions.clientNames"
    (selectionChanged)="selectionChanged($event, 'aClientNames')"
></pulse-select>
<br *ngIf="isClientName && filterOptions != null" /><br
    *ngIf="isClientName && filterOptions != null"
/>

<pulse-select
    *ngIf="isEntity && filterOptions != null"
    [selection]="aEntities"
    placeholder="{{ 'AddFilter.Entity' | translate }}"
    [options]="filterOptions.entities"
    (selectionChanged)="selectionChanged($event, 'aEntities')"
></pulse-select>
<br *ngIf="isEntity && filterOptions != null" /><br
    *ngIf="isEntity && filterOptions != null"
/>

<pulse-select
    *ngIf="isDivison && filterOptions != null"
    [selection]="aDivisions"
    placeholder="{{ 'AddFilter.Division' | translate }}"
    [options]="filterOptions.divisions"
    (selectionChanged)="selectionChanged($event, 'aDivisions')"
></pulse-select>
<br *ngIf="isDivison && filterOptions != null" /><br
    *ngIf="isDivison && filterOptions != null"
/>

<pulse-select
    *ngIf="isPlan && filterOptions != null"
    [selection]="aPlans"
    placeholder="{{ 'AddFilter.Plan' | translate }}"
    [options]="filterOptions.plans"
    (selectionChanged)="selectionChanged($event, 'aPlans')"
></pulse-select>
<br *ngIf="isPlan && filterOptions != null" /><br
    *ngIf="isPlan && filterOptions != null"
/>

<pulse-select
    *ngIf="isTimePeriod && filterOptions != null"
    tooltip='To change the options, go to the "Select Options" menu and change "Time Period".'
    [selection]="aTimePeriods"
    placeholder="{{ 'AddFilter.TimePeriod' | translate }}"
    [options]="filterOptions.timePeriods"
    (selectionChanged)="selectionChanged($event, 'aTimePeriods')"
></pulse-select>
<br *ngIf="isTimePeriod && filterOptions != null" /><br
    *ngIf="isTimePeriod && filterOptions != null"
/>

<pulse-select
    *ngIf="isTimePeriodLossRatio && filterOptions != null"
    tooltip='To change the options, go to the "Select Options" menu and change "Time Period".'
    [selection]="aTimePeriodsLossRatio"
    placeholder="{{ 'AddFilter.TimePeriod' | translate }}"
    [options]="filterOptions.timePeriodsLossRatio"
    (selectionChanged)="selectionChanged($event, 'aTimePeriodsLossRatio')"
></pulse-select>
<br *ngIf="isTimePeriodLossRatio && filterOptions != null" /><br
    *ngIf="isTimePeriodLossRatio && filterOptions != null"
/>

<pulse-select
    *ngIf="isTimePeriodEnrollment && filterOptions != null"
    tooltip='To change the options, go to the "Select Options" menu and change "Time Period".'
    [selection]="aTimePeriodsEnrollment"
    placeholder="{{ 'AddFilter.TimePeriod' | translate }}"
    [options]="filterOptions.timePeriodsEnrollment"
    (selectionChanged)="selectionChanged($event, 'aTimePeriodsEnrollment')"
></pulse-select>
<br *ngIf="isTimePeriodEnrollment && filterOptions != null" /><br
    *ngIf="isTimePeriodEnrollment && filterOptions != null"
/>

<pulse-select
    #policyType
    *ngIf="isPolicyType && filterOptions != null"
    [selection]="aPolicyTypes"
    placeholder="{{ 'AddFilter.PolicyType' | translate }}"
    [options]="filterOptions.policyTypes"
    (selectionChanged)="selectionChanged($event, 'aPolicyTypes')"
></pulse-select>
<br *ngIf="isPolicyType && filterOptions != null" /><br
    *ngIf="isPolicyType && filterOptions != null"
/>

<pulse-select
    *ngIf="isAgeBand && filterOptions != null"
    [selection]="aAgeBands"
    placeholder="{{ 'AddFilter.AgeBand' | translate }}"
    [options]="filterOptions.ageBands"
    (selectionChanged)="selectionChanged($event, 'aAgeBands')"
></pulse-select>
<br *ngIf="isAgeBand && filterOptions != null" /><br
    *ngIf="isAgeBand && filterOptions != null"
/>

<pulse-select
    *ngIf="isGender && filterOptions != null"
    [selection]="aGenders"
    placeholder="{{ 'AddFilter.Gender' | translate }}"
    [options]="filterOptions.genders"
    (selectionChanged)="selectionChanged($event, 'aGenders')"
></pulse-select>
<br *ngIf="isGender && filterOptions != null" /><br
    *ngIf="isGender && filterOptions != null"
/>

<pulse-select
    *ngIf="isClaimType && filterOptions != null"
    [selection]="aClaimTypes"
    placeholder="{{ 'AddFilter.ClaimType' | translate }}"
    [options]="filterOptions.claimTypes"
    (selectionChanged)="selectionChanged($event, 'aClaimTypes')"
></pulse-select>
<br *ngIf="isClaimType && filterOptions != null" /><br
    *ngIf="isClaimType && filterOptions != null"
/>

<pulse-select
    *ngIf="isClaimType2 && filterOptions != null"
    [selection]="aClaimTypes2"
    placeholder="{{ 'AddFilter.ClaimType2' | translate }}"
    [options]="filterOptions.claimTypes2"
    (selectionChanged)="selectionChanged($event, 'aClaimTypes2')"
></pulse-select>
<br *ngIf="isClaimType2 && filterOptions != null" /><br
    *ngIf="isClaimType2 && filterOptions != null"
/>

<pulse-select
    *ngIf="isClaimType3 && filterOptions != null"
    [selection]="aClaimTypes3"
    placeholder="{{ 'AddFilter.ClaimType3' | translate }}"
    [options]="filterOptions.claimTypes3"
    (selectionChanged)="selectionChanged($event, 'aClaimTypes3')"
></pulse-select>
<br *ngIf="isClaimType3 && filterOptions != null" /><br
    *ngIf="isClaimType3 && filterOptions != null"
/>

<pulse-select
    *ngIf="isRelationship && filterOptions != null"
    [selection]="aRelationships"
    placeholder="{{ 'AddFilter.Relationship' | translate }}"
    [options]="filterOptions.relationships"
    (selectionChanged)="selectionChanged($event, 'aRelationships')"
></pulse-select>
<br *ngIf="isRelationship && filterOptions != null" /><br
    *ngIf="isRelationship && filterOptions != null"
/>

<pulse-select
    *ngIf="isDiagCat && filterOptions != null"
    [selection]="aDiagCats"
    placeholder="{{ 'AddFilter.DiagnosisCategory' | translate }}"
    [options]="filterOptions.diagCats"
    (selectionChanged)="selectionChanged($event, 'aDiagCats')"
></pulse-select>
<br *ngIf="isDiagCat && filterOptions != null" /><br
    *ngIf="isDiagCat && filterOptions != null"
/>

<pulse-select
    *ngIf="isDiagChapter && filterOptions != null"
    [selection]="aDiagChapters"
    placeholder="{{ 'AddFilter.DiagnosisChapter' | translate }}"
    [options]="filterOptions.diagChapters"
    (selectionChanged)="selectionChanged($event, 'aDiagChapters')"
></pulse-select>
<br *ngIf="isDiagChapter && filterOptions != null" /><br
    *ngIf="isDiagChapter && filterOptions != null"
/>

<pulse-select
    *ngIf="isLocation && filterOptions != null"
    [selection]="aLocations"
    placeholder="{{ 'AddFilter.Location' | translate }}"
    [options]="filterOptions.locations"
    (selectionChanged)="selectionChanged($event, 'aLocations')"
></pulse-select>
<br *ngIf="isLocation && filterOptions != null" /><br
    *ngIf="isLocation && filterOptions != null"
/>

<pulse-select
    *ngIf="isCarrier && filterOptions != null"
    [selection]="aCarriers"
    placeholder="{{ 'AddFilter.Carrier' | translate }}"
    [options]="filterOptions.carriers"
    (selectionChanged)="selectionChanged($event, 'aCarriers')"
></pulse-select>
<br *ngIf="isCarrier && filterOptions != null" /><br
    *ngIf="isCarrier && filterOptions != null"
/>

<pulse-select
    *ngIf="isLOB && filterOptions != null"
    [selection]="aLobs"
    placeholder="{{ 'AddFilter.LOB' | translate }}"
    [options]="filterOptions.lobs"
    (selectionChanged)="selectionChanged($event, 'aLobs')"
></pulse-select>
<br *ngIf="isLOB && filterOptions != null" /><br
    *ngIf="isLOB && filterOptions != null"
/>

<pulse-select
    *ngIf="isSubLOB && filterOptions != null"
    [selection]="aSubLobs"
    placeholder="{{ 'AddFilter.SubLOB' | translate }}"
    [options]="filterOptions.subLobs"
    (selectionChanged)="selectionChanged($event, 'aSubLobs')"
></pulse-select>
<br *ngIf="isSubLOB && filterOptions != null" /><br
    *ngIf="isSubLOB && filterOptions != null"
/>

<pulse-select
    *ngIf="isNetwork && filterOptions != null"
    [selection]="aNetworks"
    placeholder="{{ 'AddFilter.Network' | translate }}"
    [options]="filterOptions.networks"
    (selectionChanged)="selectionChanged($event, 'aNetworks')"
></pulse-select>
<br *ngIf="isNetwork && filterOptions != null" /><br
    *ngIf="isNetwork && filterOptions != null"
/>

<pulse-select
    *ngIf="isProviderName && filterOptions != null"
    [selection]="aProviderNames"
    placeholder="{{ 'AddFilter.ProviderName' | translate }}"
    [options]="filterOptions.providerNames"
    (selectionChanged)="selectionChanged($event, 'aProviderNames')"
></pulse-select>
<br *ngIf="isProviderName && filterOptions != null" /><br
    *ngIf="isProviderName && filterOptions != null"
/>

<pulse-select
    *ngIf="isProviderLocation && filterOptions != null"
    [selection]="aProviderLocations"
    placeholder="{{ 'AddFilter.ProviderLocation' | translate }}"
    [options]="filterOptions.providerLocations"
    (selectionChanged)="selectionChanged($event, 'aProviderLocations')"
></pulse-select>
<br *ngIf="isProviderLocation && filterOptions != null" /><br
    *ngIf="isProviderLocation && filterOptions != null"
/>

<pulse-select
    *ngIf="isProviderType && filterOptions != null"
    [selection]="aProviderTypes"
    placeholder="{{ 'AddFilter.ProviderType' | translate }}"
    [options]="filterOptions.providerTypes"
    (selectionChanged)="selectionChanged($event, 'aProviderTypes')"
></pulse-select>
<br *ngIf="isProviderType && filterOptions != null" /><br
    *ngIf="isProviderType && filterOptions != null"
/>
