<div id="svg-spinner-container" style="padding: 4px; border-radius: 1px; background: rgba(255, 255, 255, 0.6); z-index: 90">
  <svg id="svg-spinner" viewBox="0 0 50 50">
    <style>
      #tail {
        fill: url(#fade)
      }

      #head {
        fill: #616570
      }

      stop {
        stop-color: #616570
      }
    </style>
    <linearGradient id="fade" x2="50" y1="25" y2="25" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-opacity="0">
      </stop>
      <stop offset=".15" stop-opacity=".04"></stop>
      <stop offset=".3" stop-opacity=".16"></stop>
      <stop offset=".45" stop-opacity=".36"></stop>
      <stop offset=".61" stop-opacity=".64"></stop>
      <stop offset=".76"></stop>
    </linearGradient>
    <path id="head" d="M0 25a25 25 0 1 0 50 0h-3.9a21.1 21.1 0 1 1-42.2 0"></path>
    <path id="tail" d="M50 25a25 25 0 0 0-50 0h3.9a21.1 21.1 0 1 1 42.2 0"></path>
  </svg>
</div>