import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BenefitService {

  constructor(private httpClient: HttpClient) { }

  getScoreCategories(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/benefitscore/score-categories?'+params)
  }

  getScoreQuestions(params)
  {
    return this.httpClient.get<any>(environment.api + 'api/benefitscore/score-questions?'+params)
  }

 
}
