import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FlexLayoutModule } from "@angular/flex-layout";
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { JwtInterceptor } from '../_helpers/jwt.interceptor';

import { TranslateModule } from '@ngx-translate/core';

import { ExportcartComponent, ExportCartDashboardDialog } from './exportcart/exportcart.component';
import { ExportqueueComponent } from './exportqueue/exportqueue.component';
import { SharedModule } from 'app/shared/shared.module';
import { DashboardsModule } from 'app/dashboards/dashboards.module';
import { AonLayoutComponent } from 'app/layout/aon-layout/aon-layout.component';
import { environment } from 'environments/environment';

const routes = [
  {
    path: environment.profile.project.title === 'Pulse' ? 'new-layout' : 'newlayout-wellone',     
    children: [
      {
        path     : 'export/cart',
        component: ExportcartComponent
    },
    {
        path     : 'export/queue',
        component: ExportqueueComponent
    }
      
    ]
  },
  {
      path     : 'export/cart',
      component: ExportcartComponent
  },
  {
      path     : 'export/queue',
      component: ExportqueueComponent
  }
];

@NgModule({
  declarations: [
    ExportcartComponent,
    ExportqueueComponent,
    ExportCartDashboardDialog
  ],
  imports: [
    SharedModule,
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardsModule,
    RouterModule.forChild(routes),
    TranslateModule,
    MatMomentDateModule
  ],
  entryComponents: [
    ExportCartDashboardDialog
  ],
  exports: [RouterModule],
  providers: [
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ]
})
export class ExportModule { }
