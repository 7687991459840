import { Injectable, EventEmitter, Output, Directive } from '@angular/core';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class AdditionalFiltersService {

    constructor() { }

    @Output() updateAdditionalFilters: EventEmitter<any> = new EventEmitter();

    selectionStore = 'additionalFilterSelectionStore';
    optionStore = 'additionalFilterOptionStore';

    set(selectionsToBeStored: any): void {
        sessionStorage.setItem(this.selectionStore, JSON.stringify(selectionsToBeStored));
        this.updateAdditionalFilters.emit(selectionsToBeStored);
    }

    get(): any {
        const filter = sessionStorage.getItem(this.selectionStore);

        if (typeof filter === 'undefined') {
            return {};
        }

        if (filter === null) {
            return {};
        }

        return JSON.parse(filter);
    }

    setInitialOptions(optionsToBeStored: any): void {
        sessionStorage.setItem(this.optionStore, JSON.stringify(optionsToBeStored));
    }

    getInitialOptions(): any {
        const filter =  sessionStorage.getItem(this.optionStore);

        if (typeof filter === 'undefined') {
            return {};
        }

        if (filter === null) {
            return {};
        }

        return JSON.parse(filter);
    }

    clear(): void {
        sessionStorage.setItem(this.selectionStore, JSON.stringify({}));
        sessionStorage.setItem(this.optionStore, JSON.stringify({}));
    }

    getNoOfFiltersUsed(isClientName: boolean = false, isEntity: boolean = false, isDivison: boolean = false, isPlan: boolean = false, 
        isTimePeriod: boolean = false, isTimePeriodLossRatio: boolean = false, isTimePeriodEnrollment: boolean = false,
        isPolicyType: boolean = false, isAgeBand: boolean = false, isGender: boolean = false, isClaimType: boolean = false,
        isClaimType2: boolean = false, isClaimType3: boolean = false, isRelationship: boolean = false, isDiagCat: boolean = false,
        isDiagChapter: boolean = false, isLocation: boolean = false, isCarrier: boolean = false, isLOB: boolean = false,
        isSubLOB: boolean = false, isNetwork: boolean = false, isProviderName: boolean = false, isProviderLocation: boolean = false, 
        isProviderType: boolean = false): number {
            let noOfFiltersUsed = 0;
            const filterObj = this.get();

            if (isClientName && typeof filterObj['aClientNames'] !== 'undefined') {
                if (filterObj['aClientNames'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isEntity && typeof filterObj['aEntities'] !== 'undefined') {
                if (filterObj['aEntities'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isDivison && typeof filterObj['aDivisions'] !== 'undefined') {
                if (filterObj['aEntities'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isPlan && typeof filterObj['aPlans'] !== 'undefined') {
                if (filterObj['aPlans'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isTimePeriod && typeof filterObj['aTimePeriods'] !== 'undefined') {
                if (filterObj['aTimePeriods'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isTimePeriodLossRatio && typeof filterObj['aTimePeriodsLossRatio'] !== 'undefined') {
                if (filterObj['aTimePeriodsLossRatio'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isTimePeriodEnrollment && typeof filterObj['aTimePeriodsEnrollment'] !== 'undefined') {
                if (filterObj['aTimePeriodsEnrollment'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isPolicyType && typeof filterObj['aPolicyTypes'] !== 'undefined') {
                if (filterObj['aPolicyTypes'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isAgeBand && typeof filterObj['aAgeBands'] !== 'undefined') {
                if (filterObj['aAgeBands'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isGender && typeof filterObj['aGenders'] !== 'undefined') {
                if (filterObj['aGenders'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }
           
            if (isClaimType && typeof filterObj['aClaimTypes'] !== 'undefined') {
                if (filterObj['aClaimTypes'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isClaimType2 && typeof filterObj['aClaimTypes2'] !== 'undefined') {
                if (filterObj['aClaimTypes2'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isClaimType3 && typeof filterObj['aClaimTypes3'] !== 'undefined') {
                if (filterObj['aClaimTypes3'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isRelationship && typeof filterObj['aRelationships'] !== 'undefined') {
                if (filterObj['aRelationships'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isDiagCat && typeof filterObj['aDiagCats'] !== 'undefined') {
                if (filterObj['aDiagCats'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isDiagChapter && typeof filterObj['aDiagChapters'] !== 'undefined') {
                if (filterObj['aDiagChapters'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isLocation && typeof filterObj['aLocations'] !== 'undefined') {
                if (filterObj['aLocations'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }


            if (isCarrier && typeof filterObj['aCarriers'] !== 'undefined') {
                if (filterObj['aCarriers'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isLOB && typeof filterObj['aLobs'] !== 'undefined') {
                if (filterObj['aLobs'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isSubLOB && typeof filterObj['aSubLobs'] !== 'undefined') {
                if (filterObj['aSubLobs'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isNetwork && typeof filterObj['aNetworks'] !== 'undefined') {
                if (filterObj['aNetworks'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isProviderName && typeof filterObj['aProviderNames'] !== 'undefined') {
                if (filterObj['aProviderNames'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isProviderLocation && typeof filterObj['aProviderLocations'] !== 'undefined') {
                if (filterObj['aProviderLocations'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            if (isProviderType && typeof filterObj['aProviderTypes'] !== 'undefined') {
                if (filterObj['aProviderTypes'].indexOf('ALL') < 0) {
                    noOfFiltersUsed++;
                }
            }

            return noOfFiltersUsed;
    }

    retrievePolicyTypeChileSelections(): string[] {
        const filterOptions = this.getInitialOptions();

        const defaultSelections = ['SALUD', 'HEALTH', 'DENTAL'];
        const chileSelections = [];

        for (let i = 0; i < filterOptions.policyTypes.length; i++) {
            if (defaultSelections.indexOf(filterOptions.policyTypes[i].trim().toUpperCase()) >= 0) {
                chileSelections.push(filterOptions.policyTypes[i]);
            }
        }

        return chileSelections;
    }

    retrieveAllPolicyTypeChileSelections(): string[] {
        const filterOptions = this.getInitialOptions();

        const chileSelections = [];

        chileSelections.push('ALL');
        for (let i = 0; i < filterOptions.policyTypes.length; i++) {
            chileSelections.push(filterOptions.policyTypes[i]);
        }

        return chileSelections;
    }
}
