import { Component, OnInit } from '@angular/core';
import { TableauService } from '../../services/tableau.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ActivatedRoute } from "@angular/router";
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { environment } from '../../../environments/environment';
@Component({
    selector: 'home',
    templateUrl: './sample.component.html',
    styleUrls: ['./sample.component.scss']
})
export class SampleComponent implements OnInit {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private tableauService: TableauService,
        private route: ActivatedRoute
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
    }


    //Any tableau url related, addons or site names
    tableauAddOnsPath: string;
    url: string;

    //For Admin, this is the dashboard list, being displayed.
    dashId: any;
    dashIndex = 0;
    dashboardList = [];

    //This array contains list of dashboards from Tabvleau API
    storyPreferences = [];
    library = [];

    //This variable should help for navigation and page buttons.
    currentDashboard: number;
    isAdminMode: boolean;
    title: string;

    //To Save all the user clicks in this model.
    //There should only be one object that user and dashboards should refer to.
    pulseUserSessionModel: [];
    ngOnInit() {
        this.isAdminMode = false;
        this.tableauAddOnsPath = "&:embed=y&:showVizHome=n&:tabs=n&:toolbar=y&:apiID=handler0";
        this.currentDashboard = 0;


        this.tableauService.getLibrary().toPromise().then((res: any) => {
            console.log(res.data);
            this.library = res.data;
            
            this.route.paramMap.subscribe(params => {
                this.dashId = params.get("storyId")
                this.addDashboard(this.dashId)
            });

        }).catch(error => {
            console.log(error);
        });
    }

    //Here we are changing the layout for an admin or a user 
    changePreviewMode() {
        this.isAdminMode = !this.isAdminMode;
        if(this.isAdminMode) {
            this.dashboardList = [];
            this.dashIndex = 0;
        }
    }

    //Here we are changing the layout for an admin or a user 
    changeToExportMode() {
        this.isAdminMode = false;
    }


    //When an user clicks on a dashboard, the list gets reset  and then appended with the new dashboard item. 
    
    addDashboard(dashId) {

        this.tableauService.getToken().toPromise().then((res: any) => {
            //Library contains dashboard details from Tableau API
            var dash = this.library[dashId];
            console.log(dash, res.data.ticket);
            var urlPath = environment.tableau + res + environment.sitePath + dash.contentUrl + this.tableauAddOnsPath;
            // if(this.isAdminMode)
            // {
            this.dashboardList.push(
                {
                    story: dash,
                    url: urlPath
                }
            );
            // } 

        }).catch(error => {
            console.log(error);
        });
    }

    //Displays the export queue.
    export() {
        //Page should display a notification

        //Export Queue to show

        //Export date and queue to be formed.

    };

    save() {

    };

    previous() {
        this.dashIndex--;
    }
    forward() {
        this.dashIndex++;
    }
}