import { Component, Input, OnChanges, OnInit, OnDestroy, SimpleChanges } from "@angular/core";
import { Retirement_RatioByCategories } from 'app/fwb/constants/retirement-chart-data';
import { FuseConfigService } from '@fuse/services/config.service';
import { Store } from '@ngrx/store';
import { State } from 'app/_reducers';
import { Router } from '@angular/router';
import { FWBService } from 'app/fwb/services/fwb.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VizColorConstants } from 'app/fwb/constants/fwb-charts-data';
import { RetirementService } from 'app/fwb/services/retirement.service';
@Component({
  selector: 'retirement-replacement',
  templateUrl: './retirement-replacement.component.html',
  styleUrls: ['./retirement-replacement.component.scss']
})
export class RetirementReplacementComponent implements OnInit, OnChanges {
  retirementbycategories: any;
  view: any[] = [1300, 350];
  title: string = "";

  keys: any[] = [];
  legendkeys: any[] = [];
  ratiokeys: any[] = [];
  legendtitle: [];
  contentwidth: any = "97%";
  cardheight: any = "575px";
  height: any = "475px";
  width: any = "98%";
  legendposition = "none";
  cardwidth: any = "97%";
  tooltip: boolean = true;
  resize: boolean = true;
  titleposition = "left";
  charttitle = "Target Replacement Ratio";
  yaxistitle = "";
  color = "white";
  maxyaxisnumber: number = 1;
  data: any;
  fuseConfig: any;
  navigation: any;
  emptyChartTitle = " ";
  public multi: any[] = [];
  public categorycolors: any[] = [0, 2, 4];
  chartId: string = "aonTargetReplacementRatio" + new Date().getUTCMilliseconds();
  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = "";
  showYAxisLabel: boolean = true;
  barPadding = 10;
  yAxisLabel: string = "";
  tickformat: string = ".0%";
  scaleMax = 1;
  yScaleMax = 1;
  showDataLabel = true;
  subcategorynames: any[] = [];
  private _unsubscribeAll: Subject<any>;
  currentScenarioNumber: number = 1;
  serviceData: any = null;
  isLoading = true;
  isError = false;
  errorMsg = "No Data Found.";
  loaded = false;
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fwbService: FWBService,
    private _retirementService: RetirementService,
    private store: Store<State>,
    private _router: Router) {

    this.store.select('fwb').forEach(res => {
      if (res == undefined) {
        console.log("this.fwb is undefined");
      } else {
        this.currentScenarioNumber = isNaN(res.replacementscenario) ? 3 : (res.replacementscenario);
        if (this.serviceData !== null) { this.setData(); }
      }
    });
  }

  updateScenario() {
    this._fwbService.updateLifestyleScenario(this.currentScenarioNumber);
  }
  

  public ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.setData();
    }
  }

  ngOnInit() {
    // if (this.serviceData === null) {
    this.getData();
    // }
  }

  getData() {
    this.serviceData = null;
    this._retirementService.getReplacement("").toPromise().then((resp) => {
      this.serviceData = resp;
      console.log(this.serviceData);
      this.setData();
      this.errorMsg = "";
      this.isError = false;
      this.loaded = true;
  }).catch(error => {
      this.isError = true;
      this.errorMsg = error.message;
  }).finally(() => {
      this.isLoading = false;
    })
  }

  setData() {
    this.retirementbycategories = this.serviceData.result;

    // this.retirementbycategories = Retirement_RatioByCategories;  
    this.retirementbycategories.forEach((a) => {
      a.values.forEach(b => {
        if (a.category == "Scenario " + this.currentScenarioNumber) {
          b.striped = true;
        } else {
          b.striped = false;
        }
      });
    });
    this.subcategorynames = [];

    this.setCategoryColor();
    this.chartId = "aonTargetReplacementRatio" + new Date().getUTCMilliseconds();
    this.keys = [];
    this.legendkeys = [];
    this.retirementbycategories[0].values.forEach(a => {
      this.keys.push(a.key);
      if (!a.striped) { this.legendkeys.push(a); }
    })
    this.scaleMax = 1;
    // console.log(this.retirementbycategories);
  }
  private setCategoryColor() {
    if (this.subcategorynames.length === 0) {
      this.subcategorynames = [];
      var subCatCounter = 0;
      for (var count = 0; count < this.retirementbycategories[0].values.length; count++) {
        for (var cc = 0; cc < this.retirementbycategories.length; cc++) {
          var rowCategory = this.retirementbycategories[cc].category;
          var row = this.retirementbycategories[cc].values[count];
          if (row === undefined || row === null) { } else {
            let colorindex = row.colorindex === undefined ? count : row.colorindex;
            let color = VizColorConstants[colorindex] === undefined ? VizColorConstants[0] : VizColorConstants[colorindex];
            let striped = row.striped === undefined ? false : row.striped;
            var obj = { name: row.name, key: row.key, category: rowCategory, colorindex: colorindex, colorcode: color, striped: striped, index: subCatCounter };
            this.subcategorynames.push(obj); subCatCounter++;
          }
        }
      }
    }
  }
}
