export const locale = {
    lang: 'pt-br',
    data: {
      'Storyboard': {
        'AddAll': 'Adicionar tudo',
        'Save': 'Salvar',        
        'Export': 'Exportar',
        'Library': 'Livraria',
        'Comments': 'Comentários',
        'CustomViews': 'Visões Customizadas',
        'CustomViewName': 'Nome de Visões Customizadas',
        'ApplyCustomView': 'Aplicar Visão Customizada',
        'SaveCustomView': 'Salvar Visão Customizada',
        'ResetCustomView': 'Limpar Visão Customizada',
        'NoCustomViewFound': 'Nenhuma Visão Customizada encontrada',
        'AddToExport': 'Adicionar para Exportação',
        'ADD': 'ADICIONAR',
        'SAVE': 'SALVAR',
        'CANCEL': 'CANCELAR'
      }
    }
  };
  