export const locale = {
    lang: 'en',
    data: {
        'AvgCostUtil': {
            'AverageCostImpact': 'Average Cost Impact',
            'UtilizationImpact': 'Utilization Impact',
            'AllAmountsDisplayedInLocalCurrency': 'All amounts displayed in local currency',
            'OverallTrend': 'Overall Trend',
            'AverageCost': 'Average Cost',
            'Utilization': 'Utilization',
            'FreqOfUse': 'Freq of Use',
            'ServicesReceived': 'Services Received',
            'KeyImpacts': 'Key Impacts',
            'AverageCostPerClaim': 'Average Cost Per Claim',
            'Prior': 'Prior',
            'Current': 'Current',
            'YoYChange': 'YoY Change',
            'Benchmark': 'Benchmark',
            'ClaimType': 'Claim Type',
            'Network': 'Network',
            'ProviderType': 'Provider Type',
            'Relationship': 'Relationship',
            'CostPerClaim': 'Cost Per Claim',
            'CostPerClaimant': 'Cost Per Claimant',
            'ClaimsPerClaimant': 'Claims Per Claimant',
            'UtilizationRatio': 'Utilization Ratio',
            'ClaimsReportedByPaidDate': 'Claims Reported by Paid Date',
            'ClaimsReportedByIncurredDate': 'Claims reported by incurred date',
            'IncludeClient': 'Include Client',
            'ExcludeClient': 'Exclude Client',
            'Portfolio': 'Portfolio',
            'Industry': 'Industry',
            'None': 'None',
            'Chart': 'Chart',
            'Table': 'Table',
            'ShowLegend': 'Show Legend',
            'DateRange': 'to'
        }
    }
};
