export const locale = {
    lang: 'en',
    data: {
        'DoubleQuad': {
            'DoubleQuadHeading':'Provider - Double Quadrant Analysis',
            'AllAmountsDisplayedInLocalCurrency': 'All amounts displayed in local currency',            
            'Providers':'Providers', 
            'Diagnoses':'Diagnoses' ,           
            'ClaimantCount':'Claimant Count',
            'ClaimCount':'Claim Count',            
            'CostPerClaim': 'Cost Per Claim',
            'CostPerClaimant': 'Cost Per Claimant', 
            'Claim' : 'Claim', 
            'Claimant' : 'Claimant',             
            'ClaimsTotal':'Claims Total',
            'Count':'Count',
            'ClaimsReportedByPaidDate': 'Claims reported by paid date',
            'ClaimsReportedByIncurredDate': 'Claims reported by incurred date',
            'SelectTop':'Select Top',
            'FilterNA':'Exclude NA',
            'CountBasis':'Count Basis',
            'Sort':'Sort',
            'Reset':'Reset',
            'ShownAsPer':'Shown as per',
            'DateRange': 'to'
        }
    }
};
