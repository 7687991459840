<aon-summary-card [height]="cardheight" [width]="cardwidth">
    <aon-summary-card-header headerheight="100px">
        <aon-h3 style="color: #001b5a;">
            <fa-icon style="padding-right: 5px;" [icon]="['fal', 'users']"></fa-icon>
            {{ charttitle }}
        </aon-h3>
    </aon-summary-card-header>
    <aon-summary-card-content [contentwidth]="'97%'" top="60px">
        
        <div class="flex-row-simple" style="width: 300px !important; margin-left: 55%;margin-top: -65px; margin-bottom: 20px;">
            <div style="margin-top: 20px; min-width: 95px; align-content: center; vertical-align: middle;">
                <aon-p-lg>
                    Group By
                </aon-p-lg>
            </div>
            <aon-select style="width: 300px !important;" scrollable="true" [selectedvalue]="selectedOptionName"
                (onselectionchange)="onOptionChange($event)">
                <aon-select-option *ngFor="let option of options" [value]="option.name">{{ option.name }}
                </aon-select-option>
            </aon-select>
        </div>
        <div style="margin-top: -65px; height: 85%; margin-left: -35px;">
            <aon-group-bar-chart [chartid]="chartId" [width]="width" [height]="height" [charttitle]="emptyChartTitle"
                [yaxistitle]="yaxistitle" [tooltip]="tooltip" [maxyaxisnumber]="maxyaxisnumber"
                [titleposition]="titleposition" [data]="data" [categorycolors]="categorycolors">
            </aon-group-bar-chart>
        </div>
    </aon-summary-card-content>
</aon-summary-card>