export const locale = {
    lang: 'es-ec',
    data: {
      'EnrollmentOverTime': {
        'EnrollmentOverTimeHeading' : 'Enrolamiento por tiempo',
        'Male' : 'Masculino',
        'Female' : 'Femenino',
        'NA' : 'ON',
        'AgeBand' : 'Rango de Edad',
        'Dimensions' : 'Dimension',
        'Gender' : 'Género',
        'Relationship' : 'Parentesco',
        'IsEmployee' : 'EE vs Dep',
        'Employee' : 'Empleado',
        'Dependent' : 'Dependiente',
        'Monthly' : 'Por Mes',
        'YearToDateBasis' : 'Año - a - fecha Base (total)',
        'YearToDateBasisC' : 'Año - a - fecha Base (C)',
        'Rolling3Basis' : '3 Meses Corridos',
        'Rolling6Basis' : '6 Meses Corridos',
            'Rolling9Basis' : '9 Meses Corridos',
        'Rolling12Basis' : '12 Meses Corridos',
        'ClaimsReportedByPaidDate' :  'Reclamaciones reportadas por fecha de pago',
        'ClaimsReportedByIncurredDate' :  'Reclamaciones informadas por fecha incurrida',
        'DateRange':'to'
    }
}
};
