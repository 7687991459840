export const locale = {
	lang: 'pt-br',
	data: {
		'ClaimsOverview': {
			'Prior': 'Anterior',
			'Current': 'Atual',
			'ClaimType': 'Tipo de Sinistro',
			'Relationship': 'Relacionamento',
			'Entity': 'Entidade',
			'Network': 'Rede/Reembolso',
			'Location': 'Localização',
			'ClaimsOverview': 'Resumo do Sinistro',
			'Total': 'Total',
			'TotalPaidClaim': 'Total de Sinistro',
			'NOOFCLAIMS': 'Qtd de Sinistro',
			'NOOFCLAIMANTS': 'Qtd de Usuários',
			'Claim': 'Sinistro',
			'Claimant': 'Usuário',
			'Count': 'Contar',
			'AllAmountsDisplayedInLocalCurrency': 'Todos os valores estão em moeda local',
			'ClaimsReportedByPaidDate': 'Sinistro Reportado por Data de Pagamento',
			'ClaimsReportedByIncurredDate': 'Sinistro Reportado por Data de Atendimento',
			'DateRange': 'to'
		}
	}
};