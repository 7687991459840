import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DataService } from 'app/dashboards/data.service';
import { locale as navigationEnglish } from "app/dashboards/templates/enrollment-by-age-band/i18n/en";
import { locale as navigationSpanishCL } from "app/dashboards/templates/enrollment-by-age-band/i18n/es-cl";
import { locale as navigationSpanishCO } from "app/dashboards/templates/enrollment-by-age-band/i18n/es-co";
import { locale as navigationSpanishEC } from "app/dashboards/templates/enrollment-by-age-band/i18n/es-ec";
import { locale as navigationSpanishMX } from "app/dashboards/templates/enrollment-by-age-band/i18n/es-mx";
import { locale as navigationSpanishPE } from "app/dashboards/templates/enrollment-by-age-band/i18n/es-pe";
import { locale as navigationPortugueseBR } from "app/dashboards/templates/enrollment-by-age-band/i18n/pt-br";
import { locale as navigationItalianIT } from "app/dashboards/templates/enrollment-by-age-band/i18n/it";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { FilterOptions } from 'app/dashboards/shared/models/FilterOptions';
import { DashboardTemplateBase } from '../dashboard-template-base.component';
import { DimensionOrderService } from 'app/dashboards/dimension-order.service';

@Component({
  selector: 'app-frequency-of-use-and-average-cost',
  templateUrl: './frequency-of-use-and-average-cost.component.html',
  styleUrls: ['./frequency-of-use-and-average-cost.component.css']
})
export class FrequencyOfUseAndAverageCostComponent implements OnInit, DashboardTemplateBase {
    isFilterOptionsLoading = true;
    
    data: any;
    properties: any;
    imageUrlBase = "";
    filterOptions: FilterOptions = new FilterOptions();
    ShowHideOption=ShowHideOption;
    showFilter: boolean = false;
    dataList: any = [];
    dimensionList: any = [];
    secondDimensions: any = [];
    displaydata: any = [];
    displaydata2: any = [];
    tableHeading: any = [];
    displayedColumns: any = [];
    columns: any = [];
    dynamicTable: any[][] = new Array();
    dynamicTableRow: any[] = new Array();
    legendList:any=[];
    purpleClass='#101E7F';
    blueClass='#29B0C3';
    tempList: [];
    topFilter = 5;
   
    @Output() action = new EventEmitter<any>();

    initData(): any {
        this.dataList = this.data.claimTypeTable;

         this.dataList.map((d) => {
            var objlegend = { name: '', color: '' }
            objlegend.name = d.planYear;
            if(d.isCurrent){
                objlegend.color = '#101E7F';
            }else{
                objlegend.color = "#29B0C3";
            }
            
            if(this.legendList.length < 2) {
                this.legendList.push(objlegend);
              }
        });

        this.tempList = this.dimensionOrderService.sortByColumn(
          this.dataList, 'dimension');
    }
    constructor(
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private dimensionOrderService: DimensionOrderService,
    ) {}

    ngOnInit() {
        this._fuseTranslationLoaderService.loadTranslations(
          navigationEnglish,
          navigationSpanishCL,
          navigationSpanishCO,
          navigationSpanishEC,
          navigationSpanishMX,
          navigationSpanishPE,
          navigationPortugueseBR,
          navigationItalianIT,
        );
      }

  additionalFilterSelectionChanged(val) {
    this.action.emit(val);
  }

   showHideFilter(val, filterName: string): void {
     if (val === ShowHideOption.Hide) {
            this.topFilter = 20;
       this.filterData();
     } else {
       this.topFilter = 5;
       this.filterData();
     }
     this.properties[filterName] = val;
   }

   filterData(): void {
     this.dataList = this.tempList.slice(0, this.topFilter)
   }

}

export enum ShowHideOption {
  Show = 'Show',
  Hide = 'Hide'
}



export enum ClaimantOption {
  Claim = "Claim",
  Claimant = "Claimant"
}