export const locale = {
    lang: 'es-cl',
    data: {          
        'CoMorbidity': {
            'CoMorbidityHeading':'CO-MORBILIDADES',
            'CoMorbidity':'Co-morbilidades',            
            'AssociatedCo': 'Co-morbilidades asociadas' ,
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'Diabetes':'Diabetes',          
            'Hyperlipidemia' : 'Hiperlipidemia', 
            'Hypertension':'Hipertensión',           
            'Claimants' : 'Reclamantes',
            'AverageAge' : 'Promedio Edad ',
            'Years':'años',
            'Female' : 'Femenino',            
            'Male' : 'Masculino',
            'Gender' : 'Género',
            'ClaimType': 'Prestación',
            'TotalCost': 'Monto Reembolsado Total',
            'PercentageOfTotalClaims': '% total de reclamaciones'
        }
    }
};
