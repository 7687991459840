<div fxLayout="column" *ngIf="isLoading" style="overflow: hidden;">
    <div fxLayout="row" class="fwbloadingOverlay">
        <div class="fwbloadingContent">
            Loading...
        </div>
    </div>
</div>
<aon-summary-card [height]="cardheight" [width]="cardwidth" *ngIf="!isLoading">
    <aon-summary-card-header headerheight="100px">
        <aon-h3 style="color: #001b5a;">
            <fa-icon style="padding-right: 5px;" [icon]="['fal', 'hand-holding-usd']"></fa-icon>
            {{ charttitle }}
        </aon-h3>
    </aon-summary-card-header>
    <aon-summary-card-content [contentwidth]="'97%'" top="60px">
        <fwb-error-message [isError]="isError" [message]="errorMsg"></fwb-error-message>
        <div *ngIf="!isError">
            <div style="display: flex; flex-direction: row;">
                <div>
                    <aon-h1 style="color: #001b5a;">624</aon-h1>
                </div>
                <div style="margin-top: 20px; margin-left: 5px;">
                    <aon-p-elg>
                        750
                    </aon-p-elg>
                </div>
            </div>
            <div style="width: 400px !important; margin-left: 600px;margin-top: -65px; display: flex;flex-direction: row;">
                <div style=" margin-top: 10px; min-width: 95px; align-content: center; vertical-align: middle;">
                    <aon-p-lg>
                        Group By
                    </aon-p-lg>
                </div>
                <aon-select style="width: 185px !important;" scrollable="true" [selectedvalue]="selectedOptionName"
                    (onselectionchange)="onOptionChange($event)">
                    <aon-select-option *ngFor="let option of options" [value]="option.name">{{ option.name }}
                    </aon-select-option>
                </aon-select>
            </div>
            <div style="margin-top: -65px; height: 85%; margin-left: -35px;">
                <aon-group-bar-chart [chartid]="chartId" [width]="width" [height]="height"
                    [charttitle]="emptyChartTitle" [yaxistitle]="yaxistitle" [tooltip]="tooltip"
                    [maxyaxisnumber]="maxyaxisnumber" [ticksize]="numberOfTicks" [titleposition]="titleposition"
                    [data]="data" [categorycolors]="categorycolors">
                </aon-group-bar-chart>
            </div>
        </div>
    </aon-summary-card-content>
    <aon-summary-card-footer>
        <aon-button-default (onclick)="goToLearnMore()" style="margin-top: -10px !important;" id="btn-prominent">Learn
            More</aon-button-default>
    </aon-summary-card-footer>
</aon-summary-card>