<div
    id="lossDashboard"
    style="min-height: 795px; padding: 8px; overflow: auto; position: relative;"
    fxLayout="row"
>
    <div
        fxFlex
        fxLayout="column"
    >
        <div
            id="lossHeader"
            class="dash-header"
        >
            <span id="lossHeaderTxt">{{ 'LossRatio.LossRatioHeading' | translate }}</span>
            <button
                id="lossHeaderBtn"
                mat-icon-button
                style="float: right"
                (click)="showFilter = !showFilter"
                *ngIf="!isFilterOptionsLoading"
            >
                <app-filter-button
                    [isClientName]='true'
                    [isEntity]='true'
                    [isPolicyType]='true'
                    [isPlan]='true'
                    [isProviderType]='true'
                    [isGender]='true'
                    [isRelationship]='true'
                    [isAgeBand]='true'
                    [isLocation]='true'
                    [isLOB]='true'
                    [isSubLOB]='true'
                    [isTimePeriodLossRatio]='true'
                ></app-filter-button>
            </button>
        </div>
        <div fxLayout="row">

            <div
                id="lossHeaderCurrency"
                style="font-size: 13px; font-style: italic; padding: 3px"
                fxFlex="90%"
            >
                {{ 'LossRatio.AllAmountsDisplayedInLocalCurrency' | translate }}
            </div>
        </div>
        <div
            id="lossLegends"
            style="padding:0"
        >
            <div
                fxLayout="row"
                style="font-size: 15px;"
            >
                <div fxFlex="30"></div>
                <div fxFlex="15">
                    <svg
                        width="15"
                        height="15"
                    >
                        <rect
                            width="15"
                            height="15"
                            [attr.fill]="secondary_color"
                        ></rect>
                    </svg> {{'LossRatio.Loss' | translate |titlecase}}
                </div>
                <div
                    fxFlex="15"
                    *ngIf="((properties.period === PeriodOption.Monthly) && (properties.showHide === ShowHideOption.Show))"
                >
                    <svg
                        width="15"
                        height="15"
                    >
                        <rect
                            width="15"
                            height="15"
                            [attr.fill]="primary_color"
                        ></rect>
                    </svg> {{'LossRatio.MonthlyLossRatio' | translate}}
                </div>
                <div fxFlex="15">
                    <svg
                        width="15"
                        height="15"
                    >
                        <rect
                            width="15"
                            height="15"
                            [attr.fill]="tertiary_color"
                        ></rect>
                    </svg> {{legendOrangeText}}
                </div>
                <div fxFlex="15">
                    <svg
                        width="15"
                        height="15"
                    >
                        <rect
                            width="15"
                            height="15"
                            [attr.fill]="quaternary_color"
                        ></rect>
                    </svg> {{legendBlueText}}
                </div>
                <div fxFlex></div>
            </div>
        </div>
        <div>
            <ng-container
                id="lossChartColumnLine"
                *ngIf="parentLoaded"
            >
                <app-bar-insidebar
                    [categories]="categories1"
                    [claimPMPMSelected]="claimPMPMSelected"
                    [Monthly]="properties.period === PeriodOption.Monthly"
                    [dataListIn]='dataListOrange'
                    [dataListOut]='dataListBlue'
                    [MaxOfY]="MaxofClaimPreY"
                    [plotLinesX]="plotLinesX"
                    [decimalPlaces]="(properties.country === 'Chile' ? 2 : 0)"
                    [isCountryChile]="isCountryChile"
                    [country]="properties.country"
                    [combinedLRSelected]="properties.lossRatio === LossRatioOption.CombinedLR"
                    [seriesBarName]="legendBlueText"
                    [seriesLineName]="legendOrangeText"
                    [colorLine]="tertiary_color"
                    [colorBar]="quaternary_color"
                ></app-bar-insidebar>
            </ng-container>
        </div>
        <div fxLayout="row">
            <div fxFlex><br /><br /></div>
        </div>
        <div>
            <ng-container
                id="lossChartColumnDot"
                *ngIf="parentLoaded"
            >
                <app-column-line
                    [dataList]="dataListLossColumn"
                    [dataList2]="dataListLossLine"
                    [textC]="chart1"
                    [Monthly]="properties.period === PeriodOption.Monthly"
                    [dotColor]="secondary_color"
                    [barColor]="primary_color"
                    [benchMark]="TargetLossRatio"
                    [MaxOfY]="MaxofLossY"
                    [plotLinesX]="plotLinesX"
                    [categories]="categories1"
                    [ShowLossBar]="((properties.showHide === ShowHideOption.Show) && (properties.period === PeriodOption.Monthly))"
                    [MonthlyName]="MonthlyName"
                    [CummulativeName]="CummulativeName"
                ></app-column-line>
            </ng-container>
        </div>

        <div
            fxLayout="row"
            style="margin-top: 10px"
        >
            <div [fxFlex]="80"></div>
            <div
                id="lossFooterNote"
                [fxFlex]="20"
                style="text-align: right; margin-right: 10px;"
            >
                {{properties.claimPaid === CLAIM_BASIS.PAID_BASIS ? ( 'LossRatio.ClaimsReportedByPaidDate' | translate )
                : ( 'LossRatio.ClaimsReportedByIncurredDate' | translate )}}
            </div>
        </div>
    </div>

    <div
        style="width: 200px; padding: 40px 0px 0px 16px; border-left: 1px solid #EEE"
        fxLayout="column"
    >
        <div
            id="entityDivisionFilters"
            *ngIf="showFilter"
        >
            <app-additional-filters
                [country]="properties.country"
                (action)="additionalFilterSelectionChanged($event)"
                [filterOptions]='filterOptions'
                [isClientName]='true'
                [isEntity]='true'
                [isPolicyType]='true'
                [isPlan]='true'
                [isProviderType]='true'
                [isGender]='true'
                [isRelationship]='true'
                [isAgeBand]='true'
                [isLocation]='true'
                [isLOB]='true'
                [isSubLOB]='true'
                [isTimePeriodLossRatio]='true'
            ></app-additional-filters>
        </div>
        <div
            id="lossFilters"
            fxFlex="55"
            *ngIf="!showFilter"
        >
            <div
                id="lossTxtShowHide"
                fxLayout="row"
                style=" margin-bottom: 5px;"
            >
                <span>{{ 'LossRatio.ShowHide' | translate }}</span>
            </div>
            <div
                id="lossBtnShow"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.showHide == ShowHideOption.Show}"
                (click)="filterChanged(ShowHideOption.Show, 'showHide')"
            >
                <span>{{ 'LossRatio.Show' | translate }}</span>
            </div>

            <div
                id="lossBtnHide"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.showHide == ShowHideOption.Hide}"
                (click)="filterChanged(ShowHideOption.Hide, 'showHide')"
            >
                <span>{{ 'LossRatio.Hide' | translate }}</span>
            </div>
            <div
                id="lossTxtLR"
                fxLayout="row"
                style="margin-top:10px; margin-bottom: 5px;"
            >
                <span>{{ 'LossRatio.LossRatioType' | translate }}</span>
            </div>
            <div
                id="lossBtnLossRatio"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.lossRatio == LossRatioOption.LossRatio}"
                (click)="selectionChanged(LossRatioOption.LossRatio, 'lossRatio')"
            >
                <span>{{ 'LossRatio.LossRatioHeading' | translate }}</span>
            </div>
            <div
                id="lossBtnCombinedLR"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.lossRatio == LossRatioOption.CombinedLR}"
                (click)="selectionChanged(LossRatioOption.CombinedLR, 'lossRatio')"
            >
                <span>{{ 'LossRatio.CombinedLR' | translate }}</span>
            </div>
            <div
                id="lossClaimsTotalPMPMTxt"
                fxLayout="row"
                style="margin-top:10px; margin-bottom: 10px;"
            >
                <span>{{ 'LossRatio.ClaimsTotalPMPM' | translate }}</span>
            </div>
            <div
                id="lossBtnClaimsPMPM"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.claimPMPM == ClaimPMPMOption.ClaimsTotal}"
                (click)="selectionChanged(ClaimPMPMOption.ClaimsTotal, 'claimPMPM')"
            >
                <span>{{ 'LossRatio.ClaimsTotal' | translate }}</span>
            </div>
            <div
                id="lossBtnClaimsPMPM"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.claimPMPM == ClaimPMPMOption.ClaimsPMPM}"
                (click)="selectionChanged(ClaimPMPMOption.ClaimsPMPM, 'claimPMPM')"
            >
                <span>{{ 'LossRatio.ClaimsPMPM' | translate }}</span>
            </div>
            <div
                id="lossTargetTxt"
                fxLayout="row"
                style="margin-top:10px; margin-bottom: 5px;"
            >
                <span>{{ 'LossRatio.TargetLossRatio' | translate }}</span>
            </div>
            <div
                fxLayout="row"
                style=" margin-bottom: 10px;"
            >
                <input
                    id="lossBtnTargetLR"
                    type="number"
                    [(ngModel)]="TargetLossRatio"
                    class="targetInput"
                    min="0"
                    (ngModelChange)="filterChanged(TargetLossRatio,'targetLossRatio')"
                ><span style="padding-left: 5px;">%</span>
            </div>
            <div
                id="lossBtnMonthly"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.period == PeriodOption.Monthly}"
                (click)="selectionChanged(PeriodOption.Monthly, 'period')"
            >
                <span>{{ 'LossRatio.Monthly' | translate }}</span>
            </div>
            <div
                id="lossBtnYearToDateBasis"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.period == PeriodOption.YearBasis}"
                (click)="selectionChanged(PeriodOption.YearBasis, 'period')"
            >
                <span>{{ 'LossRatio.YearToDateBasis' | translate }}</span>
            </div>
            <div
                id="lossBtnYearToDateBasisC"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.period == PeriodOption.YearBasisC}"
                (click)="selectionChanged(PeriodOption.YearBasisC, 'period')"
            >
                <span>{{ 'LossRatio.YearToDateBasisC' | translate }}</span>
            </div>
            <div
                id="lossBtnRolling3"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.period == PeriodOption.Rolling3}"
                (click)="selectionChanged(PeriodOption.Rolling3, 'period')"
            >
                <span>{{ 'LossRatio.Rolling3Basis' | translate }}</span>
            </div>
            <div
                id="lossBtnRolling6"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.period == PeriodOption.Rolling6}"
                (click)="selectionChanged(PeriodOption.Rolling6, 'period')"
            >
                <span>{{ 'LossRatio.Rolling6Basis' | translate }}</span>
            </div>
            <div
                id="lossBtnRolling9"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.period == PeriodOption.Rolling9}"
                (click)="selectionChanged(PeriodOption.Rolling9, 'period')"
            >
                <span>{{ 'LossRatio.Rolling9Basis' | translate }}</span>
            </div>
            <div
                id="lossBtnRolling12"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{'dash-option-selected': properties.period == PeriodOption.Rolling12}"
                (click)="selectionChanged(PeriodOption.Rolling12, 'period')"
            >
                <span>{{ 'LossRatio.Rolling12Basis' | translate }}</span>
            </div>
        </div>
    </div>
</div>