import { Component, OnInit, ViewChild, Input } from '@angular/core';
import * as moment from 'moment';
import { AgmaticonactionComponent } from 'app/shared/agmaticonaction/agmaticonaction.component';
import { OfflineService } from 'app/services/offline.service';
import { ToasterService } from 'app/services/toaster.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.css']
})
export class OfflineComponent implements OnInit {

  queueColDefs: any;
  offlineQueue: any[]  = [];
  isLoadingQueue = false;
  isLoadingDownload = false;
  @ViewChild('qGrid', { static: true }) qGrid;
  @Input() client: any;
  @Input() country: any;
  @Input() isSubmit = true;
  
  constructor(private _offlineService: OfflineService, private toasterService: ToasterService) { }

  ngOnInit() {
    this.setQueueColDef();
    this.loadQueue();
  }

  loadQueue() {
    this.isLoadingQueue = true;
    this._offlineService.getQueue().toPromise().then(data => {
      this.offlineQueue = data;
      this.isLoadingQueue = false;
      console.log(data);
    });
  }

  setQueueColDef() {
    var colDefs = [
      
      {
        headerName: 'Date', field: 'createdOn', sort: 'desc', width: 160, suppressSizeToFit: true,
        cellRenderer: (cell) => {
            var momentDate = moment.utc(cell.data.createdOn).toDate();
            return moment(momentDate).local().format('MM/DD/YYYY HH:mm');
        }
      },
      { headerName: 'Client', field: 'clientName', width: 150 },
      { headerName: 'Country', field: 'countryName', width: 150 },
      { headerName: 'Requested By', field: 'requestedBy', width: 150 },
      { headerName: 'Status', field: 'jobStatus', width: 150 },
      {
        width: 80,
        suppressSizeToFit: true,
        filter: false,
        cellRendererFramework: AgmaticonactionComponent,
        cellRendererParams: {
          action: (row) => this.onDownload({ data: row.data }),
          icon: 'save_alt',
          isDisabled: (row) => this.isDisabledRow({ data: row.data })
        }
      }
    ]
    this.queueColDefs = colDefs;
  }

  isDisabledRow(row) {
    return row.data.jobStatus.toLowerCase() !== 'Finished';
  }

  onDownload($event) {
    this.qGrid.showLoadingOverLay(true);
    this.isLoadingDownload = true;
    console.log('onDownload', $event.data);
    window.open(environment.api + 'api/offline/download/' + $event.data.id, '_self');
    setTimeout(() => { this.isLoadingDownload = false; this.qGrid.showLoadingOverLay(false) }, 2000);
  }

  submit() {
    this._offlineService.submit(this.client.tier1Id, this.client.name, this.country.tier2Id, this.country.name).toPromise().then(data => {
      this.loadQueue();
    }).catch(reason => {
      this.toasterService.showMessage(reason.error);
    });
  }
}
