export const locale = {
    lang: 'it',
    data: {
  'ExecutiveSummary': {
    'ExecutiveSummary': 'Rapporto sintetico',
    'AllAmountsDisplayedInLocalCurrency': 'Tutti gli importi visualizzati in valuta locale',
    'GeneralTrend': 'Trend generale',
    'Rolling12Basis': 'Rolling base 12',
    'CurrentPMPM': 'Current PMPM',
    'PriorPMPM': 'pmpm precedente',
    'OverallTrend': 'Trend generale',
    'AverageCost': 'Costo medio',
    'Utilization': 'Utilizzo',
    'ServicesReceived': 'Prestazioni erogate',
    'PlanPerformance': 'Sintesi del piano',
    'YearToDateBasis': "Base dall'inizio dell'anno",
    'YearToDateCBasis': 'Base da inizio anno (C).',
    'ClaimsReportedByPaidDate': 'Sinistri per data di pagamento',
    'ClaimsReportedByIncurredDate': 'Sinistri per data di accadimento',
    'LossRatio': 'Rapporto Sinistri-Premi',
    'Headcount': 'Numero assistiti',
    'Claims': 'Sinistro',
    'Premium': 'Premio',
    'CombinedLossRatio': 'Combined Loss Ratio',
    'ClaimLabel': 'CLAIMS + EXPENSES',
    'PremimumLabel': 'PREMIUM + INCOME',
    'DateRange': 'Intervallo Di Date'
  }
}};