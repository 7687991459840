import { ScrollingModule } from '@angular/cdk/scrolling';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExecutiveSummaryComponent } from './templates/executive-summary/executive-summary.component';
import { HealthDiseaseProfileComponent } from './templates/health-disease-profile/health-disease-profile.component';
import { ChartsModule } from '../charts/charts.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardTemplateBase } from './templates/dashboard-template-base.component';
import { PerMemberCostsComponent } from './templates/per-member-costs/per-member-costs.component';
import { PrevalenceOfDiseaseComponent } from './templates/prevalence-of-disease/prevalence-of-disease.component';
import { AverageCostUtilComponent } from './templates/average-cost-util/average-cost-util.component';
import { TranslateModule } from '@ngx-translate/core';
import { PulseSelectComponent } from './shared/pulse-select/pulse-select.component';
import { TableauSpinnerComponent } from './shared/tableau-spinner/tableau-spinner.component';
import { DashboardcontainerComponent } from './dashboardcontainer/dashboardcontainer.component';
import { ClaimCostTopProviderComponent } from './templates/claim-cost-top-provider/claim-cost-top-provider.component';
import { ClaimsOverviewComponent } from './templates/claims-overview/claims-overview.component';
import { ClaimTypeDiagnosisComponent } from './templates/claim-type-diagnosis/claim-type-diagnosis.component';
import { ClaimTypeComponent } from './templates/claim-type/claim-type.component';
import { ClaimByAgeBandComponent } from './templates/claim-by-age-band/claim-by-age-band.component';
import { ExecutiveSummaryRowComponent } from './templates/executive-summary-row/executive-summary-row.component';
import { LossRatioComponent } from './templates/loss-ratio/loss-ratio.component';
import { EnrollmentByTimeComponent } from './templates/enrollment-by-time/enrollment-by-time.component';
import { EnrollmentByAgeBandComponent } from './templates/enrollment-by-age-band/enrollment-by-age-band.component';
import { DistributionByClaimTypeComponent } from './templates/distribution-by-claim-type/distribution-by-claim-type.component';
import { AdditionalFiltersComponent } from './shared/additional-filters/additional-filters.component';
import { DoubleQuadrantProDiagComponent } from './templates/double-quadrant-pro-diag/double-quadrant-pro-diag.component';
import { FrequencyOfUseAndAverageCostComponent } from './templates/frequency-of-use-and-average-cost/frequency-of-use-and-average-cost.component';
import { CoMorbidityComponent } from './templates/co-morbidity/co-morbidity.component';
import { FilterButtonComponent } from './shared/filter-button/filter-button.component';

@NgModule({
  declarations: [
    ExecutiveSummaryComponent,
    HealthDiseaseProfileComponent,
    DashboardTemplateBase, PrevalenceOfDiseaseComponent,
    AverageCostUtilComponent,
    PrevalenceOfDiseaseComponent,
    PerMemberCostsComponent,
    PulseSelectComponent,
    TableauSpinnerComponent,
    DashboardcontainerComponent,
    ClaimCostTopProviderComponent,
    ClaimTypeComponent,
    ClaimsOverviewComponent,
    ClaimTypeDiagnosisComponent,
    ClaimByAgeBandComponent,
    ExecutiveSummaryRowComponent,
    LossRatioComponent,
    EnrollmentByTimeComponent,
    EnrollmentByAgeBandComponent,
    ExecutiveSummaryRowComponent,
    AdditionalFiltersComponent,
    DistributionByClaimTypeComponent,
    DoubleQuadrantProDiagComponent,
    FrequencyOfUseAndAverageCostComponent,
    CoMorbidityComponent,
    FilterButtonComponent,
  ],
  imports: [
    ScrollingModule,
    CommonModule,
    ChartsModule,
    SharedModule,
    TranslateModule
  ],
  entryComponents: [
    ExecutiveSummaryComponent,
    HealthDiseaseProfileComponent,
    PrevalenceOfDiseaseComponent,
    AverageCostUtilComponent,
    PerMemberCostsComponent,
    TableauSpinnerComponent,
    DashboardcontainerComponent,
    ClaimCostTopProviderComponent,
    ClaimTypeComponent,
    ClaimsOverviewComponent,
    ClaimTypeDiagnosisComponent,
    ClaimByAgeBandComponent,
    ExecutiveSummaryRowComponent,
    LossRatioComponent,
    EnrollmentByTimeComponent,
    EnrollmentByAgeBandComponent,
    DistributionByClaimTypeComponent,
    DoubleQuadrantProDiagComponent,
    FrequencyOfUseAndAverageCostComponent,
    CoMorbidityComponent
  ],
  exports: [
    DashboardTemplateBase,
    ExecutiveSummaryComponent,
    HealthDiseaseProfileComponent,
    PrevalenceOfDiseaseComponent,
    AverageCostUtilComponent,
    PerMemberCostsComponent,
    TableauSpinnerComponent,
    DashboardcontainerComponent,
    ClaimCostTopProviderComponent,
    ClaimTypeComponent,
    ClaimsOverviewComponent,
    ClaimTypeDiagnosisComponent,
    ClaimByAgeBandComponent,
    ExecutiveSummaryRowComponent,
    LossRatioComponent,
    EnrollmentByTimeComponent,
    EnrollmentByAgeBandComponent,
    DistributionByClaimTypeComponent,
    DoubleQuadrantProDiagComponent,
    FrequencyOfUseAndAverageCostComponent,
    CoMorbidityComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardsModule { }
