<div fxLayout="column" *ngIf="!data" class="loadingOverlayNew">
  <aon-loading-spinner></aon-loading-spinner>
</div>
<div *ngIf="data && isDataAvailable" class="columnDiv contentPadding">
  <div style="margin: 10px;">
    <div class="rowDiv" style="justify-content: space-between;">
      <div class="rowDiv">
        <div>
          <div class="rowDiv">
            <div style="padding-right:16px; padding-top: 12px;">
                <fa-icon [icon]="['fal', 'users']"
                [styles]="{'font-size': '32px', 'color': '#001B5A'}"></fa-icon>
            </div>
            <div><aon-h1 style="color:#001B5A;"> Participation</aon-h1></div>
          </div>
          <!-- <aon-h1 style="color:#001B5A;"></aon-h1> -->
        </div>
        <div class="vertical-line marginLeft20"></div>
        <div class="marginLeft20 marginTop20">
          <aon-p-elg class="fontWeight500">
            <b> from {{data.startDate}} through {{data.endDate}}</b>
          </aon-p-elg>
        </div>
      </div>

    </div>
  </div>

  <div>
    <aon-bar-summary width="100%" height="140px" colorindex="11">
      <aon-bar-summary-content width="20%">
        <div class="columnDiv marginLeft20">
          <div>
            <aon-p-lg class="whiteColorText"> Total Participation </aon-p-lg>
          </div>
          <div class="negativeMarginTop20 rowDiv">
            <div>
              <aon-h1 class="whiteColorText">{{data.totalParticipationPercentage}}%</aon-h1>
            </div>
            <div class="bottom whiteColorText" style="padding-bottom: 20px; margin-left: 20px;">
              <div>{{data.totalOverall  | number:'1.0':'en-US' }} of {{data.totalEligible  | number:'1.0':'en-US'}} employees</div>
            </div>
          </div>
        </div>
      </aon-bar-summary-content>
      <aon-vertical-line></aon-vertical-line>
      <aon-bar-summary-content width="70%">
        <div class="columnDiv" style = "margin-top: 15px">
          <div class="marginTop20">
            <aon-h4 class="whiteColorText">
              Your active participation rate has changed {{ changePercentage }}% since {{ maxDate }}
              <!-- Your active participation rate had gone up {{data.totalParticipationPercentageChange}}% since this time
              last week. -->
            </aon-h4>
          </div>
        </div>
      </aon-bar-summary-content>
    </aon-bar-summary>
  </div>
  <div>
    <br />
  </div>

  <div>
    <aon-summary-card width="99%" height="950px">
      <aon-summary-card-content style="padding-left: 0px !important;" contentwidth='95%'>
        <div class="columnDiv" style="padding-left: 0px !important;">
          <div>
            <div
              style="height: 800px; width: 98%;  margin-left: 10px; display: flex; flex-direction: column; background-color: white; border-radius: 5px;">
              <div style="height: 8%;">
                <div class="rowDiv" style="padding: 20px 20px 20px 20px; justify-content: space-between">
                  <div>
                    <aon-h3 style="color:#001B5A;">Participation Over Time</aon-h3>
                  </div>
                  <div class="rowDiv">
                    <!-- <div class="custom-button-icon" (click)="chartIconClick()"
                      [style.background-color]="chartIconBgColor">
                      <fa-icon size="2x" [icon]="['fal', 'chart-line']"></fa-icon>
                    </div> -->
                    <div *ngIf="false" class="custom-button-icon" (click)="tableIconClick()"
                      [style.background-color]="tableIconBgColor" style="margin-left:10px;">
                      <fa-icon size="2x" [icon]="['fal', 'table']" [styles]="{'stroke': 'teal', 'color': 'teal'}">
                      </fa-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="rowDiv"
                style="padding-left:20px; height: 100px;  margin-bottom: 20px; margin-top: 10px;"
                *ngIf="showChart">
                <div style="margin-top: 15px;">
                  <aon-buttontab-list color="teal" (click)="onOptionChange($event)">
                    <aon-buttontab value=1 width='140px'>Overall</aon-buttontab>
                    <aon-buttontab value=2 selected=true width='140px'>Business Unit</aon-buttontab>
                  </aon-buttontab-list>
                </div>
                <div [style.display]="(selectedTab == 2) ? 'block' : 'none'" class="columnDiv"
                  style="width: 15%; margin-top:-20px; margin-left:50px;">
                  <div style="height: 36px">
                    <aon-h5>Participation Type</aon-h5>
                  </div>
                  <div style="width: 100%;">
                    <aon-select *ngIf="participationResult.PercentageOrCountOption === 1" returntype='number' [selectedvalue]="participationResult.ParticipationLevelValue" width="100%"
                    (onselectionchange)='onActiveInactiveChange($event)'>
                      <aon-select-option *ngFor="let row of participationResult.ParticipationLevelPercentageArr" 
                      [value]="row.Id">{{ row.Value}}</aon-select-option>                    
                    </aon-select>
                    <aon-select *ngIf="participationResult.PercentageOrCountOption === 2" returntype='number' [selectedvalue]="participationResult.ParticipationLevelValue" width="100%"
                      (onselectionchange)='onActiveInactiveChange($event)'>
                      <aon-select-option *ngFor="let row of participationResult.ParticipationLevelCountArr" 
                      [value]="row.Id">{{ row.Value}}</aon-select-option>     
                    </aon-select>
                  </div>
                </div>
                <div [style.display]="(selectedTab == 2) ? 'block' : 'none'" class="columnDiv"
                style="width: 15%; margin-top:-20px; margin-left:50px;">
                  <div style="height: 36px">
                    <aon-h5>Ranking</aon-h5>
                  </div>
                  <div style="width: 100%;">
                    <aon-select returntype='number' selectedvalue="1" width="100%"
                      (onselectionchange)='onTopBottom5Change($event)'>
                      <aon-select-option 
                      value="1"  [selected]="participationResult.ParticipationLevelRankingOrder === 1">Top 5</aon-select-option>    
                      <aon-select-option 
                      value="2">Bottom 5</aon-select-option>      
                    </aon-select>
                  </div>
                </div>
                <div style="margin-left:50px; margin-top:20px ">
                  <div class="rowDiv">
                    <div><aon-radiobutton value=2 (ontoggle) ="countClickEvent($event)" [selected]="participationResult.PercentageOrCountOption !== 1" >Count</aon-radiobutton></div>
                    <div style="margin-left:20px;"><aon-radiobutton value=1 (ontoggle) ="percentageClickEvent($event)" [selected]="participationResult.PercentageOrCountOption === 1">Percentage</aon-radiobutton></div>                   
                  </div>
                  <!-- <div *ngIf="selectedTab == 2 && participationResult.ParticipationLevelRankingOrder === 2" class="rowDiv" style='margin-top: 15px;'>
                    <aon-switch [switchon] ="participationResult.ParticipationLevelHasBottomRankingUsers" (ontoggle)="onBottom5HasUsersChange()">Excludes Zeroes?</aon-switch>
                  </div> -->
                </div>
              
              </div>
             
              <div *ngIf="showChart" style="height: 95%; width: 100%; margin-top:20px;">
                <aon-multi-line-chart tooltip = true [data]="lineChartData"
                [yaxismaxnumber] ="maxYAxisNum"
                [xaxistickformat] = "'%b-%y'"
                xaxisticksize ="d"
                tooltipleftposition="83%"
                tooltiptopposition ="240px"
                [customtickdata]="participationResult.ParticipationChartCustomTicks"
                [isyaxispercentage] = "participationResult.PercentageOrCountOption === 1"
                 [yaxistitle]="participationResult.PercentageOrCountOption === 1 ? 'Percentage': 'Participants' "
                  width="88%" height="500px">
                </aon-multi-line-chart>
              </div>
              <div *ngIf="!showChart" style="height: 90%; width: 100%;">
                <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
                  <thead>
                    <tr>
                      <th class='clickable' style="width: 15%; padding-left: 15px"
                        (click)='toggleParticipationOverTimeSort("country")'>
                        Country
                        <fa-icon style="margin-left: 15px;"
                          *ngIf="participationOverTimeColumn === 'country' && isParticipationOverTimeDescendingOrder"
                          [icon]="['fal', 'sort-alpha-down-alt']"></fa-icon>
                        <fa-icon style="margin-left: 15px;"
                          *ngIf="participationOverTimeColumn === 'country' && !isParticipationOverTimeDescendingOrder"
                          [icon]="['fal', 'sort-alpha-down']"></fa-icon>
                      </th>
                      <th class='clickable' style="width: 45%; padding-left: 15px"
                        (click)='toggleParticipationOverTimeSort("name")'>
                        Business Unit
                        <fa-icon style="margin-left: 15px;"
                          *ngIf="participationOverTimeColumn === 'name' && isParticipationOverTimeDescendingOrder"
                          [icon]="['fal', 'sort-alpha-down-alt']"></fa-icon>
                        <fa-icon style="margin-left: 15px;"
                          *ngIf="participationOverTimeColumn === 'name' && !isParticipationOverTimeDescendingOrder"
                          [icon]="['fal', 'sort-alpha-down']"></fa-icon>
                      </th>
                      <th class='clickable' style="width: 10%;"
                        (click)='toggleParticipationOverTimeSort("dateSortable")'>
                        Date
                        <fa-icon style="margin-left: 15px;"
                          *ngIf="participationOverTimeColumn === 'dateSortable' && isParticipationOverTimeDescendingOrder"
                          [icon]="['fal', 'sort-numeric-down-alt']"></fa-icon>
                        <fa-icon style="margin-left: 15px;"
                          *ngIf="participationOverTimeColumn === 'dateSortable' && !isParticipationOverTimeDescendingOrder"
                          [icon]="['fal', 'sort-numeric-down']"></fa-icon>
                      </th>
                      <th class='clickable' style="width: 10%;" (click)='toggleParticipationOverTimeSort("active")'>
                        Active
                        <fa-icon style="margin-left: 15px;"
                          *ngIf="participationOverTimeColumn === 'active' && isParticipationOverTimeDescendingOrder"
                          [icon]="['fal', 'sort-numeric-down-alt']"></fa-icon>
                        <fa-icon style="margin-left: 15px;"
                          *ngIf="participationOverTimeColumn === 'active' && !isParticipationOverTimeDescendingOrder"
                          [icon]="['fal', 'sort-numeric-down']"></fa-icon>
                      </th>
                      <th class='clickable' style="width: 10%;" (click)='toggleParticipationOverTimeSort("enrolled")'>
                        Enrolled
                        <fa-icon style="margin-left: 15px;"
                          *ngIf="participationOverTimeColumn === 'enrolled' && isParticipationOverTimeDescendingOrder"
                          [icon]="['fal', 'sort-numeric-down-alt']"></fa-icon>
                        <fa-icon style="margin-left: 15px;"
                          *ngIf="participationOverTimeColumn === 'enrolled' && !isParticipationOverTimeDescendingOrder"
                          [icon]="['fal', 'sort-numeric-down']"></fa-icon>
                      </th>
                      <th class='clickable' style="width: 10%;" (click)='toggleParticipationOverTimeSort("eligible")'>
                        Eligible
                        <fa-icon style="margin-left: 15px;"
                          *ngIf="participationOverTimeColumn === 'eligible' && isParticipationOverTimeDescendingOrder"
                          [icon]="['fal', 'sort-numeric-down-alt']"></fa-icon>
                        <fa-icon style="margin-left: 15px;"
                          *ngIf="participationOverTimeColumn === 'eligible' && !isParticipationOverTimeDescendingOrder"
                          [icon]="['fal', 'sort-numeric-down']"></fa-icon>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of tableData">
                      <td style="padding-left: 15px;">{{ data.country }}</td>
                      <td style="padding-left: 15px;">{{ data.name }}</td>
                      <td align="right">{{ data.date }}</td>
                      <td align="right">{{ data.active | number }}</td>
                      <td align="right">{{ data.enrolled | number }}</td>
                      <td align="right">{{ data.eligible | number }}</td>
                    </tr>
                  </tbody>
                  <caption *ngIf="tableData.length >= noOfTableRowsShown" (click)="viewMore()">{{ captionText }}
                  </caption>
                </table>
              </div>
            </div>
          </div>
        </div>
      </aon-summary-card-content>
    </aon-summary-card>

  </div>


</div>


<div class="contentPadding" fxLayout="column" style="height: 83vh;" *ngIf="!isDataAvailable && loaded">    
  <aon-h1 class="title" style="margin-top:50px;">Privacy filter applied for selection less than 10 people</aon-h1>
</div>


<div fxLayout="column" class="loadingOverlayNew" *ngIf="!data && errorMsg">
  <aon-loading-spinner></aon-loading-spinner>
</div>