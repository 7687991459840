import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';

@Component({
  selector: 'app-bar-negative-stack',
  templateUrl: './bar-negative-stack.component.html',
  styleUrls: ['./bar-negative-stack.component.css']
})
export class BarNegativeStackComponent implements OnInit {

    @Input() barColor = 'red';
    @Input() value = 0;
    @Input() value2 = 0;
    @Input() percentLeft = 0;
    @Input() percentRight = 0;
    @Input() MeasureOption = 'Number';
    @Input() valueDecimalPlace = 0;
    @Input() maxLeftValue = 0;
    @Input() maxRightValue = 0;
    @Input() maxLeftPercentage = 0;
    @Input() maxRightPercentage = 0;    
    @Input() isBenchmarkShown = false;
    @Input() benchmarkLeftValue = 0;
    @Input() benchmarkRightValue = 0;

    benchwidth = 0;
    currentStartPoint = 40;
    fullWidthAllocation = 100;

    constructor() {   this.fullWidthAllocation = (window.outerWidth / screen.width) * 100; }

    ngOnInit(): void {
        this.fullWidthAllocation = (window.outerWidth / screen.width ) * 100;
    }

    getBenchPos(): any {
        return ` translate(${this.benchwidth}, 1)`;
    }

    getWidth(): string {
        return '' + ((this.value / this.maxLeftValue) * this.fullWidthAllocation) + '%';
    }
    getWidth2(): string {
        return '' + ((this.value2 / this.maxRightValue) * this.fullWidthAllocation) + '%';
    }

    lineCordinates(): string {
        return '' + (this.fullWidthAllocation - this.currentStartPoint) + '%';
    }
    maleCordinates(): string {
      if(this.maxLeftValue == null || this.maxLeftValue == 0)
      { 
        return  this.lineCordinates();
      }
        return '' + (this.fullWidthAllocation - this.currentStartPoint - ((this.value / this.maxLeftValue) * this.fullWidthAllocation)) + '%';
    }
    maleTextCordinates(): string {
      if(this.maxLeftValue == null || this.maxLeftValue == 0)
      { 
        return  this.lineCordinates();
      }
        return '' + (this.fullWidthAllocation - 15 - this.currentStartPoint - ((this.value / this.maxLeftValue) * this.fullWidthAllocation)) + '%';
    }
    femaleTextCordinates(): string {
      if(this.maxRightValue == null || this.maxRightValue == 0)
      { 
        return  this.lineCordinates();
      }
        return '' + (this.fullWidthAllocation - this.currentStartPoint + ((this.value2 / this.maxRightValue) * this.fullWidthAllocation)) + '%';
    }
    leftBenchmarkXCoordinates(): string {
      if(this.maxLeftPercentage == null || this.maxLeftPercentage == 0)
      { 
        return  this.lineCordinates();
      }
        return '' + (this.fullWidthAllocation - this.currentStartPoint - ((this.benchmarkLeftValue * 100 / this.maxLeftPercentage) * this.fullWidthAllocation)) + '%';
    } 
    rightBenchmarkXCoordinates(): string {
      if(this.maxRightPercentage == null || this.maxRightPercentage == 0)
      { 
        return  this.lineCordinates();
      }
        return '' + (this.fullWidthAllocation - this.currentStartPoint + ((this.benchmarkRightValue * 100 / this.maxRightPercentage) * this.fullWidthAllocation)) + '%';
    }

    getMalePercentText(): any {
        if (this.MeasureOption === 'Number') {
            return this.value;
        }
        else {
            return `${this.percentLeft}%`;
        }
    }

    getFemalePercentText(): any {
        if (this.MeasureOption === 'Number') {
            return this.value2;
        }
        else { 
            return `${this.percentRight}%`;
        }
    }

}
