export const locale = {
    lang: 'en',
    data: {
        'AddFilter': {
            'ClientName': 'Client Name',
            'Entity': 'Entity',
            'Division': 'Division',
            'Plan': 'Plan',
            'TimePeriod': 'Time Period',
            'PolicyType': 'Policy Type',
            'AgeBand': 'Age Band',
            'Gender': 'Gender',
            'ClaimType': 'Claim Type',
            'ClaimType2': 'Claim Type 2',
            'ClaimType3': 'Claim Type 3',
            'Relationship': 'Relationship',
            'DiagnosisCategory': 'Diagnosis Category',
            'DiagnosisChapter': 'Diagnosis Chapter',
            'Location': 'Location',
            'IsChronic': 'Is Chronic',
            'Carrier': 'Carrier',
            'LOB': 'LOB',
            'SubLOB': 'Sub LOB',
            'Network': 'Network',
            'ProviderName': 'Provider Name',
            'ProviderLocation': 'Provider Location',
            'ProviderType': 'Provider Type'
        }
    }
};
