export const locale = {
  lang: 'es-mx',
  data: {
    'Storyboard': {
        'AddAll': 'Añadir todo',
        'Save': 'Salvar',
        'Export': 'Exportar',
        'Library': 'Biblioteca',
        'Comments': 'Comentarios',
        'CustomViews': 'Vistas personalizadas',
        'CustomViewName': 'Nombre de vista personalizado',
        'ApplyCustomView': 'Aplicar vista personalizada',
        'SaveCustomView': 'Guardar vista personalizada',
        'ResetCustomView': 'Restablecer vista personalizada',
        'NoCustomViewFound': 'No se ha encontrado una vista personalizada',
        'AddToExport': 'Añadir a Exportar',
        'ADD': 'AÑADIR',
        'SAVE': 'SALVAR',
        'CANCEL': 'CANCELAR'
    }
  }
};
