
  <mat-form-field appearance="outline" [style.width.px]=120 >
    <input matInput [matDatepicker]="picker" [placeholder]="title" 
    [min]="minDt" [max]="maxDt"
    (click)="dateInputClicked()" (keydown)="false" autocomplete="off" [value]="dateValue" [disabled]="disableDatePicker" >
    <!--<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
    <mat-datepicker #picker (monthSelected)="monthSelected($event)" startView="multi-year"></mat-datepicker>
  </mat-form-field>


