import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FilterOptions } from "../shared/models/FilterOptions";

@Component({
  selector: 'dashboard-tempalte-base',
  template: '',
})
export class DashboardTemplateBase {
  @Input() imageUrlBase: string;
  @Input() properties: any;
  @Input() data: any;
  @Input() showFilter = false;
  @Input() isFilterOptionsLoading = true;
  @Input() filterOptions: FilterOptions = new FilterOptions();
  @Input() isExport?= false;
  @Input() eligibleForBenchmark?= false;
  @Output() action?: EventEmitter<any> = new EventEmitter<any>();

  initData() {

  }
}
