import { Component, OnInit, ChangeDetectorRef, HostListener, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/services/user.service';
import { Store } from '@ngrx/store';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { State } from 'app/_reducers';
import { GlobalfiltersService } from 'app/services/globalfilters.service';
import { DashboardService } from 'app/services/dashboard.service';
import { StoryService } from 'app/services/story.service';
import { ActivatedRoute } from '@angular/router';
import { CartService } from 'app/services/cart.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TableauService } from 'app/services/tableau.service';
import { User } from 'app/models/user.model';
import { locale as navigationEnglish } from './i18n/en';
import { locale as navigationSpanishCL } from './i18n/es-cl';
import { locale as navigationSpanishCO } from './i18n/es-co';
import { locale as navigationSpanishEC } from './i18n/es-ec';
import { locale as navigationSpanishMX } from './i18n/es-mx';
import { locale as navigationSpanishPE } from './i18n/es-pe';
import { locale as navigationPortugueseBR } from './i18n/pt-br';
import { locale as navigationItalianIT } from './i18n/it';
import { DashboardcontainerComponent } from 'app/dashboards/dashboardcontainer/dashboardcontainer.component';
import { AdditionalFiltersService } from 'app/dashboards/shared/additional-filters.service';
import { userActionLog, LogActionType } from 'app/models/log.model';
import { LoggerService } from 'app/services/logger.service';
import * as moment from 'moment';

declare var tableau: any;

@Component({
  selector: 'app-storycontainer',
  templateUrl: './storycontainer.component.html',
  styleUrls: ['./storycontainer.component.css']
})
export class StorycontainerComponent implements OnInit {
  logModel: userActionLog = new userActionLog();

  user: User;
  clientGroupFilterOptions: string[];
  isLoading = false;
  isLoadingOverlay = false;
  isApplyingCustomViewOverlay = false;
  isLoadingExportAllOverlay = false;
  exportAllMessage = '';
  filterChangeLogDict: any = {};
  showLibrary = false;
  storyState = new StoryState();
  isWindowFullScreen = false;
  viewModes: any = {
    stream: 'stream',
    tile: 'tile',
    single: 'single',
  };

  @ViewChild('dashLibrary') dashLibrary;
  @ViewChildren(DashboardcontainerComponent) dashContainers: QueryList<DashboardcontainerComponent>;

  constructor(
    private _ref: ChangeDetectorRef,
    private _tableauService: TableauService,
    private _translateService: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _cartService: CartService,
    private route: ActivatedRoute,
    private storyService: StoryService,
    private dashboardService: DashboardService,
    private globalFiltersService: GlobalfiltersService,
    private toaster: ToasterService,
    private store: Store<State>,
    private userService: UserService,
    private _additionalFilterService: AdditionalFiltersService,
    private _loggerService: LoggerService) {

    this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      const langParam = this.globalFiltersService.getLanguageParameter(event);
      this.storyState.workbookList.forEach(wbListItem => {
        if (wbListItem.viz) { // TODO: Handle this for non-tableau dashboards
          const wb = wbListItem.viz.getWorkbook();
          wb.changeParameterValueAsync(langParam.name, langParam.value);
        }
      });
    });

    this.userService.userChanged.subscribe((e) => {
      this._additionalFilterService.clear();

      this.isLoadingOverlay = true;

      this.clientGroupFilterOptions = this.userService.user && this.userService.user.client ? this.userService.user.client.groupFilterString.split('|') : null;

      if (this.storyState.workbookList.length > 0) { location.reload(); }
      this.storyState.workbookList.forEach(wbListItem => {
        const wb = wbListItem.viz.getWorkbook();
        this.user = e;
        this.storyState.dashboardProperties = this.getDashboardProperties();

        wb.revertAllAsync().then(() => {
          if (this.user.client && this.user.country) {
            wb.changeParameterValueAsync(this.globalFiltersService.PARAM_CLIENT, this.user.client.name).then(() => {
              this.isLoadingOverlay = false;
            })
            wb.changeParameterValueAsync(this.globalFiltersService.PARAM_COUNTRY, this.user.country.name);
            wb.changeParameterValueAsync(this.globalFiltersService.PARAM_ROLLINGWINDOW, this.user.rollingPeriod);
            wb.changeParameterValueAsync(this.globalFiltersService.PARAM_CURRENTFLAG, this.user.periodSelection);
            wb.changeParameterValueAsync(this.globalFiltersService.PARAM_CLAIMPAID, this.user.claimPaid);
            wb.changeParameterValueAsync(this.globalFiltersService.PARAM_MAXDATE, moment(new Date(this.user.timePeriod.maxDate), "YYYY/MM/DD"));
            wb.changeParameterValueAsync(this.globalFiltersService.PARAM_PERIOD1STARTDATE, moment(new Date(this.user.timePeriod.period1StartDate), "YYYY/MM/DD"));
            wb.changeParameterValueAsync(this.globalFiltersService.PARAM_PERIOD2STARTDATE, moment(new Date(this.user.timePeriod.period2StartDate), "YYYY/MM/DD"));
            wb.changeParameterValueAsync(this.globalFiltersService.PARAM_PERIOD3STARTDATE, moment(new Date(this.user.timePeriod.period3StartDate), "YYYY/MM/DD"));
            wb.changeParameterValueAsync(this.globalFiltersService.PARAM_PERIOD4STARTDATE, moment(new Date(this.user.timePeriod.period4StartDate), "YYYY/MM/DD"));
            wb.changeParameterValueAsync(this.globalFiltersService.PARAM_MAXCLAIMPAIDDATE, moment(new Date(this.user.timePeriod.maxClaimPaidDate), "YYYY/MM/DD"));
            wb.changeParameterValueAsync(this.globalFiltersService.PARAM_MAXADMITDATE, moment(new Date(this.user.timePeriod.maxAdmitDate), "YYYY/MM/DD"));
          }
        });
      });
    });

    this.route.paramMap.subscribe(params => {
      this.manageRoute(params);
    });

  }

  @HostListener('window:beforeunload', ['$event'])
  doSomething($event) {
    console.log('clearTableau');
    this.clearTableau();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.storyState.activeViewMode == this.viewModes.single) {
      if (event.key == 'ArrowLeft') {
        this.viewPrevious();
      } else if (event.key == 'ArrowRight') {
        this.viewNext();
      }

    }
  }

  ngOnInit() {
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationSpanishCL,
      navigationSpanishCO,
      navigationSpanishEC,
      navigationSpanishMX,
      navigationSpanishPE,
      navigationPortugueseBR,
      navigationItalianIT,
    );

    this.setLayout(this.storyState.layoutStoryBoard);

    this._additionalFilterService.clear();

    this.store.select('user').subscribe(res => {
      this.user = res;
      const globalSettings = this.globalFiltersService.getGlobalFilters(this.user);
      this.storyState.dashboardProperties = this.getDashboardProperties();
    });

    document.addEventListener('webkitfullscreenchange', ((event: CustomEvent) => {
      if (!window.screenTop && !window.screenY) {
        this.isWindowFullScreen = false;
      } else {
        console.log('fullscreen');
        this.isWindowFullScreen = true;
      }
    }) as EventListener);

    this.storyState.dashboardProperties = this.getDashboardProperties();

    setTimeout(() => { if (this.dashLibrary) { this.dashLibrary.isDisabledAdd = false; } }, 6000);
  }

  clearTableau() {
    console.log('clearTableau');
    if (this.storyState) {
      if (this.storyState.workbookList) {
        this.storyState.workbookList.forEach(wb => {
          this.resetCustomView(wb);
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.clearTableau();

    if (this.storyState) {
      if (this.storyState.dashboardList) {
        delete this.storyState.dashboardList;
      }
    }
  }

  manageRoute(params) {
    this.isLoadingOverlay = true;
    setTimeout(() => {
      this.isLoadingOverlay = false;
    }, 10000);

    var mode = params.get('mode');
    var sid = parseInt(params.get('id'));
    this.storyState.workbookList = [];
    this.storyState.dashboardList = [];

    if (!sid && this.storyService.currentStoryId) {
      if (isNaN(sid)) {
        this.storyState.id = null;
        history.pushState({}, null, document.URL);
      }
      else {
        this.storyState.id = this.storyService.currentStoryId;
        history.pushState({}, null, document.URL + '/' + sid);
      }
    }

    if (sid) {
      this.storyState.isReadOnly = mode == 'view';
      this.storyService.getStoryById(sid).toPromise().then(res => {
        const story = res.result;
        localStorage.setItem('story', JSON.stringify(story));
        setTimeout(() => this.storyService.setCurrentOpenStory(story, mode), 100);
        this.initializeStoryStateFromStoriesObj(story);
      });
      const user = this.userService.user;
      if (user.client && user.country) {
        this._loggerService.logStoryViewed(user.client.tier1Id, user.client.name, user.country.tier2Id, user.country.name, sid).toPromise();
      }
    }
  }

  initializeStoryStateFromStoriesObj(story) {
    this.storyState.id = story.storiesId;
    this.storyState.name = story.name;

    const storyMetadata = JSON.parse(story.metadata);
    storyMetadata.forEach(dash => {
      const index = storyMetadata.indexOf(dash);
      this.addDashboard(dash, index);
    });

    const index = this.storyService.getStoryIndex();
    this.viewDashboard(index);
  }

  addDashboard(dash, index) {
    this.storyState.dashboardList.splice(index, 0, dash);
    this.storyState.dashIndex = index;
  }

  viewTableauWorkbook(dash, index) {
    let wb = this.storyState.workbookList.filter(w => w.id == dash.workbook.id)[0];
    if (wb && !wb.viz) return;

    this.storyState.dashIndex = index;

    if (!wb) {
      wb = {
        id: dash.workbook.id,
        type: dash.workbook.type,
      };
      this.storyState.workbookList.push(wb);
      this.storyState.activeWorkbook = wb;
      console.log(wb);
      setTimeout(() => {
        this.renderWorkbook(dash, index);
      }, 400);

    } else {
      this.isLoading = true;
      if (this.storyState.activeWorkbook.id == wb.id) {
        setTimeout(() => {
          this.isLoading = false;
        }, 200)
      }
      const workbook = wb.viz.getWorkbook();
      workbook.activateSheetAsync(dash.name).then(() => {
        this.storyState.activeWorkbook = wb;
        this.isLoading = false;
        if (this.dashLibrary) { this.dashLibrary.isDisabledAdd = false; }
      });
    }
  }

  setindexCustom(objCustom: any) {
    if (this.storyState.dashboardList.length > 1) {
      for (let i = 0; i < this.storyState.dashboardList.length; i++) {
        if (this.storyState.dashboardList[i].id === objCustom.id) {
          this.storyState.dashboardList[i].name = objCustom.name;
          this.viewCustomWorkbook(this.storyState.dashboardList[i], i);
        }
      }
    }
  }

  viewCustomWorkbook(dash, index) {
    let wb = this.storyState.workbookList.find(w => w.id === dash.workbook.id);

    const isReload = true;
    this.storyState.dashIndex = index;

    this.storyState.dashboardProperties.id = dash.id;
    this.storyState.dashboardProperties.name = dash.name;

    if (!wb) {
      wb = {
        id: dash.workbook.id,
        dashId: dash.id,
        type: dash.workbook.type,
      };
      this.storyState.workbookList.push(wb);
    } else if (isReload) {
      wb.dashId = dash.id;
      const dashContainer = this.dashContainers.toArray().find(d => d.template === dash.workbook.id);
      dashContainer.getDashboardData();
    }
    this.storyState.activeWorkbook = wb;
    this.isLoading = false;
    if (this.dashLibrary) { this.dashLibrary.isDisabledAdd = false; }
  }


  viewDashboard(index) {
    if (this.dashLibrary) { this.dashLibrary.isDisabledAdd = true; }
    if (index < 0 || index >= this.storyState.dashboardList.length) { return; }
    const dash = this.storyState.dashboardList[index];

    this.storyService.setStoryIndex(index);

    console.log(dash);
    if (!dash.workbook.type || dash.workbook.type === WorkbookTypeEnum.Tableau) {
      console.log('viewTableauWorkbook');
      this.viewTableauWorkbook(dash, index);
    } else {
      this.viewCustomWorkbook(dash, index);
    }

    const user = this.userService.user;
    if (user.client && user.country && dash && this.storyState.id) {
      this._loggerService.logDashboardViewed(user.client.tier1Id, user.client.name, user.country.tier2Id, user.country.name,
        this.storyState.id, dash.workbook.id, dash.id, dash.name).toPromise();
    }
  }

  goFullScreen() {
    this.storyState.activeViewMode = this.viewModes.single;
    this.setLayout(this.storyState.activeViewMode);

    const element: any = document.getElementById('mainstoryboard');
    if (element.requestFullScreen) {
      element.requestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    }
  }

  viewPrevious() {
    this.viewDashboard(this.storyState.dashIndex - 1);
  }

  viewNext() {
    this.viewDashboard(this.storyState.dashIndex + 1);
  }

  renderWorkbook(dash, index) {
    this.isLoading = true;
    this._tableauService.getToken().toPromise().then((token: any) => {
      console.log(token);
      const div = document.getElementById('wb-' + dash.workbook.id);
      if (!div) { return; }

      const wb = this.storyState.workbookList.filter(w => w.id == dash.workbook.id)[0];
      dash.url = this.getDashboardUrl(dash, token);

      wb.viz = new tableau.Viz(div, dash.url, {
        hideToolbar: true,
        onFirstVizSizeKnown: () => {
          this.isLoading = false;
          if (this.dashLibrary) this.dashLibrary.isDisabledAdd = false;
        },
        onFirstInteractive: () => {
          // turn off loading
          this.storyState.dashIndex = index;
        }
      });
      console.log('before add event listeners');
      wb.viz.addEventListener(tableau.TableauEventName.TAB_SWITCH, ($event) => { this.tableauTabSwitch($event); });
      wb.viz.addEventListener(tableau.TableauEventName.FILTER_CHANGE, ($event) => { this.tableauFilterChange($event); });
      wb.viz.addEventListener(tableau.TableauEventName.PARAMETER_VALUE_CHANGE, ($event) => { this.tableauFilterChange($event); });
    });
  }

  isFilterChangeLocked = false;
  tableauFilterChange($event) {
    console.log('tableauFilterChange', $event);
    const dash = this.storyState.dashboardList[this.storyState.dashIndex];
    if (!this.filterChangeLogDict[dash.workbook.id]) {
      this.filterChangeLogDict[dash.workbook.id] = {};
    }

    if (!this.isFilterChangeLocked) {
      this.isFilterChangeLocked = true;
      const dashChangeLog = this.filterChangeLogDict[dash.workbook.id];
      switch ($event.getEventName()) {
        case tableau.TableauEventName.FILTER_CHANGE:
          $event.getFilterAsync().then(f => {
            dashChangeLog[$event.getFieldName()] = {};
            dashChangeLog[$event.getFieldName()]['type'] = f.getFilterType();
            dashChangeLog[$event.getFieldName()]['selectedValues'] = f.getAppliedValues();
          });
          break;
        case tableau.TableauEventName.PARAMETER_VALUE_CHANGE:
          $event.getParameterAsync().then(p => {
            dashChangeLog[$event.getParameterName()] = {};
            dashChangeLog[$event.getParameterName()]['type'] = 'parameter';
            dashChangeLog[$event.getParameterName()]['selectedValues'] = p.getCurrentValue();
          });
          break;
      }
      setTimeout(() => { this.isFilterChangeLocked = false; }, 3500);
    }
  };

  customDashboardFilterChange($event) {
    console.log('customDashboardFilterChange', $event);
    const dash = this.storyState.dashboardList[this.storyState.dashIndex];
    if (!this.filterChangeLogDict[dash.id]) {
      this.filterChangeLogDict[dash.id] = {};
    }

    const dashChangeLog = this.filterChangeLogDict[dash.id];
    dashChangeLog[$event.data.properties.name]['type'] = 'custom';
    dashChangeLog[$event.data.properties.name]['selectedValues'] = $event.data.properties.value;
  }

  tableauTabSwitch($event) {
    var newTab = $event.getNewSheetName();
    var dash = this.storyState.dashboardList.filter(s =>
      this.storyState.activeWorkbook.id == s.workbook.id && s.name == newTab)[0];
    this.storyState.dashIndex = this.storyState.dashboardList.indexOf(dash);
  };

  private getWBDash(wb) {
    const w = wb.viz.getWorkbook();
    const sheet = w.getActiveSheet();
    const dash = this.storyState.dashboardList.filter(s => s.workbook.id === wb.id && s.name === sheet.getName())[0];
    return dash;
  }

  loadDashboardCustomViews(wb) {
    if (wb.type === WorkbookTypeEnum.Tableau) {
      const dash = this.getWBDash(wb);
      wb.filters = [];
      if (this.user.client && this.user.client.name) {
        this.dashboardService.getDashboardCustomViews(dash.id, this.user.client.name).subscribe(result => {
          wb.filters = result;
        });
      }
    }

    if (wb.type === WorkbookTypeEnum.Custom) {
      if (this.user.client && this.user.client.name) {
        this.dashboardService.getDashboardCustomViews(wb.dashId, this.user.client.name).subscribe(result => {
          wb.filters = result;
        });
      }
    }
  }

  getDashboardUrl(dash, token) {
    let url = this._tableauService.getDashboardUrlPath(token, dash.contentUrl);
    const globalFilterStr = this.globalFiltersService.getGlobalFiltersString(this.user, dash.workbook.id, false);
    if (globalFilterStr) {
      url += ('?' + globalFilterStr);
    }
    return url;
  }

  setLayout(mode) {
    switch (mode) {
      case this.viewModes.stream:
        this.storyState.layoutStoryBoard = 'column';
        // this.checkRender();
        break;
      case this.viewModes.tile:
        this.storyState.layoutStoryBoard = 'row wrap';
        break;
      case this.viewModes.single:
        // this.isSingleViewChartPreviewNavigationShown = true;
        this.storyState.layoutStoryBoard = 'column';
        break;
    }
  }

  toggleLibrary() {
    this.storyState.showStoryBoard = !this.storyState.showStoryBoard;
    this.storyState.libraryFlex = this.storyState.showStoryBoard ? '160px' : '100';
  }

  getTableauContainerHeight() {
    let heightOfOtherElements = 140;
    let height = '820px';

    if (this.storyState.activeViewMode === this.viewModes.single) {
      if (window.innerHeight === screen.height) { heightOfOtherElements = 0; }
      if (window.innerHeight === screen.height && this.storyState.thumbnailNav) { heightOfOtherElements = heightOfOtherElements + 90; }

      if (this.storyState.thumbnailNav) { heightOfOtherElements = heightOfOtherElements + 130; }
      height = 'calc(100vh - ' + heightOfOtherElements + 'px)';
    }

    return height;
  }

  viewTile() {
    this.storyState.activeViewMode = this.viewModes.tile;
    this.setLayout(this.storyState.activeViewMode);
    setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 200);
  }

  viewSingle() {
    this.storyState.activeViewMode = this.viewModes.single;
    this.setLayout(this.storyState.activeViewMode);
    setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 200);
  }

  logUserAction(customView: string, messageTemplate: string) {

    this.logModel.Message = 'User selected to create custom view dashboard';
    this.logModel.CustomViewCreation = customView;
    this.logModel.MessageTemplate = messageTemplate;
    this.logModel.LogEvent = LogActionType.CreateCustomView;
    this.logModel.ActionName = LogActionType.CreateCustomView;
  }

  saveDashboardCustomView(wb) {
    if (wb.type === WorkbookTypeEnum.Tableau) {
      const dash = this.getWBDash(wb);
      const w = wb.viz.getWorkbook();
      const changes = this.filterChangeLogDict[dash.workbook.id];

      const customFilter = {
        name: wb.filterName,
        comments: wb.filterComments,
        filters: { ...changes }
      };

      dash.appliedCustomView = { ...customFilter };
      if (this.user.client && this.user.client.name) {
        this.dashboardService.saveDashboardCustomView(dash.id, this.user.client.name, customFilter.name, customFilter.comments, customFilter.filters)
          .subscribe(result => {
            if (result) {
              console.log('Custom Filters SAVED...');
              console.log(customFilter);
              this.loadDashboardCustomViews(wb);

              delete dash.filterName;
              delete dash.filterComments;
            }
            else {
              alert('Name is already used. Please enter another name.');
            }
          });
      } else {
        this.toaster.showMessage('No client selected.');
      }
      var messageTemplate = JSON.stringify({ url: dash.url, contentUrl: dash.contentUrl, appliedCustomView: dash.appliedCustomView, workbook: dash.workbook, project: dash.project, customFilter });
      this.logUserAction(wb.filterName, messageTemplate);
    }

    if (wb.type === WorkbookTypeEnum.Custom) {
      const dashContainer = this.dashContainers.toArray().find(d => d.properties.id === wb.dashId);
      const dashboardExtract = dashContainer.extractDataForExport();

      const customFilter = {
        name: wb.filterName,
        comments: wb.filterComments,
        filters: { ...dashboardExtract.changeFilterLogDictionary }
      };

      if (this.user.client && this.user.client.name) {
        this.dashboardService.saveDashboardCustomView(wb.dashId, this.user.client.name, customFilter.name, customFilter.comments, customFilter.filters)
          .subscribe(result => {
            if (result) {
              console.log('Custom Filters SAVED...');
              console.log(customFilter);
              this.loadDashboardCustomViews(wb);
            }
            else {
              alert('Name is already used. Please enter another name.');
            }
          });

        var messageTemplate = JSON.stringify({ dashboardExtract, customFilter });
        this.logUserAction(wb.filterName, messageTemplate);

      } else {
        this.toaster.showMessage('No client selected.');
      }
    }
  }

  closeDashboardCustomView(wb) {
    wb.filterName = '';
    wb.filterComments = '';
  }

  applyCustomView(wb, filter) {
    if (wb.type === WorkbookTypeEnum.Tableau) {
      this.isApplyingCustomViewOverlay = true;
      const dash = this.getWBDash(wb);
      const workbook = wb.viz.getWorkbook();
      console.log(filter);
      this.isLoading = true;
      wb.viz.pauseAutomaticUpdatesAsync().then(() => {

        workbook.revertAllAsync().then(() => {
          setTimeout(() => {
            wb.viz.resumeAutomaticUpdatesAsync();
            this.isLoading = false;
          }, 10000);

          const activeSheet = workbook.getActiveSheet();
          dash['appliedCustomView'] = filter;

          switch (activeSheet.getSheetType()) {
            case 'worksheet':
              const ws = activeSheet;
              this.setWorksheetFilter(workbook, ws, filter, wb.id);
              break;
            case 'dashboard':
              const worksheets = activeSheet.getWorksheets();
              worksheets.forEach(ws => {
                this.setWorksheetFilter(workbook, ws, filter, wb.id);
              });
              break;
            case 'story':
              break;
          }

          setTimeout(() => {
            this.isApplyingCustomViewOverlay = false;
          }, 7000);
        });
      });

      var messageTemplate = JSON.stringify({ url: dash.url, contentUrl: dash.contentUrl, appliedCustomView: dash.appliedCustomView, workbook: dash.workbook, project: dash.project, filter });

      this.logUserAction(wb.filterName, messageTemplate);

    }

    if (wb.type === WorkbookTypeEnum.Custom) {
      const dashContainer = this.dashContainers.toArray().find(d => d.properties.id === wb.dashId);
      dashContainer.changeFilterLogDictionary = filter.filters;

      Object.keys(filter.filters).forEach((changeFilterLogDictionaryKey: string) => {
        dashContainer.properties[changeFilterLogDictionaryKey] = filter.filters[changeFilterLogDictionaryKey];
      });

      dashContainer.getDashboardData();
      this.logUserAction(wb.filterName, JSON.stringify({ dashContainer, filter }));

    }

  }

  deleteDashboardCustomView(wb, filterRecord, filterList) {
    console.log(wb);
    console.log(filterRecord);
    filterList.splice(filterList.indexOf(filterRecord), 1);
    this.dashboardService.deleteDashboardCustomView(filterRecord.userCustomFiltersId).subscribe(result => {
      if (result) {
        this.loadDashboardCustomViews(wb);
      }
      else {
        alert('Filter record does not exist.');
      }
    });
  }

  resetCustomView(wb) {
    if (wb.type === WorkbookTypeEnum.Tableau) {
      if (wb.viz && wb.viz.getWorkbook) {
        try {
          const workbook = wb.viz.getWorkbook();
          workbook.revertAllAsync();

          const globalFiltersObject = this.globalFiltersService.getGlobalFiltersObject(this.user, wb.id, false);

          for (const globalFilterKey of Object.keys(globalFiltersObject)) {
            workbook.changeParameterValueAsync(globalFilterKey, globalFiltersObject[globalFilterKey]);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }

    if (wb.type === WorkbookTypeEnum.Custom) {
      const dashContainer = this.dashContainers.toArray().find(d => d.properties.id === wb.dashId);

      if (typeof dashContainer === 'undefined') {
        return;
      }

      dashContainer.populateProperties();
      console.log(dashContainer.properties);
      console.log(this.storyState.dashboardProperties);
      setTimeout(() => {
        dashContainer.getDashboardData();
      });
    }
  }

  async setWorksheetFilter(wb, ws, filter, workbookId) {
    console.log('Apply Filters');
    const lang = this._translateService.store.currentLang;
    console.log(lang);
    const langTableauStr = this.globalFiltersService.getLanguageByLangCode(lang);
    wb.changeParameterValueAsync(this.globalFiltersService.PARAM_LANG, langTableauStr);

    const globalFiltersObject = this.globalFiltersService.getGlobalFiltersObject(this.user, workbookId, false);

    for (const globalFilterKey of Object.keys(globalFiltersObject)) {
      wb.changeParameterValueAsync(globalFilterKey, globalFiltersObject[globalFilterKey]);
    }

    for (var filterName in filter.filters) {
      console.log(filterName);
      const f = filter.filters[filterName];
      switch (f.type) {
        case 'parameter':
          wb.changeParameterValueAsync(filterName, f.selectedValues.value);
          break;
        case 'categorical':
          await ws.applyFilterAsync(filterName, f.selectedValues.filter(v => v.value != null).map(v => v.formattedValue), tableau.FilterUpdateType.REPLACE);
          break;
      }
    }
  }

  addAllToExport() {
    if (this.storyState.dashboardList.length === 0) {
      return;
    }

    this.isLoadingExportAllOverlay = true;

    // Preprocess each dashboard at 20 secs each
    for (let i = 0; i < this.storyState.dashboardList.length; i++) {
      setTimeout(() => {
        this.exportAllMessage = 'Preprocessing Dashboard (' + (i + 1) + ' of ' + this.storyState.dashboardList.length + ')...';
        this.viewDashboard(i);
      }, i * 20000, i);
    }

    // Adding each processed dashboard to export cart at 1 sec each
    setTimeout(() => {
      for (let i = 0; i < this.storyState.dashboardList.length; i++) {
        setTimeout(() => {
          this.exportAllMessage = 'Adding Dashboard (' + (i + 1) + ' of ' + this.storyState.dashboardList.length + ') to Cart...';
          this.viewDashboard(i);
          setTimeout(() => {
            this.addToExport(i);
          }, 500);
        }, i * 1000, i);
      }

      // Upon completion of adding dashboards to cart, go to first dashboard and hide the overlay
      setTimeout(() => {
        this.viewDashboard(0);
        this.isLoadingExportAllOverlay = false;
      }, this.storyState.dashboardList.length * 1000);

    }, (this.storyState.dashboardList.length + 1) * 20000);
  }

  addToExport(index) {
    const dash = this.storyState.dashboardList[index];

    if (dash.workbook.type === WorkbookTypeEnum.Tableau) {
      const changes = this.filterChangeLogDict[dash.workbook.id];
      const changeSummary = [];

      let changeSummaryString = '';
      const changeSummaryStringList = [];

      if (typeof changes !== 'undefined') {
        const changeProps = Object.keys(changes);

        for (const prop of changeProps) {
          if (Array.isArray(changes[prop].selectedValues)) {
            changeSummary.push({ name: prop, type: changes[prop].type, selectedValues: changes[prop].selectedValues.map(v => v.value) });
          }
          else {
            changeSummary.push({ name: prop, type: changes[prop].type, selectedValues: changes[prop].selectedValues.value });
          }
        }

        for (let i = 0; i < changeSummary.length; i++) {
          const changeSummaryItem = changeSummary[i];
          if (Array.isArray(changeSummaryItem.selectedValues)) {
            const encodedSelectedValues = [];

            changeSummaryItem.selectedValues.forEach(selectedValue => {
              encodedSelectedValues.push(this.encodeSpecialCharacter(encodeURI(selectedValue)));
            });

            changeSummaryStringList.push(encodeURI('vf_' + changeSummaryItem.name) + '=' + encodedSelectedValues.join(','));
          }
          else {
            changeSummaryStringList.push(this.encodeSpecialCharacter(encodeURI('vf_' + changeSummaryItem.name) + '=' + encodeURI(changeSummaryItem.selectedValues)));
          }
        }

        if (changeSummaryStringList.length > 0) {
          changeSummaryString = changeSummaryStringList.join('&');
        }
      }

      let comments = '';
      let filterName = '';

      if (typeof dash.appliedCustomView !== 'undefined') {
        comments = dash.appliedCustomView.comments;
        filterName = dash.appliedCustomView.name;
      }

      this._cartService.addItem(
        dash.id,
        dash.name,
        null,
        null,
        dash.contentUrl,
        dash.workbook.id,
        dash.id,
        dash.imageBase64String,
        comments,
        filterName,
        changeSummary,
        changeSummaryString,
        WorkbookTypeEnum.Tableau);
    }

    if (dash.workbook.type === WorkbookTypeEnum.Custom) {
      const dashContainer = this.dashContainers.toArray().find(d => d.template === dash.workbook.id);
      const dashboardExtract = dashContainer.extractDataForExport();

      const changeSummary = [];

      Object.keys(dashboardExtract.changeFilterLogDictionary).forEach((changeFilterLogDictionaryKey: string) => {
        changeSummary.push({ name: changeFilterLogDictionaryKey, type: WorkbookTypeEnum.Custom.toString(), selectedValues: dashboardExtract.changeFilterLogDictionary[changeFilterLogDictionaryKey] });
      });

      this._cartService.addItem(
        dash.id,
        dash.name,
        dashboardExtract.chartData,
        dashboardExtract.chartProperties,
        dash.contentUrl,
        dash.workbook.id,
        dash.id,
        dash.imageBase64String,
        '',
        '',
        changeSummary,
        '',
        WorkbookTypeEnum.Custom);
    }
  }

  openDownloadImageDialog(wb) {
    wb.viz.showExportImageDialog();
  }

  // edit functions

  onAdd($event) {
    if ($event.data) {
      const dashClone = JSON.parse(JSON.stringify($event.data));
      this.addDashboard(dashClone, this.storyState.dashboardList.length);
      this.viewDashboard(this.storyState.dashboardList.length - 1);
      if (!this.storyState.showStoryBoard) this.toggleLibrary();
    }
  }

  removeDashboard() {
    const index = this.storyState.dashIndex;
    const dash = this.storyState.dashboardList[index];
    if (this.filterChangeLogDict[dash.id]) { delete this.filterChangeLogDict[dash.id]; }
    if (this.filterChangeLogDict[dash.workbook.id]) { delete this.filterChangeLogDict[dash.workbook.id]; }
    this.storyState.dashboardList.splice(index, 1);

    if (typeof this.storyState.dashboardProperties !== 'undefined') {
      delete this.storyState.dashboardProperties[dash.id];
    }

    const wb = this.storyState.workbookList.filter(w => w.id === dash.workbook.id)[0]
    if (wb) {
      if (this.storyState.dashboardList.filter(d => d.workbook.id === wb.id).length === 0) {
        const wbIndex = this.storyState.workbookList.indexOf(wb);
        this.storyState.workbookList.splice(wbIndex, 1);
      }
    }

    if (index >= this.storyState.dashboardList.length) {
      this.storyState.dashIndex = index - 1;
    }
    this.viewDashboard(this.storyState.dashIndex);
    if (this.storyState.dashboardList.length === 0) {
      this.isLoading = false;
      if (this.dashLibrary) { this.dashLibrary.isDisabledAdd = false; }
    }

    this.dashLibrary.updateSelection();
  }

  moveUp(index) {
    if (index > 0) {
      const dash = { ...this.storyState.dashboardList[index] };
      this.storyState.dashboardList.splice(index, 1);
      this.addDashboard(dash, index - 1);
    }
  }

  moveDown(index) {
    if (index < this.storyState.dashboardList.length - 1) {
      const dash = { ...this.storyState.dashboardList[index] };
      this.storyState.dashboardList.splice(index, 1);
      this.addDashboard(dash, index + 1);
    }
  }

  storyValid() {
    return this.storyState.name && this.storyState.dashboardList.length > 0;
  }

  save() {
    this.isLoading = true;
    const meta = this.storyState.dashboardList.map(function (d) {
      return {
        imageBase64String: d.imageBase64String,
        src: d.src, // thumbnail for custom
        id: d.id,
        name: d.name,
        contentUrl: d.contentUrl,
        project: d.project,
        workbook: d.workbook
      }
    });
    const metadata = JSON.stringify(meta);
    this.storyService.saveStory({
      id: this.storyState.id,
      storyName: this.storyState.name,
      metadata: metadata
    }).toPromise()
      .then(res => {
        if (!this.storyState.id) {
          this.storyState.id = res.result.result.storiesId;
          history.pushState({}, null, document.URL + '/' + res.result.result.storiesId);
        }
        this.toaster.showMessage('Story saved.', ToasterServiceModes.OK)
      })
      .catch(res => { this.toaster.showMessage('Error'); this.isLoading = false; })
      .finally(() => this.isLoading = false)
  }

  scrollToPrevious() {
    const scrollableRegion = document.getElementsByClassName('single-view-scrollable-region')[0];
    const dashboardRect = document.getElementsByClassName('single-view-card')[0].getBoundingClientRect();
    scrollableRegion.scrollLeft -= dashboardRect.width;
  }

  scrollToNext() {
    const scrollableRegion = document.getElementsByClassName('single-view-scrollable-region')[0];
    const dashboardRect = document.getElementsByClassName('single-view-card')[0].getBoundingClientRect();
    scrollableRegion.scrollLeft += dashboardRect.width;
  }

  updateFocus() {
    window.focus();
  }

  getDashboardProperties() {
    return {
      client: this.user.client ? this.user.client : null,
      country: this.user.country ? this.user.country.name : null,
      rollingPeriod: this.user.country ? this.user.rollingPeriod : null,
      periodSelection: this.user.periodSelection ? this.user.periodSelection : null,
      claimPaid: this.user.claimPaid,
      maxDate: this.user.timePeriod ? moment(new Date(this.user.timePeriod.maxDate)).format('YYYY/MM/DD') : null,
      period1StartDate: this.user.timePeriod && this.user.timePeriod.period1StartDate ? moment(new Date(this.user.timePeriod.period1StartDate)).format('YYYY/MM/DD') : null,
      period2StartDate: this.user.timePeriod && this.user.timePeriod.period2StartDate ? moment(new Date(this.user.timePeriod.period2StartDate)).format('YYYY/MM/DD') : null,
      period3StartDate: this.user.timePeriod && this.user.timePeriod.period3StartDate ? moment(new Date(this.user.timePeriod.period3StartDate)).format('YYYY/MM/DD') : null,
      period4StartDate: this.user.timePeriod && this.user.timePeriod.period4StartDate ? moment(new Date(this.user.timePeriod.period4StartDate)).format('YYYY/MM/DD') : null,
      maxClaimPaidDate: this.user.timePeriod ? moment(new Date(this.user.timePeriod.maxClaimPaidDate)).format('YYYY/MM/DD') : null,
      maxAdmitDate: this.user.timePeriod ? moment(new Date(this.user.timePeriod.maxAdmitDate)).format('YYYY/MM/DD') : null
    }
  }

  private encodeSpecialCharacter(unencodedString: string): string {
    unencodedString = unencodedString.replace(/\+/gi, '%5C%2B').replace(/,/gi, '%5C%2C').replace(/\&/gi, '%26').replace(/\//gi, '%2F');
    return unencodedString;
  }
}

export class Dashboard {
  imageBase64String: string;
  id: number;
  name: string;
  content: string;
  project: string;
  workbook: string;
}

export class Workbook {
  id: number;
  type?: WorkbookTypeEnum;
  isTableau?: boolean;
  viz?: any;
  dashId?: any;
}

export enum WorkbookTypeEnum {
  Tableau = 'TABLEAU',
  Custom = 'CUSTOM',
}

export class StoryState {
  id: number;
  name = '';
  metadata: any;
  isReadOnly: boolean;
  dashboardList = [];
  dashIndex = 0;
  workbookList: Workbook[] = [];
  activeWorkbook: any;
  activeViewMode = 'single';
  thumbnailNav = false;
  layoutStoryBoard = 'column';
  showStoryBoard = true;
  libraryFlex = '160px';
  dashboardProperties: any;
}
