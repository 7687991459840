import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { Store } from '@ngrx/store';
import { State } from 'app/_reducers';
import { FWBService } from 'app/fwb/services/fwb.service';
import { User } from 'app/models/user.model';
import { navigation } from 'app/navigation/navigation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BenefitQuestions } from 'app/fwb/constants/fwb-charts-data';
import { PensionService } from 'app/fwb/services/pension.service';

@Component({
  selector: 'pension-dashboard',
  templateUrl: './pension-dashboard.component.html',
  styleUrls: ['./pension-dashboard.component.scss']
})
export class PensionDashboardComponent implements OnInit, OnChanges {
  height: any = "380px";
  width: any = "98%";
  scoreCardheight: any = "540px";
  scoreCardwidth: any = "95%";
  cardheight: any = "490px";
  cardwidth: any = "98%";
  fuseConfig: any;
  navigation: any;
  //Local state 
  questionsData: any[];
  questionsDetails: any[];
  selectedQuestion: number = 5;
  fwb: any;
  isChartVisible: boolean = true;
  contenttop: string = "70px";
  chartId = "topBenefitChart";
  gridlines: any = [];
  public user: User;
  categories: any[] = [];
  questions: any[] = [];
  constructor(
    private _pensionService: PensionService,
    private _fuseConfigService: FuseConfigService,
    private _fwbService: FWBService,
    private store: Store<State>,
    private _router: Router
  ) {
    // Set the defaults
    this.navigation = navigation;
  }
 
  ngOnInit() { 
  }

  ngOnChanges() {
    // this.changeQuestion(this.selectedQuestion);
  }
 
  getUser() {

  }

}
