<div
    id="avgUtilCostDashboard"
    style="min-height: 795px; padding: 8px; overflow: auto; position: relative"
    fxLayout="row"
>
    <div fxFlex fxLayout="column">
        <div id="avgUtilCostHeader" class="dash-header">
            <span *ngIf="properties.name == 'Average Cost Impact'">{{
                "AvgCostUtil.AverageCostImpact" | translate
            }}</span>
            <span *ngIf="properties.name == 'Utilization Impact'">{{
                "AvgCostUtil.UtilizationImpact" | translate
            }}</span>
            <button
                id="avgUtilCostHeaderBtn"
                mat-icon-button
                style="float: right"
                (click)="showFilter = !showFilter"
                *ngIf="!isFilterOptionsLoading"
            >
                <app-filter-button
                    [isClientName]="true"
                    [isEntity]="true"
                    [isPolicyType]="true"
                ></app-filter-button>
            </button>
        </div>
        <div id="avgUtilCostHeaderNote" fxLayout="row" style="width: 100%">
            <div
                id="avgUtilCostHeaderNoteCurrency"
                class="note"
                style="text-align: left; padding-left: 5px"
                fxFlex
            >
                {{
                    "AvgCostUtil.AllAmountsDisplayedInLocalCurrency" | translate
                }}
            </div>
            <div fxFlex></div>
            <div
                id="avgUtilCostHeaderPeriod"
                *ngIf="dateRange && properties.chartTable === ChartTable.Table"
                style="font-size: 14px; padding: 3px"
            >
                {{ dateRange }}
            </div>
        </div>
        <div fxLayout="row">
            <div fxFlex="60%" fxLayout="column" style="margin-top: 5px">
                <div
                    id="avgUtilCostPerData"
                    fxLayout="row"
                    style="
                        margin-left: 15px;
                        max-width: 750px;
                        text-align: center;
                    "
                >
                    <span
                        id="avgUtilCostTrend"
                        fxFlex="20%"
                        class="font20 fontBold"
                        >{{
                            overallImpact * 100 | roundToDecimalPlace: 1
                        }}%<br />
                        <span id="avgUtilCostTrendTxt" class="font16">{{
                            "AvgCostUtil.OverallTrend" | translate
                        }}</span></span
                    >
                    <span fxFlex="5%" class="font20 fontBold">
                        <mat-icon>drag_handle</mat-icon>
                    </span>
                    <span
                        id="avgUtilCostVal"
                        fxFlex="20%"
                        class="font20 fontBold"
                        [ngClass]="[
                            this.properties.id == 'AverageCost'
                                ? 'AvgUtilColor'
                                : ''
                        ]"
                        >{{ averageCostImpact * 100 | roundToDecimalPlace: 1 }}%
                        <br />
                        <span id="avgUtilCostTxt" class="font16">{{
                            "AvgCostUtil.AverageCost" | translate
                        }}</span></span
                    >
                    <span fxFlex="5%">
                        <mat-icon
                            >{{
                                ("+"
                                    | toAddOrMinusOperator
                                        : utilizationImpact) == "+"
                                    ? "add"
                                    : "remove"
                            }}
                        </mat-icon>
                    </span>
                    <span
                        id="avgUtilCostUtilVal"
                        fxFlex="20%"
                        class="font20 fontBold"
                        [ngClass]="[
                            this.properties.id == 'Utilization'
                                ? 'AvgUtilColor'
                                : ''
                        ]"
                        >{{
                            utilizationImpact * 100
                                | numberAbsolute
                                | roundToDecimalPlace: 1
                        }}%
                        <br />
                        <span id="avgUtilCostUtilTxt" class="font16">{{
                            "AvgCostUtil.Utilization" | translate
                        }}</span>
                    </span>
                    <span fxFlex="5%">
                        <mat-icon
                            >{{
                                ("+"
                                    | toAddOrMinusOperator
                                        : mixOfServicesImpact) == "+"
                                    ? "add"
                                    : "remove"
                            }}
                        </mat-icon>
                    </span>
                    <span
                        id="avgUtilCostMixVal"
                        fxFlex="20%"
                        class="font20 fontBold"
                        >{{
                            mixOfServicesImpact * 100
                                | numberAbsolute
                                | roundToDecimalPlace: 1
                        }}%<br />
                        <span id="avgUtilCostMixTxt" class="font16">{{
                            "AvgCostUtil.ServicesReceived" | translate
                        }}</span>
                    </span>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <!-- <div fxFlex="20%">
                    </div> -->
                    <div
                        id="avgUtilCostKeyPlus"
                        fxFlex="100%"
                        class="dashbox margintop20"
                    >
                        <div
                            class="title"
                            style="background-color: #101e7f"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                        >
                            <span
                                >{{
                                    "AvgCostUtil.KeyImpacts" | translate
                                }}
                                (+)</span
                            >
                        </div>
                        <div
                            fxLayout="row"
                            class="tableline"
                            *ngFor="let positiveKeyImpact of positiveKeyImpacts"
                            style="padding: 8px"
                        >
                            <div fxFlex="30%">
                                {{ positiveKeyImpact.dimension | translate }}
                            </div>
                            <div fxFlex="45%">
                                {{
                                    positiveKeyImpact.secondaryDimension
                                        | translate
                                }}
                            </div>
                            <div fxFlex="25%">
                                {{
                                    positiveKeyImpact.impact
                                        | roundToDecimalPlace: 1
                                }}%
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <!-- <div fxFlex="20%">
                    </div> -->
                    <div
                        id="avgUtilCostKeyMinus"
                        fxFlex="100%"
                        class="dashbox margintop20"
                    >
                        <div
                            class="title"
                            style="background-color: #888"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                        >
                            <span
                                >{{
                                    "AvgCostUtil.KeyImpacts" | translate
                                }}(-)</span
                            >
                        </div>
                        <div
                            fxLayout="row"
                            class="tableline"
                            *ngFor="let negativeKeyImpact of negativeKeyImpacts"
                            style="padding: 8px"
                        >
                            <div fxFlex="30%">
                                {{ negativeKeyImpact.dimension | translate }}
                            </div>
                            <div fxFlex="45%">
                                {{
                                    negativeKeyImpact.secondaryDimension
                                        | translate
                                }}
                            </div>
                            <div fxFlex="25%">
                                {{
                                    negativeKeyImpact.impact
                                        | roundToDecimalPlace: 1
                                }}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div fxFlex="40%" fxLayout="row" fxLayoutAlign="center center">
                <ng-container id="avgUtilCostDonut" *ngIf="parentLoaded">
                    <app-donut
                        [datalist]="donutData"
                        [maxlabelLength]="23"
                        [param]="properties.dimension"
                        [isRoundOffdonutValueEnabled]="false"
                        [propertyID]="this.properties.id"
                        [elementRefParent]="elementRefAvg"
                        [totalPercent]="totalPercent"
                    >
                    </app-donut>
                </ng-container>
            </div>
        </div>
        <div id="avgUtilCostLowerHeader">
            <div
                *ngIf="
                    !isAvgCost &&
                    properties.mode == CostOption.ClaimsPerClaimant
                "
                style="
                    font-weight: bold;
                    font-size: 18px;
                    margin-left: 15px;
                    margin-top: 5px;
                    border-bottom: 1px solid #222;
                    margin-bottom: 5px;
                "
            >
                {{ "AvgCostUtil.ClaimsPerClaimant" | translate }}
            </div>
            <div
                *ngIf="
                    !isAvgCost && properties.mode == CostOption.UtilizationRatio
                "
                style="
                    font-weight: bold;
                    font-size: 18px;
                    margin-left: 15px;
                    margin-top: 5px;
                    border-bottom: 1px solid #222;
                    margin-bottom: 5px;
                "
            >
                {{ "AvgCostUtil.UtilizationRatio" | translate }}
            </div>
            <div
                *ngIf="!isAvgCost && properties.mode == CostOption.FreqOfUse"
                style="
                    font-weight: bold;
                    font-size: 18px;
                    margin-left: 15px;
                    margin-top: 5px;
                    border-bottom: 1px solid #222;
                    margin-bottom: 5px;
                "
            >
                {{ "AvgCostUtil.FreqOfUse" | translate }}
            </div>
            <div
                *ngIf="isAvgCost && properties.mode == CostOption.CostPerClaim"
                style="
                    font-weight: bold;
                    font-size: 18px;
                    margin-left: 15px;
                    margin-top: 5px;
                    border-bottom: 1px solid #222;
                    margin-bottom: 5px;
                "
            >
                {{ "AvgCostUtil.CostPerClaim" | translate }}
            </div>
            <div
                *ngIf="
                    isAvgCost && properties.mode == CostOption.CostPerClaimant
                "
                style="
                    font-weight: bold;
                    font-size: 18px;
                    margin-left: 15px;
                    margin-top: 5px;
                    border-bottom: 1px solid #222;
                    margin-bottom: 5px;
                "
            >
                {{ "AvgCostUtil.CostPerClaimant" | translate }}
            </div>
        </div>
        <div fxFlex fxLayout="row">
            <div [fxFlex]="2" fxLayout="column"></div>
            <div
                id="avgUtilCostTable"
                [fxFlex]="98"
                fxLayout="column"
                *ngIf="properties.chartTable == ChartTable.Table"
            >
                <div
                    id="avgUtilCostTableHeader"
                    fxFlex
                    fxLayout="row"
                    class="tableheader"
                >
                    <div
                        *ngIf="
                            properties.dimension == ClaimTypeOption.ClaimType
                        "
                        [fxFlex]="40"
                    >
                        {{ "AvgCostUtil.ClaimType" | translate }}
                    </div>
                    <div
                        *ngIf="properties.dimension == ClaimTypeOption.Network"
                        [fxFlex]="40"
                    >
                        {{ "AvgCostUtil.Network" | translate }}
                    </div>
                    <div
                        *ngIf="
                            properties.dimension == ClaimTypeOption.ProviderType
                        "
                        [fxFlex]="40"
                    >
                        {{ "AvgCostUtil.ProviderType" | translate }}
                    </div>
                    <div
                        *ngIf="
                            properties.dimension == ClaimTypeOption.Relationship
                        "
                        [fxFlex]="40"
                    >
                        {{ "AvgCostUtil.Relationship" | translate }}
                    </div>
                    <div [fxFlex]="15">
                        {{ "AvgCostUtil.Prior" | translate }}
                    </div>
                    <div [fxFlex]="15">
                        {{ "AvgCostUtil.Current" | translate }}
                    </div>
                    <div [fxFlex]="15">
                        {{ "AvgCostUtil.YoYChange" | translate }}
                    </div>
                    <div
                        [fxFlex]="15"
                        *ngIf="eligibleForBenchmark && !noneSelected"
                    >
                        {{ "AvgCostUtil.Benchmark" | translate }}
                    </div>
                </div>
                <div
                    fxFlex
                    fxLayout="row"
                    class="tablerow"
                    *ngFor="let xo of avgItemsGrid"
                >
                    <div [fxFlex]="40">{{ xo.dimension }}</div>

                    <!-- Prior -->
                    <div [fxFlex]="15">
                        <ng-container *ngIf="properties.id != 'Utilization'">
                            {{
                                xo.prior
                                    | roundToDecimalPlace
                                        : (properties.country === "Chile"
                                              ? 2
                                              : 0)
                                    | numberFormat
                            }}
                        </ng-container>

                        <ng-container *ngIf="utilClaimsPerClaimant">
                            {{
                                xo.prior
                                    | roundToDecimalPlace
                                        : (properties.country === "Chile"
                                              ? 2
                                              : 1)
                                    | numberFormat
                            }}
                        </ng-container>

                        <ng-container *ngIf="utilRatio">
                            {{
                                xo.prior * 100
                                    | roundToDecimalPlace: 0
                                    | numberFormat
                            }}%
                        </ng-container>

                        <ng-container *ngIf="utilFreqOfUse">
                            {{
                                xo.prior | roundToDecimalPlace: 2 | numberFormat
                            }}
                        </ng-container>
                    </div>

                    <!-- Current -->
                    <div [fxFlex]="15">
                        <ng-container *ngIf="properties.id != 'Utilization'">
                            {{
                                xo.current
                                    | roundToDecimalPlace
                                        : (properties.country === "Chile"
                                              ? 2
                                              : 0)
                                    | numberFormat
                            }}
                        </ng-container>

                        <ng-container *ngIf="utilClaimsPerClaimant">
                            {{
                                xo.current
                                    | roundToDecimalPlace
                                        : (properties.country === "Chile"
                                              ? 2
                                              : 1)
                                    | numberFormat
                            }}
                        </ng-container>

                        <ng-container *ngIf="utilRatio">
                            {{
                                xo.current * 100
                                    | roundToDecimalPlace: 0
                                    | numberFormat
                            }}%
                        </ng-container>

                        <ng-container *ngIf="utilFreqOfUse">
                            {{
                                xo.current
                                    | roundToDecimalPlace: 2
                                    | numberFormat
                            }}
                        </ng-container>
                    </div>

                    <!-- YoY Change -->
                    <div [fxFlex]="15">
                        {{
                            xo.yoYChange
                                | roundToDecimalPlace: 1
                                | numberFormat
                        }}%
                    </div>

                    <!-- Benchmark -->
                    <ng-container *ngIf="eligibleForBenchmark && !noneSelected">
                        <div *ngIf="properties.id != 'Utilization'" fxFlex="15">
                            {{
                                xo.benchmark
                                    | roundToDecimalPlace: 0
                                    | numberFormat
                            }}
                        </div>
                        <div *ngIf="utilClaimsPerClaimant" fxFlex="15">
                            {{
                                xo.benchmark
                                    | roundToDecimalPlace: 1
                                    | numberFormat
                            }}
                        </div>
                        <div *ngIf="utilRatio" fxFlex="15">
                            {{
                                xo.benchmark * 100
                                    | roundToDecimalPlace: 0
                                    | numberFormat
                            }}%
                        </div>
                        <div *ngIf="utilFreqOfUse" fxFlex="15">
                            {{
                                xo.benchmark
                                    | roundToDecimalPlace: 2
                                    | numberFormat
                            }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div
                [fxFlex]="98"
                fxLayout="column"
                *ngIf="properties.chartTable == ChartTable.Chart"
            >
                <ng-container id="avgUtilCostChart" *ngIf="parentLoaded">
                    <app-dual-bar
                        [categories]="categories"
                        [dataListBar1]="dataListBar1Prior"
                        [dataListBar2]="dataListBar2Current"
                        [dataListLine]="
                            !eligibleForBenchmark || noneSelected
                                ? []
                                : dataListLinebenchmark
                        "
                        [decimalPlaces]="decimalPlaces"
                        [nameBar1]="priorDateRange"
                        [nameBar2]="currentDateRange"
                        [nameLine]="
                            !eligibleForBenchmark || noneSelected
                                ? ''
                                : benchmarkLabel
                        "
                        [symbol]="isPercentage ? '%' : ''"
                        [height]="300"
                        [country]="properties.country"
                    >
                    </app-dual-bar>
                    <div
                        fxLayout="row"
                        *ngIf="avgItemsGrid && avgItemsGrid.length > 0"
                    >
                        <div fxFlex="40"></div>
                        <div fxFlex="18" *ngIf="priorDateRange">
                            <svg width="15" height="15">
                                <rect
                                    width="15"
                                    height="15"
                                    fill="#29B0C3"
                                ></rect>
                            </svg>
                            {{ priorDateRange }}
                        </div>
                        <div fxFlex="18" *ngIf="currentDateRange">
                            <svg width="15" height="15">
                                <rect
                                    width="15"
                                    height="15"
                                    fill="#101E7F"
                                ></rect>
                            </svg>
                            {{ currentDateRange }}
                        </div>
                        <div
                            fxFlex="14"
                            *ngIf="eligibleForBenchmark && !noneSelected"
                        >
                            <svg width="15" height="15">
                                <rect
                                    width="15"
                                    height="15"
                                    fill="#FF803E"
                                ></rect>
                            </svg>
                            {{ benchmarkLabel }}
                        </div>
                        <div fxFlex></div>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf="eligibleForBenchmark">
                <div>
                    <button
                        id="avgUtilCostBtnBench"
                        mat-icon-button
                        (click)="showBM = !showBM"
                        style="margin-top: -5px"
                    >
                        <mat-icon aria-label="Side nav toggle icon"
                            >menu</mat-icon
                        >
                    </button>
                </div>
                <div fxFlex="20%" fxLayout="column" style="padding: 0 25px">
                    <div id="BMoption" style="margin-top: 10px" *ngIf="showBM">
                        <div
                            id="avgUtilCostBtnInclude"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            (click)="selectionChanged(1, 'benchmarkInclude')"
                            class="dash-option"
                            [ngClass]="{
                                'dash-option-selected':
                                    properties.benchmarkInclude == 1
                            }"
                        >
                            <span>
                                {{ "AvgCostUtil.IncludeClient" | translate }}
                            </span>
                        </div>
                        <div
                            id="avgUtilCostBtnExclude"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            (click)="selectionChanged(2, 'benchmarkInclude')"
                            class="dash-option"
                            [ngClass]="{
                                'dash-option-selected':
                                    properties.benchmarkInclude == 2
                            }"
                        >
                            <span>
                                {{ "AvgCostUtil.ExcludeClient" | translate }}
                            </span>
                        </div>
                        <div
                            id="avgUtilCostBtnPortfolio"
                            fxLayout="row"
                            style="margin-top: 30px"
                            fxLayoutAlign="center center"
                            (click)="selectionChanged(1, 'benchmarkLevel')"
                            class="dash-option"
                            [ngClass]="{
                                'dash-option-selected':
                                    properties.benchmarkLevel == 1
                            }"
                        >
                            <span>
                                {{ "AvgCostUtil.Portfolio" | translate }}
                            </span>
                        </div>
                        <div
                            id="avgUtilCostBtnIndustry"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            (click)="selectionChanged(2, 'benchmarkLevel')"
                            class="dash-option"
                            [ngClass]="{
                                'dash-option-selected':
                                    properties.benchmarkLevel == 2
                            }"
                        >
                            <span>
                                {{ "AvgCostUtil.Industry" | translate }}
                            </span>
                        </div>
                        <div
                            id="avgUtilCostBtnNone"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            (click)="selectionChanged(3, 'benchmarkLevel')"
                            class="dash-option"
                            [ngClass]="{
                                'dash-option-selected':
                                    properties.benchmarkLevel == 3
                            }"
                        >
                            <span>{{ "AvgCostUtil.None" | translate }} </span>
                        </div>
                    </div>
                    <div fxFlex></div>
                    <div id="avgUtilCostFooterNote" style="text-align: right">
                        {{
                            properties.claimPaid === CLAIM_BASIS.PAID_BASIS ||
                            apacCountries.includes(properties.country)
                                ? ("AvgCostUtil.ClaimsReportedByPaidDate"
                                  | translate)
                                : ("AvgCostUtil.ClaimsReportedByIncurredDate"
                                  | translate)
                        }}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div
        style="
            width: 200px;
            padding: 80px 0px 0px 16px;
            border-left: 1px solid #eee;
        "
        fxLayout="column"
    >
        <div id="entityDivisionFilters" *ngIf="showFilter">
            <app-additional-filters
                [country]="properties.country"
                (action)="additionalFilterSelectionChanged($event)"
                [filterOptions]="filterOptions"
                [isClientName]="true"
                [isEntity]="true"
                [isPolicyType]="true"
            >
            </app-additional-filters>
        </div>
        <div id="avgUtilCostFilters" fxFlex="55" *ngIf="!showFilter">
            <div id="avgUtilCostBtnShowLegend" fxLayout="row">
                <span>{{ "AvgCostUtil.ShowLegend" | translate }}</span>
                <button
                    mat-icon-button
                    style="float: right; margin-top: -10px"
                    (click)="
                        properties.isLegendShown = !properties.isLegendShown
                    "
                >
                    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                </button>
            </div>
            <div id="avgUtilCostLegends" *ngIf="properties.isLegendShown">
                <div
                    fxLayout="row"
                    *ngFor="let leg of legendBindingList"
                    style="margin-bottom: 5px"
                >
                    <svg width="15" height="15">
                        <rect
                            width="15"
                            height="15"
                            [attr.fill]="leg.color"
                        ></rect>
                    </svg>
                    <span class="legendElp">{{ leg.name }}</span> <br />
                </div>
            </div>
            <br />
            <div
                id="avgUtilCostBtnClaimType"
                fxLayout="column"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{
                    'dash-option-selected':
                        properties.dimension == ClaimTypeOption.ClaimType
                }"
                (click)="
                    selectionChanged(ClaimTypeOption.ClaimType, 'dimension')
                "
            >
                <span>{{ "AvgCostUtil.ClaimType" | translate }}</span>
            </div>

            <div
                id="avgUtilCostBtnNetwork"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{
                    'dash-option-selected':
                        properties.dimension == ClaimTypeOption.Network
                }"
                (click)="selectionChanged(ClaimTypeOption.Network, 'dimension')"
            >
                <span>{{ "AvgCostUtil.Network" | translate }}</span>
            </div>

            <div
                id="avgUtilCostBtnProviderType"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{
                    'dash-option-selected':
                        properties.dimension == ClaimTypeOption.ProviderType
                }"
                (click)="
                    selectionChanged(ClaimTypeOption.ProviderType, 'dimension')
                "
            >
                <span>{{ "AvgCostUtil.ProviderType" | translate }}</span>
            </div>

            <div
                id="avgUtilCostBtnRelationship"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{
                    'dash-option-selected':
                        properties.dimension == ClaimTypeOption.Relationship
                }"
                (click)="
                    selectionChanged(ClaimTypeOption.Relationship, 'dimension')
                "
            >
                <span>{{ "AvgCostUtil.Relationship" | translate }}</span>
            </div>

            <br *ngIf="!showLegend" />
            <br />
            <div
                id="avgUtilCostBtnCostPerClaim"
                *ngIf="this.properties.id == 'AverageCost'"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{
                    'dash-option-selected':
                        properties.mode == CostOption.CostPerClaim
                }"
                (click)="selectionChanged(CostOption.CostPerClaim, 'mode')"
            >
                <span>{{ "AvgCostUtil.CostPerClaim" | translate }}</span>
            </div>

            <div
                id="avgUtilCostBtnCostPerClaimant"
                *ngIf="isAvgCost"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{
                    'dash-option-selected':
                        properties.mode == CostOption.CostPerClaimant
                }"
                (click)="selectionChanged(CostOption.CostPerClaimant, 'mode')"
            >
                <span>{{ "AvgCostUtil.CostPerClaimant" | translate }}</span>
            </div>

            <div
                id="avgUtilCostBtnClaimsPerClaimant"
                *ngIf="!isAvgCost"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{
                    'dash-option-selected':
                        properties.mode == CostOption.ClaimsPerClaimant
                }"
                (click)="selectionChanged(CostOption.ClaimsPerClaimant, 'mode')"
            >
                <span>{{ "AvgCostUtil.ClaimsPerClaimant" | translate }}</span>
            </div>

            <div
                id="avgUtilCostBtnUtilizationRatio"
                *ngIf="this.properties.id == 'Utilization'"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{
                    'dash-option-selected':
                        properties.mode == CostOption.UtilizationRatio
                }"
                (click)="selectionChanged(CostOption.UtilizationRatio, 'mode')"
            >
                <span>{{ "AvgCostUtil.UtilizationRatio" | translate }}</span>
            </div>
            <div
                id="avgUtilCostBtnFreqOfUse"
                *ngIf="this.properties.id == 'Utilization'"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{
                    'dash-option-selected':
                        properties.mode == CostOption.FreqOfUse
                }"
                (click)="selectionChanged(CostOption.FreqOfUse, 'mode')"
            >
                <span>{{ "AvgCostUtil.FreqOfUse" | translate }}</span>
            </div>
            <br *ngIf="!showLegend" />
            <br />
            <div
                id="avgUtilCostBtnChart"
                fxLayout="row"
                (click)="selectionChanged(ChartTable.Chart, 'chartTable')"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{
                    'dash-option-selected':
                        properties.chartTable == ChartTable.Chart
                }"
            >
                <span>{{ "AvgCostUtil.Chart" | translate }}</span>
            </div>
            <div
                id="avgUtilCostBtnTable"
                fxLayout="row"
                (click)="selectionChanged(ChartTable.Table, 'chartTable')"
                fxLayoutAlign="center center"
                class="dash-option"
                [ngClass]="{
                    'dash-option-selected':
                        properties.chartTable == ChartTable.Table
                }"
            >
                <span>{{ "AvgCostUtil.Table" | translate }}</span>
            </div>
        </div>
    </div>
</div>
