export const locale = {
    lang: 'es-ec',
    data: {
        'ExecutiveSummary': {            
            'ExecutiveSummary': 'Resumen Ejecutivo',            
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'GeneralTrend': 'Tendencia General',
            'Rolling12Basis': '12 Meses Corridos',
            'CurrentPMPM': 'PMPM Actual', 
            'PriorPMPM' : 'Anterior por miembro por mes', 
            'OverallTrend' : 'Tendencia Total', 
            'AverageCost': 'Costo Promedio',
            'Utilization': 'Utilización',
            'ServicesReceived': 'Servicios Recibidos',
            'PlanPerformance': 'Diseño de plan',
            'YearToDateBasis': 'Año - a - fecha Base (total)',
            'YearToDateCBasis': 'Año - a - fecha (C) Base (total)',
            'ClaimsReportedByPaidDate' :  'Reclamaciones reportadas por fecha de pago',
            'ClaimsReportedByIncurredDate' :  'Reclamaciones informadas por fecha incurrida',
            'LossRatio': 'Siniestralidad',
            'Headcount': 'NOMINA',
            'Claims': 'RECLAMOS',
            'Premium': 'PRIMAS',            
            'CombinedLossRatio':'Siniestralidad Combinada',
            'ClaimLabel': 'RECLAMOS + GASTOS',
            'PremimumLabel':'PRIMAS + INGRESOS',
            'DateRange': 'to'
        }
    }
};
