export const locale = {
    lang: 'en',
    data: {
        'CoMorbidity': {
            'CoMorbidityHeading':'CO-MORBIDITIES',
            'AllAmountsDisplayedInLocalCurrency': 'All amounts displayed in local currency',
            'Diabetes':'Diabetes',          
            'Hyperlipidemia' : 'Hyperlipidemia', 
            'Hypertension':'Hypertension',   
            'AssociatedCo': 'Associated Co-morbidities' ,        
            'Claimants' : 'Claimants',
            'AverageAge':'Average age',
            'Years':'years',
            'Female':'Female',
            'Male':'Male',
            'Gender': 'Gender',
            'ClaimType':'Claim Type',
            'TotalCost':'Total Cost',
            'CoMorbidity':'Co-morbidities',
            'PercentageOfTotalClaims': '% of Total Claims'
        }
    }
};
