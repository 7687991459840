export const locale = {
    lang: 'es-mx',
    data: {
        'DistributionByClaimType': {
             'TotalPaidClaim' : 'Total trámites pagados',
             'ClaimCount' : 'Numero de reclamaciones',
             'AverageCost' : 'Costo de trámite promedio',
             'Dimensions' : 'Dimensión',
             'AgeBand' : 'Rango de Edad',
             'Gender' : 'Género',
             'Relationship': 'Parentesco', 
             'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'ClaimsReportedByPaidDate' :  'Reclamaciones reportadas por fecha de pago',
            'ClaimsReportedByIncurredDate' :  'Reclamaciones informadas por fecha incurrida',
            'ClaimTypeBy': 'Tipo de Reclamación por',
            'DateRange': 'to'
          }
    }
};
