import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';
import { AgmatcheckboxComponent } from './agmatcheckbox/agmatcheckbox.component';
import { AgheadermatcheckboxComponent } from './agheadermatcheckbox/agheadermatcheckbox.component';
import { AgcustomGridComponent } from './agcustom-grid/agcustom-grid.component';
import { AgmaticonactionComponent } from './agmaticonaction/agmaticonaction.component';
import { MonthyearpickerComponent } from './monthyearpicker/monthyearpicker.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { AgGridModule } from 'ag-grid-angular';
import { DialogComponent } from './dialog/dialog.component';
import { OfflineComponent } from 'app/shared/offline/offline.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons';
import { ToAbbrevNumberStringPipe } from 'app/pipes/number/to-abbrev-number-string.pipe';
import { ToAddOrMinusOperatorPipe } from 'app/pipes/number/to-add-or-minus-operator.pipe';
import { RoundToDecimalPlacePipe } from 'app/pipes/number/round-to-decimal-place.pipe';
import { NumberFormatPipe } from 'app/pipes/number/number-format.pipe';
import { NumberAbsolutePipe } from 'app/pipes/number/number-absolute.pipe';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { BaseDialogComponent } from './base-dialog/base-dialog.component';

@NgModule({
  imports: [
    AgGridModule.withComponents([AgmatcheckboxComponent, AgheadermatcheckboxComponent, AgmaticonactionComponent]),
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FuseSharedModule,
    FontAwesomeModule,
  ],
  declarations: [
    SafePipe,
    AgmatcheckboxComponent,
    AgheadermatcheckboxComponent,
    AgcustomGridComponent,
    AgmaticonactionComponent,
    DialogComponent,
    OfflineComponent,
    ToAbbrevNumberStringPipe,
    ToAddOrMinusOperatorPipe,
    RoundToDecimalPlacePipe,
    NumberFormatPipe,
    NumberAbsolutePipe,
    LoadingDialogComponent,
    MonthyearpickerComponent,
    BaseDialogComponent,
  ],
  exports: [
    SafePipe,
    CommonModule,
    MaterialModule,
    FuseSharedModule,
    FormsModule,
    ReactiveFormsModule,
    AgmatcheckboxComponent,
    AgheadermatcheckboxComponent,
    AgcustomGridComponent,
    AgmaticonactionComponent,
    OfflineComponent,
    ToAbbrevNumberStringPipe,
    ToAddOrMinusOperatorPipe,
    RoundToDecimalPlacePipe,
    NumberFormatPipe,
    NumberAbsolutePipe,
    MonthyearpickerComponent,
    BaseDialogComponent,
  ],
  entryComponents: [OfflineComponent, DialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
  constructor(
    private _library: FaIconLibrary,
  ) {
    this._library.addIcons(falTimes);
  }
}