<div
    id="prevalDashboard"
    style="min-height: 795px; padding: 8px; overflow: auto; position: relative;"
    fxLayout="row"
>
    <div
        fxFlex
        fxLayout="column"
    >
        <div
            id="prevalHeader"
            class="dash-header"
        >
            <span id="prevalHeaderTxt">{{'DiseaseDetail.DiseaseDetailHeading' | translate}}</span>
            <button
                id="prevalHeaderBtn"
                mat-icon-button
                style="float: right"
                (click)="showFilter = !showFilter"
                *ngIf="!isFilterOptionsLoading"
            >
                <app-filter-button
                    [isClientName]='true'
                    [isEntity]='true'
                    [isPolicyType]='true'
                    [isPlan]='true'
                    [isClaimType]='true'
                    [isClaimType2]='true'
                    [isClaimType3]='true'
                    [isProviderType]='true'
                    [isGender]='true'
                    [isRelationship]='true'
                    [isAgeBand]='true'
                    [isLocation]='true'
                    [isLOB]='true'
                    [isSubLOB]='true'
                ></app-filter-button>
            </button>
        </div>
        <div fxLayout="row">
            <div
                id="prevalHeaderCurrency"
                style="text-align: left;font-style: italic; padding: 3px"
                fxFlex
            >
                {{'DiseaseDetail.AllAmountInLocalCurrency' | translate}}
            </div>
        </div>
        <div
            fxLayout="row"
            style="margin-top: 5px; "
        >
            <div
                id="prevalDiag"
                fxLayout="column"
                fxFlex="50%"
            >
                <div fxLayout="row">
                    <div fxFlex="80%">
                    </div>
                    <div fxFlex="20%">
                        <div id="prevalSelectTop">
                            <label>{{'DiseaseDetail.SelectTop' | translate}}</label>
                            <input
                                type="number"
                                [(ngModel)]="properties.prevalenceOfDiseaseTopFilter"
                                class="topInput"
                                min="0"
                                (ngModelChange)="filterTop10List()"
                            >
                        </div>
                    </div>
                </div>
                <div
                    id="prevalTableHeader"
                    fxLayout="row"
                    style="margin-top:5px;"
                >
                    <div fxFlex="85%">
                        <div class="headerText">{{'DiseaseDetail.Diagnosis' | translate}}</div>
                    </div>
                    <div fxFlex="15%">
                        <div style="padding-top:6px">({{properties.claimsTotal == ClaimClaimantsTotalOption.ClaimsTotal
                            ?('DiseaseDetail.ClaimsTotalHeader' | translate): ('DiseaseDetail.DigClaimantCount' |
                            translate)}})</div>
                    </div>
                </div>
                <div>
                    <hr class="line">
                </div>
                <tableau-spinner
                    *ngIf="isLoadingDiagnoses"
                    style="position: absolute; top: 170px; left: 22%; z-index: 90"
                ></tableau-spinner>

                <div
                    #prevalTable
                    id="prevalTable"
                    class="dignosis"
                    style=" height: 265px; overflow-x: scroll;"
                    *ngIf="parentLoaded "
                >
                    <div
                        fxLayout="row"
                        id="drow"
                        *ngFor="let finalDiagListRecord of finalDiagList"
                        (click)="diagnosisSelection(finalDiagListRecord, properties.diag)"
                        class='diagnosisSelection'
                        [ngClass]="(data.currentDiagnosis == finalDiagListRecord.dimension && data.currentDiagnosis !== '' )? 'grayRow' : ''"
                    >
                        <div
                            fxLayout="column"
                            fxFlex="85"
                            style="font-size: 14px;"
                        >
                            {{ !apacCountries.includes(properties.country) && (finalDiagListRecord.claimantCount <
                                this.properties.claimantFilterValue)
                                ? 'NA'
                                :
                                (finalDiagListRecord.dimension)
                                }}
                                </div
                            >
                                <div
                                    fxLayout="column"
                                    fxFlex="15"
                                    style="font-size: 14px;"
                                >
                                    {{ properties.claimsTotal == ClaimClaimantsTotalOption.ClaimsTotal
                                    ?(finalDiagListRecord.claimsTotal ):(finalDiagListRecord.claimantCount |
                                    numberFormat)}}
                                </div>
                        </div>
                    </div>
                </div>
                <div
                    fxLayout="column"
                    fxFlex="2%"
                >
                </div>
                <div
                    fxLayout="column"
                    fxFlex="15%"
                >
                    <div
                        id="prevalDiagnosisLevel"
                        fxLayout="row"
                        style="margin-top:15px; margin-bottom: 15px;"
                    >
                        <span>{{ 'DiseaseDetail.DiagnosisLevel' | translate }}</span>
                    </div>
                    <div
                        id="prevalBtnCategory"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.diag == CategoryChapterOption.DiagCat}"
                        (click)="diagnosisContainerSelectionChanged(CategoryChapterOption.DiagCat, 'diag')"
                    >
                        <span>{{ 'DiseaseDetail.Category' | translate }}</span>
                    </div>
                    <div
                        id="prevalBtnChapter"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.diag == CategoryChapterOption.DiagChapter}"
                        (click)="diagnosisContainerSelectionChanged(CategoryChapterOption.DiagChapter, 'diag')"
                    >
                        <span>{{ 'DiseaseDetail.Chapter' | translate }}</span>
                    </div>

                    <div
                        id="prevalBtnClaimsTotal"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        style="margin-top:30px"
                        [ngClass]="{'dash-option-selected': properties.claimsTotal == ClaimClaimantsTotalOption.ClaimsTotal}"
                        (click)="diagnosisClaimChanged(ClaimClaimantsTotalOption.ClaimsTotal, 'claimsTotal')"
                    >
                        <span>{{ 'DiseaseDetail.ClaimsTotal' | translate }}</span>
                    </div>

                    <div
                        id="prevalBtnDigClaimantCount"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.claimsTotal == ClaimClaimantsTotalOption.ClaimantsCount}"
                        (click)="diagnosisClaimChanged(ClaimClaimantsTotalOption.ClaimantsCount, 'claimsTotal')"
                    >
                        <span>{{ 'DiseaseDetail.DigClaimantCount' | translate }}</span>
                    </div>
                </div>
                <div
                    fxLayout="column"
                    fxFlex="33%"
                >
                    <div
                        fxLayout="row"
                        fxLayoutAlign="center center"
                    >
                        <ng-container
                            id="prevalDonut"
                            *ngIf="parentLoaded"
                        >
                            <app-donut-pop
                                [elementRefPre]="elementRefPre"
                                [count]="ClickCounter"
                                [totalDountData]="totalDountData"
                                (countChanged)="donutSelection($event)"
                                [datalist]='currentDonutData'
                            ></app-donut-pop>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div
                fxLayout="row"
                id="prevalDiseaseHeading"
            >
                <div
                    fxLayout="column"
                    class="headerText"
                >{{ 'DiseaseDetail.DiseaseProgression' | translate
                    }}&nbsp;&nbsp;- </div>
                <div
                    fxLayout="column"
                    class="headerText"
                >&nbsp;&nbsp;{{ yAxisTime }}</div>
            </div>
            <div fxLayout="row">
                <div
                    fxLayout="column"
                    fxFlex="50%"
                    style="margin-top:15px;"
                >
                    <tableau-spinner
                        *ngIf="isLoadingOverTime"
                        style="position: absolute; top: 485px; left: 22%; z-index: 90"
                    ></tableau-spinner>
                    <ng-container
                        id="prevalProgChart"
                        *ngIf="parentLoaded"
                    >
                        <app-line
                            [width]="'550'"
                            [dataList]="diseaseProgressionList"
                            [yAxisLable]="yAxisTime"
                            [categories]="categories"
                            [benchmark]="benchmarkValueForDiseaseProgression"
                            [benchmarkLable]="benchmarkLableForDiseaseProgression"
                            [benchmarkVisible]="benchmarkVisibilityForDiseaseProgression"
                            [MaxOfY]="maxValueForYaxis"
                            [decimalPlaceOvertime]="decimalPlaceOvertime"
                            [listName]="properties.mode"
                            [country]="properties.country"
                            [isPercentage]="isPercentage"
                        ></app-line>

                    </ng-container>
                </div>
                <div
                    fxLayout="column"
                    fxFlex="2%"
                >
                </div>
                <div
                    fxLayout="column"
                    fxFlex="15%"
                >
                    <div
                        fxLayout="row"
                        style="margin-top:20px"
                    ></div>
                    <div
                        id="prevalBtnAge"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.mode == CostDetailOption.Age}"
                        (click)="diseaseProgressionSelectionChanged(CostDetailOption.Age, 'mode')"
                    >
                        <span>{{ 'DiseaseDetail.Age' | translate }}</span>
                    </div>

                    <div
                        id="prevalBtnCostPerClaimant"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.mode == CostDetailOption.CostPerClaimant}"
                        (click)="diseaseProgressionSelectionChanged(CostDetailOption.CostPerClaimant, 'mode')"
                    >
                        <span>{{'DiseaseDetail.CostPerClaimant' | translate}}</span>
                    </div>

                    <div
                        id="prevalBtnCostPerClaim"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.mode == CostDetailOption.CostPerClaim}"
                        (click)="diseaseProgressionSelectionChanged(CostDetailOption.CostPerClaim, 'mode')"
                    >
                        <span>{{'DiseaseDetail.CostPerClaim' | translate}}</span>
                    </div>


                    <div
                        id="prevalBtnClaimsPerClaimant"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.mode == CostDetailOption.ClaimsPerClaimant}"
                        (click)="diseaseProgressionSelectionChanged(CostDetailOption.ClaimsPerClaimant, 'mode')"
                    >
                        <span>{{'DiseaseDetail.ClaimsPerClaimant' | translate}}</span>
                    </div>

                    <div
                        id="prevalBtnTotalCost"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.mode == CostDetailOption.TotalCost}"
                        (click)="diseaseProgressionSelectionChanged(CostDetailOption.TotalCost, 'mode')"
                    >
                        <span>{{'DiseaseDetail.TotalCost' | translate}}</span>
                    </div>

                    <div
                        id="prevalBtnClaimantCount"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.mode == CostDetailOption.ClaimantCount}"
                        (click)="diseaseProgressionSelectionChanged(CostDetailOption.ClaimantCount, 'mode')"
                    >
                        <span>{{'DiseaseDetail.ClaimantCount' | translate}}</span>
                    </div>

                    <div
                        id="prevalBtnPrevalence"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="dash-option"
                        [ngClass]="{'dash-option-selected': properties.mode == CostDetailOption.Prevalence}"
                        (click)="diseaseProgressionSelectionChanged(CostDetailOption.Prevalence, 'mode')"
                    >
                        <span>{{'DiseaseDetail.Prevalence' | translate}}</span>
                    </div>
                </div>
                <div
                    fxLayout="column"
                    fxFlex="2%"
                >
                </div>

                <div
                    *ngIf="eligibleForBenchmark"
                    fxLayout="column"
                    fxFlex="15%"
                    style="padding: 0 25px"
                >
                    <div fxLayout="row">
                        <span id="prevalBenchTxt">{{'DiseaseDetail.Benchmark' | translate}}
                            <button
                                id="prevalBtnBench"
                                mat-icon-button
                                (click)="showBM = !showBM"
                            >
                                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                            </button></span>
                    </div>
                    <div
                        id="prevalBMoption"
                        style="margin-top: 10px"
                        *ngIf="showBM"
                    >
                        <div
                            id="prevalBtnIncludeClient"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            (click)="selectionChanged(1, 'benchmarkInclude')"
                            class="dash-option"
                            [ngClass]="{'dash-option-selected': properties.benchmarkInclude == 1 }"
                        >
                            <span> {{ 'DiseaseDetail.IncludeClient' | translate }}
                            </span>
                        </div>
                        <div
                            id="prevalBtnExcludeClient"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            (click)="selectionChanged(2, 'benchmarkInclude')"
                            class="dash-option"
                            [ngClass]="{'dash-option-selected': properties.benchmarkInclude == 2 }"
                        >
                            <span> {{ 'DiseaseDetail.ExcludeClient' | translate }}
                            </span>
                        </div>
                        <div
                            id="prevalBtnPortfolio"
                            fxLayout="row"
                            style="margin-top:30px"
                            fxLayoutAlign="center center"
                            (click)="selectionChanged(1, 'benchmarkLevel')"
                            class="dash-option"
                            [ngClass]="{'dash-option-selected': properties.benchmarkLevel == 1 }"
                        >
                            <span> {{ 'DiseaseDetail.Portfolio' | translate }}
                            </span>
                        </div>
                        <div
                            id="prevalBtnIndustry"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            (click)="selectionChanged(2, 'benchmarkLevel')"
                            class="dash-option"
                            [ngClass]="{'dash-option-selected': properties.benchmarkLevel == 2 }"
                        >
                            <span> {{ 'DiseaseDetail.Industry' | translate }}
                            </span>
                        </div>
                        <div
                            id="prevalBtnNone"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            (click)="selectionChanged(3, 'benchmarkLevel')"
                            class="dash-option"
                            [ngClass]="{'dash-option-selected': properties.benchmarkLevel == 3 }"
                        >
                            <span>{{ 'DiseaseDetail.None' | translate }}
                            </span>
                        </div>
                    </div>
                </div>

                <div
                    fxLayout="column"
                    style="padding: 5px;padding-top: 10px;"
                    fxFlex="16%"
                >
                    <div *ngIf="data">
                        <div
                            *ngIf="data.shownAsPerDisease"
                            id="prevalSelectDiag"
                            class="innerNote"
                            [style.visibility]='data.shownAsPerDisease.trim().length > 0 ? "visible" : "hidden" '
                        >
                            {{ 'DiseaseDetail.ShownAsPer' | translate }} &nbsp;{{'DiseaseDetail.Diagnosis' |
                            translate}}&nbsp;:
                            <br>
                            <span *ngFor="let finalDiagListRecord of finalDiagList">
                                <span
                                    *ngIf="data.shownAsPerDisease === finalDiagListRecord.dimension">"{{(finalDiagListRecord.claimantCount
                                    <
                                        this.properties.claimantFilterValue)
                                        ? 'NA'
                                        :
                                        (finalDiagListRecord.dimension)
                                        }}"</span
                                    >
                                </span>
                        </div>
                        <div
                            *ngIf="data.shownAsPerDonut"
                            class="innerNoteSelectDonut"
                            style="margin-top: 15px;"
                            [style.visibility]='data.shownAsPerDonut.trim().length > 0 ? "visible" : "hidden" '
                        >
                            {{ 'DiseaseDetail.ShownAsPer' | translate }} &nbsp;{{selectedDonutDimensionText}}&nbsp;:
                            <br> "{{data.shownAsPerDonut }}"
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="row">
                <div [fxFlex]="80"></div>
                <div
                    id="prevalFooterNote"
                    [fxFlex]="20"
                    style="text-align: right; margin-right: 10px;"
                >
                    {{properties.claimPaid === CLAIM_BASIS.PAID_BASIS ?
                    ( 'DiseaseDetail.ClaimsReportedByPaidDate' | translate )
                    : ('DiseaseDetail.ClaimsReportedByIncurredDate' | translate )}}
                </div>
            </div>
        </div>
        <div
            style="width: 200px; padding: 40px 0px 0px 16px; border-left: 1px solid #EEE"
            fxLayout="column"
        >
            <div
                id="entityDivisionFilters"
                *ngIf="showFilter"
            >
                <app-additional-filters
                    [country]="properties.country"
                    (action)="additionalFilterSelectionChanged($event)"
                    [filterOptions]='filterOptions'
                    [isClientName]='true'
                    [isEntity]='true'
                    [isPolicyType]='true'
                    [isPlan]='true'
                    [isClaimType]='true'
                    [isClaimType2]='true'
                    [isClaimType3]='true'
                    [isProviderType]='true'
                    [isGender]='true'
                    [isRelationship]='true'
                    [isAgeBand]='true'
                    [isLocation]='true'
                    [isLOB]='true'
                    [isSubLOB]='true'
                >
                </app-additional-filters>
            </div>
            <div
                fxFlex="55"
                id="btnFilter"
                *ngIf="!showFilter"
            >
                <div
                    id="prevalBtnAgeBand"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    style="margin-top:20%;"
                    [ngClass]="{'dash-option-selected': properties.donutchartDimension == GenderRelOptions.AgeBand}"
                    (click)="DimensionselectionChanged(GenderRelOptions.AgeBand, 'donutchartDimension')"
                >
                    <span>{{ 'DiseaseDetail.AgeBand' | translate }}</span>
                </div>
                <div
                    id="prevalBtnGender"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{'dash-option-selected': properties.donutchartDimension == GenderRelOptions.Gender}"
                    (click)="DimensionselectionChanged(GenderRelOptions.Gender, 'donutchartDimension')"
                >
                    <span>{{ 'DiseaseDetail.Gender' | translate }}</span>
                </div>
                <div
                    id="prevalBtnRelationship"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{'dash-option-selected': properties.donutchartDimension == GenderRelOptions.Relationship}"
                    (click)="DimensionselectionChanged(GenderRelOptions.Relationship, 'donutchartDimension')"
                >
                    <span>{{ 'DiseaseDetail.Relationship' | translate }}</span>
                </div>
                <div
                    id="prevalBtnClaimType"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{'dash-option-selected': properties.donutchartDimension == GenderRelOptions.ClaimsType}"
                    (click)="DimensionselectionChanged(GenderRelOptions.ClaimsType, 'donutchartDimension')"
                    style="margin-bottom: 15px;"
                >
                    <span>{{ 'DiseaseDetail.ClaimType' | translate }}</span>
                </div>
                <div id="prevalLegendDonut">
                    <div
                        fxLayout="row"
                        *ngFor="let leg of legendList"
                        style="margin-bottom: 5px;"
                    >
                        <svg width="15"  height="15">
                            <rect width="15"
                                 height="15"
                                [attr.fill]="leg.color"
                            ></rect>
                        </svg>
                        <span class="legendElp">{{(leg.name)}}</span> <br>
                    </div>
                </div>
                <div
                    id='prevalDiagGrps'
                    style='margin-top: 20px; max-width: 160px;'
                >
                    <pulse-select
                        [selection]="selectedDiagGroupOptionList"
                        placeholder="{{'DiseaseDetail.DiagnosisGroup' | translate}}"
                        [options]="translatedDiagGroupOptionList"
                        (selectionChanged)="filterSelectionChanged($event, 'diagGrps')"
                        [hidden]="isExport"
                    >
                    </pulse-select>
                    <div [hidden]="!isExport">
                        <span>{{'DiseaseDetail.DiagnosisGroup' | translate}}</span>
                        <br />
                        <span
                            *ngIf='selectedDiagGroupOptionList.length > 0'>{{selectedDiagGroupOptionList.join('||')}}</span>
                        <span *ngIf='selectedDiagGroupOptionList.length == 0'>All</span>
                    </div>
                </div>
                <div
                    id='prevalActionables'
                    style='max-width: 160px;'
                >
                    <pulse-select
                        [selection]="selectedActionableOptionList"
                        placeholder="{{'DiseaseDetail.Actionable' | translate}}"
                        [options]="translatedActionableOptionList"
                        (selectionChanged)="filterSelectionChanged($event, 'actionables')"
                        [hidden]="isExport"
                    >
                    </pulse-select>
                    <div [hidden]='!isExport'>
                        <span>{{'DiseaseDetail.Actionable' | translate}}</span>
                        <br />
                        <span
                            *ngIf='selectedActionableOptionList.length > 0'>{{selectedActionableOptionList.join('||')}}</span>
                        <span *ngIf='selectedActionableOptionList.length == 0'>All</span>
                    </div>
                </div>
            </div>
        </div>
    </div>