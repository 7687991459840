export const locale = {
    lang: 'es-cl',
    data: {
        'ClaimAgeBand': {
            'ClaimsByAgeBand' : 'Monto Reembolsado por rango etario',            
            'AllAmountsDisplayedInLocalCurrency' : 'Todas las cantidades mostradas en moneda local',
            'CountBasis':'Contar Base',
            'Claim' : 'Reclamo', 
            'Claimant' : 'Reclamante',  
            'AgeBandSplit'  :  'Distribución Rango Etario'  ,       
            'None' : 'No',
            'Gender' : 'Genero',
            'Relationship' : 'Parentesco',                
            'Sort' : 'Ordenar',
            'ClaimsTotal' : 'Monto Reembolsado Total',
            'Count' : 'Contar',            
            'AverageCost' :  'Costo Promedio',
            'DataTable' : 'Tabla de Datos',
            'TopDiagnoses' : 'Top Detalle Prestaciones',
            'TopProviders' : 'Top Prestadores',                     
            'TotalPaidClaim' : 'Total Monto Reembolsado', 
            'ClaimantCount':'Cantidad de asegurados',
            'ClaimCount':'Cantidad de Prestaciones',          
            'ClaimsPMPM' : 'Monto Reembolsado PMPM', 
            'CostPerClaim': 'Reembolso por prestación',
            'CostPerClaimant': 'Reembolso por asegurado',             
            'ClaimsReportedByPaidDate': 'Reembolsos reportados por fecha de pago',
            'ClaimsReportedByIncurredDate': 'Reembolsos reportadas por fecha incurrida',
            'FilterNA': 'Excluir NA',
            'Display':'Mostrar Como Tabla',
            'ShowLegend':'Mostrar leyenda',
            'ShownAsPer':'Se muestra según',
            'AgeBand': 'Rango Etario',
            'DateRange': 'to'
        }
    }
};
