import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpClient: HttpClient) { }

  public getDashboardCustomViews(dashboardId: number, clientName: string)
  {
    return this.httpClient.post<any>(environment.api + 'api/DashboardFilter/Dashboard/' + dashboardId + '/Client/' + encodeURI(clientName), null);
  }
  
  public saveDashboardCustomView(dashboardId: number, clientName: string, name: string, comments: string, filterData: object)
  {
    return this.httpClient.post<any>(environment.api + 'api/DashboardFilter/Save', { dashboardId: dashboardId, clientName: clientName, name: name, comments: comments, metadata: JSON.stringify(filterData) });
  } 

  public deleteDashboardCustomView(filterId: number)
  {
    return this.httpClient.post<any>(environment.api + 'api/DashboardFilter/Delete/' + filterId, null);
  } 

}
