export const locale = {
    lang: 'it',
    data: {
  'PerMemberCosts': {
    'PerMemberCosts': 'Costo per testa',
    'AllAmountsDisplayedInLocalCurrency': 'Tutti gli importi visualizzati in valuta locale',
    'KeyImpacts': 'Impatti chiave',
    'CurrentPmpm': 'PMPM Attuale',
    'BenchmarkPmpm': 'BENCHMARK PMPM',
    'PerMemberPerMonthCost': 'Costo per testa per mese',
    'Prior': 'precedente',
    'Current': 'Attuale',
    'YoYChange': 'Cambia YoY',
    'Benchmark': 'Benchmark',
    'IncludeClient': 'Includere cliente',
    'ExcludeClient': 'Escludi cliente',
    'Portfolio': 'Portafoglio',
    'Industry': 'Industria',
    'None': 'Nessuno',
    'AgeBand': "Fascia d'età",
    'Gender': 'Genere',
    'Relationship': 'Relazione',
    'ClaimsReportedByPaidDate': 'Sinistri per data di pagamento',
    'ClaimsReportedByIncurredDate': 'Sinistri per data di accadimento',
    'Chart': 'Grafico',
    'Table': 'Tabella',
    'DateRange': 'Intervallo Di Date'
  }
}};