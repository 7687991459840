import { Pipe, PipeTransform } from '@angular/core';
import { NumberFormattingService } from 'app/services/number-formatting.service';
import { countryLATEM } from 'app/constants/countries_LATAM';

@Pipe({
  name: 'toAbbrevNumberString'
})
export class ToAbbrevNumberStringPipe implements PipeTransform {
    constructor(private _numberFormattingService: NumberFormattingService) {
        
    }

    transform(value: number, country: string = '', precision: number = 4, locale: string = 'en-US'): any {
        if (countryLATEM.indexOf(country) >= 0) {
            return this._numberFormattingService.ToAbbrevNumberStringLatem(value, precision, locale);
        }
        return this._numberFormattingService.ToAbbrevNumberString(value, precision, locale);
    }

}
