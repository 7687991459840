export const locale = {
    lang: 'es-mx',
    data: {
        'ClaimAgeBand': {
            'ClaimsByAgeBand' : 'Trámites por rango de edad',            
            'AllAmountsDisplayedInLocalCurrency' : 'Todas las cantidades mostradas en moneda local',
            'CountBasis':'Contar Base',
            'Claim' : 'Trámite', 
            'Claimant' : 'Caso',  
            'AgeBandSplit'  :  'Rango de Edad'  ,                 
            'None' : 'Ninguna',
            'Gender' : 'Género',
            'Relationship' : 'Parentesco',                
            'Sort' : 'Ordenar',
            'ClaimsTotal' : 'Total de Trámites',
            'Count' : 'Contar',            
            'AverageCost' :  'Costo de trámite promedioo',
            'DataTable' : 'Tabla de datos',
            'TopDiagnoses' : 'Top Diagnósticos',
            'TopProviders' : 'Top Prestadores',                     
            'TotalPaidClaim' : 'Total pagado', 
            'ClaimantCount':'Total casos',  
            'ClaimCount':'Número de trámites',            
            'ClaimsPMPM' : 'Costo PMPM', 
            'CostPerClaim': 'Costo por Trámite',
            'CostPerClaimant': 'Costo por caso',             
            'ClaimsReportedByPaidDate': ' ',
            'ClaimsReportedByIncurredDate': ' ',
            'FilterNA': 'Excluir NA',
            'Display':'Mostrar Como Tabla',
            'ShowLegend':'Mostrar leyenda',
            'ShownAsPer':'Se muestra según',
            'AgeBand': 'Rango Etario',
            'DateRange': 'to'
        }
}
};
