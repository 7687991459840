export const locale = {
    lang: 'es-cl',
    data: {
          'ClaimCostDiag': {
            'ClaimDiagnosis':'Monto Reembolsado por prestación / Detalle',
            'SelectTop':'Seleccionar Top',
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'ClaimType':'Prestación',          
            'TotalPaidClaim' : 'Total Monto Reembolsado', 
            'ClaimantCount':'Cantidad de asegurados',
            'ClaimCount':'Cantidad de prestaciones',            
            'ClaimsPerClaimant' : 'Monto reembolsado por asegurado', 
            'CostPerClaim': 'Reembolso por prestación',
            'CostPerClaimant': 'Reembolso por asegurado', 
            'ChangeInCost':'% Variación del Costo',
            'Claim' : 'Reclamo', 
            'Claimant' : 'Reclamante', 
            'ClaimsTotal':'Monto Reembolsado Total',
            'Count':'Contar',
            'ClaimsReportedByPaidDate': 'Reembolsos reportados por fecha de pago',
            'ClaimsReportedByIncurredDate': 'Reembolsos reportadas por fecha incurrida',
            'CountBasis':'Contar Base',
            'Sort':'Ordenar',
            'Show':'Mostrar',
            'Hide':'Ocultar',
            'ShowHide':'Mostrar/Ocultar',
            'DiagnosisLevel':'Nivel de Diagnóstico',
            'Category':'Categoría',
            'Chapter':'Capítulo',
            'ShownAsPer':'Se muestra según',            
            'TopDiagnoses' : 'Top Detalle Prestaciones',          
            'AverageCost' :  'Costo Promedio' ,
            'FilterNA': 'Excluir NA',
            'DateRange': 'to'          
        }
    }
};
