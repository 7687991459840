import {
    Component,
    OnInit,
    ElementRef,
    Output,
    EventEmitter,
    ViewChild,
    Input,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DashboardTemplateBase } from '../dashboard-template-base.component';
import { FilterOptions } from 'app/dashboards/shared/models/FilterOptions';
import { locale as navigationEnglish } from 'app/dashboards/templates/prevalence-of-disease/i18n/en';
import { locale as navigationSpanishCL } from 'app/dashboards/templates/prevalence-of-disease/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/dashboards/templates/prevalence-of-disease/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/dashboards/templates/prevalence-of-disease/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/dashboards/templates/prevalence-of-disease/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/dashboards/templates/prevalence-of-disease/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/dashboards/templates/prevalence-of-disease/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/dashboards/templates/prevalence-of-disease/i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { PulseEventName } from 'app/dashboards/shared/models/PulseEventName';
import { DataService } from 'app/dashboards/data.service';
import { NumberFormattingService } from 'app/services/number-formatting.service';
import { DimensionOrderService } from 'app/dashboards/dimension-order.service';
import { countryLATEM } from 'app/constants/countries_LATAM';
import { APAC_COUNTRIES } from 'app/constants/countries_APAC';
import { CLAIM_BASIS } from 'app/models/basis.model';
import { COLOR_PALETTE } from 'app/constants/color-palette';
import { indexOf } from 'lodash';

@Component({
    selector: 'app-prevalence-of-disease',
    templateUrl: './prevalence-of-disease.component.html',
    styleUrls: ['./prevalence-of-disease.component.css'],
})
export class PrevalenceOfDiseaseComponent
    implements OnInit, DashboardTemplateBase {
    @Input() isExport = false;
    @Output() action = new EventEmitter<any>();
    @ViewChild('prevalTable') prevalTable;
    apacCountries = APAC_COUNTRIES
    filterOptions: FilterOptions = new FilterOptions();
    isFilterOptionsLoading = true;
    imageUrlBase = '';
    data: any;
    properties: any;
    isLoading = false;
    parentLoaded = false;
    showFilter = false;
    showBM = false;
    finalDiagList: any = [];
    GenderRelOptions = GenderRelOptions;
    ClaimClaimantsTotalOption = ClaimClaimantsTotalOption;
    claimType = 'claim type';
    CategoryChapterOption = CategoryChapterOption;
    CostDetailOption = CostDetailOption;
    currentDonutSelection = '';
    currentDiagnosis: any = '';
    legendColors: any = COLOR_PALETTE;
    legendList = [];
    currentDonutData: any = [];
    totalDountData: any;
    isLoadingDonut = false;
    isLoadingDiagnoses = false;
    isLoadingOverTime = false;
    donutData: any = [];
    diseaseProgressionList: any = [];
    categories: any = [];
    decimalPlace = 0;
    decimalPlaceOvertime = 0;
    isPercentage = false;
    isCountryChile = false;
    selectedDonutDimensionText = '';
    comparablePeriod = '';
    storageName = 'additionalFilterStore';
    additionalFilterStore: any;
    yAxisTime: string;
    benchmarkValueForDiseaseProgression = 0;
    benchmarkLableForDiseaseProgression = '';
    benchmarkVisibilityForDiseaseProgression = false;
    top10DiagnosisList: any;
    maxValueForYaxis = 0;
    translatePrefix = "DiseaseDetail.";
    diagGroupOptionList = ['CHRONIC', 'Infectious', 'Trauma/Accidents', 'Cancer', 'Mental Health', 'Obstetrics', 'Others'];
    actionableOptionList = ['PREVENTABLE', 'Intervenable', 'None'];
    translatedDiagGroupOptionList = [];
    translatedActionableOptionList = [];

    selectedDiagGroupOptionList = [];
    selectedActionableOptionList = [];

    eligibleForBenchmark = false;
    CLAIM_BASIS = CLAIM_BASIS;

    initData() {
        if (typeof this.data.shownAsPerDiagnosis === 'undefined') {
            this.data.shownAsPerDiagnosis = '';
        }

        if (typeof this.data.shownAsPerDonut === 'undefined') {
            this.data.shownAsPerDonut = '';
        }

        if (typeof this.properties.secondaryDimensionValue !== 'undefined' && this.properties.secondaryDimensionValue) {
            this.data.currentDiagnosis = this.properties.secondaryDimensionValue;
        }

        if (this.data.currentDiagnosis) {
            this.data.shownAsPerDisease = this.data.currentDiagnosis;
        }

        if (typeof this.properties.thirdDimensionValue !== 'undefined' && this.properties.thirdDimensionValue) {
            this.currentDonutSelection = this.properties.thirdDimensionValue;
        }

        if (this.currentDonutSelection) {
            this.data.shownAsPerDonut = this.currentDonutSelection;
        }

        if (!this.properties.diagGrps) {
            this.properties.diagGrps = '';
        }

        if (!this.properties.actionables) {
            this.properties.actionables = '';
            //this.selectedActionableOptionList.push('All');
        }

        this.comparablePeriod =
            this.properties.rollingPeriod === 3 ? ' (C)' : '';
        this.decimalPlace = this.properties.country === 'Chile' ? 2 : 0;
        this.isCountryChile = this.properties.country === 'Chile';
        //this.resetFilterSelection();
        this.donutChart(JSON.parse(JSON.stringify(this.data.tier3SingleDimensionData)));
        this.filterTop10List();
        this.DiagnosisNotSelected();
        this.diseaseProgressionChart();
        setTimeout(() => (this.parentLoaded = true), 100);
    }

    constructor(
        private httpClient: HttpClient,
        private elementRefPre: ElementRef,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _dataService: DataService,
        private _numberFormatService: NumberFormattingService,
        private dimensionOrderService: DimensionOrderService
    ) {

    }

    ngOnInit() {
        this._fuseTranslationLoaderService.loadTranslations(
          navigationEnglish,
          navigationSpanishCL,
          navigationSpanishCO,
          navigationSpanishEC,
          navigationSpanishMX,
          navigationSpanishPE,
          navigationPortugueseBR,
          navigationItalianIT,
        );

        this.translatedDiagGroupOptionList = this.diagGroupOptionList.map(diagGroupOption => this.toTranslateFormat(diagGroupOption).toUpperCase());
        this.translatedActionableOptionList = this.actionableOptionList.map(actionableOption => this.toTranslateFormat(actionableOption).toUpperCase());

        this._translateService.onLangChange.subscribe(() => {
            this.translatedDiagGroupOptionList = this.diagGroupOptionList.map(diagGroupOption => this.toTranslateFormat(diagGroupOption).toUpperCase());
            this.translatedActionableOptionList = this.actionableOptionList.map(actionableOption => this.toTranslateFormat(actionableOption).toUpperCase());
        });

        if (this.selectedDiagGroupOptionList.length < 1 && this.properties.diagGrps) {
            this.selectedDiagGroupOptionList = this.properties.diagGrps.split('||');
        }

        if (this.selectedActionableOptionList.length < 1 && this.properties.actionables) {
            this.selectedActionableOptionList = this.properties.actionables.split('||');
        }
    }

    filterTop10List() {
        var list = this.dimensionOrderService.sortByColumn(
            this.data.diagList.current,
            this.properties.claimsTotal
        );
        let top10List = list.reverse().slice(0, this.properties.prevalenceOfDiseaseTopFilter);
        this.top10DiagnosisList = top10List;
        this.finalDiagList = top10List.map((d) => {
            return Object.assign({}, d, {
                claimsTotal:
                    countryLATEM.indexOf(this.properties.country) >= 0
                        ? this._numberFormatService.ToAbbrevNumberStringLatem(
                            d.claimsTotal,
                            4
                        )
                        : this._numberFormatService.ToAbbrevNumberString(
                            d.claimsTotal,
                            4
                        ),
            });
        });
    }

    diagnosisContainerSelectionChanged(val, filterName: string) {
        if (this.currentDonutSelection == '' && this.data.currentDiagnosis == '') {
            this.action.emit({
                type: PulseEventName.FILTLER_CHANGED,
                data: {
                    properties: {
                        name: filterName,
                        value:
                            typeof val === 'object' ? val.data.join('||') : val,
                    },
                },
            });
            this.resetDiagnosis();
        } else {
            this.currentDonutSelection = '';
            this.data.currentDiagnosis = '';
            this.data.shownAsPerDisease = '';
            this.data.shownAsPerDonut = '';
            this.resetFilterSelection();
            this.action.emit({
                type: PulseEventName.FILTLER_CHANGED,
                data: {
                    properties: {
                        name: filterName,
                        value: val,
                    },
                },
            });
        }
    }
    diagnosisClaimChanged(val, filterName: string) {
        if (this.currentDonutSelection == '' && this.data.currentDiagnosis == '') {
            this.action.emit({
                type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
                data: {
                    properties: {
                        name: filterName,
                        value:
                            typeof val === 'object' ? val.data.join('||') : val,
                    },
                },
            });

            this.filterTop10List();
        } else {
            this.currentDonutSelection = '';
            this.data.currentDiagnosis = '';
            this.data.shownAsPerDisease = '';
            this.data.shownAsPerDonut = '';
            this.resetFilterSelection();
            this.action.emit({
                type: PulseEventName.FILTLER_CHANGED,
                data: {
                    properties: {
                        name: filterName,
                        value: val,
                    },
                },
            });
        }
    }

    DiagnosisNotSelected() {
        if (this.data.currentDiagnosis === '') {
            this.properties.secondaryDimension = this.properties.diag;
            this.properties.secondaryDimensionValue = '';//  this.top10DiagnosisList != null ? this.top10DiagnosisList.map((a) => a.dimension).join("||")  : "";
            this._dataService
                .getTier3DiseaseProgressionData(this.properties)
                .toPromise()
                .then((resl) => {
                    this.data.diseaseProgression = resl;
                    this.diseaseProgressionChart();
                    this.isLoadingOverTime = false;
                });
        }
    }


    diagnosisSelection(data: any, val: any) {
        if (this.data.currentDiagnosis === data.dimension) {
            this.resetAll();
        } else if (this.data.currentDiagnosis !== data.dimension) {
            this.data.currentDiagnosis = data.dimension;
            this.data.shownAsPerDisease = data.dimension;
            if (this.currentDonutSelection !== '') {
                this.properties.thirdDimension = this.properties.donutchartDimension;
                this.properties.thirdDimensionValue = this.currentDonutSelection;
            }
            this.isLoadingOverTime = true;
            this.resetFilterSelection();
            this.properties.dimension = this.properties.donutchartDimension;
            this.properties.secondaryDimension = val;
            this.properties.secondaryDimensionValue = data.dimension;

            this._dataService
                .getTier3SingleDimensionData(this.properties)
                .toPromise()
                .then((res) => {
                    this.data.tier3SingleDimensionData = res;
                    this.donutChart(JSON.parse(JSON.stringify(res)));
                });
            this._dataService
                .getTier3DiseaseProgressionData(this.properties)
                .toPromise()
                .then(resl => {
                    this.data.diseaseProgression = resl;
                    this.diseaseProgressionChart();
                    this.isLoadingOverTime = false;
                });

        }

    }

    donutChart(donutData: any) {
        var totalofdonut = 0;
        if (donutData != null) {
            totalofdonut = donutData.current.reduce(
                (a, b) => a + b.claimsTotal,
                0
            );
        }

        this.totalDountData =
            countryLATEM.indexOf(this.properties.country) >= 0
                ? this._numberFormatService.ToAbbrevNumberStringLatem(
                    totalofdonut,
                    4
                )
                : this._numberFormatService.ToAbbrevNumberString(
                    totalofdonut,
                    4
                );

        this.currentDonutData = [];
        this.legendList = [];

        donutData.current.map((d) => {
            var dataObject = {
                label: d.dimension,
                value: 0,
            };

            if (totalofdonut != 0) {
                var claimPercentage = (d.claimsTotal / totalofdonut) * 100;
                dataObject.value = Math.floor(claimPercentage);
            }

            if (dataObject.value > 0) {
                this.currentDonutData.push(dataObject);
            }
        });

        var i = 0;
        this.currentDonutData.map((d) => {
            if (d.value > 0) {
                var objlegend = { name: '', color: '' };
                objlegend.name = d.label;
                objlegend.color = this.legendColors[i];
                this.legendList.push(objlegend);
                i++;
            }
        });

        if (this.properties.donutchartDimension == GenderRelOptions.AgeBand) {
            this.selectedDonutDimensionText = this._translateService.instant(
                'DiseaseDetail.AgeBand'
            );
        } else if (
            this.properties.donutchartDimension == GenderRelOptions.ClaimsType
        ) {
            this.selectedDonutDimensionText = this._translateService.instant(
                'DiseaseDetail.ClaimType'
            );
        } else if (
            this.properties.donutchartDimension == GenderRelOptions.Gender
        ) {
            this.selectedDonutDimensionText = this._translateService.instant(
                'DiseaseDetail.Gender'
            );
        } else if (
            this.properties.donutchartDimension == GenderRelOptions.Relationship
        ) {
            this.selectedDonutDimensionText = this._translateService.instant(
                'DiseaseDetail.Relationship'
            );
        }
    }

    donutSelection(donutText: any) {
        if (this.currentDonutSelection === donutText) {
            this.resetAll();
        } else {
            this.isLoadingDiagnoses = true;
            this.isLoadingOverTime = true;
            this.data.currentDiagnosis = '';
            this.data.shownAsPerDonut = donutText;
            this.currentDonutSelection = donutText;
            this.resetFilterSelection(); //reset filters
            this.properties.thirdDimension = this.properties.donutchartDimension;
            this.properties.thirdDimensionValue = donutText;

            this.properties.dimension =
                this.properties.diag == CategoryChapterOption.DiagCat
                    ? CategoryChapterOption.DiagCat
                    : CategoryChapterOption.DiagChapter;
            this._dataService
                .getTier3Data(this.properties)
                .toPromise()
                .then((res) => {
                    this.data.diagList = res;
                    this.filterTop10List();
                    this.isLoadingDiagnoses = false;
                    this.DiagnosisNotSelected();
                    setTimeout(() => {
                        console.log(this.prevalTable);
                        if (this.data.shownAsPerDisease) {
                            for (let i = 0; i < this.finalDiagList.length; i++) {
                                if (this.finalDiagList[i].dimension === this.data.shownAsPerDisease) {
                                    this.diagnosisSelection(this.finalDiagList[i], this.properties.diag);
                                    return;
                                }
                            }

                            this.data.shownAsPerDisease = '';
                        }
                    });
                });
            this.properties.dimension = GenderRelOptions.AgeBand; //no need of dimenion just by default give ageband
            this.setDonutDimensions(donutText);
        }
    }
    setDonutDimensions(donutText: string) {
        if (this.properties.donutchartDimension == GenderRelOptions.AgeBand) {
            this.properties.thirdDimension = this.properties.donutchartDimension;
            this.properties.thirdDimensionValue = donutText;
        } else if (
            this.properties.donutchartDimension == GenderRelOptions.Relationship
        ) {
            this.properties.thirdDimension = this.properties.donutchartDimension;
            this.properties.thirdDimensionValue = donutText;
        } else if (
            this.properties.donutchartDimension == GenderRelOptions.Gender
        ) {
            this.properties.thirdDimension = this.properties.donutchartDimension;
            this.properties.thirdDimensionValue = donutText;
        } else if (
            this.properties.donutchartDimension == GenderRelOptions.ClaimsType
        ) {
            this.properties.thirdDimension = this.properties.donutchartDimension;
            this.properties.thirdDimensionValue = donutText;
        }
    }
    DimensionselectionChanged(val, filterName: string) {
        if (this.currentDonutSelection === '' && this.data.currentDiagnosis === '') {
            this.action.emit({
                type: PulseEventName.FILTLER_CHANGED,
                data: {
                    properties: {
                        name: filterName,
                        value:
                            typeof val === 'object' ? val.data.join('||') : val,
                    },
                },
            });
            this.resetDimensionChanges();
        } else {
            this.currentDonutSelection = '';
            this.data.currentDiagnosis = '';
            this.data.shownAsPerDisease = '';
            this.data.shownAsPerDonut = '';
            this.properties.secondaryDimension = '';
            this.properties.secondaryDimensionValue = '';
            this.properties.thirdDimension = '';
            this.properties.thirdDimensionValue = '';
            this.resetFilterSelection();
            this.action.emit({
                type: PulseEventName.FILTLER_CHANGED,
                data: {
                    properties: {
                        name: filterName,
                        value: val,
                    },
                },
            });
        }
    }
    diseaseProgressionChart() {
        this.categories = [];
        this.maxValueForYaxis = 0;
        this.diseaseProgressionList = this.data.diseaseProgression.current.map(
            (d) => {
                var lableData;
                var val = this.properties.mode;
                this.isPercentage = false;
                if (val === CostDetailOption.Age) {
                    lableData = d.averageAge;
                    this.decimalPlaceOvertime = 1;
                    this.yAxisTime = this._translateService.instant(
                        'DiseaseDetail.Age'
                    );
                } else if (val == CostDetailOption.ClaimantCount) {
                    lableData = d.claimantCount;
                    this.decimalPlaceOvertime = 0;
                    this.yAxisTime = this._translateService.instant(
                        'DiseaseDetail.ClaimantCount'
                    );
                } else if (val == CostDetailOption.ClaimsPerClaimant) {
                    lableData = d.claimsPerClaimaint;
                    this.decimalPlaceOvertime = 1;
                    this.yAxisTime = this._translateService.instant(
                        'DiseaseDetail.ClaimsPerClaimant'
                    );
                } else if (val == CostDetailOption.CostPerClaim) {
                    lableData = d.averageCostPerClaim;
                    this.decimalPlaceOvertime = this.isCountryChile ? 2 : 0;
                    this.yAxisTime = this._translateService.instant(
                        'DiseaseDetail.CostPerClaim'
                    );
                } else if (val == CostDetailOption.CostPerClaimant) {
                    lableData = d.averageCostPerClaimant;
                    this.decimalPlaceOvertime = this.isCountryChile ? 2 : 0;
                    this.yAxisTime = this._translateService.instant(
                        'DiseaseDetail.CostPerClaimant'
                    );
                } else if (val == CostDetailOption.TotalCost) {
                    lableData = d.claimsTotal;
                    this.decimalPlaceOvertime = 0;
                    this.yAxisTime = this._translateService.instant(
                        'DiseaseDetail.TotalCost'
                    );
                } else if (val == CostDetailOption.Prevalence) {
                    console.log(this.data.diseaseProgression.benchmark);
                    lableData = d.prevalence * 100;
                    this.decimalPlaceOvertime = 1;
                    this.isPercentage = true;
                    this.yAxisTime = this._translateService.instant(
                        'DiseaseDetail.Prevalence'
                    );
                }

                if (d.fromDateLabel === d.toDateLabel) {
                    this.categories.push(d.fromDateLabel + this.comparablePeriod);
                }
                else {
                    this.categories.push(d.fromDateLabel + ' ' + this._translateService.instant("DiseaseDetail.DateRange") + ' ' + d.toDateLabel + this.comparablePeriod);
                }

                this.maxValueForYaxis = lableData > this.maxValueForYaxis ? lableData : this.maxValueForYaxis;

                return lableData;
            }
        );

        if (this.eligibleForBenchmark) {
            this.data.diseaseProgression.benchmark.map((d: BenchmarkModel) => {
                if (this.properties.mode == CostDetailOption.TotalCost || this.properties.mode == CostDetailOption.ClaimantCount || this.properties.benchmarkLevel == 3) {
                    this.benchmarkVisibilityForDiseaseProgression = false;
                }
                else if (d.dimension == this.properties.mode && (this.properties.mode != CostDetailOption.TotalCost && this.properties.mode != CostDetailOption.ClaimantCount && this.properties.benchmarkLevel != 3)) {

                    if (this.isPercentage) {
                        d.benchmarkValue = d.benchmarkValue * 100;
                    }

                    this.benchmarkValueForDiseaseProgression = (d.benchmarkValue);
                    this.maxValueForYaxis = this.benchmarkValueForDiseaseProgression > this.maxValueForYaxis ? this.benchmarkValueForDiseaseProgression : this.maxValueForYaxis;

                    this.benchmarkLableForDiseaseProgression = this.format(d.benchmarkValue, this.decimalPlaceOvertime);
                    this.benchmarkVisibilityForDiseaseProgression = true;
                }
            });
        }
    }

    diseaseProgressionSelectionChanged(val, filterName: string) {

        if (typeof this.data.currentDiagnosis !== 'undefined' && this.data.currentDiagnosis !== '' && this.data.currentDiagnosis !== null && this.data.currentDiagnosis !== null) {
            this.properties.secondaryDimension = this.properties.diag;
            this.properties.secondaryDimensionValue = this.data.currentDiagnosis;
        }

        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED,
            data: {
                properties: {
                    name: filterName,
                    value: typeof val === 'object' ? val.data.join('||') : val,
                },
            },
        });
        this.diseaseProgressionChart();
    }

    resetDiagnosis() {
        this.isLoadingDiagnoses = true;
        this.resetFilterSelection();

        this.properties.dimension =
            this.properties.diag == CategoryChapterOption.DiagCat
                ? CategoryChapterOption.DiagCat
                : CategoryChapterOption.DiagChapter;
        this._dataService
            .getTier3Data(this.properties)
            .toPromise()
            .then((res) => {
                this.data.diagList = res;
                this.filterTop10List();
                this.isLoadingDiagnoses = false;
            });
    }
    resetDimensionChanges() {
        this.isLoadingDonut = true;
        this.resetFilterSelection();

        this.properties.dimension = this.properties.donutchartDimension;
        this._dataService
            .getTier3SingleDimensionData(this.properties)
            .toPromise()
            .then((res) => {
                this.data.tier3SingleDimensionData = res;
                this.donutChart(JSON.parse(JSON.stringify(res)));
            });
        this.isLoadingDonut = false;
    }

    resetOverTime() {
        this.isLoadingOverTime = true;
        this.resetFilterSelection();
        this.properties.dimension = GenderRelOptions.AgeBand;

        this._dataService
            .getTier3DiseaseProgressionData(this.properties)
            .toPromise()
            .then((resl) => {
                this.data.diseaseProgression = resl;
                this.diseaseProgressionChart();
                this.isLoadingOverTime = false;
            });
    }

    resetAll() {
        this.currentDonutSelection = '';
        this.data.currentDiagnosis = '';
        this.data.shownAsPerDisease = '';
        this.data.shownAsPerDonut = '';
        this.properties.secondaryDimension = '';
        this.properties.secondaryDimensionValue = '';
        this.properties.thirdDimension = '';
        this.properties.thirdDimensionValue = '';
        this.resetFilterSelection();
        this.resetDiagnosis();
        this.resetDimensionChanges();
        this.resetOverTime();
    }

    selectionChanged(val, filterName: string) {
        if (this.currentDonutSelection == '' && this.data.currentDiagnosis == '') {
            this.action.emit({
                type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
                data: {
                    properties: {
                        name: filterName,
                        value:
                            typeof val === 'object' ? val.data.join('||') : val,
                    },
                },
            });
            this.resetOverTime();
        } else {
            this.currentDonutSelection = '';
            this.data.currentDiagnosis = '';
            this.resetFilterSelection();
            this.action.emit({
                type: PulseEventName.FILTLER_CHANGED,
                data: {
                    properties: {
                        name: filterName,
                        value: val,
                    },
                },
            });
        }
    }

    filterSelectionChanged(val, filterName: string) {
        const selections = val.data.map(val => filterName === 'diagCatsOptions'
      ? this.diagGroupOptionList[indexOf(this.translatedDiagGroupOptionList,val)]
      : this.actionableOptionList[indexOf(this.translatedActionableOptionList,val)]).join('||')

        this.action.emit({
            type: PulseEventName.FILTLER_CHANGED,
            data: {
                properties: {
                    name: filterName,
                    value: selections,
                },
            },
        });
    }

    resetFilterSelection() {
        this.properties.secondaryDimension = '';
        this.properties.secondaryDimensionValue = '';
    }

    getAdditionalFilters(): any {
        const filter = sessionStorage.getItem(this.storageName);

        if (typeof filter === 'undefined') {
            return {};
        }

        if (filter === null) {
            return {};
        }

        return JSON.parse(filter);
    }

    additionalFilterSelectionChanged(val) {
        this.action.emit(val);
    }

    format(num, dec) {
        let number = num
            ? num.toLocaleString(undefined, { maximumFractionDigits: dec })
            : '';
        return number;
    }

    toTranslateFormat(text: string) {
        var translatedText = "";
        if (text) {
            translatedText = this._translateService.instant(this.translatePrefix + text[0].toUpperCase() + text.toLowerCase().slice(1));
        }

        return translatedText.startsWith(this.translatePrefix)
            ? text
            : translatedText;
    }
}

export enum GenderRelOptions {
    AgeBand = 'Age Band',
    Gender = 'Gender',
    Relationship = 'Relationship',
    ClaimsType = 'Claim Type',
}

export enum CostDetailOption {
    Age = 'Age',
    CostPerClaim = 'Cost Per Claim',
    CostPerClaimant = 'Cost Per Claimant',
    ClaimsPerClaimant = 'Claims Per Claimant',
    TotalCost = 'Total Cost',
    ClaimantCount = 'Claimant Count',
    Prevalence = 'Prevalence'
}

export enum CategoryChapterOption {
    DiagCat = 'Diag Cat',
    DiagChapter = 'Diag Chapter',
}

export enum ClaimClaimantsTotalOption {
    ClaimsTotal = 'claimsTotal',
    ClaimantsCount = 'claimantCount',
}

export class BenchmarkModel {
    dimension = '';
    benchmarkValue = 0;
}
