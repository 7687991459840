<div
    id="exportCartDash"
    layout-align="center center"
    style="text-align: center; overflow: hidden"
>
    <mat-spinner
        style="margin: 280px auto"
        *ngIf="isLoading"
        diameter="40"
    ></mat-spinner>
    <img
        id="exportCartLoad"
        style="width: 80%"
        *ngIf="dashboardImage && dashboardType == workbookTypeEnum.Tableau"
        [src]="dashboardImage"
        (load)="onImageLoad()"
    />
    <!-- <app-dashboardcontainer *ngIf='dashboardType == workbookTypeEnum.Custom'></app-dashboardcontainer> -->
    <app-dashboardcontainer
        id="exportCartCont"
        style="font-family: 'Helvetica Now Text', Arial, Helvetica, sans-serif"
        isExportSite="true"
    ></app-dashboardcontainer>
</div>
