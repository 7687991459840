export const locale = {
    lang: 'it',
    data: {
  'DoubleQuad': {
    'DoubleQuadHeading': 'Strutture - Analisi del doppio quadrante',
    'AllAmountsDisplayedInLocalCurrency': 'Tutti gli importi visualizzati in valuta locale',
    'Providers': 'Strutture sanitarie',
    'Diagnoses': 'Patologia',
    'ClaimantCount': 'Numero teste colpite',
    'ClaimCount': 'Conteggio Sinistri',
    'CostPerClaim': 'Costo per sinistro',
    'CostPerClaimant': 'Costo per assistito attivo',
    'Claim': 'Sinistri',
    'Claimant': 'Testa colpita',
    'ClaimsTotal': 'Sinistri totali',
    'Count': 'Conteggio',
    'ClaimsReportedByPaidDate': 'Sinistri per data di pagamento',
    'ClaimsReportedByIncurredDate': 'Sinistri per data di accadimento',
    'SelectTop': 'Seleziona top',
    'FilterNA': 'Escludi NA',
    'CountBasis': 'Base del conteggio',
    'Sort': 'Ordina',
    'Reset': 'Ripristina',
    'ShownAsPer': 'Indicato come per',
    'DateRange': 'Intervallo Di Date'
  }
}};