import { Component, OnInit } from '@angular/core';
import { AdminService } from 'app/services/admin.service';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';

@Component({
  selector: 'app-benchmark-limit-admin',
  templateUrl: './benchmark-limit-admin.component.html',
  styleUrls: ['./benchmark-limit-admin.component.scss']
})
export class BenchmarkLimitAdminComponent implements OnInit {

  public countryBenchmarkList: any[] = [];

  constructor(
    private _adminService: AdminService,
    private _toasterService: ToasterService) { }

  ngOnInit(): void {
    this._adminService.getCountryBencharkLimitList().toPromise()
      .then(result => this.countryBenchmarkList = result);
  }

  public saveBenchmarkLimitList(): void {
    this._adminService.updateCountryBencharkLimitList(this.countryBenchmarkList).toPromise().then(() => {
      this._toasterService.showMessage('Successfully Saved...', ToasterServiceModes.OK);
    }, () => {
      this._toasterService.showMessage('Failed...', ToasterServiceModes.NOK);
    });
  }

}
