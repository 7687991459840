export const APAC_COUNTRIES = [
    "Cambodia",
    "Hong Kong",
    "India",
    "Indonesia",
    "Indonesia",
    "Japan",
    "Malaysia",
    "Myanmar",
    "Philippines",
    "Singapore",
    "Thailand",
    "Vietnam",
];
