import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdditionalFiltersService } from '../additional-filters.service';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.css']
})
export class FilterButtonComponent implements OnInit {

    @Input() isClientName = false;
    @Input() isEntity = false;
    @Input() isDivison = false;
    @Input() isPlan = false;
    @Input() isTimePeriod = false;
    @Input() isTimePeriodLossRatio = false;
    @Input() isTimePeriodEnrollment = false;
    @Input() isPolicyType = false;
    @Input() isAgeBand = false;
    @Input() isGender = false;
    @Input() isClaimType = false;
    @Input() isClaimType2 = false;
    @Input() isClaimType3 = false;
    @Input() isRelationship = false;
    @Input() isDiagCat = false;
    @Input() isDiagChapter = false;
    @Input() isLocation = false;
    @Input() isChronic = false;
    @Input() isCarrier = false;
    @Input() isLOB = false;
    @Input() isSubLOB = false;
    @Input() isNetwork = false;
    @Input() isProviderName = false;
    @Input() isProviderLocation = false;
    @Input() isProviderType = false;

    noOfFiltersUsed: number;

    updateAdditionalFiltersSubscription: Subscription;
    intervalTimer: any;

    constructor(private _additionalFiltersService: AdditionalFiltersService) { 
        this.noOfFiltersUsed = this._additionalFiltersService.getNoOfFiltersUsed(this.isClientName, this.isEntity, 
            this.isDivison, this.isPlan, this.isTimePeriod, this.isTimePeriodLossRatio, this.isTimePeriodEnrollment, 
            this.isPolicyType, this.isAgeBand, this.isGender, this.isClaimType, this.isClaimType2, this.isClaimType3,
            this.isRelationship, this.isDiagCat, this.isDiagChapter, this.isLocation, this.isCarrier,
            this.isLOB, this.isSubLOB, this.isNetwork, this.isProviderName, this.isProviderLocation, this.isProviderType);
    }

    ngOnInit(): void {
        this.updateAdditionalFiltersSubscription = this._additionalFiltersService.updateAdditionalFilters.subscribe(() => {
            this.noOfFiltersUsed = this._additionalFiltersService.getNoOfFiltersUsed(this.isClientName, this.isEntity, 
                this.isDivison, this.isPlan, this.isTimePeriod, this.isTimePeriodLossRatio, this.isTimePeriodEnrollment, 
                this.isPolicyType, this.isAgeBand, this.isGender, this.isClaimType, this.isClaimType2, this.isClaimType3,
                this.isRelationship, this.isDiagCat, this.isDiagChapter, this.isLocation, this.isCarrier,
                this.isLOB, this.isSubLOB, this.isNetwork, this.isProviderName, this.isProviderLocation, this.isProviderType);
        });

        this.intervalTimer = setInterval(() => {
            this.noOfFiltersUsed = this._additionalFiltersService.getNoOfFiltersUsed(this.isClientName, this.isEntity, 
                this.isDivison, this.isPlan, this.isTimePeriod, this.isTimePeriodLossRatio, this.isTimePeriodEnrollment, 
                this.isPolicyType, this.isAgeBand, this.isGender, this.isClaimType, this.isClaimType2, this.isClaimType3,
                this.isRelationship, this.isDiagCat, this.isDiagChapter, this.isLocation, this.isCarrier,
                this.isLOB, this.isSubLOB, this.isNetwork, this.isProviderName, this.isProviderLocation, this.isProviderType);
        }, 1000);
    }

    ngOnDestroy(): void {
        this.updateAdditionalFiltersSubscription.unsubscribe();
        clearInterval(this.intervalTimer);
    }

}
