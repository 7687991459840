export const locale = {
    lang: 'es-pe',
    data: {
        'ClaimsOverview': {
            'Prior': 'Anterior',
            'Current': 'Actual',
            'ClaimType': 'Tipo de atención',
            'Relationship': 'Parentesco',
            'Entity': 'Empresa',
            'Network': 'Network',
            'Location': 'Localización',
            'ClaimsOverview': 'Resumen de siniestros',
            'TotalPaidClaim': 'Pago total de siniestro',
            'Total': 'Total',
            'NOOFCLAIMS': ' No de atenciones',
            'NOOFCLAIMANTS': 'No de pacientes',
            'Claim': 'Atención',
            'Claimant': 'Paciente',
            'Count': 'Count',
            'AllAmountsDisplayedInLocalCurrency': 'Moneda local',
            'ClaimsReportedByPaidDate': 'Siniestros informados por fecha de ocurrencia',
            'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
            'DateRange': 'to'
        }
    }
};