import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import * as Highcharts from 'highcharts';
import { countryLATEM } from 'app/constants/countries_LATAM';
import { NumberFormattingService } from "app/services/number-formatting.service";

@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.css']
})
export class LineComponent implements OnInit, OnChanges {

  chart: Chart;
  options: Options;  
  @Input() dataList: any;
  @Input() yAxisLable:any;
  @Input() categories:any;
  @Input() listName:string;
  @Input() height = 280;
  @Input() width= 783;
  @Input() decimalPlaceOvertime=0;
  @Input() benchmark = 0; 
  @Input() benchmarkLable : string  = ""; 
  @Input() benchmarkVisible : boolean = false; 
  @Input() isPercentage : boolean = false; 
  @Input() MaxOfY: number=5000;
  @Input() country: string;
  constructor(private _numberFormatService: NumberFormattingService) {
  }

  ngOnInit() {
    this.initialize();
    this.chart = new Chart(this.options);
  }
  ngOnChanges() {
    this.initialize();
    this.chart = new Chart(this.options);
  }
  initialize() {
    const parent = this;
    Highcharts.setOptions({
      lang: {
        thousandsSep: ","
      }
    });
    this.options = {
      chart: {
        type: 'line',        
        height: this.height,
        width:this.width
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.categories,
        labels:{
          style: {
            color: 'black',
            fontSize: '14px',
            textOutline: 'none',
            fontWeight: '400',
            fontFamily: 'Helvetica Now Text, Arial, Helvetica, sans-serif'
          },
        }
      },
      yAxis: {
        lineWidth: 1,     
        min:0,
        max: this.MaxOfY + this.MaxOfY * 0.05,
        title: {
          text: this.yAxisLable,
          style: {
            color: 'black',
            fontSize: '14px',
            textOutline: 'none',
            fontWeight: '400',
            fontFamily: 'Helvetica Now Text, Arial, Helvetica, sans-serif'
          },
        },
        labels:{   
          formatter: function(){
            return `${countryLATEM.indexOf(parent.country) >= 0 ?
            parent._numberFormatService.ToAbbrevNumberStringLatem(this.value, 4) :
            parent._numberFormatService.ToAbbrevNumberString(this.value, 4)}${parent.isPercentage ? '%' :''}`            
        },       
          style: {
            color: 'black',
            fontSize: '14px',
            textOutline: 'none',
            fontWeight: '400',
            fontFamily: 'Helvetica Now Text, Arial, Helvetica, sans-serif'
          }
      },
      plotLines: [{
        color: '#F25D00',
        width: this.benchmarkVisible ?  1 : 0,
        value: this.benchmark, 
        zIndex:  5 ,        
        label: {
          text:  this.benchmarkVisible ?  this.isPercentage ? this.benchmarkLable + "%":  this.benchmarkLable : "",               
          style: {
            color: '#F25D00',
            fontSize: '14px',
            textOutline: 'none',
            fontWeight: '400',
            fontFamily: 'Helvetica Now Text, Arial, Helvetica, sans-serif'
          }
        },
        dashStyle:'Dash',
      }]   
    },
      legend: {
        enabled: false,
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
            style: {
              color: 'black',
              fontSize: '14px',
              textOutline: 'none',
              fontWeight: '400',
              fontFamily: 'Helvetica Now Text, Arial, Helvetica, sans-serif'              
            },
            y: -5,
            format: `${this.decimalPlaceOvertime === 2 ? "{y:,.2f}":(this.decimalPlaceOvertime == 1? "{y:,.1f}":"{y:,.0f}")}${parent.isPercentage ? '%' : ''}`,

          }
        }
      },
      tooltip: {
        enabled: true,
         useHTML: true,
         headerFormat: "{point.x}<br>",
         pointFormat: this.decimalPlaceOvertime === 2 ? "<b>{series.name}</b>: {point.y:,.2f}<br>" : 
                      (this.decimalPlaceOvertime == 1 ? "<b>{series.name}</b>: {point.y:,.1f}<br>": "<b>{series.name}</b>: {point.y:,.0f}<br>"),
        style: {
          color: 'black',
          fontSize: '14px',
          textOutline: 'none',
          fontWeight: '400',
          fontFamily: 'Helvetica Now Text, Arial, Helvetica, sans-serif'
        },

      },
      series: [{
        type: undefined,
        name: this.listName,
        data: this.dataList,
        lineWidth: 0,
        marker: {
          symbol: 'circle',
          radius: 9,
        },
        states: {
          hover: {
            enabled: false,
            lineWidth: 0
          }
      }
      }]
    }
  }
}
