import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DashboardTemplateBase } from '../dashboard-template-base.component';
import { FilterOptions } from 'app/dashboards/shared/models/FilterOptions';
import { PulseEventName } from 'app/dashboards/shared/models/PulseEventName';
import { locale as navigationEnglish } from "app/dashboards/templates/enrollment-by-time/i18n/en";
import { locale as navigationSpanishCL } from "app/dashboards/templates/enrollment-by-time/i18n/es-cl";
import { locale as navigationSpanishCO } from "app/dashboards/templates/enrollment-by-time/i18n/es-co";
import { locale as navigationSpanishEC } from "app/dashboards/templates/enrollment-by-time/i18n/es-ec";
import { locale as navigationSpanishMX } from "app/dashboards/templates/enrollment-by-time/i18n/es-mx";
import { locale as navigationSpanishPE } from "app/dashboards/templates/enrollment-by-time/i18n/es-pe";
import { locale as navigationPortugueseBR } from "app/dashboards/templates/enrollment-by-time/i18n/pt-br";
import { locale as navigationItalianIT } from "app/dashboards/templates/enrollment-by-time/i18n/it";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { element } from 'protractor';
import { objectEach } from 'highcharts';
import { COLOR_PALETTE } from 'app/constants/color-palette';

@Component({
  selector: 'app-enrollment-by-time',
  templateUrl: './enrollment-by-time.component.html',
  styleUrls: ['./enrollment-by-time.component.css']
})
export class EnrollmentByTimeComponent implements OnInit, DashboardTemplateBase {

  isFilterOptionsLoading = true;

  data: any;
  properties: any;
  imageUrlBase = '';
  filterOptions: FilterOptions = new FilterOptions();
  PeriodOption = PeriodOption;
  GenderRelOptions = GenderRelOptions;
  showFilter: boolean = false;
  categories1: any;
  seriesDataList: any = [];
  parentLoaded = false;
  rollingPeriodSelection: number = 0;

  @Output() action = new EventEmitter<any>();
  xAxisCategoryLabelList: any = [];
  legendList: any[];

  comparablePeriod : string ="";
  initData(): any {
 if(this.rollingPeriodSelection == 0 )
    {
      this.rollingPeriodSelection == this.properties.rollingPeriod;
    }
    this.comparablePeriod =  this.properties.rollingPeriod == 3 ? " (C)":  "";
    // this.categories1 = this.data.xAxisCategoryLabelList;
    this.categories1 = this.data.xAxisCategoryLabelList.map(d => (d.fromDateLabel === d.toDateLabel) ? d.fromDateLabel : d.fromDateLabel + ' ' + this._translateService.instant("EnrollmentOverTime.DateRange")+ ' '  + d.toDateLabel);
    var colors = COLOR_PALETTE;
    var i = 0;
    this.legendList = [];
    this.seriesDataList =[];
    if (this.data.enrollmentByTimeDictionary) {
       this.data.yAxisCategoryLabelList.map((d) => {
        var safeObj = false;
        var list= this.data.enrollmentByTimeDictionary[d];
            for (var ij=0; ij< list.length; ij++) {
                if (list[ij] > 0){
                    safeObj = true;
                }
            }  
        if(safeObj){
        var objlegend = { name: '', color: '' }
        objlegend.name = d;
        objlegend.color = colors[i];
        this.legendList.push(objlegend);
        let seriesObj = {
          type: undefined,
          name: '',
          data: [],
          color: colors[i]
        }
        seriesObj.name = d;
        seriesObj.data = this.data.enrollmentByTimeDictionary[d];        
        this.seriesDataList.push(seriesObj);        
        i++;
      }
      });
      if(this.properties.dimension === GenderRelOptions.AgeBand){
        this.seriesDataList = Object.assign([], this.seriesDataList).reverse();
        this.legendList = Object.assign([], this.legendList).reverse();
      }
    }
    const countryListASIA = [
      'Hong Kong', 'Malaysia', 'Philippines', 'Singapore', 'Thailand'
    ];
   
     
    setTimeout(() => this.parentLoaded = true, 100);
  }

  constructor(private _translateService: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService) { }

  ngOnInit() {
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationSpanishCL,
      navigationSpanishCO,
      navigationSpanishEC,
      navigationSpanishMX,
      navigationSpanishPE,
      navigationPortugueseBR,
      navigationItalianIT,
    );  
  }
  

selectionChanged(val, filterName: string) {
  this.properties.FILTLER_CHANGED = true;
  this.action.emit({
    type: PulseEventName.FILTLER_CHANGED,
    data: {
      properties: {
        name: filterName,
        value: val 
      }
    },
  });
}
  
filterChanged(val, filterName: string) {
  this.action.emit({
    type: PulseEventName.FILTLER_CHANGED_WITHOUT_REFRESH,
    data: {
      properties: {
        name: filterName,
        value: val 
      }
    },
  });
}

additionalFilterSelectionChanged(val) {
  this.action.emit(val);
}

}

export enum PeriodOption {
  Monthly = 1,
  YearBasis = 2,
  YearBasisC = 3,
  Rolling3 = 4,
  Rolling6 = 5,
  Rolling9 = 6,
  Rolling12 = 7,
}

export enum GenderRelOptions {
  AgeBand = "Age Band",
  Gender = "Gender",
  Relationship = "Relationship",
  Employee = "Employee"
}