import { Component, OnInit, ChangeDetectorRef, HostListener, OnDestroy } from '@angular/core';
import { StoryModel } from 'app/models/story.model';
import { TableauService } from 'app/services/tableau.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { StoryService } from 'app/services/story.service';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as navigationEnglish } from './i18n/en';
import { locale as navigationSpanishCL } from './i18n/es-cl';
import { locale as navigationSpanishCO } from './i18n/es-co';
import { locale as navigationSpanishEC } from './i18n/es-ec';
import { locale as navigationSpanishMX } from './i18n/es-mx';
import { locale as navigationSpanishPE } from './i18n/es-pe';
import { locale as navigationPortugueseBR } from './i18n/pt-br';
import { locale as navigationItalianIT } from './i18n/it';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DashboardService } from 'app/services/dashboard.service';
import { CartService } from 'app/services/cart.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { Store } from '@ngrx/store';
import { State } from '../../_reducers';
import { GlobalfiltersService } from 'app/services/globalfilters.service';
import { UserService } from 'app/services/user.service';

declare var tableau: any;

@Component({
  selector: 'storyboard',
  templateUrl: './storyboard.component.html',
  styleUrls: ['./storyboard.component.css']
})
export class StoryboardComponent implements OnInit, OnDestroy {
  isReadOnly = false;
  libraryFlex = '160px';
  layoutStoryBoard = 'column';
  showStoryBoard = true;
  isLoading: boolean = false;
  storyModelObj = new StoryModel();
  dashboardList: any[] = [];
  clonedDashboardList: any[] = [];
  viewIndex = 0;
  activeViewMode: any;
  viewModes: any = {
    stream: 'stream',
    tile: 'tile',
    single: 'single',
  };
  isSingleViewChartPreviewNavigationShown = false;
  user: any;
  isLoadingOverlay = false;
  isFilterChangeLocked = false;
  loadedDashIndexArr = []; // used for dynamic sized charts
  renderredDashIndexArr = []; 
  
  constructor(
    private _ref: ChangeDetectorRef,
    private _tableauService: TableauService,
    private _translateService: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _cartService: CartService,
    private route: ActivatedRoute,
    private storyService: StoryService,
    private dashboardService: DashboardService,
    private globalFiltersService: GlobalfiltersService,
    private toaster: ToasterService,
    private store: Store<State>,
    private userService: UserService) {
    
    this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.reloadDashboards();
    });

    this.userService.userChanged.subscribe((event: LangChangeEvent) => {
      this.reloadDashboards();
    });
    
    this.route.paramMap.subscribe(params => {
      console.log('route change');
      this.dashboardList = [];
      var mode = params.get("mode");

      var sid = parseInt(params.get("id"));
      if(!sid && this.storyService.currentStoryId) {
        sid = this.storyService.currentStoryId;
        history.pushState({}, null, document.URL + '/' + sid);
      }
      if (sid) {
        this.isLoadingOverlay = true;
        this.storyModelObj.id = sid;
        this.storyService.getStoryById(this.storyModelObj.id).toPromise().then(res => {
          var story = res.result;
          this.storyModelObj.storyName = story.name;
          var dList = JSON.parse(story.metadata);

          
          if (mode == 'view') {
            this.isReadOnly = true;
            this.activeViewMode = this.viewModes.single; // load all in stream first
            setTimeout(() => {
              this.activeViewMode = this.viewModes.single;
              this.isLoadingOverlay = false;
            }, dList.length * 500 + 8000); // workaround for rendering issues of new charts
            
            dList.forEach(d => {

              // correct data

              if(d.workbookId) {
                console.log('Correcting story json - workbookId set to workbook.id')
                d.workbook = { id: d.workbookId}
              }
              var index = dList.indexOf(d);
              this.addDashboard(d, index, index == 0);
              this.viewIndex = 0;
            });
            this.renderredDashIndexArr.push(0);

          } else {
            this.activeViewMode = this.viewModes.stream;
            this.isLoadingOverlay = false;
          
            dList.forEach(d => {
  
              // correct data
  
              if(d.workbookId) {
                console.log('Correcting story json - workbookId set to workbook.id')
                d.workbook = { id: d.workbookId}
              }
  
              this.addDashboard(d);
              this.viewIndex = 0;
            });
          }
          setTimeout(() => this.resetScroll(), 800);
        }).catch((err) => {
          this.toaster.showMessage("Error opening story: " + err.statusText, ToasterServiceModes.NOK)
        });
      }
    });
  }

  ngOnInit() {
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish, 
      navigationSpanishCL,
      navigationSpanishCO,
      navigationSpanishEC,
      navigationSpanishMX,
      navigationSpanishPE, 
      navigationPortugueseBR,
      navigationItalianIT,
    );

    this.setLayout(this.activeViewMode);

    this.store.select('user').subscribe(res => {
        this.user = res;
    });
    
    document.addEventListener('webkitfullscreenchange', ((event: CustomEvent) => {
      if (!window.screenTop && !window.screenY) {
        this.isWindowFullScreen = false;
      } else {
        console.log("fullscreen");
        this.isWindowFullScreen = true;
      }
    }) as EventListener);
  }

  ngOnDestroy(): void {
    const vizList = tableau.VizManager.getVizs();
    if (vizList) {
        vizList.forEach(viz => {
            if (typeof viz.dispose !== 'undefined') { 
                viz.dispose();
            }
        });
    }

    this.dashboardList = [];
    this.clonedDashboardList = [];
  }

  getClientName() {
      if (typeof this.user.client === 'undefined') {
        return '';
      }

      if (this.user.client === null) {
        return '';
      }

      if (typeof this.user.client.name === 'undefined') {
          return '';
      }

      if (this.user.client.name === null) {
          return '';
      }

      return this.user.client.name;
  }

  reloadDashboards() {
    this.clonedDashboardList = [...this.dashboardList];

    this.dashboardList.splice(0, this.dashboardList.length);

    let vizList = tableau.VizManager.getVizs();

    vizList.forEach(viz => {
        if (typeof viz.dispose !== 'undefined') { 
            viz.dispose();
            //viz = null;
        }
    });

    let iframeArray = document.getElementsByClassName('tableau-container');

    while (iframeArray.length > 0) {
        iframeArray[0].parentNode.removeChild(iframeArray[0]);
    }

    this.clonedDashboardList.forEach(dash => {
        const div = document.getElementById(dash.containerId);

        if (typeof div !== 'undefined') {

            if (div !== null) {
                div.parentNode.removeChild(div);

                if (typeof dash.viz !== 'undefined') {
                    dash.viz.dispose();
                    //dash.viz = null;
                }
            }
        }
    });

    const lastViewedIndex = this.viewIndex;

    this.clonedDashboardList.forEach(d => {
      setTimeout(() => {
        this.addDashboard({
          contentUrl: d.contentUrl,
          createdAt: '',
          id: d.id,
          imageBase64String: d.imageBase64String,
          name: d.name,
          owner: null,
          project: { id: d.project.id },
          tags: {},
          updatedAt: '',
          workbook: { id: d.workbook.id }
        });

        if (this.clonedDashboardList.indexOf(d) === this.clonedDashboardList.length - 1) {
            this.viewIndex = lastViewedIndex;
        }
      }, 100);
    });
  }

  setLayout(mode) {
    console.log(mode);
    switch (mode) {
      case this.viewModes.stream:
        this.layoutStoryBoard = 'column';
        this.checkRender();
        break;
      case this.viewModes.tile:
        this.layoutStoryBoard = 'row wrap';
        break;
      case this.viewModes.single:
        // this.isSingleViewChartPreviewNavigationShown = true;
        this.layoutStoryBoard = 'column';
        break;
    }
  }

  checkRender(){
    for(var i = 0; i < this.dashboardList.length; i++) {
      if (this.renderredDashIndexArr.indexOf(i) < 0) {
        var dash = this.dashboardList[i];
        this.renderDashboard(dash);
        this.renderredDashIndexArr.push(i);
      }
    }
  }

  save() {
    this.isLoading = true;
    var dashListToSave = this.dashboardList.map(function (d) {
      return {
        imageBase64String: d.imageBase64String,
        id: d.id,
        name: d.name,
        contentUrl: d.contentUrl,
        project: d.project,
        workbook: d.workbook
      }
    });
    this.storyModelObj.metadata = JSON.stringify(dashListToSave);
    this.storyService.saveStory(this.storyModelObj).toPromise()
      .then(res => {
        if(!this.storyModelObj.id) {
          this.storyModelObj.id = res.result.result.storiesId;
          history.pushState({}, null, document.URL + '/' + res.result.result.storiesId);
        }
        this.toaster.showMessage('Story saved.', ToasterServiceModes.OK)
      })
      .catch(res => { this.toaster.showMessage('Error'); this.isLoading = false; })
      .finally(() => this.isLoading = false)
  }

  selectionChange($event) {
    if ($event.add) {
      this.addDashboard($event.add);
    }

    if ($event.remove) {
      this.removeDashboard([$event.remove]);
    }
  }

  onAdd($event) {
    if ($event.data) {
      var dashClone = JSON.parse(JSON.stringify($event.data));
      this.addDashboard(dashClone);
    }
  }

  filterChangeLogDict: any = {};

  addDashboard(dash, index = null, render = true) {
    if (!this.showStoryBoard) this.toggleLibrary();
    if(index == null) index = this.dashboardList.length;
    
    dash.containerId = "td-" + index;

    dash.isLoading = true;
    dash.isPrint = true;
    this.dashboardList.splice(index, 0, dash);
    this.viewIndex = index;
    if(render) {
      this.renderDashboard(dash);
      //this._ref.detectChanges();
    }
    if (this.activeViewMode === this.viewModes.single) {
      var scrollableRegion = document.getElementsByClassName("single-view-scrollable-region")[0];
      //var currentViewedDashboardRect = document.getElementsByClassName("single-view-card")[this.viewIndex].getBoundingClientRect();
      if (typeof scrollableRegion !== 'undefined') {
        scrollableRegion.scroll(scrollableRegion.scrollWidth, 0);
      }
    }
  }

  renderDashboard(dash) {
    this._tableauService.getToken().toPromise().then((token: any) => {
        dash.url = this.getDashboardUrl(dash, token);
        console.log(dash.url);
        dash.isLoading = false;
        
        setTimeout(() => {
            var div = document.getElementById(dash.containerId);
            if(!div) return; // prevent adding to bottom of page

            //div.parentNode.removeChild(div);

            if (typeof dash.viz !== 'undefined') {
                dash.viz.dispose();
                //dash.viz = null;
            }

            dash.viz = new tableau.Viz(div, dash.url, { hideToolbar: true });

            dash.getFitler = this.newDashboardFilterAction(dash, this.filterChangeLogDict);
            dash.viz.addEventListener(tableau.TableauEventName.FILTER_CHANGE, dash.getFitler);
            dash.viz.addEventListener(tableau.TableauEventName.PARAMETER_VALUE_CHANGE, dash.getFitler);

            dash.isLoading = false;
            
            setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 300);
        }, 100);

        }).catch(error => {
        console.log(error);
        });
  }

  // assigns a function to dash.getFilter that will be a callback for viz.addEventListener
  newDashboardFilterAction = function (dash, changeLog) {
    return function ($event) {
      if (!changeLog[dash.containerId]) {
        changeLog[dash.containerId] = {};
      }

      if (!this.isFilterChangeLocked) {
        this.isFilterChangeLocked = true;
        switch ($event.getEventName()) {
          case tableau.TableauEventName.FILTER_CHANGE:
            $event.getFilterAsync().then(f => {
              changeLog[dash.containerId][$event.getFieldName()] = {};
              changeLog[dash.containerId][$event.getFieldName()]['type'] = f.getFilterType();
              changeLog[dash.containerId][$event.getFieldName()]['selectedValues'] = f.getAppliedValues();
              console.log(f.getAppliedValues());
            });
            break;
          case tableau.TableauEventName.PARAMETER_VALUE_CHANGE:
            $event.getParameterAsync().then(p => {
              changeLog[dash.containerId][$event.getParameterName()] = {};
              changeLog[dash.containerId][$event.getParameterName()]['type'] = 'parameter';
              changeLog[dash.containerId][$event.getParameterName()]['selectedValues'] = p.getCurrentValue();
            });
            break;
        }
        setTimeout(() => { this.isFilterChangeLocked = false }, 3500);
      }
    }
  }

  viewStream() {
    this.activeViewMode = this.viewModes.stream;
    this.setLayout(this.activeViewMode);
    setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 200);
  }

  viewTile() {
    this.activeViewMode = this.viewModes.tile;
    this.setLayout(this.activeViewMode);
    setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 200);
  }

  viewSingle() {
    this.activeViewMode = this.viewModes.single;
    this.setLayout(this.activeViewMode);
    setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 200);
  }

  toggleSingleViewModeChartPreviewNavigationShown() {
    this.isSingleViewChartPreviewNavigationShown = !this.isSingleViewChartPreviewNavigationShown;
  }

  viewPrevious() {
    if (this.viewIndex > 0) {
      this.viewIndex--;
      
      if (this.renderredDashIndexArr.indexOf(this.viewIndex) < 0) {
        var dash = this.dashboardList[this.viewIndex];
        this.renderDashboard(dash);
        this.renderredDashIndexArr.push(this.viewIndex);
      }
      // setTimeout(() => { this.reloadIndexForSingleView(this.viewIndex) }, 100);
      setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 200);
    }
  }


  viewNext() {
    if (this.viewIndex < this.dashboardList.length - 1) {
      this.viewIndex++;

      if (this.renderredDashIndexArr.indexOf(this.viewIndex) < 0) {
        var dash = this.dashboardList[this.viewIndex];
        this.renderDashboard(dash);
        this.renderredDashIndexArr.push(this.viewIndex);
      }
      // setTimeout(() => { this.reloadIndexForSingleView(this.viewIndex) }, 100);
      setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 200);
    }
  }

  scrollToPrevious() {
    var scrollableRegion = document.getElementsByClassName("single-view-scrollable-region")[0];
    var dashboardRect = document.getElementsByClassName("single-view-card")[0].getBoundingClientRect();
    scrollableRegion.scrollLeft -= dashboardRect.width;
  }

  scrollToNext() {
    var scrollableRegion = document.getElementsByClassName("single-view-scrollable-region")[0];
    var dashboardRect = document.getElementsByClassName("single-view-card")[0].getBoundingClientRect();
    scrollableRegion.scrollLeft += dashboardRect.width;
  }

  resetScroll() {
    var scrollableRegion = document.getElementsByClassName("single-view-scrollable-region")[0];
    if(scrollableRegion) { 
      scrollableRegion.scrollLeft = 0;
    }
  }

  removeDashboard(index) {
    let dash = this.dashboardList[index];

    delete this.filterChangeLogDict[dash.containerId];
    this.dashboardList.splice(index, 1);

    const div = document.getElementById(dash.containerId);

    if (typeof div !== 'undefined') {
        
        if (typeof dash.viz !== 'undefined') {
            dash.viz.dispose();
            //dash.viz = null;
        }
    }
  }

  nextClick() {
    this.viewNext();
  }

  previousClick() {
    this.viewPrevious();
  }
  
  navigationClick(index) {
    this.viewIndex = index;
      
    if (this.renderredDashIndexArr.indexOf(this.viewIndex) < 0) {
      var dash = this.dashboardList[this.viewIndex];
      this.renderDashboard(dash);
      this.renderredDashIndexArr.push(this.viewIndex);
    }
    
    // setTimeout(() => { this.resetCustomViewLight(index) }, 100);
    setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 200);
  }

  storyValid() {
    return this.storyModelObj.storyName && this.dashboardList.length > 0;
  }

  toggleLibrary() {
    this.showStoryBoard = !this.showStoryBoard;
    this.libraryFlex = this.showStoryBoard ? '160px' : '100';
  }

  isWindowFullScreen = false;
  goFullScreen(index) {
    this.activeViewMode = this.viewModes.single;
    this.setLayout(this.activeViewMode);

    var element: any = document.getElementById('mainstoryboard');
    if (element.requestFullScreen) {
      element.requestFullScreen();
    } else if (element.mozRequestFullScreen) {  
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    }
  }

  loadDashboardCustomViews(index) {
    var dash = this.dashboardList[index];

    dash.filters = [];

    this.dashboardService.getDashboardCustomViews(dash.id, this.getClientName()).subscribe(result => {
      dash.filters = result;
    });
  }

  saveDashboardCustomView(index) {
    var dash = this.dashboardList[index];
    var changes = this.filterChangeLogDict[dash.containerId];
    if (!dash.filters) dash.filters = [];
    var customFilter = {
      name: dash.filterName,
      comments: dash.filterComments,
      filters: { ...changes }
    }

    dash.appliedCustomView = {...customFilter};

    this.dashboardService.saveDashboardCustomView(dash.id, this.getClientName(), customFilter.name, customFilter.comments, customFilter.filters).subscribe(result => {
      if (result) {
        console.log("Custom Filters Changed...");
        console.log(customFilter);
        this.loadDashboardCustomViews(index);
        //dash.filters.push(customFilter);
        delete dash.filterName;
        delete dash.filterComments;
      }
      else {
        alert("Name is already used. Please enter another name.");
      }
    });
  }

  deleteDashboardCustomView(index, filterRecord) {
    this.dashboardService.deleteDashboardCustomView(filterRecord.userCustomFiltersId).subscribe(result => {
      if (result) {
        var dash = this.dashboardList[index];
        dash.filters.splice(dash.filters.indexOf(filterRecord), 1);
      }
      else {
        alert("Filter record does not exist.");
      }
    });
  }

  getWorksheetCustomViews(wsFilters, ws) {
    var fil = {
      worksheetName: ws.getName(),
      filters: []
    };
    ws.getFiltersAsync().then(function (f) {
      fil.filters = f;
    });
    wsFilters.push(fil);
  }

  applyCustomView(index, filter) {
    const dash = this.dashboardList[index];
    let wb = dash.viz.getWorkbook();
    console.log(filter);

    wb.revertAllAsync().then(() => {
        this._tableauService.getToken().toPromise().then((token: any) => {
        dash.url = this.getDashboardUrl(dash, token);
        console.log(dash.url);
        setTimeout(() => {
            const div = document.getElementById(dash.containerId);
            if(!div) return; // prevent adding to bottom of page

            if (typeof dash.viz !== 'undefined') {
                dash.viz.dispose();
            }

            dash.viz = new tableau.Viz(div, dash.url, 
            { hideToolbar: true, 
                onFirstInteractive: () => {
                    wb = this.dashboardList[index].viz.getWorkbook();
                    const activeSheet = wb.getActiveSheet();
                    dash['appliedCustomView'] = filter;
                    switch (activeSheet.getSheetType()) {
                        case 'worksheet':
                        let ws = activeSheet;
                        this.setWorksheetFilter(wb, ws, filter);
                        break;
                        case 'dashboard':
                        let worksheets = activeSheet.getWorksheets();
                        worksheets.forEach(ws => {
                            this.setWorksheetFilter(wb, ws, filter);
                        });
                        break;
                        case 'story':
                        break;
                    }
                } 
            });

            this.updateDashboardEventListeners(dash);

            dash.isLoading = false;
            
            setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 300);
        }, 100);

        }).catch(error => {
        console.log(error);
        });        
    });



/*     wb.revertAllAsync().then(() => {
      var activeSheet = wb.getActiveSheet();
      dash['appliedCustomView'] = filter;
      switch (activeSheet.getSheetType()) {
        case 'worksheet':
          var ws = activeSheet;
          this.setWorksheetFilter(wb, ws, filter);
          break;
        case 'dashboard':
          var worksheets = activeSheet.getWorksheets();
          worksheets.forEach(ws => {
            this.setWorksheetFilter(wb, ws, filter);
          });
          break;
        case 'story':
          break;
      }
    }); */
  }

  getDashboardUrl(dash, token) {
    let urlPath = this._tableauService.getDashboardUrlPath(token, dash.contentUrl);
    console.log(urlPath);
    if (this.globalFiltersService.getGlobalFiltersString(this.user, dash.name, false)) {
        urlPath += ('?' + this.globalFiltersService.getGlobalFiltersString(this.user, dash.name, false));
    }

    return urlPath;
  }

  updateDashboardEventListeners(dash) {
    dash.viz.removeEventListener(tableau.TableauEventName.FILTER_CHANGE, dash.getFitler);
    dash.viz.removeEventListener(tableau.TableauEventName.PARAMETER_VALUE_CHANGE, dash.getFitler);

    delete this.filterChangeLogDict[dash.containerId];
    delete dash.getFitler;
    dash.getFitler = this.newDashboardFilterAction(dash, this.filterChangeLogDict);

    dash.viz.addEventListener(tableau.TableauEventName.FILTER_CHANGE, dash.getFitler);
    dash.viz.addEventListener(tableau.TableauEventName.PARAMETER_VALUE_CHANGE, dash.getFitler);
  }

  async setWorksheetFilter(wb, ws, filter) {
    console.log("Apply Filters");
    for (var filterName in filter.filters) {
      console.log(filterName);
      var f = filter.filters[filterName];
      switch (f.type) {
        case 'parameter':
          wb.changeParameterValueAsync(filterName, f.selectedValues.value);
          break;
        case 'categorical':
          await ws.applyFilterAsync(filterName, f.selectedValues.filter(v => v.value != null).map(v => v.value), tableau.FilterUpdateType.REPLACE)
          break;
      }
    }
  }

  resetCustomViewLight(i) {
    if (this.activeViewMode == this.viewModes.single
      && this.loadedDashIndexArr.indexOf(i) < 0) {
      this.loadedDashIndexArr.push(i);
      this.resetCustomView(i);

      var dash = this.dashboardList[i];

      if (typeof dash.filter !== 'undefined') {
        delete dash.filter;
      }

      var wb = dash.viz.getWorkbook();
      wb.revertAllAsync();
      delete this.filterChangeLogDict[dash.containerId];
    }
  }

  resetCustomView(index) {
/*     var dash = this.dashboardList[index];

    if (typeof dash.filter !== 'undefined') {
        delete dash.filter;
    }

    var wb = dash.viz.getWorkbook();
    wb.revertAllAsync();
    delete this.filterChangeLogDict[dash.containerId]; */

    let dash = this.dashboardList[index];
    let wb = dash.viz.getWorkbook();
    this.isLoading = true;

    wb.revertAllAsync().then(() => {
        this._tableauService.getToken().toPromise().then((token: any) => {
        dash.url = this.getDashboardUrl(dash, token);
        console.log(dash.url);
        setTimeout(() => {
            let div = document.getElementById(dash.containerId);
            if(!div) return; // prevent adding to bottom of page

            if (typeof dash.viz !== 'undefined') {
                dash.viz.dispose();
            }

            dash.viz = new tableau.Viz(div, dash.url, 
            { hideToolbar: true });

            this.updateDashboardEventListeners(dash);

            dash.isLoading = false;
            
            setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 300);
            this.isLoading = false;
        }, 100);

        }).catch(error => {
        console.log(error);
        });
    });
  }

  showExport = false;
  addToExport(index) {
    var changes = this.filterChangeLogDict[this.dashboardList[index].containerId];

    let changeSummary = [];

    let changeSummaryString = ""
    let changeSummaryStringList = [];

    if (typeof changes !== 'undefined') {
      var changeProps = Object.keys(changes);

      for (var prop of changeProps) {
        if (Array.isArray(changes[prop].selectedValues)) {
            changeSummary.push({ name: prop, type: changes[prop].type, selectedValues: changes[prop].selectedValues.map(v => v.value) });
        }
        else {
            changeSummary.push({ name: prop, type: changes[prop].type, selectedValues: changes[prop].selectedValues.value });
        }
      }

      for (var i = 0; i < changeSummary.length; i++) {
        var changeSummaryItem = changeSummary[i];
        if (Array.isArray(changeSummaryItem.selectedValues)) {
            changeSummaryStringList.push("vf_" + changeSummaryItem.name + "=" + changeSummaryItem.selectedValues.join(","));
        }
        else {
            changeSummaryStringList.push("vf_" + changeSummaryItem.name + "=" + changeSummaryItem.selectedValues);
        }
      }

      if (changeSummaryStringList.length > 0) {
        changeSummaryString = encodeURI(changeSummaryStringList.join("&"));
      }
    }

    let comments = '';
    let filterName = '';

    if (typeof this.dashboardList[index].appliedCustomView !== 'undefined') {
        comments = this.dashboardList[index].appliedCustomView.comments;
        filterName = this.dashboardList[index].appliedCustomView.name;
    }
    
    console.log(this.dashboardList[index]);
    // this._cartService.addItem(this.dashboardList[index].id,
    //     this.dashboardList[index].name, 
    //     this.dashboardList[index].contentUrl, 
    //     this.dashboardList[index].workbook.id, 
    //     this.dashboardList[index].id, 
    //     this.dashboardList[index].imageBase64String,
    //     comments,
    //     filterName,
    //     changeSummary, 
    //     changeSummaryString);
  }

  addAllToExport() {
      if (this.dashboardList.length === 0) {
          return;
      }

      for (var i = 0; i < this.dashboardList.length; i++) {
          this.addToExport(i);
      }     
  }

  showExportToggle() {
    this.showExport = !this.showExport;
    this.showStoryBoard = !this.showExport;
    console.log(this.showExport)
  }

  moveUp(index) {
    if(index > 0) {
      var dash = {...this.dashboardList[index]};
      this.dashboardList.splice(index, 1);
      this.addDashboard(dash, index - 1);
      console.log(this.filterChangeLogDict);
      this.filterChangeLogDict['td-' + index] = {...this.filterChangeLogDict['td-' + (index - 1)]};
      delete this.filterChangeLogDict['td-' + (index - 1)]; // reset filters of the moving dash
    }
  }

  moveDown(index) {
    if(index < this.dashboardList.length - 1) {
      var dash = {...this.dashboardList[index]};
      this.dashboardList.splice(index, 1);
      this.addDashboard(dash, index + 1);
      this.filterChangeLogDict['td-' + index] = {...this.filterChangeLogDict['td-' + (index + 1)]};
      delete this.filterChangeLogDict['td-' + (index + 1)]; 
    }
  }

  getTableauContainerHeight(){
    var heightOfOtherElements = 150;
    var height = '820px';

    if(this.activeViewMode == this.viewModes.single) {
      if(window.innerHeight == screen.height) heightOfOtherElements = 0;
      if(window.innerHeight == screen.height && this.isSingleViewChartPreviewNavigationShown) heightOfOtherElements = heightOfOtherElements + 90;

      if(this.isSingleViewChartPreviewNavigationShown) heightOfOtherElements = heightOfOtherElements + 130;
      height = 'calc(100vh - ' + heightOfOtherElements + 'px)';
    }

    return height;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    if(this.activeViewMode == this.viewModes.single) {
      if(event.key == 'ArrowLeft') {
        this.viewPrevious();
      } else if(event.key == 'ArrowRight') {
        this.viewNext();
      }

    }
  }
}
