import * as moment from 'moment';

export const locale = {
    lang: 'es-mx',
    data: {
        'UserAdmin': {
            'UserAdministration': 'Administración de Usuarios',
            'HelpText': 'Puede accederse por administradores regionales/locales. Utilizado por administradores regionales para asignar administradores locales.',
            'CountryManagers': 'Administrador País',
            'Users': 'Usuarios',
            'Countries': 'Paises',
            'Clients': 'Clientes',
            'Save': 'Guardar',
            'SelectAll': 'Seleccionar todo',
            'Add': 'Agregar',
            'Success': 'Éxito',
            'userColumnDefs': [
                {
                    headerName: 'Email',
                    field: 'email',
                    sort: 'asc'
                },
                {
                    headerName: 'Nombre',
                    field: 'userName',
                    sort: 'asc'
                },
                {
                    headerName: 'Creado en (Fecha)', field: 'createdOn', sort: 'desc', width: 200, suppressSizeToFit: true,
                    cellRenderer: (cell) => {
                        if (cell.data.createdOn === null) {
                            return '';
                        }
                        
                        const momentDate = moment.utc(cell.data.createdOn).toDate();
                        return moment(momentDate).local().format('MM/DD/YYYY HH:mm');
                    }
                }
            ],      
            'storyColumnDefs': [
                { headerName: 'Nombre', field: 'name', sort: 'asc' },
                { headerName: 'Creado por', field: 'createdBy', sort: 'asc' }
            ],
            'clientColumnDefs': [
                {
                    headerName: 'Nombre',
                    field: 'name',
                    sort: 'asc'
                }
            ],
            'countryColumnDefs': [
                {
                  headerName: 'Nombre',
                  field: 'name',
                  sort: 'asc'
                }
            ],
            'userCountryColumnDefs': [
                {
                  headerName: 'Nombre',
                  field: 'name',
                  sort: 'asc'
                }
            ]
        }
    }
};