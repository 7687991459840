<div
    id="loginDashboard"
    fxFlexFill
    fxLayout="column"
    fxLayoutAlign="center flex-end"
>
    <div
        style="
            width: 100%;
            position: absolute;
            height: 100%;
            background-image: url(assets/images/backgrounds/aon.jpg);
            background-size: cover;
            background-repeat: no-repeat background-posi;
            top: 0px;
        "
    ></div>
    <div fxLayout="row">
        <mat-card
            id="loginSection"
            style="width: 430px; z-index: 90; margin-right: 50px"
            fxLayout="column"
            *ngIf="!showCountrySelect"
        >
            <div style="height: 25px">
                <button
                    id="loginArrow"
                    style="padding-left: 0px"
                    mat-icon-button
                    *ngIf="
                        isForgotPassword ||
                        isResetPassword ||
                        aonLogin ||
                        externalLogin
                    "
                    class="mat-accent"
                    (click)="
                        aonLogin = false;
                        externalLogin = false;
                        isResetPassword = false;
                        isForgotPassword = false
                    "
                >
                    <mat-icon aria-label="Back">arrow_left</mat-icon>
                </button>
            </div>
            <span id="loginLogo" style="text-align: center"
                ><img src="/assets/images/logos/logo.svg" class="login__logo"
            /></span>
            <div
                id="loginBoxHeader"
                *ngIf="
                    !isForgotPassword &&
                    !isResetPassword &&
                    !aonLogin &&
                    !externalLogin
                "
            >
                <aon-h3>Welcome to Aon {{ profile.project.title }}</aon-h3>
                <aon-p-sm class="login__description">
                    All Aon colleagues, including designer level access, should
                    access via "Aon Colleagues Login". If you use Firefox you
                    will need to refresh the screen.
                </aon-p-sm>
                <a class="login__disclaimer-link" (click)="onClickDisclaimer()"
                    >Legal Disclaimer</a
                >
            </div>
            <div
                fxLayout="row"
                *ngIf="
                    !isResetPassword &&
                    !aonLogin &&
                    !externalLogin &&
                    !isForgotPassword
                "
                style="padding-top: 40px"
            >
                <div [fxFlex]="50" style="margin-right: 3px">
                    <aon-button-default
                        id="loginBtnAon"
                        width="300px"
                        (click)="
                            reset(); aonLogin = true; externalLogin = false
                        "
                    >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aon Colleagues
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <br />Login
                    </aon-button-default>
                </div>
                <div [fxFlex]="50" style="margin-left: 3px">
                    <aon-button-default
                        id="loginBtnExternal"
                        (click)="
                            reset(); externalLogin = true; aonLogin = false
                        "
                    >
                        External Partners Login
                    </aon-button-default>
                </div>
            </div>
            <div id="loginInternalSection" *ngIf="aonLogin" fxLayout="column">
                <aon-textbox
                    id="loginUserIn"
                    placeholder="Aon ID"
                    (onchange)="userName = $event.detail.value"
                ></aon-textbox>
                <aon-textbox
                    id="loginPasswordIn"
                    placeholder="Password"
                    type="password"
                    (onchange)="password = $event.detail.value"
                ></aon-textbox>
                <div id="loginInErr" style="height: 40px">
                    <mat-spinner
                        *ngIf="isLoading"
                        class="mat-accent"
                        style="margin: 0 auto"
                        diameter="30"
                    ></mat-spinner>
                    <span style="color: orangered" *ngIf="!isLoading">{{
                        errorMessage
                    }}</span>
                </div>
                <aon-button-default
                    id="loginBtnLoginIn"
                    width="100%"
                    (onclick)="loginAD()"
                    [disabled]="!userName || !password || isLoading"
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Login
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </aon-button-default>
            </div>
            <div
                id="loginExternalSection"
                *ngIf="externalLogin"
                fxLayout="column"
            >
                <aon-textbox
                    id="loginEmailEx"
                    placeholder="Email"
                    (onchange)="email = $event.detail.value"
                ></aon-textbox>
                <aon-textbox
                    id="loginUserPassword"
                    placeholder="Password"
                    type="password"
                    (onchange)="password = $event.detail.value"
                ></aon-textbox>
                <a
                    style="cursor: pointer"
                    (click)="isForgotPassword = true; externalLogin = false"
                    >Forget Password?</a
                >
                <div id="loginExErr" style="height: 40px">
                    <mat-spinner
                        *ngIf="isLoading"
                        class="mat-accent"
                        style="margin: 0 auto"
                        diameter="30"
                    ></mat-spinner>
                    <span style="color: orangered" *ngIf="!isLoading">{{
                        errorMessage
                    }}</span>
                    <span *ngIf="!isLoading">{{ signupSuccess }}</span>
                </div>
                <div>
                    <aon-button-default
                        id="loginBtnLoginEx"
                        style="margin: 0px 10px 0px 0px"
                        (onclick)="loginExternal()"
                        [disabled]="!email || !password"
                        >Login</aon-button-default
                    >
                </div>
            </div>
            <div
                id="resetPasswordSection"
                *ngIf="isResetPassword"
                fxLayout="column"
            >
                <aon-textbox
                    id="loginResetPassword"
                    placeholder="Password"
                    type="password"
                    (onchange)="checkResetPassword($event)"
                    [error]="resetPasswordError"
                ></aon-textbox>
                <aon-textbox
                    id="loginResetPasswordConfirm"
                    placeholder="Confirm Password"
                    type="password"
                    (onchange)="checkResetPasswordConfirm($event)"
                    [error]="resetPasswordConfirmError"
                ></aon-textbox>
                <div
                    *ngIf="passwordRequirementLanguage === 'English'"
                    style="height: 120px; font-size: 10px"
                >
                    <span
                        >Choose a strong password, and the requirement(s) as
                        below:</span
                    ><br />
                    <span style="padding-left: 10px"
                        >1. Password must have at least 8 character</span
                    ><br />
                    <span style="padding-left: 10px"
                        >2. Password must have at least 1 Digit [0-9]</span
                    ><br />
                    <span style="padding-left: 10px"
                        >3. Password must have at least 1 Uppercase [A-Z]</span
                    ><br />
                    <span style="padding-left: 10px"
                        >4. Password must have at least 1 lowercase [a-z]</span
                    ><br />
                    <span
                        style="
                            color: orangered;
                            font-size: 10px;
                            margin-top: 10px;
                        "
                        *ngIf="!resetPasswordSame"
                        >Error. Both passwords are not matching.</span
                    ><br />
                    <span style="font-size: 10px"
                        ><b style="color: blue">English</b> |
                        <span
                            (click)="
                                changePasswordRequirementLanguage('Spanish')
                            "
                            style="cursor: pointer"
                            >Español</span
                        >
                        |
                        <span
                            (click)="
                                changePasswordRequirementLanguage('Portuguese')
                            "
                            style="cursor: pointer"
                            >Português</span
                        ></span
                    >
                </div>
                <div
                    *ngIf="passwordRequirementLanguage === 'Spanish'"
                    style="height: 120px; font-size: 10px"
                >
                    <span
                        >Escoja una contraseña segura, de acuerdo con las
                        características mencionadas a continuación:</span
                    ><br />
                    <span style="padding-left: 10px"
                        >1. La contraseña debe tener al menos 8 caracteres</span
                    ><br />
                    <span style="padding-left: 10px"
                        >2. La contraseña debe tener al menos 1 número
                        [0-9]</span
                    ><br />
                    <span style="padding-left: 10px"
                        >3. La contraseña debe tener al menos 1 mayúscula
                        [A-Z]</span
                    ><br />
                    <span style="padding-left: 10px"
                        >4. La contraseña debe tener al menos 1 minúscula
                        [a-z]</span
                    ><br />
                    <span
                        style="
                            color: orangered;
                            font-size: 10px;
                            margin-top: 10px;
                        "
                        *ngIf="!resetPasswordSame"
                        >Las contraseñas no coinciden. Por favor digítela
                        nuevamente. </span
                    ><br />
                    <span style="font-size: 10px"
                        ><span
                            (click)="
                                changePasswordRequirementLanguage('English')
                            "
                            style="cursor: pointer"
                            >English</span
                        >
                        | <b style="color: blue">Español</b> |
                        <span
                            (click)="
                                changePasswordRequirementLanguage('Portuguese')
                            "
                            style="cursor: pointer"
                            >Português</span
                        ></span
                    >
                </div>
                <div
                    *ngIf="passwordRequirementLanguage === 'Portuguese'"
                    style="height: 120px; font-size: 10px"
                >
                    <span
                        >Escolha uma senha forte, de acordo com as
                        características mencionadas a seguir:</span
                    ><br />
                    <span style="padding-left: 10px"
                        >1. A senha deve ter pelo menos 8 caracteres</span
                    ><br />
                    <span style="padding-left: 10px"
                        >2. A senha deve ter pelo menos 1 número [0-9]</span
                    ><br />
                    <span style="padding-left: 10px"
                        >3. A senha deve ser pelo menos 1 maiúscula [A-Z]</span
                    ><br />
                    <span style="padding-left: 10px"
                        >4. A senha deve ter pelo menos 1 letra minúscula
                        [a-z]</span
                    ><br />
                    <span
                        style="
                            color: orangered;
                            font-size: 10px;
                            margin-top: 10px;
                        "
                        *ngIf="!resetPasswordSame"
                        >As senhas não correspondem. Por favor, digite
                        novamente.</span
                    ><br />
                    <span style="font-size: 10px"
                        ><span
                            (click)="
                                changePasswordRequirementLanguage('English')
                            "
                            style="cursor: pointer"
                            >English</span
                        >
                        |
                        <span
                            (click)="
                                changePasswordRequirementLanguage('Spanish')
                            "
                            style="cursor: pointer"
                            >Español</span
                        >
                        | <b style="color: blue">Português</b></span
                    >
                </div>
                <div>
                    <aon-button-default
                        id="loginResetPasswordBtn"
                        style="margin: 0px 10px 0px 0px"
                        (onclick)="onResetPassword()"
                        [disabled]="
                            !resetPassword ||
                            !resetPasswordConfirm ||
                            resetPassword !== resetPasswordConfirm ||
                            resetPasswordError ||
                            resetPasswordConfirmError
                        "
                    >
                        Set Password</aon-button-default
                    >
                </div>
            </div>
            <div
                id="forgotPasswordSection"
                *ngIf="isForgotPassword"
                fxLayout="column"
            >
                <aon-p-sm>
                    Enter your email below and you will receive the password
                    reset link via email.
                </aon-p-sm>
                <aon-textbox
                    id="loginEmailForgotPassword"
                    placeholder="Email"
                    (onchange)="email = $event.detail.value"
                >
                </aon-textbox>
                <div id="loginExErr" style="height: 40px">
                    <mat-spinner
                        *ngIf="isLoading"
                        class="mat-accent"
                        style="margin: 0 auto"
                        diameter="30"
                    ></mat-spinner>
                    <span style="color: orangered" *ngIf="!isLoading">{{
                        errorMessage
                    }}</span>
                    <span *ngIf="!isLoading">{{ signupSuccess }}</span>
                </div>
                <div>
                    <aon-button-default
                        id="loginForgotPasswordBtn"
                        style="margin: 0px 10px 0px 0px"
                        (onclick)="onForgotPassword()"
                        [disabled]="email.trim().length === 0"
                        >Send Email</aon-button-default
                    >
                </div>
            </div>
            <br />
        </mat-card>
        <mat-card
            id="loginUserOption"
            style="min-width: 520px"
            fxLayout="column"
            *ngIf="showCountrySelect"
        >
            <app-useroptions
                [showLanguage]="
                    profile.user.userOptions.isTranslationOptionShown
                "
            ></app-useroptions>
        </mat-card>
    </div>
</div>
