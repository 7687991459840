export const locale = {
    lang: 'es-cl',
    data: {
        'DoubleQuad': {
            'DoubleQuadHeading':'Prestadores - Analisis de doble cuadrante',           
            'Providers':'Prestadores', 
            'Diagnoses':'Detalle Prestación' ,
            'AllAmountsDisplayedInLocalCurrency' : 'Todas las cantidades mostradas en moneda local',
            'ClaimantCount': 'Cantidad de Reclamantes',
            'ClaimCount': 'Cantidad de prestaciones',            
            'CostPerClaim': 'Reembolso por prestaci�n',
            'CostPerClaimant': 'Costo Por Reclamante', 
            'Claim' : 'Reclamo', 
            'Claimant' : 'Reclamante', 
            'ClaimsTotal':'Monto Reembolsado Total',
            'Count': 'Contar',
            'ClaimsReportedByPaidDate': 'Reembolsos reportados por fecha de pago',
            'ClaimsReportedByIncurredDate': 'Reembolsos reportadas por fecha incurrida',
            'SelectTop': 'Seleccionar Top',
            'FilterNA': 'Excluir NA',
            'CountBasis': 'Contar Base',
            'Sort': 'Ordenar',
            'Reset':'Reiniciar',
            'ShownAsPer':'Se muestra según',
            'DateRange': 'to'
        }
    }
};
