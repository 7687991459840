export const locale = {
    lang: 'es-ec',
    data: {
        'SuperAdmin': {
            'Settings': 'AJUSTES',
            'HelpText': 'Accedido solo por administradores.',
            'RegionalManagers': 'Gerentes Regionales',
            'Countries': 'Países',
            'Clients': 'Clientela',
            'Save': 'SALVAR',
            'NewClient':'Cliente Nuevo',
            'NewCountry':'Nuevo País',
            'EnableCountry': 'Enable Country?',
            'Name':'Nombre',
            'Create': 'CREAR',
            'Update':'ACTUALIZAR',
            'Add': 'AÑADIR',
            'Delete': 'ELIMINAR',
            'Cancel':'CANCELAR',
            'Users':'Usuarios',
            'User':'Usuario',
            'CreateAccount':'Crear una cuenta',
            'DeleteAccount': 'Borrar cuenta',
            'Roles':'Roles',
            'rmColumnDefs': [
                {
                  headerName: 'Email',
                  field: 'email',
                  sort: 'asc'
                },
                { 
                  headerName: 'Nombre', 
                  field: 'userName',
                },
                {
                    headerName: 'Fecha de creación', field: 'createdOn'
                }
            ],
            'countryColumnDefs': [
                { 
                  headerName: 'Nombre', 
                  field: 'name', 
                  sort: 'asc' 
                }
            ],
            'clientColumnDefs': [
                { 
                  headerName: 'Nombre', 
                  field: 'name', 
                  sort: 'asc',
                  width: 500
                },
                { 
                    headerName: 'Is Group?', 
                    field: 'isGroup',
                    cellRenderer: params => {
                        return `<input type='checkbox' disabled readonly ${params.value ? 'checked' : ''} />`;
                    }
                },
                { 
                    headerName: 'Created', 
                    field: 'createdOn'
                },
                { 
                    headerName: 'Selected', 
                    field: 'isSelected'
                }
            ]
        }
    }
};
