export const locale = {
    lang: 'en',
    data: {
        'Toolbar': {
            'MyProfile': 'My Profile',
            'Logout': 'Logout',
            'Cart': 'Cart'
        }
    }
};
