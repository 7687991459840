import { Component, Input, OnInit, Output, EventEmitter, OnChanges, } from "@angular/core";

@Component({
    selector: "location-chart",
    templateUrl: "./locationChart.component.html",
    styleUrls: ["./locationChart.component.scss"],
})
export class LocationChart implements OnInit, OnChanges {

    @Input() title: string = "";
    @Input() inputSeries: any[] = [];
    @Input() xAxisLabels: any = [];
    @Output() selectFilter: EventEmitter<any> = new EventEmitter<any>();
    chartId = "demographicsActiveRetiree";
    height: any = "380px";
    width: any = "100%";
    cardheight: any = "450px";
    cardwidth: any = "490px";
    tooltip: boolean = true;
    titleposition = "left";
    charttitle = "Demographics";
    yaxistitle = "";
    xaxistitle = "";
    color = "white";
    maxyaxisnumber: number = 1000;
    dataAgeBand: any;
    dataGender: any;
    fuseConfig: any;
    navigation: any;

    displayicons: boolean = false;
    legendsubtitle: string = "Total Employees";
    legendTrim: number = 0;
    legendtitle = "";
    legendPosition = "left";
    middleText: any = "500";
    showLegend: boolean = true;
    isAgeBandSelected: boolean = true;

    updateFlag = false;
    xScaleMax = 100;
    xx = 10;
    legendTitle: string = "Number of Employees";
    @Input() isError = false;
    @Input() errorMsg = "No Data Found.";
    constructor() { }


    ngOnInit() {

    }
    ngOnChanges() {
    }

    loadBidChartData(): void {
    }
}
