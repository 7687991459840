import { OverlayContainer } from '@angular/cdk/overlay';

const FULLSCREEN_EVENTS = [
  'fullscreenchange',
  'webkitfullscreenchange',
  'mozfullscreenchange',
  'MSFullscreenChange',
];

export class CustomOverlayContainer extends OverlayContainer {
  constructor(document: any) {
    super(document);
    this.handleFullscreen = this.handleFullscreen.bind(this);
  }

  _createContainer(): void {
    FULLSCREEN_EVENTS.forEach(e => document.removeEventListener(e, this.handleFullscreen));
    FULLSCREEN_EVENTS.forEach(e => document.addEventListener(e, this.handleFullscreen));

    let container = document.createElement('div');
    container.classList.add('cdk-overlay-container');
    document.body.append(container);

    this._containerElement = container;
  }

  private handleFullscreen() {
    if (document.fullscreenElement) {
      document.querySelector('#mainstoryboard')?.appendChild(this.getContainerElement());
      return;
    }

    document.body.append(this.getContainerElement());
  }
}