<button
  [matTooltip]="exportSummary"
  matTooltipClass="export-button__tooltip"
  class="export-button"
  (click)="handleClickCart()"
>
  <fa-icon [icon]="['fal', 'shopping-cart']"></fa-icon>
  <span class="export-button__badge">
    {{ (cartItems$ | async)?.length }}
  </span>
</button>