import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CLAIM_BASIS } from 'app/models/basis.model';
import { CostProjectionInputs, Membership, TimePeriod } from 'app/models/cost-projection/cost-projection-inputs.model';
import { CostProjectionResults } from 'app/models/cost-projection/cost-projection-results.model';
import { UserCostProjection } from 'app/models/cost-projection/user-cost-projection.model';
import { downloadBlob } from 'app/operators/downloadBlob';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CostProjectionService {

  constructor(private _httpClient: HttpClient) { }

  // Retrieve Cost Projection Inputs to populate Input Form
  public retrieveCostProjectionInputs(clientId: number, countryId: number, claimBasis: CLAIM_BASIS, maxDate: Date): Observable<CostProjectionInputs>
  {
    return this._httpClient.post<CostProjectionInputs>(`${environment.api}api/CostProjection/inputs`, 
    {
      clientId,
      countryId,
      claimBasis,
      maxDate: this._parseDateTimeUtc(maxDate.toString())
    });
  }

  // Retrieve Cost Projection Inputs (Membership Breakdown) when User changes Time Period values
  public retrieveCostProjectionInputsMembershipBreakdown(clientId: number, countryId: number, timePeriods: TimePeriod): Observable<Membership>
  {
    const clonedTimePeriods = {...timePeriods};
    this._convertTimePeriodToUtc(clonedTimePeriods);

    return this._httpClient.post<Membership>(`${environment.api}api/CostProjection/Membership`, 
    {
      clientId,
      countryId,
      timePeriods: clonedTimePeriods
    });
  }

  // Compute Cost Projection Results using Calculation Engine
  public computeCostProjectionResults(clientId: number, countryId: number, inputsFormValues: CostProjectionInputs): Observable<CostProjectionResults>
  {
    const clonedInputsFormValues = {...inputsFormValues};
    this._convertTimePeriodToUtc(clonedInputsFormValues.timePeriod);

    return this._httpClient.post<CostProjectionResults>(`${environment.api}api/CostProjection/client/${clientId}/country/${countryId}`, clonedInputsFormValues);
  }

  // Retrieve all saved User's Cost Projection Inputs and Results
  public retrieveUserCostProjections(clientId: number, countryId: number): Observable<UserCostProjection[]>
  {
    return this._httpClient.get<UserCostProjection[]>(`${environment.api}api/CostProjection/saved/client/${clientId}/country/${countryId}`);
  }

  // Save User's Cost Projection Inputs and Results for future reference
  public saveUserCostProjection(
    name: string, 
    clientId: number, 
    countryId: number, 
    inputsFormValues: CostProjectionInputs, 
    resultsFormValues: CostProjectionResults): Observable<any>
  {
    return this._httpClient.post(`${environment.api}api/CostProjection/save`, 
    {
      name,
      clientId,
      countryId,
      inputs: JSON.stringify(inputsFormValues),
      results: JSON.stringify(resultsFormValues),
      createdBy: '',
      createdOn: new Date()
    });
  }

  public deleteUserCostProjection(id: number): Observable<any> {
    return this._httpClient.delete(`${environment.api}api/CostProjection/delete/${id}`);
  }

  public retrieveCostProjectionCalcFile(
    clientId: number, 
    countryId: number, 
    inputsFormValues: CostProjectionInputs,
    resultsFormValues: CostProjectionResults
    ): Observable<any>
  {
    const clonedInputsFormValues = {...inputsFormValues};
    this._convertTimePeriodToUtc(clonedInputsFormValues.timePeriod);

    return this._httpClient.post<Blob>(`${environment.api}api/CostProjection/Calc/File`, 
    {
      clientId,
      countryId,
      inputsFormValues: clonedInputsFormValues,
      resultsFormValues
    }, {
      observe: 'response',
      responseType: 'blob' as 'json',
    }).pipe(downloadBlob());
  }

  private _convertTimePeriodToUtc(timePeriods: TimePeriod): void {
    timePeriods.claimsPeriod.start = this._parseDateTimeUtc(timePeriods.claimsPeriod.start);
    timePeriods.claimsPeriod.end = this._parseDateTimeUtc(timePeriods.claimsPeriod.end);

    timePeriods.exclusionPeriod.start = this._parseDateTimeUtc(timePeriods.exclusionPeriod.start);
    timePeriods.exclusionPeriod.end = this._parseDateTimeUtc(timePeriods.exclusionPeriod.end);

    timePeriods.projectionPeriod.start = this._parseDateTimeUtc(timePeriods.projectionPeriod.start);
    timePeriods.projectionPeriod.end = this._parseDateTimeUtc(timePeriods.projectionPeriod.end);
  }

  private _parseDateTimeUtc(dateTime: string): string {
    return formatDate(dateTime, 'short', 'en-US');
  }
}
