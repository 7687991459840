export const locale = {
    lang: 'es-mx',
    data: {
        'LossRatio': {
            'LossRatioHeading' : 'Severidad promedio',
            'AllAmountsDisplayedInLocalCurrency' :  'Todas las cantidades mostradas en moneda local',
            'Loss' : 'Severidad promedio',
            'LossRatioType' : 'Severidad',
            'CombinedLR' : 'Siniestralidad acumulada',
            'ClaimsTotalPMPM' : 'Total pagado // Promedio',
            'ClaimsPMPM' : 'Promedio',
            'ClaimsTotal' : 'Total pagado',
            'TargetLossRatio' : 'Objetivo Severidad promedio',
            'ClaimsReportedByPaidDate' :  ' ',
            'ClaimsReportedByIncurredDate' :  ' ',
            'Monthly' : 'Por Mes',
            'YearToDateBasis' : 'Año completo a la fecha (completas) básico',
            'YearToDateBasisC' : 'Año completo a la fecha (C) básico',
            'Rolling3Basis' : 'Continuos 3 Básicos',
            'Rolling6Basis' : 'Continuos 6 Básicos',
            'Rolling9Basis' : 'Continuos 9 Básicos',
            'Rolling12Basis' : 'Continuos 12 Básicos',
            'Show':'mostrar',
             'Hide':'Esconder',
            'ShowHide' : 'Mostrar/Esconder',
            'Claims' : 'Monto pagado',
            'Premium' : 'Primas',
            'Income':'Ingresos',
            'Expense':'Gastos',
            'ClaimLabel': 'Tramites + Gastos',
            'PremimumLabel':'Primas + Ingresos',
            'MonthlyLossRatio':'Siniestralidad mensual',
            'CumulativeLossRatio':'Siniestralidad acumulada',
            'DateRange': 'to'

        }
}
};
