import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { Store } from '@ngrx/store';
import { State } from 'app/_reducers';
import { FWBService } from 'app/fwb/services/fwb.service';
import { User } from 'app/models/user.model';
import { navigation } from 'app/navigation/navigation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BenefitQuestions } from 'app/fwb/constants/fwb-charts-data';
import { FwbWellbeingService } from 'app/fwb/services/fwb-wellbeing.service';

@Component({
  selector: 'fwb-top-summary',
  templateUrl: './fwb-top-summary.component.html',
  styleUrls: ['./fwb-top-summary.component.css']
})
export class FwbTopSummaryComponent implements OnInit, OnChanges {
  chartId = "topSummaryComponent";
  height: any = "380px";
  width: any = "98%";
  scoreCardheight: any = "540px";
  scoreCardwidth: any = "95%";
  cardheight: any = "490px";
  cardwidth: any = "98%";
  tooltip: boolean = true;
  titleposition = "left";
  charttitle = "";
  yaxistitle = "";
  xaxistitle = "Questions";
  xaxisSelectedQuestion = "Questions";
  color = "white";
  maxyaxisnumber: number = 800;
  dataMin: any;
  dataMax: any;
  dataCom: any;

  fuseConfig: any;
  navigation: any;

  legendTrim: number = 0;
  legendtitle = "";
  legendPosition: string = "bottom";
  middleText: any = "500";
  gridlines: any = [];
  showLegendMin: boolean = false;
  showLegendMod: boolean = true;
  showLegendCom: boolean = false;
  isAgeBandSelected: boolean = true;
  questionsData: any[];
  questionsDetails: any[];
  selectedQuestion: number = 1;
  fwb: any;

  colorindex: number = 3;
  contenttop: string = "70px";
  public user: User;
  displaytotal: boolean = false;
  serviceData: any = null;
  isLoading = true;
  loaded = false;
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _wellbeingService: FwbWellbeingService,
    private _fwbService: FWBService,
    private store: Store<State>,
    private _router: Router
  ) {
    // Set the defaults
    this.navigation = navigation;

    this.store.select('fwb').forEach(res => {
      if (res == undefined) {
        console.log("this.fwb is undefined");
      } else {

        this.selectedQuestion = parseInt(res.question);

        this.displaytotal = !res.togglequestionselection;
        this.questionsData = this.changeQuestion(this.selectedQuestion);
        // this.xaxisSelectedQuestion = "selected : " + this.selectedQuestion;
        this.chartId = "topSummaryComponent" + (new Date()).toISOString() + "_question_" + this.selectedQuestion;
        console.log(this.selectedQuestion);
      }

    });
  }
  // Private
  private _unsubscribeAll: Subject<any>;
  private _unsubscribeQuestion: Subject<any>;
  ngOnChanges() {
    this.changeQuestion(this.selectedQuestion);
  }

  ngOnInit() {

    // if (this.serviceData === null) {
    this.getData();
    this.maxyaxisnumber = 1000;
    // }
  }


  getData() {
    this.serviceData = null;
    this._wellbeingService.getQuestions("").toPromise().then((resp) => {
      this.serviceData = resp;
      console.log(this.serviceData);
      this.setData();
      this.changeQuestion(this.selectedQuestion);
      this.loaded = true;
    }).catch((e) => {
      console.log(e);

    }).finally(() => {
      this.isLoading = false;
    })
  }

  setData() {
    this.questionsData = this.serviceData.result;
  }
  getUser() {

  }

  changeQuestion(id): any[] {
    console.log("Question change request was called" + id);
    // this.questionsData = BenefitQuestions;
    this.questionsData.forEach((item) => {
      if (item.id == id && this.displaytotal) { item.colorindex = 2; }
      else { item.colorindex = 11; }
    });
    return this.questionsData;
  }

}