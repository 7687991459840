export const locale = {
  lang: 'en',
  data: {
    'Storyboard': {
        'AddAll': 'Add All',
        'Save': 'Save',        
        'Export': 'Export',
        'Library': 'Library',
        'Comments': 'Comments',
        'CustomViews': 'Custom Views',
        'CustomViewName': 'Custom View Name',
        'ApplyCustomView': 'Apply Custom View',
        'SaveCustomView': 'Save Custom View',
        'ResetCustomView': 'Reset Custom View',
        'NoCustomViewFound': 'No custom view found',
        'AddToExport': 'Add To Export',
        'DownloadImage': 'Download Image',
        'ADD': 'ADD',
        'SAVE': 'SAVE',
        'CANCEL': 'CANCEL'
    }
  }
};
