<app-base-dialog
  [title]="title"
  [closeLabel]="'OK'"
  [hideCloseIcon]="true"
>
  <!-- Header -->
  <div
    class="disclaimer__lang-container"
    fxLayoutAlign="end center"
    base-dialog-custom-close-icon
  >
    <span
      class="disclaimer__lang-desc"
      [matMenuTriggerFor]="languageMenu"
    >{{selectedLanguage}}</span>
    <button
      class="disclaimer__lang-icon"
      [matMenuTriggerFor]="languageMenu"
    >
      <fa-icon [icon]="['fal', 'globe-americas']"></fa-icon>
    </button>

    <mat-menu
      #languageMenu="matMenu"
      [overlapTrigger]="false"
      style="height: 38px;"
    >
      <button
        class="disclaimer__lang-option"
        mat-menu-item
        *ngFor="let lang of languages"
        (click)="onLanguageChange(lang)"
      >
        {{lang}}
      </button>
    </mat-menu>
  </div>

  <!-- Text Content -->
  <ng-container
    *ngIf="isTargetLanguage(LOCALE_GROUP.EN)"
    [ngTemplateOutlet]="english"
  ></ng-container>
  <ng-container
    *ngIf="isTargetLanguage(LOCALE_GROUP.ES)"
    [ngTemplateOutlet]="spanish"
  ></ng-container>
  <ng-container
    *ngIf="isTargetLanguage(LOCALE_GROUP.PT)"
    [ngTemplateOutlet]="portuguese"
  ></ng-container>
</app-base-dialog>

<ng-template #english>
  <p class="disclaimer__paragraph">
    The information contained in this tool is confidential and proprietary to
    Aon. It may only be used to support engagements by an Aon employee with a
    client or prospective client for the purpose of placing or intending to
    place its insurance cover(s). It may not be shared with any other third
    party or for any other purpose.
  </p>

  <p class="disclaimer__paragraph">
    Any recommendation or assessment is an expression of Aon’s opinion only, and
    is not a statement of fact, and any decision made by an entity to rely on
    any such recommendation or assessment of risk is taken at that entity’s own
    risk. Reliance on information contained within this report by anyone for
    other than the intended purposes puts the relying entity at risk of being
    misled because of confusion or failure to understand applicable assumptions,
    methodologies, or limitations of the report’s conclusions.
  </p>

  <p class="disclaimer__paragraph">
    The tool generates future forecasts derived from historical information. We
    also rely on claims and enrollment data provided by [you /your carriers /
    other third parties]. We reviewed the data for reasonableness but have not
    audited; as such we are not certifying, herein, as to its accuracy. Aon
    disclaims any and all representations and warranties with respect to the
    tool, including accuracy and fitness for use.
  </p>
</ng-template>

<ng-template #spanish>
  <p class="disclaimer__paragraph">
    La información contenida en esta herramienta es confidencial y propiedad de Aon. Sólo puede ser utilizada para
    apoyar
    los compromisos de un empleado de Aon con un cliente o posible cliente con el fin de colocar o intentar colocar
    su(s)
    cobertura(s) de seguro. No puede compartirse con ningún otro tercero ni para ningún otro propósito.
  </p>

  <p class="disclaimer__paragraph">
    Cualquier recomendación o evaluación es una expresión de la opinión de Aon solamente, y no es una declaración de
    hechos, y
    cualquier decisión tomada por una entidad para confiar en cualquier recomendación o evaluación de riesgo se toma
    bajo
    el propio riesgo de esa entidad. La confianza en la información contenida en este informe por parte de cualquier
    persona con fines distintos a los previstos pone a la entidad que la utiliza en riesgo de ser engañada debido a la
    confusión o a la falta de comprensión de los supuestos, metodologías o limitaciones aplicables a las conclusiones
    del
    informe.
  </p>

  <p class="disclaimer__paragraph">
    La herramienta genera previsiones futuras derivadas de la información histórica. También nos basamos en los
    datos de siniestros y afiliaciones proporcionados por [usted/sus proveedores/otros terceros]. Hemos revisado los
    datos
    para que sean razonables, pero no los hemos auditado; por lo tanto, no certificamos su exactitud. Aon rechaza todas
    las representaciones y garantías con respecto a la herramienta, incluyendo la exactitud y la idoneidad para su uso.
  </p>
</ng-template>

<ng-template #portuguese>
  <p class="disclaimer__paragraph">
    A informação contida nesta ferramenta é confidencial e de propriedade exclusiva da Aon. Ela só pode ser
    usada para auxiliar no agendamento de compromissos de um funcionário da Aon com um cliente ou potencial cliente, com
    o
    propósito de colocação ou pretensão de colocação de coberturas securitárias. A Ferramenta não pode ser compartilhada
    com terceiros ou para qualquer outro propósito.
  </p>

  <p class="disclaimer__paragraph">
    Qualquer recomendação ou avaliação é apenas uma expressão da opinião
    da Aon, e não uma declaração de fato, e qualquer decisão tomada por uma entidade para confiar em qualquer
    recomendação
    ou avaliação de risco é tomada por conta e risco dessa entidade. A confiança em informações contidas neste relatório
    por qualquer pessoa para outros fins que não os pretendidos colocam a entidade confiante em risco de ser enganada
    devido a confusão ou falta de compreensão das suposições, metodologias ou limitações aplicáveis das conclusões do
    relatório.
  </p>

  <p class="disclaimer__paragraph">
    A ferramenta gera prognósticos futuros as com base em informações históricas. Também nos baseamos em
    sinistros e dados de inscrição fornecidos por [você / suas transportadoras / outros terceiros]. Revisamos os dados
    por
    razoabilidade, mas não auditamos como tal, não certificamos, aqui, a sua exatidão. A Aon se isenta de toda e
    qualquer
    representação e garantia com respeito à ferramenta, incluindo precisão e adequação ao uso.
  </p>
</ng-template>