export const locale = {
    lang: 'es-cl',
    data: {
        'EnrollmentOverTime': {
            'EnrollmentOverTimeHeading' : 'Población por Tiempo',
            'Male' : 'Masculino',
            'Female' : 'Femenino',
            'NA' : 'ON',
            'AgeBand' : 'Rango Etario',
            'Dimensions' : 'Dimensión',
            'Gender' : 'Género',
            'Relationship' : 'Parentesco',
            'IsEmployee' : 'EE vs Dep',
            'Employee' : 'Titular',
            'Dependent' : 'Dependiente',
            'Monthly' : 'Por Mes',
            'YearToDateBasis' : 'Año Corrido',
            'YearToDateBasisC' : 'Año Corrido (C))',
            'Rolling3Basis' : '3 Meses Corridos',
            'Rolling6Basis' : '6 Meses Corridos',
            'Rolling9Basis' : '9 Meses Corridos',
            'Rolling12Basis' : '12 Meses Corridos',
            'ClaimsReportedByPaidDate' :  'Reclamaciones reportadas por fecha de pago',
           'ClaimsReportedByIncurredDate' :  'Reclamaciones informadas por fecha incurrida',
           'DateRange':'to'
        }
    }
};
