import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { FuseConfigService } from "@fuse/services/config.service";
import { Store, select } from "@ngrx/store";
import { navigation } from "app/navigation/navigation";
import { User } from "app/models/user.model";
import { takeUntil } from "rxjs/operators";
import { BenefitScore_Overtime, BenefitScore_Overtime_Gender, BenefitScore_Overtime_Location, BenefitScore_Overtime_SalaryBand } from 'app/fwb/constants/fwb-charts-data';
import { FwbExecutiveService } from 'app/fwb/services/fwb-executive.service';

@Component({
    selector: "financial-wellbeing-score",
    templateUrl: "./financial-wellbeing-score.component.html",
    styleUrls: ["./financial-wellbeing-score.component.css"], 
})
export class FinancialWellbeingScoreComponent implements OnInit {
    chartId = "FinancialWellbeing";
    height: any = "430px";
    //width: any = "95%";
    width: any = "930px";
    cardheight: any = "520px";
    cardwidth: any = "97%";
    tooltip: boolean = true;
    resize: boolean = true;
    numberOfTicks: number = 6;
    titleposition = "left";
    charttitle = "Financial Wellbeing Score";
    yaxistitle = "";
    color = "white";
    maxyaxisnumber: number = 1000;
    data: any;
    fuseConfig: any;
    navigation: any;
    emptyChartTitle = " ";
    categorycolors: any[];
    options = [
        { key: "AgeBand", name: "Age Band" },
        { key: "Gender", name: "Gender" },
        { key: "Location", name: "Location" },
        { key: "SalaryBand", name: "Salary Band" },
    ];
    selectedOption = true;
    public user: User;
    // locationDataBarChartData: any[];
    selectedOptionName: string = "Age Band";
    serviceData: any;
    isLoading = true;
    isError = false;
    errorMsg = "No Data Found.";
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _executiveService: FwbExecutiveService,
        private _router: Router
    ) {
        // Set the defaults
        this.navigation = navigation;
    }

    ngOnInit() {
        this.getUser();
        this.categorycolors = [];
        var obj = { name: "Prior", colorindex: 0 };
        var obj2 = { name: "Current", colorindex: 1 };
        this.categorycolors.push(obj);
        this.categorycolors.push(obj2);
        this.getData();
    }

    getData() {
        this.serviceData = null;
        this._executiveService.getFwbScore("").toPromise().then((resp) => {
            this.serviceData = resp;
            console.log(this.serviceData);
            this.setData();
            this.errorMsg = "";
            this.isError = false;
        }).catch(error => {
            this.isError = true;
            this.errorMsg = error.message;
        }).finally(() => {
            this.isLoading = false;
        })
    }


    onOptionChange($event) {
        this.selectedOptionName = $event.detail;
        if (this.serviceData === null) {
            this.isLoading = true;
            this.getData();
        }
        this.setData();
    }


    setData() {
        this.selectedOption = true;
        if (this.selectedOptionName == "Gender") {
            this.getDataByGender();
        } else if (this.selectedOptionName == "Location") {
            this.getLocationData();
        } else if (this.selectedOptionName == "Salary Band") {
            this.getDataBySalaryBand();
        } else {
            this.getDataByAgeBand();
        }
    }


    getDataByGender() {
        //  this.data = BenefitScore_Overtime_Gender;
        this.data = this.serviceData.resultGender;
    }

    getDataBySalaryBand() {
        // this.data = BenefitScore_Overtime_SalaryBand;
        this.data = this.serviceData.resultSalaryBand;
    }

    getLocationData() {
        // this.data = BenefitScore_Overtime_Location;
        this.data = this.serviceData.resultLocation;
    }

    getDataByAgeBand() {
        // this.data = BenefitScore_Overtime;
        this.data = this.serviceData.resultAgeBand;
    }


    getUser() {
    }

    goToLearnMore() {
        this._router.navigate(["/fwb/wellbeingscore"]);
    }
}
