import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
})
export class BaseDialogComponent {
  @Input() public title = '';
  @Input() public closeLabel = 'Close';
  @Input() public hideCloseBtn = false;
  @Input() public hideCloseIcon = false;
  @Input() public hideFooter = false;
}
