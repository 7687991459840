<h2 mat-dialog-title>Claim Basis Mismatch</h2>
<mat-dialog-content>
    <p>
        <b> - User Claim Basis:</b> {{claimBasisText[data.userClaimBasis]}}
        <br/>
        <b> - Saved Claim Basis:</b> {{claimBasisText[data.savedClaimBasis]}}
    </p>
    <p>
        Please Change the <b>'Claim Basis'</b> in 'Select Client' to <b>'{{claimBasisText[data.savedClaimBasis]}}'</b> before loading the Current Saved Cost Projection again.
    </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
