export const locale = {
    lang: 'es-pe',
    data: {
        'Nav': {
            'Stories': 'CUENTOS',
            'Export': {
                'Main': 'Exportar',
                'Cart': 'Carrito',
                'Queue': 'Cola'
            },
            'Admin': { 
                'Main': 'Administración',
                'Users': 'Usuarios',
                'Stories': 'Historias',
                'Settings': 'Ajustes',
                'StoryBoard': 'Tablero de Cuentos',
                'Library': 'Biblioteca',
                'ClientGroup': 'Client Group'
            }
        }
    }
};
