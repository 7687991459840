export const locale = {
    lang: 'it',
    data: {
  'ExportQueue': {
    'ExportQueue': 'Esporta coda',
    'CanViewExportQueueAndStatusHere': 'Può visualizzare la coda di esportazione e lo stato qui',
    'Name': 'Nome',
    'FileName': 'Nome file',
    'Status': 'Stato',
    'CreatedOn': 'Creato il',
    'Scheduled': 'Programmato',
    'Processing': 'in lavorazione',
    'Succeeded': 'Successo',
    'Failed': 'fallito'
  }
}};