export const locale = {
    lang: 'pt-br',
    data: {
        'ClaimCostDiag': {
		'ClaimDiagnosis': 'Tipo de Evento vs. Diagnóstico', 
		'SelectTop': 'Selecionar Maiores', 
		'AllAmountsDisplayedInLocalCurrency': 'Todos os valores estão em moeda local', 
		'ClaimType': 'Tipo de Sinistro', 
		'TotalPaidClaim': 'Total de Sinistro', 
		'ClaimantCount': 'Contagem de Usuário', 
		'ClaimCount': 'Contagem de Sinistros', 
		'ClaimsPerClaimant': 'Sinistro Por Usuário', 
		'CostPerClaim': 'Custo Por Sinistro', 
		'CostPerClaimant': 'Custo Por Usuário', 
		'ChangeInCost': 'Mudança Ano I vs. Ano II', 
		'Claim': 'Sinistro', 
		'Claimant': 'Usuário', 
		'ClaimsTotal': 'Sinistro Total', 
		'Count': 'Contar', 
		'ClaimsReportedByPaidDate': 'Sinistro Reportado por Data de Pagamento', 
		'ClaimsReportedByIncurredDate': 'Sinistro Reportado por Data de Atendimento', 
		'CountBasis': 'Base de Contagem', 
		'Sort': 'Ordenar', 
		'Show': 'Mostrar', 
		'Hide': 'Esconder', 
		'ShowHide': 'Mostrar/Esconder Quadrante de Doenças', 
		'DiagnosisLevel': 'Nível de Diagnóstico', 
		'Category': 'Categoria', 
		'Chapter': 'Capítulo', 
		'ShownAsPer': 'Mostrado como por', 
		'TopDiagnoses': 'Maiores Diagnósticos', 
		'AverageCost': 'Custo Médio', 
		'FilterNA': 'Excluir NA',
		'DateRange': 'to'
        }
    }
};
