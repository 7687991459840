export const locale = {
    lang: 'es-mx',
    data: {
        'ClaimType': {
            'ClaimHeading': 'Tipo de Reclamación',
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'ClaimType': 'Causa',
            'TotalPaidClaim': 'Total pagado',
            'ClaimantCount': 'Total casos',
            'ClaimCount': 'Número de trámites',
            'ClaimsPerClaimant': 'Trámites por caso',
            'ClaimsPerHeadcount': 'Trámites Por Plantilla',
            'CostPerClaim': 'Costo por trámite',
            'CostPerClaimant': 'Costo por caso',
            'CostPerHeadcount': 'Costo Por Plantilla',
            'ChangeInCost': '% cambio en costo',
            'Claim': 'Trámite',
            'Claimant': 'Caso',
            'Headcount': 'Plantilla',
            'ClaimsTotal': 'Total de Trámites',
            'Count': 'Contar',
            'ClaimsReportedByPaidDate': ' ',
            'ClaimsReportedByIncurredDate': ' ',
            'CountBasis': 'Contar Base',
            'Sort': 'Ordenar',
            'Show': 'Mostrar',
            'Hide': 'Ocultar',
            'ShowHide': 'Mostrar/Ocultar',
            'Average': 'Promedio',
            'PeriodAverage': 'Periodo promedio',
            'PlanYear': 'Año Del Plan'
        }
    }
};
