export const locale = {
    lang: 'es-ec',
    data: {
        'DiseaseDetail': {
            'DiseaseDetailHeading': 'Prevalencia De La Enfermedad',
            'AllAmountInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'SelectTop': 'Seleccione top',
            'Diagnosis': 'Diagnóstico',
            'ClaimsTotalHeader': 'Total de Reclamaciones',    
            'DiagnosisLevel': 'Nivel Diagnostico',
            'Category': 'Categoría',               
            'Chapter': 'Cuadro',
            'ClaimsTotal': 'Total de Reclamaciones',
            'DigClaimantCount': 'Parentesco de Demandantes', 
            'DiseaseProgression': 'Progresion De La Enferedad a Traves Del Tiempo',
            'Age': 'Etario', 
            'CostPerClaimant': 'Costo Por Reclamante',
            'CostPerClaim': 'Costo Por Reclamo',
            'ClaimsPerClaimant': 'Reclamos Por Reclamante',
            'TotalCost': 'Total de Reclamaciones',
            'ClaimantCount': 'Parentesco de Demandantes',
            'Prevalence': 'Prevalencia',
            'Benchmark': 'Benchmark',
            'IncludeClient': 'Incluir cliente',
            'ExcludeClient': 'Excluir cliente',
            'Portfolio': 'portafolio',
            'Industry': 'Industria',
            'None': 'Ninguna',
            'AgeBand': 'Rango de Edad',
            'Gender': 'Género',
            'Relationship': 'Parentesco',
            'ClaimType' : 'Tipo de Servicio',
            'ClaimsReportedByPaidDate': 'Reclamaciones reportadas por fecha de pago',
            'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',            
            'ShownAsPer':'Se muestra según',
            'DateRange':'to',
            'DiagnosisGroup': 'Grupo de diagnóstico',
            'Actionable': 'Accionables',
            'Chronic': 'Crónico',
            'Infectious': 'Infeccioso',
            'Trauma/Accidents': 'Accidentes /Trauma',
            'Cancer': 'Cáncer',
            'Mental Health': 'Salud Mental',
            'Obstetrics': 'Obstétricos',
            'Others': 'Otros',
            'Preventable': 'Prevenible',
            'Intervenable': 'Intervenible'
        }
    }
};
