import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;

  constructor(
    private _dialogRef: MatDialogRef<ChangePasswordComponent>,
    private _userService: UserService,
    private _toasterService: ToasterService) { }

  ngOnInit(): void {
    this.currentPassword = '';
    this.newPassword = '';
    this.confirmNewPassword = '';
  }

  onClose(): void {
    this._dialogRef.close();
  }

  onApply(): void {
    this._userService.changePassword(this.currentPassword, this.newPassword).toPromise().then(() => {
      this._toasterService.showMessage('Password changed successful.', ToasterServiceModes.OK);
    }, () => {
      this._toasterService.showMessage('Password changed failed. Please try again', ToasterServiceModes.NOK);
    }).finally(() => {
      this._dialogRef.close();
    });
  }

}
