<div id="exportQueue" fxLayout="column" fxFlexFill fxLayoutGap="12px"  class="contentPadding">
  <div id="exportQueueHeader" >
    <h1 class="text-mono">{{ 'ExportQueue.ExportQueue' | translate }}</h1>
    <p>{{ 'ExportQueue.CanViewExportQueueAndStatusHere' | translate }}</p>
  </div>
  <div fxLayout="row" fxLayoutGap="12px">
    <mat-card fxFlex="80">
      <div id="exportQueueSection" style="overflow: hidden">
        <div fxLayout="row">
          <strong>{{ 'ExportQueue.ExportQueue' | translate }}</strong>
          <span fxFlex></span>
          <mat-spinner *ngIf="isLoadingQueue || isLoadingDownload" class="mat-accent" diameter="18"></mat-spinner>
        </div>
        <agcustom-grid id="exportQueueGrid" #qGrid [dataList]="exportQueueList" [columnDefs]="queueColDefs">
        </agcustom-grid>
      </div>
    </mat-card>
  </div>
</div>