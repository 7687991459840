
import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import { formatNumber } from '@angular/common';
import * as Highcharts from 'highcharts';
@Component({
  selector: 'app-column-stack',
  templateUrl: './column-stack.component.html',
  styleUrls: ['./column-stack.component.css']
})
export class ColumnStackComponent implements OnInit {

  chart: Chart;
  options: Options;
  @Input() dataList: any;
  @Input() unit: string;
  @Input() textC: string;
  @Input() barColor: string;
  @Input() benchMark: any;
  @Input() categories: any;
  @Input() seriesData: any;
  @Input() Monthly: boolean;
  @Input() isStackLabels = false;
  lengthList: boolean;

  ngOnInit() {
    this.initialize();
    this.chart = new Chart(this.options);
  }
  ngOnChanges() {
    this.initialize();
    this.chart = new Chart(this.options);
  }

  initialize() {
    var pointWide = this.Monthly ? 20 : (((this.categories.length == 12) || (this.categories.length == 6)) ? 100 : null);
    this.lengthList = this.categories.length > 12 ? true : false;
    
    Highcharts.setOptions({
      lang: {
        thousandsSep: ","
      }
    });

    this.options = {
      chart: {
        type: 'column',
        height: 700
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        labels: {
          enabled: true,
          rotation: this.lengthList ? -90 : 0,
          style: {
            color: 'black',
            fontSize: '14px',
            textOutline: 'none', 
            fontWeight: '400',
            fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif'
          }
        },
        visible: true,
        categories: this.categories,
      },
      yAxis: [{
        gridLineColor: 'transparent',
        className: 'highcharts-color-0',
        labels: {
          enabled: false,
        },
        title: {
          text: ''
        },
        stackLabels: {
            enabled: this.isStackLabels,
            style: {
              color: 'black',
              fontSize: '14px',
              textOutline: '10px',
              fontWeight: 'bold',
              fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif'
            }
        }
        /* stackLabels: {
          enabled: true,
          rotation:this.lengthList ? -90 :0,
          y:this.Monthly ?-25 : -20,
          style: {
            color: 'black',
            fontSize: '14px',
            textOutline: '10px',
            fontWeight: 'bold',
            fontFamily: 'Helvetica Now Text, Arial, Helvetica, sans-serif'
          },
        } */

      },
      {
        gridLineColor: 'transparent',
        opposite: true,
        className: 'highcharts-color-0',
        title: {
          text: ''
        }
      }
      ],

      // to hide series name
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: true,
         useHTML: true,
                pointFormat: "<b>{series.name}</b>: {point.y:,.0f}  ({point.percentage:.1f}%)<br />",
                headerFormat: "<b>{point.key}</b><br><br>",
        style: {
          color: 'black',
          fontSize: '14px',
          textOutline: 'none', 
          fontWeight: '400',
          fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif'
        },

      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            rotation: this.lengthList ? -90 : 0,
            style: {
              color: 'black',
              fontSize: '14px',
              textOutline: 'none', 
              fontWeight: 'bold',
              fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif',
              textShadow: '0px 0px 15px white',
            },
            formatter: function () {
              if (this.percentage > 4)
                return formatNumber(this.y, 'en-US')+ ' (' + Highcharts.numberFormat(this.percentage, 1) + '%)';
              else return "";
            }
          },
          pointWidth: pointWide,
        },
      },
      series: this.seriesData
    }
  }
}