import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { User } from 'app/models/user.model'
import { ToasterService, ToasterServiceModes } from 'app/services/toaster.service';
import { List } from 'linqts';
import { HealthScoreService } from 'app/wellone/services/healthscore.service';
import { GlobalFilterService } from 'app/wellone/services/globalfilter.service';
import { HealthScoreDetailModel } from 'app/wellone/models/healthscore.model';
import { WelloneParameterModel } from 'app/wellone/models/welloneparameter.model';
import { WelloneService } from 'app/wellone/services/wellone.service';
import { WelloneConstant } from 'app/wellone/models/welloneconstant.model';
import * as moment from 'moment';

@Component({
  selector: 'app-wellone-health-score-detail',
  templateUrl: './healthscore.component.html',
  styleUrls: ['./healthscore.component.css']
})
export class HealthScoreComponent implements OnInit {


  healthScoreObj: HealthScoreDetailModel;
  isLoading: boolean = true;
  user: User;
  errorMsg: string;
  currentTabSummaryCardHeight:string = "1350px";
  healthScoreParams: WelloneParameterModel;
  overTimeMsg : string = "{week/day/month/quarter} : ";
  selectedDriverType: string = 'health';
  selectedTopBottomDisplay: string = 'top';
  topBottomDriverMultiLineChart: any = null;

  scoreBreakdownSortColumn = 'All';
  isScoreBreakdownSortOrderDescending = true;

  historyBusinessUnitRankOrder: string = 'top'
  organizationBusinessUnitRankOrder: string = 'top';
  organizationCountryRankOrder: string = 'top';

  historyBusinessUnitList: any[] = null;
  organizationBusinessUnitList: any[] = null;
  organizationCountryList: any[] = null;

  changePercentage = 0;
  timePeriod = 4; // All
  maxDate = '';
  isOverallSelected:boolean = false;

  testData = {
    "xCategories": [
        "All", "<29", "30-39", "40-54", "55+"
      ],
      "yCategories": [
        "All", "Business Unit 1", "Business Unit 2", "Business Unit 3",
      ],	
      "businessUnitDictionary": {	
        "All": {
          "selected": false,
          "highlight": false,
          "ageGroupDictionary": {
          "30-39": { "selected": false, "scorePercentage": 5 },
          "40-54": { "selected": false, "scorePercentage": 15 },
          "55+": { "selected": false, "scorePercentage": 8 },
          "<29": { "selected": false, "scorePercentage": 9 },
          "All": { "selected": false, "scorePercentage": 37 }
          }
        },
        "Business Unit 1": {
          "selected": false,
          "highlight": false,
            "ageGroupDictionary": {
            "30-39": { "selected": false, "scorePercentage": 5 },
            "40-54": { "selected": false, "scorePercentage": 15 },
            "55+": { "selected": false, "scorePercentage": 8 },
            "<29": { "selected": false, "scorePercentage": 9 },
            "All": { "selected": false, "scorePercentage": 37 }
            }
          },
        "Business Unit 2": { 
          "selected": false,
          "highlight": false,
            "ageGroupDictionary": { 
            "30-39": { "selected": false, "scorePercentage": 10 },
            "40-54": { "selected": false, "scorePercentage": 15 },
            "55+": { "selected": false, "scorePercentage": 13 },
            "<29": { "selected": false, "scorePercentage": 9 },
            "All": { "selected": false, "scorePercentage": 47 }
            }
          },
        "Business Unit 3": {
          "selected": false,
          "highlight": false,
            "ageGroupDictionary": {
            "30-39": { "selected": false, "scorePercentage": 2 },
            "40-54": { "selected": false, "scorePercentage": 3 },
            "55+": { "selected": false, "scorePercentage": 9 },
            "<29": { "selected": false, "scorePercentage": 2 },
            "All": { "selected": false, "scorePercentage": 16 }
            }
          }
      }
    }


  healthScoreTabClick(event): void {
    this.healthScoreObj.currentTabSelection = event.detail.trim();
    if(this.healthScoreObj.currentTabSelection === 'CURRENT')
    {
      this.healthScoreObj.currentTabResult.healthScoreRangeList.forEach(row => {
        row.isexpand = false;
      });
    }
  }

  organizationTabClick(event): void {
    this.healthScoreObj.organizationTabSelection = event.detail.trim();
  }

  sortScoreBreakdownByColumn(columnName: string, componentName: string): void {
    var component = this.healthScoreObj.currentTabResult.componentBreakdownDictionary.componentDictionary[componentName];
    if (columnName === component.sortColumnName) {
      component.isSortOrderDescending = !component.isSortOrderDescending;
    }
    else {
        component.sortColumnName = columnName;
        component.isSortOrderDescending = true;
    }

    var businessUnitKeyList = component.businessUnitKeyList;
    var businessUnitDictionary = component.businessUnitDictionary;
    businessUnitKeyList.splice(businessUnitKeyList.indexOf('All'), 1);

    businessUnitKeyList.sort((prevBusinessUnit, nextBusinessUnit) => {
      const prevRecordTotalUsers = columnName === 'Group' ? false : businessUnitDictionary[prevBusinessUnit].ageBandDictionary[columnName].totalUser > 0;
      const nextRecordTotalUsers = columnName === 'Group' ? false : businessUnitDictionary[nextBusinessUnit].ageBandDictionary[columnName].totalUser > 0;    

      const prevRecord = columnName === 'Group' ? prevBusinessUnit :  Number(businessUnitDictionary[prevBusinessUnit].ageBandDictionary[columnName].name);
      const nextRecord = columnName === 'Group' ? nextBusinessUnit :  Number(businessUnitDictionary[nextBusinessUnit].ageBandDictionary[columnName].name);           

      if (component.isSortOrderDescending) {
          return this.scoreBreakdownSortColumn === 'Group' ? 
          (nextRecord < prevRecord ? -1 : (nextRecord > prevRecord ? 1 : 0))
          : ((nextRecordTotalUsers > prevRecordTotalUsers) || (nextRecord - prevRecord));
      }
      else {
          return this.scoreBreakdownSortColumn === 'Group' ? 
          (nextRecord > prevRecord ? -1 : (nextRecord < prevRecord ? 1 : 0))  
          : ((nextRecordTotalUsers > prevRecordTotalUsers) || (prevRecord - nextRecord));;
      }
    });

    businessUnitKeyList.splice(0, 0, 'All');
  }

  participantDemoGraphicTabChanged(event): void {
    if (event.detail.trim() === 'DRIVERS') {
      this.healthScoreObj.selectedParticipantTabIndex = 1;
    }
    else if (event.detail.trim() === 'AGE') {
      this.healthScoreObj.selectedParticipantTabIndex = 2;
    }
    else if (event.detail.trim() === 'BUSINESS UNIT') {
      this.healthScoreObj.selectedParticipantTabIndex = 3;
    }

  }

  demoGraphicTabChanged(event): void {
    this.healthScoreObj.currentTabResult.selectedDemographicTabIndex = (event.detail.trim() === 'AGE') ? 1 : 2;
  }

  healthScorRangeDemoGraphicTabChanged(event, rowId): void {

    this.healthScoreObj.currentTabResult.healthScoreRangeList.forEach(item => {
      if (item.id === rowId) {
        if (event.detail.trim() === 'AGE')
          item.selectedDemographicTabIndex = 1;
        else if (event.detail.trim() === 'BUSINESS UNIT')
          item.selectedDemographicTabIndex = 2;
        else
          item.selectedDemographicTabIndex = 3;
      }
    });
  }

  constructor(
    private healthScoreService: HealthScoreService, 
    private userService: UserService,
    private globalFilterService: GlobalFilterService,
    private toasterService: ToasterService,
    private welloneService: WelloneService
  ) {

  }

  getComponentIcon(name: string): string {
    let result = '';
    switch (name) {
      case 'Nutrition':
        result = 'fal fa-carrot';
        break;
      case 'Sleep':
        result = 'fal fa-bed';
        break;
      case 'Depression':
        result = 'fal fa-frown';
        break;
      case 'Smoking':
        result = 'fal fa-smoking';
        break;
      case 'Movement':
        result = 'fal fa-swimmer';
        break;
      case 'Obesity':
        result = 'fal fa-weight';
        break;
      case 'Stress':
        result = 'fal fa-bolt';
        break;

    }

    return result;
  }

  getComponentIcon2(name: string): string {
    let result = '';
    switch (name) {
      case 'Nutrition':
        result = 'carrot';
        break;
      case 'Sleep':
        result = 'bed';
        break;
      case 'Depression':
        result = 'frown';
        break;
      case 'Smoking':
        result = 'smoking';
        break;
      case 'Movement':
        result = 'swimmer';
        break;
      case 'Obesity':
        result = 'weight';
        break;
      case 'Stress':
        result = 'bolt';
        break;

    }

    return result;
  }

  periodChange(event) : void
  {
    var intervalPeriod = parseInt(event.srcElement.value);
    var participationList =  [];
    if (intervalPeriod === 1)
    {
      participationList = JSON.parse(JSON.stringify(this.healthScoreObj.overTimeTabResult.participantScoreWeekList));
    }
    else if (intervalPeriod === 2)
    {
      participationList = JSON.parse(JSON.stringify(this.healthScoreObj.overTimeTabResult.participantScoreMonthList));
    }
    else if (intervalPeriod === 3)
    {
      participationList = JSON.parse(JSON.stringify(this.healthScoreObj.overTimeTabResult.participantScoreQuarterList));
    }   
    else
    {
      participationList = JSON.parse(JSON.stringify(this.healthScoreObj.overTimeTabResult.participantScoreAllList));
    }
    this.healthScoreObj.overTimeTabResult.participantScoreList = JSON.parse(JSON.stringify(participationList));
    this.healthScoreObj.selectedParticipantRow = new List<any>(this.healthScoreObj.overTimeTabResult.participantScoreList).FirstOrDefault(x => x.selected);
    this.healthScoreObj.overTimeTabResult.selectedTimePeriod = intervalPeriod;
  }

  percChange(old, latest) {
    if (old != 0) {
      let temp = (latest - old) / old;
      this.changePercentage = +((temp*100).toFixed(1));
    }
    else this.changePercentage = 0;  
  }

  formatDate(responseDate, subtractDays) {
    let date = new Date(responseDate);
    let calculatedDate = new Date();
    if (subtractDays != 0) {
      calculatedDate.setDate(date.getDate() - subtractDays);
    }
    else {
      return moment(date).format('DD-MMM-YYYY');
    }
    
    return moment(calculatedDate).format('DD-MMM-YYYY');
  }

  parseHealthScoreResult(res: any, oldHealthScoreObj:any): void {
    let healthScoreResponse = JSON.parse(JSON.stringify(res)); 
    this.maxDate = this.formatDate(healthScoreResponse.currentTabResult.maxDate, 0);
    
    if (this.healthScoreParams.GlobalFilters.TimePeriod === WelloneConstant.Week)
    {
        this.timePeriod = 1;
        this.maxDate = this.formatDate(healthScoreResponse.currentTabResult.maxDate, 7);
        this.percChange(healthScoreResponse.currentTabResult.averageScore_WeekAgo, healthScoreResponse.currentTabResult.averageScore);
        healthScoreResponse.overTimeTabResult.participantScoreList = JSON.parse(JSON.stringify(healthScoreResponse.overTimeTabResult.participantScoreWeekList));
    }
    else if (this.healthScoreParams.GlobalFilters.TimePeriod === WelloneConstant.Month)
    {
        this.timePeriod = 2;
        this.maxDate = this.formatDate(healthScoreResponse.currentTabResult.maxDate, 30);
        this.percChange(healthScoreResponse.currentTabResult.averageScore_MonthAgo, healthScoreResponse.currentTabResult.averageScore);
        healthScoreResponse.overTimeTabResult.participantScoreList = JSON.parse(JSON.stringify(healthScoreResponse.overTimeTabResult.participantScoreMonthList));
    }
    else if (this.healthScoreParams.GlobalFilters.TimePeriod === WelloneConstant.Quarter)
    {
        this.timePeriod = 3;
        this.maxDate = this.formatDate(healthScoreResponse.currentTabResult.maxDate, 90);
        this.percChange(healthScoreResponse.currentTabResult.averageScore_QtrAgo, healthScoreResponse.currentTabResult.averageScore);
        healthScoreResponse.overTimeTabResult.participantScoreList = JSON.parse(JSON.stringify(healthScoreResponse.overTimeTabResult.participantScoreQuarterList));
    }   
    else
    {
        this.timePeriod = 4;
        this.maxDate = this.formatDate(healthScoreResponse.currentTabResult.minDate, 0);
        this.percChange(healthScoreResponse.currentTabResult.averageScore_Inception, healthScoreResponse.currentTabResult.averageScore);
        healthScoreResponse.overTimeTabResult.participantScoreList = JSON.parse(JSON.stringify(healthScoreResponse.overTimeTabResult.participantScoreAllList));
    } 
    /* else
    {
      this.timePeriod = -1;
      healthScoreResponse.overTimeTabResult.participantScoreList = JSON.parse(JSON.stringify(healthScoreResponse.overTimeTabResult.participantScoreAllList));
    } */

    healthScoreResponse.currentTabResult.healthScoreRangeList.forEach(item => {
      item.componentBreakDownList.forEach(row => {
        row.icon = this.getComponentIcon(row.name);
      })
    });
    healthScoreResponse.currentTabResult.componentBreakdownList.forEach(row => {
      row.icon = this.getComponentIcon2(row.name);
    });

    healthScoreResponse.overTimeTabResult.overallHealthDriverData.driverList.forEach(row => {
      row.seriesData.forEach(healthRow => {
        healthRow.xVal = new Date(healthRow.xVal);
        healthRow.yVal = Number(healthRow.yVal);
      });
    });

    healthScoreResponse.overTimeTabResult.healthScoreTopBottomViewModel.topHealthScore.forEach(row => {       
      row.seriesData.forEach(healthRow => {
        healthRow.xVal = new Date(healthRow.xVal);
        healthRow.yVal = Number(healthRow.yVal);
      });
    });

    healthScoreResponse.overTimeTabResult.healthScoreTopBottomViewModel.bottomHealthScore.forEach(row => {
      row.seriesData.forEach(healthRow => {
        healthRow.xVal = new Date(healthRow.xVal);
        healthRow.yVal = Number(healthRow.yVal);
      });
    });

    healthScoreResponse.overTimeTabResult.healthScoreTopBottomViewModel.topBodyScore.forEach(row => {
      row.seriesData.forEach(healthRow => {
        healthRow.xVal = new Date(healthRow.xVal);
        healthRow.yVal = Number(healthRow.yVal);
      });
    });

    healthScoreResponse.overTimeTabResult.healthScoreTopBottomViewModel.bottomBodyScore.forEach(row => {
      row.seriesData.forEach(healthRow => {
        healthRow.xVal = new Date(healthRow.xVal);
        healthRow.yVal = Number(healthRow.yVal);
      });
    });

    healthScoreResponse.overTimeTabResult.healthScoreTopBottomViewModel.topMindScore.forEach(row => {
      row.seriesData.forEach(healthRow => {
        healthRow.xVal = new Date(healthRow.xVal);
        healthRow.yVal = Number(healthRow.yVal);
      });
    });

    healthScoreResponse.overTimeTabResult.healthScoreTopBottomViewModel.bottomMindScore.forEach(row => {
      row.seriesData.forEach(healthRow => {
        healthRow.xVal = new Date(healthRow.xVal);
        healthRow.yVal = Number(healthRow.yVal);
      });
    });    

    healthScoreResponse.overTimeTabResult.healthScoreTopBottomViewModel.topLifestyleScore.forEach(row => {
      row.seriesData.forEach(healthRow => {
        healthRow.xVal = new Date(healthRow.xVal);
        healthRow.yVal = Number(healthRow.yVal);
      });
    });

    healthScoreResponse.overTimeTabResult.healthScoreTopBottomViewModel.bottomLifestyleScore.forEach(row => {
      row.seriesData.forEach(healthRow => {
        healthRow.xVal = new Date(healthRow.xVal);
        healthRow.yVal = Number(healthRow.yVal);
      });
    });  

    if(!this.selectedDriverType)
    {
      this.selectedDriverType = 'health';
    }

    if(!this.selectedTopBottomDisplay)
    {
      this.selectedTopBottomDisplay = 'top';
    }
    healthScoreResponse.overTimeTabResult.healthScoreTopBottomViewModel.intervalList = this.getHealthCustomTickArr(healthScoreResponse.overTimeTabResult);
    this.healthScoreObj = healthScoreResponse;
    this.healthScoreObj.blankArr = [];
    this.healthScoreObj.demographicTabList = [
      { name: 'AGE', value: 1 },
      { name: 'BUSINESS UNIT', value: 2 },
      { name: 'CATEGORY', value: 3 }
    ];
    if(oldHealthScoreObj)
    {
      this.healthScoreObj.currentTabSelection = oldHealthScoreObj.currentTabSelection;
      this.healthScoreObj.organizationTabSelection = oldHealthScoreObj.organizationTabSelection;
      this.healthScoreObj.selectedParticipantTabIndex = oldHealthScoreObj.selectedParticipantTabIndex;
      if (this.timePeriod === -1)
      {
        this.healthScoreObj.overTimeTabResult.selectedTimePeriod = oldHealthScoreObj.overTimeTabResult.selectedTimePeriod;
      }
      else
      {
        this.healthScoreObj.overTimeTabResult.selectedTimePeriod = this.timePeriod;
      }
      
    }
    else
    {
      this.healthScoreObj.currentTabSelection = 'CURRENT';
      this.healthScoreObj.organizationTabSelection = 'BY AGE';
      this.healthScoreObj.selectedParticipantTabIndex = 1;
      if (this.timePeriod > -1)
      {
        this.healthScoreObj.overTimeTabResult.selectedTimePeriod = this.timePeriod;
      }
      else
      {
        this.healthScoreObj.overTimeTabResult.selectedTimePeriod = 4;
      }
    }
    
    this.healthScoreObj.participantTabList = [
      { name: 'DRIVERS', value: 1 },
      { name: 'AGE', value: 2 },
      { name: 'BUSINESS UNIT', value: 3 }
    ];

    this.healthScoreObj.selectedParticipantRow = new List<any>(this.healthScoreObj.overTimeTabResult.participantScoreList).FirstOrDefault(x => x.selected);

    if (this.healthScoreObj.selectedParticipantRow) {
      this.historyBusinessUnitList = this.orderByHistoryRank(this.healthScoreObj.selectedParticipantRow.participantBusinessUnitList, this.historyBusinessUnitRankOrder);
    }

    this.organizationBusinessUnitList = this.orderByOrganizationRank(this.healthScoreObj.organizationTabResult.businessUnitResultList, this.organizationBusinessUnitRankOrder);
    this.organizationCountryList = this.orderByOrganizationRank(this.healthScoreObj.organizationTabResult.countryResultList, this.organizationBusinessUnitRankOrder);

    console.log(this.healthScoreObj);

    this.updateTopBottomDriverMultiLineChart();
  }

  private getTickSize(data: any): any {
    var result = 7;
    switch (this.healthScoreParams.GlobalFilters.TimePeriod) {
      case WelloneConstant.Week:
        result = 7;
        break;
      case WelloneConstant.Month:
        result = 6;
        break;
      case WelloneConstant.Quarter:
        result = 8;
        break;
      case WelloneConstant.All:
        var monthNum = Math.round(data.length / 30);
        if (monthNum <= 12) {
          result = (monthNum > 3) ? 8 : 6;

        }
        if (monthNum > 12) {
          result = 6;
        }

        monthNum = Math.round(data.length / 30);
        if (monthNum <= 12) {
          result = (monthNum > 3) ? 8 : 6;
        }
        if (monthNum > 12) {
          result = 8;
        }
        break;
      default:
        result = 8;
        break;
    }
    return result;
  }

  private getMultilineChartData(historyResult:any) : any[]
  {
    var list = [];
    switch (this.selectedDriverType) {
      case 'health':
        list = 
          this.selectedTopBottomDisplay === 'top' ? 
          historyResult.healthScoreTopBottomViewModel.topHealthScore :
          historyResult.healthScoreTopBottomViewModel.bottomHealthScore;   
          break;
      case 'lifestyle':
        list = 
          this.selectedTopBottomDisplay === 'top' ? 
          historyResult.healthScoreTopBottomViewModel.topLifestyleScore :
          historyResult.healthScoreTopBottomViewModel.bottomLifestyleScore;
          break;
      case 'body':
        list = 
          this.selectedTopBottomDisplay === 'top' ? 
          historyResult.healthScoreTopBottomViewModel.topBodyScore :
          historyResult.healthScoreTopBottomViewModel.bottomBodyScore;     
          break;   
        
      case 'mind':
        list = 
          this.selectedTopBottomDisplay === 'top' ? 
          historyResult.healthScoreTopBottomViewModel.topMindScore :
          historyResult.healthScoreTopBottomViewModel.bottomMindScore; 
          break;     
        
    }
    return list;
  }

  private getPeriodDetail(): string {
    let result = '';
    if (this.healthScoreParams.GlobalFilters.TimePeriod === WelloneConstant.All) {
      result = 'All Record';
    }
    else if (this.healthScoreParams.GlobalFilters.TimePeriod === WelloneConstant.Week) {
      result = '1 Week Record';
    }
    else if (this.healthScoreParams.GlobalFilters.TimePeriod === WelloneConstant.Month) {
      result = '1 Month Record';
    }
    else if (this.healthScoreParams.GlobalFilters.TimePeriod === WelloneConstant.Quarter) {
      result = '3 Month Record';
    }
    else {
      result = 'All Record';
    }

    return result;
  }

  private getHealthCustomTickArr(data: any): any[] {
    var historyResult = JSON.parse(JSON.stringify(data))
    let result: any[] = [];
    var list  = this.getMultilineChartData(historyResult);
    var seriesData = [];
    if (list.length > 0) {
      seriesData = list[0].seriesData;      
      for(var count=1; count< list.length; count++)
      {
          var currentRow = list[count];
          if(seriesData.length < currentRow.seriesData.length)
          {
            seriesData = currentRow.seriesData;
          }
      }
    
      
      let tickNum = this.getTickSize(seriesData);
      let healthScoreList = new List<any>(seriesData).OrderByDescending(x => new Date(x.xVal)).ToArray();
      let days = Math.round(healthScoreList.length / tickNum);
      if (healthScoreList !== null && healthScoreList.length >= 7) {
        var scoreDate = new Date(healthScoreList[0].xVal);
        result.push(scoreDate);
        var count = 0;
        var tickSize = 0;
        while (tickSize < (tickNum - 1)) {
          result.push(this.addDays(scoreDate, -1 * days));
          count = count + days;
          scoreDate = healthScoreList[count].xVal;
          tickSize = tickSize + 1;
        }
        result = new List<any>(result).OrderBy(x => x).ToArray();
      }
    }


    return result;
  }

  private addDays(scoreDate: Date, days: number): Date {
    var result = new Date(scoreDate);
    result.setDate(result.getDate() + days);
    return result;
  }

  prevIconClick($event): void {
    let minId = new List<any>(this.healthScoreObj.overTimeTabResult.participantScoreList).Min(x => x.id);
    let selectedId = this.healthScoreObj.selectedParticipantRow.id;
    if (minId !== selectedId) {
      selectedId = selectedId - 1;
      let selectedRow = new List<any>(this.healthScoreObj.overTimeTabResult.participantScoreList).FirstOrDefault(x => x.id === selectedId);
      let participationList = JSON.parse(JSON.stringify(this.healthScoreObj.overTimeTabResult.participantScoreList));
      participationList.forEach(row => {
        row.colorindex = (row.id === selectedId) ? 13 : 3;
        row.selected = (row.id === selectedId);
      });
      this.healthScoreObj.overTimeTabResult.participantScoreList = participationList;
      this.healthScoreObj.selectedParticipantRow = selectedRow;
    }

  }


  nextIconClick($event): void {

    let maxId = new List<any>(this.healthScoreObj.overTimeTabResult.participantScoreList).Max(x => x.id);
    let selectedId = this.healthScoreObj.selectedParticipantRow.id;
    if (maxId !== selectedId) {
      selectedId = selectedId + 1;
      let selectedRow = new List<any>(this.healthScoreObj.overTimeTabResult.participantScoreList).FirstOrDefault(x => x.id === selectedId);
      let participationList = JSON.parse(JSON.stringify(this.healthScoreObj.overTimeTabResult.participantScoreList));
      participationList.forEach(row => {
        row.colorindex = (row.id === selectedId) ? 13 : 3;
        row.selected = (row.id === selectedId);
      });
      this.healthScoreObj.overTimeTabResult.participantScoreList = participationList;
      this.healthScoreObj.selectedParticipantRow = selectedRow;
    }

  }

  nextIconDisabled(): boolean {
    if (this.healthScoreObj.selectedParticipantRow === undefined || this.healthScoreObj.selectedParticipantRow === null) {
      return true;
    }
    let maxId = new List<any>(this.healthScoreObj.overTimeTabResult.participantScoreList).Max(x => x.id);
    let selectedId = this.healthScoreObj.selectedParticipantRow.id;
    return (maxId === selectedId);
  }

  prevIconDisabled(): boolean {
    if (this.healthScoreObj.selectedParticipantRow === undefined || this.healthScoreObj.selectedParticipantRow === null) {
      return true;
    }
    let minId = new List<any>(this.healthScoreObj.overTimeTabResult.participantScoreList).Min(x => x.id);
    let selectedId = this.healthScoreObj.selectedParticipantRow.id;
    return (minId === selectedId);
  }

  participartionBarClick(event:any): void
  {
      var row = event.detail;
      var selectedId = row.id;
      let selectedRow = new List<any>(this.healthScoreObj.overTimeTabResult.participantScoreList).FirstOrDefault(x => x.id === selectedId);
      let participationList = JSON.parse(JSON.stringify(this.healthScoreObj.overTimeTabResult.participantScoreList));
      participationList.forEach(row => {
        row.colorindex = (row.id === selectedId) ? 13 : 3;
        row.selected = (row.id === selectedId);
      });
      this.healthScoreObj.overTimeTabResult.participantScoreList = participationList;
      this.healthScoreObj.selectedParticipantRow = selectedRow;
      this.historyBusinessUnitList = this.orderByHistoryRank(this.healthScoreObj.selectedParticipantRow.participantBusinessUnitList, this.historyBusinessUnitRankOrder);
      
  }

  ngOnInit() {


    this.user = this.userService.getCurrentUser();
    this.getHealthScoreResult();
    this.userService.userChanged.subscribe(user => {
      this.user = this.userService.getCurrentUser();
      if (this.user) {
        if (this.user.client) {

        }
      }
    });

      // filter change
      this.globalFilterService.filterApplyClick.subscribe(res => {
        this.getHealthScoreResult();
    });


  }

  isPageLoad: boolean = false;
  expandRangeList:any[] = [];

  barExpandCollapseClick(event) : void
  {
    var selectedRow = event.detail;
    var data = this.healthScoreObj.currentTabResult.healthScoreRangeList;
    var componentBreakdownData = this.healthScoreObj.currentTabResult.componentBreakdownList;

    let expandedRow = new List<any>(data).FirstOrDefault(x => x.name === selectedRow.text && x.value === selectedRow.value);
    let expandedCBRow = new List<any>(componentBreakdownData).FirstOrDefault(x => x.name === selectedRow.text && x.value === selectedRow.value);

    if (expandedRow) { expandedRow.isexpand = selectedRow.isexpand; }
    if (expandedCBRow) { expandedCBRow.isexpand = selectedRow.isexpand; }

    var expandedRowCount = new List<any>(data).Count(x => x.isexpand);
    var expandedCBRowCount = new List<any>(componentBreakdownData).Count(x => x.isexpand);

    var expandHeight = (700 * expandedRowCount) + (350 * expandedCBRowCount);
    this.currentTabSummaryCardHeight = (1350 + expandHeight) + 'px';
  }

  onDriverTypeChange(event) {
    this.selectedDriverType = event.detail;
    this.updateTopBottomDriverMultiLineChart();
  }

  onTopBottom5Change(event) {
    this.selectedTopBottomDisplay = event.detail;
    this.updateTopBottomDriverMultiLineChart();
  }

  onHistoryBusinessUnitOrderChange(event: any): void {
    this.historyBusinessUnitRankOrder = event.detail;
    this.historyBusinessUnitList = this.orderByHistoryRank(this.healthScoreObj.selectedParticipantRow.participantBusinessUnitList, this.historyBusinessUnitRankOrder);
  }

  orderByHistoryRank(unorderedArray: any[], listOrder: string): any[] {
    const unorderedList = new List<any>(unorderedArray);

    if (listOrder === 'top') {
      return unorderedList.OrderByDescending(u => u.value).Take(10).ToArray();
    }

    return unorderedList.OrderBy(u => u.value).Take(10).ToArray();
  }

  onOrganizationBusinessUnitOrderChange(event: any): void {
    this.organizationBusinessUnitRankOrder = event.detail;
    this.organizationBusinessUnitList = this.orderByOrganizationRank(this.healthScoreObj.organizationTabResult.businessUnitResultList, this.organizationBusinessUnitRankOrder);
  }

  onOrganizationCountryOrderChange(event: any): void {
    this.organizationCountryRankOrder = event.detail;
    this.organizationCountryList = this.orderByOrganizationRank(this.healthScoreObj.organizationTabResult.countryResultList, this.organizationCountryRankOrder);
  }

  orderByOrganizationRank(unorderedArray: any[], listOrder: string): any[] {
    const unorderedList = new List<any>(unorderedArray);

    if (listOrder === 'top') {
      return unorderedList.OrderByDescending(u => u.value).Take(10).ToArray();
    }

    return unorderedList.OrderBy(u => u.value).Take(10).ToArray();
  }

  private updateTopBottomDriverMultiLineChart() {
    var list = [];
    if(this.isOverallSelected)
    {
      list =  this.healthScoreObj.overTimeTabResult.overallHealthDriverData.driverList;
    }
    else
    {
      switch (this.selectedDriverType) {
        case 'health':
          list = 
            this.selectedTopBottomDisplay === 'top' ? 
            this.healthScoreObj.overTimeTabResult.healthScoreTopBottomViewModel.topHealthScore :
            this.healthScoreObj.overTimeTabResult.healthScoreTopBottomViewModel.bottomHealthScore;
            break;
        case 'lifestyle':
          list = 
            this.selectedTopBottomDisplay === 'top' ? 
            this.healthScoreObj.overTimeTabResult.healthScoreTopBottomViewModel.topLifestyleScore :
            this.healthScoreObj.overTimeTabResult.healthScoreTopBottomViewModel.bottomLifestyleScore;
            break;
        case 'body':
          list = 
            this.selectedTopBottomDisplay === 'top' ? 
            this.healthScoreObj.overTimeTabResult.healthScoreTopBottomViewModel.topBodyScore :
            this.healthScoreObj.overTimeTabResult.healthScoreTopBottomViewModel.bottomBodyScore;   
            break;     
          
        case 'mind':
          list = 
            this.selectedTopBottomDisplay === 'top' ? 
            this.healthScoreObj.overTimeTabResult.healthScoreTopBottomViewModel.topMindScore :
            this.healthScoreObj.overTimeTabResult.healthScoreTopBottomViewModel.bottomMindScore; 
            break;
      }
    }
    
    if(list.length === 1)
    {
      var row = list[0];
      if(row.name === "Average" && row.seriesData.length === 0)
      {
        list = undefined;
      }
    }
    this.topBottomDriverMultiLineChart =  list;
  }

  private getHeight(data: any[]): string {
    var arrLength = data.length;
    let result = this.getRowCountHeight(arrLength) + 'px';
    return result;
  }

  private getRowCountHeight(rowCount: number): number {
    var result = 0;
    if (rowCount === 1) {
      result = 200;
    }
    else if (rowCount === 2) {
      result = 300;
    }
    else if (rowCount === 3) {
      result = 360;
    }
    else if (rowCount === 4) {
      result = 470;
    }
    else if (rowCount > 4) {
      result = (rowCount * 100);
    }
    return result;
  }

  private getHealthScoreResult(): void {
    this.errorMsg = undefined;
    let startDate: Date = new Date();
    this.isLoading = true;
    let oldHealthScoreObj = this.healthScoreObj ? JSON.parse(JSON.stringify(this.healthScoreObj)) : null;
    this.healthScoreObj = null;
    // let tennatId = this.globalFilterService.getGlobalFilters(this.user).tbsClientId;   
    //let dashboardParams = { 'Tenant_record_id': tennatId, 'Logged_uid': this.user.userName };

    this.healthScoreParams = this.welloneService.getParamInfo();
    console.log("Health Score Request Params : " + this.healthScoreParams.Tenant_record_id);
    this.healthScoreService.getHealthScoreResult(this.healthScoreParams).toPromise().then((res: any) => {
      this.isLoading = false;
      this.parseHealthScoreResult(res, oldHealthScoreObj);
      this.isPageLoad = true
      if (this.healthScoreObj.currentTabResult.componentBreakdownList.length === 0 && this.healthScoreObj.currentTabResult.healthScoreRangeList.length === 0)
      {
        this.currentTabSummaryCardHeight = '400px';
      }
      if (this.healthScoreObj.currentTabResult.componentBreakdownList.length > 0 && this.healthScoreObj.currentTabResult.healthScoreRangeList.length === 0)
      {
        this.currentTabSummaryCardHeight = '1100px';
      }
      if (this.healthScoreObj.currentTabResult.componentBreakdownList.length === 0 && this.healthScoreObj.currentTabResult.healthScoreRangeList.length > 0)
      {
        this.currentTabSummaryCardHeight = '800px';
      }
      let endDate: Date = new Date();
      var diff = (startDate.getTime() - endDate.getTime());      
      var lapseInSecond = (diff / 1000);

      console.log('lapse in second ' + lapseInSecond);
    }).catch(error => {
      this.isLoading = false;
      this.errorMsg = error.message;
      console.log(error);         
    });

  }

  onHealthOptionChange(event: any) : void
  {
  this.isOverallSelected = !this.isOverallSelected;
  this.updateTopBottomDriverMultiLineChart();
  }
}
