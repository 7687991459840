import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ChallengeService } from 'app/wellone/services/challenge.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ChallengeselectiondialogComponent } from './challengeselectiondialog/challengeselectiondialog.component';
import { WelloneParameterModel } from 'app/wellone/models/welloneparameter.model';
import { WelloneConstant } from 'app/wellone/models/welloneconstant.model';
import { GlobalFilterService } from 'app/wellone/services/globalfilter.service';
import { WelloneService } from 'app/wellone/services/wellone.service';

@Component({
    selector: 'app-challengedetail',
    templateUrl: './challengedetail.component.html',
    styleUrls: ['./challengedetail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChallengedetailComponent implements OnInit {
    newLayoutUrl: string;
    isViewCompletionRatesEnabled = false;
    selectedBusinessUnitLabel = '';
    selectedAgeGroupLabel = '';
    selectedBusinessUnit: any = null;
    selectedAgeGroup: any = null;
    data: any = null;
    isLoading = false;
    challengeParams: WelloneParameterModel;
    participationBreakdownSortColumn = 'All';
    isParticipationBreakdownSortOrderDescending = true;

    @ViewChild('participationBreakdownTable', { static: false })
    participationBreakdownTable: ElementRef;

    healthScorePerformanceTableData: any = [
        { icon: ['fal', 'wave-triangle'], category: 'Health', score: 0, performance: 0 },
        { icon: ['fal', 'apple-alt'], category: 'Lifestyle', score: 0, performance: 0 },
        { icon: ['fal', 'running'], category: 'Body', score: 0, performance: 0 },
        { icon: ['fal', 'head-side-brain'], category: 'Mind', score: 0, performance: 0 }
    ];

    constructor(private challengeService: ChallengeService,
        private route: ActivatedRoute,
        private globalFilterService: GlobalFilterService,
        public dialog: MatDialog,
        private router: Router,
        private welloneService: WelloneService,
        private ref: ChangeDetectorRef) {
            this.newLayoutUrl = '/wellone/';
        }

    ngOnInit(): void {

        this.loadChallengeData();


        // filter change
        this.globalFilterService.filterApplyClick.subscribe(res => {
            this.loadChallengeData();
        });

    }

    private loadChallengeData(): void {
        this.challengeParams = this.welloneService.getParamInfo();
        this.route.queryParams
            .subscribe(params => {
                this.data = null;
                this.isLoading = true;

                this.challengeService.getChallengeResult(this.challengeParams, params.id).toPromise().then((res: any) => {
                    console.log(res);
                    this.data = res;
                    
                    this.populateHealthScorePerformanceTableData();
                    this.ref.markForCheck();

                }, () => {
                    this.isLoading = false;
                }).finally(() => {
                    this.isLoading = false;
                });
            }
            );
    }

   

    openChallengeSelectionDialog(): void {
        const challengeSelectionDialog = this.dialog.open(ChallengeselectiondialogComponent, {
            minWidth: '40vw',
            minHeight: '390px',
            disableClose: true,
            data: {
                selectedChallengeId: this.data.id,
                challengeList: this.data.challengeList

            }
        });

        challengeSelectionDialog.afterClosed().subscribe(result => {
            if (result === null) {
                return;
            }

            this.router.navigate([this.newLayoutUrl + 'challenge/detail'], { queryParams: { id: result } });
        });
    }

    populateHealthScorePerformanceTableData(): void {
        
        this.healthScorePerformanceTableData[0].score = this.data ? this.data.healthScore : 0;
        this.healthScorePerformanceTableData[0].performance = this.data ? this.data.healthScoreChange : 0;

        this.healthScorePerformanceTableData[1].score = this.data ? this.data.lifestyleScore : 0;
        this.healthScorePerformanceTableData[1].performance = this.data ? this.data.lifestyleScoreChange : 0;

        this.healthScorePerformanceTableData[2].score = this.data ? this.data.bodyScore : 0;
        this.healthScorePerformanceTableData[2].performance = this.data ? this.data.bodyScoreChange : 0;

        this.healthScorePerformanceTableData[3].score = this.data ? this.data.mindScore : 0;
        this.healthScorePerformanceTableData[3].performance = this.data ? this.data.mindScoreChange : 0;       
        
    }

    toggleSelection(selectedBusinessUnit: any): void {
        this.selectedBusinessUnitLabel = '';
        this.selectedBusinessUnit = null;
        this.selectedAgeGroupLabel = '';
        this.selectedAgeGroup = null;
        this.toggleBusinessUnitSelection(selectedBusinessUnit.selected ? null : selectedBusinessUnit);
    }

    goToPrevAgeGroup(): void {
        const curAgeGroupIndex = this.data.xCategories.indexOf(this.selectedAgeGroupLabel);

        if (curAgeGroupIndex === 0) {
            this.toggleAgeGroupSelection(this.selectedBusinessUnit,
                this.data
                    .businessUnitDictionary[this.selectedBusinessUnitLabel]
                    .ageGroupDictionary[this.data.xCategories[this.data.xCategories.length - 1]]);
            return;
        }

        this.toggleAgeGroupSelection(this.selectedBusinessUnit,
            this.data
                .businessUnitDictionary[this.selectedBusinessUnitLabel]
                .ageGroupDictionary[this.data.xCategories[curAgeGroupIndex - 1]]);
    }

    goToNextAgeGroup(): void {
        const curAgeGroupIndex = this.data.xCategories.indexOf(this.selectedAgeGroupLabel);

        if (curAgeGroupIndex === this.data.xCategories.length - 1) {
            this.toggleAgeGroupSelection(this.selectedBusinessUnit,
                this.data
                    .businessUnitDictionary[this.selectedBusinessUnitLabel]
                    .ageGroupDictionary[this.data.xCategories[0]]);
            return;
        }

        this.toggleAgeGroupSelection(this.selectedBusinessUnit,
            this.data
                .businessUnitDictionary[this.selectedBusinessUnitLabel]
                .ageGroupDictionary[this.data.xCategories[curAgeGroupIndex + 1]]);
    }

    toggleBusinessUnitSelection(selectedBusinessUnit: any): void {
        for (let i = 0; i < this.data.yCategories.length; i++) {
            const curBusinessUnit = this.data.businessUnitDictionary[this.data.yCategories[i]];
            curBusinessUnit.selected = selectedBusinessUnit === curBusinessUnit;

            if (curBusinessUnit.selected) {
                this.selectedBusinessUnitLabel = this.data.yCategories[i];
                this.selectedBusinessUnit = curBusinessUnit;
            }

            this.toggleAgeGroupSelection(curBusinessUnit, curBusinessUnit.selected ? curBusinessUnit.ageGroupDictionary[this.data.xCategories[0]] : null);
        }
    }

    toggleAgeGroupSelection(selectedBusinessUnit: any, selectedAgeGroup: any): void {
        for (let i = 0; i < this.data.xCategories.length; i++) {
            const curAgeGroup = selectedBusinessUnit.ageGroupDictionary[this.data.xCategories[i]];
            curAgeGroup.selected = selectedAgeGroup === curAgeGroup;

            if (curAgeGroup.selected) {
                this.selectedAgeGroupLabel = this.data.xCategories[i];
                this.selectedAgeGroup = curAgeGroup;
            }
        }
    }

    toggleViewCompletionRatesEnabled(): void {
        this.isViewCompletionRatesEnabled = !this.isViewCompletionRatesEnabled;
    }

    sortParticipationBreakdownByColumn(columnName: string): void {
        if (columnName === this.participationBreakdownSortColumn) {
            this.isParticipationBreakdownSortOrderDescending = !this.isParticipationBreakdownSortOrderDescending;
        }
        else {
            this.participationBreakdownSortColumn = columnName;
            this.isParticipationBreakdownSortOrderDescending = true;
        }

        this.data.yCategories.splice(this.data.yCategories.indexOf('All'), 1);

        this.data.yCategories.sort((prevBusinessUnit, nextBusinessUnit) => {
            const prevRecord = columnName === 'Group' ? prevBusinessUnit : this.data.businessUnitDictionary[prevBusinessUnit].ageGroupDictionary[columnName];
            const nextRecord = columnName === 'Group' ? nextBusinessUnit : this.data.businessUnitDictionary[nextBusinessUnit].ageGroupDictionary[columnName];

            if (this.isParticipationBreakdownSortOrderDescending) {
                return this.participationBreakdownSortColumn === 'Group' ?
                    (nextRecord < prevRecord ? -1 : (nextRecord > prevRecord ? 1 : 0))
                    : nextRecord.challengeParticipants - prevRecord.challengeParticipants;
            }
            else {
                return this.participationBreakdownSortColumn === 'Group' ?
                    (nextRecord > prevRecord ? -1 : (nextRecord < prevRecord ? 1 : 0))
                    : prevRecord.challengeParticipants - nextRecord.challengeParticipants;
            }
        });

        this.data.yCategories.splice(0, 0, 'All');
    }
}
