import { Component, OnInit, OnDestroy } from "@angular/core";
import { User } from "app/models/user.model";
import { FuseConfigService } from "@fuse/services/config.service";
import { Store, select } from "@ngrx/store";
import { Router } from "@angular/router";
import { navigation } from "app/navigation/navigation";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "demographics-score",
    templateUrl: "./demographics-score.component.html",
    styleUrls: ["./demographics-score.component.css"],
})
export class DemographicsScoreComponent implements OnInit, OnDestroy {
    chartId = "demographics";
    height: any = "420px";
    width: any = "650px";
    cardheight: any = "550px";
    cardwidth: any = "97%";
    tooltip: boolean = true;
    titleposition = "left";
    charttitle = "Demographics";
    yaxistitle = "";
    color = "white";
    maxyaxisnumber: number = 1000;
    dataAgeBand: any;
    dataGender: any;
    fuseConfig: any;
    navigation: any;

    legendsubtitle: string = "Total Employees";
    legendTrim: number = 0;
    legendtitle = "";
    legendPosition = "left";
    middleText: any = "500";
    showLegend: boolean = true;
    isAgeBandSelected: boolean = true;

    public user: User;
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _router: Router
    ) {
        // Set the defaults
        this.navigation = navigation;
    }
    // Private
    private _unsubscribeAll: Subject<any>;
    ngOnInit() {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // Subscribe to config changes
        

        this.getUser();
        this.dataAgeBand = Object(this.getData());
        this.dataGender = Object(this.getGenderData());
    }

    toggleHorizontalTab() {
        this.isAgeBandSelected = !this.isAgeBandSelected;
    }
    getUser() {
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this._unsubscribeAll.unsubscribe();
    }
    

    getGenderData() {
        return [
            {
                name: "Male",
                value: 40,
            },
            {
                name: "Female",
                value: 60,
            },
        ];
    }

    getData() {
        return [
            {
                name: "15-19",
                value: 30,
            },
            {
                name: "20-24",
                value: 11,
            },
            {
                name: "25-29",
                value: 8,
            },
            {
                name: "30-34",
                value: 7,
            },
            {
                name: "35-39",
                value: 29,
            },
            {
                name: "40-44",
                value: 24,
            },
            {
                name: "45-49",
                value: 8,
            },
            {
                name: "50-54",
                value: 17,
            },
            {
                name: "55+",
                value: 9,
            },
        ];
    }
}
