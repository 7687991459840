import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { VizColorConstants } from 'app/fwb/constants/fwb-charts-data';
import { User } from 'app/models/user.model';
import { navigation } from 'app/navigation/navigation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'retirement-target',
    templateUrl: './target.component.html',
    styleUrls: ['./target.component.css']
})
export class TargetComponent implements OnInit {
    chartId = "targetComponent";
    height: any = "390px";
    width: any = "390px";
    contentwidth: any = "97%";
    cardheight: any = "650px";
    cardwidth: any = "100%";
    tooltip: boolean = true;
    titleposition = "left";
    charttitle = "Target Met";
    yaxistitle = "";
    color = "white";
    maxyaxisnumber: number = 1000;
    dataMin: any;
    dataMax: any;
    dataCom: any;
    legendTargetLabel: any[];
    legendTarget: any[];
    hideLegend: 'none';
    fuseConfig: any;
    navigation: any;
    legenddata: any[];
    legendsubtitleMin: string = "";
    legendsubtitleMod: string = "";
    legendsubtitleCom: string = "";
    legendTrim: number = 0;
    legendtitle = "";
    legendPosition: string = "bottom";
    middleText: any = "500";
    showLegendMin: boolean = false;
    showLegendMod: boolean = true;
    showLegendCom: boolean = false;
    isAgeBandSelected: boolean = true;
    legendItems: any;
    public user: User;
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _router: Router
    ) {
        // Set the defaults
        this.navigation = navigation;
    }
    ngOnInit() {
        this.getUser();
        this.dataMin = Object(this.getGetMinimum());
        this.dataMax = Object(this.getGetMaximum());
        this.dataCom = Object(this.getGetComfortable());
        this.legendTarget = Object(this.getlegendTargetData());
        this.legendTargetLabel = Object(this.getlegendLabels());
        this.legendItems = [];
        this.legendItems.push({
            minimum: VizColorConstants[2],
            moderate: VizColorConstants[7],
            comfortable: VizColorConstants[8]
        });
        console.log(this.legendItems);
    }

    toggleHorizontalTab() {
        this.isAgeBandSelected = !this.isAgeBandSelected;
    }
    getUser() {

    }

    goToLearnMore() {
        this._router.navigate(["/fwb/demographics"]);
    }

    getGetMinimum() {
        return [
            {
                name: "Minimum",
                value: 40,
                colorindex: 2,
                striped: false
            },
            {
                name: "Moderate",
                value: 45,
                colorindex: 7,
                striped: true
            },
            {
                name: "Comfortable",
                value: 15,
                colorindex: 8,
                striped: true
            },
        ];
    }

    getGetMaximum() {
        return [
            {
                name: "Minimum",
                value: 40,
                colorindex: 2,
                striped: true
            },
            {
                name: "Moderate",
                value: 45,
                colorindex: 7,
                striped: false
            },
            {
                name: "Comfortable",
                value: 15,
                colorindex: 8,
                striped: true
            },
        ];
    }

    getGetComfortable() {
        return [
            {
                name: "Minimum",
                value: 40,
                colorindex: 2,
                striped: true
            },
            {
                name: "Moderate",
                value: 45,
                colorindex: 7,
                striped: true
            },
            {
                name: "Comfortable",
                value: 15,
                colorindex: 8,
                striped: false
            },
        ];
    }

    getlegendTargetData() {
        return [{ name: 'Minimum - Covers all needs with some left for fun ($ 10,000 single)', value: 20.6, colorindex: 2, striped: false, shape: "circle" },
        { name: ' Moderate - More finanial security and flexibility ($ 20,000 single)', value: 19.9, colorindex: 7, striped: false, shape: "circle" },
        { name: 'Comfortable - More financial freedom and some luxuries ($ 30,000 single)', value: 14.5, colorindex: 8, striped: false, shape: "circle" },
        ];
    }
    getlegendLabels() {
        return [
            { name: 'Employees are on track to reach or exceed their chosen income level', value: 14.5, colorindex: 5, striped: false, shape: "square" },
            { name: 'Employees are likely to fall short of desired income level', value: 27, colorindex: 5, striped: true, shape: "square" },
        ];
    }
}
