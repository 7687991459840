<div id="storyAdminComp" fxLayout="column" fxFlexFill fxLayoutGap="12px" class="contentPadding">
  <div fxLayout="row">
    <h1 id="storyAdminHeader" class="text-mono">{{ 'StoriesAdmin.StoriesAdministration' | translate }}</h1>
  </div>
  <div fxLayout="row" fxLayoutGap="12px">
    <mat-card id="storyAdminStories" fxFlex="70">
      <div style="overflow: hidden">
        <div fxLayout="row">
          <p><strong>{{ 'StoriesAdmin.Stories' | translate }}</strong></p>
          <span fxFlex></span>
          <mat-spinner *ngIf="!isStoryListLoaded" class="mat-accent" diameter="30"></mat-spinner>
          <button id="storyAdminBtnNewStory" mat-button *ngIf="isEditor" (disabled)="!isStoryListLoaded" class="mat-accent fixed-height" (click)="onAddStory()">
            {{ 'StoriesAdmin.NewStory' | translate }}
          </button>
        </div>
        <agcustom-grid id="storyAdminGridStories" 
         #storiesGrid [dataList]="storyList" [columnDefs]="storyColumnDefs" [delete]="isEditor" [view]="true"
          (selectionChanged)="onStorySelectionChanged($event)" (onView)="onViewStory($event)"
          (onDelete)="onStoryDeletion($event)" [pagination]="true" [paginationPageSize]="10">
        </agcustom-grid>
      </div>
    </mat-card>
    <mat-card id="storyAdminUsers" fxFlex="30" *ngIf="isShowAssignUser && selectedStory">
      <div style="overflow: hidden">
        <div id="storyAdminUserList" fxLayout="row">
          <strong>{{ 'StoriesAdmin.Users' | translate }}</strong>
          <span fxFlex></span>
          <mat-spinner *ngIf="!isUserListLoaded" class="mat-accent" diameter="30"></mat-spinner>
          <button id="storyAdminBtnSelectAll" mat-button class="mat-accent fixed-height"
          (click)="onStorySelectionSelectAll()">
          {{ 'StoriesAdmin.SelectAll' | translate }}
        </button>
          <button id="storyAdminBtnSave" mat-button class="mat-accent fixed-height"
            (click)="onUpdateStoryUsers()">
            {{ 'StoriesAdmin.Save' | translate }}
          </button>
        </div>
        <div id="storyAdminCountry" >
          <mat-select [(ngModel)]="selectedCountryName" (selectionChange)="onCountrySelectionChange()">
            <mat-option *ngFor="let countrySelection of countrySelectionList" [value]="countrySelection.value">
              {{countrySelection.text}}
            </mat-option>
          </mat-select>
        </div>
        <agcustom-grid #storyUsersGrid id="storyAdminGridUser" [dataList]="userList" [columnDefs]="userColumnDefs" [select]="true" [pagination]="true" [paginationPageSize]="10">
        </agcustom-grid>
      </div>
    </mat-card>
  </div>
</div>