import { Component, OnInit, Input,OnChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import { formatNumber } from '@angular/common';
import * as Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more"; //module
HC_more(Highcharts); //init module

@Component({
  selector: 'app-bar-stacked',
  templateUrl: './bar-stacked.component.html',
  styleUrls: ['./bar-stacked.component.css']
})
export class BarStackedComponent implements OnInit, OnChanges {

  chart: Chart;
  options: Options;
  @Input() stackBarList:any;
  @Input() isShowPercentageSymbol:boolean = false;
  @Input() showAllPercentage:boolean = false;
  @Input() headerText:string = '';
  
  ngOnInit() {
    this.initialize();
    this.chart = new Chart(this.options);
  }

  ngOnChanges() {
    this.initialize();
    this.chart = new Chart(this.options);
  }

  initialize() {
    var currentContext = this;
    Highcharts.setOptions({
      lang: {
        thousandsSep: ","
      }
    }); 
    this.options = {
      chart: {
        type: 'bar',
        margin: [0, 0, 0, 10],
        height: 100,
    },
    credits: {
      enabled: false
    },
    title: {
      text: this.headerText
    },
    xAxis: {
        visible:false,
    },
    yAxis: {
      visible:false,
    },
    legend: {
      enabled: false
     },
    plotOptions: {
        series: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
             style:{
              color:'black',
              fontSize:'14px',
              textOutline: 'none',
              fontWeight: 'bold',
              fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif',
              textShadow: '0px 0px 15px white',
             },
             formatter:function(){
               var result = '';
               if(currentContext.showAllPercentage){
                if (this.percentage > 0) 
                {
                  result =  formatNumber(this.y, 'en-US'); 
                  result = currentContext.isShowPercentageSymbol  ? result + '%' : result;
                }
                
               }
               else{
                if (this.percentage > 5) 
                {
                  result =  formatNumber(this.y, 'en-US'); 
                  result = currentContext.isShowPercentageSymbol  ? result + '%' : result;
                }
                 
               }               
               return result;              
             }
            }
        }
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      headerFormat: "<b>{series.name}</b><br>",
      pointFormat: this.isShowPercentageSymbol ? "{point.y:,.0f}%<br>" : "{point.y:,.0f}<br>",
      style: {
        color: 'black',
        fontSize: '14px',
        textOutline: 'none', 
        fontWeight: '400',
        fontFamily: 'Helvetica Now Text, Arial, Open Sans,Helvetica, sans-serif'
      }
    },
    series:this.stackBarList

    }
  }
}
