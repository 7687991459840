import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SiteService {
    constructor(private httpClient: HttpClient) {}

    public setSelectedSite(selectedSite: any): void {
        sessionStorage.setItem("selectedSite", JSON.stringify(selectedSite));
    }

    public getSelectedSite(): any {
        return JSON.parse(sessionStorage.getItem("selectedSite"));
    }

    public listSites(): any {
        return this.httpClient.post<any>(
            environment.api + 'api/admin/site/list', null);
    }

    public addSite(name: string, value: string, siteUrl: string): any {
        return this.httpClient.post<any>(
            environment.api + 'api/admin/site/add', { name, value, siteUrl });
    }

    public updateSite(site: any): any {
        return this.httpClient.post<any>(
            environment.api + 'api/admin/site/update', site);
    }

    public deleteSite(siteId: number): any {
        return this.httpClient.post<any>(
            environment.api + 'api/admin/site/' + siteId + '/delete', null);
    }

    public getSiteClientList(siteId: number): any {
        return this.httpClient.post<any>(
            environment.api + 'api/admin/site/' + siteId + '/client/list', null
        );
    }

    public updateSiteClientList(siteId: number, siteClientViewModel: any): any {
        return this.httpClient.post<any>(
            environment.api + 'api/admin/site/' + siteId + '/client/update', siteClientViewModel
        );
    }
}
