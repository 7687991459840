import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'stories',
        title: 'STORIES',
        type: 'group',
        children: [],
        translate: 'Nav.Stories'
    },
    {
        id: 'exportqueue',
        title: 'Export Queue',
        type: 'group',
        icon: 'send',
        translate: 'Nav.Export.Main',
        children: [
            {
                id: 'exportcart',
                title: 'Cart',
                type: 'item',
                icon: 'add_shopping_cart',
                url: 'export/cart',
                translate: 'Nav.Export.Cart'
            },
            {
                id: 'exportqueue',
                title: 'Queue',
                type: 'item',
                icon: 'list',
                url: 'export/queue',
                translate: 'Nav.Export.Queue'
            }
        ]
    }
];
