import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SiteaccessService {
    constructor(private httpClient: HttpClient) {}

    public listUsers(): any {
        return this.httpClient.post<any>(
            environment.api + 'api/admin/site/user/list',
            null
        );
    }

    public retrieveUserAccess(userId: string): any {
        return this.httpClient.post<any>(
            environment.api + 'api/admin/site/user/' + userId + '/list',
            null            
        );
    }

    public updateUserAccess(userId: string, userAccessList: any[]): any {
        return this.httpClient.post<any>(
            environment.api + 'api/admin/site/user/' + userId + '/update',
            userAccessList            
        );
    }
}
