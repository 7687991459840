import { Component, OnInit, Inject, ViewChild, Output, EventEmitter, ElementRef, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserService } from 'app/services/user.service';
import { StoryService } from 'app/services/story.service';
import { FWBService } from 'app/fwb/services/fwb.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { languages } from 'app/shared/languages';
import { User } from 'app/models/user.model';
import { Store } from '@ngrx/store';
import { State } from 'app/_reducers';
import { Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { locale as enLocale } from './i18n/en';
import { locale as esLocaleCL } from './i18n/es-cl';
import { locale as esLocaleCO } from './i18n/es-co';
import { locale as esLocaleEC } from './i18n/es-ec';
import { locale as esLocaleMX } from './i18n/es-mx';
import { locale as esLocalePE } from './i18n/es-pe';
import { locale as cnLocale } from './i18n/cn';
import { locale as ptLocaleBR } from './i18n/pt-br';
import { locale as itLocale } from './i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CartService } from 'app/services/cart.service';
import { userActionLog, LogActionType, LoginType } from 'app/models/log.model';
import { environment } from "environments/environment";
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseConfig } from 'app/fuse.config';
import { SiteService } from 'app/services/site.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'app/shared/dialog/dialog.component';
import { isNumeric } from 'rxjs/util/isNumeric';
import * as moment from 'moment';
import { CLAIM_BASIS } from 'app/models/basis.model';

@Component({
  selector: 'app-useroptions',
  templateUrl: './useroptions.component.html',
  styleUrls: ['./useroptions.component.css']
})
export class UseroptionsComponent implements OnInit {
  user: User;
  siteList: any[];
  clientList: any[];
  clonedClientList: any[];
  countryList: any[];
  language = '';
  languageList: any[];
  defaultStoryList: any[];
  defaultStory: any;
  appCategory = {
    isPulse: false,
    isWellOne: false,
    isFWB: false,
  };


  rollingPeriodList = [
    { text: 'Monthly', value: '1' },
    { text: 'YTDFull', value: '2' },
    { text: 'YTDComparable', value: '3' },
    { text: 'Rolling3Months', value: '4' },
    { text: 'Rolling6Months', value: '5' },
    { text: 'Rolling9Months', value: '6' },
    { text: 'Rolling12Months', value: '7' }
  ];


  claimPaidList = [
    { text: 'PaidBasis', value: CLAIM_BASIS.PAID_BASIS },
    { text: 'IncurredIncomplete', value: CLAIM_BASIS.INCURRED_INCOMPLETE },
    { text: 'IncurredCompleted', value: CLAIM_BASIS.INCURRED_COMPLETED }
  ];

  planYearList = [
    { text: 2020, value: 2020 }
  ];

  isAdmin = false;
  isOfflineMode = false;
  isUserOptionsLoading = false;

  @Input() isDialog = false;
  @Input() showSites = true;
  @Input() showLanguage = true;
  @Input() showStories = true;
  @Input() showLogout = true;
  @Input() showAdminLinks = true;
  @Input() showCancel = false;
  @Input() showReset = false;
  @Input() showRollingPeriod = false;
  @Input() showPeriodSelection = false;
  @Input() showClaimPaid = false;
  @Input() showPlanYear = false;

  newLayoutUrl: string;
  // selection
  selectedSite: any;
  client: any;
  country: any;
  rollingPeriodSelection: string;
  claimsPaidSelection: CLAIM_BASIS;
  selectedvalueClient: any;
  selectedvalueCountry: any;
  selectedPlanYear: any;

  clientHasData: false;
  maxPaidDate: Date;
  maxAdmitDate: Date;
  maxDate: Date;

  period1StartDate: Date;
  period2StartDate: Date;
  period3StartDate: Date;
  period4StartDate: Date;

  minMaxDate: Date;
  maxMaxDate: Date;
  usePriorPeriod = false;

  showPeriodError: boolean;
  periodErrorMsg: string;

  isLoading = false;
  isLoadingCountry = false;
  isLoadingClient = false;

  logModel: userActionLog = new userActionLog();


  @Output() onOptionsSelected: EventEmitter<any> = new EventEmitter();
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onAdmin: EventEmitter<any> = new EventEmitter();
  // @ViewChild('clientSelect') clientSelect;
  // @ViewChild('countrySelect') countrySelect;
  @ViewChild('clientSelect') clientSelect: ElementRef;

  constructor(private _router: Router,
    private _userService: UserService,
    private storyService: StoryService,
    private _fuseNavigationService: FuseNavigationService,
    private _translateService: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private store: Store<State>,
    private cartService: CartService,
    private fwbService: FWBService,
    private _fuseConfigService: FuseConfigService,
    private _siteServices: SiteService,
    private _dialog: MatDialog) {



    this.appCategory.isPulse = environment.profile.project.title === "Pulse";
    this.appCategory.isWellOne = environment.profile.project.title === "Well One Analytics";
    this.appCategory.isFWB = environment.profile.project.title === "Financial Wellbeing";
    if (this.appCategory.isPulse) {
      this.newLayoutUrl = "/";
    } else if (this.appCategory.isWellOne) {
      this.newLayoutUrl = '/new-layout/';
      this.showStories = false;
      this.showClaimPaid = false;
      this.showLanguage = false;
      this.showPeriodSelection = false;
      this.showRollingPeriod = false;
    } else if (this.appCategory.isFWB) {
      this.newLayoutUrl = "/fwb/";
      this.showStories = false;
      this.showClaimPaid = false;
      this.showLanguage = false;
      this.showPeriodSelection = false;
      this.showRollingPeriod = false;
      this.showPlanYear = true;
    }
  }

  ngOnInit() {
    this._fuseTranslationLoaderService.loadTranslations(
      enLocale,
      esLocaleCL,
      esLocaleCO,
      esLocaleEC,
      esLocaleMX,
      esLocalePE,
      ptLocaleBR,
      itLocale,
    );

    this.manageInjectData();
    this.languageList = languages;
    console.log('INIT');
    this.store.select('user').subscribe(res => {
      this.user = res;
      this.rollingPeriodSelection = this.user.rollingPeriod.toString();
      this.claimsPaidSelection = this._defaultBasis;
      this.updateUsePriorPeriod(this.user.periodSelection);
      this.maxMaxDate = new Date();
      this.minMaxDate = new Date();
      this.minMaxDate.setFullYear(this.maxMaxDate.getFullYear() - 10);

      if (typeof this.user.timePeriod !== 'undefined') {

        this.clientHasData = this.user.timePeriod.clientHasData;


        if (this.user.timePeriod.maxDate !== null) {
          this.maxDate = moment(new Date(this.user.timePeriod.maxDate)).toDate();
        }

        if (this.user.timePeriod.period1StartDate !== null) {
          this.period1StartDate = moment(new Date(this.user.timePeriod.period1StartDate)).toDate();
        }

        if (this.user.timePeriod.period2StartDate !== null) {
          this.period2StartDate = moment(new Date(this.user.timePeriod.period2StartDate)).toDate();
        }

        if (this.user.timePeriod.period3StartDate !== null) {
          this.period3StartDate = moment(new Date(this.user.timePeriod.period3StartDate)).toDate();
        }

        if (this.user.timePeriod.period4StartDate !== null) {
          this.period4StartDate = moment(new Date(this.user.timePeriod.period4StartDate)).toDate();
        }

        if (this.user.timePeriod.maxClaimPaidDate !== null) {
          this.maxPaidDate = moment(new Date(this.user.timePeriod.maxClaimPaidDate)).toDate();
        }

        if (this.user.timePeriod.maxAdmitDate !== null) {
          this.maxAdmitDate = moment(new Date(this.user.timePeriod.maxAdmitDate)).toDate();
        }

        if (!this.user.timePeriod.maxDate) {
          this.setMaxDateMonthYear();
        }
        else {
          this.setDatePickerMinMaxDate();
        }


        this.validatePeriods();
      }


      //Default plan year to come from user options
      this.selectedPlanYear = "2020";

      console.log(this.user);
      this._translateService.currentLang = this.user.language;
      if (this.user.client) {
        this.setUserClientValue();
      }

      this.selectedSite = null;
      this.siteList = [];
      if (this.showSites) {
        this.getSiteList();
      }
      else {
        this.selectedSite = this._siteServices.getSelectedSite();
      }
    });
    var params = {
      username: this.user.userName
    };
    this.planYearList = this.fwbService.getPlanYears(params);
    this.getStoryList();
    this.isLoadingClient = true;

    if (this.selectedSite) {
      if (this.selectedSite.value === 'new-layout' || this.selectedSite.value === 'fwb') {
        this.showClaimPaid = false;
        this.showPeriodSelection = false;
        this.showRollingPeriod = false;
      }
    }

    this.isUserOptionsLoading = true;

    this._userService.getUserClients().toPromise().then(data => {
      this.clonedClientList = JSON.parse(JSON.stringify(data));

      setTimeout(() => {
        if (this.selectedSite) {
          this.onSiteChange({ detail: this.selectedSite });
        }
        setTimeout(() => {
          this.isUserOptionsLoading = false;
        }, 4000);
      });

      this.isLoading = false;
      this.isLoadingClient = false;
      this.isAdmin = this._userService.isAdmin() || this._userService.isRegionalManager() || this._userService.isCountryManager();
    });

    // default
    // this._userService.updateRollingPeriod(7);
    // this._userService.updatePeriodSelection(1);
    // this._userService.updateClaimPaid(true);
  }

  getSiteList() {
    this._userService.getUserSites(this.user.userId).toPromise().then(siteInfo => {
      this.selectedSite = siteInfo.defaultSite;
      this._siteServices.setSelectedSite(this.selectedSite);
      this.siteList = siteInfo.userSiteAccessRecordList;

      this.showStories = this.selectedSite.value === 'pulse';
      this.showLanguage = this.selectedSite.value === 'pulse';
    }, errorObj => {
      console.log(errorObj.error);
      this.displaySiteListErrorDialog(errorObj.error);
    });
  }

  private displaySiteListErrorDialog(errorMessage: string): void {
    const dialogRef = this._dialog.open(DialogComponent, {
      disableClose: true,
      data: { title: 'Error', message: errorMessage, isnobuttonvisible: false, yesbuttontext: 'Ok' }
    });

    dialogRef.afterClosed().subscribe(() => {
      this._userService.logout();
    });
  }

  setUserClientValue() {
    this.selectedvalueClient = this.user.client;
    this._handleClientSelectUI();
    this.client = this.user.client;
    this.isLoadingCountry = true;
    this._userService.getUserCountriesByClientId(this.client.tier1Id).toPromise().then(data => {
      this.countryList = data;

      if (this.user.country) {
        this.country = this.user.country;
        setTimeout(() => (this.selectedvalueCountry = this.user.country), 2000);
      }
      this.isLoadingCountry = false;
    });
  }

  manageInjectData() {
    // this.showCancel = this.data && this.data.showCancel;
    // this.showLogout = this.data && this.data.showLogout;
    // this.showLanguage = this.data && this.data.showLanguage;
    // this.showStories = this.data && this.data.showStories;
  }

  getStoryList() {
    this._userService.setUserSitePermissions();

    this.storyService.getAssignedUserStories().toPromise().then(data => {
      var result = data;
      console.log(result);
      this.defaultStory = null;
      this.defaultStoryList = result;

      if (this.defaultStoryList.length > 0) {
        this.defaultStory = result[0];
        console.log(this.defaultStory);
      }
      this.storyService.updateStoryNavigation(result);
    });
  }

  getClientTimePeriod() {
    this.isLoadingCountry = true;
    if (
      typeof this.client !== 'undefined' && typeof this.country !== 'undefined'
      && this.client.name !== '' && this.country.name !== ''
    ) {
      this._userService.getClientTimePeriod(
        this.client.tier1Id,
        this.client.name,
        this.client.isGroup,
        this.country.name,
      )
        .toPromise()
        .then(data => {
          const tp = data;
          if (tp?.planYears?.length > 0) {
            this.clientHasData = tp.clientHasData || undefined;
            this.period1StartDate = tp.period1StartDate ? moment(tp.period1StartDate).toDate() : undefined;
            this.period2StartDate = tp.period2StartDate ? moment(tp.period2StartDate).toDate() : undefined;
            this.period3StartDate = tp.period3StartDate ? moment(tp.period3StartDate).toDate() : undefined;
            this.period4StartDate = tp.period4StartDate ? moment(tp.period4StartDate).toDate() : undefined;
            this.maxPaidDate = tp.maxClaimPaidDate ? moment(tp.maxClaimPaidDate).toDate() : undefined;
            this.maxAdmitDate = tp.maxAdmitDate ? moment(tp.maxAdmitDate).toDate() : undefined;

            this.setMaxDateMonthYear();
            this.validatePeriods();
          }
          else {
            if (this.showPeriodSelection && this.clientHasData) {
              this.showPeriodError = true;
              this.periodErrorMsg = "Client does not have data. Please select another client/country.";
            }

          }

        })
        .finally(() => this.isLoadingCountry = false);
    } else {

      this.clientHasData = false;
      this.maxDate = null;
      this.maxAdmitDate = null;
      this.maxPaidDate = null;
      this.period1StartDate = null;
      this.period2StartDate = null;
      this.period3StartDate = null;
      this.period4StartDate = null;
      this.isLoadingCountry = false;
    }

  }

  setMaxDateMonthYear() {
    if (this.claimsPaidSelection === CLAIM_BASIS.PAID_BASIS) {
      if (typeof this.maxPaidDate !== 'undefined') {
        this.maxDate = moment(this.maxPaidDate).toDate();
      }
    } else {
      if (typeof this.maxAdmitDate !== 'undefined') {
        this.maxDate = moment(this.maxAdmitDate).toDate();
      }
    }

    this.setDatePickerMinMaxDate();

  }

  validatePeriods() {
    if (this.clientHasData) {
      this.showPeriodError = false;
      this.periodErrorMsg = '';
      let enddate = moment().toDate();
      if (typeof this.period1StartDate !== 'undefined' && typeof this.period2StartDate !== 'undefined') {
        enddate = moment(this.period1StartDate).subtract(1, "days").toDate();
        if (this.period1StartDate < enddate || this.period2StartDate >= this.period1StartDate) {
          this.showPeriodError = true;
          this.periodErrorMsg = this._translateService.instant("UserOptions.ErrorMessagePeriod1OverlapPeriod2");
        }
      }

      if (typeof this.period2StartDate !== 'undefined' && typeof this.period3StartDate !== 'undefined') {
        enddate = moment(this.period2StartDate).subtract(1, "days").toDate();
        if (this.period2StartDate < enddate || this.period3StartDate >= this.period2StartDate) {
          this.showPeriodError = true;
          this.periodErrorMsg = this._translateService.instant("UserOptions.ErrorMessagePeriod2OverlapPeriod3");

        }

      }

      if (typeof this.period3StartDate !== 'undefined' && typeof this.period4StartDate !== 'undefined') {
        enddate = moment(this.period3StartDate).subtract(1, "days").toDate();
        if (this.period3StartDate < enddate || this.period4StartDate >= this.period3StartDate) {
          this.showPeriodError = true;
          this.periodErrorMsg = this._translateService.instant("UserOptions.ErrorMessagePeriod3OverlapPeriod4");
        }

      }

      if (typeof this.period1StartDate === 'undefined') {
        this.showPeriodError = true;
        this.periodErrorMsg = this._translateService.instant("UserOptions.ErrorMessagePeriod1Missing");
      }

      if (typeof this.period2StartDate == 'undefined' && typeof this.period3StartDate !== 'undefined') {
        this.showPeriodError = true;
        this.periodErrorMsg = this._translateService.instant("UserOptions.ErrorMessagePeriod2Missing");
      }

      if (typeof this.period3StartDate == 'undefined' && typeof this.period4StartDate !== 'undefined') {
        this.showPeriodError = true;
        this.periodErrorMsg = this._translateService.instant("UserOptions.ErrorMessagePeriod3Missing");
      }

      if (typeof this.period1StartDate !== 'undefined'
        && typeof this.maxDate !== 'undefined') {

        if (this.maxDate < this.period1StartDate) {
          this.showPeriodError = true;
          this.periodErrorMsg = this._translateService.instant("UserOptions.ErrorMessageMaxDateEarlierThanPeriod1");
        }
      }
    }

  }

  setDatePickerMinMaxDate() {
    let dt = this.maxDate;
    if (this.claimsPaidSelection === CLAIM_BASIS.PAID_BASIS) {
      if (typeof this.maxPaidDate !== 'undefined') {
        dt = moment(this.maxPaidDate).toDate();
      }
    } else {
      if (typeof this.maxAdmitDate !== 'undefined') {
        dt = moment(this.maxAdmitDate).toDate();
      }
    }
    this.minMaxDate = new Date(moment(dt).year() - 10, moment(dt).month(), 1);
    this.maxMaxDate = dt;
  }

  onToggleUsePriorPeriodSelected(): void {
    this.usePriorPeriod = !this.usePriorPeriod;
  }

  onSiteChange($event) {
    this.selectedSite = $event.detail;
    this._siteServices.setSelectedSite(this.selectedSite);
    console.log(this.selectedSite);

    this.clientList = null;
    let unfilteredClientList = JSON.parse(JSON.stringify(this.clonedClientList));
    this.clientList = unfilteredClientList.filter((client) => client.sitePermissionList.indexOf(this.selectedSite.id) >= 0);

    if (this.clientList === null || this.clientList.length === 0) {
      console.error('Client list is empty!');
    }

    if (this.clientSelect.nativeElement.shadowRoot.querySelector('input') === null) {
      console.error('Client select input not found', `Client list : ${this.clientList}`);
    }

    this.clientSelect?.nativeElement?.shadowRoot?.querySelector('input')?.addEventListener('input', (event) => {
      const enteredValue = (event.target.value || '').toLowerCase();
      this.clientList = [...unfilteredClientList.filter((client) => client.name.toLowerCase().indexOf(enteredValue) >= 0)];
    });

    delete this.country;
    this.selectedvalueCountry = "";
    this.selectedvalueClient = $event.detail;
    this.client = $event.detail;

    this.showStories = !this.isDialog && this.selectedSite.value === 'pulse';
    this.showLanguage = !this.isDialog && this.selectedSite.value === 'pulse';

    if (this.user.client) {
      this.setUserClientValue();
    }
  }

  onClientChange($event) {
    delete this.country;
    this.showPeriodError = false;
    this.periodErrorMsg = "";
    this.selectedvalueCountry = "";
    this.selectedvalueClient = $event.detail;
    this._handleClientSelectUI()
    this.client = $event.detail;
    this.getClientTimePeriod();
    this.isLoadingCountry = true;
    this._userService.getUserCountriesByClientId(this.client.tier1Id).toPromise().then(data => {
      this.countryList = data;
      this.isLoadingCountry = false;
    });
  }

  onPlanYearChange($event) {
    this.selectedPlanYear = "";
    this.selectedPlanYear = $event.detail;
  }

  countryListASIA = [
    'Hong Kong', 'Malaysia', 'Philippines', 'Singapore', 'Thailand'
  ];

  updateUsePriorPeriod(periodSelection) {
    this.usePriorPeriod = periodSelection === 2;
  }

  onCountryChange($event) {
    this.showPeriodError = false;
    this.periodErrorMsg = "";
    this.country = $event.detail;
    this.selectedvalueCountry = $event.detail;
    this.getClientTimePeriod();
    if (this.countryListASIA.indexOf(this.country.name) >= 0) {
      this._userService.updateRollingPeriod(2);
      this._userService.updatePeriodSelection(1);
      this._userService.updateClaimPaid(CLAIM_BASIS.INCURRED_INCOMPLETE);

      this.store.select('user').subscribe(res => {
        this.rollingPeriodSelection = this.user.rollingPeriod.toString();
        this.claimsPaidSelection = this._defaultBasis;
        this.updateUsePriorPeriod(this.user.periodSelection);
      });
    } else {
      this._userService.updateRollingPeriod(7);
      this._userService.updatePeriodSelection(1);
      this._userService.updateClaimPaid(CLAIM_BASIS.PAID_BASIS);

      this.store.select('user').subscribe(res => {
        this.rollingPeriodSelection = this.user.rollingPeriod.toString();
        this.claimsPaidSelection = this._defaultBasis;
        this.updateUsePriorPeriod(this.user.periodSelection);
      });
    }
  }
  onRollingPeriodChange($event) {
    this.rollingPeriodSelection = $event.detail;

  }

  onClaimsPaidChange($event) {
    this.claimsPaidSelection = this._convertToClaimBasis($event.detail);
    this.setMaxDateMonthYear();

  }
  onLanguageChange($event) {
    this.language = $event.detail;
    this._translateService.use(this.language);
    this._userService.updateLanguage(this.language);
  }

  onStoryChange($event) {
    this.defaultStory = $event.detail;
  }

  onPeriod1DateChanged($event) {
    this.period1StartDate = moment($event).toDate();
    this.validatePeriods();
  }

  onPeriod2DateChanged($event) {
    this.period2StartDate = moment($event).toDate();
    this.validatePeriods();
  }

  onPeriod3DateChanged($event) {
    this.period3StartDate = moment($event).toDate();
    this.validatePeriods();
  }

  onPeriod4DateChanged($event) {
    this.period4StartDate = moment($event).toDate();
    this.validatePeriods();
  }

  onMaxDateChanged($event) {
    this.maxDate = moment($event).toDate();
    this.validatePeriods();
  }

  public cancel() {
    console.log('cancel')
    this.onCancel.emit();
  }

  continue() {
    if (!this.client || !this.country || this.showPeriodError || (this.showStories && this.defaultStory === null) || this.isLoadingCountry) {
      return;
    }

    if (this.showStories) {
      localStorage.setItem('story', JSON.stringify(this.defaultStory));
    }


    this.logModel.LogEvent = LogActionType.UserOptionsSelected;
    this.logModel.ActionName = LogActionType.UserOptionsSelected;
    // this.logLocalUserAction();


    this._userService.updateClientCountry(this.client, this.country);

    if (this.appCategory.isFWB) {
      this._router.navigate([this.newLayoutUrl + 'executive']);
    }
    this.cartService.clearItems();

    if (this.showRollingPeriod) {
      this._userService.updateRollingPeriod(parseInt(this.rollingPeriodSelection));
    }

    if (this.showPeriodSelection || this.selectedSite.value === 'pulse') {
      const periodSelection = this.usePriorPeriod ? 2 : 1;
      this._userService.updatePeriodSelection(periodSelection);

      const timeperiod = {
        period1StartDate: this.period1StartDate,
        period2StartDate: this.period2StartDate,
        period3StartDate: this.period3StartDate,
        period4StartDate: this.period4StartDate,
        maxDate: this.maxDate,
        maxClaimPaidDate: this.maxPaidDate,
        maxAdmitDate: this.maxAdmitDate,
        clientHasData: this.clientHasData
      };
      this._userService.updateTimePeriod(timeperiod);
    }

    if (this.showClaimPaid) {
      this._userService.updateClaimPaid(this.claimsPaidSelection);
    }
    if (this.showPlanYear) {
      this._userService.updatePlanYear(this.selectedPlanYear);
    }

    this._userService.userChanged.emit(this._userService.user);

    const newConfig = fuseConfig;
    newConfig.layout.style = 'aon-new-layout';
    this._fuseConfigService.setConfig(newConfig);

    if (this.showStories && this.defaultStory) {
      this.onOptionsSelected.emit({ stroiesId: this.defaultStory.storiesId });

      this._router.navigate(['/story/view/' + this.defaultStory.storiesId]);
    } else if (this.isDialog && this.selectedSite.value === 'pulse') {
      this.onOptionsSelected.emit();
    } else {
      this.onOptionsSelected.emit();
      this._router.navigate([this.selectedSite.siteUrl]);
    }
  }

  admin() {
    if (this.selectedSite.value === 'pulse') {
      let newConfig = fuseConfig;
      newConfig.layout.style = 'aon-new-layout';
      this._fuseConfigService.setConfig(newConfig);

      this._router.navigate(['admin/user']);
    }
    else {
      this._router.navigate([this.selectedSite.siteUrl]);
      let newConfig = fuseConfig;
      newConfig.layout.style = 'aon-new-layout';
      this._fuseConfigService.setConfig(newConfig);
    }
    this.onAdmin.emit();
  }


  logout() {
    this._userService.logout();
  }

  isOfflineWb() {
    return false;
  }

  getOfflineWb() {

  }

  isUserUsingNewLayout(): boolean {
    return environment.profile.newLayoutUsers.indexOf(this.user.email.toLowerCase()) >= 0;
  }

  private get _defaultBasis(): CLAIM_BASIS {
    if (Object.entries(CLAIM_BASIS).some(([, value]) => this.user.claimPaid === value)) {
      return this.user.claimPaid;
    }

    return CLAIM_BASIS.PAID_BASIS;
  }

  private _convertToClaimBasis(value: string): CLAIM_BASIS {
    return CLAIM_BASIS[CLAIM_BASIS[parseInt(value, 10)]];
  }

  private _handleClientSelectUI() {
    setTimeout(() => {
      const selected = this.selectedvalueClient.name;
      const textDiv = document.getElementById('userOptionClient').shadowRoot.querySelector("div.text");
      if (selected && textDiv && textDiv.innerHTML !== this.selectedvalueClient.name) {
        textDiv.innerHTML = this.selectedvalueClient.name;
      }
    });
  }
}
