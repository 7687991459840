export const locale = {
    lang: 'en',
    data: {
        'SuperAdmin': {
            'Settings': 'SETTINGS',
            'HelpText': 'Accessed only by admins.',
            'RegionalManagers': 'Regional Managers',
            'Countries': 'Countries',
            'Clients': 'Clients',
            'Save': 'SAVE',
            'NewClient':'New Client',
            'NewCountry':'New Country',
            'EnableCountry': 'Enable Country?',
            'Name':'Name',
            'Create': 'CREATE',
            'Update': 'UPDATE',
            'Add': 'ADD',
            'Delete': 'DELETE',
            'Cancel':'CANCEL',
            'Users':'Users',
            'User':'User',
            'CreateAccount': 'Create Account',
            'DeleteAccount': 'Delete Account',
            'Roles':'Roles',
            'rmColumnDefs': [
                {
                  headerName: 'Email',
                  field: 'email',
                  sort: 'asc'
                },
                { 
                  headerName: 'Name', 
                  field: 'userName',
                },
                {
                    headerName: 'Created Date', field: 'createdOn'
                }
            ],
            'countryColumnDefs': [
                { 
                  headerName: 'Name', 
                  field: 'name', 
                  sort: 'asc' 
                }
            ],
            'clientColumnDefs': [
                { 
                  headerName: 'Name', 
                  field: 'name', 
                  sort: 'asc',
                  width: 500
                },
                { 
                    headerName: 'Is Group?', 
                    field: 'isGroup',
                    cellRenderer: params => {
                        return `<input type='checkbox' disabled readonly ${params.value ? 'checked' : ''} />`;
                    }
                },
                { 
                    headerName: 'Created', 
                    field: 'createdOn'
                },
                { 
                    headerName: 'Selected', 
                    field: 'isSelected'
                }
            ]
        }
    }
};
