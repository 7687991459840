import { Component } from '@angular/core';

enum LOCALE_GROUP {
  EN = 'English',
  ES = 'Español',
  PT = 'Português',
}

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent {
  public languages = [
    LOCALE_GROUP.EN,
    LOCALE_GROUP.ES,
    LOCALE_GROUP.PT,
  ];
  public LOCALE_GROUP = LOCALE_GROUP;
  public selectedLanguage = LOCALE_GROUP.EN;

  public onLanguageChange(lang): void {
    this.selectedLanguage = lang;
  }

  public isTargetLanguage(target: LOCALE_GROUP): boolean {
    return this.selectedLanguage === target;
  }

  public get title(): string {
    if (this.selectedLanguage === LOCALE_GROUP.EN) {
      return 'Legal Disclaimer';
    }

    if (this.selectedLanguage === LOCALE_GROUP.ES) {
      return 'Aviso Legal';
    }

    if (this.selectedLanguage === LOCALE_GROUP.PT) {
      return 'Aviso Legal';
    }
  }
}
