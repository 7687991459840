<div fxLayout="column" *ngIf="isLoading" style="overflow: hidden;">
    <div fxLayout="row" class="fwbloadingOverlay">
        <div class="fwbloadingContent">
            Loading...
        </div>
    </div>
</div>
<aon-summary-card [height]="cardheight" [width]="cardwidth" class="individual-chart" *ngIf="!isLoading">
    <aon-summary-card-header>
        <div class="flex-row-simple white-font2 flex-end"
            style="width: 94%; height: 40px; color: #001b5a; margin-top: -5px;">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <aon-h3>
                    <fa-icon style="padding-right: 5px;" [icon]="['fal', 'donate']"></fa-icon>
                    {{ charttitle }}
                </aon-h3>


                <div style="
                    display: flex;
                    flex-direction: row;justify-content: flex-end;
                    flex: 1;
                    min-width: 50px;
                ">

                    <div [ngClass]="
                         isGlobeData
                            ? 'circle-icon header-table-toggle'
                                : 'header-table-toggle'
                             
                    ">
                        <div style="width: 4em; height: 2em;" (click)="toggleTable('globe')">
                            <fa-icon [icon]="['fal', 'globe']" style="height: 5em;"></fa-icon>
                        </div>
                    </div>

                    <div [ngClass]="
                        isTableData
                            ? 'circle-icon header-table-toggle'
                            : 'header-table-toggle'
                    ">
                        <div style="width: 4em; height: 2em;" (click)="toggleTable('table')">
                            <fa-icon [icon]="['fal', 'table']" style="height: 15em;"></fa-icon>
                        </div>
                    </div>
                    <div [ngClass]="
                         isGroupedData
                                ? 'circle-icon header-table-toggle'
                                : 'header-table-toggle'
                            
                    ">
                        <div style="width: 4em; height: 2em;" (click)="toggleTable('group')">
                            <fa-icon [icon]="['fal', 'analytics']" style="height: 5em;"></fa-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </aon-summary-card-header>
    <aon-summary-card-content [contentwidth]="'92%'" top="60px">
        <fwb-error-message [isError]="isError" [message]="errorMsg"></fwb-error-message>
        <div *ngIf="!isError">
            <div *ngIf="!isTableData && isGroupedData">
                <div style="display: flex; flex-direction: row;">
                    <div>
                        <aon-h1 style="color: #001b5a;">624</aon-h1>
                    </div>
                    <div style="margin-top: 20px; margin-left: 5px;">
                        <aon-p-elg>
                            750
                        </aon-p-elg>
                    </div>
                </div>
                <div style="text-align: left;">
                    <aon-p-lg>
                        See how your pension structure compares to the average by
                        category.
                    </aon-p-lg>
                </div>
                <div style="margin-top: -65px; height: 100%; margin-left: -52px;">
                    <aon-group-bar-chart [chartid]="chartId" [width]="width" [height]="groupheight"
                        [charttitle]="emptyChartTitle" [xaxistitle]="xaxistitle" [yaxistitle]="yaxistitle"
                        [tooltip]="tooltip" [maxyaxisnumber]="maxyaxisnumber" [titleposition]="titleposition"
                        [data]="groupedData">
                    </aon-group-bar-chart>
                </div>
            </div>

            <div *ngIf="isTableData" style="margin-left: -15px;">
                <div class="marginTop10">
                    <ag-grid-angular style="width: 95%; height: 100%; overflow-x: hidden;"
                        class="ag-theme-material grid" [rowData]="tableData" [showHeader]="isHeaderVisible"
                        [columnDefs]="columnDefs" [gridOptions]="gridOptions">
                    </ag-grid-angular>
                </div>
            </div>
            <div *ngIf="isGlobeData" style="margin-left: -15px;">
                <div class="marginTop10">
                    <ag-grid-angular style="width: 95%; height: 100%; overflow-x: hidden;"
                        class="ag-theme-material grid" [rowData]="globeData" [showHeader]="isHeaderVisible"
                        [columnDefs]="countryColumnDefs" [gridOptions]="gridOptions">
                    </ag-grid-angular>
                </div>
            </div>
        </div>
    </aon-summary-card-content>
    <aon-summary-card-footer>
        <aon-button-default (onclick)="goToLearnMore()" id="btn-prominent2">Learn More</aon-button-default>
    </aon-summary-card-footer>
</aon-summary-card>