<div
  id="storyBoardComp"
  class="page-layout blank contentStoryPadding"
  fxLayout="column"
  fusePerfectScrollbar
>
  <div
    id="storyBoard1half"
    fxLayout="row"
    fxLayoutGap="12px"
    fxLayoutAlign="start center"
    style="height: 42px; margin-top: -5px; margin-bottom: 5px;"
  >
    <mat-form-field
      *ngIf="!isReadOnly"
      fxFlex
      floatLabel="never"
    >
      <input
        matInput
        placeholder="Story Title"
        style="font-weight: bold"
        [(ngModel)]="storyModelObj.storyName"
        autocomplete="off"
        [ngModelOptions]="{standalone: true}"
      >
    </mat-form-field>
    <h3 *ngIf="isReadOnly"><strong>{{storyModelObj.storyName}}</strong></h3>
    <span fxFlex></span>
    <mat-spinner
      *ngIf="isLoading"
      class="mat-accent"
      style="margin: 0 auto"
      diameter="30"
    ></mat-spinner>

    <!-- preview navigation controls -->
    <ng-container
      id="storyBoardSingleView"
      *ngIf="activeViewMode == viewModes.single && dashboardList.length > 0"
    >
      <button
        id="storyBoardBtnToggleSingle"
        mat-icon-button
        style="max-height: 36px"
        (click)="toggleSingleViewModeChartPreviewNavigationShown()"
        [ngClass]="{ 'mat-accent': isSingleViewChartPreviewNavigationShown }"
      >
        <mat-icon>power_input</mat-icon>
      </button>
      <button
        id="storyBoardBtnPre"
        mat-icon-button
        style="max-height: 36px"
        (click)="viewPrevious()"
        [disabled]="isLoading"
      >
        <mat-icon>chevron_left</mat-icon>
      </button> {{viewIndex + 1}} / {{dashboardList.length}} &nbsp;&nbsp;
      <button
        id="storyBoardBtnNext"
        mat-icon-button
        style="max-height: 36px"
        (click)="viewNext()"
        [disabled]="isLoading"
      >
        <mat-icon>chevron_right</mat-icon>
      </button>
    </ng-container>
    <button
      id="storyBoardBtnViewSingle"
      mat-icon-button
      style="max-height: 36px"
      (click)="viewSingle()"
      [ngClass]="{ 'mat-accent': activeViewMode == viewModes.single }"
    >
      <mat-icon>crop_5_4</mat-icon>
    </button>
    <button
      id="storyBoardBtnViewStream"
      mat-icon-button
      style="max-height: 36px"
      (click)="viewStream()"
      [ngClass]="{ 'mat-accent': activeViewMode == viewModes.stream }"
    >
      <mat-icon>view_stream</mat-icon>
    </button>
    <button
      id="storyBoardBtnViewTile"
      mat-icon-button
      style="max-height: 36px"
      (click)="viewTile()"
      [ngClass]="{ 'mat-accent': activeViewMode == viewModes.tile }"
    >
      <mat-icon>view_module</mat-icon>
    </button>
    <button
      id="storyBoardBtnAddAll"
      mat-raised-button
      *ngIf="dashboardList.length > 0"
      style="max-height: 36px"
      (click)="addAllToExport()"
    >
      {{ 'Storyboard.AddAll' | translate }}
    </button>
    <button
      id="storyBoardBtnToggleLibrary"
      mat-raised-button
      *ngIf="!isReadOnly"
      style="max-height: 36px"
      (click)="toggleLibrary()"
      [ngClass]="{ 'mat-accent': !showStoryBoard }"
    >
      {{ 'Storyboard.Library' | translate }}
    </button>
    <!--     <button mat-raised-button style="max-height: 36px" (click)="showExportToggle()" [matBadge]="exportCount"
      matBadgePosition="before" matBadgeColor="warn">
      {{ 'Storyboard.Export' | translate }}
    </button> -->
    <button
      id="storyBoardBtnSave"
      mat-raised-button
      *ngIf="!isReadOnly"
      class="mat-primary"
      style="max-height: 36px"
      [disabled]="!storyValid() || isLoading"
      (click)="save()"
    >
      {{ 'Storyboard.Save' | translate }}
    </button>
  </div>
  <!-- Begin main storyboard -->
  <div
    id="storyBoardMain"
    fxLayout="row"
    fxLayoutGap="12px"
  >
    <div
      fxFlex
      id="mainstoryboard"
      (hidden)="showStoryBoard"
      style="overflow: hidden; background-color: rgb(245, 245, 245)"
    >
      <div [fxLayout]="layoutStoryBoard">
        <ng-container *ngFor="let dash of dashboardList; let i = index">

          <mat-drawer-container
            class="mat-drawer-light-backdrop"
            style="margin: 0px 0.5% 12px 0px"
            [style.position]="activeViewMode == viewModes.single && i != viewIndex ? 'absolute' : 'relative'"
            [style.top]="activeViewMode == viewModes.single && i != viewIndex ? '-9999px' : '0px'"
            [style.left]="activeViewMode == viewModes.single && i != viewIndex ? '-9999px' : '0px'"
            hasBackdrop="true"
          >

            <mat-drawer
              #drawer
              mode="over"
              style="min-width: 240px"
            >
              <div fxLayout="row">
                <span fxFlex></span>
                <button
                  mat-icon-button
                  class="mat-accent"
                  (click)="drawer.toggle()"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <div
                *ngIf="!isAddCustomView"
                fxLayout="column"
                style="padding: 0px 14px"
              >
                <strong>{{ 'Storyboard.CustomViews' | translate }}</strong>
                <br>
                <button
                  mat-button
                  (click)="drawer.toggle(); resetCustomViewLight(i)"
                >
                  <span>{{ 'Storyboard.ResetCustomView' | translate }}</span> &nbsp;
                  <mat-icon>settings_backup_restore</mat-icon>
                </button>
                <span *ngIf="!dash.filters || dash.filters.length == 0">
                  <br> {{ 'Storyboard.NoCustomViewFound' | translate }}
                  <button
                    mat-icon-button
                    class="mat-accent"
                    (click)="isAddCustomView = true;"
                  >
                    {{ 'Storyboard.ADD' | translate }}
                  </button>
                </span>
                <div
                  fxLayout="row"
                  *ngFor="let f of dash.filters"
                >
                  <span fxFlex="70">
                    <button
                      mat-menu-item
                      [value]="f"
                      (click)="drawer.toggle(); applyCustomView(i, f)"
                    >
                      <span>{{f.name}}</span>
                    </button>
                  </span>
                  <span fxFlex="30">
                    <button
                      mat-icon-button
                      [value]="f"
                      (click)="deleteDashboardCustomView(i, f)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </span>
                </div>
              </div>
              <div
                *ngIf="isAddCustomView"
                fxLayout="column"
                style="padding: 0px 14px"
              >
                <strong>{{ 'Storyboard.SaveCustomView' | translate }}</strong>
                <br><br>
                <mat-form-field>
                  <input
                    matInput
                    style="width: 250px;"
                    data-placeholder="{{ 'Storyboard.CustomViewName' | translate }}"
                    [(ngModel)]="dash.filterName"
                    autocomplete="off"
                    [ngModelOptions]="{standalone: true}"
                  >
                </mat-form-field>
                <mat-form-field>
                  <textarea
                    matInput
                    style="width: 250px;"
                    data-placeholder="{{ 'Storyboard.Comments' | translate }}"
                    autocomplete="off"
                    [(ngModel)]="dash.filterComments"
                    [ngModelOptions]="{standalone: true}"
                  ></textarea>
                </mat-form-field>
                <div fxLayout="row">
                  <button
                    mat-button
                    class="mat-accent"
                    (click)="isAddCustomView = false; drawer.toggle()"
                  >
                    {{ 'Storyboard.CANCEL' | translate }}
                  </button>
                  <span fxFlex></span>
                  <button
                    mat-button
                    class="mat-accent"
                    (click)="saveDashboardCustomView(i);drawer.toggle()"
                  >
                    {{ 'Storyboard.SAVE' | translate }}
                  </button>
                </div>
              </div>
            </mat-drawer>

            <mat-drawer-content>
              <mat-card style="padding: 0px">
                <div
                  fxLayout="row"
                  fxLayoutAlign="start end"
                >
                  <button
                    *ngIf="activeViewMode != viewModes.tile"
                    mat-icon-button
                    matTooltip="{{ 'Storyboard.ApplyCustomView' | translate }}"
                    (click)="isAddCustomView = false; drawer.toggle(); loadDashboardCustomViews(i)"
                  >
                    <mat-icon class="s-20">bookmarks</mat-icon>
                  </button>
                  <button
                    *ngIf="activeViewMode != viewModes.tile"
                    mat-icon-button
                    matTooltip="{{ 'Storyboard.SaveCustomView' | translate }}"
                    (click)="isAddCustomView = true; drawer.toggle()"
                  >
                    <mat-icon class="s-20">save</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    matTooltip="{{ 'Storyboard.AddToExport' | translate }}"
                    (click)="addToExport(i)"
                  >
                    <mat-icon class="s-20">playlist_add</mat-icon>
                  </button>

                  <span fxFlex></span>
                  <ng-container
                    *ngIf="isWindowFullScreen && activeViewMode == viewModes.single && dashboardList.length > 0"
                  >
                    <button
                      mat-icon-button
                      style="max-height: 36px"
                      (click)="toggleSingleViewModeChartPreviewNavigationShown()"
                      [ngClass]="{ 'mat-accent': isSingleViewChartPreviewNavigationShown }"
                    >
                      <mat-icon>power_input</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      style="max-height: 36px"
                      (click)="viewPrevious()"
                    >
                      <mat-icon>chevron_left</mat-icon>
                    </button>
                    <span style="line-height: 32px;">{{viewIndex + 1}} / {{dashboardList.length}} </span>
                    <button
                      mat-icon-button
                      style="max-height: 36px"
                      (click)="viewNext()"
                    >
                      <mat-icon>chevron_right</mat-icon>
                    </button>
                  </ng-container>
                  <div *ngIf="!isWindowFullScreen && activeViewMode != 'tile'">
                    <ng-container *ngIf="!isReadOnly">
                      <button
                        mat-icon-button
                        matTooltip="Move up"
                        (click)="moveUp(i)"
                      >
                        <mat-icon class="s-20">keyboard_arrow_up</mat-icon>
                      </button>
                      <button
                        mat-icon-button
                        matTooltip="Move down"
                        (click)="moveDown(i)"
                      >
                        <mat-icon class="s-20">keyboard_arrow_down</mat-icon>
                      </button>
                    </ng-container>
                    <span fxFlex></span>
                    <button
                      *ngIf="activeViewMode != viewModes.single"
                      mat-icon-button
                      matTooltip="View single"
                      (click)="viewIndex = i; viewSingle();"
                    >
                      <mat-icon class="s-20">crop_5_4</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      matTooltip="Full-screen"
                      (click)="viewIndex = i; goFullScreen(i)"
                    >
                      <mat-icon class="s-20">fullscreen</mat-icon>
                    </button>
                  </div>
                  <mat-spinner
                    *ngIf="dash.isLoading"
                    class="mat-accent"
                    diameter="20"
                    style="position: absolute; right: 10px; top: 34px"
                  ></mat-spinner>
                  <button
                    *ngIf="!isReadOnly"
                    mat-icon-button
                    (click)="removeDashboard(i)"
                  >
                    <mat-icon class="s-20">delete</mat-icon>
                  </button>
                </div>
                <div
                  style="overflow: scroll"
                  [hidden]="activeViewMode == 'tile'"
                >
                  <div
                    id="td-{{i}}"
                    class="tableau-container"
                    [style.height]="getTableauContainerHeight()"
                  >
                    <!-- TABLEAU WRAPPER -->
                  </div>
                </div>
                <div *ngIf="activeViewMode == 'tile'">
                  <img
                    class="img"
                    style="margin-top:0px; height: 180px;"
                    src="{{dash.imageBase64String}}"
                  />
                </div>

                <div *ngIf="!isWindowFullScreen && activeViewMode == 'tile'">
                  <ng-container *ngIf="!isReadOnly">
                    <button
                      mat-icon-button
                      matTooltip="Move up"
                      (click)="moveUp(i)"
                    >
                      <mat-icon class="s-20">keyboard_arrow_up</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      matTooltip="Move down"
                      (click)="moveDown(i)"
                    >
                      <mat-icon class="s-20">keyboard_arrow_down</mat-icon>
                    </button>
                  </ng-container>
                  <span fxFlex></span>
                  <button
                    *ngIf="activeViewMode != viewModes.single"
                    mat-icon-button
                    matTooltip="View single"
                    (click)="viewIndex = i; viewSingle();"
                  >
                    <mat-icon class="s-20">crop_5_4</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    matTooltip="Full-screen"
                    (click)="viewIndex = i; goFullScreen(i)"
                  >
                    <mat-icon class="s-20">fullscreen</mat-icon>
                  </button>
                </div>
              </mat-card>

            </mat-drawer-content>
          </mat-drawer-container>
        </ng-container>
      </div>

      <div
        id="storyBoardSingle"
        *ngIf="dashboardList.length > 0 && activeViewMode === 'single'"
        [hidden]="!isSingleViewChartPreviewNavigationShown"
      >
        <div
          class="container"
          fxLayout="row"
          fxLayoutAlign="left"
        >
          <div
            id="storyBoardNavBefore"
            class="clickable move-to-left-nav-button"
            (click)="scrollToPrevious()"
          >
            <button
              style="top:38%"
              mat-icon-button
            >
              <mat-icon>navigate_before</mat-icon>
            </button>
          </div>
          <div
            id="storyBoardDashList"
            fxFlex
            style="height: auto; overflow: scroll;"
            class="single-view-scrollable-region"
          >
            <div
              fxLayout="row"
              fxLayoutGap="12px"
            >
              <div
                *ngFor="let lib of dashboardList; let i = index"
                (click)="navigationClick(i)"
              >
                <mat-card
                  matTooltip="{{lib.name}}"
                  matTooltipPosition="above"
                  class="single-view-card"
                  [ngStyle]="{'border': viewIndex === i ? '1.5px solid darkcyan' : '' }"
                  style="cursor:pointer;"
                >
                  <mat-card-content>
                    <img
                      class="img"
                      style="margin-top:0px; height: 80px; min-width: 90px"
                      src="{{lib.imageBase64String}}"
                    />
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </div>
          <div
            id="storyBoardNavNext"
            class="clickable"
            (click)="scrollToNext()"
          >
            <button
              style="top:38%"
              mat-icon-button
            >
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="!isReadOnly"
      [fxFlex]="libraryFlex"
      fxLayout="column"
      fxLayoutGap="8px"
    >
      <library
        id="storyBoardSelect"
        (selectionChange)="selectionChange($event)"
        (onAdd)="onAdd($event)"
      ></library>
    </div>
  </div>
</div>
<div
  style="position: absolute; top:0; text-align: center; height: 100%; width: 100%; z-index: 80; background-color: white;"
  *ngIf="isLoadingOverlay"
>
</div>
<div
  class="processing-overlay"
  *ngIf="isLoadingOverlay"
>
  <br>
  <div
    id="storyBoardSpin"
    style="margin: 0 auto; width: 200px"
    fxLayout="row"
  >
    <strong>Loading Story... </strong> &nbsp; &nbsp;
    <mat-spinner
      class="mat-accent"
      diameter="20"
    ></mat-spinner>
  </div>
</div>