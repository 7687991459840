<aon-summary-card [height]="cardheight" [width]="cardwidth" *ngIf="!isLoading">
    <aon-summary-card-header>
        <aon-h3 style="color: #001b5a;">
            {{ charttitle }}
        </aon-h3>
    </aon-summary-card-header>
    <aon-summary-card-content top="60px" contentheight="410px" contentheight="410px">
        
        <fwb-error-message [isError]="isError" [message]="errorMsg"></fwb-error-message>
        <div *ngIf="!isError">
        <div class="flex-column-simple">

            <aon-donut-chart [chartid]="chartId" [width]="width" [height]="height" [legendtitle]="legendtitle"
                [legendposition]="legendPosition" [tooltip]="tooltip" [showLegend]="showLegend"
                [middletext]="middleText" [data]="salaryChartData" [legendsubtitle]="legendsubtitle">
            </aon-donut-chart>

            <div class="flex-column-simple" style="width: 98%;">
                <!-- <aon-p-lg>Target</aon-p-lg> -->
                <aon-legend height="90%" [data]="legendData" [legendposition]="'horizontal'"></aon-legend>
            </div>
        </div>
        </div>
    </aon-summary-card-content>
</aon-summary-card>