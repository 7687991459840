import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { adjustmentValidatorGroup } from '../constants/cost-projection-validator-groups';

@UntilDestroy()
@Component({
  selector: 'app-cost-projection-inputs',
  templateUrl: './cost-projection-inputs.component.html',
  styleUrls: ['./cost-projection-inputs.component.scss']
})
export class CostProjectionInputsComponent implements OnInit {

  public minDate: Date = new Date(moment(new Date()).subtract(10, 'year').toDate());
  public maxDate: Date = new Date();
  public projectionMaxDate: Date = new Date(moment(new Date()).add(2, 'year').toDate());

  public isTimePeriodSectionExpanded = true;
  public isMembershipSectionExpanded = true;
  public isTrendsAndAdjustmentsExpanded = true;

  @Input() inputForm: FormGroup = null;

  @Output() userResetForm = new EventEmitter<void>();
  @Output() userUpdateForm = new EventEmitter<void>();
  @Output() userSubmitForm = new EventEmitter<any>();

  constructor(private _fb: FormBuilder) { }

  ngOnInit(): void {
    this._initTrendsAndAdjustmentsTableSubscriptions();
    this._initFormSubscriptions();
    this.inputForm.markAllAsTouched();
  }

    // #region General - To cater for form reset, submit and update

    public resetForm(): void {
      this.userResetForm.emit();
    }
  
    public submitForm(): void {
      this.userSubmitForm.emit({...this.inputForm.value});
    }
  
    private _initFormSubscriptions(): void {
      this.inputForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.userUpdateForm.emit();
      });
    }
  
    // #endregion

  // #region Trends and Adjustments

  public addOtherAdjustment(): void {
    (<FormArray>this.inputForm.get('trendsAndAdjustments.adjustments')).push(
      this._fb.group({ 
        client: [100, adjustmentValidatorGroup], 
        country: [100, adjustmentValidatorGroup], 
        notes: ''
      })
    );
  }

  public removeOtherAdjustment(indexToBeRemoved: number): void {
    (<FormArray>this.inputForm.get('trendsAndAdjustments.adjustments')).controls.splice(indexToBeRemoved, 1);
  }

  // To cater client's credibility input changes
  private _initTrendsAndAdjustmentsTableSubscriptions(): void {
    this.inputForm.get('trendsAndAdjustments.credibility.client').valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(clientCredibility => {
      this.inputForm.get('trendsAndAdjustments.credibility.country').patchValue(100 - clientCredibility);
    });
  }

  // #endregion
}
