export const locale = {
    lang: 'es-pe',
    data: {
        'DiseaseDetail': {
            'DiseaseDetailHeading': 'Prevalencia De La Enfermedad',
            'AllAmountInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'SelectTop': 'Seleccione Top',
            'Diagnosis': 'Diagnóstico',
            'ClaimsTotalHeader': 'Total de siniestros',    
            'DiagnosisLevel': 'Nivel de diagnóstico',
            'Category': 'Categoría',               
            'Chapter': 'Agrupador',
            'ClaimsTotal': 'Total de siniestros',
            'DigClaimantCount': 'Cuenta de Paciente', 
            'DiseaseProgression': 'Progresión de la enfermedad a través del tiempo',
            'Age': 'Etario', 
            'CostPerClaimant': 'Costo por paciente',
            'CostPerClaim': 'Costo por atención',
            'ClaimsPerClaimant': 'Siniestros por paciente',
            'TotalCost': 'Total de siniestros',
            'ClaimantCount': 'Cuenta de Paciente',
            'Prevalence': 'Predominio',
            'Benchmark': 'Benchmark',
            'IncludeClient': 'Incluir cliente',
            'ExcludeClient': 'Excluir cliente',
            'Portfolio': 'portafolio',
            'Industry': 'Industria',
            'None': 'Ninguno',
            'AgeBand': 'Rango Etario',
            'Gender': 'Género',
            'Relationship': 'Parentesco',
            'ClaimType' : 'Tipo de atención',
            'ClaimsReportedByPaidDate': 'Siniestros informados por fecha de ocurrencia',
            'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',            
            'ShownAsPer':'Se muestra según',
            'DateRange':'to',
            'DiagnosisGroup': 'Grupo de diagnóstico',
            'Actionable': 'Accionables',
            'Chronic': 'Crónico',
            'Infectious': 'Infeccioso',
            'Trauma/Accidents': 'Accidentes /Trauma',
            'Cancer': 'Cáncer',
            'Mental Health': 'Salud Mental',
            'Obstetrics': 'Obstétricos',
            'Others': 'Otros',
            'Preventable': 'Prevenible',
            'Intervenable': 'Intervenible'
        }
    }
};