import { Component, Input, OnChanges, OnInit } from "@angular/core";

@Component({
    selector: "question-field",
    templateUrl: "./question-field.component.html",
    styleUrls: ["./question-field.component.scss"],
})
export class QuestionFieldComponent implements OnInit, OnChanges {

    @Input() data: any = [];
    @Input() booleanresponse: any = false;
    @Input() numberresponse: any = 0;
    @Input() dateFormattedValue: any = 0;
    @Input() selectedOptionNumber: any = 1;
    @Input() numberFormattedValue: any = "75%";
    isBooleanField: boolean = false;
    isNumberField: boolean = false;
    isDateField: boolean = false;
    isOptionsField: boolean = false;
    @Input() options: any[] = [];
    constructor() { }

    ngOnInit() {
        this.updateData();
    }

    ngOnChanges() {
        this.updateData();
    }

    updateData() {

        if (this.data.questionAttributeType_key == 1) {
            this.isBooleanField = true;
            this.isNumberField = false;
            this.booleanresponse = Math.floor(Math.random() * (50 - 80 + 1) + 80) > 55 ? true : false;
        } else if (
            this.data.questionAttributeType_key == 2
            || this.data.questionAttributeType_key == 3
            || this.data.questionAttributeType_key == 5
        ) {
            this.isBooleanField = false;
            this.isNumberField = true;
            this.numberFormattedValue = Math.floor(Math.random() * (50 - 80 + 1) + 80) + "%";
        } else if (this.data.questionAttributeType_key == 4) {
            this.isBooleanField = false;
            this.isNumberField = false;
            this.isDateField = true;
            this.dateFormattedValue = (new Date(2018, 12, 31));
        } else if (this.data.questionAttributeType_key == 6) {
            this.isBooleanField = false;
            this.isNumberField = false;
            this.isDateField = false;
            this.isOptionsField = true;
            this.selectedOptionNumber = 1;
            this.options = [{ name: "Less than 100 members", key: 1 }, { name: "Above 100 members", key: 2 }];
        }
    }
}
