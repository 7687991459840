import { Component, OnInit, Input, OnChanges, ElementRef, HostListener } from "@angular/core";
import { COLOR_PALETTE } from 'app/constants/color-palette';
import * as d3 from "d3";

@Component({
    selector: "app-donut",
    templateUrl: "./donut.component.html",
    styleUrls: ["./donut.component.css"]
})
export class DonutComponent implements OnInit, OnChanges {
    @Input() datalist: any = [];
    @Input() param: string;
    @Input() propertyID: any;
    @Input() elementRefParent: any;
    @Input() totalPercent: any;
    @Input() suffixSymbolForTotal: string = "%";
    @Input() suffixSymbolForLabel: string = "%";
    @Input() hideLabels: boolean = false;
    @Input() maxlabelLength: number = 10;
    @Input() donutWidth: number = 450;
    @Input() donutHeight: number = 350;
    @Input() isRoundOffdonutValueEnabled: boolean = true;
    g: any;
    arc: any;
    _current: any = {};
    pie: any;
    color: any;
    donutData: any = [];
    shrink: any;
    radius: any;
    labelr: any;
    svgLeg: any;
    Propertychk: any;

    // Label info
    labelWidth:number;
    labelHeight: number;

    constructor(private elementRef: ElementRef) { }

    ngOnChanges() {
        if (this.g) {
            this.setHeightWidth();
            this.render();
        }
    }

    setHeightWidth() {
        this.donutHeight = (this.elementRef.nativeElement.parentElement.offsetHeight - 10);
        this.donutWidth = (this.elementRef.nativeElement.parentElement.offsetWidth - 10);
    }

    @HostListener('window:resize')
    ngOnInit() {
        this.setHeightWidth();
        d3.select(this.elementRef.nativeElement)
            .select("#donut")
            .select("svg")
            .remove();
        this.drawDonut();
    }

    drawDonut() {
        this.Propertychk = this.propertyID;
        const width = this.donutWidth, height = this.donutHeight; this.color = d3
            .scaleOrdinal()
            .range(COLOR_PALETTE);
        var svg = d3
            .select(this.elementRef.nativeElement)
            .select("#donut")
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .classed("donut__svg", true);

        this.shrink = 50;
        this.radius = Math.min(width, height) / 2 - this.shrink;
        // Client width & height doesnt work here, so use offset width & height instead, and give .2 padding
        this.labelWidth = (this.elementRef.nativeElement.parentElement.offsetWidth /2 - this.radius - 20) * .8;
        this.labelHeight = (this.elementRef.nativeElement.parentElement.offsetHeight /2 - this.radius) * .8;
        this.labelr = this.radius + 15;
        this.pie = d3
            .pie()
            .value(function (d) {
                return Math.abs(d.value);
            })
            .sort(null);
        this.arc = d3
            .arc()
            .outerRadius(this.radius)
            .innerRadius(this.radius * 0.7);
        this.g = svg.append("g");
        this.g = svg
            .append("g")
            .attr(
                "transform",
                "translate(" + ((width / 2)) + "," + height / 2 + ")"
            );
        var data = this.formatdonut(this.datalist[this.param]);
        // enter data and draw pie chart
        var path = this.g
            .datum(data)
            .selectAll("path")
            .data(this.pie)
            .enter()
            .append("path")
            .attr("class", "piechart")
            .attr("fill", (d, i) => {
                return this.color(i);
            })
            .attr("d", this.arc)
            .each(function (d) {
                this._current = d;
            });
        this.innerAndOuterLabels(data);
        this.legendBind(data);
    }
    formatdonut(data: any) {
        this.donutData = [];
        if (!data) return;
        for (var key in data) {
            if (!(data[key] == null)) {
                let val = Math.round(data[key] * 10) / 10;
                if (this.isRoundOffdonutValueEnabled == true) {
                    if (Math.abs(val) > 0) {
                        this.donutData.push({
                            name: key,
                            //new TitleCasePipe().transform(key),
                            value: val
                        });
                    }
                } else {
                    this.donutData.push({
                        name: key,
                        value: val
                    });
                }
            }
        }
        return this.donutData;
    }
    render() {
        if (typeof this.datalist[this.param] === 'undefined') {
            return;
        }

        var data = this.formatdonut(this.datalist[this.param]);
        // add transition to new path
        this.g
            .datum(data)
            .selectAll("path")
            .data(this.pie)
            .transition()
            .duration(600)
            .attrTween("d", a => {
                var i = d3.interpolate(this._current[a.index], a);
                this._current[a.index] = a;
                return t => {
                    return this.arc(i(t));
                };
            });
        // add any new paths
        this.g
            .datum(data)
            .selectAll("path")
            .data(this.pie)
            .enter()
            .append("path")
            .attr("class", "piechart")
            .attr("fill", (d, i) => {
                return this.color(i);
            })
            .attr("d", this.arc)
            .each(function (d) {
                this._current = d;
            });

        // remove data not being used
        this.g
            .datum(data)
            .selectAll("path")
            .data(this.pie)
            .exit()
            .remove();
        this.innerAndOuterLabels(data);
        this.legendBind(data);
    }

    innerAndOuterLabels(data: any[]) {
        this.g.selectAll(".arc").remove();
        var label = this.g
            .selectAll(".arc")
            .data(this.pie(data))
            .enter()
            .append("g")
            .attr("class", "arc");

        label
            .append("foreignObject")
            .attr("width", this.labelWidth)
            .attr("height", this.labelHeight)
            .attr("transform", d => {
                var center = this.arc.centroid(d);
                // 0.3 space in between the start of text and outer ring of donut
                var xCoord = center[0] * 1.3;
                var yCoord = center[1] * 1.3;
                // By default foreignObject uses top left corner
                var donutLeftSide = (d.endAngle + d.startAngle) / 2 > Math.PI;
                // Decide whether which corner of foreignObject's rectangle to use
                var xCorner = donutLeftSide ? xCoord - (this.labelWidth) : xCoord;
                var yCorner = donutLeftSide ? yCoord - (this.labelHeight / 2) : yCoord;
                return ("translate(" + xCorner + "," + yCorner + ")");
            })
            // Need to let d3 konw p tag is a html p and not element in svg namepsace. Refer:
            // https://stackoverflow.com/questions/13848039/svg-foreignobject-contents-do-not-display-unless-plain-text
            .append("xhtml:p")
            .attr("style", "word-break: break-word")
            .append("text")
            .attr("text-anchor", function (d) {
                return (d.endAngle + d.startAngle) / 2 > Math.PI
                    ? "end"
                    : "start";
            })
            .attr("class", this.propertyID + "labelDonut")
            .transition()
            .duration(600)
            .text(d => {
                var displayText = d.data.value + this.suffixSymbolForLabel + ' ' + d.data.name;
                if (d.endAngle - d.startAngle < (20 * Math.PI) / 180) {
                    return "";
                }
                return displayText;
            });

        label
            .append("text")
            .attr("class", "donut-totalPercent" + this.propertyID)
            .attr("text-anchor", "middle")
            .attr("font-size", "2.5em")
            .text(this.totalPercent + " " + this.suffixSymbolForTotal)
            .attr("line-height", "1")
            .attr("font-weight", "bold")
            .attr("dy", ".5em")
            .attr("fill", "#101E7F");
    }

    legendBind(data: any[]) {
        var legendData = data.map(d => {
            if (Math.abs(d.value) > 0) return d.name;
        });
        var widthL = 200,
            heightL = 200;
        d3.select(this.elementRefParent?.nativeElement || this.elementRef.nativeElement.parentElement)
            .select('#legends')
            .select('svg')
            .remove();

        let legend = d3.select(this.elementRefParent?.nativeElement || this.elementRef.nativeElement.parentElement)
            .select('#legends')
            .append("svg")
            .attr("width", widthL)
            .attr("height", heightL)
            .selectAll("g")
            .data(legendData)
            .enter()
            .append("g")
            .attr("transform", function (d, i) {
                return "translate(50," + i * 30 + ")";
            });

        legend
            .append("rect")
            .attr("width", 18)
            .attr("height", 18)
            .style("fill", (d, i) => this.color(i));

        legend
            .append("text")
            .attr("x", 24)
            .attr("y", 9)
            .attr("dy", ".35em")
            .attr("white-space", "nowrap")
            .attr("text-overflow", "ellipsis")
            .transition()
            .duration(600)
            //css for ellpsis not working  here
            .text((d, i) => {
                if (d.length > 20) {
                    return d.slice(0, 19) + "...";
                } else return d;
            });
    }
}
