<div
    id="distriDashboard"
    style="min-height: 795px; padding: 8px; overflow: auto; position: relative;"
    fxLayout="row"
>
    <div
        fxFlex
        fxLayout="column"
    >

        <div
            id="distriHeader"
            class="dash-header"
        >
            <span id="distriHeaderTxt">{{'DistributionByClaimType.ClaimTypeBy' | translate }}
                {{properties.secondaryDimension}}</span>
            <button
                id="distriHeaderBtn"
                mat-icon-button
                style="float: right"
                (click)="showFilter = !showFilter"
                *ngIf="!isFilterOptionsLoading"
            >
                <app-filter-button
                    [isClientName]='true'
                    [isEntity]='true'
                    [isPolicyType]='true'
                    [isPlan]='true'
                    [isClaimType]='true'
                    [isClaimType2]='true'
                    [isClaimType3]='true'
                    [isProviderType]='true'
                    [isGender]='true'
                    [isRelationship]='true'
                    [isAgeBand]='true'
                    [isLocation]='true'
                    [isLOB]='true'
                    [isSubLOB]='true'
                ></app-filter-button>
            </button>
        </div>

        <div fxLayout="row">
            <div
                id="distriHeaderCurrency"
                style="font-size: 14px; font-style: italic; padding-top: 10px;"
                [fxFlex]="90"
            >
                <span>{{'DistributionByClaimType.AllAmountsDisplayedInLocalCurrency' | translate }}</span>
            </div>
        </div>
        <div
            id="distriTable"
            fxLayout="row"
            class="tableheader"
            style="margin-top:1%;border-bottom:dashed 1px #72727252;padding-bottom: 10px;"
        >
            <div
                id="distriTableHeader"
                fxFlex="40%"
                fxLayoutAlign="center center"
            >
                <div [fxFlex]></div>
                <div
                    [fxFlex]
                    *ngIf="properties.secondaryDimension !== GenderRelOptions.AgeBand"
                >{{'DistributionByClaimType.TotalPaidClaim' | translate }} </div>
                <div [fxFlex]>{{'DistributionByClaimType.ClaimCount' | translate }} </div>
                <div [fxFlex]>{{'DistributionByClaimType.AverageCost' | translate }} </div>
            </div>
            <div
                fxFlex="60%"
                fxLayoutAlign="center center"
            >
                <div
                    [fxFlex]
                    *ngFor="let sd of secondDimensions "
                >
                    <div fxFlex>{{ sd }}</div>
                </div>

            </div>
        </div>

        <div fxLayout="row">
            <div
                id="distriTableCurrent"
                [fxFlex]="100"
                style="font-size: 15px;"
            >
                <div
                    fxLayout="row"
                    class="tablerowCurrent "
                    *ngFor="let list of dataList; index as i;"
                    fxLayoutAlign="center center"
                >
                    <div
                        fxFlex="40%"
                        fxLayoutAlign="center center"
                    >
                        <div
                            [fxFlex]
                            *ngIf="list.isCurrent"
                            class="dotedRight"
                            style="margin-top:8.5%; font-size: 20px;"
                        >{{list.dimensionName }} </div>
                        <div
                            [fxFlex]
                            *ngIf="!list.isCurrent"
                            class="dotedRight"
                            style="font-size: 20px;"
                        > </div>
                        <div
                            [fxFlex]
                            class="dotedRight"
                            [ngClass]="list.isCurrent?'purpleClass':'blueClass'"
                            *ngIf="properties.secondaryDimension !== GenderRelOptions.AgeBand"
                        >
                            {{list.claimsTotal | roundToDecimalPlace: 0 | numberFormat}} </div>
                        <div
                            [fxFlex]
                            class="dotedRight"
                            [ngClass]="list.isCurrent?'purpleClass':'blueClass'"
                        >
                            {{list.claimsCount | roundToDecimalPlace: 0 | numberFormat}} </div>
                        <div
                            [fxFlex]
                            class="dotedRight"
                            [ngClass]="list.isCurrent?'purpleClass':'blueClass'"
                        >
                            {{list.averageCostPerClaim | roundToDecimalPlace: (properties.country === 'Chile' ? 2 : 0) |
                            numberFormat}} </div>
                    </div>
                    <div
                        fxFlex="60%"
                        fxLayoutAlign="center center"
                        style="display:inline!important;"
                    >
                        <div
                            class="dotedRight"
                            [fxFlex]
                            style=" flex-direction: column;"
                            [ngClass]="list.isCurrent?'purpleClass':'blueClass'"
                            *ngFor="let xo2 of list.secondaryDimensions; index as si; "
                        >
                            <span
                                *ngIf="(list.dimensionName===dataList[ i  + ( list.isCurrent ? 1  : -1)].dimensionName) && !(xo2.percentageShare === 0  && dataList[ i  + ( list.isCurrent ? 1  : -1)].secondaryDimensions[si].percentageShare === 0 )"
                            >{{xo2.percentageShare.toFixed(1)}} %</span>
                            <div
                                [fxFlex]
                                style="text-align: center; font-size: 12px;"
                                *ngIf="!(xo2.percentageChange === 0)"
                            >
                                {{xo2.percentageChange.toFixed(1)}} %
                                <mat-icon
                                    style="vertical-align: sub;"
                                    [ngClass]="list.isCurrent?'purpleClass':'blueClass'"
                                    *ngIf="xo2.percentageChange>0"
                                >arrow_drop_up</mat-icon>
                                <mat-icon
                                    style="vertical-align: sub;"
                                    [ngClass]="list.isCurrent?'purpleClass':'blueClass'"
                                    *ngIf="xo2.percentageChange<0"
                                >arrow_drop_down</mat-icon>
                            </div>
                            <div
                                style="text-align: center; "
                                *ngIf="(xo2.percentageChange === 0)"
                            >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            fxLayout="row"
            style="margin-top: 10px"
        >
            <div [fxFlex]="80"></div>
            <div
                id="distriFooterNote"
                [fxFlex]="20"
                style="text-align: right; margin-right: 10px;"
            >
                {{properties.claimPaid === CLAIM_BASIS.PAID_BASIS ? ( 'DistributionByClaimType.ClaimsReportedByPaidDate'
                | translate ) : (
                'DistributionByClaimType.ClaimsReportedByIncurredDate' | translate )}}
            </div>
        </div>

    </div>
    <div
        style="width: 200px; padding: 15px 0px 0px 16px; border-left: 1px solid #EEE"
        fxLayout="column"
    >
        <div
            id="entityDivisionFilters"
            *ngIf="showFilter"
        >
            <app-additional-filters
                [country]="properties.country"
                (action)="additionalFilterSelectionChanged($event)"
                [filterOptions]='filterOptions'
                [isClientName]='true'
                [isEntity]='true'
                [isPolicyType]='true'
                [isPlan]='true'
                [isClaimType]='true'
                [isClaimType2]='true'
                [isClaimType3]='true'
                [isProviderType]='true'
                [isGender]='true'
                [isRelationship]='true'
                [isAgeBand]='true'
                [isLocation]='true'
                [isLOB]='true'
                [isSubLOB]='true'
            ></app-additional-filters>
        </div>
        <div
            id="distriLegends"
            fxFlex="55"
            *ngIf="!showFilter"
        >
            <div
                fxLayout="row"
                *ngFor="let leg of legendList"
                style="margin-bottom: 10px; margin-top:15%;"
            >
                <svg width="15" height="15">
                    <rect width="15" height="15"[attr.fill]="leg.color"></rect> 
                </svg>
                <span style="margin-left:5px;">{{(leg.name)}}</span> <br>
            </div>
            <div style="margin-top: 20%;">
                <div
                    id="distriDimensions"
                    fxLayout="row"
                    style="margin-top:15px; margin-bottom: 5px;"
                >
                    <span>{{'DistributionByClaimType.Dimensions' | translate}}</span>
                </div>
                <div
                    id="distriBtnAgeBand"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{'dash-option-selected': properties.secondaryDimension == GenderRelOptions.AgeBand}"
                    (click)="selectionChanged(GenderRelOptions.AgeBand, 'secondaryDimension')"
                >
                    <span>{{'DistributionByClaimType.AgeBand' | translate}}</span>
                </div>

                <div
                    id="distriBtnGender"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{'dash-option-selected': properties.secondaryDimension == GenderRelOptions.Gender}"
                    (click)="selectionChanged(GenderRelOptions.Gender, 'secondaryDimension')"
                >
                    <span>{{'DistributionByClaimType.Gender' | translate}}</span>
                </div>
                <div
                    id="distriBtnRelationship"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="dash-option"
                    [ngClass]="{'dash-option-selected': properties.secondaryDimension == GenderRelOptions.Relationship}"
                    (click)="selectionChanged(GenderRelOptions.Relationship, 'secondaryDimension')"
                >
                    <span>{{'DistributionByClaimType.Relationship' | translate}}</span>
                </div>
            </div>
        </div>
    </div>
</div>