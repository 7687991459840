export const locale = {
  lang: 'es-pe',
  data: {
    'ClaimCostTopProvider': {
      'ClaimTopProviders': 'Costo de atenciones - Top proveedores',        
      'ClaimCostByTopDiagnoses':'Costo de reclamos - por los principales diagnósticos',
      'ProviderDiag': 'Proveedor v diagnóstico',
      'AllAmountsDisplayedInLocalCurrency': 'Moneda local',
      'TopProvider': 'Top Proveedors',
      'ProviderName': 'Nombre del Proveedor',
      'TopDiagnoses': 'Top Diagnósticos',
      'DiagnosisName': 'Nombre del diagnóstico',
      'TotalPaidClaim': 'Pago total de siniestro',
      'ClaimantCount': 'Cuenta de paciente',
      'ClaimCount': 'Número de atenciones',
      'ClaimsPerClaimant': 'Siniestros por paciente',
      'CostPerClaim': 'Costo por atención',
      'CostPerClaimant': 'Costo por paciente',
      '%ChangeinCost': '% Variación del costo',
      'Claim': 'Atención',
      'Claimant': 'Paciente',
      'ClaimsTotal': 'Total de siniestros',
      'Count': 'Contar',
      'AverageCost': 'Costo promedio',
      'ClaimsReportedByPaidDate': 'Siniestros informados por fecha de ocurrencia',
      'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',
      'Benchmark': 'Benchmark',
      'IncludeClient': 'Incluir cliente',
      'ExcludeClient': 'Excluir cliente',
      'Portfolio': 'Portafolio',
      'Industry': 'Industria',
      'None': 'Ninguno',
      'SelectTop': 'Seleccionar superior',
      'FilterNA': 'Excluir NA',
      'CountBasis': 'Contar Base',
      'Sort': 'Ordenar',
      'Reset': 'Reiniciar',
      'DiagnosisLevel': 'Nivel de diagnóstico',
      'Category': 'Categoría',
      'Chapter': 'Agrupador',
      'ShownAsPer':'Se muestra según',
      'DateRange':'to'

    }
  }
};
