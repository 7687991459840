import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation } from 'app/navigation/navigation';
import { rmnavigation } from 'app/navigation/rmnavigation';
import { cmnavigation } from 'app/navigation/cmnavigation';
import { adminnavigation } from 'app/navigation/adminnavigation';

import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationSpanishCL } from 'app/navigation/i18n/es-cl';
import { locale as navigationSpanishCO } from 'app/navigation/i18n/es-co';
import { locale as navigationSpanishEC } from 'app/navigation/i18n/es-ec';
import { locale as navigationSpanishMX } from 'app/navigation/i18n/es-mx';
import { locale as navigationSpanishPE } from 'app/navigation/i18n/es-pe';
import { locale as navigationPortugueseBR } from 'app/navigation/i18n/pt-br';
import { locale as navigationItalianIT } from 'app/navigation/i18n/it';
import { UserService } from './services/user.service';
import { StoryService } from './services/story.service';
import { UserIdleService } from 'angular-user-idle';
import { ToasterService, ToasterServiceModes } from './services/toaster.service';
import { HttpstatusService } from './services/httpstatus.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Title } from '@angular/platform-browser';
import { SignalRService } from './services/signal-r.service';
import { GlobalFilterService } from 'app/wellone/services/globalfilter.service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;

    // Private
    private profile = environment.profile;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _userService: UserService,
        private storyService: StoryService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _userIdleService: UserIdleService,
        private _toasterService: ToasterService,
        private _httpStatusService: HttpstatusService,
        private _titleService: Title,
        private _signalRService: SignalRService,
        public router: Router,
        private globalFilterService: GlobalFilterService,
    ) {
        this._signalRService.startConnection();

        this._titleService.setTitle('Aon ' + this.profile.project.title);

        this._fuseNavigationService.register('usernavigation', navigation);
        this._fuseNavigationService.register('rmnavigation', rmnavigation);
        this._fuseNavigationService.register('cmnavigation', cmnavigation);
        this._fuseNavigationService.register('adminnavigation', adminnavigation);

        // TODO: Whether to move to ngOnInit
        if (this._userService.isUserLoggedIn()) {

            this._userService.setUserSitePermissions();

            this.storyService.getAssignedUserStories().toPromise().then(data => {
                var result = data;
                this.storyService.updateStoryNavigation(result);
            });
        }

        // Add languages
        this._translateService.addLangs([
            'en',
            'es-cl',
            'es-co',
            'es-ec',
            'es-mx',
            'es-pe',
            'pt-br',
            'it',
        ]);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(
            navigationEnglish,
            navigationSpanishCL,
            navigationSpanishCO,
            navigationSpanishEC,
            navigationSpanishMX,
            navigationSpanishPE,
            navigationPortugueseBR,
            navigationItalianIT);

        // Use a language
        this._translateService.use('en');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = this.getLayoutConfig(config);
                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                }
                else {
                    config
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });

        this._userIdleService.startWatching();

        this._userIdleService.ping$.subscribe(() => {
            // console.log('Ping');
        });

        this._userIdleService.onTimerStart().subscribe((count) => {
            console.log('Timer start');
        });

        this._httpStatusService.getHttpErrorResponseEmitter().subscribe((httpError: HttpErrorResponse) => {
            this._toasterService.showMessage(httpError.status + ' - ' + httpError.message, ToasterServiceModes.NOK);
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    getLayoutConfig(config: any) {
        if (location.pathname !== '/') {
            config.layout.style = 'aon-new-layout'
            config.layout.navbar.hidden = false;
            config.layout.footer.hidden = false;
            config.layout.toolbar.hidden = false;
            config.layout.sidepanel.hidden = false;
        }
        this.globalFilterService.showFilter(false);



        return config;
    }
}
