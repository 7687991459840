import { FuseNavigation } from '@fuse/types';

export const cmnavigation: FuseNavigation[] = [
    {
        id: 'stories',
        title: 'STORIES',
        type: 'group',
        children: [],
        translate: 'Nav.Stories'
    },
    {
        id: 'exportqueue',
        title: 'Export Queue',
        type: 'group',
        icon: 'send',
        translate: 'Nav.Export.Main',
        children: [
            {
                id: 'exportcart',
                title: 'Cart',
                type: 'item',
                icon: 'add_shopping_cart',
                url: 'export/cart',
                translate: 'Nav.Export.Cart'
            },
            {
                id: 'exportqueue',
                title: 'Queue',
                type: 'item',
                icon: 'list',
                url: 'export/queue',
                translate: 'Nav.Export.Queue'
            }
        ]
    },
    {
        id: 'admingrp',
        title: 'ADMIN',
        type: 'group',
        icon: 'settings',
        translate: 'Nav.Admin.Main',
        children: [
            {
                id: 'storiesadmin',
                title: 'Stories',
                type: 'item',
                icon: 'layers',
                url: 'admin/stories',
                translate: 'Nav.Admin.Stories'
            },
            {
                id: 'useradmin',
                title: 'Users',
                type: 'item',
                icon: 'people',
                url: 'admin/user',
                translate: 'Nav.Admin.Users'
            },
            {
                id: 'clientgroupadmin',
                title: 'Client Group',
                type: 'item',
                icon: 'group_work',
                url: 'admin/clientgroupadmin',
                translate: 'Nav.Admin.ClientGroup'
            }
        ]
    }
];