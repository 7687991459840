<div class="base-dialog">
  <!-- Header -->
  <header class="base-dialog__header">
    <aon-h3 class="base-dialog__title" fxFlex fxLayoutAlign="start center">
      {{ title }}
    </aon-h3>
    <ng-content select="[base-dialog-custom-close-icon]"></ng-content>
    <div *ngIf="!hideCloseIcon" fxLayoutAlign="end center">
      <button class="icon-button" mat-dialog-close>
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </button>
    </div>
  </header>

  <!-- Content -->
  <main class="base-dialog__content">
    <ng-content></ng-content>
  </main>

  <!-- Footer -->
  <footer *ngIf="!hideFooter" class="base-dialog__footer" fxLayoutAlign="end center" fxLayoutGap="8px">
    <ng-content select="[base-dialog-action]"></ng-content>
    <aon-button-subtle *ngIf="!hideCloseBtn" mat-dialog-close>
      {{ closeLabel }}
    </aon-button-subtle>
  </footer>
</div>