<div style="height: 100%">
    <svg width="100%" height="100%" viewBox="0 0 40 40" class="donut">
        <circle
            class="donut-hole"
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="#fff"
        ></circle>
        <circle
            class="donut-ring"
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="transparent"
            stroke-width="7.5"
        ></circle>
        <circle
            class="donut-segment"
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="transparent"
            stroke="#101E7F"
            stroke-width="7.5"
            [attr.stroke-dasharray]="getSVGDashArray()"
            stroke-dashoffset="25"
        >
            <animate
                attributeName="stroke-dasharray"
                begin="0s"
                dur="1s"
                from="0 100"
                [attr.to]="getSVGDashArray()"
            />
        </circle>
        <g class="donut-text">
            <text y="50%" transform="translate(0, 1)">
                <tspan
                    x="50%"
                    text-anchor="middle"
                    class="donut-percent"
                    [attr.fill]="numberFill"
                >
                    {{ percentage }}%
                </tspan>
            </text>
            <!-- <text y="60%" transform="translate(0, 2)">
              <tspan x="50%" text-anchor="middle" class="donut-data">3450 widgets</tspan>
            </text> -->
        </g>
    </svg>
</div>
