export const locale = {
    lang: 'es-cl',
    data: {
        'AvgCostUtil': {
            'AverageCostImpact': 'Impacto en el monto reembolsado',
            'UtilizationImpact': 'Impacto por Frecuencia',
            'AllAmountsDisplayedInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'OverallTrend': 'Tendencia Total',
            'AverageCost': 'Costo Promedio',
            'Utilization': 'Utilización',
            'FreqOfUse': 'Frecuencia de Uso',
            'ServicesReceived': 'Servicios recibidos',
            'KeyImpacts': 'Impactos Clave',
            'AvgCostPerClaim': 'Reembolso por prestación',
            'Prior': 'Anterior',
            'Current': 'Actual',
            'YoYChange': 'Cambio Año a Año',
            'Benchmark': 'Benchmark',
            'ClaimType': 'Prestación',
            'Network': 'Prestadores',
            'ProviderType': 'Tipo Prestador',
            'Relationship': 'Parentesco',
            'CostPerClaim': 'Reembolso por prestación',
            'CostPerClaimant': 'Reembolso por asegurado',
            'ClaimsPerClaimant': 'Prestaciones por asegurado',
            'UtilizationRatio': 'Porcentaje de Utilización',
            'ClaimsReportedByPaidDate': 'Reembolsos reportados por fecha de pago',
            'ClaimsReportedByIncurredDate': 'Reembolsos reportadas por fecha incurrida',
            'IncludeClient': 'Incluir Cliente',
            'ExcludeClient': 'Excluir Cliente',
            'Portfolio': 'Portafolio',
            'Industry': 'Industria',
            'None': 'No',
            'Chart': 'Gráfico',
            'Table': 'Tabla',
            'ShowLegend': 'Mostrar leyenda',
            'DateRange': 'to'
        }
    }
};
