<div class="columnDiv contentPadding" fxLayout="column" *ngIf="!isLoading">
    <div fxLayout="row">
        <div class="flex-column contentPadding" style="width: 75%">
            <mat-card id="barplot" *ngIf="attrNameArr.length > 0">
            <table class='grid' style="width: 100%">
                <thead>
                    <tr><aon-h3>Flex Chart</aon-h3></tr>
                </thead>
                <tbody>
                    <div *ngFor="let item of displayTableDataArray; let h = index" style="width: 100%;" [attr.data-index]="h">
                        
                        <span *ngIf="h==0 && (h < numRow || numRow == null)">
                        <tr>
                                <td style="width: 25%; border-top: 1px solid var(--app-color-smoke); padding-right: 10px;" (click)="sortTable(0)">
                                    <div style="text-align: top;" class="cellPaddings"><aon-input-title>{{attrNameArr[0]}}
                                        <fa-icon style="margin-left: 15px;" [icon]="['fal', 'sort-amount-up-alt']" *ngIf="sortArr[0] && showSortArr[0]"></fa-icon>
                                        <fa-icon style="margin-left: 15px;" [icon]="['fal', 'sort-amount-down-alt']" *ngIf="!sortArr[0] && showSortArr[0]"></fa-icon>
                                    </aon-input-title></div>
                                </td>
                                <td style="width: 25%; border-top: 1px solid var(--app-color-smoke); padding-right: 10px;" *ngIf="attrNameArr.length > 1" (click)="sortTable(1)">
                                    <div style="text-align: top;" class="cellPaddings"><aon-input-title>{{attrNameArr[1]}} 
                                        <fa-icon style="margin-left: 15px;" [icon]="['fal', 'sort-amount-up-alt']" *ngIf="sortArr[1] && showSortArr[1]"></fa-icon>
                                        <fa-icon style="margin-left: 15px;" [icon]="['fal', 'sort-amount-down-alt']" *ngIf="!sortArr[1] && showSortArr[1]"></fa-icon>
                                    </aon-input-title></div>
                                </td>
                                <td style="width: 700px; border-top: 1px solid var(--app-color-smoke);" (click)="sortTable(2)">
                                    <aon-input-title>{{attrName3}} 
                                        <fa-icon style="margin-left: 15px;" [icon]="['fal', 'sort-amount-up-alt']" *ngIf="sortArr[2] && showSortArr[2]"></fa-icon>
                                        <fa-icon style="margin-left: 15px;" [icon]="['fal', 'sort-amount-down-alt']" *ngIf="!sortArr[2] && showSortArr[2]"></fa-icon>
                                    </aon-input-title>
                                </td>
                        </tr>
                        </span>
                        <span *ngIf="h < numRow || numRow == null">
                        <tr *ngFor="let value of item[1] ; let i = index" [attr.data-index]="i">
                                <td style="width: 25%;  border-top: 1px solid var(--app-color-smoke);" *ngIf="i==0">
                                    <div style="text-align: start;"><span class="spanText2">{{item[0]}}</span></div>
                                </td>
                                <td style="width: 25%;" *ngIf="i>0">
                                    <div style="text-align: start;"><span class="spanText2"></span></div>
                                </td>
                                <td style="width: 25%; border-top: 1px solid var(--app-color-smoke); padding-right: 10px;" *ngIf="attrNameArr.length > 1 && i ==0">
                                    <div style="text-align: top;" class="cellPaddings" *ngIf="displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_1'"><span class="spanText">{{value.flex1}}</span></div>
                                    <div style="text-align: top;" class="cellPaddings" *ngIf="displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_2'"><span class="spanText">{{value.flex2}}</span></div>
                                    <div style="text-align: top;" class="cellPaddings" *ngIf="displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_3'"><span class="spanText">{{value.flex3}}</span></div>
                                    <div style="text-align: top;" class="cellPaddings" *ngIf="displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_4'"><span class="spanText">{{value.flex4}}</span></div>
                                    <div style="text-align: top;" class="cellPaddings" *ngIf="displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_5'"><span class="spanText">{{value.flex5}}</span></div>
                                    <div style="text-align: top;" class="cellPaddings" *ngIf="displayNameToFlexIndex(this.attrNameArr[1]) == 'age'"><span class="spanText">{{value.ageBand}}</span></div>
                                    <div style="text-align: top;" class="cellPaddings" *ngIf="displayNameToFlexIndex(this.attrNameArr[1]) == 'country'"><span class="spanText">{{value.countryName}}</span></div>
                                </td>
                                <td style="width: 25%; padding-right: 10px;" *ngIf="attrNameArr.length > 1 && i > 0">
                                    <div style="text-align: top;" class="cellPaddings" *ngIf="displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_1'"><span class="spanText">{{value.flex1}}</span></div>
                                    <div style="text-align: top;" class="cellPaddings" *ngIf="displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_2'"><span class="spanText">{{value.flex2}}</span></div>
                                    <div style="text-align: top;" class="cellPaddings" *ngIf="displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_3'"><span class="spanText">{{value.flex3}}</span></div>
                                    <div style="text-align: top;" class="cellPaddings" *ngIf="displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_4'"><span class="spanText">{{value.flex4}}</span></div>
                                    <div style="text-align: top;" class="cellPaddings" *ngIf="displayNameToFlexIndex(this.attrNameArr[1]) == 'wellone_flex_5'"><span class="spanText">{{value.flex5}}</span></div>
                                    <div style="text-align: top;" class="cellPaddings" *ngIf="displayNameToFlexIndex(this.attrNameArr[1]) == 'age'"><span class="spanText">{{value.ageBand}}</span></div>
                                    <div style="text-align: top;" class="cellPaddings" *ngIf="displayNameToFlexIndex(this.attrNameArr[1]) == 'country'"><span class="spanText">{{value.countryName}}</span></div>
                                </td>
                                <td style="width: 700px; border-top: 1px solid var(--app-color-smoke);"  *ngIf="i ==0">
                                    <div style="text-align: top;">
                                        <aon-percentage-bar-chart
                                            width='100%'
                                            yspacing='2'
                                            [firstpercentagelabel]="toInt(value.metric, value.userCount)"
                                            [firstpercentagevalue]="toInt(value.metric, value.userCount)/modifier">
                                        </aon-percentage-bar-chart>
                                    </div>
                                </td>
                                <td style="width: 700px"  *ngIf="i>0">
                                    <div style="text-align: top;">
                                        <aon-percentage-bar-chart
                                        width='100%'
                                        yspacing='2'
                                        [firstpercentagelabel]="toInt(value.metric, value.userCount)"
                                        [firstpercentagevalue]="toInt(value.metric, value.userCount)/modifier">
                                        >
                                        </aon-percentage-bar-chart>
                                    </div>
                                </td>
                            </tr>
                        </span>
                    </div>
                </tbody>
            </table>
            </mat-card>
        </div>
        <div class="flex-column contentPadding" style="width: 25%">
            <mat-card id="barplot">
            <div class="flex-column">
                <aon-input-title>Detail Level 1 *</aon-input-title>
                <aon-select style="width: 300px !important;  margin-top: 10px;" scrollable="true"
                    [selectedvalue]="attrName1" (onselectionchange)="onAttrName1Change($event)">
                    <aon-select-option *ngFor="let detail of detailLevel1" [value]="detail">{{detail}}
                    </aon-select-option>
                </aon-select>
                <!-- <mat-form-field>
                    Detail Level 1
                    <mat-select [value]="attrName1" id='attrName1'>
                            <mat-option value='' (click)="onAttrName1Change('')"></mat-option>
                            <mat-option *ngFor = "let p of detailLevel1" [value]="p"  (click)="onAttrName1Change(p)">{{ p }}</mat-option>
                    </mat-select>
                </mat-form-field> -->
            </div>
            
            <br/>
            <div class="flex-column">
                <aon-input-title>Detail Level 2 *</aon-input-title>
                <aon-select style="width: 300px !important;  margin-top: 10px;" scrollable="true"
                    [selectedvalue]="attrName2" (onselectionchange)="onAttrName2Change($event)">
                    <aon-select-option *ngFor="let detail of detailLevel2" [value]="detail">{{detail}}
                    </aon-select-option>
                </aon-select>
                <!-- <mat-form-field>
                    Detail Level 2
                    <mat-select [value]="attrName2" id='attrName2'>
                        <mat-option value='' (click)="onAttrName2Change('')"></mat-option>
                        <mat-option *ngFor = "let p of detailLevel2" [value]="p"  (click)="onAttrName2Change(p)">{{ p }}</mat-option>
                    </mat-select>
                </mat-form-field> -->
            </div>
            
            <br/>
            <div class="flex-column">
                <aon-input-title>Metric Selection *</aon-input-title>
                <aon-select style="width: 300px !important;  margin-top: 10px;" scrollable="true"
                    [selectedvalue]="attrName3" (onselectionchange)="onAttrName3Change($event)">
                    <aon-select-option *ngFor="let detail of detailLevel3" [value]="detail">{{detail}}
                    </aon-select-option>
                </aon-select>
                <!-- <mat-form-field>
                    Metric Selection
                    <mat-select [value]="attrName3" id='attrName3'>
                        <mat-option value='' (click)="onAttrName3Change('')"></mat-option>
                        <mat-option *ngFor = "let p of detailLevel3" [value]="p"  (click)="onAttrName3Change(p)">{{ p }}</mat-option>
                    </mat-select>
                </mat-form-field> -->
            </div>
            <br/>
            <div class="flex-column">
                <!-- <mat-form-field>
                    <input matInput style="font-weight: bold" [(ngModel)]="countryFilter" placeholder="Country Filter"
                        [ngModelOptions]="{standalone: true}" autocomplete="off">
                </mat-form-field> -->
                <div style="margin-bottom: 4px;" class="flex-column">
                    <aon-input-title>{{attrName1}} Filter: </aon-input-title>
                    <div>
                        <aon-textbox [value]='countryFilter' (onchange)='onCountryFilterChange($event)'></aon-textbox>
                         <!-- <input type="text" name="countryFilter" [value]="countryFilter" class="input-number" (input)="onCountryFilterChange($event)"> -->
                    </div>
                </div>
            </div>
            <div class="flex-column">
                <!-- <mat-form-field>
                    <input matInput style="font-weight: bold" [(ngModel)]="countryFilter" placeholder="Country Filter"
                        [ngModelOptions]="{standalone: true}" autocomplete="off">
                </mat-form-field> -->
                <div style="margin-bottom: 4px;" class="flex-column">
                    <aon-input-title>Number of Rows: </aon-input-title>
                    <div>
                        <aon-textbox type='number' [value]='numRow' (onchange)='onNumRowChange($event)' min='0' (keydown)="onKeydown($event)"></aon-textbox>
                         <!-- <input type="text" name="countryFilter" [value]="countryFilter" class="input-number" (input)="onCountryFilterChange($event)"> -->
                    </div>
                </div>
            </div>
            <div class="flex-column">
                <aon-button-default (onclick)='reset($event)'>Reset</aon-button-default>
            </div>
            </mat-card>
        </div>
    </div>
</div>
<div fxLayout="column" class="loadingOverlay" *ngIf="isLoading">
    <aon-loading-spinner [message]='loadingMessage'></aon-loading-spinner>
</div>