import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FlexLayoutModule } from "@angular/flex-layout";
import { RouterModule } from '@angular/router';
import {  FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtInterceptor } from '../_helpers/jwt.interceptor';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'app/shared/shared.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouteAuthGuardGuard } from 'app/_guards/route-auth-guard.guard';
import { Roles as roles } from 'app/models/roles.model';
import { DashboardComponent } from 'app/wellone/components/dashboard/dashboard.component';
import { HealthScoreComponent } from 'app/wellone/components/healthscoredetail/healthscore.component';
import { FinancialScoreComponent } from 'app/wellone/components/financialscoredetail/financialscore.component';
import { DemographicBreakdownComponent } from 'app/wellone/components/financialscoredetail/demographicBreakdown/demographicBreakdown.component';
import { OrganisationalBreakdownComponent } from 'app/wellone/components/financialscoredetail/organisationalBreakdown/organisationalBreakdown.component';
import { DemographicsScoreComponent } from 'app/wellone/components/financialScoreDetail/demographics-score/demographics-score.component';
import { environment } from 'environments/environment';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { StepupchallengeComponent } from './components/stepupchallengedetail/stepupchallenge/stepupchallenge.component';
import { ParticipationPageComponent } from 'app/wellone/components/participation/participation.component';
import { ChallengedetailComponent } from './components/challengedetail/challengedetail.component';
import { ChallengeselectiondialogComponent } from './components/challengedetail/challengeselectiondialog/challengeselectiondialog.component';
import { CachemgmtComponent } from './components/cachemgmt/cachemgmt.component';
import { CatchallComponent } from './components/catchall/catchall.component';

const routes = [

    {
        path: 'new-layout',    
        canActivate: [RouteAuthGuardGuard],
        children: [
          {
            path: 'dashboard', component: DashboardComponent
          },
          {
            path: "participation",
            component: ParticipationPageComponent,
          },
          {
            path: "financial/score",
            component: FinancialScoreComponent,
          },
          {
            path: 'story/catchall',
            component: CatchallComponent,          
          },
          {
            path: 'health/score/detail', component: HealthScoreComponent,
            data:
              { roles: [roles.admin, roles.regionalManager, roles.countryManager] }
          },
          {
            path: 'health/stepup', component: StepupchallengeComponent,
            data:
              { roles: [roles.admin, roles.regionalManager, roles.countryManager] }
          },
          {
            path: 'challenge/detail', component: ChallengedetailComponent,
            data:
              { roles: [roles.admin, roles.regionalManager, roles.countryManager] }
          } 
          ,
          {
            path: 'cache/log', component: CachemgmtComponent,
            data:
              { roles: [roles.admin, roles.regionalManager, roles.countryManager] }
          }  
        ]
      }
    
];

@NgModule({
  declarations: [   
    DashboardComponent,
    HealthScoreComponent,
    FinancialScoreComponent,
    DemographicBreakdownComponent,
    OrganisationalBreakdownComponent,
    DemographicsScoreComponent,
    StepupchallengeComponent,
    ParticipationPageComponent,
    ChallengedetailComponent,
    ChallengeselectiondialogComponent,
    CachemgmtComponent,
    CatchallComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    TranslateModule,
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [RouterModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
    
  ],
  entryComponents: [ChallengeselectiondialogComponent]
})
export class WelloneModule {

  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIconPacks(far);
    library.addIconPacks(fal);
  }
 }
