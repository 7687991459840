import { ColorRankingComponent } from '../shared/color-ranking/color-ranking.component';
import { StarRatingComponent } from '../shared/star-rating/star-rating.component';

export const clientTableColumnDefs = [
    {
        headerName: 'Category',
        field: 'category',
        pinned: 'left',
        hidden: true,
        // minWidth: 400,
        width: 250,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;',
    },
    {
        headerName: 'Sub-Category',
        field: 'name',
        pinned: 'left',
        hidden: true,
        // minWidth: 400,
        width: 250,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;',
    },
    {
        headerName: 'Score',
        field: 'you',
        hidden: true,
        flex: 1,
        width: 110,
        pinned: 'left',
        cellClass: 'grid-cell-left numeral',
    },
    {
        headerName: 'You',
        field: 'value',
        pinned: 'left',
        // hidden: true,
        // minWidth: 400,
        width: 120,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;',
        cellRendererFramework: ColorRankingComponent,


    },
    {
        headerName: '',
        field: 'iserror',
        pinned: 'left',
        // minWidth: 400,
        width: 100,
        cellClass: 'grid-cell-left classification',
        cellRendererFramework: StarRatingComponent,
    },
];

export const globalColumnDefs = [
    {
        headerName: 'Country',
        field: 'name',
        pinned: 'left',
        width: 170,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;',
    },
    {
        headerName: 'Smart Governance',
        field: 'governance',
        pinned: 'left',
        // minWidth: 400,
        width: 195,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;',
        cellRendererFramework: ColorRankingComponent,
    },
    {
        headerName: 'Smart Design',
        field: 'design',
        pinned: 'left',
        // minWidth: 400,
        width: 160,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;',
        cellRendererFramework: ColorRankingComponent,
    },
    {
        headerName: 'Smart Engagement',
        field: 'engagement',
        pinned: 'left',
        hidden: true,
        // minWidth: 400,
        width: 240,
        cellClass: 'grid-cell-left classification',
        style: 'font-family:large;',
        cellRendererFramework: ColorRankingComponent,
    },
];

export const globalDataByRanking = [
    {
        category: 'Smart Design',
        name: 'Argentina',
        value: 1,
        average: 86,
        you: 78,
        design: 3,
        governance: 2,
        engagement: 1,
        iserror: false,
    },
    {
        category: 'Smart Design',
        name: 'Brazil',
        value: 1,
        average: 86,
        you: 78,
        design: 2,
        governance: 1,
        engagement: 2,
        iserror: false,
    },
    {
        category: 'Smart Design',
        name: 'Chile',
        value: 1,
        average: 86,
        you: 78,
        design: 1,
        governance: 2,
        engagement: 2,
        iserror: false,
    },
    {
        category: 'Smart Design',
        name: 'India',
        value: 1,
        average: 86,
        you: 78,
        design: 1,
        governance: 999,
        engagement: 1,
        iserror: false,
    },
    {
        category: 'Smart Design',
        name: 'Singapore',
        value: 1,
        average: 86,
        you: 78,
        design: 1,
        governance: 2,
        engagement: 3,
        iserror: false,
    },
    {
        category: 'Smart Governance',
        name: 'United Kingdom',
        value: 1,
        average: 78,
        you: 65,
        design: 3,
        governance: 2,
        engagement: 3,
        iserror: true,
    },
    {
        category: 'Smart Engagement',
        name: 'USA',
        value: 2,
        average: 98,
        you: 82,
        design: 2,
        governance: 2,
        engagement: 1,
        iserror: true,
    },

];


export const clientTableDataByRating = [
    {
        category: 'Smart Design',
        name: 'Contribution Structure',
        value: 2,
        average: 98,
        you: 82,
        iserror: true,
    },
    {
        category: 'Smart Design',
        name: 'Investment',
        value: 3,
        average: 86,
        you: 96,
        iserror: false,
    },
    {
        category: 'Smart Design',
        name: 'Pension Scheme Design',
        value: 1,
        average: 86,
        you: 78,
        iserror: false,
    },
    {
        category: 'Smart Engagement',
        name: 'Retirement Member Services',
        value: 3,
        average: 80,
        you: 90,
        iserror: false,
    },
    {
        category: 'Smart Governance',
        name: 'Scheme Governance',
        value: 1,
        average: 78,
        you: 65,
        iserror: true,
    },
    {
        category: 'Smart Governance',
        name: 'Asset & member protection',
        value: 999,
        average: 60,
        you: 120,
        iserror: false,
    },
    {
        category: 'Smart Governance',
        name: 'Costs / Transparency',
        value: 1,
        average: 85,
        you: 88,
        iserror: false,
    },
];

export const clientChartDataByRating = [
    {
        category: 'Scheme Governance',
        values: [
            {
                name: 'Prior', colorindex: 1, striped:true,  
                value: 78,
            },
            {
                name: 'Current', colorindex: 1, striped:false,  
                value: 65,
                iserror: true,
            },
        ],
    },
    {
        category: 'Pension Scheme Design',
        values: [
            {
                name: 'Prior', colorindex: 1, striped:true,
                value: 86,
            },
            {
                name: 'Current', colorindex: 1, striped:false,
                value: 78,
                iserror: false,
            },
        ],
    },
    {
        category: 'Contribution Structure',
        values: [
            {
                name: 'Prior', colorindex: 1, striped:true,
                value: 98,
            },
            {
                name: 'Current', colorindex: 1, striped:false,
                value: 82,
                iserror: true,
            },
        ],
    },
    {
        category: 'Costs / Transparency',
        values: [
            {
                name: 'Prior', colorindex: 1, striped:true,
                value: 85,
            },
            {
                name: 'Current', colorindex: 1, striped:false,
                value: 88,
                iserror: false,
            },
        ],
    },
    {
        category: 'Asset & member protection',
        values: [
            {
                name: 'Prior', colorindex: 1, striped:true,
                value: 80,
            },
            {
                name: 'Current', colorindex: 1, striped:false,
                value: 120,
                iserror: false,
            },
        ],
    },
    {
        category: 'Retirement Member Services',
        values: [
            {
                name: 'Prior', colorindex: 1, striped:true,
                value: 80,
            },
            {
                name: 'Current', colorindex: 1, striped:false,
                value: 78,
                you: 90,
                iserror: false,
            },
        ],
    },
    {
        category: 'Investment',
        values: [
            {
                name: 'Prior', colorindex: 1, striped:true,
                value: 86,
            },
            {
                name: 'Current', colorindex: 1, striped:false,
                value: 96,
                iserror: false,
            },
        ],
    },
];


export const PensionCategoriesQuestions = [
    {
        name: 'Scheme Governance', isexpanded: false, expandheight: 500,
        value: 240,
        
    },
    {
        name: 'Pension Scheme Design', isexpanded: false, expandheight: 500,
        value: 578,
        
    },
    {
        name: 'Contribution Structure', isexpanded: false, expandheight: 1900,
        value: 654,
         
    },
    {
        name: 'Costs / Transparency', isexpanded: false, expandheight: 1900,
        value: 890,
        
    },
    {
        name: 'Asset & Member Protection', isexpanded: false, expandheight: 1200,
        value: 310,
        
    },
    {
        name: 'Retirement Member Services', isexpanded: false, expandheight: 700,
        value: 670,
       
    },
    {
        name: 'Investment', isexpanded: false, expandheight: 700,
        value: 630,
    }

];