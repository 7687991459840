export const locale = {
    lang: 'es-mx',
    data: {
        'DiseaseDetail': {
            'DiseaseDetailHeading': 'Prevalencias de las causas y padecimientos',
            'AllAmountInLocalCurrency': 'Todas las cantidades mostradas en moneda local',
            'SelectTop': 'Selección Top',
            'Diagnosis': 'Padecimiento',
            'ClaimsTotalHeader': 'Total de Trámites',    
            'DiagnosisLevel': 'Padecimiento',
            'Category': 'Categoría',               
            'Chapter': 'Padecimientos',
            'ClaimsTotal': 'Total pagado',
            'DigClaimantCount': 'Total casos', 
            'DiseaseProgression': 'Progresión a través del tiempo',
            'Age': 'Etario', 
            'CostPerClaimant': 'Costo por caso',
            'CostPerClaim': 'Costo por trámite',
            'ClaimsPerClaimant': 'Trámites por caso',
            'TotalCost': 'Total pagado',
            'ClaimantCount': 'Total casos',
            'Prevalence': 'Predominio',
            'Benchmark': 'Análisis comparativo',
            'IncludeClient': 'Incluir cliente',
            'ExcludeClient': 'Excluir cliente',
            'Portfolio': 'portafolio',
            'Industry': 'Industria',
            'None': 'Ninguna',
            'AgeBand': 'Rango de Edad',
            'Gender': 'Género',
            'Relationship': 'Parentesco',
            'ClaimType' : 'Tipo de Reclamación',
            'ClaimsReportedByPaidDate': 'Reclamaciones reportadas por fecha de pago',
            'ClaimsReportedByIncurredDate': 'Reclamaciones reportadas por fecha incurrida',            
            'ShownAsPer':'Se muestra según',
            'DateRange':'to',
            'DiagnosisGroup': 'Grupo de diagnóstico',
            'Actionable': 'Accionables',
            'Chronic': 'Crónico',
            'Infectious': 'Infeccioso',
            'Trauma/Accidents': 'Accidentes /Trauma',
            'Cancer': 'Cáncer',
            'Mental Health': 'Salud Mental',
            'Obstetrics': 'Obstétricos',
            'Others': 'Otros',
            'Preventable': 'Prevenible',
            'Intervenable': 'Intervenible'
        }
    }
};