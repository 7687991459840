export const locale = {
  lang: 'pt-br',
  data: {
    'AddFilter': {
      'ClientName': 'Nome do cliente',
      'Entity': 'Entidade',
      'Division': 'División',
      'Plan': 'Plano',
      'TimePeriod': 'Periodo de tiempo',
      'PolicyType': 'Tipo de Plano',
      'AgeBand': 'Faixa etária',
      'Gender': 'Gênero',
      'ClaimType': 'Tipo de evento',
      'ClaimType2': 'Sinistro Subtipo 2',
      'ClaimType3': 'Sinistro Subtipo 3',
      'Relationship': 'Relacionamento',
      'DiagnosisCategory': 'Categoría de diagnóstico',
      'DiagnosisChapter': 'Diagnosis Chapter',
      'Location': 'Localização',
      'IsChronic': 'Es crónico',
      'Carrier': 'Portador',
      'LOB': 'Linha de Negócio',
      'SubLOB': 'Sublinha de Negócio',
      'Network': 'Rede',
      'ProviderName': 'Nombre del proveedor',
      'ProviderLocation': 'Ubicación del proveedor',
      'ProviderType': 'Tipo de Prestador'
    }
  }
};