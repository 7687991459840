<div class="flex-column-simple" *ngIf="!isExecutiveVisible">
    <div class="flex-row-simple" style="margin: auto;">
        <div fxLayout="column" fxFlex="50" fxLayoutGap="12px" style="padding: 18px;" *ngIf="!isExecutiveVisible">
            <mat-spinner class="mat-accent" diameter="30"></mat-spinner>
        </div>
    </div>
</div>

<div class="contentPadding columnDiv">
    <div class=" ">
        <div class="rowDiv">
            <div>
                <aon-h1 style="color: #001b5a;">Welcome</aon-h1>
            </div>
            <div class="vertical-line marginLeft20"></div>
            <div class="marginLeft20 marginTop20">
                <aon-p-lg>
                    from 1 July, 2019 through 1 July, 2020
                </aon-p-lg>
            </div>
        </div>
    </div>

    <div *ngIf="isExecutiveVisible">
        <div class="rowDiv">
            <div style="flex: 2; height: 100%;">
                <financial-wellbeing-score> </financial-wellbeing-score>
            </div>
            <div style="flex: 1; height: 100%; width: 99%;">
                <aon-summary-card [colorindex]="1" [width]="cardwidth" [height]="cardheight">
                    <aon-summary-card-header>
                        <div class="flex-row-simple white-font2 flex-end"
                            style="width: 96%; height: 40px; margin-top: -5px;">
                            <aon-h3>Opportunities</aon-h3>
                            <!-- <div class="custom-button-ghost">View All</div> -->
                        </div>
                    </aon-summary-card-header>
                    <aon-summary-card-content [top]="contenttop" [contentheight]="contentheight">
                        <div class="white-font2">
                            <div class="content-header2 border-top contentOpportunity">
                                Financial Wellbeing Score
                            </div>
                            <!-- <div class="font-grayF dateCss">
                                Date
                            </div> -->
                            <div class="oppurtunityText font-grayF contentOpportunity">
                                <aon-p-lg>10% of employees have a poor financial wellbeing score</aon-p-lg>
                                <!-- </div>
                            <div class="flex-row-simple" style="margin-top: -5px;"> -->
                                <!-- <div><aon-button-default width='auto' id='btn-prominent'>Explore</aon-button-default></div> -->
                                <!-- <div style="width: 140px;">
                                    <div class="white-font aon-button-default-custom">
                                        Explore
                                    </div>
                                </div>
                                <div style="
                                      margin-left: 25px;
                                      padding-top: 15px;
                                  " class="custom-button-ghost">
                                    Dismiss
                                </div> -->
                            </div>

                            <!-- ============================================================================================= -->
                            <div class="content-header2 border-top contentOpportunity">
                                Retirement Adequacy
                            </div>
                            <!-- <div class="font-grayF dateCss">
                                Date
                            </div> -->
                            <div class="oppurtunityText font-grayF contentOpportunity" style="
                                  font-size: 16px;
                                   
                              ">
                                <aon-p-lg>15% of employees will not meet their target lifestyle</aon-p-lg>
                            </div>
                            <div class="content-header2 border-top contentOpportunity">

                            </div>
                            <!-- <div class="flex-row-simple border-bottom" style="margin-top: -5px;">
                                <div style="width: 140px;">
                                    <div class="white-font aon-button-default-custom">
                                        Explore
                                    </div>
                                </div>
                                <div style="
                                      margin-left: 25px;
                                      padding-top: 15px;
                                  " class="custom-button-ghost">
                                    Dismiss
                                </div>
                            </div> -->
                        </div>
                    </aon-summary-card-content>
                </aon-summary-card>
            </div>
        </div>
        <div class="rowDiv chartRow">
            <div style="flex: 2;">
                <demographics-score></demographics-score>
            </div>
            <div style="flex: 2;">
                <pension-score></pension-score>
            </div>
        </div>
        <div class="rowDiv chartRow">
            <div style="flex: 2;">
                <benefit-score></benefit-score>
            </div>
            <div style="flex: 2;">
                <retirement-adequacy></retirement-adequacy>
            </div>
        </div>
    </div>
</div>