export const locale = {
    lang: 'it',
    data: {
  'ClientGroupAdmin': {
    'ClientGroupAdministration': 'Amministrazione gruppo clienti',
    'ClientGroups': 'Gruppi di clienti',
    'NewClientGroup': 'NUOVO GRUPPO CLIENTI',
    'NewClientGroupName': 'Nome del nuovo gruppo di clienti',
    'Clients': 'Clienti',
    'Save': 'SALVA',
    'Success': 'Successo',
    'Name': 'Nome',
    'CreatedBy': 'Creato da',
    'CreatedOn': 'Creato il',
    'GroupName': 'Nome del gruppo',
    'ClientName': 'Nome cliente',
    'SelectedClients': 'Clienti selezionati',
    'UnselectedClients': 'Clienti non selezionati',
    'Search': 'Ricerca',
    'clientGroupColumnDefs': [{headerName: 'Nome', field: 'name', sort: 'asc'}],
    'clientColumnDefs': [{headerName: 'Nome', field: 'name', sort: 'asc'}]
  }
}};