<!-- PROGRESS BAR -->
<fuse-progress-bar id="fuseProgressBar"></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container id="verticalLay1" *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- VERTICAL LAYOUT 2 -->
<ng-container id="verticalLay2" *ngIf="fuseConfig.layout.style === 'vertical-layout-2'">
    <vertical-layout-2></vertical-layout-2>
</ng-container>
<!-- / VERTICAL LAYOUT 2 -->

<!-- VERTICAL LAYOUT 3 -->
<ng-container id="verticalLay3" *ngIf="fuseConfig.layout.style === 'vertical-layout-3'">
    <vertical-layout-3></vertical-layout-3>
</ng-container>
<!-- / VERTICAL LAYOUT 3 -->

<!-- HORIZONTAL LAYOUT 1 -->
<ng-container id="horizonLay1" *ngIf="fuseConfig.layout.style === 'horizontal-layout-1'">
    <horizontal-layout-1></horizontal-layout-1>
</ng-container>
<!-- / HORIZONTAL LAYOUT 1 -->

<!-- AON NEW LAYOUT -->
<ng-container id="aonnewlayout" *ngIf="fuseConfig.layout.style === 'aon-new-layout'">    
    <aon-layout-component></aon-layout-component>
</ng-container>
<!-- / HORIZONTAL LAYOUT 1 -->

<!-- THEME OPTIONS PANEL -->
<!-- <button mat-icon-button class="warn mat-elevation-z2 theme-options-button"
        (click)="toggleSidebarOpen('themeOptionsPanel')">
    <mat-icon>settings</mat-icon>
</button> -->

<fuse-sidebar id="fuseSide" name="themeOptionsPanel" class="theme-options-sidebar" position="right" [invisibleOverlay]="true">
    <fuse-theme-options></fuse-theme-options>
</fuse-sidebar>
<!-- / THEME OPTIONS PANEL -->